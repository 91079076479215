import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { Button, Callout, Card, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';

export interface IWagonDialogFormValues {
  etranNote: string;
  managerNote: string;
  managerNote2: string;
  managerNote3: string;
  owner: number | null;
}

interface IProps {
  batchEditing?: boolean;
  initialValues: IWagonDialogFormValues;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (
    values: IWagonDialogFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function WagonDialogForm({
  batchEditing,
  initialValues,
  isOpen,
  title,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ error, handleSubmit, submitError, submitting }) => (
        <DialogForm
          isOpen={isOpen}
          isSubmitting={submitting}
          title={title}
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormErrors error={error || submitError} />

            <VGrid>
              {batchEditing && (
                <Row>
                  <Callout icon="info-sign" intent={Intent.PRIMARY}>
                    Оставьте поле пустым, если не хотите менять его значение.
                  </Callout>
                </Row>
              )}

              <Row>
                <PartnersAutocompleteInFormGroupForFinalForm
                  label="Владелец"
                  name="owner"
                />

                <Card>
                  <FormGroupForFinalForm label="Заметка 1" name="managerNote">
                    <InputGroupForFinalForm name="managerNote" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm label="Заметка 2" name="managerNote2">
                    <InputGroupForFinalForm name="managerNote2" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm label="Заметка 3" name="managerNote3">
                    <InputGroupForFinalForm name="managerNote3" />
                  </FormGroupForFinalForm>
                </Card>
              </Row>

              <Row>
                <FormGroupForFinalForm
                  label="Заготовка в Этране"
                  name="etranNote"
                >
                  <InputGroupForFinalForm name="etranNote" />
                </FormGroupForFinalForm>
              </Row>
            </VGrid>
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button disabled={submitting} text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Сохранить"
                type="submit"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

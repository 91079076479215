import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Toolbar } from '_core/toolbar';
import { Button, Intent, Popover, Position } from '@blueprintjs/core';
import * as React from 'react';
import { useState } from 'react';

import * as css from './batchRentWagonsPopover.module.css';

interface IFormValues {
  batch: string;
}

interface IProps {
  disabled: boolean | undefined;
  onSubmit: (values: IFormValues) => void;
}

export function BatchRentWagonsPopover({ disabled, onSubmit }: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      content={
        <FinalForm<IFormValues>
          initialValues={{ batch: '' }}
          onSubmit={values => {
            onSubmit(values);
            setIsOpen(false);
          }}
        >
          {({ error, handleSubmit, submitError, submitting }) => (
            <BaseForm onSubmit={handleSubmit}>
              <FormErrors error={error || submitError} />

              <FormGroupForFinalForm labelFor="batch" name="batch">
                <InputMultipleForFinalForm
                  autoFocus
                  id="batch"
                  name="batch"
                  rows={8}
                />
              </FormGroupForFinalForm>

              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Закрыть"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Добавить"
                  type="submit"
                />
              </Toolbar>
            </BaseForm>
          )}
        </FinalForm>
      }
      isOpen={isOpen}
      popoverClassName={css.main}
      position={Position.LEFT_TOP}
      onInteraction={setIsOpen}
    >
      <Button disabled={disabled} text="Добавить из аренды" />
    </Popover>
  );
}

import { parseAWSUrl } from '_core/aws';
import { Toolbar } from '_core/toolbar';
import { Button, Classes, Intent, Popover, Position } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';

import * as css from './notesFilesCell.module.css';

interface IProps {
  files: string[];
  onFileRemoveClick: (file: string) => void;
  onFilesUploadRequest: (files: File[]) => void;
}

export function NotesFilesCell({
  files,
  onFileRemoveClick,
  onFilesUploadRequest,
}: IProps) {
  return (
    <Popover
      content={
        <>
          {files.length !== 0 && (
            <div className={css.filesTableWrapper}>
              <table className={css.filesTable}>
                <tbody>
                  {files.map(file => {
                    const { id, filename } = parseAWSUrl(file);

                    return (
                      <tr key={id}>
                        <td className={css.filesTableCell}>
                          <a
                            className={css.fileLink}
                            download
                            href={file}
                            rel="noopener"
                            target="_blank"
                          >
                            {filename}
                          </a>
                        </td>

                        <td
                          className={cx(
                            css.filesTableCell,
                            css.filesTableCell_isRemoveBtnCell
                          )}
                        >
                          <Button
                            icon="delete"
                            intent={Intent.DANGER}
                            minimal
                            small
                            onClick={() => onFileRemoveClick(file)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          <Toolbar align="right">
            <Button className={Classes.POPOVER_DISMISS} text="Закрыть" />

            <label
              className={cx(
                Classes.BUTTON,
                Classes.intentClass(Intent.PRIMARY)
              )}
            >
              <input
                multiple
                style={{ display: 'none' }}
                type="file"
                onChange={event => {
                  onFilesUploadRequest(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    Array.from(event.currentTarget.files!)
                  );
                }}
                value=""
              />
              Загрузить
            </label>
          </Toolbar>
        </>
      }
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      position={Position.LEFT_TOP}
    >
      <Button
        fill
        icon="document"
        intent={Intent.PRIMARY}
        minimal
        small
        text={String(files.length)}
      />
    </Popover>
  );
}

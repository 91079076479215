import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { LinkButton } from '_core/router5/linkButton';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Intent, NonIdealState } from '@blueprintjs/core';
import { IContractSell } from 'contractsSell/api';
import {
  createContractsSellAgreement,
  fetchContractsSellAgreements,
  IContractsSellAgreement,
  updateContractsSellAgreement,
} from 'contractsSellAgreements/api';
import dayjs from 'dayjs';
import { DocumentStatusIndicator } from 'documents/statusIndicator';
import { DocumentStatus } from 'documents/types';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouteNode } from 'react-router5';

import { AgreementForm } from './agreements/agreementForm';
import { DocumentListLayout } from './documentListLayout';

interface IProps {
  contract: IContractSell;
}

export function ContractsSellViewAgreementsTab({ contract }: IProps) {
  const { route, router } = useRouteNode('contracts.sell.view.agreements');
  const navigationLock = useFormNavigationLock(route.name);
  const api = useApiClient();

  const { data, isFetching, updateData } = useAsyncData(
    [contract.id, api],
    async () => {
      const response = await fetchAllPages(page =>
        fetchContractsSellAgreements(api, {
          contract: contract.id,
          page,
        })
      );

      return response.results;
    }
  );

  const selectedAgreementId = Number(route.params.agreementId);

  const selectedAgreement = data?.find(
    agreement => agreement.id === selectedAgreementId
  );

  const [editingLocked, setEditingLocked] = useState(selectedAgreement != null);

  useEffect(() => {
    setEditingLocked(selectedAgreement != null);
  }, [selectedAgreement]);

  const isCreateRoute = route.name === 'contracts.sell.view.agreements.create';

  const addBtn = (
    <LinkButton
      intent={Intent.PRIMARY}
      params={{ ...route.params, agreementId: undefined }}
      text="Добавить"
      to="contracts.sell.view.agreements.create"
    />
  );

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : data.length === 0 && !isCreateRoute ? (
    <NonIdealState
      action={addBtn}
      icon="list"
      title="У договора нет дополнительных соглашений"
    />
  ) : (
    <VGrid>
      <Row>
        <Toolbar align="right">{addBtn}</Toolbar>
      </Row>

      <Row>
        <DocumentListLayout
          list={
            data.length !== 0 && (
              <Table
                getRowProps={agreement => ({
                  selected: selectedAgreement === agreement,
                })}
                isFetching={isFetching}
                items={data}
              >
                <Column
                  width={150}
                  id="name"
                  label="Наименование"
                  cell={(agreement: IContractsSellAgreement) => (
                    <LinkCell
                      params={{ ...route.params, agreementId: agreement.id }}
                      to="contracts.sell.view.agreements.view"
                    >
                      {`${agreement.number} (${dayjs(agreement.date).format(
                        DATE_FORMAT_DATE
                      )})`}
                    </LinkCell>
                  )}
                />

                <Column
                  width={80}
                  id="status"
                  label="Статус"
                  cell={(agreement: IContractsSellAgreement) => (
                    <DocumentStatusIndicator status={agreement.status} />
                  )}
                />
              </Table>
            )
          }
          form={
            !selectedAgreement && !isCreateRoute ? (
              <NonIdealState
                icon="select"
                description="Выберите соглашение в списке слева или добавьте новое"
                title="Соглашений не выбрано"
              />
            ) : (
              <AgreementForm
                agreement={selectedAgreement}
                editingLocked={editingLocked}
                initialValues={
                  selectedAgreement
                    ? selectedAgreement
                    : {
                        additionalDowntimeRate: '0.00',
                        contract: contract.id,
                        date: new Date(),
                        daysLoad: contract.daysLoad,
                        daysUnload: contract.daysUnload,
                        downtimeLimit: contract.downtimeLimit,
                        downtimeRate: contract.downtimeRate,
                        downtimeVatRate: contract.downtimeVatRate,
                        files: [],
                        note: '',
                        number: '',
                        status: DocumentStatus.Created,
                      }
                }
                navigationLock={navigationLock}
                onCancelEditing={
                  editingLocked || selectedAgreement == null
                    ? undefined
                    : () => {
                        setEditingLocked(true);
                      }
                }
                onStartEditing={
                  editingLocked
                    ? () => {
                        if (
                          // eslint-disable-next-line no-alert
                          window.confirm(
                            'Вы собираетесь изменить сохранённое дополнительное соглашение'
                          )
                        ) {
                          setEditingLocked(false);
                        }
                      }
                    : undefined
                }
                onSubmit={async values => {
                  try {
                    if (selectedAgreement) {
                      const updatedAgreement =
                        await updateContractsSellAgreement(
                          api,
                          selectedAgreement.id,
                          values
                        );

                      updateData(
                        prevData =>
                          prevData &&
                          prevData.map(agreement =>
                            agreement.id === updatedAgreement.id
                              ? updatedAgreement
                              : agreement
                          )
                      );

                      setEditingLocked(true);
                    } else {
                      const newAgreement = await createContractsSellAgreement(
                        api,
                        values
                      );

                      updateData(
                        prevData => prevData && [newAgreement].concat(prevData)
                      );

                      navigationLock.unlock();

                      router.navigate('contracts.sell.view.agreements.view', {
                        ...route.params,
                        agreementId: newAgreement.id,
                      });
                    }

                    return undefined;
                  } catch (err) {
                    return submissionErrorsFromApiError(
                      err,
                      selectedAgreement
                        ? 'Не удалось изменить доп. соглашение: Непредвиденная ошибка'
                        : 'Не удалось создать доп. соглашение: Непредвиденная ошибка'
                    );
                  }
                }}
              />
            )
          }
        />
      </Row>
    </VGrid>
  );
}

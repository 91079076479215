import { Checkbox as BlueprintCheckbox } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';
import * as reactFinalForm from 'react-final-form';

import * as css from './checkbox.module.css';

interface ICommonProps {
  className?: string;
  disabled?: boolean;
  editingLocked?: boolean;
  id?: string;
  inline?: boolean;
  label?: string;
  noBottomMargin?: boolean;
}

interface IProps extends ICommonProps {
  checked?: boolean;
  name?: string;
  value?: string;
  onChange?: (newChecked: boolean) => void;
  onClick?: (
    checked: boolean,
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void;
}

export function Checkbox({
  className,
  disabled,
  editingLocked,
  id,
  name,
  noBottomMargin,
  onChange,
  onClick,
  ...otherProps
}: IProps) {
  return (
    <BlueprintCheckbox
      {...otherProps}
      className={cx(className, {
        [css.main_editingLocked]: editingLocked,
        [css.main_noBottomMargin]: noBottomMargin,
      })}
      disabled={disabled || editingLocked}
      id={id}
      name={name}
      onChange={
        onChange &&
        (event => {
          onChange(event.currentTarget.checked);
        })
      }
      onClick={
        onClick && (event => onClick(event.currentTarget.checked, event))
      }
    />
  );
}

export function CheckboxForFinalForm({
  disabled,
  name,
  onChange,
  ...otherProps
}: ICommonProps & { name: string; onChange?: (newChecked: boolean) => void }) {
  return (
    <reactFinalForm.Field name={name}>
      {({ input, meta }) => (
        <Checkbox
          checked={input.value}
          disabled={meta.submitting || disabled}
          name={input.name}
          onChange={newValue => {
            if (onChange) {
              onChange(newValue);
            }

            input.onChange(newValue);
          }}
          {...otherProps}
        />
      )}
    </reactFinalForm.Field>
  );
}

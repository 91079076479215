import { WeighingAlert, WeighingRecord } from './api';

const alertWeighingColors = {
  [WeighingAlert.Overload]: '#E03535',
  [WeighingAlert.NetLt20]: '#E46F7E',
  [WeighingAlert.GrossGt90]: '#ffd966',
};

const alertWeighingLabels = {
  [WeighingAlert.Overload]: 'Перегруз',
  [WeighingAlert.NetLt20]: 'Нетто < 20 т.',
  [WeighingAlert.GrossGt90]: 'Брутто > 90 т.',
};

export const weighingAlerts = [
  WeighingAlert.Overload,
  WeighingAlert.NetLt20,
  WeighingAlert.GrossGt90,
];

export const getWeighingAlertColor = (alert: WeighingAlert) =>
  alertWeighingColors[alert];

export const getWeighingAlertLabel = (alert: WeighingAlert) =>
  alertWeighingLabels[alert];

export const getWeighingRowColor = ({
  weighingRecord,
}: {
  weighingRecord: WeighingRecord;
}) => {
  const highestPriorityAlert = weighingAlerts.find(alertItem =>
    weighingRecord.alerts.includes(alertItem)
  );

  return highestPriorityAlert && getWeighingAlertColor(highestPriorityAlert);
};

export const weighingAlertsOptions = weighingAlerts.map(alert => ({
  label: getWeighingAlertLabel(alert),
  value: alert,
}));

import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { LinkStyledButton } from '_core/linkStyledButton';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { useAsyncData } from '_core/useAsyncData';
import {
  Boundary,
  Classes,
  Menu,
  OverflowList,
  Popover,
} from '@blueprintjs/core';
import dayjs from 'dayjs';
import * as React from 'react';
import { Fragment, useCallback, useMemo } from 'react';
import { fetchWagonParksHistory, IWagonParksHistoryItem } from 'wagons/api';

interface IWagonParksHistoryTableItem extends IWagonParksHistoryItem {
  index: number;
}

interface IProps {
  wagonNumber: string;
}

export function WagonsEditParksHistoryTab({ wagonNumber }: IProps) {
  const api = useApiClient();

  const { data, isFetching } = useAsyncData([api, wagonNumber], async () => {
    const historyItems = await fetchWagonParksHistory(api, wagonNumber);

    return historyItems.map(
      (historyItem, index): IWagonParksHistoryTableItem => ({
        ...historyItem,
        index,
      })
    );
  });

  const columns = useMemo(
    (): Array<ListTableColumn<IWagonParksHistoryTableItem>> => [
      {
        id: 'name',
        label: 'Название парка',
        defaultWidth: 450,
        copyCellContent: historyItem => historyItem.parkName,
        renderCellContent: historyItem =>
          historyItem.parkId ? (
            <Ellipsis
              component={Link}
              params={{ id: historyItem.parkId }}
              to="parks.edit"
            >
              {historyItem.parkName}
            </Ellipsis>
          ) : (
            <Ellipsis component="span">{historyItem.parkName}</Ellipsis>
          ),
      },
      {
        id: 'inPark',
        label: 'Сейчас в парке',
        defaultWidth: 130,
        copyCellContent: historyItem => (historyItem.inPark ? 'Да' : 'Нет'),
        renderCellContent: historyItem => (
          <Ellipsis component="span">
            {historyItem.inPark ? 'Да' : 'Нет'}
          </Ellipsis>
        ),
      },
      {
        id: 'periods',
        label: 'Период нахождения',
        defaultWidth: 450,
        copyCellContent: historyItem =>
          historyItem.periods
            .map(
              ([startDate, endDate]) =>
                `С ${dayjs(startDate).format(DATE_FORMAT_DATE)} по ${dayjs(
                  endDate
                ).format(DATE_FORMAT_DATE)}`
            )
            .join(', '),
        renderCellContent: historyItem => (
          <OverflowList
            collapseFrom={Boundary.END}
            items={historyItem.periods}
            overflowRenderer={overflowItems => (
              <Popover
                content={
                  <Menu>
                    {overflowItems.map(([startDate, endDate], index) => (
                      <li className={Classes.MENU_ITEM} key={index}>
                        {`С ${dayjs(startDate).format(
                          DATE_FORMAT_DATE
                        )} по ${dayjs(endDate).format(DATE_FORMAT_DATE)}`}
                      </li>
                    ))}
                  </Menu>
                }
              >
                <LinkStyledButton>ещё {overflowItems.length}</LinkStyledButton>
              </Popover>
            )}
            visibleItemRenderer={([startDate, endDate], index) => (
              <Fragment key={index}>
                {`С ${dayjs(startDate).format(DATE_FORMAT_DATE)} по ${dayjs(
                  endDate
                ).format(DATE_FORMAT_DATE)}`}

                {index !== historyItem.periods.length - 1 ? (
                  <span>,&nbsp;</span>
                ) : null}
              </Fragment>
            )}
          />
        ),
      },
    ],
    []
  );

  const getItemId = useCallback(
    (historyItem: IWagonParksHistoryTableItem) => String(historyItem.index),
    []
  );

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : data.length === 0 ? (
    <EmptyListMessage />
  ) : (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      isFetching={isFetching}
      items={data}
    />
  );
}

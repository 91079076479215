interface IParsedAWSUrl {
  id: string;
  filename: string;
}

export function parseAWSUrl(url: string): IParsedAWSUrl {
  const [pathname] = url.split('?');
  const [id, filename] = pathname.split('/').slice(-2);

  return { id, filename };
}

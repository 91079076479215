import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import {
  DateInputInFormGroup,
  DateInputInFormGroupForFinalForm,
} from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroupForFinalForm } from '_core/inputs/inputMoney';
import { SelectForFinalForm } from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, H4, Intent } from '@blueprintjs/core';
import { UsersAutocompleteInFormGroup } from 'accounts/autocomplete';
import { ContactsAutocompleteInFormGroupForFinalForm } from 'contacts/autocomplete';
import { DocumentStatusSelectForFinalForm } from 'documents/statusSelect';
import { DocumentStatus } from 'documents/types';
import {
  ApplyExpeditionCostFor,
  ExpeditionProtocolSellRateType,
  IExpeditionProtocolSell,
} from 'expeditionProtocolsSell/api';
import {
  applyExpeditionCostForOptions,
  expeditionProtocolSellRateTypeOptions,
} from 'expeditionProtocolsSell/utils';
import {
  ExpeditionStationType,
  expeditionStationTypesOptions,
} from 'expeditions/types';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { StationsAutocompleteInFormGroupForFinalForm } from 'stations/autocomplete';
import {
  costFromTotalCostForForm,
  totalCostForForm,
  VatRate,
  vatRateOptions,
} from 'vatRates/vatRates';

import {
  ExpeditionProtocolRatesField,
  IExpeditionProtocolRatesFieldValue,
} from './ratesField';

export interface IExpeditionProtocolFormValues {
  applyExpeditionCostFor: ApplyExpeditionCostFor;
  compensateEmptyTariff: boolean;
  compensateLoadedTariff: boolean;
  emptyTariffPercent: string;
  endDate: Date | null;
  expeditionCost: string;
  expeditionCostTotal: string;
  expeditionCostVat: VatRate;
  files: string[];
  isEmptyTariffPercent: boolean;
  isLoadedTariffPercent: boolean;
  loadedTariffPercent: string;
  note: string;
  number: string;
  partnerSign: number | null;
  rates: IExpeditionProtocolRatesFieldValue[];
  rateType: ExpeditionProtocolSellRateType;
  signerAuthority: string;
  startDate: Date | null;
  station: number | null;
  stationType: ExpeditionStationType;
  status: DocumentStatus;
}

interface IProps {
  editingLocked: boolean;
  initialValues: IExpeditionProtocolFormValues;
  navigationLock: IFormNavigationLock;
  selectedProtocol: IExpeditionProtocolSell | undefined;
  onCancelEditing: (() => void) | undefined;
  onDuplicate: (() => void) | undefined;
  onStartEditing: (() => void) | undefined;
  onSubmit: (
    values: IExpeditionProtocolFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function ExpeditionProtocolForm({
  editingLocked,
  initialValues,
  navigationLock,
  selectedProtocol,
  onCancelEditing,
  onDuplicate,
  onStartEditing,
  onSubmit,
}: IProps) {
  return (
    <FinalForm<IExpeditionProtocolFormValues>
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <H4>
            {selectedProtocol
              ? `Протокол ${selectedProtocol.protocolNumber}`
              : 'Новый протокол'}
          </H4>

          <VGrid>
            <Row>
              <Grid>
                <Col span={4}>
                  <H4>Основная информация</H4>

                  <FormGroupForFinalForm
                    label="Номер"
                    labelFor="number"
                    name="number"
                  >
                    <InputGroupForFinalForm
                      id="number"
                      name="number"
                      readOnly={editingLocked}
                      required
                    />
                  </FormGroupForFinalForm>

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="startDate"
                        label="Дата начала"
                        name="startDate"
                        readOnly={editingLocked}
                        required
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="endDate"
                        label="Дата окончания"
                        name="endDate"
                        readOnly={editingLocked}
                        required
                      />
                    </Col>
                  </Grid>

                  <FormGroupForFinalForm
                    label="Вид ставки"
                    labelFor="rateType"
                    name="rateType"
                  >
                    <SelectForFinalForm
                      disabled={editingLocked}
                      fill
                      id="rateType"
                      name="rateType"
                      options={expeditionProtocolSellRateTypeOptions}
                    />
                  </FormGroupForFinalForm>

                  <DocumentStatusSelectForFinalForm
                    disabled={editingLocked}
                    id="status"
                    label="Статус"
                    name="status"
                  />

                  {selectedProtocol && (
                    <Grid>
                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата создания"
                          readOnly
                          value={selectedProtocol.created}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор"
                          readOnly
                          value={selectedProtocol.author}
                        />
                      </Col>

                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата изменения"
                          readOnly
                          value={selectedProtocol.modified}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор изменений"
                          readOnly
                          value={selectedProtocol.authorModified}
                        />
                      </Col>
                    </Grid>
                  )}
                </Col>

                <Col span={4}>
                  <H4>Станция</H4>

                  <FormGroupForFinalForm
                    label="Тип"
                    labelFor="stationType"
                    name="stationType"
                  >
                    <SelectForFinalForm
                      disabled={editingLocked}
                      id="stationType"
                      name="stationType"
                      options={expeditionStationTypesOptions}
                      onChange={newStationType => {
                        if (newStationType === ExpeditionStationType.Any) {
                          form.change('station', null);
                        }
                      }}
                    />
                  </FormGroupForFinalForm>

                  <StationsAutocompleteInFormGroupForFinalForm
                    disabled={values.stationType === ExpeditionStationType.Any}
                    readOnly={editingLocked}
                    id="station"
                    label="Станция"
                    name="station"
                  />
                </Col>

                <Col span={4}>
                  <H4>Подпись</H4>

                  <ContactsAutocompleteInFormGroupForFinalForm
                    id="partnerSign"
                    label="Подписывающий контакт"
                    name="partnerSign"
                    readOnly={editingLocked}
                  />

                  <FormGroupForFinalForm
                    label="Основание для подписи"
                    labelFor="signerAuthority"
                    name="signerAuthority"
                  >
                    <InputGroupForFinalForm
                      id="signerAuthority"
                      name="signerAuthority"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Примечание"
                    labelFor="note"
                    name="note"
                  >
                    <InputGroupForFinalForm
                      id="note"
                      name="note"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>
                </Col>
              </Grid>
            </Row>

            <Row>
              <H4>Стоимость экспедирования</H4>

              <Grid>
                <Col span={4}>
                  <Grid>
                    <Col span={2}>
                      <InputMoneyInFormGroupForFinalForm
                        id="expeditionCost"
                        label="Стоимость"
                        name="expeditionCost"
                        readOnly={editingLocked}
                        onChange={newExpeditionCost => {
                          form.change(
                            'expeditionCostTotal',
                            totalCostForForm(
                              newExpeditionCost,
                              values.expeditionCostVat
                            )
                          );
                        }}
                      />
                    </Col>

                    <Col span={2}>
                      <FormGroupForFinalForm
                        label="Ставка НДС"
                        labelFor="expeditionCostVat"
                        name="expeditionCostVat"
                      >
                        <SelectForFinalForm
                          disabled={editingLocked}
                          fill
                          id="expeditionCostVat"
                          name="expeditionCostVat"
                          options={vatRateOptions}
                          onChange={newExpeditionCostVat => {
                            form.change(
                              'expeditionCostTotal',
                              totalCostForForm(
                                values.expeditionCost,
                                newExpeditionCostVat
                              )
                            );
                          }}
                        />
                      </FormGroupForFinalForm>
                    </Col>
                  </Grid>

                  <InputMoneyInFormGroupForFinalForm
                    id="expeditionCostTotal"
                    label="Стоимость (в т.ч. НДС)"
                    name="expeditionCostTotal"
                    readOnly={editingLocked}
                    onChange={newExpeditionCostTotal => {
                      form.change(
                        'expeditionCost',
                        costFromTotalCostForForm(
                          newExpeditionCostTotal,
                          values.expeditionCostVat
                        )
                      );
                    }}
                  />
                </Col>

                <Col span={4}>
                  <FormGroupForFinalForm
                    label="Начислять стоимость экспедирования"
                    labelFor="applyExpeditionCostFor"
                    name="applyExpeditionCostFor"
                  >
                    <SelectForFinalForm
                      disabled={editingLocked}
                      fill
                      id="applyExpeditionCostFor"
                      name="applyExpeditionCostFor"
                      options={applyExpeditionCostForOptions}
                    />
                  </FormGroupForFinalForm>
                </Col>
              </Grid>
            </Row>

            <Row>
              <H4>Опции</H4>

              <Grid>
                <Col span={6}>
                  <CheckboxForFinalForm
                    disabled={editingLocked}
                    id="compensateLoadedTariff"
                    label="Компенсировать гружёный тариф"
                    name="compensateLoadedTariff"
                    onChange={newCompensateLoadedTariff => {
                      if (!newCompensateLoadedTariff) {
                        form.change('isLoadedTariffPercent', false);
                      }
                    }}
                  />

                  <CheckboxForFinalForm
                    disabled={editingLocked || !values.compensateLoadedTariff}
                    id="isLoadedTariffPercent"
                    label="Груженый тариф указан процентом"
                    name="isLoadedTariffPercent"
                  />

                  {values.isLoadedTariffPercent && (
                    <FormGroupForFinalForm
                      label="Груженый тариф (процент)"
                      labelFor="loadedTariffPercent"
                      name="loadedTariffPercent"
                    >
                      <InputGroupForFinalForm
                        id="loadedTariffPercent"
                        name="loadedTariffPercent"
                        readOnly={editingLocked}
                      />
                    </FormGroupForFinalForm>
                  )}
                </Col>

                <Col span={6}>
                  <CheckboxForFinalForm
                    disabled={editingLocked}
                    id="compensateEmptyTariff"
                    label="Компенсировать порожний тариф"
                    name="compensateEmptyTariff"
                    onChange={newCompensateEmptyTariff => {
                      if (!newCompensateEmptyTariff) {
                        form.change('isEmptyTariffPercent', false);
                      }
                    }}
                  />

                  <CheckboxForFinalForm
                    disabled={editingLocked || !values.compensateEmptyTariff}
                    id="isEmptyTariffPercent"
                    label="Порожний тариф указан процентом"
                    name="isEmptyTariffPercent"
                  />

                  {values.isEmptyTariffPercent && (
                    <FormGroupForFinalForm
                      label="Порожний тариф (процент)"
                      labelFor="emptyTariffPercent"
                      name="emptyTariffPercent"
                    >
                      <InputGroupForFinalForm
                        id="emptyTariffPercent"
                        name="emptyTariffPercent"
                        readOnly={editingLocked}
                      />
                    </FormGroupForFinalForm>
                  )}
                </Col>
              </Grid>
            </Row>

            <Row>
              <H4>Файлы протокола</H4>

              <FileInputMultipleForFinalForm
                disabled={editingLocked}
                id="files"
                name="files"
              />
            </Row>

            {(values.compensateEmptyTariff ||
              values.compensateLoadedTariff) && (
              <Row>
                <H4>Ставки</H4>

                <ExpeditionProtocolRatesField
                  change={form.change}
                  name="rates"
                  readOnly={editingLocked}
                  showEmptyCostColumns={!values.isEmptyTariffPercent}
                  showLoadedCostColumns={!values.isLoadedTariffPercent}
                />
              </Row>
            )}

            <Row stickToBottom>
              <Toolbar align="right">
                {onDuplicate && (
                  <Button
                    disabled={submitting}
                    icon="duplicate"
                    text="Дублировать"
                    onClick={onDuplicate}
                  />
                )}

                {onStartEditing && (
                  <Button
                    disabled={submitting}
                    icon="unlock"
                    intent={Intent.DANGER}
                    text="Редактировать"
                    onClick={onStartEditing}
                  />
                )}

                {onCancelEditing && (
                  <Button
                    disabled={submitting}
                    text="Отменить"
                    onClick={() => {
                      form.reset();
                      onCancelEditing();
                    }}
                  />
                )}

                {!editingLocked && (
                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    loading={submitting}
                    text="Сохранить"
                    type="submit"
                  />
                )}
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';

import { IConsigner } from './types';

interface IFetchConsignersParams {
  page?: number;
  pageSize?: number;
  search?: string;
  sorting?: ISorting[];
}

export function fetchConsigners(
  api: ApiClient,
  { page, pageSize, search, sorting }: IFetchConsignersParams = {}
) {
  return api.get<ListResponse<IConsigner>>('/consigners', {
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    pageSize,
    search,
  });
}

export function fetchConsigner(api: ApiClient, id: number) {
  return api.get<IConsigner>(`/consigners/${id}`);
}

interface ICreateOrUpdateConsignerParams {
  email: string;
  legalAddress: string;
  name: string;
  note: string;
  okpo: string;
  partner: number | null;
  phone: string;
}

export function createConsigner(
  api: ApiClient,
  params: ICreateOrUpdateConsignerParams
) {
  return api.post<IConsigner>('/consigners', params);
}

export function updateConsigner(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateConsignerParams
) {
  return api.put<IConsigner>(`/consigners/${id}`, params);
}

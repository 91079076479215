import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { formatMoney } from '_core/money/formatMoney';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToApiQueryParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import { snakeCase } from 'change-case';
import { IContractPurchaseSerialized } from 'contractsPurchase/api';
import { IContractSellSerialized } from 'contractsSell/api';
import dayjs from 'dayjs';
import {
  // Col, Grid,
  Row,
  VGrid,
} from 'layout/contentLayout';
import { IPartnerSerialized } from 'partners/api';
import { PartnersFilter } from 'partners/filter';
import { getPaymentTypeLabel, IPayment, OperationType } from 'payments/types';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';

// import { SortingInput } from '../../_core/sortingInput';

// enum PaymentSortableFields {
//   Number = 'number',
//   Date = 'date',
//   DatePaid = 'datePaid',
// }

interface ListTableItem
  extends Omit<
    IPayment,
    'consignee' | 'contractPurchase' | 'contractSell' | 'payer'
  > {
  consignee: IPartnerSerialized | null;
  contractPurchase: IContractPurchaseSerialized | null;
  contractSell: IContractSellSerialized | null;
  payer: IPartnerSerialized | null;
}

const propsToFetch = {
  consignee: '/partners',
  contractPurchase: '/contracts_purchase',
  contractSell: '/contracts_sell',
  payer: '/partners',
};

export default function PaymentsListRoute() {
  const { route, router } = useRouteNode('payments');
  const api = useApiClient();

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'created',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const { data, isFetching, refetch } = useAsyncData(
    [api, route, sorting],
    async () => {
      const paymentsSorting: ISorting[] = [];
      paymentsSorting.push(sorting);

      const { meta, results } = await api.get<ListResponse<IPayment>>(
        '/payments_bank_info',
        {
          consignee: route.params.consignee,
          number: route.params.number,
          ordering: sortingToApiQueryParam(paymentsSorting, snakeCase),
          page: route.params.page,
          pageSize: route.params.pageSize,
          payer: route.params.payer,
        }
      );

      return {
        currentPage: meta.currentPage,
        pageSize: meta.pageSize,
        payments: await (fetchRelated(api, propsToFetch, results) as Promise<
          ListTableItem[]
        >),
        totalPages: meta.totalPages,
      };
    }
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, unknown>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ ordering: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const columns = useMemo(
    (): Array<ListTableColumn<ListTableItem>> => [
      {
        id: 'number',
        label: 'Номер',
        defaultWidth: 70,
        sortable: true,
        copyCellContent: payment => String(payment.number),
        renderCellContent: payment => (
          <Ellipsis
            component={Link}
            params={{ id: payment.id }}
            to="payments.edit"
          >
            {String(payment.number)}
          </Ellipsis>
        ),
      },
      {
        id: 'type',
        label: 'Тип документа',
        defaultWidth: 180,
        copyCellContent: payment => getPaymentTypeLabel(payment.type),
        renderCellContent: payment => (
          <Ellipsis component="span">
            {getPaymentTypeLabel(payment.type)}
          </Ellipsis>
        ),
      },
      {
        id: 'date',
        label: 'Дата',
        defaultWidth: 80,
        sortable: true,
        copyCellContent: payment =>
          dayjs(payment.date).format(DATE_FORMAT_DATE),
        renderCellContent: payment => (
          <Ellipsis component="span">
            {dayjs(payment.date).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'amount',
        label: 'Сумма',
        defaultWidth: 120,
        copyCellContent: payment => formatMoney(payment.amount),
        renderCellContent: payment => (
          <Ellipsis component="span">{formatMoney(payment.amount)}</Ellipsis>
        ),
      },
      {
        id: 'datePaid',
        label: 'Дата cписания',
        defaultWidth: 80,
        sortable: true,
        copyCellContent: payment =>
          dayjs(payment.datePaid).format(DATE_FORMAT_DATE),
        renderCellContent: payment => (
          <Ellipsis component="span">
            {dayjs(payment.datePaid).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'payer',
        label: 'Плательщик',
        defaultWidth: 180,
        copyCellContent: payment =>
          payment.payer ? payment.payer.shortName : '',
        renderCellContent: payment =>
          payment.payer && (
            <Ellipsis
              component={Link}
              params={{ id: payment.payer.id }}
              to="partners.edit"
            >
              {payment.payer.shortName}
            </Ellipsis>
          ),
      },
      {
        id: 'payerName',
        label: 'Плательщик наименование',
        defaultWidth: 220,
        copyCellContent: payment => payment.payerName,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.payerName}</Ellipsis>
        ),
      },
      {
        id: 'consignee',
        label: 'Получатель',
        defaultWidth: 180,
        copyCellContent: payment =>
          payment.consignee ? payment.consignee.shortName : '',
        renderCellContent: payment =>
          payment.consignee && (
            <Ellipsis
              component={Link}
              params={{ id: payment.consignee.id }}
              to="partners.edit"
            >
              {payment.consignee.shortName}
            </Ellipsis>
          ),
      },
      {
        id: 'consigneeName',
        label: 'Получатель наименование',
        defaultWidth: 220,
        copyCellContent: payment => payment.consigneeName,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.consigneeName}</Ellipsis>
        ),
      },
      {
        id: 'purpose',
        label: 'Назначение платежа',
        defaultWidth: 500,
        copyCellContent: payment => payment.purpose,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.purpose}</Ellipsis>
        ),
      },
      {
        id: 'contract',
        label: 'Договор',
        defaultWidth: 100,
        copyCellContent: payment => {
          const contract =
            payment.operationType === OperationType.Sell
              ? payment.contractSell
              : payment.contractPurchase;

          return contract ? contract.number : '';
        },

        renderCellContent: payment => {
          const contract =
            payment.operationType === OperationType.Sell
              ? payment.contractSell
              : payment.contractPurchase;

          return (
            contract && (
              <Link
                params={{ id: contract.id }}
                to={`contracts.${payment.operationType}.view`}
              >
                {contract.number}
              </Link>
            )
          );
        },
      },
    ],
    []
  );

  const getItemId = useCallback(
    (payment: ListTableItem) => String(payment.id),
    []
  );

  const getRowColor = useCallback(
    (payment: ListTableItem) => (payment.hasDuplicate ? '#ff6e4a' : undefined),
    []
  );

  // const defaultSorting: ISorting<PaymentSortableFields> = {
  //   field: PaymentSortableFields.Date,
  //   direction: SortingDirection.Descending,
  // };

  return (
    <>
      <FormattedTitle>Банк</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Toolbar align="right">
            <PartnersFilter
              initialValue={route.params.payer}
              label="Плательщик"
              onApply={payer => {
                applyFilterParams({ payer });
              }}
            />

            <PartnersFilter
              initialValue={route.params.consignee}
              onApply={consignee => {
                applyFilterParams({ consignee });
              }}
              label="Получатель"
            />

            <Button text="Обновить" onClick={refetch} />
            <LinkButton text="Сбросить фильтры" to={route.name} />

            <LinkButton
              intent={Intent.PRIMARY}
              params={{ listParams: JSON.stringify(route.params) }}
              text="Импорт"
              to="payments.import"
            />
          </Toolbar>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <>
              {/* <Row> */}
              {/*  <Grid> */}
              {/*    <Col align="end"> */}
              {/*      <SortingInput */}
              {/*        defaultValue={defaultSorting} */}
              {/*        options={columns */}
              {/*          .filter(column => column.sortable) */}
              {/*          .map(column => ({ */}
              {/*            label: column.label, */}
              {/*            value: column.id as PaymentSortableFields, */}
              {/*          }))} */}
              {/*        value={sorting} */}
              {/*        onChange={handleSortingChange} */}
              {/*      /> */}
              {/*    </Col> */}
              {/*  </Grid> */}
              {/* </Row> */}

              <ListTable
                columns={columns}
                getItemId={getItemId}
                getRowColor={getRowColor}
                isFetching={isFetching}
                items={data.payments}
                lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
                sorting={sorting}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>
    </>
  );
}

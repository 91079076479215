import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';

const BASE_ENDPOINT = '/repairs_protocols_sell';

interface IRepairProtocolSellPartSerialized {
  cost: string;
  name: string;
  price: string;
  quantity: string;
}

interface IRepairProtocolSellSerialized {
  contract: number;
  date: string;
  endDate: string;
  id: number;
  number: string;
  parts: IRepairProtocolSellPartSerialized[];
  services: IRepairProtocolSellPartSerialized[];
  startDate: string;
  vu23Date: string | null;
  vu36Date: string | null;
}

export interface IRepairProtocolSell
  extends Omit<
    IRepairProtocolSellSerialized,
    'date' | 'endDate' | 'startDate' | 'vu23Date' | 'vu36Date'
  > {
  date: Date;
  endDate: Date;
  startDate: Date;
  vu23Date: Date | null;
  vu36Date: Date | null;
}

function deserializeRepairProtocolSell({
  date,
  endDate,
  startDate,
  vu23Date,
  vu36Date,
  ...otherProps
}: IRepairProtocolSellSerialized): IRepairProtocolSell {
  return {
    ...otherProps,
    date: parseDate(date),
    endDate: parseDate(endDate),
    startDate: parseDate(startDate),
    vu23Date: vu23Date == null ? null : parseDate(vu23Date),
    vu36Date: vu36Date == null ? null : parseDate(vu36Date),
  };
}

export enum RepairProtocolsSellSortingField {
  Date = 'date',
  EndDate = 'endDate',
  Number = 'number',
  OrderingNumber = 'orderingNumber',
  StartDate = 'startDate',
}

interface IFetchRepairProtocolsSellParams {
  contract?: number;
  page?: number;
  sorting?: Array<ISorting<RepairProtocolsSellSortingField>>;
}

export async function fetchRepairProtocolsSell(
  api: ApiClient,
  { contract, page, sorting }: IFetchRepairProtocolsSellParams = {}
): Promise<ListResponse<IRepairProtocolSell>> {
  const response = await api.get<ListResponse<IRepairProtocolSellSerialized>>(
    BASE_ENDPOINT,
    {
      contract,
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      page,
    }
  );

  return {
    ...response,
    results: response.results.map(deserializeRepairProtocolSell),
  };
}

type ICreateOrUpdateRepairProtocolSellParams = {
  contract: number;
  startDate: Date | null;
  endDate: Date | null;
  date: Date | null;
  vu23Date: Date | null;
  vu36Date: Date | null;
};

function serializeCreateOrUpdateRepairProtocolSellParams({
  date,
  endDate,
  startDate,
  vu23Date,
  vu36Date,
  ...otherParams
}: ICreateOrUpdateRepairProtocolSellParams) {
  return {
    ...otherParams,
    startDate: startDate && dayjs(startDate).format(DATE_FORMAT_API_DATE),
    endDate: endDate && dayjs(endDate).format(DATE_FORMAT_API_DATE),
    date: date && dayjs(date).format(DATE_FORMAT_API_DATE),
    vu23Date: vu23Date && dayjs(vu23Date).format(DATE_FORMAT_API_DATE),
    vu36Date: vu36Date && dayjs(vu36Date).format(DATE_FORMAT_API_DATE),
  };
}

export async function createRepairProtocolSell(
  api: ApiClient,
  params: ICreateOrUpdateRepairProtocolSellParams
) {
  const response = await api.post<IRepairProtocolSellSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateRepairProtocolSellParams(params)
  );

  return deserializeRepairProtocolSell(response);
}

export async function updateRepairProtocolSell(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateRepairProtocolSellParams
) {
  const response = await api.put<IRepairProtocolSellSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateRepairProtocolSellParams(params)
  );

  return deserializeRepairProtocolSell(response);
}

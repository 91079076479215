import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { SearchForm } from '_core/forms/searchForm';
import { uniq } from '_core/fp/uniq';
import { isNotNull } from '_core/isNotNull';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TreeTableNode, TreeTableNodeChildren } from '_core/react-window/types';
import {
  everyNode,
  findNode,
  setAllNodesIsExpanded,
} from '_core/react-window/utils';
import { LinkButton } from '_core/router5/linkButton';
import {
  expandSorting,
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
  validateSorting,
} from '_core/sorting';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import {
  ContractsPurchaseService,
  ContractsPurchaseSortingField,
  fetchContractsPurchase,
  IContractPurchase,
  isContractsPurchaseService,
} from 'contractsPurchase/api';
import {
  ContractsPurchaseAgreementsSortingField,
  fetchContractsPurchaseAgreements,
} from 'contractsPurchaseAgreements/api';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { fetchManyPartners, PartnersSortingField } from 'partners/api';
import { PartnersFilter } from 'partners/filter';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import {
  fetchRentProtocolsPurchase,
  RentProtocolsPurchaseSortingField,
} from 'rentProtocolsPurchase/api';
import {
  fetchTechrunProtocolsPurchase,
  TechrunProtocolsPurchaseSortingField,
} from 'techrunProtocolsPurchase/api';

import { ContractsTreeTable, ContractsTreeTableNodeData } from './treeTable';

const serviceFilterOptions = [
  { label: 'Аренда', value: ContractsPurchaseService.Rent },
  { label: 'Техрейс', value: ContractsPurchaseService.Techrun },
];

export default function ContractsPurchaseListRoute() {
  const { route, router } = useRouteNode('contracts.purchase');
  const listParams = JSON.stringify(route.params);
  const api = useApiClient();

  const sorting = useMemo(
    () =>
      validateSorting(
        (field): field is 'name' | 'signDate' =>
          ['name', 'signDate'].includes(field),
        sortingFromRouterParam(route.params.sorting),
        { field: 'name', direction: SortingDirection.Ascending }
      ),
    [route.params.sorting]
  );

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [
      api,
      route.params.partner,
      route.params.search,
      route.params.service,
      sorting,
    ],
    async () => {
      const contractsResponse = await fetchAllPages(page =>
        fetchContractsPurchase(api, {
          page,
          partner: isFinite(route.params.partner)
            ? Number(route.params.partner)
            : undefined,
          search: route.params.search,
          service: route.params.service
            ? (route.params.service as string)
                .split(',')
                .filter(isContractsPurchaseService)
            : undefined,
          sorting: expandSorting(sorting, {
            name: [ContractsPurchaseSortingField.Number],
            signDate: [ContractsPurchaseSortingField.SignDate],
          }),
        })
      );

      const partnerIds = uniq(
        contractsResponse.results
          .map(contract => contract.partner)
          .filter(isNotNull)
      );

      const partners = await fetchManyPartners(api, partnerIds, {
        sorting: expandSorting(sorting, {
          name: [PartnersSortingField.ShortName],
          signDate: [PartnersSortingField.ContractPurchaseSignDate],
        }),
      });

      const contractsByPartner: Record<number, IContractPurchase[]> = {};

      contractsResponse.results.forEach(contract => {
        const partnerId = contract.partner;

        if (!partnerId) {
          return;
        }

        contractsByPartner[partnerId] = contractsByPartner[partnerId] || [];
        contractsByPartner[partnerId].push(contract);
      });

      return {
        nodes: partners.map(
          (partner): TreeTableNode<ContractsTreeTableNodeData> => {
            const prevPartnerNode = data
              ? findNode(
                  data.nodes,
                  node =>
                    node.data.kind === 'partner' &&
                    node.data.partner.id === partner.id
                )
              : undefined;

            const contracts = contractsByPartner[partner.id];

            return {
              id: `partner-${partner.id}`,
              data: {
                kind: 'partner',
                partner,
                contractCount: contracts.length,
              },
              children: {
                isExpanded:
                  prevPartnerNode && prevPartnerNode.children
                    ? prevPartnerNode.children.isExpanded
                    : false,

                nodes: contracts.map(
                  (contract): TreeTableNode<ContractsTreeTableNodeData> => {
                    const childNodes: Array<
                      TreeTableNode<ContractsTreeTableNodeData>
                    > = [];

                    if (contract.techrunsProtocolsCount !== 0) {
                      const prevChildNode = data
                        ? findNode(
                            data.nodes,
                            node =>
                              node.data.kind === 'techrunProtocolsFolder' &&
                              node.data.contractId === contract.id
                          )
                        : undefined;

                      childNodes.push({
                        id: `techrunProtocolsFolder-${contract.id}`,
                        data: {
                          kind: 'techrunProtocolsFolder',
                          contractId: contract.id,
                          count: contract.techrunsProtocolsCount,
                        },
                        children: {
                          hasMoreChildNodesToFetch: true,
                          isExpanded:
                            prevChildNode && prevChildNode.children
                              ? prevChildNode.children.isExpanded
                              : false,
                          isFetching: false,
                          nodes: [],
                        },
                      });
                    }

                    if (contract.rentProtocolsCount !== 0) {
                      const prevChildNode = data
                        ? findNode(
                            data.nodes,
                            node =>
                              node.data.kind === 'rentProtocolsFolder' &&
                              node.data.contractId === contract.id
                          )
                        : undefined;

                      childNodes.push({
                        id: `rentProtocolsFolder-${contract.id}`,
                        data: {
                          kind: 'rentProtocolsFolder',
                          contractId: contract.id,
                          count: contract.rentProtocolsCount,
                        },
                        children: {
                          hasMoreChildNodesToFetch: true,
                          isExpanded:
                            prevChildNode && prevChildNode.children
                              ? prevChildNode.children.isExpanded
                              : false,
                          isFetching: false,
                          nodes: [],
                        },
                      });
                    }

                    if (contract.agreementsCount !== 0) {
                      const prevChildNode = data
                        ? findNode(
                            data.nodes,
                            node =>
                              node.data.kind === 'agreementsFolder' &&
                              node.data.contractId === contract.id
                          )
                        : undefined;

                      childNodes.push({
                        id: `agreementsFolder-${contract.id}`,
                        data: {
                          kind: 'agreementsFolder',
                          contractId: contract.id,
                          count: contract.agreementsCount,
                        },
                        children: {
                          hasMoreChildNodesToFetch: true,
                          isExpanded:
                            prevChildNode && prevChildNode.children
                              ? prevChildNode.children.isExpanded
                              : false,
                          isFetching: false,
                          nodes: [],
                        },
                      });
                    }

                    const prevContractNode = data
                      ? findNode(
                          data.nodes,
                          node =>
                            node.data.kind === 'contract' &&
                            node.data.contract.id === contract.id
                        )
                      : undefined;

                    const children:
                      | TreeTableNodeChildren<ContractsTreeTableNodeData>
                      | undefined =
                      childNodes.length !== 0
                        ? {
                            isExpanded:
                              prevContractNode && prevContractNode.children
                                ? prevContractNode.children.isExpanded
                                : false,
                            isFetching: false,
                            nodes: childNodes,
                          }
                        : undefined;

                    return {
                      id: `contract-${contract.id}`,
                      data: { kind: 'contract', contract },
                      children,
                    };
                  }
                ),
              },
            };
          }
        ),
      };
    }
  );

  const fetchChildNodes = useCallback(
    async (parentNodes: Array<TreeTableNode<ContractsTreeTableNodeData>>) => {
      const parentNode = parentNodes[parentNodes.length - 1];

      if (parentNode.data.kind === 'techrunProtocolsFolder') {
        const contractId = parentNode.data.contractId;

        const response = await fetchAllPages(page =>
          fetchTechrunProtocolsPurchase(api, {
            contract: contractId,
            page,
            sorting: expandSorting(sorting, {
              name: [TechrunProtocolsPurchaseSortingField.OrderingNumber],
              signDate: [],
            }),
          })
        );

        return response.results.map(
          (protocol): TreeTableNode<ContractsTreeTableNodeData> => ({
            id: `techrunProtocol-${protocol.id}`,
            data: {
              kind: 'techrunProtocol',
              contractId,
              protocolId: protocol.id,
              protocolName: protocol.protocolName,
            },
          })
        );
      }

      if (parentNode.data.kind === 'rentProtocolsFolder') {
        const contractId = parentNode.data.contractId;

        const response = await fetchAllPages(page =>
          fetchRentProtocolsPurchase(api, {
            contract: contractId,
            page,
            sorting: expandSorting(sorting, {
              name: [RentProtocolsPurchaseSortingField.OrderingNumber],
              signDate: [],
            }),
          })
        );

        return response.results.map(
          (protocol): TreeTableNode<ContractsTreeTableNodeData> => ({
            id: `rentProtocol-${protocol.id}`,
            data: {
              kind: 'rentProtocol',
              contractId,
              protocolId: protocol.id,
              protocolName: protocol.protocolName,
            },
          })
        );
      }

      if (parentNode.data.kind === 'agreementsFolder') {
        const contractId = parentNode.data.contractId;

        const response = await fetchAllPages(page =>
          fetchContractsPurchaseAgreements(api, {
            contract: contractId,
            page,
            sorting: expandSorting(sorting, {
              name: [ContractsPurchaseAgreementsSortingField.OrderingNumber],
              signDate: [],
            }),
          })
        );

        return response.results.map(
          (agreement): TreeTableNode<ContractsTreeTableNodeData> => ({
            id: `agreement-${agreement.id}`,
            data: {
              kind: 'agreement',
              contractId,
              agreementId: agreement.id,
              agreementNumber: agreement.number,
              agreementDate: dayjs(agreement.date).format(DATE_FORMAT_API_DATE),
            },
          })
        );
      }

      throw new Error(`Unexpected node kind: "${parentNode.data.kind}"`);
    },
    [api, sorting]
  );

  const handleNodesChange = useCallback(
    (
      newNodesOrUpdater:
        | Array<TreeTableNode<ContractsTreeTableNodeData>>
        | ((
            prevNodes: Array<TreeTableNode<ContractsTreeTableNodeData>>
          ) => Array<TreeTableNode<ContractsTreeTableNodeData>>)
    ) => {
      updateData(
        prevData =>
          prevData && {
            ...prevData,
            nodes:
              typeof newNodesOrUpdater === 'function'
                ? newNodesOrUpdater(prevData.nodes)
                : newNodesOrUpdater,
          }
      );
    },
    [updateData]
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },

    [applyFilterParams]
  );

  const allIsExpanded = data
    ? everyNode(data.nodes, node => !node.children || node.children.isExpanded)
    : false;

  return (
    <VGrid stretch>
      <FormattedTitle>Договоры входящие</FormattedTitle>

      <Row>
        <Grid>
          <Col span={3}>
            <SearchForm
              initialValue={route.params.search}
              onApply={search => {
                applyFilterParams({ search });
              }}
            />
          </Col>

          <Col span={9}>
            <Toolbar align="right">
              <PartnersFilter
                initialValue={route.params.partner}
                onApply={partner => {
                  applyFilterParams({ partner });
                }}
              />

              <CheckboxListFilter
                allOption
                initialValue={route.params.service}
                label="Типы услуг"
                options={serviceFilterOptions}
                onApply={service => {
                  applyFilterParams({ service });
                }}
              />

              <Button text="Обновить" onClick={refetch} />
              <LinkButton text="Сбросить фильтры" to={route.name} />

              <LinkButton
                intent={Intent.PRIMARY}
                params={{ listParams }}
                text="Добавить"
                to="contracts.purchase.create"
              />
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row>
        <Toolbar>
          {allIsExpanded ? (
            <Button
              icon="collapse-all"
              text="Свернуть всё"
              onClick={() => {
                updateData(
                  prevData =>
                    prevData && {
                      ...prevData,
                      nodes: setAllNodesIsExpanded(prevData.nodes, false),
                    }
                );
              }}
            />
          ) : (
            <Button
              icon="expand-all"
              text="Развернуть всё"
              onClick={() => {
                updateData(
                  prevData =>
                    prevData && {
                      ...prevData,
                      nodes: setAllNodesIsExpanded(prevData.nodes, true),
                    }
                );
              }}
            />
          )}
        </Toolbar>
      </Row>

      <Row stretch>
        {!data ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <GenericErrorMessage />
          )
        ) : (
          <ContractsTreeTable
            contractsBaseSegment="contracts.purchase"
            fetchChildNodes={fetchChildNodes}
            isFetching={isFetching}
            listParams={listParams}
            nodes={data.nodes}
            sorting={sorting}
            onNodesChange={handleNodesChange}
            onSortingChange={handleSortingChange}
          />
        )}
      </Row>
    </VGrid>
  );
}

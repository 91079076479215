import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Toolbar } from '_core/toolbar';
import { Button } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { TechrunsGu12AutocompleteInFormGroupForFinalForm } from 'techrunGu12/autocomplete';

export interface ITechrunRequestsFormGu12Item {
  number: number | null;
}

interface IProps {
  name: string;
  readOnly: boolean | undefined;
}

interface IFieldNameWithIndex {
  fieldName: string;
  index: number;
}

export function TechrunRequestsGu12Field({ name, readOnly }: IProps) {
  const { fields, meta } = useFieldArray<ITechrunRequestsFormGu12Item>(name);
  const [selectedIndices, setSelectedIndices] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitting = (meta as any).submitting as boolean;

  const columns = useMemo(
    (): Array<ListTableColumn<IFieldNameWithIndex>> => [
      {
        id: 'number',
        label: 'Номер',
        defaultWidth: 400,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: ({ fieldName }) => (
          <TechrunsGu12AutocompleteInFormGroupForFinalForm
            disabled={submitting}
            id={`${fieldName}.number`}
            name={`${fieldName}.number`}
            noBottomMargin
            readOnly={readOnly}
          />
        ),
      },
    ],
    [readOnly, submitting]
  );

  const items = useMemo(
    () =>
      fields.map(
        (fieldName, index): IFieldNameWithIndex => ({ fieldName, index })
      ),
    [fields]
  );

  const getItemId = useCallback(
    ({ index }: IFieldNameWithIndex) => String(index),
    []
  );

  return (
    <VGrid>
      <Row>
        <Toolbar>
          <Button
            disabled={submitting || readOnly}
            text="Добавить"
            onClick={() => {
              fields.push({ number: null });
            }}
          />

          <Button
            disabled={submitting || readOnly || selectedIndices.length === 0}
            text="Удалить выбранные"
            onClick={() => {
              selectedIndices
                .map(Number)
                .sort((a, b) => b - a)
                .forEach(rowIndex => {
                  fields.remove(rowIndex);
                });

              setSelectedIndices([]);
            }}
          />
        </Toolbar>
      </Row>

      <Row>
        <ListTable
          columns={columns}
          getItemId={getItemId}
          items={items}
          maxHeight={500}
          selectedItems={selectedIndices}
          selectionIsDisabled={submitting || readOnly}
          onSelectedItemsChange={setSelectedIndices}
        />
      </Row>
    </VGrid>
  );
}

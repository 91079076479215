import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import {
  DATE_FORMAT_API_DATETIME,
  DATE_FORMAT_DATE,
  DATE_FORMAT_DATETIME,
} from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { useDialog } from '_core/dialogs/useDialog';
import { useAsyncTasks } from '_core/download/asyncDownload';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { DateRangePicker } from '_core/inputs/dateRangePicker';
import { ColumnsSelectorsGroup } from '_core/lists/columnPresets/columnsSelectorsGroup';
import { useColumnPresets } from '_core/lists/columnPresets/useColumnPresets';
import { Pagination } from '_core/pagination';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { LinkButton } from '_core/router5/linkButton';
import { StateContainer } from '_core/stateContainer';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  Button,
  ButtonGroup,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime';
import { Tooltip2 } from '@blueprintjs/popover2';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { createWagonNote, fetchWagonNotes, WagonNote } from 'wagonNotes/api';
import {
  fetchWagonDislocations,
  IDislocationSerialized,
  WAGONS_BASE_ENDPOINT,
} from 'wagons/api';
import { createDislocationColumns } from 'wagons/dislocationColumns';

import { TooltipContent } from '../../../_core/blueprint/tooltipContent';
import {
  createWagonCooNote,
  fetchWagonCooNotes,
} from '../../../wagonCooNotes/api';
import {
  createWagonDeliveryOverdueNote,
  fetchWagonDeliveryOverdueNotes,
} from '../../../wagonDeliveryOverdueNotes/api';
import {
  createWagonEtranNote,
  fetchWagonEtranNotes,
} from '../../../wagonEtranNotes/api';
import {
  createWagonRzdAppealNote,
  fetchWagonRzdAppealNotes,
} from '../../../wagonRzdAppealNotes/api';
import {
  CreateNoteDialogForm,
  ICreateNoteDialogFormValues,
} from '../createNoteDialogForm';
import * as css from './editHistoryTab.module.css';

interface ITableItem extends IDislocationSerialized {
  lastNote: WagonNote | null;
  etranNote: WagonNote | null;
  appealNote: WagonNote | null;
  overdueNotes: WagonNote | null;
  cooNotes: WagonNote | null;
}

export function WagonsEditHistoryTab() {
  const { route } = useRouteNode('wagons.edit.history');
  const api = useApiClient();
  const tasks = useAsyncTasks();

  const { data, isFetching, refetch } = useAsyncData([api, route], async () => {
    const [
      { meta, results },
      notes,
      etranNotes,
      appealNotes,
      overdueNotes,
      cooNotes,
    ] = await Promise.all([
      fetchWagonDislocations(api, route.params.number, {
        dislocationDatetime: route.params.dislocationDatetime
          ? parseDate(route.params.dislocationDatetime)
          : undefined,
        fromDatetime: route.params.fromDatetime
          ? parseDate(route.params.fromDatetime)
          : undefined,
        page: route.params.page,
        pageSize: route.params.pageSize,
        toDatetime: route.params.toDatetime
          ? parseDate(route.params.toDatetime)
          : undefined,
      }),
      fetchAllPages(page =>
        fetchWagonNotes(api, { page, wagon: route.params.number })
      ).then(response => response.results),
      fetchAllPages(page =>
        fetchWagonEtranNotes(api, { page, wagon: route.params.number })
      ).then(response => response.results),
      fetchAllPages(page =>
        fetchWagonRzdAppealNotes(api, { page, wagon: route.params.number })
      ).then(response => response.results),
      fetchAllPages(page =>
        fetchWagonDeliveryOverdueNotes(api, {
          page,
          wagon: route.params.number,
        })
      ).then(response => response.results),
      fetchAllPages(page =>
        fetchWagonCooNotes(api, {
          page,
          wagon: route.params.number,
        })
      ).then(response => response.results),
    ]);

    return {
      currentPage: meta.currentPage,
      dislocations: results,
      notes,
      etranNotes,
      appealNotes,
      overdueNotes,
      cooNotes,
      pageSize: meta.pageSize,
      totalPages: meta.totalPages,
    };
  });

  const createNoteDialogForm = useDialog<{
    initialValues: ICreateNoteDialogFormValues;
  }>();

  const createNoteEtranDialogForm = useDialog<{
    initialValues: ICreateNoteDialogFormValues;
  }>();

  const createAppealNoteDialogForm = useDialog<{
    initialValues: ICreateNoteDialogFormValues;
  }>();

  const createOverdueNoteDialogForm = useDialog<{
    initialValues: ICreateNoteDialogFormValues;
  }>();

  const createCooNoteDialogForm = useDialog<{
    initialValues: ICreateNoteDialogFormValues;
  }>();

  const columns: Array<ListTableColumn<ITableItem>> = [
    {
      id: 'id',
      label: 'ID',
      defaultWidth: 140,
      copyCellContent: dislocation => String(dislocation.datePk),
      renderCellContent: dislocation => dislocation.datePk,
    },
    {
      id: 'created',
      label: 'Запись создана',
      defaultWidth: 130,
      copyCellContent: dislocation =>
        dayjs(dislocation.created).format(DATE_FORMAT_DATETIME),
      renderCellContent: dislocation =>
        dayjs(dislocation.created).format(DATE_FORMAT_DATETIME),
    },
    {
      id: 'modified',
      label: 'Запись обновлена',
      defaultWidth: 130,
      copyCellContent: dislocation =>
        dayjs(dislocation.modified).format(DATE_FORMAT_DATETIME),
      renderCellContent: dislocation =>
        dayjs(dislocation.modified).format(DATE_FORMAT_DATETIME),
    },
    {
      id: 'note',
      label: 'Комментарий',
      defaultWidth: 300,
      copyCellContent: dislocation => {
        return dislocation.lastNote ? dislocation.lastNote.note : '-';
      },
      renderCellContent: dislocation => (
        <div className={css.noteCell}>
          <div className={css.noteCellText}>
            {dislocation.lastNote ? dislocation.lastNote.note : '-'}
          </div>{' '}
          <Button
            icon="add"
            small
            onClick={() => {
              createNoteDialogForm.open({
                initialValues: {
                  created: new Date(),
                  dislocation: dislocation.datePk,
                  files: [],
                  note: '',

                  operation: [
                    dislocation.operationDatetime,
                    dislocation.operationMnemo,
                    dislocation.operationStationName,
                  ].join(' '),

                  wagon: route.params.number,
                },
              });
            }}
          />
        </div>
      ),
    },
    {
      id: 'etranNote',
      label: 'Заготовка в Этране',
      defaultWidth: 200,
      copyCellContent: dislocation => {
        return dislocation.etranNote ? dislocation.etranNote.note : '-';
      },
      renderCellContent: dislocation => (
        <div className={css.noteCell}>
          <div className={css.noteCellText}>
            {dislocation.etranNote ? dislocation.etranNote.note : '-'}
          </div>{' '}
          <Button
            icon="add"
            small
            onClick={() => {
              createNoteEtranDialogForm.open({
                initialValues: {
                  created: new Date(),
                  dislocation: dislocation.datePk,
                  files: [],
                  note: '',

                  operation: [
                    dislocation.operationDatetime,
                    dislocation.operationMnemo,
                    dislocation.operationStationName,
                  ].join(' '),

                  wagon: route.params.number,
                },
              });
            }}
          />
        </div>
      ),
    },
    {
      id: 'lastRzdAppealNote',
      label: 'Последнее обращение в РЖД',
      defaultWidth: 300,
      copyCellContent: dislocation => {
        return dislocation.appealNote ? dislocation.appealNote.note : '-';
      },
      renderCellContent: dislocation => (
        <div className={css.noteCell}>
          <div className={css.noteCellText}>
            {dislocation.appealNote ? dislocation.appealNote.note : '-'}
          </div>{' '}
          <Button
            icon="add"
            small
            onClick={() => {
              createAppealNoteDialogForm.open({
                initialValues: {
                  created: new Date(),
                  dislocation: dislocation.datePk,
                  files: [],
                  note: '',

                  operation: [
                    dislocation.operationDatetime,
                    dislocation.operationMnemo,
                    dislocation.operationStationName,
                  ].join(' '),

                  wagon: route.params.number,
                },
              });
            }}
          />
        </div>
      ),
    },

    {
      id: 'lastDeliveryOverdueNote',
      label: 'Последняя просрочка доставки',
      defaultWidth: 300,
      copyCellContent: dislocation => {
        return dislocation.overdueNotes ? dislocation.overdueNotes.note : '-';
      },
      renderCellContent: dislocation => (
        <div className={css.noteCell}>
          <div className={css.noteCellText}>
            {dislocation.overdueNotes ? dislocation.overdueNotes.note : '-'}
          </div>{' '}
          <Button
            icon="add"
            small
            onClick={() => {
              createOverdueNoteDialogForm.open({
                initialValues: {
                  created: new Date(),
                  dislocation: dislocation.datePk,
                  files: [],
                  note: '',

                  operation: [
                    dislocation.operationDatetime,
                    dislocation.operationMnemo,
                    dislocation.operationStationName,
                  ].join(' '),

                  wagon: route.params.number,
                },
              });
            }}
          />
        </div>
      ),
    },
    {
      id: 'lastCargoOverNote',
      label: 'Грузовая операция окончена (принято приемосдатчиком)',
      defaultWidth: 300,
      copyCellContent: dislocation => {
        return dislocation.cooNotes ? dislocation.cooNotes.note : '-';
      },
      renderCellContent: dislocation => (
        <div className={css.noteCell}>
          <div className={css.noteCellText}>
            {dislocation.cooNotes ? dislocation.cooNotes.note : '-'}
          </div>{' '}
          <Button
            icon="add"
            small
            onClick={() => {
              createCooNoteDialogForm.open({
                initialValues: {
                  created: new Date(),
                  dislocation: dislocation.datePk,
                  files: [],
                  note: '',

                  operation: [
                    dislocation.operationDatetime,
                    dislocation.operationMnemo,
                    dislocation.operationStationName,
                  ].join(' '),

                  wagon: route.params.number,
                },
              });
            }}
          />
        </div>
      ),
    },
    ...createDislocationColumns(),
  ];

  const columnLabels = columns.reduce<Record<string, string>>(
    (labels, column) => {
      labels[column.id] = column.label;

      return labels;
    },

    {}
  );

  const columnPresets = useColumnPresets(['wagons', 'dislocationHistory'], {
    allColumnIds: columns.map(column => column.id),
    alwaysShownColumnIds: [],
    getColumnLabel: columnId => columnLabels[columnId],
  });

  const getItemId = useCallback((item: ITableItem) => String(item.id), []);

  const items = useMemo(
    () =>
      data &&
      data.dislocations.map<ITableItem>(dislocation => ({
        ...dislocation,
        lastNote: data.notes
          .filter(note => note.dislocation === dislocation.datePk)
          .reduce<WagonNote | null>(
            (acc, note) =>
              acc == null
                ? note
                : dayjs(note.created).diff(acc.created) > 0
                ? note
                : acc,

            null
          ),
        etranNote: data.etranNotes
          .filter(note => note.dislocation === dislocation.datePk)
          .reduce<WagonNote | null>(
            (acc, note) =>
              acc == null
                ? note
                : dayjs(note.created).diff(acc.created) > 0
                ? note
                : acc,

            null
          ),
        appealNote: data.appealNotes
          .filter(note => note.dislocation === dislocation.datePk)
          .reduce<WagonNote | null>(
            (acc, note) =>
              acc == null
                ? note
                : dayjs(note.created).diff(acc.created) > 0
                ? note
                : acc,

            null
          ),
        overdueNotes: data.overdueNotes
          .filter(note => note.dislocation === dislocation.datePk)
          .reduce<WagonNote | null>(
            (acc, note) =>
              acc == null
                ? note
                : dayjs(note.created).diff(acc.created) > 0
                ? note
                : acc,

            null
          ),
        cooNotes: data.cooNotes
          .filter(note => note.dislocation === dislocation.datePk)
          .reduce<WagonNote | null>(
            (acc, note) =>
              acc == null
                ? note
                : dayjs(note.created).diff(acc.created) > 0
                ? note
                : acc,

            null
          ),
      })),
    [data]
  );

  return (
    <VGrid stretch>
      <Row>
        <Grid>
          <Col span={12}>
            <Toolbar align="right">
              <ColumnsSelectorsGroup
                columnOptions={columnPresets.columnOptions}
                defaultValue={columnPresets.defaultValue}
                initialValue={columnPresets.initialValue}
                preset={columnPresets.activePreset}
                onApply={columnPresets.onShowColumnsApply}
                onColumnPresetChange={columnPresets.onColumnPresetChange}
              />

              <Button text="Обновить" onClick={refetch} />

              <LinkButton
                params={{
                  listParams: route.params.listParams,
                  number: route.params.number,
                }}
                text="Сбросить фильтры"
                to={route.name}
              />

              <StateContainer<DateRange> initialState={[null, null]}>
                {(dateRange, setDateRange) => (
                  <ButtonGroup>
                    <Tooltip2
                      content={
                        <TooltipContent>
                          Скачивает дислокацию вагона за выбранный период. Для
                          активации кнопки выберите необходимый период.
                        </TooltipContent>
                      }
                      position={Position.BOTTOM}
                    >
                      <Button
                        disabled={
                          dateRange[0] === null || dateRange[1] === null
                        }
                        icon="download"
                        intent={Intent.SUCCESS}
                        text="Дислокация"
                        onClick={async () => {
                          const { taskId } = await api.get(
                            `${WAGONS_BASE_ENDPOINT}/${route.params.number}/download_xlsx`,
                            {
                              fromDatetime: dateRange[0]
                                ? dayjs(dateRange[0])
                                    .startOf('day')
                                    .format(DATE_FORMAT_API_DATETIME)
                                : undefined,
                              toDatetime: dateRange[1]
                                ? dayjs(dateRange[1])
                                    .endOf('day')
                                    .format(DATE_FORMAT_API_DATETIME)
                                : undefined,
                            }
                          );

                          tasks.waitForGeneratedFile(taskId);
                        }}
                      />
                    </Tooltip2>

                    <Popover
                      content={
                        <DateRangePicker
                          allowSingleDayRange
                          value={dateRange}
                          onChange={setDateRange}
                        />
                      }
                      position={Position.BOTTOM}
                    >
                      <Button
                        intent={Intent.SUCCESS}
                        rightIcon="calendar"
                        text={
                          dateRange[0] &&
                          dateRange[1] &&
                          `${dayjs(dateRange[0]).format(
                            DATE_FORMAT_DATE
                          )}-${dayjs(dateRange[1]).format(DATE_FORMAT_DATE)}`
                        }
                      />
                    </Popover>
                  </ButtonGroup>
                )}
              </StateContainer>
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        {!data || !items ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <GenericErrorMessage />
          )
        ) : items.length === 0 ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <EmptyListMessage />
          )
        ) : (
          <ListTable
            columns={columns}
            columnWidths={columnPresets.columnWidths}
            getItemId={getItemId}
            isFetching={isFetching}
            items={items}
            lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
            showColumns={columnPresets.showColumns}
            onColumnWidthChanged={columnPresets.onColumnWidthChanged}
          />
        )}
      </Row>

      {data && (
        <Row>
          <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
        </Row>
      )}

      {createNoteDialogForm.state && (
        <CreateNoteDialogForm
          initialValues={createNoteDialogForm.state.initialValues}
          isOpen={createNoteDialogForm.state.isOpen}
          onClose={createNoteDialogForm.close}
          onClosed={createNoteDialogForm.destroy}
          onSubmit={async values => {
            try {
              await createWagonNote(api, values);
              refetch();
              createNoteDialogForm.close();
              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось добавить комментарий к вагону: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}

      {createNoteEtranDialogForm.state && (
        <CreateNoteDialogForm
          initialValues={createNoteEtranDialogForm.state.initialValues}
          isOpen={createNoteEtranDialogForm.state.isOpen}
          onClose={createNoteEtranDialogForm.close}
          onClosed={createNoteEtranDialogForm.destroy}
          onSubmit={async values => {
            try {
              await createWagonEtranNote(api, values);
              refetch();
              createNoteEtranDialogForm.close();
              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось добавить комментарий к вагону: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}

      {createAppealNoteDialogForm.state && (
        <CreateNoteDialogForm
          initialValues={createAppealNoteDialogForm.state.initialValues}
          isOpen={createAppealNoteDialogForm.state.isOpen}
          onClose={createAppealNoteDialogForm.close}
          onClosed={createAppealNoteDialogForm.destroy}
          onSubmit={async values => {
            try {
              await createWagonRzdAppealNote(api, values);
              refetch();
              createAppealNoteDialogForm.close();
              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось добавить комментарий к вагону: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}

      {createOverdueNoteDialogForm.state && (
        <CreateNoteDialogForm
          initialValues={createOverdueNoteDialogForm.state.initialValues}
          isOpen={createOverdueNoteDialogForm.state.isOpen}
          onClose={createOverdueNoteDialogForm.close}
          onClosed={createOverdueNoteDialogForm.destroy}
          onSubmit={async values => {
            try {
              await createWagonDeliveryOverdueNote(api, values);
              refetch();
              createOverdueNoteDialogForm.close();
              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось добавить комментарий к вагону: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}

      {createCooNoteDialogForm.state && (
        <CreateNoteDialogForm
          initialValues={createCooNoteDialogForm.state.initialValues}
          isOpen={createCooNoteDialogForm.state.isOpen}
          onClose={createCooNoteDialogForm.close}
          onClosed={createCooNoteDialogForm.destroy}
          onSubmit={async values => {
            try {
              await createWagonCooNote(api, values);
              refetch();
              createCooNoteDialogForm.close();
              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось добавить комментарий к вагону: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}
    </VGrid>
  );
}

import * as React from 'react';

interface Props {
  children: React.ReactNode;
  depth: number;
}

export function TreeCellPadding({ children, depth }: Props) {
  return <div style={{ paddingLeft: depth * 30 }}>{children}</div>;
}

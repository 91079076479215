import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';

import {
  ISuppliersDocumentSerialized,
  SuppliersDocumentState,
  SuppliersDocumentType,
} from './types';

export enum SuppliersDocumentsSortingField {
  Date = 'date',
  Number = 'number',
  Partner = 'partner',
}

interface IFetchSuppliersDocumentsParams {
  docType?: SuppliersDocumentType[];
  fromFile?: boolean;
  ids?: number[];
  page?: number;
  pageSize?: number;
  search?: string;
  sorting?: Array<ISorting<SuppliersDocumentsSortingField>>;
  sourceName?: string;
  states?: SuppliersDocumentState[];
}

export function fetchSuppliersDocuments(
  api: ApiClient,
  {
    docType,
    fromFile,
    ids,
    page,
    pageSize,
    search,
    sorting,
    sourceName,
    states,
  }: IFetchSuppliersDocumentsParams = {}
) {
  return api.get<ListResponse<ISuppliersDocumentSerialized>>(
    '/suppliers_documents',
    {
      docType: docType && docType.length !== 0 ? docType.join(',') : undefined,
      fromFile,
      ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
      ordering: sorting
        ? sortingToApiQueryParam(sorting, snakeCase)
        : undefined,
      page,
      pageSize,
      search,
      sourceName,
      states: states && states.length ? states.join(',') : undefined,
    }
  );
}

export const fetchManySuppliersDocuments = makeFetchMany(
  fetchSuppliersDocuments,
  'ids'
);

export enum SuppliersDocumentsTreeSortingField {
  Created = 'created',
  SourceName = 'sourceName',
}

interface IFetchSuppliersDocumentsTreeParams {
  docType?: SuppliersDocumentType[];
  fromFile?: boolean;
  ids?: number[];
  search?: string;
  sorting?: Array<ISorting<SuppliersDocumentsTreeSortingField>>;
  states?: SuppliersDocumentState[];
}

export interface ISuppliersDocumentsTreeItem {
  docsCount: number;
  sourceName: string;
}

interface IFetchSuppliersDocumentsTreeResponse {
  results: ISuppliersDocumentsTreeItem[];
}

export function fetchSuppliersDocumentsTree(
  api: ApiClient,
  {
    docType,
    fromFile,
    ids,
    search,
    sorting,
    states,
  }: IFetchSuppliersDocumentsTreeParams = {}
) {
  return api.get<IFetchSuppliersDocumentsTreeResponse>(
    '/suppliers_documents/tree',
    {
      docType: docType && docType.length !== 0 ? docType.join(',') : undefined,
      fromFile,
      ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
      ordering: sorting
        ? sortingToApiQueryParam(sorting, snakeCase)
        : undefined,
      search,
      states: states && states.length ? states.join(',') : undefined,
    }
  );
}

interface ISuppliersDocumentAdjustmentDocument {
  id: number;
  number: string;
}

export enum SuppliersDocumentTransportationAmountIsCorrect {
  Greater = 'GREATER',
  Less = 'LESS',
  Ok = 'OK',
  NotLinked = 'NOT_LINKED',
}

interface ISuppliersDocumentTransportationSerialized {
  acceptDate: string | null;
  amount: string;
  amountAfterAll: number;
  amountAfterNds: number;
  amountBeforeAll: string;
  amountBeforeNds: string;
  amountChangeAll: string;
  amountChangeNds: string;
  amountIsConfirmed: boolean;
  amountIsCorrect: SuppliersDocumentTransportationAmountIsCorrect;
  amountTotal: number;
  arrivalStation: number;
  cargoName: string;
  cargoWeight: string;
  code: string;
  confirmDate: string | null;
  confirmNote: string;
  confirmUser: number | null;
  departureDate: string | null;
  departureStation: number;
  docDate: string | null;
  docNumber: string;
  docType: string;
  document: number;
  expeditionTransp: number | null;
  id: number;
  invoiceDate: string | null;
  invoiceNumber: string;
  isContainer: boolean;
  messageType: string;
  name: string;
  number: number;
  rascreditDate: string | null;
  roadFrom: string;
  roadStationFrom: string;
  roadStationTo: string;
  roadTo: string;
  techrunTransp: number | null;
  type: string;
  vatRate: number;
  vatValue: number;
  wagon: number;
  wagonNumber: string;
  waybillNumber: string;
}

export interface ISuppliersDocumentTransportation
  extends Omit<
    ISuppliersDocumentTransportationSerialized,
    | 'acceptDate'
    | 'amount'
    | 'amountBeforeAll'
    | 'amountBeforeNds'
    | 'amountChangeAll'
    | 'amountChangeNds'
    | 'cargoWeight'
    | 'confirmDate'
    | 'departureDate'
    | 'docDate'
    | 'invoiceDate'
    | 'rascreditDate'
  > {
  acceptDate: Date | null;
  amount: Decimal;
  amountBeforeAll: Decimal;
  amountBeforeNds: Decimal;
  amountChangeAll: Decimal;
  amountChangeNds: Decimal;
  cargoWeight: Decimal;
  confirmDate: Date | null;
  departureDate: Date | null;
  docDate: Date | null;
  invoiceDate: Date | null;
  rascreditDate: Date | null;
}

function deserializeSuppliersDocumentTransportation({
  acceptDate,
  amount,
  amountBeforeAll,
  amountBeforeNds,
  amountChangeAll,
  amountChangeNds,
  cargoWeight,
  confirmDate,
  departureDate,
  docDate,
  invoiceDate,
  rascreditDate,
  ...otherProps
}: ISuppliersDocumentTransportationSerialized): ISuppliersDocumentTransportation {
  return {
    acceptDate: acceptDate == null ? null : parseDate(acceptDate),
    amount: new Decimal(amount),
    amountBeforeAll: new Decimal(amountBeforeAll),
    amountBeforeNds: new Decimal(amountBeforeNds),
    amountChangeAll: new Decimal(amountChangeAll),
    amountChangeNds: new Decimal(amountChangeNds),
    cargoWeight: new Decimal(cargoWeight),
    confirmDate: confirmDate == null ? null : parseDate(confirmDate),
    departureDate: departureDate == null ? null : parseDate(departureDate),
    docDate: docDate == null ? null : parseDate(docDate),
    invoiceDate: invoiceDate == null ? null : parseDate(invoiceDate),
    rascreditDate: rascreditDate == null ? null : parseDate(rascreditDate),
    ...otherProps,
  };
}

interface ISuppliersDocumentDetailSerialized
  extends ISuppliersDocumentSerialized {
  adjustmentDocs: ISuppliersDocumentAdjustmentDocument[];
}

export interface ISuppliersDocumentDetail
  extends Omit<ISuppliersDocumentDetailSerialized, 'date'> {
  date: Date | null;
}

function deserializeSuppliersDocumentDetail(
  input: ISuppliersDocumentDetailSerialized
): ISuppliersDocumentDetail {
  return {
    ...input,
    date: input.date ? parseDate(input.date) : null,
  };
}

export async function fetchSuppliersDocument(api: ApiClient, id: number) {
  const serialized = await api.get<ISuppliersDocumentDetailSerialized>(
    `/suppliers_documents/${id}`
  );

  return deserializeSuppliersDocumentDetail(serialized);
}

interface IUpdateSuppliersDocumentParams
  extends Omit<ISuppliersDocumentDetail, 'partner'> {
  partner: number | null;
}

export async function updateSuppliersDocument(
  api: ApiClient,
  id: number,
  { date, ...otherParams }: IUpdateSuppliersDocumentParams
) {
  const serialized = await api.put<ISuppliersDocumentDetailSerialized>(
    `/suppliers_documents/${id}`,
    {
      ...otherParams,
      date: date ? dayjs(date).format(DATE_FORMAT_API_DATE) : null,
    }
  );

  return deserializeSuppliersDocumentDetail(serialized);
}

export enum SuppliersDocumentTransportationsSortingField {
  AmountIsCorrect = 'amountIsCorrect',
  Id = 'id',
  Name = 'number',
  Number = 'number',
  Type = 'type',
}

interface IFetchSuppliersDocumentTransportationsParams {
  document?: number;
  page?: number;
  search?: string;
  sorting?: Array<ISorting<SuppliersDocumentTransportationsSortingField>>;
}

export async function fetchSuppliersDocumentTransportations(
  api: ApiClient,
  {
    document,
    page,
    search,
    sorting,
  }: IFetchSuppliersDocumentTransportationsParams = {}
): Promise<ListResponse<ISuppliersDocumentTransportation>> {
  const response = await api.get<
    ListResponse<ISuppliersDocumentTransportationSerialized>
  >('/suppliers_documents_transportations', {
    document,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    search,
  });

  return {
    ...response,
    results: response.results.map(deserializeSuppliersDocumentTransportation),
  };
}

export async function linkTransportationsToSuppliersDocumentAutomatically(
  api: ApiClient,
  id: number
) {
  const serialized = await api.post<ISuppliersDocumentDetailSerialized>(
    `/suppliers_documents/${id}/link_transportations`
  );

  return deserializeSuppliersDocumentDetail(serialized);
}

interface ILinkTransportationToSuppliersDocumentParams {
  expeditionTransp: number | null;
  techrunTransp: number | null;
}

export async function linkTransportationToSuppliersDocumentTransportation(
  api: ApiClient,
  suppliersDocumentTransportationId: number,
  {
    expeditionTransp,
    techrunTransp,
  }: ILinkTransportationToSuppliersDocumentParams
) {
  const transportation =
    await api.post<ISuppliersDocumentTransportationSerialized>(
      `/suppliers_documents_transportations/${suppliersDocumentTransportationId}/link_transportation`,
      { expeditionTransp, techrunTransp }
    );

  return deserializeSuppliersDocumentTransportation(transportation);
}

interface IConfirmSuppliersDocumentTransportationsParams {
  note: string;
}

export async function confirmSuppliersDocumentTransportations(
  api: ApiClient,
  transportationIds: number[],
  { note }: IConfirmSuppliersDocumentTransportationsParams
) {
  const transportations = await api.post<
    ISuppliersDocumentTransportationSerialized[]
  >(
    '/suppliers_documents_transportations/confirm_transportations',
    { note },
    {
      query: { ids: transportationIds.join(',') },
    }
  );

  return transportations.map(deserializeSuppliersDocumentTransportation);
}

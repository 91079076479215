import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { YesNoRadioGroupFilter } from '_core/filters/yesNoRadioGroup';
import { SearchForm } from '_core/forms/searchForm';
import { uniq } from '_core/fp/uniq';
import { indexByLastItem } from '_core/indexBy';
import { InputWithSuggest } from '_core/inputs/inputWithSuggest';
import { isNotNull } from '_core/isNotNull';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { CellEditor } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { createTableCellEditor } from '_core/react-window/utils';
import { LinkButton } from '_core/router5/linkButton';
import { Ellipsis } from '_core/strings/ellipsis';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Icon, InputGroup, Intent } from '@blueprintjs/core';
import { fetchManyUsers } from 'accounts/api';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { RailroadsMultiSelectFilter } from 'railroads/multiSelectFilter';
import * as React from 'react';
import { useRouteNode } from 'react-router5';
import {
  fetchRzdShifts,
  fetchStations,
  Station,
  updateStation,
  UpdateStationInput,
} from 'stations/api';
import { StationsMultiSelectFilter } from 'stations/multiSelectFilter';
import invariant from 'tiny-invariant';

import { EditRowStationsForm } from './editRowStationsForm/editRowStationsForm';
import * as css from './stations.module.css';

interface ITableItem {
  station: Station;
  modifiedBy: IUser | null;
}

export default function StationsListRoute() {
  const { route, router } = useRouteNode('stations');
  const toaster = useToaster();
  const api = useApiClient();

  const [isOpenEditRowDialog, setIsOpenEditRowDialog] =
    React.useState<boolean>(false);
  const [activeStationForEdit, setActiveStationForEdit] =
    React.useState<Station | null>(null);

  const stationsRequest = useAsyncData([api, route], async () => {
    const { meta, results } = await fetchStations(api, {
      hasData:
        route.params.hasData === 'True'
          ? true
          : route.params.hasData === 'False'
          ? false
          : undefined,
      ids: route.params.ids
        ? (route.params.ids as string).split(',').map(Number)
        : undefined,
      page: isFinite(route.params.page) ? Number(route.params.page) : undefined,
      pageSize: isFinite(route.params.pageSize)
        ? Number(route.params.pageSize)
        : undefined,
      roads: route.params.roads
        ? (route.params.roads as string).split(',').map(Number)
        : undefined,
      search: route.params.search,
    });

    return {
      currentPage: meta.currentPage,
      pageSize: meta.pageSize,
      stations: results,
      totalPages: meta.totalPages,
    };
  });

  const usersRequest = useAsyncData([api, stationsRequest.data], async () => {
    if (!stationsRequest.data) {
      return [];
    }

    return fetchManyUsers(
      api,
      uniq(
        stationsRequest.data.stations.map(s => s.modifiedBy).filter(isNotNull)
      )
    );
  });

  const isFetching = usersRequest.isFetching || stationsRequest.isFetching;

  const items = React.useMemo(() => {
    if (!stationsRequest.data || !usersRequest.data) {
      return null;
    }

    const usersIndex = indexByLastItem(usersRequest.data, user => user.id);

    return stationsRequest.data.stations.map(
      (station): ITableItem => ({
        modifiedBy:
          station.modifiedBy == null ? null : usersIndex[station.modifiedBy],
        station,
      })
    );
  }, [stationsRequest.data, usersRequest.data]);

  const updateStationData = React.useCallback(
    async <TProps extends keyof UpdateStationInput>(
      id: number,
      input: { [TKey in TProps]: UpdateStationInput[TKey] }
    ) => {
      invariant(stationsRequest.data);

      const originalStation = stationsRequest.data.stations.find(
        station => station.id === id
      );

      invariant(originalStation);

      try {
        stationsRequest.updateData(
          prevData =>
            prevData && {
              ...prevData,
              stations: prevData.stations.map(station =>
                station.id === id ? { ...station, ...input } : station
              ),
            }
        );

        const updatedStation = await updateStation(api, id, {
          ...originalStation,
          ...input,
        });

        stationsRequest.updateData(
          prevData =>
            prevData && {
              ...prevData,
              stations: prevData.stations.map(station =>
                station.id === updatedStation.id ? updatedStation : station
              ),
            }
        );
      } catch (err) {
        stationsRequest.updateData(
          prevData =>
            prevData && {
              ...prevData,
              stations: prevData.stations.map(station =>
                station.id === id ? originalStation : station
              ),
            }
        );

        toaster.show({
          icon: 'error',
          intent: Intent.DANGER,
          message: 'Не удалось изменить станцию: Непредвиденная ошибка',
        });

        throw err;
      }
    },
    [api, stationsRequest, toaster]
  );

  const handleOpenEditModal = (station: Station) => {
    setActiveStationForEdit(station);
    setIsOpenEditRowDialog(true);
  };

  const handleCloseModalRowEdit = () => {
    setIsOpenEditRowDialog(false);
    setActiveStationForEdit(null);
  };

  const handleSaveStationData = (editedStation: Station) => {
    updateStationData(editedStation.id, editedStation);
    handleCloseModalRowEdit();
  };

  const columns = React.useMemo(
    (): Array<ListTableColumn<ITableItem>> => [
      {
        id: 'name',
        label: 'Станция',
        defaultWidth: 300,
        copyCellContent: ({ station }) => station.name,
        renderCellContent: ({ station }) => (
          <div className={css.rowBaseline}>
            <Button
              onClick={() => handleOpenEditModal(station)}
              minimal
              intent={Intent.NONE}
            >
              <Icon icon="edit" />
            </Button>
            <Ellipsis component="span">{station.name}</Ellipsis>
          </div>
        ),
      },
      {
        id: 'code',
        label: 'Код станции',
        defaultWidth: 110,
        copyCellContent: ({ station }) => station.code,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.code}</Ellipsis>
        ),
      },
      {
        id: 'roadName',
        label: 'Дорога',
        defaultWidth: 80,
        copyCellContent: ({ station }) => station.roadName,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.roadName}</Ellipsis>
        ),
      },
      {
        id: 'phone',
        label: 'ЖД телефон',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.phone,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.phone}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.phone,
            applyValue: newPhone => {
              updateStationData(station.id, { phone: newPhone });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'extraInfo',
        label: 'Доп. информация',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.extraInfo,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.extraInfo}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.extraInfo,
            applyValue: newExtraInfo => {
              updateStationData(station.id, { extraInfo: newExtraInfo });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'goGp',
        label: 'ГО и ГП',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.goGp,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.goGp}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.goGp,
            applyValue: newGoGp => {
              updateStationData(station.id, { goGp: newGoGp });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'capacityPp',
        label: 'Вместимость п/п',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.capacityPp,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.capacityPp}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.capacityPp,
            applyValue: newCapacityPp => {
              updateStationData(station.id, { capacityPp: newCapacityPp });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'loadingFront',
        label: 'Фронт погрузки',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.loadingFront,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.loadingFront}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.loadingFront,
            applyValue: newLoadingFront => {
              updateStationData(station.id, { loadingFront: newLoadingFront });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'maxWagons',
        label: 'Max вагонов в сутки',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.maxWagons,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.maxWagons}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.maxWagons,
            applyValue: newMaxWagons => {
              updateStationData(station.id, { maxWagons: newMaxWagons });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'locoAffiliation',
        label: 'Принадлежность локомотива',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.locoAffiliation,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.locoAffiliation}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.locoAffiliation,
            applyValue: newLocoAffiliation => {
              updateStationData(station.id, {
                locoAffiliation: newLocoAffiliation,
              });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'locoOnStation',
        label: 'Локомотив на станции',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.locoOnStation,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.locoOnStation}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.locoOnStation,
            applyValue: newLocoOnStation => {
              updateStationData(station.id, {
                locoOnStation: newLocoOnStation,
              });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'rzdShift',
        label: 'Сменность РЖД',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.rzdShift,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.rzdShift}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.rzdShift,
            applyValue: newRzdShift => {
              updateStationData(station.id, {
                rzdShift: newRzdShift,
              });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputWithSuggest<string>
                  autoFocus
                  fetchSuggestions={async query => {
                    const response = await fetchRzdShifts(api, {
                      pageSize: 10,
                      search: query,
                    });

                    return response.rzdShift;
                  }}
                  getSuggestionLabel={s => s}
                  value={value}
                  onChange={onChange}
                  onSuggestionSelect={onChange}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'goGpShift',
        label: 'Сменность ГО и ГП',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.goGpShift,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.goGpShift}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.goGpShift,
            applyValue: newGoGpShift => {
              updateStationData(station.id, {
                goGpShift: newGoGpShift,
              });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'note',
        label: 'Комментарий',
        defaultWidth: 180,
        copyCellContent: ({ station }) => station.note,
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">{station.note}</Ellipsis>
        ),
        getEditor: ({ station }) =>
          createTableCellEditor({
            initialValue: station.note,
            applyValue: newNote => {
              updateStationData(station.id, {
                note: newNote,
              });
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <InputGroup
                  autoFocus
                  value={value}
                  onChange={event => onChange(event.currentTarget.value)}
                />
              </CellEditor>
            ),
          }),
      },
      {
        id: 'modifiedBy',
        label: 'Автор',
        defaultWidth: 300,
        copyCellContent: ({ modifiedBy }) =>
          modifiedBy == null ? '' : formatUserName(modifiedBy),
        renderCellContent: ({ modifiedBy }) => (
          <Ellipsis component="span">
            {modifiedBy == null ? '' : formatUserName(modifiedBy)}
          </Ellipsis>
        ),
      },
      {
        id: 'modifiedAt',
        label: 'Дата изменения',
        defaultWidth: 300,
        copyCellContent: ({ station }) =>
          station.modifiedAt
            ? dayjs(station.modifiedAt).format(DATE_FORMAT_DATETIME)
            : '',
        renderCellContent: ({ station }) => (
          <Ellipsis component="span">
            {station.modifiedAt
              ? dayjs(station.modifiedAt).format(DATE_FORMAT_DATETIME)
              : ''}
          </Ellipsis>
        ),
      },
    ],
    [api, updateStationData]
  );

  const getItemId = React.useCallback(
    ({ station }: ITableItem) => String(station.id),
    []
  );

  const applyFilterParams = React.useCallback(
    (filterParams: Record<string, unknown>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  return (
    <>
      <EditRowStationsForm
        isOpen={isOpenEditRowDialog}
        station={activeStationForEdit}
        onSave={handleSaveStationData}
        onClose={handleCloseModalRowEdit}
      />

      <FormattedTitle>Справочник станций</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <StationsMultiSelectFilter
                  initialValue={route.params.ids}
                  onApply={newIds => {
                    applyFilterParams({ ids: newIds });
                  }}
                />

                <RailroadsMultiSelectFilter
                  initialValue={route.params.roads}
                  onApply={newRoads => {
                    applyFilterParams({ roads: newRoads });
                  }}
                />

                <Button text="Обновить" onClick={stationsRequest.refetch} />
                <LinkButton text="Сбросить фильтры" to={route.name} />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <YesNoRadioGroupFilter
            label="Есть данные"
            value={route.params.hasData}
            onChange={newHasData => {
              applyFilterParams({ hasData: newHasData });
            }}
          />
        </Row>

        <Row stretch>
          {!items || !stationsRequest.data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <ListTable
              columns={columns}
              getItemId={getItemId}
              isFetching={isFetching}
              items={items}
              lineNumbersStart={
                stationsRequest.data.pageSize *
                  (stationsRequest.data.currentPage - 1) +
                1
              }
            />
          )}
        </Row>

        {stationsRequest.data && (
          <Row>
            <Pagination
              pageSize={stationsRequest.data.pageSize}
              totalPages={stationsRequest.data.totalPages}
            />
          </Row>
        )}
      </VGrid>
    </>
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import {
  DATE_FORMAT_API_DATE,
  DATE_FORMAT_API_DATETIME,
} from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { serializable } from '_core/serializable';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import dayjs from 'dayjs';

const BASE_ENDPOINT = '/weighing_records';

interface IWeighingRecordSerialized {
  alerts: string[];
  capacity: number | null;
  cargo: string;
  created: string;
  gross: number;
  id: number;
  isProcessed: boolean;
  isXlsxSent: boolean;
  model: string;
  modificationDateOfAuxWagonFields: string | null;
  net: number | null;
  note: string | null;
  organization: string;
  tara: number | null;
  userModifiedAuxWagonFields: number | null;
  wagon: string;
  wagonPhoto: string;
  wagonType: string;
  weighingDatetime: string;
  weighingId: string;
}

interface IWeighingRecord
  extends Omit<
    IWeighingRecordSerialized,
    'created' | 'modificationDateOfAuxWagonFields' | 'weighingDatetime'
  > {
  created: Date;
  modificationDateOfAuxWagonFields: Date | null;
  weighingDatetime: Date;
}

@serializable
export class WeighingRecord implements IWeighingRecord {
  readonly alerts: string[];
  readonly capacity: number | null;
  readonly cargo: string;
  @serializable.datetime readonly created: Date;
  readonly gross: number;
  readonly id: number;
  readonly isProcessed: boolean;
  readonly isXlsxSent: boolean;
  readonly model: string;
  @serializable.datetime readonly modificationDateOfAuxWagonFields: Date | null;
  readonly net: number | null;
  readonly note: string | null;
  readonly organization: string;
  readonly tara: number | null;
  readonly userModifiedAuxWagonFields: number | null;
  readonly wagon: string;
  readonly wagonPhoto: string;
  readonly wagonType: string;
  @serializable.datetime readonly weighingDatetime: Date;
  readonly weighingId: string;

  static fromJSON(serialized: IWeighingRecordSerialized) {
    return serializable.fromJSON(WeighingRecord, serialized);
  }

  constructor(weighingRecord: IWeighingRecord) {
    this.alerts = weighingRecord.alerts;
    this.capacity = weighingRecord.capacity;
    this.cargo = weighingRecord.cargo;
    this.created = weighingRecord.created;
    this.gross = weighingRecord.gross;
    this.id = weighingRecord.id;
    this.isProcessed = weighingRecord.isProcessed;
    this.isXlsxSent = weighingRecord.isXlsxSent;
    this.model = weighingRecord.model;
    this.modificationDateOfAuxWagonFields =
      weighingRecord.modificationDateOfAuxWagonFields;
    this.net = weighingRecord.net;
    this.note = weighingRecord.note;
    this.organization = weighingRecord.organization;
    this.tara = weighingRecord.tara;
    this.userModifiedAuxWagonFields = weighingRecord.userModifiedAuxWagonFields;
    this.wagon = weighingRecord.wagon;
    this.wagonPhoto = weighingRecord.wagonPhoto;
    this.wagonType = weighingRecord.wagonType;
    this.weighingDatetime = weighingRecord.weighingDatetime;
    this.weighingId = weighingRecord.weighingId;
  }
}

export enum WeighingRecordsSortingField {
  Created = 'created',
  IsProcessed = 'is_processed',
  WagonNumber = 'wagon__number',
  WagonType = 'wagon_type',
  WeighingDatetime = 'weighing_datetime',
  WeighingId = 'weighing_id',
}

export enum WeighingAlert {
  Overload = 'overload',
  NetLt20 = 'net_lt_20',
  GrossGt90 = 'gross_gt_90',
}

export function isWeighingAlert(str: string): str is WeighingAlert {
  return Object.values(WeighingAlert).includes(str as WeighingAlert);
}

export async function fetchWeighingRecords(
  api: ApiClient,
  {
    alerts,
    cargo,
    fromDate,
    isProcessed,
    isXlsxSent,
    organization,
    page,
    pageSize,
    sorting,
    toDate,
    wagonType,
    wagons,
    weighingDatetime,
  }: {
    alerts?: WeighingAlert[];
    cargo?: string[];
    fromDate?: Date;
    isProcessed?: boolean;
    isXlsxSent?: boolean;
    organization?: string[];
    page?: number;
    pageSize?: number;
    sorting?: Array<ISorting<WeighingRecordsSortingField>>;
    toDate?: Date;
    wagonType?: string[];
    wagons?: string[];
    weighingDatetime?: Date;
  } = {}
): Promise<ListResponse<WeighingRecord>> {
  const response = await api.get<ListResponse<IWeighingRecordSerialized>>(
    BASE_ENDPOINT,
    {
      alerts: alerts && alerts.length !== 0 ? alerts.join(',') : undefined,
      cargo: cargo && cargo.length !== 0 ? cargo.join(',') : undefined,
      fromDate: fromDate && dayjs(fromDate).format(DATE_FORMAT_API_DATE),
      isProcessed,
      isXlsxSent,
      ordering: sorting && sortingToApiQueryParam(sorting),
      organization:
        organization && organization.length !== 0
          ? organization.join(',')
          : undefined,
      page,
      pageSize,
      toDate: toDate && dayjs(toDate).format(DATE_FORMAT_API_DATE),
      wagonType:
        wagonType && wagonType.length !== 0 ? wagonType.join(',') : undefined,
      wagons: wagons && wagons.length !== 0 ? wagons.join(',') : undefined,
      weighingDatetime:
        weighingDatetime &&
        dayjs(weighingDatetime).format(DATE_FORMAT_API_DATETIME),
    }
  );

  return {
    ...response,
    results: response.results.map(WeighingRecord.fromJSON),
  };
}

export async function fetchWeighingRecordsFilterValues(api: ApiClient) {
  const { dateRange, ...otherProps } = await api.get<{
    cargo: string[];
    dateRange: [string, string];
    wagonType: string[];
    organization: string[];
  }>(`${BASE_ENDPOINT}/filter_values`);

  return {
    ...otherProps,
    dateRange: [parseDate(dateRange[0]), parseDate(dateRange[1])] as [
      Date,
      Date
    ],
  };
}

export async function bulkCreateWeighingRecords(
  api: ApiClient,
  { files }: { files: string[] }
) {
  const createdWeighingRecords = await api.post<IWeighingRecordSerialized[]>(
    `${BASE_ENDPOINT}/bulk_create`,
    { files }
  );

  return createdWeighingRecords.map(WeighingRecord.fromJSON);
}

export function getWeighingRecordsXlsxDownloadLink(
  api: ApiClient,
  ids: number[]
) {
  return api.buildApiUrl('/weighing_records/download_xlsx', {
    ids: ids.length === 0 ? undefined : ids.join(','),
  });
}

export function getWeighingRecordsReportDownloadLink(
  api: ApiClient,
  ids: number[]
) {
  return api.buildApiUrl('/weighing_records/download_report_xls', {
    ids: ids.length === 0 ? undefined : ids.join(','),
  });
}

export function sendWeighingRecordsXlsxToTelegram(
  api: ApiClient,
  ids: number[]
) {
  api.post(
    '/weighing_records/send_xlsx_to_telegram',
    {},
    {
      query: {
        ids: ids.length === 0 ? undefined : ids.join(','),
      },
    }
  );
}

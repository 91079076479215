import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import {
  SelectForFinalForm,
  SelectInFormGroupForFinalForm,
} from '_core/inputs/select';
import { Link } from '_core/router5/link';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, H4, Intent } from '@blueprintjs/core';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { IPartnerSerialized, PartnerType } from 'partners/api';
import { partnerTypeOptions } from 'partners/utils';
import * as React from 'react';
import { useRef } from 'react';

import { PartnerContactsField } from './contactsField';

export interface IPartnerFormValues {
  actualAddress: string;
  bankName: string;
  bik: string;
  checkingAccount: string;
  contacts: number[];
  correspondentAccount: string;
  email: string;
  fullName: string;
  inn: string;
  isClient: boolean;
  isSupplier: boolean;
  kpp: string;
  legalAddress: string;
  manager: string;
  ogrn: string;
  okato: string;
  okpo: string;
  oktmo: string;
  paymentData: string;
  postAddress: string;
  shortName: string;
  type: PartnerType | '';
}

interface IProps {
  initialValues: IPartnerFormValues;
  navigationLock: IFormNavigationLock;
  partner?: IPartnerSerialized;
  workers: IUser[];
  onSave: (values: IPartnerFormValues) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IPartnerFormValues
  ) => Promise<SubmissionErrors | void>;
}

type SubmitTrigger = 'save' | 'saveAndContinue';

export function PartnersForm({
  initialValues,
  navigationLock,
  partner,
  workers,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <FinalForm
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={3}>
                  <H4>Основная информация</H4>

                  <FormGroupForFinalForm
                    label="Наименование полное"
                    labelFor="fullName"
                    labelInfo="*"
                    name="fullName"
                  >
                    <InputGroupForFinalForm
                      autoFocus
                      id="fullName"
                      name="fullName"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Наименование краткое"
                    labelFor="shortName"
                    labelInfo="*"
                    name="shortName"
                  >
                    <InputGroupForFinalForm
                      id="shortName"
                      name="shortName"
                      style={{ textTransform: 'uppercase' }}
                    />
                  </FormGroupForFinalForm>

                  <SelectInFormGroupForFinalForm
                    id="type"
                    label="Вид юридического лица"
                    name="type"
                    options={[
                      { label: 'Не указано', value: '' as PartnerType | '' },
                      ...partnerTypeOptions,
                    ]}
                  />

                  <CheckboxForFinalForm
                    id="isClient"
                    label="Клиент"
                    name="isClient"
                  />

                  <CheckboxForFinalForm
                    id="isSupplier"
                    label="Поставщик"
                    name="isSupplier"
                  />

                  <FormGroupForFinalForm
                    label="Ответственный менеджер"
                    labelFor="manager"
                    labelInfo="*"
                    name="manager"
                  >
                    <SelectForFinalForm
                      fill
                      id="manager"
                      name="manager"
                      options={workers.map(user => ({
                        label: formatUserName(user),
                        value: String(user.id),
                      }))}
                    />
                  </FormGroupForFinalForm>

                  {partner && (
                    <>
                      <p>
                        <Link
                          params={{ partner: partner.id }}
                          rel="noopener"
                          target="_blank"
                          to="contracts.purchase"
                        >
                          Договоры входящие
                        </Link>
                      </p>

                      <p>
                        <Link
                          params={{ partner: partner.id }}
                          rel="noopener"
                          target="_blank"
                          to="contracts.sell"
                        >
                          Договоры исходящие
                        </Link>
                      </p>
                    </>
                  )}
                </Col>

                <Col span={6}>
                  <H4>Контакты</H4>

                  <PartnerContactsField
                    initialPartner={partner?.id}
                    name="contacts"
                  />
                </Col>

                <Col span={3}>
                  <H4>Адреса</H4>

                  <FormGroupForFinalForm
                    label="Email"
                    labelFor="email"
                    name="email"
                  >
                    <InputGroupForFinalForm
                      id="email"
                      name="email"
                      type="email"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Адрес юридический"
                    labelFor="legalAddress"
                    name="legalAddress"
                  >
                    <InputGroupForFinalForm
                      id="legalAddress"
                      name="legalAddress"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Адрес почтовый"
                    labelFor="postAddress"
                    name="postAddress"
                  >
                    <InputGroupForFinalForm
                      id="postAddress"
                      name="postAddress"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Адрес фактический"
                    labelFor="actualAddress"
                    name="actualAddress"
                  >
                    <InputGroupForFinalForm
                      id="actualAddress"
                      name="actualAddress"
                    />
                  </FormGroupForFinalForm>

                  <H4>Реквизиты</H4>

                  <FormGroupForFinalForm label="ИНН" labelFor="inn" name="inn">
                    <InputGroupForFinalForm id="inn" name="inn" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm label="КПП" labelFor="kpp" name="kpp">
                    <InputGroupForFinalForm id="kpp" name="kpp" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="ОГРН / ОГРНИП"
                    labelFor="ogrn"
                    name="ogrn"
                  >
                    <InputGroupForFinalForm id="ogrn" name="ogrn" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="ОКАТО"
                    labelFor="okato"
                    name="okato"
                  >
                    <InputGroupForFinalForm id="okato" name="okato" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="ОКПО"
                    labelFor="okpo"
                    name="okpo"
                  >
                    <InputGroupForFinalForm id="okpo" name="okpo" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="ОКТМО"
                    labelFor="oktmo"
                    name="oktmo"
                  >
                    <InputGroupForFinalForm id="oktmo" name="oktmo" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Расчетный счет"
                    labelFor="checkingAccount"
                    name="checkingAccount"
                  >
                    <InputGroupForFinalForm
                      id="checkingAccount"
                      name="checkingAccount"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Наименование банка"
                    labelFor="bankName"
                    name="bankName"
                  >
                    <InputGroupForFinalForm id="bankName" name="bankName" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Коррсчет"
                    labelFor="correspondentAccount"
                    name="correspondentAccount"
                  >
                    <InputGroupForFinalForm
                      id="correspondentAccount"
                      name="correspondentAccount"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm label="БИК" labelFor="bik" name="bik">
                    <InputGroupForFinalForm id="bik" name="bik" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Платежная информация"
                    labelFor="paymentData"
                    name="paymentData"
                  >
                    <InputMultipleForFinalForm
                      id="paymentData"
                      name="paymentData"
                      rows={5}
                    />
                  </FormGroupForFinalForm>
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

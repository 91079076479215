import { WagonAlert } from './api';

const alertColors = {
  [WagonAlert.NonWorkingPark]: '#E03535',
  [WagonAlert.OperationBros]: '#a6a6a6',
  [WagonAlert.RepairDate]: '#E46F7E',
  [WagonAlert.RepairDate2]: '#F1B8B8',
  [WagonAlert.StationDowntime]: '#ffd966',
  [WagonAlert.WagonMileage]: '#E27454',
  [WagonAlert.DeliveryDateOverdue]: '#90e3ff',
  [WagonAlert.CargoOperationIsOver]: '#9CEE90',
};

export const getWagonAlertColor = (alert: WagonAlert) => alertColors[alert];

const alertLabels = {
  [WagonAlert.NonWorkingPark]: 'НРП',
  [WagonAlert.OperationBros]: 'Код "БРОС"',
  [WagonAlert.RepairDate]: 'До ремонта < 31 дня',
  [WagonAlert.RepairDate2]: 'До ремонта < 62 дней',
  [WagonAlert.StationDowntime]: 'Простой по станции',
  [WagonAlert.WagonMileage]: 'Остаток пробега',
  [WagonAlert.DeliveryDateOverdue]: 'Просрочка доставки',
  [WagonAlert.CargoOperationIsOver]: 'ГО окончена',
};

export const getWagonAlertLabel = (alert: WagonAlert) => alertLabels[alert];

export const wagonAlertsByPriority = [
  WagonAlert.NonWorkingPark,
  WagonAlert.RepairDate,
  WagonAlert.RepairDate2,
  WagonAlert.WagonMileage,
  WagonAlert.OperationBros,
  WagonAlert.StationDowntime,
  WagonAlert.DeliveryDateOverdue,
  WagonAlert.CargoOperationIsOver,
];

export const wagonAlertsOptions = wagonAlertsByPriority.map(alert => ({
  label: getWagonAlertLabel(alert),
  value: alert,
}));

export const getWagonHighestPriorityAlertColor = (alerts: WagonAlert[]) => {
  const highestPriorityAlert = wagonAlertsByPriority.find(alert =>
    alerts.includes(alert)
  );

  return highestPriorityAlert && getWagonAlertColor(highestPriorityAlert);
};

const nonDigitsRE = /[^\d]+/;
const wagonNumberRE = /^[1-9]\d{7}$/;

export const parseWagonNumbers = (str: string) =>
  str.split(nonDigitsRE).filter(wagonNumber => wagonNumberRE.test(wagonNumber));

export function createWagonMatcher(filterQuery: string) {
  const numbers = parseWagonNumbers(filterQuery);

  if (numbers.length === 0) {
    return (wagon: string) => {
      return wagon.includes(filterQuery);
    };
  }

  return (wagon: string) => {
    return numbers.includes(wagon);
  };
}

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { IRentProtocolSerialized } from '../rentProtocols/types';
import { BASE_ENDPOINT } from './api';

export const RentProtocolSellAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<IRentProtocolSerialized, number>({
      baseEndpoint: BASE_ENDPOINT,
      itemToOption: protocol => ({
        label: String(protocol.protocolName),
        value: protocol.id,
      }),

      getLink: protocol => ({
        params: { expandProtocol: String(protocol.id) },
        to: 'rentWagons.sell',
      }),
    })
  );

export const RentProtocolSellAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    RentProtocolSellAutocompleteInFormGroup
  );

import { range } from '_core/fp/range';
import md5 from 'blueimp-md5';
import { IExpeditionTransportation } from 'expeditionTransportations/types';
import { ITechrunTransportation } from 'techrunTransportations/types';

export function createTransportationDuplicateColorGenerator() {
  const hueValues = range(10, 331, 5);
  const saturationValues = range(50, 101, 5);
  const lightnessValues = range(70, 91, 5);

  function hashForStr(hashKey: string) {
    let result = 0;
    const md5Hash = md5(hashKey);
    const len = md5Hash.length;

    for (let i = 0; i < len; i++) {
      result += md5Hash.charCodeAt(i) - '0'.charCodeAt(0);
      result %= Number.MAX_SAFE_INTEGER;
    }

    return result;
  }

  return (
    transportation: Pick<
      IExpeditionTransportation | ITechrunTransportation,
      'hasDuplicate' | 'wagon' | 'waybillNumber'
    >
  ) => {
    if (!transportation.hasDuplicate) {
      return undefined;
    }

    const hashValue = hashForStr(
      transportation.wagon + transportation.waybillNumber
    );

    const hue = hueValues[hashValue % hueValues.length];
    const saturation = saturationValues[hashValue % saturationValues.length];
    const lightness = lightnessValues[hashValue % lightnessValues.length];

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };
}

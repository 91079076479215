import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { IContactSerialized } from './api';
import { ContactDialogForm, IContactDialogFormValues } from './dialogForm';
import { formatContactName } from './utils';

interface IExternalProps {
  initialPartner?: number | null;
}

export const ContactsAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<
      IContactSerialized,
      number,
      IContactDialogFormValues,
      IExternalProps
    >({
      baseEndpoint: '/contacts',
      optionIcon: 'envelope',
      placeholder: 'Начните вводить имя или email...',

      getLink: contact => ({
        params: { id: String(contact.id) },
        to: 'contacts.edit',
      }),

      itemToOption: contact => ({
        label: formatContactName(contact, { includeEmail: true }),
        value: contact.id,
      }),

      create: {
        DialogForm: ContactDialogForm,
        newOptionIcon: 'add',
        newOptionLabel: 'Создать новый контакт...',

        dialogProps: {
          icon: 'envelope',
          title: 'Создание нового контакта',
        },

        getFormInitialValues: (autocompleteText, { initialPartner }) => ({
          email: autocompleteText,
          firstName: '',
          firstNameRp: '',
          jobTitle: '',
          jobTitleRp: '',
          lastName: '',
          lastNameRp: '',
          middleName: '',
          middleNameRp: '',
          note: '',
          partner: initialPartner || null,
          phone: '',
          phoneExtra: '',
        }),
      },
    })
  );

export const ContactsAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    ContactsAutocompleteInFormGroup
  );

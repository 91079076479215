import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { required, validate } from '_core/forms/validators';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FileInputSingleForFinalForm } from '_core/inputs/fileInputSingle';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Button, Intent } from '@blueprintjs/core';
import { Col, Grid } from 'layout/contentLayout';
import * as React from 'react';

export interface IRentWagonsSellCloseDialogFormValues {
  actFile: string | null;
  endDate: Date;
  wagons: string;
}

interface IProps {
  initialValues: IRentWagonsSellCloseDialogFormValues;
  isOpen: boolean;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (values: IRentWagonsSellCloseDialogFormValues) => void;
}

export function RentWagonsSellCloseDialogForm({
  initialValues,
  isOpen,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  return (
    <FinalForm
      initialValues={initialValues}
      validate={validate({
        actFile: required(),
      })}
      onSubmit={onSubmit}
    >
      {({ error, handleSubmit, submitError, submitting }) => (
        <DialogForm
          isOpen={isOpen}
          isSubmitting={submitting}
          title="Принять вагоны из аренды"
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormErrors error={error || submitError} />

            <Grid>
              <Col span={6}>
                <DateInputInFormGroupForFinalForm
                  id="endDate"
                  label="Дата приема из аренды"
                  name="endDate"
                />
              </Col>

              <Col span={6}>
                <FileInputSingleForFinalForm
                  id="actFile"
                  label="Акт приёма-передачи"
                  name="actFile"
                />
              </Col>
            </Grid>

            <FormGroupForFinalForm
              label="Вагоны"
              labelFor="wagons"
              name="wagons"
            >
              <InputMultipleForFinalForm id="wagons" name="wagons" />
            </FormGroupForFinalForm>
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button disabled={submitting} text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Принять"
                type="submit"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

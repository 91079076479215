import { SelectForFinalForm } from '_core/inputs/select';
import { useAsyncData } from '_core/useAsyncData';
import * as React from 'react';
import { techrunRateToOption } from 'techrunRates/types';

import { useGetPurchaseRatesForPartner } from '../purchaseRatesForPartner';

interface IProps {
  change: (fieldName: string, value: unknown) => void;
  disabled: boolean | undefined;
  parentFieldName: string;
  purchasePartner: string | null;
  rowIndex: number;
  onChange: (rowIndex: number, newPurchaseRate: string | null) => void;
}

export function PurchaseRateCell({
  change,
  disabled,
  parentFieldName,
  purchasePartner,
  rowIndex,
  onChange,
}: IProps) {
  const getPurchaseRatesForPartner = useGetPurchaseRatesForPartner();

  const purchaseRatesRequest = useAsyncData(
    [getPurchaseRatesForPartner, purchasePartner],
    async () => {
      if (purchasePartner == null) {
        return null;
      }

      const purchaseRates = await getPurchaseRatesForPartner(
        Number(purchasePartner)
      );

      return purchaseRates;
    }
  );

  return (
    <SelectForFinalForm
      disabled={disabled}
      fill
      id={`${parentFieldName}[${rowIndex}].purchaseRate`}
      options={(purchaseRatesRequest.data || []).map(techrunRateToOption)}
      name={`${parentFieldName}[${rowIndex}].purchaseRate`}
      withEmptyOption
      onChange={newPurchaseRate => {
        onChange(rowIndex, newPurchaseRate);
        change(`${parentFieldName}.[${rowIndex}].purchaseRent`, null);
      }}
    />
  );
}

import { Alert, H4, Intent } from '@blueprintjs/core';
import * as React from 'react';

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ProtocolDateAlert({ isOpen, onCancel, onConfirm }: IProps) {
  return (
    <Alert
      cancelButtonText="Отмена"
      confirmButtonText="Продолжить"
      intent={Intent.DANGER}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <H4>Внимание!</H4>

      <p>
        Срок действия превышает календарный год, вы уверены, что хотите
        продолжить?
      </p>
    </Alert>
  );
}

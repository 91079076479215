import { useApiClient } from '_core/api/context';
import { ListResponse } from '_core/api/types';
import { useAsyncTasks } from '_core/download/asyncDownload';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { finiteNumberOr } from '_core/finiteNumberOr';
import { SearchForm } from '_core/forms/searchForm';
import { Radio, RadioGroup } from '_core/inputs/radio';
import { ColumnsSelectorsGroup } from '_core/lists/columnPresets/columnsSelectorsGroup';
import { useColumnPresets } from '_core/lists/columnPresets/useColumnPresets';
import { usePreference } from '_core/me/usePreference';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TreeTable, TreeTableColumn } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import {
  createListToTreeTableColumnAdapter,
  everyNode,
  findNode,
  setAllNodesIsExpanded,
} from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { useDerivedState } from '_core/useDerivedState';
import { Button, Intent } from '@blueprintjs/core';
import { snakeCase } from 'change-case';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { ParksFilter } from 'parks/parksFilter';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useRoute } from 'react-router5';
import { ArrDepOperStationsFilterInPopover } from 'stations/arrDepOperFilterInPopover';
import { DowntimeDurationFilter } from 'technical/downtimeDurationFilter';
import invariant from 'tiny-invariant';
import { renderWagonAlertsLegendItems } from 'wagons/alertsLegend';
import { IWagonSerialized } from 'wagons/api';
import { WagonNumbersFilter } from 'wagons/numbersFilter';
import { createWagonColumns } from 'wagons/table/columns';
import {
  getWagonHighestPriorityAlertColor,
  wagonAlertsOptions,
} from 'wagons/utils';

enum GroupBy {
  Park = 'park',
  Owner = 'owner',
}

type DowntimesTableNodeData =
  | { kind: 'park'; park: number; parkName: string; wagonCount: number }
  | { kind: 'owner'; ownerName: string; wagonCount: number }
  | { kind: 'wagon'; wagon: IWagonSerialized };

type WagonsGroup =
  | { park: number; parkName: string; wagons: IWagonSerialized[] }
  | { ownerName: string; wagons: IWagonSerialized[] };

const adaptColumn = createListToTreeTableColumnAdapter<
  IWagonSerialized,
  DowntimesTableNodeData
>(nodeData => (nodeData.kind === 'wagon' ? nodeData.wagon : null));

export default function DowntimesParksRoute() {
  const { route, router } = useRoute();
  const groupBy = (route.params.groupBy as GroupBy) || GroupBy.Park;
  const api = useApiClient();

  const queryFromRoute = useMemo(
    () => ({
      alerts: route.params.alerts,
      arrivalStation: route.params.arrivalStation,
      consignee: route.params.consignee,
      departureStation: route.params.departureStation,
      downtimeDurationGreater: route.params.downtimeDurationGreater,
      groupBy,
      numbers: route.params.numbers,
      operationStation: route.params.operationStation,
      operationStationNot: route.params.operationStationNot,
      ordering: route.params.ordering,
      page: route.params.page,
      pageSize: 5,
      parks: route.params.parks,
      search: route.params.search,
    }),

    [groupBy, route]
  );

  const { data, isFetching } = useAsyncData(
    [queryFromRoute, api],

    async () => {
      const { meta, results } = await api.get<ListResponse<WagonsGroup>>(
        '/wagon_downtimes',
        queryFromRoute
      );

      return {
        downtimes: results,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
      };
    }
  );

  const [nodes, setNodes] = useDerivedState<
    WagonsGroup[] | undefined,
    Array<TreeTableNode<DowntimesTableNodeData>> | undefined
  >(data?.downtimes, (input, prevNodes) =>
    input?.map((group): TreeTableNode<DowntimesTableNodeData> => {
      const wagonCount = group.wagons.length;

      if ('park' in group) {
        const prevParkNode = prevNodes
          ? findNode(
              prevNodes,
              node => node.data.kind === 'park' && node.data.park === group.park
            )
          : undefined;

        return {
          id: `park-${group.park}`,
          data: {
            kind: 'park',
            park: group.park,
            parkName: group.parkName,
            wagonCount,
          },
          children: {
            isExpanded:
              prevParkNode && prevParkNode.children
                ? prevParkNode.children.isExpanded
                : false,
            nodes: group.wagons.map(
              (wagon): TreeTableNode<DowntimesTableNodeData> => ({
                data: { kind: 'wagon', wagon },
                id: `park-${group.park}-wagon-${wagon.number}`,
              })
            ),
          },
        };
      }

      const prevOwnerNode = prevNodes
        ? findNode(
            prevNodes,
            node =>
              node.data.kind === 'owner' &&
              node.data.ownerName === group.ownerName
          )
        : undefined;

      return {
        id: `owner-${group.ownerName}`,
        data: { kind: 'owner', ownerName: group.ownerName, wagonCount },
        children: {
          isExpanded:
            prevOwnerNode && prevOwnerNode.children
              ? prevOwnerNode.children.isExpanded
              : false,
          nodes: group.wagons.map(
            (wagon): TreeTableNode<DowntimesTableNodeData> => ({
              data: { kind: 'wagon', wagon },
              id: `owner-${group.ownerName}-wagon-${wagon.number}`,
            })
          ),
        },
      };
    })
  );

  const preferencesPath = ['downtimes', 'parks'];
  const [parks, setParks] = usePreference([...preferencesPath, 'parks']);

  useEffect(() => {
    if (parks && route.params.parks !== parks) {
      router.navigate(route.name, { ...route.params, parks });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFilteringByDislocationDatetime = Boolean(
    route.params.dislocationDatetime
  );

  function applyFilterParams(filterParams: Record<string, string | undefined>) {
    router.navigate(route.name, {
      ...route.params,
      page: undefined,
      ...filterParams,
    });
  }

  const dislocationProp = isFilteringByDislocationDatetime
    ? 'selectedDislocation'
    : 'latestDislocation';

  const listParams = JSON.stringify(route.params);

  const columns = createWagonColumns({
    dislocationProp,
    listParams,
  }).map((originalColumn): TreeTableColumn<DowntimesTableNodeData> => {
    if (originalColumn.id === 'number') {
      return {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 400,
        expandable: true,
        copyCellContent: (node, rowIndex) => {
          switch (node.data.kind) {
            case 'owner': {
              const { ownerName, wagonCount } = node.data;

              return `${ownerName || 'Собственник не задан'} (${wagonCount})`;
            }
            case 'park': {
              const { parkName, wagonCount } = node.data;

              return `${parkName} (${wagonCount})`;
            }
            case 'wagon': {
              const { wagon } = node.data;

              return originalColumn.copyCellContent
                ? originalColumn.copyCellContent(wagon, rowIndex)
                : '';
            }
          }
        },
        renderCellContent: (node, rowIndex) => {
          switch (node.data.kind) {
            case 'owner': {
              const { ownerName, wagonCount } = node.data;

              return (
                <Ellipsis component="span">{`${
                  ownerName || 'Собственник не задан'
                } (${wagonCount})`}</Ellipsis>
              );
            }
            case 'park': {
              const { park, parkName, wagonCount } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: park }}
                  rel="noopener"
                  target="_blank"
                  to="parks.edit"
                >
                  {`${parkName} (${wagonCount})`}
                </Ellipsis>
              );
            }
            case 'wagon': {
              const { wagon } = node.data;

              return originalColumn.renderCellContent(wagon, rowIndex);
            }
          }
        },
      };
    }

    return adaptColumn(originalColumn);
  });

  const columnLabels = columns.reduce<Record<string, string>>(
    (labels, column) => {
      labels[column.id] = column.label;

      return labels;
    },

    {}
  );

  const allColumnIds = (
    groupBy === GroupBy.Park
      ? columns.filter(column => column.id !== 'requestPartner')
      : columns
  ).map(column => column.id);

  const columnPresets = useColumnPresets(preferencesPath, {
    allColumnIds,
    alwaysShownColumnIds: ['parks'],
    getColumnLabel: columnId => columnLabels[columnId],
  });

  const tasks = useAsyncTasks();

  const getRowColor = useCallback(
    (node: TreeTableNode<DowntimesTableNodeData>) => {
      if (node.data.kind !== 'wagon') {
        return undefined;
      }

      const { wagon } = node.data;

      return getWagonHighestPriorityAlertColor(wagon.alerts);
    },

    []
  );

  const allIsExpanded = nodes
    ? everyNode(nodes, node => !node.children || node.children.isExpanded)
    : false;

  return (
    <>
      <FormattedTitle>Простои по паркам</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <ColumnsSelectorsGroup
                  columnOptions={columnPresets.columnOptions}
                  defaultValue={columnPresets.defaultValue}
                  initialValue={columnPresets.initialValue}
                  preset={columnPresets.activePreset}
                  onApply={columnPresets.onShowColumnsApply}
                  onColumnPresetChange={columnPresets.onColumnPresetChange}
                />

                {!isFilteringByDislocationDatetime && (
                  <WagonNumbersFilter
                    initialValue={route.params.numbers}
                    onApply={numbers => {
                      applyFilterParams({ numbers });
                    }}
                  />
                )}

                <ParksFilter
                  initialValue={route.params.parks}
                  onApply={newParks => {
                    applyFilterParams({ parks: newParks });
                    setParks(newParks);
                  }}
                />

                <DowntimeDurationFilter
                  initialValue={{
                    downtimeDurationGreater: finiteNumberOr(
                      '',
                      route.params.downtimeDurationGreater
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ) as any,
                  }}
                  onApply={duration => {
                    applyFilterParams({ ...duration });
                  }}
                />

                {!isFilteringByDislocationDatetime && (
                  <ArrDepOperStationsFilterInPopover
                    initialValue={{
                      arrivalStation: finiteNumberOr(
                        undefined,
                        route.params.arrivalStation
                      ),

                      consignee: route.params.consignee,

                      departureStation: finiteNumberOr(
                        undefined,
                        route.params.departureStation
                      ),

                      operationStation: finiteNumberOr(
                        undefined,
                        route.params.operationStation
                      ),
                    }}
                    showConsigneeFilter
                    onApply={({
                      arrivalStation,
                      consignee,
                      departureStation,
                      operationStation,
                    }) => {
                      applyFilterParams({
                        arrivalStation: arrivalStation
                          ? String(arrivalStation)
                          : undefined,

                        consignee,

                        departureStation: departureStation
                          ? String(departureStation)
                          : undefined,

                        operationStation: operationStation
                          ? String(operationStation)
                          : undefined,
                      });
                    }}
                  />
                )}

                {!isFilteringByDislocationDatetime && (
                  <CheckboxListFilter
                    allOption
                    iconName="warning-sign"
                    initialValue={route.params.alerts}
                    label="Предупреждения"
                    options={wagonAlertsOptions}
                    onApply={alerts => {
                      applyFilterParams({ alerts });
                    }}
                  />
                )}

                <LinkButton
                  options={{ reload: true }}
                  params={route.params}
                  text="Обновить"
                  to={route.name}
                />

                <LinkButton
                  params={route.params}
                  text="Сбросить фильтры"
                  to={route.name}
                />

                <Button
                  icon="download"
                  intent={Intent.SUCCESS}
                  text="Дислокация"
                  onClick={async () => {
                    const preset = columnPresets.showColumns.map(id => {
                      const column = columns.find(col => col.id === id);
                      invariant(column, 'Cannot find column');
                      const columnId = column.id.split('.');

                      return {
                        name: column.label,
                        field: snakeCase(columnId[1] || columnId[0]),
                        model: snakeCase(columnId[1] ? columnId[0] : 'wagon'),
                      };
                    });

                    const { taskId } = await api.post<{ taskId: string }>(
                      '/wagon_downtimes/download_xlsx',
                      { groupBy, preset },
                      { query: queryFromRoute }
                    );

                    tasks.waitForGeneratedFile(taskId);
                  }}
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <RadioGroup
            inline
            selectedValue={groupBy}
            onChange={newGroupBy => {
              applyFilterParams({ groupBy: newGroupBy });
            }}
          >
            <Radio key={GroupBy.Park} label="По паркам" value={GroupBy.Park} />

            <Radio
              key={GroupBy.Owner}
              label="По собственникам"
              value={GroupBy.Owner}
            />
          </RadioGroup>
        </Row>

        <Row>
          <Toolbar>
            {allIsExpanded ? (
              <Button
                icon="collapse-all"
                text="Свернуть всё"
                onClick={() => {
                  setNodes(
                    prevNodes =>
                      prevNodes && setAllNodesIsExpanded(prevNodes, false)
                  );
                }}
              />
            ) : (
              <Button
                icon="expand-all"
                text="Развернуть всё"
                onClick={() => {
                  setNodes(
                    prevNodes =>
                      prevNodes && setAllNodesIsExpanded(prevNodes, true)
                  );
                }}
              />
            )}

            {renderWagonAlertsLegendItems()}
          </Toolbar>
        </Row>

        <Row stretch>
          {!nodes ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : nodes.length === 0 ? (
            <EmptyListMessage />
          ) : (
            <TreeTable
              columns={columns}
              columnWidths={columnPresets.columnWidths}
              getRowColor={getRowColor}
              isFetching={isFetching}
              nodes={nodes}
              overscanRowCount={5}
              showColumns={columnPresets.showColumns}
              stickyColumnCount={1}
              onColumnWidthChanged={columnPresets.onColumnWidthChanged}
              onNodesChange={setNodes}
            />
          )}
        </Row>

        {data && (
          <Row>
            <Grid>
              <Col align="center">
                <Pagination
                  pageSize={data.pageSize}
                  totalPages={data.totalPages}
                />
              </Col>
            </Grid>
          </Row>
        )}
      </VGrid>
    </>
  );
}

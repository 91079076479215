import { useEffect, useRef, useState } from 'react';

interface IState<TData> {
  data: TData | null;
  error: Error | null;
  isFetching: boolean;
}

export interface IAsyncData<TData> extends IState<TData> {
  refetch: () => void;
  updateData: (updater: (prevData: TData | null) => TData | null) => void;
}

export function useAsyncData<TData>(
  deps: readonly unknown[],
  fetcher: () => Promise<TData>
): IAsyncData<TData> {
  const [state, setState] = useState<IState<TData>>({
    data: null,
    error: null,
    isFetching: true,
  });

  const lastPromiseRef = useRef<Promise<TData> | null>(null);

  function fetchData() {
    if (!state.isFetching) {
      setState({ ...state, isFetching: true });
    }

    const promise = fetcher();
    lastPromiseRef.current = promise;

    promise.then(
      data => {
        if (promise === lastPromiseRef.current) {
          setState({ data, error: null, isFetching: false });
        }
      },

      error => {
        if (promise === lastPromiseRef.current) {
          setState({ data: null, error, isFetching: false });
        }

        throw error;
      }
    );
  }

  useEffect(() => {
    fetchData();

    return () => {
      lastPromiseRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    ...state,
    refetch: fetchData,
    updateData: updater => {
      setState(prevState => ({
        ...prevState,
        data: updater(prevState.data),
      }));
    },
  };
}

import * as React from 'react';
import { useRoute } from 'react-router5';
import invariant from 'tiny-invariant';

interface IBranchProps {
  children: React.ReactElement;
  node?: string;
}

export const RouteSwitchBranch = (_props: IBranchProps): never => {
  throw new Error('RouteSwitchBranch must not be rendered directly');
};

type BranchElement = React.ReactElement<IBranchProps, typeof RouteSwitchBranch>;

interface ISwitchProps {
  children: BranchElement | BranchElement[];
  node: string;
}

export function RouteSwitch({ children, node }: ISwitchProps) {
  const { route } = useRoute();
  const nodeSegments = node === '' ? [] : node.split('.');
  const currentRouteSegments = route.name.split('.');

  const branches = React.Children.map<IBranchProps, BranchElement>(
    children,
    branch => branch.props
  );

  let branchToRender: IBranchProps | null = null;

  for (const branch of branches) {
    if (branch.node === undefined) {
      branchToRender = branch;
      break;
    }

    if (branch.node === '' && route.name === node) {
      branchToRender = branch;
      break;
    }

    const segments = nodeSegments.concat([branch.node]);

    if (
      segments.join('.') ===
      currentRouteSegments.slice(0, segments.length).join('.')
    ) {
      branchToRender = branch;
      break;
    }
  }

  invariant(branchToRender, "Can't find a branch to render");
  return branchToRender.children;
}

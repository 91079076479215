import { useApiClient } from '_core/api/context';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { isNotNull } from '_core/isNotNull';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import * as React from 'react';
import { useMemo, useRef } from 'react';
import { useRouteNode } from 'react-router5';
import {
  fetchTechrunGu12Item,
  ITechrunGu12Item,
  updateTechrunGu12Item,
} from 'techrunGu12/api';
import { fetchManyTechrunTransportations } from 'techrunTransportations/api';

import { SubmitTrigger, TechrunsGu12Form } from './form';
import { TechrunsGu12FormRouteWrapper } from './formRouteWrapper';

export default function TechrunsGu12EditRoute() {
  const { route, router } = useRouteNode('techruns.gu12.edit');
  const navigationLock = useFormNavigationLock('techruns.gu12.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    async () => {
      const techrunGu12Item = await fetchTechrunGu12Item(api, route.params.id);

      const transportations = await fetchManyTechrunTransportations(
        api,
        techrunGu12Item.transportations
      );

      return {
        techrunGu12Item,
        transportations,
      };
    }
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const submitTriggerRef = useRef<SubmitTrigger>('save');

  const deserialize = ({
    arrivalStationName,
    departureStationName,
    id,
    owners,
    ...otherProps
  }: ITechrunGu12Item) => ({
    ...otherProps,
    owners: owners.map(owner => ({ owner })),
  });

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    )
  ) : (
    <TechrunsGu12FormRouteWrapper
      listParams={listParams}
      title={data.techrunGu12Item.number}
    >
      <TechrunsGu12Form
        initialValues={deserialize(data.techrunGu12Item)}
        navigationLock={navigationLock}
        setSubmitTrigger={newSubmitTrigger => {
          submitTriggerRef.current = newSubmitTrigger;
        }}
        transportations={data.transportations}
        onSubmit={async ({ owners, ...otherProps }) => {
          try {
            const updateParams = {
              ...otherProps,
              owners: owners.map(({ owner }) => owner).filter(isNotNull),
            };

            switch (submitTriggerRef.current) {
              case 'save':
                await updateTechrunGu12Item(api, route.params.id, updateParams);
                navigationLock.unlock();
                router.navigate('techruns.gu12', listParams);
                break;
              case 'saveAndContinue': {
                const updatedTechrunGu12Item = await updateTechrunGu12Item(
                  api,
                  route.params.id,
                  updateParams
                );

                updateData(
                  prevData =>
                    prevData && {
                      ...prevData,
                      techrunGu12Item: updatedTechrunGu12Item,
                    }
                );
                break;
              }
            }

            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось изменить документ ГУ-12: Непредвиденная ошибка'
            );
          }
        }}
      />
    </TechrunsGu12FormRouteWrapper>
  );
}

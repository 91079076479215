import { Link } from '_core/router5/link';
import { plural } from '_core/strings/utils';
import { Alert, H4, HTMLTable, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Fragment, useMemo } from 'react';

import * as css from './wagonsActiveRequestsAlert.module.css';

interface IRequest {
  id: number;
  requestName: string;
  wagons: Array<{ wagon: string }>;
}

interface IProps {
  activeRequestsWithAddedWagons: IRequest[];
  addedWagons: string[];
  isOpen: boolean;
  itemRoute: 'expeditions.requests.view' | 'techruns.requests.view';
  onClosed: () => void;
  onConfirm: () => void;
}

export function WagonsActiveRequestsAlert({
  activeRequestsWithAddedWagons,
  addedWagons,
  isOpen,
  itemRoute,
  onClosed,
  onConfirm,
}: IProps) {
  const addedWagonsInActiveRequests = useMemo(
    () =>
      addedWagons.filter(addedWagon =>
        activeRequestsWithAddedWagons.some(request =>
          request.wagons.some(wagon => wagon.wagon === addedWagon)
        )
      ),

    [activeRequestsWithAddedWagons, addedWagons]
  );

  const wagonsWithContainingActiveRequests = useMemo(
    () =>
      addedWagonsInActiveRequests.map(addedWagon => ({
        wagon: addedWagon,

        requests: activeRequestsWithAddedWagons.filter(request =>
          request.wagons.some(wagon => wagon.wagon === addedWagon)
        ),
      })),
    [activeRequestsWithAddedWagons, addedWagonsInActiveRequests]
  );

  const addedWagonsCount = addedWagons.length;
  const addedWagonsInActiveRequestsCount = addedWagonsInActiveRequests.length;

  return (
    <Alert
      className={css.main}
      confirmButtonText="Ок"
      intent={Intent.PRIMARY}
      isOpen={isOpen}
      onClosed={onClosed}
      onConfirm={onConfirm}
    >
      <H4>
        В активных заявках{' '}
        {plural(addedWagonsInActiveRequestsCount, [
          () => 'содержится',
          () => 'содержатся',
          () => 'содержатся',
        ])}{' '}
        {addedWagonsInActiveRequestsCount} из {addedWagonsCount}{' '}
        {plural(addedWagonsCount, [
          () => 'добавленного вагона',
          () => 'добавленных вагонов',
          () => 'добавленных вагонов',
        ])}
      </H4>

      <HTMLTable bordered className={css.table} condensed interactive striped>
        <tbody>
          {wagonsWithContainingActiveRequests.map(({ requests, wagon }) => (
            <tr key={wagon}>
              <td>
                <Link params={{ number: wagon }} to="wagons.edit">
                  {wagon}
                </Link>
              </td>

              <td>
                {requests.map((request, index) => (
                  <Fragment key={request.id}>
                    {index !== 0 && ', '}

                    <span>
                      <Link params={{ id: request.id }} to={itemRoute}>
                        {request.requestName}
                      </Link>
                    </span>
                  </Fragment>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </Alert>
  );
}

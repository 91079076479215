import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyForFinalForm } from '_core/inputs/inputMoney';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Toolbar } from '_core/toolbar';
import { Alert, Button, H4, Intent } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { StationsAutocompleteInFormGroupForFinalForm } from 'stations/autocomplete';

import * as css from './ratesField.module.css';

export interface IExpeditionProtocolRatesFieldValue {
  confirmedTransportationsCount: number;
  costEmpty: string;
  costLoaded: string;
  isLocked: boolean;
  station: number | null;
  tariffEmpty: string;
  tariffLoaded: string;
}

interface ITableItem extends IExpeditionProtocolRatesFieldValue {
  index: number;
}

interface IProps<TName> {
  change: (name: TName, newValue: IExpeditionProtocolRatesFieldValue[]) => void;
  name: TName;
  readOnly: boolean;
  showEmptyCostColumns: boolean;
  showLoadedCostColumns: boolean;
}

export function ExpeditionProtocolRatesField<TName extends string>({
  change,
  name,
  readOnly,
  showEmptyCostColumns,
  showLoadedCostColumns,
}: IProps<TName>) {
  const { fields, meta } =
    useFieldArray<IExpeditionProtocolRatesFieldValue>(name);

  const [tryingToUnlockRowIndex, setTryingToUnlockRowIndex] = useState<
    number | null
  >(null);

  const unlockRow = useCallback(
    (rowIndex: number) => {
      change(
        name,
        fields.value.map((originalRate, index) =>
          index === rowIndex
            ? { ...originalRate, isLocked: false }
            : originalRate
        )
      );
    },
    [change, fields.value, name]
  );

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const disabled = (meta as any).submitting as boolean;

  const columns = useMemo(() => {
    const result: Array<ListTableColumn<ITableItem>> = [];

    result.push(
      {
        id: 'confirmedTransportationsCount',
        label: 'Подтверждённые отправки',
        defaultWidth: 100,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <div className={css.transportationsCountCell}>
            <InputGroupForFinalForm
              id={`${fields.name}[${rowIndex}].confirmedTransportationsCount`}
              name={`${fields.name}[${rowIndex}].confirmedTransportationsCount`}
              readOnly
            />

            {rowItem.isLocked && (
              <Button
                className={css.transportationsCountCellUnlockButton}
                disabled={disabled || readOnly}
                icon="edit"
                intent={Intent.PRIMARY}
                minimal
                onClick={() => {
                  if (rowItem.confirmedTransportationsCount === 0) {
                    unlockRow(rowIndex);
                  } else {
                    setTryingToUnlockRowIndex(rowIndex);
                  }
                }}
              />
            )}
          </div>
        ),
      },
      {
        id: 'station',
        label: 'Станция',
        defaultWidth: 250,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <StationsAutocompleteInFormGroupForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].station`}
            name={`${fields.name}[${rowIndex}].station`}
            noBottomMargin
            readOnly={readOnly}
          />
        ),
      }
    );

    if (showLoadedCostColumns) {
      result.push({
        id: 'costLoaded',
        label: 'Стоимость гружёная',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].costLoaded`}
            name={`${fields.name}[${rowIndex}].costLoaded`}
            readOnly={readOnly}
          />
        ),
      });
    }

    if (showEmptyCostColumns) {
      result.push({
        id: 'costEmpty',
        label: 'Стоимость порожняя',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].costEmpty`}
            name={`${fields.name}[${rowIndex}].costEmpty`}
            readOnly={readOnly}
          />
        ),
      });
    }

    result.push(
      {
        id: 'tariffLoaded',
        label: 'Тариф гружёный',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].tariffLoaded`}
            name={`${fields.name}[${rowIndex}].tariffLoaded`}
            readOnly={readOnly}
          />
        ),
      },
      {
        id: 'tariffEmpty',
        label: 'Тариф порожний',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].tariffEmpty`}
            name={`${fields.name}[${rowIndex}].tariffEmpty`}
            readOnly={readOnly}
          />
        ),
      }
    );

    return result;
  }, [
    disabled,
    fields.name,
    readOnly,
    showEmptyCostColumns,
    showLoadedCostColumns,
    unlockRow,
  ]);

  const items = useMemo(
    () => fields.value.map((item, index): ITableItem => ({ ...item, index })),
    [fields.value]
  );

  const getItemId = useCallback((item: ITableItem) => String(item.index), []);

  return (
    <>
      <VGrid>
        <Row>
          <ListTable
            columns={columns}
            getItemId={getItemId}
            items={items}
            maxHeight={500}
            selectedItems={selectedItems}
            selectionIsDisabled={disabled || readOnly}
            onSelectedItemsChange={setSelectedItems}
          />
        </Row>

        <Row>
          <Toolbar>
            <Button
              disabled={disabled || readOnly}
              text="Добавить ставку"
              onClick={() => {
                fields.push({
                  confirmedTransportationsCount: 0,
                  costEmpty: '',
                  costLoaded: '',
                  isLocked: false,
                  station: null,
                  tariffEmpty: '',
                  tariffLoaded: '',
                });
              }}
            />

            <Button
              disabled={disabled || readOnly || selectedItems.length === 0}
              text="Удалить выбранные ставки"
              onClick={() => {
                selectedItems
                  .map(Number)
                  .sort((a, b) => b - a)
                  .forEach(rowIndex => {
                    fields.remove(rowIndex);
                  });

                setSelectedItems([]);
              }}
            />
          </Toolbar>
        </Row>
      </VGrid>

      <Alert
        cancelButtonText="Отмена"
        confirmButtonText="Редактировать"
        intent={Intent.DANGER}
        isOpen={tryingToUnlockRowIndex != null}
        onCancel={() => {
          setTryingToUnlockRowIndex(null);
        }}
        onConfirm={() => {
          if (tryingToUnlockRowIndex != null) {
            unlockRow(tryingToUnlockRowIndex);
          }

          setTryingToUnlockRowIndex(null);
        }}
      >
        <H4>Внимание!</H4>

        {tryingToUnlockRowIndex != null && (
          <p>
            При изменении ставки изменятся{' '}
            {fields.value[tryingToUnlockRowIndex].confirmedTransportationsCount}{' '}
            подтверждённых отправок.
          </p>
        )}
      </Alert>
    </>
  );
}

import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { TableFieldErrors } from '_core/forms/tableFieldErrors';
import { intersection } from '_core/fp/intersection';
import { without } from '_core/fp/without';
import { indexByLastItem } from '_core/indexBy';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { InputMaskedForFinalForm } from '_core/inputs/inputMasked';
import { SelectForFinalForm } from '_core/inputs/select';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { plural } from '_core/strings/utils';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { Alert, Button, InputGroup, Intent } from '@blueprintjs/core';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { IPartnerInline } from 'partners/api';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import { partnerToOption } from 'partners/utils';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import {
  fetchRentWagonPurchaseList,
  RentWagonPurchaseListItem,
  RentWagonPurchaseState,
} from 'rentWagonPurchase/api';
import { ITechrunRate, techrunRateToOption } from 'techrunRates/types';
import { ITechrunRequest } from 'techrunRequests/types';
import { createWagonMatcher, parseWagonNumbers } from 'wagons/utils';

import { BatchRentWagonsPopover } from './batchRentWagonsPopover';
import { BatchWagonsPopover } from './batchWagonsPopover';
import { PurchaseRateCell } from './purchaseRateCell';
import { WagonStatesPopover } from './wagonStatesPopover';

export interface ITechrunRequestFormWagonItem {
  isFinished: boolean;
  owner: number | null;
  purchasePartner: string | null;
  purchaseRate: string | null;
  purchaseRent: string | null;
  wagon: string;
}

interface IProps {
  change: (field: string, value: unknown) => void;
  disabled: boolean;
  editingLocked: boolean | undefined;
  name: string;
  purchasePartners: IPartnerInline[];
  rentWagons: RentWagonPurchaseListItem[];
  sellRates: ITechrunRate[];
  techrunRequest: ITechrunRequest | undefined;
}

interface IWagonWithIndex {
  index: number;
  wagon: ITechrunRequestFormWagonItem;
}

export function WagonsField({
  change,
  disabled,
  editingLocked,
  name,
  purchasePartners,
  rentWagons,
  sellRates,
  techrunRequest,
}: IProps) {
  const { fields, meta } = useFieldArray<ITechrunRequestFormWagonItem>(name);
  const api = useApiClient();
  const toaster = useToaster();
  const [search, setSearch] = useState('');
  const [selectedIndices, setSelectedIndices] = useState<string[]>([]);

  const [purchaseRateAlertState, setPurchaseRateAlertState] = useState<{
    isOpen: boolean;
    value: string | null;
  }>({ isOpen: false, value: null });

  const wagonMatcher = useMemo(() => createWagonMatcher(search), [search]);

  const wagonsWithIndices = useMemo(() => {
    const result = fields
      .map(
        (_fieldName, index): IWagonWithIndex => ({
          index,
          wagon: fields.value[index],
        })
      )
      .filter(({ wagon }) => !wagon.wagon || wagonMatcher(wagon.wagon));

    let lastIndexToLookAt = result.length - 1;
    let i = 0;
    while (i <= lastIndexToLookAt) {
      const item = result[i];

      if (item.wagon.isFinished) {
        result.splice(i, 1);
        result.push(item);
        lastIndexToLookAt--;
      } else {
        i++;
      }
    }

    return result;
  }, [fields, wagonMatcher]);

  const indexMap = useMemo(
    () => wagonsWithIndices.map(({ index }) => index),
    [wagonsWithIndices]
  );

  const columns = useMemo(
    (): Array<ListTableColumn<IWagonWithIndex>> => [
      {
        id: 'isFinished',
        label: 'Перевозка завершена',
        defaultWidth: 36,
        renderCellContent: (_wagon, rowIndex) => (
          <CheckboxForFinalForm
            disabled={disabled || editingLocked}
            id={`${fields.name}[${indexMap[rowIndex]}].isFinished`}
            name={`${fields.name}[${indexMap[rowIndex]}].isFinished`}
            noBottomMargin
          />
        ),
      },
      {
        id: 'wagon',
        label: 'Вагон',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: ({ wagon }, rowIndex) => (
          <InputMaskedForFinalForm
            disabled={disabled || wagon.isFinished}
            id={`${fields.name}[${indexMap[rowIndex]}].wagon`}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            name={`${fields.name}[${indexMap[rowIndex]}].wagon`}
            placeholder="Номер вагона..."
            readOnly={editingLocked}
          />
        ),
      },
      {
        id: 'purchasePartner',
        label: 'Поставщик',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (
          { wagon: { isFinished, purchaseRent } },
          rowIndex
        ) => (
          <SelectForFinalForm
            disabled={
              disabled || isFinished || purchaseRent != null || editingLocked
            }
            fill
            id={`${fields.name}.[${indexMap[rowIndex]}].purchasePartner`}
            name={`${fields.name}.[${indexMap[rowIndex]}].purchasePartner`}
            options={purchasePartners.map(partnerToOption)}
            withEmptyOption
            onChange={() => {
              change(
                `${fields.name}.[${indexMap[rowIndex]}].purchaseRate`,
                null
              );
              change(
                `${fields.name}.[${indexMap[rowIndex]}].purchaseRent`,
                null
              );
            }}
          />
        ),
      },
      {
        id: 'purchaseRate',
        label: 'Ставка от поставщика',
        defaultWidth: 300,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (
          { wagon: { isFinished, purchasePartner, purchaseRent } },
          rowIndex
        ) => (
          <PurchaseRateCell
            change={change}
            disabled={
              disabled || editingLocked || isFinished || purchaseRent != null
            }
            parentFieldName={fields.name}
            purchasePartner={purchasePartner}
            rowIndex={indexMap[rowIndex]}
            onChange={(innerRowIndex, newRate) => {
              if (!techrunRequest && innerRowIndex === 0) {
                setPurchaseRateAlertState({
                  isOpen: true,
                  value: newRate,
                });
              }
            }}
          />
        ),
      },
      {
        id: 'purchaseRent',
        label: 'Аренда',
        defaultWidth: 300,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (
          { wagon: { isFinished, purchasePartner, purchaseRate, wagon } },
          rowIndex
        ) => (
          <SelectForFinalForm
            disabled={
              disabled || editingLocked || isFinished || purchaseRate != null
            }
            fill
            id={`${fields.name}[${indexMap[rowIndex]}].purchaseRent`}
            name={`${fields.name}[${indexMap[rowIndex]}].purchaseRent`}
            options={rentWagons
              .filter(
                rentWagon =>
                  rentWagon.wagon === wagon &&
                  String(rentWagon.supplier) === purchasePartner
              )
              .map(rentWagon => ({
                label: rentWagon.shortName,
                value: String(rentWagon.id),
              }))}
            withEmptyOption
            onChange={() => {
              change(
                `${fields.name}.[${indexMap[rowIndex]}].purchaseRate`,
                null
              );
            }}
          />
        ),
      },
      {
        id: 'sellRate',
        label: 'Ставка для клиента',
        defaultWidth: 300,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: ({ index }) => (
          <SelectForFinalForm
            disabled
            fill
            id={`sellRate[${index}]`}
            name="sellRate"
            options={sellRates.map(techrunRateToOption)}
          />
        ),
      },
      {
        id: 'owner',
        label: 'Собственник',
        defaultWidth: 300,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: ({ wagon }, rowIndex) => (
          <PartnersAutocompleteInFormGroupForFinalForm
            addToQuery={{ isSupplier: true }}
            disabled={disabled || wagon.isFinished}
            id={`${fields.name}[${indexMap[rowIndex]}].owner`}
            name={`${fields.name}[${indexMap[rowIndex]}].owner`}
            noBottomMargin
            readOnly={editingLocked}
          />
        ),
      },
    ],
    [
      change,
      disabled,
      editingLocked,
      fields.name,
      indexMap,
      purchasePartners,
      rentWagons,
      sellRates,
      techrunRequest,
    ]
  );

  const getItemId = useCallback(
    ({ index }: IWagonWithIndex) => String(index),
    []
  );

  return (
    <>
      <VGrid>
        <Row>
          <Grid>
            <Col span={3}>
              <InputGroup
                leftIcon="search"
                placeholder="Начните вводить номер вагона..."
                type="search"
                value={search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(event.target.value);
                }}
              />
            </Col>

            <Col span={5}>
              <Toolbar align="right">
                {techrunRequest && (
                  <WagonStatesPopover requestId={techrunRequest.id} />
                )}
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Toolbar>
            <Button
              disabled={disabled || editingLocked}
              text="Добавить"
              onClick={() => {
                fields.push({
                  isFinished: false,
                  owner: null,
                  purchasePartner: null,
                  purchaseRate: null,
                  purchaseRent: null,
                  wagon: '',
                });
              }}
            />

            <Button
              disabled={
                disabled || editingLocked || selectedIndices.length === 0
              }
              text="Удалить выбранные"
              onClick={() => {
                selectedIndices
                  .map(Number)
                  .sort((a, b) => b - a)
                  .forEach(rowIndex => {
                    fields.remove(rowIndex);
                  });

                setSelectedIndices([]);
              }}
            />

            <BatchWagonsPopover
              disabled={disabled || editingLocked}
              purchasePartners={purchasePartners}
              onSubmit={({ batch, purchasePartner, purchaseRate }) => {
                const parsedNumbers = parseWagonNumbers(batch);

                const notExistingNumbers = without(
                  fields.value.map(wagon => wagon.wagon),
                  parsedNumbers
                );

                notExistingNumbers.forEach(wagon => {
                  fields.push({
                    isFinished: false,
                    owner: null,
                    purchasePartner,
                    purchaseRate,
                    purchaseRent: null,
                    wagon,
                  });
                });

                const addedWagonCount = notExistingNumbers.length;
                const totalWagonCount = parsedNumbers.length;

                toaster.show({
                  intent: Intent.SUCCESS,
                  message: plural(addedWagonCount, [
                    () =>
                      `Добавлен ${addedWagonCount} вагон из ${totalWagonCount}`,
                    () =>
                      `Добавлено ${addedWagonCount} вагона из ${totalWagonCount}`,
                    () =>
                      `Добавлено ${addedWagonCount} вагонов из ${totalWagonCount}`,
                  ]),
                });
              }}
            />

            <BatchRentWagonsPopover
              disabled={disabled || editingLocked}
              onSubmit={async ({ batch }) => {
                const parsedNumbers = parseWagonNumbers(batch);

                const currentWagonNumbers = fields.value.map(
                  wagon => wagon.wagon
                );

                const existingNumbers = intersection(
                  parsedNumbers,
                  currentWagonNumbers
                );

                const notExistingNumbers = without(
                  currentWagonNumbers,
                  parsedNumbers
                );

                const response = await fetchAllPages(page =>
                  fetchRentWagonPurchaseList(api, {
                    page,
                    states: [RentWagonPurchaseState.Active],
                    wagonNumbers: notExistingNumbers,
                  })
                );

                let addedWagonCount = 0;
                const notAddedNumbers: string[] = [...existingNumbers];

                const rentWagonsByNumber = indexByLastItem(
                  response.results,
                  rentWagon => rentWagon.wagon
                );

                notExistingNumbers.forEach(number => {
                  const rentWagon = rentWagonsByNumber[number];

                  if (rentWagon) {
                    addedWagonCount++;

                    fields.push({
                      isFinished: false,
                      owner: null,
                      purchasePartner: String(rentWagon.supplier),
                      purchaseRate: null,
                      purchaseRent: String(rentWagon.id),
                      wagon: rentWagon.wagon,
                    });
                  } else {
                    notAddedNumbers.push(number);
                  }
                });

                const totalWagonCount = parsedNumbers.length;

                const notAddedMsg = notAddedNumbers.length
                  ? ` Вагоны, которые не были добавлены: ${notAddedNumbers.join(
                      ', '
                    )}`
                  : '';

                toaster.show({
                  timeout: 0,
                  intent: Intent.SUCCESS,
                  message: `${plural(addedWagonCount, [
                    () =>
                      `Добавлен ${addedWagonCount} вагон из ${totalWagonCount}`,
                    () =>
                      `Добавлено ${addedWagonCount} вагона из ${totalWagonCount}`,
                    () =>
                      `Добавлено ${addedWagonCount} вагонов из ${totalWagonCount}`,
                  ])}.${notAddedMsg}`,
                });
              }}
            />
          </Toolbar>
        </Row>

        <Row>
          <TableFieldErrors
            fieldLabels={[['wagon', 'Вагон']]}
            rowsErrors={meta.error || meta.submitError}
          />

          <ListTable
            columns={columns}
            getItemId={getItemId}
            items={wagonsWithIndices}
            maxHeight={500}
            selectedItems={selectedIndices}
            selectionIsDisabled={disabled || editingLocked}
            onSelectedItemsChange={setSelectedIndices}
          />
        </Row>
      </VGrid>

      <Alert
        cancelButtonText="Нет"
        confirmButtonText="Да"
        intent={Intent.DANGER}
        isOpen={purchaseRateAlertState.isOpen}
        onCancel={() => {
          setPurchaseRateAlertState(prevState => ({
            ...prevState,
            isOpen: false,
          }));
        }}
        onConfirm={() => {
          change(
            'wagons',
            fields.value.map(wagon => ({
              ...wagon,
              purchaseRate: purchaseRateAlertState.value,
            }))
          );

          setPurchaseRateAlertState(prevState => ({
            ...prevState,
            isOpen: false,
          }));
        }}
      >
        Применить ко всем вагонам?
      </Alert>
    </>
  );
}

import { CheckboxList } from '_core/inputs/checkboxList';
import { usePreference } from '_core/me/usePreference';
import { Toolbar } from '_core/toolbar';
import { useErpDispatch } from '_erp/redux';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import {
  fetchParksAlertsWidgetData,
  fetchTechrunRequestsDowntimesWidgetData,
  fetchWagonsAlertsWidgetData,
  fetchWagonsStatesWidgetData,
} from './redux';
import {
  getEnabledWidgetsInColumns,
  handleWidgetsDragAndDrop,
  IWidgetColumn,
  updateEnabledWidgetsInColumns,
} from './utils';
import { NewTransportationsWidget } from './widgets/newTransportationsWidget';
import { ParksAlertsWidget } from './widgets/parksAlerts';
import { TechrunRequestsDowntimesWidget } from './widgets/techrunRequestsDowntimes';
import { WagonsAlertsWidget } from './widgets/wagonsAlerts';
import { WagonsStatesWidget } from './widgets/wagonsStatesWidget';
import { Widget } from './widgets/widget';
import * as css from './widgetsScreen.module.css';

interface IWidget {
  component: React.ComponentType;
  title: string;
}

const widgets: Record<string, IWidget> = {
  newTransportationsWidget: {
    title: 'Мои новые отправки',
    component: NewTransportationsWidget,
  },

  wagonsStatesWidget: {
    title: 'Состояние вагонов из заявок техрейса',
    component: WagonsStatesWidget,
  },

  parksAlertsWidget: {
    title: 'Предупреждения по паркам',
    component: ParksAlertsWidget,
  },

  techrunRequestsDowntimesWidget: {
    title: 'Сверхнормативный простой',
    component: TechrunRequestsDowntimesWidget,
  },

  wagonsAlertsWidget: {
    title: 'Предупреждения по вагонам',
    component: WagonsAlertsWidget,
  },
};

const widgetSelectionDialogOptions = Object.entries(widgets).map(
  ([id, data]) => ({ label: data.title, value: id })
);

interface IWidgetSelectionDialogState {
  isOpen: boolean;
  selectedWidgets: string[];
}

export default function HomeWidgetsScreen() {
  const dispatch = useErpDispatch();

  React.useEffect(() => {
    dispatch(fetchParksAlertsWidgetData());
    dispatch(fetchTechrunRequestsDowntimesWidgetData());
    dispatch(fetchWagonsAlertsWidgetData());
    dispatch(fetchWagonsStatesWidgetData());
  }, [dispatch]);

  const [isDragging, setIsDragging] = React.useState(false);

  const [widgetColumns, setWidgetColumns] = usePreference<IWidgetColumn[]>([
    'home',
    'widgetColumns',
  ]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = ({ source: src, destination: dst }: DropResult) => {
    setIsDragging(false);

    if (!dst) {
      return;
    }

    setWidgetColumns(
      handleWidgetsDragAndDrop(
        widgetColumns,
        Number(src.droppableId),
        src.index,
        Number(dst.droppableId),
        dst.index
      )
    );
  };

  const [widgetSelectionDialogState, setWidgetSelectionDialogState] =
    React.useState<IWidgetSelectionDialogState | null>(null);

  const closeWidgetSelectionDialog = () => {
    setWidgetSelectionDialogState(
      prevState => prevState && { ...prevState, isOpen: false }
    );
  };

  const destroyWidgetSelectionDialog = () => {
    setWidgetSelectionDialogState(null);
  };

  const enabledWidgets = getEnabledWidgetsInColumns(widgetColumns);

  const openWidgetSelectionDialog = () => {
    setWidgetSelectionDialogState({
      isOpen: true,
      selectedWidgets: enabledWidgets,
    });
  };

  const applySelectedWidgets = () => {
    closeWidgetSelectionDialog();

    if (!widgetSelectionDialogState) {
      return;
    }

    setWidgetColumns(
      updateEnabledWidgetsInColumns(
        widgetColumns,
        widgetSelectionDialogState.selectedWidgets
      )
    );
  };

  return (
    <VGrid>
      <Row>
        <Toolbar valign="center">
          <Button text="Выбрать виджеты" onClick={openWidgetSelectionDialog} />

          <span>
            Выбрано: {enabledWidgets.length} из {Object.values(widgets).length}
          </span>
        </Toolbar>

        {widgetSelectionDialogState && (
          <Dialog
            isOpen={widgetSelectionDialogState.isOpen}
            title="Выбор отображаемых виджетов"
            onClose={closeWidgetSelectionDialog}
            onClosed={destroyWidgetSelectionDialog}
          >
            <div className={Classes.DIALOG_BODY}>
              <CheckboxList
                allOption
                options={widgetSelectionDialogOptions}
                value={widgetSelectionDialogState.selectedWidgets}
                onChange={newSelectedWidgets => {
                  setWidgetSelectionDialogState(
                    prevState =>
                      prevState && {
                        ...prevState,
                        selectedWidgets: newSelectedWidgets,
                      }
                  );
                }}
              />
            </div>

            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button text="Отмена" onClick={closeWidgetSelectionDialog} />

                <Button
                  intent={Intent.PRIMARY}
                  text="Применить"
                  onClick={applySelectedWidgets}
                />
              </div>
            </div>
          </Dialog>
        )}
      </Row>

      <Row>
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <Grid>
            {widgetColumns.map((column, index) => (
              <Col key={index} span={4}>
                <Droppable droppableId={String(index)}>
                  {(droppableProvided, droppableSnapshot) => (
                    <div
                      className={cx(css.widgetsColumn, {
                        [css.widgetsColumn_isDragging]: isDragging,
                        [css.widgetsColumn_isDraggingOver]:
                          droppableSnapshot.isDraggingOver,
                      })}
                      ref={droppableProvided.innerRef}
                    >
                      {column.enabledWidgets.map((widgetId, widgetIndex) => (
                        <Draggable
                          key={widgetId}
                          draggableId={widgetId}
                          index={widgetIndex}
                        >
                          {(draggableProvided, draggableSnapshot) => (
                            <Widget
                              draggableProps={draggableProvided.draggableProps}
                              dragHandleProps={
                                draggableProvided.dragHandleProps
                              }
                              isDragging={draggableSnapshot.isDragging}
                              ref={draggableProvided.innerRef}
                              title={widgets[widgetId].title}
                            >
                              {React.createElement(widgets[widgetId].component)}
                            </Widget>
                          )}
                        </Draggable>
                      ))}

                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            ))}
          </Grid>
        </DragDropContext>
      </Row>
    </VGrid>
  );
}

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { IShipmentInfoListItemSerialized } from './api';

export const ShipmentInfoAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<IShipmentInfoListItemSerialized, number>({
      baseEndpoint: '/shipment_info',
      placeholder: 'Начните вводить номер документа...',

      getLink: shipmentInfo => ({
        params: { id: String(shipmentInfo.id) },
        to: 'shipmentInfo.edit',
      }),

      itemToOption: shipmentInfo => ({
        label: shipmentInfo.name,
        value: shipmentInfo.id,
      }),
    })
  );

export const ShipmentInfoAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    ShipmentInfoAutocompleteInFormGroup
  );

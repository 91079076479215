import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { fetchRelated } from '_core/api/fetchRelated';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { Switch } from '_core/inputs/switch';
import { selectPermissions } from '_core/me/me';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToApiQueryParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { useErpSelector } from '_erp/redux';
import { Button, Intent } from '@blueprintjs/core';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import {
  IBank,
  IInvoice,
  InvoiceStatus,
  invoiceStatusOptions,
} from 'invoices/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersFilter } from 'partners/filter';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useRouteNode } from 'react-router5';

import {
  IApproveDialogFormValues,
  InvoicesApproveDialogForm,
} from './approveDialogForm';
import {
  IMarkPlannedDialogFormValues,
  InvoicesMarkPlannedDialogForm,
} from './markPlannedDialogForm';
import { InvoicesListTable, InvoicesListTableItem } from './table';

export default function InvoicesListRoute() {
  const { route, router } = useRouteNode('invoices');
  const api = useApiClient();
  const permissions = useErpSelector(state => selectPermissions(state));

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'created',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const { data, isFetching, refetch } = useAsyncData(
    [api, route, sorting],
    async () => {
      const statusesArray = route.params.statuses
        ? route.params.statuses.split(',')
        : Object.values(InvoiceStatus).filter(
            status => status !== InvoiceStatus.Paid
          );

      if (route.params.showPaid === 'True') {
        statusesArray.push(InvoiceStatus.Paid);
      }

      const [banksResponse, invoicesResponse] = await Promise.all([
        fetchAllPages(page =>
          api.get<ListResponse<IBank>>('/directories_banks', { page })
        ),

        api
          .get<ListResponse<IInvoice>>('/invoices', {
            ordering: sortingToApiQueryParam([sorting], snakeCase),
            page: route.params.page,
            pageSize: route.params.pageSize,
            partner: route.params.partner,
            statuses: statusesArray.length
              ? statusesArray.join(',')
              : undefined,
          })
          .then(async listResponse => ({
            meta: listResponse.meta,
            items: await (fetchRelated(
              api,
              {
                bank: '/directories_banks',
                contract: '/contracts_purchase',
                createdUser: '/accounts',
                partner: '/partners',
              },
              listResponse.results
            ) as unknown as Promise<InvoicesListTableItem[]>),
          })),
      ]);

      return {
        banks: banksResponse.results,
        currentPage: invoicesResponse.meta.currentPage,
        invoices: invoicesResponse.items,
        pageSize: invoicesResponse.meta.pageSize,
        totalPages: invoicesResponse.meta.totalPages,
      };
    }
  );

  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

  const lastDataRef = useRef(data);

  if (data !== lastDataRef.current) {
    setSelectedInvoices([]);
    lastDataRef.current = data;
  }

  const approveDialog = useDialog<{
    initialValues: IApproveDialogFormValues;
  }>();
  const markPlannedDialog = useDialog<{
    initialValues: IMarkPlannedDialogFormValues;
  }>();

  const [isSaving, setIsSaving] = useState(false);

  const copyFromParam =
    selectedInvoices.length !== 0 && data
      ? data.invoices.find(invoice =>
          selectedInvoices.includes(String(invoice.id))
        )?.id
      : undefined;

  const areBatchActionsDisabled = selectedInvoices.length === 0 || isSaving;

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ ordering: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const listParams = JSON.stringify(route.params);

  return (
    <>
      <FormattedTitle>Счета в оплату</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Toolbar align="right">
            <PartnersFilter
              initialValue={route.params.partner}
              onApply={partner => {
                applyFilterParams({ partner });
              }}
            />

            <CheckboxListFilter
              initialValue={route.params.statuses}
              label="Статусы"
              onApply={statuses => {
                applyFilterParams({ statuses });
              }}
              options={invoiceStatusOptions.filter(
                option => option.value !== InvoiceStatus.Paid
              )}
            />

            <LinkButton
              options={{ reload: true }}
              params={route.params}
              text="Обновить"
              to={route.name}
            />

            <LinkButton text="Сбросить фильтры" to={route.name} />

            <LinkButton
              intent={Intent.PRIMARY}
              params={{ listParams }}
              text="Добавить"
              to="invoices.create"
            />
          </Toolbar>
        </Row>

        <Row>
          <Grid>
            <Col>
              <Toolbar>
                <LinkButton
                  disabled={areBatchActionsDisabled}
                  icon="duplicate"
                  intent={Intent.SUCCESS}
                  params={{ copyFrom: copyFromParam }}
                  text="Копировать"
                  to="invoices.create"
                />

                <LinkButton
                  disabled={areBatchActionsDisabled}
                  icon="duplicate"
                  intent={Intent.SUCCESS}
                  params={{ copyFrom: copyFromParam, withFiles: 'True' }}
                  text="Копировать с приложениями"
                  to="invoices.create"
                />

                <Button
                  disabled={
                    areBatchActionsDisabled || !permissions.isFinanceManager
                  }
                  intent={Intent.SUCCESS}
                  text="Согласованы"
                  onClick={() => {
                    approveDialog.open({ initialValues: { bank: '' } });
                  }}
                />

                <Button
                  disabled={
                    areBatchActionsDisabled || !permissions.isFinanceDirector
                  }
                  intent={Intent.SUCCESS}
                  text="Отложить"
                  onClick={() => {
                    markPlannedDialog.open({
                      initialValues: { paymentPlanned: null },
                    });
                  }}
                />

                <Button
                  disabled={
                    areBatchActionsDisabled || !permissions.isFinanceDirector
                  }
                  intent={Intent.SUCCESS}
                  text="К оплате"
                  onClick={() => {
                    setIsSaving(true);

                    api
                      .post(
                        '/invoices/mark_for_payment',
                        {},
                        { query: { ids: selectedInvoices.join(',') } }
                      )
                      .then(refetch)
                      .then(() => {
                        setIsSaving(false);
                      });
                  }}
                />

                <Button
                  disabled={
                    areBatchActionsDisabled || !permissions.isAccountant
                  }
                  intent={Intent.SUCCESS}
                  text="Оплачены"
                  onClick={() => {
                    setIsSaving(true);

                    api
                      .post(
                        '/invoices/mark_paid',
                        {},
                        { query: { ids: selectedInvoices.join(',') } }
                      )
                      .then(refetch)
                      .then(() => {
                        setIsSaving(false);
                      });
                  }}
                />
              </Toolbar>
            </Col>

            <Col align="end">
              <Switch
                checked={route.params.showPaid === 'True'}
                label="Показывать оплаченные"
                onChange={showPaid => {
                  applyFilterParams({
                    showPaid: showPaid ? 'True' : undefined,
                  });
                }}
              />
            </Col>
          </Grid>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <InvoicesListTable
              isFetching={isFetching}
              invoices={data.invoices}
              lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
              listParams={listParams}
              selectedInvoices={selectedInvoices}
              sorting={sorting}
              onSelectedInvoicesChange={setSelectedInvoices}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>

        {approveDialog.state && (
          <InvoicesApproveDialogForm
            banks={data?.banks ?? []}
            initialValues={approveDialog.state.initialValues}
            isOpen={approveDialog.state.isOpen}
            onClose={approveDialog.close}
            onClosed={approveDialog.destroy}
            onSubmit={async values => {
              try {
                await api.post('/invoices/mark_approved', values, {
                  query: { ids: selectedInvoices.join(',') },
                });

                approveDialog.close();
                refetch();
                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось согласовать счета: Непредвиденная ошибка'
                );
              }
            }}
          />
        )}

        {markPlannedDialog.state && (
          <InvoicesMarkPlannedDialogForm
            initialValues={markPlannedDialog.state.initialValues}
            isOpen={markPlannedDialog.state.isOpen}
            onClose={markPlannedDialog.close}
            onClosed={markPlannedDialog.destroy}
            onSubmit={async ({ paymentPlanned }) => {
              try {
                await api.post(
                  '/invoices/mark_planned',
                  {
                    paymentPlanned: paymentPlanned
                      ? dayjs(paymentPlanned).format(DATE_FORMAT_API_DATE)
                      : null,
                  },
                  { query: { ids: selectedInvoices.join(',') } }
                );
                markPlannedDialog.close();
                refetch();
                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось изменить статус счетов: Непредвиденная ошибка'
                );
              }
            }}
          />
        )}

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>
    </>
  );
}

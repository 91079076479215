import { identity } from '_core/fp/identity';
import { FormGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useMemo } from 'react';
import { useField } from 'react-final-form';
import { createNumberMask } from 'text-mask-addons';

import { InputMasked } from './inputMasked';

const moneyMask = createNumberMask({
  allowDecimal: true,
  allowNegative: true,
  decimalSymbol: ',',
  prefix: '',
  thousandsSeparatorSymbol: ' ',
});

function cleanupMaskedValue(maskedValue: string): string {
  return maskedValue.replace(/ /g, '').replace(',', '.');
}

interface Props {
  autoFocus?: boolean;
  bold?: boolean;
  disabled?: boolean;
  editingLocked?: boolean;
  id?: string;
  isInvalid?: boolean;
  large?: boolean;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  type?: string;
  value: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (newValue: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export function InputMoney({
  autoFocus,
  bold,
  disabled,
  editingLocked,
  id,
  isInvalid,
  large,
  name,
  placeholder,
  readOnly,
  type,
  value,
  onBlur,
  onChange,
  onFocus,
}: Props) {
  const handleChange = useMemo(() => {
    if (!onChange) {
      return undefined;
    }

    return (newValue: string) => {
      onChange(cleanupMaskedValue(newValue));
    };
  }, [onChange]);

  return (
    <InputMasked
      autoFocus={autoFocus}
      bold={bold}
      disabled={disabled}
      editingLocked={editingLocked}
      id={id}
      isInvalid={isInvalid}
      large={large}
      mask={moneyMask}
      name={name}
      placeholder={placeholder}
      readOnly={readOnly}
      type={type}
      value={value.replace('.', ',')}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
    />
  );
}

export function InputMoneyForFinalForm({
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  ...otherProps
}: Omit<Props, 'error' | 'isInvalid' | 'value'> & {
  name: string;
}) {
  const { input, meta } = useField<string>(name, {
    // this identity fn is needed because by default final-form removes field
    // key completely when it's set to an empty string
    parse: identity,
  });

  const error = meta.error || meta.submitError;

  return (
    <InputMoney
      disabled={meta.submitting || disabled}
      isInvalid={meta.touched && Boolean(error)}
      name={input.name}
      onBlur={event => {
        input.onBlur(event);
        onBlur?.(event);
      }}
      onChange={newValue => {
        input.onChange(newValue);
        onChange?.(newValue);
      }}
      onFocus={event => {
        input.onFocus(event);
        onFocus?.(event);
      }}
      value={input.value}
      {...otherProps}
    />
  );
}

interface InFormGroupProps extends Props {
  error?: string;
  label?: string;
}

export function InputMoneyInFormGroup({
  autoFocus,
  bold,
  disabled,
  editingLocked,
  error,
  id,
  isInvalid,
  label,
  large,
  name,
  placeholder,
  readOnly,
  type,
  value,
  onBlur,
  onChange,
  onFocus,
}: InFormGroupProps) {
  const handleChange = useMemo(() => {
    if (!onChange) {
      return undefined;
    }

    return (newValue: string) => {
      onChange(cleanupMaskedValue(newValue));
    };
  }, [onChange]);

  return (
    <FormGroup
      helperText={isInvalid && error}
      intent={isInvalid ? Intent.DANGER : undefined}
      label={label}
    >
      <InputMasked
        autoFocus={autoFocus}
        bold={bold}
        disabled={disabled}
        editingLocked={editingLocked}
        id={id}
        isInvalid={isInvalid}
        large={large}
        mask={moneyMask}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        value={value.replace('.', ',')}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
      />
    </FormGroup>
  );
}

export function InputMoneyInFormGroupForFinalForm({
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  ...otherProps
}: Omit<InFormGroupProps, 'error' | 'isInvalid' | 'value'> & {
  name: string;
}) {
  const { input, meta } = useField<string>(name, {
    // this identity fn is needed because by default final-form removes field
    // key completely when it's set to an empty string
    parse: identity,
  });

  const error = meta.error || meta.submitError;

  return (
    <InputMoneyInFormGroup
      disabled={meta.submitting || disabled}
      error={error}
      isInvalid={meta.touched && Boolean(error)}
      name={input.name}
      onBlur={event => {
        input.onBlur(event);
        onBlur?.(event);
      }}
      onChange={newValue => {
        input.onChange(newValue);
        onChange?.(newValue);
      }}
      onFocus={event => {
        input.onFocus(event);
        onFocus?.(event);
      }}
      value={input.value}
      {...otherProps}
    />
  );
}

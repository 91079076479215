import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import { fetchUsers } from 'accounts/api';
import { Row, VGrid } from 'layout/contentLayout';
import { fetchPartner, IPartnerSerialized } from 'partners/api';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';

import { IPartnerFormValues, PartnersForm } from './form';

export default function PartnersEditRoute() {
  const { route, router } = useRouteNode('partners.edit');
  const navigationLock = useFormNavigationLock('partners.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    async () => {
      const [partner, workersResponse] = await Promise.all([
        fetchPartner(api, route.params.id),
        fetchAllPages(page => fetchUsers(api, { isEmployee: true, page })),
      ]);

      return {
        partner,
        workers: workersResponse.results,
      };
    }
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const persist = (values: IPartnerFormValues) =>
    api.put<IPartnerSerialized>(`/partners/${route.params.id}`, {
      ...values,
      manager: Number(values.manager),
      shortName: values.shortName.toUpperCase(),
    });

  const title = data.partner.fullName;

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не изменить контрагента: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Контрагенты"
              params={listParams}
              to="partners"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <PartnersForm
            initialValues={{
              ...data.partner,
              manager: String(data.partner.manager),
            }}
            navigationLock={navigationLock}
            partner={data.partner}
            workers={data.workers}
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('partners', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedPartner = await persist(values);
                updateData(
                  prevData =>
                    prevData && { ...prevData, partner: updatedPartner }
                );
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

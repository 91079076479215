import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Button, IconName, Intent } from '@blueprintjs/core';
import * as React from 'react';

export interface IConsignersDialogFormValues {
  email: string;
  legalAddress: string;
  name: string;
  note: string;
  okpo: string;
  partner: number | null;
  phone: string;
}

interface IProps {
  icon: IconName;
  initialValues: IConsignersDialogFormValues;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (values: IConsignersDialogFormValues) => Promise<void>;
}

export function ConsignersDialogForm({
  icon,
  initialValues,
  isOpen,
  title,
  onClose,
  onSubmit,
}: IProps) {
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          await onSubmit(values);
          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось сохранить грузоотправителя: Непредвиденная ошибка'
          );
        }
      }}
    >
      {({ error, handleSubmit, submitError, submitting }) => (
        <DialogForm
          icon={icon}
          isOpen={isOpen}
          isSubmitting={submitting}
          title={title}
          onClose={onClose}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormErrors error={error || submitError} />

            <FormGroupForFinalForm
              label="Наименование грузоотправителя"
              name="name"
            >
              <InputGroupForFinalForm autoFocus name="name" required />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Номер телефона" name="phone">
              <InputGroupForFinalForm name="phone" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Email" name="email">
              <InputGroupForFinalForm name="email" type="email" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Юридический адрес"
              name="legalAddress"
            >
              <InputGroupForFinalForm name="legalAddress" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="ОКПО" name="okpo">
              <InputGroupForFinalForm name="okpo" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Примечание" name="note">
              <InputMultipleForFinalForm name="note" rows={5} />
            </FormGroupForFinalForm>
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button disabled={submitting} text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Сохранить"
                type="submit"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

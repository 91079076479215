import * as React from 'react';
import invariant from 'tiny-invariant';

import { ApiClient } from './client';

const ApiClientContext = React.createContext<ApiClient | null>(null);

if (__DEV__) {
  ApiClientContext.displayName = 'ApiClientContext';
}

interface Props {
  api: ApiClient;
  children: React.ReactNode;
}

export function ApiClientProvider({ api, children }: Props) {
  return (
    <ApiClientContext.Provider value={api}>
      {children}
    </ApiClientContext.Provider>
  );
}

export function useApiClient(): ApiClient {
  const api = React.useContext(ApiClientContext);
  invariant(api, 'You must render ApiClientProvider higher in the tree');
  return api;
}

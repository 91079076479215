import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { LinkButton } from '_core/router5/linkButton';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Intent, NonIdealState } from '@blueprintjs/core';
import { IContractPurchase } from 'contractsPurchase/api';
import dayjs from 'dayjs';
import { DocumentStatusIndicator } from 'documents/statusIndicator';
import { DocumentStatus } from 'documents/types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { IRentProtocolSerialized, Payer } from 'rentProtocols/types';
import {
  createRentProtocolPurchase,
  fetchRentProtocolsPurchase,
  getRentProtocolPurchaseDocxDownloadLink,
  updateRentProtocolPurchase,
} from 'rentProtocolsPurchase/api';
import { getNormalVatRate } from 'vatRates/vatRates';
import { fetchAllWagonTypes } from 'wagonTypes/api';
import { getDefaultWagonType } from 'wagonTypes/utils';

import { DocumentListLayout } from './documentListLayout';
import {
  IRentProtocolFormValues,
  RentProtocolForm,
} from './rentProtocols/rentProtocolForm';

interface IProps {
  contract: IContractPurchase;
}

export function ContractsPurchaseViewRentProtocolsTab({ contract }: IProps) {
  const { route, router } = useRouteNode(
    'contracts.purchase.view.rentProtocols'
  );

  const navigationLock = useFormNavigationLock(route.name);
  const api = useApiClient();

  const { data, isFetching, updateData } = useAsyncData(
    [contract.id, api],
    async () => {
      const [protocols, wagonTypes] = await Promise.all([
        fetchAllPages(page =>
          fetchRentProtocolsPurchase(api, { contract: contract.id, page })
        ).then(response => response.results),

        fetchAllWagonTypes(api),
      ]);

      return {
        protocols,
        wagonTypes,
      };
    }
  );

  const defaultWagonType = data
    ? getDefaultWagonType(data.wagonTypes)
    : undefined;

  const selectedProtocolId = Number(route.params.protocolId);

  const selectedProtocol = isFinite(selectedProtocolId)
    ? data?.protocols.find(protocol => protocol.id === selectedProtocolId)
    : undefined;

  const copyFromProtocolId = Number(route.params.copyFrom);

  const copyFromProtocol = isFinite(copyFromProtocolId)
    ? data?.protocols.find(protocol => protocol.id === copyFromProtocolId)
    : undefined;

  const initialValues: IRentProtocolFormValues = selectedProtocol
    ? selectedProtocol
    : copyFromProtocol
    ? { ...copyFromProtocol, files: [] }
    : {
        date: dayjs().startOf('date').toDate(),
        dayRate: '',
        dayRateTotal: '',
        endDate: dayjs().endOf('year').startOf('date').toDate(),
        expensiveComponentsPayer: Payer.Lessor,
        files: [],
        fromRoutineRepairTariffPayer: Payer.Renter,
        fromScheduledRepairTariffPayer: Payer.Lessor,
        hasRentRateRoutineRepair: false,
        hasRentRateScheduledRepair: false,
        note: '',
        number: '',
        partnerSign: contract.partnerSign,
        receiveTariffPayer: Payer.Lessor,
        returnTariffPayer: Payer.Renter,
        routineRepairPayer: Payer.Renter,
        scheduledRepairPayer: Payer.Lessor,
        signerAuthority: '',
        startDate: dayjs().startOf('date').toDate(),
        startRentRateRoutineRepair: null,
        startRentRateScheduledRepair: null,
        status: DocumentStatus.Created,
        stopRentRateRoutineRepair: null,
        stopRentRateScheduledRepair: null,
        toRoutineRepairTariffPayer: Payer.Renter,
        toScheduledRepairTariffPayer: Payer.Lessor,
        vatRate: getNormalVatRate(),
        wagonType: defaultWagonType?.id ?? null,
      };

  const [editingLocked, setEditingLocked] = useState(selectedProtocol != null);

  useEffect(() => {
    setEditingLocked(selectedProtocol != null);
  }, [selectedProtocol]);

  const isCreateRoute =
    route.name === 'contracts.purchase.view.rentProtocols.create';

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <div>
      <Toolbar align="right">
        <LinkButton
          intent={Intent.PRIMARY}
          params={{
            ...route.params,
            copyFrom: undefined,
            protocolId: undefined,
          }}
          text="Добавить"
          to="contracts.purchase.view.rentProtocols.create"
        />
      </Toolbar>

      {!isCreateRoute && data.protocols.length === 0 ? (
        <NonIdealState
          icon="list"
          description="Вы можете создать протокол аренды с помощью кнопки выше"
          title="У договора нет протоколов аренды"
        />
      ) : (
        <DocumentListLayout
          list={
            <Table
              emptyMessage="У договора нет протоколов аренды"
              getRowProps={protocol => ({
                selected: selectedProtocol === protocol,
              })}
              isFetching={isFetching}
              items={data.protocols}
            >
              <Column
                width={150}
                id="numberAndDate"
                label="Наименование"
                cell={(protocol: IRentProtocolSerialized) => (
                  <LinkCell
                    params={{
                      ...route.params,
                      protocolId: protocol.id,
                    }}
                    to="contracts.purchase.view.rentProtocols.view"
                  >
                    {protocol.protocolName}
                  </LinkCell>
                )}
              />

              <Column
                width={80}
                id="status"
                label="Статус"
                cell={(protocol: IRentProtocolSerialized) => (
                  <DocumentStatusIndicator status={protocol.status} />
                )}
              />
            </Table>
          }
          form={
            !selectedProtocol && !isCreateRoute ? (
              <NonIdealState
                icon="select"
                description="Выберите протокол в списке слева или добавьте новое"
                title="Протоколов не выбрано"
              />
            ) : (
              <RentProtocolForm
                docxLink={
                  selectedProtocol
                    ? getRentProtocolPurchaseDocxDownloadLink(
                        api,
                        selectedProtocol.id
                      )
                    : undefined
                }
                editingLocked={editingLocked}
                initialValues={initialValues}
                navigationLock={navigationLock}
                protocol={selectedProtocol}
                wagonTypes={data.wagonTypes}
                onCancelEditing={
                  editingLocked || isCreateRoute
                    ? undefined
                    : () => {
                        setEditingLocked(true);
                      }
                }
                onDuplicate={
                  editingLocked && selectedProtocol
                    ? () => {
                        router.navigate(
                          'contracts.purchase.view.rentProtocols.create',
                          {
                            id: route.params.id,
                            copyFrom: selectedProtocol.id,
                            listParams: route.params.listParams,
                          }
                        );
                      }
                    : undefined
                }
                onStartEditing={
                  editingLocked
                    ? () => {
                        if (
                          // eslint-disable-next-line no-alert
                          window.confirm(
                            'Вы собираетесь изменить сохранённый протокол аренды'
                          )
                        ) {
                          setEditingLocked(false);
                        }
                      }
                    : undefined
                }
                onSubmit={async values => {
                  try {
                    if (selectedProtocol == null) {
                      const newProtocol = await createRentProtocolPurchase(
                        api,
                        { ...values, contract: contract.id }
                      );

                      updateData(
                        prevData =>
                          prevData && {
                            ...prevData,
                            protocols: [newProtocol].concat(prevData.protocols),
                          }
                      );

                      navigationLock.unlock();

                      router.navigate(
                        'contracts.purchase.view.rentProtocols.view',
                        {
                          ...route.params,
                          protocolId: newProtocol.id,
                        }
                      );
                    } else {
                      const updatedProtocol = await updateRentProtocolPurchase(
                        api,
                        selectedProtocol.id,
                        { ...values, contract: contract.id }
                      );

                      updateData(
                        prevData =>
                          prevData && {
                            ...prevData,
                            protocols: prevData.protocols.map(protocol =>
                              protocol.id === updatedProtocol.id
                                ? updatedProtocol
                                : protocol
                            ),
                          }
                      );
                    }

                    setEditingLocked(true);

                    return undefined;
                  } catch (err) {
                    return submissionErrorsFromApiError(
                      err,
                      selectedProtocol
                        ? 'Не удалось изменить протокол аренды: Непредвиденная ошибка'
                        : 'Не удалось создать протокол аренды: Непредвиденная ошибка'
                    );
                  }
                }}
              />
            )
          }
        />
      )}
    </div>
  );
}

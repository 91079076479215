import { Dialog, IconName } from '@blueprintjs/core';
import * as React from 'react';

import { BaseForm } from './baseForm';

interface IProps {
  children?: React.ReactNode;
  icon?: IconName;
  isOpen: boolean;
  isSubmitting: boolean;
  style?: React.CSSProperties;
  title: string;
  onClose?: () => void;
  onClosed?: () => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export function DialogForm({
  children,
  icon,
  isOpen,
  isSubmitting,
  style,
  title,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  const handleSubmit = React.useCallback(
    event => {
      if (event.target === event.currentTarget) {
        onSubmit(event);
      }
    },

    [onSubmit]
  );

  return (
    <Dialog
      canEscapeKeyClose={!isSubmitting}
      canOutsideClickClose={!isSubmitting}
      icon={icon}
      isCloseButtonShown={!isSubmitting}
      isOpen={isOpen}
      style={style}
      title={title}
      onClose={onClose}
      onClosed={onClosed}
    >
      <BaseForm onSubmit={handleSubmit}>{children}</BaseForm>
    </Dialog>
  );
}

import { useApiClient } from '_core/api/context';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import * as React from 'react';
import { useRouter } from 'react-router5';
import { IWagonSerialized, WAGONS_BASE_ENDPOINT } from 'wagons/api';

import { IWagonFormValues, WagonsForm } from '../form';

interface IProps {
  listParams: Record<string, string | undefined>;
  updateWagonData: (updatedWagon: IWagonSerialized) => void;
  wagon: IWagonSerialized;
}

export function WagonsEditMainTab({
  listParams,
  updateWagonData,
  wagon,
}: IProps) {
  const router = useRouter();
  const navigationLock = useFormNavigationLock('wagons.edit.main');
  const api = useApiClient();

  const persist = ({
    capacity,
    model,
    tara,
    ...otherValues
  }: IWagonFormValues) =>
    api.put<IWagonSerialized>(`${WAGONS_BASE_ENDPOINT}/${wagon.number}`, {
      capacity:
        capacity && isFinite(Number(capacity)) ? Number(capacity) : null,
      model: model || null,
      tara: tara && isFinite(Number(tara)) ? Number(tara) : null,
      ...otherValues,
    });

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось изменить вагон: Непредвиденная ошибка'
    );
  }

  return (
    <WagonsForm
      initialValues={{
        ...wagon,
        capacity: wagon.capacity?.toString() ?? '',
        model: wagon.model ?? '',
        owner: wagon.owner && wagon.owner.id,
        tara: wagon.tara?.toString() ?? '',
      }}
      navigationLock={navigationLock}
      wagon={wagon}
      onSave={async values => {
        try {
          await persist(values);
          navigationLock.unlock();
          router.navigate('wagons', listParams);
          return undefined;
        } catch (err) {
          return handleSavingError(err);
        }
      }}
      onSaveAndContinue={async values => {
        try {
          const updatedWagon = await persist(values);
          updateWagonData(updatedWagon);
          return undefined;
        } catch (err) {
          return handleSavingError(err);
        }
      }}
    />
  );
}

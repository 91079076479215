import { Select } from '_core/inputs/select';
import { deprecatedFilterInPopover } from '_deprecated/filterInPopover';
import { FormGroup } from '@blueprintjs/core';
import * as React from 'react';

import * as css from './distanceFilter.module.css';

export const DistanceFilter = deprecatedFilterInPopover<
  string | undefined,
  {},
  string
>({
  clearValue: undefined,
  iconName: 'filter',
  label: 'Расст. до ст. назначения',
  extractInitialValue: ({ initialValue }) => initialValue || '',
  transformOutputValue: value => value || undefined,
})(function DistanceFilter({ onChange, value }) {
  return (
    <div className={css.root}>
      <FormGroup label="Расстояние до станции назначения">
        <Select
          fill
          options={[
            { label: '<выберите расстояние из списка>', value: '' },
            { label: '<300км', value: '300' },
            { label: '<500км', value: '500' },
            { label: '<1000км', value: '1000' },
          ]}
          value={value}
          onChange={onChange}
        />
      </FormGroup>
    </div>
  );
});

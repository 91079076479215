import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { selectIsFavoritePark, toggleParkFavoriteStatus } from '_core/me/me';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import {
  TabsRouteNode,
  TabsRouteNodeExpander,
  TabsRouteNodeTab,
} from '_core/router5/tabsRouteNode';
import { useToaster } from '_core/toaster/toasterContext';
import { ErpState } from '_erp/redux';
import {
  createErpAsyncThunk,
  useErpDispatch,
  useErpSelector,
} from '_erp/redux';
import { Button, Intent } from '@blueprintjs/core';
import { createSelector, createSlice, unwrapResult } from '@reduxjs/toolkit';
import { formatContactName } from 'contacts/utils';
import {
  deletePark,
  fetchPark,
  IParkSerialized,
  Park,
  ParkSubscriber,
  sendMailToParkSubscribers,
  updatePark,
} from 'parks/api';
import * as React from 'react';
import { useRouteNode } from 'react-router5';

import { ParksEditDislocationsTab } from './editDislocationsTab';
import { ParksEditDowntimesTab } from './editDowntimesTab';
import {
  IEditMailingTabFormValues,
  ParksEditMailingTab,
} from './editMailingTab';
import { ParksEditMainTab } from './editMainTab';
import { ParksEditRepairsTab } from './editRepairsTab';
import { ParksFormRouteWrapper } from './formRouteWrapper';

const fetchParkAction = createErpAsyncThunk(
  'parks/edit/fetchPark',
  (parkId: number, { extra: { api } }) => fetchPark(api, parkId)
);

const updateParkAction = createErpAsyncThunk(
  'parks/edit/updatePark',
  async (
    values: {
      allTechrunRequestWagons?: boolean;
      dailySchedules?: number[];
      expeditionRequests?: number[];
      isMailingActive?: boolean;
      name?: string;
      subscribers?: ParkSubscriber[];
      techrunRequests?: number[];
      template?: number | null;
      wagons?: string[];
      weekendSchedules?: number[];
    },
    { extra: { api }, getState }
  ) => {
    const { park } = getState().parks.edit;

    return park && (await updatePark(api, park.id, { ...park, ...values }));
  }
);

const deleteParkAction = createErpAsyncThunk(
  'parks/edit/deletePark',
  (parkId: number, { extra: { api } }) => deletePark(api, parkId)
);

const sendMailToParkSubscribersAction = createErpAsyncThunk(
  'parks/edit/sendMailToParkSubscribers',
  async (subscriberIds: number[], { dispatch, extra: { api }, getState }) => {
    const { park } = getState().parks.edit;

    if (!park) {
      return;
    }

    await sendMailToParkSubscribers(api, park.id, subscriberIds);
    await dispatch(fetchParkAction(park.id));
  }
);

const initialState: {
  fetchingError: unknown;
  isReady: boolean;
  park: IParkSerialized | null;
} = {
  fetchingError: null,
  isReady: false,
  park: null,
};

export const { reducer: parksEditRouteReducer } = createSlice({
  name: 'parks/edit',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchParkAction.pending, (state, { meta }) => {
        if (state.park && state.park.id !== meta.arg) {
          state.fetchingError = null;
          state.isReady = false;
          state.park = null;
        }
      })
      .addCase(fetchParkAction.fulfilled, (state, { payload }) => {
        state.fetchingError = null;
        state.isReady = true;
        state.park = payload;
      })
      .addCase(fetchParkAction.rejected, (state, { error }) => {
        state.fetchingError = error;
        state.isReady = true;
        state.park = null;
      })
      .addCase(updateParkAction.fulfilled, (state, { payload }) => {
        state.park = payload;
      });
  },
});

const selectPark = createSelector(
  [(state: ErpState) => state.parks.edit.park],
  park => park && Park.fromJSON(park)
);

export default function ParksEditRoute() {
  const toaster = useToaster();
  const { route, router } = useRouteNode('parks.edit');
  const navigationLock = useFormNavigationLock(route.name);
  const parkId = Number(route.params.id);
  const dispatch = useErpDispatch();

  const fetchingError = useErpSelector(state => state.parks.edit.fetchingError);
  const isReady = useErpSelector(state => state.parks.edit.isReady);
  const park = useErpSelector(selectPark);

  const isFavorite = useErpSelector(state =>
    selectIsFavoritePark(parkId, state)
  );

  const listParams = React.useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  React.useEffect(() => {
    dispatch(fetchParkAction(parkId));
  }, [dispatch, parkId]);

  if (!park) {
    return isReady ? (
      <GenericErrorMessage error={fetchingError} />
    ) : (
      <CenteredSpinner />
    );
  }

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось изменить парк: Непредвиденная ошибка'
    );
  }

  function serializeEditMailingTabFormValues({
    dailySchedules,
    template,
    weekendSchedules,
    ...otherValues
  }: IEditMailingTabFormValues) {
    return {
      ...otherValues,
      dailySchedules: dailySchedules.map(Number),
      template: template == null ? null : Number(template),
      weekendSchedules: weekendSchedules.map(Number),
    };
  }

  return (
    <ParksFormRouteWrapper listParams={listParams} title={park.name}>
      <TabsRouteNode
        nodeName="parks.edit"
        preserveRouteParams={['id', 'listParams']}
      >
        <TabsRouteNodeTab id="main" title="Основные данные">
          <ParksEditMainTab
            navigationLock={navigationLock}
            park={park}
            onRemove={async () => {
              // eslint-disable-next-line no-alert
              if (window.confirm('Вы уверены что хотите удалить парк?')) {
                try {
                  unwrapResult(await dispatch(deleteParkAction(park.id)));
                  navigationLock.unlock();
                  router.navigate('parks', listParams);
                } catch (err) {
                  return submissionErrorsFromApiError(
                    err,
                    'Не удалось удалить парк: Непредвиденная ошибка'
                  );
                }
              }

              return undefined;
            }}
            onSave={async values => {
              try {
                unwrapResult(await dispatch(updateParkAction(values)));
                navigationLock.unlock();
                router.navigate('parks', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                unwrapResult(await dispatch(updateParkAction(values)));
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </TabsRouteNodeTab>

        <TabsRouteNodeTab id="mailing" title="Рассылка">
          <ParksEditMailingTab
            navigationLock={navigationLock}
            park={park}
            onMailSend={async contacts => {
              const toastKey = toaster.show({
                icon: 'envelope',
                intent: Intent.PRIMARY,
                message:
                  contacts.length === 1
                    ? `Отправка письма контакту ${formatContactName(
                        contacts[0],
                        {
                          includeEmail: true,
                        }
                      )}...`
                    : `Отправка писем выбранным контактам...`,
                timeout: 0,
              });

              try {
                unwrapResult(
                  await dispatch(
                    sendMailToParkSubscribersAction(
                      contacts.map(contact => contact.id)
                    )
                  )
                );

                toaster.show(
                  {
                    icon: 'envelope',
                    intent: Intent.SUCCESS,
                    message:
                      contacts.length === 1
                        ? 'Письмо отправлено'
                        : 'Письма отправлены',
                    timeout: 0,
                  },
                  toastKey
                );
              } catch (err) {
                toaster.show(
                  {
                    icon: 'error',
                    intent: Intent.DANGER,
                    message:
                      contacts.length === 1
                        ? 'Не удалось отправить письмо: Непредвиденная ошибка'
                        : 'Не удалось отправить письма: Непредвиденная ошибка',
                    timeout: 0,
                  },
                  toastKey
                );

                throw err;
              }
            }}
            onSave={async values => {
              try {
                unwrapResult(
                  await dispatch(
                    updateParkAction(serializeEditMailingTabFormValues(values))
                  )
                );
                navigationLock.unlock();
                router.navigate('parks', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                unwrapResult(
                  await dispatch(
                    updateParkAction(serializeEditMailingTabFormValues(values))
                  )
                );
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </TabsRouteNodeTab>

        <TabsRouteNodeTab id="dislocations" title="Дислокации">
          <ParksEditDislocationsTab park={park} />
        </TabsRouteNodeTab>

        <TabsRouteNodeTab id="repairs" title="Ремонты">
          <ParksEditRepairsTab />
        </TabsRouteNodeTab>

        <TabsRouteNodeTab
          id="downtimes"
          params={{ downtimeDurationGreater: '3' }}
          title="Простои"
        >
          <ParksEditDowntimesTab />
        </TabsRouteNodeTab>

        <TabsRouteNodeExpander />

        <Button
          icon={isFavorite ? 'star' : 'star-empty'}
          intent={Intent.SUCCESS}
          large
          minimal
          onClick={async () => {
            try {
              await dispatch(toggleParkFavoriteStatus(parkId));
            } catch (err) {
              toaster.show({
                icon: 'error',
                intent: Intent.DANGER,
                message: 'Не удалось изменить статус избранности парка',
              });

              throw err;
            }
          }}
        />
      </TabsRouteNode>
    </ParksFormRouteWrapper>
  );
}

import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TabsRouteNode, TabsRouteNodeTab } from '_core/router5/tabsRouteNode';
import { useAsyncData } from '_core/useAsyncData';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import {
  fetchSuppliersDocument,
  ISuppliersDocumentDetail,
} from 'suppliersDocuments/api';

import { SuppliersDocumentsFromFileEditExpensesTab } from './editExpensesTab';
import { SuppliersDocumentsFromFileEditMainTab } from './editMainTab';

export default function SuppliersDocumentsFromFileEditRoute() {
  const { route } = useRouteNode('suppliersDocumentsFromFile.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    () => fetchSuppliersDocument(api, route.params.id)
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const updateSuppliersDocumentData = useCallback(
    (updatedData: ISuppliersDocumentDetail) => updateData(() => updatedData),
    [updateData]
  );

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    )
  ) : (
    <>
      <FormattedTitle>{data.number}</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Документы из РЖД"
              params={listParams}
              to="suppliersDocumentsFromFile"
            />

            <BreadcrumbsItem label={data.number} />
          </Breadcrumbs>
        </Row>

        <Row stretch>
          <TabsRouteNode
            nodeName="suppliersDocumentsFromFile.edit"
            preserveRouteParams={['id', 'listParams']}
          >
            <TabsRouteNodeTab id="main" title="Основные данные">
              <SuppliersDocumentsFromFileEditMainTab
                listParams={listParams}
                suppliersDocument={data}
                updateSuppliersDocumentData={updateSuppliersDocumentData}
              />
            </TabsRouteNodeTab>

            <TabsRouteNodeTab id="expenses" title="Перечень расходов">
              <SuppliersDocumentsFromFileEditExpensesTab
                suppliersDocument={data}
                updateSuppliersDocumentData={updateSuppliersDocumentData}
              />
            </TabsRouteNodeTab>
          </TabsRouteNode>
        </Row>
      </VGrid>
    </>
  );
}

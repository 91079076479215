import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import dayjs from 'dayjs';
import {
  createExpeditionRequest,
  fetchExpeditionRequest,
  fetchExpeditionRequests,
  IExpeditionRequest,
} from 'expeditionRequests/api';
import {
  ExpeditionRequestStatus,
  IExpeditionRequestSerialized,
} from 'expeditionRequests/types';
import { ExpeditionStationType } from 'expeditions/types';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { WagonsActiveRequestsAlert } from 'requests/wagonsActiveRequestsAlert';
import { fetchAllWagonTypes } from 'wagonTypes/api';
import { getDefaultWagonType } from 'wagonTypes/utils';

import {
  ExpeditionRequestForm,
  IExpeditionRequestFormValues,
} from './form/form';
import { ExpeditionRequestFormRouteWrapper } from './formRouteWrapper';

export default function ExpeditionRequestsCreateRoute() {
  const { route, router } = useRouteNode('expeditions.requests.create');
  const navigationLock = useFormNavigationLock('expeditions.requests.create');
  const api = useApiClient();

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const copyFromRequest = useAsyncData([api, route.params.copyFrom], () => {
    if (route.params.copyFrom != null) {
      return fetchExpeditionRequest(api, route.params.copyFrom);
    }

    return Promise.resolve(null);
  });

  const isDuplicating = route.params.copyFrom != null;

  const wagonTypesRequest = useAsyncData([api], () => fetchAllWagonTypes(api));

  const initialValues = useMemo((): IExpeditionRequestFormValues => {
    const startDate = dayjs(new Date()).startOf('day').toDate();
    const endDate = dayjs(startDate).endOf('month').startOf('day').toDate();

    if (isDuplicating && copyFromRequest.data) {
      return {
        endDate,
        name: copyFromRequest.data.name,
        number: '',
        partner: copyFromRequest.data.partner,
        sellProtocol: String(copyFromRequest.data.sellProtocol),
        startDate,
        station: copyFromRequest.data.station,
        stationType: copyFromRequest.data.stationType,
        status: copyFromRequest.data.status,
        trackRepair: copyFromRequest.data.trackRepair,
        wagons: copyFromRequest.data.wagons,
        wagonType: String(copyFromRequest.data.wagonType),
      };
    }

    const defaultWagonType = getDefaultWagonType(wagonTypesRequest.data);

    return {
      endDate,
      name: '',
      number: '',
      partner: null,
      sellProtocol: null,
      startDate,
      stationType: ExpeditionStationType.Any,
      station: null,
      status: ExpeditionRequestStatus.Created,
      trackRepair: false,
      wagons: [],
      wagonType: defaultWagonType ? String(defaultWagonType.id) : null,
    };
  }, [copyFromRequest.data, isDuplicating, wagonTypesRequest.data]);

  const [alertState, setAlertState] = useState<{
    activeRequestsWithAddedWagons: IExpeditionRequestSerialized[];
    addedWagons: string[];
    isOpen: boolean;
  } | null>(null);

  const createdRequestRef = useRef<IExpeditionRequest | null>(null);

  function navigateToCreatedRequest() {
    const createdRequest = createdRequestRef.current;

    if (!createdRequest) {
      return;
    }

    navigationLock.unlock();

    router.navigate('expeditions.requests.view', {
      id: String(createdRequest.id),
      listParams: route.params.listParams,
    });
  }

  if ((isDuplicating && !copyFromRequest.data) || !wagonTypesRequest.data) {
    return copyFromRequest.isFetching || wagonTypesRequest.isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    );
  }

  return (
    <ExpeditionRequestFormRouteWrapper
      listRoute={{ name: 'expeditions.requests', params: listParams }}
      title="Новая заявка"
    >
      <ExpeditionRequestForm
        initialValues={initialValues}
        navigationLock={navigationLock}
        wagonTypes={wagonTypesRequest.data}
        onSubmit={async values => {
          try {
            const savedExpeditionRequest = await createExpeditionRequest(api, {
              ...values,
              parks: [],
              sellProtocol: values.sellProtocol
                ? Number(values.sellProtocol)
                : null,
              wagonType: values.wagonType ? Number(values.wagonType) : null,
            });

            createdRequestRef.current = savedExpeditionRequest;

            const addedWagons = values.wagons.map(wagon => wagon.wagon);

            if (addedWagons.length) {
              const response = await fetchAllPages(page =>
                fetchExpeditionRequests(api, {
                  forDate: Date.now(),
                  hasWagons: addedWagons,
                  page,
                  statuses: [
                    ExpeditionRequestStatus.Active,
                    ExpeditionRequestStatus.Created,
                  ],
                })
              );

              const activeRequestsWithAddedWagons = response.results;

              if (activeRequestsWithAddedWagons.length !== 0) {
                setAlertState({
                  activeRequestsWithAddedWagons,
                  addedWagons,
                  isOpen: true,
                });
                return;
              }
            }

            navigateToCreatedRequest();

            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось создать заявку: Непредвиденная ошибка'
            );
          }
        }}
      />

      {alertState && (
        <WagonsActiveRequestsAlert
          activeRequestsWithAddedWagons={
            alertState.activeRequestsWithAddedWagons
          }
          addedWagons={alertState.addedWagons}
          isOpen={alertState.isOpen}
          itemRoute="expeditions.requests.view"
          onClosed={() => {
            navigateToCreatedRequest();
          }}
          onConfirm={() => {
            setAlertState(
              prevState =>
                prevState && {
                  ...prevState,
                  isOpen: false,
                }
            );
          }}
        />
      )}
    </ExpeditionRequestFormRouteWrapper>
  );
}

import { DATE_FORMAT_DATE, DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { ListTableColumn } from '_core/react-window/listTable';
import { TreeTableColumn } from '_core/react-window/treeTable';
import { createListToTreeTableColumnAdapter } from '_core/react-window/utils';
import { EntityLinkWithFilter } from '_core/router5/entityLinkWithFilter';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import dayjs from 'dayjs';
import { DocumentStatusIndicator } from 'documents/statusIndicator';
import { getDocumentStatusLabel } from 'documents/types';
import {
  DocumentsStatusesDocumentType,
  IDocumentsStatusesItem,
} from 'documentsStatuses/api';
import { getDocumentLink } from 'documentsStatuses/utils';
import * as React from 'react';

import * as css from './columns.module.css';

export interface IDocumentsStatusesTableItem
  extends Omit<IDocumentsStatusesItem, 'author'> {
  author: IUser | null;
}

const statusColumn: ListTableColumn<IDocumentsStatusesTableItem> = {
  id: 'status',
  label: 'Статус',
  defaultWidth: 220,
  renderCellContent: document => (
    <div className={css.statusCell}>
      <DocumentStatusIndicator status={document.status} />

      <Ellipsis className={css.statusCellLabel} component="span">
        {getDocumentStatusLabel(document.status)}
      </Ellipsis>
    </div>
  ),
};

const createdColumn: ListTableColumn<IDocumentsStatusesTableItem> = {
  id: 'created',
  label: 'Дата создания',
  defaultWidth: 120,
  copyCellContent: document =>
    dayjs(document.created).format(DATE_FORMAT_DATETIME),
  renderCellContent: document => (
    <Ellipsis component="span">
      {dayjs(document.created).format(DATE_FORMAT_DATETIME)}
    </Ellipsis>
  ),
};

const documentDateColumn: ListTableColumn<IDocumentsStatusesTableItem> = {
  id: 'documentDate',
  label: 'Дата документа',
  defaultWidth: 80,
  copyCellContent: document =>
    dayjs(document.documentDate).format(DATE_FORMAT_DATE),
  renderCellContent: document => (
    <Ellipsis component="span">
      {dayjs(document.documentDate).format(DATE_FORMAT_DATE)}
    </Ellipsis>
  ),
};

const authorColumn: ListTableColumn<IDocumentsStatusesTableItem> = {
  id: 'author',
  label: 'Автор',
  defaultWidth: 150,
  copyCellContent: document =>
    document.author ? formatUserName(document.author) : '',
  renderCellContent: document =>
    document.author ? (
      <Ellipsis component="span">{formatUserName(document.author)}</Ellipsis>
    ) : null,
};

export function getOurDocumentsListTableColumns({
  generateFilterLink,
}: {
  generateFilterLink: (params: Record<string, string | undefined>) => {
    params: Record<string, string | undefined>;
    to: string;
  };
}): Array<ListTableColumn<IDocumentsStatusesTableItem>> {
  const partnerColumn: ListTableColumn<IDocumentsStatusesTableItem> = {
    id: 'partner',
    label: 'Контрагент',
    defaultWidth: 280,
    copyCellContent: document => document.partner.shortName,
    renderCellContent: document => (
      <EntityLinkWithFilter
        entityLink={{
          params: { id: String(document.partner.id) },
          to: 'partners.edit',
        }}
        filterLink={generateFilterLink({
          partner: String(document.partner.id),
        })}
        text={document.partner.shortName}
      />
    ),
  };

  const documentTypeRusColumn: ListTableColumn<IDocumentsStatusesTableItem> = {
    id: 'documentTypeRus',
    label: 'Тип документа',
    defaultWidth: 200,
    copyCellContent: document => document.documentTypeRus,
    renderCellContent: document => (
      <Ellipsis component="span">{document.documentTypeRus}</Ellipsis>
    ),
  };

  const documentColumn: ListTableColumn<IDocumentsStatusesTableItem> = {
    id: 'document',
    label: 'Документ',
    defaultWidth: 560,
    copyCellContent: document => document.documentName,
    renderCellContent: document => (
      <Link {...getDocumentLink(document)} rel="noopener" target="_blank">
        <Ellipsis component="span">{document.documentName}</Ellipsis>
      </Link>
    ),
  };

  return [
    { ...statusColumn, sortable: true },
    { ...createdColumn, sortable: true },
    { ...documentDateColumn, sortable: true },
    { ...partnerColumn, sortable: true },
    { ...documentTypeRusColumn, sortable: true },
    documentColumn,
    authorColumn,
  ];
}

export type OurDocumentsTreeTableNodeData =
  | {
      kind: 'partner';
      docTypesCount: number;
      partner: number;
      partnerName: string;
    }
  | {
      kind: 'documentType';
      docsCount: number;
      documentType: DocumentsStatusesDocumentType;
      documentTypeRus: string;
    }
  | { kind: 'document'; document: IDocumentsStatusesTableItem };

const adaptColumn = createListToTreeTableColumnAdapter<
  IDocumentsStatusesTableItem,
  OurDocumentsTreeTableNodeData
>(nodeData => (nodeData.kind === 'document' ? nodeData.document : null));

export function getOurDocumentsTreeTableColumns() {
  const nameColumn: TreeTableColumn<OurDocumentsTreeTableNodeData> = {
    id: 'name',
    label: 'Наименование',
    defaultWidth: 350,
    sortable: true,
    expandable: true,
    copyCellContent: node => {
      switch (node.data.kind) {
        case 'partner': {
          const { docTypesCount, partnerName } = node.data;

          return `${partnerName} (${docTypesCount})`;
        }
        case 'documentType': {
          const { docsCount, documentTypeRus } = node.data;

          return `${documentTypeRus} (${docsCount})`;
        }
        case 'document': {
          const { document } = node.data;

          return document.documentName;
        }
      }
    },
    renderCellContent: node => {
      switch (node.data.kind) {
        case 'partner': {
          const { docTypesCount, partner, partnerName } = node.data;

          return (
            <Ellipsis
              component={Link}
              params={{ id: String(partner) }}
              rel="noopener"
              target="_blank"
              to="partners.edit"
            >
              {`${partnerName} (${docTypesCount})`}
            </Ellipsis>
          );
        }
        case 'documentType': {
          const { docsCount, documentTypeRus } = node.data;

          return (
            <Ellipsis component="span">{`${documentTypeRus} (${docsCount})`}</Ellipsis>
          );
        }
        case 'document': {
          const { document } = node.data;

          return (
            <Ellipsis
              component={Link}
              {...getDocumentLink(document)}
              rel="noopener"
              target="_blank"
            >
              {document.documentName}
            </Ellipsis>
          );
        }
      }
    },
  };

  return [nameColumn].concat(
    [statusColumn, createdColumn, documentDateColumn, authorColumn].map(
      adaptColumn
    )
  );
}

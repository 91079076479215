import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Button, IconName, Intent } from '@blueprintjs/core';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';

export interface IContactDialogFormValues {
  email: string;
  firstName: string;
  firstNameRp: string;
  jobTitle: string;
  jobTitleRp: string;
  lastName: string;
  lastNameRp: string;
  middleName: string;
  middleNameRp: string;
  note: string;
  partner: number | null;
  phone: string;
  phoneExtra: string;
}

interface IProps {
  icon: IconName;
  initialValues: IContactDialogFormValues;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onClosed?: () => void;
  onSubmit: (values: IContactDialogFormValues) => Promise<void>;
}

export function ContactDialogForm({
  icon,
  initialValues,
  isOpen,
  title,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          await onSubmit(values);
          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось сохранить контакт: Непредвиденная ошибка'
          );
        }
      }}
    >
      {({ error, form, handleSubmit, submitError, submitting }) => (
        <DialogForm
          icon={icon}
          isOpen={isOpen}
          isSubmitting={submitting}
          title={title}
          onClose={onClose}
          onClosed={() => {
            form.reset();

            if (onClosed) {
              onClosed();
            }
          }}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormErrors error={error || submitError} />

            <PartnersAutocompleteInFormGroupForFinalForm
              id="partner"
              label="Контрагент"
              name="partner"
            />

            <FormGroupForFinalForm label="Email" labelFor="email" name="email">
              <InputGroupForFinalForm autoFocus id="email" name="email" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Телефон"
              labelFor="phone"
              name="phone"
            >
              <InputGroupForFinalForm id="phone" name="phone" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Телефон дополнительно"
              labelFor="phoneExtra"
              name="phoneExtra"
            >
              <InputGroupForFinalForm id="phoneExtra" name="phoneExtra" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Фамилия"
              labelFor="lastName"
              name="lastName"
            >
              <InputGroupForFinalForm id="lastName" name="lastName" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Имя"
              labelFor="firstName"
              name="firstName"
            >
              <InputGroupForFinalForm id="firstName" name="firstName" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Отчество"
              labelFor="middleName"
              name="middleName"
            >
              <InputGroupForFinalForm id="middleName" name="middleName" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Должность"
              labelFor="jobTitle"
              name="jobTitle"
            >
              <InputGroupForFinalForm id="jobTitle" name="jobTitle" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Фамилия р.п."
              labelFor="lastNameRp"
              name="lastNameRp"
            >
              <InputGroupForFinalForm id="lastNameRp" name="lastNameRp" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Имя р.п."
              labelFor="firstNameRp"
              name="firstNameRp"
            >
              <InputGroupForFinalForm id="firstNameRp" name="firstNameRp" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Отчество р.п."
              labelFor="middleNameRp"
              name="middleNameRp"
            >
              <InputGroupForFinalForm id="middleNameRp" name="middleNameRp" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Должность р.п."
              labelFor="jobTitleRp"
              name="jobTitleRp"
            >
              <InputGroupForFinalForm id="jobTitleRp" name="jobTitleRp" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Примечание"
              labelFor="note"
              name="note"
            >
              <InputMultipleForFinalForm id="note" name="note" rows={5} />
            </FormGroupForFinalForm>
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button disabled={submitting} text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Сохранить"
                type="submit"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

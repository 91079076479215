import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { required, validate } from '_core/forms/validators';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Toolbar } from '_core/toolbar';
import { Button, Intent, Popover, Tooltip } from '@blueprintjs/core';
import { Col, Grid } from 'layout/contentLayout';
import * as React from 'react';
import { RentWagonPurchasePause } from 'rentWagonPurchase/api';
import { RentWagonSellPause } from 'rentWagonSell/api';

import { RentWagonColor } from './colors';
import * as css from './pauseRegion.module.css';

interface IProps {
  pause: RentWagonPurchasePause | RentWagonSellPause;
  style: React.CSSProperties;
  onClick: () => void;
  onMouseEnter: (event: MouseEvent) => void;
  onMouseSelectionEnd: () => void;
  onMouseSelectionStart: (
    isAdditive: boolean,
    isShiftPressing?: boolean
  ) => void;
  onPauseUpdate: (
    updatedPause: RentWagonPurchasePause | RentWagonSellPause
  ) => Promise<void>;
}

export function PauseRegion({
  pause,
  style,
  onClick,
  onMouseEnter,
  onPauseUpdate,
  onMouseSelectionEnd,
  onMouseSelectionStart,
}: IProps) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const popoverRef = React.useRef<HTMLElement | null>(null);
  const regionRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const ref = regionRef.current;

    const mouseEnter = (e: MouseEvent) => {
      e.stopImmediatePropagation();
      onMouseEnter(e);
    };

    if (ref) {
      ref?.addEventListener('mouseenter', mouseEnter);
    }

    function handleWindowMouseUp() {
      onMouseSelectionEnd();
      window.removeEventListener('mouseup', handleWindowMouseUp);
    }

    window.addEventListener('mouseup', handleWindowMouseUp);

    return () => {
      ref?.removeEventListener('mouseenter', mouseEnter);
    };
  }, [regionRef, onMouseEnter, onMouseSelectionEnd]);

  React.useEffect(() => {
    const ref = regionRef.current;

    const mouseDown = (event: MouseEvent) => {
      event.stopImmediatePropagation();
      // this is to not prevent popover content from getting focus on mouse
      // down
      if (
        event.target instanceof HTMLElement &&
        popoverRef.current &&
        popoverRef.current.contains(event.target)
      ) {
        return;
      }

      event.preventDefault();

      onMouseSelectionStart(event.ctrlKey || event.metaKey, event.shiftKey);
    };

    if (regionRef.current) {
      ref?.addEventListener('mousedown', mouseDown);
    }

    return () => {
      ref?.removeEventListener('mousedown', mouseDown);
    };
  }, [regionRef, onMouseSelectionEnd, onMouseSelectionStart]);

  return (
    <div>
      <div
        onClick={onClick}
        className={css.root}
        style={{
          ...style,
          backgroundColor:
            pause.cause === 'PAUSED'
              ? RentWagonColor.PAUSED
              : RentWagonColor.IN_REPAIR,
        }}
      >
        <Tooltip
          content={pause.note}
          disabled={isPopoverOpen || !pause.note}
          targetTagName="div"
          wrapperTagName="div"
        >
          <div ref={regionRef} className={css.inner}>
            <div className={css.note}>{pause.note}</div>

            <Popover
              content={
                <FinalForm
                  initialValues={{
                    endDate: pause.endDate,
                    note: pause.note,
                    startDate: pause.startDate,
                  }}
                  validate={validate({
                    startDate: required(),
                  })}
                  onSubmit={async values => {
                    await onPauseUpdate(
                      pause instanceof RentWagonPurchasePause
                        ? new RentWagonPurchasePause({ ...pause, ...values })
                        : new RentWagonSellPause({ ...pause, ...values })
                    );

                    setIsPopoverOpen(false);
                  }}
                >
                  {({ handleSubmit, submitting }) => (
                    <BaseForm className={css.form} onSubmit={handleSubmit}>
                      <Grid>
                        <Col span={6}>
                          <DateInputInFormGroupForFinalForm
                            label="Дата начала"
                            name="startDate"
                            usePortal={false}
                          />
                        </Col>

                        <Col span={6}>
                          <DateInputInFormGroupForFinalForm
                            label="Дата окончания"
                            name="endDate"
                            usePortal={false}
                          />
                        </Col>
                      </Grid>

                      <FormGroupForFinalForm label="Комментарий" name="note">
                        <InputMultipleForFinalForm
                          growVertically
                          name="note"
                          rows={8}
                          style={{ resize: 'both' }}
                        />
                      </FormGroupForFinalForm>

                      <Toolbar align="right">
                        <Button
                          disabled={submitting}
                          intent={Intent.PRIMARY}
                          loading={submitting}
                          text="Сохранить"
                          type="submit"
                        />
                      </Toolbar>
                    </BaseForm>
                  )}
                </FinalForm>
              }
              isOpen={isPopoverOpen}
              popoverRef={ref => {
                popoverRef.current = ref;
              }}
              onInteraction={setIsPopoverOpen}
            >
              <Button icon="edit" minimal />
            </Popover>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

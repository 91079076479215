export function formatContactName(
  contact: {
    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
  },

  {
    includeEmail = !contact.lastName &&
      !contact.firstName &&
      !contact.middleName,

    placeholderIfEmpty = false,
  } = {}
) {
  const email = includeEmail && contact.email ? `<${contact.email}>` : '';

  const formattedName = [
    contact.lastName,
    contact.firstName,
    contact.middleName,
    email,
  ]
    .filter(Boolean)
    .join(' ');

  if (!formattedName && placeholderIfEmpty) {
    return 'Безымянный контакт';
  }

  return formattedName;
}

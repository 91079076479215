import './app.css';

import { ApiClient } from '_core/api/client';
import { ApiClientProvider } from '_core/api/context';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FileUploaderProvider } from '_core/fileUploader/fileUploaderContext';
import { ToasterProvider } from '_core/toaster/toasterContext';
import { ErpStore } from '_erp/redux';
import { IToaster } from '@blueprintjs/core';
import { ErrorBoundary } from '@sentry/react';
import * as React from 'react';
import { HeadProvider, Title } from 'react-head';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router5';
import { Router } from 'router5';

import { Layout } from './layout';
import { RouteTree } from './routeTree';

interface IProps {
  api: ApiClient;
  orgName: string | undefined;
  router: Router;
  store: ErpStore;
  toaster: IToaster;
}

export function App({ api, orgName, router, store, toaster }: IProps) {
  return (
    <ToasterProvider toaster={toaster}>
      <ApiClientProvider api={api}>
        <Provider store={store}>
          <FileUploaderProvider>
            <RouterProvider router={router}>
              <HeadProvider>
                <Title>{orgName}</Title>

                <Layout orgName={orgName}>
                  <ErrorBoundary fallback={<GenericErrorMessage />}>
                    <RouteTree />
                  </ErrorBoundary>
                </Layout>
              </HeadProvider>
            </RouterProvider>
          </FileUploaderProvider>
        </Provider>
      </ApiClientProvider>
    </ToasterProvider>
  );
}

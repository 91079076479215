import { useApiClient } from '_core/api/context';
import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { FileInputSingleForFinalForm } from '_core/inputs/fileInputSingle';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { plural } from '_core/strings/utils';
import { Button, Callout, Intent } from '@blueprintjs/core';
import {
  createExpeditionTransportationsFromXlsx,
  ICreateExpeditionTransportationsFromXlsxResponse,
} from 'expeditionRequests/api';
import {
  expeditionTransportationStatusFromGroup,
  IExpeditionTransportation,
} from 'expeditionTransportations/types';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

export interface IImportTransportationsDialogFormValues {
  file: string | null;
}

interface IProps {
  initialValues: IImportTransportationsDialogFormValues;
  isOpen: boolean;
  requestId: number;
  onClose: () => void;
  onClosed: () => void;
}

export function ImportTransportationsDialog({
  initialValues,
  isOpen,
  requestId,
  onClose,
  onClosed,
}: IProps) {
  const api = useApiClient();

  const [importResponse, setImportResponse] =
    useState<ICreateExpeditionTransportationsFromXlsxResponse | null>(null);

  const columns = useMemo(
    (): Array<ListTableColumn<IExpeditionTransportation>> => [
      {
        id: 'waybillNumber',
        label: 'Номер накладной',
        defaultWidth: 150,
        copyCellContent: transportation =>
          transportation.waybillNumber || '<отсутствует>',
        renderCellContent: transportation => (
          <Ellipsis
            component={Link}
            params={{
              id: transportation.id,
              status: expeditionTransportationStatusFromGroup(
                transportation.group
              ),
            }}
            rel="noopener"
            target="_blank"
            to="expeditions.transportations.edit"
          >
            {transportation.waybillNumber || '<отсутствует>'}
          </Ellipsis>
        ),
      },
      {
        id: 'wagon',
        label: 'Вагон',
        defaultWidth: 95,
        copyCellContent: transportation => transportation.wagon,
        renderCellContent: transportation => (
          <Ellipsis
            component={Link}
            params={{ number: transportation.wagon }}
            rel="noopener"
            target="_blank"
            to="wagons.edit"
          >
            {transportation.wagon}
          </Ellipsis>
        ),
      },
    ],
    []
  );

  const getItemId = useCallback(
    (transportation: IExpeditionTransportation) => String(transportation.id),
    []
  );

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          const response = await createExpeditionTransportationsFromXlsx(api, {
            file: values.file,
            requestId,
          });

          setImportResponse(response);
          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось добавить отправки: Непредвиденная ошибка'
          );
        }
      }}
    >
      {({ error, handleSubmit, submitError, submitting }) => (
        <DialogForm
          isOpen={isOpen}
          isSubmitting={submitting}
          title="Добавление отправок вручную"
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        >
          {importResponse ? (
            <>
              <DialogBody>
                <VGrid>
                  <Row>
                    <Callout
                      intent={Intent.SUCCESS}
                      title={`${plural(importResponse.createdRecords.length, [
                        () =>
                          `Создана ${importResponse.createdRecords.length} отправка`,
                        () =>
                          `Создано ${importResponse.createdRecords.length} отправки`,
                        () =>
                          `Создано ${importResponse.createdRecords.length} отправок`,
                      ])} из ${plural(importResponse.createdRecords.length, [
                        () => `${importResponse.recordsCount} строки`,
                        () => `${importResponse.recordsCount} строк`,
                        () => `${importResponse.recordsCount} строк`,
                      ])}`}
                    />
                  </Row>

                  <Row>
                    <ListTable
                      columns={columns}
                      getItemId={getItemId}
                      items={importResponse.createdRecords}
                      maxHeight={300}
                    />
                  </Row>
                </VGrid>
              </DialogBody>

              <DialogFooter>
                <DialogFooterActions>
                  <Button
                    intent={Intent.PRIMARY}
                    text="Закрыть"
                    onClick={onClose}
                  />
                </DialogFooterActions>
              </DialogFooter>
            </>
          ) : (
            <>
              <DialogBody>
                <FormErrors error={error || submitError} />

                <FileInputSingleForFinalForm label="Файл (.xlsx)" name="file" />
              </DialogBody>

              <DialogFooter>
                <DialogFooterActions>
                  <Button
                    disabled={submitting}
                    text="Отмена"
                    onClick={onClose}
                  />

                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    loading={submitting}
                    text="Добавить"
                    type="submit"
                  />
                </DialogFooterActions>
              </DialogFooter>
            </>
          )}
        </DialogForm>
      )}
    </FinalForm>
  );
}

import { useApiClient } from '_core/api/context';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { createPark } from 'parks/api';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';

import { ParksForm } from './form';
import { ParksFormRouteWrapper } from './formRouteWrapper';

export default function ParksCreateRoute() {
  const { route, router } = useRouteNode('parks.create');
  const navigationLock = useFormNavigationLock('parks.create');
  const api = useApiClient();

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  return (
    <ParksFormRouteWrapper listParams={listParams} title="Новый парк">
      <ParksForm
        initialValues={{
          allTechrunRequestWagons: false,
          expeditionRequests: [],
          name: '',
          techrunRequests: [],
          wagons: [],
        }}
        navigationLock={navigationLock}
        onSave={async values => {
          try {
            await createPark(api, values);
            navigationLock.unlock();
            router.navigate('parks', listParams);
            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось создать парк: Непредвиденная ошибка'
            );
          }
        }}
        onSaveAndContinue={async values => {
          try {
            const createdPark = await createPark(api, values);
            navigationLock.unlock();

            router.navigate('parks.edit', {
              id: String(createdPark.id),
              listParams: route.params.listParams,
            });

            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось создать парк: Непредвиденная ошибка'
            );
          }
        }}
      />
    </ParksFormRouteWrapper>
  );
}

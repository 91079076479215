import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import {
  SelectForFinalForm,
  SelectInFormGroupForFinalForm,
} from '_core/inputs/select';
import { formatMoney } from '_core/money/formatMoney';
import { Link } from '_core/router5/link';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, H4, Intent } from '@blueprintjs/core';
import dayjs from 'dayjs';
import { fetchExpeditionProtocolsSell } from 'expeditionProtocolsSell/api';
import { IExpeditionRequest } from 'expeditionRequests/api';
import {
  ExpeditionRequestStatus,
  expeditionRequestStatusOptions,
} from 'expeditionRequests/types';
import {
  ExpeditionStationType,
  expeditionStationTypesOptions,
} from 'expeditions/types';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { StationsAutocompleteInFormGroupForFinalForm } from 'stations/autocomplete';
import { IWagonType } from 'wagonTypes/api';
import { wagonTypeToOption } from 'wagonTypes/utils';

import * as css from './form.module.css';
import {
  IExpeditionRequestFormWagonsFieldItem,
  WagonsField,
} from './wagonsField/wagonsField';

export interface IExpeditionRequestFormValues {
  endDate: Date | null;
  name: string;
  number: string;
  partner: number | null;
  sellProtocol: string | null;
  startDate: Date | null;
  station: number | null;
  stationType: ExpeditionStationType;
  status: ExpeditionRequestStatus;
  trackRepair: boolean;
  wagons: IExpeditionRequestFormWagonsFieldItem[];
  wagonType: string | null;
}

interface IViewProps
  extends Pick<
    IExpeditionRequestFormValues,
    'partner' | 'station' | 'stationType' | 'status'
  > {
  change: (field: string, value: unknown) => void;
  dirty: boolean;
  editingLocked: boolean | undefined;
  error: string | undefined;
  expeditionRequest: IExpeditionRequest | undefined;
  initialStatus: IExpeditionRequestFormValues['status'];
  navigationLock: IFormNavigationLock;
  reset: () => void;
  submitting: boolean;
  wagonTypes: IWagonType[];
  onCancelEditing: (() => void) | undefined;
  onDuplicate: (() => void) | undefined;
  onImportClick: (() => void) | undefined;
  onStartEditing: (() => void) | undefined;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

function ExpeditionRequestFormView({
  change,
  dirty,
  editingLocked,
  expeditionRequest,
  error,
  initialStatus,
  navigationLock,
  partner,
  reset,
  station,
  stationType,
  status,
  submitting,
  wagonTypes,
  onCancelEditing,
  onDuplicate,
  onImportClick,
  onStartEditing,
  onSubmit,
}: IViewProps) {
  const api = useApiClient();

  const sellProtocols = useAsyncData([partner], async () => {
    if (!partner) {
      return [];
    }

    const response = await fetchAllPages(page =>
      fetchExpeditionProtocolsSell(api, { page, partner })
    );

    return response.results;
  });

  if (!sellProtocols.data) {
    if (sellProtocols.isFetching) {
      return <CenteredSpinner />;
    }

    return <GenericErrorMessage />;
  }

  const filteredSellProtocols = sellProtocols.data.filter(
    protocol =>
      (stationType === ExpeditionStationType.Any ||
        protocol.stationType === stationType) &&
      (station == null || protocol.station === station)
  );

  const showStatusConfirmButton = status !== initialStatus && editingLocked;

  return (
    <BaseForm onSubmit={onSubmit}>
      <FormNavigationLock navigationLock={navigationLock} shouldLock={dirty} />

      <FormErrors error={error} />

      <VGrid>
        <Row>
          <Grid>
            <Col span={4}>
              <H4>Основная информация</H4>

              <CheckboxForFinalForm
                editingLocked={editingLocked}
                id="trackRepair"
                name="trackRepair"
                label="Следить за ремонтом"
              />

              <FormGroupForFinalForm
                label="Номер заявки"
                labelFor="number"
                name="number"
              >
                <InputGroupForFinalForm id="number" name="number" readOnly />
              </FormGroupForFinalForm>

              <FormGroupForFinalForm
                label="Наименование"
                labelFor="name"
                name="name"
              >
                <InputGroupForFinalForm
                  id="name"
                  name="name"
                  readOnly={editingLocked}
                />
              </FormGroupForFinalForm>

              <Grid>
                <Col span={showStatusConfirmButton ? 2 : 4}>
                  <SelectInFormGroupForFinalForm
                    id="status"
                    label="Статус заявки"
                    name="status"
                    options={expeditionRequestStatusOptions}
                  />
                </Col>

                {showStatusConfirmButton && (
                  <Col span={2}>
                    <div className={css.statusConfirmButtonWrap}>
                      <Button
                        disabled={submitting}
                        fill
                        intent={Intent.PRIMARY}
                        text="Подтвердить"
                        type="submit"
                      />
                    </div>
                  </Col>
                )}
              </Grid>

              <PartnersAutocompleteInFormGroupForFinalForm
                editingLocked={editingLocked}
                id="partner"
                label="Контрагент"
                name="partner"
                required
                onChange={() => {
                  change('sellProtocol', null);
                }}
              />

              <Grid>
                <Col span={2}>
                  <DateInputInFormGroupForFinalForm
                    editingLocked={editingLocked}
                    fill
                    id="startDate"
                    label="Дата начала"
                    name="startDate"
                    required
                    onChange={nextStartDate => {
                      if (nextStartDate) {
                        change(
                          'endDate',
                          dayjs(nextStartDate)
                            .endOf('month')
                            .startOf('day')
                            .toDate()
                        );
                      }
                    }}
                  />
                </Col>

                <Col span={2}>
                  <DateInputInFormGroupForFinalForm
                    editingLocked={editingLocked}
                    fill
                    id="endDate"
                    label="Дата окончания"
                    name="endDate"
                    required
                  />
                </Col>
              </Grid>

              <SelectInFormGroupForFinalForm
                editingLocked={editingLocked}
                id="wagonType"
                label="Вид подвижного состава"
                name="wagonType"
                options={wagonTypes.map(wagonType =>
                  wagonTypeToOption(wagonType)
                )}
              />

              <SelectInFormGroupForFinalForm
                editingLocked={editingLocked}
                id="stationType"
                label="Тип станции"
                name="stationType"
                options={expeditionStationTypesOptions}
                onChange={nextValue => {
                  if (nextValue === ExpeditionStationType.Any) {
                    change('station', null);
                  }
                }}
              />

              <StationsAutocompleteInFormGroupForFinalForm
                disabled={stationType === ExpeditionStationType.Any}
                editingLocked={editingLocked}
                id="station"
                label="Станция"
                name="station"
              />

              <FormGroupForFinalForm
                label="Протокол продажи"
                labelFor="sellProtocol"
                labelInfo="*"
                name="sellProtocol"
              >
                <SelectForFinalForm
                  editingLocked={editingLocked}
                  fill
                  id="sellProtocol"
                  name="sellProtocol"
                  options={filteredSellProtocols.map(protocol => ({
                    label: `${formatMoney(protocol.expeditionCost)} (${
                      protocol.protocolName
                    })`,
                    value: String(protocol.id),
                  }))}
                  withEmptyOption
                />
              </FormGroupForFinalForm>

              {expeditionRequest && (
                <p>
                  <Link
                    params={{
                      id: expeditionRequest.sellContract,
                      protocolId: expeditionRequest.sellProtocol,
                      showArchived: true,
                    }}
                    rel="noopener"
                    target="_blank"
                    to="contracts.sell.view.expeditionProtocols.view"
                  >
                    Протокол
                  </Link>
                </p>
              )}

              {onImportClick && (
                <p>
                  <Button
                    intent={Intent.SUCCESS}
                    text="Добавить отправки вручную"
                    onClick={onImportClick}
                  />
                </p>
              )}
            </Col>

            <Col span={8}>
              <H4>Вагоны</H4>

              <WagonsField editingLocked={editingLocked} name="wagons" />
            </Col>
          </Grid>
        </Row>

        <Row stickToBottom>
          <Toolbar align="right">
            {onDuplicate && (
              <Button
                disabled={submitting}
                icon="duplicate"
                text="Дублировать"
                onClick={onDuplicate}
              />
            )}

            {onStartEditing && (
              <Button
                disabled={submitting}
                icon="unlock"
                intent={Intent.DANGER}
                text="Редактировать"
                onClick={onStartEditing}
              />
            )}

            {onCancelEditing && (
              <Button
                disabled={submitting}
                text="Отменить"
                onClick={() => {
                  reset();
                  onCancelEditing();
                }}
              />
            )}

            {!editingLocked && (
              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                text="Сохранить"
                type="submit"
              />
            )}
          </Toolbar>
        </Row>
      </VGrid>
    </BaseForm>
  );
}

interface IProps {
  editingLocked?: boolean;
  expeditionRequest?: IExpeditionRequest;
  initialValues: IExpeditionRequestFormValues;
  navigationLock: IFormNavigationLock;
  wagonTypes: IWagonType[];
  onCancelEditing?: () => void;
  onDuplicate?: () => void;
  onImportClick?: () => void;
  onStartEditing?: () => void;
  onSubmit: (values: IExpeditionRequestFormValues) => void;
}

export function ExpeditionRequestForm({
  editingLocked,
  expeditionRequest,
  initialValues,
  navigationLock,
  wagonTypes,
  onCancelEditing,
  onDuplicate,
  onImportClick,
  onStartEditing,
  onSubmit,
}: IProps) {
  return (
    <FinalForm
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <ExpeditionRequestFormView
          change={form.change}
          dirty={dirty}
          editingLocked={editingLocked}
          error={error || submitError}
          expeditionRequest={expeditionRequest}
          initialStatus={initialValues.status}
          navigationLock={navigationLock}
          partner={values.partner}
          station={values.station}
          stationType={values.stationType}
          status={values.status}
          reset={form.reset}
          submitting={submitting}
          wagonTypes={wagonTypes}
          onCancelEditing={onCancelEditing}
          onDuplicate={onDuplicate}
          onImportClick={onImportClick}
          onStartEditing={onStartEditing}
          onSubmit={handleSubmit}
        />
      )}
    </FinalForm>
  );
}

import { ListTable } from '_core/react-window/listTable';
import { ISorting, SortingDirection } from '_core/sorting';
import { IUser } from 'accounts/types';
import { IPartnerSerialized, PartnersSortingField } from 'partners/api';
import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { SortingInput } from '../../_core/sortingInput';
import { Col, Grid, Row } from '../../layout/contentLayout';
import { getPartnersListTableColumns } from './columns';

interface IProps {
  isFetching: boolean;
  listParams: string;
  partners: IPartnerSerialized[];
  sorting: ISorting;
  usersById: Record<number, IUser>;
  onSortingChange: (newSorting: ISorting) => void;
}

export function PartnersListTable({
  isFetching,
  listParams,
  partners,
  sorting,
  usersById,
  onSortingChange,
}: IProps) {
  const columns = useMemo(
    () => getPartnersListTableColumns({ listParams, usersById }),
    [listParams, usersById]
  );

  const getItemId = useCallback(
    (partner: IPartnerSerialized) => String(partner.id),
    []
  );

  const defaultSorting: ISorting<PartnersSortingField> = {
    field: PartnersSortingField.Modified,
    direction: SortingDirection.Descending,
  };

  return (
    <>
      <Row>
        <Grid>
          <Col align="end">
            <SortingInput
              defaultValue={defaultSorting}
              options={columns
                .filter(column => column.sortable)
                .map(column => ({
                  label: column.label,
                  value: column.id as PartnersSortingField,
                }))}
              value={sorting}
              onChange={onSortingChange}
            />
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        <ListTable
          columns={columns}
          getItemId={getItemId}
          isFetching={isFetching}
          items={partners}
          sorting={sorting}
          onSortingChange={onSortingChange}
        />
      </Row>
    </>
  );
}

import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { fetchRelated } from '_core/api/fetchRelated';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { useAsyncData } from '_core/useAsyncData';
import dayjs from 'dayjs';
import { EXPEDITION_REQUESTS_BASE_ENDPOINT } from 'expeditionRequests/api';
import { IExpeditionRequestSerialized } from 'expeditionRequests/types';
import { IExpeditionTransportation } from 'expeditionTransportations/types';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { IStationSerialized } from 'stations/types';
import { ITechrunRequest } from 'techrunRequests/types';
import { ITechrunTransportation } from 'techrunTransportations/types';
import {
  getAccountingStateLabel,
  transportationStatusFromGroup,
} from 'transportations/types';

interface TableItem
  extends Omit<
    IExpeditionTransportation | ITechrunTransportation,
    'arrivalStation' | 'departureStation' | 'request'
  > {
  arrivalStation: IStationSerialized;
  departureStation: IStationSerialized;
  request: IExpeditionRequestSerialized | ITechrunRequest | null;
}

interface Props {
  isTechrun: boolean;
  transportationIds: number[];
}

export function ShipmentInfoFormTransportationsTable({
  isTechrun,
  transportationIds,
}: Props) {
  const api = useApiClient();

  const { data, isFetching } = useAsyncData(
    [isTechrun, api, transportationIds],
    async () => {
      if (transportationIds.length === 0) {
        return [];
      }

      return (await fetchRelated(
        api,
        {
          arrivalStation: '/directories_trainstations',
          departureStation: '/directories_trainstations',
          request: isTechrun
            ? '/techrun_requests'
            : EXPEDITION_REQUESTS_BASE_ENDPOINT,
        },
        (
          await fetchAllPages(page =>
            api.get<
              ListResponse<IExpeditionTransportation | ITechrunTransportation>
            >(
              isTechrun
                ? '/techrun_transportations'
                : '/expeditions_transportations',
              { ids: transportationIds.join(','), page }
            )
          )
        ).results
      )) as unknown as Promise<TableItem[]>;
    }
  );

  const columns = useMemo(
    (): Array<ListTableColumn<TableItem>> => [
      {
        id: 'waybillNumber',
        label: 'Накладная',
        defaultWidth: 100,
        copyCellContent: transportation => transportation.waybillNumber,
        renderCellContent: transportation => (
          <Ellipsis
            component={Link}
            params={{
              id: transportation.id,
              status: transportationStatusFromGroup(transportation.group),
            }}
            to={
              isTechrun
                ? 'techruns.transportations.edit'
                : 'expeditions.transportations.edit'
            }
          >
            {transportation.waybillNumber}
          </Ellipsis>
        ),
      },
      {
        id: 'request',
        label: 'Заявка',
        defaultWidth: 200,
        copyCellContent: transportation =>
          transportation.request ? transportation.request.requestName : '',
        renderCellContent: transportation =>
          transportation.request && (
            <Ellipsis
              component={Link}
              params={{ id: transportation.request.id }}
              to={
                isTechrun
                  ? 'techruns.requests.view'
                  : 'expeditions.requests.view'
              }
            >
              {transportation.request.requestName}
            </Ellipsis>
          ),
      },
      {
        id: 'accountingState',
        label: 'Бух. состояние',
        defaultWidth: 100,
        copyCellContent: transportation =>
          getAccountingStateLabel(transportation.accountingState),
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {getAccountingStateLabel(transportation.accountingState)}
          </Ellipsis>
        ),
      },
      {
        id: 'departureStation',
        label: 'Станция отправления',
        defaultWidth: 180,
        copyCellContent: transportation => transportation.departureStation.name,
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {transportation.departureStation.name}
          </Ellipsis>
        ),
      },
      {
        id: 'arrivalStation',
        label: 'Станция назначения',
        defaultWidth: 180,
        copyCellContent: transportation => transportation.arrivalStation.name,
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {transportation.arrivalStation.name}
          </Ellipsis>
        ),
      },

      {
        id: 'created',
        label: 'Создана',
        defaultWidth: 130,
        copyCellContent: transportation =>
          transportation.created &&
          dayjs(transportation.created).format(DATE_FORMAT_DATETIME),
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {transportation.created &&
              dayjs(transportation.created).format(DATE_FORMAT_DATETIME)}
          </Ellipsis>
        ),
      },
    ],
    [isTechrun]
  );

  const getItemId = useCallback(
    (transportation: TableItem) => String(transportation.id),
    []
  );

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : data.length === 0 ? (
    <EmptyListMessage description="Нет отправок" />
  ) : (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      isFetching={isFetching}
      items={data}
    />
  );
}

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
}

const currencyLabel = (currency: Currency) =>
  ({
    [Currency.RUB]: 'Рубли',
    [Currency.USD]: 'Доллары',
    [Currency.EUR]: 'Евро',
  }[currency]);

export const currencyOptions = Object.values(Currency).map(currency => ({
  label: currencyLabel(currency),
  value: currency,
}));

import { CheckboxList, ICheckboxListOption } from '_core/inputs/checkboxList';
import {
  Button,
  ButtonGroup,
  IconName,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import { deepEqual } from 'fast-equals';
import * as React from 'react';

import * as css from './checkboxList.module.css';

interface Props {
  allOption?: boolean;
  iconName?: IconName;
  initialValue: string | undefined;
  isEmpty?: (value: string | undefined) => boolean;
  label: string;
  options: ICheckboxListOption[];
  onApply: (newValue: string | undefined) => void;
}

export function CheckboxListFilter({
  allOption,
  iconName = 'filter-list',
  initialValue,
  isEmpty = value => !value,
  label,
  options,
  onApply,
}: Props) {
  const lastInitialValueRef = React.useRef(initialValue);

  const deserialize = (v: string | undefined) => (v ? v.split(',') : []);

  const [state, setState] = React.useState<{
    isOpen: boolean;
    value: string[];
  }>({
    isOpen: false,
    value: deserialize(initialValue),
  });

  if (!deepEqual(initialValue, lastInitialValueRef.current)) {
    lastInitialValueRef.current = initialValue;
    setState(prevState => ({ ...prevState, value: deserialize(initialValue) }));
  }

  return (
    <ButtonGroup>
      <Popover
        content={
          <div>
            <div className={css.root}>
              <CheckboxList
                allOption={allOption}
                options={options}
                value={state.value}
                onChange={nextValue => {
                  setState(prevState => ({ ...prevState, value: nextValue }));
                }}
              />
            </div>

            <Button
              fill
              intent={Intent.PRIMARY}
              text="Применить"
              onClick={() => {
                onApply(state.value.join(',') || undefined);
                setState(prevState => ({ ...prevState, isOpen: false }));
              }}
            />
          </div>
        }
        isOpen={state.isOpen}
        position={Position.BOTTOM}
        onInteraction={nextOpenState => {
          if (nextOpenState) {
            setState(prevState => ({
              ...prevState,
              isOpen: true,
              value: deserialize(initialValue),
            }));
          } else {
            setState(prevState => ({ ...prevState, isOpen: false }));
          }
        }}
      >
        <Button
          icon={iconName}
          intent={isEmpty(initialValue) ? undefined : Intent.DANGER}
          text={label}
        />
      </Popover>

      {!isEmpty(initialValue) && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => {
            onApply(undefined);
          }}
        />
      )}
    </ButtonGroup>
  );
}

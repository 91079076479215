import { FormGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useField } from 'react-final-form';

interface Props {
  children?: React.ReactNode;
  helperText?: string;
  label?: string | React.ReactNode;
  labelFor?: string;
  labelInfo?: React.ReactNode;
  name: string;
}

export function FormGroupForFinalForm({
  children,
  helperText,
  label,
  labelFor,
  labelInfo,
  name,
}: Props) {
  const { meta } = useField(name);
  const error = meta.touched ? meta.error || meta.submitError : undefined;

  return (
    <FormGroup
      helperText={error || helperText}
      intent={error ? Intent.DANGER : undefined}
      label={label}
      labelFor={labelFor}
      labelInfo={labelInfo}
    >
      {children}
    </FormGroup>
  );
}

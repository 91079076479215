import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { createDateRange, isValidDate, parseDate } from '_core/dates/utils';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { DateFilterInPopover } from '_core/filters/date';
import { MonthsMultiSelectFilter } from '_core/filters/monthMultiSelect';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { uniq } from '_core/fp/uniq';
import { indexByLastItem } from '_core/indexBy';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { LinkButton } from '_core/router5/linkButton';
import { plural } from '_core/strings/utils';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, NonIdealState } from '@blueprintjs/core';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { fetchManyStations } from 'stations/api';
import { ArrDepOperStationsFilterInPopover } from 'stations/arrDepOperFilterInPopover';
import {
  batchEditWagonArrivals,
  fetchWagonArrivals,
  makeWagonArrivalsNew,
  rejectWagonArrivals,
  WagonArrivalGroup,
} from 'wagonArrivals/api';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

import {
  IBatchEditDialogFormValues,
  WagonArrivalsBatchEditDialog,
} from './batchEditDialog';
import { WagonArrivalsDistanceFilter } from './distanceFilter';
import {
  IWagonArrivalsListTableItem,
  WagonArrivalsListTable,
} from './listTable';

enum WagonArrivalsGroupRouteParam {
  New = 'new',
  Executed = 'executed',
  Rejected = 'rejected',
}

export const wagonArrivalsGroupParamValues = Object.values(
  WagonArrivalsGroupRouteParam
);

function getGroupTitle(group: WagonArrivalGroup) {
  return {
    [WagonArrivalGroup.New]: 'Новые подходы',
    [WagonArrivalGroup.Executed]: 'Оформленные подходы',
    [WagonArrivalGroup.Rejected]: 'Отклонённые подходы',
  }[group];
}

export default function WagonArrivalsRoute() {
  const { route, router } = useRouteNode('wagonArrivals');
  const api = useApiClient();

  const group = {
    [WagonArrivalsGroupRouteParam.New]: WagonArrivalGroup.New,
    [WagonArrivalsGroupRouteParam.Executed]: WagonArrivalGroup.Executed,
    [WagonArrivalsGroupRouteParam.Rejected]: WagonArrivalGroup.Rejected,
  }[route.params.group as WagonArrivalsGroupRouteParam];

  const fetchWagonArrivalsParams = useMemo(
    () => ({
      arrivalDate: route.params.arrivalDate
        ? parseDate(route.params.arrivalDate)
        : undefined,
      arrivalMonths: route.params.arrivalMonths
        ? (route.params.arrivalMonths as string)
            .split(',')
            .map(str => parseDate(str))
            .filter(isValidDate)
        : undefined,
      arrivalStation: route.params.arrivalStation
        ? Number(route.params.arrivalStation)
        : undefined,
      departureDate: route.params.departureDate
        ? parseDate(route.params.departureDate)
        : undefined,
      departureMonths: route.params.departureMonths
        ? (route.params.departureMonths as string)
            .split(',')
            .map(str => parseDate(str))
            .filter(isValidDate)
        : undefined,
      departureStation: route.params.departureStation
        ? Number(route.params.departureStation)
        : undefined,
      distanceLower: isFinite(route.params.distanceLower)
        ? Number(route.params.distanceLower)
        : undefined,
      group,
      operationStation: route.params.operationStation
        ? Number(route.params.operationStation)
        : undefined,
      page: isFinite(route.params.page) ? Number(route.params.page) : undefined,
      pageSize: isFinite(route.params.pageSize)
        ? Number(route.params.pageSize)
        : undefined,
      wagonNumbers: route.params.wagonNumbers
        ? (route.params.wagonNumbers as string).split(',')
        : undefined,
    }),
    [
      group,
      route.params.arrivalDate,
      route.params.arrivalMonths,
      route.params.arrivalStation,
      route.params.departureDate,
      route.params.departureMonths,
      route.params.departureStation,
      route.params.distanceLower,
      route.params.operationStation,
      route.params.page,
      route.params.pageSize,
      route.params.wagonNumbers,
    ]
  );

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [api, fetchWagonArrivalsParams],
    async () => {
      const { meta, results } = await fetchWagonArrivals(
        api,
        fetchWagonArrivalsParams
      );

      const stationIds = uniq(
        results.flatMap(wagonArrival =>
          [
            wagonArrival.arrivalStation,
            wagonArrival.departureStation,
            wagonArrival.loadStation,
            wagonArrival.operationStation,
          ].filter((stationId): stationId is number => stationId != null)
        )
      );

      const stationsIndex = indexByLastItem(
        await fetchManyStations(api, stationIds),
        station => station.id
      );

      return {
        currentPage: meta.currentPage,
        filterOptions: meta.filterOptions,
        items: results.map(
          (wagonArrival): IWagonArrivalsListTableItem => ({
            ...wagonArrival,
            arrivalStation: stationsIndex[wagonArrival.arrivalStation],
            departureStation: stationsIndex[wagonArrival.departureStation],
            loadStation: wagonArrival.loadStation
              ? stationsIndex[wagonArrival.loadStation]
              : null,
            operationStation: wagonArrival.operationStation
              ? stationsIndex[wagonArrival.operationStation]
              : null,
          })
        ),
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
      };
    }
  );

  const lastDataRef = useRef(data);
  const [selectedArrivals, setSelectedArrivals] = useState<string[]>([]);

  if (data !== lastDataRef.current) {
    setSelectedArrivals([]);
    lastDataRef.current = data;
  }

  const [isRejectionInProgress, setIsRejectionInProgress] = useState(false);

  const [isMakeNewInProgress, setIsMakeNewInProgress] = useState(false);

  const batchEditDialogForm = useDialog<{
    initialValues: IBatchEditDialogFormValues;
  }>();

  function applyFilterParams(
    filterParams: Record<string, string | null | undefined>
  ) {
    router.navigate(route.name, {
      ...route.params,
      page: undefined,
      ...filterParams,
    });
  }

  const arrivalMonthsFilterValue = useMemo(
    () =>
      route.params.arrivalMonths
        ? (route.params.arrivalMonths as string)
            .split(',')
            .map(str => parseDate(str))
            .filter(isValidDate)
        : [],
    [route.params.arrivalMonths]
  );

  const arrivalMonthsFilterDates = useMemo(() => {
    if (!data || data.filterOptions.arrivalDate.length === 0) {
      return [];
    }

    const [fromDate, toDate] = data.filterOptions.arrivalDate;

    return createDateRange(
      'month',
      fromDate,
      dayjs(toDate).add(1, 'month').toDate()
    ).reverse();
  }, [data]);

  const departureMonthsFilterValue = useMemo(
    () =>
      route.params.departureMonths
        ? (route.params.departureMonths as string)
            .split(',')
            .map(str => parseDate(str))
            .filter(isValidDate)
        : [],
    [route.params.departureMonths]
  );

  const departureMonthsFilterDates = useMemo(() => {
    if (!data || data.filterOptions.departureDate.length === 0) {
      return [];
    }

    const [fromDate, toDate] = data.filterOptions.departureDate;

    return createDateRange(
      'month',
      fromDate,
      dayjs(toDate).add(1, 'month').toDate()
    ).reverse();
  }, [data]);

  return (
    <>
      <FormattedTitle>{getGroupTitle(group)}</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Toolbar align="right">
            <WagonNumbersFilter
              initialValue={route.params.wagonNumbers}
              onApply={(wagonNumbers: string | undefined) => {
                applyFilterParams({
                  wagonNumbers,
                });
              }}
            />

            <ArrDepOperStationsFilterInPopover
              initialValue={{
                arrivalStation: route.params.arrivalStation
                  ? Number(route.params.arrivalStation)
                  : undefined,
                consignee: undefined,
                departureStation: route.params.departureStation
                  ? Number(route.params.departureStation)
                  : undefined,
                operationStation: route.params.operationStation
                  ? Number(route.params.operationStation)
                  : undefined,
              }}
              onApply={({
                arrivalStation,
                departureStation,
                operationStation,
              }) => {
                applyFilterParams({
                  arrivalStation: arrivalStation
                    ? String(arrivalStation)
                    : undefined,
                  departureStation: departureStation
                    ? String(departureStation)
                    : undefined,
                  operationStation: operationStation
                    ? String(operationStation)
                    : undefined,
                });
              }}
            />

            <WagonArrivalsDistanceFilter
              initialValue={route.params.distanceLower}
              onApply={distanceLower => {
                applyFilterParams({ distanceLower });
              }}
            />

            <Button text="Обновить" onClick={refetch} />

            <LinkButton
              text="Сбросить фильтры"
              params={{ group: route.params.group }}
              to={route.name}
            />
          </Toolbar>
        </Row>

        <Row>
          <Toolbar valign="center">
            <div>
              {plural(selectedArrivals.length, [
                () => `Выбран ${selectedArrivals.length} подход`,
                () => `Выбрано ${selectedArrivals.length} подхода`,
                () => `Выбрано ${selectedArrivals.length} подходов`,
              ])}
              :
            </div>

            <Button
              disabled={selectedArrivals.length === 0}
              text="Групповое редактирование"
              onClick={() => {
                batchEditDialogForm.open({
                  initialValues: {
                    consignee: null,
                    etranWaybillNumber: '',
                    guNumber: '',
                    loadStation: null,
                  },
                });
              }}
            />

            {group === WagonArrivalGroup.New && (
              <Button
                disabled={
                  selectedArrivals.length === 0 || isRejectionInProgress
                }
                loading={isRejectionInProgress}
                text="Отклонить"
                onClick={async () => {
                  setIsRejectionInProgress(true);

                  try {
                    const ids = selectedArrivals.map(Number);
                    await rejectWagonArrivals(api, ids);

                    updateData(prevData => {
                      if (prevData) {
                        return {
                          ...prevData,
                          items: prevData.items.filter(
                            wagonArrival => !ids.includes(wagonArrival.id)
                          ),
                        };
                      }

                      return prevData;
                    });
                  } finally {
                    setIsRejectionInProgress(false);
                  }
                }}
              />
            )}

            {group === WagonArrivalGroup.Rejected && (
              <Button
                disabled={selectedArrivals.length === 0 || isMakeNewInProgress}
                loading={isMakeNewInProgress}
                text="Перенести в Новые"
                onClick={async () => {
                  setIsMakeNewInProgress(true);

                  try {
                    const ids = selectedArrivals.map(Number);
                    await makeWagonArrivalsNew(api, ids);

                    updateData(prevData => {
                      if (prevData) {
                        return {
                          ...prevData,

                          items: prevData.items.filter(
                            wagonArrival => !ids.includes(wagonArrival.id)
                          ),
                        };
                      }

                      return prevData;
                    });
                  } finally {
                    setIsMakeNewInProgress(false);
                  }
                }}
              />
            )}
          </Toolbar>
        </Row>

        {group === WagonArrivalGroup.Executed && (
          <Row>
            <Grid>
              <Col span={6}>
                <Toolbar>
                  <MonthsMultiSelectFilter
                    initialValue={arrivalMonthsFilterValue}
                    isFetching={isFetching}
                    label="Месяц прибытия"
                    monthDates={arrivalMonthsFilterDates}
                    onApply={newArrivalMonths => {
                      applyFilterParams({
                        arrivalMonths:
                          newArrivalMonths.length !== 0
                            ? newArrivalMonths
                                .map(date =>
                                  dayjs(date).format(DATE_FORMAT_API_DATE)
                                )
                                .join(',')
                            : undefined,
                      });
                    }}
                  />

                  <DateFilterInPopover
                    label="Дата прибытия"
                    initialValue={route.params.arrivalDate}
                    onApply={arrivalDate => {
                      applyFilterParams({ arrivalDate });
                    }}
                  />
                </Toolbar>
              </Col>

              <Col span={6}>
                <Toolbar align="right">
                  <MonthsMultiSelectFilter
                    initialValue={departureMonthsFilterValue}
                    isFetching={isFetching}
                    label="Месяц отправки"
                    monthDates={departureMonthsFilterDates}
                    onApply={newDepartureMonths => {
                      applyFilterParams({
                        departureMonths:
                          newDepartureMonths.length !== 0
                            ? newDepartureMonths
                                .map(date =>
                                  dayjs(date).format(DATE_FORMAT_API_DATE)
                                )
                                .join(',')
                            : undefined,
                      });
                    }}
                  />

                  <DateFilterInPopover
                    label="Дата отправки"
                    initialValue={route.params.departureDate}
                    onApply={departureDate => {
                      applyFilterParams({ departureDate });
                    }}
                  />
                </Toolbar>
              </Col>
            </Grid>
          </Row>
        )}

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : data.items.length === 0 ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <NonIdealState
                icon="list"
                description="Не найдено подходов удовлетворяющих критериям"
              />
            )
          ) : (
            <WagonArrivalsListTable
              arrivals={data.items}
              group={group}
              isFetching={isFetching}
              lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
              selectedArrivals={selectedArrivals}
              onSelectedArrivalsChange={setSelectedArrivals}
            />
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>

      {batchEditDialogForm.state && (
        <WagonArrivalsBatchEditDialog
          initialValues={batchEditDialogForm.state.initialValues}
          isOpen={batchEditDialogForm.state.isOpen}
          onClose={batchEditDialogForm.close}
          onClosed={batchEditDialogForm.destroy}
          onSubmit={async values => {
            try {
              await batchEditWagonArrivals(api, selectedArrivals.map(Number), {
                consignee: values.consignee,
                etranWaybillNumber: values.etranWaybillNumber,
                guNumber: values.guNumber,
                loadStation: values.loadStation,
              });

              refetch();
              batchEditDialogForm.close();

              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось отредактировать подходы: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}
    </>
  );
}

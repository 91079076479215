import { getScrollPos } from '_core/scroll';

export default function getBoundingDocumentRect(el: HTMLElement): ClientRect {
  const bcr = el.getBoundingClientRect();
  const scroll = getScrollPos();

  return {
    bottom: bcr.bottom + scroll.top,
    height: bcr.height,
    left: bcr.left + scroll.left,
    right: bcr.right + scroll.left,
    top: bcr.top + scroll.top,
    width: bcr.width,
  };
}

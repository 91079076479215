import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { indexByLastItem } from '_core/indexBy';
import { isNotNull } from '_core/isNotNull';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import { Row, VGrid } from 'layout/contentLayout';
import { fetchManyPartners } from 'partners/api';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { fetchManySuppliersDocuments } from 'suppliersDocuments/api';
import {
  fetchTechrunTransportation,
  moveTechrunTransportationsToGroup,
} from 'techrunTransportations/api';
import {
  ITechrunTransportation,
  techrunTransportationStatusFromGroup,
} from 'techrunTransportations/types';

import {
  ITechrunTransportationFormValues,
  TechrunTransportationForm,
} from './form';

export default function TechrunTransportationsEditRoute() {
  const { route, router } = useRouteNode('techruns.transportations.edit');
  const navigationLock = useFormNavigationLock('techruns.transportations.edit');
  const transportationId = Number(route.params.id);
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, transportationId],
    async () => {
      const transportation = await fetchTechrunTransportation(
        api,
        transportationId
      );

      const [partnersIndex, supplierDocumentsIndex] = await Promise.all([
        fetchManyPartners(
          api,
          transportation.expenses
            .map(expense => expense.partner)
            .filter(isNotNull)
        ).then(partners => indexByLastItem(partners, partner => partner.id)),
        fetchManySuppliersDocuments(
          api,
          transportation.expenses
            .map(expense =>
              expense.meta === 'rate'
                ? transportation.purchaseRateSupplierDocument
                : expense.meta === 'tariff'
                ? transportation.tariffSupplierDocument
                : expense.supplierDocument
            )
            .filter(isNotNull)
        ).then(supplierDocuments =>
          indexByLastItem(
            supplierDocuments,
            supplierDocument => supplierDocument.id
          )
        ),
      ]);

      const initialValues: ITechrunTransportationFormValues = {
        ...transportation,
        expenses: transportation.expenses.map(expense => ({
          ...expense,
          partner:
            expense.partner == null ? null : partnersIndex[expense.partner],
          supplierDocument:
            expense.supplierDocument == null
              ? null
              : supplierDocumentsIndex[expense.supplierDocument],
        })),
      };

      return { initialValues, transportation };
    }
  );

  const status = data
    ? techrunTransportationStatusFromGroup(data.transportation.group)
    : null;

  useEffect(() => {
    if (status == null || route.params.status !== status) {
      return;
    }

    router.navigate(
      'techruns.transportations.edit',
      { ...route.params, status },
      { replace: true }
    );
  }, [route.params, router, status]);

  const listParams = useMemo(
    () => ({ status, ...parseJsonWithFallback(route.params.listParams, {}) }),
    [route.params.listParams, status]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const persist = (values: ITechrunTransportationFormValues) =>
    api.put<ITechrunTransportation>(
      `/techrun_transportations/${data.transportation.id}`,
      {
        ...data,
        ...values,
        expenses: values.expenses.map(expense => ({
          ...expense,
          partner: expense.partner == null ? null : expense.partner.id,
          supplierDocument:
            expense.supplierDocument == null
              ? null
              : expense.supplierDocument.id,
        })),
      }
    );

  const title = data.transportation.transportationName;

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Отправки"
              params={listParams}
              to="techruns.transportations"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <TechrunTransportationForm
            initialValues={data.initialValues}
            navigationLock={navigationLock}
            transportation={data.transportation}
            onConfirmActiveDislocation={async () => {
              try {
                const updatedTransportation =
                  await api.post<ITechrunTransportation>(
                    `/techrun_transportations/${data.transportation.id}/change_active_dislocation`,
                    { confirm: true }
                  );

                updateData(
                  prevState =>
                    prevState && {
                      ...prevState,
                      transportation: updatedTransportation,
                    }
                );

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось подтвердить изменение номера накладной: Непредвиденная ошибка'
                );
              }
            }}
            onConfirmDepartureDate={async () => {
              try {
                const updatedTransportation =
                  await api.post<ITechrunTransportation>(
                    `/techrun_transportations/${data.transportation.id}/change_departure_date`,
                    {
                      confirm: true,
                    }
                  );

                updateData(
                  prevState =>
                    prevState && {
                      ...prevState,
                      transportation: updatedTransportation,
                    }
                );

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось подтвердить изменение даты отправления: Непредвиденная ошибка'
                );
              }
            }}
            onMoveToGroup={async group => {
              try {
                const updatedTransportations =
                  await moveTechrunTransportationsToGroup(
                    api,
                    [data.transportation.id],
                    group
                  );

                updateData(
                  prevState =>
                    prevState && {
                      ...prevState,
                      transportation: updatedTransportations[0],
                    }
                );

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось переместить отправку: Непредвиденная ошибка'
                );
              }
            }}
            onRejectActiveDislocation={async () => {
              try {
                const updatedTransportation =
                  await api.post<ITechrunTransportation>(
                    `/techrun_transportations/${data.transportation.id}/change_active_dislocation`,
                    { confirm: false }
                  );

                updateData(
                  prevState =>
                    prevState && {
                      ...prevState,
                      transportation: updatedTransportation,
                    }
                );

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось отклонить изменение номера накладной: Непредвиденная ошибка'
                );
              }
            }}
            onRejectDepartureDate={async () => {
              try {
                const updatedTransportation =
                  await api.post<ITechrunTransportation>(
                    `/techrun_transportations/${data.transportation.id}/change_departure_date`,
                    {
                      confirm: false,
                    }
                  );

                updateData(
                  prevState =>
                    prevState && {
                      ...prevState,
                      transportation: updatedTransportation,
                    }
                );

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось отклонить изменение даты отправления: Непредвиденная ошибка'
                );
              }
            }}
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('techruns.transportations', listParams);

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось сохранить отправку: Непредвиденная ошибка'
                );
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedTransportation = await persist(values);

                updateData(
                  prevState =>
                    prevState && {
                      ...prevState,
                      initialValues: values,
                      transportation: updatedTransportation,
                    }
                );

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось сохранить отправку: Непредвиденная ошибка'
                );
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

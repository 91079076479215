import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { ExpeditionStationType } from 'expeditions/types';
import { IExpeditionTransportation } from 'expeditionTransportations/types';

import {
  ExpeditionRequestStatus,
  IExpeditionRequestSerialized,
  IWagonsDailyUsage,
  IWagonsDailyUsagePause,
  IWagonsDailyUsageWagon,
} from './types';

export const EXPEDITION_REQUESTS_BASE_ENDPOINT = '/expeditions_requests';

export enum ExpeditionRequestsSortingField {
  EndDate = 'endDate',
  Name = 'requestName',
  StartDate = 'startDate',
}

export function fetchExpeditionRequests(
  api: ApiClient,
  {
    favoriteRequests,
    forDate,
    hasWagons,
    ids,
    page,
    pageSize,
    partner,
    search,
    sellProtocol,
    sorting,
    startMonths,
    statuses,
  }: {
    favoriteRequests?: boolean;
    forDate?: number;
    hasWagons?: string[];
    ids?: number[];
    page?: number;
    pageSize?: number;
    partner?: number;
    search?: string;
    sellProtocol?: number;
    sorting?: Array<ISorting<ExpeditionRequestsSortingField>>;
    startMonths?: Date[];
    statuses?: ExpeditionRequestStatus[];
  } = {}
) {
  return api.get<
    ListResponse<
      IExpeditionRequestSerialized,
      {
        filterOptions: {
          startDate: [string, string];
        };
      }
    >
  >(EXPEDITION_REQUESTS_BASE_ENDPOINT, {
    favoriteRequests,
    forDate: forDate && dayjs(new Date(forDate)).format(DATE_FORMAT_API_DATE),
    hasWagons:
      hasWagons && hasWagons.length !== 0 ? hasWagons.join(',') : undefined,
    ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
    ordering: sorting ? sortingToApiQueryParam(sorting, snakeCase) : undefined,
    page,
    pageSize,
    partner,
    search,
    sellProtocol,
    startMonths:
      startMonths && startMonths.length !== 0
        ? startMonths
            .map(date => dayjs(date).format(DATE_FORMAT_API_DATE))
            .join(',')
        : undefined,
    statuses:
      statuses && statuses.length !== 0 ? statuses.join(',') : undefined,
  });
}

export const fetchManyExpeditionRequests = makeFetchMany(
  fetchExpeditionRequests,
  'ids'
);

export interface IExpeditionRequest
  extends Omit<
    IExpeditionRequestSerialized,
    'endDate' | 'startDate' | 'station'
  > {
  endDate: Date;
  startDate: Date;
  station: number | null;
}

function deserializeExpeditionRequest(
  input: IExpeditionRequestSerialized
): IExpeditionRequest {
  return {
    ...input,
    endDate: parseDate(input.endDate),
    startDate: parseDate(input.startDate),
    station: input.station ?? null,
  };
}

export async function fetchExpeditionRequest(api: ApiClient, id: number) {
  const expeditionRequest = await api.get<IExpeditionRequestSerialized>(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${id}`
  );

  return deserializeExpeditionRequest(expeditionRequest);
}

interface ICreateOrUpdateExpeditionRequestParamsWagon {
  isFinished: boolean;
  wagon: string;
}

interface ICreateOrUpdateExpeditionRequestParams {
  endDate: Date | null;
  name: string;
  number: string;
  parks: number[];
  partner: number | null;
  sellProtocol: number | null;
  startDate: Date | null;
  station: number | null;
  stationType: ExpeditionStationType | null;
  status: ExpeditionRequestStatus;
  trackRepair: boolean;
  wagons: ICreateOrUpdateExpeditionRequestParamsWagon[];
  wagonType: number | null;
}

function serializeCreateOrUpdateExpeditionRequestParams({
  endDate,
  name,
  number,
  parks,
  partner,
  sellProtocol,
  startDate,
  station,
  stationType,
  status,
  trackRepair,
  wagonType,
  wagons,
}: ICreateOrUpdateExpeditionRequestParams) {
  return {
    endDate: endDate ? dayjs(endDate).format(DATE_FORMAT_API_DATE) : undefined,
    name,
    number,
    parks,
    partner,
    sellProtocol,
    startDate: startDate
      ? dayjs(startDate).format(DATE_FORMAT_API_DATE)
      : undefined,
    station,
    stationType,
    status,
    trackRepair,
    wagonType,
    wagons,
  };
}

export async function createExpeditionRequest(
  api: ApiClient,
  params: ICreateOrUpdateExpeditionRequestParams
) {
  const expeditionRequest = await api.post<IExpeditionRequestSerialized>(
    EXPEDITION_REQUESTS_BASE_ENDPOINT,
    serializeCreateOrUpdateExpeditionRequestParams(params)
  );

  return deserializeExpeditionRequest(expeditionRequest);
}

export async function updateExpeditionRequest(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateExpeditionRequestParams
) {
  const expeditionRequest = await api.put<IExpeditionRequestSerialized>(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateExpeditionRequestParams(params)
  );

  return deserializeExpeditionRequest(expeditionRequest);
}

export enum ExpeditionRequestsTreeSortingField {
  EndDate = 'endDate',
  Name = 'name',
  StartDate = 'startDate',
}

interface IFetchExpeditionRequestsTreeParams {
  favoriteRequests?: boolean;
  hasWagons?: string[];
  partner?: number;
  search?: string;
  sorting?: Array<ISorting<ExpeditionRequestsTreeSortingField>>;
  startMonths?: Date[];
  statuses?: ExpeditionRequestStatus[];
}

interface IExpeditionRequestsTreeProtocol {
  contract: number;
  id: number;
  name: string;
  requestsCount: number;
}

interface IExpeditionRequestsTreePartner {
  partner: number;
  partnerName: string;
  protocols: IExpeditionRequestsTreeProtocol[];
}

interface IExpeditionRequestsTree {
  meta: {
    filterOptions: {
      startDate: [string, string];
    };
  };
  results: IExpeditionRequestsTreePartner[];
}

export async function fetchExpeditionRequestsTree(
  api: ApiClient,
  {
    favoriteRequests,
    hasWagons,
    partner,
    search,
    sorting,
    startMonths,
    statuses,
  }: IFetchExpeditionRequestsTreeParams = {}
) {
  const response = await api.get<IExpeditionRequestsTree>(
    '/expeditions_requests/tree',
    {
      favoriteRequests,
      hasWagons:
        hasWagons && hasWagons.length !== 0 ? hasWagons.join(',') : undefined,
      ordering: sorting
        ? sortingToApiQueryParam(sorting, snakeCase)
        : undefined,
      partner,
      search,
      startMonths:
        startMonths && startMonths.length !== 0
          ? startMonths
              .map(date => dayjs(date).format(DATE_FORMAT_API_DATE))
              .join(',')
          : undefined,
      statuses:
        statuses && statuses.length !== 0 ? statuses.join(',') : undefined,
    }
  );

  const { startDate } = response.meta.filterOptions;

  const deserializedStartDate: [Date, Date] = [
    parseDate(startDate[0]),
    parseDate(startDate[1]),
  ];

  return {
    ...response,
    meta: {
      ...response.meta,
      filterOptions: {
        ...response.meta.filterOptions,
        startDate: deserializedStartDate,
      },
    },
  };
}

export interface ICreateExpeditionTransportationsFromXlsxResponse {
  createdRecords: IExpeditionTransportation[];
  recordsCount: number;
}

export function createExpeditionTransportationsFromXlsx(
  api: ApiClient,
  { file, requestId }: { file: string | null; requestId: number }
) {
  return api.post<ICreateExpeditionTransportationsFromXlsxResponse>(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${requestId}/create_transportations_from_xlsx`,
    { file }
  );
}

interface IFetchWagonsDailyUsageParams {
  startDate: Date;
  endDate: Date;
  wagonsList?: string[];
}

export function fetchWagonsDailyUsage(
  api: ApiClient,
  requestId: number,
  { startDate, endDate, wagonsList }: IFetchWagonsDailyUsageParams
) {
  return api.get<IWagonsDailyUsage>(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${requestId}/wagons_daily_usage`,

    {
      startDate: startDate && dayjs(startDate).format(DATE_FORMAT_API_DATE),
      endDate: endDate && dayjs(endDate).format(DATE_FORMAT_API_DATE),
      wagonsList:
        wagonsList && wagonsList.length !== 0
          ? wagonsList.join(',')
          : undefined,
    }
  );
}

interface IMakeShipmentForPeriodParams {
  startDate: Date;
}

export function makeShipmentForPeriod(
  api: ApiClient,
  requestId: number,
  { startDate }: IMakeShipmentForPeriodParams
) {
  return api.post(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${requestId}/make_shipment_for_period`,
    {
      startDate: dayjs(startDate).format(DATE_FORMAT_API_DATE),
    }
  );
}

interface IChangeWagonsUsagePeriodParams {
  endDate?: Date;
  startDate?: Date;
  wagons: string[];
}

export function changeWagonsUsagePeriod(
  api: ApiClient,
  requestId: number,
  { endDate, startDate, wagons }: IChangeWagonsUsagePeriodParams
) {
  return api.post(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${requestId}/change_wagons_usage_period`,

    {
      endDate: endDate && dayjs(endDate).format(DATE_FORMAT_API_DATE),
      startDate: startDate && dayjs(startDate).format(DATE_FORMAT_API_DATE),
      wagons,
    }
  );
}

interface IStartWagonsPauseParams {
  note: string;
  pauseDate: Date;
  wagons: string[];
}

export function startWagonsPause(
  api: ApiClient,
  requestId: number,
  { note, pauseDate, wagons }: IStartWagonsPauseParams
) {
  return api.post(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${requestId}/start_wagons_pause`,

    {
      pauseDate: dayjs(pauseDate).format(DATE_FORMAT_API_DATE),
      note,
      wagons,
    }
  );
}

interface IStopWagonsPauseParams {
  pauseDate: Date;
  wagons: string[];
}

export function stopWagonsPause(
  api: ApiClient,
  requestId: number,
  { pauseDate, wagons }: IStopWagonsPauseParams
) {
  return api.post(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${requestId}/stop_wagons_pause`,
    {
      wagons,
      pauseDate: dayjs(pauseDate).format(DATE_FORMAT_API_DATE),
    }
  );
}

interface IBulkUpdatePausesParams {
  pauses: IWagonsDailyUsagePause[];
}

export function bulkUpdatePauses(
  api: ApiClient,
  requestId: number,
  { pauses }: IBulkUpdatePausesParams
) {
  return api.post<IWagonsDailyUsagePause[]>(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${requestId}/bulk_update_pauses`,
    { pauses }
  );
}

export interface IBulkReplacePausesPause {
  startDate: Date;
  endDate: Date | null;
  note: string;
}

export interface IBulkReplacePausesWagon {
  wagon: string;
  startDate: Date;
  endDate: Date;
  pauses: IBulkReplacePausesPause[];
}

export interface IBulkReplacePausesParams {
  startDate: Date;
  endDate: Date;
  wagons: IBulkReplacePausesWagon[];
}

export function bulkReplacePauses(
  api: ApiClient,
  requestId: number,
  { startDate, endDate, wagons }: IBulkReplacePausesParams
) {
  return api.post<IWagonsDailyUsageWagon[]>(
    `${EXPEDITION_REQUESTS_BASE_ENDPOINT}/${requestId}/bulk_replace_pauses`,
    {
      wagonsUsage: {
        startDate: dayjs(startDate).format(DATE_FORMAT_API_DATE),
        endDate: dayjs(endDate).format(DATE_FORMAT_API_DATE),
        wagons: wagons.map(wagon => ({
          wagon: wagon.wagon,
          startDate: dayjs(wagon.startDate).format(DATE_FORMAT_API_DATE),
          endDate: dayjs(wagon.endDate).format(DATE_FORMAT_API_DATE),
          pauses: wagon.pauses.map(pause => ({
            startDate: dayjs(pause.startDate).format(DATE_FORMAT_API_DATE),
            endDate:
              pause.endDate == null
                ? null
                : dayjs(pause.endDate).format(DATE_FORMAT_API_DATE),
            note: pause.note,
          })),
        })),
      },
    }
  );
}

import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { required, validate } from '_core/forms/validators';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import {
  DateInputInFormGroup,
  DateInputInFormGroupForFinalForm,
} from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroupForFinalForm } from '_core/inputs/inputMoney';
import { SelectForFinalForm } from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { AnchorButton, Button, H4, Intent } from '@blueprintjs/core';
import { UsersAutocompleteInFormGroup } from 'accounts/autocomplete';
import { ContactsAutocompleteInFormGroupForFinalForm } from 'contacts/autocomplete';
import { IContractPurchase } from 'contractsPurchase/api';
import { IContractSell } from 'contractsSell/api';
import { fetchAllContractTemplates } from 'contractsTemplates/api';
import { DocumentStatusSelectForFinalForm } from 'documents/statusSelect';
import { DocumentStatus } from 'documents/types';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { useMemo } from 'react';
import { VatRate, vatRateOptions } from 'vatRates/vatRates';

import { IOwnersFieldItem, OwnersField } from './ownersField';

export interface IContractFormValues {
  additionalDowntimeRate: string;
  daysLoad: string;
  daysUnload: string;
  downtimeLimit: string;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  hasExpedition?: boolean;
  hasRent: boolean;
  hasRepair?: boolean;
  hasTechrun: boolean;
  number: string;
  owners: IOwnersFieldItem[];
  partner: number | null;
  partnerContact: number | null;
  partnerSign: number | null;
  signDate: Date | null;
  signPlace: string;
  status: DocumentStatus;
  template: number | null;
}

interface IProps {
  contract?: IContractPurchase | IContractSell;
  docxUrl?: string;
  editingLocked?: boolean;
  initialValues: IContractFormValues;
  navigationLock: IFormNavigationLock;
  numberIsRequired: boolean;
  onCancelEditing?: () => void;
  onStartEditing?: () => void;
  onSubmit: (
    values: IContractFormValues
  ) => Promise<SubmissionErrors | undefined>;
}

export function ContractForm({
  contract,
  docxUrl,
  editingLocked = false,
  initialValues,
  navigationLock,
  numberIsRequired,
  onCancelEditing,
  onStartEditing,
  onSubmit,
}: IProps) {
  const api = useApiClient();

  const { data, isFetching } = useAsyncData([api], () =>
    fetchAllContractTemplates(api).then(contractTemplates => ({
      templateOptions: contractTemplates.map(contractTemplate => ({
        label: contractTemplate.name,
        value: String(contractTemplate.id),
      })),
    }))
  );

  const maxDate = useMemo(() => new Date(), []);

  if (!data) {
    return isFetching ? <CenteredSpinner /> : <GenericErrorMessage />;
  }

  return (
    <FinalForm<IContractFormValues>
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      validate={validate({
        number: numberIsRequired ? required() : () => undefined,
      })}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            {docxUrl && (
              <Row>
                <Toolbar align="right">
                  <AnchorButton
                    download
                    href={docxUrl}
                    icon="download"
                    intent={Intent.SUCCESS}
                    rel="noopener"
                    target="_blank"
                    text="Скачать .docx"
                  />
                </Toolbar>
              </Row>
            )}

            <Row>
              <Grid>
                <Col span={4}>
                  {'hasExpedition' in initialValues && (
                    <CheckboxForFinalForm
                      editingLocked={editingLocked}
                      id="hasExpedition"
                      label="Содержит услуги экспедирования"
                      name="hasExpedition"
                    />
                  )}

                  <CheckboxForFinalForm
                    editingLocked={editingLocked}
                    id="hasTechrun"
                    label="Содержит услуги техрейса"
                    name="hasTechrun"
                  />

                  <CheckboxForFinalForm
                    editingLocked={editingLocked}
                    id="hasRent"
                    label="Содержит услуги аренды"
                    name="hasRent"
                  />

                  {'hasRepair' in initialValues && (
                    <CheckboxForFinalForm
                      editingLocked={editingLocked}
                      id="hasRepair"
                      label="Содержит услуги ремонта"
                      name="hasRepair"
                    />
                  )}

                  <FormGroupForFinalForm
                    label="Номер договора"
                    labelFor="number"
                    name="number"
                  >
                    <InputGroupForFinalForm
                      id="number"
                      name="number"
                      readOnly={editingLocked}
                      required={numberIsRequired}
                    />
                  </FormGroupForFinalForm>

                  <DateInputInFormGroupForFinalForm
                    editingLocked={editingLocked}
                    id="signDate"
                    label="Дата подписания"
                    name="signDate"
                    maxDate={maxDate}
                    required
                  />

                  <FormGroupForFinalForm
                    label="Город подписания"
                    labelFor="signPlace"
                    name="signPlace"
                  >
                    <InputGroupForFinalForm
                      id="signPlace"
                      name="signPlace"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Шаблон докумена"
                    labelFor="template"
                    name="template"
                  >
                    <SelectForFinalForm
                      editingLocked={editingLocked}
                      fill
                      id="template"
                      name="template"
                      options={data.templateOptions}
                      withEmptyOption
                    />
                  </FormGroupForFinalForm>

                  <DocumentStatusSelectForFinalForm
                    editingLocked={editingLocked}
                    id="status"
                    label="Статус"
                    name="status"
                  />

                  {contract && (
                    <Grid>
                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата создания"
                          readOnly
                          value={contract.created}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор"
                          readOnly
                          value={contract.author}
                        />
                      </Col>

                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата изменения"
                          readOnly
                          value={contract.modified}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор изменений"
                          readOnly
                          value={contract.authorModified}
                        />
                      </Col>
                    </Grid>
                  )}
                </Col>

                <Col span={4}>
                  <PartnersAutocompleteInFormGroupForFinalForm
                    id="partner"
                    label="Юридическое лицо"
                    name="partner"
                    required
                    editingLocked={editingLocked}
                    initialItem={contract?.partnerStored ?? undefined}
                    onChange={() => {
                      form.change('partnerSign', null);
                      form.change('partnerContact', null);
                    }}
                  />

                  <ContactsAutocompleteInFormGroupForFinalForm
                    addToQuery={{ partner: values.partner }}
                    disabled={!values.partner}
                    editingLocked={editingLocked}
                    id="partnerSign"
                    initialItem={contract?.signContactStored ?? undefined}
                    label="Подписывающий контакт"
                    name="partnerSign"
                    required
                    onChange={newPartnerSign => {
                      if (
                        newPartnerSign != null &&
                        values.partnerContact == null
                      ) {
                        form.change('partnerContact', newPartnerSign);
                      }
                    }}
                  />

                  <ContactsAutocompleteInFormGroupForFinalForm
                    addToQuery={{ partner: values.partner }}
                    disabled={!values.partner}
                    editingLocked={editingLocked}
                    id="partnerContact"
                    initialItem={contract?.mainContactStored ?? undefined}
                    label="Контактное лицо"
                    name="partnerContact"
                    required
                  />

                  <H4>Собственники</H4>

                  <OwnersField name="owners" readOnly={editingLocked} />
                </Col>

                <Col span={4}>
                  <FormGroupForFinalForm
                    label="Дней на погрузку"
                    labelFor="daysLoad"
                    name="daysLoad"
                  >
                    <InputGroupForFinalForm
                      id="daysLoad"
                      name="daysLoad"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Дней на выгрузку"
                    labelFor="daysUnload"
                    name="daysUnload"
                  >
                    <InputGroupForFinalForm
                      id="daysUnload"
                      name="daysUnload"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Доп. порог простоя"
                    labelFor="downtimeLimit"
                    name="downtimeLimit"
                  >
                    <InputGroupForFinalForm
                      id="downtimeLimit"
                      name="downtimeLimit"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <InputMoneyInFormGroupForFinalForm
                    id="downtimeRate"
                    label="Ставка за простой"
                    name="downtimeRate"
                    readOnly={editingLocked}
                  />

                  <FormGroupForFinalForm
                    label="Ставка НДС"
                    labelFor="downtimeVatRate"
                    name="downtimeVatRate"
                  >
                    <SelectForFinalForm
                      editingLocked={editingLocked}
                      fill
                      id="downtimeVatRate"
                      name="downtimeVatRate"
                      options={vatRateOptions}
                    />
                  </FormGroupForFinalForm>

                  <InputMoneyInFormGroupForFinalForm
                    id="additionalDowntimeRate"
                    label="Ставка за простой сверх доп. порога"
                    name="additionalDowntimeRate"
                    readOnly={editingLocked}
                  />
                </Col>
              </Grid>
            </Row>

            <Row>
              <H4>Файлы договора</H4>

              <FileInputMultipleForFinalForm
                editingLocked={editingLocked}
                id="files"
                name="files"
              />
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                {onStartEditing && (
                  <Button
                    disabled={submitting}
                    icon="unlock"
                    intent={Intent.DANGER}
                    text="Редактировать"
                    onClick={onStartEditing}
                  />
                )}

                {onCancelEditing && (
                  <Button
                    disabled={submitting}
                    text="Отменить"
                    onClick={() => {
                      form.reset();
                      onCancelEditing();
                    }}
                  />
                )}

                {!editingLocked && (
                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    text="Сохранить"
                    type="submit"
                  />
                )}
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

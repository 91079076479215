import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';

interface IProps {
  children?: React.ReactNode;
  listRoute: { name: string; params: Record<string, unknown> };
  title: string;
}

export function ExpeditionRequestFormRouteWrapper({
  children,
  listRoute,
  title,
}: IProps) {
  return (
    <VGrid stretch>
      <FormattedTitle>{title}</FormattedTitle>

      <Row>
        <Breadcrumbs>
          <BreadcrumbsItem
            label="Заявки"
            params={listRoute.params}
            to={listRoute.name}
          />

          <BreadcrumbsItem label={title} />
        </Breadcrumbs>
      </Row>

      <Row stretch>{children}</Row>
    </VGrid>
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { serializable } from '_core/serializable';
import dayjs from 'dayjs';
import {
  IRentWagonSellListItemSerialized,
  RentWagonSellListItem,
} from 'rentWagonSell/api';
import { VatRate } from 'vatRates/vatRates';

import { IUser } from '../accounts/types';

export const RENT_WAGON_PURCHASE_ENDPOINT = '/rent_wagon_purchase';

export enum RentWagonPurchasePauseCause {
  RoutineRepair = 'ROUTINE_REPAIR',
  ScheduledRepair = 'SCHEDULED_REPAIR',
  Paused = 'PAUSED',
}

interface IRentWagonPurchasePauseSerialized {
  id?: number;
  cause: RentWagonPurchasePauseCause;
  endDate: string | null;
  note: string;
  startDate: string;
  isCharging: boolean;
  authorModified: IUser | null;
  modified: string | null;
  duration: number | null;
}

interface IRentWagonPurchasePause
  extends Omit<
    IRentWagonPurchasePauseSerialized,
    'endDate' | 'startDate' | 'modified'
  > {
  endDate: Date | null;
  startDate: Date;
  modified: Date | null;
}

@serializable
export class RentWagonPurchasePause implements IRentWagonPurchasePause {
  readonly id;
  readonly cause;
  readonly isCharging;
  @serializable.date readonly endDate;
  readonly note;
  readonly authorModified;
  readonly modified;
  readonly duration;
  @serializable.date readonly startDate;

  static fromJSON(serialized: IRentWagonPurchasePauseSerialized) {
    return serializable.fromJSON(RentWagonPurchasePause, serialized);
  }

  constructor(pause: IRentWagonPurchasePause) {
    this.id = pause.id;
    this.cause = pause.cause;
    this.endDate = pause.endDate;
    this.note = pause.note;
    this.startDate = pause.startDate;
    this.isCharging = pause.isCharging;
    this.authorModified = pause.authorModified;
    this.modified = pause.modified;
    this.duration = pause.duration;
  }
}

export enum RentWagonPurchaseState {
  Active = 'ACTIVE',
  Repair = 'REPAIR',
  Paused = 'PAUSED',
  Inactive = 'INACTIVE',
}

interface IRentWagonPurchaseTreeMeta {
  dateRange: [string, string];
  rentsCount: number;
}

interface IRentWagonPurchaseTreeProtocol {
  contract: number;
  id: number;
  name: string;
  rentsCount: number;
}

interface IRentWagonPurchaseTreeSupplier {
  supplier: number;
  supplierName: string;
  protocols: IRentWagonPurchaseTreeProtocol[];
}

interface IRentWagonPurchaseTree {
  meta: IRentWagonPurchaseTreeMeta;
  results: IRentWagonPurchaseTreeSupplier[];
}

interface IFetchRentWagonPurchaseTreeParams {
  fromDate?: Date;
  search?: string;
  states?: RentWagonPurchaseState[];
  toDate?: Date;
  wagonNumbers?: string[];
}

export async function fetchRentWagonPurchaseTree(
  api: ApiClient,
  {
    fromDate,
    search,
    states,
    toDate,
    wagonNumbers,
  }: IFetchRentWagonPurchaseTreeParams = {}
) {
  const response = await api.get<IRentWagonPurchaseTree>(
    `${RENT_WAGON_PURCHASE_ENDPOINT}/tree`,
    {
      fromDate: dayjs(fromDate).format(DATE_FORMAT_API_DATE),
      search,
      states:
        states != null && states.length !== 0 ? states.join(',') : undefined,
      toDate: dayjs(toDate).format(DATE_FORMAT_API_DATE),
      wagonNumbers:
        wagonNumbers != null && wagonNumbers.length !== 0
          ? wagonNumbers.join(',')
          : undefined,
    }
  );

  return response;
}

interface IRentWagonPurchaseListItemSerialized {
  actFile: string | null;
  contract: number;
  endDate: string | null;
  id: number;
  pauses: IRentWagonPurchasePauseSerialized[];
  protocol: number;
  shortName: string;
  startDate: string;
  state: RentWagonPurchaseState;
  supplier: number;
  wagon: string;
}

interface IRentWagonPurchaseListItem
  extends Omit<
    IRentWagonPurchaseListItemSerialized,
    'endDate' | 'pauses' | 'startDate'
  > {
  endDate: Date | null;
  pauses: RentWagonPurchasePause[];
  startDate: Date;
}

@serializable
export class RentWagonPurchaseListItem implements IRentWagonPurchaseListItem {
  readonly actFile;
  readonly contract;
  @serializable.date readonly endDate;
  readonly id;
  @serializable.arrayOf(RentWagonPurchasePause) readonly pauses;
  readonly protocol;
  readonly shortName;
  @serializable.date readonly startDate;
  readonly state;
  readonly supplier;
  readonly wagon;

  static fromJSON(serialized: IRentWagonPurchaseListItemSerialized) {
    return serializable.fromJSON(RentWagonPurchaseListItem, serialized);
  }

  constructor(rentWagon: IRentWagonPurchaseListItem) {
    this.actFile = rentWagon.actFile;
    this.contract = rentWagon.contract;
    this.endDate = rentWagon.endDate;
    this.id = rentWagon.id;
    this.pauses = rentWagon.pauses;
    this.protocol = rentWagon.protocol;
    this.shortName = rentWagon.shortName;
    this.startDate = rentWagon.startDate;
    this.state = rentWagon.state;
    this.supplier = rentWagon.supplier;
    this.wagon = rentWagon.wagon;
  }
}

export async function fetchRentWagonPurchaseList(
  api: ApiClient,
  {
    fromDate,
    page,
    protocol,
    search,
    states,
    supplier,
    toDate,
    wagonNumbers,
  }: {
    fromDate?: Date;
    page?: number;
    protocol?: number;
    search?: string;
    states?: RentWagonPurchaseState[];
    supplier?: number;
    toDate?: Date;
    wagonNumbers?: string[];
  } = {}
): Promise<ListResponse<RentWagonPurchaseListItem>> {
  const response = await api.get<
    ListResponse<IRentWagonPurchaseListItemSerialized>
  >(RENT_WAGON_PURCHASE_ENDPOINT, {
    fromDate: dayjs(fromDate).format(DATE_FORMAT_API_DATE),
    page,
    protocol,
    search,
    states:
      states != null && states.length !== 0 ? states.join(',') : undefined,
    supplier,
    toDate: dayjs(toDate).format(DATE_FORMAT_API_DATE),
    wagonNumbers:
      wagonNumbers != null && wagonNumbers.length !== 0
        ? wagonNumbers.join(',')
        : undefined,
  });

  return {
    ...response,
    results: response.results.map(RentWagonPurchaseListItem.fromJSON),
  };
}

export async function acceptRentWagonPurchaseWagons(
  api: ApiClient,
  {
    protocol,
    startDate,
    supplier,
    wagons,
  }: {
    protocol: number | null;
    startDate: Date | null;
    supplier: number | null;
    wagons: string[];
  }
) {
  const { createdRents, renewableSellRents } = await api.post<{
    createdRents: IRentWagonPurchaseListItemSerialized[];
    renewableSellRents: IRentWagonSellListItemSerialized[];
  }>(RENT_WAGON_PURCHASE_ENDPOINT, {
    protocol,
    startDate:
      startDate == null ? null : dayjs(startDate).format(DATE_FORMAT_API_DATE),
    supplier,
    wagons,
  });

  return {
    createdRents: createdRents.map(RentWagonPurchaseListItem.fromJSON),
    renewableSellRents: renewableSellRents.map(RentWagonSellListItem.fromJSON),
  };
}

export async function closeRentWagonPurchaseWagons(
  api: ApiClient,
  {
    actFile,
    endDate,
    wagons,
  }: {
    actFile: string | null;
    endDate: Date;
    wagons: string[];
  }
) {
  const response = await api.post(`${RENT_WAGON_PURCHASE_ENDPOINT}/close`, {
    actFile,
    endDate: dayjs(endDate).format(DATE_FORMAT_API_DATE),
    wagons,
  });

  return response;
}

export async function startRentWagonPurchaseRepair(
  api: ApiClient,
  ids: number[]
) {
  const response = await api.post(
    `${RENT_WAGON_PURCHASE_ENDPOINT}/start_repair`,
    {},
    { query: { ids: ids.join(',') } }
  );

  return response;
}

export async function stopRentWagonPurchaseRepair(
  api: ApiClient,
  ids: number[]
) {
  const response = await api.post(
    `${RENT_WAGON_PURCHASE_ENDPOINT}/stop_repair`,
    {},
    { query: { ids: ids.join(',') } }
  );

  return response;
}

export async function startRentWagonPurchaseCalculation(
  api: ApiClient,
  ids: number[]
) {
  const response = await api.post(
    `${RENT_WAGON_PURCHASE_ENDPOINT}/start_calculation`,
    {},
    { query: { ids: ids.join(',') } }
  );

  return response;
}

export async function stopRentWagonPurchaseCalculation(
  api: ApiClient,
  ids: number[]
) {
  const response = await api.post(
    `${RENT_WAGON_PURCHASE_ENDPOINT}/stop_calculation`,
    {},
    { query: { ids: ids.join(',') } }
  );

  return response;
}

export interface IUpdateRentWagonPurchasePausesItem {
  id: number;
  pauses: RentWagonPurchasePause[];
}

export async function updateRentWagonPurchasePauses(
  api: ApiClient,
  rents: IUpdateRentWagonPurchasePausesItem[]
) {
  const updatedRentWagons = await api.post<
    IRentWagonPurchaseListItemSerialized[]
  >(`${RENT_WAGON_PURCHASE_ENDPOINT}/bulk_update_pauses`, { rents });

  return updatedRentWagons.map(RentWagonPurchaseListItem.fromJSON);
}

export enum RentWagonPurchaseExpenseType {
  Custom = 'CUSTOM',
  Rate = 'RATE',
}

interface IRentWagonPurchaseExpense {
  cost: string;
  costTotal: string;
  costVatValue: string;
  days: number | null;
  isCalculated: boolean;
  name: string;
  partner: number | null;
  supplierDoc: number | null;
  type: RentWagonPurchaseExpenseType;
  vatRate: VatRate;
  year: number | null;
}

export enum RentWagonPurchaseIncomeType {
  Custom = 'CUSTOM',
  Rate = 'RATE',
}

interface IRentWagonPurchaseIncome {
  cost: string;
  costTotal: string;
  costVatValue: string;
  days: number | null;
  isCalculated: boolean;
  name: string;
  partner: number | null;
  shipment: number | null;
  type: RentWagonPurchaseIncomeType;
  vatRate: VatRate;
  year: number | null;
}

interface IRentWagonPurchaseSellRent {
  id: number;
  shortName: string;
}

export interface IRentWagonPurchaseSerialized
  extends IRentWagonPurchaseListItemSerialized {
  durationInCurrentMonth: number;
  expenses: IRentWagonPurchaseExpense[];
  expensesCost: string;
  expensesCostTotal: string;
  expensesCostVatValue: string;
  incomes: IRentWagonPurchaseIncome[];
  incomesCost: string;
  incomesCostTotal: string;
  incomesCostVatValue: string;
  profit: string;
  sellRents: IRentWagonPurchaseSellRent[];
  trackRepair: boolean;
  wagonType: number;
}

interface IRentWagonPurchase
  extends Omit<
    IRentWagonPurchaseSerialized,
    'endDate' | 'pauses' | 'startDate'
  > {
  endDate: Date | null;
  pauses: RentWagonPurchasePause[];
  startDate: Date;
}

@serializable
export class RentWagonPurchase
  extends RentWagonPurchaseListItem
  implements IRentWagonPurchase
{
  readonly durationInCurrentMonth: number;
  readonly expenses: IRentWagonPurchaseExpense[];
  readonly expensesCost: string;
  readonly expensesCostTotal: string;
  readonly expensesCostVatValue: string;
  readonly incomes: IRentWagonPurchaseIncome[];
  readonly incomesCost: string;
  readonly incomesCostTotal: string;
  readonly incomesCostVatValue: string;
  readonly profit: string;
  readonly sellRents: IRentWagonPurchaseSellRent[];
  readonly trackRepair: boolean;
  readonly wagonType: number;

  static fromJSON(serialized: IRentWagonPurchaseSerialized) {
    return serializable.fromJSON(RentWagonPurchase, serialized);
  }

  constructor(rentWagon: IRentWagonPurchase) {
    super(rentWagon);
    this.durationInCurrentMonth = rentWagon.durationInCurrentMonth;
    this.expenses = rentWagon.expenses;
    this.expensesCost = rentWagon.expensesCost;
    this.expensesCostTotal = rentWagon.expensesCostTotal;
    this.expensesCostVatValue = rentWagon.expensesCostVatValue;
    this.incomes = rentWagon.incomes;
    this.incomesCost = rentWagon.incomesCost;
    this.incomesCostTotal = rentWagon.incomesCostTotal;
    this.incomesCostVatValue = rentWagon.incomesCostVatValue;
    this.profit = rentWagon.profit;
    this.sellRents = rentWagon.sellRents;
    this.trackRepair = rentWagon.trackRepair;
    this.wagonType = rentWagon.wagonType;
  }
}

export async function fetchRentWagonPurchase(api: ApiClient, id: number) {
  const rentWagon = await api.get<IRentWagonPurchaseSerialized>(
    `${RENT_WAGON_PURCHASE_ENDPOINT}/${id}`
  );

  return RentWagonPurchase.fromJSON(rentWagon);
}

export async function updateRentWagonPurchase(
  api: ApiClient,
  id: number,
  {
    endDate,
    pauses,
    startDate,
    ...otherParams
  }: {
    endDate: Date | null;
    expenses: IRentWagonPurchaseExpense[];
    incomes: IRentWagonPurchaseIncome[];
    pauses: RentWagonPurchasePause[];
    startDate: Date;
    trackRepair: boolean;
  }
) {
  const rentWagon = await api.put<IRentWagonPurchaseSerialized>(
    `${RENT_WAGON_PURCHASE_ENDPOINT}/${id}`,
    {
      ...otherParams,
      endDate:
        endDate == null ? null : dayjs(endDate).format(DATE_FORMAT_API_DATE),
      pauses: pauses.map(pause => ({
        ...pause,
        endDate:
          pause.endDate == null
            ? null
            : dayjs(pause.endDate).format(DATE_FORMAT_API_DATE),
        startDate: dayjs(pause.startDate).format(DATE_FORMAT_API_DATE),
      })),
      startDate: dayjs(startDate).format(DATE_FORMAT_API_DATE),
    }
  );

  return RentWagonPurchase.fromJSON(rentWagon);
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import { IContactSerialized } from 'contacts/api';
import dayjs from 'dayjs';
import { DocumentStatus } from 'documents/types';
import { IPartnerSerialized } from 'partners/api';
import { VatRate } from 'vatRates/vatRates';

const BASE_ENDPOINT = '/contracts_purchase';

export enum ContractsPurchaseService {
  Cleaning = 'CLEANING',
  ComRepair = 'COM_REPAIR',
  Custom = 'CUSTOM',
  Expedition = 'EXPEDITION',
  Preparation = 'PREPARATION',
  Rent = 'RENT',
  Repair = 'REPAIR',
  Supply = 'SUPPLY',
  Techrun = 'TECHRUN',
}

export function isContractsPurchaseService(
  input: string
): input is ContractsPurchaseService {
  return Object.values(ContractsPurchaseService).includes(
    input as ContractsPurchaseService
  );
}

export enum ContractsPurchaseSortingField {
  Number = 'number',
  SignDate = 'signDate',
  Name = 'name',
}

interface IFetchContractsPurchaseParams {
  page?: number;
  partner?: number;
  search?: string;
  service?: ContractsPurchaseService[];
  sorting?: Array<ISorting<ContractsPurchaseSortingField>>;
}

export interface IContractPurchaseSerialized {
  additionalDowntimeRate: string;
  agreementsCount: number;
  author: number | null;
  authorModified: number | null;
  created: string;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  hasCleaning: boolean;
  hasCommercialRepair: boolean;
  hasCustomOperations: boolean;
  hasExpedition: boolean;
  hasMaterialsSupply: boolean;
  hasPreparation: boolean;
  hasRent: boolean;
  hasTechrun: boolean;
  id: number;
  mainContactStored: IContactSerialized | null;
  modified: string;
  number: string;
  owners: number[];
  partner: number | null;
  partnerContact: number | null;
  partnerSign: number | null;
  partnerStored: IPartnerSerialized | null;
  rentProtocolsCount: number;
  signContactStored: IContactSerialized | null;
  signDate: string;
  signerAuthority: string;
  signPlace: string;
  status: DocumentStatus;
  techrunsProtocolsCount: number;
  template: number | null;
}

export interface IContractPurchase
  extends Omit<
    IContractPurchaseSerialized,
    'created' | 'modified' | 'signDate'
  > {
  created: Date;
  modified: Date;
  signDate: Date;
}

function deserializeContractPurchase({
  created,
  modified,
  signDate,
  ...otherProps
}: IContractPurchaseSerialized): IContractPurchase {
  return {
    ...otherProps,
    created: parseDate(created),
    modified: parseDate(modified),
    signDate: parseDate(signDate),
  };
}

export async function fetchContractsPurchase(
  api: ApiClient,
  { page, partner, search, service, sorting }: IFetchContractsPurchaseParams
): Promise<ListResponse<IContractPurchase>> {
  const response = await api.get<ListResponse<IContractPurchaseSerialized>>(
    BASE_ENDPOINT,
    {
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      page,
      partner,
      search,
      service: service && service.length !== 0 ? service.join(',') : undefined,
    }
  );

  return {
    ...response,
    results: response.results.map(deserializeContractPurchase),
  };
}

export async function fetchContractPurchase(api: ApiClient, id: number) {
  const contract = await api.get<IContractPurchaseSerialized>(
    `${BASE_ENDPOINT}/${id}`
  );

  return deserializeContractPurchase(contract);
}

interface ICreateOrUpdateContractPurchaseParams {
  additionalDowntimeRate: string;
  daysLoad: string;
  daysUnload: string;
  downtimeLimit: string;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  hasExpedition?: boolean;
  hasRent: boolean;
  hasRepair?: boolean;
  hasTechrun: boolean;
  number: string;
  owners: Array<number | null>;
  partner: number | null;
  partnerContact: number | null;
  partnerSign: number | null;
  signDate: Date | null;
  signPlace: string;
  status: DocumentStatus;
  template: number | null;
}

function serializeCreateOrUpdateContractPurchaseParams({
  signDate,
  ...otherParams
}: ICreateOrUpdateContractPurchaseParams) {
  return {
    ...otherParams,
    signDate: signDate && dayjs(signDate).format(DATE_FORMAT_API_DATE),
  };
}

export async function createContractPurchase(
  api: ApiClient,
  params: ICreateOrUpdateContractPurchaseParams
) {
  const createdContract = await api.post<IContractPurchaseSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateContractPurchaseParams(params)
  );

  return deserializeContractPurchase(createdContract);
}

export async function updateContractPurchase(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateContractPurchaseParams
) {
  const updatedContract = await api.put<IContractPurchaseSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateContractPurchaseParams(params)
  );

  return deserializeContractPurchase(updatedContract);
}

import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { serializable } from '_core/serializable';

interface IRailroadSerialized {
  code: string;
  fullName: string;
  id: number;
  name: string;
}

type IRailroad = IRailroadSerialized;

@serializable
export class Railroad implements IRailroad {
  readonly code: string;
  readonly fullName: string;
  readonly id: number;
  readonly name: string;

  static fromJSON(serialized: IRailroadSerialized) {
    return serializable.fromJSON(Railroad, serialized);
  }

  constructor(railroad: IRailroad) {
    this.code = railroad.code;
    this.fullName = railroad.fullName;
    this.id = railroad.id;
    this.name = railroad.name;
  }
}

interface IFetchRailroadsParams {
  ids?: number[];
  page?: number;
  pageSize?: number;
  search?: string;
}

export async function fetchRailroads(
  api: ApiClient,
  { ids, page, pageSize, search }: IFetchRailroadsParams
): Promise<ListResponse<Railroad>> {
  const response = await api.get<ListResponse<IRailroadSerialized>>(
    '/directories_railroads',
    {
      ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
      page,
      pageSize,
      search,
    }
  );

  return {
    ...response,
    results: response.results.map(Railroad.fromJSON),
  };
}

export const fetchManyRailroads = makeFetchMany(fetchRailroads, 'ids');

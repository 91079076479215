import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { without } from '_core/fp/without';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import {
  fetchExpeditionRequests,
  IExpeditionRequest,
  updateExpeditionRequest,
} from 'expeditionRequests/api';
import {
  ExpeditionRequestStatus,
  IExpeditionRequestSerialized,
} from 'expeditionRequests/types';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { WagonsActiveRequestsAlert } from 'requests/wagonsActiveRequestsAlert';
import { fetchAllWagonTypes } from 'wagonTypes/api';

import { ExpeditionRequestForm } from '../form/form';
import {
  IImportTransportationsDialogFormValues,
  ImportTransportationsDialog,
} from './importTransportationsDialog';

interface IProps {
  expeditionRequest: IExpeditionRequest;
  updateExpeditionRequest: (newExpeditionRequest: IExpeditionRequest) => void;
}

export function ExpeditionRequestMainTab({
  expeditionRequest,
  updateExpeditionRequest: updateExpeditionRequestLocalData,
}: IProps) {
  const { route, router } = useRouteNode('expeditions.requests.view.main');
  const navigationLock = useFormNavigationLock(
    'expeditions.requests.view.main'
  );

  const api = useApiClient();
  const [editingLocked, setEditingLocked] = useState(true);

  const [alertState, setAlertState] = useState<{
    activeRequestsWithAddedWagons: IExpeditionRequestSerialized[];
    addedWagons: string[];
    isOpen: boolean;
  } | null>(null);

  const initialValues = useMemo(
    () => ({
      endDate: expeditionRequest.endDate,
      name: expeditionRequest.name,
      number: expeditionRequest.number,
      partner: expeditionRequest.partner,
      sellProtocol: String(expeditionRequest.sellProtocol),
      startDate: expeditionRequest.startDate,
      station: expeditionRequest.station,
      stationType: expeditionRequest.stationType,
      status: expeditionRequest.status,
      trackRepair: expeditionRequest.trackRepair,
      wagonType: String(expeditionRequest.wagonType),
      wagons: expeditionRequest.wagons,
    }),
    [expeditionRequest]
  );

  const wagonTypesRequest = useAsyncData([api], () => fetchAllWagonTypes(api));

  const importTransportationsDialog = useDialog<{
    initialValues: IImportTransportationsDialogFormValues;
  }>();

  if (!wagonTypesRequest.data) {
    return wagonTypesRequest.isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    );
  }

  return (
    <>
      <ExpeditionRequestForm
        editingLocked={editingLocked}
        expeditionRequest={expeditionRequest}
        initialValues={initialValues}
        navigationLock={navigationLock}
        wagonTypes={wagonTypesRequest.data}
        onCancelEditing={
          editingLocked
            ? undefined
            : () => {
                setEditingLocked(true);
              }
        }
        onDuplicate={() => {
          router.navigate('expeditions.requests.create', {
            copyFrom: String(expeditionRequest.id),
            listParams: route.params.listParams,
          });
        }}
        onStartEditing={
          editingLocked
            ? () => {
                if (
                  // eslint-disable-next-line no-alert
                  window.confirm('Вы собираетесь изменить сохраненную заявку')
                ) {
                  setEditingLocked(false);
                }
              }
            : undefined
        }
        onImportClick={() => {
          importTransportationsDialog.open({ initialValues: { file: null } });
        }}
        onSubmit={async values => {
          try {
            const savedExpeditionRequest = await updateExpeditionRequest(
              api,
              expeditionRequest.id,
              {
                ...expeditionRequest,
                ...values,
                sellProtocol: values.sellProtocol
                  ? Number(values.sellProtocol)
                  : null,
                wagonType: values.wagonType ? Number(values.wagonType) : null,
              }
            );

            updateExpeditionRequestLocalData(savedExpeditionRequest);
            setEditingLocked(true);

            const addedWagons = without(
              initialValues.wagons.map(wagon => wagon.wagon),
              values.wagons.map(wagon => wagon.wagon)
            );

            if (addedWagons.length) {
              const response = await fetchAllPages(page =>
                fetchExpeditionRequests(api, {
                  forDate: Date.now(),
                  hasWagons: addedWagons,
                  page,
                  statuses: [
                    ExpeditionRequestStatus.Active,
                    ExpeditionRequestStatus.Created,
                  ],
                })
              );

              const requests = response.results;

              const activeRequestsWithAddedWagons = requests.filter(
                request => request.id !== expeditionRequest.id
              );

              if (activeRequestsWithAddedWagons.length !== 0) {
                setAlertState({
                  activeRequestsWithAddedWagons,
                  addedWagons,
                  isOpen: true,
                });
              }
            }

            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось изменить заявку: Непредвиденная ошибка'
            );
          }
        }}
      />

      {alertState && (
        <WagonsActiveRequestsAlert
          activeRequestsWithAddedWagons={
            alertState.activeRequestsWithAddedWagons
          }
          addedWagons={alertState.addedWagons}
          isOpen={alertState.isOpen}
          itemRoute="expeditions.requests.view"
          onClosed={() => {
            setAlertState(null);
          }}
          onConfirm={() => {
            setAlertState(
              prevState =>
                prevState && {
                  ...prevState,
                  isOpen: false,
                }
            );
          }}
        />
      )}

      {importTransportationsDialog.state && (
        <ImportTransportationsDialog
          initialValues={importTransportationsDialog.state.initialValues}
          isOpen={importTransportationsDialog.state.isOpen}
          requestId={expeditionRequest.id}
          onClose={importTransportationsDialog.close}
          onClosed={importTransportationsDialog.destroy}
        />
      )}
    </>
  );
}

export function omit<K extends string, T extends Record<K, unknown>>(
  props: readonly K[],
  object: T
) {
  const shouldBeOmitted = Object.fromEntries(props.map(prop => [prop, true]));

  return Object.fromEntries(
    Object.entries(object).filter(([prop]) => !shouldBeOmitted[prop])
  ) as Omit<T, K>;
}

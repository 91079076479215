import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import {
  ISorting,
  // SortingDirection
} from '_core/sorting';
import dayjs from 'dayjs';
import * as React from 'react';
import {
  IRepair,
  // RepairsSortingField
} from 'repairs/api';
import { getRepairTypeLabel } from 'repairs/utils';
import { Station } from 'stations/api';

// import { SortingInput } from '../../_core/sortingInput';
// import { Col, Grid, Row } from '../../layout/contentLayout';
import { VU36_DATE_IS_SET_COLOR } from './colors';

export interface IOurRepairsListTableItem
  extends Omit<IRepair, 'repairStation'> {
  repairStation: Station | null;
}

interface IProps {
  isFetching: boolean;
  items: IOurRepairsListTableItem[];
  sorting: ISorting;
  onSortingChange: (newSorting: ISorting) => void;
}

export function RepairsTable({
  isFetching,
  items,
  sorting,
  onSortingChange,
}: IProps) {
  const columns: Array<ListTableColumn<IOurRepairsListTableItem>> = [
    {
      id: 'wagon',
      label: 'Вагон',
      defaultWidth: 220,
      sortable: true,
      copyCellContent: repair => repair.wagon,
      renderCellContent: repair => (
        <Link params={{ id: repair.id }} to="ourRepairs.edit">
          {repair.wagon}
        </Link>
      ),
    },
    {
      id: 'wagonType',
      label: 'Тип',
      defaultWidth: 50,
      copyCellContent: repair => repair.wagonType ?? '',
      renderCellContent: repair => repair.wagonType,
    },
    {
      id: 'wagonOwner',
      label: 'Источник',
      defaultWidth: 250,
      copyCellContent: repair => repair.wagonOwner?.shortName ?? '',
      renderCellContent: repair =>
        repair.wagonOwner ? (
          <Link params={{ id: repair.wagonOwner.id }} to="partners.edit">
            {repair.wagonOwner.shortName}
          </Link>
        ) : null,
    },
    {
      id: 'renterName',
      label: 'Получатель',
      defaultWidth: 250,
      copyCellContent: repair => repair.renterName ?? '',
      renderCellContent: repair => repair.renterName,
    },
    {
      id: 'type',
      label: 'Тип ремонта',
      defaultWidth: 220,
      copyCellContent: repair => getRepairTypeLabel(repair.type),
      renderCellContent: repair => getRepairTypeLabel(repair.type),
    },
    {
      id: 'isEmpty',
      label: 'Груж/пор',
      defaultWidth: 100,
      copyCellContent: repair => (repair.isEmpty ? 'ПОР' : 'ГРУЖ'),
      renderCellContent: repair => (repair.isEmpty ? 'ПОР' : 'ГРУЖ'),
    },
    {
      id: 'repairStation',
      label: 'Станция отцепки',
      defaultWidth: 220,
      copyCellContent: repair => repair.repairStation?.name ?? '',
      renderCellContent: repair => repair.repairStation?.name,
    },
    {
      id: 'DEPO',
      label: 'Депо',
      defaultWidth: 100,
      copyCellContent: () => '',
      renderCellContent: () => null,
    },
    {
      id: 'currentStation',
      label: 'Ст. дисл.',
      defaultWidth: 220,
      copyCellContent: repair => repair.currentStation ?? '',
      renderCellContent: repair => repair.currentStation,
    },
    {
      id: 'startDate',
      label: 'Дата прибытия',
      defaultWidth: 130,
      copyCellContent: repair =>
        dayjs(repair.startDate).format(DATE_FORMAT_DATETIME),
      renderCellContent: repair =>
        dayjs(repair.startDate).format(DATE_FORMAT_DATETIME),
    },
    {
      id: 'repairStationDowntime',
      label: 'Пр. на ст. рем.',
      defaultWidth: 130,
      copyCellContent: repair => repair.repairStationDowntime?.toString() ?? '',
      renderCellContent: repair => repair.repairStationDowntime,
    },
    {
      id: 'vu23Date',
      label: 'Дата ВУ-23',
      defaultWidth: 130,
      copyCellContent: repair =>
        repair.vu23Date
          ? dayjs(repair.vu23Date).format(DATE_FORMAT_DATETIME)
          : '',
      renderCellContent: repair =>
        repair.vu23Date
          ? dayjs(repair.vu23Date).format(DATE_FORMAT_DATETIME)
          : null,
    },
    {
      id: 'defect',
      label: 'Неисправность',
      defaultWidth: 350,
      copyCellContent: repair => repair.defect,
      renderCellContent: repair => repair.defect,
    },
    {
      id: 'vu36Date',
      label: 'Дата ВУ-36',
      defaultWidth: 130,
      copyCellContent: repair =>
        repair.vu36Date
          ? dayjs(repair.vu36Date).format(DATE_FORMAT_DATETIME)
          : '',
      renderCellContent: repair =>
        repair.vu36Date
          ? dayjs(repair.vu36Date).format(DATE_FORMAT_DATETIME)
          : null,
    },
    {
      id: 'nspDowntime',
      label: 'Простой в НСП',
      defaultWidth: 150,
      copyCellContent: repair => repair.nspDowntime,
      renderCellContent: repair => repair.nspDowntime,
    },
    {
      id: 'arrivalStation',
      label: 'Станция назначения',
      defaultWidth: 220,
      copyCellContent: repair => repair.arrivalStation?.toString() ?? '',
      renderCellContent: repair => repair.arrivalStation || null,
    },
  ];

  const getRowColor = React.useCallback(
    (repair: IOurRepairsListTableItem) =>
      repair.vu36Date ? VU36_DATE_IS_SET_COLOR : undefined,
    []
  );

  const getItemId = React.useCallback(
    (repair: IOurRepairsListTableItem) => String(repair.id),
    []
  );

  // const defaultSorting: ISorting<RepairsSortingField> = {
  //   field: RepairsSortingField.Wagon,
  //   direction: SortingDirection.Descending,
  // };

  return !items ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : items.length === 0 ? (
    <EmptyListMessage />
  ) : (
    <>
      {/* <Row> */}
      {/*  <Grid> */}
      {/*    <Col align="end"> */}
      {/*      <SortingInput */}
      {/*        defaultValue={defaultSorting} */}
      {/*        options={columns */}
      {/*          .filter(column => column.sortable) */}
      {/*          .map(column => ({ */}
      {/*            label: column.label, */}
      {/*            value: column.id as RepairsSortingField, */}
      {/*          }))} */}
      {/*        value={sorting} */}
      {/*        onChange={onSortingChange} */}
      {/*      /> */}
      {/*    </Col> */}
      {/*  </Grid> */}
      {/* </Row> */}

      <ListTable
        columns={columns}
        getItemId={getItemId}
        isFetching={isFetching}
        items={items}
        maxHeight={500}
        getRowColor={getRowColor}
        sorting={sorting}
        stickyColumnCount={2}
        onSortingChange={onSortingChange}
      />
    </>
  );
}

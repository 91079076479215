import { ApiClient } from './client';
import { fetchAllPages } from './fetchAllPages';
import { ListResponse } from './types';

export function makeFetchAll<
  TFetchListFn extends (
    api: ApiClient,
    params?: { page?: number }
  ) => Promise<ListResponse<unknown>>,
  TParams extends Exclude<Parameters<TFetchListFn>[1], undefined>
>(fetchListFn: TFetchListFn) {
  return (api: ApiClient, params?: Omit<TParams, 'page'>) => {
    return fetchAllPages(
      page =>
        fetchListFn(api, { ...params, page }) as Promise<
          ListResponse<
            ReturnType<TFetchListFn> extends Promise<ListResponse<infer R>>
              ? R
              : never
          >
        >
    ).then(response => response.results);
  };
}

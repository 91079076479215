import { clamp } from '_core/fp/clamp';
import * as React from 'react';
import invariant from 'tiny-invariant';

import { Region } from './baseTable';
import * as css from './regions.module.css';

interface RenderRegionProps {
  style: React.CSSProperties;
}

export interface RenderableRegion {
  region: Region;
  render: (props: RenderRegionProps) => React.ReactNode;
}

interface RenderableRegionsContextValue {
  columnCount: number;
  measureColumns: (from: number, to: number) => number;
  measureRows: (from: number, to: number) => number;
  regions: RenderableRegion[];
  rowCount: number;
}

const RenderableRegionsContext =
  React.createContext<RenderableRegionsContextValue | null>(null);

if (__DEV__) {
  RenderableRegionsContext.displayName = 'RenderableRegionsContext';
}

export function RegionsProvider({
  children,
  columnCount,
  measureColumns,
  measureRows,
  regions,
  rowCount,
}: {
  children: React.ReactNode;
  columnCount: number;
  measureColumns: (from: number, to: number) => number;
  measureRows: (from: number, to: number) => number;
  regions: RenderableRegion[];
  rowCount: number;
}) {
  const value = React.useMemo(
    (): RenderableRegionsContextValue => ({
      columnCount,
      measureColumns,
      measureRows,
      regions,
      rowCount,
    }),
    [columnCount, measureColumns, measureRows, regions, rowCount]
  );

  return (
    <RenderableRegionsContext.Provider value={value}>
      {children}
    </RenderableRegionsContext.Provider>
  );
}

function useRenderableRegions() {
  const value = React.useContext(RenderableRegionsContext);
  invariant(value);
  return value;
}

interface RegionOffsetContextValue {
  top: number;
  left: number;
}

const RegionOffsetContext = React.createContext<RegionOffsetContextValue>({
  top: 0,
  left: 0,
});

if (__DEV__) {
  RegionOffsetContext.displayName = 'RegionOffsetContext';
}

export function RegionOffsetProvider({
  children,
  top,
  left,
}: {
  children: React.ReactNode;
  top: number;
  left: number;
}) {
  const value = React.useMemo(() => ({ top, left }), [top, left]);

  return (
    <RegionOffsetContext.Provider value={value}>
      {children}
    </RegionOffsetContext.Provider>
  );
}

export function SelectedRegion({ style }: { style: React.CSSProperties }) {
  return <div className={css.selectedRegionRoot} style={style} />;
}

export const RegionsContainer = React.memo(function RegionsContainer({
  colFrom,
  colTo,
  rowFrom,
  rowTo,
}: {
  colFrom: number;
  colTo: number;
  rowFrom: number;
  rowTo: number;
}) {
  const { columnCount, measureColumns, measureRows, regions, rowCount } =
    useRenderableRegions();

  const offset = React.useContext(RegionOffsetContext);

  return (
    <>
      {regions.map(({ region, render }, index) => {
        let startCol: number, endCol: number, startRow: number, endRow: number;

        if (region.cols) {
          const clampCols = clamp(0, columnCount - 1);
          startCol = clampCols(region.cols[0]);
          endCol = clampCols(region.cols[1]);
        } else {
          startCol = 0;
          endCol = columnCount - 1;
        }

        if (region.rows) {
          const clampRows = clamp(0, rowCount - 1);
          startRow = clampRows(region.rows[0]);
          endRow = clampRows(region.rows[1]);
        } else {
          startRow = 0;
          endRow = rowCount - 1;
        }

        // skip first row
        startRow++;
        endRow++;

        if (
          startCol > colTo ||
          endCol < colFrom ||
          startRow > rowTo ||
          endRow < rowFrom
        ) {
          return null;
        }

        return (
          <React.Fragment key={index}>
            {render({
              style: {
                position: 'absolute',
                top: measureRows(0, startRow) - offset.top - 1,
                left: measureColumns(0, startCol) - offset.left - 1,
                width: measureColumns(startCol, endCol + 1) + 1,
                height: measureRows(startRow, endRow + 1) + 1,
              },
            })}
          </React.Fragment>
        );
      })}
    </>
  );
});

import { ShipmentInfoType } from './api';

export const isTechrunShipmentInfoType = (type: ShipmentInfoType) =>
  type === ShipmentInfoType.Techrun ||
  type === ShipmentInfoType.TechrunDowntime;

export const getShipmentInfoTypeLabel = (type: ShipmentInfoType) =>
  ({
    [ShipmentInfoType.ExpeditionCost]: 'Отгрузка стоимости экспедирования',
    [ShipmentInfoType.ExpeditionTariff]: 'Отгрузка тарифа экспедирования',
    [ShipmentInfoType.Techrun]: 'Отгрузка техрейса',
    [ShipmentInfoType.TechrunDowntime]: 'Отгрузка простоев техрейса',
    [ShipmentInfoType.SellRentRate]: 'Отгрузка ставок исходящей аренды',
  }[type]);

export const shipmentInfoTypeOptions = [
  ShipmentInfoType.Techrun,
  ShipmentInfoType.TechrunDowntime,
  ShipmentInfoType.ExpeditionCost,
  ShipmentInfoType.ExpeditionTariff,
  ShipmentInfoType.SellRentRate,
].map(type => ({ label: getShipmentInfoTypeLabel(type), value: type }));

export function shipmentInfoTransportationsFieldNameForType(
  type: ShipmentInfoType
) {
  return isTechrunShipmentInfoType(type)
    ? 'transportationsTechrun'
    : 'transportationsExpeditions';
}

import { useApiClient } from '_core/api/context';
import { useToaster } from '_core/toaster/toasterContext';
import { Intent, ProgressBar } from '@blueprintjs/core';
import * as React from 'react';
import { fetchTasksGeneratedFiles, fetchTasksProcessedFiles } from 'tasks/api';

const ICON_NAME = 'cloud-download';

export function useAsyncTasks() {
  const api = useApiClient();
  const toaster = useToaster();

  return {
    waitForGeneratedFile: async (taskId: string) => {
      let cancelled = false;

      function handleDismiss() {
        cancelled = true;
      }

      const toastKey = toaster.show({
        icon: ICON_NAME,
        intent: Intent.PRIMARY,
        timeout: 0,
        onDismiss: handleDismiss,
        message: <ProgressBar intent={Intent.PRIMARY} />,
      });

      let { currentRow, file, totalRows } = await fetchTasksGeneratedFiles(
        api,
        taskId
      );

      while (!cancelled && !file) {
        toaster.show(
          {
            icon: ICON_NAME,
            intent: Intent.PRIMARY,
            timeout: 0,
            onDismiss: handleDismiss,
            message: (
              <ProgressBar
                intent={Intent.PRIMARY}
                value={currentRow / totalRows}
              />
            ),
          },
          toastKey
        );

        await new Promise(resolve => setTimeout(resolve, 1000));

        ({ currentRow, file, totalRows } = await fetchTasksGeneratedFiles(
          api,
          taskId
        ));
      }

      if (file) {
        toaster.show(
          {
            icon: ICON_NAME,
            intent: Intent.SUCCESS,
            timeout: 500,
            message: <ProgressBar intent={Intent.SUCCESS} />,
          },
          toastKey
        );

        window.open(file.url, '_blank', 'noopener');
      }
    },

    waitForProcessedFiles: async (taskId: string) => {
      let cancelled = false;

      function handleDismiss() {
        cancelled = true;
      }

      const toastKey = toaster.show({
        icon: ICON_NAME,
        intent: Intent.PRIMARY,
        timeout: 0,
        onDismiss: handleDismiss,
        message: <ProgressBar intent={Intent.PRIMARY} />,
      });

      let { createdDocs, isFinished, uploadedDocs } =
        await fetchTasksProcessedFiles(api, taskId);

      while (!cancelled && !isFinished) {
        toaster.show(
          {
            icon: ICON_NAME,
            intent: Intent.PRIMARY,
            timeout: 0,
            onDismiss: handleDismiss,
            message: (
              <ProgressBar
                intent={Intent.PRIMARY}
                value={createdDocs / uploadedDocs}
              />
            ),
          },
          toastKey
        );

        await new Promise(resolve => setTimeout(resolve, 1000));

        ({ createdDocs, isFinished, uploadedDocs } =
          await fetchTasksProcessedFiles(api, taskId));
      }

      if (isFinished) {
        toaster.show(
          {
            icon: ICON_NAME,
            intent: Intent.SUCCESS,
            timeout: 500,
            message: <ProgressBar intent={Intent.SUCCESS} />,
          },
          toastKey
        );
      }

      return { createdDocs, isFinished, uploadedDocs };
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function path<T extends Record<string, any>>(
  [prop, ...nextPath]: readonly string[],
  object: T | null | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return object != null && {}.hasOwnProperty.call(object, prop)
    ? nextPath.length === 0
      ? object[prop]
      : path(nextPath, object[prop])
    : undefined;
}

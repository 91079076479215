import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { Checkbox } from '_core/inputs/checkbox';
import { Pagination } from '_core/pagination';
import { LinkButton } from '_core/router5/linkButton';
import { Toolbar } from '_core/toolbar';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersFilter } from 'partners/filter';
import * as React from 'react';
import { useState } from 'react';
import { Router, State } from 'router5';
import { ArrDepOperStationsFilterInPopover } from 'stations/arrDepOperFilterInPopover';
import { TechrunRequestsFilterInPopover } from 'techrunRequests/filterInPopover';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

import {
  AddNewWagonsPopover,
  IAddNewWagonsFormValues,
} from './addNewWagonsPopover';
import {
  TechrunAssignmentsListTable,
  TechrunAssignmentsListTableItem,
} from './assignmentsListTable';
import { DistanceFilter } from './distanceFilter';
import {
  AssignmentNextRequestPopover,
  IAssignmentNextRequestFormValues,
} from './nextRequestPopover';

interface Props {
  data: {
    currentPage: number;
    items: TechrunAssignmentsListTableItem[];
    pageSize: number;
    totalPages: number;
  } | null;
  isFetching: boolean;
  isNewAssignmentsTab: boolean;
  route: State;
  router: Router;
  onAddNewWagonsSubmit?: (
    values: IAddNewWagonsFormValues
  ) => Promise<SubmissionErrors | void>;
  onGroupNextRequestSubmit: (
    ids: string[],
    values: IAssignmentNextRequestFormValues
  ) => Promise<SubmissionErrors | void>;
  onRowNextRequestSubmit: (
    assignment: TechrunAssignmentsListTableItem,
    values: IAssignmentNextRequestFormValues
  ) => Promise<SubmissionErrors | void>;
}

function getAssignmentNextRequestDisabledStateAndTooltip({
  assignments,
  selectedAssignments,
}: {
  assignments: TechrunAssignmentsListTableItem[] | null;
  selectedAssignments: string[];
}) {
  if (!assignments) {
    return {
      disabled: true,
      tooltip: 'Данные не загружены',
    };
  }

  if (selectedAssignments.length === 0) {
    return {
      disabled: true,
      tooltip: 'Не выбрано ни одного вагона',
    };
  }

  const supplierIdsSet = new Set<number | null>();

  assignments
    .filter(assignment => selectedAssignments.includes(String(assignment.id)))
    .forEach(assignment => {
      const supplierId = assignment.supplier ? assignment.supplier.id : null;

      supplierIdsSet.add(supplierId);
    });

  if (supplierIdsSet.size > 1) {
    return {
      disabled: true,

      tooltip:
        'Групповое распределение доступно только для вагонов от одного поставщика',
    };
  }

  return {
    disabled: false,
    tooltip: undefined,
  };
}

export function TechrunAssignmentsList({
  data,
  isFetching,
  isNewAssignmentsTab,
  route,
  router,
  onAddNewWagonsSubmit,
  onGroupNextRequestSubmit,
  onRowNextRequestSubmit,
}: Props) {
  const [
    isAssignmentNextRequestPopoverOpen,
    setIsAssignmentNextRequestPopoverOpen,
  ] = useState(false);

  const [selectedAssignments, setSelectedAssignments] = useState<string[]>([]);

  function applyFilterParams(params: Record<string, string | undefined>) {
    router.navigate(route.name, {
      ...route.params,
      page: undefined,
      ...params,
    });
  }

  const firstSelectedAssignmentId =
    selectedAssignments.length !== 0 ? selectedAssignments[0] : null;

  const firstSelectedAssignment =
    firstSelectedAssignmentId && data
      ? data.items.find(
          assignment => assignment.id === Number(firstSelectedAssignmentId)
        )
      : undefined;

  const firstSelectedAssignmentSupplierId =
    firstSelectedAssignment && firstSelectedAssignment.supplier
      ? firstSelectedAssignment.supplier.id
      : null;

  const nextRequestPopoverDisabledStateAndTooltip =
    getAssignmentNextRequestDisabledStateAndTooltip({
      assignments: data && data.items,
      selectedAssignments,
    });

  return (
    <VGrid stretch>
      <Row>
        <Toolbar align="right">
          {onAddNewWagonsSubmit && (
            <AddNewWagonsPopover onSubmit={onAddNewWagonsSubmit} />
          )}

          <AssignmentNextRequestPopover
            buttonTooltip={nextRequestPopoverDisabledStateAndTooltip.tooltip}
            disabled={nextRequestPopoverDisabledStateAndTooltip.disabled}
            initialValues={{
              exchangeOnFinish: false,
              nextRate: null,
              nextRequest: null,
            }}
            isOpen={isAssignmentNextRequestPopoverOpen}
            partnerIsRequired={isNewAssignmentsTab}
            showExchangeOnFinish={isNewAssignmentsTab}
            supplierId={firstSelectedAssignmentSupplierId}
            onInteraction={nextOpenState => {
              setIsAssignmentNextRequestPopoverOpen(nextOpenState);
            }}
            onSubmit={async values => {
              const errors = await onGroupNextRequestSubmit(
                selectedAssignments,
                values
              );

              if (errors) {
                return errors;
              }

              setIsAssignmentNextRequestPopoverOpen(false);

              return undefined;
            }}
          />

          <WagonNumbersFilter
            initialValue={route.params.wagonNumbers}
            onApply={wagonNumbers => {
              applyFilterParams({
                wagonNumbers,
              });
            }}
          />

          <ArrDepOperStationsFilterInPopover
            initialValue={{
              arrivalStation: route.params.arrivalStation,
              departureStation: route.params.departureStation,
              operationStation: route.params.operationStation,
              consignee: undefined,
            }}
            showConsigneeFilter
            onApply={({
              arrivalStation,
              departureStation,
              operationStation,
            }) => {
              applyFilterParams({
                arrivalStation:
                  arrivalStation == null ? undefined : String(arrivalStation),

                departureStation:
                  departureStation == null
                    ? undefined
                    : String(departureStation),

                operationStation:
                  operationStation == null
                    ? undefined
                    : String(operationStation),
              });
            }}
          />

          <PartnersFilter
            initialValue={route.params.partner}
            label="Клиент"
            onApply={partner => {
              applyFilterParams({ partner });
            }}
          />

          <TechrunRequestsFilterInPopover
            initialValue={route.params.request}
            label="Заявка"
            onApply={newRequest => {
              applyFilterParams({ request: newRequest });
            }}
          />

          <DistanceFilter
            initialValue={route.params.distanceLower}
            onApply={newDistanceLower => {
              applyFilterParams({ distanceLower: newDistanceLower });
            }}
          />

          <LinkButton
            options={{ reload: true }}
            params={route.params}
            text="Обновить"
            to={route.name}
          />

          <LinkButton text="Сбросить фильтры" to={route.name} />
        </Toolbar>
      </Row>

      <Row>
        <Grid>
          <Col align="end">
            <Checkbox
              checked={route.params.includeAssigned === 'True'}
              label="Показать распределённые"
              onChange={newIncludeAssigned => {
                applyFilterParams({
                  includeAssigned: newIncludeAssigned ? 'True' : undefined,
                });
              }}
            />
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        {!data ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <GenericErrorMessage />
          )
        ) : (
          <TechrunAssignmentsListTable
            assignments={data.items}
            isFetching={isFetching}
            isNewAssignmentsTab={isNewAssignmentsTab}
            lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
            selectedAssignments={selectedAssignments}
            onRowNextRequestSubmit={onRowNextRequestSubmit}
            onSelectedAssignmentsChange={setSelectedAssignments}
          />
        )}
      </Row>

      {data && (
        <Row>
          <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
        </Row>
      )}
    </VGrid>
  );
}

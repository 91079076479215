import { Currency } from 'currencies/types';
import { VatRate } from 'vatRates/vatRates';

export type InvoiceAlertColor = 'blue' | 'green' | 'pink' | 'red';

export enum InvoiceStatus {
  Created = 'CREATED',
  Approved = 'APPROVED',
  ForPayment = 'FORPAYMENT',
  Planned = 'PLANNED',
  Paid = 'PAID',
}

export enum InvoiceSortingFields {
  Partner = 'partner',
  PaymentBefore = 'paymentBefore',
  Status = 'status',
  PaidDatetime = 'paidDatetime',
}

export enum InvoiceVatType {
  Included = 'VATINCLUDED',
  Excluded = 'VATEXCLUDED',
  Zero = 'ZERO',
  NoVat = 'NOVAT',
}

export interface IInvoice {
  alertColor: InvoiceAlertColor | null;
  amount: string;
  bank: number | null;
  contract: number | null;
  created: string;
  createdUser: number | null;
  currency: Currency;
  date: string;
  forpaymentDatetime: string | null;
  forpaymentUser: number | null;
  id: number;
  note: string;
  number: string;
  paidDatetime: string | null;
  paidUser: number | null;
  partner: number | null;
  paymentAmount: string;
  paymentAmountTotal: string;
  paymentBefore: string;
  paymentPlanned: string | null;
  paymentString: string;
  plannedDatetime: string | null;
  plannedUser: number | null;
  scan: string | null;
  status: InvoiceStatus;
  vatRate: VatRate | null;
  vatRateUsed: VatRate | null;
  vatType: InvoiceVatType;
  vatValue: null;
}

export function getInvoiceAlertColor(invoice: {
  alertColor: InvoiceAlertColor | null;
}) {
  return invoice.alertColor
    ? {
        blue: '#48aff0',
        green: '#62d96b',
        pink: '#ff66a1',
        red: '#ff6e4a',
      }[invoice.alertColor]
    : undefined;
}

export const getInvoiceStatusLabel = (status: InvoiceStatus) =>
  ({
    [InvoiceStatus.Created]: 'Создан',
    [InvoiceStatus.Approved]: 'Согласован',
    [InvoiceStatus.ForPayment]: 'К оплате',
    [InvoiceStatus.Planned]: 'В плане',
    [InvoiceStatus.Paid]: 'Оплачен',
  }[status]);

export const invoiceStatusOptions = Object.values(InvoiceStatus).map(
  status => ({
    label: getInvoiceStatusLabel(status),
    value: status,
  })
);

export const invoiceVatTypeOptions = Object.values(InvoiceVatType).map(
  vatType => ({
    label: {
      [InvoiceVatType.Included]: 'Включён в сумму',
      [InvoiceVatType.Excluded]: 'Не включён в сумму',
      [InvoiceVatType.Zero]: '0%',
      [InvoiceVatType.NoVat]: 'не облагается',
    }[vatType],

    value: vatType,
  })
);

export interface IBank {
  id: number;
  name: string;
}

export const bankToOption = (bank: IBank) => ({
  label: bank.name,
  value: String(bank.id),
});

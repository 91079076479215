import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { serializable } from '_core/serializable';

const BASE_ENDPOINT = '/wagon_notes';

interface IWagonNoteSerialized {
  created: string;
  createdBy: number | null;
  dislocation: number;
  files: string[];
  id: number;
  note: string;
  operation: string;
  wagon: string;
}

interface IWagonNote extends Omit<IWagonNoteSerialized, 'created'> {
  created: Date;
}

@serializable
export class WagonNote implements IWagonNote {
  @serializable.datetime readonly created;
  readonly createdBy;
  readonly dislocation;
  readonly files;
  readonly id;
  readonly note;
  readonly operation;
  readonly wagon;

  static fromJSON(serialized: IWagonNoteSerialized) {
    return serializable.fromJSON(WagonNote, serialized);
  }

  constructor(wagonNote: IWagonNote) {
    this.created = wagonNote.created;
    this.createdBy = wagonNote.createdBy;
    this.dislocation = wagonNote.dislocation;
    this.files = wagonNote.files;
    this.id = wagonNote.id;
    this.note = wagonNote.note;
    this.operation = wagonNote.operation;
    this.wagon = wagonNote.wagon;
  }
}

export async function fetchWagonNotes(
  api: ApiClient,
  {
    page,
    pageSize,
    wagon,
  }: {
    page?: number;
    pageSize?: number;
    wagon?: string;
  } = {}
): Promise<ListResponse<WagonNote>> {
  const response = await api.get<ListResponse<IWagonNoteSerialized>>(
    BASE_ENDPOINT,
    {
      page,
      pageSize,
      wagon,
    }
  );

  return {
    ...response,
    results: response.results.map(WagonNote.fromJSON),
  };
}

export async function createWagonNote(
  api: ApiClient,
  {
    created,
    dislocation,
    files,
    note,
    operation,
    wagon,
  }: {
    created: Date;
    dislocation: number;
    files: string[];
    note: string;
    operation: string;
    wagon: string;
  }
) {
  await api.post(BASE_ENDPOINT, {
    created,
    dislocation,
    files,
    note,
    operation,
    wagon,
  });
}

export async function updateWagonNote(
  api: ApiClient,
  id: number,
  note: WagonNote
) {
  const response = await api.put<IWagonNoteSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    note
  );

  return WagonNote.fromJSON(response);
}

export async function deleteWagonNote(api: ApiClient, id: number) {
  await api.delete(`${BASE_ENDPOINT}/${id}`);
}

export function getDownloadWagonNotesXlsxUrl(api: ApiClient, wagon: string) {
  return api.buildApiUrl(`${BASE_ENDPOINT}/download_xlsx`, { wagon });
}

import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { Switch } from '_core/inputs/switch';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import { IConsigner } from 'consigners/types';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { StationFilterInPopover } from 'stations/stationFilterInPopover';
import { IStationSerialized } from 'stations/types';
import { fetchTechrunGu12Items, ITechrunGu12Item } from 'techrunGu12/api';
import { getTechrunGu12TypeLabel } from 'techrunGu12/utils';

interface ListTableItem
  extends Omit<
    ITechrunGu12Item,
    'arrivalStation' | 'consigner' | 'departureStation'
  > {
  arrivalStation: IStationSerialized;
  consigner: IConsigner | null;
  departureStation: IStationSerialized;
}

const propsToFetch = {
  arrivalStation: '/directories_trainstations',
  consigner: '/consigners',
  departureStation: '/directories_trainstations',
};

export default function TechrunsGu12ListRoute() {
  const { route, router } = useRouteNode('techruns.gu12');
  const api = useApiClient();

  const { data, isFetching, refetch } = useAsyncData([api, route], async () => {
    const { meta, results } = await fetchTechrunGu12Items(api, {
      arrivalStation: isFinite(route.params.arrivalStation)
        ? Number(route.params.arrivalStation)
        : undefined,
      departureStation: isFinite(route.params.departureStation)
        ? Number(route.params.departureStation)
        : undefined,
      includeFull: route.params.includeFull === 'True',
      page: isFinite(route.params.page) ? Number(route.params.page) : undefined,
      pageSize: isFinite(route.params.pageSize)
        ? Number(route.params.pageSize)
        : undefined,
    });

    return {
      currentPage: meta.currentPage,
      pageSize: meta.pageSize,
      totalPages: meta.totalPages,
      gu12Items: await (fetchRelated(
        api,
        propsToFetch,
        results
      ) as unknown as Promise<ListTableItem[]>),
    };
  });

  const listParams = JSON.stringify(route.params);

  const columns = useMemo(
    (): Array<ListTableColumn<ListTableItem>> => [
      {
        id: 'number',
        label: 'Номер',
        defaultWidth: 120,
        copyCellContent: techrunGu12Item => techrunGu12Item.number,
        renderCellContent: techrunGu12Item => (
          <Link
            params={{ id: techrunGu12Item.id, listParams }}
            to="techruns.gu12.edit"
          >
            {techrunGu12Item.number}
          </Link>
        ),
      },
      {
        id: 'type',
        label: 'Тип',
        defaultWidth: 100,
        copyCellContent: techrunGu12Item =>
          getTechrunGu12TypeLabel(techrunGu12Item.type),
        renderCellContent: techrunGu12Item =>
          getTechrunGu12TypeLabel(techrunGu12Item.type),
      },
      {
        id: 'consigner',
        label: 'Грузоотправитель',
        defaultWidth: 250,
        copyCellContent: techrunGu12Item =>
          techrunGu12Item.consigner ? techrunGu12Item.consigner.name : '',
        renderCellContent: techrunGu12Item =>
          techrunGu12Item.consigner ? (
            <Ellipsis component="span">
              {techrunGu12Item.consigner.name}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'startDate',
        label: 'Дата начала простоя',
        defaultWidth: 120,
        copyCellContent: techrunGu12Item =>
          techrunGu12Item.startDate
            ? dayjs(techrunGu12Item.startDate).format(DATE_FORMAT_DATETIME)
            : '',
        renderCellContent: techrunGu12Item =>
          techrunGu12Item.startDate
            ? dayjs(techrunGu12Item.startDate).format(DATE_FORMAT_DATETIME)
            : null,
      },
      {
        id: 'endDate',
        label: 'Дата окончания простоя',
        defaultWidth: 120,
        copyCellContent: techrunGu12Item =>
          techrunGu12Item.endDate
            ? dayjs(techrunGu12Item.endDate).format(DATE_FORMAT_DATETIME)
            : '',
        renderCellContent: techrunGu12Item =>
          techrunGu12Item.endDate
            ? dayjs(techrunGu12Item.endDate).format(DATE_FORMAT_DATETIME)
            : null,
      },
      {
        id: 'arrivalStation',
        label: 'Станция назначения',
        defaultWidth: 140,
        copyCellContent: techrunGu12Item => techrunGu12Item.arrivalStation.name,
        renderCellContent: techrunGu12Item =>
          techrunGu12Item.arrivalStation.name,
      },
      {
        id: 'departureStation',
        label: 'Станция отправления',
        defaultWidth: 140,
        copyCellContent: techrunGu12Item =>
          techrunGu12Item.departureStation.name,
        renderCellContent: techrunGu12Item =>
          techrunGu12Item.departureStation.name,
      },
      {
        id: 'wagonsQuantity',
        label: 'Количество вагонов',
        defaultWidth: 120,
        copyCellContent: techrunGu12Item =>
          techrunGu12Item.wagonsQuantity == null
            ? '∞'
            : String(techrunGu12Item.wagonsQuantity),
        renderCellContent: techrunGu12Item =>
          techrunGu12Item.wagonsQuantity == null
            ? '∞'
            : techrunGu12Item.wagonsQuantity,
      },
      {
        id: 'wagonsLeft',
        label: 'Осталось вагонов',
        defaultWidth: 120,
        copyCellContent: techrunGu12Item =>
          techrunGu12Item.wagonsLeft == null
            ? '∞'
            : String(techrunGu12Item.wagonsLeft),
        renderCellContent: techrunGu12Item =>
          techrunGu12Item.wagonsLeft == null ? '∞' : techrunGu12Item.wagonsLeft,
      },
    ],
    [listParams]
  );

  const getItemId = useCallback(
    (techrunGu12Item: ListTableItem) => String(techrunGu12Item.id),
    []
  );

  return (
    <>
      <FormattedTitle>Документы ГУ-12</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Toolbar align="right">
            <StationFilterInPopover
              initialValue={route.params.arrivalStation}
              label="Станция назначения"
              onApply={arrivalStation => {
                router.navigate(route.name, {
                  ...route.params,
                  arrivalStation: arrivalStation || undefined,
                });
              }}
            />

            <StationFilterInPopover
              initialValue={route.params.departureStation}
              label="Станция отправления"
              onApply={departureStation => {
                router.navigate(route.name, {
                  ...route.params,
                  departureStation: departureStation || undefined,
                });
              }}
            />

            <Button text="Обновить" onClick={refetch} />

            <LinkButton text="Сбросить фильтры" to={route.name} />

            <LinkButton
              intent={Intent.PRIMARY}
              params={{ listParams }}
              text="Добавить"
              to="techruns.gu12.create"
            />
          </Toolbar>
        </Row>

        <Row>
          <Grid>
            <Col align="end">
              <Switch
                checked={route.params.includeFull === 'True'}
                label="Показывать исчерпанные"
                onChange={includeFull => {
                  router.navigate(route.name, {
                    ...route.params,
                    includeFull: includeFull ? 'True' : undefined,
                  });
                }}
              />
            </Col>
          </Grid>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <ListTable
              columns={columns}
              getItemId={getItemId}
              isFetching={isFetching}
              items={data.gu12Items}
              lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
            />
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>
    </>
  );
}

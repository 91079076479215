import { ORG_NAME } from '_core/config';
import * as React from 'react';
import { Title } from 'react-head';

export function FormattedTitle({
  children,
  ...otherProps
}: React.HTMLAttributes<HTMLTitleElement>) {
  return (
    <Title {...otherProps}>
      {children}
      {ORG_NAME && ` - ${ORG_NAME}`}
    </Title>
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { serializable } from '_core/serializable';
import dayjs from 'dayjs';
import { VatRate } from 'vatRates/vatRates';

import { IUser } from '../accounts/types';

const RENT_WAGON_SELL_ENDPOINT = '/rent_wagon_sell';

export enum RentWagonSellPauseCause {
  RoutineRepair = 'ROUTINE_REPAIR',
  ScheduledRepair = 'SCHEDULED_REPAIR',
  Paused = 'PAUSED',
}

interface IRentWagonSellPauseSerialized {
  id?: number;
  cause: RentWagonSellPauseCause;
  endDate: string | null;
  note: string;
  startDate: string;
  isCharging: boolean;
  authorModified: IUser | null;
  modified: string | null;
  duration: number | null;
}

interface IRentWagonSellPause
  extends Omit<
    IRentWagonSellPauseSerialized,
    'endDate' | 'startDate' | 'modified'
  > {
  endDate: Date | null;
  startDate: Date;
  modified: Date | null;
}

interface IMakeShipmentForPeriodParams {
  wagons: string[];
  startDate: Date;
  endDate: Date;
  protocol: number;
}

@serializable
export class RentWagonSellPause implements IRentWagonSellPause {
  readonly id;
  readonly cause;
  readonly isCharging;
  @serializable.date readonly endDate;
  readonly note;
  readonly authorModified;
  readonly modified;
  readonly duration;
  @serializable.date readonly startDate;

  static fromJSON(serialized: IRentWagonSellPauseSerialized) {
    return serializable.fromJSON(RentWagonSellPause, serialized);
  }

  constructor(pause: IRentWagonSellPause) {
    this.id = pause.id;
    this.cause = pause.cause;
    this.endDate = pause.endDate;
    this.note = pause.note;
    this.startDate = pause.startDate;
    this.isCharging = pause.isCharging;
    this.authorModified = pause.authorModified;
    this.modified = pause.modified;
    this.duration = pause.duration;
  }
}

export enum RentWagonSellState {
  Active = 'ACTIVE',
  Repair = 'REPAIR',
  Paused = 'PAUSED',
  Inactive = 'INACTIVE',
}

interface IRentWagonSellTreeMeta {
  dateRange: [string, string];
  rentsCount: number;
}

interface IRentWagonSellTreeProtocol {
  contract: number;
  id: number;
  name: string;
  rentsCount: number;
  shipment: number | null;
}

interface IRentWagonSellTreeSupplier {
  supplier: number;
  supplierName: string;
  protocols: IRentWagonSellTreeProtocol[];
}

interface IRentWagonSellTree {
  meta: IRentWagonSellTreeMeta;
  results: IRentWagonSellTreeSupplier[];
}

interface IFetchRentWagonSellTreeParams {
  fromDate?: Date;
  search?: string;
  states?: RentWagonSellState[];
  toDate?: Date;
  wagonNumbers?: string[];
}

export async function fetchRentWagonSellTree(
  api: ApiClient,
  {
    fromDate,
    search,
    states,
    toDate,
    wagonNumbers,
  }: IFetchRentWagonSellTreeParams = {}
) {
  const response = await api.get<IRentWagonSellTree>(
    `${RENT_WAGON_SELL_ENDPOINT}/tree`,
    {
      fromDate: dayjs(fromDate).format(DATE_FORMAT_API_DATE),
      search,
      states:
        states != null && states.length !== 0 ? states.join(',') : undefined,
      toDate: dayjs(toDate).format(DATE_FORMAT_API_DATE),
      wagonNumbers:
        wagonNumbers != null && wagonNumbers.length !== 0
          ? wagonNumbers.join(',')
          : undefined,
    }
  );

  return response;
}

export interface IRentWagonSellListItemSerialized {
  actFile: string | null;
  contract: number;
  endDate: string | null;
  id: number;
  pauses: IRentWagonSellPauseSerialized[];
  protocol: number;
  shortName: string;
  startDate: string;
  state: RentWagonSellState;
  supplier: number;
  wagon: string;
}

interface IRentWagonSellListItem
  extends Omit<
    IRentWagonSellListItemSerialized,
    'endDate' | 'pauses' | 'startDate'
  > {
  endDate: Date | null;
  pauses: RentWagonSellPause[];
  startDate: Date;
}

@serializable
export class RentWagonSellListItem implements IRentWagonSellListItem {
  readonly actFile;
  readonly contract;
  @serializable.date readonly endDate;
  readonly id;
  @serializable.arrayOf(RentWagonSellPause) readonly pauses;
  readonly protocol;
  readonly shortName;
  @serializable.date readonly startDate;
  readonly state;
  readonly supplier;
  readonly wagon;

  static fromJSON(serialized: IRentWagonSellListItemSerialized) {
    return serializable.fromJSON(RentWagonSellListItem, serialized);
  }

  constructor(rentWagon: IRentWagonSellListItem) {
    this.actFile = rentWagon.actFile;
    this.contract = rentWagon.contract;
    this.endDate = rentWagon.endDate;
    this.id = rentWagon.id;
    this.pauses = rentWagon.pauses;
    this.protocol = rentWagon.protocol;
    this.shortName = rentWagon.shortName;
    this.startDate = rentWagon.startDate;
    this.state = rentWagon.state;
    this.supplier = rentWagon.supplier;
    this.wagon = rentWagon.wagon;
  }
}

export async function fetchRentWagonSellList(
  api: ApiClient,
  {
    fromDate,
    page,
    protocol,
    search,
    states,
    supplier,
    toDate,
    wagonNumbers,
  }: {
    fromDate?: Date;
    page?: number;
    protocol?: number;
    search?: string;
    states?: RentWagonSellState[];
    supplier?: number;
    toDate?: Date;
    wagonNumbers?: string[];
  } = {}
): Promise<ListResponse<RentWagonSellListItem>> {
  const response = await api.get<
    ListResponse<IRentWagonSellListItemSerialized>
  >(RENT_WAGON_SELL_ENDPOINT, {
    fromDate: dayjs(fromDate).format(DATE_FORMAT_API_DATE),
    page,
    protocol,
    search,
    states:
      states != null && states.length !== 0 ? states.join(',') : undefined,
    supplier,
    toDate: dayjs(toDate).format(DATE_FORMAT_API_DATE),
    wagonNumbers:
      wagonNumbers != null && wagonNumbers.length !== 0
        ? wagonNumbers.join(',')
        : undefined,
  });

  return {
    ...response,
    results: response.results.map(RentWagonSellListItem.fromJSON),
  };
}

export async function acceptRentWagonSellWagons(
  api: ApiClient,
  {
    protocol,
    startDate,
    supplier,
    wagons,
  }: {
    protocol: number | null;
    startDate: Date | null;
    supplier: number | null;
    wagons: string[];
  }
) {
  const response = await api.post<IRentWagonSellListItemSerialized[]>(
    RENT_WAGON_SELL_ENDPOINT,
    {
      protocol,
      startDate:
        startDate == null
          ? null
          : dayjs(startDate).format(DATE_FORMAT_API_DATE),
      supplier,
      wagons,
    }
  );

  return response.map(RentWagonSellListItem.fromJSON);
}

export async function closeRentWagonSellWagons(
  api: ApiClient,
  {
    actFile,
    endDate,
    wagons,
  }: {
    actFile: string | null;
    endDate: Date;
    wagons: string[];
  }
) {
  const response = await api.post(`${RENT_WAGON_SELL_ENDPOINT}/close`, {
    actFile,
    endDate: dayjs(endDate).format(DATE_FORMAT_API_DATE),
    wagons,
  });

  return response;
}

export async function makeShipmentForPeriod(
  api: ApiClient,
  { wagons, startDate, endDate, protocol }: IMakeShipmentForPeriodParams
) {
  const response = await api.post(
    `${RENT_WAGON_SELL_ENDPOINT}/make_shipment_for_period`,
    {
      startDate: dayjs(startDate).format(DATE_FORMAT_API_DATE),
      endDate: dayjs(endDate).format(DATE_FORMAT_API_DATE),
      wagons,
      protocol,
    }
  );

  return response;
}

export async function startRentWagonSellRepair(api: ApiClient, ids: number[]) {
  const response = await api.post(
    `${RENT_WAGON_SELL_ENDPOINT}/start_repair`,
    {},
    { query: { ids: ids.join(',') } }
  );

  return response;
}

export async function stopRentWagonSellRepair(api: ApiClient, ids: number[]) {
  const response = await api.post(
    `${RENT_WAGON_SELL_ENDPOINT}/stop_repair`,
    {},
    { query: { ids: ids.join(',') } }
  );

  return response;
}

export async function startRentWagonSellCalculation(
  api: ApiClient,
  ids: number[]
) {
  const response = await api.post(
    `${RENT_WAGON_SELL_ENDPOINT}/start_calculation`,
    {},
    { query: { ids: ids.join(',') } }
  );

  return response;
}

export async function stopRentWagonSellCalculation(
  api: ApiClient,
  ids: number[]
) {
  const response = await api.post(
    `${RENT_WAGON_SELL_ENDPOINT}/stop_calculation`,
    {},
    { query: { ids: ids.join(',') } }
  );

  return response;
}

export interface IUpdateRentWagonSellPausesItem {
  id: number;
  pauses: RentWagonSellPause[];
}

export async function updateRentWagonSellPauses(
  api: ApiClient,
  rents: IUpdateRentWagonSellPausesItem[]
) {
  const updatedRentWagons = await api.post<IRentWagonSellListItemSerialized[]>(
    `${RENT_WAGON_SELL_ENDPOINT}/bulk_update_pauses`,
    { rents }
  );

  return updatedRentWagons.map(RentWagonSellListItem.fromJSON);
}

export enum RentWagonSellExpenseType {
  Custom = 'CUSTOM',
  Rate = 'RATE',
}

interface IRentWagonSellExpense {
  cost: string;
  costTotal: string;
  costVatValue: string;
  days: number | null;
  isCalculated: boolean;
  name: string;
  partner: number | null;
  supplierDoc: number | null;
  type: RentWagonSellExpenseType;
  vatRate: VatRate;
  year: number | null;
}

export enum RentWagonSellIncomeType {
  Custom = 'CUSTOM',
  Rate = 'RATE',
}

interface IRentWagonSellIncome {
  cost: string;
  costTotal: string;
  costVatValue: string;
  days: number | null;
  isCalculated: boolean;
  name: string;
  partner: number | null;
  shipment: number | null;
  type: RentWagonSellIncomeType;
  vatRate: VatRate;
  year: number | null;
}

export interface IRentWagonSellSerialized
  extends IRentWagonSellListItemSerialized {
  durationInCurrentMonth: number;
  expenses: IRentWagonSellExpense[];
  expensesCost: string;
  expensesCostTotal: string;
  expensesCostVatValue: string;
  incomes: IRentWagonSellIncome[];
  incomesCost: string;
  incomesCostTotal: string;
  incomesCostVatValue: string;
  profit: string;
  purchaseRent: number | null;
  trackRepair: boolean;
  wagonType: number;
}

interface IRentWagonSell
  extends Omit<IRentWagonSellSerialized, 'endDate' | 'pauses' | 'startDate'> {
  endDate: Date | null;
  pauses: RentWagonSellPause[];
  startDate: Date;
}

@serializable
export class RentWagonSell
  extends RentWagonSellListItem
  implements IRentWagonSell
{
  readonly durationInCurrentMonth: number;
  readonly expenses: IRentWagonSellExpense[];
  readonly expensesCost: string;
  readonly expensesCostTotal: string;
  readonly expensesCostVatValue: string;
  readonly incomes: IRentWagonSellIncome[];
  readonly incomesCost: string;
  readonly incomesCostTotal: string;
  readonly incomesCostVatValue: string;
  readonly profit: string;
  readonly purchaseRent: number | null;
  readonly trackRepair: boolean;
  readonly wagonType: number;

  static fromJSON(serialized: IRentWagonSellSerialized) {
    return serializable.fromJSON(RentWagonSell, serialized);
  }

  constructor(rentWagon: IRentWagonSell) {
    super(rentWagon);
    this.durationInCurrentMonth = rentWagon.durationInCurrentMonth;
    this.expenses = rentWagon.expenses;
    this.expensesCost = rentWagon.expensesCost;
    this.expensesCostTotal = rentWagon.expensesCostTotal;
    this.expensesCostVatValue = rentWagon.expensesCostVatValue;
    this.incomes = rentWagon.incomes;
    this.incomesCost = rentWagon.incomesCost;
    this.incomesCostTotal = rentWagon.incomesCostTotal;
    this.incomesCostVatValue = rentWagon.incomesCostVatValue;
    this.profit = rentWagon.profit;
    this.purchaseRent = rentWagon.purchaseRent;
    this.trackRepair = rentWagon.trackRepair;
    this.wagonType = rentWagon.wagonType;
  }
}

export async function fetchRentWagonSell(api: ApiClient, id: number) {
  const rentWagon = await api.get<IRentWagonSellSerialized>(
    `${RENT_WAGON_SELL_ENDPOINT}/${id}`
  );

  return RentWagonSell.fromJSON(rentWagon);
}

export async function updateRentWagonSell(
  api: ApiClient,
  id: number,
  {
    endDate,
    pauses,
    startDate,
    ...otherParams
  }: {
    endDate: Date | null;
    expenses: IRentWagonSellExpense[];
    incomes: IRentWagonSellIncome[];
    pauses: RentWagonSellPause[];
    startDate: Date;
    trackRepair: boolean;
  }
) {
  const rentWagon = await api.put<IRentWagonSellSerialized>(
    `${RENT_WAGON_SELL_ENDPOINT}/${id}`,
    {
      ...otherParams,
      endDate:
        endDate == null ? null : dayjs(endDate).format(DATE_FORMAT_API_DATE),
      pauses: pauses.map(pause => ({
        ...pause,
        endDate:
          pause.endDate == null
            ? null
            : dayjs(pause.endDate).format(DATE_FORMAT_API_DATE),
        startDate: dayjs(pause.startDate).format(DATE_FORMAT_API_DATE),
      })),
      startDate: dayjs(startDate).format(DATE_FORMAT_API_DATE),
    }
  );

  return RentWagonSell.fromJSON(rentWagon);
}

import { ITransportationExpense } from 'transportations/types';
import { VatRate } from 'vatRates/vatRates';
import { IDislocationSerialized } from 'wagons/api';

export enum TechrunTransportationAccountingState {
  NotClosed = 'NOTCLOSED',
  Closed = 'CLOSED',
  BuhClosed = 'BUHCLOSED',
}

export function getTechrunTransportationAccountingStateLabel(
  state: TechrunTransportationAccountingState
) {
  return {
    [TechrunTransportationAccountingState.NotClosed]: 'Не закрыта',
    [TechrunTransportationAccountingState.Closed]: 'Закрыта',
    [TechrunTransportationAccountingState.BuhClosed]:
      'Закрыта бух. документами',
  }[state];
}

export const allTechrunTransportationAccountingStates = [
  TechrunTransportationAccountingState.NotClosed,
  TechrunTransportationAccountingState.Closed,
  TechrunTransportationAccountingState.BuhClosed,
];

export const techrunTransportationAccountingStateOptions =
  allTechrunTransportationAccountingStates.map(state => ({
    label: getTechrunTransportationAccountingStateLabel(state),
    value: state,
  }));

export enum TechrunTransportationGroup {
  Confirmed = 'CONFIRMED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Supplementary = 'SUPPLEMENTARY',
}

export const getTechrunTransportationGroupLabel = (
  group: TechrunTransportationGroup
) =>
  ({
    [TechrunTransportationGroup.Confirmed]: 'Подтверждённые',
    [TechrunTransportationGroup.New]: 'Новые',
    [TechrunTransportationGroup.Rejected]: 'Отклонённые',
    [TechrunTransportationGroup.Supplementary]: 'Служебные',
  }[group]);

export const techrunTransportationStatusFromGroup = (
  group: TechrunTransportationGroup
) => group.toLowerCase();

export function techrunTransportationGroupFromRouteStatus(status: string) {
  const group = status.toUpperCase();

  const validValues = Object.values(TechrunTransportationGroup);

  if (validValues.includes(group as TechrunTransportationGroup)) {
    return group as TechrunTransportationGroup;
  }

  throw new Error(
    [
      `Given invalid transportation group of "${group}" (status: "${status}"),`,
      `expected one of ${JSON.stringify(validValues)}`,
    ].join(' ')
  );
}

export interface ITechrunTransportationIncome {
  cost: string;
  costTotal: string;
  costVatValue: string;
  isCalculated: boolean;
  name: string;
  vatRate: VatRate;
}

export enum TechrunTransportationState {
  Wait = 'WAIT',
  Load = 'LOAD',
  OnWay = 'ONWAY',
  Arrived = 'ARRIVED',
  Finished = 'FINISHED',
}

export function isTechrunTransportationState(
  str: string
): str is TechrunTransportationState {
  return Object.values(TechrunTransportationState).includes(
    str as TechrunTransportationState
  );
}

export function getTechrunTransportationStateLabel(
  state: TechrunTransportationState
) {
  return {
    [TechrunTransportationState.Wait]: 'Подход',
    [TechrunTransportationState.Load]: 'Погрузка',
    [TechrunTransportationState.OnWay]: 'В пути',
    [TechrunTransportationState.Arrived]: 'Прибыл',
    [TechrunTransportationState.Finished]: 'Завершена',
  }[state];
}

export const techrunTransportationStateOptions = [
  TechrunTransportationState.Wait,
  TechrunTransportationState.Load,
  TechrunTransportationState.OnWay,
  TechrunTransportationState.Arrived,
  TechrunTransportationState.Finished,
].map(state => ({
  label: getTechrunTransportationStateLabel(state),
  value: state,
}));

enum SellRateType {
  Day = 'DAY',
  Ton = 'TON',
  Wagon = 'WAGON',
}

export interface ITechrunTransportation {
  accountingState: TechrunTransportationAccountingState;
  arrivalBeforeLoad: string | null;
  arrivalDate: string | null;
  arrivalStation: number;
  cargoWeight: string;
  consigner: string;
  consignerShort: string;
  created: string;
  departureAfterUnload: string;
  departureDate: string;
  departureDateNext: string;
  departureStation: number;
  dislocationActive: IDislocationSerialized;
  dislocationCreated: IDislocationSerialized;
  dislocationNext: IDislocationSerialized | null;
  duration: number;
  emptyTransportations: number[];
  expenses: ITransportationExpense[];
  expensesTotal: string;
  group: TechrunTransportationGroup;
  groupsTransitionRoute: TechrunTransportationGroup[];
  hasDuplicate: boolean;
  id: number;
  income: ITechrunTransportationIncome[];
  isConsignerSame: boolean;
  isDepartureDateChanged: boolean;
  isEmpty: boolean;
  isWaybillNumberChanged: boolean;
  modified: string;
  note: string;
  operationStation: number;
  owner: number | null;
  profit: string;
  purchaseRateSupplierDocument: number | null;
  purchaseRent: number | null;
  request: number | null;
  sellRateTotal: string;
  sellRateType: SellRateType;
  shipments: number[];
  state: TechrunTransportationState;
  supplierDocumentNumber: { id: number; number: string } | null;
  tariffRate: string | null;
  tariffIvc: string | null;
  tariffOverride: string | null;
  tariffSupplierDocument: number | null;
  transportationName: string;
  wagon: string;
  waybillNumber: string;
}

import * as React from 'react';
import { GridOnItemsRenderedProps } from 'react-window';
import invariant from 'tiny-invariant';

export const dummyRenderedItemsValue: GridOnItemsRenderedProps = {
  overscanColumnStartIndex: 0,
  overscanColumnStopIndex: 0,
  overscanRowStartIndex: 0,
  overscanRowStopIndex: 0,
  visibleColumnStartIndex: 0,
  visibleColumnStopIndex: 0,
  visibleRowStartIndex: 0,
  visibleRowStopIndex: 0,
};

const Context = React.createContext<GridOnItemsRenderedProps | null>(null);

if (__DEV__) {
  Context.displayName = 'RenderedItemsContext';
}

interface Props {
  children: React.ReactNode;
  renderedItems: GridOnItemsRenderedProps;
}

export function RenderedItemsProvider({ children, renderedItems }: Props) {
  return <Context.Provider value={renderedItems}>{children}</Context.Provider>;
}

export function useRenderedItems() {
  const value = React.useContext(Context);
  invariant(value);
  return value;
}

import { TechrunProtocolRateType } from './types';

function getTechrunProtocolRateTypeLabel(rateType: TechrunProtocolRateType) {
  return {
    [TechrunProtocolRateType.Wagon]: 'За вагон',
    [TechrunProtocolRateType.Ton]: 'За тонну',
  }[rateType];
}

export const techrunProtocolRateTypeOptions = [
  TechrunProtocolRateType.Wagon,
  TechrunProtocolRateType.Ton,
].map(rateType => ({
  label: getTechrunProtocolRateTypeLabel(rateType),
  value: rateType,
}));

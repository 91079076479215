import getBoundingDocumentRect from '_core/getBoundingDocumentRect';
import KeyCodes from '_core/keyCodes';
import { shallowEqual } from '_core/shallowEqual';
import { useDebouncedValue } from '_core/useDebouncedValue';
import { InputGroup, Intent, Portal } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';

import * as css from './inputWithSuggest.module.css';

interface Props<TSuggestionItem> {
  autoFocus?: boolean;
  disabled?: boolean;
  editingLocked?: boolean;
  fetchSuggestions: (query: string) => Promise<TSuggestionItem[]>;
  getSuggestionLabel: (data: TSuggestionItem) => string;
  id?: string;
  isInvalid?: boolean;
  name?: string;
  placeholder?: string;
  type?: string;
  value: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (newValue: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onSuggestionSelect: (data: TSuggestionItem) => void;
}

export function InputWithSuggest<TSuggestionItem>({
  autoFocus,
  disabled,
  editingLocked,
  fetchSuggestions,
  getSuggestionLabel,
  id,
  isInvalid,
  name,
  placeholder,
  type,
  value,
  onBlur,
  onChange,
  onFocus,
  onSuggestionSelect,
}: Props<TSuggestionItem>) {
  const [suggestIsOpen, setSuggestIsOpen] = React.useState(false);
  const [suggestItems, setSuggestItems] = React.useState<TSuggestionItem[]>([]);

  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState<
    number | null
  >(null);

  const [query, setQuery] = React.useState('');

  const selectSuggestion = (suggestion: TSuggestionItem) => {
    onSuggestionSelect(suggestion);
    setQuery('');
    setSuggestItems([]);
    setSuggestIsOpen(false);
    setSelectedOptionIndex(null);
  };

  const debouncedQuery = useDebouncedValue(query, 400);
  React.useEffect(() => {
    if (!debouncedQuery) {
      return;
    }

    let cancelled = false;

    fetchSuggestions(debouncedQuery).then(items => {
      if (cancelled) {
        return;
      }

      setSuggestIsOpen(true);
      setSuggestItems(items);
      setSelectedOptionIndex(null);
    });

    return () => {
      cancelled = true;
    };
  }, [debouncedQuery, fetchSuggestions]);

  const [suggestStyle, setSuggestStyle] = React.useState<
    React.CSSProperties | undefined
  >();

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  React.useLayoutEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const bdr = getBoundingDocumentRect(inputRef.current);
    const newStyle = { left: bdr.left, minWidth: bdr.width, top: bdr.bottom };

    if (!shallowEqual(suggestStyle, newStyle)) {
      setSuggestStyle(newStyle);
    }
  }, [suggestIsOpen, suggestStyle]);

  const suggestRootRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (!suggestIsOpen) {
      return;
    }

    function handleWindowClick(event: MouseEvent) {
      const input = inputRef.current;
      const suggestRoot = suggestRootRef.current;

      if (
        !(event.target instanceof Node) ||
        (input && input.contains(event.target)) ||
        (suggestRoot && suggestRoot.contains(event.target))
      ) {
        return;
      }

      setSuggestIsOpen(false);
    }

    window.addEventListener('click', handleWindowClick, false);

    return () => {
      window.removeEventListener('click', handleWindowClick, false);
    };
  }, [suggestIsOpen]);

  return (
    <>
      <InputGroup
        autoFocus={autoFocus}
        disabled={disabled}
        id={id}
        inputRef={inputRef}
        intent={isInvalid ? Intent.DANGER : undefined}
        name={name}
        placeholder={disabled ? undefined : placeholder}
        readOnly={editingLocked}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.currentTarget.value;

          if (onChange) {
            onChange(newValue);
          }

          setQuery(newValue);
        }}
        onFocus={onFocus}
        onKeyDown={event => {
          if (!suggestIsOpen) {
            return;
          }

          switch (event.keyCode) {
            case KeyCodes.Enter:
              if (selectedOptionIndex != null) {
                selectSuggestion(suggestItems[selectedOptionIndex]);
                event.preventDefault();
              }
              break;

            case KeyCodes.Up:
              setSelectedOptionIndex(prevSelectedOptionIndex => {
                if (prevSelectedOptionIndex == null) {
                  return suggestItems.length - 1;
                } else if (prevSelectedOptionIndex === 0) {
                  return null;
                }

                return prevSelectedOptionIndex - 1;
              });

              event.preventDefault();
              break;

            case KeyCodes.Down:
              setSelectedOptionIndex(prevSelectedOptionIndex => {
                if (prevSelectedOptionIndex == null) {
                  return 0;
                } else if (
                  prevSelectedOptionIndex ===
                  suggestItems.length - 1
                ) {
                  return null;
                }

                return prevSelectedOptionIndex + 1;
              });

              event.preventDefault();
              break;
          }
        }}
      />

      {suggestIsOpen && (
        <Portal>
          <div
            className={css.portalContent}
            ref={suggestRootRef}
            style={suggestStyle}
          >
            <ul className={css.optionList}>
              {suggestItems.map((suggestion, index) => (
                <li
                  key={index}
                  className={cx(css.option, {
                    [css.option_active]: selectedOptionIndex === index,
                  })}
                  onClick={() => {
                    selectSuggestion(suggestion);
                  }}
                >
                  {getSuggestionLabel(suggestion)}
                </li>
              ))}
            </ul>
          </div>
        </Portal>
      )}
    </>
  );
}

import { useApiClient } from '_core/api/context';
import { FilterPopoverUi, useFilterPopover } from '_core/filters/filterPopover';
import { useAsyncData } from '_core/useAsyncData';
import { useDebouncedValue } from '_core/useDebouncedValue';
import { Button, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import * as React from 'react';

import { fetchManyStations, fetchStations, Station } from './api';
import * as css from './multiSelectFilter.module.css';

interface IProps {
  initialValue: string | undefined;
  onApply: (newStationIds: string) => void;
}

export function StationsMultiSelectFilter({ initialValue, onApply }: IProps) {
  const api = useApiClient();
  const [query, setQuery] = React.useState('');
  const debouncedQuery = useDebouncedValue(query, 400);

  const { data, isFetching } = useAsyncData([debouncedQuery, api], () =>
    !debouncedQuery
      ? Promise.resolve([])
      : fetchStations(api, {
          pageSize: 9,
          search: debouncedQuery,
        }).then(response => response.results)
  );

  const [selectedStations, setSelectedStations] = React.useState<Station[]>([]);

  React.useEffect(() => {
    const ids = initialValue ? initialValue.split(',').map(Number) : [];

    fetchManyStations(api, ids).then(setSelectedStations);
  }, [api, initialValue]);

  const filterPopover = useFilterPopover<string | undefined, number[]>(
    initialValue,
    onApply,
    {
      emptyValue: undefined,
      isEmpty: value => !value,
      deserialize: value => (value ? value.split(',').map(Number) : []),
      serialize: value => (value.length ? value.join(',') : undefined),
    }
  );

  return (
    <FilterPopoverUi api={filterPopover.ui} label="Станции">
      <div className={css.main}>
        <MultiSelect
          noResults={
            isFetching ? (
              <Spinner />
            ) : (
              <MenuItem disabled text="Не удалось найти станции по запросу" />
            )
          }
          initialContent={<MenuItem disabled text="Введите запрос" />}
          items={data ?? []}
          itemRenderer={(station, { modifiers, handleClick }) =>
            !modifiers.matchesPredicate ? null : (
              <MenuItem
                active={modifiers.active}
                icon={
                  filterPopover.value.includes(station.id) ? 'tick' : 'blank'
                }
                key={station.id}
                shouldDismissPopover={false}
                text={station.name}
                onClick={handleClick}
              />
            )
          }
          placeholder="Наименование станции..."
          popoverProps={{
            targetTagName: 'div',
            usePortal: false,
            wrapperTagName: 'div',
          }}
          query={query}
          selectedItems={selectedStations}
          tagInputProps={{
            fill: true,
            rightElement:
              filterPopover.value.length > 0 ? (
                <Button
                  icon="cross"
                  minimal
                  onClick={() => {
                    setSelectedStations([]);
                    filterPopover.change([]);
                  }}
                />
              ) : undefined,
            onRemove: (_tag, indexToRemove) => {
              setSelectedStations(prevState =>
                prevState.filter((_item, index) => index !== indexToRemove)
              );

              filterPopover.change(
                filterPopover.value.filter(
                  (_id, index) => index !== indexToRemove
                )
              );
            },
          }}
          tagRenderer={station => station.name}
          onItemSelect={station => {
            setSelectedStations(prevState =>
              selectedStations.findIndex(p => p.id === station.id) === -1
                ? prevState.concat(station)
                : prevState
            );

            if (filterPopover.value.includes(station.id)) {
              filterPopover.change(
                filterPopover.value.filter(v => v !== station.id)
              );
            } else {
              filterPopover.change(filterPopover.value.concat([station.id]));
            }
          }}
          onQueryChange={setQuery}
        />
      </div>
    </FilterPopoverUi>
  );
}

export const getScrollTop = () =>
  window.pageYOffset || document.documentElement.scrollTop;

export const getScrollLeft = () =>
  window.pageXOffset || document.documentElement.scrollLeft;

export const getScrollPos = () => ({
  left: getScrollLeft(),
  top: getScrollTop(),
});

export const getScrollbarThickness = () => {
  const el = document.createElement('div');
  el.style.overflow = 'scroll';
  el.style.visibility = 'hidden';
  el.style.position = 'absolute';
  el.style.top = '0';
  el.style.left = '0';
  el.style.width = '100px';
  el.style.height = '100px';

  document.body.appendChild(el);
  const scrollbarWidth = el.offsetWidth - el.scrollWidth;
  document.body.removeChild(el);

  return scrollbarWidth;
};

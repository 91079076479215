import { ApiClient } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { ListResponse } from '_core/api/types';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { DATE_FORMAT_API_DATE, DATE_FORMAT_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { uniq } from '_core/fp/uniq';
import { indexByLastItem } from '_core/indexBy';
import { isNotNull } from '_core/isNotNull';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import { fetchManyUsers } from 'accounts/api';
import { IUser } from 'accounts/types';
import dayjs from 'dayjs';
import { IBank, IInvoice, InvoiceStatus } from 'invoices/types';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useRouteNode } from 'react-router5';

import { IInvoiceFormValues, InvoicesForm, IStateHistoryItem } from './form';

type UserKeysToFetch =
  | 'createdUser'
  | 'forpaymentUser'
  | 'paidUser'
  | 'plannedUser';

async function fetchUsers(
  api: ApiClient,
  userIdsRecord: Record<UserKeysToFetch, number | null | undefined>
) {
  const results: Partial<Record<UserKeysToFetch, IUser>> = {};

  const users = await fetchManyUsers(
    api,
    uniq(Object.values(userIdsRecord).filter(isNotNull))
  );

  const usersIndex = indexByLastItem(users, user => user.id);

  (Object.keys(userIdsRecord) as UserKeysToFetch[]).forEach(userKey => {
    const userId = userIdsRecord[userKey];

    if (userId) {
      results[userKey] = usersIndex[userId];
    }
  });

  return results;
}

export default function InvoicesEditRoute() {
  const { route, router } = useRouteNode('invoices.edit');
  const navigationLock = useFormNavigationLock('invoices.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    async () => {
      const [banks, invoice] = await Promise.all([
        fetchAllPages(page =>
          api.get<ListResponse<IBank>>('/directories_banks', { page })
        ).then(response => response.results),

        api.get<IInvoice>(`/invoices/${route.params.id}`),
      ]);

      const stateHistory: IStateHistoryItem[] = [];

      const users = await fetchUsers(api, {
        createdUser: invoice.createdUser,
        forpaymentUser: invoice.forpaymentUser,
        paidUser: invoice.paidUser,
        plannedUser: invoice.plannedUser,
      });

      if (users.createdUser) {
        stateHistory.push({
          date: parseDate(invoice.created),
          label: 'Создан',
          user: users.createdUser,
        });
      }

      if (users.plannedUser && invoice.plannedDatetime) {
        stateHistory.push({
          date: parseDate(invoice.plannedDatetime),
          label: `В плане${
            invoice.paymentPlanned
              ? ` на ${dayjs(invoice.paymentPlanned).format(DATE_FORMAT_DATE)}`
              : ''
          }`,
          user: users.plannedUser,
        });
      }

      if (users.forpaymentUser && invoice.forpaymentDatetime) {
        stateHistory.push({
          date: parseDate(invoice.forpaymentDatetime),
          label: 'К оплате',
          user: users.forpaymentUser,
        });
      }

      if (users.paidUser && invoice.paidDatetime) {
        stateHistory.push({
          date: parseDate(invoice.paidDatetime),
          label: 'Оплачен',
          user: users.paidUser,
        });
      }

      return {
        banks,
        invoice,
        stateHistory,
      };
    }
  );

  const listParams = React.useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const persist = ({
    amount,
    date,
    paymentAmount,
    paymentBefore,
    paymentPlanned,
    ...otherProps
  }: IInvoiceFormValues) => {
    return api.put<IInvoice>(`/invoices/${data.invoice.id}`, {
      ...otherProps,
      amount: amount === '' ? undefined : amount,
      date: date ? dayjs(date).format(DATE_FORMAT_API_DATE) : null,
      paymentAmount: paymentAmount === '' ? undefined : paymentAmount,
      paymentBefore: paymentBefore
        ? dayjs(paymentBefore).format(DATE_FORMAT_API_DATE)
        : null,
      paymentPlanned: paymentPlanned
        ? dayjs(paymentPlanned).format(DATE_FORMAT_API_DATE)
        : null,
    });
  };

  const title = data.invoice.paymentString
    ? data.invoice.paymentString
    : '<номер счёта не указан>';

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Счета в оплату"
              params={listParams}
              to="invoices"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <InvoicesForm
            banks={data.banks}
            initialValues={{
              ...data.invoice,
              bank:
                data.invoice.bank == null ? null : String(data.invoice.bank),
              date: parseDate(data.invoice.date),
              paymentBefore: parseDate(data.invoice.paymentBefore),
              paymentPlanned:
                data.invoice.paymentPlanned == null
                  ? null
                  : parseDate(data.invoice.paymentPlanned),
            }}
            invoice={data.invoice}
            navigationLock={navigationLock}
            stateHistory={data.stateHistory}
            onMarkApprovedClick={
              data.invoice.status === InvoiceStatus.Created
                ? async ({ bank }) => {
                    try {
                      const updatedInvoice = await api.post<IInvoice>(
                        `/invoices/${data.invoice.id}/mark_approved`,
                        {
                          bank,
                        }
                      );

                      updateData(
                        prevData =>
                          prevData && { ...prevData, invoice: updatedInvoice }
                      );
                      return undefined;
                    } catch (err) {
                      return submissionErrorsFromApiError(
                        err,
                        'Не удалось изменить состояние счёта: Непредвиденная ошибка'
                      );
                    }
                  }
                : undefined
            }
            onMarkForPaymentClick={
              data.invoice.status === InvoiceStatus.Approved ||
              data.invoice.status === InvoiceStatus.Planned
                ? async () => {
                    try {
                      const updatedInvoice = await api.post<IInvoice>(
                        `/invoices/${data.invoice.id}/mark_for_payment`
                      );

                      updateData(
                        prevData =>
                          prevData && { ...prevData, invoice: updatedInvoice }
                      );
                      return undefined;
                    } catch (err) {
                      return submissionErrorsFromApiError(
                        err,
                        'Не удалось изменить состояние счёта: Непредвиденная ошибка'
                      );
                    }
                  }
                : undefined
            }
            onMarkPaidClick={
              data.invoice.status === InvoiceStatus.ForPayment
                ? async () => {
                    try {
                      const updatedInvoice = await api.post<IInvoice>(
                        `/invoices/${data.invoice.id}/mark_paid`
                      );

                      updateData(
                        prevData =>
                          prevData && { ...prevData, invoice: updatedInvoice }
                      );
                      return undefined;
                    } catch (err) {
                      return submissionErrorsFromApiError(
                        err,
                        'Не удалось изменить состояние счёта: Непредвиденная ошибка'
                      );
                    }
                  }
                : undefined
            }
            onMarkPlannedClick={
              data.invoice.status === InvoiceStatus.Approved
                ? async ({ paymentPlanned }) => {
                    try {
                      const updatedInvoice = await api.post<IInvoice>(
                        `/invoices/${data.invoice.id}/mark_planned`,
                        {
                          paymentPlanned: paymentPlanned
                            ? dayjs(paymentPlanned).format(DATE_FORMAT_API_DATE)
                            : null,
                        }
                      );

                      updateData(
                        prevData =>
                          prevData && { ...prevData, invoice: updatedInvoice }
                      );
                      return undefined;
                    } catch (err) {
                      return submissionErrorsFromApiError(
                        err,
                        'Не удалось отложить счёт: Непредвиденная ошибка'
                      );
                    }
                  }
                : undefined
            }
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('invoices', listParams);
                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось изменить счёт: Непредвиденная ошибка'
                );
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedInvoice = await persist(values);
                updateData(
                  prevData =>
                    prevData && { ...prevData, invoice: updatedInvoice }
                );
                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось изменить счёт: Непредвиденная ошибка'
                );
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

import { MultiSelectFilter } from '_core/filters/multiSelect';
import { MenuItem } from '@blueprintjs/core';
import * as React from 'react';

import { IPartnerSerialized } from './api';

interface IProps {
  initialValue: string | undefined;
  isFetching?: boolean;
  items: IPartnerSerialized[];
  label?: string;
  onApply: (partners: string) => void;
}

export function PartnersMultiSelectFilter({
  initialValue,
  isFetching,
  items,
  label = 'Контрагенты',
  onApply,
}: IProps) {
  return (
    <MultiSelectFilter
      initialContent={<MenuItem disabled text="Введите запрос" />}
      initialValue={initialValue}
      isFetching={isFetching}
      itemPredicate={(query, partner: IPartnerSerialized) => {
        const queryLc = query.toLowerCase();
        const shortName = partner.shortName.toLowerCase();
        const fullName = partner.fullName.toLowerCase();

        return shortName.includes(queryLc) || fullName.includes(queryLc);
      }}
      items={items}
      itemToOption={(partner: IPartnerSerialized) => ({
        label: partner.shortName,
        value: partner.id,
      })}
      label={label}
      placeholder="Начните вводить наименование контрагента..."
      valueProcessor={{
        emptyValue: undefined,
        isEmpty: value => !value,
        deserialize: value => (value ? value.split(',').map(Number) : []),
        serialize: value => (value.length ? value.join(',') : undefined),
      }}
      width={400}
      onApply={onApply}
    />
  );
}

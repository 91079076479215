import { Switch as BlueprintSwitch } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';
import * as reactFinalForm from 'react-final-form';

import * as css from './switch.module.css';

interface ICommonProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  label: string;
  noBottomMargin?: boolean;
}

interface IProps extends ICommonProps {
  checked: boolean;
  name?: string;
  onChange: (newChecked: boolean) => void;
}

export function Switch({
  className,
  noBottomMargin,
  onChange,
  ...otherProps
}: IProps) {
  return (
    <BlueprintSwitch
      {...otherProps}
      className={cx(className, {
        [css.main_noBottomMargin]: noBottomMargin,
      })}
      onChange={event => {
        onChange(event.currentTarget.checked);
      }}
    />
  );
}

export function SwitchForFinalForm({
  disabled,
  name,
  ...otherProps
}: ICommonProps & { name: string }) {
  return (
    <reactFinalForm.Field name={name}>
      {({ input, meta }) => (
        <Switch
          checked={input.value}
          disabled={meta.submitting || disabled}
          name={input.name}
          onChange={input.onChange}
          {...otherProps}
        />
      )}
    </reactFinalForm.Field>
  );
}

import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Button, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import * as React from 'react';

export interface ICreateNoteDialogFormValues {
  created: Date;
  dislocation: number;
  files: string[];
  note: string;
  operation: string;
  wagon: string;
}

interface IProps {
  initialValues: ICreateNoteDialogFormValues;
  isOpen: boolean;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (
    values: ICreateNoteDialogFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function CreateNoteDialogForm({
  initialValues,
  isOpen,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ error, handleSubmit, submitError, submitting }) => (
        <DialogForm
          isOpen={isOpen}
          isSubmitting={submitting}
          title="Групповое редактирование"
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormErrors error={error || submitError} />

            <FormGroupForFinalForm
              label="Комментарий"
              labelFor="note"
              name="note"
            >
              <InputMultipleForFinalForm id="note" name="note" />
            </FormGroupForFinalForm>

            <FileInputMultipleForFinalForm id="files" name="files" />
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button disabled={submitting} text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Сохранить"
                type="submit"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

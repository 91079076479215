import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import {
  ConsignersDialogForm,
  IConsignersDialogFormValues,
} from './dialogForm';
import { IConsigner } from './types';

interface IExternalProps {
  initialPartner?: number | null;
}

const ConsignersAutocompleteInFormGroup = wrapEntityAutocompleteWithFormGroup(
  createEntityAutocomplete<
    IConsigner,
    number,
    IConsignersDialogFormValues,
    IExternalProps
  >({
    baseEndpoint: '/consigners',
    optionIcon: 'person',
    placeholder: 'Начните вводить наименование грузоотправителя...',

    getLink: consigner => ({
      params: { id: String(consigner.id) },
      to: 'consigners.edit',
    }),

    itemToOption: consigner => ({
      label: consigner.name,
      value: consigner.id,
    }),

    create: {
      DialogForm: ConsignersDialogForm,
      newOptionIcon: 'new-person',
      newOptionLabel: 'Создать нового грузоотправителя...',

      dialogProps: {
        icon: 'new-person',
        title: 'Создание нового грузоотправителя',
      },

      getFormInitialValues: (autocompleteText, { initialPartner }) => ({
        email: '',
        legalAddress: '',
        name: autocompleteText,
        note: '',
        okpo: '',
        partner: initialPartner || null,
        phone: '',
      }),
    },
  })
);

export const ConsignersAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    ConsignersAutocompleteInFormGroup
  );

import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Toolbar } from '_core/toolbar';
import { Button } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';

export interface IOwnersFieldItem {
  owner: number | null;
}

interface ITableItem extends IOwnersFieldItem {
  index: number;
}

interface IProps {
  name: string;
}

export function OwnersField({ name }: IProps) {
  const { fields, meta } = useFieldArray(name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const disabled = (meta as any).submitting as boolean;

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const columns = useMemo(
    (): Array<ListTableColumn<ITableItem>> => [
      {
        id: 'owner',
        label: 'Собственник',
        defaultWidth: 350,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (_rowItem, rowIndex) => (
          <PartnersAutocompleteInFormGroupForFinalForm
            addToQuery={{ isSupplier: true }}
            id={`${fields.name}[${rowIndex}].owner`}
            name={`${fields.name}[${rowIndex}].owner`}
            noBottomMargin
          />
        ),
      },
    ],
    [fields.name]
  );

  const getItemId = useCallback((item: ITableItem) => String(item.index), []);

  const items = useMemo(
    () => fields.value.map((item, index): ITableItem => ({ ...item, index })),
    [fields.value]
  );

  return (
    <VGrid>
      <Row>
        <Toolbar>
          <Button
            disabled={disabled}
            text="Добавить"
            onClick={() => {
              fields.push({ owner: null });
            }}
          />

          <Button
            disabled={disabled || selectedItems.length === 0}
            text="Удалить выбранные"
            onClick={() => {
              selectedItems
                .map(Number)
                .sort((a, b) => b - a)
                .forEach(rowIndex => {
                  fields.remove(rowIndex);
                });

              setSelectedItems([]);
            }}
          />
        </Toolbar>
      </Row>

      <Row>
        <ListTable
          columns={columns}
          getItemId={getItemId}
          items={items}
          maxHeight={200}
          selectedItems={selectedItems}
          selectionIsDisabled={disabled}
          onSelectedItemsChange={setSelectedItems}
        />
      </Row>
    </VGrid>
  );
}

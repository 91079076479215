import '_core/init/sentry-browser';
import '_core/init/dayjs';

import { isAbortError } from '_core/abortError';
import { createAnalytics } from '_core/analytics/createAnalytics';
import { createYandexMetrikaModule } from '_core/analytics/yandexMetrika';
import { createApiClient } from '_core/api/client';
import { NotFoundError, UnauthorizedError } from '_core/api/client';
import { ORG_NAME, YM_COUNTER_ID } from '_core/config';
import { fetchMeAction, logoutAction, selectMe } from '_core/me/me';
import { analyticsMiddleware } from '_core/router5/analyticsMiddleware';
import { authMiddleware } from '_core/router5/authMiddleware';
import { IRouterDependencies } from '_core/router5/types';
import { FocusStyleManager, Intent, Toaster } from '@blueprintjs/core';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { captureException, setUser } from '@sentry/minimal';
import { createReduxEnhancer } from '@sentry/react';
import * as React from 'react';
import { render } from 'react-dom';
import { createRouter } from 'router5';
import browserPlugin from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';

import { App } from './app';
import { reducer } from './reducer';
import { routes } from './routes';

FocusStyleManager.onlyShowFocusOnTabs();

const toaster = Toaster.create();
const api = createApiClient(fetch);

const store = configureStore({
  reducer,
  enhancers: [createReduxEnhancer()],
  middleware: getDefaultMiddleware({ thunk: { extraArgument: { api } } }),
});

import.meta.webpackHot?.accept('./reducer', () =>
  store.replaceReducer(reducer)
);

const router = createRouter<IRouterDependencies>(routes, {
  allowNotFound: true,
  caseSensitive: true,
  queryParamsMode: 'loose',
  trailingSlashMode: 'never',
  queryParams: {
    booleanFormat: 'empty-true',
    nullFormat: 'hidden',
  },
}).setDependencies({
  analytics: createAnalytics({
    modules: YM_COUNTER_ID
      ? [
          createYandexMetrikaModule(YM_COUNTER_ID, {
            accurateTrackBounce: true,
            clickmap: true,
            trackLinks: true,
            webvisor: false,
          }),
        ]
      : [],
  }),

  store,
});

router.usePlugin(browserPlugin());
router.usePlugin(listenersPlugin());

router.useMiddleware(analyticsMiddleware, authMiddleware, () => () => {
  window.scrollTo(0, 0);

  return true;
});

window.addEventListener('unhandledrejection', async event => {
  if (event.reason instanceof UnauthorizedError) {
    const route = router.getState();
    await store.dispatch(logoutAction('Необходимо повторно авторизоваться'));
    router.navigate(route.name, route.params, { reload: true });
    event.preventDefault();
  }
});

window.addEventListener('unhandledrejection', event => {
  if (isAbortError(event.reason) || event.reason instanceof NotFoundError) {
    event.preventDefault();
  }
});

window.addEventListener('unhandledrejection', event => {
  if (event.defaultPrevented) {
    // this is to prevent dev-only error overlay from being shown
    event.stopImmediatePropagation();
  } else {
    captureException(event.reason);
  }
});

store.subscribe(() => {
  const me = selectMe(store.getState());

  setUser(me && { email: me.email });
});

store
  .dispatch(fetchMeAction())
  .catch(err => {
    if (err instanceof UnauthorizedError) {
      return;
    }

    throw err;
  })
  .then(() => {
    router.start(() => {
      render(
        <App
          api={api}
          orgName={ORG_NAME}
          router={router}
          store={store}
          toaster={toaster}
        />,
        document.getElementById('app')
      );
    });
  });

let lastRelease = __SENTRY_RELEASE__;
async function checkForUpdates() {
  try {
    const release = await fetch('/release').then(res => res.text());

    if (release !== lastRelease) {
      toaster.show({
        intent: Intent.SUCCESS,
        timeout: 0,
        icon: 'refresh',
        message: 'Доступна новая версия приложения',
        action: { text: 'Обновить', onClick: () => location.reload() },
      });

      lastRelease = release;
    }
  } finally {
    window.setTimeout(checkForUpdates, 15000);
  }
}

if (!import.meta.webpackHot) {
  checkForUpdates();
}
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { ListTableColumn } from '_core/react-window/listTable';
import { TreeTableColumn } from '_core/react-window/treeTable';
import { createListToTreeTableColumnAdapter } from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { IConsigner } from 'consigners/types';
import dayjs from 'dayjs';
import { IPartnerSerialized } from 'partners/api';
import * as React from 'react';

const phoneColumn: ListTableColumn<IConsigner> = {
  id: 'phone',
  label: 'Номер телефона',
  defaultWidth: 140,
  copyCellContent: consigner => consigner.phone,
  renderCellContent: consigner => consigner.phone,
};

const emailColumn: ListTableColumn<IConsigner> = {
  id: 'email',
  label: 'E-mail',
  defaultWidth: 200,
  copyCellContent: consigner => consigner.email,
  renderCellContent: consigner => consigner.email,
};

const okpoColumn: ListTableColumn<IConsigner> = {
  id: 'okpo',
  label: 'ОКПО',
  defaultWidth: 120,
  copyCellContent: consigner => consigner.okpo,
  renderCellContent: consigner => consigner.okpo,
};

const createdColumn: ListTableColumn<IConsigner> = {
  id: 'created',
  label: 'Дата создания',
  defaultWidth: 130,
  sortable: true,
  copyCellContent: consigner =>
    dayjs(consigner.created).format(DATE_FORMAT_DATETIME),
  renderCellContent: consigner =>
    dayjs(consigner.created).format(DATE_FORMAT_DATETIME),
};

export function getConsignersListTableColumns({
  listParams,
  partnersById,
}: {
  listParams: string;
  partnersById: Record<number, IPartnerSerialized>;
}): Array<ListTableColumn<IConsigner>> {
  const nameColumn: ListTableColumn<IConsigner> = {
    id: 'consignerName',
    label: 'Наименование грузоотправителя',
    defaultWidth: 420,
    sortable: true,
    copyCellContent: consigner => consigner.name,
    renderCellContent: consigner => (
      <Link params={{ id: consigner.id, listParams }} to="consigners.edit">
        {consigner.name}
      </Link>
    ),
  };

  const partnerColumn: ListTableColumn<IConsigner> = {
    id: 'partner',
    label: 'Контрагент',
    defaultWidth: 400,
    copyCellContent: consigner => {
      const partner = consigner.partner
        ? partnersById[consigner.partner]
        : null;

      return partner ? partner.shortName : '';
    },
    renderCellContent: consigner => {
      const partner = consigner.partner
        ? partnersById[consigner.partner]
        : null;

      return partner ? (
        <Link
          params={{ id: partner.id }}
          rel="noopener"
          target="_blank"
          to="partners.edit"
        >
          {partner.shortName}
        </Link>
      ) : null;
    },
  };

  return [
    nameColumn,
    phoneColumn,
    emailColumn,
    okpoColumn,
    partnerColumn,
    createdColumn,
  ];
}

export type ConsignersTreeTableNodeData =
  | {
      kind: 'partner';
      partner: IPartnerSerialized | null;
      consignersCount: number;
    }
  | { kind: 'consigner'; consigner: IConsigner };

const adaptColumn = createListToTreeTableColumnAdapter<
  IConsigner,
  ConsignersTreeTableNodeData
>(nodeData => (nodeData.kind === 'consigner' ? nodeData.consigner : null));

function formatPartnerName(
  partner: IPartnerSerialized | null,
  consignersCount: number
) {
  return `${
    partner ? partner.shortName : '<Контрагент не указан>'
  } (${consignersCount})`;
}

export function getConsignersTreeTableColumns(): Array<
  TreeTableColumn<ConsignersTreeTableNodeData>
> {
  const nameColumn: TreeTableColumn<ConsignersTreeTableNodeData> = {
    id: 'name',
    label: 'Наименование',
    defaultWidth: 350,
    sortable: true,
    expandable: true,
    copyCellContent: node => {
      switch (node.data.kind) {
        case 'partner': {
          const { consignersCount, partner } = node.data;

          return formatPartnerName(partner, consignersCount);
        }
        case 'consigner':
          return node.data.consigner.name;
      }
    },
    renderCellContent: node => {
      switch (node.data.kind) {
        case 'partner': {
          const { consignersCount, partner } = node.data;
          const formattedName = formatPartnerName(partner, consignersCount);

          return partner ? (
            <Ellipsis
              component={Link}
              params={{ id: partner.id }}
              rel="noopener"
              target="_blank"
              to="partners.edit"
            >
              {formattedName}
            </Ellipsis>
          ) : (
            <Ellipsis component="span">{formattedName}</Ellipsis>
          );
        }
        case 'consigner': {
          const { consigner } = node.data;

          return (
            <Link
              params={{ id: consigner.id }}
              rel="noopener"
              target="_blank"
              to="consigners.edit"
            >
              {consigner.name}
            </Link>
          );
        }
      }
    },
  };

  return [nameColumn].concat(
    [phoneColumn, emailColumn, okpoColumn, createdColumn].map(adaptColumn)
  );
}

export enum DocumentStatus {
  Created = 'CREATED',
  ScanSent = 'SCAN_SENT',
  OriginalSent = 'ORIGINAL_SENT',
  ScanReceived = 'SCAN_RECEIVED',
  OriginalReceived = 'ORIGINAL_RECEIVED',
}
export function isDocumentStatus(input: string): input is DocumentStatus {
  return Object.values(DocumentStatus).includes(input as DocumentStatus);
}

export const getDocumentStatusLabel = (status: DocumentStatus) =>
  ({
    [DocumentStatus.Created]: 'Создан',
    [DocumentStatus.OriginalReceived]: 'Получен оригинал',
    [DocumentStatus.OriginalSent]: 'Отправлен оригинал',
    [DocumentStatus.ScanReceived]: 'Получен скан',
    [DocumentStatus.ScanSent]: 'Отправлен скан',
  }[status]);

export const documentStatusOptions = [
  DocumentStatus.Created,
  DocumentStatus.ScanSent,
  DocumentStatus.OriginalSent,
  DocumentStatus.ScanReceived,
  DocumentStatus.OriginalReceived,
].map(status => ({
  label: getDocumentStatusLabel(status),
  value: status,
}));

import { capitalize } from '_core/fp/capitalize';
import { range } from '_core/fp/range';
import { DatePickerLocaleUtils } from '@blueprintjs/datetime';
import dayjs from 'dayjs';
import invariant from 'tiny-invariant';

import { parseDate } from './utils';

export const datePickerLocaleUtils: DatePickerLocaleUtils = {
  formatDate: (date, format, locale) => {
    invariant(typeof format === 'string' || typeof format === 'undefined');
    return dayjs(date, undefined, locale).format(format);
  },
  formatDay: (day, locale) => dayjs(day, undefined, locale).format('ddd ll'),
  formatMonthTitle: (date, locale) =>
    capitalize(dayjs(date, undefined, locale).format('MMMM YYYY')),
  formatWeekdayLong: (day, locale) =>
    dayjs(undefined, undefined, locale).localeData().weekdays()[day],
  formatWeekdayShort: (day, locale) =>
    dayjs(undefined, undefined, locale).localeData().weekdaysMin()[day],
  getFirstDayOfWeek: locale =>
    dayjs(undefined, undefined, locale).localeData().firstDayOfWeek(),
  getMonths: locale =>
    range(0, 12).map(i =>
      capitalize(dayjs(undefined, undefined, locale).month(i).format('MMMM'))
    ) as [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string
    ],
  parseDate,
};

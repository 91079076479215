import {
  DATE_FORMAT_API_DATETIME,
  DATE_FORMAT_DATE,
} from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { DatePicker } from '_core/inputs/datePicker';
import { Radio, RadioGroup } from '_core/inputs/radio';
import { deprecatedFilterInPopover } from '_deprecated/filterInPopover';
import dayjs from 'dayjs';
import * as React from 'react';
import { useRef } from 'react';

import * as css from './historyFilterInPopover.module.css';

const times = [
  { hours: 9, minutes: 0 },
  { hours: 16, minutes: 0 },
  { hours: 23, minutes: 0 },
];

const timeOptions = times.map((time, index) => ({
  label: `${time.hours}:${String(time.minutes).padStart(2, '0')}`,
  value: index,
}));

function parseInitialValue(initialValue: string | undefined) {
  if (!initialValue) {
    return null;
  }

  const since = parseDate(initialValue);
  const hours = since.getHours();
  const minutes = since.getMinutes();

  const foundTimeIndex = times.findIndex(
    t => t.hours === hours && t.minutes === minutes
  );

  return {
    date: dayjs(since).startOf('date').toDate(),
    time: foundTimeIndex === -1 ? 0 : foundTimeIndex,
  };
}

interface IProps {
  showCurrentParkWagons?: boolean;
}

interface IValue {
  currentParkWagons: string;
  date: Date;
  time: number;
}

export const HistoryFilterInPopover = deprecatedFilterInPopover<
  {
    dislocationDateTime: string | undefined;
    currentParkWagons: string | undefined;
  },
  IProps,
  IValue
>({
  iconName: 'history',
  label: 'История',

  clearValue: {
    currentParkWagons: undefined,
    dislocationDateTime: undefined,
  },

  hasInitialValue: initialValue =>
    Boolean(initialValue.currentParkWagons || initialValue.dislocationDateTime),

  extractInitialValue: ({ initialValue }) => {
    const { date, time } = parseInitialValue(
      initialValue.dislocationDateTime
    ) || {
      date: dayjs().startOf('date').toDate(),
      time: 0,
    };

    return {
      currentParkWagons:
        initialValue.currentParkWagons === 'True' ? 'current' : 'actual',

      date,
      time,
    };
  },

  formatButtonLabel: ({ initialValue }) => {
    const parsedValue = parseInitialValue(initialValue.dislocationDateTime);

    if (!parsedValue) {
      return '';
    }

    const dateLabel = dayjs(parsedValue.date).format(DATE_FORMAT_DATE);
    const timeOption = timeOptions.find(t => t.value === parsedValue.time);
    const timeLabel = timeOption && timeOption.label;

    return [dateLabel, timeLabel].join(', ');
  },

  transformOutputValue: value => {
    const { hours, minutes } = times[value.time];

    return {
      currentParkWagons:
        value.currentParkWagons === 'current' ? 'True' : undefined,

      dislocationDateTime: dayjs(value.date)
        .set('hour', hours)
        .set('minute', minutes)
        .format(DATE_FORMAT_API_DATETIME),
    };
  },
})(function HistoryFilterInPopoverRaw({
  showCurrentParkWagons,
  value,
  onChange,
}) {
  const maxDate = useRef(new Date());

  return (
    <div>
      <DatePicker
        canClearSelection={false}
        maxDate={maxDate.current}
        value={value.date}
        onChange={date => {
          onChange({ ...value, date });
        }}
      />

      <div className={css.controls}>
        <RadioGroup
          className={css.radioGroup}
          selectedValue={value.time}
          onChange={newValue => {
            onChange({
              ...value,
              time: Number(newValue),
            });
          }}
        >
          {timeOptions.map(option => (
            <Radio
              key={option.value}
              label={option.label}
              value={option.value}
            />
          ))}
        </RadioGroup>

        {showCurrentParkWagons && (
          <RadioGroup
            className={css.radioGroup}
            selectedValue={value.currentParkWagons}
            onChange={newValue => {
              onChange({
                ...value,
                currentParkWagons: newValue,
              });
            }}
          >
            <Radio label="Актуальные" value="actual" />
            <Radio label="Текущие" value="current" />
          </RadioGroup>
        )}
      </div>
    </div>
  );
});

import { getNormalVatRate, VatRate } from 'vatRates/vatRates';

export enum TripType {
  Internal = 'INTERNAL',
  Import = 'IMPORT',
  Export = 'EXPORT',
  Transit = 'TRANSIT',
}

const tripTypeDataMap = {
  [TripType.Internal]: {
    label: 'Внутренняя перевозка',
    vatRate: getNormalVatRate(),
  },

  [TripType.Import]: {
    label: 'Импорт',
    vatRate: VatRate.None,
  },

  [TripType.Export]: {
    label: 'Экспорт',
    vatRate: VatRate.None,
  },

  [TripType.Transit]: {
    label: 'Транзит',
    vatRate: VatRate.None,
  },
};

export const vatRateForTripType = (tripType: TripType) =>
  tripTypeDataMap[tripType].vatRate;

export const tripTypeOptions = [
  TripType.Internal,
  TripType.Import,
  TripType.Export,
  TripType.Transit,
].map(tripType => ({
  label: tripTypeDataMap[tripType].label,
  value: tripType,
}));

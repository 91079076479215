import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FileInputSingleForFinalForm } from '_core/inputs/fileInputSingle';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroupForFinalForm } from '_core/inputs/inputMoney';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { SelectInFormGroupForFinalForm } from '_core/inputs/select';
import { selectPermissions } from '_core/me/me';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { useErpSelector } from '_erp/redux';
import { Button, H4, Intent, Label } from '@blueprintjs/core';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import { ContractsPurchaseAutocompleteInFormGroupForFinalForm } from 'contractsPurchase/autocomplete';
import { Currency, currencyOptions } from 'currencies/types';
import dayjs from 'dayjs';
import { SubmissionErrors } from 'final-form';
import {
  bankToOption,
  IBank,
  IInvoice,
  InvoiceVatType,
  invoiceVatTypeOptions,
} from 'invoices/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { Fragment, useRef } from 'react';
import invariant from 'tiny-invariant';
import { VatRate, vatRateOptions } from 'vatRates/vatRates';

export interface IInvoiceFormValues {
  amount: string;
  bank: string | null;
  contract: number | null;
  currency: Currency;
  date: Date | null;
  note: string;
  number: string;
  partner: number | null;
  paymentAmount: string;
  paymentBefore: Date | null;
  paymentPlanned: Date | null;
  scan: string | null;
  vatRateUsed: VatRate | null;
  vatType: InvoiceVatType;
}

export interface IStateHistoryItem {
  date: Date;
  label: string;
  user: IUser;
}

interface IProps {
  banks: IBank[];
  initialValues: IInvoiceFormValues;
  invoice?: IInvoice;
  navigationLock: IFormNavigationLock;
  stateHistory?: IStateHistoryItem[];
  onMarkApprovedClick?: (
    values: IInvoiceFormValues
  ) => Promise<SubmissionErrors | void>;
  onMarkForPaymentClick?: (
    values: IInvoiceFormValues
  ) => Promise<SubmissionErrors | void>;
  onMarkPaidClick?: (
    values: IInvoiceFormValues
  ) => Promise<SubmissionErrors | void>;
  onMarkPlannedClick?: (
    values: IInvoiceFormValues
  ) => Promise<SubmissionErrors | void>;
  onSave: (values: IInvoiceFormValues) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IInvoiceFormValues
  ) => Promise<SubmissionErrors | void>;
}

type SubmitTrigger =
  | 'markApproved'
  | 'markForPayment'
  | 'markPaid'
  | 'markPlanned'
  | 'save'
  | 'saveAndContinue';

export function InvoicesForm({
  banks,
  initialValues,
  invoice,
  navigationLock,
  stateHistory,
  onMarkApprovedClick,
  onMarkForPaymentClick,
  onMarkPaidClick,
  onMarkPlannedClick,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const submitTriggerRef = useRef<SubmitTrigger>('save');

  const permissions = useErpSelector(selectPermissions);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'markApproved':
            invariant(onMarkApprovedClick);
            return onMarkApprovedClick(values);
          case 'markForPayment':
            invariant(onMarkForPaymentClick);
            return onMarkForPaymentClick(values);
          case 'markPaid':
            invariant(onMarkPaidClick);
            return onMarkPaidClick(values);
          case 'markPlanned':
            invariant(onMarkPlannedClick);
            return onMarkPlannedClick(values);
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={8}>
                  <H4>Основная информация</H4>

                  <Grid>
                    <Col span={4}>
                      <PartnersAutocompleteInFormGroupForFinalForm
                        id="partner"
                        label="Контрагент"
                        name="partner"
                        required
                      />

                      <ContractsPurchaseAutocompleteInFormGroupForFinalForm
                        addToQuery={{ partner: values.partner }}
                        disabled={values.partner == null}
                        id="contract"
                        label="Договор"
                        name="contract"
                      />

                      <Grid>
                        <Col span={2}>
                          <FormGroupForFinalForm
                            label="Номер счёта на оплату"
                            labelFor="number"
                            name="number"
                          >
                            <InputGroupForFinalForm id="number" name="number" />
                          </FormGroupForFinalForm>
                        </Col>

                        <Col span={2}>
                          <DateInputInFormGroupForFinalForm
                            fill
                            id="date"
                            label="Дата счёта на оплату"
                            name="date"
                          />
                        </Col>

                        <Col span={2}>
                          <InputMoneyInFormGroupForFinalForm
                            id="amount"
                            label="Сумма счёта"
                            name="amount"
                            onChange={newAmount => {
                              form.change('paymentAmount', newAmount);
                            }}
                          />
                        </Col>

                        <Col span={2}>
                          <SelectInFormGroupForFinalForm
                            id="currency"
                            label="Валюта счёта"
                            name="currency"
                            options={currencyOptions}
                          />
                        </Col>

                        <Col span={2}>
                          <InputMoneyInFormGroupForFinalForm
                            id="paymentAmount"
                            label="Сумма оплаты"
                            name="paymentAmount"
                          />
                        </Col>

                        <Col span={1}>
                          <SelectInFormGroupForFinalForm
                            id="vatType"
                            label="НДС"
                            name="vatType"
                            options={invoiceVatTypeOptions}
                          />
                        </Col>

                        <Col span={1}>
                          <SelectInFormGroupForFinalForm
                            id="vatRateUsed"
                            label="Ставка"
                            name="vatRateUsed"
                            options={vatRateOptions}
                          />
                        </Col>
                      </Grid>

                      <DateInputInFormGroupForFinalForm
                        fill
                        id="paymentBefore"
                        label="Срок оплаты"
                        name="paymentBefore"
                      />
                    </Col>

                    <Col span={4}>
                      <SelectInFormGroupForFinalForm
                        disabled={
                          !onMarkApprovedClick || !permissions.isFinanceManager
                        }
                        id="bank"
                        label="Банк оплаты"
                        name="bank"
                        options={banks.map(bankToOption)}
                        withEmptyOption
                      />

                      <Label>План оплаты</Label>

                      <Grid>
                        <Col span={onMarkPlannedClick ? 2 : 4}>
                          <DateInputInFormGroupForFinalForm
                            disabled={
                              !onMarkPlannedClick ||
                              !permissions.isFinanceDirector
                            }
                            id="paymentPlanned"
                            fill
                            name="paymentPlanned"
                          />
                        </Col>

                        {onMarkPlannedClick && (
                          <Col span={2}>
                            <Button
                              disabled={
                                submitting || !permissions.isFinanceDirector
                              }
                              fill
                              text="Отложить"
                              onClick={() => {
                                submitTriggerRef.current = 'markPlanned';
                                form.submit();
                              }}
                            />
                          </Col>
                        )}
                      </Grid>

                      <FormGroupForFinalForm
                        label="Комментарий"
                        labelFor="note"
                        name="note"
                      >
                        <InputMultipleForFinalForm
                          id="note"
                          name="note"
                          rows={4}
                        />
                      </FormGroupForFinalForm>
                    </Col>
                  </Grid>

                  <FileInputSingleForFinalForm
                    id="scan"
                    label="Скан счёта на оплату"
                    name="scan"
                  />

                  {invoice && (
                    <div>
                      <H4>Строка оплаты</H4>
                      <p>{invoice.paymentString}</p>
                    </div>
                  )}
                </Col>

                <Col span={4}>
                  {stateHistory && (
                    <>
                      <H4>История состояний документа</H4>

                      <dl>
                        {stateHistory.map(({ date, label, user }, index) => (
                          <Fragment key={index}>
                            <dt>{label}</dt>

                            <dd>
                              {dayjs(date).format(DATE_FORMAT_DATETIME)} -{' '}
                              {formatUserName(user)}
                            </dd>
                          </Fragment>
                        ))}
                      </dl>
                    </>
                  )}
                </Col>
              </Grid>
            </Row>

            <Row>
              <Toolbar align="right">
                {onMarkApprovedClick && (
                  <Button
                    disabled={submitting || !permissions.isFinanceManager}
                    intent={Intent.SUCCESS}
                    text="Согласовать"
                    onClick={() => {
                      submitTriggerRef.current = 'markApproved';
                      form.submit();
                    }}
                  />
                )}

                {onMarkForPaymentClick && (
                  <Button
                    disabled={submitting || !permissions.isFinanceDirector}
                    intent={Intent.SUCCESS}
                    text="К оплате"
                    onClick={() => {
                      submitTriggerRef.current = 'markForPayment';
                      form.submit();
                    }}
                  />
                )}

                {onMarkPaidClick && (
                  <Button
                    disabled={submitting || !permissions.isAccountant}
                    intent={Intent.SUCCESS}
                    text="Оплачен"
                    onClick={() => {
                      submitTriggerRef.current = 'markPaid';
                      form.submit();
                    }}
                  />
                )}

                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitTriggerRef.current = 'save';
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { ListResponse } from '_core/api/types';
import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Select, SelectInFormGroupForFinalForm } from '_core/inputs/select';
import { useAsyncData } from '_core/useAsyncData';
import { Button, FormGroup, Intent } from '@blueprintjs/core';
import { IContractPurchaseSerialized } from 'contractsPurchase/api';
import { IContractSellSerialized } from 'contractsSell/api';
import { SubmissionErrors } from 'final-form';
import { Col, Grid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { useLayoutEffect, useMemo, useState } from 'react';
import { IRentProtocolSerialized } from 'rentProtocols/types';
import { rentProtocolSerializedToOption } from 'rentProtocols/utils';

export interface IRentWagonsSellAcceptDialogFormValues {
  protocol: string | null;
  startDate: Date;
  supplier: number | null;
  wagons: string;
}

function RentWagonsSellAcceptDialogFormInner({
  change,
  error,
  isOpen,
  submitting,
  values,
  onClose,
  onClosed,
  onSubmit,
}: {
  change: (field: string, value: unknown) => void;
  error: string | undefined;
  isOpen: boolean;
  submitting: boolean;
  values: IRentWagonsSellAcceptDialogFormValues;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}) {
  const api = useApiClient();

  const contracts = useAsyncData([api, values.supplier], async () => {
    if (!values.supplier) {
      return null;
    }

    const response = await fetchAllPages(page =>
      api.get<
        ListResponse<IContractPurchaseSerialized | IContractSellSerialized>
      >('/contracts_sell', {
        page,
        partner: values.supplier,
        service: 'RENT',
      })
    );

    return response.results;
  });

  const contractSelectOptions = useMemo(
    () =>
      contracts.data
        ? contracts.data
            .map(contract => ({ label: contract.number, value: contract.id }))
            .map(option => ({
              ...option,
              value: String(option.value),
            }))
        : [],

    [contracts.data]
  );

  const [contractSelectValue, setContractSelectValue] = useState<string | null>(
    null
  );

  useLayoutEffect(() => {
    const foundOption = contractSelectOptions.find(
      option => option.value === contractSelectValue
    );

    if (foundOption) {
      return;
    }

    if (contractSelectOptions.length !== 0) {
      setContractSelectValue(contractSelectOptions[0].value);
      return;
    }

    setContractSelectValue(null);
  }, [contractSelectOptions, contractSelectValue]);

  const rentProtocols = useAsyncData([contractSelectValue, api], async () => {
    if (contractSelectValue == null) {
      return null;
    }

    const response = await fetchAllPages(page =>
      api.get<ListResponse<IRentProtocolSerialized>>('/rent_protocols_sell', {
        contract: contractSelectValue,
        page,
      })
    );

    return response.results;
  });

  const rentProtocolsSelectOptions = useMemo(
    () =>
      rentProtocols.data
        ? rentProtocols.data.map(rentProtocolSerializedToOption)
        : [],
    [rentProtocols.data]
  );

  useLayoutEffect(() => {
    const foundOption = rentProtocolsSelectOptions.find(
      option => option.value === values.protocol
    );

    if (foundOption) {
      return;
    }

    if (rentProtocolsSelectOptions.length !== 0) {
      change('protocol', rentProtocolsSelectOptions[0].value);
      return;
    }

    change('protocol', null);
  }, [rentProtocolsSelectOptions, change, values.protocol]);

  const partnersAutocompleteAddToQuery = useMemo(
    () => ({ hasSellContractsType: 'RENT' }),
    []
  );

  return (
    <DialogForm
      isOpen={isOpen}
      isSubmitting={submitting}
      title="Сдать вагоны в аренду"
      onClose={onClose}
      onClosed={onClosed}
      onSubmit={onSubmit}
    >
      <DialogBody>
        <FormErrors error={error} />

        <DateInputInFormGroupForFinalForm
          id="startDate"
          label="Дата сдачи в аренду"
          name="startDate"
        />

        <FormGroupForFinalForm label="Вагоны" labelFor="wagons" name="wagons">
          <InputMultipleForFinalForm id="wagons" name="wagons" />
        </FormGroupForFinalForm>

        <PartnersAutocompleteInFormGroupForFinalForm
          addToQuery={partnersAutocompleteAddToQuery}
          fetchWithEmptyQuery
          id="supplier"
          label="Контрагент"
          name="supplier"
        />

        <Grid>
          <Col span={6}>
            <FormGroup label="Договор">
              <Select
                disabled={values.supplier == null}
                fill
                value={contractSelectValue}
                onChange={setContractSelectValue}
                options={contractSelectOptions}
              />
            </FormGroup>
          </Col>

          <Col span={6}>
            <SelectInFormGroupForFinalForm
              disabled={contractSelectValue == null}
              id="protocol"
              label="Протокол"
              name="protocol"
              options={rentProtocolsSelectOptions}
              withEmptyOption
            />
          </Col>
        </Grid>
      </DialogBody>

      <DialogFooter>
        <DialogFooterActions>
          <Button text="Отмена" onClick={onClose} />

          <Button
            disabled={submitting}
            intent={Intent.PRIMARY}
            loading={submitting}
            text="Сдать"
            type="submit"
          />
        </DialogFooterActions>
      </DialogFooter>
    </DialogForm>
  );
}

interface IProps {
  initialValues: IRentWagonsSellAcceptDialogFormValues;
  isOpen: boolean;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (
    values: IRentWagonsSellAcceptDialogFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function RentWagonsSellAcceptDialogForm({
  initialValues,
  isOpen,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ error, form, handleSubmit, submitError, submitting, values }) => (
        <RentWagonsSellAcceptDialogFormInner
          change={form.change}
          error={error || submitError}
          isOpen={isOpen}
          submitting={submitting}
          values={values}
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        />
      )}
    </FinalForm>
  );
}

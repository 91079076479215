import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { IEtsng } from './types';

export const EtsngAutocompleteInFormGroup = wrapEntityAutocompleteWithFormGroup(
  createEntityAutocomplete<IEtsng, number>({
    baseEndpoint: '/directories_etsng',
    optionIcon: 'box',
    placeholder: 'Начните вводить наименование груза или код ЕТСНГ...',

    itemToOption: item => ({
      label: `${item.code}: ${item.name}`,
      value: item.id,
    }),
  })
);

export const EtsngAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(EtsngAutocompleteInFormGroup);

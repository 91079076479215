import * as React from 'react';
import { Fragment } from 'react';

export function Nl2Br({ text }: { text: string }) {
  return (
    <>
      {text.split('\n').map((line, index) => (
        <Fragment key={index}>
          {index !== 0 && <br key={index} />}
          {line}
        </Fragment>
      ))}
    </>
  );
}

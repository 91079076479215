import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import {
  createDateRange,
  monthDateToSelectOption,
  parseDate,
} from '_core/dates/utils';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { DatePicker } from '_core/inputs/datePicker';
import { InputMultiple } from '_core/inputs/inputMultiple';
import { Select } from '_core/inputs/select';
import { LegendItem } from '_core/legend';
import { Region } from '_core/react-window/baseTable';
import { LinkButton } from '_core/router5/linkButton';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  Button,
  Classes,
  FormGroup,
  Intent,
  NonIdealState,
  Popover,
  Position,
} from '@blueprintjs/core';
import dayjs from 'dayjs';
import {
  bulkReplacePauses,
  bulkUpdatePauses,
  changeWagonsUsagePeriod,
  fetchWagonsDailyUsage,
  IBulkReplacePausesWagon,
  IExpeditionRequest,
  makeShipmentForPeriod,
  startWagonsPause,
  stopWagonsPause,
} from 'expeditionRequests/api';
import {
  IWagonsDailyUsagePause,
  IWagonsDailyUsageWagon,
} from 'expeditionRequests/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useRoute } from 'react-router5';
import invariant from 'tiny-invariant';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

import { WagonsDailyUsageColor } from './colors';
import {
  EditPausesDialogForm,
  IEditPausesDialogFormValues,
} from './editPausesDialogForm';
import {
  columnCellIntervalToDateRange,
  DATE_COLUMN_START_INDEX,
  findPausesForRegions,
  WagonsDailyUsageTabTable,
} from './table';
import * as css from './wagonsDailyUsageTab.module.css';

interface IProps {
  request: IExpeditionRequest;
}

function getOrCreateWagonReplacement(
  wagonNumber: string,
  wagons: IWagonsDailyUsageWagon[],
  replacements: IBulkReplacePausesWagon[]
): IBulkReplacePausesWagon | null {
  const foundReplacement = replacements.find(r => r.wagon === wagonNumber);

  if (foundReplacement) {
    return foundReplacement;
  }

  const foundWagon = wagons.find(w => w.wagon === wagonNumber);

  if (!foundWagon) {
    return null;
  }

  const newReplacement: IBulkReplacePausesWagon = {
    wagon: wagonNumber,
    startDate: parseDate(foundWagon.startDate),
    endDate: parseDate(foundWagon.endDate),
    pauses: foundWagon.pauses.map(pause => ({
      startDate: parseDate(pause.startDate),
      endDate: pause.endDate == null ? null : parseDate(pause.endDate),
      note: pause.note,
    })),
  };

  replacements.push(newReplacement);

  return newReplacement;
}

export function ExpeditionRequestWagonsDailyUsageTab({ request }: IProps) {
  const toaster = useToaster();
  const api = useApiClient();
  const { route, router } = useRoute();

  const [batchActionIsInProgress, setBatchActionIsInProgress] = useState(false);

  function applyFilterParams(filterParams: Record<string, string | undefined>) {
    router.navigate(route.name, {
      ...route.params,
      ...filterParams,
    });
  }

  const startMonthDate = useMemo(
    () => (route.params.month ? parseDate(route.params.month) : null),
    [route.params.month]
  );

  const endMonthDate = useMemo(
    () =>
      startMonthDate &&
      dayjs(startMonthDate).endOf('month').startOf('day').toDate(),
    [startMonthDate]
  );

  const minDate = startMonthDate || undefined;
  const maxDate = endMonthDate || undefined;

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [endMonthDate, request.id, api, route.params.wagonsList, startMonthDate],

    async () => {
      if (!startMonthDate || !endMonthDate) {
        return null;
      }

      const wagonsDailyUsage = await fetchWagonsDailyUsage(api, request.id, {
        startDate: startMonthDate,
        endDate: endMonthDate,
        wagonsList: route.params.wagonsList
          ? (route.params.wagonsList as string).split(',')
          : undefined,
      });

      return {
        ...wagonsDailyUsage,
        endDate: parseDate(wagonsDailyUsage.endDate),
        startDate: parseDate(wagonsDailyUsage.startDate),
      };
    }
  );

  const lastDataRef = useRef(data);
  const [selectedRegions, setSelectedRegions] = useState<Region[]>([]);
  const [selectedWagons, setSelectedWagons] = useState<string[]>([]);

  if (lastDataRef.current !== data) {
    setSelectedRegions([]);
    setSelectedWagons([]);
    lastDataRef.current = data;
  }

  function wrapBatchAction(action: () => Promise<void>) {
    return async () => {
      try {
        setBatchActionIsInProgress(true);
        await action();
        refetch();
      } catch (err) {
        if (err instanceof BadRequestError) {
          toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: err.message,
          });
        } else {
          toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: 'Что-то пошло не так',
          });

          throw err;
        }
      } finally {
        setBatchActionIsInProgress(false);
      }
    };
  }

  const [wagonsUsageStart, setWagonsUsageStart] = useState<Date | undefined>(
    undefined
  );

  const [wagonsUsageEnd, setWagonsUsageEnd] = useState<Date | undefined>(
    undefined
  );

  const [wagonsPauseStart, setWagonsPauseStart] = useState<Date | undefined>(
    undefined
  );

  const [wagonsPauseEnd, setWagonsPauseEnd] = useState<Date | undefined>(
    undefined
  );

  const [pauseStartNote, setPauseStartNote] = useState('');

  const shipmentExists = Boolean(data?.shipment);

  const isAnyWagonSelected = selectedWagons.length !== 0;

  const datesColStart = DATE_COLUMN_START_INDEX;

  const datesColEnd = endMonthDate
    ? DATE_COLUMN_START_INDEX + endMonthDate.getDate() - 1
    : null;

  const [isBulkReplacePausesInProgress, setIsBulkReplacePausesInProgress] =
    useState(false);

  const bulkReplacePausesStateful = async (
    startDate: Date,
    endDate: Date,
    wagons: IBulkReplacePausesWagon[]
  ) => {
    if (isBulkReplacePausesInProgress) {
      return;
    }

    setIsBulkReplacePausesInProgress(true);

    try {
      const updatedWagons = await bulkReplacePauses(api, request.id, {
        startDate,
        endDate,
        wagons,
      });

      updateData(
        prevData =>
          prevData && {
            ...prevData,
            wagons: prevData.wagons.map(prevWagon => {
              const updatedWagon = updatedWagons.find(
                wagon => wagon.wagon === prevWagon.wagon
              );

              return { ...prevWagon, ...updatedWagon };
            }),
          }
      );
    } catch (err) {
      if (err instanceof BadRequestError) {
        toaster.show({
          intent: Intent.DANGER,
          timeout: 0,
          message: err.message,
        });
      } else {
        toaster.show({
          intent: Intent.DANGER,
          timeout: 0,
          message: 'Ошибка при обновлении пауз',
        });

        throw err;
      }
    } finally {
      setIsBulkReplacePausesInProgress(false);
    }
  };

  const isPauseCreationAllowed = useMemo(() => {
    if (
      isBulkReplacePausesInProgress ||
      !startMonthDate ||
      datesColEnd == null ||
      selectedRegions.length === 0 ||
      !data
    ) {
      return false;
    }

    return selectedRegions.every(region => {
      const { cols, rows } = region;

      if (!cols || !rows) {
        return false;
      }

      if (cols[0] < datesColStart || cols[1] > datesColEnd) {
        return false;
      }

      const [regionStartDate, regionEndDate] = columnCellIntervalToDateRange(
        startMonthDate,
        cols
      );

      for (let rowIndex = rows[0]; rowIndex <= rows[1]; rowIndex++) {
        const wagon = data.wagons[rowIndex];

        if (!wagon) {
          return false;
        }

        const wagonStartDate = parseDate(wagon.startDate);
        const wagonEndDate = parseDate(wagon.endDate);

        if (regionStartDate < wagonStartDate || regionEndDate > wagonEndDate) {
          return false;
        }
      }

      return true;
    });
  }, [
    data,
    datesColStart,
    datesColEnd,
    isBulkReplacePausesInProgress,
    selectedRegions,
    startMonthDate,
  ]);

  const handleCreatePausesClick = async () => {
    if (!startMonthDate || !data) {
      return;
    }

    const wagonReplacements: IBulkReplacePausesWagon[] = [];

    selectedRegions.forEach(region => {
      const { cols, rows } = region;

      if (!cols || !rows) {
        return;
      }

      const [regionStartDate, regionEndDate] = columnCellIntervalToDateRange(
        startMonthDate,
        cols
      );

      for (let rowIndex = rows[0]; rowIndex <= rows[1]; rowIndex++) {
        const wagon = data.wagons[rowIndex];

        const replacement = getOrCreateWagonReplacement(
          wagon.wagon,
          data.wagons,
          wagonReplacements
        );

        if (!replacement) {
          return;
        }

        replacement.pauses.push({
          startDate: regionStartDate,
          endDate: regionEndDate,
          note: '',
        });
      }
    });

    await bulkReplacePausesStateful(
      data.startDate,
      data.endDate,
      wagonReplacements
    );
  };

  const pausesForSelectedRegions = useMemo(
    () =>
      data
        ? findPausesForRegions(
            selectedRegions,
            data.wagons,
            data.startDate,
            data.endDate
          )
        : [],
    [data, selectedRegions]
  );

  const isPauseEditingAllowed = useMemo(() => {
    if (isBulkReplacePausesInProgress) {
      return false;
    }

    return (
      pausesForSelectedRegions.length !== 0 &&
      pausesForSelectedRegions.every(
        pausesForRegion => pausesForRegion.found !== null
      )
    );
  }, [isBulkReplacePausesInProgress, pausesForSelectedRegions]);

  const editPausesDialogForm = useDialog<{
    initialValues: IEditPausesDialogFormValues;
  }>();

  const handleEditPausesClick = () => {
    editPausesDialogForm.open({ initialValues: { note: '' } });
  };

  const isPauseRemovalAllowed = useMemo(() => {
    if (isBulkReplacePausesInProgress) {
      return false;
    }

    return (
      pausesForSelectedRegions.length !== 0 &&
      pausesForSelectedRegions.every(
        pausesForRegion => pausesForRegion.found !== null
      )
    );
  }, [isBulkReplacePausesInProgress, pausesForSelectedRegions]);

  const handleRemovePausesClick = async () => {
    if (!data) {
      return;
    }

    const wagonReplacements: IBulkReplacePausesWagon[] = [];

    selectedRegions.forEach(region => {
      const { cols, rows } = region;

      if (!cols || !rows) {
        return;
      }

      pausesForSelectedRegions.forEach(({ found }) => {
        if (!found) {
          return;
        }

        const replacement = getOrCreateWagonReplacement(
          found.wagon,
          data.wagons,
          wagonReplacements
        );

        if (!replacement) {
          return;
        }

        replacement.pauses = replacement.pauses.filter(
          (_pause, index) => !found.pauseIndices.includes(index)
        );
      });
    });

    await bulkReplacePausesStateful(
      data.startDate,
      data.endDate,
      wagonReplacements
    );
  };

  const updatePauses = useCallback(
    async (updatedPausesInput: IWagonsDailyUsagePause[]) => {
      const updatedPauses = await bulkUpdatePauses(api, request.id, {
        pauses: updatedPausesInput,
      });

      const updatedPauseIds = updatedPauses.map(p => p.id);

      updateData(prevData => {
        if (!prevData) {
          return prevData;
        }

        return {
          ...prevData,
          wagons: prevData.wagons.map(wagon => {
            if (
              wagon.pauses.some(pause => updatedPauseIds.includes(pause.id))
            ) {
              return {
                ...wagon,
                pauses: wagon.pauses.map(originalPause => {
                  const updatedPause = updatedPauses.find(
                    pause => pause.id === originalPause.id
                  );

                  return updatedPause || originalPause;
                }),
              };
            }

            return wagon;
          }),
        };
      });
    },
    [request.id, api, updateData]
  );

  const handlePauseUpdate = useCallback(
    (updatedPauseInput: IWagonsDailyUsagePause) =>
      updatePauses([updatedPauseInput]),
    [updatePauses]
  );

  return (
    <VGrid stretch>
      <Row>
        <Toolbar>
          <Select
            fill
            options={createDateRange(
              'month',
              request.startDate,
              dayjs(request.endDate).add(1, 'month').toDate()
            ).map(monthDateToSelectOption)}
            value={route.params.month}
            onChange={newMonth => {
              applyFilterParams({ month: newMonth || undefined });
            }}
            withEmptyOption
          />

          {data?.shipment == null ? (
            <Button
              disabled={
                batchActionIsInProgress || isFetching || !startMonthDate
              }
              intent={Intent.PRIMARY}
              text="Отгрузить"
              onClick={wrapBatchAction(async () => {
                if (!startMonthDate) {
                  return;
                }

                await makeShipmentForPeriod(api, request.id, {
                  startDate: startMonthDate,
                });
              })}
            />
          ) : (
            <LinkButton
              disabled={isFetching}
              intent={Intent.SUCCESS}
              params={{ id: data.shipment }}
              rel="noopener"
              target="_blank"
              text="Отгружен"
              to="shipmentInfo.edit"
            />
          )}

          <Popover
            content={
              <VGrid>
                <Row>
                  <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    value={wagonsUsageStart}
                    onChange={setWagonsUsageStart}
                  />
                </Row>

                <Row className={css.popoverMainRow}>
                  <Toolbar align="right">
                    <Button
                      className={Classes.POPOVER_DISMISS}
                      disabled={
                        batchActionIsInProgress ||
                        !isAnyWagonSelected ||
                        !wagonsUsageStart
                      }
                      text="Применить"
                      onClick={wrapBatchAction(async () => {
                        if (!wagonsUsageStart) {
                          return;
                        }

                        await changeWagonsUsagePeriod(api, request.id, {
                          startDate: wagonsUsageStart,
                          wagons: selectedWagons,
                        });
                      })}
                    />
                  </Toolbar>
                </Row>
              </VGrid>
            }
            disabled={
              batchActionIsInProgress || !isAnyWagonSelected || shipmentExists
            }
            position={Position.BOTTOM}
          >
            <Button
              disabled={
                batchActionIsInProgress || !isAnyWagonSelected || shipmentExists
              }
              text="Изменить начало начисления"
            />
          </Popover>

          <Popover
            content={
              <VGrid>
                <Row>
                  <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    value={wagonsUsageEnd}
                    onChange={setWagonsUsageEnd}
                  />
                </Row>

                <Row className={css.popoverMainRow}>
                  <Toolbar align="right">
                    <Button
                      className={Classes.POPOVER_DISMISS}
                      disabled={
                        batchActionIsInProgress ||
                        !isAnyWagonSelected ||
                        !wagonsUsageEnd
                      }
                      text="Применить"
                      onClick={wrapBatchAction(async () => {
                        await changeWagonsUsagePeriod(api, request.id, {
                          endDate: wagonsUsageEnd,
                          wagons: selectedWagons,
                        });
                      })}
                    />
                  </Toolbar>
                </Row>
              </VGrid>
            }
            disabled={
              batchActionIsInProgress || !isAnyWagonSelected || shipmentExists
            }
            position={Position.BOTTOM}
          >
            <Button
              disabled={
                batchActionIsInProgress || !isAnyWagonSelected || shipmentExists
              }
              text="Изменить завершение начисления"
            />
          </Popover>

          <Popover
            content={
              <VGrid>
                <Row>
                  <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    value={wagonsPauseStart}
                    onChange={setWagonsPauseStart}
                  />
                </Row>

                <Row className={css.popoverMainRow}>
                  <FormGroup label="Примечание">
                    <InputMultiple
                      value={pauseStartNote}
                      onChange={event => {
                        setPauseStartNote(event.currentTarget.value);
                      }}
                    />
                  </FormGroup>

                  <Toolbar align="right">
                    <Button
                      className={Classes.POPOVER_DISMISS}
                      disabled={
                        batchActionIsInProgress ||
                        !isAnyWagonSelected ||
                        !wagonsPauseStart
                      }
                      text="Применить"
                      onClick={wrapBatchAction(async () => {
                        if (!wagonsPauseStart) {
                          return;
                        }

                        await startWagonsPause(api, request.id, {
                          note: pauseStartNote,
                          pauseDate: wagonsPauseStart,
                          wagons: selectedWagons,
                        });
                      })}
                    />
                  </Toolbar>
                </Row>
              </VGrid>
            }
            disabled={
              batchActionIsInProgress || !isAnyWagonSelected || shipmentExists
            }
            position={Position.BOTTOM}
          >
            <Button
              disabled={
                batchActionIsInProgress || !isAnyWagonSelected || shipmentExists
              }
              text="Приостановить начисления"
            />
          </Popover>

          <Popover
            content={
              <VGrid>
                <Row>
                  <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    value={wagonsPauseEnd}
                    onChange={setWagonsPauseEnd}
                  />
                </Row>

                <Row className={css.popoverMainRow}>
                  <Toolbar align="right">
                    <Button
                      className={Classes.POPOVER_DISMISS}
                      disabled={
                        batchActionIsInProgress ||
                        !isAnyWagonSelected ||
                        !wagonsPauseEnd
                      }
                      text="Применить"
                      onClick={wrapBatchAction(async () => {
                        if (!wagonsPauseEnd) {
                          return;
                        }

                        await stopWagonsPause(api, request.id, {
                          pauseDate: wagonsPauseEnd,
                          wagons: selectedWagons,
                        });
                      })}
                    />
                  </Toolbar>
                </Row>
              </VGrid>
            }
            disabled={
              batchActionIsInProgress || !isAnyWagonSelected || shipmentExists
            }
            position={Position.BOTTOM}
          >
            <Button
              disabled={
                batchActionIsInProgress || !isAnyWagonSelected || shipmentExists
              }
              text="Возобновить начисления"
            />
          </Popover>

          <WagonNumbersFilter
            initialValue={route.params.wagonsList}
            onApply={newWagonsList => {
              applyFilterParams({ wagonsList: newWagonsList });
            }}
          />
        </Toolbar>
      </Row>

      <Row>
        <Grid>
          <Col span={6}>
            <Toolbar>
              <LegendItem
                backgroundColor={WagonsDailyUsageColor.INACTIVE}
                label="вне периода начисления"
              />

              <LegendItem
                backgroundColor={WagonsDailyUsageColor.ACTIVE}
                label="в периоде начисления"
              />

              <LegendItem
                backgroundColor={WagonsDailyUsageColor.PAUSED}
                label="пауза"
              />
            </Toolbar>
          </Col>

          <Col span={6}>
            <Toolbar align="right">
              <Button
                disabled={!isPauseCreationAllowed}
                intent={Intent.PRIMARY}
                text="Пауза"
                onClick={handleCreatePausesClick}
              />

              <Button
                disabled={!isPauseEditingAllowed}
                intent={Intent.SUCCESS}
                text="Редактировать"
                onClick={handleEditPausesClick}
              />

              <Button
                disabled={!isPauseRemovalAllowed}
                intent={Intent.DANGER}
                text="Удалить"
                onClick={handleRemovePausesClick}
              />
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        {!data ? (
          isFetching ? (
            <CenteredSpinner />
          ) : startMonthDate ? (
            <GenericErrorMessage />
          ) : (
            <NonIdealState icon="warning-sign" title="Выберите месяц" />
          )
        ) : data.wagons.length === 0 ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <EmptyListMessage />
          )
        ) : (
          <WagonsDailyUsageTabTable
            endDate={data.endDate}
            isFetching={isFetching}
            selectedRegions={selectedRegions}
            selectedWagons={selectedWagons}
            startDate={data.startDate}
            wagons={data.wagons}
            onPauseUpdate={handlePauseUpdate}
            onSelectedWagonsChange={setSelectedWagons}
            onSelection={setSelectedRegions}
          />
        )}
      </Row>

      {editPausesDialogForm.state && (
        <EditPausesDialogForm
          initialValues={editPausesDialogForm.state.initialValues}
          isOpen={editPausesDialogForm.state.isOpen}
          onClose={editPausesDialogForm.close}
          onClosed={editPausesDialogForm.destroy}
          onSubmit={async values => {
            try {
              invariant(data);

              const updatePausesInput = pausesForSelectedRegions.flatMap(
                ({ found }) => {
                  if (!found) {
                    return [];
                  }

                  const wagon = data.wagons.find(w => w.wagon === found.wagon);

                  if (!wagon) {
                    return [];
                  }

                  return found.pauseIndices.map(index => ({
                    ...wagon.pauses[index],
                    note: values.note,
                  }));
                }
              );

              await updatePauses(updatePausesInput);
              editPausesDialogForm.close();
              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось отредактировать паузы: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}
    </VGrid>
  );
}

import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { Button, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { StationsAutocompleteInFormGroupForFinalForm } from 'stations/autocomplete';

export interface IBatchEditDialogFormValues {
  consignee: number | null;
  etranWaybillNumber: string;
  guNumber: string;
  loadStation: number | null;
}

interface IProps {
  initialValues: IBatchEditDialogFormValues;
  isOpen: boolean;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (
    values: IBatchEditDialogFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function WagonArrivalsBatchEditDialog({
  initialValues,
  isOpen,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ error, handleSubmit, submitError, submitting }) => (
        <DialogForm
          isOpen={isOpen}
          isSubmitting={submitting}
          title="Групповое редактирование"
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormErrors error={error || submitError} />

            <StationsAutocompleteInFormGroupForFinalForm
              id="loadStation"
              label="Станция погрузки"
              name="loadStation"
            />

            <FormGroupForFinalForm
              label="Номер ГУ"
              labelFor="guNumber"
              name="guNumber"
            >
              <InputGroupForFinalForm id="guNumber" name="guNumber" />
            </FormGroupForFinalForm>

            <PartnersAutocompleteInFormGroupForFinalForm
              id="consignee"
              label="Грузополучатель"
              name="consignee"
            />

            <FormGroupForFinalForm
              label="Накладная в Этране"
              labelFor="etranWaybillNumber"
              name="etranWaybillNumber"
            >
              <InputGroupForFinalForm
                id="etranWaybillNumber"
                name="etranWaybillNumber"
              />
            </FormGroupForFinalForm>
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button disabled={submitting} text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Сохранить"
                type="submit"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

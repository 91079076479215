import { sumMaybeDecimals } from '_core/decimal';
import { TableFieldErrors } from '_core/forms/tableFieldErrors';
import { indexByAllItems } from '_core/indexBy';
import { InputMoney } from '_core/inputs/inputMoney';
import { Select } from '_core/inputs/select';
import { isNotNull } from '_core/isNotNull';
import { formatMoney } from '_core/money/formatMoney';
import { BaseCell, CellEditor } from '_core/react-window/cells';
import { TreeCell } from '_core/react-window/treeCell';
import { TreeCellPadding } from '_core/react-window/treeCellPadding';
import { TreeTable, TreeTableColumn } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import {
  createTableCellEditor,
  everyNode,
  findNode,
  setAllNodesIsExpanded,
  switchOverNodeKind,
} from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { Button, H4, InputGroup, Intent } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import { IPartnerSerialized } from 'partners/api';
import { PartnersAutocompleteInFormGroup } from 'partners/autocomplete';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { RentWagonSellExpenseType } from 'rentWagonSell/api';
import { SuppliersDocumentsFromFileAutocompleteInFormGroup } from 'suppliersDocuments/autocomplete';
import { ISuppliersDocumentSerialized } from 'suppliersDocuments/types';
import {
  costFromTotalCostForForm,
  totalCostForForm,
  VatRate,
  vatRateLabel,
  vatRateOptions,
  vatValueForForm,
  vatValueFromTotalCostForForm,
} from 'vatRates/vatRates';

import * as css from './expensesField.module.css';

export interface IRentWagonSellExpensesFieldItem {
  cost: string;
  costTotal: string;
  costVatValue: string;
  days: number | null;
  isCalculated: boolean;
  name: string;
  partner: IPartnerSerialized | null;
  supplierDoc: ISuppliersDocumentSerialized | null;
  type: RentWagonSellExpenseType;
  vatRate: VatRate;
  year: number | null;
}

interface IProps {
  change: (name: string, value: unknown) => void;
  expensesCost: string;
  expensesCostTotal: string;
  expensesCostVatValue: string;
  name: string;
}

type ExpenseTreeTableNodeYearData = {
  kind: 'year';
  year: number;
  days: number;
  cost: string;
  costTotal: string;
  costVatValue: string;
};

type ExpenseTreeTableNodeExpenseData = {
  kind: 'expense';
  expense: IRentWagonSellExpensesFieldItem;
  index: number;
};

type ExpenseTreeTableNodeTotalData = {
  kind: 'total';
  cost: string;
  costTotal: string;
  costVatValue: string;
};

type ExpenseTreeTableNodeData =
  | ExpenseTreeTableNodeYearData
  | ExpenseTreeTableNodeExpenseData
  | ExpenseTreeTableNodeTotalData;

interface ICreateNodesOptions {
  expenses: IRentWagonSellExpensesFieldItem[];
  expensesCost: string;
  expensesCostTotal: string;
  expensesCostVatValue: string;
}

function createNodes(
  prevNodes: Array<TreeTableNode<ExpenseTreeTableNodeData>> | null,
  {
    expenses,
    expensesCost,
    expensesCostTotal,
    expensesCostVatValue,
  }: ICreateNodesOptions
) {
  function createExpenseNode(
    expense: IRentWagonSellExpensesFieldItem,
    index: number
  ): TreeTableNode<ExpenseTreeTableNodeData> {
    return {
      id: `expense-${index}`,
      data: { kind: 'expense', expense, index },
    };
  }

  const totalNode: TreeTableNode<ExpenseTreeTableNodeTotalData> = {
    id: 'total',
    data: {
      kind: 'total',
      cost: expensesCost,
      costTotal: expensesCostTotal,
      costVatValue: expensesCostVatValue,
    },
  };

  if (expenses.filter(expense => expense.isCalculated).length > 12) {
    const expensesGroupedByYear = Object.entries(
      indexByAllItems(
        expenses,
        expense => expense.year,
        (expense, index) => ({ expense, index })
      )
    ).map(([key, yearExpenses]): TreeTableNode<ExpenseTreeTableNodeData> => {
      const year = Number(key);

      const prevYearNode = prevNodes
        ? findNode(
            prevNodes,
            node => node.data.kind === 'year' && node.data.year === year
          )
        : undefined;

      return {
        id: `year-${year}`,
        data: {
          kind: 'year',
          year,
          days: yearExpenses
            .map(({ expense }) => expense.days)
            .filter(isNotNull)
            .reduce((sum, n) => sum + n, 0),
          cost: String(
            sumMaybeDecimals(yearExpenses.map(({ expense }) => expense.cost))
          ),
          costVatValue: String(
            sumMaybeDecimals(
              yearExpenses.map(({ expense }) => expense.costVatValue)
            )
          ),
          costTotal: String(
            sumMaybeDecimals(
              yearExpenses.map(({ expense }) => expense.costTotal)
            )
          ),
        },
        children: {
          isExpanded:
            prevYearNode && prevYearNode.children
              ? prevYearNode.children.isExpanded
              : false,
          nodes: yearExpenses.map(({ expense, index }) =>
            createExpenseNode(expense, index)
          ),
        },
      };
    });

    return [
      ...expensesGroupedByYear,
      ...expenses
        .map((expense, index) => ({ expense, index }))
        .filter(({ expense }) => !expense.isCalculated)
        .map(({ expense, index }) => createExpenseNode(expense, index)),
      totalNode,
    ];
  }

  return [...expenses.map(createExpenseNode), totalNode];
}

export function RentWagonSellExpensesField({
  change,
  expensesCost,
  expensesCostTotal,
  expensesCostVatValue,
  name,
}: IProps) {
  const { fields, meta } = useFieldArray<IRentWagonSellExpensesFieldItem>(name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitting = (meta as any).submitting as boolean;

  const columns = useMemo(
    (): Array<TreeTableColumn<ExpenseTreeTableNodeData>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 280,
        expandable: true,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onExpand,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            noPadding
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            <TreeCellPadding depth={row.path.length}>
              <TreeCell row={row} onExpand={onExpand}>
                {children}
              </TreeCell>
            </TreeCellPadding>
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ year }) => String(year),
            expense: ({ expense }) => expense.name,
            total: () => 'Итого',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ year }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {String(year)}
              </Ellipsis>
            ),
            expense: ({ expense }) => (
              <Ellipsis component="span">{expense.name}</Ellipsis>
            ),
            total: () => (
              <Ellipsis className={css.boldCellText} component="span">
                Итого
              </Ellipsis>
            ),
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            expense: ({ expense, index }) =>
              expense.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: expense.name,
                    applyValue: newName => {
                      change(`${fields.name}[${index}].name`, newName);
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <InputGroup
                          autoFocus
                          value={value}
                          onChange={event =>
                            onChange(event.currentTarget.value)
                          }
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'days',
        label: 'Суток начислено',
        defaultWidth: 150,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ days }) => days.toString(),
            expense: ({ expense }) =>
              expense.days == null ? '' : expense.days.toString(),
            total: () => '',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ days }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {days.toString()}
              </Ellipsis>
            ),
            expense: ({ expense }) =>
              expense.days == null ? null : (
                <Ellipsis component="span">{expense.days.toString()}</Ellipsis>
              ),
            total: () => null,
          }),
      },
      {
        id: 'cost',
        label: 'Стоимость',
        defaultWidth: 150,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ cost }) => formatMoney(cost),
            expense: ({ expense }) => formatMoney(expense.cost),
            total: ({ cost }) => formatMoney(cost),
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ cost }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(cost)}
              </Ellipsis>
            ),
            expense: ({ expense }) => (
              <Ellipsis component="span">{formatMoney(expense.cost)}</Ellipsis>
            ),
            total: ({ cost }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(cost)}
              </Ellipsis>
            ),
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            expense: ({ expense, index }) =>
              expense.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: expense.cost,
                    applyValue: newCost => {
                      change(`${fields.name}[${index}].cost`, newCost);

                      change(
                        `${fields.name}[${index}].costVatValue`,
                        vatValueForForm(newCost, expense.vatRate)
                      );

                      change(
                        `${fields.name}[${index}].costTotal`,
                        totalCostForForm(newCost, expense.vatRate)
                      );
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <InputMoney
                          autoFocus
                          value={value}
                          onChange={onChange}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'vatRate',
        label: 'Ставка НДС',
        defaultWidth: 105,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => '',
            expense: ({ expense }) => vatRateLabel(expense.vatRate),
            total: () => '',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => null,
            expense: ({ expense }) => (
              <Ellipsis component="span">
                {vatRateLabel(expense.vatRate)}
              </Ellipsis>
            ),
            total: () => null,
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            expense: ({ expense, index }) =>
              expense.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: expense.vatRate,
                    applyValue: newVatRate => {
                      change(`${fields.name}[${index}].vatRate`, newVatRate);

                      change(
                        `${fields.name}[${index}].costVatValue`,
                        vatValueForForm(expense.cost, newVatRate)
                      );

                      change(
                        `${fields.name}[${index}].costTotal`,
                        totalCostForForm(expense.cost, newVatRate)
                      );
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <Select
                          autoFocus
                          fill
                          options={vatRateOptions}
                          value={value}
                          onChange={onChange}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'costVatValue',
        label: 'НДС (руб.)',
        defaultWidth: 150,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ costVatValue }) => formatMoney(costVatValue),
            expense: ({ expense }) => formatMoney(expense.costVatValue),
            total: ({ costVatValue }) => formatMoney(costVatValue),
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ costVatValue }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(costVatValue)}
              </Ellipsis>
            ),
            expense: ({ expense }) => (
              <Ellipsis component="span">
                {formatMoney(expense.costVatValue)}
              </Ellipsis>
            ),
            total: ({ costVatValue }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(costVatValue)}
              </Ellipsis>
            ),
          }),
      },
      {
        id: 'costTotal',
        label: 'Стоимость (в т.ч. НДС)',
        defaultWidth: 150,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ costTotal }) => formatMoney(costTotal),
            expense: ({ expense }) => formatMoney(expense.costTotal),
            total: ({ costTotal }) => formatMoney(costTotal),
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ costTotal }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(costTotal)}
              </Ellipsis>
            ),
            expense: ({ expense }) => (
              <Ellipsis component="span">
                {formatMoney(expense.costTotal)}
              </Ellipsis>
            ),
            total: ({ costTotal }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(costTotal)}
              </Ellipsis>
            ),
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            expense: ({ expense, index }) =>
              expense.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: expense.costTotal,
                    applyValue: newCostTotal => {
                      change(
                        `${fields.name}[${index}].costTotal`,
                        newCostTotal
                      );

                      change(
                        `${fields.name}[${index}].costVatValue`,
                        vatValueFromTotalCostForForm(
                          newCostTotal,
                          expense.vatRate
                        )
                      );

                      change(
                        `${fields.name}[${index}].cost`,
                        costFromTotalCostForForm(newCostTotal, expense.vatRate)
                      );
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <InputMoney
                          autoFocus
                          value={value}
                          onChange={onChange}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'partner',
        label: 'Контрагент',
        defaultWidth: 270,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => '',
            expense: ({ expense }) => expense.partner?.shortName ?? '',
            total: () => '',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => null,
            expense: ({ expense }) =>
              expense.partner ? (
                <Ellipsis
                  component={Link}
                  params={{ id: String(expense.partner.id) }}
                  rel="noopener"
                  target="_blank"
                  to="partners.edit"
                >
                  {expense.partner.shortName}
                </Ellipsis>
              ) : null,
            total: () => null,
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            expense: ({ expense, index }) =>
              expense.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: expense.partner,
                    applyValue: newPartner => {
                      change(`${fields.name}[${index}].partner`, newPartner);
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <PartnersAutocompleteInFormGroup
                          autoFocus
                          initialItem={value ?? undefined}
                          noBottomMargin
                          value={value?.id ?? null}
                          onChange={(_newPartnerId, newPartner) => {
                            onChange(newPartner);
                          }}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'supplierDoc',
        label: 'Документ от поставщика',
        defaultWidth: 270,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => '',
            expense: ({ expense }) =>
              expense.supplierDoc
                ? expense.supplierDoc.number ||
                  `<номер не указан>, id: ${expense.supplierDoc.id}`
                : '',
            total: () => '',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => null,
            expense: ({ expense }) =>
              expense.supplierDoc ? (
                <Ellipsis
                  component={Link}
                  params={{ id: String(expense.supplierDoc.id) }}
                  rel="noopener"
                  target="_blank"
                  to="suppliersDocumentsFromFile.edit"
                >
                  {expense.supplierDoc.number ||
                    `<номер не указан>, id: ${expense.supplierDoc.id}`}
                </Ellipsis>
              ) : null,
            total: () => null,
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            expense: ({ expense, index }) =>
              expense.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: expense.supplierDoc,
                    applyValue: newSupplierDoc => {
                      change(
                        `${fields.name}[${index}].supplierDoc`,
                        newSupplierDoc
                      );
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <SuppliersDocumentsFromFileAutocompleteInFormGroup
                          autoFocus
                          initialItem={value ?? undefined}
                          noBottomMargin
                          value={value?.id ?? null}
                          onChange={(_newSupplierDocId, newSupplierDoc) => {
                            onChange(newSupplierDoc);
                          }}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
    ],
    [change, fields.name]
  );

  const createNodesOptions = useMemo(
    (): ICreateNodesOptions => ({
      expenses: fields.value,
      expensesCost,
      expensesCostTotal,
      expensesCostVatValue,
    }),
    [fields.value, expensesCost, expensesCostTotal, expensesCostVatValue]
  );

  const [nodes, setNodes] = useState<
    Array<TreeTableNode<ExpenseTreeTableNodeData>>
  >(() => createNodes(null, createNodesOptions));

  const lastCreateNodesOptionsRef = useRef(createNodesOptions);

  if (createNodesOptions !== lastCreateNodesOptionsRef.current) {
    setNodes(createNodes(nodes, createNodesOptions));
    lastCreateNodesOptionsRef.current = createNodesOptions;
  }

  const [selectedNodes, setSelectedNodes] = useState<
    Array<TreeTableNode<ExpenseTreeTableNodeData>>
  >([]);

  return (
    <>
      <H4>
        Расходы{' '}
        {everyNode(
          nodes,
          node => !node.children || node.children.isExpanded
        ) ? (
          <Button
            icon="collapse-all"
            minimal
            text="Свернуть всё"
            onClick={() => {
              setNodes(prevNodes => setAllNodesIsExpanded(prevNodes, false));
            }}
          />
        ) : (
          <Button
            icon="expand-all"
            minimal
            text="Развернуть всё"
            onClick={() => {
              setNodes(prevNodes => setAllNodesIsExpanded(prevNodes, true));
            }}
          />
        )}
      </H4>

      <VGrid>
        <TableFieldErrors
          fieldLabels={[
            ['name', 'Наименование'],
            ['days', 'Суток начислено'],
            ['cost', 'Стоимость'],
            ['vatRate', 'Ставка НДС'],
            ['costVatValue', 'НДС (руб.)'],
            ['costTotal', 'Стоимость (в т.ч. НДС)'],
            ['partner', 'Контрагент'],
            ['supplierDoc', 'Документ от поставщика'],
          ]}
          rowsErrors={meta.error || meta.submitError}
        />

        <Row>
          <TreeTable
            columns={columns}
            isNodeSelectable={node => node.data.kind !== 'year'}
            maxHeight={500}
            nodes={nodes}
            overscanRowCount={20}
            selectedNodes={selectedNodes}
            stickyBottomRowCount={1}
            stickyColumnCount={1}
            onNodesChange={setNodes}
            onSelectedNodesChange={setSelectedNodes}
          />
        </Row>

        <Row>
          <Toolbar>
            <Button
              disabled={submitting}
              icon="add-row-bottom"
              intent={Intent.PRIMARY}
              text="Добавить расход"
              onClick={() => {
                fields.push({
                  cost: '',
                  costTotal: '',
                  costVatValue: '',
                  days: null,
                  isCalculated: false,
                  name: '',
                  partner: null,
                  supplierDoc: null,
                  type: RentWagonSellExpenseType.Custom,
                  vatRate: VatRate.None,
                  year: null,
                });
              }}
            />

            <Button
              disabled={
                submitting ||
                selectedNodes.length === 0 ||
                selectedNodes.some(
                  node =>
                    node.data.kind !== 'expense' ||
                    node.data.expense.isCalculated
                )
              }
              icon="remove"
              intent={Intent.DANGER}
              text="Удалить выбранные расходы"
              onClick={() => {
                selectedNodes
                  .filter(
                    (
                      node
                    ): node is TreeTableNode<ExpenseTreeTableNodeExpenseData> =>
                      node.data.kind === 'expense'
                  )
                  .map(node => node.data.index)
                  .sort((a, b) => b - a)
                  .forEach(rowIndex => {
                    fields.remove(rowIndex);
                  });

                setSelectedNodes([]);
              }}
            />
          </Toolbar>
        </Row>
      </VGrid>
    </>
  );
}

import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { finiteNumberOr } from '_core/finiteNumberOr';
import { SearchForm } from '_core/forms/searchForm';
import { ICheckboxListOption } from '_core/inputs/checkboxList';
import { ColumnsSelectorsGroup } from '_core/lists/columnPresets/columnsSelectorsGroup';
import { ColumnsPreset, IShowColumns } from '_core/lists/columnPresets/types';
import { IColumnsWidth } from '_core/lists/columnPresets/useColumnPresets';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { LinkButton } from '_core/router5/linkButton';
import { Toolbar } from '_core/toolbar';
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime';
import { Tooltip2 } from '@blueprintjs/popover2';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback } from 'react';
import { State } from 'router5';
import { ArrDepOperStationsFilterInPopover } from 'stations/arrDepOperFilterInPopover';
import { DowntimeDurationFilter } from 'technical/downtimeDurationFilter';
import { WagonAlertsLegend } from 'wagons/alertsLegend';
import { IWagonSerialized } from 'wagons/api';
import { HasEtranNoteFilter } from 'wagons/hasEtranNoteFilter';
import { WagonNumbersFilter } from 'wagons/numbersFilter';
import {
  getWagonHighestPriorityAlertColor,
  wagonAlertsOptions,
} from 'wagons/utils';

import { useApiClient } from '../../_core/api/context';
import {
  DATE_FORMAT_API_DATETIME,
  DATE_FORMAT_DATE,
} from '../../_core/dates/formats';
import { useAsyncTasks } from '../../_core/download/asyncDownload';
import { DateRangePicker } from '../../_core/inputs/dateRangePicker';
import { StateContainer } from '../../_core/stateContainer';

interface IProps {
  activePreset: ColumnsPreset | null;
  applyFilterParams: (filterParams: Record<string, string | undefined>) => void;
  columnOptions: ICheckboxListOption[];
  columns: Array<ListTableColumn<IWagonSerialized>>;
  columnWidths: IColumnsWidth | null;
  data: { items: IWagonSerialized[] } | null;
  defaultValue: string[];
  initialValue: IShowColumns;
  isFetching: boolean;
  route: State;
  showColumns: string[];
  onColumnPresetChange: (preset: ColumnsPreset | null) => void;
  onColumnWidthChanged: (columnId: string, newWidth: number) => void;
  onShowColumnsApply: (showColumns: IShowColumns) => void;
}

export function WagonsList({
  activePreset,
  applyFilterParams,
  columnOptions,
  columns,
  columnWidths,
  data,
  defaultValue,
  initialValue,
  isFetching,
  route,
  showColumns,
  onColumnPresetChange,
  onColumnWidthChanged,
  onShowColumnsApply,
}: IProps) {
  const api = useApiClient();
  const tasks = useAsyncTasks();
  const getItemId = useCallback((wagon: IWagonSerialized) => wagon.number, []);

  const getRowColor = useCallback(
    (wagon: IWagonSerialized) =>
      getWagonHighestPriorityAlertColor(wagon.alerts),
    []
  );

  return (
    <VGrid stretch>
      <Row>
        <Grid>
          <Col span={3}>
            <SearchForm
              initialValue={route.params.search}
              onApply={search => {
                applyFilterParams({ search });
              }}
            />
          </Col>

          <Col span={9}>
            <Toolbar align="right">
              <ColumnsSelectorsGroup
                columnOptions={columnOptions}
                defaultValue={defaultValue}
                initialValue={initialValue}
                onApply={onShowColumnsApply}
                preset={activePreset}
                onColumnPresetChange={onColumnPresetChange}
              />

              <HasEtranNoteFilter
                initialValue={route.params.hasEtranNote}
                label="Заготовка в Этране"
                onApply={hasEtranNote => {
                  applyFilterParams({ hasEtranNote });
                }}
              />

              <WagonNumbersFilter
                initialValue={route.params.numbers}
                onApply={numbers => {
                  applyFilterParams({ numbers });
                }}
              />

              <DowntimeDurationFilter
                initialValue={{
                  downtimeDurationGreater: finiteNumberOr(
                    '',
                    route.params.downtimeDurationGreater
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ) as any,
                }}
                onApply={duration => {
                  applyFilterParams({ ...duration });
                }}
              />

              <ArrDepOperStationsFilterInPopover
                initialValue={{
                  arrivalStation: finiteNumberOr(
                    undefined,
                    route.params.arrivalStation
                  ),
                  consignee: route.params.consignee,
                  departureStation: finiteNumberOr(
                    undefined,
                    route.params.departureStation
                  ),
                  operationStation: finiteNumberOr(
                    undefined,
                    route.params.operationStation
                  ),
                }}
                showConsigneeFilter
                onApply={stationsFilter => {
                  applyFilterParams({
                    arrivalStation: String(stationsFilter.arrivalStation),
                    consignee: String(stationsFilter.consignee),
                    departureStation: String(stationsFilter.departureStation),
                    operationStation: String(stationsFilter.operationStation),
                  });
                }}
              />

              <CheckboxListFilter
                allOption
                iconName="warning-sign"
                initialValue={route.params.alerts}
                label="Предупреждения"
                options={wagonAlertsOptions}
                onApply={alerts => {
                  applyFilterParams({ alerts });
                }}
              />

              <LinkButton
                options={{ reload: true }}
                params={route.params}
                text="Обновить"
                to={route.name}
              />

              <LinkButton
                params={route.params}
                text="Сбросить фильтры"
                to={route.name}
              />

              <StateContainer<DateRange> initialState={[null, null]}>
                {(dateRange, setDateRange) => {
                  return (
                    <ButtonGroup>
                      <Tooltip2
                        placement="bottom"
                        content="Скачивает перечень завершенных простоев вагонов с признаком грузовая операция окончена (принято приемосдатчиком) за выбранный период."
                      >
                        <AnchorButton
                          disabled={
                            dateRange[0] === null || dateRange[1] === null
                          }
                          icon="download"
                          intent={Intent.SUCCESS}
                          text="Простой с ГОО"
                          onClick={async () => {
                            const { taskId } = await api.get(
                              `/parks/${route.params.id}/download_coo_report_xlsx`,
                              {
                                startDate: dateRange[0]
                                  ? dayjs(dateRange[0])
                                      .startOf('day')
                                      .format(DATE_FORMAT_API_DATETIME)
                                  : undefined,
                                endDate: dateRange[1]
                                  ? dayjs(dateRange[1])
                                      .endOf('day')
                                      .format(DATE_FORMAT_API_DATETIME)
                                  : undefined,
                              }
                            );

                            await tasks.waitForGeneratedFile(taskId);
                          }}
                        />
                      </Tooltip2>

                      <Popover
                        content={
                          <DateRangePicker
                            allowSingleDayRange
                            value={dateRange}
                            onChange={setDateRange}
                          />
                        }
                        position={Position.BOTTOM}
                      >
                        <Button
                          intent={Intent.SUCCESS}
                          rightIcon="calendar"
                          text={
                            dateRange[0] &&
                            dateRange[1] &&
                            `${dayjs(dateRange[0]).format(
                              DATE_FORMAT_DATE
                            )}-${dayjs(dateRange[1]).format(DATE_FORMAT_DATE)}`
                          }
                        />
                      </Popover>
                    </ButtonGroup>
                  );
                }}
              </StateContainer>

              {route.name === 'parks.edit.downtimes' && (
                <StateContainer<DateRange> initialState={[null, null]}>
                  {(dateRange, setDateRange) => {
                    // /api/parks/341/download_downtime_claim_xlsx?start_date=2021-11-01T00%3A00&end_date=2021-11-30T23%3A59

                    return (
                      <ButtonGroup>
                        <Tooltip2
                          placement="bottom"
                          content="Формирует исходящую претензию с простоями вагонов парка за выбранный период."
                        >
                          <AnchorButton
                            disabled={
                              dateRange[0] === null || dateRange[1] === null
                            }
                            icon="download"
                            intent={Intent.SUCCESS}
                            text="Претензия"
                            onClick={async () => {
                              const { taskId } = await api.get(
                                `/parks/${route.params.id}/download_downtime_claim_xlsx`,
                                {
                                  startDate: dateRange[0]
                                    ? dayjs(dateRange[0])
                                        .startOf('day')
                                        .format(DATE_FORMAT_API_DATETIME)
                                    : undefined,
                                  endDate: dateRange[1]
                                    ? dayjs(dateRange[1])
                                        .endOf('day')
                                        .format(DATE_FORMAT_API_DATETIME)
                                    : undefined,
                                }
                              );

                              await tasks.waitForGeneratedFile(taskId);
                            }}
                          />
                        </Tooltip2>

                        <Popover
                          content={
                            <DateRangePicker
                              allowSingleDayRange
                              value={dateRange}
                              onChange={setDateRange}
                            />
                          }
                          position={Position.BOTTOM}
                        >
                          <Button
                            intent={Intent.SUCCESS}
                            rightIcon="calendar"
                            text={
                              dateRange[0] &&
                              dateRange[1] &&
                              `${dayjs(dateRange[0]).format(
                                DATE_FORMAT_DATE
                              )}-${dayjs(dateRange[1]).format(
                                DATE_FORMAT_DATE
                              )}`
                            }
                          />
                        </Popover>
                      </ButtonGroup>
                    );
                  }}
                </StateContainer>
              )}
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row>
        <WagonAlertsLegend />
      </Row>

      <Row stretch>
        {!data ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <GenericErrorMessage />
          )
        ) : data.items.length === 0 ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <EmptyListMessage />
          )
        ) : (
          <ListTable
            columns={columns}
            columnWidths={columnWidths}
            getItemId={getItemId}
            getRowColor={getRowColor}
            isFetching={isFetching}
            items={data.items}
            stickyColumnCount={2}
            showColumns={showColumns}
            onColumnWidthChanged={onColumnWidthChanged}
          />
        )}
      </Row>
    </VGrid>
  );
}

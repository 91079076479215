import {
  createEntityAutocomplete,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { ITechrunRequest } from './types';

export const TechrunRequestsAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<ITechrunRequest, number>({
      baseEndpoint: '/techrun_requests',
      itemToOption: request => ({
        label: request.requestName,
        value: request.id,
      }),

      getLink: request => ({
        params: { id: String(request.id) },
        to: 'techruns.requests.view',
      }),
    })
  );

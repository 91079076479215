import { datePickerLocaleUtils } from '_core/dates/datePickerLocaleUtils';
import { Divider, Menu, MenuItem } from '@blueprintjs/core';
import {
  DateRangePicker as BlueprintDateRangePicker,
  DateRangePickerProps,
} from '@blueprintjs/datetime';
import { Tooltip2 } from '@blueprintjs/popover2';
import dayjs from 'dayjs';
import * as React from 'react';
import invariant from 'tiny-invariant';

import * as css from './dateRangePicker.module.css';

type DisabledProps = 'shortcuts';
type RequiredProps = 'value' | 'onChange';

interface Props
  extends Required<Pick<DateRangePickerProps, RequiredProps>>,
    Omit<DateRangePickerProps, DisabledProps | RequiredProps> {
  maxDaysInRange?: [number, string];
}

export const DateRangePicker = React.forwardRef(function DateRangePicker(
  {
    locale = 'ru',
    localeUtils = datePickerLocaleUtils,
    maxDate,
    maxDaysInRange,
    minDate,
    value,
    onChange,
    ...otherProps
  }: Props,
  ref: React.ForwardedRef<BlueprintDateRangePicker>
) {
  if (maxDaysInRange) {
    invariant(!maxDate && !minDate);
  }

  const today = dayjs().startOf('date').toDate();
  const yesterday = dayjs(today).subtract(1, 'day').toDate();

  function sc(label: string, dateRange: [Date, Date]) {
    return { dateRange, label };
  }

  const shortcuts = [
    sc('Сегодня', [today, today]),
    sc('Вчера', [yesterday, yesterday]),
    sc('Неделя', [
      dayjs(today).subtract(1, 'week').add(1, 'day').toDate(),
      today,
    ]),
    sc('1-я декада месяца', [
      dayjs(today).set('date', 1).toDate(),
      dayjs(today).set('date', 10).toDate(),
    ]),
    sc('2-я декада месяца', [
      dayjs(today).set('date', 11).toDate(),
      dayjs(today).set('date', 20).toDate(),
    ]),
    sc('3-я декада месяца', [
      dayjs(today).set('date', 21).toDate(),
      dayjs(today).endOf('month').startOf('date').toDate(),
    ]),
    sc('Месяц', [
      dayjs(today).subtract(1, 'month').add(1, 'day').toDate(),
      today,
    ]),
    sc('3 месяца', [
      dayjs(today).subtract(3, 'month').add(1, 'day').toDate(),
      today,
    ]),
    sc('6 месяцев', [
      dayjs(today).subtract(6, 'month').add(1, 'day').toDate(),
      today,
    ]),
    sc('Год', [dayjs(today).subtract(1, 'year').add(1, 'day').toDate(), today]),
    sc('2 года', [
      dayjs(today).subtract(2, 'year').add(1, 'day').toDate(),
      today,
    ]),
  ];

  let dateRangePickerMaxDate = maxDate;
  let dateRangePickerMinDate = minDate;

  if (maxDaysInRange != null && !(value[0] && value[1])) {
    const pivot = value[0] || value[1];

    if (pivot) {
      dateRangePickerMinDate = dayjs(pivot)
        .subtract(maxDaysInRange[0], 'day')
        .toDate();

      dateRangePickerMaxDate = dayjs(pivot)
        .add(maxDaysInRange[0] - 1, 'day')
        .toDate();
    }
  }

  return (
    <div className={css.root}>
      <Menu>
        {shortcuts.map(shortcut => {
          const daysInRange = dayjs(shortcut.dateRange[1]).diff(
            shortcut.dateRange[0],
            'day'
          );

          return (
            <MenuItem
              key={shortcut.label}
              active={
                value[0]?.getTime() === shortcut.dateRange[0].getTime() &&
                value[1]?.getTime() === shortcut.dateRange[1].getTime()
              }
              shouldDismissPopover={false}
              disabled={
                (minDate != null &&
                  shortcut.dateRange[0].getTime() < minDate.getTime()) ||
                (maxDate != null &&
                  shortcut.dateRange[1].getTime() > maxDate.getTime()) ||
                (maxDaysInRange != null && daysInRange > maxDaysInRange[0])
              }
              onClick={() => {
                onChange(shortcut.dateRange);
              }}
              text={
                maxDaysInRange ? (
                  <Tooltip2
                    content={maxDaysInRange[1]}
                    disabled={daysInRange <= maxDaysInRange[0]}
                  >
                    {shortcut.label}
                  </Tooltip2>
                ) : (
                  shortcut.label
                )
              }
            />
          );
        })}
      </Menu>

      <Divider />

      <BlueprintDateRangePicker
        allowSingleDayRange
        {...otherProps}
        locale={locale}
        localeUtils={localeUtils}
        maxDate={dateRangePickerMaxDate}
        minDate={dateRangePickerMinDate}
        ref={ref}
        shortcuts={false}
        value={value}
        onChange={onChange}
      />
    </div>
  );
});

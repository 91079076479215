import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { NonIdealState } from '@blueprintjs/core';
import { fetchTelegramLink } from 'accounts/api';
import * as React from 'react';
import { useRouteNode } from 'react-router5';

export default function TelegramRoute() {
  const { route } = useRouteNode('telegram');
  const api = useApiClient();
  const [error, setError] = React.useState<unknown>();

  React.useEffect(() => {
    let cancelled = false;

    fetchTelegramLink(api, route.params.uid, route.params.token)
      .then(response => {
        if (!cancelled) {
          location.href = response.link;
        }
      })
      .catch(err => {
        if (!cancelled) {
          setError(err);
        }
      });

    return () => {
      cancelled = true;
    };
  });

  return error ? (
    error instanceof BadRequestError ? (
      <NonIdealState icon="error" description={error.message} title="Ошибка" />
    ) : (
      <GenericErrorMessage error={error} />
    )
  ) : (
    <CenteredSpinner />
  );
}

import { datePickerLocaleUtils } from '_core/dates/datePickerLocaleUtils';
import { DatePicker as BlueprintDatePicker } from '@blueprintjs/datetime';
import * as React from 'react';

interface Props {
  canClearSelection?: boolean;
  maxDate?: Date;
  minDate?: Date;
  value?: Date;
  onChange?: (newValue: Date) => void;
}

export function DatePicker({
  canClearSelection,
  maxDate,
  minDate,
  value,
  onChange,
}: Props) {
  return (
    <BlueprintDatePicker
      canClearSelection={canClearSelection}
      locale="ru"
      localeUtils={datePickerLocaleUtils}
      maxDate={maxDate}
      minDate={minDate}
      value={value}
      onChange={onChange}
    />
  );
}

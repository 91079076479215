import * as React from 'react';

export function BaseForm(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
) {
  return <form noValidate {...props} />;
}

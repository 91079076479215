import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import {
  DateInputInFormGroup,
  DateInputInFormGroupForFinalForm,
} from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroupForFinalForm } from '_core/inputs/inputMoney';
import {
  SelectInFormGroupForFinalForm,
  SelectOption,
} from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { AnchorButton, Button, H4, Intent, Tooltip } from '@blueprintjs/core';
import { UsersAutocompleteInFormGroup } from 'accounts/autocomplete';
import { ContactsAutocompleteInFormGroupForFinalForm } from 'contacts/autocomplete';
import { ContractKind } from 'contracts/types';
import { DocumentStatusSelectForFinalForm } from 'documents/statusSelect';
import { DocumentStatus } from 'documents/types';
import { EtsngAutocompleteInFormGroupForFinalForm } from 'etsng/autocomplete';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useState } from 'react';
import { TechrunProtocolRateType } from 'techrunProtocols/types';
import { techrunProtocolRateTypeOptions } from 'techrunProtocols/utils';
import { ITechrunProtocolPurchase } from 'techrunProtocolsPurchase/api';
import { ITechrunProtocolSell } from 'techrunProtocolsSell/api';
import { TripType, tripTypeOptions, vatRateForTripType } from 'tripTypes/types';
import {
  totalCostForForm,
  VatRate,
  vatRateOptions,
  vatValueForForm,
} from 'vatRates/vatRates';

import { ProtocolDateAlert } from '../protocolDateAlert';
import {
  ITechrunProtocolRatesFieldValue,
  TechrunProtocolRatesField,
} from './ratesField';

export interface ITechrunProtocolFormValues {
  additionalDowntimeRate: string;
  cargo: number | null;
  date: Date | null;
  daysLoad: string;
  daysUnload: string;
  downtimeLimit: string;
  downtimeRate: string;
  downtimeRateTotal: string;
  downtimeRateVatValue: string;
  downtimeVatRate: VatRate;
  endDate: Date | null;
  files: string[];
  hasEmptyTariff: boolean;
  hasIntEmptyTariff: boolean;
  hasIntLoadedTariff: boolean;
  hasLoadedTariff: boolean;
  hasUseWagon: boolean;
  name: string;
  number: string;
  partnerSign: number | null;
  rates: ITechrunProtocolRatesFieldValue[];
  rateType: TechrunProtocolRateType;
  signerAuthority: string;
  startDate: Date | null;
  status: DocumentStatus;
  tripType: TripType;
  vatRate: VatRate | null;
  wagonType: number | null;
}

interface IProps {
  contractKind: ContractKind;
  docxLink: string | undefined;
  editingLocked: boolean;
  initialValues: ITechrunProtocolFormValues;
  navigationLock: IFormNavigationLock;
  selectedProtocol: ITechrunProtocolPurchase | ITechrunProtocolSell | undefined;
  wagonTypeOptions: SelectOption[];
  onCancelEditing: (() => void) | undefined;
  onDuplicate: (() => void) | undefined;
  onRatesImportUpload: (file: string) => Promise<void>;
  onStartEditing: (() => void) | undefined;
  onSubmit: (
    values: ITechrunProtocolFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function TechrunProtocolForm({
  contractKind,
  docxLink,
  editingLocked,
  initialValues,
  navigationLock,
  selectedProtocol,
  wagonTypeOptions,
  onCancelEditing,
  onDuplicate,
  onRatesImportUpload,
  onStartEditing,
  onSubmit,
}: IProps) {
  const [dateAlertIsOpen, setDateAlertIsOpen] = useState(false);

  return (
    <FinalForm<ITechrunProtocolFormValues>
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <H4>
                {selectedProtocol
                  ? `Протокол ${selectedProtocol.protocolNumber}`
                  : 'Новый протокол'}
              </H4>

              {docxLink && (
                <Tooltip
                  content={
                    editingLocked
                      ? undefined
                      : 'Сохраните протокол перед скачиванием'
                  }
                >
                  <AnchorButton
                    disabled={!editingLocked}
                    href={docxLink}
                    icon="download"
                    intent={Intent.PRIMARY}
                    text="Скачать .docx"
                  />
                </Tooltip>
              )}
            </Row>

            <Row>
              <Grid>
                <Col span={4}>
                  <H4>Основная информация</H4>

                  <FormGroupForFinalForm
                    label="Наименование"
                    labelFor="name"
                    name="name"
                  >
                    <InputGroupForFinalForm
                      id="name"
                      name="name"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Номер"
                    labelFor="number"
                    name="number"
                  >
                    <InputGroupForFinalForm
                      id="number"
                      name="number"
                      readOnly={editingLocked}
                      required
                    />
                  </FormGroupForFinalForm>

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="startDate"
                        label="Дата начала"
                        name="startDate"
                        readOnly={editingLocked}
                        required
                      />

                      <SelectInFormGroupForFinalForm
                        disabled={editingLocked}
                        id="tripType"
                        label="Вид перевозки"
                        name="tripType"
                        options={tripTypeOptions}
                        onChange={(newTripType: TripType) => {
                          form.change(
                            'vatRate',
                            vatRateForTripType(newTripType)
                          );
                        }}
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="endDate"
                        label="Дата окончания"
                        name="endDate"
                        readOnly={editingLocked}
                        required
                      />

                      <SelectInFormGroupForFinalForm
                        disabled={editingLocked}
                        emptyOptionText="Без НДС"
                        id="vatRate"
                        label="Ставка НДС"
                        name="vatRate"
                        options={vatRateOptions}
                        withEmptyOption
                        onChange={(newVatRate: VatRate | null) => {
                          values.rates.forEach((rate, rowIndex) => {
                            form.change(
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              `rates[${rowIndex}].vatValue` as any,
                              vatValueForForm(
                                rate.cost,
                                newVatRate || VatRate.None
                              )
                            );

                            form.change(
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              `rates[${rowIndex}].costTotal` as any,
                              totalCostForForm(
                                rate.cost,
                                newVatRate || VatRate.None
                              )
                            );
                          });
                        }}
                      />
                    </Col>
                  </Grid>

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="wagonType"
                    label="Вид подвижного состава"
                    name="wagonType"
                    options={wagonTypeOptions}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="rateType"
                    label="Вид ставки"
                    name="rateType"
                    options={techrunProtocolRateTypeOptions}
                  />

                  <DocumentStatusSelectForFinalForm
                    disabled={editingLocked}
                    id="status"
                    label="Статус"
                    name="status"
                  />

                  {selectedProtocol && (
                    <Grid>
                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата создания"
                          readOnly
                          value={selectedProtocol.created}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор"
                          readOnly
                          value={selectedProtocol.author}
                        />
                      </Col>

                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата изменения"
                          readOnly
                          value={selectedProtocol.modified}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор изменений"
                          readOnly
                          value={selectedProtocol.authorModified}
                        />
                      </Col>
                    </Grid>
                  )}

                  <FormGroupForFinalForm
                    label="Основание для подписи"
                    labelFor="signerAuthority"
                    name="signerAuthority"
                  >
                    <InputGroupForFinalForm
                      id="signerAuthority"
                      name="signerAuthority"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>
                </Col>

                <Col span={4}>
                  <H4>Нормативные сроки</H4>

                  <EtsngAutocompleteInFormGroupForFinalForm
                    id="cargo"
                    label="Наименование груза и код ЕТСНГ"
                    name="cargo"
                    readOnly={editingLocked}
                  />

                  <FormGroupForFinalForm
                    label="Дней на погрузку"
                    labelFor="daysLoad"
                    name="daysLoad"
                  >
                    <InputGroupForFinalForm
                      id="daysLoad"
                      name="daysLoad"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Дней на выгрузку"
                    labelFor="daysUnload"
                    name="daysUnload"
                  >
                    <InputGroupForFinalForm
                      id="daysUnload"
                      name="daysUnload"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Доп. порог простоя"
                    labelFor="downtimeLimit"
                    name="downtimeLimit"
                  >
                    <InputGroupForFinalForm
                      id="downtimeLimit"
                      name="downtimeLimit"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>
                </Col>

                <Col span={4}>
                  <H4>Доп. порог простоя</H4>

                  <InputMoneyInFormGroupForFinalForm
                    id="downtimeRate"
                    label="Ставка за простой"
                    name="downtimeRate"
                    readOnly={editingLocked}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="downtimeVatRate"
                    label="Ставка НДС"
                    name="downtimeVatRate"
                    options={vatRateOptions}
                  />

                  <InputMoneyInFormGroupForFinalForm
                    id="additionalDowntimeRate"
                    label="Ставка за простой сверх доп. порога"
                    name="additionalDowntimeRate"
                    readOnly={editingLocked}
                  />

                  <InputMoneyInFormGroupForFinalForm
                    id="downtimeRateVatValue"
                    label="Размер НДС ставки за простой"
                    name="downtimeRateVatValue"
                    readOnly
                  />

                  <InputMoneyInFormGroupForFinalForm
                    id="downtimeRateTotal"
                    label="Ставка за простой итого"
                    name="downtimeRateTotal"
                    readOnly
                  />
                </Col>
              </Grid>
            </Row>

            <Row>
              <H4>Опции</H4>

              <Grid>
                <Col span={8}>
                  <CheckboxForFinalForm
                    disabled={editingLocked}
                    id="hasUseWagon"
                    label="Пользование вагоном"
                    name="hasUseWagon"
                  />

                  <CheckboxForFinalForm
                    disabled={editingLocked}
                    id="hasEmptyTariff"
                    label="Порожний тариф включён в стоимость ставки"
                    name="hasEmptyTariff"
                  />

                  <CheckboxForFinalForm
                    disabled={editingLocked}
                    id="hasLoadedTariff"
                    label="Груженый тариф включён в стоимость ставки"
                    name="hasLoadedTariff"
                  />

                  <CheckboxForFinalForm
                    disabled={
                      editingLocked || values.tripType === TripType.Internal
                    }
                    id="hasIntLoadedTariff"
                    label="Груженый по иностранным государствам"
                    name="hasIntLoadedTariff"
                  />

                  <CheckboxForFinalForm
                    disabled={
                      editingLocked || values.tripType === TripType.Internal
                    }
                    id="hasIntEmptyTariff"
                    label="Порожний по иностранным государствам"
                    name="hasIntEmptyTariff"
                  />
                </Col>

                <Col span={4}>
                  <ContactsAutocompleteInFormGroupForFinalForm
                    id="partnerSign"
                    label="Подписывающий контакт"
                    name="partnerSign"
                    readOnly={editingLocked}
                  />

                  <DateInputInFormGroupForFinalForm
                    fill
                    id="date"
                    label="Дата протокола"
                    name="date"
                    readOnly={editingLocked}
                    required
                  />
                </Col>
              </Grid>
            </Row>

            <Row>
              <H4>Файлы протокола</H4>

              <FileInputMultipleForFinalForm
                disabled={editingLocked}
                id="files"
                name="files"
              />
            </Row>

            <Row>
              <H4>Ставки</H4>

              <TechrunProtocolRatesField
                change={form.change}
                contractKind={contractKind}
                hasEmptyTariff={values.hasEmptyTariff}
                hasLoadedTariff={values.hasLoadedTariff}
                name="rates"
                readOnly={editingLocked}
                vatRate={values.vatRate}
                onRatesImportUpload={onRatesImportUpload}
              />
            </Row>

            <Row>
              <Toolbar align="right">
                {selectedProtocol && (
                  <AnchorButton
                    icon="trash"
                    href={`mailto:it@tk-ra.ru?subject=Запрос на удаление протокола техрейса&body=Наименование протокола: ${selectedProtocol.protocolName}. Ссылка на протокол: ${document.location.href}`}
                    text="Запрос на удаление"
                  />
                )}

                {onDuplicate && editingLocked && (
                  <Button
                    disabled={submitting}
                    icon="duplicate"
                    text="Дублировать"
                    onClick={onDuplicate}
                  />
                )}

                {onStartEditing && (
                  <Button
                    disabled={submitting}
                    icon="unlock"
                    intent={Intent.DANGER}
                    text="Редактировать"
                    onClick={onStartEditing}
                  />
                )}

                {onCancelEditing && (
                  <Button
                    disabled={submitting}
                    text="Отменить"
                    onClick={() => {
                      form.reset();
                      onCancelEditing();
                    }}
                  />
                )}

                {!editingLocked && (
                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    loading={submitting}
                    text="Сохранить"
                    onClick={() => {
                      const now = new Date();

                      if (
                        !selectedProtocol &&
                        values.endDate &&
                        values.endDate.getFullYear() > now.getFullYear()
                      ) {
                        setDateAlertIsOpen(true);
                      } else {
                        form.submit();
                      }
                    }}
                  />
                )}
              </Toolbar>
            </Row>
          </VGrid>

          <ProtocolDateAlert
            isOpen={dateAlertIsOpen}
            onCancel={() => setDateAlertIsOpen(false)}
            onConfirm={() => {
              form.submit();
              setDateAlertIsOpen(false);
            }}
          />
        </BaseForm>
      )}
    </FinalForm>
  );
}

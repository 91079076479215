import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import {
  DateInputInFormGroup,
  DateInputInFormGroupForFinalForm,
} from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroup } from '_core/inputs/inputMoney';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Select } from '_core/inputs/select';
import { ColumnsSelectorsGroup } from '_core/lists/columnPresets/columnsSelectorsGroup';
import { useColumnPresets } from '_core/lists/columnPresets/useColumnPresets';
import { ListTable } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import {
  Button,
  FormGroup,
  H4,
  Icon,
  InputGroup,
  Intent,
  Label,
} from '@blueprintjs/core';
import { downtimeTypeOptions, IDowntime } from 'downtimes/types';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useRef } from 'react';
import { StationsAutocompleteInFormGroup } from 'stations/autocomplete';
import { ITechrunTransportation } from 'techrunTransportations/types';
import {
  accountingStateOptions,
  transportationStateOptions,
  transportationStatusFromGroup,
} from 'transportations/types';
import { IDislocationSerialized } from 'wagons/api';
import { createDislocationColumns } from 'wagons/dislocationColumns';

export interface IDowntimesFormValues {
  corrAdditionalDowntimeLimit: string;
  corrDowntimeLimit: string;
  corrEndDate: Date | null;
  corrStartDate: Date | null;
  name: string;
  note: string;
}

interface IProps {
  dislocations: IDislocationSerialized[];
  downtime: IDowntime;
  initialValues: IDowntimesFormValues;
  navigationLock: IFormNavigationLock;
  transportation: ITechrunTransportation;
  onSave: (values: IDowntimesFormValues) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IDowntimesFormValues
  ) => Promise<SubmissionErrors | void>;
}

type SubmitTrigger = 'save' | 'saveAndContinue';

export function DowntimesForm({
  dislocations,
  downtime,
  initialValues,
  navigationLock,
  transportation,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const submitTriggerRef = useRef<SubmitTrigger>('save');
  const columns = React.useMemo(() => createDislocationColumns(), []);

  const columnLabels = columns.reduce<Record<string, string>>(
    (labels, column) => {
      labels[column.id] = column.label;

      return labels;
    },

    {}
  );

  const columnPresets = useColumnPresets(['downtimes', 'edit'], {
    allColumnIds: columns.map(column => column.id),
    alwaysShownColumnIds: ['trainNumber'],
    getColumnLabel: columnId => columnLabels[columnId],
  });

  const getItemId = useCallback(
    (dislocation: IDislocationSerialized) => String(dislocation.id),
    []
  );

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={4}>
                  <H4>Основная информация</H4>

                  <Grid>
                    <Col span={2}>
                      <FormGroup label="Тип">
                        <Select
                          disabled
                          fill
                          id="type"
                          name="type"
                          options={downtimeTypeOptions}
                          value={downtime.type}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={2}>
                      <FormGroup label="Бух. состояние">
                        <Select
                          disabled
                          fill
                          id="accountingState"
                          name="accountingState"
                          options={accountingStateOptions}
                          value={downtime.accountingState}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={4}>
                      <FormGroupForFinalForm
                        label="Наименование"
                        labelFor="name"
                        name="name"
                      >
                        <InputGroupForFinalForm id="name" name="name" />
                      </FormGroupForFinalForm>
                    </Col>

                    <Col span={2}>
                      <FormGroup label="Накладная" labelFor="waybillNumber">
                        <InputGroup
                          id="waybillNumber"
                          name="waybillNumber"
                          readOnly
                          value={downtime.waybillNumber}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={2}>
                      <FormGroup label="Вагон" labelFor="wagon">
                        <InputGroup
                          id="wagon"
                          name="wagon"
                          readOnly
                          value={downtime.wagon}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={4}>
                      <FormGroup label="Отправка" labelFor="transportationName">
                        <InputGroup
                          id="transportationName"
                          name="transportationName"
                          readOnly
                          value={transportation.transportationName}
                        />
                      </FormGroup>

                      <p>
                        <Link
                          params={{
                            id: transportation.id,
                            status: transportationStatusFromGroup(
                              transportation.group
                            ),
                          }}
                          rel="noopener"
                          target="_blank"
                          to="techruns.transportations.edit"
                        >
                          Перейти к отправке <Icon icon="link" />
                        </Link>
                      </p>
                    </Col>

                    <Col span={4}>
                      <FormGroup label="Состояние отправки">
                        <Select
                          disabled
                          fill
                          id="transportationState"
                          name="transportationState"
                          options={transportationStateOptions}
                          value={downtime.transportationState}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={2}>
                      <FormGroup
                        label="Накладная отпр."
                        labelFor="transportationWaybillNumber"
                      >
                        <InputGroup
                          id="transportationWaybillNumber"
                          name="transportationWaybillNumber"
                          readOnly
                          value={transportation.waybillNumber}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={2}>
                      <FormGroup
                        label="Вагон отпр."
                        labelFor="transportationWagon"
                      >
                        <InputGroup
                          id="transportationWagon"
                          name="transportationWagon"
                          readOnly
                          value={transportation.wagon}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={2}>
                      <StationsAutocompleteInFormGroup
                        id="departureStation"
                        label="Ст. отправления"
                        name="departureStation"
                        readOnly
                        value={downtime.departureStation}
                      />
                    </Col>

                    <Col span={2}>
                      <StationsAutocompleteInFormGroup
                        id="arrivalStation"
                        label="Ст. назначения"
                        name="arrivalStation"
                        readOnly
                        value={downtime.arrivalStation}
                      />
                    </Col>

                    <Col span={4}>
                      <StationsAutocompleteInFormGroup
                        id="downtimeStation"
                        label="Станция простоя"
                        name="downtimeStation"
                        readOnly
                        value={downtime.downtimeStation}
                      />
                    </Col>
                  </Grid>
                </Col>

                <Col span={4}>
                  <H4>Даты и суммы</H4>

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroup
                        id="startDate"
                        label="Дата начала"
                        name="startDate"
                        readOnly
                        value={downtime.startDate}
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroup
                        id="endDate"
                        label="Дата окончания"
                        name="endDate"
                        readOnly
                        value={downtime.endDate}
                      />
                    </Col>

                    <Col span={4}>
                      <DateInputInFormGroup
                        id="stationDepartureDate"
                        label="Дата отправления со станции"
                        name="stationDepartureDate"
                        readOnly
                        value={downtime.stationDepartureDate}
                      />
                    </Col>

                    <Col span={2}>
                      <FormGroup
                        label="Номер накладной приб."
                        labelFor="beforeLoadWaybillNumber"
                      >
                        <InputGroup
                          id="beforeLoadWaybillNumber"
                          name="beforeLoadWaybillNumber"
                          readOnly
                          value={downtime.beforeLoadWaybillNumber}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={2}>
                      <FormGroup
                        label="Номер накладной отпр."
                        labelFor="afterUnloadWaybillNumber"
                      >
                        <InputGroup
                          id="afterUnloadWaybillNumber"
                          name="afterUnloadWaybillNumber"
                          readOnly
                          value={downtime.afterUnloadWaybillNumber}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={2}>
                      <FormGroup label="Норма 1" labelFor="downtimeLimit1">
                        <InputGroup
                          id="downtimeLimit1"
                          name="downtimeLimit1"
                          readOnly
                          value={String(downtime.downtimeLimit1)}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={2}>
                      <FormGroup label="Норма 2" labelFor="downtimeLimit2">
                        <InputGroup
                          id="downtimeLimit2"
                          name="downtimeLimit2"
                          readOnly
                          value={String(downtime.downtimeLimit2)}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={4}>
                      <H4>Рассчитанные</H4>
                    </Col>

                    <Col align="end" span={2}>
                      <Label>Простой</Label>
                    </Col>

                    <Col span={2}>
                      <FormGroup labelFor="downtimeDuration">
                        <InputGroup
                          id="downtimeDuration"
                          name="downtimeDuration"
                          readOnly
                          value={
                            downtime.downtimeDuration == null
                              ? ''
                              : String(downtime.downtimeDuration)
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col align="end" span={2}>
                      <Label>Сверх нормы</Label>
                    </Col>

                    <Col span={1}>
                      <FormGroup labelFor="durationOverLimit1">
                        <InputGroup
                          id="durationOverLimit1"
                          name="durationOverLimit1"
                          readOnly
                          value={String(downtime.durationOverLimit1)}
                        />
                      </FormGroup>
                    </Col>

                    <Col span={1}>
                      <FormGroup labelFor="durationOverLimit2">
                        <InputGroup
                          id="durationOverLimit2"
                          name="durationOverLimit2"
                          readOnly
                          value={String(downtime.durationOverLimit2)}
                        />
                      </FormGroup>
                    </Col>

                    <Col align="end" span={2}>
                      <Label>Ставка</Label>
                    </Col>

                    <Col span={1}>
                      <InputMoneyInFormGroup
                        id="downtimeRate"
                        name="downtimeRate"
                        readOnly
                        value={downtime.downtimeRate}
                      />
                    </Col>

                    <Col span={1}>
                      <InputMoneyInFormGroup
                        id="additionalDowntimeRate"
                        name="additionalDowntimeRate"
                        readOnly
                        value={downtime.additionalDowntimeRate}
                      />
                    </Col>

                    <Col align="end" span={2}>
                      <Label>Стоимость</Label>
                    </Col>

                    <Col span={1}>
                      <InputMoneyInFormGroup
                        id="overLimit1Cost"
                        name="overLimit1Cost"
                        readOnly
                        value={downtime.overLimit1Cost}
                      />
                    </Col>

                    <Col span={1}>
                      <InputMoneyInFormGroup
                        id="overLimit2Cost"
                        name="overLimit2Cost"
                        readOnly
                        value={downtime.overLimit2Cost}
                      />
                    </Col>

                    <Col align="end" span={2}>
                      <Label>Итого стоимость</Label>
                    </Col>

                    <Col span={2}>
                      <InputMoneyInFormGroup
                        id="totalOverLimitCost"
                        name="totalOverLimitCost"
                        readOnly
                        value={downtime.totalOverLimitCost}
                      />
                    </Col>

                    <Col span={4}>
                      <H4>Заметка</H4>

                      <FormGroupForFinalForm labelFor="note" name="note">
                        <InputMultipleForFinalForm
                          id="note"
                          name="note"
                          rows={5}
                        />
                      </FormGroupForFinalForm>
                    </Col>
                  </Grid>
                </Col>

                <Col span={4}>
                  <H4>Корректировка</H4>

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        id="corrStartDate"
                        label="Дата начала"
                        name="corrStartDate"
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        id="corrEndDate"
                        label="Дата окончания"
                        name="corrEndDate"
                      />
                    </Col>

                    <Col span={2}>
                      <FormGroupForFinalForm
                        label="Норма 1"
                        labelFor="corrDowntimeLimit"
                        name="corrDowntimeLimit"
                      >
                        <InputGroupForFinalForm
                          id="corrDowntimeLimit"
                          name="corrDowntimeLimit"
                        />
                      </FormGroupForFinalForm>
                    </Col>

                    <Col span={2}>
                      <FormGroupForFinalForm
                        label="Норма 2"
                        labelFor="corrAdditionalDowntimeLimit"
                        name="corrAdditionalDowntimeLimit"
                      >
                        <InputGroupForFinalForm
                          id="corrAdditionalDowntimeLimit"
                          name="corrAdditionalDowntimeLimit"
                        />
                      </FormGroupForFinalForm>
                    </Col>
                  </Grid>
                </Col>
              </Grid>
            </Row>

            <Row>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitTriggerRef.current = 'save';
                  }}
                />
              </Toolbar>
            </Row>

            <Row>
              <ColumnsSelectorsGroup
                columnOptions={columnPresets.columnOptions}
                defaultValue={columnPresets.defaultValue}
                initialValue={columnPresets.initialValue}
                onApply={columnPresets.onShowColumnsApply}
                preset={columnPresets.activePreset}
                onColumnPresetChange={columnPresets.onColumnPresetChange}
              />
            </Row>

            <Row>
              {dislocations.length === 0 ? (
                <EmptyListMessage />
              ) : (
                <ListTable
                  columns={columns}
                  getItemId={getItemId}
                  items={dislocations}
                  maxHeight={500}
                  showColumns={columnPresets.showColumns}
                />
              )}
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

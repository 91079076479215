import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'react-router5';

const FORM_NAVIGATION_LOCK_MESSAGE =
  'Изменения в форме не были сохранены. Вы уверены что хотите уйти со страницы?';

export interface IFormNavigationLock {
  lock: () => void;
  unlock: () => void;
}

export function useFormNavigationLock(routeName: string): IFormNavigationLock {
  const router = useRouter();

  const handleBeforeUnloadRef = useRef((event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = FORM_NAVIGATION_LOCK_MESSAGE;

    return FORM_NAVIGATION_LOCK_MESSAGE;
  });

  const lock = useCallback(() => {
    router.canDeactivate(
      routeName,
      () => () =>
        // eslint-disable-next-line no-alert
        window.confirm(FORM_NAVIGATION_LOCK_MESSAGE)
    );

    window.addEventListener(
      'beforeunload',
      handleBeforeUnloadRef.current,
      false
    );
  }, [routeName, router]);

  const unlock = useCallback(() => {
    router.clearCanDeactivate(routeName);

    window.removeEventListener(
      'beforeunload',
      handleBeforeUnloadRef.current,
      false
    );
  }, [routeName, router]);

  useEffect(() => unlock, [unlock]);

  return useMemo(() => ({ lock, unlock }), [lock, unlock]);
}

export function FormNavigationLock({
  navigationLock,
  shouldLock,
}: {
  navigationLock: IFormNavigationLock;
  shouldLock: boolean;
}) {
  useEffect(() => {
    if (shouldLock) {
      navigationLock.lock();
    } else {
      navigationLock.unlock();
    }
  }, [shouldLock, navigationLock]);

  useEffect(() => navigationLock.unlock, [navigationLock.unlock]);

  return null;
}

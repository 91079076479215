import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { createDateRange } from '_core/dates/utils';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { DateRangeWithMonthSelectFilter } from '_core/filters/dateRangeWithMonthSelect';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { SearchForm } from '_core/forms/searchForm';
import { uniq } from '_core/fp/uniq';
import { indexByLastItem } from '_core/indexBy';
import { Switch } from '_core/inputs/switch';
import { LegendItem } from '_core/legend';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { Region } from '_core/react-window/baseTable';
import { TreeTableNode } from '_core/react-window/types';
import {
  everyNode,
  findNode,
  flattenNodesToRows,
  setAllNodesIsExpanded,
  updateNodes,
} from '_core/react-window/utils';
import { LinkButton } from '_core/router5/linkButton';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent, Spinner } from '@blueprintjs/core';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useRouteNode } from 'react-router5';
import {
  acceptRentWagonPurchaseWagons,
  closeRentWagonPurchaseWagons,
  fetchRentWagonPurchaseList,
  fetchRentWagonPurchaseTree,
  IUpdateRentWagonPurchasePausesItem,
  RentWagonPurchase,
  RentWagonPurchaseListItem,
  RentWagonPurchasePause,
  RentWagonPurchasePauseCause,
  RentWagonPurchaseState,
  // startRentWagonPurchaseCalculation,
  // startRentWagonPurchaseRepair,
  // stopRentWagonPurchaseCalculation,
  // stopRentWagonPurchaseRepair,
  updateRentWagonPurchasePauses,
} from 'rentWagonPurchase/api';
import { RentWagonSellListItem } from 'rentWagonSell/api';
import invariant from 'tiny-invariant';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

import { RentWagonColor } from '../colors';
import {
  EditPausesDialogForm,
  IEditPausesDialogFormValues,
} from '../editPausesDialogForm';
import {
  DATE_COLUMN_START_INDEX,
  findPausesForRegions,
  RentWagonsTreeTable,
  RentWagonsTreeTableNodeData,
  RentWagonsTreeTableRentWagonNodeData,
} from '../treeTable';
import {
  IRentWagonsPurchaseAcceptDialogFormValues,
  RentWagonsPurchaseAcceptDialogForm,
} from './acceptDialogForm';
import {
  IRentWagonsPurchaseCloseDialogFormValues,
  RentWagonsPurchaseCloseDialogForm,
} from './closeDialogForm';
import { RenewSellRentsDialog } from './renewSellRentsDialog';

function createPausesFromRegions(
  selectedRegions: Region[],
  dates: Date[],
  nodes: Array<
    TreeTableNode<RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>>
  >,
  cause: RentWagonPurchasePauseCause
) {
  const result: IUpdateRentWagonPurchasePausesItem[] = [];

  const nodeRows = flattenNodesToRows(nodes);

  for (const { cols, rows } of selectedRegions) {
    if (!cols || !rows) {
      return [];
    }

    if (cols[0] < DATE_COLUMN_START_INDEX) {
      return [];
    }

    for (let rowIndex = rows[0]; rowIndex < rows[1] + 1; rowIndex++) {
      const nodeRow = nodeRows[rowIndex];

      if (nodeRow.type !== 'node' || nodeRow.node.data.kind !== 'rentWagon') {
        return [];
      }

      const { rentWagon } = nodeRow.node.data;

      let rent = result.find(r => r.id === rentWagon.id);

      if (!rent) {
        rent = { id: rentWagon.id, pauses: [] };
        result.push(rent);
      }

      rent.pauses.push(
        new RentWagonPurchasePause({
          cause,
          note: '',
          startDate: dates[cols[0] - DATE_COLUMN_START_INDEX],
          endDate: dates[cols[1] - DATE_COLUMN_START_INDEX],
          isCharging: true,
          authorModified: null,
          modified: null,
          duration: null,
        })
      );
    }
  }

  return result;
}

interface IRenewSellRentsDialogState {
  isOpen: boolean;
  renewableSellRents: RentWagonSellListItem[];
}

export default function RentWagonsPurchaseListRoute() {
  const { route, router } = useRouteNode('rentWagons.purchase');
  const toaster = useToaster();
  const api = useApiClient();

  const dateRangeFilterValue = useMemo(
    () => ({
      startDate: (route.params.fromDate
        ? dayjs(route.params.fromDate)
        : dayjs().startOf('month')
      ).toDate(),
      endDate: (route.params.toDate
        ? dayjs(route.params.toDate)
        : dayjs().endOf('month').startOf('day')
      ).toDate(),
    }),

    [route.params.fromDate, route.params.toDate]
  );

  const [showInactive, setInactive] = React.useState<boolean>(true);

  const states = useMemo(() => {
    const result = Object.values(RentWagonPurchaseState).filter(
      state => state !== RentWagonPurchaseState.Inactive
    );

    if (showInactive) {
      result.push(RentWagonPurchaseState.Inactive);
    }

    return result;
  }, [showInactive]);

  const commonFilterQuery = useMemo(
    () => ({
      fromDate: dateRangeFilterValue.startDate,
      search: route.params.search as string | undefined,
      states,
      toDate: dateRangeFilterValue.endDate,
      wagonNumbers:
        route.params.wagonNumbers != null
          ? (route.params.wagonNumbers as string).split(',')
          : undefined,
    }),
    [dateRangeFilterValue, route, states]
  );

  const protocolIdToExpand = useMemo(
    () =>
      route.params.expandProtocol == null
        ? null
        : Number(route.params.expandProtocol),
    [route.params.expandProtocol]
  );

  const acceptDialog = useDialog<{
    initialValues: IRentWagonsPurchaseAcceptDialogFormValues;
  }>();
  const closeDialog = useDialog<{
    initialValues: IRentWagonsPurchaseCloseDialogFormValues;
  }>();

  function applyFilterParams(
    filterParams: Record<string, number | string | undefined>
  ) {
    router.navigate(route.name, {
      ...route.params,
      page: undefined,
      ...filterParams,
    });
  }

  const [selectedNodes, setSelectedNodes] = useState<
    Array<TreeTableNode<RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>>>
  >([]);

  const [selectedRegions, setSelectedRegions] = useState<Region[]>([]);

  const [isBatchUpdateInProgress, setIsBatchUpdateInProgress] = useState(false);

  const isBulkUpdatesPausesDisabled =
    isBatchUpdateInProgress || selectedRegions.length === 0;

  const batchUpdate = useCallback(
    async (updater: () => Promise<void>) => {
      try {
        setIsBatchUpdateInProgress(true);
        await updater();
      } catch (err) {
        if (err instanceof BadRequestError && err.message) {
          toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: err.message,
          });
        } else {
          toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: 'Что-то пошло не так',
          });

          throw err;
        }
      } finally {
        setIsBatchUpdateInProgress(false);
      }
    },
    [toaster]
  );

  const [dateRangeToRender, setDateRangeToRender] =
    useState(dateRangeFilterValue);

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [commonFilterQuery, protocolIdToExpand, api],
    async () => {
      const treeResponse = await fetchRentWagonPurchaseTree(
        api,
        commonFilterQuery
      );

      return {
        meta: treeResponse.meta,

        nodes: treeResponse.results.map(
          (
            supplier
          ): TreeTableNode<
            RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>
          > => {
            const prevSupplierNode = data
              ? findNode(
                  data.nodes,
                  node =>
                    node.data.kind === 'supplier' &&
                    node.data.supplierId === supplier.supplier
                )
              : undefined;

            const supplierIsExpanded =
              prevSupplierNode && prevSupplierNode.children
                ? prevSupplierNode.children.isExpanded
                : protocolIdToExpand != null &&
                  supplier.protocols.some(
                    protocol => protocol.id === protocolIdToExpand
                  );

            return {
              id: `supplier-${supplier.supplier}`,
              data: {
                kind: 'supplier',
                supplierId: supplier.supplier,
                supplierName: supplier.supplierName,
                protocolCount: supplier.protocols.length,
              },
              children: {
                isExpanded: supplierIsExpanded,
                nodes: supplier.protocols.map(
                  (
                    protocol
                  ): TreeTableNode<
                    RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>
                  > => {
                    const prevProtocolNode = data
                      ? findNode(
                          data.nodes,
                          node =>
                            node.data.kind === 'protocol' &&
                            node.data.protocolId === protocol.id
                        )
                      : undefined;

                    const protocolIsExpanded =
                      prevProtocolNode && prevProtocolNode.children
                        ? prevProtocolNode.children.isExpanded
                        : protocolIdToExpand != null &&
                          protocol.id === protocolIdToExpand;

                    return {
                      id: `protocol-${protocol.id}`,
                      data: {
                        kind: 'protocol',
                        contractId: protocol.contract,
                        protocolId: protocol.id,
                        protocolName: protocol.name,
                        rentWagonCount: protocol.rentsCount,
                      },
                      children: {
                        isExpanded: protocolIsExpanded,
                        hasMoreChildNodesToFetch: true,
                        isFetching: false,
                        nodes: [],
                      },
                    };
                  }
                ),
              },
            };
          }
        ),
      };
    }
  );

  const lastDataRef = useRef(data);

  if (lastDataRef.current !== data) {
    setSelectedRegions([]);
    setSelectedNodes([]);
    setDateRangeToRender(dateRangeFilterValue);
    lastDataRef.current = data;
  }

  const nodes = data && data.nodes;

  const updateNodesState = useCallback(
    (
      mapper: (
        node: TreeTableNode<
          RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>
        >
      ) => TreeTableNode<RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>>
    ) => {
      updateData(
        prevData =>
          prevData && {
            ...prevData,
            nodes: updateNodes(prevData.nodes, mapper),
          }
      );
    },

    [updateData]
  );

  const bulkUpdatePauses = useCallback(
    (rents: IUpdateRentWagonPurchasePausesItem[]) =>
      batchUpdate(async () => {
        const updatedRentWagons = await updateRentWagonPurchasePauses(
          api,
          rents
        );

        const updatedRentWagonsById = indexByLastItem(
          updatedRentWagons,
          rentWagon => rentWagon.id
        );

        updateNodesState(node => {
          if (node.data.kind !== 'rentWagon') {
            return node;
          }

          const { rentWagon } = node.data;

          if (!(rentWagon.id in updatedRentWagonsById)) {
            return node;
          }

          const updatedRentWagon = updatedRentWagonsById[rentWagon.id];

          return {
            ...node,
            data: { ...node.data, rentWagon: updatedRentWagon },
          };
        });
      }),
    [batchUpdate, api, updateNodesState]
  );

  const datesToRender = useMemo(
    () =>
      createDateRange(
        'day',
        dateRangeToRender.startDate,
        dayjs(dateRangeToRender.endDate).add(1, 'day').toDate()
      ),
    [dateRangeToRender]
  );

  const datesColStart = DATE_COLUMN_START_INDEX;
  const datesColEnd = DATE_COLUMN_START_INDEX + datesToRender.length - 1;

  const isPauseCreationAllowed = useMemo(() => {
    if (isBulkUpdatesPausesDisabled) {
      return false;
    }

    const nodeRows = nodes ? flattenNodesToRows(nodes) : [];

    return selectedRegions.every(region => {
      const { cols, rows } = region;

      if (!cols || !rows) {
        return false;
      }

      if (cols[0] < datesColStart || cols[1] > datesColEnd) {
        return false;
      }

      if (rows[0] > nodeRows.length || rows[1] > nodeRows.length) {
        return false;
      }

      for (let rowIndex = rows[0]; rowIndex <= rows[1]; rowIndex++) {
        const nodeRow = nodeRows[rowIndex];

        if (nodeRow.type !== 'node' || nodeRow.node.data.kind !== 'rentWagon') {
          return false;
        }
      }

      return true;
    });
  }, [
    datesColStart,
    datesColEnd,
    isBulkUpdatesPausesDisabled,
    nodes,
    selectedRegions,
  ]);

  const createPausesOnSelectedRegions = (
    pauseCause: RentWagonPurchasePauseCause
  ) => {
    invariant(nodes);

    bulkUpdatePauses(
      createPausesFromRegions(
        selectedRegions,
        datesToRender,
        nodes,
        pauseCause
      ).map(rent => {
        const rentWagonNode = findNode(
          nodes,
          (
            node
          ): node is TreeTableNode<
            RentWagonsTreeTableNodeData<RentWagonPurchaseListItem> & {
              kind: 'rentWagon';
            }
          > =>
            node.data.kind === 'rentWagon' && node.data.rentWagon.id === rent.id
        );

        if (!rentWagonNode) {
          return rent;
        }

        const { rentWagon } = rentWagonNode.data;

        return {
          ...rent,
          pauses: [...rentWagon.pauses, ...rent.pauses],
        };
      })
    );
  };

  const handleCreatePausesClick = () => {
    createPausesOnSelectedRegions(RentWagonPurchasePauseCause.Paused);
  };

  const handleCreateRoutineRepairsClick = () => {
    createPausesOnSelectedRegions(RentWagonPurchasePauseCause.RoutineRepair);
  };

  const handleCreateScheduledRepairsClick = () => {
    createPausesOnSelectedRegions(RentWagonPurchasePauseCause.ScheduledRepair);
  };

  const pausesForSelectedRegions = useMemo(
    () =>
      nodes
        ? findPausesForRegions(
            selectedRegions,
            nodes,
            dateRangeToRender.startDate,
            dateRangeToRender.endDate
          )
        : [],
    [dateRangeToRender, nodes, selectedRegions]
  );

  const isPauseEditingAllowed = useMemo(() => {
    if (isBulkUpdatesPausesDisabled) {
      return false;
    }

    return (
      pausesForSelectedRegions.length !== 0 &&
      pausesForSelectedRegions.every(
        pausesForRegion => pausesForRegion.found !== null
      )
    );
  }, [isBulkUpdatesPausesDisabled, pausesForSelectedRegions]);

  const editPausesDialogForm = useDialog<{
    initialValues: IEditPausesDialogFormValues;
  }>();

  const handleEditPausesClick = () => {
    editPausesDialogForm.open({ initialValues: { note: '' } });
  };

  const isPauseRemovalAllowed = useMemo(() => {
    if (isBulkUpdatesPausesDisabled) {
      return false;
    }

    return (
      pausesForSelectedRegions.length !== 0 &&
      pausesForSelectedRegions.every(
        pausesForRegion => pausesForRegion.found !== null
      )
    );
  }, [isBulkUpdatesPausesDisabled, pausesForSelectedRegions]);

  const handleRemovePausesClick = () => {
    if (!nodes) {
      return;
    }

    const indicesToRemove: Record<
      IUpdateRentWagonPurchasePausesItem['id'],
      { rentWagon: RentWagonPurchaseListItem; pauseIndices: number[] }
    > = {};

    pausesForSelectedRegions.forEach(({ found }) => {
      if (!found) {
        return;
      }

      const rentWagonNode = findNode(
        nodes,
        (
          node
        ): node is TreeTableNode<
          RentWagonsTreeTableNodeData<RentWagonPurchaseListItem> & {
            kind: 'rentWagon';
          }
        > =>
          node.data.kind === 'rentWagon' &&
          node.data.rentWagon.id === found.rentWagonId
      );

      if (!rentWagonNode) {
        return;
      }

      indicesToRemove[found.rentWagonId] = indicesToRemove[
        found.rentWagonId
      ] || { rentWagon: rentWagonNode.data.rentWagon, pauseIndices: [] };
      indicesToRemove[found.rentWagonId].pauseIndices.push(
        ...found.pauseIndices
      );
    });

    const rents = Object.values(indicesToRemove).map(
      ({ rentWagon, pauseIndices }): IUpdateRentWagonPurchasePausesItem => ({
        id: rentWagon.id,
        pauses: rentWagon.pauses.filter(
          (_pause, index) => !pauseIndices.includes(index)
        ),
      })
    );

    bulkUpdatePauses(rents);
  };

  const fetchChildNodes = useCallback(
    async (
      parentNodes: Array<
        TreeTableNode<RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>>
      >
    ) => {
      invariant(parentNodes.length === 2);
      const parentNode = parentNodes[parentNodes.length - 1];

      if (parentNode.data.kind === 'protocol') {
        const grandParentNode = parentNodes[parentNodes.length - 2];
        invariant(
          grandParentNode.data.kind === 'supplier',
          `Expected protocol node parent to be supplier, got: "${grandParentNode.data.kind}"`
        );

        const protocolId = parentNode.data.protocolId;
        const supplierId = grandParentNode.data.supplierId;

        const response = await fetchAllPages(page =>
          fetchRentWagonPurchaseList(api, {
            ...commonFilterQuery,
            page,
            protocol: protocolId,
            supplier: supplierId,
          })
        );

        return response.results.map(
          (
            rentWagon
          ): TreeTableNode<
            RentWagonsTreeTableRentWagonNodeData<RentWagonPurchaseListItem>
          > => ({
            data: { kind: 'rentWagon', rentWagon },
            id: `rentWagon-${rentWagon.id}`,
          })
        );
      }

      throw new Error(`Unexpected node kind: "${parentNode.data.kind}"`);
    },
    [commonFilterQuery, api]
  );

  const handleNodesChange = useCallback(
    (
      newNodesOrUpdater:
        | Array<
            TreeTableNode<
              RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>
            >
          >
        | ((
            prevNodes: Array<
              TreeTableNode<
                RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>
              >
            >
          ) => Array<
            TreeTableNode<
              RentWagonsTreeTableNodeData<RentWagonPurchaseListItem>
            >
          >)
    ) => {
      updateData(
        prevData =>
          prevData && {
            ...prevData,
            nodes:
              typeof newNodesOrUpdater === 'function'
                ? newNodesOrUpdater(prevData.nodes)
                : newNodesOrUpdater,
          }
      );
    },
    [updateData]
  );

  const handlePauseUpdate = useCallback(
    (
      rentWagon: RentWagonPurchase,
      pauseIndex: number,
      updatedPause: RentWagonPurchasePause
    ) => {
      const rent: IUpdateRentWagonPurchasePausesItem = {
        id: rentWagon.id,
        pauses: rentWagon.pauses.map((pause, index) =>
          index === pauseIndex ? updatedPause : pause
        ),
      };

      return bulkUpdatePauses([rent]);
    },

    [bulkUpdatePauses]
  );

  const selectedRentWagons = selectedNodes
    .filter(
      (
        node
      ): node is TreeTableNode<
        RentWagonsTreeTableRentWagonNodeData<RentWagonPurchaseListItem>
      > => node.data.kind === 'rentWagon'
    )
    .map(node => node.data.rentWagon);

  // const anyRentWagonSelected = selectedRentWagons.length !== 0;

  const allIsExpanded = nodes
    ? everyNode(nodes, node => !node.children || node.children.isExpanded)
    : false;

  const [renewSellRentsDialogState, setRenewSellRentsDialogState] =
    useState<IRenewSellRentsDialogState | null>(null);

  return (
    <VGrid stretch>
      <FormattedTitle>Вагоны входящие</FormattedTitle>

      <Row>
        <Grid>
          <Col span={3}>
            <SearchForm
              initialValue={route.params.search}
              onApply={search => {
                applyFilterParams({ search });
              }}
            />
          </Col>

          <Col span={9}>
            <Toolbar align="right">
              {data && (
                <DateRangeWithMonthSelectFilter
                  minDate={dayjs(data.meta.dateRange[0])
                    .startOf('month')
                    .toDate()}
                  maxDate={dayjs(data.meta.dateRange[1])
                    .add(1, 'month')
                    .startOf('month')
                    .toDate()}
                  initialValue={dateRangeFilterValue}
                  onApply={newDateRangeFilterValue => {
                    applyFilterParams(
                      newDateRangeFilterValue
                        ? {
                            fromDate: dayjs(
                              newDateRangeFilterValue.startDate
                            ).format(DATE_FORMAT_API_DATE),
                            toDate: dayjs(
                              newDateRangeFilterValue.endDate
                            ).format(DATE_FORMAT_API_DATE),
                          }
                        : {
                            fromDate: undefined,
                            toDate: undefined,
                          }
                    );
                  }}
                />
              )}

              <WagonNumbersFilter
                initialValue={route.params.wagonNumbers}
                onApply={wagonNumbers => {
                  applyFilterParams({ wagonNumbers });
                }}
              />

              <LinkButton
                options={{ reload: true }}
                params={route.params}
                text="Обновить"
                to={route.name}
              />

              <LinkButton text="Сбросить фильтры" to={route.name} />
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row>
        <Grid>
          <Col span={9}>
            <Toolbar valign="center">
              <span>Общее количество вагонов:</span>
              {!data ? <Spinner size={14} /> : data.meta.rentsCount}
            </Toolbar>
          </Col>

          <Col span={3}>
            <Toolbar align="right">
              <Switch
                checked={showInactive}
                label="Показывать выведенные из аренды"
                onChange={newShowInactive => {
                  setInactive(prevParam => !prevParam);
                  applyFilterParams({
                    showInactive: newShowInactive ? 'True' : undefined,
                  });
                }}
              />
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row>
        <Toolbar valign="center">
          <span>Выбрано вагонов: {selectedNodes.length}</span>

          <Button
            intent={Intent.SUCCESS}
            onClick={() => {
              acceptDialog.open({
                initialValues: {
                  protocol: null,
                  startDate: new Date(),
                  supplier: null,
                  wagons: '',
                },
              });
            }}
            text="Принять в аренду"
          />

          <Button
            intent={Intent.SUCCESS}
            onClick={() => {
              closeDialog.open({
                initialValues: {
                  actFile: null,

                  wagons: uniq(
                    selectedRentWagons.map(rentWagon => rentWagon.wagon)
                  ).join(' '),

                  endDate: new Date(),
                },
              });
            }}
            text="Вывести из аренды"
          />

          {/* <Button */}
          {/*  disabled={isBatchUpdateInProgress || !anyRentWagonSelected} */}
          {/*  intent={Intent.SUCCESS} */}
          {/*  onClick={() => { */}
          {/*    batchUpdate(() => */}
          {/*      startRentWagonPurchaseRepair( */}
          {/*        api, */}
          {/*        selectedRentWagons.map(rentWagon => rentWagon.id) */}
          {/*      ).then(refetch) */}
          {/*    ); */}
          {/*  }} */}
          {/*  text="Отправить в ремонт" */}
          {/* /> */}

          {/* <Button */}
          {/*  disabled={isBatchUpdateInProgress || !anyRentWagonSelected} */}
          {/*  intent={Intent.SUCCESS} */}
          {/*  onClick={() => { */}
          {/*    batchUpdate(() => */}
          {/*      stopRentWagonPurchaseRepair( */}
          {/*        api, */}
          {/*        selectedRentWagons.map(rentWagon => rentWagon.id) */}
          {/*      ).then(refetch) */}
          {/*    ); */}
          {/*  }} */}
          {/*  text="Принять из ремонта" */}
          {/* /> */}

          {/* <Button */}
          {/*  disabled={isBatchUpdateInProgress || !anyRentWagonSelected} */}
          {/*  intent={Intent.SUCCESS} */}
          {/*  onClick={() => { */}
          {/*    batchUpdate(() => */}
          {/*      stopRentWagonPurchaseCalculation( */}
          {/*        api, */}
          {/*        selectedRentWagons.map(rentWagon => rentWagon.id) */}
          {/*      ).then(refetch) */}
          {/*    ); */}
          {/*  }} */}
          {/*  text="Остановить начисление" */}
          {/* /> */}

          {/* <Button */}
          {/*  disabled={isBatchUpdateInProgress || !anyRentWagonSelected} */}
          {/*  intent={Intent.SUCCESS} */}
          {/*  onClick={() => { */}
          {/*    batchUpdate(() => */}
          {/*      startRentWagonPurchaseCalculation( */}
          {/*        api, */}
          {/*        selectedRentWagons.map(rentWagon => rentWagon.id) */}
          {/*      ).then(refetch) */}
          {/*    ); */}
          {/*  }} */}
          {/*  text="Возобновить начисление" */}
          {/* /> */}
        </Toolbar>
      </Row>

      <Row>
        <Grid>
          <Col span={8}>
            <Toolbar>
              {allIsExpanded ? (
                <Button
                  icon="collapse-all"
                  text="Свернуть всё"
                  onClick={() => {
                    updateData(
                      prevData =>
                        prevData && {
                          ...prevData,
                          nodes: setAllNodesIsExpanded(prevData.nodes, false),
                        }
                    );
                  }}
                />
              ) : (
                <Button
                  icon="expand-all"
                  text="Развернуть всё"
                  onClick={() => {
                    updateData(
                      prevData =>
                        prevData && {
                          ...prevData,
                          nodes: setAllNodesIsExpanded(prevData.nodes, true),
                        }
                    );
                  }}
                />
              )}

              <LegendItem
                backgroundColor={RentWagonColor.NOT_RENTED}
                label="не в аренде"
              />

              <LegendItem
                backgroundColor={RentWagonColor.RENTED}
                label="в аренде"
              />

              <LegendItem
                backgroundColor={RentWagonColor.PAUSED}
                label="пауза"
              />

              <LegendItem
                backgroundColor={RentWagonColor.IN_REPAIR}
                label="в ремонте"
              />
            </Toolbar>
          </Col>

          <Col span={4}>
            <Toolbar align="right">
              <Button
                disabled={!isPauseCreationAllowed}
                intent={Intent.PRIMARY}
                text="Пауза"
                onClick={handleCreatePausesClick}
              />

              <Button
                disabled={!isPauseCreationAllowed}
                intent={Intent.PRIMARY}
                text="Текущий ремонт"
                onClick={handleCreateRoutineRepairsClick}
              />

              <Button
                disabled={!isPauseCreationAllowed}
                intent={Intent.PRIMARY}
                text="Деповский ремонт"
                onClick={handleCreateScheduledRepairsClick}
              />

              <Button
                disabled={!isPauseEditingAllowed}
                intent={Intent.SUCCESS}
                text="Редактировать"
                onClick={handleEditPausesClick}
              />

              <Button
                disabled={!isPauseRemovalAllowed}
                intent={Intent.DANGER}
                text="Удалить"
                onClick={handleRemovePausesClick}
              />
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        {!nodes ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <GenericErrorMessage />
          )
        ) : nodes.length === 0 ? (
          <EmptyListMessage />
        ) : (
          <RentWagonsTreeTable
            baseSegment="rentWagons.purchase"
            contractsBaseSegment="contracts.purchase"
            endDate={dateRangeToRender.endDate}
            fetchChildNodes={fetchChildNodes}
            isFetching={isFetching}
            nodes={nodes}
            selectedNodes={selectedNodes}
            selectedRegions={selectedRegions}
            startDate={dateRangeToRender.startDate}
            onNodesChange={handleNodesChange}
            onPauseUpdate={handlePauseUpdate}
            onSelectedNodesChange={setSelectedNodes}
            onSelection={setSelectedRegions}
          />
        )}
      </Row>

      {editPausesDialogForm.state && (
        <EditPausesDialogForm
          initialValues={editPausesDialogForm.state.initialValues}
          isOpen={editPausesDialogForm.state.isOpen}
          onClose={editPausesDialogForm.close}
          onClosed={editPausesDialogForm.destroy}
          onSubmit={async values => {
            try {
              const rents: IUpdateRentWagonPurchasePausesItem[] = [];

              if (!nodes) {
                return;
              }

              pausesForSelectedRegions.forEach(({ found }) => {
                if (!found) {
                  return;
                }

                const rentWagonNode = findNode(
                  nodes,
                  (
                    node
                  ): node is TreeTableNode<
                    RentWagonsTreeTableNodeData<RentWagonPurchaseListItem> & {
                      kind: 'rentWagon';
                    }
                  > =>
                    node.data.kind === 'rentWagon' &&
                    node.data.rentWagon.id === found.rentWagonId
                );

                if (!rentWagonNode) {
                  return;
                }

                const { rentWagon } = rentWagonNode.data;

                rents.push({
                  id: rentWagon.id,
                  pauses: rentWagon.pauses.map((pause, pauseIndex) => {
                    if (found.pauseIndices.includes(pauseIndex)) {
                      return new RentWagonPurchasePause({
                        ...pause,
                        note: values.note,
                      });
                    }

                    return pause;
                  }),
                });
              });

              await bulkUpdatePauses(rents);

              editPausesDialogForm.close();
              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось отредактировать паузы: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}

      {acceptDialog.state && (
        <RentWagonsPurchaseAcceptDialogForm
          initialValues={acceptDialog.state.initialValues}
          isOpen={acceptDialog.state.isOpen}
          onClose={acceptDialog.close}
          onClosed={acceptDialog.destroy}
          onSubmit={async ({ protocol, startDate, supplier, wagons }) => {
            try {
              const response = await acceptRentWagonPurchaseWagons(api, {
                protocol: protocol == null ? null : Number(protocol),
                startDate,
                supplier,
                wagons: wagons.trim().split(/\s+/),
              });

              if (response.renewableSellRents.length !== 0) {
                setRenewSellRentsDialogState({
                  isOpen: true,
                  renewableSellRents: response.renewableSellRents,
                });
              }

              refetch();
              acceptDialog.close();
              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось принять вагоны в аренду: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}

      {renewSellRentsDialogState && (
        <RenewSellRentsDialog
          isOpen={renewSellRentsDialogState.isOpen}
          renewableSellRents={renewSellRentsDialogState.renewableSellRents}
          onClose={() => {
            setRenewSellRentsDialogState(
              prevState => prevState && { ...prevState, isOpen: false }
            );
          }}
          onClosed={() => {
            setRenewSellRentsDialogState(null);
          }}
        />
      )}

      {closeDialog.state && (
        <RentWagonsPurchaseCloseDialogForm
          initialValues={closeDialog.state.initialValues}
          isOpen={closeDialog.state.isOpen}
          onClose={closeDialog.close}
          onClosed={closeDialog.destroy}
          onSubmit={async ({ actFile, endDate, wagons }) => {
            try {
              await closeRentWagonPurchaseWagons(api, {
                actFile,
                endDate,
                wagons: wagons.trim().split(/\s+/),
              });

              refetch();
              closeDialog.close();

              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось вывести вагоны из аренды: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}
    </VGrid>
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import dayjs from 'dayjs';
import { IPartnerInline } from 'partners/api';

export enum WagonArrivalGroup {
  New = 'NEW',
  Executed = 'EXECUTED',
  Rejected = 'REJECTED',
}

interface IWagonArrivalSerialized {
  arrivalDate: string | null;
  departureDate: string | null;
  operationDatetime: string;
  arrivalRoad: string;
  arrivalStation: number;
  consignee: IPartnerInline | null;
  departureRoad: string;
  departureStation: number;
  distanceToArrivalStation: number;
  etranWaybillNumber: string;
  group: WagonArrivalGroup;
  guNumber: string;
  id: number;
  loadStation: number | null;
  operationCode: string;
  operationRoad: string;
  operationStation: number | null;
  wagon: string;
  wagonOwner: IPartnerInline | null;
  wagonType: string;
}

export interface IWagonArrival
  extends Omit<
    IWagonArrivalSerialized,
    'arrivalDate' | 'departureDate' | 'operationDatetime'
  > {
  arrivalDate: Date | null;
  departureDate: Date | null;
  operationDatetime: Date;
}

function deserializeWagonArrival({
  arrivalDate,
  departureDate,
  operationDatetime,
  ...otherProps
}: IWagonArrivalSerialized): IWagonArrival {
  return {
    ...otherProps,
    arrivalDate: arrivalDate ? parseDate(arrivalDate) : null,
    departureDate: departureDate ? parseDate(departureDate) : null,
    operationDatetime: parseDate(operationDatetime),
  };
}

interface IFetchWagonArrivalsParams {
  arrivalDate?: Date;
  arrivalMonths?: Date[];
  arrivalStation?: number;
  departureDate?: Date;
  departureMonths?: Date[];
  departureStation?: number;
  distanceLower?: number;
  group?: WagonArrivalGroup;
  operationStation?: number;
  page?: number;
  pageSize?: number;
  wagonNumbers?: string[];
}

export async function fetchWagonArrivals(
  api: ApiClient,
  {
    arrivalDate,
    arrivalMonths,
    arrivalStation,
    departureDate,
    departureMonths,
    departureStation,
    distanceLower,
    group,
    operationStation,
    page,
    pageSize,
    wagonNumbers,
  }: IFetchWagonArrivalsParams = {}
): Promise<
  ListResponse<
    IWagonArrival,
    {
      filterOptions: {
        arrivalDate: [Date, Date] | [];
        departureDate: [Date, Date] | [];
      };
    }
  >
> {
  const { meta, results } = await api.get<
    ListResponse<
      IWagonArrivalSerialized,
      {
        filterOptions: {
          arrivalDate: [string, string] | [];
          departureDate: [string, string] | [];
        };
      }
    >
  >('/wagon_arrivals', {
    arrivalDate: arrivalDate
      ? dayjs(arrivalDate).format(DATE_FORMAT_API_DATE)
      : undefined,
    arrivalMonths: arrivalMonths
      ? arrivalMonths
          .map(monthDate => dayjs(monthDate).format(DATE_FORMAT_API_DATE))
          .join(',')
      : undefined,
    arrivalStation,
    departureDate: departureDate
      ? dayjs(departureDate).format(DATE_FORMAT_API_DATE)
      : undefined,
    departureMonths: departureMonths
      ? departureMonths
          .map(monthDate => dayjs(monthDate).format(DATE_FORMAT_API_DATE))
          .join(',')
      : undefined,
    departureStation,
    distanceLower,
    group,
    operationStation,
    page,
    pageSize,
    wagonNumbers:
      wagonNumbers && wagonNumbers.length !== 0
        ? wagonNumbers.join(',')
        : undefined,
  });

  let arrivalDateRange: [Date, Date] | [] = [];

  if (meta.filterOptions.arrivalDate.length === 2) {
    const [arrivalDateFromStr, arrivalDateToStr] =
      meta.filterOptions.arrivalDate;

    arrivalDateRange = [
      parseDate(arrivalDateFromStr),
      parseDate(arrivalDateToStr),
    ];
  }

  let departureDateRange: [Date, Date] | [] = [];

  if (meta.filterOptions.departureDate.length === 2) {
    const [departureDateFromStr, departureDateToStr] =
      meta.filterOptions.departureDate;

    departureDateRange = [
      parseDate(departureDateFromStr),
      parseDate(departureDateToStr),
    ];
  }

  return {
    meta: {
      ...meta,
      filterOptions: {
        ...meta.filterOptions,
        arrivalDate: arrivalDateRange,
        departureDate: departureDateRange,
      },
    },

    results: results.map(deserializeWagonArrival),
  };
}

export async function rejectWagonArrivals(api: ApiClient, ids: number[]) {
  await api.post(
    '/wagon_arrivals/reject',
    {},
    {
      query: {
        ids: ids.join(','),
      },
    }
  );
}

export async function makeWagonArrivalsNew(api: ApiClient, ids: number[]) {
  await api.post(
    '/wagon_arrivals/make_new',
    {},
    {
      query: {
        ids: ids.join(','),
      },
    }
  );
}

interface IBatchEditWagonArrivalsParams {
  consignee: number | null;
  etranWaybillNumber: string;
  guNumber: string;
  loadStation: number | null;
}

export async function batchEditWagonArrivals(
  api: ApiClient,
  ids: number[],
  {
    consignee,
    etranWaybillNumber,
    guNumber,
    loadStation,
  }: IBatchEditWagonArrivalsParams
) {
  await api.put(
    '/wagon_arrivals',
    {
      consignee,
      etranWaybillNumber,
      guNumber,
      loadStation,
    },
    {
      query: {
        ids: ids.join(','),
      },
    }
  );
}

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';
import { selectMe } from '_core/me/me';
import { useErpSelector } from '_erp/redux';
import * as React from 'react';

import { IPartnerSerialized } from './api';
import { IPartnersDialogFormValues, PartnersDialogForm } from './dialogForm';

const BaseAutocompleteInFormGroup = wrapEntityAutocompleteWithFormGroup(
  createEntityAutocomplete<
    IPartnerSerialized,
    number,
    IPartnersDialogFormValues
  >({
    baseEndpoint: '/partners',
    create: {
      DialogForm: PartnersDialogForm,
      dialogProps: {
        icon: 'new-person',
        title: 'Создание нового контрагента',
      },
      getFormInitialValues: autocompleteText => ({
        actualAddress: '',
        contacts: [],
        email: '',
        fullName: autocompleteText,
        inn: '',
        isClient: false,
        isSupplier: false,
        kpp: '',
        legalAddress: '',
        ogrn: '',
        okato: '',
        okpo: '',
        paymentData: '',
        postAddress: '',
        shortName: '',
      }),
      newOptionIcon: 'new-person',
      newOptionLabel: 'Создать нового контрагента...',
    },
    getLink: partner => ({
      params: { id: String(partner.id) },
      to: 'partners.edit',
    }),
    itemToOption: partner => ({
      label: partner.shortName,
      value: partner.id,
    }),
    optionIcon: 'person',
    placeholder: 'Начните вводить наименование контрагента...',
  })
);

export function PartnersAutocompleteInFormGroup(
  props: Omit<
    React.ComponentProps<typeof BaseAutocompleteInFormGroup>,
    'currentUser'
  >
) {
  const currentUser = useErpSelector(selectMe);

  return (
    <BaseAutocompleteInFormGroup
      {...props}
      serializeDialogFormValues={values => ({
        ...values,
        manager: currentUser ? currentUser.id : null,
        shortName: values.shortName.toUpperCase(),
      })}
    />
  );
}

export const PartnersAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    PartnersAutocompleteInFormGroup
  );

export function parseJsonWithFallback<
  T extends Record<string, unknown> = Record<string, unknown>
>(jsonStr: string, fallback: T) {
  let listParams: T;

  try {
    listParams = JSON.parse(jsonStr);
  } catch {
    listParams = fallback;
  }

  return listParams;
}

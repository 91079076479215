import { useCallback, useMemo, useState } from 'react';

type IState<TData> = TData & { isOpen: boolean };

interface IDialog<TData> {
  state: IState<TData> | null;
  open: (initialValues: TData) => void;
  close: () => void;
  destroy: () => void;
}

export function useDialog<TData = {}>(): IDialog<TData> {
  const [state, setState] = useState<IState<TData> | null>(null);

  const open = useCallback((data: TData) => {
    setState({ ...data, isOpen: true });
  }, []);

  const close = useCallback(() => {
    setState(prevState => prevState && { ...prevState, isOpen: false });
  }, []);

  const destroy = useCallback(() => {
    setState(null);
  }, []);

  return useMemo(
    () => ({ state, open, close, destroy }),
    [state, open, close, destroy]
  );
}

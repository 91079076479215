import { ApiClient } from './client';
import { fetchAllPages } from './fetchAllPages';
import { ListResponse } from './types';

export function makeFetchMany<
  TFetchListFn extends (
    api: ApiClient,
    params?: { page?: number }
  ) => Promise<ListResponse<unknown>>,
  TParams extends Exclude<Parameters<TFetchListFn>[1], undefined>,
  TArrayParams extends {
    [K in keyof TParams]: Exclude<TParams[K], undefined> extends Array<infer T>
      ? T[]
      : never;
  },
  TIdsParam extends keyof TArrayParams
>(fetchListFn: TFetchListFn, idsParam: TIdsParam) {
  type Param = Exclude<TArrayParams[TIdsParam], undefined>;

  return (
    api: ApiClient,
    ids: Param extends unknown[] ? Param : never,
    params?: Omit<TParams, TIdsParam | 'page'>
  ) => {
    return ids.length === 0
      ? Promise.resolve([])
      : fetchAllPages(
          page =>
            fetchListFn(api, { ...params, [idsParam]: ids, page }) as Promise<
              ListResponse<
                ReturnType<TFetchListFn> extends Promise<ListResponse<infer R>>
                  ? R
                  : never
              >
            >
        ).then(response => response.results);
  };
}

import { parseAWSUrl } from '_core/aws';
import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { LinkStyledButton } from '_core/linkStyledButton';
import { formatMoney } from '_core/money/formatMoney';
import { TreeTable, TreeTableColumn } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import { Link } from '_core/router5/link';
import { ISorting, SortingDirection } from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import {
  Boundary,
  Classes,
  Menu,
  OverflowList,
  Popover,
} from '@blueprintjs/core';
import cx from 'classnames';
import dayjs from 'dayjs';
import * as React from 'react';
import { Fragment, useMemo } from 'react';
import {
  getSuppliersDocumentStateLabel,
  getSuppliersDocumentTypeLabel,
  ISuppliersDocumentSerialized,
  SuppliersDocumentType,
} from 'suppliersDocuments/types';

import { SortingInput } from '../../_core/sortingInput';
import { Col, Grid, Row } from '../../layout/contentLayout';
import { SuppliersDocumentsSortingField } from '../../suppliersDocuments/api';

export type SuppliersDocumentsFromFileTreeTableNodeData =
  | { kind: 'sourceName'; sourceName: string; suppliersDocumentCount: number }
  | {
      kind: 'suppliersDocument';
      suppliersDocument: ISuppliersDocumentSerialized;
    };

interface IProps {
  fetchChildNodes?: (
    parentNodes: Array<
      TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>
    >
  ) => Promise<
    Array<TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>>
  >;
  isFetching: boolean;
  listParams: string;
  nodes: Array<TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>>;
  sorting: ISorting;
  onNodesChange: (
    newNodesOrUpdater:
      | Array<TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>>
      | ((
          prevNodes: Array<
            TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>
          >
        ) => Array<TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>>)
  ) => void;
  onSortingChange: (newSorting: ISorting) => void;
}

export function SuppliersDocumentsFromFileTreeTable({
  fetchChildNodes,
  isFetching,
  listParams,
  nodes,
  sorting,
  onNodesChange,
  onSortingChange,
}: IProps) {
  const columns = useMemo(
    (): Array<TreeTableColumn<SuppliersDocumentsFromFileTreeTableNodeData>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 300,
        sortable: true,
        expandable: true,
        copyCellContent: node => {
          switch (node.data.kind) {
            case 'sourceName': {
              const { sourceName, suppliersDocumentCount } = node.data;

              return `${sourceName} (${suppliersDocumentCount})`;
            }
            case 'suppliersDocument': {
              return node.data.suppliersDocument.number;
            }
          }
        },
        renderCellContent: node => {
          switch (node.data.kind) {
            case 'sourceName': {
              const { sourceName, suppliersDocumentCount } = node.data;

              return `${sourceName} (${suppliersDocumentCount})`;
            }
            case 'suppliersDocument': {
              const suppliersDocument = node.data.suppliersDocument;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: suppliersDocument.id, listParams }}
                  rel="noopener"
                  target="_blank"
                  to="suppliersDocumentsFromFile.edit"
                >
                  {suppliersDocument.number}
                </Ellipsis>
              );
            }
          }
        },
      },
      {
        id: 'date',
        label: 'Дата документа',
        defaultWidth: 80,
        sortable: true,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return suppliersDocument.date
            ? dayjs(suppliersDocument.date).format(DATE_FORMAT_DATE)
            : '';
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return (
            suppliersDocument.date &&
            dayjs(suppliersDocument.date).format(DATE_FORMAT_DATE)
          );
        },
      },
      {
        id: 'adjustmentFor',
        label: 'Корректировка',
        defaultWidth: 210,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return suppliersDocument.adjustmentForNumber;
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return suppliersDocument.docType ===
            SuppliersDocumentType.Adjustment ? (
            suppliersDocument.adjustmentFor != null ? (
              <Link
                params={{ id: suppliersDocument.adjustmentFor }}
                rel="noopener"
                target="_blank"
                to="suppliersDocumentsFromFile.edit"
              >
                {suppliersDocument.adjustmentForNumber}
              </Link>
            ) : (
              suppliersDocument.adjustmentForNumber
            )
          ) : null;
        },
      },
      {
        id: 'docType',
        label: 'Тип документа',
        defaultWidth: 150,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return getSuppliersDocumentTypeLabel(suppliersDocument.docType);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return getSuppliersDocumentTypeLabel(suppliersDocument.docType);
        },
      },
      {
        id: 'state',
        label: 'Статус',
        defaultWidth: 250,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return getSuppliersDocumentStateLabel(suppliersDocument.state);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return getSuppliersDocumentStateLabel(suppliersDocument.state);
        },
      },
      {
        id: 'amount',
        label: 'Сумма',
        defaultWidth: 100,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return formatMoney(suppliersDocument.amount);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return formatMoney(suppliersDocument.amount);
        },
      },
      {
        id: 'vatRate',
        label: 'Ставка НДС',
        defaultWidth: 60,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return `${Math.floor(Number(suppliersDocument.vatRate))}%`;
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return `${Math.floor(Number(suppliersDocument.vatRate))}%`;
        },
      },
      {
        id: 'amountTotal',
        label: 'Сумма итого',
        defaultWidth: 100,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return formatMoney(suppliersDocument.amountTotal);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return formatMoney(suppliersDocument.amountTotal);
        },
      },
      {
        id: 'notAssignedExpenses',
        label: 'Нераспределённая сумма',
        defaultWidth: 100,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return formatMoney(suppliersDocument.notAssignedExpenses);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return formatMoney(suppliersDocument.notAssignedExpenses);
        },
      },
      {
        id: 'files',
        label: 'Файлы',
        defaultWidth: 150,
        copyCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return '';
          }

          const suppliersDocument = node.data.suppliersDocument;

          return suppliersDocument.files.join(', ');
        },
        renderCellContent: node => {
          if (node.data.kind !== 'suppliersDocument') {
            return null;
          }

          const suppliersDocument = node.data.suppliersDocument;

          return (
            <OverflowList
              collapseFrom={Boundary.END}
              items={suppliersDocument.files}
              overflowRenderer={overflowItems => (
                <Popover
                  content={
                    <Menu>
                      {overflowItems.map(awsUrl => (
                        <li key={awsUrl}>
                          <a
                            className={cx(
                              Classes.MENU_ITEM,
                              Classes.POPOVER_DISMISS
                            )}
                            download
                            href={awsUrl}
                            rel="noopener"
                            target="_blank"
                          >
                            {parseAWSUrl(awsUrl).filename}
                          </a>
                        </li>
                      ))}
                    </Menu>
                  }
                >
                  <LinkStyledButton>
                    ещё {overflowItems.length}
                  </LinkStyledButton>
                </Popover>
              )}
              visibleItemRenderer={(awsUrl, index) => (
                <Fragment key={awsUrl}>
                  <Ellipsis
                    component="a"
                    download
                    href={awsUrl}
                    rel="noopener"
                    target="_blank"
                    wrap
                  >
                    {parseAWSUrl(awsUrl).filename}
                  </Ellipsis>

                  {index !== suppliersDocument.files.length - 1 ? (
                    <span>,&nbsp;</span>
                  ) : null}
                </Fragment>
              )}
            />
          );
        },
      },
    ],

    [listParams]
  );

  const defaultSorting: ISorting<SuppliersDocumentsSortingField> = {
    field: SuppliersDocumentsSortingField.Date,
    direction: SortingDirection.Descending,
  };

  return (
    <>
      <Row>
        <Grid>
          <Col align="end">
            <SortingInput
              defaultValue={defaultSorting}
              options={columns
                .filter(column => column.sortable)
                .map(column => ({
                  label: column.label,
                  value: column.id as SuppliersDocumentsSortingField,
                }))}
              value={sorting}
              onChange={onSortingChange}
            />
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        <TreeTable
          columns={columns}
          fetchChildNodes={fetchChildNodes}
          isFetching={isFetching}
          nodes={nodes}
          sorting={sorting}
          onNodesChange={onNodesChange}
          onSortingChange={onSortingChange}
        />
      </Row>
    </>
  );
}

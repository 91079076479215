import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import { fetchContact, updateContact } from 'contacts/api';
import { formatContactName } from 'contacts/utils';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';

import { ContactForm } from './form';

export default function ContactsEditRoute() {
  const { route, router } = useRouteNode('contacts.edit');
  const navigationLock = useFormNavigationLock('contacts.edit');
  const contactId = Number(route.params.id);
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [contactId, api],
    () => fetchContact(api, contactId)
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const title = formatContactName(data, { placeholderIfEmpty: true });

  function handleSubmissionError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось изменить контакт: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Контакты"
              params={listParams}
              to="contacts"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <ContactForm
            initialValues={data}
            navigationLock={navigationLock}
            onSave={async values => {
              try {
                await updateContact(api, contactId, values);
                navigationLock.unlock();
                router.navigate('contacts', listParams);
                return undefined;
              } catch (err) {
                return handleSubmissionError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedContact = await updateContact(
                  api,
                  contactId,
                  values
                );
                updateData(() => updatedContact);
                return undefined;
              } catch (err) {
                return handleSubmissionError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

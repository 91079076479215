import { TreeTable } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import { ISorting } from '_core/sorting';
import * as React from 'react';
import { useMemo } from 'react';

import {
  getOurDocumentsTreeTableColumns,
  OurDocumentsTreeTableNodeData,
} from './columns';

interface IProps {
  fetchChildNodes: (
    parentNodes: Array<TreeTableNode<OurDocumentsTreeTableNodeData>>
  ) => Promise<Array<TreeTableNode<OurDocumentsTreeTableNodeData>>>;
  isFetching: boolean;
  nodes: Array<TreeTableNode<OurDocumentsTreeTableNodeData>>;
  sorting: ISorting;
  onNodesChange: (
    newNodesOrUpdater:
      | Array<TreeTableNode<OurDocumentsTreeTableNodeData>>
      | ((
          prevNodes: Array<TreeTableNode<OurDocumentsTreeTableNodeData>>
        ) => Array<TreeTableNode<OurDocumentsTreeTableNodeData>>)
  ) => void;
  onSortingChange: (newSorting: ISorting) => void;
}

export function OurDocumentsTreeTable({
  fetchChildNodes,
  isFetching,
  nodes,
  sorting,
  onNodesChange,
  onSortingChange,
}: IProps) {
  const columns = useMemo(() => getOurDocumentsTreeTableColumns(), []);

  return (
    <TreeTable
      columns={columns}
      fetchChildNodes={fetchChildNodes}
      isFetching={isFetching}
      nodes={nodes}
      sorting={sorting}
      onNodesChange={onNodesChange}
      onSortingChange={onSortingChange}
    />
  );
}

export function finiteNumberOr<T>(
  fallbackValue: T,
  valueToParse: unknown
): T | number {
  if (valueToParse == null || valueToParse === '' || valueToParse === false) {
    return fallbackValue;
  }

  const value = Number(valueToParse);

  if (!isFinite(value)) {
    return fallbackValue;
  }

  return value;
}

import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { DateInputForFinalForm } from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { isNotNull } from '_core/isNotNull';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, H4, Intent, Label } from '@blueprintjs/core';
import { fetchManyUsers } from 'accounts/api';
import { UsersAutocompleteInFormGroupForFinalForm } from 'accounts/autocomplete';
import { formatUserName } from 'accounts/formatUserName';
import dayjs from 'dayjs';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { IWagonSerialized } from 'wagons/api';
import { getWagonAlertLabel } from 'wagons/utils';

import { InputMoneyForFinalForm } from '../../_core/inputs/inputMoney';

export interface IWagonFormValues {
  capacity: string;
  managerNote: string;
  managerNote2: string;
  managerNote3: string;
  model: string;
  owner: number | null;
  tara: string;
}

interface IProps {
  initialValues: IWagonFormValues;
  navigationLock: IFormNavigationLock;
  wagon: IWagonSerialized;
  onSave: (values: IWagonFormValues) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IWagonFormValues
  ) => Promise<SubmissionErrors | void>;
}

type SubmitTrigger = 'save' | 'saveAndContinue';

interface INoteTableItem {
  noteField: string;
  modifiedField: string;
  userField: string;
}

export function WagonsForm({
  initialValues,
  navigationLock,
  wagon,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const api = useApiClient();

  const { data } = useAsyncData(
    [api, wagon.capacityUser, wagon.modelUser, wagon.taraUser],
    async () => {
      const users = await fetchManyUsers(
        api,
        [wagon.capacityUser, wagon.modelUser, wagon.taraUser].filter(isNotNull)
      );

      const findUser = (userId: number | null) =>
        userId == null ? null : users.find(user => user.id === userId) ?? null;

      return {
        capacityUser: findUser(wagon.capacityUser),
        modelUser: findUser(wagon.modelUser),
        taraUser: findUser(wagon.taraUser),
      };
    }
  );

  const noteTableItems = useMemo(
    (): INoteTableItem[] => [
      {
        noteField: 'managerNote',
        modifiedField: 'managerNoteModified',
        userField: 'managerNoteUser',
      },
      {
        noteField: 'managerNote2',
        modifiedField: 'managerNote2Modified',
        userField: 'managerNote2User',
      },
      {
        noteField: 'managerNote3',
        modifiedField: 'managerNote3Modified',
        userField: 'managerNote3User',
      },
    ],
    []
  );

  const noteTableColumns = useMemo(
    (): Array<ListTableColumn<INoteTableItem>> => [
      {
        id: 'note',
        label: 'Заметка',
        defaultWidth: 280,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: item => (
          <InputGroupForFinalForm id={item.noteField} name={item.noteField} />
        ),
      },
      {
        id: 'modified',
        label: 'Дата изменения',
        defaultWidth: 135,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: item => (
          <DateInputForFinalForm
            dateFormat={DATE_FORMAT_DATETIME}
            disabled
            fill
            id={item.modifiedField}
            name={item.modifiedField}
          />
        ),
      },
      {
        id: 'user',
        label: 'Пользователь',
        defaultWidth: 200,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: item => (
          <UsersAutocompleteInFormGroupForFinalForm
            disabled
            id={item.userField}
            name={item.userField}
            noBottomMargin
          />
        ),
      },
    ],
    []
  );

  const getNoteTableItemId = useCallback(
    (item: INoteTableItem) => item.noteField,
    []
  );

  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={6}>
                  <VGrid>
                    <Row>
                      <H4>Основные данные</H4>

                      <PartnersAutocompleteInFormGroupForFinalForm
                        id="owner"
                        label="Владелец"
                        name="owner"
                      />

                      <ListTable
                        columns={noteTableColumns}
                        getItemId={getNoteTableItemId}
                        items={noteTableItems}
                        maxHeight={120}
                      />
                    </Row>

                    <Row>
                      <Grid>
                        <Col span={4}>
                          <FormGroupForFinalForm
                            label="Тара, т."
                            labelFor="tara"
                            name="tara"
                          >
                            <InputMoneyForFinalForm id="tara" name="tara" />
                          </FormGroupForFinalForm>
                        </Col>

                        <Col span={2}>
                          <Label>Последнее изменение</Label>
                          {wagon.taraModified == null ? (
                            '-'
                          ) : (
                            <>
                              {dayjs(wagon.taraModified).format(
                                DATE_FORMAT_DATETIME
                              )}
                              ,{' '}
                              {data?.taraUser == null
                                ? 'из дислокации'
                                : formatUserName(data.taraUser)}
                            </>
                          )}
                        </Col>

                        <Col span={4}>
                          <FormGroupForFinalForm
                            label="Грузоподъёмность, т."
                            labelFor="capacity"
                            name="capacity"
                          >
                            <InputMoneyForFinalForm
                              id="capacity"
                              name="capacity"
                            />
                          </FormGroupForFinalForm>
                        </Col>

                        <Col span={2}>
                          <Label>Последнее изменение</Label>
                          {wagon.capacityModified == null ? (
                            '-'
                          ) : (
                            <>
                              {dayjs(wagon.capacityModified).format(
                                DATE_FORMAT_DATETIME
                              )}
                              ,{' '}
                              {data?.capacityUser == null
                                ? 'из дислокации'
                                : formatUserName(data.capacityUser)}
                            </>
                          )}
                        </Col>

                        <Col span={4}>
                          <FormGroupForFinalForm
                            label="Модель"
                            labelFor="model"
                            name="model"
                          >
                            <InputGroupForFinalForm id="model" name="model" />
                          </FormGroupForFinalForm>
                        </Col>

                        <Col span={2}>
                          <Label>Последнее изменение</Label>
                          {wagon.modelModified == null ? (
                            '-'
                          ) : (
                            <>
                              {dayjs(wagon.modelModified).format(
                                DATE_FORMAT_DATETIME
                              )}
                              ,{' '}
                              {data?.modelUser == null
                                ? 'из дислокации'
                                : formatUserName(data.modelUser)}
                            </>
                          )}
                        </Col>
                      </Grid>
                    </Row>
                  </VGrid>
                </Col>

                <Col span={4}>
                  <H4>Парки</H4>

                  {wagon.parks.length > 0 ? (
                    wagon.parks.map(park => (
                      <div key={park.id}>
                        <Link params={{ id: park.id }} to="parks.edit">
                          {park.name}
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div>Парков нет</div>
                  )}
                </Col>

                <Col span={2}>
                  <H4>Предупреждения</H4>

                  {wagon.alerts.length > 0 ? (
                    wagon.alerts.map(alert => (
                      <div key={alert}>{getWagonAlertLabel(alert)}</div>
                    ))
                  ) : (
                    <div>Предупреждений нет</div>
                  )}
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitTriggerRef.current = 'save';
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { DateInputInFormGroup } from '_core/inputs/dateInput';
import { InputMoneyInFormGroup } from '_core/inputs/inputMoney';
import { InputMultiple } from '_core/inputs/inputMultiple';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, FormGroup, H4, InputGroup, Intent } from '@blueprintjs/core';
import { ContractsPurchaseAutocompleteInFormGroupForFinalForm } from 'contractsPurchase/autocomplete';
import { ContractsSellAutocompleteInFormGroupForFinalForm } from 'contractsSell/autocomplete';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import {
  getOperationTypeLabel,
  getPaymentTypeLabel,
  IPayment,
  OperationType,
} from 'payments/types';
import * as React from 'react';

export interface IPaymentsFormValues {
  consignee: number | null;
  contractPurchase: number | null;
  contractSell: number | null;
  payer: number | null;
}

interface IProps {
  initialValues: IPaymentsFormValues;
  navigationLock: IFormNavigationLock;
  payment: IPayment;
  onSubmit: (values: IPaymentsFormValues) => Promise<SubmissionErrors | void>;
}

export function PaymentForm({
  initialValues,
  navigationLock,
  payment,
  onSubmit,
}: IProps) {
  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <Row>
            <Grid>
              <Col span={4}>
                <H4>Основная информация</H4>

                <FormGroup label="Номер" labelFor="number">
                  <InputGroup
                    id="number"
                    name="number"
                    readOnly
                    value={String(payment.number)}
                  />
                </FormGroup>

                <FormGroup label="Тип документа" labelFor="type">
                  <InputGroup
                    id="type"
                    name="type"
                    readOnly
                    value={getPaymentTypeLabel(payment.type)}
                  />
                </FormGroup>

                <DateInputInFormGroup
                  fill
                  id="date"
                  label="Дата"
                  name="date"
                  readOnly
                  value={payment.date}
                />

                <InputMoneyInFormGroup
                  id="amount"
                  label="Сумма"
                  name="amount"
                  readOnly
                  value={payment.amount}
                />

                <DateInputInFormGroup
                  fill
                  id="datePaid"
                  label="Дата cписания"
                  name="datePaid"
                  readOnly
                  value={payment.datePaid}
                />

                <H4>Дополнительно</H4>

                <FormGroup label="Назначение платежа">
                  <InputMultiple
                    id="purpose"
                    name="purpose"
                    readOnly
                    rows={5}
                    value={payment.purpose}
                  />
                </FormGroup>

                <FormGroup label="Вид операции" labelFor="operationType">
                  <InputGroup
                    id="operationType"
                    name="operationType"
                    readOnly
                    value={getOperationTypeLabel(payment.operationType)}
                  />
                </FormGroup>

                {payment.operationType === OperationType.Purchase ? (
                  <ContractsPurchaseAutocompleteInFormGroupForFinalForm
                    addToQuery={
                      values.consignee
                        ? { partner: values.consignee }
                        : undefined
                    }
                    disabled={values.consignee == null}
                    id="contractPurchase"
                    label="Договор"
                    name="contractPurchase"
                  />
                ) : (
                  <ContractsSellAutocompleteInFormGroupForFinalForm
                    addToQuery={
                      values.payer ? { partner: values.payer } : undefined
                    }
                    disabled={values.payer == null}
                    id="contractSell"
                    label="Договор"
                    name="contractSell"
                  />
                )}
              </Col>

              <Col span={4}>
                <H4>Плательщик</H4>

                <PartnersAutocompleteInFormGroupForFinalForm
                  id="payer"
                  label="Плательщик"
                  name="payer"
                  onChange={newPayer => {
                    if (
                      payment.operationType === OperationType.Sell &&
                      values.payer !== newPayer
                    ) {
                      form.change('contractSell', null);
                    }
                  }}
                />

                <FormGroup label="Счет плательщика" labelFor="payerAccount">
                  <InputGroup
                    id="payerAccount"
                    name="payerAccount"
                    readOnly
                    value={payment.payerAccount}
                  />
                </FormGroup>

                <FormGroup label="Плательщик наименование" labelFor="payerName">
                  <InputGroup
                    id="payerName"
                    name="payerName"
                    readOnly
                    value={payment.payerName}
                  />
                </FormGroup>

                <FormGroup label="Плательщик ИНН" labelFor="payerInn">
                  <InputGroup
                    id="payerInn"
                    name="payerInn"
                    readOnly
                    value={payment.payerInn}
                  />
                </FormGroup>

                <FormGroup label="Плательщик КПП" labelFor="payerKpp">
                  <InputGroup
                    id="payerKpp"
                    name="payerKpp"
                    readOnly
                    value={payment.payerKpp}
                  />
                </FormGroup>

                <FormGroup
                  label="Плательщик расчетный счет"
                  labelFor="payerCheckingAccount"
                >
                  <InputGroup
                    id="payerCheckingAccount"
                    name="payerCheckingAccount"
                    readOnly
                    value={payment.payerCheckingAccount}
                  />
                </FormGroup>

                <FormGroup label="Плательщик банк" labelFor="payerBank">
                  <InputGroup
                    id="payerBank"
                    name="payerBank"
                    readOnly
                    value={payment.payerBank}
                  />
                </FormGroup>

                <FormGroup label="Плательщик БИК" labelFor="payerBik">
                  <InputGroup
                    id="payerBik"
                    name="payerBik"
                    readOnly
                    value={payment.payerBik}
                  />
                </FormGroup>

                <FormGroup
                  label="Плательщик коррсчет"
                  labelFor="payerCorrespondentAccount"
                >
                  <InputGroup
                    id="payerCorrespondentAccount"
                    name="payerCorrespondentAccount"
                    readOnly
                    value={payment.payerCorrespondentAccount}
                  />
                </FormGroup>
              </Col>

              <Col span={4}>
                <H4>Получатель</H4>

                <PartnersAutocompleteInFormGroupForFinalForm
                  id="consignee"
                  label="Получатель"
                  name="consignee"
                  onChange={newConsignee => {
                    if (
                      payment.operationType === OperationType.Purchase &&
                      values.consignee !== newConsignee
                    ) {
                      form.change('contractPurchase', null);
                    }
                  }}
                />

                <FormGroup label="Счет получателя" labelFor="consigneeAccount">
                  <InputGroup
                    id="consigneeAccount"
                    name="consigneeAccount"
                    readOnly
                    value={payment.consigneeAccount}
                  />
                </FormGroup>

                <FormGroup
                  label="Получатель наименование"
                  labelFor="consigneeName"
                >
                  <InputGroup
                    id="consigneeName"
                    name="consigneeName"
                    readOnly
                    value={payment.consigneeName}
                  />
                </FormGroup>

                <FormGroup label="Получатель ИНН" labelFor="consigneeInn">
                  <InputGroup
                    id="consigneeInn"
                    name="consigneeInn"
                    readOnly
                    value={payment.consigneeInn}
                  />
                </FormGroup>

                <FormGroup label="Получатель КПП" labelFor="consigneeKpp">
                  <InputGroup
                    id="consigneeKpp"
                    name="consigneeKpp"
                    readOnly
                    value={payment.consigneeKpp}
                  />
                </FormGroup>

                <FormGroup
                  label="Получатель расчетный счет"
                  labelFor="consigneeCheckingAccount"
                >
                  <InputGroup
                    id="consigneeCheckingAccount"
                    name="consigneeCheckingAccount"
                    readOnly
                    value={payment.consigneeCheckingAccount}
                  />
                </FormGroup>

                <FormGroup label="Получатель банк" labelFor="consigneeBank">
                  <InputGroup
                    id="consigneeBank"
                    name="consigneeBank"
                    readOnly
                    value={payment.consigneeBank}
                  />
                </FormGroup>

                <FormGroup label="Получатель БИК" labelFor="consigneeBik">
                  <InputGroup
                    id="consigneeBik"
                    name="consigneeBik"
                    readOnly
                    value={payment.consigneeBik}
                  />
                </FormGroup>

                <FormGroup
                  label="Получатель коррсчет"
                  labelFor="consigneeCorrespondentAccount"
                >
                  <InputGroup
                    id="consigneeCorrespondentAccount"
                    name="consigneeCorrespondentAccount"
                    readOnly
                    value={payment.consigneeCorrespondentAccount}
                  />
                </FormGroup>
              </Col>
            </Grid>
          </Row>

          <Row stickToBottom>
            <Toolbar align="right">
              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                text="Сохранить"
                type="submit"
              />
            </Toolbar>
          </Row>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { TooltipContent } from '_core/blueprint/tooltipContent';
import {
  DATE_FORMAT_API_DATE,
  DATE_FORMAT_API_DATETIME,
  DATE_FORMAT_DATETIME,
} from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { useUploadFile } from '_core/fileUploader/fileUploaderContext';
import { DateRangeWithMonthSelectFilter } from '_core/filters/dateRangeWithMonthSelect';
import { MultiSelectFilter } from '_core/filters/multiSelect';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { indexByLastItem } from '_core/indexBy';
import { FileChooserButton } from '_core/inputs/fileChooserButton';
import { isNotNull } from '_core/isNotNull';
import { Pagination } from '_core/pagination';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import {
  expandSorting,
  ISorting,
  SortingDirection,
  validateSorting,
} from '_core/sorting';
import { StateContainer } from '_core/stateContainer';
import { Ellipsis } from '_core/strings/ellipsis';
import { plural } from '_core/strings/utils';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Icon,
  Intent,
  Position,
  ProgressBar,
} from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { fetchManyUsers } from 'accounts/api';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { updateWagons } from 'wagons/api';
import { WagonNumbersFilter } from 'wagons/numbersFilter';
import {
  bulkCreateWeighingRecords,
  fetchWeighingRecords,
  fetchWeighingRecordsFilterValues,
  getWeighingRecordsReportDownloadLink,
  getWeighingRecordsXlsxDownloadLink,
  isWeighingAlert,
  sendWeighingRecordsXlsxToTelegram,
  WeighingRecord,
  WeighingRecordsSortingField,
} from 'weighingRecords/api';

import { BooleanIcon } from '../../_core/booleanIcon';
import { CheckboxListFilter } from '../../_core/filters/checkboxList';
import { FormattedTitle } from '../../_core/react-head/formattedTitle';
import { SortingInput } from '../../_core/sortingInput';
import {
  getWeighingAlertLabel,
  getWeighingRowColor,
  weighingAlertsOptions,
} from '../../weighingRecords/utils';
import {
  BatchEditDialogForm,
  IBatchEditDialogFormValues,
} from './batchEditDialogForm';
import * as css from './index.module.css';
import { WeighingsStatusFilterPopover } from './statusFilterPopover';
import WeighingLegend from './weighingLegend';

interface ITableItem {
  userModifiedAuxWagonFields: IUser | null;
  weighingRecord: WeighingRecord;
}

enum SortingField {
  Created = 'created',
  IsProcessed = 'isProcessed',
  Wagon = 'wagon',
  WagonType = 'wagonType',
  WeighingDatetime = 'weighingDatetime',
  WeighingId = 'weighingId',
}

const weightFormat = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default function WeighingRoute() {
  const { route, router } = useRouteNode('weighing');
  const api = useApiClient();
  const uploadFile = useUploadFile();
  const toaster = useToaster();

  const dateRangeFilterValue = useMemo(
    () =>
      route.params.fromDate && route.params.toDate
        ? {
            startDate: parseDate(route.params.fromDate),
            endDate: parseDate(route.params.toDate),
          }
        : null,

    [route.params.fromDate, route.params.toDate]
  );

  const [sorting, setSorting] = useState<ISorting>({
    field: 'created',
    direction: SortingDirection.Descending,
  });

  const defaultSorting: ISorting<WeighingRecordsSortingField> = {
    field: WeighingRecordsSortingField.Created,
    direction: SortingDirection.Descending,
  };

  const { data, error, isFetching, refetch } = useAsyncData(
    [
      api,
      dateRangeFilterValue,
      route.params.cargo,
      route.params.isProcessed,
      route.params.isXlsxSent,
      route.params.organization,
      route.params.page,
      route.params.pageSize,
      route.params.wagonType,
      route.params.wagons,
      route.params.weighingDatetime,
      route.params.alerts,
      sorting,
    ],
    async () => {
      const [{ meta, results }, filterValues] = await Promise.all([
        fetchWeighingRecords(api, {
          alerts: route.params.alerts
            ? (route.params.alerts as string).split(',').filter(isWeighingAlert)
            : undefined,
          cargo: route.params.cargo
            ? (route.params.cargo as string).split(',')
            : undefined,
          fromDate: dateRangeFilterValue?.startDate,
          isProcessed:
            route.params.isProcessed === 'True'
              ? true
              : route.params.isProcessed === 'False'
              ? false
              : undefined,
          isXlsxSent:
            route.params.isXlsxSent === 'True'
              ? true
              : route.params.isXlsxSent === 'False'
              ? false
              : undefined,
          organization: route.params.organization
            ? (route.params.organization as string).split(',')
            : undefined,
          page: route.params.page,
          pageSize: route.params.pageSize,
          sorting: expandSorting(
            validateSorting(
              (field): field is SortingField =>
                Object.values(SortingField).includes(field as SortingField),
              sorting,
              {
                field: SortingField.Created,
                direction: SortingDirection.Descending,
              }
            ),
            {
              [SortingField.Created]: [WeighingRecordsSortingField.Created],
              [SortingField.IsProcessed]: [
                WeighingRecordsSortingField.IsProcessed,
              ],
              [SortingField.Wagon]: [WeighingRecordsSortingField.WagonNumber],
              [SortingField.WagonType]: [WeighingRecordsSortingField.WagonType],
              [SortingField.WeighingDatetime]: [
                WeighingRecordsSortingField.WeighingDatetime,
              ],
              [SortingField.WeighingId]: [
                WeighingRecordsSortingField.WeighingId,
              ],
            }
          ),
          toDate: dateRangeFilterValue?.endDate,
          wagonType: route.params.wagonType
            ? (route.params.wagonType as string).split(',')
            : undefined,
          wagons: route.params.wagons
            ? (route.params.wagons as string).split(',')
            : undefined,
          weighingDatetime: route.params.weighingDatetime,
        }),
        fetchWeighingRecordsFilterValues(api),
      ]);

      const userIds = results
        .map(weighingRecord => weighingRecord.userModifiedAuxWagonFields)
        .filter(isNotNull);

      const usersIndex = indexByLastItem(
        await fetchManyUsers(api, userIds),
        user => user.id
      );

      const tableItems = results.map(
        (weighingRecord): ITableItem => ({
          userModifiedAuxWagonFields:
            weighingRecord.userModifiedAuxWagonFields == null
              ? null
              : usersIndex[weighingRecord.userModifiedAuxWagonFields],
          weighingRecord,
        })
      );

      return {
        currentPage: meta.currentPage,
        filterValues,
        items: tableItems,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
      };
    }
  );

  const columns = useMemo(
    (): Array<ListTableColumn<ITableItem>> => [
      {
        id: 'weighingDatetime',
        defaultWidth: 170,
        label: 'Дата взвешивания',
        sortable: true,
        copyCellContent: ({ weighingRecord }) =>
          dayjs(weighingRecord.weighingDatetime).format(DATE_FORMAT_DATETIME),
        renderCellContent: ({ weighingRecord }) => (
          <Toolbar noWrap valign="center">
            <Ellipsis component="span">
              {dayjs(weighingRecord.weighingDatetime).format(
                DATE_FORMAT_DATETIME
              )}
            </Ellipsis>

            <Link
              params={{
                ...route.params,
                page: undefined,
                weighingDatetime: dayjs(weighingRecord.weighingDatetime).format(
                  DATE_FORMAT_API_DATETIME
                ),
              }}
              to={route.name}
            >
              <Icon icon="filter" />
            </Link>

            {weighingRecord.alerts.length !== 0 && (
              <Tooltip2
                content={weighingRecord.alerts
                  .map(getWeighingAlertLabel)
                  .join(', ')}
                intent={Intent.WARNING}
                position={Position.TOP_LEFT}
              >
                <Button icon="warning-sign" minimal />
              </Tooltip2>
            )}
          </Toolbar>
        ),
      },
      {
        id: 'wagon',
        defaultWidth: 135,
        label: 'Вагон',
        sortable: true,
        copyCellContent: ({ weighingRecord }) => weighingRecord.wagon,
        renderCellContent: ({ weighingRecord }) => (
          <Toolbar noWrap valign="center">
            <Ellipsis
              component={Link}
              params={{ number: weighingRecord.wagon }}
              rel="noopener"
              target="_blank"
              to="wagons.edit"
            >
              {weighingRecord.wagon}
            </Ellipsis>

            <Link
              params={{
                ...route.params,
                page: undefined,
                wagons: weighingRecord.wagon,
              }}
              to={route.name}
            >
              <Icon icon="filter" />
            </Link>

            <Popover2
              content={
                <div className={css.wagonCellPhotoButtonPopoverContent}>
                  <a
                    href={weighingRecord.wagonPhoto}
                    rel="noopener"
                    target="_blank"
                  >
                    <img
                      className={css.wagonCellPhotoButtonPopoverContentImage}
                      src={weighingRecord.wagonPhoto}
                    />
                  </a>
                </div>
              }
              position={Position.RIGHT}
            >
              <Button icon="mugshot" intent={Intent.PRIMARY} minimal small />
            </Popover2>
          </Toolbar>
        ),
      },
      {
        id: 'wagonType',
        defaultWidth: 140,
        label: 'Тип вагона',
        sortable: true,
        copyCellContent: ({ weighingRecord }) => weighingRecord.wagonType,
        renderCellContent: ({ weighingRecord }) => (
          <Toolbar noWrap valign="center">
            <Ellipsis component="span">{weighingRecord.wagonType}</Ellipsis>

            <Link
              params={{
                ...route.params,
                page: undefined,
                wagonType: weighingRecord.wagonType,
              }}
              to={route.name}
            >
              <Icon icon="filter" />
            </Link>
          </Toolbar>
        ),
      },
      {
        id: 'gross',
        defaultWidth: 67,
        label: 'Брутто',
        copyCellContent: ({ weighingRecord }) =>
          weightFormat.format(weighingRecord.gross),
        renderCellContent: ({ weighingRecord }) => (
          <Ellipsis className={css.cellCenterContainer} component="div">
            {weightFormat.format(weighingRecord.gross)}
          </Ellipsis>
        ),
      },
      {
        id: 'tara',
        defaultWidth: 56,
        label: 'Тара',
        copyCellContent: ({ weighingRecord }) =>
          weighingRecord.tara == null
            ? ''
            : weightFormat.format(weighingRecord.tara),
        renderCellContent: ({ weighingRecord }) =>
          weighingRecord.tara == null ? null : (
            <Ellipsis className={css.cellCenterContainer} component="div">
              {weightFormat.format(weighingRecord.tara)}
            </Ellipsis>
          ),
      },
      {
        id: 'net',
        defaultWidth: 60,
        label: 'Нетто',
        copyCellContent: ({ weighingRecord }) =>
          weighingRecord.net == null
            ? ''
            : weightFormat.format(weighingRecord.net),
        renderCellContent: ({ weighingRecord }) =>
          weighingRecord.net == null ? null : (
            <Ellipsis className={css.cellCenterContainer} component="div">
              {weightFormat.format(weighingRecord.net)}
            </Ellipsis>
          ),
      },
      {
        id: 'capacity',
        defaultWidth: 145,
        label: 'Грузоподъёмность',
        copyCellContent: ({ weighingRecord }) =>
          weighingRecord.capacity == null
            ? ''
            : weightFormat.format(weighingRecord.capacity),
        renderCellContent: ({ weighingRecord }) =>
          weighingRecord.capacity == null ? null : (
            <Ellipsis className={css.cellCenterContainer} component="div">
              {weightFormat.format(weighingRecord.capacity)}
            </Ellipsis>
          ),
      },
      {
        id: 'model',
        defaultWidth: 95,
        label: 'Модель',
        copyCellContent: ({ weighingRecord }) => weighingRecord.model,
        renderCellContent: ({ weighingRecord }) => (
          <Ellipsis component="span">{weighingRecord.model}</Ellipsis>
        ),
      },
      {
        id: 'cargo',
        defaultWidth: 190,
        label: 'Груз',
        copyCellContent: ({ weighingRecord }) => weighingRecord.cargo,
        renderCellContent: ({ weighingRecord }) => (
          <Ellipsis component="span">{weighingRecord.cargo}</Ellipsis>
        ),
      },
      {
        id: 'organization',
        defaultWidth: 97,
        label: 'Контрагент',
        copyCellContent: ({ weighingRecord }) => weighingRecord.organization,
        renderCellContent: ({ weighingRecord }) => (
          <Ellipsis component="span">{weighingRecord.organization}</Ellipsis>
        ),
      },
      {
        id: 'note',
        defaultWidth: 75,
        label: 'Заметка',
        copyCellContent: ({ weighingRecord }) => weighingRecord.note ?? '',
        renderCellContent: ({ weighingRecord }) =>
          weighingRecord.note == null ? null : (
            <Ellipsis component="span">{weighingRecord.note}</Ellipsis>
          ),
      },
      {
        id: 'isProcessed',
        defaultWidth: 110,
        label: 'Обработано',
        sortable: true,
        copyCellContent: ({ weighingRecord }) =>
          weighingRecord.isProcessed ? 'Обработано' : 'Не обработано',
        renderCellContent: ({ weighingRecord }) => (
          <div className={css.cellCenterContainer}>
            <BooleanIcon value={weighingRecord.isProcessed} />
          </div>
        ),
      },
      {
        id: 'isXlsxSent',
        defaultWidth: 102,
        label: 'Отправлено',
        sortable: true,
        copyCellContent: ({ weighingRecord }) =>
          weighingRecord.isXlsxSent ? 'Да' : 'Нет',
        renderCellContent: ({ weighingRecord }) => (
          <div className={css.cellCenterContainer}>
            <BooleanIcon value={weighingRecord.isXlsxSent} />
          </div>
        ),
      },
      {
        id: 'userModifiedAuxWagonFields',
        defaultWidth: 145,
        label: 'Источник',
        copyCellContent: ({ userModifiedAuxWagonFields, weighingRecord }) =>
          userModifiedAuxWagonFields == null
            ? weighingRecord.isProcessed
              ? 'Дислокация'
              : ''
            : formatUserName(userModifiedAuxWagonFields),
        renderCellContent: ({ userModifiedAuxWagonFields, weighingRecord }) => (
          <Ellipsis component="span">
            {userModifiedAuxWagonFields == null
              ? weighingRecord.isProcessed
                ? 'Дислокация'
                : ''
              : formatUserName(userModifiedAuxWagonFields)}
          </Ellipsis>
        ),
      },
      {
        id: 'modificationDateOfAuxWagonFields',
        defaultWidth: 134,
        label: 'Дата заполнения',
        copyCellContent: ({ weighingRecord }) =>
          weighingRecord.modificationDateOfAuxWagonFields == null
            ? ''
            : dayjs(weighingRecord.modificationDateOfAuxWagonFields).format(
                DATE_FORMAT_DATETIME
              ),
        renderCellContent: ({ weighingRecord }) =>
          weighingRecord.modificationDateOfAuxWagonFields == null ? null : (
            <Ellipsis component="span">
              {dayjs(weighingRecord.modificationDateOfAuxWagonFields).format(
                DATE_FORMAT_DATETIME
              )}
            </Ellipsis>
          ),
      },
      {
        id: 'created',
        defaultWidth: 156,
        label: 'Дата добавления',
        sortable: true,
        copyCellContent: ({ weighingRecord }) =>
          dayjs(weighingRecord.created).format(DATE_FORMAT_DATETIME),
        renderCellContent: ({ weighingRecord }) => (
          <Ellipsis component="span">
            {dayjs(weighingRecord.created).format(DATE_FORMAT_DATETIME)}
          </Ellipsis>
        ),
      },
      {
        id: 'weighingId',
        defaultWidth: 156,
        label: 'ID',
        sortable: true,
        copyCellContent: ({ weighingRecord }) => weighingRecord.weighingId,
        renderCellContent: ({ weighingRecord }) => (
          <Ellipsis component="span">{weighingRecord.weighingId}</Ellipsis>
        ),
      },
    ],
    [route.name, route.params]
  );

  const getItemId = useCallback(
    ({ weighingRecord }: ITableItem) => String(weighingRecord.id),
    []
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const [selectedWeights, setSelectedWeights] = useState<string[]>([]);
  const selectedWeightsIds = selectedWeights.map(Number);

  const [isSendingXlsxToTelegram, setIsSendingXlsxToTelegram] =
    React.useState(false);

  const batchEditDialogForm = useDialog<{
    initialValues: IBatchEditDialogFormValues;
  }>();

  const xlsxIsNotAvailable =
    !data ||
    selectedWeightsIds.length === 0 ||
    selectedWeightsIds
      .map(id => data.items.find(item => item.weighingRecord.id === id))
      .filter(isNotNull)
      .some(item => !item.weighingRecord.isProcessed);

  const getRowColor = useCallback(getWeighingRowColor, []);

  return (
    <>
      <FormattedTitle>Взвешивание</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Toolbar align="right">
            {data && (
              <DateRangeWithMonthSelectFilter
                minDate={dayjs(data.filterValues.dateRange[0])
                  .startOf('month')
                  .toDate()}
                maxDate={dayjs(data.filterValues.dateRange[1])
                  .add(1, 'month')
                  .startOf('month')
                  .toDate()}
                initialValue={dateRangeFilterValue}
                onApply={newDateRangeFilterValue => {
                  applyFilterParams(
                    newDateRangeFilterValue
                      ? {
                          fromDate: dayjs(
                            newDateRangeFilterValue.startDate
                          ).format(DATE_FORMAT_API_DATE),
                          toDate: dayjs(newDateRangeFilterValue.endDate).format(
                            DATE_FORMAT_API_DATE
                          ),
                        }
                      : {
                          fromDate: undefined,
                          toDate: undefined,
                        }
                  );
                }}
              />
            )}

            <WagonNumbersFilter
              initialValue={route.params.wagons}
              onApply={wagons => {
                applyFilterParams({ wagons });
              }}
            />

            <MultiSelectFilter
              initialValue={route.params.wagonType}
              isFetching={isFetching}
              itemPredicate={(query, wagonType) =>
                wagonType.toLowerCase().includes(query.toLowerCase())
              }
              items={data ? data.filterValues.wagonType : []}
              itemToOption={wagonType => ({
                label: wagonType,
                value: wagonType,
              })}
              label="Тип вагона"
              valueProcessor={{
                emptyValue: undefined,
                deserialize: value => (value ? value.split(',') : []),
                serialize: value =>
                  value.length ? value.join(',') : undefined,
                isEmpty: value => !value,
              }}
              width={300}
              onApply={wagonType => {
                applyFilterParams({ wagonType });
              }}
            />

            <MultiSelectFilter
              initialValue={route.params.cargo}
              isFetching={isFetching}
              itemPredicate={(query, cargo) =>
                cargo.toLowerCase().includes(query.toLowerCase())
              }
              items={data ? data.filterValues.cargo : []}
              itemToOption={cargo => ({ label: cargo, value: cargo })}
              label="Груз"
              valueProcessor={{
                emptyValue: undefined,
                deserialize: value => (value ? value.split(',') : []),
                serialize: value =>
                  value.length ? value.join(',') : undefined,
                isEmpty: value => !value,
              }}
              width={300}
              onApply={cargo => {
                applyFilterParams({ cargo });
              }}
            />

            <MultiSelectFilter
              initialValue={route.params.organization}
              isFetching={isFetching}
              itemPredicate={(query, organization) =>
                organization.toLowerCase().includes(query.toLowerCase())
              }
              items={data ? data.filterValues.organization : []}
              itemToOption={organization => ({
                label: organization,
                value: organization,
              })}
              label="Контрагент"
              valueProcessor={{
                emptyValue: undefined,
                deserialize: value => (value ? value.split(',') : []),
                serialize: value =>
                  value.length ? value.join(',') : undefined,
                isEmpty: value => !value,
              }}
              width={300}
              onApply={organization => {
                applyFilterParams({ organization });
              }}
            />

            <WeighingsStatusFilterPopover
              label="Статус"
              initialValues={{
                isProcessed:
                  route.params.isProcessed === 'True'
                    ? true
                    : route.params.isProcessed === 'False'
                    ? false
                    : undefined,
                isSent:
                  route.params.isXlsxSent === 'True'
                    ? true
                    : route.params.isXlsxSent === 'False'
                    ? false
                    : undefined,
              }}
              onSubmit={({ isProcessed, isSent }) => {
                applyFilterParams({
                  isProcessed:
                    isProcessed === true
                      ? 'True'
                      : isProcessed === false
                      ? 'False'
                      : undefined,
                  isXlsxSent:
                    isSent === true
                      ? 'True'
                      : isSent === false
                      ? 'False'
                      : undefined,
                });
              }}
            />

            <CheckboxListFilter
              allOption
              iconName="warning-sign"
              initialValue={route.params.alerts}
              label="Предупреждения"
              options={weighingAlertsOptions}
              onApply={alerts => {
                applyFilterParams({ alerts });
              }}
            />

            <Tooltip2
              content={
                <TooltipContent>
                  Обновить расчетные значения без сброса фильтрации и
                  сортировки.
                </TooltipContent>
              }
            >
              <Button text="Обновить" onClick={refetch} />
            </Tooltip2>

            <Tooltip2
              content={
                <TooltipContent>
                  Сбросить фильтрацию по значениям. Для отмены сортировки
                  обновите страницу в браузере.
                </TooltipContent>
              }
            >
              <LinkButton text="Сбросить фильтры" to={route.name} />
            </Tooltip2>

            <Tooltip2
              content={
                <TooltipContent>
                  Для формирования и загрузки справки выберите необходимые
                  вагоны статусом &laquo;Обработано&raquo;.
                </TooltipContent>
              }
              disabled={!xlsxIsNotAvailable}
            >
              <AnchorButton
                disabled={xlsxIsNotAvailable}
                download
                href={getWeighingRecordsXlsxDownloadLink(
                  api,
                  selectedWeightsIds
                )}
                icon="download"
                intent={Intent.PRIMARY}
                rel="noopener"
                target="_blank"
                text="Справка"
              />
            </Tooltip2>

            <Tooltip2
              content={
                <TooltipContent>
                  Скачивает отчет с указанием расхождений между
                  грузоподъемностью и массой нетто выбранных вагонов. Для
                  активации кнопки выберите необходимые вагоны со статусом
                  &laquo;Обработано&raquo;.
                </TooltipContent>
              }
              disabled={!xlsxIsNotAvailable}
            >
              <AnchorButton
                disabled={xlsxIsNotAvailable}
                download
                href={getWeighingRecordsReportDownloadLink(
                  api,
                  selectedWeightsIds
                )}
                icon="download"
                intent={Intent.SUCCESS}
                rel="noopener"
                target="_blank"
                text="Отчет"
              />
            </Tooltip2>
          </Toolbar>
        </Row>

        <Row>
          <Toolbar valign="center">
            <span>
              {plural(selectedWeightsIds.length, [
                () => `Выбран ${selectedWeightsIds.length} вагон`,
                () => `Выбрано ${selectedWeightsIds.length} вагона`,
                () => `Выбрано ${selectedWeightsIds.length} вагонов`,
              ])}
              :
            </span>

            <Button
              disabled={selectedWeightsIds.length === 0}
              intent={Intent.PRIMARY}
              text="Редактировать"
              onClick={() => {
                batchEditDialogForm.open({
                  initialValues: {
                    capacity: '',
                    model: '',
                    tara: '',
                  },
                });
              }}
            />
            <ButtonGroup>
              <Tooltip2
                content={
                  <TooltipContent>
                    Отправляет справку с результатами взвешивания вагонов через
                    Telegram. Для активации кнопки выберите необходимые вагоны
                    со статусом &laquo;Обработано&raquo;.
                  </TooltipContent>
                }
                disabled={!xlsxIsNotAvailable}
              >
                <AnchorButton
                  disabled={xlsxIsNotAvailable || isSendingXlsxToTelegram}
                  icon="send-message"
                  intent={Intent.PRIMARY}
                  loading={isSendingXlsxToTelegram}
                  rel="noopener"
                  target="_blank"
                  text="Отправить справку"
                  onClick={async () => {
                    try {
                      setIsSendingXlsxToTelegram(false);

                      await sendWeighingRecordsXlsxToTelegram(
                        api,
                        selectedWeightsIds
                      );

                      toaster.show({
                        intent: Intent.SUCCESS,
                        message: 'Справка успешно отправлена в Telegram',
                      });

                      setSelectedWeights([]);
                      refetch();
                    } catch (err) {
                      if (err instanceof BadRequestError) {
                        toaster.show({
                          intent: Intent.DANGER,
                          timeout: 0,
                          message: err.message,
                        });
                      } else {
                        toaster.show({
                          intent: Intent.DANGER,
                          timeout: 0,
                          message:
                            'Не удалось отправить справку в Telegram: Непредвиденная ошибка',
                        });

                        throw err;
                      }
                    } finally {
                      setIsSendingXlsxToTelegram(false);
                    }
                  }}
                />
              </Tooltip2>
            </ButtonGroup>
          </Toolbar>
        </Row>

        <Row>
          <Grid>
            <Col>
              <WeighingLegend />
            </Col>

            <Col align="end">
              <StateContainer initialState={false}>
                {(isUploading, setIsUploading) => (
                  <FileChooserButton
                    disabled={isUploading}
                    intent={Intent.NONE}
                    loading={isUploading}
                    icon="upload"
                    multiple
                    text="xml"
                    onChoose={async files => {
                      let toastKey: string | undefined;

                      try {
                        setIsUploading(true);

                        const uploadFileResponses = await Promise.all(
                          files.map(uploadFile)
                        );

                        toastKey = toaster.show({
                          intent: Intent.PRIMARY,
                          timeout: 0,
                          message: (
                            <>
                              <p>Файлы обрабатываются</p>
                              <ProgressBar intent={Intent.PRIMARY} />
                            </>
                          ),
                        });

                        const createdWeighingRecords =
                          await bulkCreateWeighingRecords(api, {
                            files: uploadFileResponses.map(
                              response => response.file
                            ),
                          });

                        const createdRecordsCount =
                          createdWeighingRecords.length;

                        toastKey = toaster.show(
                          {
                            intent: Intent.SUCCESS,
                            timeout: 5000,
                            message: plural(createdRecordsCount, [
                              () =>
                                `Из загруженных файлов успешно создано ${createdRecordsCount} взвешивание`,
                              () =>
                                `Из загруженных файлов успешно создано ${createdRecordsCount} взвешивания`,
                              () =>
                                `Из загруженных файлов успешно создано ${createdRecordsCount} взвешиваний`,
                            ]),
                          },
                          toastKey
                        );

                        refetch();
                      } catch (err) {
                        if (err instanceof BadRequestError) {
                          toaster.show(
                            {
                              intent: Intent.DANGER,
                              timeout: 0,
                              message: err.message,
                            },
                            toastKey
                          );
                        } else {
                          toaster.show(
                            {
                              intent: Intent.DANGER,
                              timeout: 0,
                              message: 'Ошибка при загрузке файлов взвешивания',
                            },
                            toastKey
                          );

                          throw err;
                        }
                      } finally {
                        setIsUploading(false);
                      }
                    }}
                  />
                )}
              </StateContainer>

              <SortingInput
                defaultValue={defaultSorting}
                options={columns
                  .filter(column => column.sortable)
                  .map(column => ({
                    label: column.label,
                    value: column.id as WeighingRecordsSortingField,
                  }))}
                value={sorting}
                onChange={setSorting}
              />
            </Col>
          </Grid>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage error={error} />
            )
          ) : (
            <>
              <ListTable
                columns={columns}
                getItemId={getItemId}
                isFetching={isFetching}
                items={data.items}
                getRowColor={getRowColor}
                lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
                selectedItems={selectedWeights}
                sorting={sorting}
                stickyColumnCount={4}
                onSelectedItemsChange={setSelectedWeights}
                onSortingChange={setSorting}
              />
            </>
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>

      {batchEditDialogForm.state && (
        <BatchEditDialogForm
          initialValues={batchEditDialogForm.state.initialValues}
          isOpen={batchEditDialogForm.state.isOpen}
          onClose={batchEditDialogForm.close}
          onClosed={batchEditDialogForm.destroy}
          onSubmit={async values => {
            try {
              const selectedWeightsWagons = selectedWeightsIds
                .map(id =>
                  data?.items.find(
                    ({ weighingRecord }) => weighingRecord.id === id
                  )
                )
                .filter(isNotNull)
                .map(({ weighingRecord }) => weighingRecord.wagon);

              await updateWagons(api, selectedWeightsWagons, {
                capacity: values.capacity.replace(',', '.') || undefined,
                model: values.model || undefined,
                tara: values.tara.replace(',', '.') || undefined,
              });

              batchEditDialogForm.close();
              refetch();

              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось отредактировать вагоны: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}
    </>
  );
}

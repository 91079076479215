import { useApiClient } from '_core/api/context';
import { FilterPopoverUi, useFilterPopover } from '_core/filters/filterPopover';
import { useAsyncData } from '_core/useAsyncData';
import { useDebouncedValue } from '_core/useDebouncedValue';
import { MenuItem, Spinner } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { fetchManySuppliersDocuments, fetchSuppliersDocuments } from './api';
import * as css from './suggestFilterInPopover.module.css';
import { ISuppliersDocumentSerialized } from './types';

interface IProps {
  initialValue: string | undefined;
  onApply: (suppliersDocuments: string) => void;
}

export function SuppliersDocumentsSuggestFilterInPopover({
  initialValue,
  onApply,
}: IProps) {
  const api = useApiClient();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query, 400);

  const searchResponse = useAsyncData([debouncedQuery, api], async () =>
    debouncedQuery
      ? (
          await fetchSuppliersDocuments(api, {
            pageSize: 9,
            search: debouncedQuery,
          })
        ).results
      : []
  );

  const [selectedSuppliersDocuments, setSelectedSuppliersDocuments] = useState<
    ISuppliersDocumentSerialized[]
  >([]);

  const updateSelectedSuppliersDocuments = useCallback(
    (ids: number[]) => {
      fetchManySuppliersDocuments(api, ids).then(
        initiallySelectedSuppliersDocuments => {
          setSelectedSuppliersDocuments(initiallySelectedSuppliersDocuments);
        }
      );
    },

    [api]
  );

  useEffect(() => {
    updateSelectedSuppliersDocuments(
      initialValue ? initialValue.split(',').map(Number) : []
    );
  }, [initialValue, updateSelectedSuppliersDocuments]);

  const suppliersDocuments = selectedSuppliersDocuments.concat(
    searchResponse.data
      ? searchResponse.data.filter(
          suppliersDocument =>
            selectedSuppliersDocuments.findIndex(
              doc => doc.id === suppliersDocument.id
            ) === -1
        )
      : []
  );

  const getSuppliersDocumentLabel = (
    suppliersDocument: ISuppliersDocumentSerialized
  ) =>
    suppliersDocument.number ||
    `<номер не указан>, id: ${suppliersDocument.id}`;

  const filterPopover = useFilterPopover<string | undefined, number | null>(
    initialValue,
    onApply,

    {
      emptyValue: undefined,
      isEmpty: value => !value,
      deserialize: value => (value ? Number(value) : null),
      serialize: value => (value ? String(value) : undefined),
    }
  );

  return (
    <FilterPopoverUi api={filterPopover.ui} label="Счёт-фактура">
      <div className={css.main} style={{ width: 400 }}>
        <Suggest
          noResults={
            searchResponse.isFetching ? (
              <Spinner />
            ) : (
              <MenuItem disabled text="Элементов не найдено" />
            )
          }
          initialContent={<MenuItem disabled text="Введите запрос" />}
          inputProps={{
            placeholder: 'Поиск...',
          }}
          inputValueRenderer={getSuppliersDocumentLabel}
          itemPredicate={(q, suppliersDocument) =>
            suppliersDocument.number.toLowerCase().includes(q.toLowerCase())
          }
          items={suppliersDocuments}
          itemRenderer={(suppliersDocument, { modifiers, handleClick }) =>
            modifiers.matchesPredicate ? (
              <MenuItem
                active={modifiers.active}
                icon={
                  suppliersDocument.id === filterPopover.value
                    ? 'tick'
                    : 'blank'
                }
                key={suppliersDocument.id}
                shouldDismissPopover={false}
                text={getSuppliersDocumentLabel(suppliersDocument)}
                onClick={handleClick}
              />
            ) : null
          }
          popoverProps={{
            targetTagName: 'div',
            usePortal: false,
            wrapperTagName: 'div',
          }}
          query={query}
          selectedItem={suppliersDocuments.find(
            doc => doc.id === filterPopover.value
          )}
          onItemSelect={suppliersDocument => {
            setSelectedSuppliersDocuments(prevState =>
              selectedSuppliersDocuments.findIndex(
                p => p.id === suppliersDocument.id
              ) === -1
                ? prevState.concat(suppliersDocument)
                : prevState
            );

            setQuery('');

            if (suppliersDocument.id === filterPopover.value) {
              filterPopover.change(null);
            } else {
              filterPopover.change(suppliersDocument.id);
            }
          }}
          onQueryChange={setQuery}
        />
      </div>
    </FilterPopoverUi>
  );
}

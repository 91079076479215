import { useApiClient } from '_core/api/context';
import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { FileInputSingleForFinalForm } from '_core/inputs/fileInputSingle';
import { Select, SelectForFinalForm } from '_core/inputs/select';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { plural } from '_core/strings/utils';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Callout, FormGroup, Intent } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import { IPartnerInline } from 'partners/api';
import { partnerToOption } from 'partners/utils';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { techrunRateToOption } from 'techrunRates/types';
import {
  createTechrunTransportationsFromXlsx,
  ICreateTechrunTransportationsFromXlsxResponse,
} from 'techrunRequests/api';
import {
  ITechrunTransportation,
  techrunTransportationStatusFromGroup,
} from 'techrunTransportations/types';

import { useGetPurchaseRatesForPartner } from './purchaseRatesForPartner';

export interface IImportTransportationsDialogFormValues {
  file: string | null;
  purchaseRate: number | null;
  purchaseRent: boolean;
}

interface IProps {
  initialValues: IImportTransportationsDialogFormValues;
  isOpen: boolean;
  purchasePartners: IPartnerInline[];
  requestId: number;
  onClose: () => void;
  onClosed: () => void;
}

export function ImportTransportationsDialog({
  initialValues,
  isOpen,
  purchasePartners,
  requestId,
  onClose,
  onClosed,
}: IProps) {
  const api = useApiClient();

  const [importResponse, setImportResponse] =
    useState<ICreateTechrunTransportationsFromXlsxResponse | null>(null);

  const columns = useMemo(
    (): Array<ListTableColumn<ITechrunTransportation>> => [
      {
        id: 'waybillNumber',
        label: 'Номер накладной',
        defaultWidth: 150,
        copyCellContent: transportation =>
          transportation.waybillNumber || '<отсутствует>',
        renderCellContent: transportation => (
          <Ellipsis
            component={Link}
            params={{
              id: transportation.id,
              status: techrunTransportationStatusFromGroup(
                transportation.group
              ),
            }}
            rel="noopener"
            target="_blank"
            to="techruns.transportations.edit"
          >
            {transportation.waybillNumber || '<отсутствует>'}
          </Ellipsis>
        ),
      },
      {
        id: 'wagon',
        label: 'Вагон',
        defaultWidth: 95,
        copyCellContent: transportation => transportation.wagon,
        renderCellContent: transportation => (
          <Ellipsis
            component={Link}
            params={{ number: transportation.wagon }}
            rel="noopener"
            target="_blank"
            to="wagons.edit"
          >
            {transportation.wagon}
          </Ellipsis>
        ),
      },
    ],
    []
  );

  const getItemId = useCallback(
    (transportation: ITechrunTransportation) => String(transportation.id),
    []
  );

  const getPurchaseRatesForPartner = useGetPurchaseRatesForPartner();

  const [purchasePartner, setPurchasePartner] = useState<string | null>(null);

  const purchaseRatesRequest = useAsyncData(
    [getPurchaseRatesForPartner, purchasePartner],
    () =>
      purchasePartner == null || isNaN(Number(purchasePartner))
        ? Promise.resolve(null)
        : getPurchaseRatesForPartner(Number(purchasePartner))
  );

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          const response = await createTechrunTransportationsFromXlsx(api, {
            file: values.file,
            purchaseRate: values.purchaseRent ? null : values.purchaseRate,
            purchaseRent: values.purchaseRent,
            requestId,
          });

          setImportResponse(response);

          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось добавить отправки: Непредвиденная ошибка'
          );
        }
      }}
    >
      {({ error, handleSubmit, submitError, submitting, values }) => (
        <DialogForm
          isOpen={isOpen}
          isSubmitting={submitting}
          title="Добавление отправок вручную"
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        >
          {importResponse ? (
            <>
              <DialogBody>
                <VGrid>
                  <Row>
                    <Callout
                      intent={Intent.SUCCESS}
                      title={`${plural(importResponse.createdRecords.length, [
                        () =>
                          `Создана ${importResponse.createdRecords.length} отправка`,
                        () =>
                          `Создано ${importResponse.createdRecords.length} отправки`,
                        () =>
                          `Создано ${importResponse.createdRecords.length} отправок`,
                      ])} из ${plural(importResponse.createdRecords.length, [
                        () => `${importResponse.recordsCount} строки`,
                        () => `${importResponse.recordsCount} строк`,
                        () => `${importResponse.recordsCount} строк`,
                      ])}`}
                    />
                  </Row>

                  <Row>
                    <ListTable
                      columns={columns}
                      getItemId={getItemId}
                      items={importResponse.createdRecords}
                      maxHeight={300}
                    />
                  </Row>
                </VGrid>
              </DialogBody>

              <DialogFooter>
                <DialogFooterActions>
                  <Button
                    intent={Intent.PRIMARY}
                    text="Закрыть"
                    onClick={onClose}
                  />
                </DialogFooterActions>
              </DialogFooter>
            </>
          ) : (
            <>
              <DialogBody>
                <FormErrors error={error || submitError} />

                <FileInputSingleForFinalForm
                  id="file"
                  label="Файл (.xlsx)"
                  name="file"
                />

                <CheckboxForFinalForm
                  id="purchaseRent"
                  label="Аренда"
                  name="purchaseRent"
                />

                <FormGroup label="Поставщик">
                  <Select
                    disabled={values.purchaseRent || submitting}
                    fill
                    options={purchasePartners.map(partnerToOption)}
                    withEmptyOption
                    value={purchasePartner}
                    onChange={setPurchasePartner}
                  />
                </FormGroup>

                <FormGroup label="Ставка от поставщика">
                  <SelectForFinalForm
                    disabled={values.purchaseRent || !purchaseRatesRequest.data}
                    id="purchaseRate"
                    options={(purchaseRatesRequest.data || []).map(
                      techrunRateToOption
                    )}
                    name="purchaseRate"
                    withEmptyOption
                  />
                </FormGroup>
              </DialogBody>

              <DialogFooter>
                <DialogFooterActions>
                  <Button
                    disabled={submitting}
                    text="Отмена"
                    onClick={onClose}
                  />

                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    loading={submitting}
                    text="Добавить"
                    type="submit"
                  />
                </DialogFooterActions>
              </DialogFooter>
            </>
          )}
        </DialogForm>
      )}
    </FinalForm>
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { IPartnerInline } from 'partners/api';

const BASE_ENDPOINT = '/repairs_repair';

export enum RepairType {
  Current = 'CURRENT',
  Planned = 'PLANNED',
}

interface IRepairPart {
  cost: string;
  name: string;
  price: string;
  quantity: string;
}

interface IRepairService {
  cost: string;
  name: string;
  price: string;
  quantity: string;
}

interface IRepairSerialized {
  arrivalStation: number | null;
  arrivedForLoadDate: string | null;
  corrArrivedForLoadDate: string | null;
  corrSendedFromRepairDate: string | null;
  corrSendedToRepairDate: string | null;
  corrVu23Date: string | null;
  corrVu36Date: string | null;
  currentStation: string | null;
  defect: string;
  dontBill: boolean;
  dontBillCause: string;
  endDate: string | null;
  id: number;
  incomeRate: string;
  isEmpty: boolean;
  nspDowntime: string;
  parts: IRepairPart[];
  purchaseFiles: string[];
  renterName: string | null;
  repairStation: number | null;
  repairStationDowntime: number | null;
  sendedFromRepairDate: string | null;
  sendedToRepairDate: string | null;
  sellFiles: string[];
  services: IRepairService[];
  startDate: string;
  type: RepairType;
  vu23Date: string | null;
  vu36Date: string | null;
  wagon: string;
  wagonOwner: IPartnerInline | null;
  wagonType: string | null;
}

export interface IRepair
  extends Omit<
    IRepairSerialized,
    | 'arrivedForLoadDate'
    | 'corrArrivedForLoadDate'
    | 'corrSendedFromRepairDate'
    | 'corrSendedToRepairDate'
    | 'corrVu23Date'
    | 'corrVu36Date'
    | 'endDate'
    | 'sendedFromRepairDate'
    | 'sendedToRepairDate'
    | 'startDate'
    | 'vu23Date'
    | 'vu36Date'
  > {
  arrivedForLoadDate: Date | null;
  corrArrivedForLoadDate: Date | null;
  corrSendedFromRepairDate: Date | null;
  corrSendedToRepairDate: Date | null;
  corrVu23Date: Date | null;
  corrVu36Date: Date | null;
  endDate: Date | null;
  sendedFromRepairDate: Date | null;
  sendedToRepairDate: Date | null;
  startDate: Date;
  vu23Date: Date | null;
  vu36Date: Date | null;
}

function deserializeRepair({
  arrivedForLoadDate,
  corrArrivedForLoadDate,
  corrSendedFromRepairDate,
  corrSendedToRepairDate,
  corrVu23Date,
  corrVu36Date,
  endDate,
  sendedFromRepairDate,
  sendedToRepairDate,
  startDate,
  vu23Date,
  vu36Date,
  ...otherProps
}: IRepairSerialized): IRepair {
  return {
    ...otherProps,
    arrivedForLoadDate:
      arrivedForLoadDate == null ? null : parseDate(arrivedForLoadDate),
    corrArrivedForLoadDate:
      corrArrivedForLoadDate == null ? null : parseDate(corrArrivedForLoadDate),
    corrSendedFromRepairDate:
      corrSendedFromRepairDate == null
        ? null
        : parseDate(corrSendedFromRepairDate),
    corrSendedToRepairDate:
      corrSendedToRepairDate == null ? null : parseDate(corrSendedToRepairDate),
    corrVu23Date: corrVu23Date == null ? null : parseDate(corrVu23Date),
    corrVu36Date: corrVu36Date == null ? null : parseDate(corrVu36Date),
    endDate: endDate == null ? null : parseDate(endDate),
    sendedFromRepairDate:
      sendedFromRepairDate == null ? null : parseDate(sendedFromRepairDate),
    sendedToRepairDate:
      sendedToRepairDate == null ? null : parseDate(sendedToRepairDate),
    startDate: parseDate(startDate),
    vu23Date: vu23Date == null ? null : parseDate(vu23Date),
    vu36Date: vu36Date == null ? null : parseDate(vu36Date),
  };
}
export enum RepairsSortingField {
  Created = 'created',
  Wagon = 'wagon',
}

interface IFetchRepairsParams {
  fromDate?: Date;
  page?: number;
  pageSize?: number;
  search?: string;
  sorting?: Array<ISorting<RepairsSortingField>>;
  toDate?: Date;
  wagonNumbers?: string[];
}

interface IRepairsGroupSerialized {
  roadName: string | null;
  repairs: IRepairSerialized[];
}

interface IRepairsGroup extends Omit<IRepairsGroupSerialized, 'repairs'> {
  repairs: IRepair[];
}

export async function fetchRepairs(
  api: ApiClient,
  {
    fromDate,
    page,
    pageSize,
    search,
    sorting,
    toDate,
    wagonNumbers,
  }: IFetchRepairsParams = {}
): Promise<ListResponse<IRepairsGroup>> {
  const response = await api.get<ListResponse<IRepairsGroupSerialized>>(
    BASE_ENDPOINT,
    {
      fromDate: fromDate
        ? dayjs(fromDate).format(DATE_FORMAT_API_DATE)
        : undefined,
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      page,
      pageSize: pageSize || 5,
      search,
      toDate: toDate ? dayjs(toDate).format(DATE_FORMAT_API_DATE) : undefined,
      wagonNumbers:
        wagonNumbers && wagonNumbers.length !== 0
          ? wagonNumbers.join(',')
          : undefined,
    }
  );

  return {
    ...response,
    results: response.results.map(({ repairs, ...otherProps }) => ({
      ...otherProps,
      repairs: repairs.map(deserializeRepair),
    })),
  };
}

export async function fetchRepair(api: ApiClient, id: number) {
  const repair = await api.get<IRepairSerialized>(`${BASE_ENDPOINT}/${id}`);

  return deserializeRepair(repair);
}

interface ICreateOrUpdateRepairParamsPart {
  cost: string;
  name: string;
  price: string;
  quantity: string;
}

interface ICreateOrUpdateRepairParamsService {
  cost: string;
  name: string;
  price: string;
  quantity: string;
}

interface ICreateOrUpdateRepairParams {
  arrivedForLoadDate?: Date | null;
  corrArrivedForLoadDate: Date | null;
  corrSendedFromRepairDate: Date | null;
  corrSendedToRepairDate: Date | null;
  corrVu23Date: Date | null;
  corrVu36Date: Date | null;
  endDate: Date | null;
  parts: ICreateOrUpdateRepairParamsPart[];
  purchaseFiles: string[];
  sellFiles: string[];
  sendedFromRepairDate?: Date | null;
  sendedToRepairDate?: Date | null;
  services: ICreateOrUpdateRepairParamsService[];
  startDate: Date | null;
  type: RepairType;
  vu23Date?: Date | null;
  vu36Date?: Date | null;
  wagon: string;
}

function serializeCreateOrUpdateRepairParams({
  arrivedForLoadDate,
  corrArrivedForLoadDate,
  corrSendedFromRepairDate,
  corrSendedToRepairDate,
  corrVu23Date,
  corrVu36Date,
  endDate,
  sendedFromRepairDate,
  sendedToRepairDate,
  startDate,
  vu23Date,
  vu36Date,
  ...otherParams
}: ICreateOrUpdateRepairParams) {
  return {
    ...otherParams,
    arrivedForLoadDate:
      arrivedForLoadDate &&
      dayjs(arrivedForLoadDate).format(DATE_FORMAT_API_DATE),
    corrArrivedForLoadDate:
      corrArrivedForLoadDate &&
      dayjs(corrArrivedForLoadDate).format(DATE_FORMAT_API_DATE),
    corrSendedFromRepairDate:
      corrSendedFromRepairDate &&
      dayjs(corrSendedFromRepairDate).format(DATE_FORMAT_API_DATE),
    corrSendedToRepairDate:
      corrSendedToRepairDate &&
      dayjs(corrSendedToRepairDate).format(DATE_FORMAT_API_DATE),
    corrVu23Date:
      corrVu23Date && dayjs(corrVu23Date).format(DATE_FORMAT_API_DATE),
    corrVu36Date:
      corrVu36Date && dayjs(corrVu36Date).format(DATE_FORMAT_API_DATE),
    endDate: endDate && dayjs(endDate).format(DATE_FORMAT_API_DATE),
    sendedFromRepairDate:
      sendedFromRepairDate &&
      dayjs(sendedFromRepairDate).format(DATE_FORMAT_API_DATE),
    sendedToRepairDate:
      sendedToRepairDate &&
      dayjs(sendedToRepairDate).format(DATE_FORMAT_API_DATE),
    startDate: startDate && dayjs(startDate).format(DATE_FORMAT_API_DATE),
    vu23Date: vu23Date && dayjs(vu23Date).format(DATE_FORMAT_API_DATE),
    vu36Date: vu36Date && dayjs(vu36Date).format(DATE_FORMAT_API_DATE),
  };
}

export async function createRepair(
  api: ApiClient,
  params: ICreateOrUpdateRepairParams
) {
  const createdRepair = await api.post<IRepairSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateRepairParams(params)
  );

  return deserializeRepair(createdRepair);
}

export async function updateRepair(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateRepairParams
) {
  const updatedRepair = await api.put<IRepairSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateRepairParams(params)
  );

  return deserializeRepair(updatedRepair);
}

import { Select } from '_core/inputs/select';
import {
  Button,
  ButtonGroup,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import * as React from 'react';
import { useState } from 'react';

const ENTER_MANUALLY_OPTION_VALUE = 'enter-manually';
const OPTION_VALUES = ['0', '150', '300', '450', '600'];

interface IPopoverContentProps {
  initialValue: string | undefined;
  onApply: (newValue: string | undefined | null) => void;
}

function WagonArrivalsDistanceFilterPopoverContent({
  initialValue,
  onApply,
}: IPopoverContentProps) {
  const [inputValue, setInputValue] = useState(
    initialValue === undefined || OPTION_VALUES.includes(initialValue)
      ? ''
      : initialValue
  );

  const [selectValue, setSelectValue] = useState<string | null>(
    initialValue === undefined
      ? ''
      : OPTION_VALUES.includes(initialValue)
      ? initialValue
      : ENTER_MANUALLY_OPTION_VALUE
  );

  return (
    <>
      <FormGroup label="Расстояние до станции назначения">
        <Select
          fill
          options={[
            { label: '<выберите расстояние из списка>', value: '' },
            ...OPTION_VALUES.map(optionValue => ({
              label: `${optionValue}км`,
              value: optionValue,
            })),
            { label: 'Ввести вручную', value: ENTER_MANUALLY_OPTION_VALUE },
          ]}
          value={selectValue}
          onChange={newSelectValue => {
            setInputValue(prevValue =>
              newSelectValue === ENTER_MANUALLY_OPTION_VALUE ? '' : prevValue
            );

            setSelectValue(newSelectValue);
          }}
        />
      </FormGroup>

      {selectValue === ENTER_MANUALLY_OPTION_VALUE && (
        <FormGroup>
          <InputGroup
            value={inputValue}
            onChange={event => {
              setInputValue(event.currentTarget.value);
            }}
          />
        </FormGroup>
      )}

      <Button
        fill
        intent={Intent.PRIMARY}
        text="Применить"
        onClick={() => {
          if (
            selectValue === ENTER_MANUALLY_OPTION_VALUE &&
            inputValue &&
            isFinite(Number(inputValue))
          ) {
            onApply(inputValue);
          } else if (
            selectValue !== '' &&
            selectValue !== ENTER_MANUALLY_OPTION_VALUE
          ) {
            onApply(selectValue);
          } else {
            onApply(undefined);
          }
        }}
      />
    </>
  );
}

interface IProps {
  initialValue: string | undefined;
  onApply: (newValue: string | undefined | null) => void;
}

export function WagonArrivalsDistanceFilter({ initialValue, onApply }: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ButtonGroup>
      <Popover
        content={
          <WagonArrivalsDistanceFilterPopoverContent
            initialValue={initialValue}
            onApply={newValue => {
              onApply(newValue);
              setIsOpen(false);
            }}
          />
        }
        isOpen={isOpen}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        position={Position.BOTTOM}
        onClose={() => {
          setIsOpen(false);
        }}
        onInteraction={setIsOpen}
      >
        <Button
          icon="filter"
          intent={initialValue !== undefined ? Intent.DANGER : undefined}
          text="Расстояние до станции назначения"
        />
      </Popover>

      {initialValue !== undefined && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => {
            onApply(undefined);
          }}
        />
      )}
    </ButtonGroup>
  );
}

import { Toolbar } from '_core/toolbar';
import { Button, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useEffect, useLayoutEffect, useState } from 'react';

export default function TestSentryRoute() {
  const [throwInRender, setThrowInRender] = useState(false);
  if (throwInRender) {
    throw new Error('thrown from render');
  }

  const [throwInUseEffect, setThrowInUseEffect] = useState(false);
  useEffect(() => {
    if (throwInUseEffect) {
      throw new Error('thrown from useEffect');
    }
  }, [throwInUseEffect]);

  const [throwInUseLayoutEffect, setThrowInUseLayoutEffect] = useState(false);
  useLayoutEffect(() => {
    if (throwInUseLayoutEffect) {
      throw new Error('thrown from useLayoutEffect');
    }
  }, [throwInUseLayoutEffect]);

  return (
    <Toolbar>
      <Button
        intent={Intent.DANGER}
        text="Ошибка рендеринга"
        onClick={() => {
          setThrowInRender(true);
        }}
      />

      <Button
        intent={Intent.DANGER}
        text="Ошибка в useEffect"
        onClick={() => {
          setThrowInUseEffect(true);
        }}
      />

      <Button
        intent={Intent.DANGER}
        text="Ошибка в useLayoutEffect"
        onClick={() => {
          setThrowInUseLayoutEffect(true);
        }}
      />

      <Button
        intent={Intent.DANGER}
        text="Promise.reject"
        onClick={() => {
          Promise.reject(new Error('unhandledrejection'));
        }}
      />
    </Toolbar>
  );
}

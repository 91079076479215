import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { NonIdealState } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import dayjs from 'dayjs';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { RentWagonSellPauseCause } from 'rentWagonSell/api';
import { rentWagonSellPauseCauseLabel } from 'rentWagonSell/utils';

import { BooleanIcon } from '../../../_core/booleanIcon';
import {
  DATE_FORMAT_DATE,
  DATE_FORMAT_DATETIME,
} from '../../../_core/dates/formats';
import { Ellipsis } from '../../../_core/strings/ellipsis';
import { formatUserName } from '../../../accounts/formatUserName';
import { IUser } from '../../../accounts/types';
import * as css from '../../weighing/index.module.css';
import * as styles from '../pauseRegion.module.css';

export interface IRentWagonSellFormPause {
  cause: RentWagonSellPauseCause;
  endDate: Date | null;
  note: string;
  startDate: Date;
  isCharging: boolean;
  duration: number | null;
  authorModified: IUser | null;
  modified: Date | null;
}

interface IPauseWithIndex extends IRentWagonSellFormPause {
  index: number;
}

interface IProps {
  name: string;
}

export function RentWagonSellPausesField({ name }: IProps) {
  const { fields, meta } = useFieldArray<IRentWagonSellFormPause>(name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isDisabled = (meta as any).submitting as boolean;

  const columns = useMemo(
    (): Array<ListTableColumn<IPauseWithIndex>> => [
      {
        id: 'startDate',
        label: 'Дата начала',
        defaultWidth: 130,
        renderCell: ({ children, style }) => (
          <BaseCell style={style}>{children}</BaseCell>
        ),
        renderCellContent: pause => (
          <Ellipsis component="span">
            {dayjs(pause.startDate).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'endDate',
        label: 'Дата окончания',
        defaultWidth: 130,
        renderCell: ({ children, style }) => (
          <BaseCell style={style}>{children}</BaseCell>
        ),
        renderCellContent: pause => (
          <Ellipsis component="span">
            {dayjs(pause.endDate).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'duration',
        label: 'Суток',
        defaultWidth: 55,

        renderCell: ({ children, style }) => (
          <BaseCell style={style}>{children}</BaseCell>
        ),
        renderCellContent: pause => (
          <Ellipsis component="span">{pause.duration?.toString()}</Ellipsis>
        ),
      },
      {
        id: 'isCharging',
        label: 'Начисляется',
        defaultWidth: 110,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: pause => (
          <div className={css.cellCenterContainer}>
            <BooleanIcon value={pause.isCharging} />
          </div>
        ),
      },
      {
        id: 'cause',
        label: 'Причина',
        defaultWidth: 140,
        renderCell: ({ children, style }) => (
          <BaseCell style={style}>{children}</BaseCell>
        ),
        renderCellContent: pause => (
          <Ellipsis component="span">
            {rentWagonSellPauseCauseLabel(pause.cause)}
          </Ellipsis>
        ),
      },
      {
        id: 'note',
        label: 'Комментарий',
        defaultWidth: 155,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),

        renderCellContent: (_pause, rowIndex) => (
          <InputGroupForFinalForm
            id={`${fields.name}[${rowIndex}].note`}
            name={`${fields.name}[${rowIndex}].note`}
            disabled={isDisabled}
            intent={Intent.NONE}
            className={styles.input}
          />
        ),
      },
      {
        id: 'modified',
        label: 'Изменено',
        defaultWidth: 120,
        renderCell: ({ children, style }) => (
          <BaseCell style={style}>{children}</BaseCell>
        ),
        renderCellContent: pause =>
          dayjs(pause.modified).format(DATE_FORMAT_DATETIME),
      },
      {
        id: 'authorModified',
        label: 'Автор',
        defaultWidth: 154,
        renderCell: ({ children, style }) => (
          <BaseCell style={style}>{children}</BaseCell>
        ),
        renderCellContent: pause => (
          <Ellipsis component="span">
            {pause.authorModified == null
              ? ''
              : formatUserName(pause.authorModified)}
          </Ellipsis>
        ),
      },
    ],
    [fields.name, isDisabled]
  );

  const getItemId = useCallback(
    (pause: IPauseWithIndex) => String(pause.index),
    []
  );

  const items = useMemo(
    () =>
      fields.value.map(
        (props, index): IPauseWithIndex => ({ ...props, index })
      ),
    [fields]
  );

  return items.length === 0 ? (
    <NonIdealState icon="list" description="Нет пауз" />
  ) : (
    <ListTable columns={columns} getItemId={getItemId} items={items} />
  );
}

import { identity } from '_core/fp/identity';
import {
  Button,
  InputGroup,
  InputGroupProps2,
  Intent,
} from '@blueprintjs/core';
import * as React from 'react';
import { useField } from 'react-final-form';

type Props = Omit<InputGroupProps2, 'rightElement' | 'type'>;

export function InputPassword(props: Props) {
  const [shouldShowPassword, setShouldShowPassword] = React.useState(false);

  return (
    <InputGroup
      {...props}
      rightElement={
        <Button
          icon={shouldShowPassword ? 'eye-off' : 'eye-open'}
          minimal
          onClick={() => {
            setShouldShowPassword(b => !b);
          }}
        />
      }
      type={shouldShowPassword ? 'text' : 'password'}
    />
  );
}

interface ForFinalFormProps extends Omit<Props, 'value' | 'onChange'> {
  name: string;
  onChange?: (newValue: string) => void;
}

export function InputPasswordForFinalForm({
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  ...otherProps
}: ForFinalFormProps) {
  const { input, meta } = useField<string>(name, {
    // this identity fn is needed because by default final-form removes field
    // key completely when it's set to an empty string
    parse: identity,
  });

  return (
    <InputPassword
      disabled={meta.submitting || disabled}
      intent={
        meta.touched && (meta.error || meta.submitError)
          ? Intent.DANGER
          : undefined
      }
      name={input.name}
      onBlur={event => {
        input.onBlur(event);
        onBlur?.(event);
      }}
      onChange={event => {
        const newValue = event.currentTarget.value;
        input.onChange(newValue);
        onChange?.(newValue);
      }}
      onFocus={event => {
        input.onFocus(event);
        onFocus?.(event);
      }}
      value={input.value}
      {...otherProps}
    />
  );
}

import { identity } from '_core/fp/identity';
import {
  Button,
  ButtonGroup,
  IconName,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import * as React from 'react';

function FilterInPopoverRaw({
  buttonIconName,
  buttonLabel,
  children,
  disabled,
  hasInitialValue,
  isOpen,
  onApply,
  onClear,
  onClose,
  onInteraction,
}: {
  buttonIconName?: IconName;
  buttonLabel?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  hasInitialValue?: boolean;
  isOpen?: boolean;
  onApply: () => void;
  onClear: () => void;
  onClose?: () => void;
  onInteraction: (nextOpenState: boolean) => void;
}) {
  return (
    <ButtonGroup>
      <Popover
        content={
          <div>
            {children}

            <Button
              fill
              intent={Intent.PRIMARY}
              text="Применить"
              onClick={onApply}
            />
          </div>
        }
        disabled={disabled}
        // NOTE: hasBackdrop is needed for correct behavior when rendering to
        // portal from popover children. For example, Autocomplete renders
        // dropdown through portal and we don't want popover to close when
        // clicking on it's option. See
        // http://blueprintjs.com/docs/#core/components/popover for more info
        // on this option
        hasBackdrop
        isOpen={isOpen}
        position={Position.BOTTOM}
        onClose={onClose}
        onInteraction={onInteraction}
      >
        <Button
          disabled={disabled}
          icon={buttonIconName}
          intent={hasInitialValue ? Intent.DANGER : undefined}
          text={buttonLabel}
        />
      </Popover>

      {hasInitialValue && (
        <Button
          disabled={disabled}
          icon="cross"
          intent={Intent.DANGER}
          onClick={onClear}
        />
      )}
    </ButtonGroup>
  );
}

interface IFullProps<TValue, TInternalValue, TProps> {
  clearValue: TValue;
  disabled: boolean;
  extractInitialValue: (
    props: Pick<IFullProps<TValue, TInternalValue, TProps>, 'initialValue'> &
      Partial<
        Pick<
          IFullProps<TValue, TInternalValue, TProps>,
          'clearValue' | 'disabled'
        >
      > & { isOpen: boolean } & TProps
  ) => TInternalValue;
  formatButtonLabel: (
    props: Pick<IFullProps<TValue, TInternalValue, TProps>, 'initialValue'> &
      Partial<Pick<IFullProps<TValue, TInternalValue, TProps>, 'disabled'>> &
      TProps
  ) => string | null | undefined;
  hasInitialValue: (initialValue: TValue) => boolean;
  iconName: IconName;
  initialValue: TValue;
  label: string;
  transformOutputValue: (value: TInternalValue) => TValue;
  onApply: (value: TValue) => void;
}

interface IDeprecatedFilterInPopoverInjectedProps<TInternalValue> {
  value: TInternalValue;
  onChange: (newValue: TInternalValue) => void;
}

type IProps<TValue, TInternalValue, TProps> = Pick<
  IFullProps<TValue, TInternalValue, TProps>,
  'initialValue' | 'onApply'
> &
  Partial<
    Pick<
      IFullProps<TValue, TInternalValue, TProps>,
      | 'disabled'
      | 'extractInitialValue'
      | 'hasInitialValue'
      | 'iconName'
      | 'label'
      | 'transformOutputValue'
    >
  >;

interface IState<TInternalValue> {
  isOpen: boolean;
  value: TInternalValue;
}

type ConfigProps<TValue, TInternalValue, TProps> = Pick<
  IFullProps<TValue, TInternalValue, TProps>,
  'clearValue'
> &
  Partial<
    Pick<
      IFullProps<TValue, TInternalValue, TProps>,
      | 'extractInitialValue'
      | 'formatButtonLabel'
      | 'hasInitialValue'
      | 'iconName'
      | 'initialValue'
      | 'label'
      | 'transformOutputValue'
    >
  >;

/**
 * @deprecated better just write this manually for each case
 */
export function deprecatedFilterInPopover<
  TValue,
  TProps,
  TInternalValue = TValue
>(configProps: ConfigProps<TValue, TInternalValue, TProps>) {
  type DefaultProps = Pick<
    IFullProps<TValue, TInternalValue, TProps>,
    | 'extractInitialValue'
    | 'formatButtonLabel'
    | 'iconName'
    | 'hasInitialValue'
    | 'transformOutputValue'
  > &
    ConfigProps<TValue, TInternalValue, TProps>;

  type RealProps = IProps<TValue, TInternalValue, TProps> & DefaultProps;

  return (
    Filter: React.ComponentType<
      TProps & IDeprecatedFilterInPopoverInjectedProps<TInternalValue>
    >
  ) =>
    class FilterInPopover extends React.Component<
      TProps & IProps<TValue, TInternalValue, TProps>,
      IState<TInternalValue>
    > {
      static defaultProps: DefaultProps = {
        extractInitialValue: ({ initialValue }) => initialValue,
        formatButtonLabel: () => undefined,
        hasInitialValue: Boolean,
        transformOutputValue: identity,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(configProps as any),
      };

      constructor(props: TProps & IProps<TValue, TInternalValue, TProps>) {
        super(props);

        const {
          extractInitialValue,
          formatButtonLabel,
          hasInitialValue,
          iconName,
          label,
          transformOutputValue,
          onApply,
          ...otherProps
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = this.props as any as RealProps;

        const isOpen = false;

        const value = extractInitialValue(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.assign({ isOpen }, otherProps as any)
        );

        this.state = {
          isOpen,
          value,
        };
      }

      private readonly handleInteraction = (nextOpenState: boolean) => {
        const {
          extractInitialValue,
          formatButtonLabel,
          hasInitialValue,
          iconName,
          label,
          transformOutputValue,
          onApply,
          ...otherProps
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = this.props as any as RealProps;

        const { isOpen } = this.state;

        if (nextOpenState) {
          this.setState({
            isOpen: true,
            value: extractInitialValue(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              Object.assign({ isOpen }, otherProps as any)
            ),
          });
        } else {
          this.setState({ isOpen: false });
        }
      };

      render() {
        const {
          clearValue,
          disabled,
          extractInitialValue,
          formatButtonLabel,
          hasInitialValue: hasInitialValueFn,
          iconName,
          initialValue,
          label,
          transformOutputValue,
          onApply: onApplyProp,
          ...otherProps
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = this.props as any as RealProps;

        const { isOpen, value } = this.state;

        const formattedString = formatButtonLabel(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.assign({ initialValue }, otherProps as any)
        );

        const buttonLabel = formattedString
          ? [label, formattedString].join(': ')
          : label;

        const hasInitialValue = hasInitialValueFn(initialValue);

        const onApply = () => {
          onApplyProp(transformOutputValue(value));
          this.setState({ isOpen: false });
        };

        const onChange = (newValue: TInternalValue) => {
          this.setState({ value: newValue });
        };

        const onClear = () => {
          this.setState({ isOpen: false });
          onApplyProp(clearValue);
        };

        return (
          <FilterInPopoverRaw
            buttonIconName={iconName}
            buttonLabel={buttonLabel}
            disabled={disabled}
            hasInitialValue={hasInitialValue}
            isOpen={isOpen}
            onApply={onApply}
            onClear={onClear}
            onInteraction={this.handleInteraction}
          >
            <Filter
              {...(otherProps as TProps)}
              value={value}
              onChange={onChange}
            />
          </FilterInPopoverRaw>
        );
      }
    };
}

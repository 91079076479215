import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { createRepair, RepairType } from 'repairs/api';

import { OurRepairsForm } from './form';

export default function OurRepairsCreateRoute() {
  const { route, router } = useRouteNode('ourRepairs.create');
  const navigationLock = useFormNavigationLock('ourRepairs.create');
  const api = useApiClient();

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const title = 'Новый ремонт';

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось создать ремонт: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Наши ремонты"
              params={listParams}
              to="ourRepairs"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <OurRepairsForm
            initialValues={{
              corrArrivedForLoadDate: null,
              corrSendedFromRepairDate: null,
              corrSendedToRepairDate: null,
              corrVu23Date: null,
              corrVu36Date: null,
              dontBill: false,
              dontBillCause: '',
              endDate: null,
              incomeRate: '',
              parts: [],
              purchaseFiles: [],
              repairStation: null,
              sellFiles: [],
              services: [],
              startDate: null,
              type: RepairType.Current,
              wagon: '',
            }}
            navigationLock={navigationLock}
            onSave={async values => {
              try {
                await createRepair(api, values);
                navigationLock.unlock();
                router.navigate('ourRepairs', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const createdRepair = await createRepair(api, values);
                navigationLock.unlock();
                router.navigate('ourRepairs.edit', {
                  id: createdRepair.id,
                  listParams: route.params.listParams,
                });
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

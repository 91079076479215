import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { serializable } from '_core/serializable';

import { IStationSerialized } from './types';

interface IStation extends Omit<IStationSerialized, 'modifiedAt'> {
  modifiedAt: Date | null;
}

@serializable
export class Station implements IStation {
  readonly capacityPp;
  readonly code;
  readonly extraInfo;
  readonly goGp;
  readonly goGpShift;
  readonly id;
  readonly loadingFront;
  readonly locoAffiliation;
  readonly locoOnStation;
  readonly maxWagons;
  @serializable.datetime readonly modifiedAt;
  readonly modifiedBy;
  readonly name;
  readonly note;
  readonly phone;
  readonly roadName;
  readonly rzdShift;

  static fromJSON(serialized: IStationSerialized) {
    return serializable.fromJSON(Station, serialized);
  }

  constructor(station: IStation) {
    this.capacityPp = station.capacityPp;
    this.code = station.code;
    this.extraInfo = station.extraInfo;
    this.goGp = station.goGp;
    this.goGpShift = station.goGpShift;
    this.id = station.id;
    this.loadingFront = station.loadingFront;
    this.locoAffiliation = station.locoAffiliation;
    this.locoOnStation = station.locoOnStation;
    this.maxWagons = station.maxWagons;
    this.modifiedAt = station.modifiedAt;
    this.modifiedBy = station.modifiedBy;
    this.name = station.name;
    this.note = station.note;
    this.phone = station.phone;
    this.roadName = station.roadName;
    this.rzdShift = station.rzdShift;
  }
}

interface IFetchStationsParams {
  hasData?: boolean;
  ids?: number[];
  page?: number;
  pageSize?: number;
  roads?: number[];
  search?: string;
}

export async function fetchStations(
  api: ApiClient,
  { hasData, ids, page, pageSize, roads, search }: IFetchStationsParams = {}
): Promise<ListResponse<Station>> {
  const response = await api.get<ListResponse<IStationSerialized>>(
    '/directories_trainstations',
    {
      hasData,
      ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
      page,
      pageSize,
      roads: roads && roads.length !== 0 ? roads.join(',') : undefined,
      search,
    }
  );

  return {
    ...response,
    results: response.results.map(Station.fromJSON),
  };
}

export const fetchManyStations = makeFetchMany(fetchStations, 'ids');

export async function fetchStation(api: ApiClient, id: number) {
  const stations = await fetchManyStations(api, [id]);

  return stations[0];
}

export type UpdateStationInput = Pick<
  IStation,
  | 'capacityPp'
  | 'extraInfo'
  | 'goGp'
  | 'goGpShift'
  | 'loadingFront'
  | 'locoAffiliation'
  | 'locoOnStation'
  | 'maxWagons'
  | 'note'
  | 'phone'
  | 'rzdShift'
>;

export async function updateStation(
  api: ApiClient,
  id: number,
  input: UpdateStationInput
) {
  const updatedStation = await api.put<IStationSerialized>(
    `/directories_trainstations/${id}`,
    input
  );

  return Station.fromJSON(updatedStation);
}

export function fetchRzdShifts(
  api: ApiClient,
  { pageSize, search }: { pageSize: number; search: string }
) {
  return api.get<{ rzdShift: string[] }>(
    '/directories_trainstations/rzd_shift',
    { pageSize, search }
  );
}

import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_API_DATE, DATE_FORMAT_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { DateRangeWithMonthSelectFilter } from '_core/filters/dateRangeWithMonthSelect';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { BaseCell } from '_core/react-window/cells';
import { TreeTable, TreeTableColumn } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import {
  everyNode,
  findNode,
  setAllNodesIsExpanded,
} from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button } from '@blueprintjs/core';
import dayjs from 'dayjs';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import {
  fetchWagonsTrackingStats,
  IWagonsTrackingStatsExpeditionPartnerRecord,
  IWagonsTrackingStatsExpeditionRecord,
  IWagonsTrackingStatsParkRecord,
  IWagonsTrackingStatsRentPartnerRecord,
  IWagonsTrackingStatsRentPurchaseRecord,
  IWagonsTrackingStatsRentSellRecord,
  IWagonsTrackingStatsTechrunPartnerRecord,
  IWagonsTrackingStatsTechrunRecord,
  IWagonsTrackingStatsWagonCount,
  WagonsTrackingStatsRecord,
  WagonsTrackingStatsRecordType,
} from 'wagons/api';

import * as css from './list.module.css';

type WagonsTrackingTableNodeData =
  | {
      kind: 'rentTotal';
      childCount: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'rentPartner';
      displayName: string;
      childCount: number;
      partnerId: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'rentProtocolPurchase';
      displayName: string;
      partnerId: number;
      protocolId: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'rentProtocolSell';
      displayName: string;
      partnerId: number;
      protocolId: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'expeditionTotal';
      childCount: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'expeditionPartner';
      displayName: string;
      childCount: number;
      partnerId: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'expeditionRequest';
      displayName: string;
      requestId: number;
      partnerId: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'techrunTotal';
      childCount: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'techrunPartner';
      displayName: string;
      partnerId: number;
      childCount: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'techrunRequest';
      displayName: string;
      requestId: number;
      partnerId: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'parkTotal';
      childCount: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'park';
      displayName: string;
      parkId: number;
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    }
  | {
      kind: 'grandTotal';
      wagonCounts: IWagonsTrackingStatsWagonCount[];
    };

export default function WagonsTrackingRoute() {
  const { route, router } = useRouteNode('wagonsTracking');
  const api = useApiClient();

  const dateRangeFilterValue = useMemo(
    () => ({
      startDate:
        route.params.fromDate == null
          ? dayjs().startOf('month').toDate()
          : parseDate(route.params.fromDate),
      endDate:
        route.params.toDate == null
          ? dayjs().startOf('day').toDate()
          : parseDate(route.params.toDate),
    }),
    [route.params.fromDate, route.params.toDate]
  );

  const queryParams = useMemo(
    () => ({
      fromDate: dateRangeFilterValue.startDate,
      toDate: dateRangeFilterValue.endDate,
    }),
    [dateRangeFilterValue.endDate, dateRangeFilterValue.startDate]
  );

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [api, queryParams],
    async () => {
      const stats = await fetchWagonsTrackingStats(api, {
        fromDate: queryParams.fromDate,
        toDate: queryParams.toDate,
      });

      const nodes: Array<TreeTableNode<WagonsTrackingTableNodeData>> = [];

      const currentWagonCountCompareFn = (
        recA: WagonsTrackingStatsRecord,
        recB: WagonsTrackingStatsRecord
      ) => {
        const a = recA.wagonCounts[recA.wagonCounts.length - 1]?.count ?? 0;
        const b = recB.wagonCounts[recB.wagonCounts.length - 1]?.count ?? 0;

        return b - a;
      };

      const rentTotalRecord = stats.results.find(
        record => record.recordType === WagonsTrackingStatsRecordType.RentTotal
      );

      if (rentTotalRecord) {
        const prevRentTotalNode = data
          ? findNode(data.nodes, node => node.data.kind === 'rentTotal')
          : undefined;

        const partnerRecords = stats.results.filter(
          (record): record is IWagonsTrackingStatsRentPartnerRecord =>
            record.recordType === WagonsTrackingStatsRecordType.RentPartner
        );

        nodes.push({
          id: 'rentTotal',
          data: {
            kind: 'rentTotal',
            childCount: partnerRecords.length,
            wagonCounts: rentTotalRecord.wagonCounts,
          },
          children: {
            isExpanded:
              prevRentTotalNode && prevRentTotalNode.children
                ? prevRentTotalNode.children.isExpanded
                : false,
            nodes: partnerRecords
              .sort(currentWagonCountCompareFn)
              .map(
                (
                  rentPartnerRecord
                ): TreeTableNode<WagonsTrackingTableNodeData> => {
                  const prevRentPartnerNode = data
                    ? findNode(
                        data.nodes,
                        node =>
                          node.data.kind === 'rentPartner' &&
                          node.data.partnerId === rentPartnerRecord.partnerId
                      )
                    : undefined;

                  const protocolRecords = stats.results.filter(
                    (
                      record
                    ): record is
                      | IWagonsTrackingStatsRentPurchaseRecord
                      | IWagonsTrackingStatsRentSellRecord =>
                      record.recordType ===
                        WagonsTrackingStatsRecordType.Rent &&
                      record.partnerId === rentPartnerRecord.partnerId
                  );

                  return {
                    id: `rentPartner-${rentPartnerRecord.partnerId}`,
                    data: {
                      kind: 'rentPartner',
                      displayName: rentPartnerRecord.displayName,
                      childCount: protocolRecords.length,
                      partnerId: rentPartnerRecord.partnerId,
                      wagonCounts: rentPartnerRecord.wagonCounts,
                    },
                    children: {
                      isExpanded:
                        prevRentPartnerNode && prevRentPartnerNode.children
                          ? prevRentPartnerNode.children.isExpanded
                          : false,
                      nodes: protocolRecords
                        .sort(currentWagonCountCompareFn)
                        .map(
                          (
                            rentRecord
                          ): TreeTableNode<WagonsTrackingTableNodeData> => {
                            if (rentRecord.purchaseRentProtocolId != null) {
                              return {
                                id: `rentProtocolPurchase-${rentRecord.purchaseRentProtocolId}`,
                                data: {
                                  kind: 'rentProtocolPurchase',
                                  displayName: rentRecord.displayName,
                                  partnerId: rentRecord.partnerId,
                                  protocolId: rentRecord.purchaseRentProtocolId,
                                  wagonCounts: rentRecord.wagonCounts,
                                },
                              };
                            }

                            return {
                              id: `rentProtocolSell-${rentRecord.sellRentProtocolId}`,
                              data: {
                                kind: 'rentProtocolSell',
                                displayName: rentRecord.displayName,
                                partnerId: rentRecord.partnerId,
                                protocolId: rentRecord.sellRentProtocolId,
                                wagonCounts: rentRecord.wagonCounts,
                              },
                            };
                          }
                        ),
                    },
                  };
                }
              ),
          },
        });
      }

      const expeditionTotalRecord = stats.results.find(
        record =>
          record.recordType === WagonsTrackingStatsRecordType.ExpeditionTotal
      );

      if (expeditionTotalRecord) {
        const prevExpeditionTotalNode = data
          ? findNode(data.nodes, node => node.data.kind === 'expeditionTotal')
          : undefined;

        const partnerRecords = stats.results.filter(
          (record): record is IWagonsTrackingStatsExpeditionPartnerRecord =>
            record.recordType ===
            WagonsTrackingStatsRecordType.ExpeditionPartner
        );

        nodes.push({
          id: 'expeditionTotal',
          data: {
            kind: 'expeditionTotal',
            childCount: partnerRecords.length,
            wagonCounts: expeditionTotalRecord.wagonCounts,
          },
          children: {
            isExpanded:
              prevExpeditionTotalNode && prevExpeditionTotalNode.children
                ? prevExpeditionTotalNode.children.isExpanded
                : false,
            nodes: partnerRecords
              .sort(currentWagonCountCompareFn)
              .map(
                (
                  expeditionPartnerRecord
                ): TreeTableNode<WagonsTrackingTableNodeData> => {
                  const prevExpeditionPartnerNode = data
                    ? findNode(
                        data.nodes,
                        node =>
                          node.data.kind === 'expeditionPartner' &&
                          node.data.partnerId ===
                            expeditionPartnerRecord.partnerId
                      )
                    : undefined;

                  const requestRecords = stats.results.filter(
                    (record): record is IWagonsTrackingStatsExpeditionRecord =>
                      record.recordType ===
                        WagonsTrackingStatsRecordType.Expedition &&
                      record.partnerId === expeditionPartnerRecord.partnerId
                  );

                  return {
                    id: `expeditionPartner-${expeditionPartnerRecord.partnerId}`,
                    data: {
                      kind: 'expeditionPartner',
                      displayName: expeditionPartnerRecord.displayName,
                      childCount: requestRecords.length,
                      partnerId: expeditionPartnerRecord.partnerId,
                      wagonCounts: expeditionPartnerRecord.wagonCounts,
                    },
                    children: {
                      isExpanded:
                        prevExpeditionPartnerNode &&
                        prevExpeditionPartnerNode.children
                          ? prevExpeditionPartnerNode.children.isExpanded
                          : false,
                      nodes: requestRecords
                        .sort(currentWagonCountCompareFn)
                        .map(
                          (
                            expeditionRequestRecord
                          ): TreeTableNode<WagonsTrackingTableNodeData> => ({
                            id: `expeditionRequest-${expeditionRequestRecord.expeditionRequestId}`,
                            data: {
                              kind: 'expeditionRequest',
                              displayName: expeditionRequestRecord.displayName,
                              partnerId: expeditionRequestRecord.partnerId,
                              requestId:
                                expeditionRequestRecord.expeditionRequestId,
                              wagonCounts: expeditionRequestRecord.wagonCounts,
                            },
                          })
                        ),
                    },
                  };
                }
              ),
          },
        });
      }

      const techrunTotalRecord = stats.results.find(
        record =>
          record.recordType === WagonsTrackingStatsRecordType.TechrunTotal
      );

      if (techrunTotalRecord) {
        const prevTechrunTotalNode = data
          ? findNode(data.nodes, node => node.data.kind === 'techrunTotal')
          : undefined;

        const partnerRecords = stats.results.filter(
          (record): record is IWagonsTrackingStatsTechrunPartnerRecord =>
            record.recordType === WagonsTrackingStatsRecordType.TechrunPartner
        );

        nodes.push({
          id: 'techrunTotal',
          data: {
            kind: 'techrunTotal',
            childCount: partnerRecords.length,
            wagonCounts: techrunTotalRecord.wagonCounts,
          },
          children: {
            isExpanded:
              prevTechrunTotalNode && prevTechrunTotalNode.children
                ? prevTechrunTotalNode.children.isExpanded
                : false,
            nodes: partnerRecords
              .sort(currentWagonCountCompareFn)
              .map(
                (
                  techrunPartnerRecord
                ): TreeTableNode<WagonsTrackingTableNodeData> => {
                  const prevTechrunPartnerNode = data
                    ? findNode(
                        data.nodes,
                        node =>
                          node.data.kind === 'techrunPartner' &&
                          node.data.partnerId === techrunPartnerRecord.partnerId
                      )
                    : undefined;

                  const requestRecords = stats.results.filter(
                    (record): record is IWagonsTrackingStatsTechrunRecord =>
                      record.recordType ===
                        WagonsTrackingStatsRecordType.Techrun &&
                      record.partnerId === techrunPartnerRecord.partnerId
                  );

                  return {
                    id: `techrunPartner-${techrunPartnerRecord.partnerId}`,
                    data: {
                      kind: 'techrunPartner',
                      displayName: techrunPartnerRecord.displayName,
                      childCount: requestRecords.length,
                      partnerId: techrunPartnerRecord.partnerId,
                      wagonCounts: techrunPartnerRecord.wagonCounts,
                    },
                    children: {
                      isExpanded:
                        prevTechrunPartnerNode &&
                        prevTechrunPartnerNode.children
                          ? prevTechrunPartnerNode.children.isExpanded
                          : false,
                      nodes: requestRecords
                        .sort(currentWagonCountCompareFn)
                        .map(
                          (
                            techrunRequestRecord
                          ): TreeTableNode<WagonsTrackingTableNodeData> => ({
                            id: `techrunRequest-${techrunRequestRecord.techrunRequestId}`,
                            data: {
                              kind: 'techrunRequest',
                              displayName: techrunRequestRecord.displayName,
                              partnerId: techrunRequestRecord.partnerId,
                              requestId: techrunRequestRecord.techrunRequestId,
                              wagonCounts: techrunRequestRecord.wagonCounts,
                            },
                          })
                        ),
                    },
                  };
                }
              ),
          },
        });
      }

      const parkTotalRecord = stats.results.find(
        record => record.recordType === WagonsTrackingStatsRecordType.ParkTotal
      );

      if (parkTotalRecord) {
        const prevParkTotalNode = data
          ? findNode(data.nodes, node => node.data.kind === 'parkTotal')
          : undefined;

        const parkRecords = stats.results.filter(
          (record): record is IWagonsTrackingStatsParkRecord =>
            record.recordType === WagonsTrackingStatsRecordType.Park
        );

        nodes.push({
          id: 'parkTotal',
          data: {
            kind: 'parkTotal',
            childCount: parkRecords.length,
            wagonCounts: parkTotalRecord.wagonCounts,
          },
          children: {
            isExpanded:
              prevParkTotalNode && prevParkTotalNode.children
                ? prevParkTotalNode.children.isExpanded
                : false,
            nodes: parkRecords.sort(currentWagonCountCompareFn).map(
              (parkRecord): TreeTableNode<WagonsTrackingTableNodeData> => ({
                id: `park-${parkRecord.parkId}`,
                data: {
                  kind: 'park',
                  displayName: parkRecord.displayName,
                  parkId: parkRecord.parkId,
                  wagonCounts: parkRecord.wagonCounts,
                },
              })
            ),
          },
        });
      }

      const grandTotalRecord = stats.results.find(
        record => record.recordType === WagonsTrackingStatsRecordType.GrandTotal
      );

      if (grandTotalRecord) {
        nodes.push({
          id: 'grandTotal',
          data: {
            kind: 'grandTotal',
            wagonCounts: grandTotalRecord.wagonCounts,
          },
        });
      }

      const firstRecord = stats.results[0];

      const dates = firstRecord
        ? firstRecord.wagonCounts.map(wagonCount => wagonCount.date)
        : [];

      return {
        dates,
        meta: stats.meta,
        nodes,
      };
    }
  );

  const handleNodesChange = useCallback(
    (
      newNodesOrUpdater:
        | Array<TreeTableNode<WagonsTrackingTableNodeData>>
        | ((
            prevNodes: Array<TreeTableNode<WagonsTrackingTableNodeData>>
          ) => Array<TreeTableNode<WagonsTrackingTableNodeData>>)
    ) => {
      updateData(
        prevData =>
          prevData && {
            ...prevData,
            nodes:
              typeof newNodesOrUpdater === 'function'
                ? newNodesOrUpdater(prevData.nodes)
                : newNodesOrUpdater,
          }
      );
    },
    [updateData]
  );

  const columns = useMemo(() => {
    const staticColumns: Array<TreeTableColumn<WagonsTrackingTableNodeData>> = [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 450,
        expandable: true,
        copyCellContent: node => {
          switch (node.data.kind) {
            case 'rentTotal': {
              const { childCount } = node.data;

              return `Аренда (${childCount})`;
            }
            case 'expeditionTotal': {
              const { childCount } = node.data;

              return `Экспедирование (${childCount})`;
            }
            case 'techrunTotal': {
              const { childCount } = node.data;

              return `Техрейс (${childCount})`;
            }
            case 'parkTotal': {
              const { childCount } = node.data;

              return `Парки (${childCount})`;
            }
            case 'grandTotal':
              return 'Общий итог';
            case 'rentPartner':
            case 'expeditionPartner':
            case 'techrunPartner': {
              const { childCount } = node.data;

              return `${node.data.displayName} (${childCount})`;
            }
            case 'rentProtocolPurchase':
            case 'rentProtocolSell':
            case 'expeditionRequest':
            case 'techrunRequest':
            case 'park':
              return node.data.displayName;
          }
        },
        renderCellContent: node => {
          switch (node.data.kind) {
            case 'rentTotal': {
              const { childCount } = node.data;

              return (
                <Ellipsis
                  className={css.boldCellText}
                  component="span"
                >{`Аренда (${childCount})`}</Ellipsis>
              );
            }
            case 'rentPartner': {
              const { displayName, childCount, partnerId } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: String(partnerId) }}
                  rel="noopener"
                  target="_blank"
                  to="partners.edit"
                >
                  {`${displayName} (${childCount})`}
                </Ellipsis>
              );
            }
            case 'rentProtocolPurchase': {
              const { displayName, protocolId } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ expandProtocol: String(protocolId) }}
                  rel="noopener"
                  target="_blank"
                  to="rentWagons.purchase"
                >
                  {displayName}
                </Ellipsis>
              );
            }
            case 'rentProtocolSell': {
              const { displayName, protocolId } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ expandProtocol: String(protocolId) }}
                  rel="noopener"
                  target="_blank"
                  to="rentWagons.sell"
                >
                  {displayName}
                </Ellipsis>
              );
            }
            case 'expeditionTotal': {
              const { childCount } = node.data;

              return (
                <Ellipsis
                  className={css.boldCellText}
                  component="span"
                >{`Экспедирование (${childCount})`}</Ellipsis>
              );
            }
            case 'expeditionPartner': {
              const { displayName, childCount, partnerId } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: String(partnerId) }}
                  rel="noopener"
                  target="_blank"
                  to="partners.edit"
                >
                  {`${displayName} (${childCount})`}
                </Ellipsis>
              );
            }
            case 'expeditionRequest': {
              const { displayName, requestId } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: String(requestId) }}
                  rel="noopener"
                  target="_blank"
                  to="expeditions.requests.view"
                >
                  {displayName}
                </Ellipsis>
              );
            }
            case 'techrunTotal': {
              const { childCount } = node.data;

              return (
                <Ellipsis
                  className={css.boldCellText}
                  component="span"
                >{`Техрейс (${childCount})`}</Ellipsis>
              );
            }
            case 'techrunPartner': {
              const { displayName, childCount, partnerId } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: String(partnerId) }}
                  rel="noopener"
                  target="_blank"
                  to="partners.edit"
                >
                  {`${displayName} (${childCount})`}
                </Ellipsis>
              );
            }
            case 'techrunRequest': {
              const { displayName, requestId } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: String(requestId) }}
                  rel="noopener"
                  target="_blank"
                  to="techruns.requests.view"
                >
                  {displayName}
                </Ellipsis>
              );
            }
            case 'parkTotal': {
              const { childCount } = node.data;

              return (
                <Ellipsis
                  className={css.boldCellText}
                  component="span"
                >{`Парки (${childCount})`}</Ellipsis>
              );
            }
            case 'park': {
              const { displayName, parkId } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: String(parkId) }}
                  rel="noopener"
                  target="_blank"
                  to="parks.edit"
                >
                  {displayName}
                </Ellipsis>
              );
            }
            case 'grandTotal':
              return (
                <Ellipsis className={css.boldCellText} component="span">
                  Общий итог
                </Ellipsis>
              );
          }
        },
      },
    ];

    return staticColumns.concat(
      data
        ? data.dates.map((date, index) => ({
            id: `date-${dayjs(date).format(DATE_FORMAT_API_DATE)}`,
            label: dayjs(date).format(DATE_FORMAT_DATE),
            defaultWidth: 80,
            copyCellContent: node =>
              node.data.wagonCounts[index].count == null
                ? ''
                : String(node.data.wagonCounts[index].count),
            renderCell: ({
              children,
              row,
              style,
              onMouseDown,
              onMouseEnter,
            }) => {
              const { wagonCounts } = row.node.data;
              const currentCount = wagonCounts[index].count;

              const prevCount =
                index === 0 ? currentCount : wagonCounts[index - 1].count;

              return (
                <BaseCell
                  style={{
                    ...style,
                    background:
                      currentCount !== prevCount ? '#ffd966' : undefined,
                  }}
                  onMouseDown={onMouseDown}
                  onMouseEnter={onMouseEnter}
                >
                  {children}
                </BaseCell>
              );
            },
            renderCellContent: node => {
              if (node.data.wagonCounts[index].count == null) {
                return null;
              }

              switch (node.data.kind) {
                case 'rentTotal':
                case 'expeditionTotal':
                case 'techrunTotal':
                case 'parkTotal':
                case 'grandTotal':
                  return (
                    <Ellipsis className={css.boldCellText} component="span">
                      {String(node.data.wagonCounts[index].count)}
                    </Ellipsis>
                  );
                default:
                  return (
                    <Ellipsis component="span">
                      {String(node.data.wagonCounts[index].count)}
                    </Ellipsis>
                  );
              }
            },
          }))
        : []
    );
  }, [data]);

  function applyFilterParams(filterParams: Record<string, string | undefined>) {
    router.navigate(route.name, {
      ...route.params,
      ...filterParams,
    });
  }

  const allIsExpanded = data
    ? everyNode(data.nodes, node => !node.children || node.children.isExpanded)
    : false;

  return (
    <>
      <FormattedTitle>Вагоны на слежении</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Toolbar align="right">
            {data && (
              <DateRangeWithMonthSelectFilter
                minDate={dayjs(data.meta.dateRange[0])
                  .startOf('month')
                  .toDate()}
                maxDate={dayjs(data.meta.dateRange[1])
                  .add(1, 'month')
                  .startOf('month')
                  .toDate()}
                initialValue={dateRangeFilterValue}
                onApply={newDateRangeFilterValue => {
                  applyFilterParams(
                    newDateRangeFilterValue
                      ? {
                          fromDate: dayjs(
                            newDateRangeFilterValue.startDate
                          ).format(DATE_FORMAT_API_DATE),
                          toDate: dayjs(newDateRangeFilterValue.endDate).format(
                            DATE_FORMAT_API_DATE
                          ),
                        }
                      : {
                          fromDate: undefined,
                          toDate: undefined,
                        }
                  );
                }}
              />
            )}

            <Button text="Обновить" onClick={refetch} />

            <LinkButton text="Сбросить фильтры" to={route.name} />
          </Toolbar>
        </Row>

        <Row>
          <Toolbar>
            {allIsExpanded ? (
              <Button
                icon="collapse-all"
                text="Свернуть всё"
                onClick={() => {
                  updateData(
                    prevData =>
                      prevData && {
                        ...prevData,
                        nodes: setAllNodesIsExpanded(prevData.nodes, false),
                      }
                  );
                }}
              />
            ) : (
              <Button
                icon="expand-all"
                text="Развернуть всё"
                onClick={() => {
                  updateData(
                    prevData =>
                      prevData && {
                        ...prevData,
                        nodes: setAllNodesIsExpanded(prevData.nodes, true),
                      }
                  );
                }}
              />
            )}
          </Toolbar>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <TreeTable
              columns={columns}
              isFetching={isFetching}
              nodes={data.nodes}
              stickyColumnCount={1}
              stickyBottomRowCount={1}
              onNodesChange={handleNodesChange}
            />
          )}
        </Row>
      </VGrid>
    </>
  );
}

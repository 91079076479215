import { IUser } from './types';

export function formatUserName(user: IUser) {
  if (!(user.lastName || user.firstName || user.middleName)) {
    return user.email;
  }

  return [user.lastName, user.firstName, user.middleName]
    .filter(Boolean)
    .join(' ');
}

import {
  selectFavoriteExpeditionRequests,
  selectFavoriteTechrunRequests,
} from '_core/me/me';
import { Link } from '_core/router5/link';
import { useErpSelector } from '_erp/redux';
import {
  selectNewExpeditionTransportationsCount,
  selectNewTechrunTransportationsCount,
} from '_erp/stats';
import * as React from 'react';

export function NewTransportationsWidget() {
  const favoriteExpeditionRequests = useErpSelector(
    selectFavoriteExpeditionRequests
  );

  const favoriteTechrunRequests = useErpSelector(selectFavoriteTechrunRequests);

  const newExpeditionTransportationsCount = useErpSelector(
    selectNewExpeditionTransportationsCount
  );

  const newTechrunTransportationsCount = useErpSelector(
    selectNewTechrunTransportationsCount
  );

  return (
    <>
      <p>
        <Link
          params={{
            requests: favoriteExpeditionRequests.join(',') || undefined,
            status: 'new',
          }}
          rel="noopener"
          target="_blank"
          to="expeditions.transportations"
        >
          Новые отправки экспедирования:{' '}
          {newExpeditionTransportationsCount ?? '…'}
        </Link>
      </p>

      <p>
        <Link
          params={{
            requests: favoriteTechrunRequests.join(',') || undefined,
            status: 'new',
          }}
          rel="noopener"
          target="_blank"
          to="techruns.transportations"
        >
          Новые отправки техрейса: {newTechrunTransportationsCount ?? '…'}
        </Link>
      </p>
    </>
  );
}

import { ITransportationExpense } from 'transportations/types';
import { VatRate } from 'vatRates/vatRates';
import { IDislocationSerialized } from 'wagons/api';

export enum ExpeditionTransportationAccountingState {
  NotClosed = 'NOTCLOSED',
  WaitCostClose = 'WAIT_COST_CLOSE',
  WaitTariffClose = 'WAIT_TARIFF_CLOSE',
  Closed = 'CLOSED',
  BuhClosed = 'BUHCLOSED',
}

export function getExpeditionTransportationAccountingStateLabel(
  state: ExpeditionTransportationAccountingState
) {
  return {
    [ExpeditionTransportationAccountingState.NotClosed]: 'Не закрыта',
    [ExpeditionTransportationAccountingState.WaitCostClose]:
      'Ждёт закрытия услуги',
    [ExpeditionTransportationAccountingState.WaitTariffClose]:
      'Ждёт выставления тарифа',
    [ExpeditionTransportationAccountingState.Closed]: 'Закрыта',
    [ExpeditionTransportationAccountingState.BuhClosed]:
      'Закрыта бух. документами',
  }[state];
}

export const allExpeditionTransportationAccountingStates = [
  ExpeditionTransportationAccountingState.NotClosed,
  ExpeditionTransportationAccountingState.WaitCostClose,
  ExpeditionTransportationAccountingState.WaitTariffClose,
  ExpeditionTransportationAccountingState.Closed,
  ExpeditionTransportationAccountingState.BuhClosed,
];

export const expeditionTransportationAccountingStateOptions =
  allExpeditionTransportationAccountingStates.map(state => ({
    label: getExpeditionTransportationAccountingStateLabel(state),
    value: state,
  }));

export enum ExpeditionTransportationGroup {
  Confirmed = 'CONFIRMED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Supplementary = 'SUPPLEMENTARY',
}

export function getExpeditionTransportationGroupLabel(
  group: ExpeditionTransportationGroup
) {
  return {
    [ExpeditionTransportationGroup.Confirmed]: 'Подтверждённые',
    [ExpeditionTransportationGroup.New]: 'Новые',
    [ExpeditionTransportationGroup.Rejected]: 'Отклонённые',
    [ExpeditionTransportationGroup.Supplementary]: 'Служебные',
  }[group];
}

export function expeditionTransportationStatusFromGroup(
  group: ExpeditionTransportationGroup
) {
  return group.toLowerCase();
}

export function expeditionTransportationGroupFromRouteStatus(
  status: string
): ExpeditionTransportationGroup {
  const group = status.toUpperCase();

  const validValues = Object.values(ExpeditionTransportationGroup);

  if (validValues.includes(group as ExpeditionTransportationGroup)) {
    return group as ExpeditionTransportationGroup;
  }

  throw new Error(
    [
      `Given invalid transportation group of "${group}" (status: "${status}"),`,
      `expected one of ${JSON.stringify(validValues)}`,
    ].join(' ')
  );
}

export enum ExpeditionTransportationIncomeType {
  Custom = 'CUSTOM', // Дополнительный
  ExpeditionCost = 'EXPEDITION_COST', // Стоимость экспедирования
  ExpeditionTariff = 'EXPEDITION_TARIFF', // Тариф экспедирования
  ExpeditionRate = 'EXPEDITION_RATE', // Ставка экспедирования
}

export interface IExpeditionTransportationIncome {
  cost: string;
  costTotal: string;
  costVatValue: string | null;
  incomeType: ExpeditionTransportationIncomeType;
  isCalculated: boolean;
  name: string;
  vatRate: VatRate | null;
}

export enum ExpeditionTransportationState {
  Wait = 'WAIT',
  Load = 'LOAD',
  OnWay = 'ONWAY',
  Arrived = 'ARRIVED',
  Finished = 'FINISHED',
}

export function isExpeditionTransportationState(
  str: string
): str is ExpeditionTransportationState {
  return Object.values(ExpeditionTransportationState).includes(
    str as ExpeditionTransportationState
  );
}

export function getExpeditionTransportationStateLabel(
  state: ExpeditionTransportationState
) {
  return {
    [ExpeditionTransportationState.Wait]: 'Подход',
    [ExpeditionTransportationState.Load]: 'Погрузка',
    [ExpeditionTransportationState.OnWay]: 'В пути',
    [ExpeditionTransportationState.Arrived]: 'Прибыл',
    [ExpeditionTransportationState.Finished]: 'Завершена',
  }[state];
}

export const expeditionTransportationStateOptions = [
  ExpeditionTransportationState.Wait,
  ExpeditionTransportationState.Load,
  ExpeditionTransportationState.OnWay,
  ExpeditionTransportationState.Arrived,
  ExpeditionTransportationState.Finished,
].map(state => ({
  label: getExpeditionTransportationStateLabel(state),
  value: state,
}));

export interface IExpeditionTransportationShipment {
  id: number;
  name: string;
  type: ExpeditionTransportationIncomeType;
}

enum SellRateType {
  Day = 'DAY',
  Ton = 'TON',
  Wagon = 'WAGON',
}

export interface IExpeditionTransportation {
  accountingState: ExpeditionTransportationAccountingState;
  arrivalBeforeLoad: string | null;
  arrivalDate: string | null;
  arrivalStation: number;
  cargoWeight: string;
  consigner: string | null;
  created: string;
  departureAfterUnload: string | null;
  departureDate: string;
  departureDateNext: string;
  departureStation: number;
  dislocationActive: IDislocationSerialized;
  dislocationCreated: IDislocationSerialized;
  dislocationNext: IDislocationSerialized;
  emptyTransportations: number[];
  expenses: ITransportationExpense[];
  expensesTotal: string;
  group: ExpeditionTransportationGroup;
  groupsTransitionRoute: ExpeditionTransportationGroup[];
  hasDuplicate: boolean;
  id: number;
  income: IExpeditionTransportationIncome[];
  isDepartureDateChanged: boolean;
  isEmpty: boolean;
  isWaybillNumberChanged: boolean;
  modified: string;
  note: string;
  operationStation: number;
  payer: string;
  profit: string;
  purchaseRateSupplierDocument: number | null;
  purchaseRent: number | null;
  request: number | null;
  sellRateTotal: string;
  sellRateType: SellRateType;
  shipments: IExpeditionTransportationShipment[];
  state: ExpeditionTransportationState;
  supplierDocumentNumber: { id: number; number: string } | null;
  tariffCalculated: string | null;
  tariffIvc: string | null;
  tariffOverride: string | null;
  tariffSupplierDocument: number | null;
  transportationName: string;
  wagon: string;
  waybillNumber: string;
}

import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputPasswordForFinalForm } from '_core/inputs/inputPassword';
import { loginAction, selectLogoutMessage } from '_core/me/me';
import { useErpDispatch, useErpSelector } from '_erp/redux';
import { Button, Card, Intent } from '@blueprintjs/core';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useRouteNode } from 'react-router5';

import * as css from './login.module.css';

export default function LoginRoute() {
  const logoutMessage = useErpSelector(selectLogoutMessage);
  const dispatch = useErpDispatch();
  const { route, router } = useRouteNode('login');

  return (
    <FinalForm
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async values => {
        try {
          unwrapResult(
            await dispatch(
              loginAction({
                email: values.email,
                password: values.password,
              })
            )
          );
          router.navigate(route.name, route.params, { reload: true });
          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось войти: Непредвиденная ошибка'
          );
        }
      }}
    >
      {({ error, handleSubmit, submitError, submitting }) => (
        <div className={css.root}>
          <Card className={css.card}>
            <FormErrors error={error || submitError || logoutMessage} />

            <BaseForm onSubmit={handleSubmit}>
              <FormGroupForFinalForm
                label="Email"
                labelFor="email"
                labelInfo="*"
                name="email"
              >
                <InputGroupForFinalForm
                  autoComplete="username"
                  autoFocus
                  id="email"
                  large
                  name="email"
                  placeholder="user@example.com"
                  type="email"
                />
              </FormGroupForFinalForm>

              <FormGroupForFinalForm
                label="Пароль"
                labelFor="password"
                labelInfo="*"
                name="password"
              >
                <InputPasswordForFinalForm
                  autoComplete="current-password"
                  id="password"
                  large
                  name="password"
                  placeholder="**********"
                />
              </FormGroupForFinalForm>

              <Button
                disabled={submitting}
                fill
                large
                loading={submitting}
                intent={Intent.PRIMARY}
                type="submit"
              >
                Войти
              </Button>
            </BaseForm>
          </Card>
        </div>
      )}
    </FinalForm>
  );
}

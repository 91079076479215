import { RentWagonPurchasePauseCause } from 'rentWagonPurchase/api';

export const rentWagonPurchasePauseCauseLabel = (
  cause: RentWagonPurchasePauseCause
) =>
  ({
    ROUTINE_REPAIR: 'Текущий ремонт',
    SCHEDULED_REPAIR: 'Деповский ремонт',
    PAUSED: 'Пауза',
  }[cause]);

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { IContractSellSerialized } from './api';

export const ContractsSellAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<IContractSellSerialized, number>({
      baseEndpoint: '/contracts_sell',
      itemToOption: contract => ({
        label: contract.number,
        value: contract.id,
      }),

      getLink: contract => ({
        params: { id: String(contract.id) },
        to: 'contracts.sell.view',
      }),
    })
  );

export const ContractsSellAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    ContractsSellAutocompleteInFormGroup
  );

import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { PreferencesVersions } from '_core/preferences';

import { IUser } from './types';

const BASE_URL = '/accounts';

export function fetchUsers(
  api: ApiClient,
  {
    ids,
    isEmployee,
    page,
  }: { ids?: number[]; isEmployee?: boolean; page?: number } = {}
) {
  return api.get<ListResponse<IUser>>(BASE_URL, {
    ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
    isEmployee,
    page,
  });
}

export const fetchManyUsers = makeFetchMany(fetchUsers, 'ids');

export interface ProfileSerialized {
  dateJoined: string;
  email: string;
  expeditionsRequests: number[];
  firstName: string;
  id: number;
  isAccountant: boolean;
  isFinanceDirector: boolean;
  isFinanceManager: boolean;
  lastLogin: string;
  lastName: string;
  middleName: string;
  note: string;
  parks: number[];
  phone: string;
  preferences: PreferencesVersions;
  purchaseContracts: number[];
  sellContracts: number[];
  shipments: number[];
  techrunsRequests: number[];
}

export interface FavoriteParksSerialized {
  parks: number[];
}

export function fetchMe(api: ApiClient) {
  return api.get<ProfileSerialized>(`${BASE_URL}/me`);
}

export function updateMe(api: ApiClient, input: ProfileSerialized) {
  return api.put<ProfileSerialized>(`${BASE_URL}/me`, input);
}

export function updateFavoriteParks(
  api: ApiClient,
  input: FavoriteParksSerialized
) {
  return api.put<ProfileSerialized>(
    `${BASE_URL}/me/update_favorite_parks`,
    input
  );
}

export enum LoginRole {
  Customer = 'customer',
  Employee = 'employee',
}

export function login(
  api: ApiClient,
  role: LoginRole,
  email: string,
  password: string
) {
  return api.post<{ profile: ProfileSerialized }>(`${BASE_URL}/login`, {
    email,
    password,
    role,
  });
}

export function logout(api: ApiClient) {
  return api.post<null>(`${BASE_URL}/logout`);
}

export function fetchTelegramLink(api: ApiClient, uid: string, token: string) {
  return api.post<{ link: string }>(`${BASE_URL}/telegram_auth_confirm`, {
    uid,
    token,
  });
}

export async function resetPassword(api: ApiClient, email: string) {
  await api.post(`${BASE_URL}/reset_password`, { email });
}

export async function resetPasswordConfirm(
  api: ApiClient,
  uid: string,
  token: string,
  password: string
) {
  const response = await api.post<{ profile: ProfileSerialized }>(
    `${BASE_URL}/reset_password_confirm`,
    {
      uid,
      token,
      password,
    }
  );

  return response;
}

import * as React from 'react';

import { FieldError } from './fieldError';

type IRowErrors<TRowErrorField extends string> = {
  [TKey in TRowErrorField]?: string;
};

interface IProps<TRowErrorField extends string> {
  fieldLabels: Array<[TRowErrorField, string]>;
  rowsErrors: Array<IRowErrors<TRowErrorField>> | undefined;
}

export function TableFieldErrors<TRowErrorField extends string>({
  fieldLabels,
  rowsErrors,
}: IProps<TRowErrorField>) {
  if (!rowsErrors) {
    return null;
  }

  const errorStrings: string[] = [];

  rowsErrors.forEach((rowErrors, rowIndex) => {
    fieldLabels.forEach(([field, label]) => {
      if (rowErrors[field]) {
        errorStrings.push(
          `Строка ${rowIndex + 1}, ${label}: ${rowErrors[field]}`
        );
      }
    });
  });

  return <FieldError text={errorStrings.join('\n')} />;
}

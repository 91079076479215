import {
  Button,
  ButtonGroup,
  IconName,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import { deepEqual } from 'fast-equals';
import * as React from 'react';

/**
 * @deprecated better just write this manually for each case
 */
export interface FilterPopoverValueProcessor<T, I> {
  emptyValue: T;
  deserialize: (value: T) => I;
  isEmpty: (value: T) => boolean;
  serialize: (value: I) => T;
}

interface Props {
  api: {
    isOpen: boolean;
    isValueEmpty: boolean;
    apply: () => void;
    clear: () => void;
    open: () => void;
    close: () => void;
  };
  children: React.ReactNode;
  hasBackdrop?: boolean;
  icon?: IconName;
  label: string;
}

/**
 * @deprecated better just write this manually for each case
 */
export function FilterPopoverUi({
  api,
  children,
  hasBackdrop,
  icon = 'filter',
  label,
}: Props) {
  return (
    <ButtonGroup>
      <Popover
        content={
          <div>
            {children}

            <Button
              fill
              intent={Intent.PRIMARY}
              text="Применить"
              onClick={() => {
                api.apply();
              }}
            />
          </div>
        }
        hasBackdrop={hasBackdrop}
        isOpen={api.isOpen}
        position={Position.BOTTOM}
        onInteraction={nextOpenState => {
          if (nextOpenState) {
            api.open();
          } else {
            api.close();
          }
        }}
      >
        <Button
          icon={icon}
          intent={api.isValueEmpty ? undefined : Intent.DANGER}
          text={label}
        />
      </Popover>

      {!api.isValueEmpty && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => {
            api.clear();
          }}
        />
      )}
    </ButtonGroup>
  );
}

/**
 * @deprecated better just write this manually for each case
 */
export function useFilterPopover<T, I>(
  initialValue: T,
  onApply: (newValue: T) => void,
  {
    deserialize,
    emptyValue,
    isEmpty,
    serialize,
  }: FilterPopoverValueProcessor<T, I>
) {
  const [state, setState] = React.useState<{ isOpen: boolean; value: I }>({
    isOpen: false,
    value: deserialize(initialValue),
  });

  const lastInitialValueRef = React.useRef(initialValue);
  if (!deepEqual(initialValue, lastInitialValueRef.current)) {
    lastInitialValueRef.current = initialValue;
    setState(prevState => ({
      ...prevState,
      value: deserialize(initialValue),
    }));
  }

  return React.useMemo(
    () => ({
      change: (nextValue: I) => {
        setState(prevState => ({ ...prevState, value: nextValue }));
      },
      value: state.value,
      ui: {
        isOpen: state.isOpen,
        isValueEmpty: isEmpty(initialValue),
        apply: () => {
          onApply(serialize(state.value));
          setState(prevState => ({ ...prevState, isOpen: false }));
        },
        clear: () => {
          onApply(emptyValue);
        },
        close: () => {
          setState(prevState => ({ ...prevState, isOpen: false }));
        },
        open: () => {
          setState(prevState => ({
            ...prevState,
            isOpen: true,
            value: deserialize(initialValue),
          }));
        },
      },
    }),
    [
      deserialize,
      emptyValue,
      initialValue,
      isEmpty,
      onApply,
      serialize,
      state.isOpen,
      state.value,
    ]
  );
}

export enum TechrunRequestStatus {
  Created = 'CREATED',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Closed = 'CLOSED',
}

export function isTechrunRequestStatus(
  str: string
): str is TechrunRequestStatus {
  return Object.values(TechrunRequestStatus).includes(
    str as TechrunRequestStatus
  );
}

interface ITechrunRequestWagonPurchasePartner {
  id: number;
  shortName: string;
}

export interface ITechrunRequestWagon {
  isFinished: boolean;
  owner: number | null;
  purchasePartner: ITechrunRequestWagonPurchasePartner | null;
  purchaseRate: number | null;
  purchaseRent: number | null;
  wagon: string;
}

export interface ITechrunRequest {
  arrivalStation: number;
  consigner: number | null;
  departureStation: number;
  endDate: string;
  gu12Docs: number[];
  id: number;
  name: string;
  number: string;
  parks: number[];
  partner: number;
  requestName: string;
  sellContract: number;
  sellProtocol: number;
  sellRate: number;
  startDate: string;
  status: TechrunRequestStatus;
  trackRepair: boolean;
  wagons: ITechrunRequestWagon[];
  wagonsRequired: number;
  wagonType: number;
}

export const techrunRequestStatusLabel = (status: TechrunRequestStatus) =>
  ({
    [TechrunRequestStatus.Created]: 'Создана',
    [TechrunRequestStatus.Active]: 'Активна',
    [TechrunRequestStatus.Completed]: 'Выполнена',
    [TechrunRequestStatus.Closed]: 'Закрыта',
  }[status]);

export const techrunRequestStatusOptions = [
  TechrunRequestStatus.Created,
  TechrunRequestStatus.Active,
  TechrunRequestStatus.Completed,
  TechrunRequestStatus.Closed,
].map(status => ({
  label: techrunRequestStatusLabel(status),
  value: status,
}));

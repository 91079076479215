import { TechrunGu12Type } from './api';

export const getTechrunGu12TypeLabel = (type: TechrunGu12Type) =>
  ({
    [TechrunGu12Type.Export]: 'Экспорт',
    [TechrunGu12Type.Internal]: 'Внутренняя',
    [TechrunGu12Type.Letter]: 'Письмо',
  }[type]);

export const techrunGu12TypeOptions = [
  TechrunGu12Type.Export,
  TechrunGu12Type.Internal,
  TechrunGu12Type.Letter,
].map(type => ({
  label: getTechrunGu12TypeLabel(type),
  value: type,
}));

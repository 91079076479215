import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import {
  chainValidators,
  numeric,
  required,
  validate,
} from '_core/forms/validators';
import { range } from '_core/fp/range';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { Select } from '_core/inputs/select';
import { LinkButton } from '_core/router5/linkButton';
import { Toolbar } from '_core/toolbar';
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRoute } from 'react-router5';

import * as css from './pagination.module.css';

const ARBITRARY_OPTION = { label: 'Ввести вручную', value: 'arbitrary' };
const MAX_PAGINATION_ITEMS = 11;

const PAGE_SIZE_VALUES = [25, 50, 100, 250, 500];

const PAGE_SIZE_OPTIONS = PAGE_SIZE_VALUES.map(n => ({
  label: String(n),
  value: String(n),
})).concat([ARBITRARY_OPTION]);

const numberedPage = (n: number) => ({ n, ellipsize: false });
const ellipsizedPage = (n: number) => ({ n, ellipsize: true });

export const groupItems = (currentPage: number, totalPages: number) => {
  const pageNumbers = range(1, totalPages + 1);

  if (pageNumbers.length <= MAX_PAGINATION_ITEMS) {
    return pageNumbers.map(numberedPage);
  }

  let leftPages: number[];
  let rightPages: number[];

  if (currentPage < 8) {
    // left
    leftPages = pageNumbers.slice(0, 8);
    rightPages = pageNumbers.slice(-2);

    return [
      ...leftPages.map(numberedPage),
      ellipsizedPage(Math.ceil((8 + pageNumbers.length - 3) / 2)),
      ...rightPages.map(numberedPage),
    ];
  } else if (currentPage > totalPages - 7) {
    // right
    leftPages = pageNumbers.slice(0, 2);
    rightPages = pageNumbers.slice(-8);

    return [
      ...leftPages.map(numberedPage),
      ellipsizedPage(Math.ceil((2 + pageNumbers.length - 9) / 2)),
      ...rightPages.map(numberedPage),
    ];
  }

  // both
  leftPages = pageNumbers.slice(0, 2);
  const centerPages = pageNumbers.slice(currentPage - 3, currentPage + 2);
  rightPages = pageNumbers.slice(-2);

  return [
    ...leftPages.map(numberedPage),
    ellipsizedPage(Math.ceil(currentPage / 2)),
    ...centerPages.map(numberedPage),
    ellipsizedPage(
      Math.ceil(currentPage + (pageNumbers.length - currentPage) / 2)
    ),
    ...rightPages.map(numberedPage),
  ];
};

interface Props {
  pageSize: number;
  totalPages: number;
}

export function Pagination({ pageSize, totalPages }: Props) {
  const { route, router } = useRoute();

  const finalPageSize = isFinite(route.params.pageSize)
    ? Number(route.params.pageSize)
    : pageSize;

  const isArbitraryPageSize = !PAGE_SIZE_VALUES.includes(finalPageSize);

  const [isPageSizeFormShown, setIsPageSizeFormShown] =
    useState(isArbitraryPageSize);

  useEffect(() => {
    if (isArbitraryPageSize !== isPageSizeFormShown) {
      setIsPageSizeFormShown(isArbitraryPageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArbitraryPageSize]);

  const currentPage = isFinite(route.params.page)
    ? Number(route.params.page)
    : 1;

  function acceptPageSize(newPageSize: number) {
    router.navigate(route.name, {
      ...route.params,
      page: undefined,
      pageSize: newPageSize,
    });
  }

  return (
    <div className={css.root}>
      <div className={css.pageNumbers}>
        {totalPages > 1 && (
          <ButtonGroup>
            {groupItems(currentPage, totalPages).map((item, index) => (
              <LinkButton
                key={index}
                active={item.n === currentPage}
                params={{ ...route.params, page: item.n }}
                text={item.ellipsize ? '...' : String(item.n)}
                to={route.name}
              />
            ))}
          </ButtonGroup>
        )}
      </div>

      <div className={css.pageSizeToolbarWrapper}>
        <Toolbar align="right" valign="center">
          <div>На странице:</div>

          <Select
            fill
            options={PAGE_SIZE_OPTIONS}
            value={
              isArbitraryPageSize
                ? ARBITRARY_OPTION.value
                : String(finalPageSize)
            }
            onChange={newValue => {
              if (newValue === ARBITRARY_OPTION.value) {
                setIsPageSizeFormShown(true);
              } else {
                setIsPageSizeFormShown(false);
                acceptPageSize(Number(newValue));
              }
            }}
          />

          {isPageSizeFormShown && (
            <FinalForm
              initialValues={{ pageSize: finalPageSize }}
              validate={validate({
                pageSize: chainValidators(required(), numeric()),
              })}
              onSubmit={values => {
                acceptPageSize(values.pageSize);
              }}
            >
              {({ handleSubmit }) => (
                <BaseForm onSubmit={handleSubmit}>
                  <Toolbar>
                    <div className={css.pageSizeInputWrapper}>
                      <InputGroupForFinalForm id="pageSize" name="pageSize" />
                    </div>

                    <Button intent={Intent.PRIMARY} text="ОК" type="submit" />
                  </Toolbar>
                </BaseForm>
              )}
            </FinalForm>
          )}
        </Toolbar>
      </div>
    </div>
  );
}

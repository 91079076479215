import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { LinkButton } from '_core/router5/linkButton';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Intent, NonIdealState } from '@blueprintjs/core';
import { IContractSell } from 'contractsSell/api';
import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouteNode } from 'react-router5';
import {
  createRepairProtocolSell,
  fetchRepairProtocolsSell,
  IRepairProtocolSell,
  updateRepairProtocolSell,
} from 'repairProtocolsSell/api';

import { DocumentListLayout } from './documentListLayout';
import {
  IRepairProtocolFormValues,
  RepairProtocolForm,
} from './repairProtocols/repairProtocolForm';

interface IProps {
  contract: IContractSell;
}

export function ContractsSellViewRepairProtocolsTab({ contract }: IProps) {
  const { route, router } = useRouteNode(
    'contracts.sell.view.repairsProtocols'
  );

  const navigationLock = useFormNavigationLock(route.name);
  const api = useApiClient();

  const { data, isFetching, updateData } = useAsyncData(
    [contract.id, api],
    async () => {
      const response = await fetchAllPages(page =>
        fetchRepairProtocolsSell(api, { contract: contract.id, page })
      );

      return response.results;
    }
  );

  const selectedProtocolId = Number(route.params.protocolId);

  const selectedProtocol = isFinite(selectedProtocolId)
    ? data?.find(protocol => protocol.id === selectedProtocolId)
    : undefined;

  const [editingLocked, setEditingLocked] = useState(selectedProtocol != null);

  useEffect(() => {
    setEditingLocked(selectedProtocol != null);
  }, [selectedProtocol]);

  const isCreateRoute =
    route.name === 'contracts.sell.view.repairsProtocols.create';

  const copyFromProtocolId = Number(route.params.copyFrom);

  const copyFromProtocol = isFinite(copyFromProtocolId)
    ? data?.find(protocol => protocol.id === copyFromProtocolId)
    : undefined;

  const initialValues: IRepairProtocolFormValues = selectedProtocol
    ? selectedProtocol
    : copyFromProtocol
    ? copyFromProtocol
    : {
        date: dayjs().startOf('date').toDate(),
        endDate: dayjs().endOf('year').startOf('date').toDate(),
        number: '',
        parts: [],
        services: [],
        startDate: dayjs().startOf('date').toDate(),
        vu23Date: null,
        vu36Date: null,
      };

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <div>
      <Toolbar align="right">
        <LinkButton
          intent={Intent.PRIMARY}
          params={{
            ...route.params,
            copyFrom: undefined,
            protocolId: undefined,
          }}
          text="Добавить"
          to="contracts.sell.view.repairsProtocols.create"
        />
      </Toolbar>

      {!isCreateRoute && data.length === 0 ? (
        <NonIdealState
          icon="list"
          description="Вы можете создать протокол ремонта с помощью кнопки выше"
          title="У договора нет протоколов ремонта"
        />
      ) : (
        <DocumentListLayout
          list={
            <Table
              emptyMessage="У договора нет протоколов ремонта"
              getRowProps={(protocol: IRepairProtocolSell) => ({
                selected: selectedProtocol === protocol,
              })}
              isFetching={isFetching}
              items={data}
            >
              <Column
                width={150}
                id="numberAndDate"
                label="Наименование"
                cell={(protocol: IRepairProtocolSell) => (
                  <LinkCell
                    params={{
                      ...route.params,
                      protocolId: protocol.id,
                    }}
                    to="contracts.sell.view.repairsProtocols.view"
                  >
                    {protocol.number}
                  </LinkCell>
                )}
              />
            </Table>
          }
          form={
            !selectedProtocol && !isCreateRoute ? (
              <NonIdealState
                icon="select"
                description="Выберите протокол в списке слева или добавьте новое"
                title="Протоколов не выбрано"
              />
            ) : (
              <RepairProtocolForm
                editingLocked={editingLocked}
                initialValues={initialValues}
                navigationLock={navigationLock}
                protocol={selectedProtocol}
                onCancelEditing={
                  editingLocked || isCreateRoute
                    ? undefined
                    : () => {
                        setEditingLocked(true);
                      }
                }
                onDuplicate={
                  editingLocked && selectedProtocol
                    ? () => {
                        router.navigate(
                          'contracts.sell.view.repairsProtocols.create',
                          {
                            id: route.params.id,
                            copyFrom: selectedProtocol.id,
                            listParams: route.params.listParams,
                          }
                        );
                      }
                    : undefined
                }
                onStartEditing={
                  editingLocked
                    ? () => {
                        if (
                          // eslint-disable-next-line no-alert
                          window.confirm(
                            'Вы собираетесь изменить сохранённый протокол ремонта'
                          )
                        ) {
                          setEditingLocked(false);
                        }
                      }
                    : undefined
                }
                onSubmit={async values => {
                  try {
                    if (selectedProtocol == null) {
                      const newProtocol = await createRepairProtocolSell(api, {
                        ...values,
                        contract: contract.id,
                      });

                      updateData(
                        prevData => prevData && [newProtocol].concat(prevData)
                      );

                      navigationLock.unlock();

                      router.navigate(
                        'contracts.sell.view.repairsProtocols.view',
                        {
                          ...route.params,
                          protocolId: newProtocol.id,
                        }
                      );
                    } else {
                      const updatedProtocol = await updateRepairProtocolSell(
                        api,
                        selectedProtocol.id,
                        { ...values, contract: contract.id }
                      );

                      updateData(
                        prevData =>
                          prevData &&
                          prevData.map(protocol =>
                            protocol.id === updatedProtocol.id
                              ? updatedProtocol
                              : protocol
                          )
                      );
                    }

                    return undefined;
                  } catch (err) {
                    return submissionErrorsFromApiError(
                      err,
                      selectedProtocol
                        ? 'Не удалось изменить протокол ремонта: Непредвиденная ошибка'
                        : 'Не удалось создать протокол ремонта: Непредвиденная ошибка'
                    );
                  }
                }}
              />
            )
          }
        />
      )}
    </div>
  );
}

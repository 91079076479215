import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { formatMoney } from '_core/money/formatMoney';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import {
  ISorting,
  // SortingDirection
} from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { Icon } from '@blueprintjs/core';
import { IUser } from 'accounts/types';
import { IContractPurchaseSerialized } from 'contractsPurchase/api';
import dayjs from 'dayjs';
import {
  getInvoiceAlertColor,
  getInvoiceStatusLabel,
  IBank,
  IInvoice,
  // InvoiceSortingFields,
} from 'invoices/types';
import { IPartnerSerialized } from 'partners/api';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { vatRateLabel } from 'vatRates/vatRates';
// import { Col, Grid, Row } from '../../layout/contentLayout';
// import { SortingInput } from '../../_core/sortingInput';

export interface InvoicesListTableItem
  extends Omit<IInvoice, 'bank' | 'contract' | 'createdUser' | 'partner'> {
  bank: IBank | null;
  contract: IContractPurchaseSerialized | null;
  createdUser: IUser | null;
  partner: IPartnerSerialized;
}

interface Props {
  invoices: InvoicesListTableItem[];
  isFetching: boolean;
  lineNumbersStart: number;
  listParams: string;
  selectedInvoices: string[];
  sorting: ISorting;
  onSelectedInvoicesChange: (newSelectedInvoices: string[]) => void;
  onSortingChange: (newSorting: ISorting) => void;
}

export function InvoicesListTable({
  invoices,
  isFetching,
  lineNumbersStart,
  listParams,
  selectedInvoices,
  sorting,
  onSelectedInvoicesChange,
  onSortingChange,
}: Props) {
  const columns = useMemo(
    (): Array<ListTableColumn<InvoicesListTableItem>> => [
      {
        id: 'paymentString',
        label: 'Строка оплаты',
        defaultWidth: 400,
        copyCellContent: invoice => invoice.paymentString,
        renderCellContent: invoice => (
          <Ellipsis
            component={Link}
            params={{ id: String(invoice.id), listParams }}
            to="invoices.edit"
          >
            {invoice.paymentString}
          </Ellipsis>
        ),
      },
      {
        id: 'partner',
        label: 'Контрагент',
        defaultWidth: 250,
        sortable: true,
        copyCellContent: invoice => invoice.partner.fullName,
        renderCellContent: invoice => (
          <Ellipsis
            component={Link}
            params={{ id: String(invoice.partner.id) }}
            to="partners.edit"
          >
            {invoice.partner.fullName}
          </Ellipsis>
        ),
      },
      {
        id: 'contract',
        label: 'Договор',
        defaultWidth: 90,
        copyCellContent: invoice =>
          invoice.contract ? invoice.contract.number : '',
        renderCellContent: invoice =>
          invoice.contract && (
            <Ellipsis
              component={Link}
              params={{ id: invoice.contract.id }}
              to="contracts.purchase.view"
            >
              {invoice.contract.number}
            </Ellipsis>
          ),
      },
      {
        id: 'paymentAmountTotal',
        label: 'Сумма к оплате',
        defaultWidth: 60,
        copyCellContent: invoice => formatMoney(invoice.paymentAmountTotal),
        renderCellContent: invoice => (
          <Ellipsis component="span">
            {formatMoney(invoice.paymentAmountTotal)}
          </Ellipsis>
        ),
      },
      {
        id: 'vatRate',
        label: 'НДС',
        defaultWidth: 120,
        copyCellContent: invoice => vatRateLabel(invoice.vatRate),
        renderCellContent: invoice => (
          <Ellipsis component="span">{vatRateLabel(invoice.vatRate)}</Ellipsis>
        ),
      },
      {
        id: 'scan',
        label: 'Скан счёта на оплату',
        defaultWidth: 70,
        renderCellContent: invoice =>
          invoice.scan && (
            <a href={invoice.scan} rel="noopener" target="_blank">
              <Icon icon="document" />
            </a>
          ),
      },
      {
        id: 'createdUser',
        label: 'Автор',
        defaultWidth: 180,
        copyCellContent: invoice =>
          invoice.createdUser ? invoice.createdUser.email : '<не указан>',
        renderCellContent: invoice => (
          <Ellipsis component="span">
            {invoice.createdUser ? invoice.createdUser.email : '<не указан>'}
          </Ellipsis>
        ),
      },
      {
        id: 'paymentBefore',
        label: 'Срок оплаты',
        defaultWidth: 115,
        sortable: true,
        copyCellContent: invoice =>
          dayjs(invoice.paymentBefore).format(DATE_FORMAT_DATE),
        renderCellContent: invoice => (
          <Ellipsis component="span">
            {dayjs(invoice.paymentBefore).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'paymentPlanned',
        label: 'План оплаты',
        defaultWidth: 115,
        copyCellContent: invoice =>
          invoice.paymentPlanned
            ? dayjs(invoice.paymentPlanned).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: invoice =>
          invoice.paymentPlanned ? (
            <Ellipsis component="span">
              {dayjs(invoice.paymentPlanned).format(DATE_FORMAT_DATE)}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'status',
        label: 'Статус',
        defaultWidth: 90,
        sortable: true,
        copyCellContent: invoice => getInvoiceStatusLabel(invoice.status),
        renderCellContent: invoice => (
          <Ellipsis component="span">
            {getInvoiceStatusLabel(invoice.status)}
          </Ellipsis>
        ),
      },
      {
        id: 'paidDatetime',
        label: 'Дата оплаты',
        defaultWidth: 115,
        sortable: true,
        copyCellContent: invoice =>
          invoice.paidDatetime
            ? dayjs(invoice.paidDatetime).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: invoice =>
          invoice.paidDatetime ? (
            <Ellipsis component="span">
              {dayjs(invoice.paidDatetime).format(DATE_FORMAT_DATE)}
            </Ellipsis>
          ) : null,
      },
    ],
    [listParams]
  );

  const getItemId = useCallback(
    (invoice: InvoicesListTableItem) => String(invoice.id),
    []
  );

  const getRowColor = useCallback(
    (invoice: InvoicesListTableItem) => getInvoiceAlertColor(invoice),
    []
  );

  // const defaultSorting: ISorting<InvoiceSortingFields> = {
  //   field: InvoiceSortingFields.PaymentBefore,
  //   direction: SortingDirection.Descending,
  // };

  return (
    <>
      {/* <Row> */}
      {/*  <Grid> */}
      {/*    <Col align="end"> */}
      {/*      <SortingInput */}
      {/*        defaultValue={defaultSorting} */}
      {/*        options={columns */}
      {/*          .filter(column => column.sortable) */}
      {/*          .map(column => ({ */}
      {/*            label: column.label, */}
      {/*            value: column.id as InvoiceSortingFields, */}
      {/*          }))} */}
      {/*        value={sorting} */}
      {/*        onChange={onSortingChange} */}
      {/*      /> */}
      {/*    </Col> */}
      {/*  </Grid> */}
      {/* </Row> */}

      <ListTable
        columns={columns}
        getItemId={getItemId}
        getRowColor={getRowColor}
        isFetching={isFetching}
        items={invoices}
        lineNumbersStart={lineNumbersStart}
        selectedItems={selectedInvoices}
        sorting={sorting}
        onSelectedItemsChange={onSelectedInvoicesChange}
        onSortingChange={onSortingChange}
      />
    </>
  );
}

import { BadRequestError, ValidationError } from '_core/api/client';
import { captureException } from '@sentry/minimal';
import { FORM_ERROR } from 'final-form';

export function submissionErrorsFromApiError(
  err: unknown,
  fallbackMsg: string
) {
  if (err instanceof BadRequestError) {
    return { [FORM_ERROR]: err.message };
  }

  if (err instanceof ValidationError) {
    return {
      [FORM_ERROR]: err.formError.join('\n'),
      ...err.joinFieldErrors(),
    };
  }

  captureException(err);

  return { [FORM_ERROR]: fallbackMsg };
}

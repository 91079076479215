import { useApiClient } from '_core/api/context';
import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Classes, Intent, Popover, Position } from '@blueprintjs/core';
import {
  fetchManyContacts,
  getOrCreateContactsFromText,
  IContact,
  updateContact,
} from 'contacts/api';
import { ContactsAutocompleteInFormGroup } from 'contacts/autocomplete';
import { ContactDialogForm } from 'contacts/dialogForm';
import * as React from 'react';
import { useState } from 'react';
import { useField } from 'react-final-form';

import * as css from './contactsField.module.css';

interface IAddContactsByRawTextFormValues {
  text: string;
}

interface IProps {
  initialPartner: number | undefined;
  name: string;
}

export function PartnerContactsField({ initialPartner, name }: IProps) {
  const { input, meta } = useField<number[]>(name);
  const api = useApiClient();

  const { data, isFetching, refetch } = useAsyncData([api, input.value], () =>
    fetchManyContacts(api, input.value)
  );

  const [editDialogFormState, setEditDialogFormState] = useState<{
    contact: IContact;
    isOpen: boolean;
  } | null>(null);

  const [
    addContactsByRawTextPopoverIsOpen,
    setAddContactsByRawTextPopoverIsOpen,
  ] = useState(false);

  return (
    <div>
      <div className={css.addContactToolbar}>
        <div className={css.addContactToolbarAutocompleteWrapper}>
          <ContactsAutocompleteInFormGroup
            disabled={meta.submitting}
            initialPartner={initialPartner}
            label="Добавить контакт"
            value={null}
            onChange={id => {
              if (id !== null) {
                input.onChange(input.value.concat([id]));
              }
            }}
          />

          {editDialogFormState && (
            <ContactDialogForm
              icon="envelope"
              initialValues={editDialogFormState.contact}
              isOpen={editDialogFormState.isOpen}
              title="Редактирование контакта"
              onClose={() => {
                setEditDialogFormState(
                  prevState =>
                    prevState && {
                      ...prevState,
                      isOpen: false,
                    }
                );
              }}
              onClosed={() => {
                setEditDialogFormState(null);
              }}
              onSubmit={async values => {
                await updateContact(
                  api,
                  editDialogFormState.contact.id,
                  values
                );

                setEditDialogFormState(
                  prevState =>
                    prevState && {
                      ...prevState,
                      isOpen: false,
                    }
                );

                refetch();
              }}
            />
          )}
        </div>

        <div className={css.addContactToolbarButtonWrapper}>
          <Popover
            content={
              <div className={css.addContactToolbarPopoverContent}>
                <FinalForm<IAddContactsByRawTextFormValues>
                  initialValues={{ text: '' }}
                  onSubmit={async values => {
                    try {
                      const contacts = await getOrCreateContactsFromText(
                        api,
                        values.text
                      );

                      input.onChange(
                        Array.from(
                          new Set(
                            input.value.concat(
                              contacts.map(contact => contact.id)
                            )
                          )
                        )
                      );

                      setAddContactsByRawTextPopoverIsOpen(false);

                      return undefined;
                    } catch (err) {
                      return submissionErrorsFromApiError(
                        err,
                        'Не удалось добавить контакты: Непредвиденная ошибка'
                      );
                    }
                  }}
                >
                  {({ error, handleSubmit, submitError, submitting }) => (
                    <BaseForm onSubmit={handleSubmit}>
                      <FormErrors error={error || submitError} />

                      <FormGroupForFinalForm name="text">
                        <InputMultipleForFinalForm name="text" rows={8} />
                      </FormGroupForFinalForm>

                      <Toolbar align="right">
                        <Button
                          className={Classes.POPOVER_DISMISS}
                          disabled={submitting}
                          text="Закрыть"
                        />

                        <Button
                          disabled={submitting}
                          intent={Intent.PRIMARY}
                          loading={submitting}
                          text="Добавить"
                          type="submit"
                        />
                      </Toolbar>
                    </BaseForm>
                  )}
                </FinalForm>
              </div>
            }
            isOpen={addContactsByRawTextPopoverIsOpen}
            position={Position.BOTTOM_RIGHT}
            onInteraction={setAddContactsByRawTextPopoverIsOpen}
          >
            <Button
              disabled={meta.submitting}
              text="Добавить из списка email"
            />
          </Popover>
        </div>
      </div>

      <Table emptyMessage="Нет контактов" isFetching={isFetching} items={data}>
        <Column
          id="email"
          label="Email"
          width={230}
          cell={(contact: IContact) => contact.email}
        />

        <Column
          id="fio"
          label="ФИО"
          cell={(contact: IContact) =>
            [contact.lastName]
              .concat(
                [contact.firstName, contact.middleName]
                  .filter(Boolean)
                  .map(str => `${str[0]}.`)
              )
              .join(' ')
          }
        />

        <Column
          id="actions"
          width={92}
          cell={(contact: IContact) => (
            <>
              <Button
                className={css.actionsCellButton}
                icon="edit"
                minimal
                onClick={() => {
                  setEditDialogFormState({
                    contact,
                    isOpen: true,
                  });
                }}
              />

              <Button
                className={css.actionsCellButton}
                icon="delete"
                intent={Intent.DANGER}
                minimal
                onClick={() => {
                  if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                      'Вы уверены что хотите удалить контакт контрагента?'
                    )
                  ) {
                    input.onChange(input.value.filter(id => id !== contact.id));
                  }
                }}
              />
            </>
          )}
        />
      </Table>
    </div>
  );
}

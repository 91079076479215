import { RouteSwitch, RouteSwitchBranch } from '_core/router5/routeSwitch';
import * as React from 'react';

import ConsignersCreateRoute from './consigners/create';
import ConsignersEditRoute from './consigners/edit';
import ConsignersListRoute from './consigners/list';
import ContactsCreateRoute from './contacts/create';
import ContactsEditRoute from './contacts/edit';
import ContactsListRoute from './contacts/list';
import ContractsPurchaseCreateRoute from './contracts/purchaseCreate';
import ContractsPurchaseListRoute from './contracts/purchaseList';
import ContractsPurchaseViewRoute from './contracts/purchaseView';
import ContractsSellCreateRoute from './contracts/sellCreate';
import ContractsSellListRoute from './contracts/sellList';
import ContractsSellViewRoute from './contracts/sellView';
import DowntimesEditRoute from './downtimes/edit';
import DowntimesListRoute from './downtimes/list';
import DowntimesParksRoute from './downtimes/parks';
import ExpeditionRequestsCreateRoute from './expeditions/requests/create';
import ExpeditionRequestsListRoute from './expeditions/requests/list';
import ExpeditionRequestsViewRoute from './expeditions/requests/view';
import ExpeditionTransportationsEditRoute from './expeditions/transportations/edit';
import ExpeditionTransportationsListRoute from './expeditions/transportations/list';
import HomeRoute from './home';
import InvoicesCreateRoute from './invoices/create';
import InvoicesEditRoute from './invoices/edit';
import InvoicesListRoute from './invoices/list';
import LoginRoute from './login';
import NotFoundPage from './notFoundPage';
import OurDocumentsRoute from './ourDocuments';
import OurRepairsCreateRoute from './ourRepairs/create';
import OurRepairsEditRoute from './ourRepairs/edit';
import OurRepairsListRoute from './ourRepairs/list';
import ParksCreateRoute from './parks/create';
import ParksEditRoute from './parks/edit';
import ParksListRoute from './parks/list';
import PartnersCreateRoute from './partners/create';
import PartnersEditRoute from './partners/edit';
import PartnersListRoute from './partners/list';
import PaymentsEditRoute from './payments/edit';
import PaymentsImportRoute from './payments/import';
import PaymentsListRoute from './payments/list';
import RentWagonPurchaseEditRoute from './rentWagons/purchase/edit';
import RentWagonsPurchaseListRoute from './rentWagons/purchase/list';
import RentWagonSellEditRoute from './rentWagons/sell/edit';
import RentWagonsSellListRoute from './rentWagons/sell/list';
import RepairsRoute from './repairs';
import ShipmentInfoEditRoute from './shipmentInfo/edit';
import ShipmentInfoListRoute from './shipmentInfo/list';
import StationsListRoute from './stations/list';
import SuppliersDocumentsCreateRoute from './suppliersDocuments/create';
import SuppliersDocumentsEditRoute from './suppliersDocuments/edit';
import SuppliersDocumentsListRoute from './suppliersDocuments/list';
import SuppliersDocumentsFromFileEditRoute from './suppliersDocumentsFromFile/edit';
import SuppliersDocumentsFromFileListRoute from './suppliersDocumentsFromFile/list';
import TechrunAssignWagonsRouteTabs from './techruns/assignWagons';
import TechrunsGu12CreateRoute from './techruns/gu12/create';
import TechrunsGu12EditRoute from './techruns/gu12/edit';
import TechrunsGu12ListRoute from './techruns/gu12/list';
import TechrunRequestsCreateRoute from './techruns/requests/create';
import TechrunRequestsListRoute from './techruns/requests/list';
import TechrunRequestsViewRoute from './techruns/requests/view';
import TechrunTransportationsEditRoute from './techruns/transportations/edit';
import TechrunTransportationsListRoute from './techruns/transportations/list';
import TelegramRoute from './telegram';
import TestSentryRoute from './testSentry';
import WagonArrivalsRoute from './wagonArrivals/list';
import WagonsEditRoute from './wagons/edit';
import WagonsListRoute from './wagons/list';
import WagonsTrackingRoute from './wagonsTracking/list';
import WeighingRoute from './weighing';

export function RouteTree() {
  return (
    <RouteSwitch node="">
      <RouteSwitchBranch node="testSentry">
        <TestSentryRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch node="consigners">
        <RouteSwitch node="consigners">
          <RouteSwitchBranch node="">
            <ConsignersListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="create">
            <ConsignersCreateRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <ConsignersEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="contacts">
        <RouteSwitch node="contacts">
          <RouteSwitchBranch node="">
            <ContactsListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="create">
            <ContactsCreateRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <ContactsEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="contracts">
        <RouteSwitch node="contracts">
          <RouteSwitchBranch node="purchase">
            <RouteSwitch node="contracts.purchase">
              <RouteSwitchBranch node="">
                <ContractsPurchaseListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="create">
                <ContractsPurchaseCreateRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="view">
                <ContractsPurchaseViewRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>

          <RouteSwitchBranch node="sell">
            <RouteSwitch node="contracts.sell">
              <RouteSwitchBranch node="">
                <ContractsSellListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="create">
                <ContractsSellCreateRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="view">
                <ContractsSellViewRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="downtimes">
        <RouteSwitch node="downtimes">
          <RouteSwitchBranch node="">
            <DowntimesListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <DowntimesEditRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="parks">
            <DowntimesParksRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="expeditions">
        <RouteSwitch node="expeditions">
          <RouteSwitchBranch node="requests">
            <RouteSwitch node="expeditions.requests">
              <RouteSwitchBranch node="">
                <ExpeditionRequestsListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="create">
                <ExpeditionRequestsCreateRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="view">
                <ExpeditionRequestsViewRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>

          <RouteSwitchBranch node="transportations">
            <RouteSwitch node="expeditions.transportations">
              <RouteSwitchBranch node="">
                <ExpeditionTransportationsListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="edit">
                <ExpeditionTransportationsEditRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="home">
        <HomeRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch node="invoices">
        <RouteSwitch node="invoices">
          <RouteSwitchBranch node="">
            <InvoicesListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="create">
            <InvoicesCreateRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <InvoicesEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="login">
        <LoginRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch node="ourDocuments">
        <OurDocumentsRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch node="ourRepairs">
        <RouteSwitch node="ourRepairs">
          <RouteSwitchBranch node="">
            <OurRepairsListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="create">
            <OurRepairsCreateRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <OurRepairsEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="parks">
        <RouteSwitch node="parks">
          <RouteSwitchBranch node="">
            <ParksListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="create">
            <ParksCreateRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <ParksEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="partners">
        <RouteSwitch node="partners">
          <RouteSwitchBranch node="">
            <PartnersListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="create">
            <PartnersCreateRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <PartnersEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="payments">
        <RouteSwitch node="payments">
          <RouteSwitchBranch node="">
            <PaymentsListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <PaymentsEditRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="import">
            <PaymentsImportRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="rentWagons">
        <RouteSwitch node="rentWagons">
          <RouteSwitchBranch node="purchase">
            <RouteSwitch node="rentWagons.purchase">
              <RouteSwitchBranch node="">
                <RentWagonsPurchaseListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="edit">
                <RentWagonPurchaseEditRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>

          <RouteSwitchBranch node="sell">
            <RouteSwitch node="rentWagons.sell">
              <RouteSwitchBranch node="">
                <RentWagonsSellListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="edit">
                <RentWagonSellEditRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="repairs">
        <RepairsRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch node="shipmentInfo">
        <RouteSwitch node="shipmentInfo">
          <RouteSwitchBranch node="">
            <ShipmentInfoListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <ShipmentInfoEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="stations">
        <RouteSwitch node="stations">
          <RouteSwitchBranch node="">
            <StationsListRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="suppliersDocuments">
        <RouteSwitch node="suppliersDocuments">
          <RouteSwitchBranch node="">
            <SuppliersDocumentsListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="create">
            <SuppliersDocumentsCreateRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <SuppliersDocumentsEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="suppliersDocumentsFromFile">
        <RouteSwitch node="suppliersDocumentsFromFile">
          <RouteSwitchBranch node="">
            <SuppliersDocumentsFromFileListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <SuppliersDocumentsFromFileEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="techruns">
        <RouteSwitch node="techruns">
          <RouteSwitchBranch node="assignWagons">
            <TechrunAssignWagonsRouteTabs />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="gu12">
            <RouteSwitch node="techruns.gu12">
              <RouteSwitchBranch node="">
                <TechrunsGu12ListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="create">
                <TechrunsGu12CreateRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="edit">
                <TechrunsGu12EditRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>

          <RouteSwitchBranch node="requests">
            <RouteSwitch node="techruns.requests">
              <RouteSwitchBranch node="">
                <TechrunRequestsListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="create">
                <TechrunRequestsCreateRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="view">
                <TechrunRequestsViewRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>

          <RouteSwitchBranch node="transportations">
            <RouteSwitch node="techruns.transportations">
              <RouteSwitchBranch node="">
                <TechrunTransportationsListRoute />
              </RouteSwitchBranch>

              <RouteSwitchBranch node="edit">
                <TechrunTransportationsEditRoute />
              </RouteSwitchBranch>
            </RouteSwitch>
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="telegram">
        <TelegramRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch node="wagonArrivals">
        <WagonArrivalsRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch node="wagons">
        <RouteSwitch node="wagons">
          <RouteSwitchBranch node="">
            <WagonsListRoute />
          </RouteSwitchBranch>

          <RouteSwitchBranch node="edit">
            <WagonsEditRoute />
          </RouteSwitchBranch>
        </RouteSwitch>
      </RouteSwitchBranch>

      <RouteSwitchBranch node="wagonsTracking">
        <WagonsTrackingRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch node="weighing">
        <WeighingRoute />
      </RouteSwitchBranch>

      <RouteSwitchBranch>
        <NotFoundPage />
      </RouteSwitchBranch>
    </RouteSwitch>
  );
}

import { DATE_FORMAT_API_DATE, DATE_FORMAT_DATE } from '_core/dates/formats';
import {
  createDateRange,
  monthDateToSelectOption,
  parseDate,
} from '_core/dates/utils';
import { useFilterPopover } from '_core/filters/filterPopover';
import { DateRangePicker } from '_core/inputs/dateRangePicker';
import { Select } from '_core/inputs/select';
import {
  Button,
  ButtonGroup,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime';
import dayjs from 'dayjs';
import * as React from 'react';

interface IValue {
  startDate: Date;
  endDate: Date;
}

function getSelectValue(minDate: Date, maxDate: Date, value: DateRange) {
  const fromDate = value[0] && dayjs(value[0]);
  const toDate = value[1] && dayjs(value[1]);

  if (
    !fromDate ||
    !toDate ||
    fromDate.get('month') !== toDate.get('month') ||
    fromDate.isBefore(minDate) ||
    fromDate.isAfter(maxDate) ||
    toDate.isBefore(minDate) ||
    toDate.isAfter(maxDate)
  ) {
    return null;
  }

  if (
    (fromDate.isSame(minDate) || fromDate.isSame(fromDate.startOf('month'))) &&
    (toDate.isSame(maxDate) ||
      toDate.isSame(fromDate.endOf('month').startOf('day')))
  ) {
    return fromDate.startOf('month').format(DATE_FORMAT_API_DATE);
  }

  return null;
}

interface Props {
  minDate: Date;
  maxDate: Date;
  initialValue: IValue | null;
  onApply: (newValue: IValue | null) => void;
}

export function DateRangeWithMonthSelectFilter({
  minDate,
  maxDate,
  initialValue,
  onApply,
}: Props) {
  const filterPopover = useFilterPopover<IValue | null, DateRange>(
    initialValue,
    onApply,

    {
      emptyValue: null,
      isEmpty: value => !value,
      deserialize: value => [value && value.startDate, value && value.endDate],

      serialize: ([fromDate, toDate]) =>
        fromDate && toDate ? { startDate: fromDate, endDate: toDate } : null,
    }
  );

  const selectValue = getSelectValue(minDate, maxDate, filterPopover.value);

  const [isDateRangePickerShown, setIsDateRangePickerShown] = React.useState(
    () => !selectValue
  );

  const handleSelectChange = (newSelectValue: string | null) => {
    if (newSelectValue) {
      const newMonthDate = parseDate(newSelectValue);

      let newStartDate = dayjs(newMonthDate).toDate();

      if (newStartDate < minDate) {
        newStartDate = minDate;
      }

      let newEndDate = dayjs(newMonthDate)
        .endOf('month')
        .startOf('day')
        .toDate();

      if (newEndDate > maxDate) {
        newEndDate = maxDate;
      }

      filterPopover.change([newStartDate, newEndDate]);
    }

    setIsDateRangePickerShown(newSelectValue == null);
  };

  return (
    <ButtonGroup>
      <Popover
        content={
          <>
            <Select
              emptyOptionText="<Указать вручную>"
              fill
              value={selectValue}
              withEmptyOption
              onChange={handleSelectChange}
            >
              {createDateRange(
                'year',
                minDate,
                dayjs(maxDate).add(1, 'year').toDate()
              )
                .reverse()
                .map(yearDate => {
                  const yearStr = dayjs(yearDate).format('YYYY');

                  const fromDate = minDate > yearDate ? minDate : yearDate;

                  const lastMonthDate = dayjs(yearDate).add(1, 'year').toDate();

                  const toDate =
                    maxDate < lastMonthDate ? maxDate : lastMonthDate;

                  const monthDates: Date[] = [];

                  for (
                    let date = dayjs(fromDate);
                    date.isBefore(toDate);
                    date = date.add(1, 'month')
                  ) {
                    date = date.startOf('month');
                    monthDates.push(date.toDate());
                  }

                  return (
                    <optgroup key={yearStr} label={yearStr}>
                      {monthDates
                        .map(monthDateToSelectOption)
                        .reverse()
                        .map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                    </optgroup>
                  );
                })}
            </Select>

            {isDateRangePickerShown && (
              <DateRangePicker
                contiguousCalendarMonths={false}
                minDate={minDate}
                value={filterPopover.value}
                onChange={filterPopover.change}
              />
            )}

            <Button
              fill
              intent={Intent.PRIMARY}
              text="Применить"
              onClick={filterPopover.ui.apply}
            />
          </>
        }
        isOpen={filterPopover.ui.isOpen}
        position={Position.BOTTOM}
        onInteraction={nextOpenState => {
          if (nextOpenState) {
            filterPopover.ui.open();
          } else {
            filterPopover.ui.close();
          }
        }}
      >
        <Button
          icon="calendar"
          intent={filterPopover.ui.isValueEmpty ? undefined : Intent.DANGER}
          text={`Период${
            initialValue
              ? `: ${dayjs(initialValue.startDate).format(
                  DATE_FORMAT_DATE
                )}-${dayjs(initialValue.endDate).format(DATE_FORMAT_DATE)}`
              : ''
          }`}
        />
      </Popover>

      {!filterPopover.ui.isValueEmpty && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => {
            filterPopover.ui.clear();
          }}
        />
      )}
    </ButtonGroup>
  );
}

import { FilterPopoverUi, useFilterPopover } from '_core/filters/filterPopover';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import * as React from 'react';
import { StationsAutocompleteInFormGroup } from 'stations/autocomplete';

import * as css from './arrDepOperFilterInPopover.module.css';

export interface IStationsFilterValue {
  arrivalStation: number | undefined;
  consignee: string | undefined;
  departureStation: number | undefined;
  operationStation: number | undefined;
}

interface IProps {
  initialValue: IStationsFilterValue;
  showConsigneeFilter?: boolean;
  onApply: (newValue: IStationsFilterValue) => void;
}

export function ArrDepOperStationsFilterInPopover({
  initialValue,
  showConsigneeFilter,
  onApply,
}: IProps) {
  const filterPopover = useFilterPopover<
    IStationsFilterValue,
    IStationsFilterValue
  >(initialValue, onApply, {
    emptyValue: {
      arrivalStation: undefined,
      consignee: undefined,
      departureStation: undefined,
      operationStation: undefined,
    },

    deserialize(value) {
      return value;
    },

    serialize(value) {
      return value;
    },

    isEmpty({ arrivalStation, consignee, departureStation, operationStation }) {
      return ![
        arrivalStation,
        departureStation,
        operationStation,
        consignee,
      ].some(Boolean);
    },
  });

  return (
    <FilterPopoverUi
      api={filterPopover.ui}
      hasBackdrop
      icon="filter-list"
      label="Станции"
    >
      <div className={css.main}>
        <StationsAutocompleteInFormGroup
          label="Станция отправления"
          value={filterPopover.value.departureStation || null}
          onChange={stationId => {
            filterPopover.change({
              ...filterPopover.value,
              departureStation: stationId || undefined,
            });
          }}
        />

        <StationsAutocompleteInFormGroup
          label="Станция назначения"
          value={filterPopover.value.arrivalStation || null}
          onChange={stationId => {
            filterPopover.change({
              ...filterPopover.value,
              arrivalStation: stationId || undefined,
            });
          }}
        />

        <StationsAutocompleteInFormGroup
          label="Станция операции"
          value={filterPopover.value.operationStation || null}
          onChange={stationId => {
            filterPopover.change({
              ...filterPopover.value,
              operationStation: stationId || undefined,
            });
          }}
        />

        {showConsigneeFilter && (
          <FormGroup label="Грузополучатель ОКПО">
            <InputGroup
              value={filterPopover.value.consignee || ''}
              onChange={event => {
                filterPopover.change({
                  ...filterPopover.value,
                  consignee: event.currentTarget.value || undefined,
                });
              }}
            />
          </FormGroup>
        )}
      </div>
    </FilterPopoverUi>
  );
}

import { ApiClient } from '_core/api/client';

interface TaskGeneratedFile {
  contentType: string;
  name: string;
  url: string;
}

interface FetchTasksGeneratedFilesResponse {
  currentRow: number;
  file: TaskGeneratedFile | null;
  totalRows: number;
}

export function fetchTasksGeneratedFiles(api: ApiClient, taskId: string) {
  return api.get<FetchTasksGeneratedFilesResponse>(
    `/tasks_generated_files/${taskId}`
  );
}

interface FetchTasksProcessedFilesResponse {
  createdDocs: number;
  isFinished: boolean;
  uploadedDocs: number;
}

export function fetchTasksProcessedFiles(api: ApiClient, taskId: string) {
  return api.get<FetchTasksProcessedFilesResponse>(
    `/tasks_processed_files/${taskId}`
  );
}

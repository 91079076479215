import createFocusDecorator from 'final-form-focus';
import * as React from 'react';
import { useState } from 'react';
import { Form, FormProps } from 'react-final-form';

interface IProps<TFormValues> extends FormProps<TFormValues, TFormValues> {
  initialValues: TFormValues;
}

export function FinalForm<TFormValues>({
  decorators: decoratorsProp = [],
  ...otherProps
}: IProps<TFormValues>) {
  const [decorators] = useState(() => [
    createFocusDecorator<TFormValues>(),
    ...decoratorsProp,
  ]);

  return <Form {...otherProps} decorators={decorators} />;
}

import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Ellipsis } from '_core/strings/ellipsis';
import dayjs from 'dayjs';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { IShipmentInfoWagonsUsageShipment } from 'shipmentInfo/api';

interface IProps {
  wagonsUsageShipment: IShipmentInfoWagonsUsageShipment[];
}

interface ITableItem {
  index: number;
  shipment: IShipmentInfoWagonsUsageShipment;
}

export function ShipmentInfoWagonUsageShipmentTable({
  wagonsUsageShipment,
}: IProps) {
  const columns = useMemo(
    (): Array<ListTableColumn<ITableItem>> => [
      {
        id: 'wagon',
        label: 'Номер вагона',
        defaultWidth: 100,
        copyCellContent: ({ shipment }) => shipment.wagon,
        renderCellContent: ({ shipment }) => (
          <Ellipsis component="span">{shipment.wagon}</Ellipsis>
        ),
      },
      {
        id: 'startDate',
        label: 'Дата начала периода',
        defaultWidth: 100,
        copyCellContent: ({ shipment }) =>
          dayjs(shipment.startDate).format(DATE_FORMAT_DATE),
        renderCellContent: ({ shipment }) => (
          <Ellipsis component="span">
            {dayjs(shipment.startDate).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'endDate',
        label: 'Дата окончания периода',
        defaultWidth: 100,
        copyCellContent: ({ shipment }) =>
          dayjs(shipment.endDate).format(DATE_FORMAT_DATE),
        renderCellContent: ({ shipment }) => (
          <Ellipsis component="span">
            {dayjs(shipment.endDate).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'duration',
        label: 'Количество суток использования',
        defaultWidth: 100,
        copyCellContent: ({ shipment }) => String(shipment.duration),
        renderCellContent: ({ shipment }) => (
          <Ellipsis component="span">{String(shipment.duration)}</Ellipsis>
        ),
      },
    ],
    []
  );

  const getItemId = useCallback(
    (shipment: ITableItem) => String(shipment.index),
    []
  );

  const items = useMemo(
    () =>
      wagonsUsageShipment.map(
        (shipment, index): ITableItem => ({ index, shipment })
      ),
    [wagonsUsageShipment]
  );

  return <ListTable columns={columns} getItemId={getItemId} items={items} />;
}

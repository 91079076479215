import { Ellipsis } from '_core/strings/ellipsis';
import { Icon } from '@blueprintjs/core';
import * as React from 'react';

import * as css from './entityLinkWithFilter.module.css';
import { Link } from './link';

interface ILink {
  params: Record<string, string | undefined>;
  to: string;
}

interface IProps {
  entityLink: ILink;
  filterLink: ILink;
  text: string;
}

export function EntityLinkWithFilter({ entityLink, filterLink, text }: IProps) {
  return (
    <div className={css.root}>
      <Ellipsis
        component={Link}
        params={entityLink.params}
        rel="noopener"
        target="_blank"
        to={entityLink.to}
      >
        {text}
      </Ellipsis>

      <Link
        className={css.linkIcon}
        params={filterLink.params}
        to={filterLink.to}
      >
        <Icon icon="filter" />
      </Link>
    </div>
  );
}

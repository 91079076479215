import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { Link } from '_core/router5/link';
import { useErpSelector } from '_erp/redux';
import {
  Button,
  Collapse,
  HTMLTable,
  Icon,
  NonIdealState,
} from '@blueprintjs/core';
import { IParksAlertsItem } from 'myDocuments/api';
import * as React from 'react';
import { useState } from 'react';
import invariant from 'tiny-invariant';
import { WagonAlert } from 'wagons/api';
import { getWagonAlertLabel } from 'wagons/utils';

import { selectParksAlertsWidgetData } from '../redux';
import * as css from './parksAlerts.module.css';

function ParkAlertsRow({
  isExpanded,
  parkAlerts,
  onIsExpandedToggle,
}: {
  isExpanded: boolean;
  parkAlerts: IParksAlertsItem;
  onIsExpandedToggle: () => void;
}) {
  return (
    <div className={css.parkAlertsRowRoot}>
      <div className={css.parkAlertsRowHeader}>
        <div className={css.parkAlertsRowToggleAndName}>
          <Icon
            className={css.parkAlertsRowToggleIcon}
            icon={isExpanded ? 'chevron-down' : 'chevron-right'}
            onClick={onIsExpandedToggle}
          />

          <Link
            className={css.parkAlertsRowNameLink}
            params={{ id: String(parkAlerts.parkId) }}
            rel="noopener"
            target="_blank"
            to="parks.edit.main"
          >
            {parkAlerts.parkName}
          </Link>
        </div>

        <div className={css.parkAlertsRowHeaderCount}>
          {parkAlerts.parkAlerts.nonworkingParkCount +
            parkAlerts.parkAlerts.operationBrosCount +
            parkAlerts.parkAlerts.repairDateCount +
            parkAlerts.parkAlerts.stationDowntimeCount +
            parkAlerts.parkAlerts.wagonMileageCount}
        </div>
      </div>

      <Collapse isOpen={isExpanded}>
        <div className={css.parkAlertsRowStatesTableWrapper}>
          <HTMLTable
            bordered
            className={css.parkAlertsRowStatesTable}
            condensed
          >
            <tbody>
              <tr>
                <td>
                  <Link
                    params={{
                      alerts: WagonAlert.NonWorkingPark,
                      id: String(parkAlerts.parkId),
                    }}
                    rel="noopener"
                    target="_blank"
                    to="parks.edit.dislocations"
                  >
                    {getWagonAlertLabel(WagonAlert.NonWorkingPark)}
                  </Link>
                </td>

                <td className={css.parkAlertsRowNumberCell}>
                  {parkAlerts.parkAlerts.nonworkingParkCount}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      alerts: WagonAlert.RepairDate,
                      id: String(parkAlerts.parkId),
                    }}
                    rel="noopener"
                    target="_blank"
                    to="parks.edit.dislocations"
                  >
                    {getWagonAlertLabel(WagonAlert.RepairDate)}
                  </Link>
                </td>

                <td className={css.parkAlertsRowNumberCell}>
                  {parkAlerts.parkAlerts.repairDateCount}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      alerts: WagonAlert.WagonMileage,
                      id: String(parkAlerts.parkId),
                    }}
                    rel="noopener"
                    target="_blank"
                    to="parks.edit.dislocations"
                  >
                    {getWagonAlertLabel(WagonAlert.WagonMileage)}
                  </Link>
                </td>

                <td className={css.parkAlertsRowNumberCell}>
                  {parkAlerts.parkAlerts.wagonMileageCount}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      alerts: WagonAlert.OperationBros,
                      id: String(parkAlerts.parkId),
                    }}
                    rel="noopener"
                    target="_blank"
                    to="parks.edit.dislocations"
                  >
                    {getWagonAlertLabel(WagonAlert.OperationBros)}
                  </Link>
                </td>

                <td className={css.parkAlertsRowNumberCell}>
                  {parkAlerts.parkAlerts.operationBrosCount}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      alerts: WagonAlert.StationDowntime,
                      id: String(parkAlerts.parkId),
                    }}
                    rel="noopener"
                    target="_blank"
                    to="parks.edit.dislocations"
                  >
                    {getWagonAlertLabel(WagonAlert.StationDowntime)}
                  </Link>
                </td>

                <td className={css.parkAlertsRowNumberCell}>
                  {parkAlerts.parkAlerts.stationDowntimeCount}
                </td>
              </tr>
            </tbody>
          </HTMLTable>
        </div>
      </Collapse>
    </div>
  );
}

export function ParksAlertsWidget() {
  const { data, isFetched } = useErpSelector(selectParksAlertsWidgetData);

  const [isParkAlertsExpanded, setIsParkAlertsExpanded] = useState<
    Record<number, boolean | undefined>
  >({});

  const areAllExpanded = data
    ? data.every(parkAlerts => isParkAlertsExpanded[parkAlerts.parkId])
    : false;

  function setAllRequestsIsExpanded(isExpanded: boolean) {
    invariant(data);
    const newMap: Record<number, boolean> = {};

    data.forEach(parkAlerts => {
      newMap[parkAlerts.parkId] = isExpanded;
    });

    setIsParkAlertsExpanded(newMap);
  }

  return !data ? (
    isFetched ? (
      <GenericErrorMessage />
    ) : (
      <CenteredSpinner />
    )
  ) : data.length === 0 ? (
    <NonIdealState
      icon="star-empty"
      title="У вас нет ни одного избранного парка"
    />
  ) : (
    <>
      {areAllExpanded ? (
        <Button
          icon="expand-all"
          text="Свернуть всё"
          onClick={() => {
            setAllRequestsIsExpanded(false);
          }}
        />
      ) : (
        <Button
          icon="collapse-all"
          text="Развернуть всё"
          onClick={() => {
            setAllRequestsIsExpanded(true);
          }}
        />
      )}

      {data.map(parkAlerts => (
        <ParkAlertsRow
          key={parkAlerts.parkId}
          isExpanded={isParkAlertsExpanded[parkAlerts.parkId] === true}
          parkAlerts={parkAlerts}
          onIsExpandedToggle={() => {
            setIsParkAlertsExpanded(prevState => ({
              ...prevState,
              [parkAlerts.parkId]: !prevState[parkAlerts.parkId],
            }));
          }}
        />
      ))}
    </>
  );
}

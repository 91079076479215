import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { TreeTable, TreeTableColumn } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import { updateNodes } from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { ISorting, SortingDirection } from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import {
  ContractsPurchaseSortingField,
  IContractPurchase,
} from 'contractsPurchase/api';
import { IContractSell } from 'contractsSell/api';
import dayjs from 'dayjs';
import { IPartnerSerialized } from 'partners/api';
import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { SortingInput } from '../../_core/sortingInput';
import { Col, Grid, Row } from '../../layout/contentLayout';

export type ContractsTreeTableNodeData =
  | { kind: 'partner'; partner: IPartnerSerialized; contractCount: number }
  | {
      kind: 'contract';
      contract: IContractPurchase | IContractSell;
    }
  | { kind: 'expeditionProtocolsFolder'; contractId: number; count: number }
  | {
      kind: 'expeditionProtocol';
      contractId: number;
      protocolId: number;
      protocolName: string;
    }
  | { kind: 'techrunProtocolsFolder'; contractId: number; count: number }
  | {
      kind: 'techrunProtocol';
      contractId: number;
      protocolId: number;
      protocolName: string;
    }
  | { kind: 'rentProtocolsFolder'; contractId: number; count: number }
  | {
      kind: 'rentProtocol';
      contractId: number;
      protocolId: number;
      protocolName: string;
    }
  | { kind: 'repairProtocolsFolder'; contractId: number; count: number }
  | {
      kind: 'repairProtocol';
      contractId: number;
      protocolId: number;
      protocolNumber: string;
    }
  | { kind: 'agreementsFolder'; contractId: number; count: number }
  | {
      kind: 'agreement';
      contractId: number;
      agreementId: number;
      agreementNumber: string;
      agreementDate: string;
    };

interface IProps {
  contractsBaseSegment: 'contracts.purchase' | 'contracts.sell';
  fetchChildNodes: (
    parentNodes: Array<TreeTableNode<ContractsTreeTableNodeData>>
  ) => Promise<Array<TreeTableNode<ContractsTreeTableNodeData>>>;
  isFetching: boolean;
  listParams: string;
  nodes: Array<TreeTableNode<ContractsTreeTableNodeData>>;
  sorting: ISorting;
  onNodesChange: (
    newNodesOrUpdater:
      | Array<TreeTableNode<ContractsTreeTableNodeData>>
      | ((
          prevNodes: Array<TreeTableNode<ContractsTreeTableNodeData>>
        ) => Array<TreeTableNode<ContractsTreeTableNodeData>>)
  ) => void;
  onSortingChange: (newSorting: ISorting) => void;
}

export function ContractsTreeTable({
  contractsBaseSegment,
  fetchChildNodes,
  isFetching,
  listParams,
  nodes,
  sorting,
  onNodesChange,
  onSortingChange,
}: IProps) {
  const columns = useMemo(
    (): Array<TreeTableColumn<ContractsTreeTableNodeData>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 400,
        sortable: true,
        expandable: true,
        copyCellContent: node => {
          switch (node.data.kind) {
            case 'partner': {
              const { contractCount, partner } = node.data;

              return `${partner.shortName} (${contractCount})`;
            }
            case 'contract': {
              const { contract } = node.data;

              return contract.number;
            }
            case 'expeditionProtocolsFolder': {
              const { count } = node.data;

              return `Протоколы экспедирования (${count})`;
            }
            case 'expeditionProtocol': {
              const { protocolName } = node.data;

              return protocolName;
            }
            case 'techrunProtocolsFolder': {
              const { count } = node.data;

              return `Протоколы техрейса (${count})`;
            }
            case 'techrunProtocol': {
              const { protocolName } = node.data;

              return protocolName;
            }
            case 'rentProtocolsFolder': {
              const { count } = node.data;

              return `Протоколы аренды (${count})`;
            }
            case 'rentProtocol': {
              const { protocolName } = node.data;

              return protocolName;
            }
            case 'repairProtocolsFolder': {
              const { count } = node.data;

              return `Протоколы ремонта (${count})`;
            }
            case 'repairProtocol': {
              const { protocolNumber } = node.data;

              return protocolNumber;
            }
            case 'agreementsFolder': {
              const { count } = node.data;

              return `Доп. соглашения (${count})`;
            }
            case 'agreement': {
              const { agreementNumber, agreementDate } = node.data;

              return `${agreementNumber} (${dayjs(agreementDate).format(
                DATE_FORMAT_DATE
              )})`;
            }
          }
        },
        renderCellContent: node => {
          switch (node.data.kind) {
            case 'partner': {
              const { contractCount, partner } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: partner.id }}
                  rel="noopener"
                  target="_blank"
                  to="partners.edit"
                >
                  {`${partner.shortName} (${contractCount})`}
                </Ellipsis>
              );
            }
            case 'contract': {
              const contract = node.data.contract;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contract.id, listParams }}
                  to={`${contractsBaseSegment}.view`}
                >
                  {contract.number}
                </Ellipsis>
              );
            }
            case 'expeditionProtocolsFolder': {
              const { contractId, count } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId, showArchived: true }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.expeditionProtocols`}
                >{`Протоколы экспедирования (${count})`}</Ellipsis>
              );
            }
            case 'expeditionProtocol': {
              const { contractId, protocolId, protocolName } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId, protocolId, showArchived: true }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.expeditionProtocols.view`}
                >
                  {protocolName}
                </Ellipsis>
              );
            }
            case 'techrunProtocolsFolder': {
              const { contractId, count } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId, showArchived: true }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.techrunProtocols`}
                >{`Протоколы техрейса (${count})`}</Ellipsis>
              );
            }
            case 'techrunProtocol': {
              const { contractId, protocolId, protocolName } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId, protocolId, showArchived: true }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.techrunProtocols.view`}
                >
                  {protocolName}
                </Ellipsis>
              );
            }
            case 'rentProtocolsFolder': {
              const { contractId, count } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.rentProtocols`}
                >{`Протоколы аренды (${count})`}</Ellipsis>
              );
            }
            case 'rentProtocol': {
              const { contractId, protocolId, protocolName } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId, protocolId }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.rentProtocols.view`}
                >
                  {protocolName}
                </Ellipsis>
              );
            }
            case 'repairProtocolsFolder': {
              const { contractId, count } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.repairsProtocols`}
                >{`Протоколы ремонта (${count})`}</Ellipsis>
              );
            }
            case 'repairProtocol': {
              const { contractId, protocolId, protocolNumber } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId, protocolId }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.repairsProtocols.view`}
                >
                  {protocolNumber}
                </Ellipsis>
              );
            }
            case 'agreementsFolder': {
              const { contractId, count } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.agreements`}
                >{`Доп. соглашения (${count})`}</Ellipsis>
              );
            }
            case 'agreement': {
              const {
                contractId,
                agreementId,
                agreementNumber,
                agreementDate,
              } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: contractId, agreementId }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.agreements.view`}
                >
                  {`${agreementNumber} (${dayjs(agreementDate).format(
                    DATE_FORMAT_DATE
                  )})`}
                </Ellipsis>
              );
            }
          }
        },
      },
      {
        id: 'signDate',
        label: 'Дата подписания',
        defaultWidth: 160,
        sortable: true,
        copyCellContent: node => {
          if (node.data.kind !== 'contract') {
            return '';
          }

          const { contract } = node.data;

          return dayjs(contract.signDate).format(DATE_FORMAT_DATE);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'contract') {
            return null;
          }

          const { contract } = node.data;

          return (
            <Ellipsis component="span">
              {dayjs(contract.signDate).format(DATE_FORMAT_DATE)}
            </Ellipsis>
          );
        },
      },
    ],

    [contractsBaseSegment, listParams]
  );

  const handleNodeIsExpandedChange = useCallback(
    (
      targetNode: TreeTableNode<ContractsTreeTableNodeData>,
      newIsExpanded: boolean
    ) => {
      onNodesChange(prevNodes => {
        let updatedNodes = updateNodes(prevNodes, node =>
          node.id === targetNode.id && node.children
            ? {
                ...node,
                children: {
                  ...node.children,
                  isExpanded: newIsExpanded,
                },
              }
            : node
        );

        if (
          newIsExpanded &&
          targetNode.data.kind === 'contract' &&
          targetNode.children
        ) {
          targetNode.children.nodes.forEach(childNode => {
            switch (childNode.data.kind) {
              case 'agreementsFolder':
              case 'expeditionProtocolsFolder':
              case 'rentProtocolsFolder':
              case 'repairProtocolsFolder':
              case 'techrunProtocolsFolder': {
                const { count } = childNode.data;

                if (count <= 10) {
                  updatedNodes = updateNodes(updatedNodes, node =>
                    node.id === childNode.id && node.children
                      ? {
                          ...node,
                          children: {
                            ...node.children,
                            isExpanded: true,
                          },
                        }
                      : node
                  );
                }
              }
            }
          });
        }

        return updatedNodes;
      });
    },
    [onNodesChange]
  );

  const defaultSorting: ISorting<ContractsPurchaseSortingField> = {
    field: ContractsPurchaseSortingField.Name,
    direction: SortingDirection.Ascending,
  };

  return (
    <>
      <Row>
        <Grid>
          <Col align="end">
            <SortingInput
              defaultValue={defaultSorting}
              options={columns
                .filter(column => column.sortable)
                .map(column => ({
                  label: column.label,
                  value: column.id as ContractsPurchaseSortingField,
                }))}
              value={sorting}
              onChange={onSortingChange}
            />
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        <TreeTable
          columns={columns}
          fetchChildNodes={fetchChildNodes}
          isFetching={isFetching}
          nodes={nodes}
          sorting={sorting}
          onNodeIsExpandedChange={handleNodeIsExpandedChange}
          onNodesChange={onNodesChange}
          onSortingChange={onSortingChange}
        />
      </Row>
    </>
  );
}

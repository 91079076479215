import { Checkbox } from '_core/inputs/checkbox';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyForFinalForm } from '_core/inputs/inputMoney';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Toolbar } from '_core/toolbar';
import {
  Alert,
  AnchorButton,
  Button,
  H4,
  Intent,
  Tooltip,
} from '@blueprintjs/core';
import { ContractKind } from 'contracts/types';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { StationsAutocompleteInFormGroupForFinalForm } from 'stations/autocomplete';
import {
  costFromTotalCostForForm,
  totalCostForForm,
  VatRate,
  vatValueForForm,
  vatValueFromTotalCostForForm,
} from 'vatRates/vatRates';

import { ImportRatesDialog } from './importRatesDialog';
import * as css from './ratesField.module.css';

export interface ITechrunProtocolRatesFieldValue {
  arrivalStation: number | null;
  confirmedTransportationsCount: number;
  cost: string;
  costTotal: string;
  departureStation: number | null;
  isLocked: boolean;
  tariffEmpty?: string;
  tariffEmptyTotal?: string;
  tariffEmptyVat?: VatRate;
  tariffLoaded?: string;
  tariffLoadedTotal?: string;
  tariffLoadedVat?: VatRate;
  vatValue: string;
}

interface ITableItem extends ITechrunProtocolRatesFieldValue {
  index: number;
}

interface IProps {
  change: (field: string, value: unknown) => void;
  contractKind: ContractKind;
  hasEmptyTariff: boolean;
  hasLoadedTariff: boolean;
  name: string;
  readOnly: boolean;
  vatRate: VatRate | null;
  onRatesImportUpload: (file: string) => Promise<void>;
}

export function TechrunProtocolRatesField({
  change,
  contractKind,
  hasEmptyTariff,
  hasLoadedTariff,
  name,
  readOnly,
  vatRate,
  onRatesImportUpload,
}: IProps) {
  const { fields, meta } = useFieldArray<ITechrunProtocolRatesFieldValue>(name);

  const [tryingToUnlockRowIndex, setTryingToUnlockRowIndex] = useState<
    number | null
  >(null);

  const unlockRow = useCallback(
    (rowIndex: number) => {
      change(
        name,
        fields.value.map((originalRate, index) =>
          index === rowIndex
            ? { ...originalRate, isLocked: false }
            : originalRate
        )
      );
    },
    [change, fields.value, name]
  );

  const [importRatesDialogIsOpen, setImportRatesDialogIsOpen] = useState(false);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [copyArrivalStation, setCopyArrivalStation] = useState(false);
  const [copyDepartureStation, setCopyDepartureStation] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const disabled = (meta as any).submitting as boolean;

  const columns = useMemo(() => {
    const result: Array<ListTableColumn<ITableItem>> = [];

    result.push(
      {
        id: 'confirmed',
        label: 'Подтверждённые отправки',
        defaultWidth: 100,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <div className={css.transportationsCountCell}>
            <InputGroupForFinalForm
              id={`${fields.name}[${rowIndex}].confirmedTransportationsCount`}
              name={`${fields.name}[${rowIndex}].confirmedTransportationsCount`}
              readOnly
            />

            {rowItem.isLocked && (
              <Button
                className={css.transportationsCountCellUnlockButton}
                disabled={disabled || readOnly}
                icon="edit"
                intent={Intent.PRIMARY}
                minimal
                onClick={() => {
                  if (rowItem.confirmedTransportationsCount === 0) {
                    unlockRow(rowIndex);
                  } else {
                    setTryingToUnlockRowIndex(rowIndex);
                  }
                }}
              />
            )}
          </div>
        ),
      },
      {
        id: 'departureStation',
        label: 'Станция отправления',
        defaultWidth: 250,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <StationsAutocompleteInFormGroupForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].departureStation`}
            name={`${fields.name}[${rowIndex}].departureStation`}
            noBottomMargin
            readOnly={readOnly}
          />
        ),
      },
      {
        id: 'arrivalStation',
        label: 'Станция назначения',
        defaultWidth: 250,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <StationsAutocompleteInFormGroupForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].arrivalStation`}
            name={`${fields.name}[${rowIndex}].arrivalStation`}
            noBottomMargin
            readOnly={readOnly}
          />
        ),
      },
      {
        id: 'cost',
        label: 'Стоимость',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].cost`}
            name={`${fields.name}[${rowIndex}].cost`}
            readOnly={readOnly}
            onChange={newCost => {
              change(
                `${fields.name}[${rowIndex}].vatValue`,
                vatValueForForm(newCost, vatRate || VatRate.None)
              );

              change(
                `${fields.name}[${rowIndex}].costTotal`,
                totalCostForForm(newCost, vatRate || VatRate.None)
              );
            }}
          />
        ),
      },
      {
        id: 'vatValue',
        label: 'НДС (руб.)',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (_rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            id={`${fields.name}[${rowIndex}].vatValue`}
            name={`${fields.name}[${rowIndex}].vatValue`}
            readOnly
          />
        ),
      },
      {
        id: 'costTotal',
        label: 'Стоимость (в т.ч. НДС)',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            disabled={disabled || rowItem.isLocked}
            id={`${fields.name}[${rowIndex}].costTotal`}
            name={`${fields.name}[${rowIndex}].costTotal`}
            readOnly={readOnly}
            onChange={newCostTotal => {
              change(
                `${fields.name}[${rowIndex}].vatValue`,
                vatValueFromTotalCostForForm(
                  newCostTotal,
                  vatRate || VatRate.None
                )
              );

              change(
                `${fields.name}[${rowIndex}].cost`,
                costFromTotalCostForForm(newCostTotal, vatRate || VatRate.None)
              );
            }}
          />
        ),
      }
    );

    if (contractKind === 'sell') {
      result.push(
        {
          id: 'tariffEmpty',
          label: 'Тариф порожний',
          defaultWidth: 150,
          renderCell: ({ children, style }) => (
            <BaseCell noPadding style={style}>
              {children}
            </BaseCell>
          ),
          renderCellContent: (rowItem, rowIndex) => (
            <InputMoneyForFinalForm
              disabled={disabled || rowItem.isLocked}
              id={`${fields.name}[${rowIndex}].tariffEmpty`}
              name={`${fields.name}[${rowIndex}].tariffEmpty`}
              readOnly={readOnly}
              onChange={newTariffEmpty => {
                const { tariffEmptyVat } = rowItem;

                if (!tariffEmptyVat) {
                  return;
                }

                change(
                  `${fields.name}[${rowIndex}].tariffEmptyTotal`,
                  totalCostForForm(newTariffEmpty, tariffEmptyVat)
                );
              }}
            />
          ),
        },
        {
          id: 'tariffLoaded',
          label: 'Тариф гружёный',
          defaultWidth: 150,
          renderCell: ({ children, style }) => (
            <BaseCell noPadding style={style}>
              {children}
            </BaseCell>
          ),
          renderCellContent: (rowItem, rowIndex) => (
            <InputMoneyForFinalForm
              disabled={disabled || rowItem.isLocked}
              id={`${fields.name}[${rowIndex}].tariffLoaded`}
              name={`${fields.name}[${rowIndex}].tariffLoaded`}
              readOnly={readOnly}
              onChange={newTariffLoaded => {
                const { tariffLoadedVat } = rowItem;

                if (!tariffLoadedVat) {
                  return;
                }

                change(
                  `${fields.name}[${rowIndex}].tariffLoadedTotal`,
                  totalCostForForm(newTariffLoaded, tariffLoadedVat)
                );
              }}
            />
          ),
        }
      );

      if (hasLoadedTariff) {
        result.push({
          id: 'tariffLoadedTotal',
          label: 'Тариф гружёный (в т.ч. НДС)',
          defaultWidth: 180,
          renderCell: ({ children, style }) => (
            <BaseCell noPadding style={style}>
              {children}
            </BaseCell>
          ),
          renderCellContent: (rowItem, rowIndex) => (
            <InputMoneyForFinalForm
              disabled={disabled || rowItem.isLocked}
              id={`${fields.name}[${rowIndex}].tariffLoadedTotal`}
              name={`${fields.name}[${rowIndex}].tariffLoadedTotal`}
              readOnly={readOnly}
              onChange={newTariffLoadedTotal => {
                const { tariffLoadedVat } = rowItem;

                if (!tariffLoadedVat) {
                  return;
                }

                change(
                  `${fields.name}[${rowIndex}].tariffLoaded`,
                  costFromTotalCostForForm(
                    newTariffLoadedTotal,
                    tariffLoadedVat
                  )
                );
              }}
            />
          ),
        });
      }

      if (hasEmptyTariff) {
        result.push({
          id: 'tariffEmptyTotal',
          label: 'Тариф порожний (в т.ч. НДС)',
          defaultWidth: 180,
          renderCell: ({ children, style }) => (
            <BaseCell noPadding style={style}>
              {children}
            </BaseCell>
          ),
          renderCellContent: (rowItem, rowIndex) => (
            <InputMoneyForFinalForm
              disabled={disabled || rowItem.isLocked}
              id={`${fields.name}[${rowIndex}].tariffEmptyTotal`}
              name={`${fields.name}[${rowIndex}].tariffEmptyTotal`}
              readOnly={readOnly}
              onChange={newTariffEmptyTotal => {
                const { tariffEmptyVat } = rowItem;

                if (!tariffEmptyVat) {
                  return;
                }

                change(
                  `${fields.name}[${rowIndex}].tariffEmpty`,
                  costFromTotalCostForForm(newTariffEmptyTotal, tariffEmptyVat)
                );
              }}
            />
          ),
        });
      }
    }

    return result;
  }, [
    change,
    contractKind,
    disabled,
    fields.name,
    hasEmptyTariff,
    hasLoadedTariff,
    readOnly,
    unlockRow,
    vatRate,
  ]);

  const getItemId = useCallback((item: ITableItem) => String(item.index), []);

  const items = useMemo(
    () => fields.value.map((item, index): ITableItem => ({ ...item, index })),
    [fields.value]
  );

  return (
    <>
      <VGrid>
        <Row>
          <Toolbar>
            <Checkbox
              checked={copyDepartureStation}
              disabled={disabled || readOnly}
              label="Копировать станцию отправления"
              noBottomMargin
              onChange={setCopyDepartureStation}
            />

            <Checkbox
              checked={copyArrivalStation}
              disabled={disabled || readOnly}
              label="Копировать станцию назначения"
              noBottomMargin
              onChange={setCopyArrivalStation}
            />
          </Toolbar>
        </Row>

        <Row>
          <ListTable
            columns={columns}
            getItemId={getItemId}
            items={items}
            maxHeight={500}
            selectedItems={selectedItems}
            selectionIsDisabled={disabled || readOnly}
            onSelectedItemsChange={setSelectedItems}
          />
        </Row>

        <Row>
          <Toolbar>
            <Button
              disabled={disabled || readOnly}
              text="Добавить ставку"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const lastField = fields.value[fields.length! - 1];

                fields.push({
                  arrivalStation:
                    copyArrivalStation && lastField
                      ? lastField.arrivalStation
                      : null,
                  confirmedTransportationsCount: 0,
                  cost: '',
                  costTotal: totalCostForForm('', vatRate || VatRate.None),
                  departureStation:
                    copyDepartureStation && lastField
                      ? lastField.departureStation
                      : null,
                  isLocked: false,
                  tariffEmpty: '',
                  tariffEmptyVat: VatRate.None,
                  tariffLoaded: '',
                  tariffLoadedVat: VatRate.None,
                  vatValue: vatValueForForm('', vatRate || VatRate.None),
                });
              }}
            />

            <Button
              disabled={disabled || readOnly || selectedItems.length === 0}
              text="Удалить выбранные ставки"
              onClick={() => {
                selectedItems
                  .map(Number)
                  .sort((a, b) => b - a)
                  .forEach(rowIndex => {
                    fields.remove(rowIndex);
                  });

                setSelectedItems([]);
              }}
            />

            <Tooltip
              content="Сохраните протокол перед импортом ставок"
              disabled={readOnly}
            >
              {
                // AnchorButton is used here because simple Button uses 'button'
                // tag and it's 'disabled' attribute, which prevents events
                // needed for tooltip to behave correctly. See here for details
                // https://blueprintjs.com/docs/#core/components/tooltip
              }
              <AnchorButton
                disabled={disabled || !readOnly}
                intent={Intent.PRIMARY}
                text="Импорт ставок из .xlsx"
                onClick={() => setImportRatesDialogIsOpen(true)}
              />
            </Tooltip>
          </Toolbar>
        </Row>
      </VGrid>

      <Alert
        cancelButtonText="Отмена"
        confirmButtonText="Редактировать"
        intent={Intent.DANGER}
        isOpen={tryingToUnlockRowIndex != null}
        onCancel={() => {
          setTryingToUnlockRowIndex(null);
        }}
        onConfirm={() => {
          if (tryingToUnlockRowIndex == null) {
            return;
          }

          unlockRow(tryingToUnlockRowIndex);
          setTryingToUnlockRowIndex(null);
        }}
      >
        <H4>Внимание!</H4>

        {tryingToUnlockRowIndex != null && (
          <p>
            При изменении ставки изменятся{' '}
            {fields.value[tryingToUnlockRowIndex].confirmedTransportationsCount}{' '}
            подтверждённых отправок.
          </p>
        )}
      </Alert>

      <ImportRatesDialog
        isOpen={importRatesDialogIsOpen}
        onClose={() => setImportRatesDialogIsOpen(false)}
        onSuccess={() => setImportRatesDialogIsOpen(false)}
        onUpload={onRatesImportUpload}
      />
    </>
  );
}

import { meReducer } from '_core/me/me';
import { combineReducers } from '@reduxjs/toolkit';

import { homeRouteReducer } from './home/redux';
import { parksEditRouteReducer } from './parks/edit';
import { statsReducer } from './stats';

export const reducer = combineReducers({
  home: homeRouteReducer,
  me: meReducer,
  parks: combineReducers({
    edit: parksEditRouteReducer,
  }),
  stats: statsReducer,
});

import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { fetchRelated } from '_core/api/fetchRelated';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { createDateRange, isValidDate, parseDate } from '_core/dates/utils';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { MonthsMultiSelectFilter } from '_core/filters/monthMultiSelect';
import { SearchForm } from '_core/forms/searchForm';
import { usePreference } from '_core/me/usePreference';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TreeTableNode } from '_core/react-window/types';
import {
  everyNode,
  findNode,
  setAllNodesIsExpanded,
} from '_core/react-window/utils';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import dayjs from 'dayjs';
import {
  ExpeditionRequestsSortingField,
  ExpeditionRequestsTreeSortingField,
  fetchExpeditionRequests,
  fetchExpeditionRequestsTree,
} from 'expeditionRequests/api';
import {
  expeditionRequestStatusOptions,
  isExpeditionRequestStatus,
} from 'expeditionRequests/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersFilter } from 'partners/filter';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

import {
  ExpeditionRequestsTreeTable,
  ExpeditionRequestsTreeTableNodeData,
  IExpeditionRequestsTreeTableItem,
} from './treeTable';

export default function ExpeditionRequestsListRoute() {
  const { route, router } = useRouteNode('expeditions.requests');
  const api = useApiClient();

  const [favoriteDocs] = usePreference<boolean>(['favoriteDocs']);

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'startDate',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const startMonthsFilterValue = useMemo(() => {
    const param = route.params.startMonths as string | undefined;

    return param
      ? param
          .split(',')
          .map(str => parseDate(str))
          .filter(isValidDate)
      : [];
  }, [route.params.startMonths]);

  const commonFetchParams = useMemo(
    () => ({
      favoriteRequests: favoriteDocs,
      hasWagons: route.params.hasWagons
        ? (route.params.hasWagons as string).split(',')
        : undefined,
      partner: isFinite(route.params.partner)
        ? Number(route.params.partner)
        : undefined,
      search: route.params.search ? String(route.params.search) : undefined,
      startMonths: startMonthsFilterValue,
      statuses: route.params.statuses
        ? (route.params.statuses as string)
            .split(',')
            .filter(isExpeditionRequestStatus)
        : undefined,
    }),
    [
      favoriteDocs,
      route.params.hasWagons,
      route.params.partner,
      route.params.search,
      route.params.statuses,
      startMonthsFilterValue,
    ]
  );

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [commonFetchParams, favoriteDocs, api, sorting],
    async () => {
      const treeSorting: Array<ISorting<ExpeditionRequestsTreeSortingField>> =
        [];

      switch (sorting.field) {
        case 'endDate':
          treeSorting.push({
            field: ExpeditionRequestsTreeSortingField.EndDate,
            direction: sorting.direction,
          });
          break;
        case 'name':
          treeSorting.push({
            field: ExpeditionRequestsTreeSortingField.Name,
            direction: sorting.direction,
          });
          break;
        case 'startDate':
          treeSorting.push({
            field: ExpeditionRequestsTreeSortingField.StartDate,
            direction: sorting.direction,
          });
          break;
        default:
          throw new Error(`Unexpected sorting field: "${sorting.field}"`);
      }

      const treeResponse = await fetchExpeditionRequestsTree(api, {
        ...commonFetchParams,
        sorting: treeSorting,
      });

      return {
        filterOptions: treeResponse.meta.filterOptions,
        nodes: treeResponse.results.map(
          (partner): TreeTableNode<ExpeditionRequestsTreeTableNodeData> => {
            const prevPartnerNode = data
              ? findNode(
                  data.nodes,
                  node =>
                    node.data.kind === 'partner' &&
                    node.data.id === partner.partner
                )
              : undefined;

            return {
              id: `partner-${partner.partner}`,
              data: {
                kind: 'partner',
                id: partner.partner,
                partnerName: partner.partnerName,
                protocolCount: partner.protocols.length,
              },
              children: {
                isExpanded:
                  prevPartnerNode && prevPartnerNode.children
                    ? prevPartnerNode.children.isExpanded
                    : false,

                nodes: partner.protocols.map(
                  (
                    protocol
                  ): TreeTableNode<ExpeditionRequestsTreeTableNodeData> => {
                    const prevProtocolNode = data
                      ? findNode(
                          data.nodes,
                          node =>
                            node.data.kind === 'protocol' &&
                            node.data.id === protocol.id
                        )
                      : undefined;

                    return {
                      id: `protocol-${protocol.id}`,
                      data: {
                        kind: 'protocol',
                        contract: protocol.contract,
                        id: protocol.id,
                        protocolName: protocol.name,
                        requestCount: protocol.requestsCount,
                      },
                      children: {
                        hasMoreChildNodesToFetch: true,
                        isExpanded:
                          prevProtocolNode && prevProtocolNode.children
                            ? prevProtocolNode.children.isExpanded
                            : false,
                        isFetching: false,
                        nodes: [],
                      },
                    };
                  }
                ),
              },
            };
          }
        ),
      };
    }
  );

  const fetchChildNodes = useCallback(
    async (
      parentNodes: Array<TreeTableNode<ExpeditionRequestsTreeTableNodeData>>
    ) => {
      const parentNode = parentNodes[parentNodes.length - 1];

      if (parentNode.data.kind === 'protocol') {
        const protocolId = parentNode.data.id;

        const requestsSorting: Array<ISorting<ExpeditionRequestsSortingField>> =
          [];

        switch (sorting.field) {
          case 'endDate':
            requestsSorting.push({
              field: ExpeditionRequestsSortingField.EndDate,
              direction: sorting.direction,
            });
            break;
          case 'name':
            requestsSorting.push({
              field: ExpeditionRequestsSortingField.Name,
              direction: sorting.direction,
            });
            break;
          case 'startDate':
            requestsSorting.push({
              field: ExpeditionRequestsSortingField.StartDate,
              direction: sorting.direction,
            });
            break;
          default:
            throw new Error(`Unexpected sorting field: "${sorting.field}"`);
        }

        const listResponse = await fetchAllPages(page =>
          fetchExpeditionRequests(api, {
            ...commonFetchParams,
            page,
            sorting: requestsSorting,
            sellProtocol: protocolId,
          })
        );

        const requests = (await fetchRelated(
          api,
          { sellContract: '/contracts_sell' },
          listResponse.results
        )) as unknown as IExpeditionRequestsTreeTableItem[];

        return requests.map(
          (request): TreeTableNode<ExpeditionRequestsTreeTableNodeData> => ({
            data: { kind: 'request', request },
            id: `request-${request.id}`,
          })
        );
      }

      throw new Error(`Unexpected node kind: "${parentNode.data.kind}"`);
    },
    [commonFetchParams, api, sorting.direction, sorting.field]
  );

  const handleNodesChange = useCallback(
    (
      newNodesOrUpdater:
        | Array<TreeTableNode<ExpeditionRequestsTreeTableNodeData>>
        | ((
            prevNodes: Array<TreeTableNode<ExpeditionRequestsTreeTableNodeData>>
          ) => Array<TreeTableNode<ExpeditionRequestsTreeTableNodeData>>)
    ) => {
      updateData(
        prevData =>
          prevData && {
            ...prevData,
            nodes:
              typeof newNodesOrUpdater === 'function'
                ? newNodesOrUpdater(prevData.nodes)
                : newNodesOrUpdater,
          }
      );
    },
    [updateData]
  );

  const listParams = JSON.stringify(route.params);

  const [startMonths, setStartMonths] = usePreference<string | null>([
    'expeditions',
    'requests',
    'list',
    'startMonths',
  ]);

  useEffect(() => {
    if (startMonths) {
      if (route.params.startMonths !== startMonths) {
        router.navigate(route.name, { ...route.params, startMonths });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startMonthsFilterDates = useMemo(() => {
    if (!data) {
      return [];
    }

    const [fromDate, toDate] = data.filterOptions.startDate;

    return createDateRange(
      'month',
      fromDate,
      dayjs(toDate).add(1, 'month').toDate()
    ).reverse();
  }, [data]);

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },

    [applyFilterParams]
  );

  const allIsExpanded = data
    ? everyNode(data.nodes, node => !node.children || node.children.isExpanded)
    : false;

  return (
    <>
      <FormattedTitle>Заявки экспедирования</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <WagonNumbersFilter
                  initialValue={route.params.hasWagons}
                  onApply={hasWagons => {
                    applyFilterParams({ hasWagons });
                  }}
                />

                <MonthsMultiSelectFilter
                  initialValue={startMonthsFilterValue}
                  isFetching={isFetching}
                  label="Месяц начала"
                  monthDates={startMonthsFilterDates}
                  onApply={newStartMonths => {
                    const newStartMonthsStr =
                      newStartMonths.length !== 0
                        ? newStartMonths
                            .map(date =>
                              dayjs(date).format(DATE_FORMAT_API_DATE)
                            )
                            .join(',')
                        : undefined;

                    setStartMonths(newStartMonthsStr || null);
                    applyFilterParams({ startMonths: newStartMonthsStr });
                  }}
                />

                <CheckboxListFilter
                  initialValue={route.params.statuses}
                  label="Статусы"
                  options={expeditionRequestStatusOptions}
                  onApply={statuses => {
                    applyFilterParams({ statuses });
                  }}
                />

                <PartnersFilter
                  initialValue={route.params.partner}
                  onApply={partner => {
                    applyFilterParams({ partner });
                  }}
                />

                <Button text="Обновить" onClick={refetch} />

                <Button
                  text="Сбросить фильтры"
                  onClick={() => {
                    router.navigate(route.name);
                  }}
                />

                <LinkButton
                  intent={Intent.PRIMARY}
                  params={{ listParams }}
                  text="Добавить"
                  to="expeditions.requests.create"
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Toolbar>
            {allIsExpanded ? (
              <Button
                icon="collapse-all"
                text="Свернуть всё"
                onClick={() => {
                  updateData(
                    prevData =>
                      prevData && {
                        ...prevData,
                        nodes: setAllNodesIsExpanded(prevData.nodes, false),
                      }
                  );
                }}
              />
            ) : (
              <Button
                icon="expand-all"
                text="Развернуть всё"
                onClick={() => {
                  updateData(
                    prevData =>
                      prevData && {
                        ...prevData,
                        nodes: setAllNodesIsExpanded(prevData.nodes, true),
                      }
                  );
                }}
              />
            )}
          </Toolbar>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <ExpeditionRequestsTreeTable
              fetchChildNodes={fetchChildNodes}
              isFetching={isFetching}
              listParams={listParams}
              nodes={data.nodes}
              sorting={sorting}
              onSortingChange={handleSortingChange}
              onNodesChange={handleNodesChange}
            />
          )}
        </Row>
      </VGrid>
    </>
  );
}

import { BaseForm } from '_core/forms/baseForm';
import { Button, InputGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';

interface IProps {
  initialValue: string | undefined;
  onApply: (search: string | undefined) => void;
}

export function SearchForm({ initialValue, onApply }: IProps) {
  const prevInitialValue = React.useRef(initialValue);
  const [valueState, setValue] = React.useState(initialValue || '');

  let value = valueState;

  if (initialValue !== prevInitialValue.current) {
    prevInitialValue.current = initialValue;
    value = initialValue || '';
    setValue(value);
  }

  return (
    <BaseForm
      onSubmit={event => {
        onApply(value || undefined);

        event.preventDefault();
      }}
    >
      <InputGroup
        autoFocus
        leftIcon="search"
        placeholder="Поиск..."
        rightElement={
          <Button
            data-testid="submit"
            icon="arrow-right"
            intent={Intent.PRIMARY}
            minimal
            type="submit"
          />
        }
        type="search"
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.currentTarget.value);
        }}
      />
    </BaseForm>
  );
}

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { EXPEDITION_REQUESTS_BASE_ENDPOINT } from './api';
import { IExpeditionRequestSerialized } from './types';

export const ExpeditionRequestsAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<IExpeditionRequestSerialized, number>({
      baseEndpoint: EXPEDITION_REQUESTS_BASE_ENDPOINT,
      itemToOption: request => ({
        label: request.requestName,
        value: request.id,
      }),

      getLink: request => ({
        params: { id: String(request.id) },
        to: 'expeditions.requests.view',
      }),
    })
  );

export const ExpeditionRequestsAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    ExpeditionRequestsAutocompleteInFormGroup
  );

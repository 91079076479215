import { useApiClient } from '_core/api/context';
import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import {
  FormNavigationLock,
  useFormNavigationLock,
} from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { fetchManyParks, Park } from 'parks/api';
import { ParksAutocompleteInFormGroup } from 'parks/autocomplete';
import * as React from 'react';
import { useField } from 'react-final-form';
import { ITechrunRequest } from 'techrunRequests/types';

function ParksField({ name }: { name: string }) {
  const { input, meta } = useField<number[]>(name);
  const disabled = meta.submitting;
  const api = useApiClient();

  const parks = useAsyncData([api, input.value], () =>
    fetchManyParks(api, input.value)
  );

  return (
    <div>
      <ParksAutocompleteInFormGroup
        disabled={disabled}
        label="Добавить парк"
        value={null}
        onChange={(id: number | null) => {
          if (id == null) {
            return;
          }

          const newValue = Array.from(new Set(input.value.concat([id])));

          input.onChange(newValue);
        }}
      />

      <Table
        emptyMessage="Нет парков"
        isFetching={parks.isFetching}
        items={parks.data}
      >
        <Column
          id="name"
          label="Наименование"
          cell={(park: Park) => (
            <LinkCell params={{ id: park.id }} to="parks.edit">
              {park.name}
            </LinkCell>
          )}
        />

        <Column
          id="actions"
          width={52}
          cell={(park: Park) => (
            <Button
              disabled={disabled}
              icon="delete"
              intent={Intent.DANGER}
              minimal
              onClick={() => {
                if (
                  // eslint-disable-next-line no-alert
                  window.confirm(
                    'Вы уверены что хотите удалить парк из заявки?'
                  )
                ) {
                  input.onChange(input.value.filter(id => id !== park.id));
                }
              }}
            />
          )}
        />
      </Table>
    </div>
  );
}

interface IFormValues {
  parks: number[];
}

interface IProps {
  techrunRequest: ITechrunRequest;
  updateTechrunRequestData: (newTechrunRequest: ITechrunRequest) => void;
}

export function TechrunRequestsViewParksTab({
  techrunRequest,
  updateTechrunRequestData,
}: IProps) {
  const api = useApiClient();
  const navigationLock = useFormNavigationLock('techruns.requests.view');

  return (
    <FinalForm<IFormValues>
      initialValues={{ parks: techrunRequest.parks }}
      onSubmit={async values => {
        try {
          const savedTechrunRequest = await api.put<ITechrunRequest>(
            `/techrun_requests/${techrunRequest.id}`,
            {
              ...techrunRequest,
              parks: values.parks,
            }
          );

          updateTechrunRequestData(savedTechrunRequest);
          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось изменить парки заявки: Непредвиденная ошибка'
          );
        }
      }}
    >
      {({ dirty, error, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <VGrid>
            <Row>
              <FormNavigationLock
                navigationLock={navigationLock}
                shouldLock={dirty}
              />
              <FormErrors error={error || submitError} />

              <Grid>
                <Col span={4}>
                  <ParksField name="parks" />
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { IStationSerialized } from './types';

export const StationsAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<IStationSerialized, number>({
      baseEndpoint: '/directories_trainstations',
      placeholder: 'Наименование станции...',

      itemToOption: station => ({
        label: station.name,
        value: station.id,
      }),
    })
  );

export const StationsAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    StationsAutocompleteInFormGroup
  );

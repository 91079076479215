import { DocumentStatus } from 'documents/types';
import { VatRate } from 'vatRates/vatRates';

export enum Payer {
  Renter = 'RENTER',
  Lessor = 'LESSOR',
}

export enum StartRepairDate {
  FromRepairDate = 'FROM_REPAIR_DATE',
  ToRepairDate = 'TO_REPAIR_DATE',
  Vu36Date = 'VU36_DATE',
}

export enum StopRepairDate {
  FromRepairDate = 'FROM_REPAIR_DATE',
  ToRepairDate = 'TO_REPAIR_DATE',
  Vu23Date = 'VU23_DATE',
}

export interface IRentProtocolSerialized {
  contract: number;
  date: string;
  dayRate: string;
  dayRateTotal: string;
  endDate: string;
  expensiveComponentsPayer: Payer;
  files: string[];
  fromRoutineRepairTariffPayer: Payer;
  fromScheduledRepairTariffPayer: Payer;
  hasRentRateRoutineRepair: boolean;
  hasRentRateScheduledRepair: boolean;
  id: number;
  note: string;
  number: string;
  partnerSign: number;
  protocolName: string;
  protocolNumber: string;
  receiveTariffPayer: Payer;
  returnTariffPayer: Payer;
  routineRepairPayer: Payer;
  scheduledRepairPayer: Payer;
  signerAuthority: string;
  startDate: string;
  startRentRateRoutineRepair: StartRepairDate | '';
  startRentRateScheduledRepair: StartRepairDate | '';
  status: DocumentStatus;
  stopRentRateRoutineRepair: StopRepairDate | '';
  stopRentRateScheduledRepair: StopRepairDate | '';
  toRoutineRepairTariffPayer: Payer;
  toScheduledRepairTariffPayer: Payer;
  vatRate: VatRate;
  wagonType: number | null;
}

import { TreeTable } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import { ISorting, SortingDirection } from '_core/sorting';
import * as React from 'react';
import { useMemo } from 'react';

import { SortingInput } from '../../_core/sortingInput';
import { Col, Grid, Row } from '../../layout/contentLayout';
import { PartnersSortingField } from '../../partners/api';
import {
  ContactsTreeTableNodeData,
  getContactsTreeTableColumns,
} from './columns';

interface IProps {
  isFetching: boolean;
  nodes: Array<TreeTableNode<ContactsTreeTableNodeData>>;
  sorting: ISorting;
  onNodesChange: (
    newNodesOrUpdater:
      | Array<TreeTableNode<ContactsTreeTableNodeData>>
      | ((
          prevNodes: Array<TreeTableNode<ContactsTreeTableNodeData>>
        ) => Array<TreeTableNode<ContactsTreeTableNodeData>>)
  ) => void;
  onSortingChange: (newSorting: ISorting) => void;
}

export function ContactsTreeTable({
  isFetching,
  nodes,
  sorting,
  onNodesChange,
  onSortingChange,
}: IProps) {
  const columns = useMemo(() => getContactsTreeTableColumns(), []);

  const defaultSorting: ISorting<PartnersSortingField> = {
    field: PartnersSortingField.Modified,
    direction: SortingDirection.Descending,
  };

  return (
    <>
      <Row>
        <Grid>
          <Col align="end">
            <SortingInput
              defaultValue={defaultSorting}
              options={columns
                .filter(column => column.sortable)
                .map(column => ({
                  label: column.label,
                  value: column.id as PartnersSortingField,
                }))}
              value={sorting}
              onChange={onSortingChange}
            />
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        <TreeTable
          columns={columns}
          isFetching={isFetching}
          nodes={nodes}
          sorting={sorting}
          onNodesChange={onNodesChange}
          onSortingChange={onSortingChange}
        />
      </Row>
    </>
  );
}

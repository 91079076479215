import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { lastDayOfMonth } from '_core/dates/utils';
import { decimalOrNull } from '_core/decimal';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { SelectForFinalForm } from '_core/inputs/select';
import { formatMoney } from '_core/money/formatMoney';
import { Link } from '_core/router5/link';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, FormGroup, H4, InputGroup, Intent } from '@blueprintjs/core';
import { ConsignersAutocompleteInFormGroupForFinalForm } from 'consigners/autocomplete';
import dayjs from 'dayjs';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { IPartnerInline } from 'partners/api';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { fetchRentWagonPurchaseList } from 'rentWagonPurchase/api';
import { StationsAutocompleteInFormGroupForFinalForm } from 'stations/autocomplete';
import { ITechrunRate, techrunRateToOption } from 'techrunRates/types';
import {
  ITechrunRequest,
  TechrunRequestStatus,
  techrunRequestStatusOptions,
} from 'techrunRequests/types';
import { IWagonType } from 'wagonTypes/api';
import { wagonTypeToOption } from 'wagonTypes/utils';

import * as css from './form.module.css';
import {
  ITechrunRequestsFormGu12Item,
  TechrunRequestsGu12Field,
} from './gu12Field';
import {
  IImportTransportationsDialogFormValues,
  ImportTransportationsDialog,
} from './importTransportationsDialog';
import { PurchaseRatesForPartnerProvider } from './purchaseRatesForPartner';
import {
  ITechrunRequestFormWagonItem,
  WagonsField,
} from './wagonsField/wagonsField';

export interface ITechrunRequestFormValues {
  arrivalStation: number | null;
  departureStation: number | null;
  consigner: number | null;
  endDate: Date | null;
  gu12Docs: ITechrunRequestsFormGu12Item[];
  name: string;
  partner: number | null;
  sellRate: string | null;
  startDate: Date | null;
  status: TechrunRequestStatus;
  trackRepair: boolean;
  wagons: ITechrunRequestFormWagonItem[];
  wagonsRequired: string;
  wagonType: string | null;
}

function getTechrunRatesSelectHelperText(
  selectedRate: ITechrunRate | undefined
) {
  if (!selectedRate) {
    return undefined;
  }

  const vatRate = decimalOrNull(selectedRate.vatRate);
  const vatValue = decimalOrNull(selectedRate.vatValue);
  const costTotal = decimalOrNull(selectedRate.costTotal);

  return vatRate && vatValue && costTotal
    ? `НДС ${vatRate.toPrecision(2)}% (${formatMoney(
        vatValue
      )} руб.), итого ${formatMoney(costTotal)} руб.`
    : undefined;
}

function TechrunRequestFormView({
  arrivalStation,
  change,
  departureStation,
  dirty,
  editingLocked,
  endDate,
  error,
  handleSubmit,
  initialValues,
  navigationLock,
  partner,
  reset,
  sellRate,
  startDate,
  status,
  submit,
  submitting,
  techrunRequest,
  wagonTypes,
  onCancelEditing,
  onDuplicateRequest,
  onStartEditing,
}: {
  arrivalStation: number | null;
  change: (field: string, value: unknown) => void;
  departureStation: number | null;
  dirty: boolean;
  editingLocked: boolean | undefined;
  endDate: Date | null;
  error: string | undefined;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  initialValues: Partial<ITechrunRequestFormValues>;
  navigationLock: IFormNavigationLock;
  partner: number | null;
  reset: () => void;
  sellRate: string | null;
  startDate: Date | null;
  status: TechrunRequestStatus;
  submit: () => void;
  submitting: boolean;
  techrunRequest: ITechrunRequest | undefined;
  wagonTypes: IWagonType[];
  onCancelEditing: (() => void) | undefined;
  onDuplicateRequest: (() => void) | undefined;
  onStartEditing: (() => void) | undefined;
}) {
  const api = useApiClient();

  const rentWagons = useAsyncData([api], async () => {
    const response = await fetchAllPages(page =>
      fetchRentWagonPurchaseList(api, { page })
    );

    return response.results;
  });

  const sellRates = useAsyncData(
    [arrivalStation, departureStation, partner, api, startDate],
    async () => {
      if (
        arrivalStation == null ||
        departureStation == null ||
        partner == null ||
        startDate == null
      ) {
        return [];
      }

      return (
        await fetchAllPages(page =>
          api.get<ListResponse<ITechrunRate>>('/techrun_rates_sell', {
            arrivalStation,
            departureStation,
            forDate: dayjs(startDate).format(DATE_FORMAT_API_DATE),
            page,
            partner,
          })
        )
      ).results;
    }
  );

  const showStatusConfirmButton =
    status !== initialValues.status && editingLocked;

  const importTransportationsDialog = useDialog<{
    initialValues: IImportTransportationsDialogFormValues;
  }>();

  const purchasePartners = useAsyncData(
    [arrivalStation, departureStation, endDate, api, startDate],
    async () => {
      if (
        arrivalStation == null ||
        departureStation == null ||
        endDate == null ||
        startDate == null
      ) {
        return [];
      }

      const partners = await api.post<IPartnerInline[]>(
        '/techrun_requests/suppliers',
        {
          arrivalStation,
          departureStation,
          startDate: dayjs(startDate).format(DATE_FORMAT_API_DATE),
          endDate: dayjs(endDate).format(DATE_FORMAT_API_DATE),
        }
      );

      return partners;
    }
  );

  return !purchasePartners.data || !rentWagons.data || !sellRates.data ? (
    purchasePartners.isFetching ||
    rentWagons.isFetching ||
    sellRates.isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <PurchaseRatesForPartnerProvider
      arrivalStation={arrivalStation}
      departureStation={departureStation}
      endDate={endDate}
      startDate={startDate}
    >
      <BaseForm onSubmit={handleSubmit}>
        <FormNavigationLock
          navigationLock={navigationLock}
          shouldLock={dirty}
        />

        <FormErrors error={error} />

        <VGrid>
          <Row>
            <Grid>
              <Col span={4}>
                <H4>Основная информация</H4>

                <CheckboxForFinalForm
                  disabled={editingLocked}
                  id="trackRepair"
                  label="Следить за ремонтом"
                  name="trackRepair"
                />

                <FormGroupForFinalForm
                  label="Наименование"
                  labelFor="name"
                  name="name"
                >
                  <InputGroupForFinalForm
                    id="name"
                    name="name"
                    readOnly={editingLocked}
                  />
                </FormGroupForFinalForm>

                <FormGroup label="Номер заявки" labelFor="number">
                  <InputGroup
                    id="number"
                    name="number"
                    readOnly
                    value={techrunRequest?.number ?? ''}
                  />
                </FormGroup>

                <Grid>
                  <Col span={showStatusConfirmButton ? 2 : 4}>
                    <FormGroupForFinalForm
                      label="Статус заявки"
                      labelFor="status"
                      name="status"
                    >
                      <SelectForFinalForm
                        fill
                        id="status"
                        name="status"
                        options={techrunRequestStatusOptions}
                      />
                    </FormGroupForFinalForm>
                  </Col>

                  {showStatusConfirmButton && (
                    <Col span={2}>
                      <div className={css.statusConfirmButtonWrap}>
                        <Button
                          disabled={submitting}
                          fill
                          intent={Intent.PRIMARY}
                          text="Подтвердить"
                          onClick={() => {
                            submit();
                          }}
                        />
                      </div>
                    </Col>
                  )}
                </Grid>

                <PartnersAutocompleteInFormGroupForFinalForm
                  id="partner"
                  label="Контрагент"
                  name="partner"
                  readOnly={editingLocked}
                  required
                  onChange={() => {
                    change('sellRate', null);
                  }}
                />

                <ConsignersAutocompleteInFormGroupForFinalForm
                  id="consigner"
                  label="Грузоотправитель"
                  name="consigner"
                  readOnly={editingLocked}
                />

                <Grid>
                  <Col span={2}>
                    <DateInputInFormGroupForFinalForm
                      fill
                      id="startDate"
                      label="Дата начала"
                      name="startDate"
                      readOnly={editingLocked}
                      required
                      onChange={newStartDate => {
                        change('sellRate', null);

                        if (newStartDate) {
                          change('endDate', lastDayOfMonth(newStartDate));
                        }
                      }}
                    />
                  </Col>

                  <Col span={2}>
                    <DateInputInFormGroupForFinalForm
                      fill
                      id="endDate"
                      label="Дата окончания"
                      name="endDate"
                      readOnly={editingLocked}
                      required
                    />
                  </Col>
                </Grid>

                <FormGroupForFinalForm
                  label="Вид подвижного состава"
                  labelFor="wagonType"
                  name="wagonType"
                >
                  <SelectForFinalForm
                    disabled={editingLocked}
                    fill
                    id="wagonType"
                    name="wagonType"
                    options={wagonTypes.map(wagonTypeToOption)}
                  />
                </FormGroupForFinalForm>

                <StationsAutocompleteInFormGroupForFinalForm
                  id="departureStation"
                  label="Станция отправления"
                  name="departureStation"
                  readOnly={editingLocked}
                  required
                  onChange={() => {
                    change('sellRate', null);
                  }}
                />

                <StationsAutocompleteInFormGroupForFinalForm
                  id="arrivalStation"
                  label="Станция назначения"
                  name="arrivalStation"
                  readOnly={editingLocked}
                  required
                  onChange={() => {
                    change('sellRate', null);
                  }}
                />

                <FormGroupForFinalForm
                  helperText={getTechrunRatesSelectHelperText(
                    sellRates.data.find(rate => rate.id === Number(sellRate))
                  )}
                  label="Ставка для клиента"
                  labelFor="sellRate"
                  labelInfo="*"
                  name="sellRate"
                >
                  <SelectForFinalForm
                    disabled={
                      editingLocked ||
                      arrivalStation == null ||
                      departureStation == null ||
                      partner == null ||
                      startDate == null
                    }
                    fill
                    id="sellRate"
                    name="sellRate"
                    options={sellRates.data.map(techrunRateToOption)}
                    withEmptyOption
                  />
                </FormGroupForFinalForm>

                <FormGroupForFinalForm
                  label="Необходимое количество вагонов"
                  labelFor="wagonsRequired"
                  name="wagonsRequired"
                >
                  <InputGroupForFinalForm
                    id="wagonsRequired"
                    name="wagonsRequired"
                    readOnly={editingLocked}
                  />
                </FormGroupForFinalForm>

                {techrunRequest && (
                  <>
                    <p>
                      <Link
                        params={{
                          requests: String(techrunRequest.id),
                          status: 'new',
                        }}
                        rel="noopener"
                        target="_blank"
                        to="techruns.transportations"
                      >
                        Неподтверждённые отправки
                      </Link>
                    </p>

                    <p>
                      <Link
                        params={{
                          id: techrunRequest.sellContract,
                          protocolId: techrunRequest.sellProtocol,
                          showArchived: true,
                        }}
                        rel="noopener"
                        target="_blank"
                        to="contracts.sell.view.techrunProtocols.view"
                      >
                        Протокол
                      </Link>
                    </p>

                    <p>
                      <Link
                        params={{
                          requests: String(techrunRequest.id),
                          status: 'confirmed',
                        }}
                        rel="noopener"
                        target="_blank"
                        to="techruns.transportations"
                      >
                        Подтверждённые отправки
                      </Link>
                    </p>

                    <p>
                      <Button
                        intent={Intent.SUCCESS}
                        text="Добавить отправки вручную"
                        onClick={() => {
                          importTransportationsDialog.open({
                            initialValues: {
                              file: null,
                              purchaseRate: null,
                              purchaseRent: false,
                            },
                          });
                        }}
                      />
                    </p>
                  </>
                )}
              </Col>

              <Col span={8}>
                <VGrid>
                  <Row>
                    <H4>Вагоны</H4>

                    <WagonsField
                      change={change}
                      disabled={submitting}
                      editingLocked={editingLocked}
                      name="wagons"
                      purchasePartners={purchasePartners.data}
                      rentWagons={rentWagons.data}
                      sellRates={sellRates.data}
                      techrunRequest={techrunRequest}
                    />
                  </Row>

                  <Row>
                    <H4>Документы ГУ-12</H4>

                    <TechrunRequestsGu12Field
                      name="gu12Docs"
                      readOnly={editingLocked}
                    />
                  </Row>
                </VGrid>
              </Col>
            </Grid>
          </Row>

          <Row stickToBottom>
            <Toolbar align="right">
              {onDuplicateRequest && (
                <Button
                  disabled={submitting}
                  icon="duplicate"
                  text="Дублировать"
                  onClick={onDuplicateRequest}
                />
              )}

              {onStartEditing && (
                <Button
                  disabled={submitting}
                  icon="unlock"
                  intent={Intent.DANGER}
                  text="Редактировать"
                  onClick={onStartEditing}
                />
              )}

              {onCancelEditing && (
                <Button
                  disabled={submitting}
                  text="Отменить"
                  onClick={() => {
                    reset();
                    onCancelEditing();
                  }}
                />
              )}

              {!editingLocked && (
                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                />
              )}
            </Toolbar>
          </Row>
        </VGrid>
      </BaseForm>

      {techrunRequest && importTransportationsDialog.state && (
        <ImportTransportationsDialog
          initialValues={importTransportationsDialog.state.initialValues}
          isOpen={importTransportationsDialog.state.isOpen}
          purchasePartners={purchasePartners.data}
          requestId={techrunRequest.id}
          onClose={importTransportationsDialog.close}
          onClosed={importTransportationsDialog.destroy}
        />
      )}
    </PurchaseRatesForPartnerProvider>
  );
}

interface IProps {
  editingLocked?: boolean;
  initialValues: ITechrunRequestFormValues;
  navigationLock: IFormNavigationLock;
  techrunRequest?: ITechrunRequest;
  wagonTypes: IWagonType[];
  onCancelEditing?: () => void;
  onDuplicateRequest?: () => void;
  onStartEditing?: () => void;
  onSubmit: (
    values: ITechrunRequestFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function TechrunRequestForm({
  editingLocked,
  initialValues,
  navigationLock,
  techrunRequest,
  wagonTypes,
  onCancelEditing,
  onDuplicateRequest,
  onStartEditing,
  onSubmit,
}: IProps) {
  return (
    <FinalForm<ITechrunRequestFormValues>
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <TechrunRequestFormView
          arrivalStation={values.arrivalStation}
          change={form.change}
          departureStation={values.departureStation}
          dirty={dirty}
          editingLocked={editingLocked}
          endDate={values.endDate}
          error={error || submitError}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          navigationLock={navigationLock}
          partner={values.partner}
          reset={form.reset}
          sellRate={values.sellRate}
          startDate={values.startDate}
          status={values.status}
          submit={form.submit}
          submitting={submitting}
          techrunRequest={techrunRequest}
          wagonTypes={wagonTypes}
          onCancelEditing={onCancelEditing}
          onDuplicateRequest={onDuplicateRequest}
          onStartEditing={onStartEditing}
        />
      )}
    </FinalForm>
  );
}

import { State } from 'router5';
import invariant from 'tiny-invariant';

const segmentStrToArr = (segmentStr: string) =>
  segmentStr.split('.').filter(Boolean);

export function getNextSubSegment(route: State, baseSegment: string) {
  invariant(
    route.name.startsWith(baseSegment),
    'Cannot get next subSegment from route which do not match baseSegment'
  );

  const baseSegmentArr = segmentStrToArr(baseSegment);
  const routeNameSegmentArr = segmentStrToArr(route.name);

  return routeNameSegmentArr[baseSegmentArr.length] || '';
}

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import {
  IRentWagonPurchaseSerialized,
  RENT_WAGON_PURCHASE_ENDPOINT,
} from './api';

const RentWagonPurchaseAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<IRentWagonPurchaseSerialized, number>({
      baseEndpoint: RENT_WAGON_PURCHASE_ENDPOINT,

      getLink: rentWagonPurchase => ({
        params: { id: String(rentWagonPurchase.id) },
        to: 'rentWagons.purchase.edit',
      }),

      itemToOption: rentWagonPurchase => ({
        label: rentWagonPurchase.shortName,
        value: rentWagonPurchase.id,
      }),
    })
  );

export const RentWagonPurchaseAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    RentWagonPurchaseAutocompleteInFormGroup
  );

import { ISorting, SortingDirection } from '_core/sorting';
import { useIsMobile } from '_core/useIsMobile';
import {
  Button,
  ButtonGroup,
  Classes,
  Menu,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import cx from 'classnames';
import * as React from 'react';

import * as css from './sortingInput.module.css';

interface Option<TValue extends string> {
  label: string;
  value: TValue;
}

interface Props<T extends string> {
  defaultValue: ISorting<T>;
  fill?: boolean;
  options: Array<Option<T>>;
  value: ISorting<T>;
  onChange: (newSorting: ISorting<T>) => void;
}

export function SortingInput<T extends string>({
  defaultValue,
  fill,
  options,
  value,
  onChange,
}: Props<T>) {
  const isMobile = useIsMobile();
  const activeOption = options.find(option => option.value === value.field);

  return (
    <ButtonGroup fill={fill} minimal>
      <Button
        className={Classes.FIXED}
        icon={
          value.direction === SortingDirection.Descending
            ? 'sort-desc'
            : 'sort-asc'
        }
        onClick={() => {
          onChange({
            direction:
              value.direction === SortingDirection.Ascending
                ? SortingDirection.Descending
                : SortingDirection.Ascending,
            field: value.field,
          });
        }}
      />

      {isMobile ? (
        <select
          className={cx(css.select, Classes.BUTTON, Classes.FILL)}
          value={value.field}
          onChange={newSortingField => {
            onChange({
              field: newSortingField.currentTarget.value as T,
              direction: SortingDirection.Ascending,
            });
          }}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <Popover2
          content={
            <Menu>
              {options.map(option => (
                <MenuItem
                  key={option.value}
                  active={option.value === value.field}
                  text={option.label}
                  onClick={() => {
                    onChange({
                      direction: SortingDirection.Ascending,
                      field: option.value,
                    });
                  }}
                />
              ))}
            </Menu>
          }
          position={Position.BOTTOM_LEFT}
        >
          <Button alignText="left" text={activeOption?.label ?? 'Сортировка'} />
        </Popover2>
      )}

      {(value.direction !== defaultValue.direction ||
        value.field !== defaultValue.field) && (
        <Button
          className={Classes.FIXED}
          icon="cross"
          onClick={() => {
            onChange(defaultValue);
          }}
        />
      )}
    </ButtonGroup>
  );
}

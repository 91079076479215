import { Classes, Icon, IconName, Intent, Spinner } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';
import invariant from 'tiny-invariant';

interface IProps {
  disabled?: boolean;
  intent?: Intent;
  loading?: boolean;
  multiple?: boolean;
  text: string;
  onChoose: (files: File[]) => void;
  icon?: IconName;
}

export function FileChooserButton({
  disabled,
  intent,
  loading,
  multiple,
  text,
  onChoose,
  icon,
}: IProps) {
  return (
    <label
      className={cx(Classes.BUTTON, Classes.intentClass(intent), {
        [Classes.DISABLED]: disabled,
        [Classes.LOADING]: loading,
      })}
      style={{ margin: '0 10px' }}
    >
      {icon && <Icon icon={icon} />}
      {loading && <Spinner className={Classes.BUTTON_SPINNER} size={20} />}
      <span
        style={{ marginRight: icon ? 7 : 0 }}
        className={Classes.BUTTON_TEXT}
      >
        {text}
      </span>

      <input
        disabled={disabled}
        hidden
        multiple={multiple}
        type="file"
        // NOTE: this is needed to trigger onChange even if we select the same
        // file in dialog
        value=""
        onChange={event => {
          invariant(event.currentTarget.files);
          onChoose(Array.from(event.currentTarget.files));
        }}
      />
    </label>
  );
}

import 'reflect-metadata';

import dayjs from 'dayjs';

import {
  DATE_FORMAT_API_DATE,
  DATE_FORMAT_API_DATETIME,
} from './dates/formats';
import { parseDate } from './dates/utils';

type Metadata<TSerialized, TDeserialized> = {
  fromJSON: (value: TSerialized) => TDeserialized;
  toJSON: (value: TDeserialized) => TSerialized;
};

const metadataKey = Symbol();

function propDecorator<TSerialized, TDeserialized>(
  metadata: Metadata<TSerialized, TDeserialized>
) {
  return Reflect.metadata(metadataKey, metadata);
}

function getMetadata(
  target: Object,
  propertyKey: string | symbol
): Metadata<unknown, unknown> | undefined {
  return Reflect.getMetadata(metadataKey, target, propertyKey);
}

function createDateDecorator(format: string) {
  return propDecorator<string | null, Date | null>({
    fromJSON: str => (str ? parseDate(str) : null),
    toJSON: date => date && dayjs(date).format(format),
  });
}

export const serializable = Object.assign(
  function serializable(constructor: Function) {
    constructor.prototype.toJSON = function toJSON() {
      return Object.fromEntries(
        Object.entries(this).map(([key, value]) => {
          const metadata = getMetadata(this, key);

          return [key, metadata ? metadata.toJSON(value) : value];
        })
      );
    };
  },

  {
    arrayOf: <TSerialized, TDeserialized>(SerialazableClass: {
      fromJSON: (json: TSerialized) => TDeserialized;
    }) =>
      propDecorator<TSerialized[], TDeserialized[]>({
        fromJSON: a => a.map(SerialazableClass.fromJSON),
        toJSON: a => a as unknown as TSerialized[],
      }),

    date: createDateDecorator(DATE_FORMAT_API_DATE),
    datetime: createDateDecorator(DATE_FORMAT_API_DATETIME),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromJSON: <T extends new (data: unknown) => any>(
      constructor: T,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: Record<string, any>
    ): InstanceType<T> => {
      return new constructor(
        Object.fromEntries(
          Object.entries(data).map(([key, value]) => {
            const metadata = getMetadata(constructor.prototype, key);

            return [key, metadata ? metadata.fromJSON(data[key]) : value];
          })
        )
      );
    },
  }
);

import { calcForForm } from '_core/decimal';
import { SelectOption } from '_core/inputs/select';
import Decimal from 'decimal.js';

export enum VatRate {
  None = '0.00',
  Pre2019 = '18.00',
  Normal = '20.00',
}

export const getNormalVatRate = () => {
  return VatRate.Normal;
};

export const vatRateLabel = (vatRate: VatRate | null) =>
  vatRate == null ? 'не облагается' : `${Number(vatRate)}%`;

const vatRateOption = (
  vatRate: VatRate,
  additionalProps: Omit<SelectOption<VatRate>, 'label' | 'value'> = {}
) => ({
  ...additionalProps,
  label: vatRateLabel(vatRate),
  value: vatRate,
});

export const vatRateOptions = [
  vatRateOption(VatRate.None),
  vatRateOption(VatRate.Pre2019, { disabled: true }),
  vatRateOption(VatRate.Normal),
];

export const calcVatValue = (cost: Decimal, vatRate: Decimal) =>
  cost.times(vatRate).dividedBy(100);

export const vatValueForForm = (cost: string, vatRate: string | null) =>
  calcForForm(calcVatValue)(cost, vatRate);

export const calcTotalCost = (cost: Decimal, vatRate: Decimal) =>
  cost.plus(calcVatValue(cost, vatRate));

export const totalCostForForm = (cost: string, vatRate: string | null) =>
  calcForForm(calcTotalCost)(cost, vatRate);

export const calcVatValueFromTotalCost = (
  totalCost: Decimal,
  vatRate: Decimal
) => totalCost.dividedBy(vatRate.plus(100)).times(vatRate);

export const vatValueFromTotalCostForForm = (
  totalCost: string,
  vatRate: VatRate
) => calcForForm(calcVatValueFromTotalCost)(totalCost, vatRate);

export const calcCostFromTotalCost = (totalCost: Decimal, vatRate: Decimal) =>
  totalCost.minus(calcVatValueFromTotalCost(totalCost, vatRate));

export const costFromTotalCostForForm = (totalCost: string, vatRate: VatRate) =>
  calcForForm(calcCostFromTotalCost)(totalCost, vatRate);

import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroupForFinalForm } from '_core/inputs/inputMoney';
import { SelectInFormGroupForFinalForm } from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, H4, Intent } from '@blueprintjs/core';
import { EXPEDITION_REQUESTS_BASE_ENDPOINT } from 'expeditionRequests/api';
import { fetchManyExpeditionTransportations } from 'expeditionTransportations/api';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { useRef } from 'react';
import {
  ISuppliersDocumentSerialized,
  SuppliersDocumentState,
  suppliersDocumentStateOptions,
} from 'suppliersDocuments/types';
import { fetchManyTechrunTransportations } from 'techrunTransportations/api';
import {
  costFromTotalCostForForm,
  totalCostForForm,
  VatRate,
  vatRateOptions,
  vatValueForForm,
  vatValueFromTotalCostForForm,
} from 'vatRates/vatRates';

import {
  ExpeditionTransportationsTable,
  IExpeditionTransportationsTableItem,
} from './expeditionTransportationsTable';
import * as css from './form.module.css';
import {
  ITechrunTransportationsTableItem,
  TechrunTransportationsTable,
} from './techrunTransportationsTable';

export interface ISuppliersDocumentsFormValues {
  amount: string;
  amountTotal: string;
  date: Date | null;
  files: string[];
  number: string;
  partner: number | null;
  relatedExpenses: string;
  state: SuppliersDocumentState;
  vatRate: VatRate;
  vatValue: string;
}

interface IProps {
  entityData?: ISuppliersDocumentSerialized;
  initialValues: ISuppliersDocumentsFormValues;
  navigationLock: IFormNavigationLock;
  onSave: (
    values: ISuppliersDocumentsFormValues
  ) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: ISuppliersDocumentsFormValues
  ) => Promise<SubmissionErrors | void>;
}

type SubmitTrigger = 'save' | 'saveAndContinue';

export function SuppliersDocumentsForm({
  entityData,
  initialValues,
  navigationLock,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const api = useApiClient();

  const expeditionTransportations = useAsyncData(
    [api, entityData?.relatedExpeditionTransps],
    async () => {
      if (!entityData?.relatedExpeditionTransps) {
        return [];
      }

      const relatedExpeditionTransps = await fetchManyExpeditionTransportations(
        api,
        entityData.relatedExpeditionTransps
      );

      const relatedExpeditionTranspsWithRelated = await (fetchRelated(
        api,
        {
          arrivalStation: '/directories_trainstations',
          departureStation: '/directories_trainstations',
          request: EXPEDITION_REQUESTS_BASE_ENDPOINT,
        },
        relatedExpeditionTransps
      ) as unknown as Promise<IExpeditionTransportationsTableItem[]>);

      return relatedExpeditionTranspsWithRelated;
    }
  );

  const techrunTransportations = useAsyncData(
    [api, entityData?.relatedTechrunTransps],
    async () => {
      if (!entityData?.relatedTechrunTransps) {
        return [];
      }

      const relatedTechrunTransps = await fetchManyTechrunTransportations(
        api,
        entityData?.relatedTechrunTransps
      );

      const relatedTechrunTranspsWithRelated = await (fetchRelated(
        api,
        {
          arrivalStation: '/directories_trainstations',
          departureStation: '/directories_trainstations',
          request: '/techrun_requests',
        },
        relatedTechrunTransps
      ) as unknown as Promise<ITechrunTransportationsTableItem[]>);

      return relatedTechrunTranspsWithRelated;
    }
  );

  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={2}>
                  <DateInputInFormGroupForFinalForm
                    id="date"
                    label="Дата"
                    name="date"
                  />

                  <FormGroupForFinalForm
                    label="Номер документа"
                    labelFor="number"
                    name="number"
                  >
                    <InputGroupForFinalForm id="number" name="number" />
                  </FormGroupForFinalForm>

                  <SelectInFormGroupForFinalForm
                    id="state"
                    label="Статус закрытия"
                    name="state"
                    options={suppliersDocumentStateOptions}
                  />

                  <PartnersAutocompleteInFormGroupForFinalForm
                    id="partner"
                    label="Контрагент"
                    name="partner"
                    required
                  />

                  <InputMoneyInFormGroupForFinalForm
                    id="amount"
                    label="Сумма"
                    name="amount"
                    onChange={newAmout => {
                      form.change(
                        'amountTotal',
                        totalCostForForm(newAmout, values.vatRate)
                      );
                      form.change(
                        'vatValue',
                        vatValueForForm(newAmout, values.vatRate)
                      );
                    }}
                  />

                  <SelectInFormGroupForFinalForm
                    id="vatRate"
                    label="Ставка НДС"
                    name="vatRate"
                    options={vatRateOptions}
                    onChange={newVatRate => {
                      form.change(
                        'vatValue',
                        vatValueForForm(values.amount, newVatRate)
                      );
                      form.change(
                        'amountTotal',
                        totalCostForForm(values.amount, newVatRate)
                      );
                    }}
                  />

                  <InputMoneyInFormGroupForFinalForm
                    id="amountTotal"
                    label="Сумма итого"
                    name="amountTotal"
                    onChange={newAmoutTotal => {
                      form.change(
                        'vatValue',
                        vatValueFromTotalCostForForm(
                          newAmoutTotal,
                          values.vatRate
                        )
                      );
                      form.change(
                        'amount',
                        costFromTotalCostForForm(newAmoutTotal, values.vatRate)
                      );
                    }}
                  />

                  <InputMoneyInFormGroupForFinalForm
                    id="vatValue"
                    label="Сумма НДС"
                    name="vatValue"
                    readOnly
                  />

                  <InputMoneyInFormGroupForFinalForm
                    id="relatedExpenses"
                    label="Сумма связанных расходов"
                    name="relatedExpenses"
                    readOnly
                  />
                </Col>

                <Col className={css.transportationTablesCol} span={10}>
                  <VGrid stretch>
                    <Row stretch>
                      {!techrunTransportations.data ? (
                        techrunTransportations.isFetching ? (
                          <CenteredSpinner />
                        ) : (
                          <GenericErrorMessage />
                        )
                      ) : techrunTransportations.data.length === 0 ? (
                        <EmptyListMessage description="Нет связанных отправок техрейса" />
                      ) : (
                        <TechrunTransportationsTable
                          isFetching={techrunTransportations.isFetching}
                          transportations={techrunTransportations.data}
                        />
                      )}
                    </Row>

                    <Row stretch>
                      {!expeditionTransportations.data ? (
                        expeditionTransportations.isFetching ? (
                          <CenteredSpinner />
                        ) : (
                          <GenericErrorMessage />
                        )
                      ) : expeditionTransportations.data.length === 0 ? (
                        <EmptyListMessage description="Нет связанных отправок экспедирования" />
                      ) : (
                        <ExpeditionTransportationsTable
                          isFetching={expeditionTransportations.isFetching}
                          transportations={expeditionTransportations.data}
                        />
                      )}
                    </Row>
                  </VGrid>
                </Col>
              </Grid>
            </Row>

            <Row>
              <H4>Файлы</H4>

              <FileInputMultipleForFinalForm id="files" name="files" />
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitTriggerRef.current = 'save';
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

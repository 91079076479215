import { ApiClient } from '_core/api/client';
import { assocPath } from '_core/fp/assocPath';
import { omit } from '_core/fp/omit';
import { path } from '_core/fp/path';
import { uniq } from '_core/fp/uniq';
import { indexByLastItem } from '_core/indexBy';
import { isNotNull } from '_core/isNotNull';

import { fetchAllPages } from './fetchAllPages';
import { ListResponse } from './types';

const ensureArray = <T>(arrayOrValue: T | T[]): T[] =>
  Array.isArray(arrayOrValue) ? arrayOrValue : [arrayOrValue];

const propNameToPath = (propName: string) => propName.split('.');

export function fetchRelated<
  TId extends string | number,
  TItem extends { id: TId },
  TItemWithRelated extends TItem
>(
  api: ApiClient,
  propsToFetch: {
    [key: string]: string | { endpoint: string; toProp?: string };
  },
  baseResponse: TItem[]
): Promise<TItemWithRelated[]> {
  let propsLeftToFetch = propsToFetch;

  const mergedRequests = Object.keys(propsToFetch)
    .map(fromProp => {
      const ids = uniq(
        baseResponse
          .map(item =>
            ensureArray(path(propNameToPath(fromProp), item) as TId[])
          )
          .reduce((head, tail) => head.concat(tail), [])
          .filter(Boolean)
      );

      if (ids.length === 0) {
        return null;
      }

      const propOptions = propsToFetch[fromProp];

      propsLeftToFetch = omit([fromProp], propsLeftToFetch);

      return {
        ids,
        fromProp,

        endpoint:
          typeof propOptions === 'string' ? propOptions : propOptions.endpoint,
      };
    })
    .filter(isNotNull)
    .reduce<{
      [key: string]: {
        ids: TId[];
        fromProps: string[];
      };
    }>(
      (result, { endpoint, ids, fromProp }) => {
        result[endpoint] = result[endpoint] || {
          fromProps: [],
          ids: [],
        };

        const mergedRequest = result[endpoint];

        mergedRequest.ids.push(...ids);
        mergedRequest.fromProps.push(fromProp);

        return result;
      },

      {}
    );

  return Promise.all(
    Object.keys(mergedRequests)
      .reduce(
        (
          requests: Array<{
            endpoint: string;
            ids: TId[];
            fromProps: string[];
          }>,

          endpoint
        ) =>
          requests.concat([
            {
              endpoint,
              ids: mergedRequests[endpoint].ids,
              fromProps: mergedRequests[endpoint].fromProps,
            },
          ]),

        []
      )
      .map(({ endpoint, ids, fromProps }) =>
        fetchAllPages(page =>
          api.get<ListResponse<TItem>>(endpoint, { ids: ids.join(','), page })
        ).then(({ results }) => ({
          fromProps,
          index: indexByLastItem(results, item => item.id),
        }))
      )
  )
    .then(responses =>
      responses.reduce<
        Array<{
          fromProp: string;
          index: { [key: string]: TItem };
        }>
      >((relatedItemIndices, { fromProps, index }) => {
        fromProps.forEach(fromProp => {
          relatedItemIndices.push({ fromProp, index });
        });

        return relatedItemIndices;
      }, [])
    )
    .then(relatedItemIndices =>
      baseResponse.map(item =>
        relatedItemIndices.reduce((result, { fromProp, index }) => {
          const propToFetch = propsToFetch[fromProp];
          const propValue = path(propNameToPath(fromProp), result);

          return assocPath(
            propNameToPath(
              (typeof propToFetch === 'string'
                ? undefined
                : propToFetch.toProp) || fromProp
            ),
            Array.isArray(propValue)
              ? propValue.map((id: string) => index[id] || null).filter(Boolean)
              : index[propValue] || null,
            result
          );
        }, item)
      )
    )
    .then(
      mergedResponse =>
        (propsLeftToFetch === propsToFetch
          ? mergedResponse
          : fetchRelated(
              api,
              propsLeftToFetch,
              mergedResponse
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            )) as any
    );
}

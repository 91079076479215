import { useApiClient } from '_core/api/context';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import {
  FormNavigationLock,
  useFormNavigationLock,
} from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { fetchManyParks, Park } from 'parks/api';
import { ParksAutocompleteInFormGroup } from 'parks/autocomplete';
import * as React from 'react';
import { useField } from 'react-final-form';

function ParksField({ name }: { name: string }) {
  const { input, meta } = useField<number[]>(name);
  const api = useApiClient();

  const { data, isFetching } = useAsyncData([input.value, api], () =>
    fetchManyParks(api, input.value)
  );

  return (
    <div>
      <ParksAutocompleteInFormGroup
        disabled={meta.submitting}
        label="Добавить парк"
        value={null}
        onChange={id => {
          if (id != null) {
            input.onChange(input.value.concat([id]));
          }
        }}
      />

      <Table emptyMessage="Нет парков" isFetching={isFetching} items={data}>
        <Column
          id="name"
          label="Наименование"
          cell={(park: Park) => (
            <LinkCell params={{ id: park.id }} to="parks.edit">
              {park.name}
            </LinkCell>
          )}
        />

        <Column
          id="actions"
          width={52}
          cell={(park: Park) => (
            <div>
              <Button
                disabled={meta.submitting}
                icon="delete"
                intent={Intent.DANGER}
                minimal
                onClick={() => {
                  if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                      'Вы уверены что хотите удалить парк из заявки?'
                    )
                  ) {
                    input.onChange(input.value.filter(id => id !== park.id));
                  }
                }}
              />
            </div>
          )}
        />
      </Table>
    </div>
  );
}

interface IFormValues {
  parks: number[];
}

interface IProps {
  initialValues: IFormValues;
  onSubmit: (values: IFormValues) => Promise<SubmissionErrors | void>;
}

export function ExpeditionRequestParksTab({ initialValues, onSubmit }: IProps) {
  const navigationLock = useFormNavigationLock(
    'expeditions.requests.view.parks'
  );

  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ dirty, error, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <VGrid>
            <Row>
              <FormNavigationLock
                navigationLock={navigationLock}
                shouldLock={dirty}
              />

              <FormErrors error={error || submitError} />

              <Grid>
                <Col span={4}>
                  <ParksField name="parks" />
                </Col>
              </Grid>
            </Row>

            <Row>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { RepairType } from './api';

export function getRepairTypeLabel(type: RepairType) {
  return {
    [RepairType.Current]: 'Текущий',
    [RepairType.Planned]: 'Запланированный',
  }[type];
}

export const repairTypeOptions = [RepairType.Current, RepairType.Planned].map(
  value => ({
    label: getRepairTypeLabel(value),
    value,
  })
);

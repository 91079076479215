import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import { IContactSerialized } from 'contacts/api';
import dayjs from 'dayjs';
import { DocumentStatus } from 'documents/types';
import { IPartnerSerialized } from 'partners/api';
import { VatRate } from 'vatRates/vatRates';

const BASE_ENDPOINT = '/contracts_sell';

export enum ContractsSellService {
  Expedition = 'EXPEDITION',
  Rent = 'RENT',
  Repair = 'REPAIR',
  Techrun = 'TECHRUN',
}

export function isContractsSellService(
  input: string
): input is ContractsSellService {
  return Object.values(ContractsSellService).includes(
    input as ContractsSellService
  );
}

export enum ContractsSellSortingField {
  Number = 'number',
  SignDate = 'signDate',
}

interface IFetchContractsSellParams {
  page?: number;
  partner?: number;
  search?: string;
  service?: ContractsSellService[];
  sorting?: Array<ISorting<ContractsSellSortingField>>;
}

export interface IContractSellSerialized {
  additionalDowntimeRate: string;
  agreementsCount: number;
  author: number | null;
  authorModified: number | null;
  created: string;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  expeditionsProtocolsCount: number;
  files: string[];
  hasExpedition: boolean;
  hasRent: boolean;
  hasRepair: boolean;
  hasTechrun: boolean;
  id: number;
  mainContactStored: IContactSerialized | null;
  modified: string;
  number: string;
  owners: number[];
  partner: number | null;
  partnerContact: number | null;
  partnerSign: number | null;
  partnerStored: IPartnerSerialized | null;
  rentProtocolsCount: number;
  repairPartsCharge: null;
  repairServiceCharge: null;
  repairsProtocolsCount: number;
  signContactStored: IContactSerialized | null;
  signDate: string;
  signPlace: string;
  signerAuthority: string;
  status: DocumentStatus;
  techrunsProtocolsCount: number;
  template: number | null;
}

export interface IContractSell
  extends Omit<IContractSellSerialized, 'created' | 'modified' | 'signDate'> {
  created: Date;
  modified: Date;
  signDate: Date;
}

function deserializeContractSell({
  created,
  modified,
  signDate,
  ...otherProps
}: IContractSellSerialized): IContractSell {
  return {
    ...otherProps,
    created: parseDate(created),
    modified: parseDate(modified),
    signDate: parseDate(signDate),
  };
}

export async function fetchContractsSell(
  api: ApiClient,
  { page, partner, search, service, sorting }: IFetchContractsSellParams
): Promise<ListResponse<IContractSell>> {
  const response = await api.get<ListResponse<IContractSellSerialized>>(
    BASE_ENDPOINT,
    {
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      page,
      partner,
      search,
      service: service && service.length !== 0 ? service.join(',') : undefined,
    }
  );

  return {
    ...response,
    results: response.results.map(deserializeContractSell),
  };
}

export async function fetchContractSell(api: ApiClient, id: number) {
  const contract = await api.get<IContractSellSerialized>(
    `${BASE_ENDPOINT}/${id}`
  );

  return deserializeContractSell(contract);
}

interface ICreateOrUpdateContractSellParams {
  additionalDowntimeRate: string;
  daysLoad: string;
  daysUnload: string;
  downtimeLimit: string;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  hasExpedition?: boolean;
  hasRent: boolean;
  hasRepair?: boolean;
  hasTechrun: boolean;
  number: string;
  owners: Array<number | null>;
  partner: number | null;
  partnerContact: number | null;
  partnerSign: number | null;
  signDate: Date | null;
  signPlace: string;
  status: DocumentStatus;
  template: number | null;
}

function serializeCreateOrUpdateContractSellParams({
  signDate,
  ...otherParams
}: ICreateOrUpdateContractSellParams) {
  return {
    ...otherParams,
    signDate: signDate && dayjs(signDate).format(DATE_FORMAT_API_DATE),
  };
}

export async function createContractSell(
  api: ApiClient,
  params: ICreateOrUpdateContractSellParams
) {
  const createdContract = await api.post<IContractSellSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateContractSellParams(params)
  );

  return deserializeContractSell(createdContract);
}

export async function updateContractSell(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateContractSellParams
) {
  const updatedContract = await api.put<IContractSellSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateContractSellParams(params)
  );

  return deserializeContractSell(updatedContract);
}

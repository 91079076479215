import { NotFoundError } from '_core/api/client';
import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';

interface IProps {
  error?: unknown;
}

export function GenericErrorMessage({ error }: IProps) {
  if (error instanceof NotFoundError) {
    return (
      <NonIdealState icon="error" description="Элемент не найден" title="404" />
    );
  }

  return (
    <NonIdealState
      icon="error"
      description="Что-то пошло не так"
      title="Ошибка"
    />
  );
}

import getBoundingDocumentRect from '_core/getBoundingDocumentRect';
import KeyCodes from '_core/keyCodes';
import { LinkButton } from '_core/router5/linkButton';
import { shallowEqual } from '_core/shallowEqual';
import { Button, InputGroup, Intent, Portal, Spinner } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';
import invariant from 'tiny-invariant';

import * as css from './autocomplete.module.css';

const newItem = Symbol('NEW_ITEM');
type NewItem = typeof newItem;
function isNewItem<TItem>(item: TItem | NewItem): item is NewItem {
  return item === newItem;
}

export interface AutocompleteLink {
  params?: Record<string, string | undefined>;
  to: string;
}

interface Props<TItem> {
  autoFocus?: boolean;
  disabled?: boolean;
  getItemLabel: (item: TItem) => string;
  getItemKey: (item: TItem) => React.Key;
  id?: string;
  isAwaitingNewItems: boolean;
  isInvalid?: boolean;
  items: TItem[];
  link?: AutocompleteLink;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  renderItem: (item: TItem) => React.ReactElement;
  renderNewItem?: () => React.ReactElement;
  selectedItem: TItem | null;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onNewItemSelect?: (inputValue: string) => void;
  onQueryChange: (newQuery: string) => void;
  onSelectedItemChange: (newSelectedItem: TItem | null) => void;
}

export function Autocomplete<TItem>({
  autoFocus,
  disabled,
  getItemLabel,
  getItemKey,
  id,
  isAwaitingNewItems,
  isInvalid,
  items,
  link,
  name,
  placeholder,
  readOnly,
  renderItem,
  renderNewItem,
  selectedItem,
  onBlur,
  onFocus,
  onNewItemSelect,
  onQueryChange,
  onSelectedItemChange,
}: Props<TItem>) {
  const [inputValue, setInputValue] = React.useState('');

  if (renderNewItem || onNewItemSelect) {
    invariant(renderNewItem && onNewItemSelect);
  }

  const finalItems =
    renderNewItem && onNewItemSelect
      ? (items as Array<TItem | NewItem>).concat([newItem])
      : items;

  const inputWrapRef = React.useRef<HTMLDivElement | null>(null);
  const inputElRef = React.useRef<HTMLInputElement | null>(null);

  const [inputWrapBdr, setInputWrapBdr] = React.useState<ClientRect | null>(
    null
  );

  const [isOpen, setIsOpen] = React.useState(false);

  function selectItem(item: TItem | NewItem) {
    if (isNewItem(item)) {
      invariant(onNewItemSelect);
      onNewItemSelect(inputValue);
    } else {
      onSelectedItemChange(item);
    }

    setInputValue('');
    setIsOpen(false);
  }

  const [selectedItemIndex, setSelectedItemIndex] = React.useState<
    number | null
  >(null);

  const updateInputWrapBdr = React.useCallback(() => {
    if (!inputWrapRef.current) {
      return;
    }

    const newBdr = getBoundingDocumentRect(inputWrapRef.current);

    setInputWrapBdr(prevBdr =>
      shallowEqual(newBdr, prevBdr) ? prevBdr : newBdr
    );
  }, []);

  React.useEffect(() => {
    updateInputWrapBdr();
  });

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    function handleWindowClick(event: MouseEvent) {
      const rootNode = inputWrapRef.current;

      if (
        !rootNode ||
        (event.target instanceof Node && rootNode.contains(event.target))
      ) {
        return;
      }

      setIsOpen(false);
    }

    window.addEventListener('click', handleWindowClick, false);

    return () => {
      window.removeEventListener('click', handleWindowClick, false);
    };
  }, [isOpen]);

  React.useEffect(() => {
    function handleWindowResize() {
      updateInputWrapBdr();
    }

    function handleWindowScroll() {
      updateInputWrapBdr();
    }

    window.addEventListener('resize', handleWindowResize, false);
    window.addEventListener('scroll', handleWindowScroll, false);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll, false);
      window.removeEventListener('resize', handleWindowResize, false);
    };
  }, [updateInputWrapBdr]);

  return (
    <>
      <div className={css.inputGroupAndLink} ref={inputWrapRef}>
        <InputGroup
          // see https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Disabling_autocompletion
          autoComplete="off"
          autoFocus={autoFocus}
          disabled={disabled}
          fill
          id={id}
          inputRef={node => {
            inputElRef.current = node;
          }}
          intent={isInvalid ? Intent.DANGER : undefined}
          name={name}
          placeholder={disabled || readOnly ? undefined : placeholder}
          readOnly={readOnly || selectedItem !== null}
          rightElement={
            <>
              {isAwaitingNewItems ? <Spinner size={16} /> : null}

              <Button
                icon="cross"
                minimal
                style={{
                  // this is just hidden so that handleWindowClick does not
                  // close dropdown when clicking on it, because after click it
                  // disappears from DOM
                  visibility:
                    disabled || readOnly || selectedItem === null
                      ? 'hidden'
                      : undefined,
                }}
                onClick={() => {
                  onSelectedItemChange(null);
                  setIsOpen(true);

                  if (!inputElRef.current) {
                    return;
                  }

                  inputElRef.current.focus();
                }}
              />
            </>
          }
          value={
            selectedItem === null ? inputValue : getItemLabel(selectedItem)
          }
          onBlur={onBlur}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newInputValue = event.currentTarget.value;

            onQueryChange(newInputValue);
            setInputValue(newInputValue);
          }}
          onClick={() => {
            if (!disabled && !readOnly && selectedItem === null) {
              setIsOpen(true);
            }
          }}
          onFocus={onFocus}
          onKeyDown={event => {
            switch (event.keyCode) {
              case KeyCodes.Enter:
                if (selectedItemIndex != null) {
                  selectItem(finalItems[selectedItemIndex]);
                  setSelectedItemIndex(null);
                }
                event.preventDefault();
                break;
              case KeyCodes.Up:
                if (isOpen) {
                  if (selectedItemIndex == null) {
                    setSelectedItemIndex(finalItems.length - 1);
                  } else if (selectedItemIndex === 0) {
                    setSelectedItemIndex(null);
                  } else {
                    setSelectedItemIndex(selectedItemIndex - 1);
                  }
                }
                event.preventDefault();
                break;
              case KeyCodes.Down:
                if (isOpen) {
                  if (selectedItemIndex == null) {
                    setSelectedItemIndex(0);
                  } else if (selectedItemIndex === finalItems.length - 1) {
                    setSelectedItemIndex(null);
                  } else {
                    setSelectedItemIndex(selectedItemIndex + 1);
                  }
                }
                event.preventDefault();
                break;
            }
          }}
        />

        {link && (
          <LinkButton
            className={css.linkButton}
            icon="search"
            minimal
            params={link.params}
            rel="noopener"
            target="_blank"
            to={link.to}
          />
        )}
      </div>

      {isOpen && inputWrapBdr && (
        <Portal>
          <div
            className={css.dropdown}
            style={{
              left: inputWrapBdr.left,
              minWidth: inputWrapBdr.width,
              top: inputWrapBdr.bottom,
            }}
          >
            {finalItems.length > 0 ? (
              <ul className={css.optionList}>
                {finalItems.map((item, index) => {
                  let key: React.Key;
                  let optionChild: React.ReactElement;

                  if (isNewItem(item)) {
                    invariant(renderNewItem);
                    key = 'new';
                    optionChild = renderNewItem();
                  } else {
                    key = getItemKey(item);
                    optionChild = renderItem(item);
                  }

                  return (
                    <li
                      key={key}
                      className={cx(css.option, {
                        [css.option_active]: index === selectedItemIndex,
                      })}
                      onClick={() => {
                        selectItem(item);
                      }}
                    >
                      {optionChild}
                    </li>
                  );
                })}
              </ul>
            ) : (
              !isAwaitingNewItems &&
              inputValue.trim().length !== 0 && (
                <div className={css.noOptionsMessage}>Нет результатов</div>
              )
            )}
          </div>
        </Portal>
      )}
    </>
  );
}

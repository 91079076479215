import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';

import {
  ExpeditionTransportationAccountingState,
  ExpeditionTransportationGroup,
  ExpeditionTransportationState,
  IExpeditionTransportation,
} from './types';

export enum ExpeditionTransportationsSortingField {
  ArrivalDate = 'arrivalDate',
  Created = 'created',
  DepartureDate = 'departureDate',
  Name = 'transportationName',
  State = 'state',
}

interface IFetchExpeditionTransportationsParams {
  accountingStates?: ExpeditionTransportationAccountingState[];
  arrivalBefore?: Date;
  arrivalMonth?: Date;
  arrivalMonths?: Date[];
  arrivalStation?: number;
  consignee?: number;
  departureBefore?: Date;
  departureMonth?: Date;
  departureMonths?: Date[];
  departureStation?: number;
  favoriteTransps?: boolean;
  group?: ExpeditionTransportationGroup;
  hasSuppliersDocs?: boolean;
  ids?: number[];
  isEmpty?: boolean;
  operationStation?: number;
  page?: number;
  pageSize?: number;
  partners?: number[];
  requests?: number[];
  search?: string;
  sorting?: Array<ISorting<ExpeditionTransportationsSortingField>>;
  states?: ExpeditionTransportationState[];
  tariffSupplierDocument?: number;
  wagonNumbers?: string[];
}

export function fetchExpeditionTransportations(
  api: ApiClient,
  {
    accountingStates,
    arrivalBefore,
    arrivalMonth,
    arrivalMonths,
    arrivalStation,
    consignee,
    departureBefore,
    departureMonth,
    departureMonths,
    departureStation,
    favoriteTransps,
    group,
    hasSuppliersDocs,
    ids,
    isEmpty,
    operationStation,
    page,
    pageSize,
    partners,
    requests,
    search,
    sorting,
    states,
    tariffSupplierDocument,
    wagonNumbers,
  }: IFetchExpeditionTransportationsParams = {}
) {
  return api.get<
    ListResponse<
      IExpeditionTransportation,
      {
        filterOptions: {
          arrivalDate: string[];
          departureDate: string[];
          partners: number[];
          requests: number[];
        };
      }
    >
  >('/expeditions_transportations', {
    accountingStates:
      accountingStates && accountingStates.length !== 0
        ? accountingStates.join(',')
        : undefined,
    arrivalBefore:
      arrivalBefore && dayjs(arrivalBefore).format(DATE_FORMAT_API_DATE),
    arrivalMonth:
      arrivalMonth && dayjs(arrivalMonth).format(DATE_FORMAT_API_DATE),
    arrivalMonths:
      arrivalMonths && arrivalMonths.length !== 0
        ? arrivalMonths
            .map(monthDate => dayjs(monthDate).format(DATE_FORMAT_API_DATE))
            .join(',')
        : undefined,
    arrivalStation,
    consignee,
    departureBefore:
      departureBefore && dayjs(departureBefore).format(DATE_FORMAT_API_DATE),
    departureMonth:
      departureMonth && dayjs(departureMonth).format(DATE_FORMAT_API_DATE),
    departureMonths:
      departureMonths && departureMonths.length !== 0
        ? departureMonths
            .map(monthDate => dayjs(monthDate).format(DATE_FORMAT_API_DATE))
            .join(',')
        : undefined,
    departureStation,
    favoriteTransps,
    group,
    hasSuppliersDocs,
    ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
    isEmpty,
    operationStation,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    pageSize,
    partners:
      partners && partners.length !== 0 ? partners.join(',') : undefined,
    requests:
      requests && requests.length !== 0 ? requests.join(',') : undefined,
    search,
    states: states && states.length !== 0 ? states.join(',') : undefined,
    tariffSupplierDocument,
    wagonNumbers:
      wagonNumbers && wagonNumbers.length !== 0
        ? wagonNumbers.join(',')
        : undefined,
  });
}

export const fetchManyExpeditionTransportations = makeFetchMany(
  fetchExpeditionTransportations,
  'ids'
);

export function fetchExpeditionTransportation(api: ApiClient, id: number) {
  return api.get<IExpeditionTransportation>(
    `/expeditions_transportations/${id}`
  );
}

export function moveExpeditionTransportationsToGroup(
  api: ApiClient,
  ids: number[],
  group: ExpeditionTransportationGroup
) {
  return api.post<IExpeditionTransportation[]>(
    '/expeditions_transportations/move_to_group',
    { group },
    { query: { ids: ids.join(',') } }
  );
}

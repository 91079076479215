import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import {
  ISorting,
  SortingDirection,
  sortingToApiQueryParam,
} from '_core/sorting';
import { snakeCase } from 'change-case';

export enum PartnerType {
  IP = 'IP',
  OOO = 'OOO',
  ZAO = 'ZAO',
  PAO = 'PAO',
  NAO = 'NAO',
}

export interface IPartnerSerialized {
  actualAddress: string;
  bankName: string;
  bik: string;
  checkingAccount: string;
  contacts: number[];
  correspondentAccount: string;
  created: string;
  email: string;
  fullName: string;
  id: number;
  inn: string;
  isClient: boolean;
  isSupplier: boolean;
  kpp: string;
  legalAddress: string;
  manager: number;
  modified: string;
  ogrn: string;
  okato: string;
  okpo: string;
  oktmo: string;
  paymentData: string;
  postAddress: string;
  shortName: string;
  type: PartnerType;
}

export interface IPartnerInline {
  id: number;
  shortName: string;
}

export enum PartnersSortingField {
  ContractPurchaseSignDate = 'contractPurchaseSignDate',
  ContractSellSignDate = 'contractSellSignDate',
  Created = 'created',
  ExpeditionProtocolSellCreated = 'expeditionProtocolSellCreated',
  IsClient = 'isClient',
  IsSupplier = 'isSupplier',
  Manager = 'manager',
  Modified = 'modified',
  ShortName = 'shortName',
  TechrunProtocolSellCreated = 'techrunProtocolSellCreated',
}

interface IFetchPartnersParams {
  hasPurchaseContracts?: boolean;
  hasSellContracts?: boolean;
  ids?: number[];
  page?: number;
  pageSize?: number;
  search?: string;
  sorting?: Array<ISorting<PartnersSortingField>>;
}

export function fetchPartners(
  api: ApiClient,
  {
    hasPurchaseContracts,
    hasSellContracts,
    ids,
    page,
    pageSize,
    search,
    sorting: sortingParam,
  }: IFetchPartnersParams
) {
  let sorting: ISorting[] | undefined;

  if (sortingParam) {
    sorting = sortingParam.map(sortingItem => {
      switch (sortingItem.field) {
        case PartnersSortingField.ContractPurchaseSignDate:
          return sortingItem.direction === SortingDirection.Descending
            ? {
                field: 'purchasecontractSignDateDesc',
                direction: SortingDirection.Ascending,
              }
            : {
                field: 'purchasecontractSignDateAsc',
                direction: SortingDirection.Ascending,
              };
        case PartnersSortingField.ContractSellSignDate:
          return sortingItem.direction === SortingDirection.Descending
            ? {
                field: 'sellcontractSignDateDesc',
                direction: SortingDirection.Ascending,
              }
            : {
                field: 'sellcontractSignDateAsc',
                direction: SortingDirection.Ascending,
              };
        case PartnersSortingField.ExpeditionProtocolSellCreated:
          return sortingItem.direction === SortingDirection.Descending
            ? {
                field: 'expSellprotocolCreatedDesc',
                direction: SortingDirection.Ascending,
              }
            : {
                field: 'expSellprotocolCreatedAsc',
                direction: SortingDirection.Ascending,
              };
        case PartnersSortingField.TechrunProtocolSellCreated:
          return sortingItem.direction === SortingDirection.Descending
            ? {
                field: 'sellprotocolCreatedDesc',
                direction: SortingDirection.Ascending,
              }
            : {
                field: 'sellprotocolCreatedAsc',
                direction: SortingDirection.Ascending,
              };
        default:
          return sortingItem;
      }
    });
  }

  return api.get<ListResponse<IPartnerSerialized>>('/partners', {
    hasPurchaseContracts,
    hasSellContracts,
    ids: ids?.join(','),
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    pageSize,
    search,
  });
}

export const fetchManyPartners = makeFetchMany(fetchPartners, 'ids');

export function fetchPartner(api: ApiClient, id: number) {
  return api.get<IPartnerSerialized>(`/partners/${id}`);
}

import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { IMyDocumentsTechrunsRequestsItem } from 'myDocuments/api';
import { ITechrunTransportation } from 'techrunTransportations/types';

import { ITechrunRequest, TechrunRequestStatus } from './types';

export enum TechrunRequestsSortingField {
  EndDate = 'endDate',
  Name = 'requestName',
  StartDate = 'startDate',
}

interface IFetchTechrunRequestsParams {
  favoriteRequests?: boolean;
  forDate?: Date;
  hasWagons?: string[];
  ids?: number[];
  isActive?: boolean;
  page?: number;
  pageSize?: number;
  partner?: number;
  search?: string;
  sellProtocol?: number;
  sorting?: Array<ISorting<TechrunRequestsSortingField>>;
  startMonths?: Date[];
  statuses?: TechrunRequestStatus[];
}

export function fetchTechrunRequests(
  api: ApiClient,
  {
    favoriteRequests,
    forDate,
    hasWagons,
    ids,
    isActive,
    page,
    pageSize,
    partner,
    search,
    sellProtocol,
    sorting,
    startMonths,
    statuses,
  }: IFetchTechrunRequestsParams = {}
) {
  return api.get<
    ListResponse<
      ITechrunRequest,
      {
        filterOptions: {
          startDate: [string, string];
        };
      }
    >
  >('/techrun_requests', {
    favoriteRequests,
    forDate: forDate && dayjs(forDate).format(DATE_FORMAT_API_DATE),
    hasWagons:
      hasWagons && hasWagons.length !== 0 ? hasWagons.join(',') : undefined,
    ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
    isActive,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    pageSize,
    partner,
    search,
    sellProtocol,
    startMonths:
      startMonths && startMonths.length !== 0
        ? startMonths
            .map(date => dayjs(date).format(DATE_FORMAT_API_DATE))
            .join(',')
        : undefined,
    statuses:
      statuses && statuses.length !== 0 ? statuses.join(',') : undefined,
  });
}

export const fetchManyTechrunRequests = makeFetchMany(
  fetchTechrunRequests,
  'ids'
);

export enum TechrunRequestsTreeSortingField {
  EndDate = 'endDate',
  Name = 'name',
  StartDate = 'startDate',
}

interface IFetchTechrunRequestsTreeParams {
  favoriteRequests?: boolean;
  hasWagons?: string[];
  partner?: number;
  search?: string;
  sorting?: Array<ISorting<TechrunRequestsTreeSortingField>>;
  startMonths?: Date[];
  statuses?: TechrunRequestStatus[];
}

interface ITechrunRequestsTreeProtocol {
  contract: number;
  id: number;
  name: string;
  requestsCount: number;
}

interface ITechrunRequestsTreePartner {
  partner: number;
  partnerName: string;
  protocols: ITechrunRequestsTreeProtocol[];
}

interface ITechrunRequestsTree {
  meta: {
    filterOptions: {
      startDate: [string, string];
    };
  };
  results: ITechrunRequestsTreePartner[];
}

export async function fetchTechrunRequestsTree(
  api: ApiClient,
  {
    favoriteRequests,
    hasWagons,
    partner,
    search,
    sorting,
    startMonths,
    statuses,
  }: IFetchTechrunRequestsTreeParams = {}
) {
  const response = await api.get<ITechrunRequestsTree>(
    '/techrun_requests/tree',
    {
      favoriteRequests,
      hasWagons:
        hasWagons && hasWagons.length !== 0 ? hasWagons.join(',') : undefined,
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      partner,
      search,
      startMonths:
        startMonths && startMonths.length !== 0
          ? startMonths
              .map(date => dayjs(date).format(DATE_FORMAT_API_DATE))
              .join(',')
          : undefined,
      statuses:
        statuses && statuses.length !== 0 ? statuses.join(',') : undefined,
    }
  );

  const { startDate } = response.meta.filterOptions;

  const deserializedStartDate: [Date, Date] = [
    parseDate(startDate[0]),
    parseDate(startDate[1]),
  ];

  return {
    ...response,
    meta: {
      ...response.meta,
      filterOptions: {
        ...response.meta.filterOptions,
        startDate: deserializedStartDate,
      },
    },
  };
}

export interface ICreateTechrunTransportationsFromXlsxResponse {
  createdRecords: ITechrunTransportation[];
  recordsCount: number;
}

export function createTechrunTransportationsFromXlsx(
  api: ApiClient,
  {
    file,
    purchaseRate,
    purchaseRent,
    requestId,
  }: {
    file: string | null;
    purchaseRate: number | null;
    purchaseRent: boolean;
    requestId: number;
  }
) {
  return api.post<ICreateTechrunTransportationsFromXlsxResponse>(
    `/techrun_requests/${requestId}/create_transportations_from_xlsx`,
    { file, purchaseRate, purchaseRent }
  );
}

export function fetchTechrunRequestWagonsStates(
  api: ApiClient,
  requestId: number
) {
  return api.get<IMyDocumentsTechrunsRequestsItem>(
    `/techrun_requests/${requestId}/get_wagons_states`
  );
}

import { produce } from 'immer';
import { useState } from 'react';

import { usePreference } from '../../me/usePreference';
import { ColumnsPreset, IShowColumns } from './types';

function getPresetColumns(
  preset: ColumnsPreset | null,
  showColumns?: IShowColumns
) {
  switch (preset) {
    case ColumnsPreset.Preset1:
      return showColumns && showColumns[0];
    case ColumnsPreset.Preset2:
      return showColumns && showColumns[1];
    case ColumnsPreset.Preset3:
      return showColumns && showColumns[2];
    default:
      return null;
  }
}

export type IColumnsWidth = Array<{ name: string; value: number }>;

export function useColumnPresets(
  basePrefsPath: string[],
  {
    allColumnIds,
    alwaysShownColumnIds,
    getColumnLabel,
  }: {
    allColumnIds: string[];
    alwaysShownColumnIds: string[];
    getColumnLabel: (columnId: string) => string;
  }
) {
  const [columnsWidthPref, setColumnsWidthPref] = usePreference<
    [IColumnsWidth | null, IColumnsWidth | null, IColumnsWidth | null]
  >([...basePrefsPath, 'columnsWidth']);

  const [showColumnsPref, setShowColumnsPref] = usePreference<IShowColumns>([
    ...basePrefsPath,
    'showColumns',
  ]);

  const [activePreset, setActivePreset] = useState<ColumnsPreset | null>(null);

  const hideableColumnIds = allColumnIds.filter(
    columnId => !alwaysShownColumnIds.includes(columnId)
  );

  return {
    activePreset,
    columnOptions: hideableColumnIds.map(columnId => ({
      label: getColumnLabel(columnId),
      value: columnId,
    })),
    columnWidths:
      activePreset == null ? null : columnsWidthPref[activePreset - 1],
    defaultValue: hideableColumnIds,
    initialValue: showColumnsPref,
    showColumns: alwaysShownColumnIds.concat(
      getPresetColumns(activePreset, showColumnsPref) || hideableColumnIds
    ),
    onColumnPresetChange: (preset: ColumnsPreset | null) => {
      setActivePreset(preset);
    },
    onColumnWidthChanged: (name: string, newWidth: number) => {
      if (activePreset == null) {
        return;
      }

      setColumnsWidthPref(
        produce(columnsWidthPref, draft => {
          const preset = (draft[activePreset - 1] ||= []);
          const found = preset.find(item => item.name === name);

          if (found) {
            found.value = newWidth;
          } else {
            preset.push({ name, value: newWidth });
          }
        })
      );
    },
    onShowColumnsApply: (showColumns: IShowColumns) => {
      setShowColumnsPref(showColumns);
    },
  };
}

// See http://localization-guide.readthedocs.io/en/latest/l10n/pluralforms.html
type PluralTemplate = () => string;
type PluralForms = [PluralTemplate, PluralTemplate, PluralTemplate];

export function plural(n: number, pluralForms: PluralForms) {
  const pluralFormIndex =
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2;

  const pluralForm = pluralForms[pluralFormIndex];

  return pluralForm();
}

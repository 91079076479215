import { IPartnerSerialized, PartnerType } from './api';

export function getPartnerTypeLabel(type: PartnerType) {
  return {
    [PartnerType.IP]: 'ИП',
    [PartnerType.OOO]: 'ООО',
    [PartnerType.ZAO]: 'ЗАО',
    [PartnerType.PAO]: 'ПАО',
    [PartnerType.NAO]: 'НАО',
  }[type];
}

export const partnerTypeOptions = [
  PartnerType.IP,
  PartnerType.OOO,
  PartnerType.ZAO,
  PartnerType.PAO,
  PartnerType.NAO,
].map(type => ({
  label: getPartnerTypeLabel(type),
  value: type,
}));

export const partnerToOption = (partner: IPartnerSerialized) => ({
  label: partner.shortName,
  value: String(partner.id),
});

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { DocumentStatus } from 'documents/types';
import { IPartnerInline } from 'partners/api';
import { TechrunProtocolRateType } from 'techrunProtocols/types';
import { TripType } from 'tripTypes/types';
import { VatRate } from 'vatRates/vatRates';

const BASE_ENDPOINT = '/techrun_protocols_purchase';

interface ITechrunProtocolPurchaseRateSerialized {
  arrivalStation: number;
  confirmedTransportationsCount: number;
  cost: string;
  costTotal: string;
  departureStation: number;
  id: number;
  partner: IPartnerInline;
  protocol: number;
  protocolName: string;
  vatRate: VatRate;
  vatValue: string;
}

interface ITechrunProtocolPurchaseSerialized {
  additionalDowntimeRate: string;
  author: number | null;
  authorModified: number | null;
  cargo: number | null;
  contract: number;
  created: string;
  date: string;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeRateTotal: string;
  downtimeRateVatValue: string;
  downtimeVatRate: VatRate;
  endDate: string;
  files: string[];
  hasEmptyTariff: boolean;
  hasIntEmptyTariff: boolean;
  hasIntLoadedTariff: boolean;
  hasLoadedTariff: boolean;
  hasNoVatRate: boolean;
  hasUseWagon: boolean;
  id: number;
  modified: string;
  name: string;
  note: string;
  number: string;
  partner: number;
  partnerSign: number | null;
  protocolName: string;
  protocolNumber: string;
  rateType: TechrunProtocolRateType;
  rates: ITechrunProtocolPurchaseRateSerialized[];
  signerAuthority: string;
  startDate: string;
  status: DocumentStatus;
  tripType: TripType;
  vatRate: VatRate;
  wagonType: number;
}

export interface ITechrunProtocolPurchase
  extends Omit<
    ITechrunProtocolPurchaseSerialized,
    'created' | 'date' | 'endDate' | 'modified' | 'startDate'
  > {
  created: Date;
  date: Date;
  endDate: Date;
  modified: Date;
  startDate: Date;
}

function deserializeTechrunProtocolPurchase({
  created,
  date,
  endDate,
  modified,
  startDate,
  ...otherProps
}: ITechrunProtocolPurchaseSerialized): ITechrunProtocolPurchase {
  return {
    ...otherProps,
    created: parseDate(created),
    date: parseDate(date),
    endDate: parseDate(endDate),
    modified: parseDate(modified),
    startDate: parseDate(startDate),
  };
}

export enum TechrunProtocolsPurchaseSortingField {
  Date = 'date',
  EndDate = 'endDate',
  Name = 'name',
  OrderingNumber = 'orderingNumber',
  StartDate = 'startDate',
}

interface IFetchTechrunProtocolsPurchaseParams {
  contract?: number;
  isArchived?: boolean;
  page?: number;
  sorting?: Array<ISorting<TechrunProtocolsPurchaseSortingField>>;
}

export async function fetchTechrunProtocolsPurchase(
  api: ApiClient,
  {
    contract,
    isArchived,
    page,
    sorting,
  }: IFetchTechrunProtocolsPurchaseParams = {}
): Promise<ListResponse<ITechrunProtocolPurchase>> {
  const response = await api.get<
    ListResponse<ITechrunProtocolPurchaseSerialized>
  >(BASE_ENDPOINT, {
    contract,
    isArchived,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
  });

  return {
    ...response,
    results: response.results.map(deserializeTechrunProtocolPurchase),
  };
}

interface ICreateOrUpdateTechrunProtocolPurchaseParamsRate {
  arrivalStation: number | null;
  confirmedTransportationsCount: number;
  cost: string;
  costTotal: string;
  departureStation: number | null;
  vatValue: string;
}

interface ICreateOrUpdateTechrunProtocolPurchaseParams {
  additionalDowntimeRate: string;
  cargo: number | null;
  date: Date | null;
  daysLoad: string;
  daysUnload: string;
  downtimeLimit: string;
  downtimeRate: string;
  downtimeRateTotal: string;
  downtimeRateVatValue: string;
  downtimeVatRate: VatRate;
  endDate: Date | null;
  files: string[];
  hasEmptyTariff: boolean;
  hasIntEmptyTariff: boolean;
  hasIntLoadedTariff: boolean;
  hasLoadedTariff: boolean;
  hasUseWagon: boolean;
  name: string;
  number: string;
  partnerSign: number | null;
  rates: ICreateOrUpdateTechrunProtocolPurchaseParamsRate[];
  rateType: TechrunProtocolRateType;
  signerAuthority: string;
  startDate: Date | null;
  status: DocumentStatus;
  tripType: TripType;
  vatRate: VatRate | null;
  wagonType: number | null;
}

function serializeCreateOrUpdateTechrunProtocolPurchaseParams({
  date,
  endDate,
  startDate,
  ...otherParams
}: ICreateOrUpdateTechrunProtocolPurchaseParams) {
  return {
    ...otherParams,
    date: date && dayjs(date).format(DATE_FORMAT_API_DATE),
    endDate: endDate && dayjs(endDate).format(DATE_FORMAT_API_DATE),
    startDate: startDate && dayjs(startDate).format(DATE_FORMAT_API_DATE),
  };
}

export async function createTechrunProtocolPurchase(
  api: ApiClient,
  params: ICreateOrUpdateTechrunProtocolPurchaseParams
) {
  const createdProtocol = await api.post<ITechrunProtocolPurchaseSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateTechrunProtocolPurchaseParams(params)
  );

  return deserializeTechrunProtocolPurchase(createdProtocol);
}

export async function updateTechrunProtocolPurchase(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateTechrunProtocolPurchaseParams
) {
  const updatedProtocol = await api.put<ITechrunProtocolPurchaseSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateTechrunProtocolPurchaseParams(params)
  );

  return deserializeTechrunProtocolPurchase(updatedProtocol);
}

export async function uploadTechrunProtocolPurchaseRates(
  api: ApiClient,
  id: number,
  file: string
) {
  const updatedProtocol = await api.post<ITechrunProtocolPurchaseSerialized>(
    `${BASE_ENDPOINT}/${id}/upload_rates_xlsx`,
    { file }
  );

  return deserializeTechrunProtocolPurchase(updatedProtocol);
}

export function getTechrunProtocolPurchaseDocxDownloadLink(
  api: ApiClient,
  id: number
) {
  return api.buildApiUrl(`${BASE_ENDPOINT}/${id}/download_docx`);
}

import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { indexByLastItem } from '_core/indexBy';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { isNotNull } from '_core/isNotNull';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  AnchorButton,
  Button,
  Classes,
  Intent,
  NonIdealState,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import {
  fetchManyContacts,
  getOrCreateContactsFromText,
  IContact,
  updateContact,
} from 'contacts/api';
import { ContactsAutocompleteInFormGroup } from 'contacts/autocomplete';
import { ContactDialogForm } from 'contacts/dialogForm';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { ParkSubscriber } from 'parks/api';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useField } from 'react-final-form';

import * as css from './subscribersField.module.css';

interface IProps {
  lastMailed: Date | null;
  name: string;
  selectedContacts: string[];
  onSelectedContactsChange: (newSelectedContacts: string[]) => void;
  onSendMailRequest: (contacts: IContact[]) => void;
}

interface ParkSubscribersFieldItem extends Omit<ParkSubscriber, 'contact'> {
  contact: IContact;
}

export function ParkSubscribersField({
  lastMailed,
  name,
  selectedContacts,
  onSelectedContactsChange,
  onSendMailRequest,
}: IProps) {
  const { input, meta } = useField<ParkSubscriber[]>(name);
  const api = useApiClient();

  const { data, isFetching, refetch } = useAsyncData(
    [api, input.value],
    async () => {
      const contacts = await fetchManyContacts(
        api,
        input.value.map(({ contact }) => contact)
      );

      const contactsIndex = indexByLastItem(contacts, contact => contact.id);

      return input.value.map(
        ({ contact, ...otherProps }): ParkSubscribersFieldItem => ({
          ...otherProps,
          contact: contactsIndex[contact],
        })
      );
    }
  );

  const [editDialogFormState, setEditDialogFormState] = useState<{
    contact: IContact;
    isOpen: boolean;
  } | null>(null);

  const [
    addContactsByRawTextPopoverIsOpen,
    setAddContactsByRawTextPopoverIsOpen,
  ] = useState(false);

  const allContactsExistInInitialValue = data
    ?.map(el => el.contact.id)
    .every(contactId =>
      meta.initial?.some(initialContact => initialContact.contact === contactId)
    );

  const columns = useMemo(
    (): Array<ListTableColumn<ParkSubscribersFieldItem>> => [
      {
        id: 'name',
        label: 'Email',
        defaultWidth: 350,
        copyCellContent: ({ contact }) => contact.email,
        renderCellContent: ({ contact }) => (
          <Ellipsis component="span">{contact.email}</Ellipsis>
        ),
      },
      {
        id: 'fio',
        label: 'ФИО',
        defaultWidth: 350,
        copyCellContent: ({ contact }) =>
          [contact.lastName]
            .concat(
              [contact.firstName, contact.middleName]
                .filter(Boolean)
                .map(str => `${str[0]}.`)
            )
            .join(' '),
        renderCellContent: ({ contact }) => (
          <Ellipsis component="span">
            {[contact.lastName]
              .concat(
                [contact.firstName, contact.middleName]
                  .filter(Boolean)
                  .map(str => `${str[0]}.`)
              )
              .join(' ')}
          </Ellipsis>
        ),
      },
      {
        id: 'lastMailingTime',
        label: 'Последняя рассылка',
        defaultWidth: 180,
        copyCellContent: ({ lastMailingTime }) =>
          lastMailingTime == null
            ? ''
            : dayjs(lastMailingTime).format(DATE_FORMAT_DATETIME),
        renderCellContent: ({ lastMailingTime }) =>
          lastMailingTime == null ? null : (
            <Ellipsis component="span">
              {dayjs(lastMailingTime).format(DATE_FORMAT_DATETIME)}
            </Ellipsis>
          ),
      },
      {
        id: 'actions',
        label: '',
        defaultWidth: 132,
        renderCellContent: ({ contact }) => (
          <>
            <Tooltip
              disabled={allContactsExistInInitialValue}
              content="Сохраните парк перед отправкой рассылки добавленным контактам"
            >
              <AnchorButton
                disabled={!allContactsExistInInitialValue}
                className={css.actionsCellButton}
                icon="envelope"
                minimal
                onClick={() => {
                  onSendMailRequest([contact]);
                }}
              />
            </Tooltip>

            <Button
              className={css.actionsCellButton}
              icon="edit"
              minimal
              onClick={() => {
                setEditDialogFormState({
                  contact,
                  isOpen: true,
                });
              }}
            />

            <Button
              className={css.actionsCellButton}
              icon="delete"
              intent={Intent.DANGER}
              minimal
              onClick={() => {
                if (
                  // eslint-disable-next-line no-alert
                  window.confirm(
                    'Вы уверены что хотите удалить подписчика из парка?'
                  )
                ) {
                  input.onChange(
                    input.value.filter(
                      subscriber => subscriber.contact !== contact.id
                    )
                  );
                }
              }}
            />
          </>
        ),
      },
    ],
    [input, onSendMailRequest, allContactsExistInInitialValue]
  );

  const allSelectedContactsExistInInitialValue = selectedContacts
    .map(Number)
    .every(contactId =>
      meta.initial?.some(initialContact => initialContact.contact === contactId)
    );

  return (
    <div className={css.root}>
      <VGrid stretch>
        <Row>
          <div className={css.addContactToolbar}>
            <div className={css.addContactToolbarAutocompleteWrapper}>
              <ContactsAutocompleteInFormGroup
                disabled={meta.submitting}
                label="Добавить подписчика"
                noBottomMargin
                value={null}
                onChange={id => {
                  if (id !== null) {
                    input.onChange(
                      input.value.concat([
                        { contact: id, lastMailingTime: null },
                      ])
                    );
                  }
                }}
              />

              {editDialogFormState && (
                <ContactDialogForm
                  icon="envelope"
                  initialValues={editDialogFormState.contact}
                  isOpen={editDialogFormState.isOpen}
                  title="Редактирование контакта"
                  onClose={() => {
                    setEditDialogFormState(
                      prevState =>
                        prevState && {
                          ...prevState,
                          isOpen: false,
                        }
                    );
                  }}
                  onClosed={() => {
                    setEditDialogFormState(null);
                  }}
                  onSubmit={async values => {
                    await updateContact(
                      api,
                      editDialogFormState.contact.id,
                      values
                    );

                    setEditDialogFormState(
                      prevState =>
                        prevState && {
                          ...prevState,
                          isOpen: false,
                        }
                    );

                    refetch();
                  }}
                />
              )}
            </div>

            <div className={css.addContactToolbarButtonWrapper}>
              <Popover
                content={
                  <div className={css.addContactToolbarPopoverContent}>
                    <FinalForm<{ text: string }>
                      initialValues={{ text: '' }}
                      onSubmit={async values => {
                        try {
                          const contacts = await getOrCreateContactsFromText(
                            api,
                            values.text
                          );

                          input.onChange(
                            Array.from(
                              new Set(
                                input.value.concat(
                                  contacts.map(contact => ({
                                    contact: contact.id,
                                    lastMailingTime: null,
                                  }))
                                )
                              )
                            )
                          );

                          setAddContactsByRawTextPopoverIsOpen(false);

                          return undefined;
                        } catch (err) {
                          return submissionErrorsFromApiError(
                            err,
                            'Не удалось добавить контакты: Непредвиденная ошибка'
                          );
                        }
                      }}
                    >
                      {({ error, handleSubmit, submitError, submitting }) => (
                        <BaseForm onSubmit={handleSubmit}>
                          <FormErrors error={error || submitError} />

                          <FormGroupForFinalForm name="text">
                            <InputMultipleForFinalForm name="text" rows={8} />
                          </FormGroupForFinalForm>

                          <Toolbar align="right">
                            <Button
                              className={Classes.POPOVER_DISMISS}
                              disabled={submitting}
                              text="Закрыть"
                            />

                            <Button
                              disabled={submitting}
                              intent={Intent.PRIMARY}
                              loading={submitting}
                              text="Добавить"
                              type="submit"
                            />
                          </Toolbar>
                        </BaseForm>
                      )}
                    </FinalForm>
                  </div>
                }
                disabled={meta.submitting}
                isOpen={addContactsByRawTextPopoverIsOpen}
                position={Position.BOTTOM_RIGHT}
                onInteraction={setAddContactsByRawTextPopoverIsOpen}
              >
                <Button
                  disabled={meta.submitting}
                  text="Добавить из списка email"
                />
              </Popover>
            </div>
          </div>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : data.length === 0 ? (
            <NonIdealState icon="list" description="У парка нет подписчиков" />
          ) : (
            <ListTable
              columns={columns}
              getItemId={({ contact }) => String(contact.id)}
              items={data}
              selectedItems={selectedContacts}
              onSelectedItemsChange={onSelectedContactsChange}
            />
          )}
        </Row>

        <Row>
          <Grid>
            <Col>
              Последняя автоматическая рассылка:{' '}
              {lastMailed
                ? dayjs(lastMailed).format(DATE_FORMAT_DATETIME)
                : 'рассылок не было'}
            </Col>

            <Col align="end">
              <Tooltip
                disabled={
                  selectedContacts.length !== 0 &&
                  allSelectedContactsExistInInitialValue
                }
                content={
                  selectedContacts.length === 0
                    ? 'Выберите контакты, которым нужно отправить рассылку, из таблицы выше'
                    : !allSelectedContactsExistInInitialValue
                    ? 'Сохраните парк перед отправкой рассылки добавленным контактам'
                    : undefined
                }
              >
                <AnchorButton
                  disabled={
                    selectedContacts.length === 0 ||
                    !allSelectedContactsExistInInitialValue
                  }
                  icon="envelope"
                  intent={Intent.PRIMARY}
                  text="Отправить рассылку выбранным контактам"
                  onClick={() => {
                    if (!data) {
                      return;
                    }

                    onSendMailRequest(
                      selectedContacts
                        .map(Number)
                        .map(contactId =>
                          data.find(({ contact }) => contact.id === contactId)
                        )
                        .filter(isNotNull)
                        .map(({ contact }) => contact)
                    );
                  }}
                />
              </Tooltip>
            </Col>
          </Grid>
        </Row>
      </VGrid>
    </div>
  );
}

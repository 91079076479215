import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import {
  selectIsFavoriteShipmentInfo,
  toggleShipmentInfoFavoriteStatus,
} from '_core/me/me';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useToaster } from '_core/toaster/toasterContext';
import { useAsyncData } from '_core/useAsyncData';
import { useErpDispatch, useErpSelector } from '_erp/redux';
import { Intent } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import {
  accountingCloseTransportationsForShipment,
  fetchShipmentInfoItem,
  updateShipmentInfoItem,
} from 'shipmentInfo/api';

import { IShipmentInfoFormValues, ShipmentInfoForm } from './form';

export default function ShipmentInfoEditRoute() {
  const toaster = useToaster();
  const { route, router } = useRouteNode('shipmentInfo.edit');
  const navigationLock = useFormNavigationLock('shipmentInfo.edit');
  const api = useApiClient();
  const dispatch = useErpDispatch();

  const shipmentInfoItemId = Number(route.params.id);

  const { data, error, isFetching, refetch, updateData } = useAsyncData(
    [api, shipmentInfoItemId],
    () => fetchShipmentInfoItem(api, shipmentInfoItemId)
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const isFavorite = useErpSelector(state =>
    selectIsFavoriteShipmentInfo(shipmentInfoItemId, state)
  );

  if (!data) {
    if (isFetching) {
      return <CenteredSpinner />;
    }

    return <GenericErrorMessage error={error} />;
  }

  const persist = ({
    supplierDoc,
    ...otherValues
  }: IShipmentInfoFormValues) => {
    return updateShipmentInfoItem(api, data.id, {
      ...data,
      ...otherValues,
      supplierDoc: {
        ...data.supplierDoc,
        id: supplierDoc,
      },
    });
  };

  const title = data.name;

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось изменить отгрузочную информацию: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Отгрузочная информация"
              params={listParams}
              to="shipmentInfo"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <ShipmentInfoForm
            initialValues={{
              ...data,
              supplierDoc: data.supplierDoc ? data.supplierDoc.id : null,
            }}
            isFavorite={isFavorite}
            navigationLock={navigationLock}
            shipmentInfo={data}
            onIsFavoriteToggle={async () => {
              try {
                await dispatch(toggleShipmentInfoFavoriteStatus(data.id));
              } catch (err) {
                toaster.show({
                  icon: 'error',
                  intent: Intent.DANGER,
                  message: 'Не удалось изменить статус избранности отгрузки',
                });

                throw err;
              }
            }}
            onAccountingCloseTransportations={async values => {
              try {
                const updatedShipmentInfo = await persist(values);

                await accountingCloseTransportationsForShipment(
                  api,
                  updatedShipmentInfo.id,
                  {
                    date: updatedShipmentInfo.date,
                    dateUpd: updatedShipmentInfo.dateUpd,
                    files: updatedShipmentInfo.files,
                    numberUpd: updatedShipmentInfo.numberUpd,
                    transportationsExpeditions:
                      updatedShipmentInfo.transportationsExpeditions,
                    transportationsTechrun:
                      updatedShipmentInfo.transportationsTechrun,
                  }
                );

                refetch();
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('shipmentInfo', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedShipmentInfo = await persist(values);
                updateData(() => updatedShipmentInfo);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

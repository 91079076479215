export function shallowEqual<T extends unknown>(a: T, b: T) {
  if (Object.is(a, b)) {
    return true;
  }

  if (
    typeof a !== 'object' ||
    a === null ||
    typeof b !== 'object' ||
    b === null
  ) {
    return false;
  }

  const props = Object.keys(a as Record<string, unknown>);

  if (props.length !== Object.keys(b as Record<string, unknown>).length) {
    return false;
  }

  const hasProp = Object.prototype.hasOwnProperty.bind(b);

  return props.every(
    prop =>
      hasProp(prop) &&
      Object.is(
        (a as Record<string, unknown>)[prop],
        (b as Record<string, unknown>)[prop]
      )
  );
}

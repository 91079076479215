import { useRef, useState } from 'react';

export function useDerivedState<TInput, TState>(
  input: TInput,
  deriveFn: (input: TInput, prevState: TState | null) => TState
) {
  const lastInputRef = useRef(input);
  const result = useState(() => deriveFn(input, null));
  const [, setState] = result;

  if (input !== lastInputRef.current) {
    setState(prevState => deriveFn(input, prevState));
    lastInputRef.current = input;
  }

  return result;
}

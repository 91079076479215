import { IToaster } from '@blueprintjs/core';
import * as React from 'react';
import invariant from 'tiny-invariant';

const ToasterContext = React.createContext<IToaster | null>(null);

if (__DEV__) {
  ToasterContext.displayName = 'ToasterContext';
}

interface IProviderProps {
  children: React.ReactNode;
  toaster: IToaster;
}

export function ToasterProvider({ children, toaster }: IProviderProps) {
  return (
    <ToasterContext.Provider value={toaster}>
      {children}
    </ToasterContext.Provider>
  );
}

export function useToaster(): IToaster {
  const toaster = React.useContext(ToasterContext);
  invariant(toaster, 'No toaster found in context');
  return toaster;
}

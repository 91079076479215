export enum ExpeditionStationType {
  Any = 'ANY_STATION',
  Arrival = 'ARRIVAL',
  Departure = 'DEPARTURE',
}

const getExpeditionStationTypeLabel = (stationType: ExpeditionStationType) =>
  ({
    [ExpeditionStationType.Any]: 'Любые станции',
    [ExpeditionStationType.Arrival]: 'Станция назначения',
    [ExpeditionStationType.Departure]: 'Станция отправления',
  }[stationType]);

export const expeditionStationTypesOptions = [
  ExpeditionStationType.Departure,
  ExpeditionStationType.Arrival,
  ExpeditionStationType.Any,
].map(stationType => ({
  label: getExpeditionStationTypeLabel(stationType),
  value: stationType,
}));

import { useApiClient } from '_core/api/context';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { SwitchForFinalForm } from '_core/inputs/switch';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  Button,
  FormGroup,
  H4,
  InputGroup,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { Park } from 'parks/api';
import * as React from 'react';
import { useRef, useState } from 'react';
import { WagonAlertsLegend } from 'wagons/alertsLegend';
import { fetchManyWagons } from 'wagons/api';
import { createWagonMatcher } from 'wagons/utils';

import { ParkWagonsField } from './parkWagonsField';
import { RequestsTableField } from './requestsTableField';
import { WagonTags } from './wagonTags';

export interface IParksFormValues {
  allTechrunRequestWagons: boolean;
  expeditionRequests: number[];
  name: string;
  techrunRequests: number[];
  wagons: string[];
}

type SubmitFn = (
  values: IParksFormValues
) => Promise<SubmissionErrors | void> | void;

interface IProps {
  initialValues: IParksFormValues;
  navigationLock: IFormNavigationLock;
  park?: Park;
  onRemove?: () => Promise<SubmissionErrors | void>;
  onSave: (values: IParksFormValues) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IParksFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function ParksForm({
  initialValues,
  navigationLock,
  park,
  onRemove,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const api = useApiClient();

  const { data, isFetching } = useAsyncData(
    [api, park?.expeditionRequestsWagons, park?.techrunRequestsWagons],

    async () => {
      if (!park) {
        return {
          expeditionRequestsWagons: [],
          techrunRequestsWagons: [],
        };
      }

      const [expeditionRequestsWagons, techrunRequestsWagons] =
        await Promise.all([
          fetchManyWagons(api, park.expeditionRequestsWagons),
          fetchManyWagons(api, park.techrunRequestsWagons),
        ]);

      return {
        expeditionRequestsWagons,
        techrunRequestsWagons,
      };
    }
  );

  const submitFnRef = useRef<SubmitFn>(() => undefined);

  const [wagonsSearchQuery, setWagonsSearchQuery] = useState('');
  const wagonsMatcher = createWagonMatcher(wagonsSearchQuery);

  if (!data) {
    return isFetching ? <CenteredSpinner /> : <GenericErrorMessage />;
  }

  const expeditionRequestsWagonsFiltered = data.expeditionRequestsWagons.filter(
    ({ number }) => wagonsMatcher(number)
  );

  const techrunRequestsWagonsFiltered = data.techrunRequestsWagons.filter(
    ({ number }) => wagonsMatcher(number)
  );

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={values => submitFnRef.current(values)}
    >
      {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <H4>Основная информация</H4>

              <Grid>
                <Col span={4}>
                  <FormGroupForFinalForm
                    label="Наименование"
                    labelFor="name"
                    labelInfo="*"
                    name="name"
                  >
                    <InputGroupForFinalForm autoFocus id="name" name="name" />
                  </FormGroupForFinalForm>

                  <SwitchForFinalForm
                    id="allTechrunRequestWagons"
                    label="Показывать все вагоны из заявки"
                    name="allTechrunRequestWagons"
                  />
                </Col>
              </Grid>
            </Row>

            <Row>
              <H4>Вагоны</H4>

              <WagonAlertsLegend />
            </Row>

            <Row>
              <FormGroup label="Поиск вагонов по номеру">
                <InputGroup
                  placeholder="Начните вводить номер вагона..."
                  value={wagonsSearchQuery}
                  onChange={event => {
                    setWagonsSearchQuery(event.currentTarget.value);
                  }}
                />
              </FormGroup>

              <Grid>
                <Col span={4}>
                  <ParkWagonsField
                    expeditionRequestsWagons={data.expeditionRequestsWagons}
                    name="wagons"
                    techrunRequestsWagons={data.techrunRequestsWagons}
                    wagonNumbers={park?.wagonNumbers}
                    wagonsMatcher={wagonsMatcher}
                  />
                </Col>

                <Col span={4}>
                  <VGrid>
                    <Row>
                      <H4>
                        Вагоны из заявок техрейсов:{' '}
                        {data.techrunRequestsWagons.length}
                      </H4>

                      {data.techrunRequestsWagons.length === 0 ? (
                        <NonIdealState icon="list" description="Нет вагонов" />
                      ) : techrunRequestsWagonsFiltered.length === 0 ? (
                        <NonIdealState
                          icon="list"
                          description="Не найдено вагонов, удовлетворяющих запросу"
                        />
                      ) : (
                        <WagonTags wagons={techrunRequestsWagonsFiltered} />
                      )}
                    </Row>

                    <Row>
                      <H4>Заявки техрейсов</H4>

                      <RequestsTableField
                        name="techrunRequests"
                        requestKind="techrun"
                        tableEmptyMessage="Нет заявок техрейса"
                      />
                    </Row>
                  </VGrid>
                </Col>

                <Col span={4}>
                  <VGrid>
                    <Row>
                      <H4>
                        Вагоны из заявок экспедирования:{' '}
                        {data.expeditionRequestsWagons.length}
                      </H4>

                      {data.expeditionRequestsWagons.length === 0 ? (
                        <NonIdealState icon="list" description="Нет вагонов" />
                      ) : expeditionRequestsWagonsFiltered.length === 0 ? (
                        <NonIdealState
                          icon="list"
                          description="Не найдено вагонов, удовлетворяющих запросу"
                        />
                      ) : (
                        <WagonTags wagons={expeditionRequestsWagonsFiltered} />
                      )}
                    </Row>

                    <Row>
                      <H4>Заявки экспедирования</H4>

                      <RequestsTableField
                        name="expeditionRequests"
                        requestKind="expedition"
                        tableEmptyMessage="Нет заявок экспедирования"
                      />
                    </Row>
                  </VGrid>
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Grid>
                <Col>
                  <Toolbar>
                    {onRemove && (
                      <Button
                        disabled={submitting}
                        intent={Intent.DANGER}
                        text="Удалить"
                        onClick={() => {
                          submitFnRef.current = onRemove;
                          form.submit();
                        }}
                      />
                    )}
                  </Toolbar>
                </Col>

                <Col>
                  <Toolbar align="right">
                    <Button
                      disabled={submitting}
                      text="Сохранить и продолжить редактирование"
                      onClick={() => {
                        submitFnRef.current = onSaveAndContinue;
                        form.submit();
                      }}
                    />

                    <Button
                      disabled={submitting}
                      intent={Intent.PRIMARY}
                      text="Сохранить"
                      type="submit"
                      onClick={() => {
                        submitFnRef.current = onSave;
                      }}
                    />
                  </Toolbar>
                </Col>
              </Grid>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

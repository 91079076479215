import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { ISuppliersDocumentSerialized } from './types';

export const SuppliersDocumentsFromFileAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<ISuppliersDocumentSerialized, number>({
      baseEndpoint: '/suppliers_documents',
      optionIcon: 'document',
      placeholder: 'Начните вводить номер документа...',

      getLink: doc => ({
        params: { id: String(doc.id) },
        to: 'suppliersDocumentsFromFile.edit',
      }),

      itemToOption: doc => ({
        label: doc.number || `<номер не указан>, id: ${doc.id}`,
        value: doc.id,
      }),
    })
  );

export const SuppliersDocumentsFromFileAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    SuppliersDocumentsFromFileAutocompleteInFormGroup
  );

import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { BooleanIcon } from '_core/booleanIcon';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { SearchForm } from '_core/forms/searchForm';
import { usePreference } from '_core/me/usePreference';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import {
  expandSorting,
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
  validateSorting,
} from '_core/sorting';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { fetchParks, Park, ParksSortingField } from 'parks/api';
import {
  formatParkSchedules,
  IParkScheduleSerialized,
} from 'parksSchedules/api';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

import { SortingInput } from '../../_core/sortingInput';

interface ListTableItem
  extends Omit<Park, 'dailySchedules' | 'weekendSchedules'> {
  dailySchedules: IParkScheduleSerialized[];
  weekendSchedules: IParkScheduleSerialized[];
}

const propsToFetch = {
  dailySchedules: '/parks_schedules',
  weekendSchedules: '/parks_schedules',
};

enum SortingField {
  IsMailingActive = 'isMailingActive',
  LastMailed = 'lastMailed',
  LatestDislocationChange = 'latestDislocationChange',
  ManualModified = 'manualModified',
  Name = 'name',
  WagonCount = 'wagonCount',
}

export default function ParksListRoute() {
  const { route, router } = useRouteNode('parks');
  const api = useApiClient();
  const [favoriteDocs] = usePreference<boolean>(['favoriteDocs']);

  const sorting = useMemo(
    () =>
      validateSorting(
        (field): field is SortingField =>
          Object.values(SortingField).includes(field as SortingField),
        sortingFromRouterParam(route.params.sorting),
        {
          field: SortingField.ManualModified,
          direction: SortingDirection.Descending,
        }
      ),
    [route.params.sorting]
  );

  const { data, isFetching, refetch } = useAsyncData(
    [
      api,
      favoriteDocs,
      route.params.hasWagons,
      route.params.page,
      route.params.pageSize,
      route.params.search,
      sorting,
    ],
    async () => {
      const { meta, results } = await fetchParks(api, {
        favoriteParks: favoriteDocs,
        hasWagons: route.params.hasWagons
          ? (route.params.hasWagons as string).split(',')
          : undefined,
        page: isFinite(route.params.page)
          ? Number(route.params.page)
          : undefined,
        pageSize: isFinite(route.params.pageSize)
          ? Number(route.params.pageSize)
          : undefined,
        search: route.params.search,
        sorting: expandSorting(sorting, {
          [SortingField.IsMailingActive]: [ParksSortingField.IsMailingActive],
          [SortingField.LastMailed]: [ParksSortingField.LastMailed],
          [SortingField.LatestDislocationChange]: [
            ParksSortingField.LatestDislocationChange,
          ],
          [SortingField.ManualModified]: [ParksSortingField.ManualModified],
          [SortingField.Name]: [ParksSortingField.Name],
          [SortingField.WagonCount]: [ParksSortingField.WagonCount],
        }),
      });

      const parks = await (fetchRelated(
        api,
        propsToFetch,
        results
      ) as unknown as Promise<ListTableItem[]>);

      return {
        currentPage: meta.currentPage,
        parks,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
      };
    }
  );

  const listParams = JSON.stringify(route.params);

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const columns = useMemo(
    (): Array<ListTableColumn<ListTableItem>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 450,
        sortable: true,
        startSortingWith: SortingDirection.Ascending,
        copyCellContent: park => park.name,
        renderCellContent: park => (
          <Link params={{ id: park.id, listParams }} to="parks.edit">
            {park.name}
          </Link>
        ),
      },
      {
        id: 'wagonCount',
        label: 'Количество вагонов',
        defaultWidth: 130,
        sortable: true,
        copyCellContent: park => String(park.wagonCount),
        renderCellContent: park => park.wagonCount,
      },
      {
        id: 'isMailingActive',
        label: 'Рассылка активна',
        defaultWidth: 160,
        sortable: true,
        copyCellContent: park =>
          park.isMailingActive ? 'Рассылка активна' : 'Рассылка неактивна',
        renderCellContent: park => <BooleanIcon value={park.isMailingActive} />,
      },
      {
        id: 'dailySchedules',
        label: 'Будние дни',
        defaultWidth: 135,
        copyCellContent: park => formatParkSchedules(park.dailySchedules),
        renderCellContent: park => formatParkSchedules(park.dailySchedules),
      },
      {
        id: 'weekendSchedules',
        label: 'Выходные дни',
        defaultWidth: 135,
        copyCellContent: park => formatParkSchedules(park.weekendSchedules),
        renderCellContent: park => formatParkSchedules(park.weekendSchedules),
      },
      {
        id: 'lastMailed',
        label: 'Последняя рассылка',
        defaultWidth: 180,
        sortable: true,
        copyCellContent: park =>
          park.lastMailed
            ? dayjs(park.lastMailed).format(DATE_FORMAT_DATETIME)
            : '',
        renderCellContent: park =>
          park.lastMailed &&
          dayjs(park.lastMailed).format(DATE_FORMAT_DATETIME),
      },
      {
        id: 'latestDislocationChange',
        label: 'Дислокация обновлена',
        defaultWidth: 180,
        sortable: true,
        copyCellContent: park =>
          park.latestDislocationChange
            ? dayjs(park.latestDislocationChange).format(DATE_FORMAT_DATETIME)
            : '',
        renderCellContent: park =>
          park.latestDislocationChange &&
          dayjs(park.latestDislocationChange).format(DATE_FORMAT_DATETIME),
      },
      {
        id: 'manualModified',
        label: 'Изменён',
        defaultWidth: 120,
        sortable: true,
        copyCellContent: park =>
          dayjs(park.manualModified).format(DATE_FORMAT_DATETIME),
        renderCellContent: park =>
          dayjs(park.manualModified).format(DATE_FORMAT_DATETIME),
      },
    ],
    [listParams]
  );

  const getItemId = useCallback((park: ListTableItem) => String(park.id), []);

  const defaultSorting: ISorting<SortingField> = {
    field: SortingField.ManualModified,
    direction: SortingDirection.Descending,
  };

  return (
    <>
      <FormattedTitle>Парки</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <WagonNumbersFilter
                  initialValue={route.params.hasWagons}
                  onApply={hasWagons => {
                    applyFilterParams({ hasWagons });
                  }}
                />

                <Button text="Обновить" onClick={refetch} />

                <LinkButton text="Сбросить фильтры" to={route.name} />

                <LinkButton
                  intent={Intent.PRIMARY}
                  params={{ listParams }}
                  text="Добавить"
                  to="parks.create"
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Grid>
            <Col align="end">
              <SortingInput
                defaultValue={defaultSorting}
                options={columns
                  .filter(column => column.sortable)
                  .map(column => ({
                    label: column.label,
                    value: column.id as SortingField,
                  }))}
                value={sorting}
                onChange={handleSortingChange}
              />
            </Col>
          </Grid>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <ListTable
              columns={columns}
              getItemId={getItemId}
              isFetching={isFetching}
              items={data.parks}
              lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
              sorting={sorting}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>
    </>
  );
}

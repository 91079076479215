interface IAnalyticsModule {
  initialize: () => void;
  trackPage: (url: string) => void;
}

export interface IAnalytics {
  trackPage: (url: string) => void;
}

export function createAnalytics({
  modules,
}: {
  modules: IAnalyticsModule[];
}): IAnalytics {
  modules.forEach(mod => {
    mod.initialize();
  });

  return {
    trackPage: url => {
      modules.forEach(mod => {
        mod.trackPage(url);
      });
    },
  };
}

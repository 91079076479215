import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { Link } from '_core/router5/link';
import { useErpSelector } from '_erp/redux';
import { HTMLTable, NonIdealState } from '@blueprintjs/core';
import * as React from 'react';

import { selectTechrunRequestsDowntimesWidgetData } from '../redux';
import * as css from './techrunRequestsDowntimes.module.css';

export function TechrunRequestsDowntimesWidget() {
  const { data, isFetched } = useErpSelector(
    selectTechrunRequestsDowntimesWidgetData
  );

  return !data ? (
    isFetched ? (
      <GenericErrorMessage />
    ) : (
      <CenteredSpinner />
    )
  ) : data.length === 0 ? (
    <NonIdealState
      icon="star-empty"
      title="Сверхнормативных простоев не найдено"
    />
  ) : (
    <HTMLTable bordered className={css.table} condensed>
      <tbody>
        {data.map(requestDowntime => (
          <tr key={requestDowntime.requestId}>
            <td>
              <Link
                params={{ request: String(requestDowntime.requestId) }}
                rel="noopener"
                target="_blank"
                to="downtimes"
              >
                {requestDowntime.requestName}
              </Link>
            </td>

            <td className={css.numberCell}>
              {requestDowntime.requestDowntimes}
            </td>
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  );
}

export enum PaymentType {
  BankOrder = 'BANK_ORDER',
  PaymentOrder = 'PAYMENT_ORDER',
}

export const getPaymentTypeLabel = (paymentType: PaymentType) => {
  return {
    [PaymentType.BankOrder]: 'Банковский ордер',
    [PaymentType.PaymentOrder]: 'Платежное поручение',
  }[paymentType];
};

export enum OperationType {
  Purchase = 'purchase',
  Sell = 'sell',
}

export const getOperationTypeLabel = (operationType: OperationType) => {
  return {
    [OperationType.Purchase]: 'Покупка',
    [OperationType.Sell]: 'Продажа',
  }[operationType];
};

export interface IPayment {
  amount: string;
  consignee: number | null;
  consigneeAccount: string;
  consigneeBank: string;
  consigneeBik: string;
  consigneeCheckingAccount: string;
  consigneeCorrespondentAccount: string;
  consigneeInn: string;
  consigneeKpp: string;
  consigneeName: string;
  contractPurchase: number | null;
  contractSell: number | null;
  date: string;
  datePaid: string;
  hasDuplicate: boolean;
  id: number;
  number: number;
  operationType: OperationType;
  payer: number | null;
  payerAccount: string;
  payerBank: string;
  payerBik: string;
  payerCheckingAccount: string;
  payerCorrespondentAccount: string;
  payerInn: string;
  payerKpp: string;
  payerName: string;
  purpose: string;
  type: PaymentType;
}

import { DATE_FORMAT_API_DATETIME } from '_core/dates/formats';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import {
  DateInputInFormGroup,
  DateInputInFormGroupForFinalForm,
} from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { SelectInFormGroupForFinalForm } from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, H4, Intent, Tooltip } from '@blueprintjs/core';
import dayjs from 'dayjs';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row } from 'layout/contentLayout';
import * as React from 'react';
import { useRef } from 'react';
import { useRouter } from 'react-router5';
import { IRepair, RepairType } from 'repairs/api';
import { repairTypeOptions } from 'repairs/utils';
import { StationsAutocompleteInFormGroupForFinalForm } from 'stations/autocomplete';

import { IRepairPartsFieldItem, RepairPartsField } from './partsField';

export interface IOurRepairsFormValues {
  corrArrivedForLoadDate: Date | null;
  corrSendedFromRepairDate: Date | null;
  corrSendedToRepairDate: Date | null;
  corrVu23Date: Date | null;
  corrVu36Date: Date | null;
  dontBill: boolean;
  dontBillCause: string;
  endDate: Date | null;
  incomeRate: string;
  parts: IRepairPartsFieldItem[];
  purchaseFiles: string[];
  repairStation: number | null;
  sellFiles: string[];
  services: IRepairPartsFieldItem[];
  startDate: Date | null;
  type: RepairType;
  wagon: string;
}

interface IProps {
  initialValues: IOurRepairsFormValues;
  navigationLock: IFormNavigationLock;
  repair?: IRepair;
  onSave: (values: IOurRepairsFormValues) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IOurRepairsFormValues
  ) => Promise<SubmissionErrors | void>;
}

type SubmitTrigger = 'save' | 'saveAndContinue';

export function OurRepairsForm({
  initialValues,
  navigationLock,
  repair,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const router = useRouter();
  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <FinalForm
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <Row>
            <Grid>
              <Col span={4}>
                <H4>Основная информация</H4>

                <FormGroupForFinalForm
                  label="Вагон"
                  labelFor="wagon"
                  labelInfo="*"
                  name="wagon"
                >
                  <InputGroupForFinalForm id="wagon" name="wagon" />
                </FormGroupForFinalForm>

                <SelectInFormGroupForFinalForm
                  id="type"
                  label="Тип ремонта"
                  name="type"
                  options={repairTypeOptions}
                />

                <DateInputInFormGroupForFinalForm
                  fill
                  id="startDate"
                  label="Дата начала действия"
                  name="startDate"
                  required
                />

                <DateInputInFormGroupForFinalForm
                  fill
                  id="endDate"
                  label="Дата окончания действия"
                  name="endDate"
                />

                <StationsAutocompleteInFormGroupForFinalForm
                  id="repairStation"
                  label="Станция ремонта"
                  name="repairStation"
                />

                <CheckboxForFinalForm
                  id="dontBill"
                  label="Не перевыставлять"
                  name="dontBill"
                />

                <FormGroupForFinalForm
                  label="Причина не перевыставлять"
                  labelFor="dontBillCause"
                  name="dontBillCause"
                >
                  <InputGroupForFinalForm
                    id="dontBillCause"
                    name="dontBillCause"
                  />
                </FormGroupForFinalForm>

                <FormGroupForFinalForm
                  label="Процент для расчета дохода"
                  labelFor="incomeRate"
                  name="incomeRate"
                >
                  <InputGroupForFinalForm id="incomeRate" name="incomeRate" />
                </FormGroupForFinalForm>

                <Row>
                  <Tooltip
                    content={
                      !repair?.vu23Date
                        ? 'Отсутствует дата перевода в нерабочий парк (ВУ-23)'
                        : ''
                    }
                  >
                    <Button
                      intent={Intent.SUCCESS}
                      disabled={!repair?.vu23Date}
                      onClick={() => {
                        router.navigate('wagons.edit.history', {
                          dislocationDatetime: repair?.vu23Date
                            ? dayjs(repair.vu23Date)
                                .subtract(5, 'day')
                                .format(DATE_FORMAT_API_DATETIME)
                            : undefined,
                          number: values.wagon,
                          toDatetime: repair?.vu23Date
                            ? dayjs(repair.vu23Date).format(
                                DATE_FORMAT_API_DATETIME
                              )
                            : undefined,
                        });
                      }}
                    >
                      Дислокации за 5 суток до даты перевода в нерабочий парк
                      (ВУ-23)
                    </Button>
                  </Tooltip>
                </Row>

                <Row>
                  <Tooltip
                    content={
                      !repair?.arrivedForLoadDate
                        ? 'Отсутствует дата прибытия на первую станцию погрузки'
                        : ''
                    }
                  >
                    <Button
                      intent={Intent.SUCCESS}
                      disabled={!repair?.arrivedForLoadDate}
                      onClick={() => {
                        router.navigate('wagons.edit.history', {
                          dislocationDatetime: repair?.arrivedForLoadDate
                            ? dayjs(repair.arrivedForLoadDate).format(
                                DATE_FORMAT_API_DATETIME
                              )
                            : undefined,
                          number: values.wagon,
                          toDatetime: repair?.arrivedForLoadDate
                            ? dayjs(repair.arrivedForLoadDate)
                                .add(5, 'day')
                                .format(DATE_FORMAT_API_DATETIME)
                            : undefined,
                        });
                      }}
                    >
                      Дислокации за 5 суток после даты прибытия на 1-ю станцию
                      погрузки
                    </Button>
                  </Tooltip>
                </Row>
              </Col>

              <Col span={8}>
                <Row>
                  <Grid>
                    <Col span={4}>
                      <H4>Даты</H4>

                      <DateInputInFormGroup
                        fill
                        id="sendedToRepairDate"
                        label="Дата отправки в ремонт"
                        name="sendedToRepairDate"
                        readOnly
                        value={repair?.sendedToRepairDate ?? null}
                      />

                      <DateInputInFormGroup
                        fill
                        id="vu23Date"
                        label="Дата перевода в нерабочий парк (ВУ-23)"
                        name="vu23Date"
                        readOnly
                        value={repair?.vu23Date ?? null}
                      />

                      <DateInputInFormGroup
                        fill
                        id="vu36Date"
                        label="Дата перевода в рабочий парк (ВУ-36)"
                        name="vu36Date"
                        readOnly
                      />

                      <DateInputInFormGroup
                        fill
                        id="sendedFromRepairDate"
                        label="Дата отправки из ремонта"
                        name="sendedFromRepairDate"
                        readOnly
                        value={repair?.sendedFromRepairDate ?? null}
                      />

                      <DateInputInFormGroup
                        fill
                        id="arrivedForLoadDate"
                        label="Дата прибытия на первую станцию погрузки"
                        name="arrivedForLoadDate"
                        readOnly
                        value={repair?.arrivedForLoadDate ?? null}
                      />

                      <H4>Загруженные файлы по расходам</H4>

                      <FileInputMultipleForFinalForm
                        id="purchaseFiles"
                        name="purchaseFiles"
                      />
                    </Col>

                    <Col span={4}>
                      <H4>Корр</H4>

                      <DateInputInFormGroupForFinalForm
                        fill
                        id="corrSendedToRepairDate"
                        label="Фактическая дата отправки в ремонт"
                        name="corrSendedToRepairDate"
                      />

                      <DateInputInFormGroupForFinalForm
                        fill
                        id="corrVu23Date"
                        label="Фактическая дата перевода в нерабочий парк (ВУ-23)"
                        name="corrVu23Date"
                      />

                      <DateInputInFormGroupForFinalForm
                        fill
                        id="corrVu36Date"
                        label="Фактическая дата перевода в рабочий парк (ВУ-36)"
                        name="corrVu36Date"
                      />

                      <DateInputInFormGroupForFinalForm
                        fill
                        id="corrSendedFromRepairDate"
                        label="Фактическая дата отправки из ремонта"
                        name="corrSendedFromRepairDate"
                      />

                      <DateInputInFormGroupForFinalForm
                        fill
                        id="corrArrivedForLoadDate"
                        label="Фактическая дата прибытия на первую станцию погрузки"
                        name="corrArrivedForLoadDate"
                      />

                      <H4>Загруженные файлы по доходам</H4>

                      <FileInputMultipleForFinalForm
                        id="sellFiles"
                        name="sellFiles"
                      />
                    </Col>
                  </Grid>
                </Row>
              </Col>
            </Grid>
          </Row>

          <Row>
            <Grid>
              <Col span={6}>
                <H4>Услуги</H4>

                <RepairPartsField change={form.change} name="services" />
              </Col>

              <Col span={6}>
                <H4>Детали</H4>

                <RepairPartsField change={form.change} name="parts" />
              </Col>
            </Grid>
          </Row>

          <Row stickToBottom>
            <Toolbar align="right">
              <Button
                disabled={submitting}
                text="Сохранить и продолжить редактирование"
                onClick={() => {
                  submitTriggerRef.current = 'saveAndContinue';
                  form.submit();
                }}
              />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                text="Сохранить"
                type="submit"
                onClick={() => {
                  submitTriggerRef.current = 'save';
                }}
              />
            </Toolbar>
          </Row>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { Switch } from '_core/inputs/switch';
import { logoutAction, selectMe } from '_core/me/me';
import { usePreference } from '_core/me/usePreference';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import { useErpDispatch, useErpSelector } from '_erp/redux';
import {
  fetchMenuCounters,
  selectNewExpeditionTransportationsCount,
  selectNewTechrunTransportationsCount,
} from '_erp/stats';
import {
  Alignment,
  Button,
  Classes,
  Collapse,
  Icon,
  Intent,
  Menu,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Text,
} from '@blueprintjs/core';
import { formatUserName } from 'accounts/formatUserName';
import cx from 'classnames';
import * as React from 'react';
import { useRouter } from 'react-router5';

import * as css from './layout.module.css';

interface IProps {
  children: React.ReactNode;
  orgName: string | undefined;
}

interface IMenuItemProps {
  activeStrict?: boolean;
  children?:
    | React.ReactElement<IMenuItemProps>
    | Array<React.ReactElement<IMenuItemProps>>;
  depth?: number;
  labelElement?: React.ReactNode;
  text: string;
  params?: { [key: string]: string };
  to?: string;
}

function MenuItem({
  activeStrict,
  children,
  depth = 0,
  labelElement,
  text,
  params,
  to,
}: IMenuItemProps) {
  const [isOpen, setIsOpen] = React.useState(true);
  const router = useRouter();

  const [isActive, setIsActive] = React.useState(() =>
    to ? router.isActive(to, params, activeStrict) : false
  );

  React.useEffect(() => {
    if (!to) {
      return;
    }

    const sub = router.subscribe(() => {
      setIsActive(router.isActive(to, params, activeStrict));
    });

    return () => {
      if (typeof sub === 'function') {
        sub();
      } else {
        sub.unsubscribe();
      }
    };
  }, [activeStrict, params, router, to]);

  const collapsible = to == null;

  return (
    <li>
      <Link
        className={cx(
          Classes.MENU_ITEM,
          isActive ? Classes.intentClass(Intent.PRIMARY) : undefined,
          {
            [Classes.ACTIVE]: isActive,
            [css.menuLink_collapsible]: collapsible,
          }
        )}
        params={params}
        style={{ paddingLeft: depth * 23 }}
        to={to}
        onClick={() => {
          setIsOpen(prevIsOpen => !prevIsOpen);
        }}
      >
        <Icon
          icon={
            collapsible ? (isOpen ? 'caret-down' : 'caret-right') : undefined
          }
        />

        <Text className={Classes.FILL} ellipsize>
          {text}
        </Text>

        {labelElement && (
          <span className={Classes.MENU_ITEM_LABEL}>{labelElement}</span>
        )}
      </Link>

      <Collapse isOpen={isOpen} keepChildrenMounted>
        {children && (
          <ul className={Classes.LIST_UNSTYLED}>
            {React.Children.map(children, child =>
              React.cloneElement(React.Children.only(child), {
                depth: depth + 1,
              })
            )}
          </ul>
        )}
      </Collapse>
    </li>
  );
}

function MenuItemBadge({ children }: { children: React.ReactNode }) {
  return <span className={css.layoutMenuItemBadgeRoot}>{children}</span>;
}

function AppMenu() {
  const newExpeditionTransportationsCount = useErpSelector(
    selectNewExpeditionTransportationsCount
  );

  const newTechrunTransportationsCount = useErpSelector(
    selectNewTechrunTransportationsCount
  );

  const dispatch = useErpDispatch();
  const router = useRouter();

  React.useEffect(() => {
    let lastRoute = router.getState();

    const sub = router.subscribe(() => {
      const route = router.getState();

      if (route.name !== lastRoute.name) {
        dispatch(fetchMenuCounters());
      }

      lastRoute = route;
    });

    dispatch(fetchMenuCounters());

    return () => {
      if (typeof sub === 'function') {
        sub();
      } else {
        sub.unsubscribe();
      }
    };
  }, [dispatch, router]);

  const [favoriteDocs] = usePreference<boolean>(['favoriteDocs']);

  return (
    <Menu>
      <MenuItem text="Справочники">
        <MenuItem text="Контрагенты" to="partners" />
        <MenuItem text="Контакты" to="contacts" />
        <MenuItem text="Грузоотправители" to="consigners" />
        <MenuItem text="Справочник станций" to="stations" />
      </MenuItem>

      <MenuItem text="Парки">
        <MenuItem text="Парки" to="parks" />
        <MenuItem text="Вагоны" to="wagons" />
      </MenuItem>

      <MenuItem text="Договоры">
        <MenuItem text="Входящие" to="contracts.purchase" />
        <MenuItem text="Исходящие" to="contracts.sell" />
      </MenuItem>

      <MenuItem text="Подход">
        <MenuItem
          text="Новые"
          params={{ distanceLower: '300', group: 'new' }}
          to="wagonArrivals"
        />

        <MenuItem
          text="Оформленные"
          params={{ distanceLower: '300', group: 'executed' }}
          to="wagonArrivals"
        />

        <MenuItem
          text="Отклонённые"
          params={{ distanceLower: '300', group: 'rejected' }}
          to="wagonArrivals"
        />
      </MenuItem>

      <MenuItem text="Техрейс">
        <MenuItem text="Документы ГУ-12" to="techruns.gu12" />
        <MenuItem text="Заявки" to="techruns.requests" />

        <MenuItem text="Распределение вагонов" to="techruns.assignWagons" />

        <MenuItem text="Отправки">
          <MenuItem
            labelElement={
              favoriteDocs && newTechrunTransportationsCount != null ? (
                <MenuItemBadge>{newTechrunTransportationsCount}</MenuItemBadge>
              ) : undefined
            }
            params={{ status: 'new' }}
            text="Новые"
            to="techruns.transportations"
          />

          <MenuItem
            text="Подтверждённые"
            params={{ status: 'confirmed' }}
            to="techruns.transportations"
          />

          <MenuItem
            text="Отклонённые"
            params={{ status: 'rejected' }}
            to="techruns.transportations"
          />

          <MenuItem
            text="Служебные"
            params={{ status: 'supplementary' }}
            to="techruns.transportations"
          />
        </MenuItem>
      </MenuItem>

      <MenuItem text="Экспедирование">
        <MenuItem text="Заявки" to="expeditions.requests" />

        <MenuItem text="Отправки">
          <MenuItem
            labelElement={
              favoriteDocs && newExpeditionTransportationsCount != null ? (
                <MenuItemBadge>
                  {newExpeditionTransportationsCount}
                </MenuItemBadge>
              ) : undefined
            }
            params={{ status: 'new' }}
            text="Новые"
            to="expeditions.transportations"
          />

          <MenuItem
            text="Подтверждённые"
            params={{ status: 'confirmed' }}
            to="expeditions.transportations"
          />

          <MenuItem
            text="Отклонённые"
            params={{ status: 'rejected' }}
            to="expeditions.transportations"
          />

          <MenuItem
            text="Служебные"
            params={{ status: 'supplementary' }}
            to="expeditions.transportations"
          />
        </MenuItem>
      </MenuItem>

      <MenuItem text="Аренда">
        <MenuItem text="Вагоны входящие" to="rentWagons.purchase" />
        <MenuItem text="Вагоны исходящие" to="rentWagons.sell" />
      </MenuItem>

      <MenuItem text="Техническое">
        <MenuItem text="Взвешивание" to="weighing" />

        <MenuItem text="Ремонты">
          <MenuItem text="По паркам" to="repairs" />
          <MenuItem text="Наши ремонты" to="ourRepairs" />
        </MenuItem>

        <MenuItem text="Простои">
          <MenuItem activeStrict text="Простои" to="downtimes" />

          <MenuItem
            text="Простои по паркам"
            to="downtimes.parks"
            params={{ downtimeDurationGreater: '3' }}
          />
        </MenuItem>
      </MenuItem>

      <MenuItem text="Бухгалтерия">
        <MenuItem text="Отгрузочная информация" to="shipmentInfo" />
        <MenuItem text="Наши документы" to="ourDocuments" />

        <MenuItem text="Документы от подрядчика">
          <MenuItem text="Документы от подрядчика" to="suppliersDocuments" />

          <MenuItem text="Документы из РЖД" to="suppliersDocumentsFromFile" />
        </MenuItem>

        <MenuItem text="Счета в оплату" to="invoices" />
        <MenuItem text="Банк" to="payments" />
      </MenuItem>

      <MenuItem text="Аналитика">
        <MenuItem text="Вагоны на слежении" to="wagonsTracking" />
      </MenuItem>
    </Menu>
  );
}

export function Layout({ children, orgName }: IProps) {
  const currentUser = useErpSelector(selectMe);
  const dispatch = useErpDispatch();
  const router = useRouter();

  const [favoriteDocs, setFavoriteDocs] = usePreference<boolean>([
    'favoriteDocs',
  ]);

  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  return (
    <div className={css.root}>
      <Navbar fixedToTop>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>
            <LinkButton icon="home" minimal text={orgName} to="home" />
          </NavbarHeading>
        </NavbarGroup>

        {currentUser && (
          <NavbarGroup align={Alignment.RIGHT}>
            <Switch
              checked={favoriteDocs}
              className={css.favoriteDocsSwitch}
              label="Мои документы"
              noBottomMargin
              onChange={setFavoriteDocs}
            />

            <span>
              <Icon
                className={css.userNameIcon}
                icon="user"
                iconSize={Icon.SIZE_STANDARD}
              />

              {formatUserName(currentUser)}
            </span>

            <Button
              aria-label="Выйти"
              disabled={isLoggingOut}
              icon="log-out"
              loading={isLoggingOut}
              minimal
              onClick={async () => {
                try {
                  setIsLoggingOut(true);
                  await dispatch(logoutAction());
                  const route = router.getState();
                  router.navigate(route.name, route.params, { reload: true });
                } finally {
                  setIsLoggingOut(false);
                }
              }}
            />
          </NavbarGroup>
        )}
      </Navbar>

      <div className={css.menuAndContent}>
        {currentUser && (
          <>
            <div className={css.menuPlaceholder} />

            <div className={css.menuWrapper}>
              <AppMenu />
            </div>
          </>
        )}

        <div className={css.layoutContent}>{children}</div>
      </div>
    </div>
  );
}

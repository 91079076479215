import { calcForForm } from '_core/decimal';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import {
  InputMoneyInFormGroup,
  InputMoneyInFormGroupForFinalForm,
} from '_core/inputs/inputMoney';
import { Button, H4, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { Col, Grid } from 'layout/contentLayout';
import * as React from 'react';

interface IProps {
  currentValue: string;
  onSubmit: (
    values: IPaymentDetailsSplitFormValues
  ) => Promise<SubmissionErrors | void>;
}

export interface IPaymentDetailsSplitFormValues {
  amount: string;
}

export function PaymentsEditSplitForm({ currentValue, onSubmit }: IProps) {
  return (
    <FinalForm<IPaymentDetailsSplitFormValues>
      initialValues={{ amount: '' }}
      onSubmit={onSubmit}
    >
      {({ error, handleSubmit, submitError, submitting, values }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormErrors error={error || submitError} />

          <Grid>
            <Col span={4}>
              <H4>Разделить платеж</H4>

              <InputMoneyInFormGroupForFinalForm
                label="Сумма нового документа"
                name="amount"
              />

              <InputMoneyInFormGroup
                label="Остаток"
                readOnly
                value={calcForForm((currentAmount, newAmount) =>
                  currentAmount.minus(newAmount)
                )(currentValue, values.amount)}
              />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Разделить"
                type="submit"
              />
            </Col>
          </Grid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { isNotNull } from '_core/isNotNull';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import dayjs from 'dayjs';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { WagonsActiveRequestsAlert } from 'requests/wagonsActiveRequestsAlert';
import { fetchTechrunRequests } from 'techrunRequests/api';
import { ITechrunRequest, TechrunRequestStatus } from 'techrunRequests/types';
import { fetchAllWagonTypes } from 'wagonTypes/api';
import { getDefaultWagonType } from 'wagonTypes/utils';

import { TechrunRequestForm } from './form/form';
import { TechrunRequestsFormRouteWrapper } from './formRouteWrapper';

export default function TechrunRequestsCreateRoute() {
  const { route, router } = useRouteNode('techruns.requests.create');
  const navigationLock = useFormNavigationLock('techruns.requests.create');
  const api = useApiClient();

  const { data, isFetching } = useAsyncData(
    [api, route.params.copyFrom, route.params.id],
    async () => {
      if (route.params.copyFrom == null) {
        return null;
      }

      const request = await api.get<ITechrunRequest>(
        `/techrun_requests/${route.params.copyFrom}`
      );

      return request;
    }
  );

  const wagonTypes = useAsyncData([api], () => fetchAllWagonTypes(api));

  const isDuplicating = route.params.copyFrom != null;

  const [alertState, setAlertState] = useState<{
    activeRequestsWithAddedWagons: ITechrunRequest[];
    addedWagons: string[];
    isOpen: boolean;
  } | null>({
    activeRequestsWithAddedWagons: [],
    addedWagons: [],
    isOpen: false,
  });

  const createdRequestRouteRef = useRef<{
    name: string;
    params: Record<string, string>;
  } | null>(null);

  function navigateToCreatedRequestRoute() {
    const createdRequestRoute = createdRequestRouteRef.current;

    if (!createdRequestRoute) {
      return;
    }

    router.navigate(createdRequestRoute.name, createdRequestRoute.params);
  }

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const defaultWagonType = getDefaultWagonType(wagonTypes.data);

  return (isDuplicating && !data) || !wagonTypes.data ? (
    isFetching || wagonTypes.isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <TechrunRequestsFormRouteWrapper
      listParams={listParams}
      title="Новая заявка"
    >
      <TechrunRequestForm
        initialValues={
          isDuplicating && data
            ? {
                ...data,
                gu12Docs: data.gu12Docs.map(number => ({ number })),
                sellRate: String(data.sellRate),
                wagonsRequired: String(data.wagonsRequired),
                wagonType: String(data.wagonType),
                endDate: dayjs().endOf('month').startOf('date').toDate(),
                startDate: dayjs().startOf('date').toDate(),
                wagons: [],
              }
            : {
                arrivalStation: null,
                consigner: null,
                departureStation: null,
                endDate: dayjs().endOf('month').startOf('date').toDate(),
                gu12Docs: [],
                name: '',
                partner: null,
                sellRate: null,
                startDate: dayjs().startOf('date').toDate(),
                status: TechrunRequestStatus.Created,
                trackRepair: false,
                wagons: [],
                wagonsRequired: '',
                wagonType: defaultWagonType
                  ? String(defaultWagonType.id)
                  : null,
              }
        }
        navigationLock={navigationLock}
        wagonTypes={wagonTypes.data}
        onSubmit={async values => {
          try {
            const createdTechrunRequest = await api.post<ITechrunRequest>(
              '/techrun_requests',
              {
                ...values,
                endDate:
                  values.endDate == null
                    ? null
                    : dayjs(values.endDate).format(DATE_FORMAT_API_DATE),
                gu12Docs: values.gu12Docs
                  .map(({ number }) => number)
                  .filter(isNotNull),
                startDate:
                  values.startDate == null
                    ? null
                    : dayjs(values.startDate).format(DATE_FORMAT_API_DATE),
              }
            );

            navigationLock.unlock();

            createdRequestRouteRef.current = {
              name: 'techruns.requests.view',

              params: {
                id: String(createdTechrunRequest.id),
                listParams: route.params.listParams,
              },
            };

            const addedWagons = values.wagons.map(wagon => wagon.wagon);

            if (addedWagons.length) {
              const requestsListResponse = await fetchAllPages(page =>
                fetchTechrunRequests(api, {
                  forDate: new Date(),
                  hasWagons: addedWagons,
                  page,
                  statuses: [
                    TechrunRequestStatus.Active,
                    TechrunRequestStatus.Created,
                  ],
                })
              );

              const activeRequestsWithAddedWagons =
                requestsListResponse.results;

              if (activeRequestsWithAddedWagons.length !== 0) {
                setAlertState({
                  activeRequestsWithAddedWagons,
                  addedWagons,
                  isOpen: true,
                });
                return;
              }
            }

            navigateToCreatedRequestRoute();

            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось создать заявку: Непредвиденная ошибка'
            );
          }
        }}
      />

      {alertState && (
        <WagonsActiveRequestsAlert
          activeRequestsWithAddedWagons={
            alertState.activeRequestsWithAddedWagons
          }
          addedWagons={alertState.addedWagons}
          isOpen={alertState.isOpen}
          itemRoute="techruns.requests.view"
          onClosed={() => {
            navigateToCreatedRequestRoute();
          }}
          onConfirm={() => {
            setAlertState(
              prevState =>
                prevState && {
                  ...prevState,
                  isOpen: false,
                }
            );
          }}
        />
      )}
    </TechrunRequestsFormRouteWrapper>
  );
}

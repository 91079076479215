import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { DialogForm } from '_core/forms/dialogForm';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { Button, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import * as React from 'react';

export interface IBatchEditDialogFormValues {
  capacity: string;
  model: string;
  tara: string;
}

interface IProps {
  initialValues: IBatchEditDialogFormValues;
  isOpen: boolean;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (
    values: IBatchEditDialogFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function BatchEditDialogForm({
  initialValues,
  isOpen,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, submitting }) => (
        <DialogForm
          isOpen={isOpen}
          isSubmitting={submitting}
          title="Групповое редактирование"
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormGroupForFinalForm label="Тара, т." labelFor="tara" name="tara">
              <InputGroupForFinalForm id="tara" name="tara" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Грузоподъёмность, т."
              labelFor="capacity"
              name="capacity"
            >
              <InputGroupForFinalForm id="capacity" name="capacity" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Модель" labelFor="model" name="model">
              <InputGroupForFinalForm id="model" name="model" />
            </FormGroupForFinalForm>
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                type="submit"
                text="Сохранить"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

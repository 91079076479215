import { Radio, RadioGroup as BlueprintRadioGroup } from '@blueprintjs/core';
import * as React from 'react';
import * as reactFinalForm from 'react-final-form';

interface ICommonProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  inline?: boolean;
}

interface IProps extends ICommonProps {
  selectedValue: string | number | undefined;
  onChange: (newChecked: string) => void;
}

export { Radio };

export function RadioGroup({ onChange, ...otherProps }: IProps) {
  return (
    <BlueprintRadioGroup
      {...otherProps}
      onChange={event => {
        onChange(event.currentTarget.value);
      }}
    />
  );
}

export function RadioGroupForFinalForm({
  disabled,
  name,
  ...otherProps
}: ICommonProps & { name: string }) {
  return (
    <reactFinalForm.Field name={name}>
      {({ input, meta }) => (
        <BlueprintRadioGroup
          {...otherProps}
          disabled={meta.submitting || disabled}
          name={input.name}
          selectedValue={input.value}
          onChange={input.onChange}
        />
      )}
    </reactFinalForm.Field>
  );
}

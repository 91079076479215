import { useApiClient } from '_core/api/context';
import { ListResponse } from '_core/api/types';
import { useColumnPresets } from '_core/lists/columnPresets/useColumnPresets';
import { useAsyncData } from '_core/useAsyncData';
import * as React from 'react';
import { useRoute } from 'react-router5';
import { IWagonSerialized } from 'wagons/api';
import { createWagonColumns } from 'wagons/table/columns';

import { WagonsList } from './wagonsList';

export function ParksEditDowntimesTab() {
  const { route, router } = useRoute();
  const api = useApiClient();

  const { data, isFetching } = useAsyncData([api, route], async () => {
    const { results } = await api.get<
      ListResponse<{ wagons: IWagonSerialized[] }>
    >('/wagon_downtimes', {
      alerts: route.params.alerts,
      arrivalStation: route.params.arrivalStation,
      consignee: route.params.consignee,
      departureStation: route.params.departureStation,
      downtimeDurationGreater: route.params.downtimeDurationGreater,
      groupBy: 'park',
      hasEtranNote: route.params.hasEtranNote,
      numbers: route.params.numbers,
      operationStation: route.params.operationStation,
      operationStationNot: route.params.operationStationNot,
      page: route.params.page,
      pageSize: route.params.pageSize,
      park: route.params.id,
      search: route.params.search,
    });

    return {
      items: results[0] ? results[0].wagons : [],
    };
  });

  const columns = createWagonColumns({
    listParams: JSON.stringify(route.params),
  })
    .slice()
    .filter(column => column.id !== 'requestPartner');

  const columnLabels = columns.reduce<Record<string, string>>(
    (labels, column) => {
      labels[column.id] = column.label;

      return labels;
    },
    {}
  );

  const {
    activePreset,
    columnOptions,
    columnWidths,
    defaultValue,
    initialValue,
    showColumns,
    onColumnPresetChange,
    onColumnWidthChanged,
    onShowColumnsApply,
  } = useColumnPresets(['parks', 'downtimes'], {
    allColumnIds: columns.map(column => column.id),
    alwaysShownColumnIds: ['number'],
    getColumnLabel: columnId => columnLabels[columnId],
  });

  return (
    <WagonsList
      activePreset={activePreset}
      applyFilterParams={params => {
        router.navigate(route.name, {
          ...route.params,
          page: undefined,
          ...params,
        });
      }}
      columnOptions={columnOptions}
      columns={columns}
      columnWidths={columnWidths}
      data={data}
      defaultValue={defaultValue}
      initialValue={initialValue}
      isFetching={isFetching}
      route={route}
      showColumns={showColumns}
      onColumnPresetChange={onColumnPresetChange}
      onColumnWidthChanged={onColumnWidthChanged}
      onShowColumnsApply={onShowColumnsApply}
    />
  );
}

import * as React from 'react';

import * as css from './tooltipContent.module.css';

interface IProps {
  children: React.ReactNode;
}

export function TooltipContent({ children }: IProps) {
  return <div className={css.root}>{children}</div>;
}

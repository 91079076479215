import { range } from '_core/fp/range';

import { ListResponse } from './types';

export async function fetchAllPages<TItem, TMetaExtended = {}>(
  fetchPage: (page: number) => Promise<ListResponse<TItem, TMetaExtended>>
): Promise<ListResponse<TItem, TMetaExtended>> {
  const firstResponse = await fetchPage(1);

  const otherPagesResponses = await Promise.all(
    range(2, firstResponse.meta.totalPages + 1).map(page => fetchPage(page))
  );

  return {
    meta: {
      ...firstResponse.meta,
      currentPage: 1,
      totalPages: 1,
    },

    results: [
      ...firstResponse.results,
      ...otherPagesResponses.flatMap(response => response.results),
    ],
  };
}

import { ApiClient } from '_core/api/client';
import { makeFetchAll } from '_core/api/makeFetchAll';
import { ListResponse } from '_core/api/types';
import { parseDate } from '_core/dates/utils';

interface IParkTemplateRecord {
  name: string;
  nameDb: string;
  model: string;
  type: string;
  group: string;
}

interface IParkTemplateSerialized {
  created: string;
  id: number;
  name: string;
  records: IParkTemplateRecord[];
}

export interface IParkTemplate
  extends Omit<IParkTemplateSerialized, 'created'> {
  created: Date;
}

function deserializeParkTemplate({
  created,
  ...otherProps
}: IParkTemplateSerialized): IParkTemplate {
  return {
    ...otherProps,
    created: parseDate(created),
  };
}

interface IFetchParksTemplatesParams {
  page?: number;
}

async function fetchParksTemplates(
  api: ApiClient,
  { page }: IFetchParksTemplatesParams = {}
): Promise<ListResponse<IParkTemplate>> {
  const response = await api.get<ListResponse<IParkTemplateSerialized>>(
    '/parks_templates',
    { page }
  );

  return {
    ...response,
    results: response.results.map(deserializeParkTemplate),
  };
}

export const fetchAllParksTemplates = makeFetchAll(fetchParksTemplates);

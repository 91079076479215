import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { useAsyncTasks } from '_core/download/asyncDownload';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import {
  IUploadFileResponse,
  useUploadFile,
} from '_core/fileUploader/fileUploaderContext';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { SearchForm } from '_core/forms/searchForm';
import { FileChooserButton } from '_core/inputs/fileChooserButton';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TreeTableNode } from '_core/react-window/types';
import {
  everyNode,
  findNode,
  setAllNodesIsExpanded,
} from '_core/react-window/utils';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
} from '_core/sorting';
import { StateContainer } from '_core/stateContainer';
import { plural } from '_core/strings/utils';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent, ProgressBar } from '@blueprintjs/core';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import {
  fetchSuppliersDocuments,
  fetchSuppliersDocumentsTree,
  SuppliersDocumentsSortingField,
  SuppliersDocumentsTreeSortingField,
} from 'suppliersDocuments/api';
import {
  isSuppliersDocumentState,
  isSuppliersDocumentType,
  suppliersDocumentStateOptions,
  suppliersDocumentTypeOptions,
} from 'suppliersDocuments/types';

import {
  SuppliersDocumentsFromFileTreeTable,
  SuppliersDocumentsFromFileTreeTableNodeData,
} from './treeTable';

export default function SuppliersDocumentsFromFileListRoute() {
  const { route, router } = useRouteNode('suppliersDocumentsFromFile');
  const api = useApiClient();
  const uploadFile = useUploadFile();
  const toaster = useToaster();
  const tasks = useAsyncTasks();

  const commonFilterParams = useMemo(
    () => ({
      docType: route.params.docType
        ? (route.params.docType as string)
            .split(',')
            .filter(isSuppliersDocumentType)
        : undefined,
      fromFile: true,
      ids: route.params.ids
        ? (route.params.ids as string).split(',').map(Number)
        : undefined,
      search: route.params.search,
      states: route.params.states
        ? (route.params.states as string)
            .split(',')
            .filter(isSuppliersDocumentState)
        : undefined,
    }),
    [route]
  );

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'date',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [commonFilterParams, api, sorting],
    async () => {
      const suppliersDocumentsTreeSorting: Array<
        ISorting<SuppliersDocumentsTreeSortingField>
      > = [];

      if (sorting.field === 'name') {
        suppliersDocumentsTreeSorting.push({
          field: SuppliersDocumentsTreeSortingField.SourceName,
          direction: sorting.direction,
        });
      } else if (sorting.field === 'date') {
        suppliersDocumentsTreeSorting.push({
          field: SuppliersDocumentsTreeSortingField.Created,
          direction: sorting.direction,
        });
      } else {
        throw new Error(`Unexpected sorting field: "${sorting.field}"`);
      }

      const treeResponse = await fetchSuppliersDocumentsTree(api, {
        ...commonFilterParams,
        sorting: suppliersDocumentsTreeSorting,
      });

      return {
        nodes: treeResponse.results.map(
          (
            treeItem
          ): TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData> => {
            const prevSourceNameNode = data
              ? findNode(
                  data.nodes,
                  node =>
                    node.data.kind === 'sourceName' &&
                    node.data.sourceName === treeItem.sourceName
                )
              : undefined;

            return {
              data: {
                kind: 'sourceName',
                sourceName: treeItem.sourceName,
                suppliersDocumentCount: treeItem.docsCount,
              },
              id: `sourceName-${treeItem.sourceName}`,
              children: {
                isExpanded:
                  prevSourceNameNode && prevSourceNameNode.children
                    ? prevSourceNameNode.children.isExpanded
                    : false,
                hasMoreChildNodesToFetch: true,
                nodes: [],
              },
            };
          }
        ),
      };
    }
  );

  const fetchChildNodes = useCallback(
    async (
      parentNodes: Array<
        TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>
      >
    ) => {
      const parentNode = parentNodes[parentNodes.length - 1];

      if (parentNode.data.kind === 'sourceName') {
        const sourceName = parentNode.data.sourceName;

        const suppliersDocumentsSorting: Array<
          ISorting<SuppliersDocumentsSortingField>
        > = [];

        if (sorting.field === 'name') {
          suppliersDocumentsSorting.push({
            field: SuppliersDocumentsSortingField.Number,
            direction: sorting.direction,
          });
        } else if (sorting.field === 'date') {
          suppliersDocumentsSorting.push({
            field: SuppliersDocumentsSortingField.Date,
            direction: sorting.direction,
          });
        } else {
          throw new Error(`Unexpected sorting field: "${sorting.field}"`);
        }

        const listResponse = await fetchAllPages(page =>
          fetchSuppliersDocuments(api, {
            ...commonFilterParams,
            page,
            sorting: suppliersDocumentsSorting,
            sourceName,
          })
        );

        return listResponse.results.map(
          (
            suppliersDocument
          ): TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData> => ({
            data: { kind: 'suppliersDocument', suppliersDocument },
            id: `suppliersDocument-${suppliersDocument.id}`,
          })
        );
      }

      throw new Error(`Unexpected node kind: "${parentNode.data.kind}"`);
    },
    [commonFilterParams, api, sorting]
  );

  const handleNodesChange = useCallback(
    (
      newNodesOrUpdater:
        | Array<TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>>
        | ((
            prevNodes: Array<
              TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>
            >
          ) => Array<
            TreeTableNode<SuppliersDocumentsFromFileTreeTableNodeData>
          >)
    ) => {
      updateData(
        prevData =>
          prevData && {
            ...prevData,
            nodes:
              typeof newNodesOrUpdater === 'function'
                ? newNodesOrUpdater(prevData.nodes)
                : newNodesOrUpdater,
          }
      );
    },
    [updateData]
  );

  const listParams = JSON.stringify(route.params);

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },

    [applyFilterParams]
  );

  const allIsExpanded = data
    ? everyNode(data.nodes, node => !node.children || node.children.isExpanded)
    : false;

  return (
    <>
      <FormattedTitle>Документы из РЖД</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <CheckboxListFilter
                  allOption
                  initialValue={route.params.states}
                  label="Статусы"
                  options={suppliersDocumentStateOptions}
                  onApply={states => {
                    applyFilterParams({ states });
                  }}
                />

                <CheckboxListFilter
                  allOption
                  initialValue={route.params.docType}
                  label="Тип документа"
                  options={suppliersDocumentTypeOptions}
                  onApply={docType => {
                    applyFilterParams({ docType });
                  }}
                />

                <LinkButton
                  options={{ reload: true }}
                  params={route.params}
                  text="Обновить"
                  to={route.name}
                />

                <LinkButton text="Сбросить фильтры" to={route.name} />

                <StateContainer initialState={false}>
                  {(isUploading, setIsUploading) => (
                    <FileChooserButton
                      disabled={isUploading}
                      intent={Intent.PRIMARY}
                      loading={isUploading}
                      text="Добавить"
                      onChoose={async files => {
                        const file = files[0];
                        let uploadFileResponse: IUploadFileResponse | undefined;
                        let toastKey: string | undefined;

                        try {
                          setIsUploading(true);
                          const response = await uploadFile(file);
                          uploadFileResponse = response;

                          toastKey = toaster.show({
                            intent: Intent.PRIMARY,
                            timeout: 0,
                            message: (
                              <>
                                <p>
                                  Файл &quot;{response.name}
                                  &quot; обрабатывается
                                </p>

                                <ProgressBar intent={Intent.PRIMARY} />
                              </>
                            ),
                          });

                          const { taskId } = await api.post<{ taskId: string }>(
                            '/suppliers_documents/upload_xml',
                            { file: response.file }
                          );

                          const { createdDocs, isFinished, uploadedDocs } =
                            await tasks.waitForProcessedFiles(taskId);

                          if (isFinished) {
                            toastKey = toaster.show(
                              {
                                intent: Intent.SUCCESS,
                                timeout: 5000,
                                message: `Из файла "${
                                  response.name
                                }" успешно ${plural(createdDocs, [
                                  () => `создан ${createdDocs} документ`,
                                  () => `создано ${createdDocs} документа`,
                                  () => `создано ${createdDocs} документов`,
                                ])} из ${uploadedDocs}`,
                              },
                              toastKey
                            );
                          }

                          refetch();
                        } catch (err) {
                          toaster.show(
                            {
                              intent: Intent.DANGER,
                              timeout: 0,
                              message: uploadFileResponse
                                ? `Ошибка при обработка файла "${uploadFileResponse.name}"`
                                : `Не удалось загрузить файл "${file.name}"`,
                            },
                            toastKey
                          );

                          throw err;
                        } finally {
                          setIsUploading(false);
                        }
                      }}
                    />
                  )}
                </StateContainer>
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Toolbar>
            {allIsExpanded ? (
              <Button
                icon="collapse-all"
                text="Свернуть всё"
                onClick={() => {
                  updateData(
                    prevData =>
                      prevData && {
                        ...prevData,
                        nodes: setAllNodesIsExpanded(prevData.nodes, false),
                      }
                  );
                }}
              />
            ) : (
              <Button
                icon="expand-all"
                text="Развернуть всё"
                onClick={() => {
                  updateData(
                    prevData =>
                      prevData && {
                        ...prevData,
                        nodes: setAllNodesIsExpanded(prevData.nodes, true),
                      }
                  );
                }}
              />
            )}
          </Toolbar>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <SuppliersDocumentsFromFileTreeTable
              fetchChildNodes={fetchChildNodes}
              isFetching={isFetching}
              listParams={listParams}
              nodes={data.nodes}
              sorting={sorting}
              onNodesChange={handleNodesChange}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>
      </VGrid>
    </>
  );
}

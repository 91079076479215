import { selectPreference, setPreferenceAction } from '_core/me/me';
import { useErpDispatch, useErpSelector } from '_erp/redux';
import { unwrapResult } from '@reduxjs/toolkit';

export function usePreference<T = unknown>(
  path: string[]
): [T, (newValue: T) => void] {
  const dispatch = useErpDispatch();
  const value = useErpSelector<T>(state => selectPreference(state, path));

  return [
    value,
    newValue => {
      dispatch(setPreferenceAction({ path, value: newValue })).then(
        unwrapResult
      );
    },
  ];
}

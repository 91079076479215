import { DATE_FORMAT_DATE, DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { Button, Intent, Position, Tooltip } from '@blueprintjs/core';
import dayjs from 'dayjs';
import * as React from 'react';
import { IDislocationSerialized } from 'wagons/api';

import { formatDecimal } from '../_core/decimal';

export const createDislocationColumns = ({
  sortableByDistanceToArrivalStation,
}: {
  sortableByDistanceToArrivalStation?: boolean;
} = {}): Array<ListTableColumn<IDislocationSerialized>> => [
  {
    id: 'trainNumber',
    label: 'Номер поезда',
    defaultWidth: 65,
    copyCellContent: dislocation => dislocation.trainNumber,
    renderCellContent: dislocation => dislocation.trainNumber,
  },
  {
    id: 'trainIndex',
    label: 'Индекс поезда',
    defaultWidth: 150,
    copyCellContent: dislocation => dislocation.trainIndex,
    renderCellContent: dislocation => dislocation.trainIndex,
  },
  {
    id: 'operationStationCode',
    label: 'Станция операции (Код)',
    defaultWidth: 70,
    copyCellContent: dislocation => dislocation.operationStationCode,
    renderCellContent: dislocation => dislocation.operationStationCode,
  },
  {
    id: 'operationStationName',
    label: 'Станция операции (Наименование)',
    defaultWidth: 300,
    copyCellContent: dislocation => dislocation.operationStationName,
    renderCellContent: dislocation => (
      <Ellipsis
        component={Link}
        params={{ ids: String(dislocation.operationStation) }}
        rel="noopener"
        target="_blank"
        to="stations"
      >
        {dislocation.operationStationName}
      </Ellipsis>
    ),
  },
  {
    id: 'stationDowntime',
    label: 'Простой по станции',
    defaultWidth: 70,
    copyCellContent: dislocation =>
      dislocation.stationDowntime == null
        ? ''
        : formatDecimal(dislocation.stationDowntime),
    renderCellContent: dislocation =>
      dislocation.stationDowntime == null
        ? ''
        : formatDecimal(dislocation.stationDowntime),
  },
  {
    id: 'operationRoadMnemo',
    label: 'Дорога операции (Мнемокод)',
    defaultWidth: 60,
    copyCellContent: dislocation => dislocation.operationRoadMnemo,
    renderCellContent: dislocation => dislocation.operationRoadMnemo,
  },
  {
    id: 'operationDatetime',
    label: 'Дата и время операции',
    defaultWidth: 130,
    sortable: true,
    copyCellContent: dislocation =>
      dayjs(dislocation.operationDatetime).format(DATE_FORMAT_DATETIME),
    renderCellContent: dislocation =>
      dayjs(dislocation.operationDatetime).format(DATE_FORMAT_DATETIME),
  },
  {
    id: 'operationCode',
    label: 'Операция (Код)',
    defaultWidth: 60,
    copyCellContent: dislocation => dislocation.operationCode,
    renderCellContent: dislocation => dislocation.operationCode,
  },
  {
    id: 'operationMnemo',
    label: 'Операция (Мнемокод)',
    defaultWidth: 70,
    copyCellContent: dislocation => dislocation.operationMnemo,
    renderCellContent: dislocation => dislocation.operationMnemo,
  },
  {
    id: 'operationDowntime',
    label: 'Простой по операции',
    defaultWidth: 70,
    sortable: true,
    copyCellContent: dislocation =>
      dislocation.operationDowntime == null
        ? ''
        : formatDecimal(dislocation.operationDowntime),
    renderCellContent: dislocation =>
      dislocation.operationDowntime == null
        ? ''
        : formatDecimal(dislocation.operationDowntime),
  },
  {
    id: 'departureStationCode',
    label: 'Станция отправления (Код)',
    defaultWidth: 70,
    copyCellContent: dislocation => dislocation.departureStationCode,
    renderCellContent: dislocation => dislocation.departureStationCode,
  },
  {
    id: 'departureStationName',
    label: 'Станция отправления (Наименование)',
    defaultWidth: 300,
    copyCellContent: dislocation => dislocation.departureStationName,
    renderCellContent: dislocation => (
      <Ellipsis
        component={Link}
        params={{ ids: String(dislocation.departureStation) }}
        rel="noopener"
        target="_blank"
        to="stations"
      >
        {dislocation.departureStationName}
      </Ellipsis>
    ),
  },
  {
    id: 'departureRoadMnemo',
    label: 'Дорога отправления (Мнемокод)',
    defaultWidth: 60,
    copyCellContent: dislocation => dislocation.departureRoadMnemo,
    renderCellContent: dislocation => dislocation.departureRoadMnemo,
  },
  {
    id: 'departureDatetime',
    label: 'Дата и время отправления',
    defaultWidth: 130,
    sortable: true,
    copyCellContent: dislocation =>
      dislocation.departureDatetime == null
        ? ''
        : dayjs(dislocation.departureDatetime).format(DATE_FORMAT_DATETIME),
    renderCellContent: dislocation =>
      dislocation.departureDatetime == null
        ? null
        : dayjs(dislocation.departureDatetime).format(DATE_FORMAT_DATETIME),
  },
  {
    id: 'arrivalDatetime',
    label: 'Дата и время прибытия',
    defaultWidth: 130,
    sortable: true,
    copyCellContent: dislocation =>
      dislocation.arrivalDatetime
        ? dayjs(dislocation.arrivalDatetime).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: dislocation =>
      dislocation.arrivalDatetime
        ? dayjs(dislocation.arrivalDatetime).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'arrivalDatetimeCalculated',
    label: 'Дата и время прибытия (расчётные)',
    defaultWidth: 130,
    sortable: true,
    copyCellContent: dislocation =>
      dislocation.arrivalDatetimeCalculated
        ? dayjs(dislocation.arrivalDatetimeCalculated).format(
            DATE_FORMAT_DATETIME
          )
        : '',
    renderCellContent: dislocation =>
      dislocation.arrivalDatetimeCalculated
        ? dayjs(dislocation.arrivalDatetimeCalculated).format(
            DATE_FORMAT_DATETIME
          )
        : null,
  },
  {
    id: 'arrivalStationName',
    label: 'Станция назначения (Наименование)',
    defaultWidth: 300,
    copyCellContent: dislocation => dislocation.arrivalStationName,
    renderCellContent: dislocation => (
      <Ellipsis
        component={Link}
        params={{ ids: String(dislocation.arrivalStation) }}
        rel="noopener"
        target="_blank"
        to="stations"
      >
        {dislocation.arrivalStationName}
      </Ellipsis>
    ),
  },
  {
    id: 'arrivalStationCode',
    label: 'Станция назначения (Код)',
    defaultWidth: 70,
    copyCellContent: dislocation => dislocation.arrivalStationCode,
    renderCellContent: dislocation => dislocation.arrivalStationCode,
  },
  {
    id: 'arrivalRoadMnemo',
    label: 'Дорога назначения (Мнемокод)',
    defaultWidth: 60,
    copyCellContent: dislocation => dislocation.arrivalRoadMnemo,
    renderCellContent: dislocation => dislocation.arrivalRoadMnemo,
  },
  {
    id: 'cargoWeight',
    label: 'Вес груза (тн)',
    defaultWidth: 80,
    copyCellContent: dislocation => dislocation.cargoWeight,
    renderCellContent: dislocation => dislocation.cargoWeight,
  },
  {
    id: 'parkTypeName',
    label: 'Тип парка (Наименование)',
    defaultWidth: 150,
    copyCellContent: dislocation => dislocation.parkTypeName,
    renderCellContent: dislocation => (
      <Ellipsis component="span">{dislocation.parkTypeName}</Ellipsis>
    ),
  },
  {
    id: 'cargoCode',
    label: 'Груз (Код)',
    defaultWidth: 70,
    copyCellContent: dislocation => dislocation.cargoCode,
    renderCellContent: dislocation => dislocation.cargoCode,
  },
  {
    id: 'cargoName',
    label: 'Груз (Наименование)',
    defaultWidth: 150,
    copyCellContent: dislocation => dislocation.cargoName,
    renderCellContent: dislocation => (
      <Ellipsis component="span">{dislocation.cargoName}</Ellipsis>
    ),
  },
  {
    id: 'unloadDatetime',
    label: 'Дата и время выгрузки',
    defaultWidth: 130,
    copyCellContent: dislocation =>
      dislocation.unloadDatetime
        ? dayjs(dislocation.unloadDatetime).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: dislocation =>
      dislocation.unloadDatetime
        ? dayjs(dislocation.unloadDatetime).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'wagonCapacity',
    label: 'Грузоподъемность',
    defaultWidth: 60,
    copyCellContent: dislocation => String(dislocation.wagonCapacity),
    renderCellContent: dislocation => dislocation.wagonCapacity,
  },
  {
    id: 'dataFormationDatetime',
    label: 'Дата формирования данных',
    defaultWidth: 90,
    copyCellContent: dislocation =>
      dayjs(dislocation.dataFormationDatetime).format(DATE_FORMAT_DATE),
    renderCellContent: dislocation =>
      dayjs(dislocation.dataFormationDatetime).format(DATE_FORMAT_DATE),
  },
  {
    id: 'ownerCountryName',
    label: 'Страна собственник (Наименование)',
    defaultWidth: 100,
    copyCellContent: dislocation => dislocation.ownerCountryName,
    renderCellContent: dislocation => dislocation.ownerCountryName,
  },
  {
    id: 'deliveryDate',
    label: 'Срок доставки',
    defaultWidth: 90,
    copyCellContent: dislocation =>
      dislocation.deliveryDate
        ? dayjs(dislocation.deliveryDate).format(DATE_FORMAT_DATE)
        : '',
    renderCellContent: dislocation => {
      if (!dislocation.deliveryDate) return null;

      const departure = dayjs(dislocation.departureDatetime);
      const delivery = dayjs(dislocation.deliveryDate);
      const isValidDeliveryDate = delivery.isAfter(departure, 'day');

      return (
        <>
          {dislocation.departureDatetime ? (
            <>
              <span style={{ color: !isValidDeliveryDate ? 'red' : undefined }}>
                {delivery.format(DATE_FORMAT_DATE)}
              </span>
              {!isValidDeliveryDate && (
                <Tooltip
                  content="Срок доставки меньше или равен дате отправления."
                  intent={Intent.WARNING}
                  position={Position.TOP_LEFT}
                >
                  <Button icon="warning-sign" minimal />
                </Tooltip>
              )}
            </>
          ) : (
            <>{delivery.format(DATE_FORMAT_DATE)}</>
          )}
        </>
      );
    },
  },
  {
    id: 'deliveryDateOverdue',
    label: 'Просрочка доставки',
    defaultWidth: 120,
    sortable: sortableByDistanceToArrivalStation,
    copyCellContent: dislocation => String(dislocation.deliveryDateOverdue),
    renderCellContent: dislocation => dislocation.deliveryDateOverdue,
  },
  {
    id: 'wagonModel',
    label: 'Модель',
    defaultWidth: 120,
    copyCellContent: dislocation => dislocation.wagonModel,
    renderCellContent: dislocation => dislocation.wagonModel,
  },
  {
    id: 'parkTypeCode',
    label: 'Тип парка (Код)',
    defaultWidth: 60,
    copyCellContent: dislocation => dislocation.parkTypeCode,
    renderCellContent: dislocation => dislocation.parkTypeCode,
  },
  {
    id: 'ownerOkpoName',
    label: 'Собственник ОКПО (Наименование)',
    defaultWidth: 230,
    copyCellContent: dislocation => dislocation.ownerOkpoName,
    renderCellContent: dislocation => (
      <Ellipsis component="span">{dislocation.ownerOkpoName}</Ellipsis>
    ),
  },
  {
    id: 'distanceToArrivalStation',
    label: 'Расстояние до станции назначения',
    defaultWidth: 70,
    sortable: sortableByDistanceToArrivalStation,
    copyCellContent: dislocation =>
      String(dislocation.distanceToArrivalStation),
    renderCellContent: dislocation => dislocation.distanceToArrivalStation,
  },
  {
    id: 'wagonTypeMnemo',
    label: 'Род вагона (Мнемокод)',
    defaultWidth: 60,
    copyCellContent: dislocation => dislocation.wagonTypeMnemo,
    renderCellContent: dislocation => dislocation.wagonTypeMnemo,
  },
  {
    id: 'wagonTara',
    label: 'Тара',
    defaultWidth: 60,
    copyCellContent: dislocation => String(dislocation.wagonTara),
    renderCellContent: dislocation => dislocation.wagonTara,
  },
  {
    id: 'travelTime',
    label: 'Время в пути',
    defaultWidth: 70,
    copyCellContent: dislocation =>
      dislocation.travelTime == null
        ? ''
        : formatDecimal(dislocation.travelTime),
    renderCellContent: dislocation =>
      dislocation.travelTime == null
        ? ''
        : formatDecimal(dislocation.travelTime),
  },
  {
    id: 'consigneeOkpoName',
    label: 'Грузополучатель ОКПО (Наименование)',
    defaultWidth: 300,
    copyCellContent: dislocation => dislocation.consigneeOkpoName,
    renderCellContent: dislocation => (
      <Ellipsis component="span">{dislocation.consigneeOkpoName}</Ellipsis>
    ),
  },
  {
    id: 'consignerOkpoName',
    label: 'Грузоотправитель ОКПО (Наименование)',
    defaultWidth: 300,
    copyCellContent: dislocation => dislocation.consignerOkpoName,
    renderCellContent: dislocation => (
      <Ellipsis component="span">{dislocation.consignerOkpoName}</Ellipsis>
    ),
  },
  {
    id: 'payerCode',
    label: 'Плательщик (Код)',
    defaultWidth: 120,
    copyCellContent: dislocation => dislocation.payerCode,
    renderCellContent: dislocation => dislocation.payerCode,
  },
  {
    id: 'tariffRub',
    label: 'Тариф (руб)',
    defaultWidth: 90,
    copyCellContent: dislocation => dislocation.tariffRub,
    renderCellContent: dislocation => dislocation.tariffRub,
  },
  {
    id: 'waybillNumber',
    label: 'Накладная (Номер)',
    defaultWidth: 120,
    copyCellContent: dislocation => dislocation.waybillNumber,
    renderCellContent: dislocation => dislocation.waybillNumber,
  },
  {
    id: 'waybillTypeCode',
    label: 'Вид накладной (Код)',
    defaultWidth: 50,
    copyCellContent: dislocation => dislocation.waybillTypeCode,
    renderCellContent: dislocation => dislocation.waybillTypeCode,
  },
  {
    id: 'waybillTypeName',
    label: 'Вид накладной (Наименование)',
    defaultWidth: 70,
    copyCellContent: dislocation => dislocation.waybillTypeName,
    renderCellContent: dislocation => dislocation.waybillTypeName,
  },
  {
    id: 'consignerOkpoCode',
    label: 'Грузоотправитель ОКПО (Код)',
    defaultWidth: 100,
    copyCellContent: dislocation => dislocation.consignerOkpoCode,
    renderCellContent: dislocation => dislocation.consignerOkpoCode,
  },
  {
    id: 'consigneeOkpoCode',
    label: 'Грузополучатель ОКПО (Код)',
    defaultWidth: 100,
    copyCellContent: dislocation => dislocation.consigneeOkpoCode,
    renderCellContent: dislocation => dislocation.consigneeOkpoCode,
  },
  {
    id: 'consignerCode',
    label: 'Грузоотправитель (Код)',
    defaultWidth: 70,
    copyCellContent: dislocation => dislocation.consignerCode,
    renderCellContent: dislocation => dislocation.consignerCode,
  },
  {
    id: 'consigneeCode',
    label: 'Грузополучатель (Код)',
    defaultWidth: 70,
    copyCellContent: dislocation => dislocation.consigneeCode,
    renderCellContent: dislocation => dislocation.consigneeCode,
  },
  {
    id: 'repairTypeName',
    label: 'Вид планируемого ремонта (наименование)',
    defaultWidth: 200,
    copyCellContent: dislocation => dislocation.repairTypeName,
    renderCellContent: dislocation => dislocation.repairTypeName,
  },
  {
    id: 'repairDate',
    label: 'Дата планируемого ремонта',
    defaultWidth: 90,
    sortable: true,
    copyCellContent: dislocation =>
      dayjs(dislocation.repairDate).format(DATE_FORMAT_DATE),
    renderCellContent: dislocation =>
      dayjs(dislocation.repairDate).format(DATE_FORMAT_DATE),
  },
  {
    id: 'wagonMileage',
    label: 'Пробег остаточный (км)',
    defaultWidth: 100,
    sortable: true,
    copyCellContent: dislocation => String(dislocation.wagonMileage),
    renderCellContent: dislocation => dislocation.wagonMileage,
  },
  {
    id: 'ownerOkpoCode',
    label: 'Собственник ОКПО (Код)',
    defaultWidth: 100,
    copyCellContent: dislocation => dislocation.ownerOkpoCode,
    renderCellContent: dislocation => dislocation.ownerOkpoCode,
  },
  {
    id: 'renterOkpoCode',
    label: 'Арендатор ОКПО (Код)',
    defaultWidth: 100,
    copyCellContent: dislocation => dislocation.renterOkpoCode,
    renderCellContent: dislocation => dislocation.renterOkpoCode,
  },
  {
    id: 'renterOkpoName',
    label: 'Арендатор ОКПО (наименование)',
    defaultWidth: 300,
    copyCellContent: dislocation => dislocation.renterOkpoName,
    renderCellContent: dislocation => (
      <Ellipsis component="span">{dislocation.renterOkpoName}</Ellipsis>
    ),
  },
  {
    id: 'operationName',
    label: 'Операция (Наименование)',
    defaultWidth: 300,
    copyCellContent: dislocation => dislocation.operationName,
    renderCellContent: dislocation => (
      <Ellipsis component="span">{dislocation.operationName}</Ellipsis>
    ),
  },
  {
    id: 'defect',
    label: 'Неисправность',
    defaultWidth: 300,
    copyCellContent: dislocation => dislocation.defect,
    renderCellContent: dislocation => (
      <Ellipsis component="span">{dislocation.defect}</Ellipsis>
    ),
  },
  {
    id: 'nrp',
    label: 'НРП',
    defaultWidth: 60,
    copyCellContent: dislocation => dislocation.nrp,
    renderCellContent: dislocation => dislocation.nrp,
  },
  {
    id: 'nrpSign',
    label: 'НРП (Признак)',
    defaultWidth: 60,
    copyCellContent: dislocation => dislocation.nrpSign,
    renderCellContent: dislocation => dislocation.nrpSign,
  },
];

import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { DateInputInFormGroup } from '_core/inputs/dateInput';
import { Select } from '_core/inputs/select';
import { formatMoney } from '_core/money/formatMoney';
import { Link } from '_core/router5/link';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import {
  Button,
  FormGroup,
  H4,
  InputGroup,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroup } from 'partners/autocomplete';
import * as React from 'react';
import { useRef } from 'react';
import { rentProtocolToOption } from 'rentProtocols/utils';
import { IRentProtocolPurchase } from 'rentProtocolsPurchase/api';
import { RentWagonPurchase } from 'rentWagonPurchase/api';

import {
  IRentWagonPurchaseExpensesFieldItem,
  RentWagonPurchaseExpensesField,
} from './expensesField';
import {
  IRentWagonPurchaseIncomesFieldItem,
  RentWagonPurchaseIncomesField,
} from './incomesField';
import {
  IRentWagonPurchaseFormPause,
  RentWagonPurchasePausesField,
} from './pausesField';

type SubmitTrigger = 'save' | 'saveAndContinue';

export interface IRentWagonPurchaseFormValues {
  expenses: IRentWagonPurchaseExpensesFieldItem[];
  incomes: IRentWagonPurchaseIncomesFieldItem[];
  pauses: IRentWagonPurchaseFormPause[];
  trackRepair: boolean;
}

interface IProps {
  initialValues: IRentWagonPurchaseFormValues;
  navigationLock: IFormNavigationLock;
  rentProtocols: IRentProtocolPurchase[];
  rentWagon: RentWagonPurchase;
  onSave: (
    values: IRentWagonPurchaseFormValues
  ) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IRentWagonPurchaseFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function RentWagonPurchaseForm({
  initialValues,
  navigationLock,
  rentProtocols,
  rentWagon,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <FinalForm
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      subscription={{
        dirty: true,
        error: true,
        submitError: true,
        submitting: true,
      }}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={4}>
                  <H4>Основная информация</H4>

                  <CheckboxForFinalForm
                    id="trackRepair"
                    label="Следить за ремонтом"
                    name="trackRepair"
                  />

                  <FormGroup label="Вагон" labelFor="wagon">
                    <InputGroup
                      id="wagon"
                      name="wagon"
                      readOnly
                      value={rentWagon.wagon}
                    />
                  </FormGroup>

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroup
                        id="startDate"
                        label="Дата начала"
                        name="startDate"
                        readOnly
                        value={rentWagon.startDate}
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroup
                        id="endDate"
                        label="Дата окончания"
                        name="endDate"
                        readOnly
                        value={rentWagon.endDate}
                      />
                    </Col>
                  </Grid>

                  <PartnersAutocompleteInFormGroup
                    id="supplier"
                    label="Поставщик"
                    name="supplier"
                    readOnly
                    value={rentWagon.supplier}
                  />

                  <FormGroup label="Протокол">
                    <Select
                      disabled
                      fill
                      getLink={value => {
                        if (!value) {
                          return undefined;
                        }

                        const protocolId = Number(value);
                        const protocol = rentProtocols.find(
                          p => p.id === protocolId
                        );

                        return protocol
                          ? {
                              to: 'contracts.purchase.view.rentProtocols.view',
                              params: {
                                id: String(protocol.contract),
                                protocolId: value,
                              },
                            }
                          : undefined;
                      }}
                      id="protocol"
                      name="protocol"
                      options={rentProtocols.map(rentProtocolToOption)}
                      value={String(rentWagon.protocol)}
                      withEmptyOption
                    />
                  </FormGroup>

                  <H4>Исходящие аренды</H4>

                  {rentWagon.sellRents.length === 0 ? (
                    <div>
                      <NonIdealState
                        description="Нет исходящих аренд"
                        icon="list"
                      />
                    </div>
                  ) : (
                    <ul>
                      {rentWagon.sellRents.map(sellRent => (
                        <li key={sellRent.id}>
                          <Link
                            params={{ id: String(sellRent.id) }}
                            rel="noopener"
                            target="_blank"
                            to="rentWagons.sell.edit"
                          >
                            {sellRent.shortName}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </Col>

                <Col span={8}>
                  <RentWagonPurchasePausesField name="pauses" />
                </Col>
              </Grid>
            </Row>

            <Row>
              <RentWagonPurchaseIncomesField
                change={form.change}
                incomesCost={rentWagon.incomesCost}
                incomesCostTotal={rentWagon.incomesCostTotal}
                incomesCostVatValue={rentWagon.incomesCostVatValue}
                name="incomes"
              />
            </Row>

            <Row>
              <RentWagonPurchaseExpensesField
                change={form.change}
                expensesCost={rentWagon.expensesCost}
                expensesCostTotal={rentWagon.expensesCostTotal}
                expensesCostVatValue={rentWagon.expensesCostVatValue}
                name="expenses"
              />
            </Row>

            <Row>
              <H4>
                Прибыль: {formatMoney(rentWagon.profit, { withCurrency: true })}
              </H4>
            </Row>

            <Row>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitTriggerRef.current = 'save';
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import { fetchConsigner, updateConsigner } from 'consigners/api';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo, useRef } from 'react';
import { useRouteNode } from 'react-router5';

import { ConsignersForm, IConsignersFormValues, SubmitTrigger } from './form';

export default function ConsignersEditRoute() {
  const { route, router } = useRouteNode('consigners.edit');
  const api = useApiClient();
  const navigationLock = useFormNavigationLock('consigners.edit');
  const submitTriggerRef = useRef<SubmitTrigger>('save');

  const consignerId = Number(route.params.id);
  const { data, error, isFetching, updateData } = useAsyncData(
    [consignerId, api],
    () => fetchConsigner(api, consignerId)
  );

  const initialValues = useMemo(
    (): IConsignersFormValues | null =>
      data && {
        email: data.email,
        legalAddress: data.legalAddress,
        name: data.name,
        note: data.note,
        okpo: data.okpo,
        phone: data.phone,
      },
    [data]
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data || !initialValues) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const title = data.name;

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Грузоотправители"
              params={listParams}
              to="consigners"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <ConsignersForm
            initialValues={initialValues}
            navigationLock={navigationLock}
            setSubmitTrigger={submitTrigger => {
              submitTriggerRef.current = submitTrigger;
            }}
            onSubmit={async values => {
              try {
                const updatedConsigner = await updateConsigner(
                  api,
                  consignerId,
                  { ...data, ...values }
                );

                navigationLock.unlock();

                if (submitTriggerRef.current === 'save') {
                  router.navigate('consigners', listParams);
                } else {
                  updateData(() => updatedConsigner);
                }

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось изменить грузоотправителя: Непредвиденная ошибка'
                );
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

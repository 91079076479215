import { FilterPopoverUi, useFilterPopover } from '_core/filters/filterPopover';
import * as React from 'react';

import { Radio, RadioGroup } from '../_core/inputs/radio';
import * as css from './hasEtranNoteFilter.module.css';

interface Props {
  initialValue: string | undefined;
  label: string;
  onApply: (newValue: string | undefined) => void;
}

export function HasEtranNoteFilter({ initialValue, label, onApply }: Props) {
  const filterPopover = useFilterPopover<string | undefined, string | null>(
    initialValue,
    onApply,
    {
      emptyValue: undefined,
      isEmpty: value => !value,
      serialize: value => value || undefined,
      deserialize: value => value || '',
    }
  );

  return (
    <FilterPopoverUi api={filterPopover.ui} label={label}>
      <div className={css.root}>
        {/* <Select */}
        {/*  fill */}
        {/*  options={[ */}
        {/*    { label: '<Выберите значение>', value: '' }, */}
        {/*    { label: 'Есть заметка', value: 'True' }, */}
        {/*    { label: 'Нет заметки', value: 'False' }, */}
        {/*  ]} */}
        {/*  value={filterPopover.value} */}
        {/*  onChange={filterPopover.change} */}
        {/* /> */}

        <RadioGroup
          onChange={filterPopover.change}
          selectedValue={filterPopover.value || ''}
        >
          <Radio label="Не выбрано" value="" />
          <Radio label="Есть заметка" value="True" />
          <Radio label="Нет заметки" value="False" />
        </RadioGroup>
      </div>
    </FilterPopoverUi>
  );
}

// extracted by mini-css-extract-plugin
export var root = "_6836172ba59089a70743ea6e33a1c986-css";
export var favoriteDocsSwitch = "d5dc339c514abf753e9e220f261374b5-css";
export var userNameIcon = "_580682ae3f947976a57f0ba515dd690a-css";
export var menuAndContent = "_1278c6d9fc532dd0ed66217112adb23c-css";
export var menuWidth = "_9d3c1dffa3dd37f98631a37929d4a735-css";
export var menuPlaceholder = "c6281d7b09f4e207398dd04631b4c0e7-css _9d3c1dffa3dd37f98631a37929d4a735-css";
export var menuWrapper = "_24965d5547e63d66304a182aae50ee4e-css _9d3c1dffa3dd37f98631a37929d4a735-css";
export var menuLink_collapsible = "_8e877513410938e5a54316e9c56eda2f-css";
export var layoutMenuItemBadgeRoot = "_865bc0f0c7e44642b2e5a7a3e0f2acc5-css";
export var layoutContent = "_4dc0786b44fb3627d97eaf8495d02a18-css";
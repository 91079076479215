import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import { DocumentStatus } from 'documents/types';
import { IPartnerInline } from 'partners/api';

export enum DocumentsStatusesDocumentType {
  ContractAgreementPurchase = 'contract_agreement_purchase',
  ContractAgreementSell = 'contract_agreement_sell',
  ContractPurchase = 'contract_purchase',
  ContractSell = 'contract_sell',
  ExpeditionProtocolSell = 'expeditions_protocol_sell',
  RentProtocolPurchase = 'rent_protocol_purchase',
  RentProtocolSell = 'rent_protocol_sell',
  TechrunProtocolPurchase = 'techruns_protocol_purchase',
  TechrunProtocolSell = 'techruns_protocol_sell',
}

export function isDocumentsStatusesDocumentType(
  input: string
): input is DocumentsStatusesDocumentType {
  return Object.values(DocumentsStatusesDocumentType).includes(
    input as DocumentsStatusesDocumentType
  );
}

interface IDocumentsStatusesItemSerialized {
  author: number | null;
  contractAgreementPurchase: number | null;
  contractAgreementSell: number | null;
  contractPurchase: number | null;
  contractSell: number | null;
  created: string;
  documentDate: string;
  documentName: string;
  documentType: DocumentsStatusesDocumentType;
  documentTypeRus: string;
  expeditionsProtocolSell: number | null;
  id: number;
  partner: IPartnerInline;
  rentProtocolPurchase: number | null;
  rentProtocolSell: number | null;
  status: DocumentStatus;
  techrunsProtocolPurchase: number | null;
  techrunsProtocolSell: number | null;
}

export interface IDocumentsStatusesItem
  extends Omit<IDocumentsStatusesItemSerialized, 'created' | 'documentDate'> {
  created: Date;
  documentDate: Date;
}

function deserializeDocumentsStatusesItem({
  created,
  documentDate,
  ...otherProps
}: IDocumentsStatusesItemSerialized): IDocumentsStatusesItem {
  return {
    ...otherProps,
    created: parseDate(created),
    documentDate: parseDate(documentDate),
  };
}

export enum DocumentsStatusesSortingField {
  Created = 'created',
  DocumentDate = 'documentDate',
  DocumentTypeRus = 'documentTypeRus',
  Partner = 'partner',
  Status = 'status',
}

interface IFetchDocumentsStatusesParams {
  author?: number;
  documentTypes?: DocumentsStatusesDocumentType[];
  page?: number;
  pageSize?: number;
  partner?: number;
  search?: string;
  sorting?: Array<ISorting<DocumentsStatusesSortingField>>;
  statuses?: DocumentStatus[];
}

export async function fetchDocumentsStatuses(
  api: ApiClient,
  {
    author,
    documentTypes,
    page,
    pageSize,
    partner,
    search,
    sorting,
    statuses,
  }: IFetchDocumentsStatusesParams = {}
): Promise<ListResponse<IDocumentsStatusesItem>> {
  const response = await api.get<
    ListResponse<IDocumentsStatusesItemSerialized>
  >('/documents_statuses', {
    author,
    documentTypes:
      documentTypes && documentTypes.length !== 0
        ? documentTypes.join(',')
        : undefined,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    pageSize,
    partner,
    search,
    statuses:
      statuses && statuses.length !== 0 ? statuses.join(',') : undefined,
  });

  return {
    ...response,
    results: response.results.map(deserializeDocumentsStatusesItem),
  };
}

export enum DocumentsStatusesTreeSortingField {
  Name = 'name',
}

interface IFetchDocumentsStatusesTreeParams {
  author?: number;
  documentTypes?: DocumentsStatusesDocumentType[];
  partner?: number;
  search?: string;
  sorting?: Array<ISorting<DocumentsStatusesTreeSortingField>>;
  statuses?: DocumentStatus[];
}

interface IDocumentsStatusesTreeDocType {
  docsCount: number;
  documentType: DocumentsStatusesDocumentType;
  documentTypeRus: string;
}

interface IDocumentsStatusesTreePartner {
  docTypes: IDocumentsStatusesTreeDocType[];
  partner: number;
  partnerName: string;
}

interface IFetchDocumentsStatusesTreeResponse {
  results: IDocumentsStatusesTreePartner[];
}

export function fetchDocumentsStatusesTree(
  api: ApiClient,
  {
    author,
    documentTypes,
    partner,
    search,
    sorting,
    statuses,
  }: IFetchDocumentsStatusesTreeParams = {}
) {
  return api.get<IFetchDocumentsStatusesTreeResponse>(
    '/documents_statuses/tree',
    {
      author,
      documentTypes:
        documentTypes && documentTypes.length !== 0
          ? documentTypes.join(',')
          : undefined,
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      partner,
      search,
      statuses:
        statuses && statuses.length !== 0 ? statuses.join(',') : undefined,
    }
  );
}

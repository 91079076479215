import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMaskedForFinalForm } from '_core/inputs/inputMasked';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, FormGroup, H4, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { createNumberMask } from 'text-mask-addons';

export interface IConsignersFormValues {
  email: string;
  legalAddress: string;
  name: string;
  note: string;
  okpo: string;
  phone: string;
}

const okpoMask = createNumberMask({
  allowLeadingZeroes: true,
  includeThousandsSeparator: false,
  prefix: '',
});

export type SubmitTrigger = 'save' | 'saveAndContinue';

interface IProps {
  initialValues: IConsignersFormValues;
  navigationLock: IFormNavigationLock;
  setSubmitTrigger: (submitTrigger: SubmitTrigger) => void;
  onSubmit: (values: IConsignersFormValues) => Promise<SubmissionErrors | void>;
}

export function ConsignersForm({
  initialValues,
  navigationLock,
  setSubmitTrigger,
  onSubmit,
}: IProps) {
  return (
    <FinalForm<IConsignersFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty, form, handleSubmit, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <VGrid>
            <Row>
              <Grid>
                <Col span={3}>
                  <H4>Основная информация</H4>

                  <FormGroupForFinalForm
                    label="Наименование грузоотправителя"
                    labelFor="name"
                    name="name"
                  >
                    <InputGroupForFinalForm
                      autoFocus
                      id="name"
                      name="name"
                      required
                    />
                  </FormGroupForFinalForm>
                </Col>

                <Col span={3}>
                  <H4>Адреса</H4>

                  <FormGroupForFinalForm
                    label="Номер телефона"
                    labelFor="phone"
                    name="phone"
                  >
                    <InputGroupForFinalForm id="phone" name="phone" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Email"
                    labelFor="email"
                    name="email"
                  >
                    <InputGroupForFinalForm
                      id="email"
                      name="email"
                      type="email"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Юридический адрес"
                    labelFor="legalAddress"
                    name="legalAddress"
                  >
                    <InputGroupForFinalForm
                      id="legalAddress"
                      name="legalAddress"
                    />
                  </FormGroupForFinalForm>

                  <H4>Реквизиты</H4>

                  <FormGroup label="ОКПО" labelInfo="*">
                    <InputMaskedForFinalForm
                      id="okpo"
                      mask={okpoMask}
                      name="okpo"
                    />
                  </FormGroup>
                </Col>

                <Col span={3}>
                  <H4>Дополнительно</H4>

                  <FormGroupForFinalForm
                    label="Примечание"
                    labelFor="note"
                    name="note"
                  >
                    <InputMultipleForFinalForm id="note" name="note" rows={5} />
                  </FormGroupForFinalForm>
                </Col>
              </Grid>
            </Row>

            <Row>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    setSubmitTrigger('saveAndContinue');
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    setSubmitTrigger('save');
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { TableFieldErrors } from '_core/forms/tableFieldErrors';
import { without } from '_core/fp/without';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { InputMaskedForFinalForm } from '_core/inputs/inputMasked';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { plural } from '_core/strings/utils';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { Button, InputGroup, Intent } from '@blueprintjs/core';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { createWagonMatcher } from 'wagons/utils';

import { BatchWagonsPopover } from './batchWagonsPopover';

export interface IExpeditionRequestFormWagonsFieldItem {
  isFinished: boolean;
  wagon: string;
}

interface IProps {
  editingLocked?: boolean;
  name: string;
}

interface IWagonWithIndex {
  index: number;
  wagon: IExpeditionRequestFormWagonsFieldItem;
}

export function WagonsField({ editingLocked, name }: IProps) {
  const { fields, meta } =
    useFieldArray<IExpeditionRequestFormWagonsFieldItem>(name);

  const toaster = useToaster();
  const [search, setSearch] = React.useState('');
  const [selectedIndices, setSelectedIndices] = React.useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isDisabled = (meta as any).submitting as boolean;

  const wagonMatcher = React.useMemo(
    () => createWagonMatcher(search),
    [search]
  );

  const wagonsWithIndices = React.useMemo(
    () =>
      fields
        .map(
          (_fieldName, index): IWagonWithIndex => ({
            index,
            wagon: fields.value[index],
          })
        )
        .filter(({ wagon }) => !wagon.wagon || wagonMatcher(wagon.wagon)),
    [fields, wagonMatcher]
  );

  const indexMap = React.useMemo(
    () => wagonsWithIndices.map(({ index }) => index),
    [wagonsWithIndices]
  );

  const columns = React.useMemo(
    (): Array<ListTableColumn<IWagonWithIndex>> => [
      {
        id: 'isFinished',
        label: 'Перевозка завершена',
        defaultWidth: 36,
        renderCellContent: (_wagon, rowIndex) => (
          <CheckboxForFinalForm
            disabled={isDisabled}
            editingLocked={editingLocked}
            noBottomMargin
            name={`${fields.name}[${indexMap[rowIndex]}].isFinished`}
          />
        ),
      },
      {
        id: 'wagon',
        label: 'Вагон',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (_wagon, rowIndex) => (
          <InputMaskedForFinalForm
            disabled={isDisabled}
            editingLocked={editingLocked}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            name={`${fields.name}[${indexMap[rowIndex]}].wagon`}
            placeholder="Номер вагона..."
          />
        ),
      },
    ],
    [editingLocked, fields.name, indexMap, isDisabled]
  );

  const getItemId = React.useCallback(
    ({ index }: IWagonWithIndex) => String(index),
    []
  );

  return (
    <VGrid>
      <Row>
        <Grid>
          <Col span={3}>
            <InputGroup
              leftIcon="search"
              placeholder="Начните вводить номер вагона..."
              type="search"
              value={search}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
              }}
            />
          </Col>
        </Grid>
      </Row>

      <Row>
        <Toolbar>
          <Button
            disabled={isDisabled || editingLocked}
            text="Добавить"
            onClick={() => {
              fields.push({ isFinished: false, wagon: '' });
            }}
          />

          <Button
            disabled={
              isDisabled || editingLocked || selectedIndices.length === 0
            }
            text="Удалить выбранные"
            onClick={() => {
              selectedIndices
                .map(Number)
                .sort((a, b) => b - a)
                .forEach(rowIndex => {
                  fields.remove(rowIndex);
                });

              setSelectedIndices([]);
            }}
          />

          <BatchWagonsPopover
            disabled={isDisabled || editingLocked}
            onBatchAdd={numbersToAdd => {
              const existingNumbers = fields.value.map(wagon => wagon.wagon);
              const newNumbers = without(existingNumbers, numbersToAdd);

              newNumbers.forEach(wagon => {
                fields.push({ isFinished: false, wagon });
              });

              const addedWagonCount = newNumbers.length;
              const totalWagonCount = numbersToAdd.length;

              toaster.show({
                intent: Intent.SUCCESS,

                message: plural(addedWagonCount, [
                  () =>
                    `Добавлен ${addedWagonCount} вагон из ${totalWagonCount}`,

                  () =>
                    `Добавлено ${addedWagonCount} вагона из ${totalWagonCount}`,

                  () =>
                    `Добавлено ${addedWagonCount} вагонов из ${totalWagonCount}`,
                ]),
              });
            }}
          />
        </Toolbar>
      </Row>

      <Row>
        <TableFieldErrors
          fieldLabels={[['wagon', 'Вагон']]}
          rowsErrors={meta.submitError}
        />

        <ListTable
          columns={columns}
          getItemId={getItemId}
          items={wagonsWithIndices}
          maxHeight={500}
          selectedItems={selectedIndices}
          selectionIsDisabled={isDisabled || editingLocked}
          onSelectedItemsChange={setSelectedIndices}
        />
      </Row>
    </VGrid>
  );
}

import * as React from 'react';

import * as css from './documentListLayout.module.css';

interface IProps {
  form: React.ReactNode;
  list: React.ReactNode;
}

export function DocumentListLayout({ form, list }: IProps) {
  return (
    <div className={css.root}>
      <div className={css.list}>{list}</div>
      <div className={css.form}>{form}</div>
    </div>
  );
}

import { capitalize } from '_core/fp/capitalize';
import dayjs from 'dayjs';

import { DATE_FORMAT_API_DATE, DATE_FORMAT_DATE } from './formats';

export function createDateRange(unit: dayjs.OpUnitType, from: Date, to: Date) {
  const result = [];
  const end = dayjs(to).startOf(unit);

  for (
    let current = dayjs(from).startOf(unit);
    current.isBefore(end);
    current = current.add(1, unit)
  ) {
    result.push(current.toDate());
  }

  return result;
}

export function formatFromTo(fromDate: Date, toDate: Date) {
  const from = dayjs(fromDate);
  const to = dayjs(toDate);

  return from.isSame(to, 'date')
    ? from.format(DATE_FORMAT_DATE)
    : `${from.format(DATE_FORMAT_DATE)}-${to.format(DATE_FORMAT_DATE)}`;
}

export function formatMonth(date: Date) {
  return capitalize(dayjs(date).format('MMMM YYYY'));
}

export function isValidDate(date: Date) {
  return dayjs(date).isValid();
}

export function lastDayOfMonth(date: Date) {
  return dayjs(date).endOf('month').startOf('day').toDate();
}

export function monthDateToSelectOption(date: Date) {
  return {
    label: formatMonth(date),
    value: dayjs(date).format(DATE_FORMAT_API_DATE),
  };
}

export function parseDate(input: string, format?: string, locale?: string) {
  return dayjs(input, format, locale).toDate();
}

export function trimTime(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

import { UnauthorizedError } from '_core/api/client';
import { ErpState, ErpThunkAction } from '_erp/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMyDocumentsNewTransportationsResponse } from 'myDocuments/api';

interface State {
  newExpeditionTransportationsCount: number | undefined;
  newTechrunTransportationsCount: number | undefined;
}

const initialState: State = {
  newExpeditionTransportationsCount: undefined,
  newTechrunTransportationsCount: undefined,
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    updateStats(state, action: PayloadAction<State>) {
      state.newExpeditionTransportationsCount =
        action.payload.newExpeditionTransportationsCount;
      state.newTechrunTransportationsCount =
        action.payload.newTechrunTransportationsCount;
    },
  },
});

export const statsReducer = statsSlice.reducer;

export function fetchMenuCounters(): ErpThunkAction<Promise<void>> {
  return async (dispatch, _getState, { api }) => {
    try {
      const response = await api.get<IMyDocumentsNewTransportationsResponse>(
        '/my_documents/new_transportations'
      );

      dispatch(
        statsSlice.actions.updateStats({
          newExpeditionTransportationsCount:
            response.expeditionsTransportations,
          newTechrunTransportationsCount: response.techrunsTransportations,
        })
      );
    } catch (err) {
      if (err instanceof UnauthorizedError) {
        return;
      }

      throw err;
    }
  };
}

export function selectNewExpeditionTransportationsCount(state: ErpState) {
  return state.stats.newExpeditionTransportationsCount;
}

export function selectNewTechrunTransportationsCount(state: ErpState) {
  return state.stats.newTechrunTransportationsCount;
}

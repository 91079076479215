import { useApiClient } from '_core/api/context';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { createContractPurchase } from 'contractsPurchase/api';
import { DocumentStatus } from 'documents/types';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { VatRate } from 'vatRates/vatRates';

import { ContractForm } from './form';
import { ContractsPurchaseFormRouteWrapper } from './purchaseFormRouteWrapper';

export default function ContractsPurchaseCreateRoute() {
  const { route, router } = useRouteNode('contracts.purchase.create');
  const navigationLock = useFormNavigationLock('contracts.purchase.create');
  const api = useApiClient();

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  return (
    <ContractsPurchaseFormRouteWrapper
      listParams={listParams}
      title="Новый договор"
    >
      <ContractForm
        initialValues={{
          additionalDowntimeRate: '0.00',
          daysLoad: '0',
          daysUnload: '0',
          downtimeLimit: '0',
          downtimeRate: '0.00',
          downtimeVatRate: VatRate.None,
          files: [],
          hasRent: false,
          hasTechrun: false,
          number: '',
          owners: [],
          partner: null,
          partnerContact: null,
          partnerSign: null,
          signDate: new Date(),
          signPlace: 'Санкт-Петербург',
          status: DocumentStatus.Created,
          template: null,
        }}
        navigationLock={navigationLock}
        numberIsRequired
        onSubmit={async ({ owners, ...otherValues }) => {
          try {
            const createdContract = await createContractPurchase(api, {
              ...otherValues,
              owners: owners.map(owner => owner.owner),
            });

            navigationLock.unlock();

            router.navigate('contracts.purchase.view', {
              id: createdContract.id,
              listParams: route.params.listParams,
            });

            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось создать договор: Непредвиденная ошибка'
            );
          }
        }}
      />
    </ContractsPurchaseFormRouteWrapper>
  );
}

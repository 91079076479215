import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { formatMoney } from '_core/money/formatMoney';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Ellipsis } from '_core/strings/ellipsis';
import dayjs from 'dayjs';
import {
  getOperationTypeLabel,
  getPaymentTypeLabel,
  IPayment,
} from 'payments/types';
import * as React from 'react';
import { useCallback, useMemo } from 'react';

interface IProps {
  payments: IPayment[];
  selectedPayments: string[];
  onSelectedPaymentsChange: (newSelectedPayments: string[]) => void;
}

export function PaymentsImportTable({
  payments,
  selectedPayments,
  onSelectedPaymentsChange,
}: IProps) {
  const columns = useMemo(
    (): Array<ListTableColumn<IPayment>> => [
      {
        id: 'number',
        label: 'Номер',
        defaultWidth: 70,
        copyCellContent: payment => String(payment.number),
        renderCellContent: payment => (
          <Ellipsis component="span">{String(payment.number)}</Ellipsis>
        ),
      },
      {
        id: 'type',
        label: 'Тип документа',
        defaultWidth: 190,
        copyCellContent: payment => getPaymentTypeLabel(payment.type),
        renderCellContent: payment => (
          <Ellipsis component="span">
            {getPaymentTypeLabel(payment.type)}
          </Ellipsis>
        ),
      },
      {
        id: 'date',
        label: 'Дата',
        defaultWidth: 80,
        copyCellContent: payment =>
          dayjs(payment.date).format(DATE_FORMAT_DATE),
        renderCellContent: payment => (
          <Ellipsis component="span">
            {dayjs(payment.date).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'amount',
        label: 'Сумма',
        defaultWidth: 140,
        copyCellContent: payment => formatMoney(payment.amount),
        renderCellContent: payment => (
          <Ellipsis component="span">{formatMoney(payment.amount)}</Ellipsis>
        ),
      },
      {
        id: 'datePaid',
        label: 'Дата cписания',
        defaultWidth: 80,
        copyCellContent: payment =>
          dayjs(payment.datePaid).format(DATE_FORMAT_DATE),
        renderCellContent: payment => (
          <Ellipsis component="span">
            {dayjs(payment.datePaid).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'payerAccount',
        label: 'Счет плательщика',
        defaultWidth: 200,
        copyCellContent: payment => payment.payerAccount,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.payerAccount}</Ellipsis>
        ),
      },
      {
        id: 'payerName',
        label: 'Плательщик наименование',
        defaultWidth: 220,
        copyCellContent: payment => payment.payerName,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.payerName}</Ellipsis>
        ),
      },
      {
        id: 'payerInn',
        label: 'Плательщик ИНН',
        defaultWidth: 110,
        copyCellContent: payment => payment.payerInn,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.payerInn}</Ellipsis>
        ),
      },
      {
        id: 'payerKpp',
        label: 'Плательщик КПП',
        defaultWidth: 110,
        copyCellContent: payment => payment.payerKpp,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.payerKpp}</Ellipsis>
        ),
      },
      {
        id: 'payerCheckingAccount',
        label: 'Плательщик расчетный счет',
        defaultWidth: 200,
        copyCellContent: payment => payment.payerCheckingAccount,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.payerCheckingAccount}</Ellipsis>
        ),
      },
      {
        id: 'payerBank',
        label: 'Плательщик банк',
        defaultWidth: 350,
        copyCellContent: payment => payment.payerBank,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.payerBank}</Ellipsis>
        ),
      },
      {
        id: 'payerBik',
        label: 'Плательщик БИК',
        defaultWidth: 100,
        copyCellContent: payment => payment.payerBik,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.payerBik}</Ellipsis>
        ),
      },
      {
        id: 'payerCorrespondentAccount',
        label: 'Плательщик коррсчет',
        defaultWidth: 200,
        copyCellContent: payment => payment.payerCorrespondentAccount,
        renderCellContent: payment => (
          <Ellipsis component="span">
            {payment.payerCorrespondentAccount}
          </Ellipsis>
        ),
      },
      {
        id: 'consigneeAccount',
        label: 'Счет плательщика',
        defaultWidth: 200,
        copyCellContent: payment => payment.consigneeAccount,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.consigneeAccount}</Ellipsis>
        ),
      },
      {
        id: 'consigneeName',
        label: 'Получатель наименование',
        defaultWidth: 220,
        copyCellContent: payment => payment.consigneeName,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.consigneeName}</Ellipsis>
        ),
      },
      {
        id: 'consigneeInn',
        label: 'Получатель ИНН',
        defaultWidth: 110,
        copyCellContent: payment => payment.consigneeInn,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.consigneeInn}</Ellipsis>
        ),
      },
      {
        id: 'consigneeKpp',
        label: 'Получатель КПП',
        defaultWidth: 110,
        copyCellContent: payment => payment.consigneeKpp,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.consigneeKpp}</Ellipsis>
        ),
      },
      {
        id: 'consigneeCheckingAccount',
        label: 'Получатель расчетный счет',
        defaultWidth: 200,
        copyCellContent: payment => payment.consigneeCheckingAccount,
        renderCellContent: payment => (
          <Ellipsis component="span">
            {payment.consigneeCheckingAccount}
          </Ellipsis>
        ),
      },
      {
        id: 'consigneeBank',
        label: 'Получатель банк',
        defaultWidth: 350,
        copyCellContent: payment => payment.consigneeBank,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.consigneeBank}</Ellipsis>
        ),
      },
      {
        id: 'consigneeBik',
        label: 'Получатель БИК',
        defaultWidth: 100,
        copyCellContent: payment => payment.consigneeBik,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.consigneeBik}</Ellipsis>
        ),
      },
      {
        id: 'consigneeCorrespondentAccount',
        label: 'Получатель коррсчет',
        defaultWidth: 200,
        copyCellContent: payment => payment.consigneeCorrespondentAccount,
        renderCellContent: payment => (
          <Ellipsis component="span">
            {payment.consigneeCorrespondentAccount}
          </Ellipsis>
        ),
      },
      {
        id: 'purpose',
        label: 'Назначение платежа',
        defaultWidth: 500,
        copyCellContent: payment => payment.purpose,
        renderCellContent: payment => (
          <Ellipsis component="span">{payment.purpose}</Ellipsis>
        ),
      },
      {
        id: 'operationType',
        label: 'Вид операции',
        defaultWidth: 100,
        copyCellContent: payment =>
          getOperationTypeLabel(payment.operationType),
        renderCellContent: payment => (
          <Ellipsis component="span">
            {getOperationTypeLabel(payment.operationType)}
          </Ellipsis>
        ),
      },
    ],
    []
  );

  const getItemId = useCallback((payment: IPayment) => String(payment.id), []);

  const getRowColor = useCallback(
    (payment: IPayment) => (payment.hasDuplicate ? '#ff6e4a' : undefined),
    []
  );

  return (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      getRowColor={getRowColor}
      items={payments}
      selectedItems={selectedPayments}
      onSelectedItemsChange={onSelectedPaymentsChange}
    />
  );
}

import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import {
  DateInputInFormGroup,
  DateInputInFormGroupForFinalForm,
} from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroupForFinalForm } from '_core/inputs/inputMoney';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { SelectForFinalForm } from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, H4, Intent } from '@blueprintjs/core';
import { UsersAutocompleteInFormGroup } from 'accounts/autocomplete';
import { IContractsPurchaseAgreement } from 'contractsPurchaseAgreements/api';
import { IContractsSellAgreement } from 'contractsSellAgreements/api';
import { DocumentStatusSelectForFinalForm } from 'documents/statusSelect';
import { DocumentStatus } from 'documents/types';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { VatRate, vatRateOptions } from 'vatRates/vatRates';

export interface IAgreementFormValues {
  additionalDowntimeRate: string;
  contract: number;
  date: Date | null;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  note: string;
  number: string;
  status: DocumentStatus;
}

interface IProps {
  agreement: IContractsPurchaseAgreement | IContractsSellAgreement | undefined;
  editingLocked: boolean;
  initialValues: IAgreementFormValues;
  navigationLock: IFormNavigationLock;
  onCancelEditing: (() => void) | undefined;
  onStartEditing: (() => void) | undefined;
  onSubmit: (values: IAgreementFormValues) => Promise<SubmissionErrors | void>;
}

export function AgreementForm({
  agreement,
  editingLocked,
  initialValues,
  navigationLock,
  onCancelEditing,
  onStartEditing,
  onSubmit,
}: IProps) {
  return (
    <FinalForm<IAgreementFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <H4>
                {agreement
                  ? `Соглашение ${agreement.number}`
                  : 'Новое соглашение'}
              </H4>

              <Grid>
                <Col span={4}>
                  <H4>Основная информация</H4>

                  <Grid>
                    <Col span={2}>
                      <FormGroupForFinalForm
                        label="Номер"
                        labelFor="number"
                        name="number"
                      >
                        <InputGroupForFinalForm
                          id="number"
                          name="number"
                          readOnly={editingLocked}
                          required
                        />
                      </FormGroupForFinalForm>
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="date"
                        label="Дата"
                        name="date"
                        readOnly={editingLocked}
                        required
                      />
                    </Col>
                  </Grid>

                  <FormGroupForFinalForm
                    label="Дней на погрузку"
                    labelFor="daysLoad"
                    name="daysLoad"
                  >
                    <InputGroupForFinalForm
                      id="daysLoad"
                      name="daysLoad"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Дней на выгрузку"
                    labelFor="daysUnload"
                    name="daysUnload"
                  >
                    <InputGroupForFinalForm
                      id="daysUnload"
                      name="daysUnload"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Доп. порог простоя"
                    labelFor="downtimeLimit"
                    name="downtimeLimit"
                  >
                    <InputGroupForFinalForm
                      id="downtimeLimit"
                      name="downtimeLimit"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <InputMoneyInFormGroupForFinalForm
                    id="downtimeRate"
                    label="Ставка за простой"
                    name="downtimeRate"
                    readOnly={editingLocked}
                  />

                  <FormGroupForFinalForm
                    label="Ставка НДС"
                    labelFor="downtimeVatRate"
                    name="downtimeVatRate"
                  >
                    <SelectForFinalForm
                      disabled={editingLocked}
                      fill
                      id="downtimeVatRate"
                      name="downtimeVatRate"
                      options={vatRateOptions}
                    />
                  </FormGroupForFinalForm>

                  <InputMoneyInFormGroupForFinalForm
                    id="additionalDowntimeRate"
                    label="Ставка за простой сверх доп. порога"
                    name="additionalDowntimeRate"
                    readOnly={editingLocked}
                  />

                  <DocumentStatusSelectForFinalForm
                    disabled={editingLocked}
                    id="status"
                    label="Статус"
                    name="status"
                  />

                  {agreement && (
                    <Grid>
                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата создания"
                          readOnly
                          value={agreement.created}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор"
                          readOnly
                          value={agreement.author}
                        />
                      </Col>

                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата изменения"
                          readOnly
                          value={agreement.modified}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор изменений"
                          readOnly
                          value={agreement.authorModified}
                        />
                      </Col>
                    </Grid>
                  )}

                  <FormGroupForFinalForm
                    label="Заметка"
                    labelFor="note"
                    name="note"
                  >
                    <InputMultipleForFinalForm
                      id="note"
                      name="note"
                      readOnly={editingLocked}
                      rows={3}
                    />
                  </FormGroupForFinalForm>
                </Col>

                <Col span={8}>
                  <H4>Файлы</H4>

                  <FileInputMultipleForFinalForm
                    disabled={editingLocked}
                    id="files"
                    name="files"
                  />
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                {onStartEditing && (
                  <Button
                    disabled={submitting}
                    icon="unlock"
                    intent={Intent.DANGER}
                    text="Редактировать"
                    onClick={onStartEditing}
                  />
                )}

                {onCancelEditing && (
                  <Button
                    disabled={submitting}
                    text="Отменить"
                    onClick={() => {
                      form.reset();
                      onCancelEditing();
                    }}
                  />
                )}

                {!editingLocked && (
                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    loading={submitting}
                    text="Сохранить"
                    type="submit"
                  />
                )}
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

interface IYmInitOptions {
  accurateTrackBounce?: boolean;
  clickmap?: boolean;
  trackLinks?: boolean;
  webvisor?: boolean;
}

interface IYandexMetrika {
  (
    counterId: string,
    method: 'init',
    options: IYmInitOptions & { defer?: boolean }
  ): void;

  (counterId: string, method: 'hit', url: string): void;
}

// prettier-ignore
function initYm(): IYandexMetrika {
  /* eslint-disable */
  // @ts-ignore
  (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
  // @ts-ignore
  m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  // @ts-ignore
  return window.ym
  /* eslint-enable */
}

export function createYandexMetrikaModule(
  counterId: string,
  initOptions: IYmInitOptions
) {
  let ym: IYandexMetrika;

  return {
    initialize: () => {
      ym = initYm();
      ym(counterId, 'init', { ...initOptions, defer: true });
    },

    trackPage: (url: string) => {
      ym(counterId, 'hit', url);
    },
  };
}

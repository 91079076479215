import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { formatUserName } from './formatUserName';
import { IUser } from './types';

export const UsersAutocompleteInFormGroup = wrapEntityAutocompleteWithFormGroup(
  createEntityAutocomplete<IUser, number>({
    baseEndpoint: '/accounts',
    itemToOption: user => ({
      label: formatUserName(user),
      value: user.id,
    }),
  })
);

export const UsersAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(UsersAutocompleteInFormGroup);

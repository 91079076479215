import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';

interface IProps {
  children: React.ReactNode;
  listParams: Record<string, unknown>;
  title: string;
}

export function ContractsPurchaseFormRouteWrapper({
  children,
  listParams,
  title,
}: IProps) {
  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Договоры входящие"
              params={listParams}
              to="contracts.purchase"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>{children}</Row>
      </VGrid>
    </>
  );
}

import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import dayjs from 'dayjs';
import * as React from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { ITechrunRate } from 'techrunRates/types';
import invariant from 'tiny-invariant';

type PurchaseRatesForPartnerPromise = Promise<ITechrunRate[] | null>;
type GetPurchaseRatesForPartner = (
  partnerId: number
) => PurchaseRatesForPartnerPromise;

interface IPromiseCache {
  [partnerId: number]: PurchaseRatesForPartnerPromise;
}

const PurchaseRatesForPartnerContext =
  createContext<GetPurchaseRatesForPartner | null>(null);

if (__DEV__) {
  PurchaseRatesForPartnerContext.displayName = 'PurchaseRatesForPartnerContext';
}

interface IProps {
  arrivalStation: number | null;
  children: React.ReactNode;
  departureStation: number | null;
  endDate: Date | null;
  startDate: Date | null;
}

export function PurchaseRatesForPartnerProvider({
  arrivalStation,
  children,
  departureStation,
  endDate,
  startDate,
}: IProps) {
  const api = useApiClient();

  const promiseCache = useMemo<IPromiseCache>(
    () => ({}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [arrivalStation, departureStation, endDate, startDate]
  );

  const getPurchaseRatesForPartner = useCallback<GetPurchaseRatesForPartner>(
    partnerId => {
      if (promiseCache[partnerId]) {
        return promiseCache[partnerId];
      }

      const promise =
        arrivalStation == null ||
        departureStation == null ||
        endDate == null ||
        startDate == null
          ? Promise.resolve(null)
          : api.post<ITechrunRate[]>(`/techrun_requests/purchase_rates`, {
              arrivalStation,
              departureStation,
              endDate: dayjs(endDate).format(DATE_FORMAT_API_DATE),
              startDate: dayjs(startDate).format(DATE_FORMAT_API_DATE),
              supplier: partnerId,
            });

      promiseCache[partnerId] = promise;

      return promise;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [arrivalStation, departureStation, endDate, api, startDate]
  );

  return (
    <PurchaseRatesForPartnerContext.Provider value={getPurchaseRatesForPartner}>
      {children}
    </PurchaseRatesForPartnerContext.Provider>
  );
}

export function useGetPurchaseRatesForPartner() {
  const getPurchaseRatesForPartner = useContext(PurchaseRatesForPartnerContext);
  invariant(
    getPurchaseRatesForPartner != null,
    'You must render PurchaseRatesForPartnerProvider higher in the tree'
  );
  return getPurchaseRatesForPartner;
}

import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Toolbar } from '_core/toolbar';
import { Button, Intent, Popover, Tooltip } from '@blueprintjs/core';
import { IWagonsDailyUsagePause } from 'expeditionRequests/types';
import * as React from 'react';

import { WagonsDailyUsageColor } from './colors';
import * as css from './pauseRegion.module.css';

interface IProps {
  pause: IWagonsDailyUsagePause;
  style: React.CSSProperties;
  onMouseEnter: (event: React.MouseEvent) => void;
  onMouseSelectionEnd: () => void;
  onMouseSelectionStart: (isAdditive: boolean) => void;
  onPauseUpdate: (updatedPause: IWagonsDailyUsagePause) => Promise<void>;
}

export function PauseRegion({
  pause,
  style,
  onMouseEnter,
  onMouseSelectionEnd,
  onMouseSelectionStart,
  onPauseUpdate,
}: IProps) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const popoverRef = React.useRef<HTMLElement | null>(null);

  return (
    <div
      className={css.root}
      style={{ ...style, backgroundColor: WagonsDailyUsageColor.PAUSED }}
      onMouseDown={event => {
        // this is to not prevent popover content from getting focus on mouse
        // down
        if (
          event.target instanceof HTMLElement &&
          popoverRef.current &&
          popoverRef.current.contains(event.target)
        ) {
          return;
        }

        event.preventDefault();

        function handleWindowMouseUp() {
          onMouseSelectionEnd();
          window.removeEventListener('mouseup', handleWindowMouseUp);
        }

        window.addEventListener('mouseup', handleWindowMouseUp);
        onMouseSelectionStart(event.ctrlKey || event.metaKey);
      }}
      onMouseEnter={onMouseEnter}
    >
      <Tooltip
        content={pause.note}
        disabled={isPopoverOpen || !pause.note}
        targetTagName="div"
        wrapperTagName="div"
      >
        <div className={css.inner}>
          <div className={css.note}>{pause.note}</div>

          <Popover
            content={
              <FinalForm
                initialValues={{ note: pause.note }}
                onSubmit={async values => {
                  await onPauseUpdate({ ...pause, note: values.note });

                  setIsPopoverOpen(false);
                }}
              >
                {({ handleSubmit, submitting }) => (
                  <BaseForm className={css.form} onSubmit={handleSubmit}>
                    <FormGroupForFinalForm label="Комментарий" name="note">
                      <InputMultipleForFinalForm
                        growVertically
                        name="note"
                        rows={8}
                        style={{ resize: 'both' }}
                      />
                    </FormGroupForFinalForm>

                    <Toolbar align="right">
                      <Button
                        disabled={submitting}
                        intent={Intent.PRIMARY}
                        loading={submitting}
                        text="Сохранить"
                        type="submit"
                      />
                    </Toolbar>
                  </BaseForm>
                )}
              </FinalForm>
            }
            isOpen={isPopoverOpen}
            popoverRef={ref => {
              popoverRef.current = ref;
            }}
            onInteraction={setIsPopoverOpen}
          >
            <Button icon="edit" minimal />
          </Popover>
        </div>
      </Tooltip>
    </div>
  );
}

import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';

const BASE_ENDPOINT = '/contacts';

export interface IContactSerialized {
  email: string;
  firstName: string;
  firstNameRp: string;
  id: number;
  jobTitle: string;
  jobTitleRp: string;
  lastName: string;
  lastNameRp: string;
  middleName: string;
  middleNameRp: string;
  modified: string;
  note: string;
  partner: number | null;
  phone: string;
  phoneExtra: string;
}

export interface IContact extends Omit<IContactSerialized, 'modified'> {
  modified: Date;
}

function deserializeContact({
  modified,
  ...otherProps
}: IContactSerialized): IContact {
  return {
    ...otherProps,
    modified: parseDate(modified),
  };
}

export async function fetchContacts(
  api: ApiClient,
  {
    ids,
    page,
    search,
    sorting,
  }: {
    ids?: number[];
    page?: number;
    search?: string;
    sorting?: ISorting[];
  } = {}
): Promise<ListResponse<IContact>> {
  const response = await api.get<ListResponse<IContactSerialized>>(
    BASE_ENDPOINT,
    {
      ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      page,
      search,
    }
  );

  return {
    ...response,
    results: response.results.map(deserializeContact),
  };
}

export const fetchManyContacts = makeFetchMany(fetchContacts, 'ids');

export async function fetchContact(api: ApiClient, id: number) {
  const contact = await api.get<IContactSerialized>(`${BASE_ENDPOINT}/${id}`);

  return deserializeContact(contact);
}

interface ICreateOrUpdateContactParams {
  email: string;
  firstName: string;
  firstNameRp: string;
  jobTitle: string;
  jobTitleRp: string;
  lastName: string;
  lastNameRp: string;
  middleName: string;
  middleNameRp: string;
  note: string;
  partner: number | null;
  phone: string;
  phoneExtra: string;
}

function serializeCreateOrUpdateContactParams({
  ...otherParams
}: ICreateOrUpdateContactParams) {
  return {
    ...otherParams,
  };
}

export async function createContact(
  api: ApiClient,
  params: ICreateOrUpdateContactParams
) {
  const createdContact = await api.post<IContactSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateContactParams(params)
  );

  return deserializeContact(createdContact);
}

export async function updateContact(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateContactParams
) {
  const updatedContact = await api.put<IContactSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateContactParams(params)
  );

  return deserializeContact(updatedContact);
}

export async function getOrCreateContactsFromText(
  api: ApiClient,
  text: string
) {
  const createdContacts = await api.post<IContactSerialized[]>(
    `${BASE_ENDPOINT}/rawtext`,
    { text }
  );

  return createdContacts.map(deserializeContact);
}

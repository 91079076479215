import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { chainValidators, required, validate } from '_core/forms/validators';
import { intersection } from '_core/fp/intersection';
import { without } from '_core/fp/without';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { plural } from '_core/strings/utils';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { Button, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useRef } from 'react';
import { parseWagonNumbers } from 'wagons/utils';

type SubmitTrigger = 'add' | 'remove';

interface IFormValues {
  batch: string;
}

interface IProps {
  currentWagons: string[];
  disabled: boolean | undefined;
  onClose: () => void;
  onWagonsUpdate: (newWagons: string[]) => void;
}

export function AddRemoveWagonsForm({
  currentWagons,
  disabled,
  onClose,
  onWagonsUpdate,
}: IProps) {
  const submitTriggerRef = useRef<SubmitTrigger>('add');
  const toaster = useToaster();

  return (
    <FinalForm<IFormValues>
      initialValues={{ batch: '' }}
      validate={validate({
        batch: chainValidators(required(), (batch: string) =>
          parseWagonNumbers(batch).length === 0
            ? 'Не введён ни один корректный номер вагона'
            : undefined
        ),
      })}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'add': {
            const wagonNumbers = parseWagonNumbers(values.batch);
            const wagonsToAdd = without(currentWagons, wagonNumbers);
            const addCount = wagonsToAdd.length;

            if (addCount === 0) {
              return {
                batch: 'Все указанные вагоны уже есть в парке',
              };
            }

            toaster.show({
              intent: Intent.SUCCESS,
              message: plural(addCount, [
                () => `Успешно добавлен ${addCount} вагон`,
                () => `Успешно добавлено ${addCount} вагона`,
                () => `Успешно добавлено ${addCount} вагонов`,
              ]),
            });

            onWagonsUpdate(wagonsToAdd.concat(currentWagons));
            return;
          }
          case 'remove': {
            const wagonNumbers = parseWagonNumbers(values.batch);
            const wagonsToRemove = intersection(currentWagons, wagonNumbers);
            const removeCount = wagonsToRemove.length;

            if (removeCount === 0) {
              return {
                batch: 'Ни один из указанных вагонов не найден в парке',
              };
            }

            toaster.show({
              intent: Intent.SUCCESS,
              message: plural(removeCount, [
                () => `Успешно удалён ${removeCount} вагон`,
                () => `Успешно удалено ${removeCount} вагона`,
                () => `Успешно удалено ${removeCount} вагонов`,
              ]),
            });

            onWagonsUpdate(without(wagonNumbers, currentWagons));
            return;
          }
        }
      }}
    >
      {({ form, handleSubmit }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormGroupForFinalForm labelFor="batch" name="batch">
            <InputMultipleForFinalForm
              disabled={disabled}
              id="batch"
              name="batch"
              rows={8}
            />
          </FormGroupForFinalForm>

          <Toolbar align="right">
            <Button disabled={disabled} text="Закрыть" onClick={onClose} />

            <Button
              disabled={disabled}
              intent={Intent.DANGER}
              text="Удалить"
              onClick={() => {
                submitTriggerRef.current = 'remove';
                form.submit();
              }}
            />

            <Button
              disabled={disabled}
              intent={Intent.PRIMARY}
              text="Добавить"
              type="submit"
              onClick={() => {
                submitTriggerRef.current = 'add';
              }}
            />
          </Toolbar>
        </BaseForm>
      )}
    </FinalForm>
  );
}

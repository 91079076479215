import { Link } from '_core/router5/link';
import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';

export default function NotFoundPage() {
  return (
    <NonIdealState icon="error" title="404">
      <div>
        Страница не существует, попробуйте начать с{' '}
        <Link to="home">Главной страницы</Link>.
      </div>
    </NonIdealState>
  );
}

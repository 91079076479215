import { sumMaybeDecimals } from '_core/decimal';
import { TableFieldErrors } from '_core/forms/tableFieldErrors';
import { InputMoney } from '_core/inputs/inputMoney';
import { Select } from '_core/inputs/select';
import { isNotNull } from '_core/isNotNull';
import { formatMoney } from '_core/money/formatMoney';
import { BaseCell, CellEditor } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { createTableCellEditor } from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { Button, InputGroup, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import {
  ExpeditionTransportationIncomeType,
  IExpeditionTransportationShipment,
} from 'expeditionTransportations/types';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import {
  costFromTotalCostForForm,
  totalCostForForm,
  VatRate,
  vatRateLabel,
  vatRateOptions,
  vatValueForForm,
  vatValueFromTotalCostForForm,
} from 'vatRates/vatRates';

import * as css from './incomesField.module.css';

export interface IExpeditionTransportationIncomesFieldItem {
  cost: string;
  costTotal: string;
  costVatValue: string | null;
  incomeType: ExpeditionTransportationIncomeType;
  isCalculated: boolean;
  name: string;
  vatRate: VatRate | null;
}

type TableItem =
  | {
      kind: 'income';
      income: IExpeditionTransportationIncomesFieldItem;
      index: number;
    }
  | {
      kind: 'total';
      cost: string;
      costVatValue: string;
      costTotal: string;
    };

interface IProps {
  change: (field: string, value: unknown) => void;
  name: string;
  shipments: IExpeditionTransportationShipment[];
}

export function ExpeditionTransportationIncomesField({
  change,
  name,
  shipments,
}: IProps) {
  const { fields, meta } =
    useFieldArray<IExpeditionTransportationIncomesFieldItem>(name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitting = (meta as any).submitting as boolean;

  const columns = React.useMemo(
    (): Array<ListTableColumn<TableItem>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 250,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          item.kind === 'total' ? 'Итого' : item.income.name,
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {item.kind === 'total' ? 'Итого' : item.income.name}
          </Ellipsis>
        ),
        getEditor: item =>
          item.kind === 'total' || item.income.isCalculated
            ? undefined
            : createTableCellEditor({
                initialValue: item.income.name,
                applyValue: newName => {
                  change(`${fields.name}[${item.index}].name`, newName);
                },
                render: ({ style, value, onChange }) => (
                  <CellEditor style={style}>
                    <InputGroup
                      autoFocus
                      value={value}
                      onChange={event => onChange(event.currentTarget.value)}
                    />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'cost',
        label: 'Стоимость',
        defaultWidth: 150,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          item.kind === 'total' ? item.cost : item.income.cost,
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {formatMoney(item.kind === 'total' ? item.cost : item.income.cost)}
          </Ellipsis>
        ),
        getEditor: item =>
          item.kind === 'total' || item.income.isCalculated
            ? undefined
            : createTableCellEditor({
                initialValue: item.income.cost,
                applyValue: newCost => {
                  change(`${fields.name}[${item.index}].cost`, newCost);
                  change(
                    `${fields.name}[${item.index}].costVatValue`,
                    item.income.vatRate
                      ? vatValueForForm(newCost, item.income.vatRate)
                      : null
                  );
                  change(
                    `${fields.name}[${item.index}].costTotal`,
                    item.income.vatRate
                      ? totalCostForForm(newCost, item.income.vatRate)
                      : null
                  );
                },
                render: ({ style, value, onChange }) => (
                  <CellEditor style={style}>
                    <InputMoney autoFocus value={value} onChange={onChange} />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'vatRate',
        label: 'Ставка НДС',
        defaultWidth: 150,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          item.kind === 'total' ? '' : vatRateLabel(item.income.vatRate),
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {item.kind === 'total' ? '' : vatRateLabel(item.income.vatRate)}
          </Ellipsis>
        ),
        getEditor: item =>
          item.kind === 'total' || item.income.isCalculated
            ? undefined
            : createTableCellEditor({
                initialValue: item.income.vatRate,
                applyValue: newVatRate => {
                  change(`${fields.name}[${item.index}].vatRate`, newVatRate);
                  change(
                    `${fields.name}[${item.index}].costVatValue`,
                    vatValueForForm(item.income.cost, newVatRate)
                  );
                  change(
                    `${fields.name}[${item.index}].costTotal`,
                    totalCostForForm(item.income.cost, newVatRate)
                  );
                },
                render: ({ style, value, onChange }) => (
                  <CellEditor style={style}>
                    <Select
                      autoFocus
                      fill
                      options={vatRateOptions}
                      withEmptyOption
                      value={value}
                      onChange={onChange}
                    />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'costVatValue',
        label: 'НДС (руб.)',
        defaultWidth: 150,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          item.kind === 'total'
            ? item.costVatValue
            : item.income.costVatValue ?? '',
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {item.kind === 'total'
              ? formatMoney(item.costVatValue)
              : formatMoney(item.income.costVatValue)}
          </Ellipsis>
        ),
      },
      {
        id: 'costTotal',
        label: 'Стоимость (в т.ч. НДС)',
        defaultWidth: 200,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {item.kind === 'total'
              ? formatMoney(item.costTotal)
              : formatMoney(item.income.costTotal)}
          </Ellipsis>
        ),
        getEditor: item =>
          item.kind === 'total' || item.income.isCalculated
            ? undefined
            : createTableCellEditor({
                initialValue: item.income.costTotal,
                applyValue: newCostTotal => {
                  change(
                    `${fields.name}[${item.index}].costTotal`,
                    newCostTotal
                  );
                  change(
                    `${fields.name}[${item.index}].costVatValue`,
                    item.income.vatRate
                      ? vatValueFromTotalCostForForm(
                          newCostTotal,
                          item.income.vatRate
                        )
                      : null
                  );
                  change(
                    `${fields.name}[${item.index}].cost`,
                    item.income.vatRate
                      ? costFromTotalCostForForm(
                          newCostTotal,
                          item.income.vatRate
                        )
                      : null
                  );
                },
                render: ({ style, value, onChange }) => (
                  <CellEditor style={style}>
                    <InputMoney autoFocus value={value} onChange={onChange} />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'shipment',
        label: 'Отгрузка',
        defaultWidth: 250,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        renderCellContent: item => {
          if (item.kind === 'total') {
            return null;
          }

          const shipment = shipments.find(
            s => s.type === item.income.incomeType
          );

          if (!shipment) {
            return null;
          }

          return (
            <Ellipsis
              component={Link}
              params={{ id: String(shipment.id) }}
              rel="noopener"
              target="_blank"
              to="shipmentInfo.edit"
            >
              {shipment.name}
            </Ellipsis>
          );
        },
      },
    ],
    [change, fields.name, shipments]
  );

  const getItemId = React.useCallback(
    (item: TableItem) =>
      item.kind === 'income' ? `income-${item.index}` : 'total',
    []
  );

  const items = React.useMemo(
    () =>
      fields.value
        .map((income, index): TableItem => ({ kind: 'income', income, index }))
        .concat({
          kind: 'total',
          cost: sumMaybeDecimals(fields.value.map(item => item.cost)).toFixed(
            2
          ),
          costVatValue: sumMaybeDecimals(
            fields.value.map(item => item.costVatValue).filter(isNotNull)
          ).toFixed(2),
          costTotal: sumMaybeDecimals(
            fields.value.map(item => item.costTotal)
          ).toFixed(2),
        }),
    [fields.value]
  );

  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  return (
    <VGrid>
      <Row>
        <TableFieldErrors
          fieldLabels={[
            ['name', 'Наименование'],
            ['cost', 'Стоимость'],
          ]}
          rowsErrors={meta.error || meta.submitError}
        />

        <ListTable
          columns={columns}
          getItemId={getItemId}
          items={items}
          maxHeight={500}
          selectedItems={selectedItems}
          stickyBottomRowCount={1}
          stickyColumnCount={3}
          onSelectedItemsChange={setSelectedItems}
        />
      </Row>

      <Row>
        <Toolbar>
          <Button
            disabled={submitting}
            icon="add-row-bottom"
            intent={Intent.PRIMARY}
            text="Добавить доход"
            onClick={() => {
              fields.push({
                cost: '',
                costTotal: '',
                costVatValue: '',
                incomeType: ExpeditionTransportationIncomeType.Custom,
                isCalculated: false,
                name: '',
                vatRate: VatRate.None,
              });
            }}
          />

          <Button
            disabled={
              submitting ||
              selectedItems.length === 0 ||
              selectedItems
                .map(itemId => items.find(item => getItemId(item) === itemId))
                .filter(isNotNull)
                .some(item => item.kind === 'total' || item.income.isCalculated)
            }
            icon="remove"
            intent={Intent.DANGER}
            text="Удалить выбранные доходы"
            onClick={() => {
              selectedItems
                .map(itemId => items.find(item => getItemId(item) === itemId))
                .filter(
                  (item): item is TableItem & { kind: 'income' } =>
                    item?.kind === 'income'
                )
                .map(item => item.index)
                .sort((a, b) => b - a)
                .forEach(index => {
                  fields.remove(index);
                });

              setSelectedItems([]);
            }}
          />
        </Toolbar>
      </Row>
    </VGrid>
  );
}

import { CenteredWrapper } from '_core/layout/centeredWrapper';
import { Spinner } from '@blueprintjs/core';
import * as React from 'react';

export function CenteredSpinner() {
  return (
    <CenteredWrapper>
      <Spinner />
    </CenteredWrapper>
  );
}

import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATE, DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { Checkbox } from '_core/inputs/checkbox';
import { DateInputInFormGroup } from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMoneyInFormGroup } from '_core/inputs/inputMoney';
import { Select } from '_core/inputs/select';
import { formatMoney } from '_core/money/formatMoney';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  Button,
  Callout,
  FormGroup,
  H4,
  H5,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  Position,
  UL,
} from '@blueprintjs/core';
import dayjs from 'dayjs';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { useRef } from 'react';
import { fetchRentWagonPurchase } from 'rentWagonPurchase/api';
import {
  fetchManyShipmentInfoItems,
  IShipmentInfoListItem,
} from 'shipmentInfo/api';
import { StationsAutocompleteInFormGroup } from 'stations/autocomplete';
import { TechrunRequestsAutocompleteInFormGroup } from 'techrunRequests/autocomplete';
import { fetchManyTechrunTransportations } from 'techrunTransportations/api';
import {
  getTechrunTransportationGroupLabel,
  ITechrunTransportation,
  TechrunTransportationGroup,
} from 'techrunTransportations/types';
import {
  accountingStateOptions,
  transportationStateOptions,
} from 'transportations/types';

import {
  ITechrunTransportationExpensesFieldItem,
  TechrunTransportationExpensesField,
} from './expensesField';
import {
  ITechrunTransportationIncomesFieldItem,
  TechrunTransportationIncomesField,
} from './incomesField';

enum RateType {
  Day = 'DAY',
  Ton = 'TON',
  Wagon = 'WAGON',
}

const rateTypeOptions = [RateType.Wagon, RateType.Ton, RateType.Day].map(
  rateType => ({
    label: {
      [RateType.Day]: 'За сутки',
      [RateType.Wagon]: 'За вагон',
      [RateType.Ton]: 'За тонну',
    }[rateType],
    value: rateType,
  })
);

export interface ITechrunTransportationFormValues {
  expenses: ITechrunTransportationExpensesFieldItem[];
  income: ITechrunTransportationIncomesFieldItem[];
  note: string;
  owner: number | null;
}

interface IProps {
  initialValues: ITechrunTransportationFormValues;
  navigationLock: IFormNavigationLock;
  transportation: ITechrunTransportation;
  onConfirmActiveDislocation: () => Promise<SubmissionErrors | void>;
  onConfirmDepartureDate: () => Promise<SubmissionErrors | void>;
  onMoveToGroup: (
    group: TechrunTransportationGroup
  ) => Promise<SubmissionErrors | void>;
  onRejectActiveDislocation: () => Promise<SubmissionErrors | void>;
  onRejectDepartureDate: () => Promise<SubmissionErrors | void>;
  onSave: (
    values: ITechrunTransportationFormValues
  ) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: ITechrunTransportationFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function TechrunTransportationForm({
  initialValues,
  navigationLock,
  transportation,
  onConfirmActiveDislocation,
  onConfirmDepartureDate,
  onMoveToGroup,
  onRejectActiveDislocation,
  onRejectDepartureDate,
  onSaveAndContinue,
  onSave,
}: IProps) {
  const api = useApiClient();

  const { data, isFetching } = useAsyncData([api, transportation], async () => {
    const [emptyTransportations, purchaseRent, shipments] = await Promise.all([
      fetchManyTechrunTransportations(api, transportation.emptyTransportations),
      transportation.purchaseRent
        ? fetchRentWagonPurchase(api, transportation.purchaseRent)
        : Promise.resolve(null),
      fetchManyShipmentInfoItems(api, transportation.shipments),
    ]);

    return {
      emptyTransportations,
      purchaseRent,
      shipments,
    };
  });

  const submitHandlerRef = useRef(onSave);

  return (
    <FinalForm
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={values => submitHandlerRef.current(values)}
    >
      {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            {transportation.group === TechrunTransportationGroup.New && (
              <Row>
                <Callout
                  icon="eye-open"
                  intent={Intent.SUCCESS}
                  title="Это новая отправка"
                >
                  <Toolbar>
                    <Popover
                      content={
                        <Menu>
                          {transportation.groupsTransitionRoute.map(group => (
                            <MenuItem
                              key={group}
                              text={getTechrunTransportationGroupLabel(group)}
                              onClick={() => {
                                submitHandlerRef.current = () =>
                                  onMoveToGroup(group);
                                form.submit();
                              }}
                            />
                          ))}
                        </Menu>
                      }
                      disabled={submitting}
                      position={Position.BOTTOM_RIGHT}
                    >
                      <Button
                        disabled={submitting}
                        rightIcon="caret-down"
                        text="Переместить"
                      />
                    </Popover>
                  </Toolbar>
                </Callout>
              </Row>
            )}

            {transportation.isDepartureDateChanged && (
              <Row>
                <Callout
                  icon="warning-sign"
                  intent={Intent.WARNING}
                  title={`Дата отправления изменилась с ${dayjs(
                    transportation.departureDate
                  ).format(DATE_FORMAT_DATE)} на ${dayjs(
                    transportation.departureDateNext
                  ).format(DATE_FORMAT_DATE)}`}
                >
                  <Toolbar>
                    <Button
                      intent={Intent.PRIMARY}
                      text="Подтвердить"
                      disabled={submitting}
                      onClick={() => {
                        submitHandlerRef.current = onConfirmDepartureDate;
                        form.submit();
                      }}
                    />

                    <Button
                      intent={Intent.DANGER}
                      text="Отклонить"
                      disabled={submitting}
                      onClick={() => {
                        submitHandlerRef.current = onRejectDepartureDate;
                        form.submit();
                      }}
                    />
                  </Toolbar>
                </Callout>
              </Row>
            )}

            {transportation.isWaybillNumberChanged &&
            transportation.dislocationNext ? (
              <Row>
                <Callout
                  icon="warning-sign"
                  intent={Intent.WARNING}
                  title={`Номер накладной изменился с ${transportation.dislocationActive.waybillNumber} на ${transportation.dislocationNext.waybillNumber}`}
                >
                  <p>
                    <Link
                      params={{
                        dislocationDatetime:
                          transportation.dislocationCreated.created,
                        number: transportation.wagon,
                      }}
                      to="wagons.edit.history"
                    >
                      История дислокаций
                    </Link>
                  </p>

                  <Toolbar>
                    <Button
                      intent={Intent.PRIMARY}
                      text="Подтвердить"
                      disabled={submitting}
                      onClick={() => {
                        submitHandlerRef.current = onConfirmActiveDislocation;
                        form.submit();
                      }}
                    />

                    <Button
                      intent={Intent.DANGER}
                      text="Отменить"
                      disabled={submitting}
                      onClick={() => {
                        submitHandlerRef.current = onRejectActiveDislocation;
                        form.submit();
                      }}
                    />
                  </Toolbar>
                </Callout>
              </Row>
            ) : null}

            <Row>
              <VGrid>
                <Row>
                  <Grid>
                    <Col span={4}>
                      <H4>Основная информация</H4>

                      <FormGroup label="Наименование отправки">
                        <InputGroup
                          name="transportationName"
                          readOnly
                          value={transportation.transportationName}
                        />
                      </FormGroup>

                      <Grid>
                        <Col span={2}>
                          <FormGroup label="Накладная">
                            <InputGroup
                              name="waybillNumber"
                              readOnly
                              value={transportation.waybillNumber}
                            />
                          </FormGroup>
                        </Col>

                        <Col span={2}>
                          <FormGroup label="Вагон">
                            <InputGroup
                              name="wagon"
                              readOnly
                              value={transportation.wagon}
                            />
                          </FormGroup>
                        </Col>
                      </Grid>

                      <FormGroup>
                        <Toolbar>
                          <LinkButton
                            intent={Intent.SUCCESS}
                            params={{ number: transportation.wagon }}
                            rel="noopener"
                            target="_blank"
                            text="Дислокация"
                            to="wagons.edit.history"
                          />

                          {transportation.purchaseRent && (
                            <LinkButton
                              intent={Intent.SUCCESS}
                              params={{ id: transportation.purchaseRent }}
                              text="Аренда"
                              to="rentWagons.purchase.edit"
                            />
                          )}
                        </Toolbar>
                      </FormGroup>

                      <TechrunRequestsAutocompleteInFormGroup
                        label="Заявка"
                        name="request"
                        readOnly
                        value={transportation.request}
                      />

                      {transportation.group === 'SUPPLEMENTARY' && (
                        <FormGroup label="Аренда">
                          <InputGroup
                            readOnly
                            value={
                              isFetching
                                ? 'Загрузка...'
                                : data && data.purchaseRent
                                ? data.purchaseRent.shortName
                                : ''
                            }
                          />
                        </FormGroup>
                      )}

                      <FormGroup label="Грузоотправитель">
                        <InputGroup
                          name="consigner"
                          readOnly
                          value={transportation.consigner}
                        />
                      </FormGroup>

                      <Grid>
                        <Col span={2}>
                          <InputMoneyInFormGroup
                            label="Расчётный тариф"
                            name="tariffRate"
                            readOnly
                            value={transportation.tariffRate ?? ''}
                          />
                        </Col>

                        <Col span={2}>
                          <InputMoneyInFormGroup
                            label="Тариф из дислокации"
                            name="tariffIvc"
                            readOnly
                            value={transportation.tariffIvc ?? ''}
                          />
                        </Col>
                      </Grid>

                      <InputMoneyInFormGroup
                        label="Тариф из перечня"
                        name="tariffOverride"
                        readOnly
                        value={transportation.tariffOverride ?? ''}
                      />

                      <PartnersAutocompleteInFormGroupForFinalForm
                        addToQuery={{ isSupplier: true }}
                        label="Собственник"
                        name="owner"
                      />

                      <Grid>
                        <Col span={2}>
                          <FormGroup label="Вид ставки">
                            <Select
                              disabled
                              fill
                              name="sellRateType"
                              options={rateTypeOptions}
                              value={
                                transportation.sellRateType as unknown as RateType
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col span={2}>
                          <FormGroup label="Вес груза (тн)">
                            <InputGroup
                              name="cargoWeight"
                              readOnly
                              value={transportation.cargoWeight}
                            />
                          </FormGroup>
                        </Col>
                      </Grid>

                      <Checkbox
                        checked={transportation.isEmpty}
                        disabled
                        name="isEmpty"
                        label="Порожняя отправка"
                      />

                      <Grid>
                        <Col span={2}>
                          <FormGroup label="Длительность">
                            <InputGroup
                              name="duration"
                              readOnly
                              value={String(transportation.duration)}
                            />
                          </FormGroup>
                        </Col>
                      </Grid>

                      {transportation.group !== 'SUPPLEMENTARY' && (
                        <div>
                          <H5>Связанные служебные отправки</H5>

                          {!data ? (
                            isFetching ? (
                              <CenteredSpinner />
                            ) : (
                              <GenericErrorMessage />
                            )
                          ) : !data.emptyTransportations ||
                            data.emptyTransportations.length === 0 ? (
                            <NonIdealState
                              icon="list"
                              description="Нет элементов"
                            />
                          ) : (
                            <UL>
                              {data.emptyTransportations.map(transp => (
                                <li key={transp.id}>
                                  <Link
                                    params={{
                                      id: transp.id,
                                      status: 'supplementary',
                                    }}
                                    rel="noopener"
                                    target="_blank"
                                    to="techruns.transportations.edit"
                                  >
                                    {transp.transportationName}
                                  </Link>
                                </li>
                              ))}
                            </UL>
                          )}
                        </div>
                      )}

                      {transportation.group !== 'SUPPLEMENTARY' && (
                        <div>
                          <H5>Отгрузочная информация</H5>

                          <Table
                            isFetching={isFetching}
                            items={data && data.shipments}
                          >
                            <Column
                              id="waybillNumber"
                              label="Накладная"
                              width={135}
                              cell={({
                                id,
                                numberUpd,
                              }: IShipmentInfoListItem) => (
                                <LinkCell
                                  params={{ id }}
                                  to="shipmentInfo.edit"
                                >
                                  {numberUpd || '<отсутствует>'}
                                </LinkCell>
                              )}
                            />
                          </Table>
                        </div>
                      )}
                    </Col>

                    <Col span={4}>
                      <H4>Даты и станции</H4>

                      <Grid>
                        <Col span={2}>
                          <DateInputInFormGroup
                            fill
                            label="Дата отправления"
                            name="departureDate"
                            readOnly
                            value={transportation.departureDate}
                          />
                        </Col>

                        <Col span={2}>
                          <DateInputInFormGroup
                            fill
                            label="Дата прибытия"
                            name="arrivalDate"
                            readOnly
                            value={transportation.arrivalDate}
                          />
                        </Col>
                      </Grid>

                      <StationsAutocompleteInFormGroup
                        label="Станция отправления"
                        name="departureStation"
                        readOnly
                        value={transportation.departureStation}
                      />

                      <StationsAutocompleteInFormGroup
                        label="Станция назначения"
                        name="arrivalStation"
                        readOnly
                        value={transportation.arrivalStation}
                      />

                      <StationsAutocompleteInFormGroup
                        label="Станция операции"
                        name="operationStation"
                        readOnly
                        value={transportation.operationStation}
                      />

                      <DateInputInFormGroup
                        fill
                        label="Дата отправки вагона после выгрузки"
                        name="departureAfterUnload"
                        readOnly
                        value={transportation.departureAfterUnload}
                      />

                      <DateInputInFormGroup
                        fill
                        label="Дата прибытия вагона под погрузку"
                        name="arrivalBeforeLoad"
                        readOnly
                        value={transportation.arrivalBeforeLoad}
                      />
                    </Col>

                    <Col span={4}>
                      <H4>Редактировать</H4>

                      <FormGroup label="Состояние">
                        <Select
                          disabled
                          fill
                          name="state"
                          options={transportationStateOptions}
                          value={transportation.state}
                        />
                      </FormGroup>

                      <FormGroup label="Бух. состояние">
                        <Select
                          disabled
                          fill
                          name="accountingState"
                          options={accountingStateOptions}
                          value={transportation.accountingState}
                        />
                      </FormGroup>

                      <FormGroupForFinalForm label="Заметка" name="note">
                        <InputGroup name="note" />
                      </FormGroupForFinalForm>

                      <Grid>
                        <Col span={2}>
                          <DateInputInFormGroup
                            dateFormat={DATE_FORMAT_DATETIME}
                            label="Дата создания"
                            name="created"
                            readOnly
                            value={transportation.created}
                          />
                        </Col>

                        <Col span={2}>
                          <DateInputInFormGroup
                            dateFormat={DATE_FORMAT_DATETIME}
                            label="Дата изменения"
                            name="modified"
                            readOnly
                            value={transportation.modified}
                          />
                        </Col>
                      </Grid>
                    </Col>
                  </Grid>
                </Row>

                <Row>
                  <H4>Доходы</H4>

                  <TechrunTransportationIncomesField
                    change={form.change}
                    name="income"
                  />
                </Row>

                <Row>
                  <H4>Расходы</H4>

                  <TechrunTransportationExpensesField
                    change={form.change}
                    name="expenses"
                  />
                </Row>

                <Row>
                  <H4>
                    Прибыль:{' '}
                    {formatMoney(transportation.profit, { withCurrency: true })}
                  </H4>
                </Row>
              </VGrid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitHandlerRef.current = onSaveAndContinue;
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitHandlerRef.current = onSave;
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { sumMaybeDecimals } from '_core/decimal';
import { TableFieldErrors } from '_core/forms/tableFieldErrors';
import { indexByAllItems } from '_core/indexBy';
import { InputMoney } from '_core/inputs/inputMoney';
import { Select } from '_core/inputs/select';
import { isNotNull } from '_core/isNotNull';
import { formatMoney } from '_core/money/formatMoney';
import { BaseCell, CellEditor } from '_core/react-window/cells';
import { TreeCell } from '_core/react-window/treeCell';
import { TreeCellPadding } from '_core/react-window/treeCellPadding';
import { TreeTable, TreeTableColumn } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import {
  createTableCellEditor,
  everyNode,
  findNode,
  setAllNodesIsExpanded,
  switchOverNodeKind,
} from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { Button, H4, InputGroup, Intent } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import { IPartnerSerialized } from 'partners/api';
import { PartnersAutocompleteInFormGroup } from 'partners/autocomplete';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { RentWagonPurchaseIncomeType } from 'rentWagonPurchase/api';
import {
  deserializeShipmentInfoListItem,
  IShipmentInfoListItem,
  serializeShipmentInfoListItem,
} from 'shipmentInfo/api';
import { ShipmentInfoAutocompleteInFormGroup } from 'shipmentInfo/autocomplete';
import {
  costFromTotalCostForForm,
  totalCostForForm,
  VatRate,
  vatRateLabel,
  vatRateOptions,
  vatValueForForm,
  vatValueFromTotalCostForForm,
} from 'vatRates/vatRates';

import * as css from './incomesField.module.css';

export interface IRentWagonPurchaseIncomesFieldItem {
  cost: string;
  costTotal: string;
  costVatValue: string;
  days: number | null;
  isCalculated: boolean;
  name: string;
  partner: IPartnerSerialized | null;
  shipment: IShipmentInfoListItem | null;
  type: RentWagonPurchaseIncomeType;
  vatRate: VatRate;
  year: number | null;
}

interface IProps {
  change: (name: string, value: unknown) => void;
  incomesCost: string;
  incomesCostTotal: string;
  incomesCostVatValue: string;
  name: string;
}

type IncomeTreeTableNodeYearData = {
  kind: 'year';
  year: number;
  days: number;
  cost: string;
  costTotal: string;
  costVatValue: string;
};

type IncomeTreeTableNodeIncomeData = {
  kind: 'income';
  income: IRentWagonPurchaseIncomesFieldItem;
  index: number;
};

type IncomeTreeTableNodeTotalData = {
  kind: 'total';
  cost: string;
  costTotal: string;
  costVatValue: string;
};

type IncomeTreeTableNodeData =
  | IncomeTreeTableNodeYearData
  | IncomeTreeTableNodeIncomeData
  | IncomeTreeTableNodeTotalData;

interface ICreateNodesOptions {
  incomes: IRentWagonPurchaseIncomesFieldItem[];
  incomesCost: string;
  incomesCostTotal: string;
  incomesCostVatValue: string;
}

function createNodes(
  prevNodes: Array<TreeTableNode<IncomeTreeTableNodeData>> | null,
  {
    incomes,
    incomesCost,
    incomesCostTotal,
    incomesCostVatValue,
  }: ICreateNodesOptions
) {
  function createIncomeNode(
    income: IRentWagonPurchaseIncomesFieldItem,
    index: number
  ): TreeTableNode<IncomeTreeTableNodeData> {
    return {
      id: `income-${index}`,
      data: { kind: 'income', income, index },
    };
  }

  const totalNode: TreeTableNode<IncomeTreeTableNodeTotalData> = {
    id: 'total',
    data: {
      kind: 'total',
      cost: incomesCost,
      costTotal: incomesCostTotal,
      costVatValue: incomesCostVatValue,
    },
  };

  if (incomes.filter(income => income.isCalculated).length > 12) {
    const incomesGroupedByYear = Object.entries(
      indexByAllItems(
        incomes,
        income => income.year,
        (income, index) => ({ income, index })
      )
    ).map(([key, yearIncomes]): TreeTableNode<IncomeTreeTableNodeData> => {
      const year = Number(key);

      const prevYearNode = prevNodes
        ? findNode(
            prevNodes,
            node => node.data.kind === 'year' && node.data.year === year
          )
        : undefined;

      return {
        id: `year-${year}`,
        data: {
          kind: 'year',
          year,
          days: yearIncomes
            .map(({ income }) => income.days)
            .filter(isNotNull)
            .reduce((sum, n) => sum + n, 0),
          cost: String(
            sumMaybeDecimals(yearIncomes.map(({ income }) => income.cost))
          ),
          costVatValue: String(
            sumMaybeDecimals(
              yearIncomes.map(({ income }) => income.costVatValue)
            )
          ),
          costTotal: String(
            sumMaybeDecimals(yearIncomes.map(({ income }) => income.costTotal))
          ),
        },
        children: {
          isExpanded:
            prevYearNode && prevYearNode.children
              ? prevYearNode.children.isExpanded
              : false,
          nodes: yearIncomes.map(({ income, index }) =>
            createIncomeNode(income, index)
          ),
        },
      };
    });

    return [
      ...incomesGroupedByYear,
      ...incomes
        .map((income, index) => ({ income, index }))
        .filter(({ income }) => !income.isCalculated)
        .map(({ income, index }) => createIncomeNode(income, index)),
      totalNode,
    ];
  }

  return [...incomes.map(createIncomeNode), totalNode];
}

export function RentWagonPurchaseIncomesField({
  change,
  incomesCost,
  incomesCostTotal,
  incomesCostVatValue,
  name,
}: IProps) {
  const { fields, meta } =
    useFieldArray<IRentWagonPurchaseIncomesFieldItem>(name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitting = (meta as any).submitting as boolean;

  const columns = useMemo(
    (): Array<TreeTableColumn<IncomeTreeTableNodeData>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 280,
        expandable: true,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onExpand,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            noPadding
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            <TreeCellPadding depth={row.path.length}>
              <TreeCell row={row} onExpand={onExpand}>
                {children}
              </TreeCell>
            </TreeCellPadding>
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ year }) => String(year),
            income: ({ income }) => income.name,
            total: () => 'Итого',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ year }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {String(year)}
              </Ellipsis>
            ),
            income: ({ income }) => (
              <Ellipsis component="span">{income.name}</Ellipsis>
            ),
            total: () => (
              <Ellipsis className={css.boldCellText} component="span">
                Итого
              </Ellipsis>
            ),
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            income: ({ income, index }) =>
              income.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: income.name,
                    applyValue: newName => {
                      change(`${fields.name}[${index}].name`, newName);
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <InputGroup
                          autoFocus
                          value={value}
                          onChange={event =>
                            onChange(event.currentTarget.value)
                          }
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'days',
        label: 'Суток начислено',
        defaultWidth: 150,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ days }) => days.toString(),
            income: ({ income }) =>
              income.days == null ? '' : income.days.toString(),
            total: () => '',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ days }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {days.toString()}
              </Ellipsis>
            ),
            income: ({ income }) =>
              income.days == null ? null : (
                <Ellipsis component="span">{income.days.toString()}</Ellipsis>
              ),
            total: () => null,
          }),
      },
      {
        id: 'cost',
        label: 'Стоимость',
        defaultWidth: 150,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ cost }) => formatMoney(cost),
            income: ({ income }) => formatMoney(income.cost),
            total: ({ cost }) => formatMoney(cost),
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ cost }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(cost)}
              </Ellipsis>
            ),
            income: ({ income }) => (
              <Ellipsis component="span">{formatMoney(income.cost)}</Ellipsis>
            ),
            total: ({ cost }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(cost)}
              </Ellipsis>
            ),
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            income: ({ income, index }) =>
              income.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: income.cost,
                    applyValue: newCost => {
                      change(`${fields.name}[${index}].cost`, newCost);

                      change(
                        `${fields.name}[${index}].costVatValue`,
                        vatValueForForm(newCost, income.vatRate)
                      );

                      change(
                        `${fields.name}[${index}].costTotal`,
                        totalCostForForm(newCost, income.vatRate)
                      );
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <InputMoney
                          autoFocus
                          value={value}
                          onChange={onChange}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'vatRate',
        label: 'Ставка НДС',
        defaultWidth: 105,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => '',
            income: ({ income }) => vatRateLabel(income.vatRate),
            total: () => '',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => null,
            income: ({ income }) => (
              <Ellipsis component="span">
                {vatRateLabel(income.vatRate)}
              </Ellipsis>
            ),
            total: () => null,
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            income: ({ income, index }) =>
              income.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: income.vatRate,
                    applyValue: newVatRate => {
                      change(`${fields.name}[${index}].vatRate`, newVatRate);

                      change(
                        `${fields.name}[${index}].costVatValue`,
                        vatValueForForm(income.cost, newVatRate)
                      );

                      change(
                        `${fields.name}[${index}].costTotal`,
                        totalCostForForm(income.cost, newVatRate)
                      );
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <Select
                          autoFocus
                          fill
                          options={vatRateOptions}
                          value={value}
                          onChange={onChange}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'costVatValue',
        label: 'НДС (руб.)',
        defaultWidth: 150,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ costVatValue }) => formatMoney(costVatValue),
            income: ({ income }) => formatMoney(income.costVatValue),
            total: ({ costVatValue }) => formatMoney(costVatValue),
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ costVatValue }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(costVatValue)}
              </Ellipsis>
            ),
            income: ({ income }) => (
              <Ellipsis component="span">
                {formatMoney(income.costVatValue)}
              </Ellipsis>
            ),
            total: ({ costVatValue }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(costVatValue)}
              </Ellipsis>
            ),
          }),
      },
      {
        id: 'costTotal',
        label: 'Стоимость (в т.ч. НДС)',
        defaultWidth: 150,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ costTotal }) => formatMoney(costTotal),
            income: ({ income }) => formatMoney(income.costTotal),
            total: ({ costTotal }) => formatMoney(costTotal),
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: ({ costTotal }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(costTotal)}
              </Ellipsis>
            ),
            income: ({ income }) => (
              <Ellipsis component="span">
                {formatMoney(income.costTotal)}
              </Ellipsis>
            ),
            total: ({ costTotal }) => (
              <Ellipsis className={css.boldCellText} component="span">
                {formatMoney(costTotal)}
              </Ellipsis>
            ),
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            income: ({ income, index }) =>
              income.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: income.costTotal,
                    applyValue: newCostTotal => {
                      change(
                        `${fields.name}[${index}].costTotal`,
                        newCostTotal
                      );

                      change(
                        `${fields.name}[${index}].costVatValue`,
                        vatValueFromTotalCostForForm(
                          newCostTotal,
                          income.vatRate
                        )
                      );

                      change(
                        `${fields.name}[${index}].cost`,
                        costFromTotalCostForForm(newCostTotal, income.vatRate)
                      );
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <InputMoney
                          autoFocus
                          value={value}
                          onChange={onChange}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'partner',
        label: 'Контрагент',
        defaultWidth: 270,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => '',
            income: ({ income }) => income.partner?.shortName ?? '',
            total: () => '',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => null,
            income: ({ income }) =>
              income.partner ? (
                <Ellipsis
                  component={Link}
                  params={{ id: String(income.partner.id) }}
                  rel="noopener"
                  target="_blank"
                  to="partners.edit"
                >
                  {income.partner.shortName}
                </Ellipsis>
              ) : null,
            total: () => null,
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            income: ({ income, index }) =>
              income.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: income.partner,
                    applyValue: newPartner => {
                      change(`${fields.name}[${index}].partner`, newPartner);
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <PartnersAutocompleteInFormGroup
                          autoFocus
                          initialItem={value ?? undefined}
                          noBottomMargin
                          value={value?.id ?? null}
                          onChange={(_newPartnerId, newPartner) => {
                            onChange(newPartner);
                          }}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
      {
        id: 'shipment',
        label: 'Отгрузка',
        defaultWidth: 270,
        renderCell: ({
          children,
          row,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={row.node.data.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => '',
            income: ({ income }) => income.shipment?.name ?? '',
            total: () => '',
          }),
        renderCellContent: node =>
          switchOverNodeKind(node.data, {
            year: () => null,
            income: ({ income }) =>
              income.shipment ? (
                <Ellipsis
                  component={Link}
                  params={{ id: String(income.shipment.id) }}
                  rel="noopener"
                  target="_blank"
                  to="shipmentInfo.edit"
                >
                  {income.shipment.name}
                </Ellipsis>
              ) : null,
            total: () => null,
          }),
        getEditor: node =>
          switchOverNodeKind(node.data, {
            year: () => undefined,
            income: ({ income, index }) =>
              income.isCalculated
                ? undefined
                : createTableCellEditor({
                    initialValue: income.shipment
                      ? serializeShipmentInfoListItem(income.shipment)
                      : null,
                    applyValue: newShipment => {
                      change(
                        `${fields.name}[${index}].shipment`,
                        newShipment
                          ? deserializeShipmentInfoListItem(newShipment)
                          : null
                      );
                    },
                    render: ({ style, value, onChange }) => (
                      <CellEditor style={style}>
                        <ShipmentInfoAutocompleteInFormGroup
                          autoFocus
                          initialItem={value ?? undefined}
                          noBottomMargin
                          value={value?.id ?? null}
                          onChange={(_newShipmentId, newShipment) => {
                            onChange(newShipment);
                          }}
                        />
                      </CellEditor>
                    ),
                  }),
            total: () => undefined,
          }),
      },
    ],
    [change, fields.name]
  );

  const createNodesOptions = useMemo(
    (): ICreateNodesOptions => ({
      incomes: fields.value,
      incomesCost,
      incomesCostTotal,
      incomesCostVatValue,
    }),
    [fields.value, incomesCost, incomesCostTotal, incomesCostVatValue]
  );

  const [nodes, setNodes] = useState<
    Array<TreeTableNode<IncomeTreeTableNodeData>>
  >(() => createNodes(null, createNodesOptions));

  const lastCreateNodesOptionsRef = useRef(createNodesOptions);

  if (createNodesOptions !== lastCreateNodesOptionsRef.current) {
    setNodes(createNodes(nodes, createNodesOptions));
    lastCreateNodesOptionsRef.current = createNodesOptions;
  }

  const [selectedNodes, setSelectedNodes] = useState<
    Array<TreeTableNode<IncomeTreeTableNodeData>>
  >([]);

  return (
    <>
      <H4>
        Доходы{' '}
        {everyNode(
          nodes,
          node => !node.children || node.children.isExpanded
        ) ? (
          <Button
            icon="collapse-all"
            minimal
            text="Свернуть всё"
            onClick={() => {
              setNodes(prevNodes => setAllNodesIsExpanded(prevNodes, false));
            }}
          />
        ) : (
          <Button
            icon="expand-all"
            minimal
            text="Развернуть всё"
            onClick={() => {
              setNodes(prevNodes => setAllNodesIsExpanded(prevNodes, true));
            }}
          />
        )}
      </H4>

      <VGrid>
        <TableFieldErrors
          fieldLabels={[
            ['name', 'Наименование'],
            ['days', 'Суток начислено'],
            ['cost', 'Стоимость'],
            ['vatRate', 'Ставка НДС'],
            ['costVatValue', 'НДС (руб.)'],
            ['costTotal', 'Стоимость (в т.ч. НДС)'],
            ['partner', 'Контрагент'],
            ['shipment', 'Отгрузка'],
          ]}
          rowsErrors={meta.error || meta.submitError}
        />

        <Row>
          <TreeTable
            columns={columns}
            isNodeSelectable={node => node.data.kind !== 'year'}
            maxHeight={500}
            nodes={nodes}
            overscanRowCount={20}
            selectedNodes={selectedNodes}
            stickyBottomRowCount={1}
            stickyColumnCount={1}
            onNodesChange={setNodes}
            onSelectedNodesChange={setSelectedNodes}
          />
        </Row>

        <Row>
          <Toolbar>
            <Button
              disabled={submitting}
              icon="add-row-bottom"
              intent={Intent.PRIMARY}
              text="Добавить доход"
              onClick={() => {
                fields.push({
                  cost: '',
                  costTotal: '',
                  costVatValue: '',
                  days: null,
                  isCalculated: false,
                  name: '',
                  partner: null,
                  shipment: null,
                  type: RentWagonPurchaseIncomeType.Custom,
                  vatRate: VatRate.None,
                  year: null,
                });
              }}
            />

            <Button
              disabled={
                submitting ||
                selectedNodes.length === 0 ||
                selectedNodes.some(
                  node =>
                    node.data.kind !== 'income' || node.data.income.isCalculated
                )
              }
              icon="remove"
              intent={Intent.DANGER}
              text="Удалить выбранные доходы"
              onClick={() => {
                selectedNodes
                  .filter(
                    (
                      node
                    ): node is TreeTableNode<IncomeTreeTableNodeIncomeData> =>
                      node.data.kind === 'income'
                  )
                  .map(node => node.data.index)
                  .sort((a, b) => b - a)
                  .forEach(rowIndex => {
                    fields.remove(rowIndex);
                  });

                setSelectedNodes([]);
              }}
            />
          </Toolbar>
        </Row>
      </VGrid>
    </>
  );
}

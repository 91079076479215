export function indexByAllItems<TKey extends string | number | symbol, TItem>(
  items: TItem[],
  getKey: (item: TItem) => TKey | null
): Record<TKey, TItem[]>;
export function indexByAllItems<
  TKey extends string | number | symbol,
  TItem,
  TIndexedItem
>(
  items: TItem[],
  getKey: (item: TItem) => TKey | null,
  getIndexedItem: (item: TItem, index: number) => TIndexedItem
): Record<TKey, TIndexedItem[]>;
export function indexByAllItems<
  TKey extends string | number | symbol,
  TItem,
  TIndexedItem
>(
  items: TItem[],
  getKey: (item: TItem) => TKey | null,
  getIndexedItem: (item: TItem, index: number) => TIndexedItem = item =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item as any
) {
  return items.reduce(
    (result, item, index) => {
      const key = getKey(item);

      if (key != null) {
        result[key] = result[key] || [];
        result[key].push(getIndexedItem(item, index));
      }

      return result;
    },

    {} as Record<TKey, TIndexedItem[]>
  );
}

export function indexByLastItem<TKey extends string | number | symbol, TItem>(
  items: TItem[],
  getKey: (item: TItem) => TKey | null
) {
  return items.reduce(
    (result, item) => {
      const key = getKey(item);

      if (key != null) {
        result[key] = item;
      }

      return result;
    },

    {} as Record<TKey, TItem>
  );
}

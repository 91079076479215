import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import cx from 'classnames';
import * as React from 'react';

import * as css from './link.module.css';

interface IProps {
  children: string;
  className?: string;
  params?: Record<string, unknown>;
  parentCellHeight?: number;
  rel?: string;
  target?: string;
  to: string;
  onClick?: (event: React.MouseEvent<unknown>) => void;
}

export function LinkCell({
  className,
  parentCellHeight,
  ...otherProps
}: IProps) {
  return (
    <Ellipsis
      {...otherProps}
      className={cx(className, css.root)}
      component={Link}
      style={{ height: parentCellHeight }}
    />
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import dayjs from 'dayjs';

export enum TechrunGu12Type {
  Export = 'EXPORT',
  Internal = 'INTERNAL',
  Letter = 'LETTER',
}

export interface ITechrunGu12ItemSerialized {
  arrivalStation: number;
  arrivalStationName: string;
  consigner: number | null;
  departureStation: number;
  departureStationName: string;
  endDate: string | null;
  id: number;
  number: string;
  owners: number[];
  startDate: string | null;
  transportations: number[];
  type: TechrunGu12Type;
  wagonsLeft: number | null;
  wagonsQuantity: number | null;
}

export interface ITechrunGu12Item
  extends Omit<ITechrunGu12ItemSerialized, 'endDate' | 'startDate'> {
  endDate: Date | null;
  startDate: Date | null;
}

function deserializeTechrunGu12Item({
  endDate,
  startDate,
  ...otherProps
}: ITechrunGu12ItemSerialized): ITechrunGu12Item {
  return {
    ...otherProps,
    endDate: endDate == null ? null : parseDate(endDate),
    startDate: startDate == null ? null : parseDate(startDate),
  };
}

interface IFetchTechrunGu12Params {
  arrivalStation?: number;
  departureStation?: number;
  includeFull?: boolean;
  page?: number;
  pageSize?: number;
}

export async function fetchTechrunGu12Items(
  api: ApiClient,
  {
    arrivalStation,
    departureStation,
    includeFull,
    page,
    pageSize,
  }: IFetchTechrunGu12Params
): Promise<ListResponse<ITechrunGu12Item>> {
  const listResponse = await api.get<ListResponse<ITechrunGu12ItemSerialized>>(
    '/techrun_gu12',
    {
      arrivalStation,
      departureStation,
      includeFull,
      page,
      pageSize,
    }
  );

  return {
    ...listResponse,
    results: listResponse.results.map(deserializeTechrunGu12Item),
  };
}

export async function fetchTechrunGu12Item(api: ApiClient, id: number) {
  const gu12Item = await api.get<ITechrunGu12ItemSerialized>(
    `/techrun_gu12/${id}`
  );

  return deserializeTechrunGu12Item(gu12Item);
}

interface ICreateOrUpdateTechrunGu12ItemParams {
  owners: Array<number | null>;
  arrivalStation: number | null;
  consigner: number | null;
  departureStation: number | null;
  endDate: Date | null;
  number: string;
  startDate: Date | null;
  transportations: number[];
  type: TechrunGu12Type;
  wagonsLeft: number | null;
  wagonsQuantity: number | null;
}

function serializeCreateOrUpdateTechrunGu12ItemParams({
  endDate,
  startDate,
  ...otherProps
}: ICreateOrUpdateTechrunGu12ItemParams) {
  return {
    ...otherProps,
    endDate:
      endDate == null ? null : dayjs(endDate).format(DATE_FORMAT_API_DATE),
    startDate:
      startDate == null ? null : dayjs(startDate).format(DATE_FORMAT_API_DATE),
  };
}

export async function createTechrunGu12Item(
  api: ApiClient,
  params: ICreateOrUpdateTechrunGu12ItemParams
) {
  const createdGu12Item = await api.post<ITechrunGu12ItemSerialized>(
    '/techrun_gu12',
    serializeCreateOrUpdateTechrunGu12ItemParams(params)
  );

  return deserializeTechrunGu12Item(createdGu12Item);
}

export async function updateTechrunGu12Item(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateTechrunGu12ItemParams
) {
  const updatedGu12Item = await api.put<ITechrunGu12ItemSerialized>(
    `/techrun_gu12/${id}`,
    params
  );

  return deserializeTechrunGu12Item(updatedGu12Item);
}

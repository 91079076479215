import {
  ExpeditionTransportationAccountingState,
  ExpeditionTransportationGroup,
  ExpeditionTransportationState,
} from 'expeditionTransportations/types';
import {
  TechrunTransportationAccountingState,
  TechrunTransportationGroup,
  TechrunTransportationState,
} from 'techrunTransportations/types';
import { VatRate } from 'vatRates/vatRates';

export const getAccountingStateLabel = (
  state:
    | ExpeditionTransportationAccountingState
    | TechrunTransportationAccountingState
) =>
  ({
    [TechrunTransportationAccountingState.NotClosed]: 'Не закрыта',
    [ExpeditionTransportationAccountingState.WaitCostClose]:
      'Ждёт закрытия услуги',
    [ExpeditionTransportationAccountingState.WaitTariffClose]:
      'Ждёт выставления тарифа',
    [TechrunTransportationAccountingState.Closed]: 'Закрыта',
    [TechrunTransportationAccountingState.BuhClosed]:
      'Закрыта бух. документами',
  }[state]);

export const accountingStateOptions = [
  TechrunTransportationAccountingState.NotClosed,
  TechrunTransportationAccountingState.Closed,
  TechrunTransportationAccountingState.BuhClosed,
].map(state => ({
  label: getAccountingStateLabel(state),
  value: state,
}));

export const transportationStatusFromGroup = (
  group:
    | ExpeditionTransportationGroup
    | TechrunTransportationGroup = TechrunTransportationGroup.New
) => group.toLowerCase();

export const getTransportationGroupLabel = (
  group: ExpeditionTransportationGroup | TechrunTransportationGroup
) =>
  ({
    [TechrunTransportationGroup.Confirmed]: 'Подтверждённые',
    [TechrunTransportationGroup.New]: 'Новые',
    [TechrunTransportationGroup.Rejected]: 'Отклонённые',
    [TechrunTransportationGroup.Supplementary]: 'Служебные',
  }[group]);

export const getTransportationStateLabel = (
  state: ExpeditionTransportationState | TechrunTransportationState
) =>
  ({
    [TechrunTransportationState.Wait]: 'Подход',
    [TechrunTransportationState.Load]: 'Погрузка',
    [TechrunTransportationState.OnWay]: 'В пути',
    [TechrunTransportationState.Arrived]: 'Прибыл',
    [TechrunTransportationState.Finished]: 'Завершена',
  }[state]);

export const transportationStateOptions = [
  TechrunTransportationState.Wait,
  TechrunTransportationState.Load,
  TechrunTransportationState.OnWay,
  TechrunTransportationState.Arrived,
  TechrunTransportationState.Finished,
].map(state => ({
  label: getTransportationStateLabel(state),
  value: state,
}));

export interface ITransportationExpense {
  cost: string;
  costTotal: string;
  costVatValue: string;
  meta: 'rate' | 'supplier_expense' | 'tariff' | undefined;
  name: string;
  partner: number | null;
  supplierDocument: number | null;
  vatRate: VatRate;
}

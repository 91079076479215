import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import {
  DATE_FORMAT_API_DATE,
  DATE_FORMAT_API_DATETIME,
} from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import dayjs from 'dayjs';
import { IParkSerialized } from 'parks/api';
import { IPartnerInline, IPartnerSerialized } from 'partners/api';

export const WAGONS_BASE_ENDPOINT = '/wagons';

export interface IDislocationSerialized {
  arrivalDatetime: string | null;
  arrivalDatetimeCalculated: string | null;
  arrivalRoadMnemo: string;
  arrivalStation: number;
  arrivalStationCode: string;
  arrivalStationName: string;
  cargoCode: string;
  cargoName: string;
  cargoWeight: string;
  consigneeCode: string;
  consigneeOkpoCode: string;
  consigneeOkpoName: string;
  consignerCode: string;
  consignerOkpoCode: string;
  consignerOkpoName: string;
  created: string;
  dataFormationDatetime: string;
  datePk: number;
  defect: string;
  deliveryDate: string | null;
  deliveryDateOverdue: number | null;
  departureDatetime: string | null;
  departureRoadMnemo: string;
  departureStation: number;
  departureStationCode: string;
  departureStationName: string;
  distanceToArrivalStation: number;
  id: number;
  modified: string;
  nrp: string;
  nrpSign: string;
  operationCode: string;
  operationDatetime: string;
  operationDowntime: string;
  operationMnemo: string;
  operationName: string;
  operationRoadMnemo: string;
  operationStation: number;
  operationStationCode: string;
  operationStationName: string;
  ownerCountryName: string;
  ownerOkpoCode: string;
  ownerOkpoName: string;
  parkTypeCode: string;
  parkTypeName: string;
  payerCode: string;
  renterOkpoCode: string;
  renterOkpoName: string;
  repairDate: string;
  repairTypeName: string;
  stationDowntime: string;
  tariffRub: string;
  trainIndex: string;
  trainNumber: string;
  travelTime: string;
  unloadDatetime: string | null;
  wagonCapacity: number;
  wagonMileage: null;
  wagonModel: string;
  wagonTara: number;
  wagonTypeMnemo: string;
  waybillNumber: string;
  waybillTypeCode: string;
  waybillTypeName: string;
}

export enum WagonAlert {
  NonWorkingPark = 'nonworking_park',
  OperationBros = 'operation_bros',
  RepairDate = 'repair_date',
  RepairDate2 = 'repair_date_2',
  StationDowntime = 'station_downtime',
  WagonMileage = 'wagon_mileage',
  DeliveryDateOverdue = 'delivery_date_overdue',
  CargoOperationIsOver = 'cargo_operation_is_over',
}

export function isWagonAlert(str: string): str is WagonAlert {
  return Object.values(WagonAlert).includes(str as WagonAlert);
}

export interface IWagonSerialized {
  alerts: WagonAlert[];
  capacity: number | null;
  capacityModified: string | null;
  capacityUser: number | null;
  created: string;
  lastEtranNote: string;
  lastEtranNoteDate: string | null;
  hasRecentNoteFiles: boolean;
  lastNote: string;
  lastRzdAppealNote: string;
  lastRzdAppealNoteDate: string | null;
  lastCargoOperationOverNote: string;
  lastCargoOperationOverNoteDate: string | null;
  lastDeliveryOverdueNote: string;
  lastDeliveryOverdueNoteDate: string | null;
  lastNoteDate: string | null;
  cargoOperationIsOver: boolean;
  cargoOperationIsOverDate: string | null;
  latestDislocation: IDislocationSerialized | null;
  managerNote: string;
  managerNoteModified: string | null;
  managerNoteUser: number | null;
  managerNote2: string;
  managerNote2Modified: string | null;
  managerNote2User: number | null;
  managerNote3: string;
  managerNote3Modified: string | null;
  managerNote3User: number | null;
  model: string | null;
  modelModified: string | null;
  modelUser: number | null;
  number: string;
  owner: IPartnerInline | null;
  parks: IParkSerialized[];
  purchasePartner: IPartnerSerialized;
  requestPartner: IPartnerSerialized | null;
  selectedDislocation: IDislocationSerialized;
  tara: number | null;
  taraModified: string | null;
  taraUser: number | null;
}

export enum WagonsSortingField {
  Created = 'created',
  DistanceToArrivalStation = 'distance_to_arrival_station',
  LastEtranNoteDate = 'last_etran_note_date',
  LastNoteDate = 'last_note_date',
  LastCargoOperationOverNoteDate = 'cargo_operation_over_note_date',
  LastDeliveryOverdueNoteDate = 'delivery_overdue_note_date',
  LastRzdAppealNoteDate = 'rzd_appeal_note_date',
  LatestDislocationArrivalDatetime = 'latest_dislocation__arrival_datetime',
  LatestDislocationArrivalDatetimeCalculated = 'latest_dislocation__arrival_datetime_calculated',
  LatestDislocationDepartureDatetime = 'latest_dislocation__departure_datetime',
  LatestDislocationOperationDatetime = 'latest_dislocation__operation_datetime',
  LatestDislocationOperationDowntime = 'latest_dislocation__operation_downtime',
  LatestDislocationRepairDate = 'latest_dislocation__repair_date',
  LatestDislocationWagonMileage = 'latest_dislocation__wagon_mileage',
  ManagerNote2Modified = 'manager_note2_modified',
  ManagerNote3Modified = 'manager_note3_modified',
  ManagerNoteModified = 'manager_note_modified',
  Number = 'number',
  NumbersFilter = 'numbers_list',
  DeliveryDateOverdue = 'delivery_date_overdue',
}

function serializeWagonsSorting(
  sorting: Array<ISorting<WagonsSortingField>> | undefined
): string | number | boolean | null | undefined {
  return sorting && sortingToApiQueryParam(sorting);
}

export async function fetchWagons(
  api: ApiClient,
  {
    addedToPark,
    alerts,
    arrivalStation,
    consignee,
    currentParkWagons,
    departureStation,
    dislocationDatetime,
    hasEtranNote,
    numbers,
    operationStation,
    operationStationNot,
    owner,
    page,
    pageSize,
    park,
    parks,
    search,
    sorting,
  }: {
    addedToPark?: boolean;
    alerts?: WagonAlert[];
    arrivalStation?: number;
    consignee?: number;
    currentParkWagons?: boolean;
    departureStation?: number;
    dislocationDatetime?: Date;
    hasEtranNote?: boolean;
    numbers?: string[];
    operationStation?: number;
    operationStationNot?: number;
    owner?: number;
    page?: number;
    pageSize?: number;
    park?: number;
    parks?: number[];
    search?: string;
    sorting?: Array<ISorting<WagonsSortingField>>;
  } = {}
) {
  const response = await api.get<ListResponse<IWagonSerialized>>(
    WAGONS_BASE_ENDPOINT,
    {
      addedToPark,
      alerts: alerts && alerts.length !== 0 ? alerts.join(',') : undefined,
      arrivalStation,
      consignee,
      currentParkWagons,
      departureStation,
      dislocationDatetime:
        dislocationDatetime == null
          ? undefined
          : dayjs(dislocationDatetime).format(DATE_FORMAT_API_DATETIME),
      hasEtranNote,
      numbers: numbers && numbers.length !== 0 ? numbers.join(',') : undefined,
      operationStation,
      operationStationNot,
      ordering: serializeWagonsSorting(sorting),
      owner,
      page,
      pageSize,
      park,
      parks: parks && parks.length !== 0 ? parks.join(',') : undefined,
      search,
    }
  );

  return response;
}

export const fetchManyWagons = makeFetchMany(fetchWagons, 'numbers');

export async function updateWagons(
  api: ApiClient,
  wagons: string[],
  {
    capacity,
    etranNote,
    managerNote,
    managerNote2,
    managerNote3,
    model,
    owner,
    tara,
  }: Partial<{
    capacity: string;
    etranNote: string;
    managerNote: string;
    managerNote2: string;
    managerNote3: string;
    model: string;
    owner: number | null;
    tara: string;
  }>
) {
  const updatedWagons = await api.put<IWagonSerialized[]>(
    WAGONS_BASE_ENDPOINT,
    {
      capacity,
      etranNote,
      managerNote,
      managerNote2,
      managerNote3,
      model,
      owner,
      tara,
    },
    { query: { numbers: wagons.join(',') } }
  );

  return updatedWagons;
}

export async function updateWagon(
  api: ApiClient,
  wagon: string,
  {
    etranNote,
    managerNote,
    managerNote2,
    managerNote3,
    owner,
  }: {
    etranNote: string;
    managerNote: string;
    managerNote2: string;
    managerNote3: string;
    owner: number | null;
  }
) {
  const updatedWagon = await api.put<IWagonSerialized>(
    `${WAGONS_BASE_ENDPOINT}/${wagon}`,
    {
      etranNote,
      managerNote,
      managerNote2,
      managerNote3,
      owner,
    }
  );

  return updatedWagon;
}

export async function downloadWagonsXlsx(
  api: ApiClient,
  {
    addedToPark,
    alerts,
    arrivalStation,
    consignee,
    departureStation,
    dislocationDatetime,
    hasEtranNote,
    numbers,
    operationStation,
    operationStationNot,
    owner,
    page,
    pageSize,
    parks,
    search,
    sorting,
  }: {
    addedToPark?: boolean;
    alerts?: WagonAlert[];
    arrivalStation?: number;
    consignee?: number;
    departureStation?: number;
    dislocationDatetime?: Date;
    hasEtranNote?: boolean;
    numbers?: string[];
    operationStation?: number;
    operationStationNot?: number;
    owner?: number;
    page?: number;
    pageSize?: number;
    parks?: number[];
    search?: string;
    sorting?: Array<ISorting<WagonsSortingField>>;
  } = {}
) {
  const response = await api.get<{ taskId: string }>(
    `${WAGONS_BASE_ENDPOINT}/download_xlsx`,
    {
      addedToPark,
      alerts: alerts && alerts.length !== 0 ? alerts.join(',') : undefined,
      arrivalStation,
      consignee,
      departureStation,
      dislocationDatetime:
        dislocationDatetime == null
          ? undefined
          : dayjs(dislocationDatetime).format(DATE_FORMAT_API_DATETIME),
      hasEtranNote,
      numbers: numbers && numbers.length !== 0 ? numbers.join(',') : undefined,
      operationStation,
      operationStationNot,
      ordering: serializeWagonsSorting(sorting),
      owner,
      page,
      pageSize,
      parks: parks && parks.length !== 0 ? parks.join(',') : undefined,
      search,
    }
  );

  return response;
}

export function downloadWagonNotesXlsx(
  api: ApiClient,
  {
    addedToPark,
    alerts,
    arrivalStation,
    consignee,
    departureStation,
    dislocationDatetime,
    notesFromDatetime,
    hasEtranNote,
    numbers,
    operationStation,
    operationStationNot,
    owner,
    page,
    pageSize,
    parks,
    search,
    sorting,
    notesToDatetime,
  }: {
    addedToPark?: boolean;
    alerts?: WagonAlert[];
    arrivalStation?: number;
    consignee?: number;
    departureStation?: number;
    dislocationDatetime?: Date;
    notesFromDatetime?: Date | undefined;
    hasEtranNote?: boolean;
    numbers?: string[];
    operationStation?: number;
    operationStationNot?: number;
    owner?: number;
    page?: number;
    pageSize?: number;
    parks?: number[];
    search?: string;
    sorting?: Array<ISorting<WagonsSortingField>>;
    notesToDatetime?: Date | undefined;
  } = {}
) {
  return api.get<{ taskId: string }>(
    `${WAGONS_BASE_ENDPOINT}/download_notes_xlsx`,
    {
      addedToPark,
      alerts: alerts && alerts.length !== 0 ? alerts.join(',') : undefined,
      arrivalStation,
      consignee,
      departureStation,
      dislocationDatetime:
        dislocationDatetime == null
          ? undefined
          : dayjs(dislocationDatetime).format(DATE_FORMAT_API_DATETIME),
      notesFromDatetime:
        notesFromDatetime &&
        dayjs(notesFromDatetime).format(DATE_FORMAT_API_DATETIME),
      hasEtranNote,
      numbers: numbers && numbers.length !== 0 ? numbers.join(',') : undefined,
      operationStation,
      operationStationNot,
      ordering: serializeWagonsSorting(sorting),
      owner,
      page,
      pageSize,
      parks: parks && parks.length !== 0 ? parks.join(',') : undefined,
      search,
      notesToDatetime:
        notesToDatetime &&
        dayjs(notesToDatetime).format(DATE_FORMAT_API_DATETIME),
    }
  );
}

export async function addNotesToWagons(
  api: ApiClient,
  wagons: string[],
  {
    etranNote,
    note,
    rzdAppealNote,
    cargoOverNote,
    deliveryOverdueNote,
  }: {
    etranNote: string;
    note: string;
    rzdAppealNote: string;
    cargoOverNote: string;
    deliveryOverdueNote: string;
  }
) {
  const response = await api.post<IWagonSerialized[]>(
    `${WAGONS_BASE_ENDPOINT}/add_notes`,
    {
      etranNote,
      note,
      wagons,
      rzdAppealNote,
      cargoOverNote,
      deliveryOverdueNote,
    }
  );

  return response;
}

export enum WagonsTrackingStatsRecordType {
  Expedition = 'EXP',
  ExpeditionPartner = 'EXP_PARTNER',
  ExpeditionTotal = 'EXP_TOTAL',
  GrandTotal = 'GRAND_TOTAL',
  Park = 'PARK',
  ParkTotal = 'PARK_TOTAL',
  Rent = 'RENT',
  RentPartner = 'RENT_PARTNER',
  RentTotal = 'RENT_TOTAL',
  Techrun = 'TECH',
  TechrunPartner = 'TECH_PARTNER',
  TechrunTotal = 'TECH_TOTAL',
}

interface IWagonsTrackingStatsWagonCountSerialized {
  count: number | null;
  date: string;
}

export interface IWagonsTrackingStatsWagonCount {
  count: number | null;
  date: Date;
}

function deserializeWagonsTrackingStatsWagonCount({
  date,
  ...otherProps
}: IWagonsTrackingStatsWagonCountSerialized): IWagonsTrackingStatsWagonCount {
  return {
    ...otherProps,
    date: parseDate(date),
  };
}

interface IWagonsTrackingStatsExpeditionRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.Expedition;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
  expeditionRequestId: number;
  partnerId: number;
}

export interface IWagonsTrackingStatsExpeditionRecord
  extends Omit<IWagonsTrackingStatsExpeditionRecordSerialized, 'wagonCounts'> {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsExpeditionPartnerRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.ExpeditionPartner;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
  partnerId: number;
}

export interface IWagonsTrackingStatsExpeditionPartnerRecord
  extends Omit<
    IWagonsTrackingStatsExpeditionPartnerRecordSerialized,
    'wagonCounts'
  > {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsExpeditionTotalRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.ExpeditionTotal;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
}

export interface IWagonsTrackingStatsExpeditionTotalRecord
  extends Omit<
    IWagonsTrackingStatsExpeditionTotalRecordSerialized,
    'wagonCounts'
  > {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsGrandTotalRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.GrandTotal;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
}

export interface IWagonsTrackingStatsGrandTotalRecord
  extends Omit<IWagonsTrackingStatsGrandTotalRecordSerialized, 'wagonCounts'> {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsParkRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.Park;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
  parkId: number;
}

export interface IWagonsTrackingStatsParkRecord
  extends Omit<IWagonsTrackingStatsParkRecordSerialized, 'wagonCounts'> {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsParkTotalRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.ParkTotal;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
}

export interface IWagonsTrackingStatsParkTotalRecord
  extends Omit<IWagonsTrackingStatsParkTotalRecordSerialized, 'wagonCounts'> {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsRentPartnerRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.RentPartner;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
  partnerId: number;
}

export interface IWagonsTrackingStatsRentPartnerRecord
  extends Omit<IWagonsTrackingStatsRentPartnerRecordSerialized, 'wagonCounts'> {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsRentPurchaseRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.Rent;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
  partnerId: number;
  purchaseRentContractId: number;
  purchaseRentProtocolId: number;
  sellRentContractId: null;
  sellRentProtocolId: null;
}

export interface IWagonsTrackingStatsRentPurchaseRecord
  extends Omit<
    IWagonsTrackingStatsRentPurchaseRecordSerialized,
    'wagonCounts'
  > {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsRentSellRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.Rent;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
  partnerId: number;
  purchaseRentContractId: null;
  purchaseRentProtocolId: null;
  sellRentContractId: number;
  sellRentProtocolId: number;
}

export interface IWagonsTrackingStatsRentSellRecord
  extends Omit<IWagonsTrackingStatsRentSellRecordSerialized, 'wagonCounts'> {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsRentTotalRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.RentTotal;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
}

export interface IWagonsTrackingStatsRentTotalRecord
  extends Omit<IWagonsTrackingStatsRentTotalRecordSerialized, 'wagonCounts'> {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsTechrunRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.Techrun;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
  partnerId: number;
  techrunRequestId: number;
}

export interface IWagonsTrackingStatsTechrunRecord
  extends Omit<IWagonsTrackingStatsTechrunRecordSerialized, 'wagonCounts'> {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsTechrunPartnerRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.TechrunPartner;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
  partnerId: number;
}

export interface IWagonsTrackingStatsTechrunPartnerRecord
  extends Omit<
    IWagonsTrackingStatsTechrunPartnerRecordSerialized,
    'wagonCounts'
  > {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

interface IWagonsTrackingStatsTechrunTotalRecordSerialized {
  recordType: WagonsTrackingStatsRecordType.TechrunTotal;
  displayName: string;
  wagonCounts: IWagonsTrackingStatsWagonCountSerialized[];
}

export interface IWagonsTrackingStatsTechrunTotalRecord
  extends Omit<
    IWagonsTrackingStatsTechrunTotalRecordSerialized,
    'wagonCounts'
  > {
  wagonCounts: IWagonsTrackingStatsWagonCount[];
}

type WagonsTrackingStatsRecordSerialized =
  | IWagonsTrackingStatsExpeditionRecordSerialized
  | IWagonsTrackingStatsExpeditionPartnerRecordSerialized
  | IWagonsTrackingStatsExpeditionTotalRecordSerialized
  | IWagonsTrackingStatsGrandTotalRecordSerialized
  | IWagonsTrackingStatsParkRecordSerialized
  | IWagonsTrackingStatsParkTotalRecordSerialized
  | IWagonsTrackingStatsRentPartnerRecordSerialized
  | IWagonsTrackingStatsRentPurchaseRecordSerialized
  | IWagonsTrackingStatsRentSellRecordSerialized
  | IWagonsTrackingStatsRentTotalRecordSerialized
  | IWagonsTrackingStatsTechrunRecordSerialized
  | IWagonsTrackingStatsTechrunPartnerRecordSerialized
  | IWagonsTrackingStatsTechrunTotalRecordSerialized;

export type WagonsTrackingStatsRecord =
  | IWagonsTrackingStatsExpeditionRecord
  | IWagonsTrackingStatsExpeditionPartnerRecord
  | IWagonsTrackingStatsExpeditionTotalRecord
  | IWagonsTrackingStatsGrandTotalRecord
  | IWagonsTrackingStatsParkRecord
  | IWagonsTrackingStatsParkTotalRecord
  | IWagonsTrackingStatsRentPartnerRecord
  | IWagonsTrackingStatsRentPurchaseRecord
  | IWagonsTrackingStatsRentSellRecord
  | IWagonsTrackingStatsRentTotalRecord
  | IWagonsTrackingStatsTechrunRecord
  | IWagonsTrackingStatsTechrunPartnerRecord
  | IWagonsTrackingStatsTechrunTotalRecord;

function deserializeWagonsTrackingStatsRecord({
  wagonCounts,
  ...otherProps
}: WagonsTrackingStatsRecordSerialized): WagonsTrackingStatsRecord {
  return {
    ...otherProps,
    wagonCounts: wagonCounts.map(deserializeWagonsTrackingStatsWagonCount),
  };
}

interface IFetchWagonsTrackingStatsParams {
  fromDate?: Date;
  toDate?: Date;
}

interface IFetchWagonsTrackingStatsResponseSerialized {
  meta: { dateRange: [string, string] };
  results: WagonsTrackingStatsRecordSerialized[];
}

interface IFetchWagonsTrackingStatsResponse {
  meta: { dateRange: [Date, Date] };
  results: WagonsTrackingStatsRecord[];
}

export async function fetchWagonsTrackingStats(
  api: ApiClient,
  { toDate, fromDate }: IFetchWagonsTrackingStatsParams = {}
): Promise<IFetchWagonsTrackingStatsResponse> {
  const response = await api.get<IFetchWagonsTrackingStatsResponseSerialized>(
    `${WAGONS_BASE_ENDPOINT}/wagon_tracking_stats`,
    {
      fromDate:
        fromDate == null
          ? undefined
          : dayjs(fromDate).format(DATE_FORMAT_API_DATE),
      toDate:
        toDate == null ? undefined : dayjs(toDate).format(DATE_FORMAT_API_DATE),
    }
  );

  return {
    meta: {
      dateRange: [
        parseDate(response.meta.dateRange[0]),
        parseDate(response.meta.dateRange[1]),
      ],
    },

    results: response.results.map(deserializeWagonsTrackingStatsRecord),
  };
}

interface IFetchWagonDislocationsParams {
  dislocationDatetime?: Date;
  fromDatetime?: Date;
  page?: number;
  pageSize?: number;
  toDatetime?: Date;
}

export async function fetchWagonDislocations(
  api: ApiClient,
  wagon: string,
  {
    dislocationDatetime,
    fromDatetime,
    page,
    pageSize,
    toDatetime,
  }: IFetchWagonDislocationsParams = {}
) {
  const listResponse = await api.get<ListResponse<IDislocationSerialized>>(
    `${WAGONS_BASE_ENDPOINT}/${wagon}/dislocations`,
    {
      dislocationDatetime:
        dislocationDatetime == null
          ? undefined
          : dayjs(dislocationDatetime).format(DATE_FORMAT_API_DATETIME),
      fromDatetime:
        fromDatetime == null
          ? undefined
          : dayjs(fromDatetime).format(DATE_FORMAT_API_DATETIME),
      page,
      pageSize,
      toDatetime:
        toDatetime == null
          ? undefined
          : dayjs(toDatetime).format(DATE_FORMAT_API_DATETIME),
    }
  );

  return listResponse;
}

interface IWagonParksHistoryItemSerialized {
  inPark: boolean;
  parkId: number;
  parkName: string;
  periods: Array<[string, string]>;
}

export interface IWagonParksHistoryItem
  extends Omit<IWagonParksHistoryItemSerialized, 'periods'> {
  periods: Array<[Date, Date]>;
}

export class WagonParksHistoryItem implements IWagonParksHistoryItem {
  readonly inPark: boolean;
  readonly parkId: number;
  readonly parkName: string;
  readonly periods: Array<[Date, Date]>;

  static fromJSON({
    periods,
    ...otherProps
  }: IWagonParksHistoryItemSerialized) {
    return new WagonParksHistoryItem({
      ...otherProps,
      periods: periods.map(([startDate, endDate]) => [
        parseDate(startDate),
        parseDate(endDate),
      ]),
    });
  }

  constructor(historyItem: IWagonParksHistoryItem) {
    this.inPark = historyItem.inPark;
    this.parkId = historyItem.parkId;
    this.parkName = historyItem.parkName;
    this.periods = historyItem.periods;
  }
}

export async function fetchWagonParksHistory(api: ApiClient, wagon: string) {
  const historyItems = await api.get<IWagonParksHistoryItemSerialized[]>(
    `${WAGONS_BASE_ENDPOINT}/${wagon}/parks_history`
  );

  return historyItems.map(WagonParksHistoryItem.fromJSON);
}

import { Route } from 'router5';

import { wagonArrivalsGroupParamValues } from './wagonArrivals/list';

export const routes: Route[] = [
  { name: 'testSentry', path: '/____test-sentry____' },
  { name: 'consigners', path: '/consigners' },
  { name: 'consigners.create', path: '/create' },
  { name: 'consigners.edit', path: '/edit/:id' },

  { name: 'contacts', path: '/contacts' },
  { name: 'contacts.create', path: '/create' },
  { name: 'contacts.edit', path: '/edit/:id' },

  { name: 'contracts', path: '/contracts' },

  { name: 'contracts.purchase', path: '/purchase' },
  { name: 'contracts.purchase.create', path: '/create' },
  {
    name: 'contracts.purchase.view',
    path: '/view/:id',
    forwardTo: 'contracts.purchase.view.main',
  },
  { name: 'contracts.purchase.view.main', path: '/main' },

  {
    name: 'contracts.purchase.view.techrunProtocols',
    path: '/techrun-protocols?showArchived',
  },

  {
    name: 'contracts.purchase.view.techrunProtocols.create',
    path: '/create',
  },

  {
    name: 'contracts.purchase.view.techrunProtocols.view',
    path: '/:protocolId',
  },

  { name: 'contracts.purchase.view.rentProtocols', path: '/rent-protocols' },
  { name: 'contracts.purchase.view.rentProtocols.create', path: '/create' },
  {
    name: 'contracts.purchase.view.rentProtocols.view',
    path: '/:protocolId',
  },

  { name: 'contracts.purchase.view.agreements', path: '/agreements' },
  { name: 'contracts.purchase.view.agreements.create', path: '/create' },
  { name: 'contracts.purchase.view.agreements.view', path: '/:agreementId' },

  { name: 'contracts.sell', path: '/sell' },
  { name: 'contracts.sell.create', path: '/create' },
  {
    name: 'contracts.sell.view',
    path: '/view/:id',
    forwardTo: 'contracts.sell.view.main',
  },
  { name: 'contracts.sell.view.main', path: '/main' },

  {
    name: 'contracts.sell.view.techrunProtocols',
    path: '/techrun-protocols?showArchived',
  },
  { name: 'contracts.sell.view.techrunProtocols.create', path: '/create' },
  { name: 'contracts.sell.view.techrunProtocols.view', path: '/:protocolId' },

  {
    name: 'contracts.sell.view.expeditionProtocols',
    path: '/expedition-protocols?showArchived',
  },

  { name: 'contracts.sell.view.expeditionProtocols.create', path: '/create' },

  {
    name: 'contracts.sell.view.expeditionProtocols.view',
    path: '/:protocolId',
  },

  { name: 'contracts.sell.view.rentProtocols', path: '/rent-protocols' },
  { name: 'contracts.sell.view.rentProtocols.create', path: '/create' },
  { name: 'contracts.sell.view.rentProtocols.view', path: '/:protocolId' },

  {
    name: 'contracts.sell.view.repairsProtocols',
    path: '/repairs-protocols',
  },
  { name: 'contracts.sell.view.repairsProtocols.create', path: '/create' },
  { name: 'contracts.sell.view.repairsProtocols.view', path: '/:protocolId' },

  { name: 'contracts.sell.view.agreements', path: '/agreements' },
  { name: 'contracts.sell.view.agreements.create', path: '/create' },
  { name: 'contracts.sell.view.agreements.view', path: '/:agreementId' },

  { name: 'downtimes', path: '/downtimes' },
  { name: 'downtimes.edit', path: '/edit/:id' },
  { name: 'downtimes.parks', path: '/parks' },

  { name: 'expeditions', path: '/expeditions' },
  { name: 'expeditions.requests', path: '/requests' },
  { name: 'expeditions.requests.create', path: '/create' },
  {
    name: 'expeditions.requests.view',
    path: '/view/:id',
    forwardTo: 'expeditions.requests.view.main',
  },
  { name: 'expeditions.requests.view.main', path: '/main' },
  { name: 'expeditions.requests.view.parks', path: '/parks' },
  {
    name: 'expeditions.requests.view.wagonsDailyUsage',
    path: '/wagons-daily-usage',
  },
  { name: 'expeditions.transportations', path: '/transportations/:status' },
  { name: 'expeditions.transportations.edit', path: '/edit/:id' },

  { name: 'home', path: '/' },

  { name: 'invoices', path: '/invoices' },
  { name: 'invoices.create', path: '/create' },
  { name: 'invoices.edit', path: '/edit/:id' },

  { name: 'login', path: '/login' },

  { name: 'ourDocuments', path: '/our-documents' },

  { name: 'ourRepairs', path: '/our-repairs' },
  { name: 'ourRepairs.create', path: '/create' },
  { name: 'ourRepairs.edit', path: '/edit/:id' },

  { name: 'parks', path: '/parks' },
  { name: 'parks.create', path: '/create' },
  {
    name: 'parks.edit',
    path: '/edit/:id',
    forwardTo: 'parks.edit.dislocations',
  },
  { name: 'parks.edit.main', path: '/main' },
  { name: 'parks.edit.mailing', path: '/mailing' },
  { name: 'parks.edit.dislocations', path: '/dislocations' },
  { name: 'parks.edit.repairs', path: '/repairs' },
  { name: 'parks.edit.downtimes', path: '/downtimes' },
  { name: 'parks.edit.wagons', path: '/wagons' },

  { name: 'partners', path: '/partners' },
  { name: 'partners.create', path: '/create' },
  { name: 'partners.edit', path: '/edit/:id' },

  { name: 'payments', path: '/payments' },
  { name: 'payments.import', path: '/import' },
  { name: 'payments.edit', path: '/edit/:id' },

  { name: 'rentWagons', path: '/rent-wagons' },
  { name: 'rentWagons.purchase', path: '/purchase' },
  { name: 'rentWagons.purchase.edit', path: '/edit/:id' },
  { name: 'rentWagons.sell', path: '/sell' },
  { name: 'rentWagons.sell.edit', path: '/edit/:id' },

  { name: 'repairs', path: '/repairs' },

  { name: 'shipmentInfo', path: '/shipment-info' },
  { name: 'shipmentInfo.edit', path: '/edit/:id' },

  { name: 'stations', path: '/stations' },

  { name: 'suppliersDocuments', path: '/suppliers-documents' },
  { name: 'suppliersDocuments.edit', path: '/edit/:id' },
  { name: 'suppliersDocuments.create', path: '/create' },

  {
    name: 'suppliersDocumentsFromFile',
    path: '/suppliers-documents-from-file',
  },

  {
    name: 'suppliersDocumentsFromFile.edit',
    path: '/edit/:id',
    forwardTo: 'suppliersDocumentsFromFile.edit.main',
  },

  { name: 'suppliersDocumentsFromFile.edit.main', path: '/main' },
  { name: 'suppliersDocumentsFromFile.edit.expenses', path: '/expenses' },

  { name: 'techruns', path: '/techruns' },

  { name: 'techruns.assignWagons', path: '/assignWagons' },
  { name: 'techruns.assignWagons.new', path: '/new' },

  { name: 'techruns.gu12', path: '/gu-12' },
  { name: 'techruns.gu12.create', path: '/create' },
  { name: 'techruns.gu12.edit', path: '/edit/:id' },

  { name: 'techruns.requests', path: '/requests' },
  { name: 'techruns.requests.create', path: '/create' },
  {
    name: 'techruns.requests.view',
    path: '/view/:id',
    forwardTo: 'techruns.requests.view.main',
  },
  { name: 'techruns.requests.view.main', path: '/main' },
  { name: 'techruns.requests.view.parks', path: '/parks' },

  { name: 'techruns.transportations', path: '/transportations/:status' },
  { name: 'techruns.transportations.edit', path: '/edit/:id' },

  { name: 'telegram', path: '/telegram' },

  {
    name: 'wagonArrivals',
    path: `/wagon-arrivals/:group<${wagonArrivalsGroupParamValues.join('|')}>`,
  },

  { name: 'wagons', path: '/wagons' },
  {
    name: 'wagons.edit',
    path: '/edit/:number',
    forwardTo: 'wagons.edit.main',
  },
  { name: 'wagons.edit.main', path: '/main' },
  { name: 'wagons.edit.history', path: '/history' },
  { name: 'wagons.edit.notes', path: '/notes' },
  { name: 'wagons.edit.etranNotes', path: '/etranNotes' },
  { name: 'wagons.edit.parksHistory', path: '/parksHistory' },
  { name: 'wagons.edit.rjdAppealNotes', path: '/rjdAppealNotes' },
  { name: 'wagons.edit.deliveryOverdueNotes', path: '/deliveryOverdueNotes' },
  { name: 'wagons.edit.coo', path: '/coo' },

  { name: 'wagonsTracking', path: '/wagons-tracking' },
  { name: 'weighing', path: '/weighing' },
];

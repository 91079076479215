import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { SubmissionErrors } from 'final-form';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { IStationSerialized } from 'stations/types';
import { TechrunWagonAssignment } from 'techrunAssignments/types';
import { ITechrunRequest } from 'techrunRequests/types';

import * as css from './assignmentsListTable.module.css';
import {
  AssignmentNextRequestPopover,
  IAssignmentNextRequestFormValues,
} from './nextRequestPopover';

export const propsToFetch = {
  arrivalStation: '/directories_trainstations',
  departureStation: '/directories_trainstations',
  nextRequest: '/techrun_requests',
  operationStation: '/directories_trainstations',
  request: '/techrun_requests',
};

export interface TechrunAssignmentsListTableItem
  extends Omit<TechrunWagonAssignment, keyof typeof propsToFetch> {
  arrivalStation: IStationSerialized | null;
  departureStation: IStationSerialized | null;
  nextRequest: ITechrunRequest | null;
  operationStation: IStationSerialized | null;
  request?: ITechrunRequest;
}

interface Props {
  assignments: TechrunAssignmentsListTableItem[];
  isFetching: boolean;
  isNewAssignmentsTab: boolean;
  lineNumbersStart: number;
  selectedAssignments: string[];
  onRowNextRequestSubmit: (
    assignment: TechrunAssignmentsListTableItem,
    values: IAssignmentNextRequestFormValues
  ) => Promise<SubmissionErrors | void>;
  onSelectedAssignmentsChange: (newAssignments: string[]) => void;
}

export function TechrunAssignmentsListTable({
  assignments,
  isFetching,
  isNewAssignmentsTab,
  lineNumbersStart,
  selectedAssignments,
  onRowNextRequestSubmit,
  onSelectedAssignmentsChange,
}: Props) {
  const columns = useMemo(
    (): Array<ListTableColumn<TechrunAssignmentsListTableItem>> => [
      {
        id: 'wagon',
        label: 'Вагон',
        defaultWidth: 100,
        copyCellContent: assignment => assignment.wagon,
        renderCellContent: assignment => (
          <Link params={{ number: assignment.wagon }} to="wagons.edit">
            {assignment.wagon}
          </Link>
        ),
      },
      {
        id: 'request',
        label: 'Текущая заявка',
        defaultWidth: 450,
        copyCellContent: assignment =>
          assignment.request ? assignment.request.requestName : '',
        renderCellContent: assignment =>
          assignment.request ? (
            <Ellipsis
              component={Link}
              params={{ id: assignment.request.id }}
              to="techruns.requests.view"
            >
              {assignment.request.requestName}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'arrivalStation',
        label: 'Ст. отпр./назн./опер.',
        defaultWidth: 400,
        copyCellContent: assignment =>
          [
            assignment.departureStation
              ? assignment.departureStation.name
              : '...',

            assignment.arrivalStation ? assignment.arrivalStation.name : '...',

            assignment.operationStation
              ? assignment.operationStation.name
              : '...',
          ]
            .filter(Boolean)
            .join(' / '),
        renderCellContent: assignment => (
          <Ellipsis component="span">
            {[
              assignment.departureStation
                ? assignment.departureStation.name
                : '...',

              assignment.arrivalStation
                ? assignment.arrivalStation.name
                : '...',

              assignment.operationStation
                ? assignment.operationStation.name
                : '...',
            ]
              .filter(Boolean)
              .join(' / ')}
          </Ellipsis>
        ),
      },
      {
        id: 'requestPartner',
        label: 'Поставщик',
        defaultWidth: 160,
        copyCellContent: assignment =>
          assignment.supplier ? assignment.supplier.shortName : '',
        renderCellContent: assignment =>
          assignment.supplier ? (
            <Ellipsis
              component={Link}
              params={{ id: assignment.supplier.id }}
              to="partners.edit"
            >
              {assignment.supplier.shortName}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'distanceToArrivalStation',
        label: 'Расстояние до станции назначения',
        defaultWidth: 80,
        copyCellContent: assignment =>
          String(assignment.distanceToArrivalStation),
        renderCellContent: assignment => (
          <div className={css.distanceToArrivalStationCell}>
            {assignment.distanceToArrivalStation}
          </div>
        ),
      },
      {
        id: 'nextRequest',
        label: 'Новая заявка',
        defaultWidth: 150,
        copyCellContent: assignment =>
          assignment.nextRequest
            ? assignment.nextRequest.requestName
            : assignment.isAssigned
            ? 'Размен'
            : '',
        renderCellContent: assignment =>
          assignment.nextRequest ? (
            assignment.nextRequest.requestName
          ) : assignment.isAssigned ? (
            'Размен'
          ) : (
            <AssignmentNextRequestPopover
              initialPartner={
                assignment.requestPartner && assignment.requestPartner.id
              }
              initialValues={{
                exchangeOnFinish: assignment.exchangeOnFinish,
                nextRate:
                  assignment.nextRate == null
                    ? null
                    : String(assignment.nextRate),
                nextRequest: null,
              }}
              partnerIsRequired={isNewAssignmentsTab}
              showExchangeOnFinish={!isNewAssignmentsTab}
              supplierId={assignment.supplier ? assignment.supplier.id : null}
              onSubmit={values => {
                return onRowNextRequestSubmit(assignment, values);
              }}
            />
          ),
      },
    ],
    [isNewAssignmentsTab, onRowNextRequestSubmit]
  );

  const getItemId = useCallback(
    (assignment: TechrunAssignmentsListTableItem) => String(assignment.id),
    []
  );

  return (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      isFetching={isFetching}
      items={assignments}
      lineNumbersStart={lineNumbersStart}
      selectedItems={selectedAssignments}
      stickyColumnCount={3}
      onSelectedItemsChange={onSelectedAssignmentsChange}
    />
  );
}

import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { parseAWSUrl } from '_core/aws';
import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { SearchForm } from '_core/forms/searchForm';
import { LinkStyledButton } from '_core/linkStyledButton';
import { usePreference } from '_core/me/usePreference';
import { formatMoney } from '_core/money/formatMoney';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { EntityLinkWithFilter } from '_core/router5/entityLinkWithFilter';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  Boundary,
  Button,
  Classes,
  Menu,
  OverflowList,
  Popover,
} from '@blueprintjs/core';
import cx from 'classnames';
import dayjs from 'dayjs';
import { EXPEDITION_REQUESTS_BASE_ENDPOINT } from 'expeditionRequests/api';
import { ExpeditionRequestsFilterInPopover } from 'expeditionRequests/filterInPopover';
import { IExpeditionRequestSerialized } from 'expeditionRequests/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { IPartnerSerialized } from 'partners/api';
import { PartnersFilter } from 'partners/filter';
import * as React from 'react';
import { Fragment, useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import {
  fetchShipmentInfoItems,
  IShipmentInfoListItem,
  isShipmentInfoAccountingState,
  isShipmentInfoType,
  ShipmentInfoAccountingState,
} from 'shipmentInfo/api';
import {
  getShipmentInfoTypeLabel,
  isTechrunShipmentInfoType,
  shipmentInfoTypeOptions,
} from 'shipmentInfo/utils';
import { ITechrunRequest } from 'techrunRequests/types';

interface ListTableItem
  extends Omit<
    IShipmentInfoListItem,
    'partner' | 'requestsExpeditions' | 'requestsTechruns'
  > {
  partner: IPartnerSerialized | null;
  requestsExpeditions: IExpeditionRequestSerialized[];
  requestsTechruns: ITechrunRequest[];
}

const propsToFetch = {
  partner: '/partners',
  requestsExpeditions: EXPEDITION_REQUESTS_BASE_ENDPOINT,
  requestsTechruns: '/techrun_requests',
};

export default function ShipmentInfoListRoute() {
  const { route, router } = useRouteNode('shipmentInfo');
  const api = useApiClient();
  const [favoriteDocs] = usePreference<boolean>(['favoriteDocs']);

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'date',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const { data, isFetching, refetch } = useAsyncData(
    [api, favoriteDocs, route, sorting],
    async () => {
      const { meta, results } = await fetchShipmentInfoItems(api, {
        accountingStates: route.params.accountingStates
          ? (route.params.accountingStates as string)
              .split(',')
              .filter(isShipmentInfoAccountingState)
          : undefined,
        favoriteShipments: favoriteDocs,
        forRequest: isFinite(route.params.forRequest)
          ? Number(route.params.forRequest)
          : undefined,
        ids: route.params.ids
          ? (route.params.ids as string).split(',').map(Number)
          : undefined,
        page: isFinite(route.params.page)
          ? Number(route.params.page)
          : undefined,
        pageSize: isFinite(route.params.pageSize)
          ? Number(route.params.pageSize)
          : undefined,
        partner: isFinite(route.params.partner)
          ? Number(route.params.partner)
          : undefined,
        search: route.params.search
          ? (route.params.search as string)
          : undefined,
        sorting: [sorting],
        type: route.params.type
          ? (route.params.type as string).split(',').filter(isShipmentInfoType)
          : undefined,
      });

      return {
        currentPage: meta.currentPage,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
        shipments: (await (fetchRelated(
          api,
          propsToFetch,
          results
        ) as unknown)) as ListTableItem[],
      };
    }
  );

  const listParams = JSON.stringify(route.params);

  const columns = useMemo(
    (): Array<ListTableColumn<ListTableItem>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 130,
        copyCellContent: shipmentInfo => shipmentInfo.name,
        renderCellContent: shipmentInfo => (
          <Ellipsis
            component={Link}
            params={{ id: shipmentInfo.id, listParams }}
            rel="noopener"
            target="_blank"
            to="shipmentInfo.edit"
          >
            {shipmentInfo.name}
          </Ellipsis>
        ),
      },
      {
        id: 'numberUpd',
        label: 'Номер УПД',
        defaultWidth: 120,
        sortable: true,
        copyCellContent: shipmentInfo =>
          shipmentInfo.numberUpd || '<не указан>',
        renderCellContent: shipmentInfo =>
          shipmentInfo.numberUpd || '<не указан>',
      },
      {
        id: 'date',
        label: 'Дата',
        defaultWidth: 90,
        sortable: true,
        copyCellContent: shipmentInfo =>
          dayjs(shipmentInfo.date).format(DATE_FORMAT_DATE),
        renderCellContent: shipmentInfo =>
          dayjs(shipmentInfo.date).format(DATE_FORMAT_DATE),
      },
      {
        id: 'partner',
        label: 'Клиент',
        defaultWidth: 200,
        sortable: true,
        copyCellContent: shipmentInfo => shipmentInfo.partner?.shortName ?? '',
        renderCellContent: shipmentInfo =>
          shipmentInfo.partner && (
            <EntityLinkWithFilter
              entityLink={{
                params: { id: String(shipmentInfo.partner.id) },
                to: 'partners.edit',
              }}
              filterLink={{
                params: {
                  ...route.params,
                  partner: String(shipmentInfo.partner.id),
                },
                to: route.name,
              }}
              text={shipmentInfo.partner.shortName}
            />
          ),
      },
      {
        id: 'dateUpd',
        label: 'Дата УПД',
        defaultWidth: 90,
        sortable: true,
        copyCellContent: shipmentInfo =>
          shipmentInfo.dateUpd
            ? dayjs(shipmentInfo.dateUpd).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: shipmentInfo =>
          shipmentInfo.dateUpd
            ? dayjs(shipmentInfo.dateUpd).format(DATE_FORMAT_DATE)
            : null,
      },
      {
        id: 'type',
        label: 'Тип',
        defaultWidth: 200,
        copyCellContent: shipmentInfo =>
          getShipmentInfoTypeLabel(shipmentInfo.type),
        renderCellContent: shipmentInfo => (
          <Ellipsis component="span">
            {getShipmentInfoTypeLabel(shipmentInfo.type)}
          </Ellipsis>
        ),
      },
      {
        id: 'totalAmount',
        label: 'Итого к выставлению',
        defaultWidth: 130,
        copyCellContent: shipmentInfo => formatMoney(shipmentInfo.totalAmount),
        renderCellContent: shipmentInfo =>
          formatMoney(shipmentInfo.totalAmount),
      },
      {
        id: 'feeAmount',
        label: 'Комиссионное вознаграждение',
        defaultWidth: 130,
        copyCellContent: shipmentInfo => formatMoney(shipmentInfo.feeAmount),
        renderCellContent: shipmentInfo => formatMoney(shipmentInfo.feeAmount),
      },
      {
        id: 'requests',
        label: 'Заявки',
        defaultWidth: 180,
        copyCellContent: shipmentInfo => {
          const requests = [
            ...shipmentInfo.requestsExpeditions,
            ...shipmentInfo.requestsTechruns,
          ];

          return requests.map(request => request.requestName).join(', ');
        },
        renderCellContent: shipmentInfo => {
          const requests = [
            ...shipmentInfo.requestsExpeditions,
            ...shipmentInfo.requestsTechruns,
          ];

          return (
            <OverflowList
              collapseFrom={Boundary.END}
              items={requests}
              overflowRenderer={overflowItems => (
                <Popover
                  content={
                    <Menu>
                      {overflowItems.map(request => (
                        <li key={request.id}>
                          <Link
                            className={cx(
                              Classes.MENU_ITEM,
                              Classes.POPOVER_DISMISS
                            )}
                            params={{ id: String(request.id) }}
                            rel="noopener"
                            target="_blank"
                            to={
                              isTechrunShipmentInfoType(shipmentInfo.type)
                                ? 'techruns.requests.view'
                                : 'expeditions.requests.view'
                            }
                          >
                            {request.requestName}
                          </Link>
                        </li>
                      ))}
                    </Menu>
                  }
                >
                  <LinkStyledButton>
                    ещё {overflowItems.length}
                  </LinkStyledButton>
                </Popover>
              )}
              visibleItemRenderer={(request, index) => (
                <Fragment key={request.id}>
                  <Link
                    params={{ id: String(request.id) }}
                    rel="noopener"
                    target="_blank"
                    to={
                      isTechrunShipmentInfoType(shipmentInfo.type)
                        ? 'techruns.requests.view'
                        : 'expeditions.requests.view'
                    }
                  >
                    {request.requestName}
                  </Link>

                  {index !== requests.length - 1 ? <span>,&nbsp;</span> : null}
                </Fragment>
              )}
            />
          );
        },
      },
      {
        id: 'files',
        label: 'Файлы',
        defaultWidth: 180,
        copyCellContent: shipmentInfo =>
          shipmentInfo.files
            .map(awsUrl => parseAWSUrl(awsUrl).filename)
            .join(', '),
        renderCellContent: shipmentInfo => (
          <OverflowList
            collapseFrom={Boundary.END}
            items={shipmentInfo.files}
            overflowRenderer={overflowItems => (
              <Popover
                content={
                  <Menu>
                    {overflowItems.map(awsUrl => (
                      <li key={awsUrl}>
                        <a
                          className={cx(
                            Classes.MENU_ITEM,
                            Classes.POPOVER_DISMISS
                          )}
                          href={awsUrl}
                          rel="noopener"
                          target="_blank"
                        >
                          {parseAWSUrl(awsUrl).filename}
                        </a>
                      </li>
                    ))}
                  </Menu>
                }
              >
                <LinkStyledButton>ещё {overflowItems.length}</LinkStyledButton>
              </Popover>
            )}
            visibleItemRenderer={(awsUrl, index) => (
              <Fragment key={awsUrl}>
                <a href={awsUrl} rel="noopener" target="_blank">
                  {parseAWSUrl(awsUrl).filename}
                </a>

                {index !== shipmentInfo.files.length - 1 ? (
                  <span>,&nbsp;</span>
                ) : null}
              </Fragment>
            )}
          />
        ),
      },
      {
        id: 'isClosed',
        label: 'Проведён',
        defaultWidth: 120,
        copyCellContent: shipmentInfo =>
          shipmentInfo.isClosed ? 'Проведён' : 'Не проведён',
        renderCellContent: shipmentInfo =>
          shipmentInfo.isClosed ? 'Проведён' : 'Не проведён',
      },
    ],
    [listParams, route]
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, unknown>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const getItemId = useCallback(
    (shipmentInfo: ListTableItem) => String(shipmentInfo.id),
    []
  );

  const shipmentInfoAccountingStateLabels = {
    [ShipmentInfoAccountingState.NotClosed]: 'Нет',
    [ShipmentInfoAccountingState.Closed]: 'Да',
  };

  return (
    <>
      <FormattedTitle>Отгрузочная информация</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <CheckboxListFilter
                  label="Проведён"
                  initialValue={route.params.accountingStates}
                  options={[
                    ShipmentInfoAccountingState.NotClosed,
                    ShipmentInfoAccountingState.Closed,
                  ].map(state => ({
                    label: shipmentInfoAccountingStateLabels[state],
                    value: state,
                  }))}
                  onApply={accountingStates => {
                    applyFilterParams({ accountingStates });
                  }}
                />

                <CheckboxListFilter
                  initialValue={route.params.type}
                  label="Услуга"
                  options={shipmentInfoTypeOptions}
                  onApply={type => {
                    applyFilterParams({ type });
                  }}
                />

                <PartnersFilter
                  initialValue={route.params.partner}
                  label="Клиент"
                  onApply={partner => {
                    applyFilterParams({ partner });
                  }}
                />

                <ExpeditionRequestsFilterInPopover
                  initialValue={route.params.forRequest}
                  label="Заявка"
                  onApply={forRequest => {
                    applyFilterParams({ forRequest });
                  }}
                />

                <Button text="Обновить" onClick={refetch} />

                <LinkButton text="Сбросить фильтры" to={route.name} />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : data.shipments.length === 0 ? (
            <EmptyListMessage />
          ) : (
            <ListTable
              columns={columns}
              getItemId={getItemId}
              isFetching={isFetching}
              items={data.shipments}
              lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
              sorting={sorting}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>
    </>
  );
}

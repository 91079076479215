import { range } from '_core/fp/range';
import { sum } from '_core/fp/sum';
import { times } from '_core/fp/times';
import { isNotNull } from '_core/isNotNull';
import KeyCodes from '_core/keyCodes';
import { IColumnsWidth } from '_core/lists/columnPresets/useColumnPresets';
import { shallowEqual } from '_core/shallowEqual';
import { ISorting, SortingDirection } from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { Spinner } from '@blueprintjs/core';
import cx from 'classnames';
import { deepEqual } from 'fast-equals';
import * as React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { GridOnItemsRenderedProps, VariableSizeGrid } from 'react-window';
import invariant from 'tiny-invariant';

import * as css from './baseTable.module.css';
import { BaseCell, HeadCell } from './cells';
import {
  RegionOffsetProvider,
  RegionsContainer,
  RegionsProvider,
  RenderableRegion,
  SelectedRegion,
} from './regions';
import { useRenderedItems } from './renderedItems';
import { ResizeUiProvider, useResizeUi } from './resizeUi';

function copyCells(cells: string[][]) {
  const table = document.createElement('table');
  table.style.overflow = 'hidden';
  table.style.height = '0px';
  table.style.setProperty('-webkit-user-select', 'all');
  table.style.setProperty('-moz-user-select', 'all');
  table.style.setProperty('-ms-user-select', 'all');
  table.style.setProperty('user-select', 'all');

  for (const row of cells) {
    const tr = table.appendChild(document.createElement('tr'));
    for (const cell of row) {
      const td = tr.appendChild(document.createElement('td'));
      td.textContent = cell;
    }
  }

  if (!document.queryCommandSupported('copy')) {
    return false;
  }

  document.body.appendChild(table);
  try {
    window.getSelection()?.selectAllChildren(table);

    // add plaintext fallback
    // http://stackoverflow.com/questions/23211018/copy-to-clipboard-with-jquery-js-in-chrome
    table.addEventListener('copy', (event: ClipboardEvent) => {
      event.preventDefault();
      if (event.clipboardData != null) {
        event.clipboardData.setData(
          'text',
          cells.map(row => row.join('\t')).join('\n')
        );
      }
    });

    return document.execCommand('copy');
  } catch {
    return false;
  } finally {
    document.body.removeChild(table);
  }
}

type CellCoordinate = [number, number];

// /**
//  * Returns the smallest single contiguous `IRegion` that contains all cells in the
//  * supplied array.
//  */
function getBoundingRegion(cells: CellCoordinate[]) {
  let minRow: number | undefined;
  let maxRow: number | undefined;
  let minCol: number | undefined;
  let maxCol: number | undefined;

  for (const [row, col] of cells) {
    minRow = minRow == null || row < minRow ? row : minRow;
    maxRow = maxRow == null || row > maxRow ? row : maxRow;
    minCol = minCol == null || col < minCol ? col : minCol;
    maxCol = maxCol == null || col > maxCol ? col : maxCol;
  }

  if (minRow == null) {
    return null;
  }

  return {
    cols: [minCol, maxCol],
    rows: [minRow, maxRow],
  };
}

// /**
//  * Maps a dense array of cell coordinates to a sparse 2-dimensional array
//  * of cell values.
//  *
//  * We create a new 2-dimensional array representing the smallest single
//  * contiguous `IRegion` that contains all cells in the supplied array. We
//  * invoke the mapper callback only on the cells in the supplied coordinate
//  * array and store the result. Returns the resulting 2-dimensional array.
//  */
function sparseMapCells<T>(
  cells: CellCoordinate[],
  mapper: (row: number, col: number) => T
): T[][] | null {
  const bounds = getBoundingRegion(cells);

  if (bounds == null) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const numRows = bounds.rows[1]! + 1 - bounds.rows[0]!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const numCols = bounds.cols[1]! + 1 - bounds.cols[0]!;
  const result = times(numRows, () => new Array<T>(numCols));
  cells.forEach(([row, col]) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    result[row - bounds.rows[0]!][col - bounds.cols[0]!] = mapper(row, col);
  });
  return result;
}

// /**
//  * Using the supplied array of non-contiguous `IRegion`s, this method
//  * returns an ordered array of every unique cell that exists in those
//  * regions.
//  */
function enumerateUniqueCells(regions: Region[]) {
  const seen: Partial<Record<string, true>> = {};
  const list: CellCoordinate[] = [];
  for (const region of regions) {
    for (let row = region.rows[0]; row <= region.rows[1]; row++) {
      for (let col = region.cols[0]; col <= region.cols[1]; col++) {
        const key = `${row}-${col}`;
        if (!seen[key]) {
          seen[key] = true;
          list.push([row, col]);
        }
      }
    }
  }

  // sort list by rows then columns
  list.sort(function rowFirstComparator(a, b) {
    const rowDiff = a[0] - b[0];
    return rowDiff === 0 ? a[1] - b[1] : rowDiff;
  });
  return list;
}

/**
 * An _inclusive_ interval of ZERO-indexed cell indices.
 */
export type CellInterval = [number, number];

export interface Region {
  /**
   * The first and last row indices in the region, inclusive and zero-indexed.
   */
  rows: CellInterval;
  /**
   * The first and last column indices in the region, inclusive and
   * zero-indexed.
   */
  cols: CellInterval;
}

function propsAreEqual<TProps extends { style?: React.CSSProperties }>(
  { style: prevStyle, ...otherPrevProps }: TProps,
  { style: nextStyle, ...otherNextProps }: TProps
) {
  return (
    shallowEqual(nextStyle, prevStyle) &&
    shallowEqual(otherNextProps, otherPrevProps)
  );
}

interface BaseTableRenderHeadProps {
  children: React.ReactNode;
  sortingDirection: SortingDirection | undefined;
  style: React.CSSProperties;
  onSortingToggle: (() => void) | undefined;
}

export interface BaseTableRenderCellProps {
  children: React.ReactNode;
  style: React.CSSProperties;
  onDoubleClick: (event: React.MouseEvent) => void;
  onMouseDown: (event: React.MouseEvent) => void;
  onMouseEnter: (event: React.MouseEvent) => void;
}

interface BaseTableRenderCellEditorProps<TEditorValue> {
  cancelEditing: () => void;
  style: React.CSSProperties;
  value: TEditorValue;
  onChange: (newValue: TEditorValue) => void;
}

export interface BaseTableCellEditor<TEditorValue> {
  initialValue: TEditorValue;
  applyValue: (editorValue: TEditorValue) => void;
  render: (
    props: BaseTableRenderCellEditorProps<TEditorValue>
  ) => React.ReactNode;
}

export interface BaseTableColumn {
  id: string;
  label: string;
  defaultWidth?: number;
  sortable?: boolean;
  startSortingWith?: SortingDirection;
  copyCellContent?: (rowIndex: number) => string;
  renderHead?: (
    props: BaseTableRenderHeadProps,
    columnIndex: number
  ) => React.ReactNode;
  renderHeadContent?: (label: string) => React.ReactNode;
  renderCell?: (
    props: BaseTableRenderCellProps,
    rowIndex: number,
    columnIndex: number
  ) => React.ReactNode;
  renderCellContent: (rowIndex: number) => React.ReactNode;
  getEditor?: (rowIndex: number) => BaseTableCellEditor<unknown> | undefined;
}

interface ItemData {
  columns: BaseTableColumn[];
}

interface CellCoords {
  columnIndex: number;
  rowIndex: number;
}

interface EditingCell {
  coords: CellCoords;
  draftValue: unknown;
}

interface TableContextValue {
  columnCount: number;
  columnWidth: (index: number) => number;
  editingCell: EditingCell | null;
  getRowColor?: (rowIndex: number) => string | undefined;
  itemData: ItemData;
  itemKey: (params: ItemKeyParams) => React.Key;
  measureColumns: (from: number, to: number) => number;
  measureRows: (from: number, to: number) => number;
  rowCount: number;
  rowHeight: (index: number) => number;
  stickyBottomRowCount: number;
  stickyColumnCount: number;
  stickyTopRowCount: number;
  onCellDoubleClick: (
    event: React.MouseEvent,
    rowIndex: number,
    columnIndex: number
  ) => void;
  onCellEditingCancel: () => void;
  onCellMouseDown: (
    event: React.MouseEvent,
    rowIndex: number,
    columnIndex: number
  ) => void;
  onCellMouseEnter: (
    event: React.MouseEvent,
    rowIndex: number,
    columnIndex: number
  ) => void;
  onCellValueChange: (newValue: unknown) => void;
  onCopy: (event: React.ClipboardEvent) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const TableContext = React.createContext<TableContextValue | null>(null);

if (__DEV__) {
  TableContext.displayName = 'TableContext';
}

function useTableContext() {
  const value = React.useContext(TableContext);
  invariant(value);
  return value;
}

interface TableSortingContextValue {
  sorting: ISorting | undefined;
  onSortingChange: ((newSorting: ISorting) => void) | undefined;
}

const TableSortingContext =
  React.createContext<TableSortingContextValue | null>(null);

if (__DEV__) {
  TableSortingContext.displayName = 'TableSortingContext';
}

interface TableSortingProviderProps {
  children: React.ReactNode;
  sorting: ISorting | undefined;
  onSortingChange: ((newSorting: ISorting) => void) | undefined;
}

function TableSortingProvider({
  children,
  sorting,
  onSortingChange,
}: TableSortingProviderProps) {
  const value = React.useMemo(
    () => ({ sorting, onSortingChange }),
    [onSortingChange, sorting]
  );

  return (
    <TableSortingContext.Provider value={value}>
      {children}
    </TableSortingContext.Provider>
  );
}

function useTableSorting() {
  const value = React.useContext(TableSortingContext);
  invariant(value);
  return value;
}

function defaultRenderHead(
  {
    children,
    sortingDirection,
    style,
    onSortingToggle,
  }: BaseTableRenderHeadProps,
  columnIndex: number
) {
  return (
    <HeadCell
      columnIndex={columnIndex}
      sortingDirection={sortingDirection}
      style={style}
      onSortingToggle={onSortingToggle}
    >
      {children}
    </HeadCell>
  );
}

function defaultRenderHeadContent(label: string) {
  return <Ellipsis component="span">{label}</Ellipsis>;
}

export function defaultRenderCell({
  children,
  style,
  onDoubleClick,
  onMouseDown,
  onMouseEnter,
}: BaseTableRenderCellProps) {
  return (
    <BaseCell
      style={style}
      onDoubleClick={onDoubleClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </BaseCell>
  );
}

interface ColumnHeadCellProps {
  columnIndex: number;
  columns: BaseTableColumn[];
  style: React.CSSProperties;
}

function ColumnHeadCell({ columnIndex, columns, style }: ColumnHeadCellProps) {
  const {
    id,
    label,
    renderHead = defaultRenderHead,
    renderHeadContent = defaultRenderHeadContent,
    sortable,
    startSortingWith,
  } = columns[columnIndex];

  const { sorting, onSortingChange } = useTableSorting();

  const getSortingDirection = () => {
    if (
      startSortingWith !== undefined &&
      startSortingWith !== sorting?.direction &&
      sorting?.field !== id
    ) {
      return startSortingWith;
    }

    return sorting?.field === id
      ? sorting?.direction === SortingDirection.Ascending
        ? SortingDirection.Descending
        : SortingDirection.Ascending
      : SortingDirection.Descending;
  };

  return (
    <>
      {renderHead(
        {
          children: renderHeadContent(label),
          style,

          sortingDirection:
            sortable && sorting && sorting.field === id
              ? sorting.direction
              : undefined,

          onSortingToggle:
            sortable && onSortingChange
              ? () => {
                  onSortingChange({
                    field: id,
                    direction: getSortingDirection(),
                  });
                }
              : undefined,
        },
        columnIndex
      )}
    </>
  );
}

interface ColumnBodyCellProps {
  columnIndex: number;
  columns: BaseTableColumn[];
  rowIndex: number;
  style: React.CSSProperties;
}

function ColumnBodyCell({
  columnIndex,
  columns,
  rowIndex,
  style: styleProp,
}: ColumnBodyCellProps) {
  const {
    getEditor,
    renderCell = defaultRenderCell,
    renderCellContent,
  } = columns[columnIndex];

  const {
    editingCell,
    getRowColor,
    onCellDoubleClick,
    onCellEditingCancel,
    onCellMouseDown,
    onCellMouseEnter,
    onCellValueChange,
  } = useTableContext();

  const handleDoubleClick = React.useCallback(
    (event: React.MouseEvent) => {
      onCellDoubleClick(event, rowIndex, columnIndex);
    },
    [columnIndex, onCellDoubleClick, rowIndex]
  );

  const handleMouseDown = React.useCallback(
    (event: React.MouseEvent) => {
      onCellMouseDown(event, rowIndex, columnIndex);
    },

    [columnIndex, rowIndex, onCellMouseDown]
  );

  const handleMouseEnter = React.useCallback(
    (event: React.MouseEvent) => {
      onCellMouseEnter(event, rowIndex, columnIndex);
    },

    [columnIndex, rowIndex, onCellMouseEnter]
  );

  const style = React.useMemo(
    () => ({
      ...styleProp,
      backgroundColor: getRowColor && getRowColor(rowIndex),
    }),
    [getRowColor, rowIndex, styleProp]
  );

  if (
    editingCell != null &&
    editingCell.coords.columnIndex === columnIndex &&
    editingCell.coords.rowIndex === rowIndex
  ) {
    return (
      <>
        {getEditor?.(rowIndex)?.render({
          cancelEditing: onCellEditingCancel,
          style,
          value: editingCell.draftValue,
          onChange: onCellValueChange,
        })}
      </>
    );
  }

  return (
    <>
      {renderCell(
        {
          children: renderCellContent(rowIndex),
          style,
          onDoubleClick: handleDoubleClick,
          onMouseDown: handleMouseDown,
          onMouseEnter: handleMouseEnter,
        },

        rowIndex,
        columnIndex
      )}
    </>
  );
}

interface CellProps {
  columnIndex: number;
  data: ItemData;
  rowIndex: number;
  style: React.CSSProperties;
}

const Cell = React.memo(
  function Cell({
    columnIndex,
    data: { columns },
    rowIndex,
    style,
  }: CellProps) {
    return rowIndex === 0 ? (
      <ColumnHeadCell
        columnIndex={columnIndex}
        columns={columns}
        style={style}
      />
    ) : (
      <ColumnBodyCell
        columns={columns}
        columnIndex={columnIndex}
        rowIndex={rowIndex - 1}
        style={style}
      />
    );
  },

  propsAreEqual
);

const CellGroup = React.memo(function CellGroup({
  colFrom,
  colTo,
  columnWidth,
  itemData,
  itemKey,
  measureColumns,
  measureRows,
  rowFrom: rowFromProp,
  rowHeight,
  rowTo: rowToProp,
  visibleRowFrom,
  visibleRowTo,
}: {
  colFrom: number;
  colTo: number;
  columnWidth: (index: number) => number;
  itemData: ItemData;
  itemKey: (params: ItemKeyParams) => React.Key;
  measureColumns: (from: number, to: number) => number;
  measureRows: (from: number, to: number) => number;
  rowFrom: number;
  rowHeight: (index: number) => number;
  rowTo: number;
  visibleRowFrom?: number;
  visibleRowTo?: number;
}) {
  let rowFrom = rowFromProp;
  let rowTo = rowToProp;

  if (
    visibleRowFrom !== undefined &&
    visibleRowFrom > rowFrom &&
    visibleRowFrom < rowTo
  ) {
    rowFrom = visibleRowFrom;
  }

  if (
    visibleRowTo !== undefined &&
    visibleRowTo < rowTo &&
    visibleRowTo >= rowFrom
  ) {
    rowTo = visibleRowTo;
  }

  const columns = range(colFrom, colTo);
  const rows = range(rowFrom, rowTo);

  const offsetLeft = measureColumns(0, colFrom);
  const offsetTop = measureRows(0, rowFromProp);

  return (
    <>
      {rows.map(rowIndex =>
        columns.map(columnIndex => (
          <Cell
            key={itemKey({ columnIndex, rowIndex })}
            columnIndex={columnIndex}
            data={itemData}
            rowIndex={rowIndex}
            style={{
              position: 'absolute',
              top: measureRows(0, rowIndex) - offsetTop,
              left: measureColumns(0, columnIndex) - offsetLeft,
              width: columnWidth(columnIndex),
              height: rowHeight(rowIndex),
            }}
          />
        ))
      )}
    </>
  );
});

const StaticCell = React.memo(
  function StaticCell({ columnIndex, data, rowIndex, style }: CellProps) {
    const {
      rowCount,
      stickyBottomRowCount,
      stickyColumnCount,
      stickyTopRowCount,
    } = useTableContext();

    return columnIndex >= stickyColumnCount &&
      rowIndex >= stickyTopRowCount &&
      rowIndex <= rowCount - 1 - stickyBottomRowCount ? (
      <Cell
        columnIndex={columnIndex}
        data={data}
        rowIndex={rowIndex}
        style={style}
      />
    ) : null;
  },

  propsAreEqual
);

enum StickyCellGroupPosition {
  TopLeft = 'tl',
  TopRight = 'tr',
  CenterLeft = 'cl',
  BottomLeft = 'bl',
  BottomRight = 'br',
}

const StickyCellGroup = React.memo(function StickyCellGroup({
  colFrom,
  colTo,
  columnWidth,
  itemData,
  itemKey,
  measureColumns,
  measureRows,
  position,
  rowFrom,
  rowHeight,
  rowTo,
}: {
  colFrom: number;
  colTo: number;
  columnWidth: (index: number) => number;
  itemData: ItemData;
  itemKey: (params: ItemKeyParams) => React.Key;
  measureColumns: (from: number, to: number) => number;
  measureRows: (from: number, to: number) => number;
  position: StickyCellGroupPosition;
  rowFrom: number;
  rowHeight: (index: number) => number;
  rowTo: number;
}) {
  const renderedItems = useRenderedItems();

  let visibleRowFrom: number | undefined;
  let visibleRowTo: number | undefined;

  if (position === StickyCellGroupPosition.CenterLeft) {
    visibleRowFrom = renderedItems.overscanRowStartIndex;
    visibleRowTo = renderedItems.overscanRowStopIndex + 1;
  }

  return (
    <div
      className={cx(css.stickyCellGroup, {
        [css.stickyCellGroup_position_topLeft]:
          position === StickyCellGroupPosition.TopLeft,
        [css.stickyCellGroup_position_topRight]:
          position === StickyCellGroupPosition.TopRight,
        [css.stickyCellGroup_position_centerLeft]:
          position === StickyCellGroupPosition.CenterLeft,
        [css.stickyCellGroup_position_bottomLeft]:
          position === StickyCellGroupPosition.BottomLeft,
        [css.stickyCellGroup_position_bottomRight]:
          position === StickyCellGroupPosition.BottomRight,
      })}
      style={{
        width:
          position === StickyCellGroupPosition.TopRight ||
          position === StickyCellGroupPosition.BottomRight
            ? `calc(100% - ${measureColumns(0, colFrom)}px)`
            : measureColumns(colFrom, colTo),
        height: measureRows(rowFrom, rowTo),
      }}
    >
      <CellGroup
        colFrom={colFrom}
        colTo={colTo}
        columnWidth={columnWidth}
        itemData={itemData}
        itemKey={itemKey}
        measureColumns={measureColumns}
        measureRows={measureRows}
        rowFrom={rowFrom}
        rowHeight={rowHeight}
        rowTo={rowTo}
        visibleRowFrom={visibleRowFrom}
        visibleRowTo={visibleRowTo}
      />

      <RegionOffsetProvider
        top={measureRows(0, rowFrom)}
        left={measureColumns(0, colFrom)}
      >
        <RegionsContainer
          colFrom={colFrom}
          colTo={colTo}
          rowFrom={rowFrom}
          rowTo={rowTo}
        />
      </RegionOffsetProvider>
    </div>
  );
});

function ResizeUiRulers() {
  const { rulerHeight, rulerLeft } = useResizeUi();

  return rulerHeight == null || rulerLeft == null ? null : (
    <div
      className={css.resizeUiRuler}
      style={{ left: rulerLeft, height: rulerHeight }}
    />
  );
}

const InnerElement = React.forwardRef(function InnerElement(
  {
    children,
    style,
  }: {
    children: React.ReactNode;
    style: React.CSSProperties;
  },
  ref: React.Ref<HTMLDivElement>
) {
  const {
    columnCount,
    columnWidth,
    itemData,
    itemKey,
    measureColumns,
    measureRows,
    rowCount,
    rowHeight,
    stickyBottomRowCount,
    stickyColumnCount,
    stickyTopRowCount,
    onCopy,
    onKeyDown,
  } = useTableContext();

  const stickyCellGroupProps = {
    columnWidth,
    itemData,
    itemKey,
    measureColumns,
    measureRows,
    rowHeight,
  };

  return (
    <div
      ref={ref}
      style={style}
      tabIndex={0}
      onCopy={onCopy}
      onKeyDown={onKeyDown}
    >
      {children}

      <RegionsContainer
        colFrom={stickyColumnCount}
        colTo={columnCount - 1}
        rowFrom={stickyTopRowCount}
        rowTo={rowCount - 1}
      />

      <StickyCellGroup
        {...stickyCellGroupProps}
        colFrom={0}
        colTo={stickyColumnCount}
        position={StickyCellGroupPosition.TopLeft}
        rowFrom={0}
        rowTo={stickyTopRowCount}
      />

      <StickyCellGroup
        {...stickyCellGroupProps}
        colFrom={stickyColumnCount}
        colTo={columnCount}
        position={StickyCellGroupPosition.TopRight}
        rowFrom={0}
        rowTo={stickyTopRowCount}
      />

      {rowCount > stickyTopRowCount + stickyBottomRowCount && (
        <StickyCellGroup
          {...stickyCellGroupProps}
          colFrom={0}
          colTo={stickyColumnCount}
          position={StickyCellGroupPosition.CenterLeft}
          rowFrom={stickyTopRowCount}
          rowTo={rowCount - stickyBottomRowCount}
        />
      )}

      {rowCount > stickyTopRowCount && (
        <>
          <StickyCellGroup
            {...stickyCellGroupProps}
            colFrom={0}
            colTo={stickyColumnCount}
            position={StickyCellGroupPosition.BottomLeft}
            rowFrom={Math.max(
              stickyTopRowCount,
              rowCount - stickyBottomRowCount
            )}
            rowTo={rowCount}
          />

          <StickyCellGroup
            {...stickyCellGroupProps}
            colFrom={stickyColumnCount}
            colTo={columnCount}
            position={StickyCellGroupPosition.BottomRight}
            rowFrom={Math.max(
              stickyTopRowCount,
              rowCount - stickyBottomRowCount
            )}
            rowTo={rowCount}
          />
        </>
      )}

      <ResizeUiRulers />
    </div>
  );
});

interface OuterElementContextValue {
  height: number;
  isFetching: boolean | undefined;
}

const OuterElementContext =
  React.createContext<OuterElementContextValue | null>(null);

if (__DEV__) {
  OuterElementContext.displayName = 'OuterElementContext';
}

function OuterElementContextProvider({
  children,
  height,
  isFetching,
}: {
  children: React.ReactNode;
  height: number;
  isFetching: boolean | undefined;
}) {
  return (
    <OuterElementContext.Provider
      value={React.useMemo(
        (): OuterElementContextValue => ({ height, isFetching }),
        [height, isFetching]
      )}
    >
      {children}
    </OuterElementContext.Provider>
  );
}

function useOuterElementContext() {
  const value = React.useContext(OuterElementContext);
  invariant(value);
  return value;
}

const OuterElement = React.forwardRef(function OuterElement(
  {
    children,
    ...otherProps
  }: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
  ref: React.Ref<HTMLDivElement>
) {
  const { height, isFetching } = useOuterElementContext();

  return (
    <div {...otherProps} ref={ref}>
      {isFetching && (
        <div className={css.spinnerOverlayParent}>
          <div className={css.spinnerOverlay} style={{ height }}>
            <Spinner />
          </div>
        </div>
      )}
      {children}
    </div>
  );
});

interface ItemKeyParams {
  columnIndex: number;
  rowIndex: number;
}

interface Props {
  columns: BaseTableColumn[];
  columnWidths?: IColumnsWidth | null;
  customRegions?: RenderableRegion[];
  defaultColumnWidth?: number;
  getRowColor?: (rowIndex: number) => string | undefined;
  isFetching?: boolean;
  itemKey?: (params: ItemKeyParams) => React.Key;
  maxHeight?: number;
  overscanColumnCount?: number;
  overscanRowCount?: number;
  rowCount: number;
  selectedRegions?: Region[];
  showColumns?: string[];
  sorting?: ISorting;
  stickyBottomRowCount?: number;
  stickyColumnCount?: number;
  stickyTopRowCount?: number;
  onColumnWidthChanged?: (columnId: string, newWidth: number) => void;
  onItemsRendered: (props: GridOnItemsRenderedProps) => void;
  onSelection?: (newSelectedRegions: Region[]) => void;
  onSortingChange?: (newSorting: ISorting) => void;
}

const defaultItemKey = ({ columnIndex, rowIndex }: ItemKeyParams) =>
  `${rowIndex}:${columnIndex}`;

interface SelectionHelpers {
  dragStartCell: CellCoords | null;
  lastClickedCell: CellCoords | null;
  selectedRegions: Region[];
  setSelectedRegions: (newSelectedRegions: Region[]) => void;
}

export const BaseTable = React.memo(function BaseTable({
  columns: columnsProp,
  columnWidths: columnWidthsProp,
  customRegions,
  defaultColumnWidth = 150,
  getRowColor,
  isFetching,
  itemKey: itemKeyProp = defaultItemKey,
  maxHeight,
  overscanColumnCount,
  overscanRowCount,
  rowCount: rowCountProp,
  selectedRegions: selectedRegionsProp,
  showColumns,
  sorting,
  stickyBottomRowCount = 0,
  stickyColumnCount = 0,
  stickyTopRowCount: stickyTopRowCountProp = 0,
  onColumnWidthChanged,
  onItemsRendered,
  onSelection,
  onSortingChange,
}: Props) {
  const gridRef = React.useRef<VariableSizeGrid | null>(null);
  const outerRef = React.useRef<HTMLDivElement | null>(null);
  const innerRef = React.useRef<HTMLDivElement | null>(null);
  const [scrollbarHeight, setScrollbarHeight] = React.useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useLayoutEffect(() => {
    const outerEl = outerRef.current;

    if (!outerEl) {
      return;
    }

    setScrollbarHeight(outerEl.offsetHeight - outerEl.clientHeight);
  });

  const columns = React.useMemo(() => {
    if (!showColumns) {
      return columnsProp;
    }

    const shouldShowColumn = showColumns.reduce<Record<string, true>>(
      (all, columnName) => {
        all[columnName] = true;

        return all;
      },

      {}
    );

    return columnsProp.filter(column => shouldShowColumn[column.id]);
  }, [columnsProp, showColumns]);

  const [columnWidthsUncontrolled, setColumnWidthsUncontrolled] =
    React.useState<IColumnsWidth>([]);

  const columnWidths = columnWidthsProp || columnWidthsUncontrolled;
  const isColumnWidthsControlled = columnWidthsProp != null;

  const handleColumnWidthChanged = React.useCallback(
    (columnId: string, newWidth: number) => {
      if (onColumnWidthChanged) {
        onColumnWidthChanged(columnId, newWidth);
      }

      if (!isColumnWidthsControlled) {
        setColumnWidthsUncontrolled(prevColumnWidths =>
          prevColumnWidths
            .filter(colWidth => colWidth.name !== columnId)
            .concat({
              name: columnId,
              value: newWidth,
            })
        );
      }
    },

    [isColumnWidthsControlled, onColumnWidthChanged]
  );

  const columnWidth = React.useCallback(
    (index: number) => {
      const column = columns[index];

      if (columnWidths) {
        const foundWidth = columnWidths.find(
          colWidth => colWidth.name === column.id
        );

        if (foundWidth) {
          return foundWidth.value;
        }
      }

      return column.defaultWidth || defaultColumnWidth;
    },

    [columns, columnWidths, defaultColumnWidth]
  );

  React.useEffect(() => {
    if (!gridRef.current) {
      return;
    }

    const grid = gridRef.current;

    grid.resetAfterColumnIndex(0);
  }, [columnWidth]);

  const rowHeight = React.useCallback(() => 30, []);

  const itemData = React.useMemo(() => ({ columns }), [columns]);

  const measureColumns = React.useCallback(
    (from: number, to: number) => sum(range(from, to).map(columnWidth)),
    [columnWidth]
  );

  const measureRows = React.useCallback(
    (from: number, to: number) => sum(range(from, to).map(rowHeight)),
    [rowHeight]
  );

  const itemKey = React.useMemo(
    () =>
      ({ columnIndex, rowIndex }: ItemKeyParams) =>
        rowIndex === 0
          ? `head:${columns[columnIndex].id}`
          : itemKeyProp({ columnIndex, rowIndex: rowIndex - 1 }),
    [columns, itemKeyProp]
  );

  const columnCount = columns.length;
  const rowCount = rowCountProp + 1;

  const [selectedRegionsUncontolled, setSelectedRegionsUncontolled] =
    React.useState<Region[]>([]);

  let selectedRegions: Region[] = selectedRegionsUncontolled;
  let setSelectedRegions: (newSelectedRegions: Region[]) => void =
    setSelectedRegionsUncontolled;

  if (selectedRegionsProp !== undefined) {
    selectedRegions = selectedRegionsProp;
    invariant(
      onSelection,
      'You must also pass onSelection prop when selectedRegions is passed'
    );
    setSelectedRegions = onSelection;
  }

  const selectionHelpersRef = React.useRef<SelectionHelpers>({
    dragStartCell: null,
    lastClickedCell: null,
    selectedRegions,
    setSelectedRegions,
  });

  selectionHelpersRef.current.selectedRegions = selectedRegions;
  selectionHelpersRef.current.setSelectedRegions = setSelectedRegions;

  const handleCellMouseEnter = React.useCallback(
    (_event: React.MouseEvent, rowIndex: number, columnIndex: number) => {
      const selectionHelpers = selectionHelpersRef.current;

      if (!selectionHelpers.dragStartCell) {
        return;
      }

      const lastRegionIndex = selectionHelpers.selectedRegions.length - 1;
      const lastRegion = selectionHelpers.selectedRegions[lastRegionIndex];
      invariant(lastRegion.cols);
      invariant(lastRegion.rows);

      const updatedRegion: Region = {
        cols:
          columnIndex < selectionHelpers.dragStartCell.columnIndex
            ? [columnIndex, selectionHelpers.dragStartCell.columnIndex]
            : [selectionHelpers.dragStartCell.columnIndex, columnIndex],

        rows:
          rowIndex < selectionHelpers.dragStartCell.rowIndex
            ? [rowIndex, selectionHelpers.dragStartCell.rowIndex]
            : [selectionHelpers.dragStartCell.rowIndex, rowIndex],
      };

      selectionHelpers.setSelectedRegions(
        selectionHelpers.selectedRegions.slice(0, -1).concat([updatedRegion])
      );
    },

    []
  );

  const [editingCell, setEditingCell] = React.useState<EditingCell | null>(
    null
  );

  const handleCellDoubleClick = React.useCallback(
    (_event: React.MouseEvent, rowIndex: number, columnIndex: number) => {
      const { getEditor } = columns[columnIndex];

      const editor = getEditor?.(rowIndex);

      if (editor) {
        setEditingCell({
          coords: { columnIndex, rowIndex },
          draftValue: editor.initialValue,
        });
      }
    },
    [columns]
  );

  const handleCellValueChange = React.useCallback((newValue: unknown) => {
    setEditingCell(
      prevState => prevState && { ...prevState, draftValue: newValue }
    );
  }, []);

  const resetEditingCell = React.useCallback(() => {
    setEditingCell(null);
  }, []);

  const applyCellValue = React.useCallback(() => {
    if (editingCell) {
      const { getEditor } = columns[editingCell.coords.columnIndex];

      getEditor?.(editingCell.coords.rowIndex)?.applyValue(
        editingCell.draftValue
      );

      resetEditingCell();
    }
  }, [columns, editingCell, resetEditingCell]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      switch (event.keyCode) {
        case KeyCodes.Enter:
          if (!event.defaultPrevented) {
            applyCellValue();
          }
          break;
        case KeyCodes.Escape:
          resetEditingCell();
          break;
      }
    },
    [applyCellValue, resetEditingCell]
  );

  const handleCellEditingCancel = React.useCallback(() => {
    resetEditingCell();
  }, [resetEditingCell]);

  const handleCellMouseDown = React.useCallback(
    (event: React.MouseEvent, rowIndex: number, columnIndex: number) => {
      const innerEl = innerRef.current;
      invariant(innerEl);

      if (event.button !== 0) {
        return;
      }

      applyCellValue();

      innerEl.focus({ preventScroll: true });

      const selection = window.getSelection();

      if (selection) {
        const rangeToSelect = document.createRange();
        rangeToSelect.setStart(innerEl, 0);
        rangeToSelect.setEnd(innerEl, 0);
        selection.removeAllRanges();
        selection.addRange(rangeToSelect);
      }

      const cell: CellCoords = { columnIndex, rowIndex };
      const selectionHelpers = selectionHelpersRef.current;

      if (event.shiftKey && selectionHelpers.lastClickedCell) {
        const updatedRegion: Region = {
          cols:
            cell.columnIndex < selectionHelpers.lastClickedCell.columnIndex
              ? [cell.columnIndex, selectionHelpers.lastClickedCell.columnIndex]
              : [
                  selectionHelpers.lastClickedCell.columnIndex,
                  cell.columnIndex,
                ],

          rows:
            cell.rowIndex < selectionHelpers.lastClickedCell.rowIndex
              ? [cell.rowIndex, selectionHelpers.lastClickedCell.rowIndex]
              : [selectionHelpers.lastClickedCell.rowIndex, cell.rowIndex],
        };

        const lastRegionIndex = selectionHelpers.selectedRegions.length - 1;
        const lastRegion = selectionHelpers.selectedRegions[lastRegionIndex];

        if (!deepEqual(updatedRegion, lastRegion)) {
          selectionHelpers.setSelectedRegions(
            selectionHelpers.selectedRegions.slice(0, -1).concat(updatedRegion)
          );
        }
      } else {
        selectionHelpersRef.current.dragStartCell = cell;

        const newRegion: Region = {
          cols: [columnIndex, columnIndex],
          rows: [rowIndex, rowIndex],
        };

        selectionHelpers.setSelectedRegions(
          (event.ctrlKey || event.metaKey
            ? selectionHelpers.selectedRegions
            : []
          ).concat(newRegion)
        );

        window.addEventListener('mouseup', handleMouseUp, false);
      }

      function handleMouseUp() {
        window.removeEventListener('mouseup', handleMouseUp, false);

        selectionHelpersRef.current.lastClickedCell =
          selectionHelpersRef.current.dragStartCell;

        selectionHelpersRef.current.dragStartCell = null;
      }
    },
    [applyCellValue]
  );

  const stickyTopRowCount = stickyTopRowCountProp + 1;

  const handleCopy = React.useCallback(
    (event: React.ClipboardEvent) => {
      const innerEl = innerRef.current;
      const selection = window.getSelection();

      // this is to allow copying from, for example, inputs in cells
      if (!innerEl || !selection || selection.focusNode !== innerEl) {
        return;
      }

      event.preventDefault();

      const selectionHelpers = selectionHelpersRef.current;

      const sparse = sparseMapCells(
        enumerateUniqueCells(selectionHelpers.selectedRegions),
        (rowIndex, columnIndex) => {
          const column = columns[columnIndex];

          return column.copyCellContent ? column.copyCellContent(rowIndex) : '';
        }
      );

      // actually, it can be null, when region list is empty
      if (sparse) {
        copyCells(sparse);
      }
    },
    [columns]
  );

  const tableContextValue = React.useMemo(
    (): TableContextValue => ({
      columnCount,
      columnWidth,
      editingCell,
      getRowColor,
      itemData,
      itemKey,
      measureColumns,
      measureRows,
      rowCount,
      rowHeight,
      stickyBottomRowCount,
      stickyColumnCount,
      stickyTopRowCount,
      onCellDoubleClick: handleCellDoubleClick,
      onCellEditingCancel: handleCellEditingCancel,
      onCellMouseDown: handleCellMouseDown,
      onCellMouseEnter: handleCellMouseEnter,
      onCellValueChange: handleCellValueChange,
      onCopy: handleCopy,
      onKeyDown: handleKeyDown,
    }),

    [
      columnCount,
      columnWidth,
      editingCell,
      getRowColor,
      handleCellDoubleClick,
      handleCellEditingCancel,
      handleCellMouseDown,
      handleCellMouseEnter,
      handleCellValueChange,
      handleCopy,
      handleKeyDown,
      itemData,
      itemKey,
      measureColumns,
      measureRows,
      rowCount,
      rowHeight,
      stickyBottomRowCount,
      stickyColumnCount,
      stickyTopRowCount,
    ]
  );

  const regions = React.useMemo(() => {
    const selectedRenderableRegions = selectedRegions.map(
      (region): RenderableRegion => ({
        region,
        render: ({ style }) => <SelectedRegion style={style} />,
      })
    );

    return customRegions
      ? customRegions.concat(selectedRenderableRegions)
      : selectedRenderableRegions;
  }, [customRegions, selectedRegions]);

  return (
    <AutoSizer disableHeight={maxHeight != null}>
      {({ height, width }) => (
        <TableContext.Provider value={tableContextValue}>
          <TableSortingProvider
            sorting={sorting}
            onSortingChange={onSortingChange}
          >
            <RegionsProvider
              columnCount={columnCount}
              measureColumns={measureColumns}
              measureRows={measureRows}
              regions={regions}
              rowCount={rowCount}
            >
              <ResizeUiProvider
                columns={columns}
                innerRef={innerRef}
                onColumnWidthChanged={handleColumnWidthChanged}
              >
                <OuterElementContextProvider
                  height={height}
                  isFetching={isFetching}
                >
                  <VariableSizeGrid
                    className={css.grid}
                    columnCount={columnCount}
                    columnWidth={columnWidth}
                    estimatedColumnWidth={Math.max(
                      ...columns
                        .map(column => column.defaultWidth)
                        .filter(isNotNull)
                    )}
                    height={
                      maxHeight != null
                        ? Math.min(
                            maxHeight,
                            measureRows(0, rowCount) + scrollbarHeight
                          )
                        : height
                    }
                    innerElementType={InnerElement}
                    innerRef={innerRef}
                    outerElementType={OuterElement}
                    itemData={itemData}
                    itemKey={itemKey}
                    outerRef={outerRef}
                    overscanColumnCount={overscanColumnCount}
                    overscanRowCount={overscanRowCount}
                    ref={gridRef}
                    rowCount={rowCount}
                    rowHeight={rowHeight}
                    width={width}
                    onItemsRendered={onItemsRendered}
                  >
                    {StaticCell}
                  </VariableSizeGrid>
                </OuterElementContextProvider>
              </ResizeUiProvider>
            </RegionsProvider>
          </TableSortingProvider>
        </TableContext.Provider>
      )}
    </AutoSizer>
  );
});

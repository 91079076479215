import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { SearchForm } from '_core/forms/searchForm';
import { uniq } from '_core/fp/uniq';
import { indexByLastItem } from '_core/indexBy';
import { Switch } from '_core/inputs/switch';
import { isNotNull } from '_core/isNotNull';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TreeTableNode } from '_core/react-window/types';
import { findNode } from '_core/react-window/utils';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { useDerivedState } from '_core/useDerivedState';
import { Button, Intent } from '@blueprintjs/core';
import { fetchContacts, IContact } from 'contacts/api';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { fetchManyPartners, PartnersSortingField } from 'partners/api';
import { IPartnerSerialized } from 'partners/api';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useRouteNode } from 'react-router5';

import { ContactsTreeTableNodeData } from './columns';
import { ContactsListTable } from './listTable';
import { ContactsTreeTable } from './treeTable';

interface IContactsListAsyncData {
  contacts: IContact[];
  contactsByPartnerList: Array<[IPartnerSerialized | null, IContact[]]>;
  partnersById: Record<number, IPartnerSerialized>;
}

export default function ContactsListRoute() {
  const { route, router } = useRouteNode('contacts');
  const api = useApiClient();

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'modified',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const { data, isFetching, refetch } = useAsyncData<IContactsListAsyncData>(
    [api, route.params.search, sorting],
    async () => {
      const contactsSorting: ISorting[] = [];
      const partnersSorting: Array<ISorting<PartnersSortingField>> = [];

      if (sorting.field === 'name') {
        contactsSorting.push({
          field: 'email',
          direction: sorting.direction,
        });

        partnersSorting.push({
          field: PartnersSortingField.ShortName,
          direction: sorting.direction,
        });
      } else {
        contactsSorting.push(sorting);
      }

      const listResponse = await fetchAllPages(page =>
        fetchContacts(api, {
          page,
          search: route.params.search,
          sorting: contactsSorting,
        })
      );

      const contacts = listResponse.results;

      const partnerIds = uniq(
        contacts.map(contact => contact.partner).filter(isNotNull)
      );

      const partners = await fetchManyPartners(api, partnerIds, {
        sorting: partnersSorting,
      });

      const partnersById = indexByLastItem(partners, partner => partner.id);

      const contactsWithNoPartner: IContact[] = [];
      const contactsByPartner: Record<number, IContact[]> = {};

      contacts.forEach(contact => {
        const partner = contact.partner;

        if (!partner) {
          contactsWithNoPartner.push(contact);
          return;
        }

        contactsByPartner[partner] = contactsByPartner[partner] || [];
        contactsByPartner[partner].push(contact);
      });

      const noPartnerGroup: [IPartnerSerialized | null, IContact[]] = [
        null,
        contactsWithNoPartner,
      ];

      const contactsByPartnerList: Array<
        [IPartnerSerialized | null, IContact[]]
      > = [noPartnerGroup].concat(
        partners.map((partner): [IPartnerSerialized, IContact[]] => [
          partner,
          contactsByPartner[partner.id],
        ])
      );

      return {
        contacts,
        contactsByPartnerList,
        partnersById,
      };
    }
  );

  const [nodes, setNodes] = useDerivedState<
    IContactsListAsyncData | null,
    Array<TreeTableNode<ContactsTreeTableNodeData>> | null
  >(
    data,
    (input, prevNodes) =>
      input &&
      input.contactsByPartnerList.map(
        ([partner, contacts]): TreeTableNode<ContactsTreeTableNodeData> => {
          const prevPartnerNode = prevNodes
            ? findNode(
                prevNodes,
                node =>
                  node.data.kind === 'partner' &&
                  node.data.partner?.id === partner?.id
              )
            : undefined;

          return {
            id: `partner-${partner ? partner.id : 'null'}`,
            data: { kind: 'partner', partner, contactsCount: contacts.length },
            children: {
              isExpanded:
                prevPartnerNode && prevPartnerNode.children
                  ? prevPartnerNode.children.isExpanded
                  : false,

              nodes: contacts.map(
                (contact): TreeTableNode<ContactsTreeTableNodeData> => ({
                  id: `contact-${contact.id}`,
                  data: { kind: 'contact', contact },
                })
              ),
            },
          };
        }
      )
  );

  const listParams = JSON.stringify(route.params);

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const [renderTreeTable, setRenderTreeTable] = useState(true);

  return (
    <>
      <FormattedTitle>Контакты</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <Button text="Обновить" onClick={refetch} />

                <LinkButton text="Сбросить фильтры" to={route.name} />

                <LinkButton
                  intent={Intent.PRIMARY}
                  params={{ listParams }}
                  text="Добавить"
                  to="contacts.create"
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Switch
            checked={renderTreeTable}
            label="Группировать по контрагенту"
            onChange={newChecked => {
              setRenderTreeTable(newChecked);
              applyFilterParams({ sorting: undefined });
            }}
          />
        </Row>

        <Row stretch>
          {!data || !nodes ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : renderTreeTable ? (
            <ContactsTreeTable
              isFetching={isFetching}
              nodes={nodes}
              sorting={sorting}
              onNodesChange={setNodes}
              onSortingChange={handleSortingChange}
            />
          ) : (
            <ContactsListTable
              contacts={data.contacts}
              isFetching={isFetching}
              listParams={listParams}
              partnersById={data.partnersById}
              sorting={sorting}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>
      </VGrid>
    </>
  );
}

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { ITechrunGu12ItemSerialized } from './api';

const TechrunsGu12AutocompleteInFormGroup = wrapEntityAutocompleteWithFormGroup(
  createEntityAutocomplete<ITechrunGu12ItemSerialized, number>({
    baseEndpoint: '/techrun_gu12',

    getLink: item => ({
      params: { id: String(item.id) },
      to: 'techruns.gu12.edit',
    }),

    itemToOption: item => ({
      label: `${item.number} (${item.departureStationName} -> ${item.arrivalStationName})`,
      value: item.id,
    }),
  })
);

export const TechrunsGu12AutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    TechrunsGu12AutocompleteInFormGroup
  );

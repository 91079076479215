import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { fetchRelated } from '_core/api/fetchRelated';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { SearchForm } from '_core/forms/searchForm';
import { LegendItem } from '_core/legend';
import {
  expandSorting,
  ISorting,
  SortingDirection,
  validateSorting,
} from '_core/sorting';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, ButtonGroup, H4, Intent } from '@blueprintjs/core';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  confirmSuppliersDocumentTransportations,
  fetchSuppliersDocumentTransportations,
  ISuppliersDocumentDetail,
  linkTransportationsToSuppliersDocumentAutomatically,
  linkTransportationToSuppliersDocumentTransportation,
  SuppliersDocumentTransportationAmountIsCorrect,
  SuppliersDocumentTransportationsSortingField,
} from 'suppliersDocuments/api';

import {
  IBindDialogFormValues,
  TransportationBindDialog,
  TransportationKind,
} from './bindDialog';
import {
  ConfirmTransportationsDialog,
  IConfirmTransportationsDialogFormValues,
} from './confirmTransportationsDialog';
import {
  getSuppliersDocTranspAmountIsCorrectColor,
  isTransportationsTableSortingField,
  ITransportationsTableItem,
  SuppliersDocumentFromFileTransportationsTable,
  TransportationsTableSortingField,
} from './transportationsTable';

interface IProps {
  suppliersDocument: ISuppliersDocumentDetail;
  updateSuppliersDocumentData: (
    newSuppliersDocument: ISuppliersDocumentDetail
  ) => void;
}

export function SuppliersDocumentsFromFileEditExpensesTab({
  suppliersDocument,
  updateSuppliersDocumentData,
}: IProps) {
  const api = useApiClient();
  const toaster = useToaster();

  const confirmDialog = useDialog<{
    initialValues: IConfirmTransportationsDialogFormValues;
  }>();

  const [submitting, setSubmitting] = useState(false);

  const [sorting, setSorting] = useState<
    ISorting<TransportationsTableSortingField>
  >({ field: 'id', direction: SortingDirection.Ascending });

  const handleSortingChange = useCallback((newSorting: ISorting) => {
    setSorting(
      validateSorting(isTransportationsTableSortingField, newSorting, {
        field: 'id',
        direction: SortingDirection.Ascending,
      })
    );
  }, []);

  const [search, setSearch] = React.useState<string>();

  const { data, isFetching, refetch } = useAsyncData(
    [search, sorting, suppliersDocument.id],
    async () => {
      const response = await fetchAllPages(page =>
        fetchSuppliersDocumentTransportations(api, {
          document: suppliersDocument.id,
          page,
          search,
          sorting: expandSorting(sorting, {
            amountIsCorrect: [
              SuppliersDocumentTransportationsSortingField.AmountIsCorrect,
            ],
            id: [SuppliersDocumentTransportationsSortingField.Id],
            name: [SuppliersDocumentTransportationsSortingField.Name],
            number: [SuppliersDocumentTransportationsSortingField.Number],
            type: [SuppliersDocumentTransportationsSortingField.Type],
          }),
        })
      );

      return fetchRelated(
        api,
        {
          confirmUser: '/accounts',
          expeditionTransp: '/expeditions_transportations',
          techrunTransp: '/techrun_transportations',
        },
        response.results
      ) as unknown as Promise<ITransportationsTableItem[]>;
    }
  );

  const [bindDialogTransportation, setBindDialogTransportation] =
    useState<ITransportationsTableItem | null>(null);

  const bindDialogForm = useDialog<{ initialValues: IBindDialogFormValues }>();

  const [selectedTransportations, setSelectedTransportations] = useState<
    string[]
  >([]);

  const isTransportationConfirmationAllowed = useMemo(
    () =>
      data != null &&
      selectedTransportations.length !== 0 &&
      selectedTransportations.every(transpIdStr => {
        const transpId = Number(transpIdStr);
        const transportation = data.find(t => t.id === transpId);

        if (!transportation || transportation.amountIsConfirmed) {
          return false;
        }

        return (
          transportation.amountIsCorrect ===
            SuppliersDocumentTransportationAmountIsCorrect.Greater ||
          transportation.amountIsCorrect ===
            SuppliersDocumentTransportationAmountIsCorrect.Less
        );
      }),
    [data, selectedTransportations]
  );

  const handleBindClick = useCallback(
    (transportation: ITransportationsTableItem) => {
      setBindDialogTransportation(transportation);

      bindDialogForm.open({
        initialValues: {
          kind: TransportationKind.Expedition,
          transportation: null,
        },
      });
    },
    [bindDialogForm]
  );

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <>
      <H4>Перевозки</H4>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm initialValue={search} onApply={setSearch} />
            </Col>

            <Col span={9}>
              <Toolbar align="right" valign="center">
                <Button
                  disabled={submitting}
                  intent={Intent.SUCCESS}
                  text="Найти и привязать отправки"
                  onClick={async () => {
                    try {
                      setSubmitting(true);

                      updateSuppliersDocumentData(
                        await linkTransportationsToSuppliersDocumentAutomatically(
                          api,
                          suppliersDocument.id
                        )
                      );

                      refetch();
                    } catch (err) {
                      toaster.show({
                        icon: 'error',
                        intent: Intent.DANGER,
                        message:
                          'Не удалось привязать отправки: Непредвиденная ошибка',
                      });

                      throw err;
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                />

                <Button
                  disabled={!isTransportationConfirmationAllowed}
                  intent={Intent.SUCCESS}
                  text="Подтвердить"
                  onClick={() => {
                    confirmDialog.open({ initialValues: { note: '' } });
                  }}
                />

                <span>Сортировка:</span>

                <ButtonGroup>
                  <Button
                    active={sorting.field === 'id'}
                    icon={
                      sorting.field === 'id'
                        ? sorting.direction === SortingDirection.Ascending
                          ? 'sort-asc'
                          : 'sort-desc'
                        : undefined
                    }
                    text="По умолчанию"
                    onClick={() => {
                      if (
                        sorting.field === 'id' &&
                        sorting.direction === SortingDirection.Ascending
                      ) {
                        handleSortingChange({
                          field: 'id',
                          direction: SortingDirection.Descending,
                        });
                      } else {
                        handleSortingChange({
                          field: 'id',
                          direction: SortingDirection.Ascending,
                        });
                      }
                    }}
                  />

                  <Button
                    active={sorting.field === 'amountIsCorrect'}
                    icon={
                      sorting.field === 'amountIsCorrect'
                        ? sorting.direction === SortingDirection.Ascending
                          ? 'sort-asc'
                          : 'sort-desc'
                        : undefined
                    }
                    text="По статусу"
                    onClick={() => {
                      if (
                        sorting.field === 'amountIsCorrect' &&
                        sorting.direction === SortingDirection.Ascending
                      ) {
                        handleSortingChange({
                          field: 'amountIsCorrect',
                          direction: SortingDirection.Descending,
                        });
                      } else {
                        handleSortingChange({
                          field: 'amountIsCorrect',
                          direction: SortingDirection.Ascending,
                        });
                      }
                    }}
                  />
                </ButtonGroup>
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Toolbar>
            <LegendItem
              backgroundColor={getSuppliersDocTranspAmountIsCorrectColor({
                amountIsConfirmed: false,
                amountIsCorrect:
                  SuppliersDocumentTransportationAmountIsCorrect.NotLinked,
              })}
              label="Нет привязанных отправок"
            />

            <LegendItem
              backgroundColor={getSuppliersDocTranspAmountIsCorrectColor({
                amountIsConfirmed: false,
                amountIsCorrect:
                  SuppliersDocumentTransportationAmountIsCorrect.Greater,
              })}
              label="Тариф в протоколе меньше тарифа из перечня"
            />

            <LegendItem
              backgroundColor={getSuppliersDocTranspAmountIsCorrectColor({
                amountIsConfirmed: false,
                amountIsCorrect:
                  SuppliersDocumentTransportationAmountIsCorrect.Less,
              })}
              label="Тариф в протоколе больше тарифа из перечня"
            />

            <LegendItem
              backgroundColor={getSuppliersDocTranspAmountIsCorrectColor({
                amountIsConfirmed: true,
                amountIsCorrect:
                  SuppliersDocumentTransportationAmountIsCorrect.Ok,
              })}
              label="Тариф в протоколе равен тарифу из перечня или расход подтверждён вручную"
            />
          </Toolbar>
        </Row>

        <Row stretch>
          <SuppliersDocumentFromFileTransportationsTable
            docType={suppliersDocument.docType}
            isFetchingTransportations={isFetching}
            selectedTransportations={selectedTransportations}
            sorting={sorting}
            transportations={data}
            onBindClick={handleBindClick}
            onSelectedTransportationsChange={setSelectedTransportations}
            onSortingChange={handleSortingChange}
          />
        </Row>
      </VGrid>

      {bindDialogForm.state && bindDialogTransportation && (
        <TransportationBindDialog
          initialValues={bindDialogForm.state.initialValues}
          isOpen={bindDialogForm.state.isOpen}
          wagonNumber={bindDialogTransportation.wagonNumber}
          waybillNumber={bindDialogTransportation.waybillNumber}
          onClose={bindDialogForm.close}
          onClosed={() => {
            bindDialogForm.destroy();
            setBindDialogTransportation(null);
          }}
          onSubmit={async values => {
            try {
              await linkTransportationToSuppliersDocumentTransportation(
                api,
                bindDialogTransportation.id,

                values.kind === TransportationKind.Expedition
                  ? {
                      expeditionTransp: values.transportation,
                      techrunTransp: null,
                    }
                  : {
                      expeditionTransp: null,
                      techrunTransp: values.transportation,
                    }
              );

              refetch();
              bindDialogForm.close();

              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось привязать отправку: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}

      {confirmDialog.state && (
        <ConfirmTransportationsDialog
          initialValues={confirmDialog.state.initialValues}
          isOpen={confirmDialog.state.isOpen}
          onClose={confirmDialog.close}
          onClosed={confirmDialog.destroy}
          onSubmit={async values => {
            try {
              await confirmSuppliersDocumentTransportations(
                api,
                selectedTransportations.map(Number),
                { note: values.note }
              );

              refetch();
              confirmDialog.close();

              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось подтвердить отправки: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}
    </>
  );
}

import { VatRate } from 'vatRates/vatRates';

export enum SuppliersDocumentState {
  Open = 'OPEN',
  Check = 'CHECK',
  Closed = 'CLOSED',
}

export function isSuppliersDocumentState(
  str: string
): str is SuppliersDocumentState {
  return Object.values(SuppliersDocumentState).includes(
    str as SuppliersDocumentState
  );
}

export function getSuppliersDocumentStateLabel(state: SuppliersDocumentState) {
  return {
    [SuppliersDocumentState.Open]: 'документ открыт',
    [SuppliersDocumentState.Check]: 'документ нуждается в проверке',
    [SuppliersDocumentState.Closed]: 'документ закрыт',
  }[state];
}

export const suppliersDocumentStateOptions = [
  SuppliersDocumentState.Open,
  SuppliersDocumentState.Check,
  SuppliersDocumentState.Closed,
].map(key => ({
  label: getSuppliersDocumentStateLabel(key),
  value: key,
}));

export enum SuppliersDocumentType {
  Adjustment = 'ADJUSTMENT',
  Main = 'MAIN',
  New = 'NEW',
}

export function isSuppliersDocumentType(
  str: string
): str is SuppliersDocumentType {
  return Object.values(SuppliersDocumentType).includes(
    str as SuppliersDocumentType
  );
}

export function getSuppliersDocumentTypeLabel(type: SuppliersDocumentType) {
  return {
    [SuppliersDocumentType.Adjustment]: 'Корректировка',
    [SuppliersDocumentType.Main]: 'Основной',
    [SuppliersDocumentType.New]: 'Вновь выявленный',
  }[type];
}

export const suppliersDocumentTypeOptions = [
  SuppliersDocumentType.Main,
  SuppliersDocumentType.Adjustment,
  SuppliersDocumentType.New,
].map(type => ({
  label: getSuppliersDocumentTypeLabel(type),
  value: type,
}));

export interface ISuppliersDocumentSerialized {
  adjustmentFor: number | null;
  adjustmentForNumber: string;
  amount: string;
  amountTotal: string;
  date: string | null;
  docType: SuppliersDocumentType;
  files: string[];
  fromFile: boolean;
  id: number;
  notAssignedExpenses: string;
  number: string;
  partner: number;
  relatedExpeditionTransps: number[];
  relatedExpenses: string;
  relatedTechrunTransps: number[];
  sourceName: string | null;
  state: SuppliersDocumentState;
  vatRate: VatRate;
  vatValue: string;
}

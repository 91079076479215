// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function assocPath<T extends Record<string, any>>(
  path: readonly string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  obj: T
): T {
  if (path.length === 0) {
    return value;
  }

  const [prop, ...nextPath] = path;

  return {
    ...obj,
    [prop]: assocPath(
      nextPath,
      value,
      {}.hasOwnProperty.call(obj, prop) ? obj[prop] : {}
    ),
  };
}

import { ExpeditionStationType } from 'expeditions/types';

export enum ExpeditionRequestSellRateType {
  Day = 'DAY',
  Ton = 'TON',
  Wagon = 'WAGON',
}

export enum ExpeditionRequestStatus {
  Created = 'CREATED',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Closed = 'CLOSED',
}

export function isExpeditionRequestStatus(
  str: string
): str is ExpeditionRequestStatus {
  return Object.values(ExpeditionRequestStatus).includes(
    str as ExpeditionRequestStatus
  );
}

interface IExpeditionRequestWagon {
  isFinished: boolean;
  wagon: string;
}

export interface IExpeditionRequestSerialized {
  endDate: string;
  id: number;
  name: string;
  number: string;
  parks: number[];
  partner: number;
  requestName: string;
  sellContract: number;
  sellProtocol: number;
  sellRateType: ExpeditionRequestSellRateType;
  startDate: string;
  station?: number;
  stationType: ExpeditionStationType;
  status: ExpeditionRequestStatus;
  trackRepair: boolean;
  wagons: IExpeditionRequestWagon[];
  wagonType: number;
}

export const expeditionRequestStatusLabel = (status: ExpeditionRequestStatus) =>
  ({
    [ExpeditionRequestStatus.Created]: 'Создана',
    [ExpeditionRequestStatus.Active]: 'Активна',
    [ExpeditionRequestStatus.Completed]: 'Выполнена',
    [ExpeditionRequestStatus.Closed]: 'Закрыта',
  }[status]);

export const expeditionRequestStatusOptions = [
  ExpeditionRequestStatus.Created,
  ExpeditionRequestStatus.Active,
  ExpeditionRequestStatus.Completed,
  ExpeditionRequestStatus.Closed,
].map(status => ({
  label: expeditionRequestStatusLabel(status),
  value: status,
}));

export enum WagonsDailyUsageState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Paused = 'PAUSED',
}

export function getWagonsDailyUsageStateLabel(state: WagonsDailyUsageState) {
  return {
    [WagonsDailyUsageState.Active]: 'Активен',
    [WagonsDailyUsageState.Inactive]: 'Завершено начисление',
    [WagonsDailyUsageState.Paused]: 'Пауза',
  }[state];
}

export interface IWagonsDailyUsagePause {
  duration: number | null;
  endDate: string | null;
  id: number;
  note: string;
  startDate: string;
}

export interface IWagonsDailyUsageWagon {
  duration: number;
  endDate: string;
  pauses: IWagonsDailyUsagePause[];
  startDate: string;
  state: WagonsDailyUsageState;
  wagon: string;
}

export interface IWagonsDailyUsage {
  endDate: string;
  shipment: number | null;
  startDate: string;
  wagons: IWagonsDailyUsageWagon[];
}

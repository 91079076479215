import { usePreference } from '_core/me/usePreference';
import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';

import HomeWidgetsScreen from './widgetsScreen';

export default function HomeRoute() {
  const [favoriteDocs] = usePreference<boolean>(['favoriteDocs']);

  return favoriteDocs ? (
    <HomeWidgetsScreen />
  ) : (
    <NonIdealState icon="home" title="Добро пожаловать!" />
  );
}

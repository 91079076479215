import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { serializable } from '_core/serializable';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';

interface IParkWagonSerialized {
  created: string;
  justAdded: boolean;
  wagon: string;
}

interface IParkWagon extends Omit<IParkWagonSerialized, 'created'> {
  created: Date;
}

export enum DownloadEmptyValues {
  All = 'all',
  ThreeHundred = '300',
}

@serializable
export class ParkWagon implements IParkWagon {
  @serializable.datetime readonly created;
  readonly justAdded;
  readonly wagon;

  static fromJSON(serialized: IParkWagonSerialized) {
    return serializable.fromJSON(ParkWagon, serialized);
  }

  constructor(parkWagon: IParkWagon) {
    this.created = parkWagon.created;
    this.justAdded = parkWagon.justAdded;
    this.wagon = parkWagon.wagon;
  }
}

interface IParkSubscriberSerialized {
  contact: number;
  lastMailingTime: string | null;
}

interface IParkSubscriber
  extends Omit<IParkSubscriberSerialized, 'lastMailingTime'> {
  lastMailingTime: Date | null;
}

@serializable
export class ParkSubscriber implements IParkSubscriber {
  readonly contact;
  @serializable.datetime readonly lastMailingTime;

  static fromJSON(serialized: IParkSubscriberSerialized) {
    return serializable.fromJSON(ParkSubscriber, serialized);
  }

  constructor(parkSubscriber: IParkSubscriber) {
    this.contact = parkSubscriber.contact;
    this.lastMailingTime = parkSubscriber.lastMailingTime;
  }
}

export interface IParkSerialized {
  allTechrunRequestWagons: boolean;
  created: string;
  dailySchedules: number[];
  expeditionRequests: number[];
  expeditionRequestsWagons: string[];
  id: number;
  isMailingActive: boolean;
  isTechnical: boolean;
  lastMailed: string | null;
  latestDislocationChange: string | null;
  manualModified: string;
  name: string;
  subscribers: IParkSubscriberSerialized[];
  techrunRequests: number[];
  techrunRequestsWagons: string[];
  template: number | null;
  wagonCount: number;
  wagonNumbers: IParkWagonSerialized[];
  wagons: string[];
  weekendSchedules: number[];
}

interface IPark
  extends Omit<
    IParkSerialized,
    | 'created'
    | 'lastMailed'
    | 'latestDislocationChange'
    | 'manualModified'
    | 'subscribers'
    | 'wagonNumbers'
  > {
  created: Date;
  lastMailed: Date | null;
  latestDislocationChange: Date | null;
  manualModified: Date;
  subscribers: ParkSubscriber[];
  wagonNumbers: ParkWagon[];
}

@serializable
export class Park implements IPark {
  readonly allTechrunRequestWagons;
  @serializable.datetime readonly created;
  readonly dailySchedules;
  readonly expeditionRequests;
  readonly expeditionRequestsWagons;
  readonly id;
  readonly isMailingActive;
  readonly isTechnical;
  @serializable.datetime readonly lastMailed;
  @serializable.datetime readonly latestDislocationChange;
  @serializable.datetime readonly manualModified;
  readonly name;
  @serializable.arrayOf(ParkSubscriber) readonly subscribers;
  readonly techrunRequests;
  readonly techrunRequestsWagons;
  readonly template;
  readonly wagonCount;
  @serializable.arrayOf(ParkWagon) readonly wagonNumbers;
  readonly wagons;
  readonly weekendSchedules;

  static fromJSON(serialized: IParkSerialized) {
    return serializable.fromJSON(Park, serialized);
  }

  constructor(park: IPark) {
    this.allTechrunRequestWagons = park.allTechrunRequestWagons;
    this.created = park.created;
    this.dailySchedules = park.dailySchedules;
    this.expeditionRequests = park.expeditionRequests;
    this.expeditionRequestsWagons = park.expeditionRequestsWagons;
    this.id = park.id;
    this.isMailingActive = park.isMailingActive;
    this.isTechnical = park.isTechnical;
    this.lastMailed = park.lastMailed;
    this.latestDislocationChange = park.latestDislocationChange;
    this.manualModified = park.manualModified;
    this.name = park.name;
    this.subscribers = park.subscribers;
    this.techrunRequests = park.techrunRequests;
    this.techrunRequestsWagons = park.techrunRequestsWagons;
    this.template = park.template;
    this.wagonCount = park.wagonCount;
    this.wagonNumbers = park.wagonNumbers;
    this.wagons = park.wagons;
    this.weekendSchedules = park.weekendSchedules;
  }
}

export enum ParksSortingField {
  IsMailingActive = 'isMailingActive',
  LastMailed = 'lastMailed',
  LatestDislocationChange = 'latestDislocationChange',
  ManualModified = 'manualModified',
  Name = 'name',
  WagonCount = 'wagonCount',
}

interface IFetchParksParams {
  favoriteParks?: boolean;
  hasWagons?: string[];
  ids?: number[];
  page?: number;
  pageSize?: number;
  search?: string;
  sorting?: Array<ISorting<ParksSortingField>>;
}

export async function fetchParks(
  api: ApiClient,
  {
    favoriteParks,
    hasWagons,
    ids,
    page,
    pageSize,
    search,
    sorting,
  }: IFetchParksParams = {}
): Promise<ListResponse<Park>> {
  const response = await api.get<ListResponse<IParkSerialized>>('/parks', {
    favoriteParks,
    hasWagons:
      hasWagons && hasWagons.length !== 0 ? hasWagons.join(',') : undefined,
    ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    pageSize,
    search,
  });

  return {
    ...response,
    results: response.results.map(Park.fromJSON),
  };
}

export const fetchManyParks = makeFetchMany(fetchParks, 'ids');

export function fetchPark(api: ApiClient, id: number) {
  return api.get<IParkSerialized>(`/parks/${id}`);
}

export async function createPark(
  api: ApiClient,
  {
    allTechrunRequestWagons = false,
    dailySchedules = [],
    expeditionRequests = [],
    isMailingActive = false,
    name,
    subscribers = [],
    techrunRequests = [],
    template,
    wagons = [],
    weekendSchedules = [],
  }: {
    allTechrunRequestWagons?: boolean;
    dailySchedules?: number[];
    expeditionRequests?: number[];
    isMailingActive?: boolean;
    name: string;
    subscribers?: Array<{ contact: number }>;
    techrunRequests?: number[];
    template?: number | null;
    wagons: string[];
    weekendSchedules?: number[];
  }
) {
  const createdPark = await api.post<IParkSerialized>('/parks', {
    allTechrunRequestWagons,
    dailySchedules,
    expeditionRequests,
    isMailingActive,
    name,
    subscribers,
    techrunRequests,
    template,
    wagons,
    weekendSchedules,
  });

  return Park.fromJSON(createdPark);
}

export async function updatePark(
  api: ApiClient,
  id: number,
  {
    allTechrunRequestWagons,
    dailySchedules,
    expeditionRequests,
    isMailingActive,
    name,
    subscribers,
    techrunRequests,
    template,
    wagons,
    weekendSchedules,
  }: {
    allTechrunRequestWagons: boolean;
    dailySchedules: number[];
    expeditionRequests: number[];
    isMailingActive: boolean;
    name: string;
    subscribers: Array<{ contact: number }>;
    techrunRequests: number[];
    template: number | null;
    wagons: string[];
    weekendSchedules: number[];
  }
) {
  const updatedPark = await api.put<IParkSerialized>(`/parks/${id}`, {
    allTechrunRequestWagons,
    dailySchedules,
    expeditionRequests,
    isMailingActive,
    name,
    subscribers,
    techrunRequests,
    template,
    wagons,
    weekendSchedules,
  });

  return updatedPark;
}

export async function deletePark(api: ApiClient, id: number) {
  await api.delete(`/parks/${id}`);
}

export async function sendMailToParkSubscribers(
  api: ApiClient,
  parkId: number,
  subscriberIds: number[]
) {
  await api.post<undefined>(`/parks/${parkId}/send_mail`, { subscriberIds });
}

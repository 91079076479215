import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { ListResponse } from '_core/api/types';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useAsyncData } from '_core/useAsyncData';
import * as React from 'react';
import { useRoute } from 'react-router5';
import { TechrunWagonAssignment } from 'techrunAssignments/types';

import { TechrunAssignmentsList } from './assignmentsList';
import {
  propsToFetch,
  TechrunAssignmentsListTableItem,
} from './assignmentsListTable';

export function TechrunAssignWagonsRoute() {
  const { route, router } = useRoute();
  const api = useApiClient();

  const { data, isFetching, refetch } = useAsyncData([api, route], async () => {
    const { meta, results } = await api.get<
      ListResponse<TechrunWagonAssignment>
    >('/techrun_assign_wagons', {
      arrivalStation: route.params.arrivalStation,
      consignee: route.params.consignee,
      departureStation: route.params.departureStation,
      distanceLower: route.params.distanceLower,
      includeAssigned: route.params.includeAssigned === 'True',
      operationStation: route.params.operationStation,
      page: route.params.page,
      pageSize: route.params.pageSize,
      partner: route.params.partner,
      request: route.params.request,
      wagonNumber: route.params.wagonNumbers,
    });

    return {
      currentPage: meta.currentPage,
      pageSize: meta.pageSize,
      totalPages: meta.totalPages,
      items: await (fetchRelated(
        api,
        propsToFetch,
        results
      ) as unknown as Promise<TechrunAssignmentsListTableItem[]>),
    };
  });

  return (
    <>
      <FormattedTitle>Распределение вагонов</FormattedTitle>

      <TechrunAssignmentsList
        data={data}
        isFetching={isFetching}
        isNewAssignmentsTab={false}
        route={route}
        router={router}
        onGroupNextRequestSubmit={async (ids, values) => {
          try {
            await api.post('/techrun_assign_wagons/assign', values, {
              query: { ids: ids.join(',') },
            });

            refetch();
            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось распределить вагоны: Непредвиденная ошибка'
            );
          }
        }}
        onRowNextRequestSubmit={async (assignment, values) => {
          try {
            await api.post<TechrunWagonAssignment>(
              `/techrun_assign_wagons/${assignment.id}/assign`,
              values
            );

            refetch();
            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось распределить вагоны: Непредвиденная ошибка'
            );
          }
        }}
      />
    </>
  );
}

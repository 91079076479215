import { ApiClient } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { fetchRelated } from '_core/api/fetchRelated';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { SearchForm } from '_core/forms/searchForm';
import { Switch } from '_core/inputs/switch';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TreeTableNode } from '_core/react-window/types';
import { findNode } from '_core/react-window/utils';
import { LinkButton } from '_core/router5/linkButton';
import {
  expandSorting,
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
  validateSorting,
} from '_core/sorting';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button } from '@blueprintjs/core';
import { UsersFilter } from 'accounts/filter';
import { documentStatusOptions, isDocumentStatus } from 'documents/types';
import {
  DocumentsStatusesDocumentType,
  DocumentsStatusesSortingField,
  DocumentsStatusesTreeSortingField,
  fetchDocumentsStatuses,
  fetchDocumentsStatusesTree,
  IDocumentsStatusesItem,
  isDocumentsStatusesDocumentType,
} from 'documentsStatuses/api';
import { getDocumentTypeLabel } from 'documentsStatuses/utils';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersFilter } from 'partners/filter';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';

import {
  IDocumentsStatusesTableItem,
  OurDocumentsTreeTableNodeData,
} from './columns';
import { OurDocumentsListTable } from './listTable';
import { OurDocumentsTreeTable } from './treeTable';

const documentTypeOptions = [
  DocumentsStatusesDocumentType.ContractAgreementPurchase,
  DocumentsStatusesDocumentType.ContractAgreementSell,
  DocumentsStatusesDocumentType.ContractPurchase,
  DocumentsStatusesDocumentType.ContractSell,
  DocumentsStatusesDocumentType.ExpeditionProtocolSell,
  DocumentsStatusesDocumentType.RentProtocolPurchase,
  DocumentsStatusesDocumentType.RentProtocolSell,
  DocumentsStatusesDocumentType.TechrunProtocolPurchase,
  DocumentsStatusesDocumentType.TechrunProtocolSell,
].map(type => ({
  label: getDocumentTypeLabel(type),
  value: type,
}));

enum ListTableSortingField {
  Created = 'created',
  DocumentDate = 'documentDate',
  DocumentTypeRus = 'documentTypeRus',
  Partner = 'partner',
  Status = 'status',
}

function isListTableSortingField(
  input: string
): input is ListTableSortingField {
  return Object.values(ListTableSortingField).includes(
    input as ListTableSortingField
  );
}

const documentsStatusesSortings: Record<
  ListTableSortingField,
  DocumentsStatusesSortingField[]
> = {
  [ListTableSortingField.Created]: [DocumentsStatusesSortingField.Created],
  [ListTableSortingField.DocumentDate]: [
    DocumentsStatusesSortingField.DocumentDate,
  ],
  [ListTableSortingField.DocumentTypeRus]: [
    DocumentsStatusesSortingField.DocumentTypeRus,
  ],
  [ListTableSortingField.Partner]: [DocumentsStatusesSortingField.Partner],
  [ListTableSortingField.Status]: [DocumentsStatusesSortingField.Status],
};

enum TreeTableSortingField {
  Name = 'name',
}

function isTreeTableSortingField(
  input: string
): input is TreeTableSortingField {
  return Object.values(TreeTableSortingField).includes(
    input as TreeTableSortingField
  );
}

const documentsStatusesTreeSortings: Record<
  TreeTableSortingField,
  DocumentsStatusesTreeSortingField[]
> = {
  [TreeTableSortingField.Name]: [DocumentsStatusesTreeSortingField.Name],
};

function fetchDocumentsRelatedData(
  api: ApiClient,
  items: IDocumentsStatusesItem[]
) {
  return fetchRelated(
    api,
    { author: '/accounts' },
    items
  ) as unknown as Promise<IDocumentsStatusesTableItem[]>;
}

export default function OurDocumentsRoute() {
  const { route, router } = useRouteNode('ourDocuments');
  const api = useApiClient();

  const commonRequestParams = useMemo(
    () => ({
      author: isFinite(route.params.author)
        ? Number(route.params.author)
        : undefined,
      documentTypes: route.params.documentTypes
        ? (route.params.documentTypes as string)
            .split(',')
            .filter(isDocumentsStatusesDocumentType)
        : undefined,
      partner: isFinite(route.params.partner)
        ? Number(route.params.partner)
        : undefined,
      search: route.params.search,
      statuses: route.params.statuses
        ? (route.params.statuses as string).split(',').filter(isDocumentStatus)
        : undefined,
    }),
    [
      route.params.author,
      route.params.documentTypes,
      route.params.partner,
      route.params.search,
      route.params.statuses,
    ]
  );

  const renderTreeGrid = route.params.flat !== true;

  const sorting = useMemo(
    () =>
      validateSorting(
        isListTableSortingField,
        sortingFromRouterParam(route.params.sorting),
        {
          field: ListTableSortingField.Created,
          direction: SortingDirection.Descending,
        }
      ),
    [route.params.sorting]
  );

  const listTableRequest = useAsyncData(
    [
      commonRequestParams,
      sorting,
      renderTreeGrid,
      route.params.page,
      route.params.pageSize,
    ],
    async () => {
      if (renderTreeGrid) {
        return null;
      }

      const { meta, results } = await fetchDocumentsStatuses(api, {
        ...commonRequestParams,
        page: isFinite(route.params.page)
          ? Number(route.params.page)
          : undefined,
        pageSize: isFinite(route.params.pageSize)
          ? Number(route.params.pageSize)
          : undefined,
        sorting: expandSorting(sorting, documentsStatusesSortings),
      });

      return {
        currentPage: meta.currentPage,
        items: await fetchDocumentsRelatedData(api, results),
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
      };
    }
  );

  const treeTableSorting = useMemo(
    () =>
      validateSorting(
        isTreeTableSortingField,
        sortingFromRouterParam(route.params.sorting),
        {
          field: TreeTableSortingField.Name,
          direction: SortingDirection.Ascending,
        }
      ),
    [route.params.sorting]
  );

  const treeTableRequest = useAsyncData(
    [commonRequestParams, renderTreeGrid, treeTableSorting],
    async () => {
      if (!renderTreeGrid) {
        return null;
      }

      const treeResponse = await fetchDocumentsStatusesTree(api, {
        ...commonRequestParams,
        sorting: expandSorting(treeTableSorting, documentsStatusesTreeSortings),
      });

      return {
        nodes: treeResponse.results.map(
          (partner): TreeTableNode<OurDocumentsTreeTableNodeData> => {
            const prevPartnerNode = treeTableRequest.data
              ? findNode(
                  treeTableRequest.data.nodes,
                  node =>
                    node.data.kind === 'partner' &&
                    node.data.partner === partner.partner
                )
              : undefined;

            return {
              id: `partner-${partner.partner}`,
              data: {
                kind: 'partner',
                docTypesCount: partner.docTypes.length,
                partner: partner.partner,
                partnerName: partner.partnerName,
              },
              children: {
                isExpanded:
                  prevPartnerNode && prevPartnerNode.children
                    ? prevPartnerNode.children.isExpanded
                    : false,

                nodes: partner.docTypes.map(
                  (docType): TreeTableNode<OurDocumentsTreeTableNodeData> => {
                    const prevDocTypeNode = treeTableRequest.data
                      ? findNode(
                          treeTableRequest.data.nodes,
                          node =>
                            node.data.kind === 'documentType' &&
                            node.data.documentType === docType.documentType
                        )
                      : undefined;

                    return {
                      id: `docType-${docType.documentType}-${partner.partner}`,
                      data: {
                        kind: 'documentType',
                        docsCount: docType.docsCount,
                        documentType: docType.documentType,
                        documentTypeRus: docType.documentTypeRus,
                      },
                      children: {
                        hasMoreChildNodesToFetch: true,
                        isExpanded:
                          prevDocTypeNode && prevDocTypeNode.children
                            ? prevDocTypeNode.children.isExpanded
                            : false,
                        isFetching: false,
                        nodes: [],
                      },
                    };
                  }
                ),
              },
            };
          }
        ),
      };
    }
  );

  const fetchChildNodes = useCallback(
    async (
      parentNodes: Array<TreeTableNode<OurDocumentsTreeTableNodeData>>
    ) => {
      const parentNode = parentNodes[parentNodes.length - 1];
      const grandParentNode = parentNodes[parentNodes.length - 2];

      if (
        grandParentNode.data.kind === 'partner' &&
        parentNode.data.kind === 'documentType'
      ) {
        const { partner } = grandParentNode.data;
        const { documentType } = parentNode.data;

        const listResponse = await fetchAllPages(page =>
          fetchDocumentsStatuses(api, {
            ...commonRequestParams,
            documentTypes: [documentType],
            page,
            partner,
            sorting: expandSorting(sorting, documentsStatusesSortings),
          })
        );

        const documents = await fetchDocumentsRelatedData(
          api,
          listResponse.results
        );

        return documents.map(
          (document): TreeTableNode<OurDocumentsTreeTableNodeData> => ({
            id: `document-${document.id}`,
            data: { kind: 'document', document },
          })
        );
      }

      throw new Error(`Unexpected node kind: "${parentNode.data.kind}"`);
    },
    [commonRequestParams, sorting, api]
  );

  const updateTreeTableData = treeTableRequest.updateData;

  const handleNodesChange = useCallback(
    (
      newNodesOrUpdater:
        | Array<TreeTableNode<OurDocumentsTreeTableNodeData>>
        | ((
            prevNodes: Array<TreeTableNode<OurDocumentsTreeTableNodeData>>
          ) => Array<TreeTableNode<OurDocumentsTreeTableNodeData>>)
    ) => {
      updateTreeTableData(
        prevData =>
          prevData && {
            ...prevData,
            nodes:
              typeof newNodesOrUpdater === 'function'
                ? newNodesOrUpdater(prevData.nodes)
                : newNodesOrUpdater,
          }
      );
    },
    [updateTreeTableData]
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, boolean | string | undefined>) => {
      const rt = router.getState();

      router.navigate(rt.name, {
        ...rt.params,
        page: undefined,
        ...filterParams,
      });
    },
    [router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const generateFilterLink = useCallback(
    (params: Record<string, string | undefined>) => {
      const rt = router.getState();

      return { params: { ...rt.params, ...params }, to: rt.name };
    },
    [router]
  );

  return (
    <>
      <FormattedTitle>Наши документы</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <PartnersFilter
                  initialValue={route.params.partner}
                  onApply={partner => {
                    applyFilterParams({ partner });
                  }}
                />

                <UsersFilter
                  initialValue={
                    isFinite(route.params.author)
                      ? Number(route.params.author)
                      : null
                  }
                  label="Автор"
                  onApply={newAuthor => {
                    applyFilterParams({
                      author: newAuthor == null ? undefined : String(newAuthor),
                    });
                  }}
                />

                <CheckboxListFilter
                  initialValue={route.params.statuses}
                  label="Статусы"
                  options={documentStatusOptions}
                  onApply={statuses => {
                    applyFilterParams({ statuses });
                  }}
                />

                <CheckboxListFilter
                  initialValue={route.params.documentTypes}
                  label="Типы документов"
                  options={documentTypeOptions}
                  onApply={documentTypes => {
                    applyFilterParams({ documentTypes });
                  }}
                />

                <Button text="Обновить" onClick={listTableRequest.refetch} />

                <LinkButton text="Сбросить фильтры" to={route.name} />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Toolbar>
            <Switch
              checked={renderTreeGrid}
              label="Группировать по контрагенту"
              onChange={newChecked => {
                const paramsToApply: Record<
                  string,
                  boolean | string | undefined
                > = {
                  flat: newChecked ? undefined : true,
                };

                const currentSorting = sortingFromRouterParam(
                  route.params.sorting
                );

                if (
                  currentSorting &&
                  (newChecked
                    ? isListTableSortingField(currentSorting.field)
                    : isTreeTableSortingField(currentSorting.field))
                ) {
                  paramsToApply.sorting = undefined;
                }

                if (newChecked) {
                  Object.assign(paramsToApply, { pageSize: undefined });
                }

                applyFilterParams(paramsToApply);
              }}
            />
          </Toolbar>
        </Row>

        {renderTreeGrid ? (
          <Row stretch>
            {!treeTableRequest.data ? (
              treeTableRequest.isFetching ? (
                <CenteredSpinner />
              ) : (
                <GenericErrorMessage />
              )
            ) : (
              <OurDocumentsTreeTable
                fetchChildNodes={fetchChildNodes}
                isFetching={treeTableRequest.isFetching}
                nodes={treeTableRequest.data.nodes}
                onNodesChange={handleNodesChange}
                sorting={treeTableSorting}
                onSortingChange={handleSortingChange}
              />
            )}
          </Row>
        ) : (
          <>
            <Row stretch>
              {!listTableRequest.data ? (
                listTableRequest.isFetching ? (
                  <CenteredSpinner />
                ) : (
                  <GenericErrorMessage />
                )
              ) : (
                <OurDocumentsListTable
                  documentsStatusesItems={listTableRequest.data.items}
                  generateFilterLink={generateFilterLink}
                  isFetching={listTableRequest.isFetching}
                  lineNumbersStart={
                    listTableRequest.data.pageSize *
                      (listTableRequest.data.currentPage - 1) +
                    1
                  }
                  sorting={sorting}
                  onSortingChange={handleSortingChange}
                />
              )}
            </Row>

            {listTableRequest.data && (
              <Row>
                <Pagination
                  pageSize={listTableRequest.data.pageSize}
                  totalPages={listTableRequest.data.totalPages}
                />
              </Row>
            )}
          </>
        )}
      </VGrid>
    </>
  );
}

import * as React from 'react';

export interface IColumnProps<TItem> {
  id: string;
  cell: (item: TItem, rowIndex: number, columnIndex: number) => React.ReactNode;
  label?: string;
  width?: number;
}

export class Column<TItem> extends React.Component<IColumnProps<TItem>> {
  render(): React.ReactElement {
    throw new Error('Column should not be rendered directly');
  }
}

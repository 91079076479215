import { calcForForm } from '_core/decimal';
import { TableFieldErrors } from '_core/forms/tableFieldErrors';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyForFinalForm } from '_core/inputs/inputMoney';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Toolbar } from '_core/toolbar';
import { Button } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';

export interface IRepairProtocolPartsFieldValue {
  cost: string;
  name: string;
  price: string;
  quantity: string;
}

interface ITableItem extends IRepairProtocolPartsFieldValue {
  index: number;
}

interface IProps {
  change: (field: string, value: unknown) => void;
  name: string;
  readOnly: boolean;
}

export function RepairProtocolPartsField({ change, name, readOnly }: IProps) {
  const { fields, meta } = useFieldArray<IRepairProtocolPartsFieldValue>(name);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const columns = useMemo(
    (): Array<ListTableColumn<ITableItem>> => [
      {
        id: 'name',
        label: 'Название',
        defaultWidth: 150,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (_rowItem, rowIndex) => (
          <InputGroupForFinalForm
            id={`${fields.name}[${rowIndex}].name`}
            name={`${fields.name}[${rowIndex}].name`}
            readOnly={readOnly}
          />
        ),
      },
      {
        id: 'price',
        label: 'Цена',
        defaultWidth: 80,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            id={`${fields.name}[${rowIndex}].price`}
            name={`${fields.name}[${rowIndex}].price`}
            readOnly={readOnly}
            onChange={newPrice => {
              change(
                `${fields.name}[${rowIndex}].cost`,
                calcForForm((price, quantity) => price.mul(quantity))(
                  newPrice,
                  rowItem.quantity
                )
              );
            }}
          />
        ),
      },
      {
        id: 'quantity',
        label: 'Количество',
        defaultWidth: 80,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputGroupForFinalForm
            id={`${fields.name}[${rowIndex}].quantity`}
            name={`${fields.name}[${rowIndex}].quantity`}
            readOnly={readOnly}
            onChange={newQuantity => {
              change(
                `${fields.name}[${rowIndex}].cost`,
                calcForForm((price, quantity) => price.mul(quantity))(
                  rowItem.price,
                  newQuantity
                )
              );
            }}
          />
        ),
      },
      {
        id: 'cost',
        label: 'Сумма',
        defaultWidth: 80,
        renderCell: ({ children, style }) => (
          <BaseCell noPadding style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: (rowItem, rowIndex) => (
          <InputMoneyForFinalForm
            id={`${fields.name}[${rowIndex}].cost`}
            name={`${fields.name}[${rowIndex}].cost`}
            readOnly={readOnly}
            onChange={newCost => {
              change(
                `${fields.name}[${rowIndex}].price`,
                calcForForm((quantity, cost) => cost.dividedBy(quantity))(
                  rowItem.quantity,
                  newCost
                )
              );
            }}
          />
        ),
      },
    ],
    [change, fields.name, readOnly]
  );

  const items = useMemo(
    () => fields.value.map((item, index): ITableItem => ({ ...item, index })),
    [fields.value]
  );

  const getItemId = useCallback((item: ITableItem) => String(item.index), []);

  return (
    <VGrid>
      <Row>
        <TableFieldErrors
          fieldLabels={[
            ['name', 'Название'],
            ['price', 'Цена'],
            ['quantity', 'Количество'],
            ['cost', 'Сумма'],
          ]}
          rowsErrors={meta.error || meta.submitError}
        />

        <ListTable
          columns={columns}
          getItemId={getItemId}
          items={items}
          maxHeight={250}
          selectedItems={selectedItems}
          selectionIsDisabled={readOnly}
          onSelectedItemsChange={setSelectedItems}
        />
      </Row>

      <Row>
        <Toolbar>
          <Button
            disabled={readOnly}
            text="Добавить строку"
            onClick={() => {
              fields.push({
                cost: '',
                name: '',
                price: '',
                quantity: '',
              });
            }}
          />

          <Button
            disabled={readOnly || selectedItems.length === 0}
            text="Удалить выбранные строки"
            onClick={() => {
              selectedItems
                .map(Number)
                .sort((a, b) => b - a)
                .forEach(rowIndex => {
                  fields.remove(rowIndex);
                });

              setSelectedItems([]);
            }}
          />
        </Toolbar>
      </Row>
    </VGrid>
  );
}

import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { SelectInFormGroupForFinalForm } from '_core/inputs/select';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  AnchorButton,
  Button,
  Classes,
  Intent,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import dayjs from 'dayjs';
import { SubmissionErrors } from 'final-form';
import { Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroup } from 'partners/autocomplete';
import * as React from 'react';
import { useState } from 'react';
import { ITechrunRate, techrunRateToOption } from 'techrunRates/types';
import { fetchTechrunRequests } from 'techrunRequests/api';

import * as css from './nextRequestPopover.module.css';

export interface IAssignmentNextRequestFormValues {
  exchangeOnFinish: boolean;
  nextRate: string | null;
  nextRequest: string | null;
}

interface IFormProps {
  error: string | undefined;
  exchangeOnFinish: boolean;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  initialPartner: number | undefined;
  nextRequest: string | null;
  partnerIsRequired: boolean | undefined;
  showExchangeOnFinish: boolean;
  submitting: boolean;
  supplierId: number | null;
}

function AssignmentNextRequestPopoverForm({
  error,
  exchangeOnFinish,
  handleSubmit,
  initialPartner,
  nextRequest,
  partnerIsRequired,
  showExchangeOnFinish,
  submitting,
  supplierId,
}: IFormProps) {
  const api = useApiClient();

  const requests = useAsyncData([api], async () => {
    const response = await fetchAllPages(page =>
      fetchTechrunRequests(api, { isActive: true, page })
    );

    return response.results;
  });

  const [partner, setPartner] = useState(initialPartner || null);

  const nextRequestFound = requests.data
    ? requests.data.find(r => r.id === Number(nextRequest))
    : undefined;

  const rates = useAsyncData([api, nextRequestFound], () =>
    nextRequestFound && supplierId != null
      ? api
          .get<ListResponse<ITechrunRate>>('/techrun_rates_purchase', {
            arrivalStation: nextRequestFound.arrivalStation,
            departureStation: nextRequestFound.departureStation,
            forDate: dayjs().format(DATE_FORMAT_API_DATE),
            partner: supplierId,
          })
          .then(response => response.results)
      : Promise.resolve([])
  );

  return (
    <BaseForm className={css.form} onSubmit={handleSubmit}>
      <FormErrors error={error} />

      <VGrid>
        <Row>
          {showExchangeOnFinish ? (
            <CheckboxForFinalForm
              id="exchangeOnFinish"
              label="Размен"
              name="exchangeOnFinish"
            />
          ) : null}

          <PartnersAutocompleteInFormGroup
            label="Клиент"
            disabled={exchangeOnFinish}
            value={partner}
            onChange={setPartner}
          />

          <SelectInFormGroupForFinalForm
            disabled={
              exchangeOnFinish || (partnerIsRequired && partner == null)
            }
            id="nextRequest"
            label="Заявка"
            name="nextRequest"
            options={[{ label: '<выберите заявку>', value: '0' }].concat(
              requests.data
                ? requests.data
                    .filter(
                      request => partner == null || request.partner === partner
                    )
                    .map(request => ({
                      label: request.requestName,
                      value: String(request.id),
                    }))
                : []
            )}
          />

          <SelectInFormGroupForFinalForm
            disabled={exchangeOnFinish || !nextRequestFound}
            id="nextRate"
            label="Ставка"
            name="nextRate"
            options={[{ label: '<выберите ставку>', value: '0' }].concat(
              rates.data ? rates.data.map(techrunRateToOption) : []
            )}
          />
        </Row>

        <Row>
          <Toolbar align="right">
            <Button
              disabled={submitting}
              intent={Intent.PRIMARY}
              text="Сохранить"
              type="submit"
            />
          </Toolbar>
        </Row>
      </VGrid>
    </BaseForm>
  );
}

interface IProps {
  buttonTooltip?: string;
  disabled?: boolean;
  initialPartner?: number;
  initialValues: IAssignmentNextRequestFormValues;
  isOpen?: boolean;
  partnerIsRequired?: boolean;
  showExchangeOnFinish: boolean;
  supplierId: number | null;
  onInteraction?: (nextOpenState: boolean) => void;
  onSubmit: (
    values: IAssignmentNextRequestFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function AssignmentNextRequestPopover({
  buttonTooltip,
  disabled,
  initialPartner,
  initialValues,
  isOpen,
  partnerIsRequired,
  showExchangeOnFinish,
  supplierId,
  onInteraction,
  onSubmit,
}: IProps) {
  const popoverChild = (
    <AnchorButton
      disabled={disabled}
      intent={Intent.SUCCESS}
      text="Новая заявка"
    />
  );

  return (
    <Popover
      autoFocus
      content={
        <FinalForm
          initialValues={initialValues}
          onSubmit={values => {
            const finalValues = { ...values };

            if (finalValues.exchangeOnFinish) {
              finalValues.nextRate = null;
              finalValues.nextRequest = null;
            }

            return onSubmit(finalValues);
          }}
        >
          {({ error, handleSubmit, submitError, submitting, values }) => (
            <AssignmentNextRequestPopoverForm
              error={error || submitError}
              exchangeOnFinish={values.exchangeOnFinish}
              handleSubmit={handleSubmit}
              initialPartner={initialPartner}
              nextRequest={values.nextRequest}
              partnerIsRequired={partnerIsRequired}
              showExchangeOnFinish={showExchangeOnFinish}
              submitting={submitting}
              supplierId={supplierId}
            />
          )}
        </FinalForm>
      }
      disabled={disabled}
      hasBackdrop
      isOpen={isOpen}
      lazy
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      position={Position.BOTTOM_RIGHT}
      onInteraction={onInteraction}
    >
      {buttonTooltip ? (
        <Tooltip content={buttonTooltip}>{popoverChild}</Tooltip>
      ) : (
        popoverChild
      )}
    </Popover>
  );
}

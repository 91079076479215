import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { useFileUploader } from '_core/fileUploader/fileUploaderContext';
import { uniq } from '_core/fp/uniq';
import { indexByLastItem } from '_core/indexBy';
import { isNotNull } from '_core/isNotNull';
import { Pagination } from '_core/pagination';
import { EditableTextCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Ellipsis } from '_core/strings/ellipsis';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { AnchorButton, Button, Intent } from '@blueprintjs/core';
import { fetchManyUsers } from 'accounts/api';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import dayjs from 'dayjs';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { useRoute } from 'react-router5';
import {
  deleteWagonEtranNote,
  fetchWagonEtranNotes,
  getDownloadWagonEtranNotesXlsxUrl,
  updateWagonEtranNote,
  WagonEtranNote,
} from 'wagonEtranNotes/api';

import { NotesFilesCell } from '../notesFilesCell';

interface TableItem {
  createdBy: IUser | null;
  wagonNote: WagonEtranNote;
}

export function WagonsEditEtranNotesTab() {
  const { route } = useRoute();
  const api = useApiClient();
  const toaster = useToaster();
  const fileUploader = useFileUploader();

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [api, route.params.number, route.params.page, route.params.pageSize],
    async () => {
      const { meta, results } = await fetchWagonEtranNotes(api, {
        page: route.params.page,
        pageSize: route.params.pageSize,
        wagon: route.params.number,
      });

      const usersIndex = indexByLastItem(
        await fetchManyUsers(
          api,
          uniq(results.map(note => note.createdBy).filter(isNotNull))
        ),
        user => user.id
      );

      return {
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
        items: results.map(
          (wagonNote): TableItem => ({
            createdBy:
              wagonNote.createdBy == null
                ? null
                : usersIndex[wagonNote.createdBy],
            wagonNote,
          })
        ),
      };
    }
  );

  const tableItemUpdatePromisesRef = useRef<
    Record<WagonEtranNote['id'], Promise<TableItem> | undefined>
  >({});

  const updateNote = useCallback(
    async (
      noteId: WagonEtranNote['id'],
      updateFn: (tableItem: WagonEtranNote) => WagonEtranNote
    ) => {
      if (!data) {
        return;
      }

      const localTableItem = data.items.find(
        item => item.wagonNote.id === noteId
      );

      if (!localTableItem) {
        return;
      }

      try {
        let tableItemUpdatePromise = tableItemUpdatePromisesRef.current[noteId];

        let latestTableItem: TableItem;

        if (tableItemUpdatePromise) {
          latestTableItem = await tableItemUpdatePromise;
        } else {
          latestTableItem = localTableItem;
        }

        const noteUpdateInput = updateFn(latestTableItem.wagonNote);

        updateData(
          prevData =>
            prevData && {
              ...prevData,
              items: prevData.items.map(item =>
                item.wagonNote.id === noteId
                  ? { ...item, wagonNote: noteUpdateInput }
                  : item
              ),
            }
        );

        tableItemUpdatePromise = tableItemUpdatePromisesRef.current[noteId] =
          updateWagonEtranNote(api, noteId, noteUpdateInput).then(
            async updatedNote => ({
              wagonNote: updatedNote,
              createdBy:
                updatedNote.createdBy == null
                  ? null
                  : (await fetchManyUsers(api, [updatedNote.createdBy]))[0],
            })
          );

        const updatedTableItem = await tableItemUpdatePromise;

        updateData(
          prevData =>
            prevData && {
              ...prevData,
              items: prevData.items.map(item =>
                item.wagonNote.id === noteId ? updatedTableItem : item
              ),
            }
        );
      } catch (err) {
        updateData(
          prevData =>
            prevData && {
              ...prevData,
              items: prevData.items.map(item =>
                item.wagonNote.id === noteId ? localTableItem : item
              ),
            }
        );

        if (err instanceof BadRequestError) {
          toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: err.message,
          });
        } else {
          toaster.show({
            icon: 'error',
            intent: Intent.DANGER,
            message: 'Не удалось изменить комментарий',
          });

          throw err;
        }
      }
    },
    [data, api, toaster, updateData]
  );

  const columns = useMemo(
    (): Array<ListTableColumn<TableItem>> => [
      {
        id: 'created',
        label: 'Запись создана',
        defaultWidth: 130,
        copyCellContent: ({ wagonNote }) =>
          dayjs(wagonNote.created).format(DATE_FORMAT_DATETIME),
        renderCellContent: ({ wagonNote }) => (
          <Ellipsis component="span">
            {dayjs(wagonNote.created).format(DATE_FORMAT_DATETIME)}
          </Ellipsis>
        ),
      },
      {
        id: 'createdBy',
        label: 'Автор',
        defaultWidth: 130,
        copyCellContent: ({ createdBy }) =>
          createdBy == null ? '' : formatUserName(createdBy),
        renderCellContent: ({ createdBy }) =>
          createdBy == null ? null : (
            <Ellipsis component="span">{formatUserName(createdBy)}</Ellipsis>
          ),
      },
      {
        id: 'operation',
        label: 'Операция',
        defaultWidth: 300,
        copyCellContent: ({ wagonNote }) => wagonNote.operation,
        renderCellContent: ({ wagonNote }) => (
          <Ellipsis component="span">{wagonNote.operation}</Ellipsis>
        ),
      },
      {
        id: 'note',
        label: 'Комментарий',
        defaultWidth: 700,
        copyCellContent: ({ wagonNote }) => wagonNote.note,
        renderCell: ({ item, style, onMouseDown, onMouseEnter }) => (
          <EditableTextCell
            style={style}
            value={item.wagonNote.note.replace(/\n/g, ' ')}
            onConfirm={newNote => {
              updateNote(
                item.wagonNote.id,
                note =>
                  new WagonEtranNote({
                    ...note,
                    note: newNote,
                  })
              );
            }}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          />
        ),
        renderCellContent: () => null,
      },
      {
        id: 'files',
        label: 'Файлы',
        defaultWidth: 90,
        renderCellContent: ({ wagonNote }) => (
          <NotesFilesCell
            files={wagonNote.files}
            onFileRemoveClick={fileToRemove => {
              updateNote(
                wagonNote.id,
                note =>
                  new WagonEtranNote({
                    ...note,
                    files: note.files.filter(file => file !== fileToRemove),
                  })
              );
            }}
            onFilesUploadRequest={files => {
              files.forEach(async file => {
                const { promise } = fileUploader.uploadFile(file);
                const response = await promise;

                updateNote(
                  wagonNote.id,
                  note =>
                    new WagonEtranNote({
                      ...note,
                      files: [...note.files, response.file],
                    })
                );
              });
            }}
          />
        ),
      },
      {
        id: 'remove',
        label: '',
        defaultWidth: 44,
        renderCellContent: tableItem => (
          <Button
            icon="delete"
            intent={Intent.DANGER}
            minimal
            small
            onClick={async () => {
              if (
                // eslint-disable-next-line no-alert
                window.confirm('Вы действительно хотите удалить комментарий?')
              ) {
                updateData(
                  prevData =>
                    prevData && {
                      ...prevData,
                      items: prevData.items.filter(
                        item => item.wagonNote.id !== tableItem.wagonNote.id
                      ),
                    }
                );

                try {
                  await deleteWagonEtranNote(api, tableItem.wagonNote.id);
                } catch (err) {
                  toaster.show({
                    icon: 'error',
                    intent: Intent.DANGER,
                    message: 'Не удалось удалить комментарий',
                  });

                  updateData(
                    prevData =>
                      prevData && {
                        ...prevData,
                        items: prevData.items.concat(tableItem),
                      }
                  );

                  throw err;
                }
              }
            }}
          />
        ),
      },
    ],
    [fileUploader, api, toaster, updateData, updateNote]
  );

  const getItemId = useCallback(
    (item: TableItem) => String(item.wagonNote.id),
    []
  );

  return (
    <VGrid stretch>
      <Row>
        <Toolbar align="right">
          <Button text="Обновить" onClick={refetch} />

          <AnchorButton
            href={getDownloadWagonEtranNotesXlsxUrl(api, route.params.number)}
            icon="download"
            intent={Intent.SUCCESS}
            text="Скачать .xlsx"
          />
        </Toolbar>
      </Row>

      <Row stretch>
        {!data ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <GenericErrorMessage />
          )
        ) : data.items.length === 0 ? (
          <EmptyListMessage />
        ) : (
          <ListTable
            columns={columns}
            getItemId={getItemId}
            isFetching={isFetching}
            items={data.items}
          />
        )}
      </Row>

      {data && (
        <Row>
          <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
        </Row>
      )}
    </VGrid>
  );
}

import { LinkButton } from '_core/router5/linkButton';
import { HTMLSelect } from '@blueprintjs/core';
import * as React from 'react';
import { useField } from 'react-final-form';
import invariant from 'tiny-invariant';

import { FormGroupForFinalForm } from './formGroup';
import * as css from './select.module.css';

export interface SelectOption<TValue extends string = string> {
  disabled?: boolean;
  label: string;
  value: TValue;
}

interface LinkProps {
  params?: Record<string, string | undefined>;
  to: string;
}

interface Props<TValue extends string> {
  autoFocus?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  editingLocked?: boolean;
  emptyOptionText?: string;
  fill?: boolean;
  getLink?: (value: TValue | null) => LinkProps | undefined;
  id?: string;
  name?: string;
  options?: Array<SelectOption<TValue>>;
  value: TValue | null;
  withEmptyOption?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  onChange?: (newValue: TValue | null) => void;
  onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

export function Select<TValue extends string>({
  autoFocus,
  children,
  disabled,
  editingLocked,
  emptyOptionText = disabled ? '' : '<Выберите значение>',
  fill,
  getLink,
  id,
  name,
  options,
  value,
  withEmptyOption,
  onBlur,
  onChange,
  onFocus,
}: Props<TValue>) {
  const link = getLink && getLink(value);

  invariant(
    !(children && options),
    'Do not pass children and options props at the same time!!!'
  );

  return (
    <div className={css.root}>
      <HTMLSelect
        autoFocus={autoFocus}
        disabled={disabled || editingLocked}
        fill={fill}
        id={id}
        name={name}
        value={value || ''}
        onBlur={onBlur}
        onChange={
          onChange &&
          (event => {
            if (withEmptyOption && event.currentTarget.value === '') {
              onChange(null);
            } else {
              onChange(event.currentTarget.value as TValue);
            }
          })
        }
        onFocus={onFocus}
      >
        {withEmptyOption && <option value="">{emptyOptionText}</option>}

        {children ||
          options?.map(option => (
            <option
              key={option.value}
              disabled={option.disabled}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
      </HTMLSelect>

      {link && (
        <LinkButton
          className={css.linkButton}
          icon="search"
          minimal
          params={link.params}
          rel="noopener"
          target="_blank"
          to={link.to}
        />
      )}
    </div>
  );
}

export function SelectForFinalForm<TValue extends string>({
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  ...otherProps
}: Omit<Props<TValue>, 'value'> & { name: string }) {
  const { input, meta } = useField<TValue | null>(name);

  return (
    <Select
      disabled={meta.submitting || disabled}
      name={input.name}
      onBlur={event => {
        input.onBlur(event);
        onBlur?.(event);
      }}
      onChange={newValue => {
        input.onChange(newValue);
        onChange?.(newValue);
      }}
      onFocus={event => {
        input.onFocus(event);
        onFocus?.(event);
      }}
      value={input.value}
      {...otherProps}
    />
  );
}

export function SelectInFormGroupForFinalForm<TValue extends string>({
  fill = true,
  id,
  label,
  name,
  ...otherProps
}: Omit<Props<TValue>, 'value'> & { label?: string; name: string }) {
  return (
    <FormGroupForFinalForm label={label} labelFor={id} name={name}>
      <SelectForFinalForm {...otherProps} fill={fill} id={id} name={name} />
    </FormGroupForFinalForm>
  );
}

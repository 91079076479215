import { SelectOption } from '_core/inputs/select';
import { formatMoney } from '_core/money/formatMoney';

export interface ITechrunRate {
  arrivalStation: number;
  cost: string;
  costTotal: string;
  departureStation: number;
  id: number;
  partner: { id: number; shortName: string };
  protocolName: string;
  vatRate: string;
  vatValue: string;
}

export function techrunRateToOption(rate: ITechrunRate): SelectOption {
  return {
    label: `${formatMoney(rate.cost)} (${rate.protocolName})`,
    value: String(rate.id),
  };
}

import { deprecatedFilterInPopover } from '_deprecated/filterInPopover';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import * as React from 'react';

import * as css from './downtimeDurationFilter.module.css';

interface IValue {
  downtimeDurationGreater: string | undefined;
}

export const DowntimeDurationFilter = deprecatedFilterInPopover<IValue, {}>({
  iconName: 'filter-list',
  label: 'Длительность простоя',

  hasInitialValue: initialValue =>
    Boolean(initialValue.downtimeDurationGreater),

  clearValue: {
    downtimeDurationGreater: undefined,
  },
})(function DowntimeDurationFilterRaw({ value, onChange }) {
  return (
    <div className={css.main}>
      <FormGroup label="Длительность простоя в днях">
        <InputGroup
          autoFocus
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value={value.downtimeDurationGreater!}
          onChange={event => {
            onChange({
              ...value,
              downtimeDurationGreater: event.currentTarget.value,
            });
          }}
        />
      </FormGroup>
    </div>
  );
});

import { Classes, Icon, IconName, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';
import { NavigationOptions } from 'router5';

import { Link } from './link';

interface IProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: IconName;
  intent?: Intent;
  minimal?: boolean;
  options?: NavigationOptions;
  params?: Record<string, unknown>;
  small?: boolean;
  rel?: string;
  target?: string;
  text?: string;
  to: string | undefined;
}

export function LinkButton({
  active,
  className,
  disabled,
  icon,
  intent,
  minimal,
  options,
  params,
  rel,
  target,
  small,
  text,
  to,
}: IProps) {
  return (
    <Link
      className={cx(
        className,
        Classes.BUTTON,
        intent ? Classes.intentClass(intent) : undefined,
        {
          [Classes.ACTIVE]: active,
          [Classes.DISABLED]: disabled,
          [Classes.MINIMAL]: minimal,
          [Classes.SMALL]: small,
        }
      )}
      options={options}
      params={params}
      rel={rel}
      target={target}
      to={disabled ? undefined : to}
    >
      {icon ? <Icon icon={icon} /> : null}

      {text && <span className={Classes.BUTTON_TEXT}>{text}</span>}
    </Link>
  );
}

import { TabsRouteNode, TabsRouteNodeTab } from '_core/router5/tabsRouteNode';
import * as React from 'react';

import { TechrunAssignWagonsRoute } from './assignWagons';
import { TechrunAssignWagonsNewRoute } from './assignWagonsNew';

export default function TechrunAssignWagonsRouteTabs() {
  return (
    <TabsRouteNode nodeName="techruns.assignWagons">
      <TabsRouteNodeTab title="Распределение вагонов">
        <TechrunAssignWagonsRoute />
      </TabsRouteNodeTab>

      <TabsRouteNodeTab id="new" title="Новые вагоны к распределению">
        <TechrunAssignWagonsNewRoute />
      </TabsRouteNodeTab>
    </TabsRouteNode>
  );
}

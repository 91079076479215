import { useApiClient } from '_core/api/context';
import { FilterPopoverUi, useFilterPopover } from '_core/filters/filterPopover';
import { useAsyncData } from '_core/useAsyncData';
import { useDebouncedValue } from '_core/useDebouncedValue';
import { Button, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import * as React from 'react';

import { fetchManyRailroads, fetchRailroads, Railroad } from './api';
import * as css from './multiSelectFilter.module.css';

interface IProps {
  initialValue: string | undefined;
  onApply: (newRailroadIds: string) => void;
}

export function RailroadsMultiSelectFilter({ initialValue, onApply }: IProps) {
  const api = useApiClient();
  const [query, setQuery] = React.useState('');
  const debouncedQuery = useDebouncedValue(query, 400);

  const { data, isFetching } = useAsyncData([debouncedQuery, api], () =>
    !debouncedQuery
      ? Promise.resolve([])
      : fetchRailroads(api, {
          pageSize: 9,
          search: debouncedQuery,
        }).then(response => response.results)
  );

  const [selectedRailroads, setSelectedRailroads] = React.useState<Railroad[]>(
    []
  );

  React.useEffect(() => {
    const ids = initialValue ? initialValue.split(',').map(Number) : [];

    fetchManyRailroads(api, ids).then(setSelectedRailroads);
  }, [api, initialValue]);

  const filterPopover = useFilterPopover<string | undefined, number[]>(
    initialValue,
    onApply,
    {
      emptyValue: undefined,
      isEmpty: value => !value,
      deserialize: value => (value ? value.split(',').map(Number) : []),
      serialize: value => (value.length ? value.join(',') : undefined),
    }
  );

  return (
    <FilterPopoverUi api={filterPopover.ui} label="Дороги">
      <div className={css.main}>
        <MultiSelect
          noResults={
            isFetching ? (
              <Spinner />
            ) : (
              <MenuItem disabled text="Не удалось найти дороги по запросу" />
            )
          }
          initialContent={<MenuItem disabled text="Введите запрос" />}
          items={data ?? []}
          itemRenderer={(railroad, { modifiers, handleClick }) => {
            if (!modifiers.matchesPredicate) {
              return null;
            }

            return (
              <MenuItem
                active={modifiers.active}
                icon={
                  filterPopover.value.includes(railroad.id) ? 'tick' : 'blank'
                }
                key={railroad.id}
                shouldDismissPopover={false}
                text={railroad.name}
                onClick={handleClick}
              />
            );
          }}
          placeholder="Наименование дороги..."
          popoverProps={{
            targetTagName: 'div',
            usePortal: false,
            wrapperTagName: 'div',
          }}
          query={query}
          selectedItems={selectedRailroads}
          tagInputProps={{
            fill: true,
            rightElement:
              filterPopover.value.length > 0 ? (
                <Button
                  icon="cross"
                  minimal
                  onClick={() => {
                    setSelectedRailroads([]);
                    filterPopover.change([]);
                  }}
                />
              ) : undefined,
            onRemove: (_tag, indexToRemove) => {
              setSelectedRailroads(prevState =>
                prevState.filter((_item, index) => index !== indexToRemove)
              );

              filterPopover.change(
                filterPopover.value.filter(
                  (_id, index) => index !== indexToRemove
                )
              );
            },
          }}
          tagRenderer={railroad => railroad.name}
          onItemSelect={railroad => {
            setSelectedRailroads(prevState =>
              selectedRailroads.findIndex(p => p.id === railroad.id) === -1
                ? prevState.concat(railroad)
                : prevState
            );

            if (filterPopover.value.includes(railroad.id)) {
              filterPopover.change(
                filterPopover.value.filter(v => v !== railroad.id)
              );
            } else {
              filterPopover.change(filterPopover.value.concat([railroad.id]));
            }
          }}
          onQueryChange={setQuery}
        />
      </div>
    </FilterPopoverUi>
  );
}

import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { ListResponse } from '_core/api/types';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { plural } from '_core/strings/utils';
import { useToaster } from '_core/toaster/toasterContext';
import { useAsyncData } from '_core/useAsyncData';
import { Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useRoute } from 'react-router5';
import { TechrunWagonAssignment } from 'techrunAssignments/types';
import { parseWagonNumbers } from 'wagons/utils';

import { TechrunAssignmentsList } from './assignmentsList';
import {
  propsToFetch,
  TechrunAssignmentsListTableItem,
} from './assignmentsListTable';

export function TechrunAssignWagonsNewRoute() {
  const toaster = useToaster();
  const { route, router } = useRoute();
  const api = useApiClient();

  const { data, isFetching, refetch } = useAsyncData([api, route], async () => {
    const { meta, results } = await api.get<
      ListResponse<TechrunWagonAssignment>
    >('/techrun_wagons_for_assignment', {
      arrivalStation: route.params.arrivalStation,
      consignee: route.params.consignee,
      departureStation: route.params.departureStation,
      distanceLower: route.params.distanceLower,
      includeAssigned: route.params.includeAssigned === 'True',
      operationStation: route.params.operationStation,
      page: route.params.page,
      pageSize: route.params.pageSize,
      partner: route.params.partner,
      request: route.params.request,
      wagonNumber: route.params.wagonNumberss,
    });

    return {
      currentPage: meta.currentPage,
      pageSize: meta.pageSize,
      totalPages: meta.totalPages,
      items: await (fetchRelated(
        api,
        propsToFetch,
        results
      ) as unknown as Promise<TechrunAssignmentsListTableItem[]>),
    };
  });

  return (
    <>
      <FormattedTitle>Новые вагоны к распределению</FormattedTitle>

      <TechrunAssignmentsList
        data={data}
        isFetching={isFetching}
        isNewAssignmentsTab
        route={route}
        router={router}
        onAddNewWagonsSubmit={async values => {
          try {
            const wagonNumbers = parseWagonNumbers(values.wagons);

            const addedAssignments = await api.post<TechrunWagonAssignment[]>(
              '/techrun_wagons_for_assignment',
              {
                wagons: wagonNumbers,
              }
            );

            const addedCount = addedAssignments.length;
            const totalCount = wagonNumbers.length;

            toaster.show({
              intent: Intent.SUCCESS,

              message: plural(addedCount, [
                () => `Добавлен ${addedCount} вагон из ${totalCount}`,
                () => `Добавлено ${addedCount} вагона из ${totalCount}`,
                () => `Добавлено ${addedCount} вагонов из ${totalCount}`,
              ]),
            });

            refetch();
            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось добавить новые вагоны: Непредвиденная ошибка'
            );
          }
        }}
        onGroupNextRequestSubmit={async (ids, values) => {
          try {
            await api.post('/techrun_wagons_for_assignment/assign', values, {
              query: { ids: ids.join(',') },
            });

            refetch();
            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось распределить вагоны: Непредвиденная ошибка'
            );
          }
        }}
        onRowNextRequestSubmit={async (assignment, values) => {
          try {
            await api.post<TechrunWagonAssignment>(
              `/techrun_wagons_for_assignment/${assignment.id}/assign`,
              values
            );

            refetch();
            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось распределить вагоны: Непредвиденная ошибка'
            );
          }
        }}
      />
    </>
  );
}

import { deprecatedFilterInPopover } from '_deprecated/filterInPopover';
import * as React from 'react';
import { StationsAutocompleteInFormGroup } from 'stations/autocomplete';

import * as css from './stationFilterInPopover.module.css';

export const StationFilterInPopover = deprecatedFilterInPopover<
  string | undefined,
  {},
  number | null
>({
  clearValue: undefined,
  iconName: 'filter',
  label: 'Станция',
  extractInitialValue: ({ initialValue }) => {
    const nValue = Number(initialValue);

    return isFinite(nValue) ? nValue : null;
  },

  transformOutputValue: value => (value == null ? undefined : String(value)),
})(function StationFilterInPopoverRaw({ value, onChange }) {
  return (
    <div className={css.main}>
      <StationsAutocompleteInFormGroup
        autoFocus
        value={value}
        onChange={onChange}
      />
    </div>
  );
});

import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';

interface IProps {
  children: React.ReactNode;
  listParams: Record<string, unknown>;
  title: string;
}

export function ParksFormRouteWrapper({ children, listParams, title }: IProps) {
  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem label="Парки" params={listParams} to="parks" />
            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row stretch>{children}</Row>
      </VGrid>
    </>
  );
}

import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import {
  SelectForFinalForm,
  SelectInFormGroupForFinalForm,
} from '_core/inputs/select';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  Button,
  FormGroup,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import { IPartnerInline } from 'partners/api';
import { partnerToOption } from 'partners/utils';
import * as React from 'react';
import { techrunRateToOption } from 'techrunRates/types';

import { useGetPurchaseRatesForPartner } from '../purchaseRatesForPartner';
import * as css from './batchWagonsPopover.module.css';

interface IFormValues {
  batch: string;
  purchasePartner: string | null;
  purchaseRate: string | null;
}

function BatchWagonsPopoverContent({
  error,
  purchasePartner,
  purchasePartners,
  submitting,
  onClose,
  onSubmit,
}: {
  error: string;
  purchasePartner: string | null;
  purchasePartners: IPartnerInline[];
  submitting: boolean;
  onClose: () => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}) {
  const getPurchaseRatesForPartner = useGetPurchaseRatesForPartner();

  const purchaseRatesRequest = useAsyncData(
    [getPurchaseRatesForPartner, purchasePartner],
    () =>
      purchasePartner == null
        ? Promise.resolve(null)
        : getPurchaseRatesForPartner(Number(purchasePartner))
  );

  return (
    <BaseForm onSubmit={onSubmit}>
      <FormErrors error={error} />

      <FormGroupForFinalForm labelFor="batch" name="batch">
        <InputMultipleForFinalForm autoFocus id="batch" name="batch" rows={8} />
      </FormGroupForFinalForm>

      <SelectInFormGroupForFinalForm
        id="purchasePartner"
        label="Поставщик"
        name="purchasePartner"
        options={purchasePartners.map(partnerToOption)}
        withEmptyOption
      />

      <FormGroup label="Ставка от поставщика">
        <SelectForFinalForm
          disabled={!purchaseRatesRequest.data}
          fill
          id="purchaseRate"
          options={(purchaseRatesRequest.data || []).map(techrunRateToOption)}
          name="purchaseRate"
          withEmptyOption
        />
      </FormGroup>

      <Toolbar align="right">
        <Button disabled={submitting} text="Закрыть" onClick={onClose} />

        <Button
          disabled={submitting}
          intent={Intent.PRIMARY}
          text="Добавить"
          type="submit"
        />
      </Toolbar>
    </BaseForm>
  );
}

interface IProps {
  disabled: boolean | undefined;
  purchasePartners: IPartnerInline[];
  onSubmit: (values: IFormValues) => void;
}

export function BatchWagonsPopover({
  disabled,
  purchasePartners,
  onSubmit,
}: IProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Popover
      content={
        <FinalForm<IFormValues>
          initialValues={{
            batch: '',
            purchasePartner: null,
            purchaseRate: null,
          }}
          onSubmit={values => {
            onSubmit(values);
            setIsOpen(false);
          }}
        >
          {({ error, handleSubmit, submitError, submitting, values }) => (
            <BatchWagonsPopoverContent
              error={error || submitError}
              purchasePartner={values.purchasePartner}
              purchasePartners={purchasePartners}
              submitting={submitting}
              onClose={() => {
                setIsOpen(false);
              }}
              onSubmit={handleSubmit}
            />
          )}
        </FinalForm>
      }
      isOpen={isOpen}
      popoverClassName={css.root}
      position={Position.LEFT_TOP}
      onInteraction={setIsOpen}
    >
      <Button disabled={disabled} text="Добавить несколько" />
    </Popover>
  );
}

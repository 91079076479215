import { Nl2Br } from '_core/strings/nl2Br';
import { Callout, Intent } from '@blueprintjs/core';
import * as React from 'react';

import * as css from './formErrors.module.css';

interface IProps {
  error: string | undefined;
}

export function FormErrors({ error }: IProps) {
  return error ? (
    <Callout className={css.root} icon="error" intent={Intent.DANGER}>
      <Nl2Br text={error} />
    </Callout>
  ) : null;
}

import { isPlainObject } from '_core/fp/isPlainObject';

export function deepChangeCase(
  changeCaseFn: (s: string) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  if (isPlainObject(value)) {
    return Object.keys(value).reduce<Record<string, unknown>>((result, key) => {
      result[changeCaseFn(key)] = deepChangeCase(changeCaseFn, value[key]);

      return result;
    }, {});
  }

  if (Array.isArray(value)) {
    return value.map(item => deepChangeCase(changeCaseFn, item));
  }

  return value;
}

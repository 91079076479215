import { parseAWSUrl } from '_core/aws';
import { FileUploaderConsumer } from '_core/fileUploader/fileUploaderContext';
import { Ellipsis } from '_core/strings/ellipsis';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Button, FormGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useField } from 'react-final-form';

import { Dropzone } from './dropzone';
import * as css from './fileInputMultiple.module.css';

interface IProps {
  disabled?: boolean;
  editingLocked?: boolean;
  error?: string;
  id?: string;
  isInvalid?: boolean;
  label?: string;
  name?: string;
  value: string[];
  onChange: (newValue: string[] | string | null) => void;
}

export function FileInputMultiple({
  disabled,
  editingLocked,
  error,
  id,
  isInvalid,
  label,
  name,
  value,
  onChange,
}: IProps) {
  const cancelsRef = useRef<Array<() => void>>([]);

  useEffect(
    () => () => {
      cancelsRef.current.forEach(cancel => {
        cancel();
      });
    },
    []
  );

  return (
    <FormGroup
      helperText={isInvalid && error}
      intent={isInvalid ? Intent.DANGER : undefined}
      labelFor={id}
      label={label}
    >
      <Table
        emptyMessage="Нет файлов"
        items={value.map((awsUrl, index) => {
          const parsedAWSUrl = parseAWSUrl(awsUrl);

          return {
            awsUrl,
            filename: parsedAWSUrl.filename,
            id: parsedAWSUrl.id,
            index,
          };
        })}
      >
        <Column
          id="filename"
          label="Имя файла"
          cell={({ awsUrl, filename }) => (
            <Ellipsis
              component="a"
              download
              href={awsUrl}
              rel="noopener"
              target="_blank"
            >
              {filename}
            </Ellipsis>
          )}
        />

        <Column
          id="actions"
          width={52}
          cell={({ index }) => (
            <div>
              <Button
                disabled={disabled || editingLocked}
                icon="delete"
                intent={Intent.DANGER}
                minimal
                onClick={() => onChange(value.filter((_, i) => i !== index))}
              />
            </div>
          )}
        />
      </Table>

      {!disabled && !editingLocked && (
        <div className={css.dropzoneWrapper}>
          <FileUploaderConsumer>
            {fileUploader => (
              <Dropzone
                id={id}
                isInvalid={isInvalid}
                label="Загрузить файлы"
                multiple
                name={name}
                onFilesSelect={files => {
                  files.forEach(async file => {
                    const { cancel, promise } = fileUploader.uploadFile(file);
                    cancelsRef.current.push(cancel);

                    const response = await promise;
                    onChange([...value, response.file]);
                  });
                }}
              />
            )}
          </FileUploaderConsumer>
        </div>
      )}
    </FormGroup>
  );
}

export function FileInputMultipleForFinalForm({
  disabled,
  name,
  ...otherProps
}: Omit<IProps, 'value' | 'onChange'> & { name: string }) {
  const { input, meta } = useField<string[]>(name);
  const error = meta.error || meta.submitError;

  return (
    <FileInputMultiple
      disabled={meta.submitting || disabled}
      error={error}
      isInvalid={meta.touched && Boolean(error)}
      onChange={input.onChange}
      value={input.value}
      {...otherProps}
    />
  );
}

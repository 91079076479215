import { MiddlewareFactory } from 'router5/dist/types/router';

import { IRouterDependencies } from './types';

export const analyticsMiddleware: MiddlewareFactory<IRouterDependencies> =
  (_router, { analytics }) =>
  () => {
    analytics.trackPage(window.location.href);
    return true;
  };

import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Button, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import * as React from 'react';

export interface IEditPausesDialogFormValues {
  note: string;
}

interface IProps {
  initialValues: IEditPausesDialogFormValues;
  isOpen: boolean;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (
    values: IEditPausesDialogFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function EditPausesDialogForm({
  initialValues,
  isOpen,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ error, handleSubmit, submitError, submitting }) => (
        <DialogForm
          isOpen={isOpen}
          isSubmitting={submitting}
          title="Групповое редактирование пауз"
          onClose={onClose}
          onClosed={onClosed}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormErrors error={error || submitError} />

            <FormGroupForFinalForm label="Комментарий" name="note">
              <InputMultipleForFinalForm name="note" />
            </FormGroupForFinalForm>
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Сохранить"
                type="submit"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

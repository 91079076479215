import { Icon, Intent } from '@blueprintjs/core';
import * as React from 'react';

interface IProps {
  value: boolean;
}

export function BooleanIcon({ value }: IProps) {
  return (
    <Icon
      icon={value ? 'confirm' : 'delete'}
      intent={value ? Intent.SUCCESS : Intent.DANGER}
    />
  );
}

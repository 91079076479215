import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { without } from '_core/fp/without';
import { isNotNull } from '_core/isNotNull';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import dayjs from 'dayjs';
import * as React from 'react';
import { useState } from 'react';
import { useRouteNode } from 'react-router5';
import { WagonsActiveRequestsAlert } from 'requests/wagonsActiveRequestsAlert';
import { fetchTechrunRequests } from 'techrunRequests/api';
import { ITechrunRequest, TechrunRequestStatus } from 'techrunRequests/types';
import { fetchAllWagonTypes } from 'wagonTypes/api';

import { TechrunRequestForm } from './form/form';

interface IProps {
  techrunRequest: ITechrunRequest;
  updateTechrunRequestData: (newTechrunRequest: ITechrunRequest) => void;
}

export function TechrunRequestsViewMainTab({
  techrunRequest,
  updateTechrunRequestData,
}: IProps) {
  const { route, router } = useRouteNode('techruns.requests.view.main');
  const navigationLock = useFormNavigationLock('techruns.requests.view.main');
  const api = useApiClient();

  const wagonTypes = useAsyncData([api], () => fetchAllWagonTypes(api));

  const [editingLocked, setEditingLocked] = useState(true);

  const [alertState, setAlertState] = useState<{
    activeRequestsWithAddedWagons: ITechrunRequest[];
    addedWagons: string[];
    isOpen: boolean;
  } | null>({
    activeRequestsWithAddedWagons: [],
    addedWagons: [],
    isOpen: false,
  });

  return !wagonTypes.data ? (
    wagonTypes.isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <>
      <TechrunRequestForm
        editingLocked={editingLocked}
        initialValues={{
          ...techrunRequest,
          endDate: parseDate(techrunRequest.endDate),
          gu12Docs: techrunRequest.gu12Docs.map(number => ({ number })),
          sellRate: String(techrunRequest.sellRate),
          startDate: parseDate(techrunRequest.startDate),
          wagons: techrunRequest.wagons.map(wagon => ({
            ...wagon,
            purchasePartner:
              wagon.purchasePartner == null
                ? null
                : String(wagon.purchasePartner.id),
            purchaseRate:
              wagon.purchaseRate == null ? null : String(wagon.purchaseRate),
            purchaseRent:
              wagon.purchaseRent == null ? null : String(wagon.purchaseRent),
          })),
          wagonsRequired: String(techrunRequest.wagonsRequired),
          wagonType: String(techrunRequest.wagonType),
        }}
        navigationLock={navigationLock}
        techrunRequest={techrunRequest}
        wagonTypes={wagonTypes.data}
        onCancelEditing={
          editingLocked
            ? undefined
            : () => {
                setEditingLocked(true);
              }
        }
        onDuplicateRequest={() => {
          router.navigate('techruns.requests.create', {
            copyFrom: route.params.id,
            listParams: route.params.listParams,
          });
        }}
        onStartEditing={
          editingLocked
            ? () => {
                if (
                  // eslint-disable-next-line no-alert
                  window.confirm('Вы собираетесь изменить сохраненную заявку')
                ) {
                  setEditingLocked(false);
                }
              }
            : undefined
        }
        onSubmit={async values => {
          try {
            const updatedTechrunRequest = await api.put<ITechrunRequest>(
              `/techrun_requests/${route.params.id}`,
              {
                ...values,
                endDate:
                  values.endDate == null
                    ? null
                    : dayjs(values.endDate).format(DATE_FORMAT_API_DATE),
                gu12Docs: values.gu12Docs
                  .map(({ number }) => number)
                  .filter(isNotNull),
                startDate:
                  values.startDate == null
                    ? null
                    : dayjs(values.startDate).format(DATE_FORMAT_API_DATE),
              }
            );

            updateTechrunRequestData(updatedTechrunRequest);
            setEditingLocked(true);

            const addedWagons = without(
              techrunRequest.wagons.map(wagon => wagon.wagon),
              values.wagons.map(wagon => wagon.wagon)
            );

            if (addedWagons.length) {
              const requestsListResponse = await fetchAllPages(page =>
                fetchTechrunRequests(api, {
                  forDate: new Date(),
                  hasWagons: addedWagons,
                  page,
                  statuses: [
                    TechrunRequestStatus.Active,
                    TechrunRequestStatus.Created,
                  ],
                })
              );

              const activeRequestsWithAddedWagons =
                requestsListResponse.results.filter(
                  request => request.id !== Number(route.params.id)
                );

              if (activeRequestsWithAddedWagons.length !== 0) {
                setAlertState({
                  activeRequestsWithAddedWagons,
                  addedWagons,
                  isOpen: true,
                });
              }
            }

            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось изменить заявку: Непредвиденная ошибка'
            );
          }
        }}
      />

      {alertState && (
        <WagonsActiveRequestsAlert
          activeRequestsWithAddedWagons={
            alertState.activeRequestsWithAddedWagons
          }
          addedWagons={alertState.addedWagons}
          isOpen={alertState.isOpen}
          itemRoute="techruns.requests.view"
          onClosed={() => {
            setAlertState(null);
          }}
          onConfirm={() => {
            setAlertState(
              prevState =>
                prevState && {
                  ...prevState,
                  isOpen: false,
                }
            );
          }}
        />
      )}
    </>
  );
}

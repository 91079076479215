import { BooleanIcon } from '_core/booleanIcon';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { ListTableColumn } from '_core/react-window/listTable';
import { TreeTableColumn } from '_core/react-window/treeTable';
import { createListToTreeTableColumnAdapter } from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import dayjs from 'dayjs';
import { IPartnerSerialized } from 'partners/api';
import * as React from 'react';

const isClientColumn: ListTableColumn<IPartnerSerialized> = {
  id: 'isClient',
  label: 'Клиент',
  defaultWidth: 180,
  sortable: true,
  copyCellContent: () => '',
  renderCellContent: partner => <BooleanIcon value={partner.isClient} />,
};

const isSupplierColumn: ListTableColumn<IPartnerSerialized> = {
  id: 'isSupplier',
  label: 'Поставщик',
  defaultWidth: 180,
  sortable: true,
  copyCellContent: () => '',
  renderCellContent: partner => <BooleanIcon value={partner.isSupplier} />,
};

const modifiedColumn: ListTableColumn<IPartnerSerialized> = {
  id: 'modified',
  label: 'Изменён',
  defaultWidth: 130,
  sortable: true,
  copyCellContent: partner =>
    dayjs(partner.modified).format(DATE_FORMAT_DATETIME),
  renderCellContent: partner =>
    dayjs(partner.modified).format(DATE_FORMAT_DATETIME),
};

export function getPartnersListTableColumns({
  listParams,
  usersById,
}: {
  listParams: string;
  usersById: Record<number, IUser>;
}) {
  const shortNameColumn: ListTableColumn<IPartnerSerialized> = {
    id: 'shortName',
    label: 'Наименование краткое',
    defaultWidth: 350,
    sortable: true,
    copyCellContent: partner => partner.shortName,
    renderCellContent: partner => (
      <Link params={{ id: partner.id, listParams }} to="partners.edit">
        {partner.shortName}
      </Link>
    ),
  };

  const managerColumn: ListTableColumn<IPartnerSerialized> = {
    id: 'manager',
    label: 'Ответственный менеджер',
    defaultWidth: 250,
    sortable: true,
    copyCellContent: partner => formatUserName(usersById[partner.manager]),
    renderCellContent: partner => formatUserName(usersById[partner.manager]),
  };

  return [
    shortNameColumn,
    isClientColumn,
    isSupplierColumn,
    managerColumn,
    modifiedColumn,
  ];
}

export type PartnersTreeTableNodeData =
  | { kind: 'manager'; manager: IUser; partnersCount: number }
  | { kind: 'partner'; partner: IPartnerSerialized };

const adaptColumn = createListToTreeTableColumnAdapter<
  IPartnerSerialized,
  PartnersTreeTableNodeData
>(nodeData => (nodeData.kind === 'partner' ? nodeData.partner : null));

export function getPartnersTreeTableColumns({
  listParams,
}: {
  listParams: string;
}) {
  const nameColumn: TreeTableColumn<PartnersTreeTableNodeData> = {
    id: 'name',
    label: 'Наименование',
    defaultWidth: 350,
    sortable: true,
    expandable: true,
    copyCellContent: node => {
      switch (node.data.kind) {
        case 'manager': {
          const { manager, partnersCount } = node.data;

          return `${formatUserName(manager)} (${partnersCount})`;
        }
        case 'partner':
          return node.data.partner.shortName;
      }
    },
    renderCellContent: node => {
      switch (node.data.kind) {
        case 'manager': {
          const { manager, partnersCount } = node.data;

          return (
            <>
              {formatUserName(manager)} ({partnersCount})
            </>
          );
        }
        case 'partner': {
          const { partner } = node.data;

          return (
            <Link params={{ id: partner.id, listParams }} to="partners.edit">
              {partner.shortName}
            </Link>
          );
        }
      }
    },
  };

  return [nameColumn].concat(
    [isClientColumn, isSupplierColumn, modifiedColumn].map(adaptColumn)
  );
}

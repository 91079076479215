import { SelectOption } from '_core/inputs/select';

import { IParkTemplate } from './api';

export function parkTemplateToSelectOption(
  parkTemplate: IParkTemplate
): SelectOption {
  return {
    label: parkTemplate.name,
    value: String(parkTemplate.id),
  };
}

import { Toolbar } from '_core/toolbar';
import React from 'react';

import { LegendItem } from '../../_core/legend';
import {
  getWeighingAlertColor,
  getWeighingAlertLabel,
  weighingAlerts,
} from '../../weighingRecords/utils';

const WeighingLegend = () => {
  return (
    <Toolbar>
      {weighingAlerts.map(alert => (
        <LegendItem
          key={alert}
          backgroundColor={getWeighingAlertColor(alert)}
          label={getWeighingAlertLabel(alert)}
        />
      ))}
    </Toolbar>
  );
};

export default WeighingLegend;

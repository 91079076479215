import cx from 'classnames';
import * as React from 'react';

import * as css from './toolbar.module.css';

interface IProps {
  align?: 'center' | 'right';
  children: React.ReactNode;
  noWrap?: boolean;
  valign?: 'center';
}

export function Toolbar({ align, children, noWrap, valign }: IProps) {
  return (
    <div
      className={cx(css.root, {
        [css.root_align_center]: align === 'center',
        [css.root_align_right]: align === 'right',
        [css.root_noWrap]: noWrap,
        [css.root_valign_center]: valign === 'center',
      })}
    >
      {React.Children.map(
        children,
        child => child && <div className={css.item}>{child}</div>
      )}
    </div>
  );
}

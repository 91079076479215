import prettyBytes from '_core/prettyBytes';
import { Intent, ProgressBar } from '@blueprintjs/core';
import * as React from 'react';

import { IUploadFileResponse } from './fileUploaderContext';
import * as css from './fileUploaderToastMessage.module.css';

interface IProps {
  errorMessage?: string;
  file: File;
  intent?: Intent;
  progress?: number;
  uploadedFile?: IUploadFileResponse;
}

export function FileUploaderToastMessage({
  errorMessage,
  file,
  intent,
  progress,
  uploadedFile,
}: IProps) {
  return uploadedFile ? (
    <span>Файл &quot;{uploadedFile.name}&quot; успешно загружен.</span>
  ) : (
    <div>
      <div className={css.filename}>{file.name}</div>

      {errorMessage ? (
        <strong>{errorMessage}</strong>
      ) : (
        <div>
          <ProgressBar intent={intent} value={progress} />

          {progress ? (
            <div className={css.progressText}>
              <span>{(100 * progress).toFixed(2)}%</span>

              <span className={css.progressTextSize}>
                {prettyBytes(progress * file.size)} / {prettyBytes(file.size)}
              </span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

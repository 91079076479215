import { ListTable } from '_core/react-window/listTable';
import { ISorting } from '_core/sorting';
import * as React from 'react';
import { useCallback, useMemo } from 'react';

import {
  getOurDocumentsListTableColumns,
  IDocumentsStatusesTableItem,
} from './columns';

interface IProps {
  documentsStatusesItems: IDocumentsStatusesTableItem[];
  generateFilterLink: (params: Record<string, string | undefined>) => {
    params: Record<string, string | undefined>;
    to: string;
  };
  isFetching: boolean;
  lineNumbersStart: number;
  sorting: ISorting;
  onSortingChange: (newSorting: ISorting) => void;
}

export function OurDocumentsListTable({
  documentsStatusesItems,
  generateFilterLink,
  isFetching,
  lineNumbersStart,
  sorting,
  onSortingChange,
}: IProps) {
  const columns = useMemo(
    () => getOurDocumentsListTableColumns({ generateFilterLink }),
    [generateFilterLink]
  );

  const getItemId = useCallback(
    (document: IDocumentsStatusesTableItem) => String(document.id),
    []
  );

  return (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      isFetching={isFetching}
      items={documentsStatusesItems}
      lineNumbersStart={lineNumbersStart}
      sorting={sorting}
      onSortingChange={onSortingChange}
    />
  );
}

import { useApiClient } from '_core/api/context';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import * as React from 'react';
import { useRef } from 'react';
import { useRouter } from 'react-router5';
import {
  ISuppliersDocumentDetail,
  updateSuppliersDocument,
} from 'suppliersDocuments/api';

import { SubmitTrigger, SuppliersDocumentsFromFileForm } from './form';

interface IProps {
  listParams: Record<string, unknown>;
  suppliersDocument: ISuppliersDocumentDetail;
  updateSuppliersDocumentData: (
    newSuppliersDocument: ISuppliersDocumentDetail
  ) => void;
}

export function SuppliersDocumentsFromFileEditMainTab({
  listParams,
  suppliersDocument,
  updateSuppliersDocumentData,
}: IProps) {
  const router = useRouter();
  const navigationLock = useFormNavigationLock(
    'suppliersDocumentsFromFile.edit.main'
  );
  const api = useApiClient();

  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <SuppliersDocumentsFromFileForm
      initialValues={suppliersDocument}
      navigationLock={navigationLock}
      setSubmitTrigger={submitTrigger => {
        submitTriggerRef.current = submitTrigger;
      }}
      suppliersDocument={suppliersDocument}
      onSubmit={async values => {
        try {
          const submitTrigger = submitTriggerRef.current;

          switch (submitTrigger) {
            case 'save':
              await updateSuppliersDocument(api, suppliersDocument.id, values);
              navigationLock.unlock();
              router.navigate('suppliersDocumentsFromFile', listParams);
              break;
            case 'saveAndContinue':
              updateSuppliersDocumentData(
                await updateSuppliersDocument(api, suppliersDocument.id, values)
              );
              break;
            default:
              throw new Error(`Unexpected submitTrigger: "${submitTrigger}"`);
          }

          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось обновить документ: Непредвиденная ошибка'
          );
        }
      }}
    />
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { DocumentStatus } from 'documents/types';
import { IPartnerInline } from 'partners/api';
import { TechrunProtocolRateType } from 'techrunProtocols/types';
import { TripType } from 'tripTypes/types';
import { VatRate } from 'vatRates/vatRates';

const BASE_ENDPOINT = '/techrun_protocols_sell';

interface ITechrunProtocolSellRateSerialized {
  anyArrivalStation: boolean;
  anyDepartureStation: boolean;
  arrivalStation: number;
  confirmedTransportationsCount: number;
  cost: string;
  costTotal: string;
  departureStation: number;
  id: number;
  partner: IPartnerInline;
  protocol: number;
  protocolName: string;
  tariffEmpty: string;
  tariffEmptyTotal: string;
  tariffEmptyVat: VatRate;
  tariffEmptyVatValue: string;
  tariffLoaded: string;
  tariffLoadedTotal: string;
  tariffLoadedVat: VatRate;
  tariffLoadedVatValue: string;
  vatRate: string;
  vatValue: string;
  weight: null;
}

interface ITechrunProtocolSellSerialized {
  additionalDowntimeRate: string;
  author: number | null;
  authorModified: number | null;
  cargo: number | null;
  contract: number;
  created: string;
  date: string;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeRateTotal: string;
  downtimeRateVatValue: string;
  downtimeVatRate: VatRate;
  endDate: string;
  files: string[];
  hasEmptyTariff: boolean;
  hasIntEmptyTariff: boolean;
  hasIntLoadedTariff: boolean;
  hasLoadedTariff: boolean;
  hasNoVatRate: boolean;
  hasUseWagon: boolean;
  id: number;
  modified: string;
  name: string;
  note: string;
  number: string;
  partner: number;
  partnerSign: number | null;
  protocolName: string;
  protocolNumber: string;
  rateType: TechrunProtocolRateType;
  rates: ITechrunProtocolSellRateSerialized[];
  signerAuthority: string;
  startDate: string;
  status: DocumentStatus;
  tripType: TripType;
  vatRate: VatRate;
  wagonType: number;
}

export interface ITechrunProtocolSell
  extends Omit<
    ITechrunProtocolSellSerialized,
    'created' | 'date' | 'endDate' | 'modified' | 'startDate'
  > {
  created: Date;
  date: Date;
  endDate: Date;
  modified: Date;
  startDate: Date;
}

function deserializeTechrunProtocolSell({
  created,
  date,
  endDate,
  modified,
  startDate,
  ...otherProps
}: ITechrunProtocolSellSerialized): ITechrunProtocolSell {
  return {
    ...otherProps,
    created: parseDate(created),
    date: parseDate(date),
    endDate: parseDate(endDate),
    modified: parseDate(modified),
    startDate: parseDate(startDate),
  };
}

export enum TechrunProtocolsSellSortingField {
  Date = 'date',
  EndDate = 'endDate',
  Name = 'name',
  OrderingNumber = 'orderingNumber',
  StartDate = 'startDate',
}

interface IFetchTechrunProtocolsSellParams {
  contract?: number;
  isArchived?: boolean;
  sorting?: Array<ISorting<TechrunProtocolsSellSortingField>>;
  page?: number;
}

export async function fetchTechrunProtocolsSell(
  api: ApiClient,
  { contract, isArchived, sorting, page }: IFetchTechrunProtocolsSellParams = {}
): Promise<ListResponse<ITechrunProtocolSell>> {
  const response = await api.get<ListResponse<ITechrunProtocolSellSerialized>>(
    BASE_ENDPOINT,
    {
      contract,
      isArchived,
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      page,
    }
  );

  return {
    ...response,
    results: response.results.map(deserializeTechrunProtocolSell),
  };
}

interface ICreateOrUpdateTechrunProtocolSellParamsRate {
  arrivalStation: number | null;
  confirmedTransportationsCount: number;
  cost: string;
  costTotal: string;
  departureStation: number | null;
  tariffEmpty: string | null;
  tariffLoaded: string | null;
  vatValue: string;
}

interface ICreateOrUpdateTechrunProtocolSellParams {
  additionalDowntimeRate: string;
  cargo: number | null;
  date: Date | null;
  daysLoad: string;
  daysUnload: string;
  downtimeLimit: string;
  downtimeRate: string;
  downtimeRateTotal: string;
  downtimeRateVatValue: string;
  downtimeVatRate: VatRate;
  endDate: Date | null;
  files: string[];
  hasEmptyTariff: boolean;
  hasIntEmptyTariff: boolean;
  hasIntLoadedTariff: boolean;
  hasLoadedTariff: boolean;
  hasUseWagon: boolean;
  name: string;
  number: string;
  partnerSign: number | null;
  rates: ICreateOrUpdateTechrunProtocolSellParamsRate[];
  rateType: TechrunProtocolRateType;
  signerAuthority: string;
  startDate: Date | null;
  status: DocumentStatus;
  tripType: TripType;
  vatRate: VatRate | null;
  wagonType: number | null;
}

function serializeCreateOrUpdateTechrunProtocolSellParams({
  date,
  endDate,
  startDate,
  ...otherParams
}: ICreateOrUpdateTechrunProtocolSellParams) {
  return {
    ...otherParams,
    date: date && dayjs(date).format(DATE_FORMAT_API_DATE),
    endDate: endDate && dayjs(endDate).format(DATE_FORMAT_API_DATE),
    startDate: startDate && dayjs(startDate).format(DATE_FORMAT_API_DATE),
  };
}

export async function createTechrunProtocolSell(
  api: ApiClient,
  params: ICreateOrUpdateTechrunProtocolSellParams
) {
  const createdProtocol = await api.post<ITechrunProtocolSellSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateTechrunProtocolSellParams(params)
  );

  return deserializeTechrunProtocolSell(createdProtocol);
}

export async function updateTechrunProtocolSell(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateTechrunProtocolSellParams
) {
  const updatedProtocol = await api.put<ITechrunProtocolSellSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateTechrunProtocolSellParams(params)
  );

  return deserializeTechrunProtocolSell(updatedProtocol);
}

export async function uploadTechrunProtocolSellRates(
  api: ApiClient,
  id: number,
  file: string
) {
  const updatedProtocol = await api.post<ITechrunProtocolSellSerialized>(
    `${BASE_ENDPOINT}/${id}/upload_rates_xlsx`,
    { file }
  );

  return deserializeTechrunProtocolSell(updatedProtocol);
}

export function getTechrunProtocolSellDocxDownloadLink(
  api: ApiClient,
  id: number
) {
  return api.buildApiUrl(`${BASE_ENDPOINT}/${id}/download_docx`);
}

import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { DateRange } from '@blueprintjs/datetime';
import dayjs from 'dayjs';

export function deserializeDateRangeFilterValue(
  value: string | undefined
): DateRange {
  const emptyRange: DateRange = [null, null];

  if (!value) {
    return emptyRange;
  }

  const parts = value.split('::');

  if (parts.length !== 2) {
    return emptyRange;
  }

  const from = dayjs(parts[0]);
  const to = dayjs(parts[1]);

  if (!from.isValid() || !to.isValid()) {
    return emptyRange;
  }

  return [from.toDate(), to.toDate()];
}

export function serializeDateRangeFilterValue([from, to]: DateRange) {
  return from == null || to == null
    ? undefined
    : [
        dayjs(from).format(DATE_FORMAT_API_DATE),
        dayjs(to).format(DATE_FORMAT_API_DATE),
      ].join('::');
}

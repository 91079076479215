import { useApiClient } from '_core/api/context';
import { range } from '_core/fp/range';
import { isNotNull } from '_core/isNotNull';
import { selectFavoriteParks } from '_core/me/me';
import { useAsyncData } from '_core/useAsyncData';
import { useDebouncedValue } from '_core/useDebouncedValue';
import { useErpSelector } from '_erp/redux';
import {
  Button,
  ButtonGroup,
  Classes,
  Intent,
  MenuItem,
  Popover,
  Position,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import * as React from 'react';

import { fetchManyParks, fetchParks, Park } from './api';
import * as css from './parksFilter.module.css';

interface Props {
  initialValue: string | undefined;
  onApply: (parks: string | undefined) => void;
}

export function ParksFilter({ initialValue, onApply }: Props) {
  const api = useApiClient();
  const [query, setQuery] = React.useState('');

  const deserializedInitialValue = React.useMemo(
    () => (initialValue ? initialValue.split(',').map(Number) : []),
    [initialValue]
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState<number[]>(deserializedInitialValue);
  const [selectedParks, setSelectedParks] = React.useState<Park[]>([]);

  const updateSelectedParks = React.useCallback(
    (ids: number[]) => {
      fetchManyParks(api, ids).then(initiallySelectedParks => {
        setSelectedParks(initiallySelectedParks);
      });
    },
    [api]
  );

  React.useEffect(() => {
    updateSelectedParks(deserializedInitialValue);
    setValue(deserializedInitialValue);
  }, [deserializedInitialValue, updateSelectedParks]);

  const debouncedQuery = useDebouncedValue(query, 400);
  const queryResults = useAsyncData([api, debouncedQuery], async () =>
    debouncedQuery
      ? (await fetchParks(api, { pageSize: 9, search: debouncedQuery })).results
      : []
  );

  const favoriteParkIds = useErpSelector(selectFavoriteParks);

  const isParkSelected = (park: Park) => value.includes(park.id);

  const parks = selectedParks.concat(
    queryResults.data
      ? queryResults.data.filter(
          park => selectedParks.findIndex(p => p.id === park.id) === -1
        )
      : []
  );

  const isClear = value.length === 0;

  return (
    <ButtonGroup>
      <Popover
        content={
          <div>
            <div className={css.main}>
              <div className={css.favoriteButton}>
                <Button
                  fill
                  intent={Intent.SUCCESS}
                  text="Выбрать избранные"
                  onClick={() => {
                    setValue(favoriteParkIds);
                    updateSelectedParks(favoriteParkIds);
                  }}
                />
              </div>

              <MultiSelect
                noResults={
                  queryResults.isFetching ? (
                    range(0, 10).map(n => (
                      <MenuItem
                        key={n}
                        disabled
                        text="Загрузка..."
                        textClassName={Classes.SKELETON}
                      />
                    ))
                  ) : (
                    <MenuItem disabled text="Не удалось найти парки" />
                  )
                }
                initialContent={<MenuItem disabled text="Введите запрос" />}
                itemPredicate={(q, park, _index, exactMatch) =>
                  exactMatch
                    ? park.name === q
                    : park.name.toLowerCase().includes(q.toLowerCase())
                }
                items={parks}
                itemRenderer={(park, { modifiers, handleClick }) =>
                  modifiers.matchesPredicate ? (
                    <MenuItem
                      active={modifiers.active}
                      icon={isParkSelected(park) ? 'tick' : 'blank'}
                      key={park.id}
                      shouldDismissPopover={false}
                      text={park.name}
                      onClick={handleClick}
                    />
                  ) : null
                }
                placeholder="Поиск..."
                popoverProps={{
                  targetTagName: 'div',
                  usePortal: false,
                  wrapperTagName: 'div',
                }}
                query={query}
                selectedItems={value
                  .map(id => parks.find(park => park.id === id))
                  .filter(isNotNull)}
                tagInputProps={{
                  fill: true,

                  rightElement:
                    value.length > 0 ? (
                      <Button
                        icon="cross"
                        minimal
                        onClick={() => {
                          setValue([]);
                        }}
                      />
                    ) : undefined,

                  onRemove: (_tag, indexToRemove) => {
                    setValue(
                      value.filter((_id, index) => index !== indexToRemove)
                    );
                  },
                }}
                tagRenderer={park => park.name}
                onItemSelect={park => {
                  setSelectedParks(prevState =>
                    selectedParks.findIndex(p => p.id === park.id) === -1
                      ? prevState.concat(park)
                      : prevState
                  );

                  setValue(
                    isParkSelected(park)
                      ? value.filter(v => v !== park.id)
                      : value.concat([park.id])
                  );
                }}
                onQueryChange={setQuery}
              />
            </div>

            <Button
              fill
              intent={Intent.PRIMARY}
              text="Применить"
              onClick={() => {
                onApply(value.length ? value.join(',') : undefined);
                setIsOpen(false);
              }}
            />
          </div>
        }
        isOpen={isOpen}
        position={Position.BOTTOM}
        onInteraction={newIsOpen => {
          if (newIsOpen) {
            setValue(deserializedInitialValue);
          }

          setIsOpen(newIsOpen);
        }}
      >
        <Button
          icon="filter"
          intent={isClear ? undefined : Intent.DANGER}
          text="Парки"
        />
      </Popover>

      {!isClear && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => {
            onApply(undefined);
          }}
        />
      )}
    </ButtonGroup>
  );
}

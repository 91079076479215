import invariant from 'tiny-invariant';

function createV0() {
  function cols() {
    return { columnsWidth: null, showColumns: null };
  }

  function preset() {
    return {
      downtimes: { parks: cols() },
      expeditions: { transportations: { list: cols() } },
      parks: { dislocations: cols(), downtimes: cols(), repairs: cols() },
      repairs: cols(),
      techruns: { transportations: { list: cols() } },
      wagons: { dislocationHistory: cols(), list: cols() },
    };
  }

  return {
    preset1: preset(),
    preset2: preset(),
    preset3: preset(),
  };
}

function migrateV1(prefs: ReturnType<typeof createV0>) {
  function migrateColumnPrefs(
    getPreset: (preset: typeof prefs[keyof typeof prefs]) => {
      columnsWidth: typeof prefs[keyof typeof prefs]['downtimes']['parks']['columnsWidth'];
      showColumns: typeof prefs[keyof typeof prefs]['downtimes']['parks']['showColumns'];
    }
  ) {
    const presets = [prefs.preset1, prefs.preset2, prefs.preset3].map(
      getPreset
    );

    return {
      columnsWidth: presets.map(p => p.columnsWidth ?? null),
      showColumns: presets.map(p => p.showColumns ?? null),
    };
  }

  return {
    version: 1 as const,
    downtimes: {
      parks: migrateColumnPrefs(p => p.downtimes.parks),
    },
    expeditions: {
      transportations: {
        list: migrateColumnPrefs(p => p.expeditions.transportations.list),
      },
    },
    parks: {
      dislocations: migrateColumnPrefs(p => p.parks.dislocations),
      downtimes: migrateColumnPrefs(p => p.parks.downtimes),
      repairs: migrateColumnPrefs(p => p.parks.repairs),
    },
    repairs: migrateColumnPrefs(p => p.repairs),
    techruns: {
      transportations: {
        list: migrateColumnPrefs(p => p.techruns.transportations.list),
      },
    },
    wagons: {
      dislocationHistory: migrateColumnPrefs(p => p.wagons.dislocationHistory),
      list: migrateColumnPrefs(p => p.wagons.list),
    },
  };
}

function migrateV2(prefs: ReturnType<typeof migrateV1>) {
  return {
    ...prefs,
    version: 2 as const,
    expeditions: {
      ...prefs.expeditions,
      requests: { list: { startMonths: null } },
    },
    techruns: { ...prefs.techruns, requests: { list: { startMonths: null } } },
  };
}

function migrateV3(prefs: ReturnType<typeof migrateV2>) {
  return {
    ...prefs,
    version: 3 as const,
    downtimes: {
      ...prefs.downtimes,
      columnsWidth: [null, null, null],
      showColumns: [null, null, null],
    },
  };
}

function migrateV4(prefs: ReturnType<typeof migrateV3>) {
  return {
    ...prefs,
    version: 4 as const,
    downtimes: {
      ...prefs.downtimes,
      edit: {
        columnsWidth: [null, null, null],
        showColumns: [null, null, null],
      },
    },
  };
}

function migrateV5(prefs: ReturnType<typeof migrateV4>) {
  return { ...prefs, version: 5 as const, favoriteDocs: false };
}

function migrateV6(prefs: ReturnType<typeof migrateV5>) {
  return {
    ...prefs,
    version: 6 as const,
    home: {
      widgetColumns: [
        { enabledWidgets: ['newTransportationsWidget'], disabledWidgets: [] },
        { enabledWidgets: ['wagonsStatesWidget'], disabledWidgets: [] },
        { enabledWidgets: [], disabledWidgets: [] },
      ],
    },
  };
}

function migrateV7(prefs: ReturnType<typeof migrateV6>) {
  return {
    ...prefs,
    version: 7 as const,
    home: {
      ...prefs.home,
      widgetColumns: prefs.home.widgetColumns.map((column, index) =>
        index === 2
          ? {
              ...column,
              enabledWidgets: column.enabledWidgets,
              disabledWidgets: [
                ...column.disabledWidgets,
                'parksAlertsWidget',
                'wagonsAlertsWidget',
                'techrunRequestsDowntimesWidget',
              ],
            }
          : column
      ),
    },
  };
}

export type PreferencesVersions =
  | { [TKey in never]: never } // fresh empty state
  | ReturnType<typeof createV0>
  | ReturnType<typeof migrateV1>
  | ReturnType<typeof migrateV2>
  | ReturnType<typeof migrateV3>
  | ReturnType<typeof migrateV4>
  | ReturnType<typeof migrateV5>
  | ReturnType<typeof migrateV6>
  | ReturnType<typeof migrateV7>;

export function migratePreferences(
  prefs: PreferencesVersions
): ReturnType<typeof migrateV7> {
  if (Object.keys(prefs).length === 0) {
    return migratePreferences(createV0());
  } else if (!('version' in prefs)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return migratePreferences(migrateV1(prefs as any));
  } else if (prefs.version === 1) {
    return migratePreferences(migrateV2(prefs));
  } else if (prefs.version === 2) {
    return migratePreferences(migrateV3(prefs));
  } else if (prefs.version === 3) {
    return migratePreferences(migrateV4(prefs));
  } else if (prefs.version === 4) {
    return migratePreferences(migrateV5(prefs));
  } else if (prefs.version === 5) {
    return migratePreferences(migrateV6(prefs));
  } else if (prefs.version === 6) {
    return migratePreferences(migrateV7(prefs));
  }

  invariant(
    prefs.version === 7,
    `Unexpected preferences shape: ${JSON.stringify(prefs)}`
  );

  return prefs;
}

import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { parseAWSUrl } from '_core/aws';
import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { SearchForm } from '_core/forms/searchForm';
import { LinkStyledButton } from '_core/linkStyledButton';
import { formatMoney } from '_core/money/formatMoney';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  Boundary,
  Classes,
  Intent,
  Menu,
  OverflowList,
  Popover,
} from '@blueprintjs/core';
import cx from 'classnames';
import dayjs from 'dayjs';
import {
  expeditionTransportationStatusFromGroup,
  IExpeditionTransportation,
} from 'expeditionTransportations/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { IPartnerSerialized } from 'partners/api';
import * as React from 'react';
import { Fragment, useCallback, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import {
  fetchSuppliersDocuments,
  SuppliersDocumentsSortingField,
} from 'suppliersDocuments/api';
import {
  getSuppliersDocumentStateLabel,
  ISuppliersDocumentSerialized,
} from 'suppliersDocuments/types';
import {
  ITechrunTransportation,
  techrunTransportationStatusFromGroup,
} from 'techrunTransportations/types';

interface ListTableItem
  extends Omit<
    ISuppliersDocumentSerialized,
    'partner' | 'relatedTechrunTransps' | 'relatedExpeditionTransps'
  > {
  partner: IPartnerSerialized;
  relatedExpeditionTransps: IExpeditionTransportation[];
  relatedTechrunTransps: ITechrunTransportation[];
}

const propsToFetch = {
  partner: '/partners',
  relatedTechrunTransps: '/techrun_transportations',
  relatedExpeditionTransps: '/expeditions_transportations',
};

export default function SuppliersDocumentsListRoute() {
  const { route, router } = useRouteNode('suppliersDocuments');
  const api = useApiClient();

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'date',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const { data, isFetching } = useAsyncData([api, route, sorting], async () => {
    const suppliersDocumentsSorting: Array<
      ISorting<SuppliersDocumentsSortingField>
    > = [];

    if (sorting.field === 'date') {
      suppliersDocumentsSorting.push({
        field: SuppliersDocumentsSortingField.Date,
        direction: sorting.direction,
      });
    } else if (sorting.field === 'number') {
      suppliersDocumentsSorting.push({
        field: SuppliersDocumentsSortingField.Number,
        direction: sorting.direction,
      });
    } else if (sorting.field === 'partner') {
      suppliersDocumentsSorting.push({
        field: SuppliersDocumentsSortingField.Partner,
        direction: sorting.direction,
      });
    } else {
      throw new Error(`Unexpected sorting field: "${sorting.field}"`);
    }

    const { meta, results } = await fetchSuppliersDocuments(api, {
      fromFile: false,
      ids: route.params.ids
        ? (route.params.ids as string).split(',').map(Number).filter(isFinite)
        : undefined,
      page: isFinite(route.params.page) ? Number(route.params.page) : undefined,
      pageSize: isFinite(route.params.pageSize)
        ? Number(route.params.pageSize)
        : undefined,
      search: route.params.search,
      sorting: suppliersDocumentsSorting,
    });

    return {
      currentPage: meta.currentPage,
      pageSize: meta.pageSize,
      totalPages: meta.totalPages,
      suppliersDocuments: await (fetchRelated(
        api,
        propsToFetch,
        results
      ) as unknown as Promise<ListTableItem[]>),
    };
  });

  const listParams = JSON.stringify(route.params);

  const columns = useMemo(
    (): Array<ListTableColumn<ListTableItem>> => [
      {
        id: 'date',
        label: 'Дата',
        defaultWidth: 80,
        sortable: true,
        copyCellContent: suppliersDocument =>
          suppliersDocument.date == null
            ? ''
            : dayjs(suppliersDocument.date).format(DATE_FORMAT_DATE),
        renderCellContent: suppliersDocument =>
          suppliersDocument.date == null ? null : (
            <Ellipsis
              component={Link}
              params={{ id: suppliersDocument.id, listParams }}
              to="suppliersDocuments.edit"
            >
              {dayjs(suppliersDocument.date).format(DATE_FORMAT_DATE)}
            </Ellipsis>
          ),
      },
      {
        id: 'number',
        label: 'Номер документа',
        defaultWidth: 150,
        sortable: true,
        copyCellContent: suppliersDocument =>
          suppliersDocument.number || '<не указан>',
        renderCellContent: suppliersDocument => (
          <Ellipsis
            component={Link}
            params={{ id: suppliersDocument.id, listParams }}
            to="suppliersDocuments.edit"
          >
            {suppliersDocument.number || '<не указан>'}
          </Ellipsis>
        ),
      },
      {
        id: 'partner',
        label: 'Контрагент',
        defaultWidth: 250,
        sortable: true,
        copyCellContent: suppliersDocument =>
          suppliersDocument.partner.shortName,
        renderCellContent: suppliersDocument => (
          <Ellipsis
            component={Link}
            params={{ id: suppliersDocument.partner.id }}
            to="partners.edit"
          >
            {suppliersDocument.partner.shortName}
          </Ellipsis>
        ),
      },
      {
        id: 'state',
        label: 'Статус закрытия',
        defaultWidth: 150,
        copyCellContent: suppliersDocument =>
          getSuppliersDocumentStateLabel(suppliersDocument.state),
        renderCellContent: suppliersDocument => (
          <Ellipsis component="span">
            {getSuppliersDocumentStateLabel(suppliersDocument.state)}
          </Ellipsis>
        ),
      },
      {
        id: 'relatedTechrunTransps',
        label: 'Связанные отправки техрейса',
        defaultWidth: 280,
        copyCellContent: suppliersDocument =>
          suppliersDocument.relatedTechrunTransps
            .map(transportation => transportation.transportationName)
            .join(', '),
        renderCellContent: suppliersDocument => (
          <OverflowList
            collapseFrom={Boundary.END}
            items={suppliersDocument.relatedTechrunTransps}
            overflowRenderer={overflowItems => (
              <Popover
                content={
                  <Menu>
                    {overflowItems.map(transportation => (
                      <li key={transportation.id}>
                        <Link
                          className={cx(
                            Classes.MENU_ITEM,
                            Classes.POPOVER_DISMISS
                          )}
                          params={{
                            id: String(transportation.id),
                            status: techrunTransportationStatusFromGroup(
                              transportation.group
                            ),
                          }}
                          to="techruns.transportations.edit"
                        >
                          {transportation.transportationName}
                        </Link>
                      </li>
                    ))}
                  </Menu>
                }
              >
                <LinkStyledButton>ещё {overflowItems.length}</LinkStyledButton>
              </Popover>
            )}
            visibleItemRenderer={(transportation, index) => (
              <Fragment key={transportation.id}>
                <Link
                  params={{
                    id: String(transportation.id),
                    status: techrunTransportationStatusFromGroup(
                      transportation.group
                    ),
                  }}
                  to="techruns.transportations.edit"
                >
                  {transportation.transportationName}
                </Link>

                {index !==
                suppliersDocument.relatedTechrunTransps.length - 1 ? (
                  <span>,&nbsp;</span>
                ) : null}
              </Fragment>
            )}
          />
        ),
      },
      {
        id: 'relatedExpeditionTransps',
        label: 'Связанные отправки экспедирования',
        defaultWidth: 280,
        copyCellContent: suppliersDocument =>
          suppliersDocument.relatedExpeditionTransps
            .map(transportation => transportation.transportationName)
            .join(', '),
        renderCellContent: suppliersDocument => (
          <OverflowList
            collapseFrom={Boundary.END}
            items={suppliersDocument.relatedExpeditionTransps}
            overflowRenderer={overflowItems => (
              <Popover
                content={
                  <Menu>
                    {overflowItems.map(transportation => (
                      <li key={transportation.id}>
                        <Link
                          className={cx(
                            Classes.MENU_ITEM,
                            Classes.POPOVER_DISMISS
                          )}
                          params={{
                            id: String(transportation.id),
                            status: expeditionTransportationStatusFromGroup(
                              transportation.group
                            ),
                          }}
                          to="expeditions.transportations.edit"
                        >
                          {transportation.transportationName}
                        </Link>
                      </li>
                    ))}
                  </Menu>
                }
              >
                <LinkStyledButton>ещё {overflowItems.length}</LinkStyledButton>
              </Popover>
            )}
            visibleItemRenderer={(transportation, index) => (
              <Fragment key={transportation.id}>
                <Link
                  params={{
                    id: String(transportation.id),
                    status: expeditionTransportationStatusFromGroup(
                      transportation.group
                    ),
                  }}
                  to="expeditions.transportations.edit"
                >
                  {transportation.transportationName}
                </Link>

                {index !==
                suppliersDocument.relatedExpeditionTransps.length - 1 ? (
                  <span>,&nbsp;</span>
                ) : null}
              </Fragment>
            )}
          />
        ),
      },
      {
        id: 'amount',
        label: 'Сумма',
        defaultWidth: 120,
        copyCellContent: suppliersDocument =>
          formatMoney(suppliersDocument.amount),
        renderCellContent: suppliersDocument => (
          <Ellipsis component="span">
            {formatMoney(suppliersDocument.amount)}
          </Ellipsis>
        ),
      },
      {
        id: 'vatRate',
        label: 'Ставка НДС',
        defaultWidth: 120,
        copyCellContent: suppliersDocument =>
          `${Number(suppliersDocument.vatRate)}%`,
        renderCellContent: suppliersDocument => (
          <Ellipsis component="span">{`${Number(
            suppliersDocument.vatRate
          )}%`}</Ellipsis>
        ),
      },
      {
        id: 'vatValue',
        label: 'Сумма НДС',
        defaultWidth: 120,
        copyCellContent: suppliersDocument =>
          formatMoney(suppliersDocument.vatValue),
        renderCellContent: suppliersDocument => (
          <Ellipsis component="span">
            {formatMoney(suppliersDocument.vatValue)}
          </Ellipsis>
        ),
      },
      {
        id: 'amountTotal',
        label: 'Сумма итого',
        defaultWidth: 120,
        copyCellContent: suppliersDocument =>
          formatMoney(suppliersDocument.amountTotal),
        renderCellContent: suppliersDocument => (
          <Ellipsis component="span">
            {formatMoney(suppliersDocument.amountTotal)}
          </Ellipsis>
        ),
      },
      {
        id: 'relatedExpenses',
        label: 'Сумма связанных расходов',
        defaultWidth: 120,
        copyCellContent: suppliersDocument =>
          formatMoney(suppliersDocument.relatedExpenses),
        renderCellContent: suppliersDocument => (
          <Ellipsis component="span">
            {formatMoney(suppliersDocument.relatedExpenses)}
          </Ellipsis>
        ),
      },
      {
        id: 'files',
        label: 'Файлы',
        defaultWidth: 200,
        renderCellContent: suppliersDocument => (
          <OverflowList
            collapseFrom={Boundary.END}
            items={suppliersDocument.files}
            overflowRenderer={overflowItems => (
              <Popover
                content={
                  <Menu>
                    {overflowItems.map(awsUrl => (
                      <li key={awsUrl}>
                        <a
                          className={cx(
                            Classes.MENU_ITEM,
                            Classes.POPOVER_DISMISS
                          )}
                          download
                          href={awsUrl}
                        >
                          {parseAWSUrl(awsUrl).filename}
                        </a>
                      </li>
                    ))}
                  </Menu>
                }
              >
                <LinkStyledButton>ещё {overflowItems.length}</LinkStyledButton>
              </Popover>
            )}
            visibleItemRenderer={(awsUrl, index) => (
              <Fragment key={awsUrl}>
                <Ellipsis component="a" download href={awsUrl} wrap>
                  {parseAWSUrl(awsUrl).filename}
                </Ellipsis>

                {index !== suppliersDocument.files.length - 1 ? (
                  <span>,&nbsp;</span>
                ) : null}
              </Fragment>
            )}
          />
        ),
      },
    ],
    [listParams]
  );

  const getItemId = useCallback(
    (suppliersDocument: ListTableItem) => String(suppliersDocument.id),
    []
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, unknown>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  return (
    <>
      <FormattedTitle>Документы от подрядчика</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <LinkButton
                  options={{ reload: true }}
                  params={route.params}
                  text="Обновить"
                  to={route.name}
                />

                <LinkButton text="Сбросить фильтры" to={route.name} />

                <LinkButton
                  intent={Intent.PRIMARY}
                  params={{ listParams }}
                  text="Добавить"
                  to="suppliersDocuments.create"
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <ListTable
              columns={columns}
              getItemId={getItemId}
              isFetching={isFetching}
              items={data.suppliersDocuments}
              lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
              sorting={sorting}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>
    </>
  );
}

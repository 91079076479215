import { Radio, RadioGroup } from '@blueprintjs/core';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';

import { DownloadEmptyValues } from '../../parks/api';

interface Props {
  initialValue: DownloadEmptyValues | null;
  onSubmit: Dispatch<SetStateAction<DownloadEmptyValues | ''>>;
}
export function getEmptyParksValue(value: DownloadEmptyValues | '') {
  switch (value) {
    case DownloadEmptyValues.All:
      return 'Все расстояния';
    case DownloadEmptyValues.ThreeHundred:
      return '<=300 км';
    default:
      return '';
  }
}

export function EmptyParksForm({ onSubmit, initialValue }: Props) {
  return (
    <div style={{ padding: 10 }}>
      <RadioGroup
        onChange={newValue =>
          onSubmit(newValue.currentTarget.value as DownloadEmptyValues)
        }
        selectedValue={initialValue || ''}
      >
        <Radio label="Не выбрано" value="" />
        <Radio label="Все расстояния" value={DownloadEmptyValues.All} />
        <Radio label="<=300 км" value={DownloadEmptyValues.ThreeHundred} />
      </RadioGroup>
    </div>
  );
}

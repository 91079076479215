import {
  Button,
  ButtonGroup,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import { deepEqual } from 'fast-equals';
import * as React from 'react';

import {
  WeighingsStatusFilterForm,
  WeighingsStatusFilterFormValues,
} from './statusFilterForm';

interface Props {
  label: string;
  initialValues: WeighingsStatusFilterFormValues;
  onSubmit: (values: WeighingsStatusFilterFormValues) => void;
}

export function WeighingsStatusFilterPopover({
  label,
  initialValues,
  onSubmit,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false);

  const hasValue = !deepEqual(initialValues, {
    isProcessed: undefined,
    isSent: undefined,
  });

  return (
    <ButtonGroup>
      <Popover
        content={
          <WeighingsStatusFilterForm
            initialValues={initialValues}
            onSubmit={values => {
              onSubmit(values);
              setIsOpen(false);
            }}
          />
        }
        isOpen={isOpen}
        position={Position.BOTTOM}
        onInteraction={setIsOpen}
      >
        <Button
          icon="filter-list"
          intent={hasValue ? Intent.DANGER : undefined}
          text={label}
        />
      </Popover>

      {hasValue && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => {
            onSubmit({ isProcessed: undefined, isSent: undefined });
          }}
        />
      )}
    </ButtonGroup>
  );
}

import * as React from 'react';

import * as css from './autocompleteFilter.module.css';
import {
  FilterPopoverUi,
  FilterPopoverValueProcessor,
  useFilterPopover,
} from './filterPopover';

interface IChildArg<TInternalValue> {
  value: TInternalValue;
  onChange: (nextValue: TInternalValue) => void;
}

interface IProps<TValue, TInternalValue> {
  children: (arg: IChildArg<TInternalValue>) => React.ReactChild;
  initialValue: TValue;
  label: string;
  valueProcessor: FilterPopoverValueProcessor<TValue, TInternalValue>;
  onApply: (nextValue: TValue) => void;
}

export function AutocompleteFilter<TValue, TInternalValue>({
  children,
  initialValue,
  label,
  valueProcessor,
  onApply,
}: IProps<TValue, TInternalValue>) {
  const filterPopover = useFilterPopover(initialValue, onApply, valueProcessor);

  return (
    <FilterPopoverUi api={filterPopover.ui} hasBackdrop label={label}>
      <div className={css.root}>
        {children({
          value: filterPopover.value,

          onChange: nextValue => {
            filterPopover.change(nextValue);
          },
        })}
      </div>
    </FilterPopoverUi>
  );
}

import Decimal from 'decimal.js';

import { isNotNull } from './isNotNull';

export const decimalOrNull = (input: Decimal.Value | string) => {
  try {
    if (typeof input === 'string') {
      return new Decimal(input.replace(',', '.'));
    }

    return new Decimal(input);
  } catch (_err) {
    return null;
  }
};

export const calcForForm =
  (calcFn: (...args: Decimal[]) => Decimal) =>
  (...args: Array<Decimal.Value | null>) => {
    const decimals = args.map(decimalOrNull);

    if (decimals.some(decimal => decimal == null)) {
      return '';
    }

    return calcFn(...(decimals as Decimal[])).toFixed(2);
  };

export function sumMaybeDecimals(maybeDecimals: string[]) {
  return maybeDecimals
    .map(decimalOrNull)
    .filter(isNotNull)
    .reduce((sum, cur) => sum.plus(cur), new Decimal('0.00'));
}

export const decimalFormat = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatDecimal(input: string | null) {
  return decimalFormat.format(Number(input));
}

import { BooleanIcon } from '_core/booleanIcon';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { LinkStyledButton } from '_core/linkStyledButton';
import { defaultRenderCell } from '_core/react-window/baseTable';
import { HeadCell } from '_core/react-window/cells';
import { ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import {
  Boundary,
  Button,
  Classes,
  Intent,
  Menu,
  OverflowList,
  Popover,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import cx from 'classnames';
import dayjs from 'dayjs';
import * as React from 'react';
import { Fragment, useState } from 'react';
import { IWagonSerialized, WagonsSortingField } from 'wagons/api';
import { getWagonAlertLabel, parseWagonNumbers } from 'wagons/utils';

import { decimalFormat } from '../../_core/decimal';
import { createDislocationColumns } from '../dislocationColumns';
import * as css from './columns.module.css';

function BatchSelectPopover({
  onBatchSelect,
}: {
  onBatchSelect: (wagonNumbers: string[]) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      content={
        <FinalForm
          initialValues={{ wagons: '' }}
          onSubmit={values => {
            const wagonNumbers = parseWagonNumbers(values.wagons);

            if (wagonNumbers.length === 0) {
              return {
                wagons: 'Не найден ни один корректный номер вагона',
              };
            }

            onBatchSelect(wagonNumbers);
            setIsOpen(false);
            return undefined;
          }}
        >
          {({ handleSubmit }) => (
            <BaseForm onSubmit={handleSubmit}>
              <FormGroupForFinalForm name="wagons">
                <InputMultipleForFinalForm autoFocus name="wagons" rows={8} />
              </FormGroupForFinalForm>

              <Toolbar align="right">
                <Button
                  text="Закрыть"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />

                <Button intent={Intent.PRIMARY} text="Выбрать" type="submit" />
              </Toolbar>
            </BaseForm>
          )}
        </FinalForm>
      }
      isOpen={isOpen}
      popoverClassName={css.popover}
      position={Position.RIGHT_TOP}
      onInteraction={setIsOpen}
    >
      <Button icon="manually-entered-data" minimal />
    </Popover>
  );
}

export enum WagonColumnsSortingField {
  ArrivalDatetime = 'dislocation.arrivalDatetime',
  ArrivalDatetimeCalculated = 'dislocation.arrivalDatetimeCalculated',
  Created = 'created',
  DepartureDatetime = 'dislocation.departureDatetime',
  DistanceToArrivalStation = 'dislocation.distanceToArrivalStation',
  LastEtranNoteDate = 'lastEtranNoteDate',
  LastNoteDate = 'lastNoteDate',
  LastCargoOperationOverNoteDate = 'lastCargoOperationOverNoteDate',
  LastDeliveryOverdueNoteDate = 'lastDeliveryOverdueNoteDate',
  LastRzdAppealNoteDate = 'lastRzdAppealNoteDate',
  ManagerNote2Modified = 'managerNote2Modified',
  ManagerNote3Modified = 'managerNote3Modified',
  ManagerNoteModified = 'managerNoteModified',
  Number = 'number',
  OperationDatetime = 'dislocation.operationDatetime',
  OperationDowntime = 'dislocation.operationDowntime',
  RepairDate = 'dislocation.repairDate',
  WagonMileage = 'dislocation.wagonMileage',
  DeliveryDateOverdue = 'dislocation.deliveryDateOverdue',
}

export const isWagonColumnsSortingField = (
  field: string
): field is WagonColumnsSortingField =>
  Object.values(WagonColumnsSortingField).includes(
    field as WagonColumnsSortingField
  );

export const wagonColumnsSortingFieldMap: Record<
  WagonColumnsSortingField,
  WagonsSortingField[]
> = {
  [WagonColumnsSortingField.ArrivalDatetime]: [
    WagonsSortingField.LatestDislocationArrivalDatetime,
  ],
  [WagonColumnsSortingField.ArrivalDatetimeCalculated]: [
    WagonsSortingField.LatestDislocationArrivalDatetimeCalculated,
  ],
  [WagonColumnsSortingField.Created]: [WagonsSortingField.Created],
  [WagonColumnsSortingField.DepartureDatetime]: [
    WagonsSortingField.LatestDislocationDepartureDatetime,
  ],
  [WagonColumnsSortingField.DistanceToArrivalStation]: [
    WagonsSortingField.DistanceToArrivalStation,
  ],
  [WagonColumnsSortingField.LastEtranNoteDate]: [
    WagonsSortingField.LastEtranNoteDate,
  ],
  [WagonColumnsSortingField.LastNoteDate]: [WagonsSortingField.LastNoteDate],
  [WagonColumnsSortingField.LastCargoOperationOverNoteDate]: [
    WagonsSortingField.LastCargoOperationOverNoteDate,
  ],
  [WagonColumnsSortingField.LastDeliveryOverdueNoteDate]: [
    WagonsSortingField.LastDeliveryOverdueNoteDate,
  ],
  [WagonColumnsSortingField.LastRzdAppealNoteDate]: [
    WagonsSortingField.LastRzdAppealNoteDate,
  ],
  [WagonColumnsSortingField.ManagerNote2Modified]: [
    WagonsSortingField.ManagerNote2Modified,
  ],
  [WagonColumnsSortingField.ManagerNote3Modified]: [
    WagonsSortingField.ManagerNote3Modified,
  ],
  [WagonColumnsSortingField.ManagerNoteModified]: [
    WagonsSortingField.ManagerNoteModified,
  ],
  [WagonColumnsSortingField.Number]: [WagonsSortingField.Number],
  [WagonColumnsSortingField.OperationDatetime]: [
    WagonsSortingField.LatestDislocationOperationDatetime,
  ],
  [WagonColumnsSortingField.OperationDowntime]: [
    WagonsSortingField.LatestDislocationOperationDowntime,
  ],
  [WagonColumnsSortingField.RepairDate]: [
    WagonsSortingField.LatestDislocationRepairDate,
  ],
  [WagonColumnsSortingField.WagonMileage]: [
    WagonsSortingField.LatestDislocationWagonMileage,
  ],
  [WagonColumnsSortingField.DeliveryDateOverdue]: [
    WagonsSortingField.DeliveryDateOverdue,
  ],
};

export const createWagonColumns = ({
  dislocationProp = 'selectedDislocation',
  isBatchEditEnabled,
  listParams,
  sortableByDistanceToArrivalStation,
  onBatchEditClick,
  onBatchSelect,
  onEditClick,
}: {
  dislocationProp?: 'selectedDislocation' | 'latestDislocation';
  isBatchEditEnabled?: boolean;
  listParams?: string;
  sortableByDistanceToArrivalStation?: boolean;
  onBatchEditClick?: () => void;
  onBatchSelect?: (numbers: string[]) => void;
  onEditClick?: (number: string) => void;
}): Array<ListTableColumn<IWagonSerialized>> => [
  {
    id: WagonColumnsSortingField.Number,
    label: '№ вагона',
    defaultWidth: 220,
    sortable: true,
    renderHead: (
      { children, sortingDirection, style, onSortingToggle },
      columnIndex
    ) => (
      <HeadCell
        columnIndex={columnIndex}
        prepend={
          !onBatchEditClick && !onBatchSelect ? null : (
            <>
              {onBatchEditClick && (
                <Button
                  disabled={!isBatchEditEnabled}
                  icon="edit"
                  minimal
                  onClick={onBatchEditClick}
                />
              )}

              {onBatchSelect && (
                <BatchSelectPopover onBatchSelect={onBatchSelect} />
              )}
            </>
          )
        }
        sortingDirection={sortingDirection}
        style={style}
        onSortingToggle={onSortingToggle}
      >
        {children}
      </HeadCell>
    ),
    copyCellContent: wagon => wagon.number,
    renderCellContent: wagon => (
      <div>
        {onEditClick && (
          <Button
            icon="edit"
            intent={Intent.PRIMARY}
            minimal
            onClick={() => {
              onEditClick(wagon.number);
            }}
          />
        )}

        <Link
          className={css.wagonNumberLink}
          params={{ listParams, number: wagon.number }}
          rel="noopener"
          target="_blank"
          to="wagons.edit"
        >
          {wagon.number}
        </Link>

        {wagon.alerts.length !== 0 && (
          <Tooltip
            content={wagon.alerts.map(getWagonAlertLabel).join(', ')}
            intent={Intent.WARNING}
            position={Position.TOP_LEFT}
          >
            <Button icon="warning-sign" minimal />
          </Tooltip>
        )}

        {wagon.hasRecentNoteFiles && (
          <Tooltip
            content="Есть прикреплённые файлы"
            position={Position.TOP_LEFT}
          >
            <Button icon="paperclip" minimal />
          </Tooltip>
        )}
      </div>
    ),
  },
  {
    id: 'managerNote',
    label: 'Заметка 1',
    defaultWidth: 200,
    copyCellContent: wagon => wagon.managerNote,
    renderCellContent: wagon => (
      <Ellipsis component="span">{wagon.managerNote}</Ellipsis>
    ),
  },
  {
    id: 'managerNoteModified',
    label: 'Дата изменения заметки 1',
    defaultWidth: 200,
    sortable: true,
    copyCellContent: wagon =>
      wagon.managerNoteModified
        ? dayjs(wagon.managerNoteModified).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.managerNoteModified
        ? dayjs(wagon.managerNoteModified).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'managerNote2',
    label: 'Заметка 2',
    defaultWidth: 200,
    copyCellContent: wagon => wagon.managerNote2,
    renderCellContent: wagon => (
      <Ellipsis component="span">{wagon.managerNote2}</Ellipsis>
    ),
  },
  {
    id: 'managerNote2Modified',
    label: 'Дата изменения заметки 2',
    defaultWidth: 200,
    sortable: true,
    copyCellContent: wagon =>
      wagon.managerNote2Modified
        ? dayjs(wagon.managerNote2Modified).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.managerNote2Modified
        ? dayjs(wagon.managerNote2Modified).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'managerNote3',
    label: 'Заметка 3',
    defaultWidth: 200,
    copyCellContent: wagon => wagon.managerNote3,
    renderCellContent: wagon => (
      <Ellipsis component="span">{wagon.managerNote3}</Ellipsis>
    ),
  },
  {
    id: 'managerNote3Modified',
    label: 'Дата изменения заметки 3',
    defaultWidth: 200,
    sortable: true,
    copyCellContent: wagon =>
      wagon.managerNote3Modified
        ? dayjs(wagon.managerNote3Modified).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.managerNote3Modified
        ? dayjs(wagon.managerNote3Modified).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'lastEtranNote',
    label: 'Заготовка в Этране',
    defaultWidth: 200,
    copyCellContent: wagon => wagon.lastEtranNote,
    renderCellContent: wagon =>
      wagon.lastEtranNote ? (
        <Ellipsis
          component={Link}
          params={{ number: wagon.number }}
          rel="noopener"
          target="_blank"
          to="wagons.edit.etranNotes"
        >
          {wagon.lastEtranNote}
        </Ellipsis>
      ) : null,
  },
  {
    id: 'lastEtranNoteDate',
    label: 'Дата изменения заготовки в Этране',
    defaultWidth: 200,
    sortable: true,
    copyCellContent: wagon =>
      wagon.lastEtranNoteDate
        ? dayjs(wagon.lastEtranNoteDate).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.lastEtranNoteDate
        ? dayjs(wagon.lastEtranNoteDate).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'lastNote',
    label: 'Последний комментарий',
    defaultWidth: 200,
    copyCellContent: wagon => wagon.lastNote,
    renderCellContent: wagon =>
      wagon.lastNote ? (
        <Ellipsis
          component={Link}
          params={{ number: wagon.number }}
          rel="noopener"
          target="_blank"
          to="wagons.edit.notes"
        >
          {wagon.lastNote}
        </Ellipsis>
      ) : null,
  },
  {
    id: 'lastNoteDate',
    label: 'Дата последнего комментария',
    defaultWidth: 200,
    sortable: true,
    copyCellContent: wagon =>
      wagon.lastNoteDate
        ? dayjs(wagon.lastNoteDate).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.lastNoteDate
        ? dayjs(wagon.lastNoteDate).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'lastRzdAppealNote',
    label: 'Последнее обращение в РЖД',
    defaultWidth: 220,
    copyCellContent: wagon => wagon.lastRzdAppealNote,
    renderCellContent: wagon =>
      wagon.lastRzdAppealNote ? (
        <Ellipsis
          component={Link}
          params={{ number: wagon.number }}
          rel="noopener"
          target="_blank"
          to="wagons.edit.notes"
        >
          {wagon.lastRzdAppealNote}
        </Ellipsis>
      ) : null,
  },
  {
    id: 'lastRzdAppealNoteDate',
    label: 'Дата последнего обращения в РЖД',
    defaultWidth: 200,
    sortable: true,
    copyCellContent: wagon =>
      wagon.lastRzdAppealNoteDate
        ? dayjs(wagon.lastRzdAppealNoteDate).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.lastRzdAppealNoteDate
        ? dayjs(wagon.lastRzdAppealNoteDate).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'lastDeliveryOverdueNote',
    label: 'Последняя просрочка доставки',
    defaultWidth: 250,
    copyCellContent: wagon => wagon.lastDeliveryOverdueNote,
    renderCellContent: wagon =>
      wagon.lastDeliveryOverdueNote ? (
        <Ellipsis
          component={Link}
          params={{ number: wagon.number }}
          rel="noopener"
          target="_blank"
          to="wagons.edit.notes"
        >
          {wagon.lastDeliveryOverdueNote}
        </Ellipsis>
      ) : null,
  },
  {
    id: 'lastDeliveryOverdueNoteDate',
    label: 'Дата последней просрочки доставки',
    defaultWidth: 200,
    sortable: true,
    copyCellContent: wagon =>
      wagon.lastDeliveryOverdueNoteDate
        ? dayjs(wagon.lastDeliveryOverdueNoteDate).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.lastDeliveryOverdueNoteDate
        ? dayjs(wagon.lastDeliveryOverdueNoteDate).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'lastCargoOperationOverNote',
    label: 'Грузовая операция окончена (принято приемосдатчиком)',
    defaultWidth: 250,
    copyCellContent: wagon => wagon.lastCargoOperationOverNote,
    renderCellContent: wagon =>
      wagon.lastCargoOperationOverNote ? (
        <Ellipsis
          component={Link}
          params={{ number: wagon.number }}
          rel="noopener"
          target="_blank"
          to="wagons.edit.notes"
        >
          {wagon.lastCargoOperationOverNote}
        </Ellipsis>
      ) : (
        <div className={css.cellCenterContainer}>
          <BooleanIcon value={false} />
        </div>
      ),
  },
  {
    id: 'lastCargoOperationOverNoteDate',
    label: 'Дата последней ГОО',
    defaultWidth: 200,
    sortable: true,
    copyCellContent: wagon =>
      wagon.lastCargoOperationOverNoteDate
        ? dayjs(wagon.lastCargoOperationOverNoteDate).format(
            DATE_FORMAT_DATETIME
          )
        : '',
    renderCellContent: wagon =>
      wagon.lastCargoOperationOverNoteDate
        ? dayjs(wagon.lastCargoOperationOverNoteDate).format(
            DATE_FORMAT_DATETIME
          )
        : null,
  },
  {
    id: 'owner',
    label: 'Владелец',
    defaultWidth: 250,
    copyCellContent: wagon => (wagon.owner ? wagon.owner.shortName : ''),
    renderCellContent: wagon =>
      wagon.owner ? (
        <Ellipsis
          component={Link}
          params={{ id: wagon.owner.id }}
          rel="noopener"
          target="_blank"
          to="partners.edit"
        >
          {wagon.owner.shortName}
        </Ellipsis>
      ) : null,
  },
  {
    id: 'requestPartner',
    label: 'Принадлежность',
    defaultWidth: 250,
    copyCellContent: wagon =>
      wagon.requestPartner ? wagon.requestPartner.shortName : '',
    renderCellContent: wagon =>
      wagon.requestPartner ? (
        <Ellipsis
          component={Link}
          params={{ id: wagon.requestPartner.id }}
          to="partners.edit"
        >
          {wagon.requestPartner.shortName}
        </Ellipsis>
      ) : null,
  },
  {
    id: 'tara',
    label: 'Тара',
    defaultWidth: 60,
    copyCellContent: wagon =>
      wagon.tara == null ? '' : decimalFormat.format(wagon.tara),
    renderCellContent: wagon =>
      wagon.tara == null ? '' : decimalFormat.format(wagon.tara),
  },
  {
    id: 'taraModified',
    label: 'Дата изменения Тары',
    defaultWidth: 120,
    copyCellContent: wagon =>
      wagon.taraModified
        ? dayjs(wagon.taraModified).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.taraModified
        ? dayjs(wagon.taraModified).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'capacity',
    label: 'Грузоподъёмность',
    defaultWidth: 60,
    copyCellContent: wagon =>
      wagon.capacity == null ? '' : decimalFormat.format(wagon.capacity),
    renderCellContent: wagon =>
      wagon.capacity == null ? '' : decimalFormat.format(wagon.capacity),
  },
  {
    id: 'capacityModified',
    label: 'Дата изменения грузоподъёмности',
    defaultWidth: 120,
    copyCellContent: wagon =>
      wagon.capacityModified
        ? dayjs(wagon.capacityModified).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.capacityModified
        ? dayjs(wagon.capacityModified).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'model',
    label: 'Модель',
    defaultWidth: 120,
    copyCellContent: wagon => wagon.model ?? '',
    renderCellContent: wagon => wagon.model,
  },
  {
    id: 'modelModified',
    label: 'Дата изменения модели',
    defaultWidth: 120,
    copyCellContent: wagon =>
      wagon.modelModified
        ? dayjs(wagon.modelModified).format(DATE_FORMAT_DATETIME)
        : '',
    renderCellContent: wagon =>
      wagon.modelModified
        ? dayjs(wagon.modelModified).format(DATE_FORMAT_DATETIME)
        : null,
  },
  {
    id: 'parks',
    label: 'Парки',
    defaultWidth: 250,
    copyCellContent: wagon => wagon.parks.map(park => park.name).join(', '),
    renderCellContent: wagon => (
      <OverflowList
        collapseFrom={Boundary.END}
        items={wagon.parks}
        overflowRenderer={overflowItems => (
          <Popover
            content={
              <Menu>
                {overflowItems.map(park => (
                  <li key={park.id}>
                    <Link
                      className={cx(Classes.MENU_ITEM, Classes.POPOVER_DISMISS)}
                      params={{ id: String(park.id) }}
                      rel="noopener"
                      target="_blank"
                      to="parks.edit"
                    >
                      {park.name}
                    </Link>
                  </li>
                ))}
              </Menu>
            }
          >
            <LinkStyledButton>ещё {overflowItems.length}</LinkStyledButton>
          </Popover>
        )}
        visibleItemRenderer={(park, index) => (
          <Fragment key={park.id}>
            <Link
              params={{ id: String(park.id) }}
              rel="noopener"
              target="_blank"
              to="parks.edit"
            >
              {park.name}
            </Link>

            {index !== wagon.parks.length - 1 ? <span>,&nbsp;</span> : null}
          </Fragment>
        )}
      />
    ),
  },
  ...createDislocationColumns({ sortableByDistanceToArrivalStation }).map(
    ({
      id,
      copyCellContent,
      getEditor,
      renderCell = defaultRenderCell,
      renderCellContent,
      ...otherColumnProps
    }): ListTableColumn<IWagonSerialized> => ({
      ...otherColumnProps,
      id: `dislocation.${id}`,
      copyCellContent: (wagon, rowIndex) => {
        const dislocation = wagon[dislocationProp];

        return dislocation && copyCellContent
          ? copyCellContent(dislocation, rowIndex)
          : '';
      },
      getEditor: wagon => {
        const dislocation = wagon[dislocationProp];

        return dislocation && getEditor ? getEditor(dislocation) : undefined;
      },
      renderCell: ({ item: wagon, ...otherProps }, rowIndex, columnIndex) => {
        const dislocation = wagon[dislocationProp];

        return dislocation
          ? renderCell(
              { item: dislocation, ...otherProps },
              rowIndex,
              columnIndex
            )
          : defaultRenderCell(otherProps);
      },
      renderCellContent: (wagon, rowIndex) => {
        const dislocation = wagon[dislocationProp];

        return dislocation ? renderCellContent(dislocation, rowIndex) : null;
      },
    })
  ),
  {
    id: 'dislocation.created',
    label: 'Запись создана',
    defaultWidth: 130,
    copyCellContent: wagon => {
      const dislocation = wagon[dislocationProp];

      return dislocation
        ? dayjs(dislocation.created).format(DATE_FORMAT_DATETIME)
        : '';
    },
    renderCellContent: wagon => {
      const dislocation = wagon[dislocationProp];

      return dislocation
        ? dayjs(dislocation.created).format(DATE_FORMAT_DATETIME)
        : null;
    },
  },
  {
    id: 'dislocation.modified',
    label: 'Запись обновлена',
    defaultWidth: 130,
    copyCellContent: wagon => {
      const dislocation = wagon[dislocationProp];

      return dislocation
        ? dayjs(dislocation.modified).format(DATE_FORMAT_DATETIME)
        : '';
    },
    renderCellContent: wagon => {
      const dislocation = wagon[dislocationProp];

      return dislocation
        ? dayjs(dislocation.modified).format(DATE_FORMAT_DATETIME)
        : null;
    },
  },
  {
    id: 'created',
    label: 'Дата добавления',
    defaultWidth: 160,
    sortable: true,
    copyCellContent: wagon =>
      dayjs(parseDate(wagon.created)).format(DATE_FORMAT_DATETIME),
    renderCellContent: wagon =>
      dayjs(parseDate(wagon.created)).format(DATE_FORMAT_DATETIME),
  },
];

import { useApiClient } from '_core/api/context';
import { deserializeDateRangeFilterValue } from '_core/blueprint/dateRange';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { DateRangeFilter } from '_core/filters/dateRange';
import { SearchForm } from '_core/forms/searchForm';
import { uniq } from '_core/fp/uniq';
import { indexByLastItem } from '_core/indexBy';
import { LegendItem } from '_core/legend';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { LinkButton } from '_core/router5/linkButton';
import {
  expandSorting,
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
  validateSorting,
} from '_core/sorting';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { H4, Intent } from '@blueprintjs/core';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { Fragment } from 'react';
import { useRouteNode } from 'react-router5';
import { fetchRepairs, RepairsSortingField } from 'repairs/api';
import { fetchManyStations } from 'stations/api';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

import { VU36_DATE_IS_SET_COLOR } from './colors';
import * as css from './list.module.css';
import { RepairsTable } from './listTable';

enum SortingField {
  Created = 'created',
  Wagon = 'wagon',
}

export default function OurRepairsListRoute() {
  const { route, router } = useRouteNode('ourRepairs');
  const api = useApiClient();

  const sorting = React.useMemo(
    () =>
      validateSorting(
        (input): input is SortingField =>
          Object.values(SortingField).includes(input as SortingField),
        sortingFromRouterParam(route.params.sorting),
        {
          field: SortingField.Created,
          direction: SortingDirection.Descending,
        }
      ),
    [route.params.sorting]
  );

  const { data, isFetching } = useAsyncData([api, sorting, route], async () => {
    const [fromDate, toDate] = deserializeDateRangeFilterValue(
      route.params.dateRange
    );

    const { meta, results } = await fetchRepairs(api, {
      fromDate: fromDate || undefined,
      toDate: toDate || undefined,
      page: isFinite(route.params.page) ? Number(route.params.page) : undefined,
      pageSize: isFinite(route.params.pageSize)
        ? Number(route.params.pageSize)
        : 5,
      search: route.params.search ? (route.params.search as string) : undefined,
      sorting: expandSorting(sorting, {
        [SortingField.Created]: [RepairsSortingField.Created],
        [SortingField.Wagon]: [RepairsSortingField.Wagon],
      }),
      wagonNumbers: route.params.wagonNumbers
        ? (route.params.wagonNumbers as string).split(',')
        : undefined,
    });

    const stations = await fetchManyStations(
      api,
      uniq(
        results.flatMap(repairsGroup =>
          repairsGroup.repairs
            .map(repair => repair.repairStation)
            .filter(
              (station): station is Exclude<typeof station, null | undefined> =>
                station != null
            )
        )
      )
    );

    const stationsIndex = indexByLastItem(stations, station => station.id);

    return {
      pageSize: meta.pageSize,
      totalPages: meta.totalPages,
      repairsGroups: results.map(repairsGroup => ({
        ...repairsGroup,
        repairs: repairsGroup.repairs.map(repair => ({
          ...repair,
          repairStation:
            repair.repairStation == null
              ? null
              : stationsIndex[repair.repairStation],
        })),
      })),
    };
  });

  const applyFilterParams = React.useCallback(
    (filterParams: Record<string, string | undefined>) => {
      const curState = router.getState();

      router.navigate(curState.name, {
        ...curState.params,
        page: undefined,
        ...filterParams,
      });
    },
    [router]
  );

  const handleSortingChange = React.useCallback(
    (newSorting: ISorting<string>) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  return (
    <>
      <FormattedTitle>Наши ремонты</FormattedTitle>

      <VGrid>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <WagonNumbersFilter
                  initialValue={route.params.wagonNumbers}
                  onApply={wagonNumbers => {
                    applyFilterParams({ wagonNumbers });
                  }}
                />

                <DateRangeFilter
                  initialValue={route.params.dateRange}
                  label="Период"
                  onApply={newDateRange => {
                    applyFilterParams({ dateRange: newDateRange });
                  }}
                />

                <LinkButton
                  options={{ reload: true }}
                  params={route.params}
                  text="Обновить"
                  to={route.name}
                />

                <LinkButton text="Сбросить фильтры" to={route.name} />

                <LinkButton
                  intent={Intent.PRIMARY}
                  params={{ listParams: JSON.stringify(route.params) }}
                  text="Добавить"
                  to="ourRepairs.create"
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        {!data ? (
          <Row stretch>
            {isFetching ? <CenteredSpinner /> : <GenericErrorMessage />}
          </Row>
        ) : data.repairsGroups.length === 0 ? (
          <Row stretch>
            <EmptyListMessage description="Не найдено ремонтов, соответствующих критериям" />
          </Row>
        ) : (
          <>
            {data.repairsGroups.map((repairsGroup, index) => (
              <Fragment key={`${route.params.page}-${index}`}>
                <Row>
                  <H4 className={css.groupTitle}>
                    {repairsGroup.roadName == null
                      ? 'Дорога не указана'
                      : repairsGroup.roadName}
                  </H4>
                </Row>

                <Row>
                  <Toolbar>
                    <LegendItem
                      backgroundColor={VU36_DATE_IS_SET_COLOR}
                      label="Проставлена дата ВУ36"
                    />
                  </Toolbar>
                </Row>

                <Row>
                  <RepairsTable
                    isFetching={isFetching}
                    items={repairsGroup.repairs}
                    sorting={sorting}
                    onSortingChange={handleSortingChange}
                  />
                </Row>

                <Row>
                  <strong>
                    Итого
                    {repairsGroup.roadName != null
                      ? ` по ${repairsGroup.roadName}`
                      : null}
                    : {repairsGroup.repairs.length}
                  </strong>
                </Row>
              </Fragment>
            ))}

            <Row>
              <Grid>
                <Col align="center">
                  <Pagination
                    pageSize={data.pageSize}
                    totalPages={data.totalPages}
                  />
                </Col>
              </Grid>
            </Row>
          </>
        )}
      </VGrid>
    </>
  );
}

import { isNotNull } from '_core/isNotNull';
import { Button, IconName, MenuItem, Spinner } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import * as React from 'react';

import {
  FilterPopoverUi,
  FilterPopoverValueProcessor,
  useFilterPopover,
} from './filterPopover';
import * as css from './multiSelect.module.css';

interface Option<T> {
  label: string;
  value: T;
}

interface Props<T, V> {
  icon?: IconName;
  initialContent?: React.ReactNode;
  initialValue: string | undefined;
  isFetching?: boolean;
  itemPredicate: (query: string, item: T) => boolean;
  items: T[];
  itemToOption: (item: T) => Option<V>;
  label: string;
  placeholder?: string;
  valueProcessor: FilterPopoverValueProcessor<string | undefined, V[]>;
  width: number;
  onApply: (value: string | undefined) => void;
}

export function MultiSelectFilter<T, V extends string | number>({
  icon,
  initialContent,
  initialValue,
  isFetching,
  itemPredicate,
  items,
  itemToOption,
  label,
  placeholder = 'Поиск...',
  valueProcessor,
  width,
  onApply,
}: Props<T, V>) {
  const { change, ui, value } = useFilterPopover(
    initialValue,
    onApply,
    valueProcessor
  );

  function isOptionSelected(option: Option<V>) {
    return value.includes(option.value);
  }

  return (
    <FilterPopoverUi api={ui} icon={icon} label={label}>
      <div className={css.root} style={{ width }}>
        <MultiSelect
          noResults={
            isFetching ? (
              <Spinner />
            ) : (
              <MenuItem disabled text="Элементов не найдено" />
            )
          }
          initialContent={initialContent}
          itemPredicate={itemPredicate}
          items={items}
          itemRenderer={(item: T, { modifiers, handleClick }) => {
            if (!modifiers.matchesPredicate) {
              return null;
            }

            const option = itemToOption(item);

            return (
              <MenuItem
                active={modifiers.active}
                icon={isOptionSelected(option) ? 'tick' : 'blank'}
                key={option.value}
                shouldDismissPopover={false}
                text={option.label}
                onClick={handleClick}
              />
            );
          }}
          placeholder={placeholder}
          popoverProps={{
            targetTagName: 'div',
            usePortal: false,
            wrapperTagName: 'div',
          }}
          selectedItems={value
            .map(valueItem =>
              items.find(item => itemToOption(item).value === valueItem)
            )
            .filter(isNotNull)}
          tagInputProps={{
            fill: true,
            rightElement:
              value.length > 0 ? (
                <Button
                  icon="cross"
                  minimal
                  onClick={() => {
                    change([]);
                  }}
                />
              ) : undefined,
            onRemove: (_node, indexToRemove) => {
              change(value.filter((_tag, index) => index !== indexToRemove));
            },
          }}
          tagRenderer={item => itemToOption(item).label}
          onItemSelect={item => {
            const option = itemToOption(item);

            if (isOptionSelected(option)) {
              change(value.filter(v => v !== option.value));
            } else {
              change(value.concat([option.value]));
            }
          }}
        />
      </div>
    </FilterPopoverUi>
  );
}

import { LegendItem } from '_core/legend';
import { Toolbar } from '_core/toolbar';
import * as React from 'react';

import {
  getWagonAlertColor,
  getWagonAlertLabel,
  wagonAlertsByPriority,
} from './utils';

export function renderWagonAlertsLegendItems() {
  return wagonAlertsByPriority.map(alert => (
    <LegendItem
      key={alert}
      backgroundColor={getWagonAlertColor(alert)}
      label={getWagonAlertLabel(alert)}
    />
  ));
}

export function WagonAlertsLegend() {
  return <Toolbar>{renderWagonAlertsLegendItems()}</Toolbar>;
}

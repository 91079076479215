import { Checkbox } from '_core/inputs/checkbox';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';

import * as css from './treeCell.module.css';
import { useTreeTableSelection } from './treeTableSelection';
import { TreeTableRowNode } from './types';

interface Props<T> {
  children: React.ReactNode;
  noPadding?: boolean;
  row: TreeTableRowNode<T>;
  onExpand: () => void;
}

export function TreeCell<T>({ children, row, noPadding, onExpand }: Props<T>) {
  const { isNodeSelectable, selectedNodes, onSelectionCheckboxClick } =
    useTreeTableSelection();

  return (
    <div
      className={cx(css.root, {
        [css.root_hasChildren]: row.node.children != null,
      })}
    >
      {row.node.children ? (
        <div className={css.controls}>
          <button
            className={css.chevron}
            type="button"
            onClick={() => {
              onExpand();
            }}
          >
            <Icon
              icon={
                row.node.children.isExpanded ? 'chevron-down' : 'chevron-right'
              }
            />
          </button>
        </div>
      ) : null}

      {isNodeSelectable(row.node) && (
        <Checkbox
          checked={selectedNodes.includes(row.node)}
          noBottomMargin
          onClick={(newChecked, event) => {
            onSelectionCheckboxClick(newChecked, event, row);
          }}
        />
      )}

      <div className={css.iconWrapper}>
        {row.node.children ? (
          <Icon
            icon={row.node.children.isExpanded ? 'folder-open' : 'folder-close'}
          />
        ) : (
          <Icon icon="document" />
        )}
      </div>

      <div
        className={cx(css.content, {
          [css.content_noPadding]: noPadding,
        })}
      >
        {children}
      </div>
    </div>
  );
}

import { useApiClient } from '_core/api/context';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import {
  selectIsFavoritePurchaseContract,
  togglePurchaseContractFavoriteStatus,
} from '_core/me/me';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import {
  TabsRouteNode,
  TabsRouteNodeExpander,
  TabsRouteNodeTab,
} from '_core/router5/tabsRouteNode';
import { useToaster } from '_core/toaster/toasterContext';
import { useAsyncData } from '_core/useAsyncData';
import { useErpDispatch, useErpSelector } from '_erp/redux';
import { Button, Intent } from '@blueprintjs/core';
import { fetchContractPurchase } from 'contractsPurchase/api';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';

import { ContractsPurchaseFormRouteWrapper } from './purchaseFormRouteWrapper';
import { ContractsPurchaseViewAgreementsTab } from './purchaseViewAgreementsTab';
import { ContractsPurchaseViewMainTab } from './purchaseViewMainTab';
import { ContractsPurchaseViewRentProtocolsTab } from './purchaseViewRentProtocolsTab';
import { ContractsPurchaseViewTechrunProtocolsTab } from './purchaseViewTechrunProtocolsTab';

export default function ContractsPurchaseViewRoute() {
  const toaster = useToaster();
  const dispatch = useErpDispatch();
  const api = useApiClient();
  const { route } = useRouteNode('contracts.purchase.view');
  const contractId = Number(route.params.id);

  const { data, error, isFetching, updateData } = useAsyncData(
    [contractId, api],
    () => fetchContractPurchase(api, contractId)
  );

  const isFavorite = useErpSelector(state =>
    selectIsFavoritePurchaseContract(contractId, state)
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    )
  ) : (
    <ContractsPurchaseFormRouteWrapper
      listParams={listParams}
      title={data.number}
    >
      <TabsRouteNode
        nodeName="contracts.purchase.view"
        preserveRouteParams={['id', 'listParams']}
      >
        <TabsRouteNodeTab id="main" title="Основные данные">
          <ContractsPurchaseViewMainTab
            contract={data}
            updateContract={updatedContract =>
              updateData(() => updatedContract)
            }
          />
        </TabsRouteNodeTab>

        {data.hasTechrun && (
          <TabsRouteNodeTab id="techrunProtocols" title="Протоколы техрейса">
            <ContractsPurchaseViewTechrunProtocolsTab contract={data} />
          </TabsRouteNodeTab>
        )}

        {data.hasRent && (
          <TabsRouteNodeTab id="rentProtocols" title="Протоколы аренды">
            <ContractsPurchaseViewRentProtocolsTab contract={data} />
          </TabsRouteNodeTab>
        )}

        <TabsRouteNodeTab id="agreements" title="Дополнительные соглашения">
          <ContractsPurchaseViewAgreementsTab contract={data} />
        </TabsRouteNodeTab>

        <TabsRouteNodeExpander />

        <Button
          icon={isFavorite ? 'star' : 'star-empty'}
          intent={Intent.SUCCESS}
          large
          minimal
          onClick={async () => {
            try {
              await dispatch(togglePurchaseContractFavoriteStatus(contractId));
            } catch (err) {
              toaster.show({
                icon: 'error',
                intent: Intent.DANGER,
                message:
                  'Не удалось изменить статус избранности входящего договора',
              });

              throw err;
            }
          }}
        />
      </TabsRouteNode>
    </ContractsPurchaseFormRouteWrapper>
  );
}

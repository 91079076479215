import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { SelectForFinalForm } from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { Button, H4, Intent } from '@blueprintjs/core';
import { ConsignersAutocompleteInFormGroupForFinalForm } from 'consigners/autocomplete';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { StationsAutocompleteInFormGroupForFinalForm } from 'stations/autocomplete';
import { TechrunGu12Type } from 'techrunGu12/api';
import { techrunGu12TypeOptions } from 'techrunGu12/utils';
import {
  ITechrunTransportation,
  techrunTransportationStatusFromGroup,
} from 'techrunTransportations/types';

import { IOwnersFieldItem, OwnersField } from './ownersField';

export interface ITechrunGu12FormValues {
  arrivalStation: number | null;
  consigner: number | null;
  departureStation: number | null;
  endDate: Date | null;
  number: string;
  owners: IOwnersFieldItem[];
  startDate: Date | null;
  transportations: number[];
  type: TechrunGu12Type;
  wagonsLeft: number | null;
  wagonsQuantity: number | null;
}

export type SubmitTrigger = 'save' | 'saveAndContinue';

interface IProps {
  initialValues: ITechrunGu12FormValues;
  navigationLock: IFormNavigationLock;
  setSubmitTrigger: (newSubmitTrigger: SubmitTrigger) => void;
  transportations?: ITechrunTransportation[];
  onSubmit: (
    values: ITechrunGu12FormValues
  ) => Promise<SubmissionErrors | void>;
}

export function TechrunsGu12Form({
  initialValues,
  navigationLock,
  setSubmitTrigger,
  transportations,
  onSubmit,
}: IProps) {
  return (
    <FinalForm
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={12}>
                  <H4>Основная информация</H4>
                </Col>

                <Col span={4}>
                  <FormGroupForFinalForm
                    label="Тип"
                    labelFor="type"
                    labelInfo="*"
                    name="type"
                  >
                    <SelectForFinalForm
                      fill
                      id="type"
                      name="type"
                      options={techrunGu12TypeOptions}
                    />
                  </FormGroupForFinalForm>

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        id="startDate"
                        label="Дата начала"
                        name="startDate"
                        required
                        onChange={newStartDate => {
                          if (newStartDate) {
                            form.change('endDate', newStartDate);
                          }
                        }}
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        id="endDate"
                        label="Дата окончания"
                        name="endDate"
                        required
                      />
                    </Col>
                  </Grid>

                  <StationsAutocompleteInFormGroupForFinalForm
                    id="departureStation"
                    label="Станция отправления"
                    name="departureStation"
                    required
                  />

                  <FormGroupForFinalForm
                    label="Количество вагонов"
                    labelFor="wagonsQuantity"
                    labelInfo="*"
                    name="wagonsQuantity"
                  >
                    <InputGroupForFinalForm
                      disabled={values.type === TechrunGu12Type.Letter}
                      id="wagonsQuantity"
                      name="wagonsQuantity"
                    />
                  </FormGroupForFinalForm>
                </Col>

                <Col span={4}>
                  <FormGroupForFinalForm
                    label="Номер"
                    labelFor="number"
                    labelInfo="*"
                    name="number"
                  >
                    <InputGroupForFinalForm id="number" name="number" />
                  </FormGroupForFinalForm>

                  <ConsignersAutocompleteInFormGroupForFinalForm
                    id="consigner"
                    label="Грузоотправитель"
                    name="consigner"
                  />

                  <StationsAutocompleteInFormGroupForFinalForm
                    id="arrivalStation"
                    label="Станция назначения"
                    name="arrivalStation"
                    required
                  />

                  <FormGroupForFinalForm
                    label="Вагонов осталось"
                    labelFor="wagonsLeft"
                    name="wagonsLeft"
                  >
                    <InputGroupForFinalForm
                      id="wagonsLeft"
                      name="wagonsLeft"
                      readOnly
                    />
                  </FormGroupForFinalForm>
                </Col>

                <Col span={4} />

                <Col span={4}>
                  <H4>Собственники</H4>

                  <OwnersField name="owners" />
                </Col>

                <Col span={4}>
                  {transportations && (
                    <>
                      <H4>Отправки</H4>

                      <Table items={transportations}>
                        <Column
                          id="wagon"
                          label="Вагон"
                          cell={(transportation: ITechrunTransportation) => (
                            <LinkCell
                              params={{ number: transportation.wagon }}
                              to="wagons.edit"
                            >
                              {transportation.wagon}
                            </LinkCell>
                          )}
                        />

                        <Column
                          id="transportationName"
                          label="Название отправки"
                          cell={(transportation: ITechrunTransportation) => (
                            <LinkCell
                              params={{
                                id: transportation.id,
                                status: techrunTransportationStatusFromGroup(
                                  transportation.group
                                ),
                              }}
                              to="techruns.transportations.edit"
                            >
                              {transportation.transportationName}
                            </LinkCell>
                          )}
                        />
                      </Table>
                    </>
                  )}
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    setSubmitTrigger('saveAndContinue');
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    setSubmitTrigger('save');
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import * as React from 'react';

interface IProps<TState> {
  children: (
    state: TState,
    setState: React.Dispatch<React.SetStateAction<TState>>
  ) => React.ReactElement | null;
  initialState: TState;
}

export function StateContainer<TState>({
  children,
  initialState,
}: IProps<TState>) {
  const [state, setState] = React.useState(initialState);

  return children(state, setState);
}

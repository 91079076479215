import { Link } from '_core/router5/link';
import { Intent, Tag } from '@blueprintjs/core';
import * as React from 'react';
import { IWagonSerialized } from 'wagons/api';
import { getWagonHighestPriorityAlertColor } from 'wagons/utils';

import * as css from './wagonTags.module.css';

interface ILocalWagonTagData {
  isLocal: boolean;
  number: string;
}

type IWagonTagData = IWagonSerialized | ILocalWagonTagData;

export function createLocalWagonTag(number: string): ILocalWagonTagData {
  return { number, isLocal: true };
}

function isLocalWagonTag(
  tagData: IWagonTagData
): tagData is ILocalWagonTagData {
  return 'isLocal' in tagData && tagData.isLocal;
}

interface IProps {
  wagons: IWagonTagData[];
  onItemRemove?: (number: string) => void;
}

export function WagonTags({ wagons, onItemRemove }: IProps) {
  return (
    <div className={css.root}>
      {wagons.map(wagon => {
        const NumberComponent = isLocalWagonTag(wagon) ? 'span' : Link;

        const numberProps = isLocalWagonTag(wagon)
          ? { to: undefined }
          : { params: { number: wagon.number }, to: 'wagons.edit' };

        return (
          <Tag
            key={wagon.number}
            className={css.tag}
            intent={Intent.SUCCESS}
            minimal
            style={{
              backgroundColor: isLocalWagonTag(wagon)
                ? '#ddd'
                : getWagonHighestPriorityAlertColor(wagon.alerts),
            }}
            onRemove={onItemRemove && (() => onItemRemove(wagon.number))}
          >
            <NumberComponent {...numberProps}>{wagon.number}</NumberComponent>
          </Tag>
        );
      })}
    </div>
  );
}

import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, H4 } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useState } from 'react';
import { IRepairProtocolSell } from 'repairProtocolsSell/api';

import { ProtocolDateAlert } from '../protocolDateAlert';
import {
  IRepairProtocolPartsFieldValue,
  RepairProtocolPartsField,
} from './partsField';

export interface IRepairProtocolFormValues {
  date: Date | null;
  endDate: Date | null;
  number: string;
  parts: IRepairProtocolPartsFieldValue[];
  services: IRepairProtocolPartsFieldValue[];
  startDate: Date | null;
  vu23Date: Date | null;
  vu36Date: Date | null;
}

interface IProps {
  editingLocked: boolean;
  initialValues: IRepairProtocolFormValues;
  navigationLock: IFormNavigationLock;
  protocol: IRepairProtocolSell | undefined;
  onCancelEditing: (() => void) | undefined;
  onDuplicate: (() => void) | undefined;
  onStartEditing: (() => void) | undefined;
  onSubmit: (
    values: IRepairProtocolFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function RepairProtocolForm({
  editingLocked,
  initialValues,
  navigationLock,
  protocol,
  onCancelEditing,
  onDuplicate,
  onStartEditing,
  onSubmit,
}: IProps) {
  const [isDateAlertOpen, setIsDateAlertOpen] = useState(false);

  return (
    <FinalForm<IRepairProtocolFormValues>
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <H4>
                {protocol ? `Протокол ${protocol.number}` : 'Новый протокол'}
              </H4>

              <Grid>
                <Col span={4}>
                  <FormGroupForFinalForm
                    label="Номер"
                    labelFor="number"
                    name="number"
                  >
                    <InputGroupForFinalForm
                      id="number"
                      name="number"
                      readOnly={editingLocked}
                      required
                    />
                  </FormGroupForFinalForm>

                  <DateInputInFormGroupForFinalForm
                    fill
                    id="date"
                    label="Дата"
                    name="date"
                    readOnly={editingLocked}
                    required
                  />

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="startDate"
                        label="Дата начала"
                        name="startDate"
                        readOnly={editingLocked}
                        required
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="endDate"
                        label="Дата окончания"
                        name="endDate"
                        readOnly={editingLocked}
                        required
                      />
                    </Col>
                  </Grid>

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="vu23Date"
                        label="Дата ВУ-23"
                        name="vu23Date"
                        readOnly={editingLocked}
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="vu36Date"
                        label="Дата ВУ-36"
                        name="vu36Date"
                        readOnly={editingLocked}
                      />
                    </Col>
                  </Grid>
                </Col>

                <Col span={8}>
                  <VGrid>
                    <Row>
                      <H4>Услуги</H4>

                      <RepairProtocolPartsField
                        change={form.change}
                        name="services"
                        readOnly={editingLocked}
                      />
                    </Row>

                    <Row>
                      <H4>Детали</H4>

                      <RepairProtocolPartsField
                        change={form.change}
                        name="parts"
                        readOnly={editingLocked}
                      />
                    </Row>
                  </VGrid>
                </Col>
              </Grid>
            </Row>

            <Row>
              <Toolbar align="right">
                {onDuplicate && (
                  <Button
                    disabled={submitting}
                    icon="duplicate"
                    text="Дублировать"
                    onClick={onDuplicate}
                  />
                )}

                {onStartEditing && (
                  <Button
                    disabled={submitting}
                    icon="unlock"
                    intent={Intent.DANGER}
                    text="Редактировать"
                    onClick={onStartEditing}
                  />
                )}

                {onCancelEditing && (
                  <Button
                    disabled={submitting}
                    text="Отменить"
                    onClick={() => {
                      form.reset();
                      onCancelEditing();
                    }}
                  />
                )}

                {!editingLocked && (
                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    loading={submitting}
                    text="Сохранить"
                    onClick={() => {
                      const now = new Date();

                      if (
                        !protocol &&
                        values.endDate &&
                        values.endDate.getFullYear() > now.getFullYear()
                      ) {
                        setIsDateAlertOpen(true);
                      } else {
                        form.submit();
                      }
                    }}
                  />
                )}
              </Toolbar>
            </Row>
          </VGrid>

          <ProtocolDateAlert
            isOpen={isDateAlertOpen}
            onCancel={() => {
              setIsDateAlertOpen(false);
            }}
            onConfirm={() => {
              form.submit();
              setIsDateAlertOpen(false);
            }}
          />
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { SearchForm } from '_core/forms/searchForm';
import { indexByLastItem } from '_core/indexBy';
import { Switch } from '_core/inputs/switch';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TreeTableNode } from '_core/react-window/types';
import { findNode } from '_core/react-window/utils';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { useDerivedState } from '_core/useDerivedState';
import { Button, Intent } from '@blueprintjs/core';
import { fetchManyUsers } from 'accounts/api';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import {
  fetchPartners,
  IPartnerSerialized,
  PartnersSortingField,
} from 'partners/api';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useRouteNode } from 'react-router5';

import { PartnersTreeTableNodeData } from './columns';
import { PartnersListTable } from './listTable';
import { PartnersTreeTable } from './treeTable';

interface IPartnersListAsyncData {
  managers: IUser[];
  partners: IPartnerSerialized[];
  partnersByManager: Record<number, IPartnerSerialized[]>;
  usersById: Record<number, IUser>;
}

export default function PartnersListRoute() {
  const { route, router } = useRouteNode('partners');
  const api = useApiClient();

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'modified',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const { data, isFetching, refetch } = useAsyncData<IPartnersListAsyncData>(
    [api, route.params.pageSize, route.params.search, sorting],
    async () => {
      const partnersSorting: Array<ISorting<PartnersSortingField>> = [];

      if (sorting.field === 'name' || sorting.field === 'shortName') {
        partnersSorting.push({
          field: PartnersSortingField.ShortName,
          direction: sorting.direction,
        });
      } else if (sorting.field === 'isClient') {
        partnersSorting.push({
          field: PartnersSortingField.IsClient,
          direction: sorting.direction,
        });
      } else if (sorting.field === 'isSupplier') {
        partnersSorting.push({
          field: PartnersSortingField.IsSupplier,
          direction: sorting.direction,
        });
      } else if (sorting.field === 'manager') {
        partnersSorting.push({
          field: PartnersSortingField.Manager,
          direction: sorting.direction,
        });
      } else if (sorting.field === 'modified') {
        partnersSorting.push({
          field: PartnersSortingField.Modified,
          direction: sorting.direction,
        });
      } else {
        throw new Error(`Unexpected sorting field: "${sorting.field}"`);
      }

      const listResponse = await fetchAllPages(page =>
        fetchPartners(api, {
          page,
          pageSize: isFinite(route.params.pageSize)
            ? Number(route.params.pageSize)
            : undefined,
          search: route.params.search,
          sorting: partnersSorting,
        })
      );

      const partners = listResponse.results;
      const userIds = partners.map(partner => partner.manager);
      const users = await fetchManyUsers(api, userIds);
      const usersById = indexByLastItem(users, user => user.id);

      if (sorting.field === 'name') {
        users.sort((a, b) => {
          const aFormatted = formatUserName(a);
          const bFormatted = formatUserName(b);

          let result = 0;

          if (aFormatted < bFormatted) {
            result = -1;
          } else if (aFormatted > bFormatted) {
            result = 1;
          }

          if (sorting.direction === SortingDirection.Descending) {
            return -result;
          }

          return result;
        });
      }

      const partnersByManager: Record<number, IPartnerSerialized[]> = {};

      partners.forEach(partner => {
        const manager = partner.manager;

        partnersByManager[manager] = partnersByManager[manager] || [];
        partnersByManager[manager].push(partner);
      });

      return {
        managers: users,
        partners,
        partnersByManager,
        usersById,
      };
    }
  );

  const [nodes, setNodes] = useDerivedState<
    IPartnersListAsyncData | null,
    Array<TreeTableNode<PartnersTreeTableNodeData>> | null
  >(
    data,
    (input, prevNodes) =>
      input &&
      input.managers.map(
        (manager): TreeTableNode<PartnersTreeTableNodeData> => {
          const prevManagerNode = prevNodes
            ? findNode(
                prevNodes,
                node =>
                  node.data.kind === 'manager' &&
                  node.data.manager.id === manager.id
              )
            : undefined;

          const partners = input.partnersByManager[manager.id];

          return {
            id: `manager-${manager.id}`,
            data: { kind: 'manager', manager, partnersCount: partners.length },
            children: {
              isExpanded:
                prevManagerNode && prevManagerNode.children
                  ? prevManagerNode.children.isExpanded
                  : false,

              nodes: partners.map(
                (partner): TreeTableNode<PartnersTreeTableNodeData> => ({
                  id: `partner-${partner.id}`,
                  data: { kind: 'partner', partner },
                })
              ),
            },
          };
        }
      )
  );

  const listParams = JSON.stringify(route.params);

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const [renderTreeTable, setRenderTreeTable] = useState(true);

  return (
    <>
      <FormattedTitle>Контрагенты</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  router.navigate(route.name, {
                    ...route.params,
                    page: undefined,
                    search,
                  });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <Button text="Обновить" onClick={refetch} />

                <LinkButton text="Сбросить фильтры" to={route.name} />

                <LinkButton
                  intent={Intent.PRIMARY}
                  params={{ listParams }}
                  text="Добавить"
                  to="partners.create"
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Switch
            checked={renderTreeTable}
            label="Группировать по ответственному менеджеру"
            onChange={newChecked => {
              setRenderTreeTable(newChecked);
              applyFilterParams({ sorting: undefined });
            }}
          />
        </Row>

        <Row stretch>
          {!data || !nodes ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : renderTreeTable ? (
            <PartnersTreeTable
              isFetching={isFetching}
              listParams={listParams}
              nodes={nodes}
              sorting={sorting}
              onSortingChange={handleSortingChange}
              onNodesChange={setNodes}
            />
          ) : (
            <PartnersListTable
              isFetching={isFetching}
              listParams={listParams}
              partners={data.partners}
              sorting={sorting}
              usersById={data.usersById}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>
      </VGrid>
    </>
  );
}

import { ApiClient } from '_core/api/client';
import { makeFetchAll } from '_core/api/makeFetchAll';
import { ListResponse } from '_core/api/types';

const BASE_URL = '/contracts_templates';

interface IContractTemplate {
  id: number;
  name: string;
}

function fetchContractTemplates(
  api: ApiClient,
  { page }: { page?: number } = {}
) {
  return api.get<ListResponse<IContractTemplate>>(BASE_URL, { page });
}

export const fetchAllContractTemplates = makeFetchAll(fetchContractTemplates);

import { Card, H5, Icon } from '@blueprintjs/core';
import * as React from 'react';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';

import * as css from './widget.module.css';

interface IProps {
  children: React.ReactNode;
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragging: boolean;
  title: string;
}

export const Widget = React.forwardRef(function Widget(
  { children, draggableProps, dragHandleProps, isDragging, title }: IProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <div className={css.root} ref={ref} {...draggableProps}>
      <Card elevation={isDragging ? 4 : 2}>
        <div className={css.titleAndDragHandle}>
          <H5>{title}</H5>
          <Icon className={css.dragHandle} icon="menu" {...dragHandleProps} />
        </div>

        {children}
      </Card>
    </div>
  );
});

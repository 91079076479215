import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { formatMonth } from '_core/dates/utils';
import { isNotNull } from '_core/isNotNull';
import {
  Button,
  ButtonGroup,
  Intent,
  MenuItem,
  Popover,
  Position,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import dayjs from 'dayjs';
import { deepEqual } from 'fast-equals';
import * as React from 'react';

import * as css from './monthMultiSelect.module.css';

interface PopoverContentProps {
  initialValue: Date[];
  monthDates: Date[];
  onApply: (newValue: Date[]) => void;
}

function MonthsMultiSelectFilterPopoverContent({
  initialValue,
  monthDates,
  onApply,
}: PopoverContentProps) {
  const [value, setValue] = React.useState(initialValue);

  return (
    <>
      <div className={css.root}>
        <MultiSelect
          fill
          itemPredicate={(query, date) =>
            formatMonth(date).toLowerCase().includes(query.toLowerCase())
          }
          items={monthDates}
          itemRenderer={(date, { modifiers, handleClick }) =>
            modifiers.matchesPredicate ? (
              <MenuItem
                key={dayjs(date).format(DATE_FORMAT_API_DATE)}
                active={modifiers.active}
                icon={
                  value.some(item => deepEqual(item, date)) ? 'tick' : 'blank'
                }
                shouldDismissPopover={false}
                text={formatMonth(date)}
                onClick={handleClick}
              />
            ) : null
          }
          noResults={
            <MenuItem
              disabled
              text="Не удалось найти подходящий запросу месяц"
            />
          }
          placeholder=""
          popoverProps={{
            popoverClassName: css.popoverContent,
            position: Position.RIGHT,
          }}
          selectedItems={value
            .map(item => monthDates.find(date => deepEqual(date, item)))
            .filter(isNotNull)}
          tagInputProps={{
            onRemove: (_node, indexToRemove) => {
              setValue(prevValue =>
                prevValue.filter((_date, index) => index !== indexToRemove)
              );
            },
          }}
          tagRenderer={formatMonth}
          onItemSelect={dateToSelect => {
            setValue(prevValue =>
              prevValue.some(date => deepEqual(date, dateToSelect))
                ? prevValue.filter(date => !deepEqual(date, dateToSelect))
                : prevValue.concat([dateToSelect])
            );
          }}
        />
      </div>

      <Button
        fill
        intent={Intent.PRIMARY}
        text="Применить"
        onClick={() => {
          onApply(value);
        }}
      />
    </>
  );
}

interface Props {
  initialValue: Date[];
  isFetching?: boolean;
  label: string;
  monthDates: Date[];
  onApply: (newValue: Date[]) => void;
}

export function MonthsMultiSelectFilter({
  initialValue,
  isFetching,
  label,
  monthDates,
  onApply,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isEmpty = initialValue.length === 0;

  return (
    <ButtonGroup>
      <Popover
        content={
          <MonthsMultiSelectFilterPopoverContent
            initialValue={initialValue}
            monthDates={monthDates}
            onApply={newValue => {
              setIsOpen(false);
              onApply(newValue);
            }}
          />
        }
        isOpen={isOpen}
        position={Position.BOTTOM}
        onInteraction={setIsOpen}
      >
        <Button
          disabled={isFetching}
          icon="calendar"
          intent={isEmpty ? undefined : Intent.DANGER}
          loading={isFetching}
          text={label}
        />
      </Popover>

      {!isEmpty && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => {
            onApply([]);
          }}
        />
      )}
    </ButtonGroup>
  );
}

import { IColumnsWidth } from '_core/lists/columnPresets/useColumnPresets';
import { ISorting } from '_core/sorting';
import { Spinner } from '@blueprintjs/core';
import * as React from 'react';
import { GridOnItemsRenderedProps } from 'react-window';
import invariant from 'tiny-invariant';

import {
  BaseTable,
  BaseTableCellEditor,
  BaseTableColumn,
  BaseTableRenderCellProps,
  defaultRenderCell,
  Region,
} from './baseTable';
import { BaseCell } from './cells';
import { RenderableRegion } from './regions';
import {
  dummyRenderedItemsValue,
  RenderedItemsProvider,
} from './renderedItems';
import { TreeCell } from './treeCell';
import { TreeCellPadding } from './treeCellPadding';
import * as css from './treeTable.module.css';
import { TreeTableSelectionProvider } from './treeTableSelection';
import { TreeTableNode, TreeTableRowNode } from './types';
import { flattenNodesToRows, updateNodes } from './utils';

interface TreeTableRenderCellProps<T> extends BaseTableRenderCellProps {
  row: TreeTableRowNode<T>;
  onExpand: () => void;
}

function renderTreeCell<T>({
  children,
  row,
  style,
  onExpand,
  onMouseDown,
  onMouseEnter,
}: TreeTableRenderCellProps<T>) {
  return (
    <BaseCell
      noPadding
      style={style}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    >
      <TreeCellPadding depth={row.path.length}>
        <TreeCell row={row} onExpand={onExpand}>
          {children}
        </TreeCell>
      </TreeCellPadding>
    </BaseCell>
  );
}

interface TreeTableRenderSpinnerCellProps
  extends Omit<BaseTableRenderCellProps, 'children'> {
  depth: number;
}

function defaultRenderSpinnerCell({
  depth,
  style,
  onMouseDown,
  onMouseEnter,
}: TreeTableRenderSpinnerCellProps) {
  return (
    <BaseCell
      noPadding
      style={style}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    >
      <TreeCellPadding depth={depth}>
        <div className={css.spinnerCellContent}>
          <Spinner size={16} />
          <div className={css.spinnerCellContentText}>Загрузка...</div>
        </div>
      </TreeCellPadding>
    </BaseCell>
  );
}

export interface TreeTableColumn<T>
  extends Omit<
    BaseTableColumn,
    'copyCellContent' | 'getEditor' | 'renderCell' | 'renderCellContent'
  > {
  copyCellContent?: (node: TreeTableNode<T>, rowIndex: number) => string;
  expandable?: boolean;
  getEditor?: (
    node: TreeTableNode<T>
  ) => BaseTableCellEditor<unknown> | undefined;
  renderCell?: (
    props: TreeTableRenderCellProps<T>,
    rowIndex: number,
    columnIndex: number
  ) => React.ReactNode;
  renderCellContent: (
    node: TreeTableNode<T>,
    rowIndex: number
  ) => React.ReactNode;
  renderSpinnerCell?: (
    props: TreeTableRenderSpinnerCellProps
  ) => React.ReactNode;
}

interface Props<T> {
  columns: Array<TreeTableColumn<T>>;
  columnWidths?: IColumnsWidth | null;
  customRegions?: RenderableRegion[];
  fetchChildNodes?: (
    parentNodes: Array<TreeTableNode<T>>
  ) => Promise<Array<TreeTableNode<T>>>;
  getRowColor?: (node: TreeTableNode<T>) => string | undefined;
  isFetching?: boolean;
  isNodeSelectable?: (node: TreeTableNode<T>) => boolean;
  maxHeight?: number;
  nodes: Array<TreeTableNode<T>>;
  overscanColumnCount?: number;
  overscanRowCount?: number;
  selectedNodes?: Array<TreeTableNode<T>>;
  selectedRegions?: Region[];
  showColumns?: string[];
  sorting?: ISorting;
  stickyBottomRowCount?: number;
  stickyColumnCount?: number;
  onColumnWidthChanged?: (columnId: string, newWidth: number) => void;
  onNodeIsExpandedChange?: (
    node: TreeTableNode<T>,
    newIsExpanded: boolean
  ) => void;
  onNodesChange: (
    newNodesOrUpdater:
      | Array<TreeTableNode<T>>
      | ((prevNodes: Array<TreeTableNode<T>>) => Array<TreeTableNode<T>>)
  ) => void;
  onSelectedNodesChange?: (newSelectedNodes: Array<TreeTableNode<T>>) => void;
  onSelection?: (newSelectedRegions: Region[]) => void;
  onSortingChange?: (newSorting: ISorting) => void;
}

export function TreeTable<T>({
  columns: columnsProp,
  columnWidths,
  customRegions,
  fetchChildNodes,
  getRowColor: getRowColorProp,
  isFetching,
  isNodeSelectable: isNodeSelectableProp,
  maxHeight,
  nodes,
  overscanColumnCount,
  overscanRowCount,
  selectedNodes: selectedNodesProp,
  selectedRegions,
  showColumns,
  sorting,
  stickyBottomRowCount,
  stickyColumnCount,
  onColumnWidthChanged,
  onNodeIsExpandedChange,
  onNodesChange,
  onSelectedNodesChange: onSelectedNodesChangeProp,
  onSelection,
  onSortingChange,
}: Props<T>) {
  const [renderedItems, setRenderedItems] =
    React.useState<GridOnItemsRenderedProps>(dummyRenderedItemsValue);

  const rows = React.useMemo(() => flattenNodesToRows(nodes), [nodes]);

  React.useEffect(() => {
    if (!fetchChildNodes) {
      return;
    }

    for (
      let rowIndex = renderedItems.overscanRowStartIndex;
      rowIndex < Math.min(renderedItems.overscanRowStopIndex, rows.length);
      rowIndex++
    ) {
      const row = rows[rowIndex];

      if (row.type !== 'spinner') {
        continue;
      }

      const parentNode =
        row.path.length === 0 ? null : row.path[row.path.length - 1];
      invariant(parentNode, 'There is no parent to fetch child nodes for');
      invariant(parentNode.children, 'Spinner parent node has no children');

      if (
        !parentNode.children.hasMoreChildNodesToFetch ||
        parentNode.children.isFetching
      ) {
        continue;
      }

      onNodesChange(prevNodes =>
        updateNodes(prevNodes, node =>
          node.id === parentNode.id && node.children
            ? {
                ...node,
                children: { ...node.children, isFetching: true },
              }
            : node
        )
      );

      fetchChildNodes(row.path).then(
        newNodes => {
          onNodesChange(prevNodes =>
            updateNodes(prevNodes, node =>
              node.id === parentNode.id && node.children
                ? {
                    ...node,
                    children: {
                      ...node.children,
                      hasMoreChildNodesToFetch: false,
                      isFetching: false,
                      nodes: newNodes,
                    },
                  }
                : node
            )
          );
        },

        err => {
          onNodesChange(prevNodes =>
            updateNodes(prevNodes, node =>
              node.id === parentNode.id && node.children
                ? {
                    ...node,
                    children: {
                      ...node.children,
                      hasMoreChildNodesToFetch: false,
                      isFetching: false,
                    },
                  }
                : node
            )
          );

          throw err;
        }
      );
    }
  }, [fetchChildNodes, onNodesChange, renderedItems, rows]);

  const columns = React.useMemo(
    () =>
      columnsProp.map(
        ({
          expandable,
          getEditor,
          copyCellContent,
          renderCell = expandable ? renderTreeCell : defaultRenderCell,
          renderCellContent,
          renderSpinnerCell = expandable
            ? defaultRenderSpinnerCell
            : defaultRenderCell,
          ...otherColumnProps
        }): BaseTableColumn => ({
          ...otherColumnProps,
          copyCellContent: rowIndex => {
            const row = rows[rowIndex];

            switch (row.type) {
              case 'spinner':
                return '';
              case 'node': {
                return copyCellContent
                  ? copyCellContent(row.node, rowIndex)
                  : '';
              }
            }
          },
          getEditor: getEditor
            ? rowIndex => {
                const row = rows[rowIndex];

                return row.type === 'spinner' ? undefined : getEditor(row.node);
              }
            : undefined,
          renderCell: (props, rowIndex, columnIndex) => {
            const row = rows[rowIndex];
            const depth = row.path.length;

            switch (row.type) {
              case 'spinner':
                return renderSpinnerCell({ ...props, depth });
              case 'node':
                return renderCell(
                  {
                    ...props,
                    row,

                    onExpand: () => {
                      if (!row.node.children) {
                        return;
                      }

                      const newIsExpanded = !row.node.children.isExpanded;

                      if (onNodeIsExpandedChange) {
                        onNodeIsExpandedChange(row.node, newIsExpanded);
                      } else {
                        onNodesChange(prevNodes =>
                          updateNodes(prevNodes, node =>
                            node.id === row.node.id && node.children
                              ? {
                                  ...node,
                                  children: {
                                    ...node.children,
                                    isExpanded: newIsExpanded,
                                  },
                                }
                              : node
                          )
                        );
                      }
                    },
                  },

                  rowIndex,
                  columnIndex
                );
            }
          },
          renderCellContent: rowIndex => {
            const row = rows[rowIndex];

            switch (row.type) {
              case 'spinner':
                return null;
              case 'node':
                return renderCellContent(row.node, rowIndex);
            }
          },
        })
      ),

    [columnsProp, onNodeIsExpandedChange, onNodesChange, rows]
  );

  const getRowColor = React.useMemo(
    () =>
      getRowColorProp &&
      ((rowIndex: number) => {
        const row = rows[rowIndex];

        switch (row.type) {
          case 'spinner':
            return undefined;
          case 'node':
            return getRowColorProp(row.node);
        }
      }),

    [getRowColorProp, rows]
  );

  const itemKey = React.useCallback(
    ({ columnIndex, rowIndex }: { columnIndex: number; rowIndex: number }) => {
      const row = rows[rowIndex];

      switch (row.type) {
        case 'spinner': {
          const parentNode =
            row.path.length === 0 ? null : row.path[row.path.length - 1];
          invariant(parentNode, 'Found spinner row with no parent');
          return `${parentNode.id}-spinner${columnIndex}`;
        }
        case 'node':
          return `${row.node.id}x${columnIndex}`;
      }
    },

    [rows]
  );

  const isNodeSelectable = React.useMemo(() => {
    if (!isNodeSelectableProp) {
      return () => false;
    }

    return isNodeSelectableProp;
  }, [isNodeSelectableProp]);

  const selectedNodes = React.useMemo(
    () => selectedNodesProp || [],
    [selectedNodesProp]
  );

  const onSelectedNodesChange = React.useCallback(
    (newSelectedNodes: Array<TreeTableNode<T>>) => {
      if (onSelectedNodesChangeProp) {
        onSelectedNodesChangeProp(newSelectedNodes);
      }
    },

    [onSelectedNodesChangeProp]
  );

  return (
    <RenderedItemsProvider renderedItems={renderedItems}>
      <TreeTableSelectionProvider
        isNodeSelectable={isNodeSelectable}
        rows={rows}
        selectedNodes={selectedNodes}
        onSelectedNodesChange={onSelectedNodesChange}
      >
        <BaseTable
          columns={columns}
          columnWidths={columnWidths}
          customRegions={customRegions}
          getRowColor={getRowColor}
          isFetching={isFetching}
          itemKey={itemKey}
          maxHeight={maxHeight}
          overscanColumnCount={overscanColumnCount}
          overscanRowCount={overscanRowCount}
          rowCount={rows.length}
          selectedRegions={selectedRegions}
          showColumns={showColumns}
          sorting={sorting}
          stickyBottomRowCount={stickyBottomRowCount}
          stickyColumnCount={stickyColumnCount}
          onColumnWidthChanged={onColumnWidthChanged}
          onItemsRendered={setRenderedItems}
          onSelection={onSelection}
          onSortingChange={onSortingChange}
        />
      </TreeTableSelectionProvider>
    </RenderedItemsProvider>
  );
}

import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { TabsRouteNode, TabsRouteNodeTab } from '_core/router5/tabsRouteNode';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Tooltip } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { IWagonSerialized, WAGONS_BASE_ENDPOINT } from 'wagons/api';

import { WagonsEditCooNotesTab } from './tabs/editCooNotesTab';
import { WagonsEditDeliveryOverdueNotesTab } from './tabs/editDeliveryOverdueNotesTab';
import { WagonsEditEtranNotesTab } from './tabs/editEtranNotesTab';
import { WagonsEditHistoryTab } from './tabs/editHistoryTab';
import { WagonsEditMainTab } from './tabs/editMainTab';
import { WagonsEditNotesTab } from './tabs/editNotesTab';
import { WagonsEditParksHistoryTab } from './tabs/editParksHistoryTab';
import { WagonsEditRjdAppealNotesTab } from './tabs/editRjdAppealNotesTab';

export default function WagonsEditRoute() {
  const { route } = useRouteNode('wagons.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.number],
    () =>
      api.get<IWagonSerialized>(
        `${WAGONS_BASE_ENDPOINT}/${route.params.number}`
      )
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const title = data.number;

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem label="Вагоны" params={listParams} to="wagons" />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row stretch>
          <TabsRouteNode
            nodeName="wagons.edit"
            preserveRouteParams={['listParams', 'number']}
          >
            <TabsRouteNodeTab id="main" title="Основные данные">
              <WagonsEditMainTab
                listParams={listParams}
                updateWagonData={updatedWagon => {
                  updateData(() => updatedWagon);
                }}
                wagon={data}
              />
            </TabsRouteNodeTab>

            <TabsRouteNodeTab id="history" title="История дислокаций">
              <WagonsEditHistoryTab />
            </TabsRouteNodeTab>

            <TabsRouteNodeTab id="parksHistory" title="История по паркам">
              <WagonsEditParksHistoryTab wagonNumber={data.number} />
            </TabsRouteNodeTab>

            <TabsRouteNodeTab id="notes" title="Комментарии">
              <WagonsEditNotesTab />
            </TabsRouteNodeTab>

            <TabsRouteNodeTab id="etranNotes" title="Заготовка в Этране">
              <WagonsEditEtranNotesTab />
            </TabsRouteNodeTab>

            <TabsRouteNodeTab id="rjdAppealNotes" title="Обращение в РЖД">
              <WagonsEditRjdAppealNotesTab />
            </TabsRouteNodeTab>

            <TabsRouteNodeTab
              id="deliveryOverdueNotes"
              title="Просрочка доставки"
            >
              <WagonsEditDeliveryOverdueNotesTab />
            </TabsRouteNodeTab>

            <TabsRouteNodeTab id="coo" title="ГОО (принято приемосдатчиком)">
              <WagonsEditCooNotesTab />
            </TabsRouteNodeTab>

            {data.hasRecentNoteFiles && (
              <Tooltip content="Есть прикреплённые файлы">
                <Button icon="paperclip" minimal />
              </Tooltip>
            )}
          </TabsRouteNode>
        </Row>
      </VGrid>
    </>
  );
}

import { ApiClient } from '_core/api/client';
import {
  AnyAction,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  EnhancedStore,
  MiddlewareArray,
  ThunkAction,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ThunkMiddleware } from 'redux-thunk';

import { reducer } from './reducer';

interface ThunkExtraArgument {
  api: ApiClient;
}

export type ErpState = ReturnType<typeof reducer>;
type Action = AnyAction;

export type ErpStore = EnhancedStore<
  ErpState,
  Action,
  MiddlewareArray<ThunkMiddleware<ErpState, Action, ThunkExtraArgument>>
>;

export type ErpThunkAction<T> = ThunkAction<
  T,
  ErpState,
  ThunkExtraArgument,
  Action
>;

interface ThunkApiConfig<TSerializedError> {
  dispatch: ErpStore['dispatch'];
  extra: ThunkExtraArgument;
  serializedErrorType: TSerializedError;
  state: ErpState;
}

export const createErpAsyncThunk = <T, Arg = void, TError = unknown>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<T, Arg, ThunkApiConfig<TError>>,
  options?: {
    condition?(
      arg: Arg,
      api: { getState: () => ErpState; extra: ThunkExtraArgument }
    ): boolean;
    dispatchConditionRejection?: boolean;
    serializeError?: (error: unknown) => TError;
  }
) =>
  createAsyncThunk<T, Arg, ThunkApiConfig<TError>>(typePrefix, payloadCreator, {
    ...options,
    serializeError: options?.serializeError ?? (err => err as TError),
  });

export const useErpSelector: TypedUseSelectorHook<ErpState> = useSelector;
export const useErpDispatch = () => useDispatch<ErpStore['dispatch']>();

import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { isNotNull } from '_core/isNotNull';
import { Link } from '_core/router5/link';
import { Toolbar } from '_core/toolbar';
import {
  Button,
  Classes,
  Dialog,
  HTMLTable,
  Icon,
  Intent,
  Tooltip,
} from '@blueprintjs/core';
import * as React from 'react';
import { useState } from 'react';
import {
  acceptRentWagonSellWagons,
  RentWagonSellListItem,
} from 'rentWagonSell/api';

import * as css from './renewSellRentsDialog.module.css';

interface IProps {
  isOpen: boolean;
  renewableSellRents: RentWagonSellListItem[];
  onClose: () => void;
  onClosed: () => void;
}

type CreationStatus =
  | { kind: 'inprogress' }
  | { kind: 'success'; createdSellRent: RentWagonSellListItem }
  | { kind: 'fail'; error: unknown };

export function RenewSellRentsDialog({
  isOpen,
  renewableSellRents,
  onClose,
  onClosed,
}: IProps) {
  const api = useApiClient();

  const [rowStatuses, setRowStatuses] = useState<
    Partial<Record<number, CreationStatus>>
  >({});

  const isCreating = Object.values(rowStatuses)
    .filter(isNotNull)
    .some(rowStatus => rowStatus.kind === 'inprogress');

  return (
    <Dialog
      canEscapeKeyClose={!isCreating}
      canOutsideClickClose={!isCreating}
      isCloseButtonShown={!isCreating}
      isOpen={isOpen}
      title="Создать также исходящие аренды?"
      onClose={onClose}
      onClosed={onClosed}
    >
      <div className={Classes.DIALOG_BODY}>
        <HTMLTable bordered className={css.table} condensed striped>
          <thead>
            <tr>
              <th>Предыдущая аренда</th>
              <th>Вагон</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {renewableSellRents.map(rentWagon => {
              const rowStatus = rowStatuses[rentWagon.id];
              const isInProgress = rowStatus?.kind === 'inprogress';
              const isFailed = rowStatus?.kind === 'fail';

              return (
                <tr key={rentWagon.id}>
                  <td>
                    <Link
                      params={{ id: String(rentWagon.id) }}
                      rel="noopener"
                      target="_blank"
                      to="rentWagons.sell.edit"
                    >
                      {rentWagon.shortName}
                    </Link>
                  </td>

                  <td>
                    <Link
                      params={{ number: rentWagon.wagon }}
                      rel="noopener"
                      target="_blank"
                      to="wagons.edit"
                    >
                      {rentWagon.wagon}
                    </Link>
                  </td>

                  <td>
                    {rowStatus?.kind === 'success' ? (
                      <Link
                        params={{ id: String(rowStatus.createdSellRent.id) }}
                        rel="noopener"
                        target="_blank"
                        to="rentWagons.sell.edit"
                      >
                        {rowStatus.createdSellRent.shortName}
                      </Link>
                    ) : (
                      <Toolbar noWrap>
                        {rowStatus?.kind === 'fail' && (
                          <Tooltip
                            content={
                              rowStatus.error instanceof BadRequestError
                                ? rowStatus.error.message
                                : 'Непредвиденная ошибка'
                            }
                            intent={Intent.DANGER}
                          >
                            <Icon icon="error" intent={Intent.DANGER} />
                          </Tooltip>
                        )}

                        <Button
                          disabled={isInProgress}
                          intent={isFailed ? Intent.DANGER : Intent.PRIMARY}
                          loading={isInProgress}
                          small
                          text={isFailed ? 'Попробовать снова' : 'Создать'}
                          onClick={async () => {
                            setRowStatuses(prevState => ({
                              ...prevState,
                              [rentWagon.id]: { kind: 'inprogress' },
                            }));

                            try {
                              const response = await acceptRentWagonSellWagons(
                                api,
                                {
                                  protocol: rentWagon.protocol,
                                  startDate: new Date(),
                                  supplier: rentWagon.supplier,
                                  wagons: [rentWagon.wagon],
                                }
                              );

                              setRowStatuses(prevState => ({
                                ...prevState,
                                [rentWagon.id]: {
                                  kind: 'success',
                                  createdSellRent: response[0],
                                },
                              }));
                            } catch (err) {
                              setRowStatuses(prevState => ({
                                ...prevState,
                                [rentWagon.id]: { kind: 'fail', error: err },
                              }));

                              throw err;
                            }
                          }}
                        />
                      </Toolbar>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button disabled={isCreating} text="Закрыть" onClick={onClose} />
        </div>
      </div>
    </Dialog>
  );
}

import { identity } from './fp/identity';

export enum SortingDirection {
  Ascending,
  Descending,
}

export interface ISorting<TField extends string = string> {
  direction: SortingDirection;
  field: TField;
}

export function validateSorting<T extends string>(
  validate: (field: string) => field is T,
  sorting: ISorting<string> | null,
  fallback: ISorting<T>
): ISorting<T> {
  return sorting && validate(sorting.field)
    ? { ...sorting, field: sorting.field }
    : fallback;
}

export function expandSorting<TIn extends string, TOut extends string>(
  sorting: ISorting<TIn>,
  fieldMap: Record<TIn, TOut[]>
) {
  return fieldMap[sorting.field].map(
    (field): ISorting<TOut> => ({ field, direction: sorting.direction })
  );
}

const ASCENDING_CHAR = '▼';
const DESCENDING_CHAR = '▲';

export function sortingToRouterParam(sorting: ISorting): string {
  const prefix =
    sorting.direction === SortingDirection.Ascending
      ? ASCENDING_CHAR
      : DESCENDING_CHAR;

  return `${prefix}${sorting.field}`;
}

export function sortingFromRouterParam(
  param: string | undefined
): ISorting | null {
  if (param === undefined) {
    return null;
  }

  const directionChar = param[0];

  if (![ASCENDING_CHAR, DESCENDING_CHAR].includes(directionChar)) {
    return null;
  }

  return {
    field: param.slice(1),
    direction:
      directionChar === ASCENDING_CHAR
        ? SortingDirection.Ascending
        : SortingDirection.Descending,
  };
}

export function sortingToApiQueryParam(
  sortings: ISorting[],
  stringify: (field: string) => string = identity
) {
  return sortings.length === 0
    ? undefined
    : sortings
        .map(({ direction, field }) => {
          const sign = direction === SortingDirection.Ascending ? '' : '-';

          return `${sign}${stringify(field)}`;
        })
        .join(',');
}

import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { selectFavoriteParks } from '_core/me/me';
import { Link } from '_core/router5/link';
import { useErpSelector } from '_erp/redux';
import { HTMLTable } from '@blueprintjs/core';
import * as React from 'react';
import { WagonAlert } from 'wagons/api';
import { getWagonAlertLabel } from 'wagons/utils';

import { selectWagonsAlertsWidgetData } from '../redux';
import * as css from './wagonsAlerts.module.css';

export function WagonsAlertsWidget() {
  const { data, isFetched } = useErpSelector(selectWagonsAlertsWidgetData);
  const favoriteParks = useErpSelector(selectFavoriteParks);

  return !data ? (
    isFetched ? (
      <GenericErrorMessage />
    ) : (
      <CenteredSpinner />
    )
  ) : (
    <HTMLTable bordered className={css.table} condensed>
      <tbody>
        <tr>
          <td>
            <Link
              params={{
                alerts: WagonAlert.NonWorkingPark,
                parks: favoriteParks.join(','),
              }}
              rel="noopener"
              target="_blank"
              to="wagons"
            >
              {getWagonAlertLabel(WagonAlert.NonWorkingPark)}
            </Link>
          </td>

          <td className={css.numberCell}>{data.nonworkingParkCount}</td>
        </tr>

        <tr>
          <td>
            <Link
              params={{
                alerts: WagonAlert.RepairDate,
                parks: favoriteParks.join(','),
              }}
              rel="noopener"
              target="_blank"
              to="wagons"
            >
              {getWagonAlertLabel(WagonAlert.RepairDate)}
            </Link>
          </td>

          <td className={css.numberCell}>{data.repairDateCount}</td>
        </tr>

        <tr>
          <td>
            <Link
              params={{
                alerts: WagonAlert.WagonMileage,
                parks: favoriteParks.join(','),
              }}
              rel="noopener"
              target="_blank"
              to="wagons"
            >
              {getWagonAlertLabel(WagonAlert.WagonMileage)}
            </Link>
          </td>

          <td className={css.numberCell}>{data.wagonMileageCount}</td>
        </tr>

        <tr>
          <td>
            <Link
              params={{
                alerts: WagonAlert.OperationBros,
                parks: favoriteParks.join(','),
              }}
              rel="noopener"
              target="_blank"
              to="wagons"
            >
              {getWagonAlertLabel(WagonAlert.OperationBros)}
            </Link>
          </td>

          <td className={css.numberCell}>{data.operationBrosCount}</td>
        </tr>

        <tr>
          <td>
            <Link
              params={{
                alerts: WagonAlert.StationDowntime,
                parks: favoriteParks.join(','),
              }}
              rel="noopener"
              target="_blank"
              to="wagons"
            >
              {getWagonAlertLabel(WagonAlert.StationDowntime)}
            </Link>
          </td>

          <td className={css.numberCell}>{data.stationDowntimeCount}</td>
        </tr>

        <tr>
          <td>
            <Link
              params={{
                alerts: WagonAlert.DeliveryDateOverdue,
                parks: favoriteParks.join(','),
              }}
              rel="noopener"
              target="_blank"
              to="wagons"
            >
              {getWagonAlertLabel(WagonAlert.DeliveryDateOverdue)}
            </Link>
          </td>

          <td className={css.numberCell}>{data.deliveryDateOverdueCount}</td>
        </tr>
      </tbody>
    </HTMLTable>
  );
}

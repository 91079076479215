import { ApiClient } from '_core/api/client';
import { makeFetchAll } from '_core/api/makeFetchAll';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_TIME } from '_core/dates/formats';
import { serializable } from '_core/serializable';
import dayjs from 'dayjs';

export interface IParkScheduleSerialized {
  created: string;
  id: number;
  startTime: string;
}

export interface IParkSchedule
  extends Omit<IParkScheduleSerialized, 'created'> {
  created: Date;
}

@serializable
class ParkSchedule implements IParkSchedule {
  @serializable.datetime readonly created;
  readonly id;
  readonly startTime;

  static fromJSON(serialized: IParkScheduleSerialized) {
    return serializable.fromJSON(ParkSchedule, serialized);
  }

  constructor(parkSchedule: IParkSchedule) {
    this.created = parkSchedule.created;
    this.id = parkSchedule.id;
    this.startTime = parkSchedule.startTime;
  }
}

async function fetchParksSchedules(
  api: ApiClient,
  { page }: { page?: number } = {}
): Promise<ListResponse<IParkSchedule>> {
  const response = await api.get<ListResponse<IParkScheduleSerialized>>(
    '/parks_schedules',
    { page }
  );

  return {
    ...response,
    results: response.results.map(ParkSchedule.fromJSON),
  };
}

export const fetchAllParkSchedules = makeFetchAll(fetchParksSchedules);

export function formatParkSchedules(schedules: Array<{ startTime: string }>) {
  return schedules
    .map(schedule =>
      dayjs(schedule.startTime, 'HH:mm:ss').format(DATE_FORMAT_TIME)
    )
    .join(', ');
}

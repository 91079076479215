import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { DocumentStatus } from 'documents/types';
import { VatRate } from 'vatRates/vatRates';

const BASE_ENDPOINT = '/contracts_sell_agreement';

interface IContractsSellAgreementSerialized {
  additionalDowntimeRate: string;
  author: number | null;
  authorModified: number | null;
  contract: number;
  created: string;
  date: string;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  id: number;
  modified: string;
  note: string;
  number: string;
  status: DocumentStatus;
}

export interface IContractsSellAgreement
  extends Omit<
    IContractsSellAgreementSerialized,
    'created' | 'date' | 'modified'
  > {
  created: Date;
  date: Date;
  modified: Date;
}

function deserializeContractSellAgreement({
  created,
  date,
  modified,
  ...otherProps
}: IContractsSellAgreementSerialized): IContractsSellAgreement {
  return {
    ...otherProps,
    created: parseDate(created),
    date: parseDate(date),
    modified: parseDate(modified),
  };
}

export enum ContractsSellAgreementsSortingField {
  Created = 'created',
  Date = 'date',
  OrderingNumber = 'orderingNumber',
}

interface IFetchContractsSellAgreementsParams {
  contract?: number;
  page?: number;
  sorting?: Array<ISorting<ContractsSellAgreementsSortingField>>;
}

export async function fetchContractsSellAgreements(
  api: ApiClient,
  { contract, page, sorting }: IFetchContractsSellAgreementsParams = {}
): Promise<ListResponse<IContractsSellAgreement>> {
  const response = await api.get<
    ListResponse<IContractsSellAgreementSerialized>
  >(BASE_ENDPOINT, {
    contract,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
  });

  return {
    ...response,
    results: response.results.map(deserializeContractSellAgreement),
  };
}

interface ICreateOrUpdateContractsSellAgreementParams {
  additionalDowntimeRate: string;
  contract: number;
  date: Date | null;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  note: string;
  number: string;
  status: DocumentStatus;
}

function serializeCreateOrUpdateContractsSellAgreementParams({
  date,
  ...otherParams
}: ICreateOrUpdateContractsSellAgreementParams) {
  return {
    ...otherParams,
    date: date && dayjs(date).format(DATE_FORMAT_API_DATE),
  };
}

export async function createContractsSellAgreement(
  api: ApiClient,
  params: ICreateOrUpdateContractsSellAgreementParams
) {
  const updatedAgreement = await api.post<IContractsSellAgreementSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateContractsSellAgreementParams(params)
  );

  return deserializeContractSellAgreement(updatedAgreement);
}

export async function updateContractsSellAgreement(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateContractsSellAgreementParams
) {
  const updatedAgreement = await api.put<IContractsSellAgreementSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateContractsSellAgreementParams(params)
  );

  return deserializeContractSellAgreement(updatedAgreement);
}

import { useApiClient } from '_core/api/context';
import { Link } from '_core/router5/link';
import { useAsyncData } from '_core/useAsyncData';
import {
  Button,
  Classes,
  Intent,
  Popover,
  Position,
  UL,
} from '@blueprintjs/core';
import * as React from 'react';
import { fetchTechrunRequestWagonsStates } from 'techrunRequests/api';

interface IWagonStateLinkProps {
  count: number | null | undefined;
  label: string;
  params: Record<string, unknown> | null | undefined;
}

function WagonStateLink({ count, label, params }: IWagonStateLinkProps) {
  return (
    <li>
      <Link
        params={params || undefined}
        rel="noopener"
        target="_blank"
        to={params == null ? undefined : 'wagons'}
      >
        {label} ({count == null ? '...' : count})
      </Link>
    </li>
  );
}

function WagonStatesPopoverContent({ requestId }: { requestId: number }) {
  const api = useApiClient();

  const { data } = useAsyncData([api, requestId], () =>
    fetchTechrunRequestWagonsStates(api, requestId)
  );

  return (
    <UL className={Classes.LIST_UNSTYLED}>
      <WagonStateLink
        count={data?.wagonsStates.total}
        label="Все"
        params={
          data && data.requestWagons.length !== 0
            ? { numbers: data.requestWagons.join(',') }
            : null
        }
      />

      <WagonStateLink
        count={data?.wagonsStates.assigned}
        label="Распределённые"
        params={
          data && data.wagonsStates.assignedWagons.length !== 0
            ? { numbers: data.wagonsStates.assignedWagons.join(',') }
            : null
        }
      />

      <WagonStateLink
        count={data?.wagonsStates.arrivals}
        label="Подход под погрузку"
        params={
          data && data.requestWagons.length !== 0
            ? {
                arrivalStation: data.requestDepartureStation,
                numbers: data.requestWagons.join(','),
                operationStationNot: data.requestDepartureStation,
              }
            : null
        }
      />

      <WagonStateLink
        count={data?.wagonsStates.load}
        label="Под погрузкой"
        params={
          data && data.requestWagons.length !== 0
            ? {
                numbers: data.requestWagons.join(','),
                operationStation: data.requestDepartureStation,
              }
            : null
        }
      />

      <WagonStateLink
        count={data?.wagonsStates.onway}
        label="В пути"
        params={
          data && data.wagonsStates.onwayWagons.length !== 0
            ? { numbers: data.wagonsStates.onwayWagons.join(',') }
            : null
        }
      />

      <WagonStateLink
        count={data?.wagonsStates.unload}
        label="Под выгрузкой"
        params={
          data && data.wagonsStates.unloadWagons.length !== 0
            ? { numbers: data.wagonsStates.unloadWagons.join(',') }
            : null
        }
      />
    </UL>
  );
}

interface IProps {
  requestId: number;
}

export function WagonStatesPopover({ requestId }: IProps) {
  return (
    <Popover
      content={<WagonStatesPopoverContent requestId={requestId} />}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      position={Position.LEFT_BOTTOM}
    >
      <Button intent={Intent.SUCCESS} text="Состояние вагонов" />
    </Popover>
  );
}

import { Radio, RadioGroup } from '_core/inputs/radio';
import * as React from 'react';

import * as css from './yesNoRadioGroup.module.css';

interface IProps {
  label: string;
  value: string | undefined;
  onChange: (newValue: string | undefined) => void;
}

export function YesNoRadioGroupFilter({ label, value, onChange }: IProps) {
  return (
    <div>
      <span>{label}:</span>{' '}
      <RadioGroup
        className={css.radioGroup}
        inline
        selectedValue={value || ''}
        onChange={newValue => {
          onChange(newValue || undefined);
        }}
      >
        <Radio label="Да" value="True" />
        <Radio label="Нет" value="False" />
        <Radio label="Не важно" value="" />
      </RadioGroup>
    </div>
  );
}

import cx from 'classnames';
import * as React from 'react';

import * as css from './ellipsis.module.css';

interface IProps {
  children?: string | string[];
  className?: string;
  component: React.ElementType;
  style?: React.CSSProperties;
  wrap?: boolean;
}

export function Ellipsis({
  children,
  className,
  component: Component,
  wrap,
  ...otherProps
}: IProps & Record<string, unknown>) {
  return (
    <Component
      className={cx(className, css.root, {
        [css.root_wrap]: wrap,
      })}
      title={
        !children || typeof children === 'string' ? children : children.join('')
      }
      {...otherProps}
    >
      {children}
    </Component>
  );
}

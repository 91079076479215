import { useApiClient } from '_core/api/context';
import {
  DATE_FORMAT_API_DATETIME,
  DATE_FORMAT_DATE,
} from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { useDialog } from '_core/dialogs/useDialog';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { finiteNumberOr } from '_core/finiteNumberOr';
import { SearchForm } from '_core/forms/searchForm';
import { indexByLastItem } from '_core/indexBy';
import { DateRangePicker } from '_core/inputs/dateRangePicker';
import { ColumnsSelectorsGroup } from '_core/lists/columnPresets/columnsSelectorsGroup';
import { useColumnPresets } from '_core/lists/columnPresets/useColumnPresets';
import { Pagination } from '_core/pagination';
import { ListTable } from '_core/react-window/listTable';
import {
  expandSorting,
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
  validateSorting,
} from '_core/sorting';
import { StateContainer } from '_core/stateContainer';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime';
import { Tooltip2 } from '@blueprintjs/popover2';
import { camelCase } from 'change-case';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { DownloadEmptyValues, Park } from 'parks/api';
import { fetchAllParksTemplates } from 'parksTemplates/api';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useRoute } from 'react-router5';
import { ArrDepOperStationsFilterInPopover } from 'stations/arrDepOperFilterInPopover';
import { WagonAlertsLegend } from 'wagons/alertsLegend';
import {
  fetchWagons,
  isWagonAlert,
  IWagonSerialized,
  updateWagon,
  updateWagons,
} from 'wagons/api';
import { IWagonDialogFormValues, WagonDialogForm } from 'wagons/dialogForm';
import { HasEtranNoteFilter } from 'wagons/hasEtranNoteFilter';
import { HistoryFilterInPopover } from 'wagons/historyFilterInPopover';
import { WagonNumbersFilter } from 'wagons/numbersFilter';
import {
  createWagonColumns,
  isWagonColumnsSortingField,
  WagonColumnsSortingField,
  wagonColumnsSortingFieldMap,
} from 'wagons/table/columns';
import {
  getWagonHighestPriorityAlertColor,
  wagonAlertsOptions,
} from 'wagons/utils';

import { EmptyParksForm, getEmptyParksValue } from './emptyParksForm';

interface Props {
  park: Park;
}

export function ParksEditDislocationsTab({ park }: Props) {
  const { route, router } = useRoute();
  const api = useApiClient();

  const [isModalPopoverOpen, setModalPopoverOpen] = useState<boolean>(false);

  const batchWagonDialogForm =
    useDialog<{ initialValues: IWagonDialogFormValues }>();
  const openBatchWagonDialogForm = batchWagonDialogForm.open;

  const wagonDialogForm =
    useDialog<{ initialValues: IWagonDialogFormValues; wagonNumber: string }>();
  const openWagonDialogForm = wagonDialogForm.open;

  const sorting = useMemo(
    () =>
      validateSorting(
        isWagonColumnsSortingField,
        sortingFromRouterParam(route.params.sorting),
        {
          field: WagonColumnsSortingField.Number,
          direction: SortingDirection.Ascending,
        }
      ),
    [route.params.sorting]
  );

  const wagonsRequest = useAsyncData([api, route, sorting], async () => {
    if (route.name !== 'parks.edit.dislocations') {
      return null;
    }

    const { meta, results } = await fetchWagons(api, {
      alerts: route.params.alerts
        ? (route.params.alerts as string).split(',').filter(isWagonAlert)
        : undefined,
      arrivalStation: isFinite(route.params.arrivalStation)
        ? Number(route.params.arrivalStation)
        : undefined,
      consignee: isFinite(route.params.consignee)
        ? Number(route.params.consignee)
        : undefined,
      currentParkWagons:
        route.params.currentParkWagons === 'True' ? true : undefined,
      departureStation: isFinite(route.params.departureStation)
        ? Number(route.params.departureStation)
        : undefined,
      dislocationDatetime: route.params.dislocationDatetime
        ? parseDate(route.params.dislocationDatetime)
        : undefined,
      hasEtranNote:
        route.params.hasEtranNote === 'True'
          ? true
          : route.params.hasEtranNote === 'False'
          ? false
          : undefined,
      numbers: route.params.numbers
        ? (route.params.numbers as string).split(',')
        : undefined,
      operationStation: isFinite(route.params.operationStation)
        ? Number(route.params.operationStation)
        : undefined,
      page: isFinite(route.params.page) ? Number(route.params.page) : undefined,
      pageSize: isFinite(route.params.pageSize)
        ? Number(route.params.pageSize)
        : undefined,
      park: isFinite(route.params.id) ? Number(route.params.id) : undefined,
      search: route.params.search ? (route.params.search as string) : undefined,
      sorting: expandSorting(sorting, wagonColumnsSortingFieldMap),
    });

    return {
      currentPage: meta.currentPage,
      pageSize: meta.pageSize,
      totalPages: meta.totalPages,
      wagons: results,
    };
  });

  const [selectedWagons, setSelectedWagons] = useState<string[]>([]);

  const parkTemplatesRequest = useAsyncData([api], () =>
    fetchAllParksTemplates(api)
  );

  const isFilteringByDislocationDatetime = Boolean(
    route.params.dislocationDatetime
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleBatchSelect = useCallback(
    (numbers: string[]) => {
      if (!wagonsRequest.data) {
        return;
      }

      const allWagonNumbers = wagonsRequest.data.wagons.map(
        wagon => wagon.number
      );

      setSelectedWagons(prevState =>
        prevState.concat(
          numbers.filter(
            number =>
              !prevState.includes(number) && allWagonNumbers.includes(number)
          )
        )
      );
    },
    [wagonsRequest.data]
  );

  const handleBatchEditClick = useCallback(() => {
    openBatchWagonDialogForm({
      initialValues: {
        etranNote: '',
        managerNote: '',
        managerNote2: '',
        managerNote3: '',
        owner: null,
      },
    });
  }, [openBatchWagonDialogForm]);

  const handleEditClick = useCallback(
    (number: string) => {
      if (!wagonsRequest.data) {
        return;
      }

      const wagon = wagonsRequest.data.wagons.find(w => w.number === number);

      if (!wagon) {
        return;
      }

      openWagonDialogForm({
        wagonNumber: number,

        initialValues: {
          etranNote: wagon.lastEtranNote,
          managerNote: wagon.managerNote,
          managerNote2: wagon.managerNote2,
          managerNote3: wagon.managerNote3,
          owner: wagon.owner && wagon.owner.id,
        },
      });
    },
    [openWagonDialogForm, wagonsRequest.data]
  );

  const isBatchEditEnabled = selectedWagons.length !== 0;

  const columns = useMemo(
    () =>
      createWagonColumns({
        dislocationProp: isFilteringByDislocationDatetime
          ? 'selectedDislocation'
          : 'latestDislocation',
        isBatchEditEnabled,
        sortableByDistanceToArrivalStation: true,
        onBatchEditClick: handleBatchEditClick,
        onBatchSelect: handleBatchSelect,
        onEditClick: handleEditClick,
      }),
    [
      handleBatchEditClick,
      handleBatchSelect,
      handleEditClick,
      isBatchEditEnabled,
      isFilteringByDislocationDatetime,
    ]
  ).filter(column => column.id !== 'requestPartner');

  const columnLabels = columns.reduce<Record<string, string>>(
    (labels, column) => {
      labels[column.id] = column.label;

      return labels;
    },

    {}
  );

  const columnPresets = useColumnPresets(['parks', 'dislocations'], {
    allColumnIds: columns.map(column => column.id),
    alwaysShownColumnIds: ['number'],
    getColumnLabel: columnId => columnLabels[columnId],
  });

  const handleSortingChange = useCallback(
    (newSorting: ISorting<WagonColumnsSortingField>) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const getRowColor = useCallback(
    (wagon: IWagonSerialized) =>
      getWagonHighestPriorityAlertColor(wagon.alerts),
    []
  );

  const getItemId = useCallback((wagon: IWagonSerialized) => wagon.number, []);

  return (
    <>
      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <ColumnsSelectorsGroup
                  columnOptions={columnPresets.columnOptions}
                  defaultValue={columnPresets.defaultValue}
                  initialValue={columnPresets.initialValue}
                  templateColumns={
                    parkTemplatesRequest.data
                      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        parkTemplatesRequest.data
                          .find(t => t.id === park.template)!
                          .records.map(record =>
                            [
                              record.model === 'dislocations.DislocationRecord'
                                ? 'dislocation'
                                : undefined,

                              camelCase(record.nameDb),
                            ]
                              .filter(Boolean)
                              .join('.')
                          )
                      : undefined
                  }
                  onApply={columnPresets.onShowColumnsApply}
                  preset={columnPresets.activePreset}
                  onColumnPresetChange={columnPresets.onColumnPresetChange}
                />

                <HasEtranNoteFilter
                  initialValue={route.params.hasEtranNote}
                  label="Заготовка в Этране"
                  onApply={hasEtranNote => {
                    applyFilterParams({ hasEtranNote });
                  }}
                />

                {!isFilteringByDislocationDatetime && (
                  <WagonNumbersFilter
                    initialValue={route.params.numbers}
                    onApply={numbers => {
                      applyFilterParams({ numbers });
                    }}
                  />
                )}

                <ArrDepOperStationsFilterInPopover
                  initialValue={{
                    arrivalStation: finiteNumberOr(
                      undefined,
                      route.params.arrivalStation
                    ),
                    departureStation: finiteNumberOr(
                      undefined,
                      route.params.departureStation
                    ),
                    operationStation: finiteNumberOr(
                      undefined,
                      route.params.operationStation
                    ),
                    consignee: route.params.consignee,
                  }}
                  showConsigneeFilter
                  onApply={({
                    arrivalStation,
                    consignee,
                    departureStation,
                    operationStation,
                  }) => {
                    applyFilterParams({
                      arrivalStation:
                        (arrivalStation && String(arrivalStation)) || undefined,

                      consignee,

                      departureStation:
                        (departureStation && String(departureStation)) ||
                        undefined,

                      operationStation:
                        (operationStation && String(operationStation)) ||
                        undefined,
                    });
                  }}
                />

                {!isFilteringByDislocationDatetime && (
                  <CheckboxListFilter
                    allOption
                    iconName="warning-sign"
                    initialValue={route.params.alerts}
                    label="Предупреждения"
                    options={wagonAlertsOptions}
                    onApply={alerts => {
                      applyFilterParams({ alerts });
                    }}
                  />
                )}

                <HistoryFilterInPopover
                  initialValue={{
                    currentParkWagons: route.params.currentParkWagons,
                    dislocationDateTime: route.params.dislocationDatetime,
                  }}
                  showCurrentParkWagons
                  onApply={historyFilter => {
                    const params: Record<string, string | undefined> = {
                      alerts: undefined,
                      arrivalStation: undefined,
                      departureStation: undefined,
                      operationStation: undefined,

                      currentParkWagons: historyFilter.currentParkWagons,
                      dislocationDatetime: historyFilter.dislocationDateTime,
                    };

                    if (
                      sorting.field ===
                      WagonColumnsSortingField.DistanceToArrivalStation
                    ) {
                      params.sorting = undefined;
                    }

                    applyFilterParams(params);
                  }}
                />

                <StateContainer<DownloadEmptyValues | null> initialState={null}>
                  {(distance, setDistance) => {
                    const href = distance
                      ? api.buildApiUrl(
                          `/parks/${route.params.id}/download_empty_arriving`,
                          {
                            distance,
                          }
                        )
                      : undefined;

                    return (
                      <ButtonGroup>
                        <AnchorButton
                          disabled={!href}
                          href={href}
                          download
                          icon="download"
                          intent={Intent.SUCCESS}
                          rel="noopener"
                          target="_blank"
                          text={`Подход порожних${
                            distance ? ` (${getEmptyParksValue(distance)})` : ''
                          }`}
                        />

                        <Popover
                          content={
                            <EmptyParksForm
                              initialValue={distance}
                              onSubmit={v => {
                                setDistance(v as DownloadEmptyValues);
                                setModalPopoverOpen(false);
                              }}
                            />
                          }
                          position={Position.BOTTOM}
                          isOpen={isModalPopoverOpen}
                          canEscapeKeyClose
                        >
                          <Button
                            onClick={() => setModalPopoverOpen(prev => !prev)}
                            intent={Intent.SUCCESS}
                            rightIcon="cog"
                            text=""
                          />
                        </Popover>
                      </ButtonGroup>
                    );
                  }}
                </StateContainer>

                <StateContainer<DateRange> initialState={[null, null]}>
                  {(dateRange, setDateRange) => {
                    const href =
                      dateRange[0] && dateRange[1]
                        ? api.buildApiUrl(
                            `/parks/${route.params.id}/download_notes_xlsx`,
                            {
                              startDate: dayjs(dateRange[0])
                                .startOf('day')
                                .format(DATE_FORMAT_API_DATETIME),
                              endDate: dayjs(dateRange[1])
                                .endOf('day')
                                .format(DATE_FORMAT_API_DATETIME),
                            }
                          )
                        : undefined;

                    return (
                      <ButtonGroup>
                        <Tooltip2
                          placement="bottom"
                          content="Скачивает все имеющиеся комментарии для всех (либо только для отфильтрованных) вагонов за выбранный период."
                        >
                          <AnchorButton
                            disabled={!href}
                            href={href}
                            download
                            icon="download"
                            intent={Intent.SUCCESS}
                            rel="noopener"
                            target="_blank"
                            text="Комментарии"
                          />
                        </Tooltip2>

                        <Popover
                          content={
                            <DateRangePicker
                              allowSingleDayRange
                              value={dateRange}
                              onChange={setDateRange}
                            />
                          }
                          position={Position.BOTTOM}
                        >
                          <Button
                            intent={Intent.SUCCESS}
                            rightIcon="calendar"
                            text={
                              dateRange[0] &&
                              dateRange[1] &&
                              `${dayjs(dateRange[0]).format(
                                DATE_FORMAT_DATE
                              )}-${dayjs(dateRange[1]).format(
                                DATE_FORMAT_DATE
                              )}`
                            }
                          />
                        </Popover>
                      </ButtonGroup>
                    );
                  }}
                </StateContainer>
                <Tooltip2
                  placement="bottom"
                  content="Скачивает текущую дислокацию всех вагонов."
                >
                  <AnchorButton
                    href={api.buildApiUrl(
                      `/parks/${route.params.id}/download_xlsx`,
                      { dislocationDatetime: route.params.dislocationDatetime }
                    )}
                    icon="download"
                    intent={Intent.SUCCESS}
                    text="Дислокация"
                  />
                </Tooltip2>
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <span>Выбрано вагонов: {selectedWagons.length}</span>
        </Row>

        <Row>
          <WagonAlertsLegend />
        </Row>

        <Row stretch>
          {!wagonsRequest.data ? (
            wagonsRequest.isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : wagonsRequest.data.wagons.length === 0 ? (
            wagonsRequest.isFetching ? (
              <CenteredSpinner />
            ) : (
              <EmptyListMessage description="Нет дислокаций" />
            )
          ) : (
            <ListTable
              columns={columns}
              columnWidths={columnPresets.columnWidths}
              getItemId={getItemId}
              getRowColor={getRowColor}
              isFetching={wagonsRequest.isFetching}
              items={wagonsRequest.data.wagons}
              lineNumbersStart={
                wagonsRequest.data.pageSize *
                  (wagonsRequest.data.currentPage - 1) +
                1
              }
              selectedItems={selectedWagons}
              showColumns={columnPresets.showColumns}
              sorting={sorting}
              stickyColumnCount={3}
              onColumnWidthChanged={columnPresets.onColumnWidthChanged}
              onSelectedItemsChange={setSelectedWagons}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>

        {wagonsRequest.data && (
          <Row>
            <Pagination
              pageSize={wagonsRequest.data.pageSize}
              totalPages={wagonsRequest.data.totalPages}
            />
          </Row>
        )}
      </VGrid>

      {wagonDialogForm.state && (
        <WagonDialogForm
          initialValues={wagonDialogForm.state.initialValues}
          isOpen={wagonDialogForm.state.isOpen}
          title="Редактирование вагона"
          onClose={wagonDialogForm.close}
          onClosed={wagonDialogForm.destroy}
          onSubmit={async values => {
            try {
              const updatedWagon = await updateWagon(
                api,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                wagonDialogForm.state!.wagonNumber,
                values
              );

              wagonsRequest.updateData(
                oldData =>
                  oldData && {
                    ...oldData,
                    wagons: oldData.wagons.map(wagon =>
                      wagon.number === updatedWagon.number
                        ? updatedWagon
                        : wagon
                    ),
                  }
              );

              wagonDialogForm.close();

              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось отредактировать вагон: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}

      {batchWagonDialogForm.state && (
        <WagonDialogForm
          batchEditing
          initialValues={batchWagonDialogForm.state.initialValues}
          isOpen={batchWagonDialogForm.state.isOpen}
          title="Групповое редактирование"
          onClose={batchWagonDialogForm.close}
          onClosed={batchWagonDialogForm.destroy}
          onSubmit={async values => {
            try {
              if (!wagonsRequest.data) {
                return;
              }

              const updatedWagons = await updateWagons(api, selectedWagons, {
                etranNote: values.etranNote || undefined,
                managerNote: values.managerNote || undefined,
                managerNote2: values.managerNote2 || undefined,
                managerNote3: values.managerNote3 || undefined,
                owner: values.owner || undefined,
              });

              const updatedWagonsMap = indexByLastItem(
                updatedWagons,
                wagon => wagon.number
              );

              wagonsRequest.updateData(
                oldData =>
                  oldData && {
                    ...oldData,
                    wagons: oldData.wagons.map(wagon => {
                      const updatedWagon = updatedWagonsMap[wagon.number];

                      return updatedWagon ? updatedWagon : wagon;
                    }),
                  }
              );

              batchWagonDialogForm.close();
              setSelectedWagons([]);

              return undefined;
            } catch (err) {
              return submissionErrorsFromApiError(
                err,
                'Не удалось отредактировать вагоны: Непредвиденная ошибка'
              );
            }
          }}
        />
      )}
    </>
  );
}

import { useApiClient } from '_core/api/context';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import {
  selectIsFavoriteExpeditionRequest,
  toggleExpeditionRequestFavoriteStatus,
} from '_core/me/me';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import {
  TabsRouteNode,
  TabsRouteNodeExpander,
  TabsRouteNodeTab,
} from '_core/router5/tabsRouteNode';
import { useToaster } from '_core/toaster/toasterContext';
import { useAsyncData } from '_core/useAsyncData';
import { useErpDispatch, useErpSelector } from '_erp/redux';
import { Button, Intent } from '@blueprintjs/core';
import {
  fetchExpeditionRequest,
  updateExpeditionRequest,
} from 'expeditionRequests/api';
import { ExpeditionRequestSellRateType } from 'expeditionRequests/types';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';

import { ExpeditionRequestFormRouteWrapper } from '../formRouteWrapper';
import { ExpeditionRequestWagonsDailyUsageTab } from '../wagonsDailyUsageTab/wagonsDailyUsageTab';
import { ExpeditionRequestMainTab } from './mainTab';
import { ExpeditionRequestParksTab } from './parksTab';

export default function ExpeditionRequestsViewRoute() {
  const dispatch = useErpDispatch();
  const toaster = useToaster();
  const api = useApiClient();
  const { route } = useRouteNode('expeditions.requests.view');

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const isFavorite = useErpSelector(state =>
    selectIsFavoriteExpeditionRequest(Number(route.params.id), state)
  );

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    () => fetchExpeditionRequest(api, route.params.id)
  );

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    )
  ) : (
    <ExpeditionRequestFormRouteWrapper
      listRoute={{ name: 'expeditions.requests', params: listParams }}
      title={data.requestName}
    >
      <TabsRouteNode
        nodeName="expeditions.requests.view"
        preserveRouteParams={['id', 'listParams']}
      >
        <TabsRouteNodeTab id="main" title="Основные данные">
          <ExpeditionRequestMainTab
            expeditionRequest={data}
            updateExpeditionRequest={newExpeditionRequest =>
              updateData(() => newExpeditionRequest)
            }
          />
        </TabsRouteNodeTab>

        <TabsRouteNodeTab id="parks" title="Парки">
          <ExpeditionRequestParksTab
            initialValues={{ parks: data.parks }}
            onSubmit={async values => {
              try {
                const updatedExpeditionRequest = await updateExpeditionRequest(
                  api,
                  data.id,
                  {
                    ...data,
                    parks: values.parks,
                  }
                );

                updateData(() => updatedExpeditionRequest);
                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось изменить парки: Непредвиденная ошибка'
                );
              }
            }}
          />
        </TabsRouteNodeTab>

        {data.sellRateType === ExpeditionRequestSellRateType.Day && (
          <TabsRouteNodeTab id="wagonsDailyUsage" title="Периоды начисления">
            <ExpeditionRequestWagonsDailyUsageTab request={data} />
          </TabsRouteNodeTab>
        )}

        <TabsRouteNodeExpander />

        <Button
          icon={isFavorite ? 'star' : 'star-empty'}
          intent={Intent.SUCCESS}
          large
          minimal
          onClick={async () => {
            try {
              await dispatch(
                toggleExpeditionRequestFavoriteStatus(Number(route.params.id))
              );
            } catch (err) {
              toaster.show({
                icon: 'error',
                intent: Intent.DANGER,
                message:
                  'Не удалось изменить статус избранности заявки экспедирования',
              });

              throw err;
            }
          }}
        />
      </TabsRouteNode>
    </ExpeditionRequestFormRouteWrapper>
  );
}

import { decimalOrNull } from '_core/decimal';
import { Decimal } from 'decimal.js';

export function formatMoney(
  value: Decimal.Value | null,
  { withCurrency = false } = {}
) {
  if (value == null) {
    return '';
  }

  const decimalValue = decimalOrNull(value);

  if (decimalValue == null) {
    return '';
  }

  const [beforePoint, afterPoint] = decimalValue.toFixed(2).split('.');

  const result = [
    beforePoint.replace(/(\d)(?=(\d{3})+$)/g, '$1 '),
    ',',
    afterPoint,
  ].join('');

  if (withCurrency) {
    return `${result}р`;
  }

  return result;
}

export function chunk<T>(size: number, array: readonly T[]): T[][] {
  const copy = array.slice();
  const result: T[][] = [];

  while (copy.length !== 0) {
    result.push(copy.splice(0, size));
  }

  return result;
}

import { range } from '_core/fp/range';
import { union } from '_core/fp/union';
import { without } from '_core/fp/without';
import { Checkbox } from '_core/inputs/checkbox';
import { IColumnsWidth } from '_core/lists/columnPresets/useColumnPresets';
import { ISorting } from '_core/sorting';
import * as React from 'react';
import { GridOnItemsRenderedProps } from 'react-window';
import invariant from 'tiny-invariant';

import {
  BaseTable,
  BaseTableCellEditor,
  BaseTableColumn,
  BaseTableRenderCellProps,
  defaultRenderCell,
  Region,
} from './baseTable';
import { BaseCell } from './cells';
import * as css from './listTable.module.css';
import { RenderableRegion } from './regions';
import {
  dummyRenderedItemsValue,
  RenderedItemsProvider,
} from './renderedItems';

const LINE_NUMBER_COLUMN_ID = 'LINE_NUMBER';
const SELECTED_COLUMN_ID = 'SELECTED';

interface ListTableRenderCellProps<TItem> extends BaseTableRenderCellProps {
  item: TItem;
}

export interface ListTableColumn<TItem>
  extends Omit<
    BaseTableColumn,
    'copyCellContent' | 'getEditor' | 'renderCell' | 'renderCellContent'
  > {
  copyCellContent?: (item: TItem, rowIndex: number) => string;
  getEditor?: (item: TItem) => BaseTableCellEditor<unknown> | undefined;
  renderCell?: (
    props: ListTableRenderCellProps<TItem>,
    rowIndex: number,
    columnIndex: number
  ) => React.ReactNode;
  renderCellContent: (item: TItem, rowIndex: number) => React.ReactNode | null;
}

interface Props<T, TSortingField extends string> {
  columns: Array<ListTableColumn<T>>;
  columnWidths?: IColumnsWidth | null;
  customRegions?: RenderableRegion[];
  getItemId: (item: T) => string;
  getRowColor?: (item: T) => string | undefined;
  isFetching?: boolean;
  items: T[];
  lineNumbers?: boolean;
  lineNumbersStart?: number;
  maxHeight?: number;
  selectedItems?: string[];
  selectedRegions?: Region[];
  selectionIsDisabled?: boolean;
  showColumns?: string[];
  sorting?: ISorting<TSortingField>;
  stickyBottomRowCount?: number;
  stickyColumnCount?: number;
  onColumnWidthChanged?: (columnId: string, newWidth: number) => void;
  onSelectedItemsChange?: (newSelectedItems: string[]) => void;
  onSelection?: (newSelectedRegions: Region[]) => void;
  onSortingChange?: (newSorting: ISorting<TSortingField>) => void;
}

export function ListTable<T, TSortingField extends string>({
  columns: columnsProp,
  columnWidths,
  customRegions,
  getItemId,
  getRowColor: getRowColorProp,
  isFetching,
  items,
  lineNumbers = true,
  lineNumbersStart = 1,
  maxHeight,
  selectedItems,
  selectedRegions,
  selectionIsDisabled,
  showColumns: showColumnsProp,
  sorting,
  stickyBottomRowCount,
  stickyColumnCount,
  onColumnWidthChanged,
  onSelectedItemsChange,
  onSelection,
  onSortingChange,
}: Props<T, TSortingField>) {
  const [renderedItems, setRenderedItems] =
    React.useState<GridOnItemsRenderedProps>(dummyRenderedItemsValue);

  const lastCheckedRowIndexRef = React.useRef<number | null>(null);

  const columns = React.useMemo(() => {
    let result = columnsProp.map(
      ({
        copyCellContent,
        getEditor,
        renderCell = defaultRenderCell,
        renderCellContent,
        ...otherColumnProps
      }): BaseTableColumn => ({
        ...otherColumnProps,
        copyCellContent:
          copyCellContent &&
          (rowIndex => copyCellContent(items[rowIndex], rowIndex)),
        getEditor: getEditor
          ? rowIndex => getEditor(items[rowIndex])
          : undefined,
        renderCell: (props, rowIndex, columnIndex) =>
          renderCell(
            { ...props, item: items[rowIndex] },
            rowIndex,
            columnIndex
          ),
        renderCellContent: rowIndex =>
          renderCellContent(items[rowIndex], rowIndex),
      })
    );

    if (selectedItems) {
      invariant(
        onSelectedItemsChange,
        'You must pass onSelectedItemsChange together with selectedItems'
      );

      const selectedColumn: BaseTableColumn = {
        id: SELECTED_COLUMN_ID,
        label: '',
        defaultWidth: 36,
        copyCellContent: () => '',
        renderHeadContent: () => (
          <Checkbox
            checked={
              items.length !== 0 &&
              items.every(item => selectedItems.includes(getItemId(item)))
            }
            disabled={selectionIsDisabled}
            noBottomMargin
            onChange={newChecked => {
              if (newChecked) {
                onSelectedItemsChange(items.map(item => getItemId(item)));
              } else {
                onSelectedItemsChange([]);
              }
            }}
          />
        ),
        renderCellContent: rowIndex => {
          const itemId = getItemId(items[rowIndex]);

          return (
            <Checkbox
              checked={selectedItems.includes(itemId)}
              disabled={selectionIsDisabled}
              noBottomMargin
              onClick={(newChecked, event) => {
                if (event.shiftKey && lastCheckedRowIndexRef.current !== null) {
                  const lastCheckedRowIndex = lastCheckedRowIndexRef.current;

                  const loIndex =
                    lastCheckedRowIndex < rowIndex
                      ? lastCheckedRowIndex
                      : rowIndex;

                  const hiIndex =
                    lastCheckedRowIndex > rowIndex
                      ? lastCheckedRowIndex
                      : rowIndex;

                  const idsToChange = range(loIndex, hiIndex + 1).map(index =>
                    getItemId(items[index])
                  );

                  if (newChecked) {
                    onSelectedItemsChange(union(idsToChange, selectedItems));
                  } else {
                    onSelectedItemsChange(without(idsToChange, selectedItems));
                  }
                } else {
                  if (newChecked) {
                    onSelectedItemsChange(selectedItems.concat(itemId));
                  } else {
                    onSelectedItemsChange(
                      selectedItems.filter(id => id !== itemId)
                    );
                  }

                  lastCheckedRowIndexRef.current = rowIndex;
                }
              }}
            />
          );
        },
      };

      result = [selectedColumn].concat(result);
    }

    if (lineNumbers) {
      const lineNumberColumn: BaseTableColumn = {
        id: LINE_NUMBER_COLUMN_ID,
        label: '',
        defaultWidth: 50,
        copyCellContent: () => '',
        renderCell: ({ children, style }) => (
          <BaseCell head style={style}>
            {children}
          </BaseCell>
        ),
        renderCellContent: rowIndex => (
          <div className={css.lineNumberCell}>
            {lineNumbersStart + rowIndex}
          </div>
        ),
      };

      result = [lineNumberColumn].concat(result);
    }

    return result;
  }, [
    columnsProp,
    getItemId,
    items,
    lineNumbers,
    lineNumbersStart,
    selectedItems,
    selectionIsDisabled,
    onSelectedItemsChange,
  ]);

  const showColumns = React.useMemo(() => {
    if (!showColumnsProp) {
      return showColumnsProp;
    }

    let result = showColumnsProp;

    if (selectedItems) {
      result = [SELECTED_COLUMN_ID].concat(result);
    }

    if (lineNumbers) {
      result = [LINE_NUMBER_COLUMN_ID].concat(result);
    }

    return result;
  }, [lineNumbers, showColumnsProp, selectedItems]);

  const itemKey = React.useCallback(
    ({ columnIndex, rowIndex }: { columnIndex: number; rowIndex: number }) =>
      `${getItemId(items[rowIndex])}x${columns[columnIndex].id}`,

    [columns, getItemId, items]
  );

  const getRowColor = React.useMemo(() => {
    if (!getRowColorProp) {
      return undefined;
    }

    return (rowIndex: number) => getRowColorProp(items[rowIndex]);
  }, [getRowColorProp, items]);

  return (
    <RenderedItemsProvider renderedItems={renderedItems}>
      <BaseTable
        columns={columns}
        columnWidths={columnWidths}
        customRegions={customRegions}
        getRowColor={getRowColor}
        isFetching={isFetching}
        itemKey={itemKey}
        maxHeight={maxHeight}
        rowCount={items.length}
        selectedRegions={selectedRegions}
        showColumns={showColumns}
        sorting={sorting}
        stickyBottomRowCount={stickyBottomRowCount}
        stickyColumnCount={stickyColumnCount}
        onColumnWidthChanged={onColumnWidthChanged}
        onItemsRendered={setRenderedItems}
        onSelection={onSelection}
        onSortingChange={onSortingChange}
      />
    </RenderedItemsProvider>
  );
}

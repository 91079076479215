import { MultiSelectFilter } from '_core/filters/multiSelect';
import { MenuItem } from '@blueprintjs/core';
import * as React from 'react';

import { ITechrunRequest } from './types';

interface IProps {
  initialValue: string;
  isFetching?: boolean;
  items: ITechrunRequest[];
  onApply: (requests: string) => void;
}

export function TechrunRequestsMultiSelectFilterInPopover({
  initialValue,
  isFetching,
  items,
  onApply,
}: IProps) {
  return (
    <MultiSelectFilter
      initialContent={<MenuItem disabled text="Введите запрос" />}
      initialValue={initialValue}
      isFetching={isFetching}
      itemPredicate={(query, request) =>
        request.requestName.toLowerCase().includes(query.toLowerCase())
      }
      items={items}
      itemToOption={request => ({
        label: request.requestName,
        value: request.id,
      })}
      label="Заявки"
      placeholder="Начните вводить имя заявки..."
      valueProcessor={{
        emptyValue: undefined,
        deserialize: value => (value ? value.split(',').map(Number) : []),
        serialize: value => (value.length ? value.join(',') : undefined),
        isEmpty: value => !value,
      }}
      width={400}
      onApply={onApply}
    />
  );
}

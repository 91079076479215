import invariant from 'tiny-invariant';

import { BaseTableCellEditor, defaultRenderCell } from './baseTable';
import { ListTableColumn } from './listTable';
import { TreeTableColumn } from './treeTable';
import { TreeTableNode, TreeTableRow } from './types';

export function createListToTreeTableColumnAdapter<TItem, T>(
  getItemFromNodeData: (nodeData: T) => TItem | null
) {
  return ({
    copyCellContent,
    getEditor,
    renderCell = defaultRenderCell,
    renderCellContent,
    ...otherColumnProps
  }: ListTableColumn<TItem>): TreeTableColumn<T> => {
    return {
      ...otherColumnProps,
      copyCellContent: (node, rowIndex) => {
        const item = getItemFromNodeData(node.data);

        return item === null || !copyCellContent
          ? ''
          : copyCellContent(item, rowIndex);
      },
      getEditor: node => {
        const item = getItemFromNodeData(node.data);

        return item === null || !getEditor ? undefined : getEditor(item);
      },
      renderCell: ({ row, ...otherProps }, rowIndex, columnIndex) => {
        const item = getItemFromNodeData(row.node.data);

        return item === null
          ? defaultRenderCell(otherProps)
          : renderCell({ item, ...otherProps }, rowIndex, columnIndex);
      },
      renderCellContent: (node, rowIndex) => {
        const item = getItemFromNodeData(node.data);

        return item === null ? null : renderCellContent(item, rowIndex);
      },
    };
  };
}

function traverseNodesStep<T>(
  nodes: Array<TreeTableNode<T>>,
  cb: (
    node: TreeTableNode<T>,
    parentPath: Array<TreeTableNode<T>>
  ) => boolean | void,
  parentPath: Array<TreeTableNode<T>>
) {
  for (const node of nodes) {
    if (cb(node, parentPath)) {
      return true;
    }

    if (
      node.children &&
      traverseNodesStep(node.children.nodes, cb, parentPath.concat(node))
    ) {
      return true;
    }
  }

  return false;
}

function traverseNodes<T>(
  nodes: Array<TreeTableNode<T>>,
  cb: (
    node: TreeTableNode<T>,
    parentPath: Array<TreeTableNode<T>>
  ) => boolean | void
) {
  traverseNodesStep(nodes, cb, []);
}

export function findNodePath<T>(
  nodes: Array<TreeTableNode<T>>,
  predicate: (node: TreeTableNode<T>) => boolean
) {
  let result: Array<TreeTableNode<T>> | undefined;

  traverseNodes(nodes, (node, parentPath) => {
    if (predicate(node)) {
      result = parentPath.concat(node);
      return true;
    }

    return false;
  });

  return result;
}

export function everyNode<T>(
  nodes: Array<TreeTableNode<T>>,
  predicate: (node: TreeTableNode<T>) => boolean
) {
  let result = true;

  traverseNodes(nodes, node => {
    if (!predicate(node)) {
      result = false;
      return true;
    }

    return false;
  });

  return result;
}

export function findNode<T, R extends T>(
  nodes: Array<TreeTableNode<T>>,
  predicate: (node: TreeTableNode<T>) => node is TreeTableNode<R>
): TreeTableNode<R> | undefined;
export function findNode<T>(
  nodes: Array<TreeTableNode<T>>,
  predicate: (node: TreeTableNode<T>) => boolean
): TreeTableNode<T> | undefined;
export function findNode<T>(
  nodes: Array<TreeTableNode<T>>,
  predicate: (node: TreeTableNode<T>) => boolean
): TreeTableNode<T> | undefined {
  let result: TreeTableNode<T> | undefined;

  traverseNodes(nodes, node => {
    if (predicate(node)) {
      result = node;
      return true;
    }

    return false;
  });

  return result;
}

export function findAllNodePaths<T>(
  nodes: Array<TreeTableNode<T>>,
  predicate: (node: TreeTableNode<T>) => boolean
) {
  const result: Array<Array<TreeTableNode<T>>> = [];

  traverseNodes(nodes, (node, parentPath) => {
    if (predicate(node)) {
      result.push(parentPath.concat(node));
    }
  });

  return result;
}

export function flattenNodesToRows<T>(
  nodes: Array<TreeTableNode<T>>
): Array<TreeTableRow<T>> {
  function nodeToRows(node: TreeTableNode<T>, path: Array<TreeTableNode<T>>) {
    const result: Array<TreeTableRow<T>> = [];

    result.push({ path, type: 'node', node });

    if (node.children && node.children.isExpanded) {
      const childPath = path.concat(node);

      result.push(
        ...node.children.nodes.flatMap(childNode =>
          nodeToRows(childNode, childPath)
        )
      );

      if (node.children.hasMoreChildNodesToFetch) {
        result.push({ path: childPath, type: 'spinner' });
      }
    }

    return result;
  }

  return nodes.flatMap(node => nodeToRows(node, []));
}

export function updateNode<T>(
  input: TreeTableNode<T>,
  mapper: (node: TreeTableNode<T>) => TreeTableNode<T>
) {
  let result = mapper(input);

  if (result.children) {
    const updatedChildNodes = updateNodes(result.children.nodes, mapper);

    if (updatedChildNodes !== result.children.nodes) {
      if (result === input) {
        result = { ...result, children: { ...result.children } };
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      result.children!.nodes = updatedChildNodes;
    }
  }

  return result;
}

export function updateNodes<T>(
  input: Array<TreeTableNode<T>>,
  mapper: (node: TreeTableNode<T>) => TreeTableNode<T>
) {
  let result = input;

  for (let i = 0; i < result.length; i++) {
    const original = result[i];
    const updated = updateNode(original, mapper);

    if (updated !== original) {
      if (result === input) {
        result = result.slice();
      }

      result[i] = updated;
    }
  }

  return result;
}

export function setAllNodesIsExpanded<T>(
  nodes: Array<TreeTableNode<T>>,
  isExpanded: boolean
) {
  return updateNodes(nodes, node =>
    node.children
      ? { ...node, children: { ...node.children, isExpanded } }
      : node
  );
}

export function switchOverNodeKind<K extends string, T extends { kind: K }, R>(
  data: T,
  kindHandlers: { [K1 in T['kind']]?: (data: T & { kind: K1 }) => R }
) {
  const handler = kindHandlers[data.kind];
  invariant(handler, `Unexpected node kind: "${data.kind}"`);
  return handler(data);
}

// this helper is needed to maintain correct TEditorValue type
export function createTableCellEditor<TEditorValue>(
  editor: BaseTableCellEditor<TEditorValue>
): BaseTableCellEditor<TEditorValue> {
  return editor;
}

import { IRentProtocolPurchase } from 'rentProtocolsPurchase/api';
import { IRentProtocolSell } from 'rentProtocolsSell/api';

import {
  IRentProtocolSerialized,
  Payer,
  StartRepairDate,
  StopRepairDate,
} from './types';

export const payerOptions = Object.values(Payer).map(payer => ({
  label: { [Payer.Renter]: 'Арендатор', [Payer.Lessor]: 'Арендодатель' }[payer],
  value: payer,
}));

export const startRepairDateOptions = Object.values(StartRepairDate).map(
  startRepairDate => ({
    label: {
      [StartRepairDate.Vu36Date]: 'Дата выписки ВУ-36',
      [StartRepairDate.ToRepairDate]: 'Дата отправления из ремонта',
      [StartRepairDate.FromRepairDate]: 'Дата прибытия на станцию погрузки',
    }[startRepairDate],
    value: startRepairDate,
  })
);

export const stopRepairDateOptions = Object.values(StopRepairDate).map(
  stopRepairDate => ({
    label: {
      [StopRepairDate.Vu23Date]: 'Дата выписки ВУ-23',
      [StopRepairDate.ToRepairDate]: 'Дата отправки в ремонт',
      [StopRepairDate.FromRepairDate]: 'Дата прибытия в ремонт',
    }[stopRepairDate],
    value: stopRepairDate,
  })
);

export const rentProtocolSerializedToOption = (
  protocol: IRentProtocolSerialized
) => ({
  label: protocol.protocolName,
  value: String(protocol.id),
});

export const rentProtocolToOption = (
  protocol: IRentProtocolPurchase | IRentProtocolSell
) => ({
  label: protocol.protocolName,
  value: String(protocol.id),
});

import { pick } from '_core/fp/pick';
import { Tab, Tabs } from '@blueprintjs/core';
import * as React from 'react';
import { isElement } from 'react-is';
import { useRouteNode } from 'react-router5';

import { getNextSubSegment } from './getNextSubSegment';
import * as css from './tabsRouteNode.module.css';

interface ITabProps {
  children: React.ReactElement;
  id?: string;
  params?: Record<string, unknown>;
  title: string;
}

export function TabsRouteNodeTab(_: ITabProps) {
  return null;
}

export const TabsRouteNodeExpander = Tabs.Expander;

interface IProps {
  children: React.ReactNode;
  nodeName: string;
  preserveRouteParams?: string[];
}

export function TabsRouteNode({
  children,
  nodeName,
  preserveRouteParams = [],
}: IProps) {
  const { route, router } = useRouteNode(nodeName);

  return (
    <Tabs
      className={css.root}
      id={nodeName}
      renderActiveTabPanelOnly
      selectedTabId={getNextSubSegment(route, nodeName)}
      onChange={newTabId => {
        const newChild = React.Children.toArray(children)
          .filter(isElement)
          .find(child => child.props.id === newTabId);

        router.navigate([nodeName, newTabId].filter(Boolean).join('.'), {
          ...pick(preserveRouteParams, route.params),
          ...(newChild && newChild.props.params),
        });
      }}
    >
      {React.Children.map(children, child =>
        !isElement(child) || child.type !== TabsRouteNodeTab ? (
          child
        ) : (
          <Tab
            id={child.props.id || ''}
            panel={child.props.children}
            title={child.props.title}
          />
        )
      )}
    </Tabs>
  );
}

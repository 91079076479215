import { ApplyExpeditionCostFor, ExpeditionProtocolSellRateType } from './api';

function getApplyExpeditionCostForLabel(
  applyExpeditionCostFor: ApplyExpeditionCostFor
): string {
  return {
    [ApplyExpeditionCostFor.Load]: 'За груженый рейс',
    [ApplyExpeditionCostFor.Empty]: 'За порожний рейс',
    [ApplyExpeditionCostFor.Any]: 'За любой рейс',
    [ApplyExpeditionCostFor.NotApply]: 'Не начислять',
  }[applyExpeditionCostFor];
}

export const applyExpeditionCostForOptions = [
  ApplyExpeditionCostFor.Load,
  ApplyExpeditionCostFor.Empty,
  ApplyExpeditionCostFor.Any,
  ApplyExpeditionCostFor.NotApply,
].map(applyExpeditionCostFor => ({
  label: getApplyExpeditionCostForLabel(applyExpeditionCostFor),
  value: applyExpeditionCostFor,
}));

function getExpeditionProtocolSellRateTypeLabel(
  rateType: ExpeditionProtocolSellRateType
) {
  return {
    [ExpeditionProtocolSellRateType.Day]: 'За сутки',
    [ExpeditionProtocolSellRateType.Wagon]: 'За вагон',
    [ExpeditionProtocolSellRateType.Ton]: 'За тонну',
  }[rateType];
}

export const expeditionProtocolSellRateTypeOptions = [
  ExpeditionProtocolSellRateType.Wagon,
  ExpeditionProtocolSellRateType.Ton,
  ExpeditionProtocolSellRateType.Day,
].map(rateType => ({
  label: getExpeditionProtocolSellRateTypeLabel(rateType),
  value: rateType,
}));

import {
  createEntityAutocomplete,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { IParkSerialized } from './api';

export const ParksAutocompleteInFormGroup = wrapEntityAutocompleteWithFormGroup(
  createEntityAutocomplete<IParkSerialized, number>({
    baseEndpoint: '/parks',
    itemToOption: park => ({ label: park.name, value: park.id }),

    getLink: park => ({
      params: { id: String(park.id) },
      to: 'parks.edit',
    }),
  })
);

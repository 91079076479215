import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { useAsyncTasks } from '_core/download/asyncDownload';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { finiteNumberOr } from '_core/finiteNumberOr';
import { SearchForm } from '_core/forms/searchForm';
import { Switch } from '_core/inputs/switch';
import { ColumnsSelectorsGroup } from '_core/lists/columnPresets/columnsSelectorsGroup';
import { useColumnPresets } from '_core/lists/columnPresets/useColumnPresets';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { EntityLinkWithFilter } from '_core/router5/entityLinkWithFilter';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToApiQueryParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { plural } from '_core/strings/utils';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import {
  IDowntime,
  // DowntimeSortingFields
} from 'downtimes/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersFilter } from 'partners/filter';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { createShipmentInfoItems } from 'shipmentInfo/api';
import { ArrDepOperStationsFilterInPopover } from 'stations/arrDepOperFilterInPopover';
import { IStationSerialized } from 'stations/types';
import { TechrunRequestsFilterInPopover } from 'techrunRequests/filterInPopover';
import { ITechrunRequest } from 'techrunRequests/types';
import { TechrunTransportationAccountingState } from 'techrunTransportations/types';
import {
  accountingStateOptions,
  getAccountingStateLabel,
  getTransportationStateLabel,
} from 'transportations/types';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

// import { SortingInput } from '../../_core/sortingInput';

interface ListTableItem
  extends Omit<IDowntime, 'arrivalStation' | 'departureStation' | 'request'> {
  arrivalStation: IStationSerialized;
  departureStation: IStationSerialized;
  request: ITechrunRequest;
}

const propsToFetch = {
  arrivalStation: '/directories_trainstations',
  departureStation: '/directories_trainstations',
  request: '/techrun_requests',
};

export default function DowntimesListRoute() {
  const { route, router } = useRouteNode('downtimes');
  const api = useApiClient();
  const tasks = useAsyncTasks();
  const toaster = useToaster();

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'created',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const { data, isFetching } = useAsyncData(
    [route, sorting],

    async () => {
      const accountingStatesParam =
        (route.params.accountingStates as string) ||
        accountingStateOptions
          .filter(
            option =>
              option.value !== TechrunTransportationAccountingState.BuhClosed
          )
          .map(option => option.value)
          .join(',');

      const accountingStatesArr = (
        accountingStatesParam ? accountingStatesParam.split(',') : []
      ) as TechrunTransportationAccountingState[];

      if (
        route.params.showBuhClosed === 'True' &&
        accountingStatesArr.length !== 0
      ) {
        accountingStatesArr.push(
          TechrunTransportationAccountingState.BuhClosed
        );
      }

      const { meta, results } = await api.get<ListResponse<IDowntime>>(
        '/techrun_downtimes',
        {
          accountingStates: accountingStatesArr.join(',') || undefined,
          arrivalStation: route.params.arrivalStation,
          consignee: route.params.consignee,
          departureStation: route.params.departureStation,
          operationStation: route.params.operationStation,
          ordering: sortingToApiQueryParam([sorting], snakeCase),
          page: route.params.page,
          pageSize: route.params.pageSize,
          partner: route.params.partner,
          request: route.params.request,
          search: route.params.search,
          wagonNumbers: route.params.wagonNumbers,
        }
      );

      return {
        currentPage: meta.currentPage,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
        downtimes: await (fetchRelated(
          api,
          propsToFetch,
          results
        ) as unknown as Promise<ListTableItem[]>),
      };
    }
  );

  const getItemId = useCallback(
    (downtime: ListTableItem): string => String(downtime.id),
    []
  );

  const [selectedDowntimes, setSelectedDowntimes] = useState<string[]>([]);

  const lastDataRef = useRef(data);

  if (data !== lastDataRef.current) {
    setSelectedDowntimes([]);
    lastDataRef.current = data;
  }

  const [submitting, setSubmitting] = useState(false);

  const columns = useMemo(
    (): Array<ListTableColumn<ListTableItem>> => [
      {
        id: 'waybillNumber',
        label: 'Накладная',
        copyCellContent: downtime => downtime.waybillNumber || '<отсутствует>',
        renderCellContent: downtime => (
          <Ellipsis
            component={Link}
            params={{
              id: downtime.id,
              listParams: JSON.stringify(route.params),
            }}
            rel="noopener"
            target="_blank"
            to="downtimes.edit"
          >
            {downtime.waybillNumber || '<отсутствует>'}
          </Ellipsis>
        ),
      },
      {
        id: 'wagon',
        label: 'Вагон',
        copyCellContent: downtime => downtime.wagon,
        renderCellContent: downtime => (
          <Ellipsis
            component={Link}
            params={{ number: downtime.wagon }}
            rel="noopener"
            target="_blank"
            to="wagons.edit"
          >
            {downtime.wagon}
          </Ellipsis>
        ),
      },
      {
        id: 'request',
        label: 'Заявка',
        copyCellContent: downtime => downtime.request.requestName,
        renderCellContent: downtime =>
          downtime.request ? (
            <EntityLinkWithFilter
              entityLink={{
                params: { id: String(downtime.request.id) },
                to: 'techruns.requests.view',
              }}
              filterLink={{
                params: {
                  ...route.params,
                  request: String(downtime.request.id),
                },
                to: 'downtimes',
              }}
              text={downtime.request.requestName}
            />
          ) : null,
      },
      {
        id: 'departureStation',
        label: 'Станция отправления',
        copyCellContent: downtime =>
          downtime.departureStation ? downtime.departureStation.name : '',
        renderCellContent: downtime =>
          downtime.departureStation ? (
            <Ellipsis component="span">
              {downtime.departureStation.name}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'arrivalStation',
        label: 'Станция назначения',
        copyCellContent: downtime =>
          downtime.arrivalStation ? downtime.arrivalStation.name : '',
        renderCellContent: downtime =>
          downtime.arrivalStation ? (
            <Ellipsis component="span">{downtime.arrivalStation.name}</Ellipsis>
          ) : null,
      },
      {
        id: 'state',
        label: 'Состояние отправки',
        copyCellContent: downtime =>
          getTransportationStateLabel(downtime.transportationState),
        renderCellContent: downtime => (
          <Ellipsis component="span">
            {getTransportationStateLabel(downtime.transportationState)}
          </Ellipsis>
        ),
      },
      {
        id: 'accountingState',
        label: 'Бух. состояние',
        copyCellContent: downtime =>
          getAccountingStateLabel(downtime.accountingState),
        renderCellContent: downtime => (
          <Ellipsis component="span">
            {getAccountingStateLabel(downtime.accountingState)}
          </Ellipsis>
        ),
      },
      {
        id: 'downtimeDuration',
        label: 'Простой',
        sortable: true,
        copyCellContent: downtime =>
          downtime.downtimeDuration == null
            ? ''
            : String(downtime.downtimeDuration),
        renderCellContent: downtime => downtime.downtimeDuration,
      },
      {
        id: 'durationOverLimit1',
        label: 'Простой сверх нормы',
        sortable: true,
        copyCellContent: downtime => String(downtime.durationOverLimit1),
        renderCellContent: downtime => downtime.durationOverLimit1,
      },
      {
        id: 'lastWagonNote',
        label: 'Последний комментарий',
        copyCellContent: downtime => downtime.lastWagonNote,
        renderCellContent: downtime => (
          <Ellipsis component="span">{downtime.lastWagonNote}</Ellipsis>
        ),
      },
      {
        id: 'lastWagonNoteDate',
        label: 'Дата последнего комментария',
        copyCellContent: downtime =>
          downtime.lastWagonNoteDate == null
            ? ''
            : dayjs(downtime.lastWagonNoteDate).format(DATE_FORMAT_DATETIME),
        renderCellContent: downtime =>
          downtime.lastWagonNoteDate &&
          dayjs(downtime.lastWagonNoteDate).format(DATE_FORMAT_DATETIME),
      },
      {
        id: 'startDate',
        label: 'Дата начала',
        sortable: true,
        copyCellContent: downtime =>
          downtime.startDate == null
            ? ''
            : dayjs(downtime.startDate).format(DATE_FORMAT_DATETIME),
        renderCellContent: downtime =>
          downtime.startDate &&
          dayjs(downtime.startDate).format(DATE_FORMAT_DATETIME),
      },
      {
        id: 'endDate',
        label: 'Дата окончания',
        sortable: true,
        copyCellContent: downtime =>
          downtime.endDate == null
            ? ''
            : dayjs(downtime.endDate).format(DATE_FORMAT_DATETIME),
        renderCellContent: downtime =>
          downtime.endDate &&
          dayjs(downtime.endDate).format(DATE_FORMAT_DATETIME),
      },
    ],
    [route.params]
  );

  const columnLabels = columns.reduce<Record<string, string>>(
    (labels, column) => {
      labels[column.id] = column.label;

      return labels;
    },

    {}
  );

  const columnPresets = useColumnPresets(['downtimes'], {
    allColumnIds: columns.map(column => column.id),
    alwaysShownColumnIds: ['waybillNumber'],
    getColumnLabel: columnId => columnLabels[columnId],
  });

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | number | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  // const defaultSorting: ISorting<DowntimeSortingFields> = {
  //   field: DowntimeSortingFields.StartDate,
  //   direction: SortingDirection.Descending,
  // };

  return (
    <>
      <FormattedTitle>Простои</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <ColumnsSelectorsGroup
                  columnOptions={columnPresets.columnOptions}
                  defaultValue={columnPresets.defaultValue}
                  initialValue={columnPresets.initialValue}
                  onApply={columnPresets.onShowColumnsApply}
                  preset={columnPresets.activePreset}
                  onColumnPresetChange={columnPresets.onColumnPresetChange}
                />

                <WagonNumbersFilter
                  initialValue={route.params.wagonNumbers}
                  onApply={wagonNumbers => {
                    applyFilterParams({ wagonNumbers });
                  }}
                />

                <PartnersFilter
                  initialValue={route.params.partner}
                  label="Клиент"
                  onApply={partner => {
                    applyFilterParams({ partner });
                  }}
                />

                <TechrunRequestsFilterInPopover
                  initialValue={route.params.request}
                  label="Заявка"
                  onApply={request => {
                    applyFilterParams({ request });
                  }}
                />

                <CheckboxListFilter
                  label="Бух. состояния"
                  initialValue={route.params.accountingStates}
                  options={accountingStateOptions}
                  onApply={(accountingStates: string) => {
                    applyFilterParams({ accountingStates });
                  }}
                />

                <ArrDepOperStationsFilterInPopover
                  initialValue={{
                    arrivalStation: finiteNumberOr(
                      undefined,
                      route.params.arrivalStation
                    ),
                    consignee: route.params.consignee,
                    departureStation: finiteNumberOr(
                      undefined,
                      route.params.departureStation
                    ),
                    operationStation: finiteNumberOr(
                      undefined,
                      route.params.operationStation
                    ),
                  }}
                  showConsigneeFilter
                  onApply={stationsFilter => {
                    applyFilterParams({ ...stationsFilter });
                  }}
                />

                <LinkButton
                  options={{ reload: true }}
                  params={route.params}
                  text="Обновить"
                  to={route.name}
                />

                <LinkButton
                  params={{ status: route.params.status }}
                  text="Сбросить фильтры"
                  to={route.name}
                />

                <Tooltip2
                  placement="bottom"
                  content="Скачивает все простои из отправок техрейса на текущий момент без даты окончания."
                >
                  <Button
                    icon="download"
                    intent={Intent.SUCCESS}
                    text="Простои"
                    onClick={async () => {
                      const { taskId } = await api.get(
                        '/techrun_downtimes/download_xlsx'
                      );

                      tasks.waitForGeneratedFile(taskId);
                    }}
                  />
                </Tooltip2>
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Grid>
            <Col span={6}>
              <Toolbar valign="center">
                <div>
                  {plural(selectedDowntimes.length, [
                    () => `Выбрана ${selectedDowntimes.length} отправка`,
                    () => `Выбрано ${selectedDowntimes.length} отправки`,
                    () => `Выбрано ${selectedDowntimes.length} отправок`,
                  ])}
                  :
                </div>

                <Button
                  disabled={
                    isFetching ||
                    selectedDowntimes.length === 0 ||
                    submitting ||
                    data == null ||
                    data.downtimes.some(
                      item =>
                        item.accountingState !==
                          TechrunTransportationAccountingState.NotClosed &&
                        selectedDowntimes.includes(String(item.id))
                    )
                  }
                  intent={Intent.SUCCESS}
                  text="Закрыть"
                  onClick={async () => {
                    setSubmitting(true);

                    try {
                      const shipmentInfoItems = await createShipmentInfoItems(
                        api,
                        { downtimesTechrun: selectedDowntimes.map(Number) }
                      );

                      router.navigate('shipmentInfo', {
                        ids: shipmentInfoItems.map(item => item.id),
                      });
                    } catch (err) {
                      if (err instanceof BadRequestError) {
                        toaster.show({
                          icon: 'error',
                          intent: Intent.DANGER,
                          message: err.message,
                        });
                      } else {
                        toaster.show({
                          icon: 'error',
                          intent: Intent.DANGER,
                          message: 'Что-то пошло не так',
                        });

                        throw err;
                      }
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                />
              </Toolbar>
            </Col>

            <Col span={6}>
              <Toolbar valign="center" align="right">
                <Switch
                  checked={route.params.showBuhClosed === 'True'}
                  label="Показать закрытые бух. документами"
                  noBottomMargin
                  onChange={showBuhClosed => {
                    applyFilterParams({
                      showBuhClosed: showBuhClosed ? 'True' : undefined,
                    });
                  }}
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : data.downtimes.length === 0 ? (
            <EmptyListMessage />
          ) : (
            <>
              {/* <Row> */}
              {/*  <Grid> */}
              {/*    <Col align="end"> */}
              {/*      <SortingInput */}
              {/*        defaultValue={defaultSorting} */}
              {/*        options={columns */}
              {/*          .filter(column => column.sortable) */}
              {/*          .map(column => ({ */}
              {/*            label: column.label, */}
              {/*            value: column.id as DowntimeSortingFields, */}
              {/*          }))} */}
              {/*        value={sorting} */}
              {/*        onChange={handleSortingChange} */}
              {/*      /> */}
              {/*    </Col> */}
              {/*  </Grid> */}
              {/* </Row> */}
              <ListTable
                columns={columns}
                columnWidths={columnPresets.columnWidths}
                getItemId={getItemId}
                isFetching={isFetching}
                items={data.downtimes}
                lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
                selectedItems={selectedDowntimes}
                showColumns={columnPresets.showColumns}
                sorting={sorting}
                stickyColumnCount={3}
                onColumnWidthChanged={columnPresets.onColumnWidthChanged}
                onSelectedItemsChange={setSelectedDowntimes}
                onSortingChange={handleSortingChange}
              />
            </>
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>
    </>
  );
}

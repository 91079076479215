import {
  deserializeDateRangeFilterValue,
  serializeDateRangeFilterValue,
} from '_core/blueprint/dateRange';
import { DateRangePicker } from '_core/inputs/dateRangePicker';
import { DateRange } from '@blueprintjs/datetime';
import * as React from 'react';

import { FilterPopoverUi, useFilterPopover } from './filterPopover';

type Value = string | undefined;

interface IProps {
  initialValue: Value;
  label: string;
  onApply: (newValue: Value) => void;
}

export function DateRangeFilter({ initialValue, label, onApply }: IProps) {
  const filterPopover = useFilterPopover<string | undefined, DateRange>(
    initialValue,
    onApply,

    {
      emptyValue: undefined,
      isEmpty: value => !value,
      deserialize: deserializeDateRangeFilterValue,
      serialize: serializeDateRangeFilterValue,
    }
  );

  return (
    <FilterPopoverUi api={filterPopover.ui} icon="calendar" label={label}>
      <DateRangePicker
        allowSingleDayRange
        value={filterPopover.value}
        onChange={newValue => {
          filterPopover.change(newValue);
        }}
      />
    </FilterPopoverUi>
  );
}

import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';

interface IProps {
  children?: React.ReactNode;
  listParams: Record<string, unknown>;
  title: string;
}

export function TechrunRequestsFormRouteWrapper({
  children,
  listParams,
  title,
}: IProps) {
  return (
    <VGrid>
      <FormattedTitle>{title}</FormattedTitle>

      <Row>
        <Breadcrumbs>
          <BreadcrumbsItem
            label="Заявки"
            params={listParams}
            to="techruns.requests"
          />

          <BreadcrumbsItem label={title} />
        </Breadcrumbs>
      </Row>

      <Row>{children}</Row>
    </VGrid>
  );
}

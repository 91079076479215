import {
  createEntityAutocomplete,
  wrapEntityAutocompleteInFormGroupForFinalForm,
  wrapEntityAutocompleteWithFormGroup,
} from '_core/inputs/autocomplete/entityAutocomplete';

import { IContractPurchaseSerialized } from './api';

export const ContractsPurchaseAutocompleteInFormGroup =
  wrapEntityAutocompleteWithFormGroup(
    createEntityAutocomplete<IContractPurchaseSerialized, number>({
      baseEndpoint: '/contracts_purchase',
      itemToOption: contract => ({
        label: contract.number,
        value: contract.id,
      }),

      getLink: contract => ({
        params: { id: String(contract.id) },
        to: 'contracts.purchase.view',
      }),
    })
  );

export const ContractsPurchaseAutocompleteInFormGroupForFinalForm =
  wrapEntityAutocompleteInFormGroupForFinalForm(
    ContractsPurchaseAutocompleteInFormGroup
  );

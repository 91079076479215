import { Classes } from '@blueprintjs/core';
import * as React from 'react';

export function DialogBody({ children }: { children: React.ReactNode }) {
  return <div className={Classes.DIALOG_BODY}>{children}</div>;
}

export function DialogFooter({ children }: { children: React.ReactNode }) {
  return <div className={Classes.DIALOG_FOOTER}>{children}</div>;
}

export function DialogFooterActions({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className={Classes.DIALOG_FOOTER_ACTIONS}>{children}</div>;
}

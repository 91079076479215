import { CheckboxList, ICheckboxListOption } from '_core/inputs/checkboxList';
import { Toolbar } from '_core/toolbar';
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  Intent,
} from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useState } from 'react';

import * as css from './columnsSelectorsGroup.module.css';
import { ColumnsPreset, IShowColumns } from './types';

interface IColumnsSelectorInDialogProps {
  buttonLabel: string;
  columnOptions: ICheckboxListOption[];
  defaultValue: string[];
  initialValue: string[] | null;
  isActive: boolean;
  templateColumns?: string[];
  onActivate: () => void;
  onApply: (changedShowColumns: string[] | null) => void;
}

function ColumnsSelectorInDialog({
  buttonLabel,
  columnOptions,
  defaultValue,
  initialValue,
  isActive,
  templateColumns,
  onActivate,
  onApply,
}: IColumnsSelectorInDialogProps) {
  const initialColumns = initialValue || defaultValue;
  const [columns, setColumns] = useState(initialColumns);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ButtonGroup>
        <Button
          icon="th"
          text={buttonLabel}
          active={isActive}
          onClick={onActivate}
        />

        <Button
          icon="cog"
          onClick={() => {
            setColumns(initialColumns);
            setIsOpen(true);
          }}
        />
      </ButtonGroup>

      <Dialog
        className={css.dialog}
        icon="th"
        isOpen={isOpen}
        title="Выбор столбцов для отображения"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <VGrid>
            {templateColumns && (
              <Row>
                <Button
                  icon="th-derived"
                  text="Выбрать из шаблона"
                  onClick={() => {
                    setColumns(templateColumns);
                  }}
                />
              </Row>
            )}

            <Row>
              <CheckboxList
                allOption
                columnCount={2}
                options={columnOptions}
                value={columns}
                onChange={setColumns}
              />
            </Row>
          </VGrid>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Отмена"
              onClick={() => {
                setIsOpen(false);
              }}
            />

            <Button
              intent={Intent.PRIMARY}
              text="Применить"
              onClick={() => {
                onApply(columns);
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

interface IProps {
  columnOptions: ICheckboxListOption[];
  defaultValue: string[];
  initialValue: IShowColumns;
  preset: ColumnsPreset | null;
  templateColumns?: string[];
  onApply: (showColumns: IShowColumns) => void;
  onColumnPresetChange: (preset: ColumnsPreset | null) => void;
}

export function ColumnsSelectorsGroup({
  columnOptions,
  defaultValue,
  initialValue,
  preset,
  templateColumns,
  onApply,
  onColumnPresetChange,
}: IProps) {
  function getChangedShowColumns(
    selectorId: ColumnsPreset,
    changedColumns: string[]
  ): IShowColumns {
    const newInitialValue = initialValue || [[], [], []];

    switch (selectorId) {
      case ColumnsPreset.Preset1:
        return Object.assign([...newInitialValue], { 0: changedColumns });
      case ColumnsPreset.Preset2:
        return Object.assign([...newInitialValue], { 1: changedColumns });
      case ColumnsPreset.Preset3:
        return Object.assign([...newInitialValue], { 2: changedColumns });
      default:
        return newInitialValue;
    }
  }

  const applySelectorColumns =
    (selectorId: ColumnsPreset) => (changedShowColumns: string[]) => {
      onApply(getChangedShowColumns(selectorId, changedShowColumns));
    };

  return (
    <Toolbar>
      <ColumnsSelectorInDialog
        buttonLabel="Фильтр 1"
        columnOptions={columnOptions}
        defaultValue={defaultValue}
        initialValue={initialValue && initialValue[0]}
        templateColumns={templateColumns}
        onApply={applySelectorColumns(ColumnsPreset.Preset1)}
        isActive={preset === ColumnsPreset.Preset1}
        onActivate={() => onColumnPresetChange(ColumnsPreset.Preset1)}
      />

      <ColumnsSelectorInDialog
        buttonLabel="Фильтр 2"
        columnOptions={columnOptions}
        defaultValue={defaultValue}
        initialValue={initialValue && initialValue[1]}
        templateColumns={templateColumns}
        onApply={applySelectorColumns(ColumnsPreset.Preset2)}
        isActive={preset === ColumnsPreset.Preset2}
        onActivate={() => onColumnPresetChange(ColumnsPreset.Preset2)}
      />

      <ColumnsSelectorInDialog
        buttonLabel="Фильтр 3"
        columnOptions={columnOptions}
        defaultValue={defaultValue}
        initialValue={initialValue && initialValue[2]}
        templateColumns={templateColumns}
        onApply={applySelectorColumns(ColumnsPreset.Preset3)}
        isActive={preset === ColumnsPreset.Preset3}
        onActivate={() => onColumnPresetChange(ColumnsPreset.Preset3)}
      />

      {preset && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          text="Отключить фильтр"
          onClick={() => onColumnPresetChange(null)}
        />
      )}
    </Toolbar>
  );
}

import {
  Button,
  ButtonGroup,
  Intent,
  Popover,
  Position,
} from '@blueprintjs/core';
import * as React from 'react';
import { useState } from 'react';

import { UsersAutocompleteInFormGroup } from './autocomplete';
import * as css from './filter.module.css';

interface IPopoverContentProps {
  initialValue: number | null;
  onApply: (newValue: number | null) => void;
}

function UsersFilterPopoverContent({
  initialValue,
  onApply,
}: IPopoverContentProps) {
  const [value, setValue] = useState(initialValue);

  return (
    <>
      <div className={css.root}>
        <UsersAutocompleteInFormGroup
          noBottomMargin
          value={value}
          onChange={setValue}
        />
      </div>

      <Button
        fill
        intent={Intent.PRIMARY}
        text="Применить"
        onClick={() => {
          onApply(value);
        }}
      />
    </>
  );
}

interface IProps {
  initialValue: number | null;
  label: string;
  onApply: (newValue: number | null) => void;
}

export function UsersFilter({ initialValue, label, onApply }: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ButtonGroup>
      <Popover
        content={
          <UsersFilterPopoverContent
            initialValue={initialValue}
            onApply={newValue => {
              onApply(newValue);
              setIsOpen(false);
            }}
          />
        }
        hasBackdrop
        isOpen={isOpen}
        position={Position.BOTTOM}
        onInteraction={setIsOpen}
      >
        <Button
          icon="filter"
          intent={initialValue == null ? undefined : Intent.DANGER}
          text={label}
        />
      </Popover>

      {initialValue != null && (
        <Button
          icon="cross"
          intent={Intent.DANGER}
          onClick={() => {
            onApply(null);
          }}
        />
      )}
    </ButtonGroup>
  );
}

import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { HTMLTable } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';

import { TextCell } from './cells/text';
import { IColumnProps } from './column';
import * as css from './table.module.css';

type GetRowProps<TItem> = (item: TItem) => { selected: boolean };

interface IProps<TItem> {
  children: React.ReactNode;
  emptyMessage?: string;
  getRowProps?: GetRowProps<TItem>;
  isFetching?: boolean;
  items: TItem[] | undefined | null;
}

export function Table<TItem extends { id: string | number }>({
  children,
  emptyMessage,
  getRowProps,
  isFetching,
  items,
}: IProps<TItem>) {
  if (!items || items.length === 0) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <EmptyListMessage description={emptyMessage} />
    );
  }

  const columns = React.Children.toArray(children).map(
    (column: React.ReactElement<IColumnProps<unknown>>) => column.props
  );

  return (
    <div
      className={cx(css.root, {
        [css.root_isFetching]: isFetching,
      })}
    >
      <HTMLTable bordered className={css.table} condensed interactive>
        <colgroup>
          {columns.map(column => (
            <col key={column.id} width={column.width} />
          ))}
        </colgroup>

        <thead>
          <tr>
            {columns.map(column => (
              <th key={column.id}>
                <TextCell>{column.label}</TextCell>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {items.map((item, rowIndex) => {
            const rowProps = getRowProps
              ? getRowProps(item)
              : { selected: undefined };

            return (
              <tr
                key={item.id}
                className={cx(css.row, {
                  [css.row_selected]: rowProps.selected,
                })}
              >
                {columns.map((column, columnIndex) => (
                  <td key={column.id}>
                    {column.cell(item, rowIndex, columnIndex)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </div>
  );
}

import { AutocompleteFilter } from '_core/filters/autocompleteFilter';
import { FilterPopoverValueProcessor } from '_core/filters/filterPopover';
import * as React from 'react';

import { TechrunRequestsAutocompleteInFormGroup } from './autocomplete';
import * as css from './filterInPopover.module.css';

const valueProcessor: FilterPopoverValueProcessor<
  string | undefined,
  number | null
> = {
  emptyValue: undefined,
  isEmpty: value => !value,
  serialize: value => (value == null ? undefined : String(value)),

  deserialize(value) {
    const n = typeof value === 'string' ? Number(value) : null;

    return n != null && isFinite(n) ? n : null;
  },
};

export function TechrunRequestsFilterInPopover({
  initialValue,
  label,
  onApply,
}: {
  initialValue: string | undefined;
  label: string;
  onApply: (nextValue: string | undefined) => void;
}) {
  return (
    <AutocompleteFilter
      initialValue={initialValue}
      label={label}
      valueProcessor={valueProcessor}
      onApply={onApply}
    >
      {({ value, onChange }) => (
        <div className={css.main}>
          <TechrunRequestsAutocompleteInFormGroup
            label="Заявка"
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </AutocompleteFilter>
  );
}

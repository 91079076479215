import { Link } from '_core/router5/link';
import { Classes } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';

interface IItemProps {
  label: string;
  params?: Record<string, unknown>;
  to?: string;
}

export function BreadcrumbsItem({ label, params, to }: IItemProps) {
  return (
    <li>
      <Link
        className={cx(Classes.BREADCRUMB, {
          [Classes.BREADCRUMB_CURRENT]: !to,
        })}
        params={params}
        to={to}
      >
        {label}
      </Link>
    </li>
  );
}

interface IProps {
  children: React.ReactNode;
}

export function Breadcrumbs({ children }: IProps) {
  return <ul className={Classes.BREADCRUMBS}>{children}</ul>;
}

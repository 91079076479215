import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { Switch } from '_core/inputs/switch';
import { LinkButton } from '_core/router5/linkButton';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Intent, NonIdealState } from '@blueprintjs/core';
import { IContractSell } from 'contractsSell/api';
import dayjs from 'dayjs';
import { DocumentStatusIndicator } from 'documents/statusIndicator';
import { DocumentStatus } from 'documents/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { TechrunProtocolRateType } from 'techrunProtocols/types';
import {
  createTechrunProtocolSell,
  fetchTechrunProtocolsSell,
  getTechrunProtocolSellDocxDownloadLink,
  ITechrunProtocolSell,
  updateTechrunProtocolSell,
  uploadTechrunProtocolSellRates,
} from 'techrunProtocolsSell/api';
import invariant from 'tiny-invariant';
import { TripType, vatRateForTripType } from 'tripTypes/types';
import { fetchAllWagonTypes } from 'wagonTypes/api';
import { getDefaultWagonType, wagonTypeToOption } from 'wagonTypes/utils';

import { DocumentListLayout } from './documentListLayout';
import {
  ITechrunProtocolFormValues,
  TechrunProtocolForm,
} from './techrunProtocols/techrunProtocolForm';

interface IProps {
  contract: IContractSell;
}

export function ContractsSellViewTechrunProtocolsTab({ contract }: IProps) {
  const { route, router } = useRouteNode(
    'contracts.sell.view.techrunProtocols'
  );

  const navigationLock = useFormNavigationLock(route.name);
  const api = useApiClient();

  const { data, isFetching, updateData } = useAsyncData(
    [contract.id, api, route.params.showArchived],
    async () => {
      const [protocolsListResponse, wagonTypes] = await Promise.all([
        fetchAllPages(page =>
          fetchTechrunProtocolsSell(api, {
            contract: contract.id,
            isArchived: route.params.showArchived ? undefined : false,
            page,
          })
        ),

        fetchAllWagonTypes(api),
      ]);

      return {
        protocols: protocolsListResponse.results,
        wagonTypes,
      };
    }
  );

  const isCreateRoute =
    route.name === 'contracts.sell.view.techrunProtocols.create';

  const selectedProtocolId =
    route.name === 'contracts.sell.view.techrunProtocols.view'
      ? Number(route.params.protocolId)
      : null;

  const selectedProtocol = data?.protocols.find(
    protocol => protocol.id === selectedProtocolId
  );

  const [editingLocked, setEditingLocked] = useState(selectedProtocol !== null);

  useEffect(() => {
    setEditingLocked(selectedProtocol != null);
  }, [selectedProtocol]);

  const getInitialValues = (): ITechrunProtocolFormValues => {
    function protocolToFormValues({
      daysLoad,
      daysUnload,
      downtimeLimit,
      rates,
      ...otherProps
    }: ITechrunProtocolSell): ITechrunProtocolFormValues {
      return {
        ...otherProps,
        daysLoad: String(daysLoad),
        daysUnload: String(daysUnload),
        downtimeLimit: String(downtimeLimit),
        rates: rates.map(rate => ({ ...rate, isLocked: true })),
      };
    }

    if (selectedProtocol) {
      return protocolToFormValues(selectedProtocol);
    }

    if (route.params.copyFrom) {
      const protocolToCopyFrom = data?.protocols.find(
        protocol => protocol.id === Number(route.params.copyFrom)
      );

      if (protocolToCopyFrom) {
        return protocolToFormValues({ ...protocolToCopyFrom, files: [] });
      }
    }

    const defaultWagonType = getDefaultWagonType(data?.wagonTypes ?? null);

    return {
      additionalDowntimeRate: contract.additionalDowntimeRate,
      cargo: null,
      date: new Date(),
      daysLoad: String(contract.daysLoad),
      daysUnload: String(contract.daysUnload),
      downtimeLimit: String(contract.downtimeLimit),
      downtimeRate: contract.downtimeRate,
      downtimeRateTotal: '',
      downtimeRateVatValue: '',
      downtimeVatRate: contract.downtimeVatRate,
      endDate: dayjs().endOf('year').startOf('date').toDate(),
      files: [],
      hasEmptyTariff: true,
      hasIntEmptyTariff: false,
      hasIntLoadedTariff: false,
      hasLoadedTariff: false,
      hasUseWagon: true,
      name: '',
      number: '',
      partnerSign: contract.partnerSign,
      rates: [],
      rateType: TechrunProtocolRateType.Wagon,
      signerAuthority: '',
      startDate: new Date(),
      status: DocumentStatus.Created,
      tripType: TripType.Internal,
      vatRate: vatRateForTripType(TripType.Internal),
      wagonType: defaultWagonType?.id ?? null,
    };
  };

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <VGrid>
      <Row>
        <Grid>
          <Col>
            <Toolbar>
              <Switch
                checked={route.params.showArchived === true}
                label="Архивные протоколы"
                onChange={newShowArchived => {
                  router.navigate(route.name, {
                    ...route.params,
                    showArchived: newShowArchived || undefined,
                  });
                }}
              />
            </Toolbar>
          </Col>

          <Col>
            <Toolbar align="right">
              <LinkButton
                intent={Intent.PRIMARY}
                params={{
                  ...route.params,
                  copyFrom: undefined,
                  protocolId: undefined,
                }}
                text="Добавить"
                to="contracts.sell.view.techrunProtocols.create"
              />
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row>
        {!isCreateRoute && data.protocols.length === 0 ? (
          <NonIdealState
            icon="list"
            description="Вы можете создать протокол с помощью кнопки выше"
            title="У договора нет протоколов"
          />
        ) : (
          <DocumentListLayout
            list={
              <Table
                emptyMessage="У договора нет протоколов"
                getRowProps={(protocol: ITechrunProtocolSell) => ({
                  selected: selectedProtocol
                    ? protocol.id === selectedProtocol.id
                    : false,
                })}
                isFetching={isFetching}
                items={data.protocols}
              >
                <Column
                  width={150}
                  id="protocolName"
                  label="Наименование"
                  cell={(protocol: ITechrunProtocolSell) => (
                    <LinkCell
                      params={{ ...route.params, protocolId: protocol.id }}
                      to="contracts.sell.view.techrunProtocols.view"
                    >
                      {protocol.protocolName}
                    </LinkCell>
                  )}
                />

                <Column
                  width={80}
                  id="status"
                  label="Статус"
                  cell={(protocol: ITechrunProtocolSell) => (
                    <DocumentStatusIndicator status={protocol.status} />
                  )}
                />
              </Table>
            }
            form={
              !isCreateRoute && !selectedProtocol ? (
                <NonIdealState
                  icon="select"
                  description="Выберите протокол в списке слева или добавьте новый"
                  title="Протоколов не выбрано"
                />
              ) : (
                <TechrunProtocolForm
                  contractKind="sell"
                  docxLink={
                    selectedProtocol
                      ? getTechrunProtocolSellDocxDownloadLink(
                          api,
                          selectedProtocol.id
                        )
                      : undefined
                  }
                  editingLocked={editingLocked}
                  initialValues={getInitialValues()}
                  navigationLock={navigationLock}
                  selectedProtocol={selectedProtocol}
                  wagonTypeOptions={data.wagonTypes.map(wagonTypeToOption)}
                  onCancelEditing={
                    editingLocked || isCreateRoute
                      ? undefined
                      : () => {
                          setEditingLocked(true);
                        }
                  }
                  onDuplicate={
                    selectedProtocol
                      ? () => {
                          router.navigate(
                            'contracts.sell.view.techrunProtocols.create',
                            {
                              ...route.params,
                              copyFrom: String(selectedProtocol.id),
                              protocolId: undefined,
                            }
                          );
                        }
                      : undefined
                  }
                  onRatesImportUpload={async file => {
                    invariant(
                      selectedProtocol,
                      'Trying to import rates with no selected protocol'
                    );

                    const updatedProtocol =
                      await uploadTechrunProtocolSellRates(
                        api,
                        selectedProtocol.id,
                        file
                      );

                    updateData(
                      prevData =>
                        prevData && {
                          ...prevData,
                          protocols: prevData.protocols.map(protocol =>
                            protocol.id === updatedProtocol.id
                              ? updatedProtocol
                              : protocol
                          ),
                        }
                    );
                  }}
                  onStartEditing={
                    editingLocked
                      ? () => {
                          if (
                            // eslint-disable-next-line no-alert
                            window.confirm(
                              'Вы собираетесь изменить сохранённый протокол техрейса'
                            )
                          ) {
                            setEditingLocked(false);
                          }
                        }
                      : undefined
                  }
                  onSubmit={async values => {
                    const serialize = ({
                      rates,
                      ...formData
                    }: ITechrunProtocolFormValues) => ({
                      ...formData,
                      contract: contract.id,

                      rates: rates.map(
                        ({ isLocked, tariffEmpty, tariffLoaded, ...rate }) => ({
                          ...rate,
                          tariffEmpty: tariffEmpty || null,
                          tariffLoaded: tariffLoaded || null,
                        })
                      ),
                    });

                    try {
                      if (selectedProtocol == null) {
                        const newProtocol = await createTechrunProtocolSell(
                          api,
                          serialize(values)
                        );

                        updateData(
                          prevData =>
                            prevData && {
                              ...prevData,
                              protocols: [newProtocol].concat(
                                prevData.protocols
                              ),
                            }
                        );

                        navigationLock.unlock();

                        router.navigate(
                          'contracts.sell.view.techrunProtocols.view',
                          {
                            ...route.params,
                            protocolId: newProtocol.id,
                          }
                        );
                      } else {
                        const updatedProtocol = await updateTechrunProtocolSell(
                          api,
                          selectedProtocol.id,
                          serialize(values)
                        );

                        updateData(
                          prevData =>
                            prevData && {
                              ...prevData,
                              protocols: prevData.protocols.map(protocol =>
                                protocol.id === updatedProtocol.id
                                  ? updatedProtocol
                                  : protocol
                              ),
                            }
                        );

                        setEditingLocked(true);
                      }

                      return undefined;
                    } catch (err) {
                      return submissionErrorsFromApiError(
                        err,
                        selectedProtocol
                          ? 'Не удалось изменить протокол техрейса: Непредвиденная ошибка'
                          : 'Не удалось создать протокол техрейса: Непредвиденная ошибка'
                      );
                    }
                  }}
                />
              )
            }
          />
        )}
      </Row>
    </VGrid>
  );
}

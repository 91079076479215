import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import dayjs from 'dayjs';
import { IExpeditionRequestSerialized } from 'expeditionRequests/types';
import {
  expeditionTransportationStatusFromGroup,
  IExpeditionTransportation,
} from 'expeditionTransportations/types';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { IStationSerialized } from 'stations/types';
import { getAccountingStateLabel } from 'transportations/types';

export interface IExpeditionTransportationsTableItem
  extends Omit<
    IExpeditionTransportation,
    'arrivalStation' | 'departureStation' | 'request'
  > {
  arrivalStation: IStationSerialized;
  departureStation: IStationSerialized;
  request: IExpeditionRequestSerialized | null;
}

interface IProps {
  isFetching: boolean;
  transportations: IExpeditionTransportationsTableItem[];
}

export function ExpeditionTransportationsTable({
  isFetching,
  transportations,
}: IProps) {
  const columns = useMemo(
    (): Array<ListTableColumn<IExpeditionTransportationsTableItem>> => [
      {
        id: 'waybillNumber',
        label: 'Накладная',
        defaultWidth: 100,
        copyCellContent: transportation => transportation.waybillNumber,
        renderCellContent: transportation => (
          <Ellipsis
            component={Link}
            params={{
              id: transportation.id,
              status: expeditionTransportationStatusFromGroup(
                transportation.group
              ),
            }}
            to="expeditions.transportations.edit"
          >
            {transportation.waybillNumber}
          </Ellipsis>
        ),
      },
      {
        id: 'request',
        label: 'Заявка',
        defaultWidth: 380,
        copyCellContent: transportation =>
          transportation.request ? transportation.request.requestName : '',
        renderCellContent: transportation =>
          transportation.request && (
            <Ellipsis
              component={Link}
              params={{ id: transportation.request.id }}
              to="expeditions.requests.view"
            >
              {transportation.request.requestName}
            </Ellipsis>
          ),
      },
      {
        id: 'accountingState',
        label: 'Бух. состояние',
        defaultWidth: 100,
        copyCellContent: transportation =>
          getAccountingStateLabel(transportation.accountingState),
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {getAccountingStateLabel(transportation.accountingState)}
          </Ellipsis>
        ),
      },
      {
        id: 'departureStation',
        label: 'Станция отправления',
        defaultWidth: 180,
        copyCellContent: transportation => transportation.departureStation.name,
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {transportation.departureStation.name}
          </Ellipsis>
        ),
      },
      {
        id: 'arrivalStation',
        label: 'Станция назначения',
        defaultWidth: 180,
        copyCellContent: transportation => transportation.arrivalStation.name,
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {transportation.arrivalStation.name}
          </Ellipsis>
        ),
      },
      {
        id: 'created',
        label: 'Создана',
        defaultWidth: 130,
        copyCellContent: transportation =>
          dayjs(transportation.created).format(DATE_FORMAT_DATETIME),
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {dayjs(transportation.created).format(DATE_FORMAT_DATETIME)}
          </Ellipsis>
        ),
      },
    ],
    []
  );

  const getItemId = useCallback(
    (transportation: IExpeditionTransportationsTableItem) =>
      String(transportation.id),
    []
  );

  return (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      isFetching={isFetching}
      items={transportations}
    />
  );
}

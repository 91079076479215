import { range } from '_core/fp/range';
import { Checkbox } from '_core/inputs/checkbox';
import * as React from 'react';
import { useField } from 'react-final-form';

import * as css from './checkboxList.module.css';

function rearrangeIntoColumns<T>(colCount: number, items: T[]): T[] {
  if (colCount === 1) {
    return items;
  }

  const itemCount = items.length;
  const rowCount = Math.ceil(itemCount / colCount);

  return range(0, rowCount)
    .flatMap(row => range(0, colCount).map(col => rowCount * col + row))
    .slice(0, itemCount)
    .map(index => items[index]);
}

export interface ICheckboxListOption {
  label: string;
  value: string;
}

interface Props {
  allOption?: boolean;
  allOptionLabel?: string;
  columnCount?: number;
  disabled?: boolean;
  name?: string;
  options: ICheckboxListOption[];
  value: string[];
  onChange: (newValue: string[]) => void;
}

const ALL_OPTION_VALUE = 'all';

export function CheckboxList({
  allOption: allOptionProp,
  allOptionLabel = 'Все',
  columnCount = 1,
  disabled,
  name,
  options,
  value,
  onChange,
}: Props) {
  return (
    <div className={css.main}>
      {rearrangeIntoColumns(
        columnCount,
        (allOptionProp
          ? [{ label: allOptionLabel, value: ALL_OPTION_VALUE }]
          : []
        ).concat(options)
      ).map(option => {
        const isAllOption = option.value === ALL_OPTION_VALUE;

        return (
          <div
            key={option.value}
            className={css.item}
            style={{ width: `${100 / columnCount}%` }}
          >
            <Checkbox
              checked={
                isAllOption
                  ? value.length === options.length
                  : value.includes(option.value)
              }
              disabled={disabled}
              label={option.label}
              name={name}
              value={option.value}
              onChange={checked => {
                onChange(
                  isAllOption
                    ? checked
                      ? options.map(o => o.value)
                      : []
                    : checked
                    ? value.concat([option.value])
                    : value.filter(item => item !== option.value)
                );
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export function CheckboxListForFinalForm({
  disabled,
  name,
  ...otherProps
}: Omit<Props, 'value' | 'onChange'> & { name: string }) {
  const { input, meta } = useField(name);

  return (
    <CheckboxList
      {...otherProps}
      disabled={meta.submitting || disabled}
      name={input.name}
      onChange={input.onChange}
      value={input.value}
    />
  );
}

import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { trimTime } from '_core/dates/utils';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import dayjs from 'dayjs';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import {
  ISuppliersDocumentSerialized,
  SuppliersDocumentState,
} from 'suppliersDocuments/types';
import { getNormalVatRate } from 'vatRates/vatRates';

import { ISuppliersDocumentsFormValues, SuppliersDocumentsForm } from './form';

export default function SuppliersDocumentsCreateRoute() {
  const { route, router } = useRouteNode('suppliersDocuments.create');
  const navigationLock = useFormNavigationLock('suppliersDocuments.create');
  const api = useApiClient();

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const persist = ({ date, ...otherValues }: ISuppliersDocumentsFormValues) =>
    api.post<ISuppliersDocumentSerialized>('/suppliers_documents', {
      ...otherValues,
      date: date ? dayjs(date).format(DATE_FORMAT_API_DATE) : null,
    });

  const title = 'Новый документ';

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось создать документ подрядчика: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Документы от подрядчика"
              params={listParams}
              to="suppliersDocuments"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <SuppliersDocumentsForm
            initialValues={{
              amount: '0.00',
              amountTotal: '0.00',
              date: trimTime(new Date()),
              files: [],
              number: '',
              partner: null,
              relatedExpenses: '0.00',
              state: SuppliersDocumentState.Open,
              vatRate: getNormalVatRate(),
              vatValue: '0.00',
            }}
            navigationLock={navigationLock}
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('suppliersDocuments', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const createdSuppliersDocument = await persist(values);
                navigationLock.unlock();
                router.navigate('suppliersDocuments.edit', {
                  id: createdSuppliersDocument.id,
                  listParams: route.params.listParams,
                });
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

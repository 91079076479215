import { selectMe } from '_core/me/me';
import { MiddlewareFactory } from 'router5/dist/types/router';

import { IRouterDependencies } from './types';

export const authMiddleware: MiddlewareFactory<IRouterDependencies> =
  (router, { store }) =>
  (toState, _, done) => {
    const currentUser = selectMe(store.getState());
    const isLoginPage = toState.name === 'login';

    if (isLoginPage && currentUser) {
      done({
        redirect: router.matchPath(toState.params.next || '/') || {
          name: 'home',
        },
      });
    } else if (!currentUser && !isLoginPage) {
      done({
        redirect: {
          name: 'login',
          params: { next: router.buildPath(toState.name, toState.params) },
        },
      });
    } else {
      done();
    }
  };

import { useApiClient } from '_core/api/context';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import {
  selectIsFavoriteTechrunRequest,
  toggleTechrunRequestFavoriteStatus,
} from '_core/me/me';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import {
  TabsRouteNode,
  TabsRouteNodeExpander,
  TabsRouteNodeTab,
} from '_core/router5/tabsRouteNode';
import { useToaster } from '_core/toaster/toasterContext';
import { useAsyncData } from '_core/useAsyncData';
import { useErpDispatch, useErpSelector } from '_erp/redux';
import { Button, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { ITechrunRequest } from 'techrunRequests/types';

import { TechrunRequestsFormRouteWrapper } from './formRouteWrapper';
import { TechrunRequestsViewMainTab } from './viewMainTab';
import { TechrunRequestsViewParksTab } from './viewParksTab';

export default function TechrunRequestsViewRoute() {
  const { route } = useRouteNode('techruns.requests.view');
  const api = useApiClient();
  const toaster = useToaster();
  const dispatch = useErpDispatch();
  const requestId = Number(route.params.id);
  const isFavorite = useErpSelector(state =>
    selectIsFavoriteTechrunRequest(requestId, state)
  );

  const { data, error, isFetching, updateData } = useAsyncData(
    [requestId, api],
    () => api.get<ITechrunRequest>(`/techrun_requests/${requestId}`)
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    )
  ) : (
    <TechrunRequestsFormRouteWrapper
      listParams={listParams}
      title={data.requestName}
    >
      <TabsRouteNode
        nodeName="techruns.requests.view"
        preserveRouteParams={['id', 'listParams']}
      >
        <TabsRouteNodeTab id="main" title="Основные данные">
          <TechrunRequestsViewMainTab
            techrunRequest={data}
            updateTechrunRequestData={updatedRequestData =>
              updateData(() => updatedRequestData)
            }
          />
        </TabsRouteNodeTab>

        <TabsRouteNodeTab id="parks" title="Парки">
          <TechrunRequestsViewParksTab
            techrunRequest={data}
            updateTechrunRequestData={updatedRequestData =>
              updateData(() => updatedRequestData)
            }
          />
        </TabsRouteNodeTab>

        <TabsRouteNodeExpander />

        <Button
          icon={isFavorite ? 'star' : 'star-empty'}
          intent={Intent.SUCCESS}
          large
          minimal
          onClick={async () => {
            try {
              await dispatch(toggleTechrunRequestFavoriteStatus(data.id));
            } catch (err) {
              toaster.show({
                icon: 'error',
                intent: Intent.DANGER,
                message:
                  'Не удалось изменить статус избранности заявки техрейса',
              });

              throw err;
            }
          }}
        />
      </TabsRouteNode>
    </TechrunRequestsFormRouteWrapper>
  );
}

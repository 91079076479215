import { IFormNavigationLock } from '_core/router5/navigationLock';
import { SubmissionErrors } from 'final-form';
import { Park } from 'parks/api';
import * as React from 'react';

import { IParksFormValues, ParksForm } from './form';

interface IProps {
  navigationLock: IFormNavigationLock;
  park: Park;
  onRemove: () => Promise<SubmissionErrors | void>;
  onSave: (values: IParksFormValues) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IParksFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function ParksEditMainTab({
  navigationLock,
  park,
  onRemove,
  onSave,
  onSaveAndContinue,
}: IProps) {
  return (
    <ParksForm
      initialValues={{
        allTechrunRequestWagons: park.allTechrunRequestWagons,
        expeditionRequests: park.expeditionRequests,
        name: park.name,
        techrunRequests: park.techrunRequests,
        wagons: park.wagons,
      }}
      navigationLock={navigationLock}
      park={park}
      onRemove={onRemove}
      onSave={onSave}
      onSaveAndContinue={onSaveAndContinue}
    />
  );
}

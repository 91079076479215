import { FinalForm } from '_core/final-form/finalForm';
import { Button, Dialog, Intent } from '@blueprintjs/core';
import React from 'react';

import { BaseForm } from '../../../_core/forms/baseForm';
import { FormErrors } from '../../../_core/forms/formErrors';
import { FormGroupForFinalForm } from '../../../_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '../../../_core/inputs/inputMultiple';
import { Toolbar } from '../../../_core/toolbar';
import { Station } from '../../../stations/api';
import * as css from './editRowStationsForm.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  station: Station | null;
  onSave: (station: Station) => void;
};

export const EditRowStationsForm = ({
  isOpen,
  onClose,
  station,
  onSave,
}: Props) => {
  const initialValues = React.useMemo(
    (): Station | {} =>
      station
        ? {
            phone: station?.phone,
            extraInfo: station?.extraInfo,
            goGp: station?.goGp,
            capacityPp: station?.capacityPp,
            loadingFront: station?.loadingFront,
            maxWagons: station?.maxWagons,
            locoAffiliation: station?.locoAffiliation,
            locoOnStation: station?.locoOnStation,
            rzdShift: station?.rzdShift,
            goGpShift: station?.goGpShift,
            note: station?.note,
          }
        : {},
    [station]
  );

  const handleSaveStation = (values: Station) => {
    if (values) {
      onSave({ ...station, ...values });
    }
  };

  return (
    <Dialog
      title={`Редактирование станции "${station?.name}"`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FinalForm<Station | {}>
        initialValues={initialValues}
        onSubmit={handleSaveStation}
      >
        {({ error, handleSubmit, submitError, submitting }) => (
          <BaseForm className={css.form} onSubmit={handleSubmit}>
            <FormErrors error={error || submitError} />

            <FormGroupForFinalForm label="ЖД телефон" name="phone">
              <InputMultipleForFinalForm name="phone" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Доп. информация" name="extraInfo">
              <InputMultipleForFinalForm name="extraInfo" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="ГО и ГП" name="goGp">
              <InputMultipleForFinalForm name="goGp" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Вместимость п/п" name="capacityPp">
              <InputMultipleForFinalForm name="capacityPp" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Фронт погрузки" name="loadingFront">
              <InputMultipleForFinalForm name="loadingFront" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Max вагонов в сутки" name="maxWagons">
              <InputMultipleForFinalForm name="maxWagons" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Принадлежность локомотива"
              name="locoAffiliation"
            >
              <InputMultipleForFinalForm name="locoAffiliation" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Локомотив на станции"
              name="locoOnStation"
            >
              <InputMultipleForFinalForm name="locoOnStation" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Сменность РЖД" name="rzdShift">
              <InputMultipleForFinalForm name="rzdShift" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Сменность ГО и ГП" name="goGpShift">
              <InputMultipleForFinalForm name="goGpShift" rows={2} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Комментарий" name="note">
              <InputMultipleForFinalForm name="note" rows={2} />
            </FormGroupForFinalForm>

            <Toolbar align="center">
              <Button onClick={onClose} text="Закрыть" />

              <Button
                text="Сохранить"
                type="submit"
                intent={Intent.SUCCESS}
                loading={submitting}
              />
            </Toolbar>
          </BaseForm>
        )}
      </FinalForm>
    </Dialog>
  );
};

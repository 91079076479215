import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import dayjs from 'dayjs';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { ISuppliersDocumentSerialized } from 'suppliersDocuments/types';

import { ISuppliersDocumentsFormValues, SuppliersDocumentsForm } from './form';

export default function SuppliersDocumentsEditRoute() {
  const { route, router } = useRouteNode('suppliersDocuments.edit');
  const navigationLock = useFormNavigationLock('suppliersDocuments.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    () =>
      api.get<ISuppliersDocumentSerialized>(
        `/suppliers_documents/${route.params.id}`
      )
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const persist = ({ date, ...otherValues }: ISuppliersDocumentsFormValues) =>
    api.put<ISuppliersDocumentSerialized>(
      `/suppliers_documents/${route.params.id}`,
      {
        ...otherValues,
        date: date ? dayjs(date).format(DATE_FORMAT_API_DATE) : null,
      }
    );

  const title = data.number || '<не указан>';

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось изменить документ подрядчика: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Документы от подрядчика"
              params={listParams}
              to="suppliersDocuments"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <SuppliersDocumentsForm
            entityData={data}
            initialValues={{
              ...data,
              date: data.date == null ? null : parseDate(data.date),
            }}
            navigationLock={navigationLock}
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('suppliersDocuments', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedSuppliersDocument = await persist(values);
                updateData(() => updatedSuppliersDocument);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

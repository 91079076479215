import { useApiClient } from '_core/api/context';
import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxListForFinalForm } from '_core/inputs/checkboxList';
import { SelectInFormGroupForFinalForm } from '_core/inputs/select';
import { SwitchForFinalForm } from '_core/inputs/switch';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Alert, Button, FormGroup, Intent } from '@blueprintjs/core';
import { IContact } from 'contacts/api';
import { formatContactName } from 'contacts/utils';
import dayjs from 'dayjs';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { Park, ParkSubscriber } from 'parks/api';
import { fetchAllParkSchedules } from 'parksSchedules/api';
import { parkScheduleToSelectOption } from 'parksSchedules/utils';
import { fetchAllParksTemplates } from 'parksTemplates/api';
import { parkTemplateToSelectOption } from 'parksTemplates/utils';
import * as React from 'react';
import { useRef, useState } from 'react';

import * as css from './editMailingTab.module.css';
import { ParkSubscribersField } from './subscribersField';

export interface IEditMailingTabFormValues {
  dailySchedules: string[];
  isMailingActive: boolean;
  subscribers: ParkSubscriber[];
  template: string | null;
  weekendSchedules: string[];
}

interface IProps {
  navigationLock: IFormNavigationLock;
  park: Park;
  onMailSend: (contacts: IContact[]) => Promise<void>;
  onSave: (
    values: IEditMailingTabFormValues
  ) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IEditMailingTabFormValues
  ) => Promise<SubmissionErrors | void>;
}

interface IConfirmSendMailAlertState {
  contacts: IContact[];
  isOpen: boolean;
}

export function ParksEditMailingTab({
  navigationLock,
  park,
  onMailSend,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const api = useApiClient();

  const { data, isFetching } = useAsyncData([api], async () => {
    const [schedules, templates] = await Promise.all([
      fetchAllParkSchedules(api),
      fetchAllParksTemplates(api),
    ]);

    return {
      schedules,
      templates,
    };
  });

  const [confirmSendMailAlertState, setConfirmSendMailAlertState] =
    useState<IConfirmSendMailAlertState | null>(null);

  const submitFnRef = useRef(onSave);

  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <>
      <FinalForm
        initialValues={{
          ...park,
          dailySchedules: park.dailySchedules.map(String),
          template: park.template ? String(park.template) : null,
          weekendSchedules: park.weekendSchedules.map(String),
        }}
        onSubmit={values => submitFnRef.current(values)}
      >
        {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
          <BaseForm className={css.form} onSubmit={handleSubmit}>
            <FormNavigationLock
              navigationLock={navigationLock}
              shouldLock={dirty}
            />

            <VGrid stretch>
              {(error || submitError) && (
                <Row>
                  <FormErrors error={error || submitError} />
                </Row>
              )}

              <Row containContent stretch>
                <Grid className={css.grid}>
                  <Col span={2}>
                    <SelectInFormGroupForFinalForm
                      id="template"
                      label="Шаблон"
                      name="template"
                      options={data.templates.map(parkTemplateToSelectOption)}
                    />

                    <SwitchForFinalForm
                      id="isMailingActive"
                      label="Рассылка активна"
                      name="isMailingActive"
                    />

                    <FormGroup label="Расписания отправки">
                      <CheckboxListForFinalForm
                        name="dailySchedules"
                        options={data.schedules.map(parkScheduleToSelectOption)}
                      />
                    </FormGroup>

                    <FormGroup label="Выходные дни">
                      <CheckboxListForFinalForm
                        name="weekendSchedules"
                        options={data.schedules.map(parkScheduleToSelectOption)}
                      />
                    </FormGroup>
                  </Col>

                  <Col span={10}>
                    <ParkSubscribersField
                      name="subscribers"
                      lastMailed={park.lastMailed}
                      selectedContacts={selectedContacts}
                      onSelectedContactsChange={setSelectedContacts}
                      onSendMailRequest={contacts => {
                        setConfirmSendMailAlertState({
                          contacts,
                          isOpen: true,
                        });
                      }}
                    />
                  </Col>
                </Grid>
              </Row>

              <Row>
                <Toolbar align="right">
                  <Button
                    disabled={submitting}
                    text="Сохранить и продолжить редактирование"
                    onClick={() => {
                      submitFnRef.current = onSaveAndContinue;
                      form.submit();
                    }}
                  />

                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    text="Сохранить"
                    type="submit"
                    onClick={() => {
                      submitFnRef.current = onSave;
                    }}
                  />
                </Toolbar>
              </Row>
            </VGrid>
          </BaseForm>
        )}
      </FinalForm>

      {confirmSendMailAlertState && (
        <Alert
          cancelButtonText="Отмена"
          canEscapeKeyCancel
          canOutsideClickCancel
          confirmButtonText="Отправить"
          icon="envelope"
          intent={Intent.PRIMARY}
          isOpen={confirmSendMailAlertState.isOpen}
          onCancel={() => {
            setConfirmSendMailAlertState(
              prevState => prevState && { ...prevState, isOpen: false }
            );
          }}
          onClosed={() => {
            setConfirmSendMailAlertState(null);
          }}
          onConfirm={async () => {
            setConfirmSendMailAlertState(
              prevState => prevState && { ...prevState, isOpen: false }
            );

            await onMailSend(confirmSendMailAlertState.contacts);
            setSelectedContacts([]);
          }}
        >
          <p>
            {park.lastMailed
              ? `Последняя автоматическая рассылка была ${dayjs(
                  park.lastMailed
                ).format(DATE_FORMAT_DATETIME)}`
              : 'Автоматической рассылки ещё не было'}
            . Отправить текущую дислокацию{' '}
            {confirmSendMailAlertState.contacts.length === 1
              ? `контакту "${formatContactName(
                  confirmSendMailAlertState.contacts[0],
                  { includeEmail: true }
                )}"`
              : 'выбранным контактам'}
            ?
          </p>
        </Alert>
      )}
    </>
  );
}

import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { chainValidators, required, validate } from '_core/forms/validators';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Toolbar } from '_core/toolbar';
import { Button, Intent, Popover, Position } from '@blueprintjs/core';
import * as React from 'react';
import { useState } from 'react';
import { parseWagonNumbers } from 'wagons/utils';

import * as css from './batchWagonsPopover.module.css';

interface IBatchWagonsPopoverValues {
  wagons: string;
}

interface IProps {
  disabled?: boolean;
  onBatchAdd: (numbers: string[]) => void;
}

export function BatchWagonsPopover({ disabled, onBatchAdd }: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      content={
        <FinalForm<IBatchWagonsPopoverValues>
          initialValues={{ wagons: '' }}
          validate={validate({
            wagons: chainValidators(required(), (value: string) =>
              parseWagonNumbers(value).length === 0
                ? 'Не найдено ни одного корректного номера вагона'
                : undefined
            ),
          })}
          onSubmit={values => {
            onBatchAdd(parseWagonNumbers(values.wagons));
            setIsOpen(false);
          }}
        >
          {({ handleSubmit }) => (
            <BaseForm className={css.form} onSubmit={handleSubmit}>
              <FormGroupForFinalForm name="wagons">
                <InputMultipleForFinalForm
                  autoFocus
                  disabled={disabled}
                  name="wagons"
                  rows={8}
                />
              </FormGroupForFinalForm>

              <Toolbar align="right">
                <Button
                  disabled={disabled}
                  text="Закрыть"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />

                <Button
                  disabled={disabled}
                  intent={Intent.PRIMARY}
                  text="Добавить"
                  type="submit"
                />
              </Toolbar>
            </BaseForm>
          )}
        </FinalForm>
      }
      isOpen={isOpen}
      position={Position.LEFT_TOP}
      onClose={() => {
        setIsOpen(false);
      }}
      onInteraction={setIsOpen}
    >
      <Button disabled={disabled} text="Добавить несколько" />
    </Popover>
  );
}

import { DATE_FORMAT_DATE, DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import dayjs from 'dayjs';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { Station } from 'stations/api';
import { IWagonArrival, WagonArrivalGroup } from 'wagonArrivals/api';

export interface IWagonArrivalsListTableItem
  extends Omit<
    IWagonArrival,
    'arrivalStation' | 'departureStation' | 'loadStation' | 'operationStation'
  > {
  arrivalStation: Station;
  departureStation: Station;
  loadStation: Station | null;
  operationStation: Station | null;
}

interface IProps {
  arrivals: IWagonArrivalsListTableItem[];
  group: WagonArrivalGroup;
  isFetching: boolean;
  lineNumbersStart: number;
  selectedArrivals: string[];
  onSelectedArrivalsChange: (newSelectedArrivals: string[]) => void;
}

export function WagonArrivalsListTable({
  arrivals,
  group,
  isFetching,
  lineNumbersStart,
  selectedArrivals,
  onSelectedArrivalsChange,
}: IProps) {
  const columns = useMemo<Array<ListTableColumn<IWagonArrivalsListTableItem>>>(
    () => [
      {
        id: 'wagon',
        label: 'Номер вагона',
        defaultWidth: 90,
        copyCellContent: wagonArrival => wagonArrival.wagon,
        renderCellContent: wagonArrival => (
          <Ellipsis
            component={Link}
            params={{ number: wagonArrival.wagon }}
            to="wagons.edit"
          >
            {wagonArrival.wagon}
          </Ellipsis>
        ),
      },
      {
        id: 'wagonType',
        label: 'Модель вагона',
        defaultWidth: 80,
        copyCellContent: wagonArrival => wagonArrival.wagonType,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">{wagonArrival.wagonType}</Ellipsis>
        ),
      },
      {
        id: 'wagonOwner',
        label: 'Собственник вагона',
        defaultWidth: 170,
        copyCellContent: wagonArrival =>
          wagonArrival.wagonOwner ? wagonArrival.wagonOwner.shortName : '',
        renderCellContent: wagonArrival =>
          wagonArrival.wagonOwner ? (
            <Ellipsis
              component={Link}
              params={{ id: wagonArrival.wagonOwner.id }}
              to="partners.edit"
            >
              {wagonArrival.wagonOwner.shortName}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'departureDate',
        label: 'Дата отправления',
        defaultWidth: 120,
        copyCellContent: wagonArrival =>
          wagonArrival.departureDate
            ? dayjs(wagonArrival.departureDate).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: wagonArrival =>
          wagonArrival.departureDate ? (
            <Ellipsis component="span">
              {dayjs(wagonArrival.departureDate).format(DATE_FORMAT_DATE)}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'departureStation',
        label: 'Станция отправления',
        defaultWidth: 280,
        copyCellContent: wagonArrival => wagonArrival.departureStation.name,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">
            {wagonArrival.departureStation.name}
          </Ellipsis>
        ),
      },
      {
        id: 'departureRoad',
        label: 'Дорога отправления',
        defaultWidth: 70,
        copyCellContent: wagonArrival => wagonArrival.departureRoad,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">{wagonArrival.departureRoad}</Ellipsis>
        ),
      },
      {
        id: 'operationDatetime',
        label: 'Дата и время операции',
        defaultWidth: 120,
        copyCellContent: wagonArrival =>
          dayjs(wagonArrival.operationDatetime).format(DATE_FORMAT_DATETIME),
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">
            {dayjs(wagonArrival.operationDatetime).format(DATE_FORMAT_DATETIME)}
          </Ellipsis>
        ),
      },
      {
        id: 'operationCode',
        label: 'Код операции',
        defaultWidth: 60,
        copyCellContent: wagonArrival => wagonArrival.operationCode,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">{wagonArrival.operationCode}</Ellipsis>
        ),
      },
      {
        id: 'operationStation',
        label: 'Станция операции',
        defaultWidth: 280,
        copyCellContent: wagonArrival =>
          wagonArrival.operationStation
            ? wagonArrival.operationStation.name
            : '',
        renderCellContent: wagonArrival =>
          wagonArrival.operationStation ? (
            <Ellipsis component="span">
              {wagonArrival.operationStation.name}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'operationRoad',
        label: 'Дорога операции',
        defaultWidth: 70,
        copyCellContent: wagonArrival => wagonArrival.operationRoad,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">{wagonArrival.operationRoad}</Ellipsis>
        ),
      },
      {
        id: 'distanceToArrivalStation',
        label: 'Расстояние до станции назначения',
        defaultWidth: 70,
        copyCellContent: wagonArrival =>
          String(wagonArrival.distanceToArrivalStation),
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">
            {String(wagonArrival.distanceToArrivalStation)}
          </Ellipsis>
        ),
      },
      {
        id: 'arrivalStation',
        label: 'Станция назначения',
        defaultWidth: 280,
        copyCellContent: wagonArrival => wagonArrival.arrivalStation.name,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">
            {wagonArrival.arrivalStation.name}
          </Ellipsis>
        ),
      },
      {
        id: 'arrivalRoad',
        label: 'Дорога назначения',
        defaultWidth: 70,
        copyCellContent: wagonArrival => wagonArrival.arrivalRoad,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">{wagonArrival.arrivalRoad}</Ellipsis>
        ),
      },
      {
        id: 'loadStation',
        label: 'Станция погрузки',
        defaultWidth: 280,
        copyCellContent: wagonArrival =>
          wagonArrival.loadStation ? wagonArrival.loadStation.name : '',
        renderCellContent: wagonArrival =>
          wagonArrival.loadStation ? (
            <Ellipsis component="span">
              {wagonArrival.loadStation.name}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'guNumber',
        label: 'Номер ГУ',
        defaultWidth: 150,
        copyCellContent: wagonArrival => wagonArrival.guNumber,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">{wagonArrival.guNumber}</Ellipsis>
        ),
      },
      {
        id: 'consignee',
        label: 'Грузополучатель',
        defaultWidth: 170,
        copyCellContent: wagonArrival =>
          wagonArrival.consignee ? wagonArrival.consignee.shortName : '',
        renderCellContent: wagonArrival =>
          wagonArrival.consignee ? (
            <Ellipsis
              component={Link}
              params={{ id: wagonArrival.consignee.id }}
              to="partners.edit"
            >
              {wagonArrival.consignee.shortName}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'etranWaybillNumber',
        label: 'Накладная в Этране',
        defaultWidth: 150,
        copyCellContent: wagonArrival => wagonArrival.etranWaybillNumber,
        renderCellContent: wagonArrival => (
          <Ellipsis component="span">
            {wagonArrival.etranWaybillNumber}
          </Ellipsis>
        ),
      },
    ],
    []
  );

  const getItemId = useCallback(
    (wagonArrival: IWagonArrivalsListTableItem): string =>
      String(wagonArrival.id),
    []
  );

  const showColumns = useMemo(() => {
    const columnIds = columns.map(column => column.id);

    if (group === WagonArrivalGroup.Executed) {
      return columnIds.filter(id => id !== 'operationDatetime');
    }

    if (
      group === WagonArrivalGroup.New ||
      group === WagonArrivalGroup.Rejected
    ) {
      return columnIds.filter(id => id !== 'departureDate');
    }

    return columnIds;
  }, [columns, group]);

  return (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      isFetching={isFetching}
      items={arrivals}
      stickyColumnCount={3}
      lineNumbersStart={lineNumbersStart}
      selectedItems={selectedArrivals}
      showColumns={showColumns}
      onSelectedItemsChange={onSelectedArrivalsChange}
    />
  );
}

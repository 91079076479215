import { BadRequestError } from '_core/api/client';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { FormErrors } from '_core/forms/formErrors';
import { FileInputSingle } from '_core/inputs/fileInputSingle';
import { Classes, Dialog } from '@blueprintjs/core';
import * as React from 'react';
import { useState } from 'react';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onUpload: (file: string) => Promise<void>;
}

export function ImportRatesDialog({
  isOpen,
  onClose,
  onSuccess,
  onUpload,
}: IProps) {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isInProgress, setIsInProgress] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        if (!isInProgress) {
          onClose();
        }
      }}
      title="Импорт ставок протокола техрейса"
    >
      <div className={Classes.DIALOG_BODY}>
        {isInProgress ? (
          <CenteredSpinner />
        ) : (
          <>
            <FormErrors error={error} />

            <FileInputSingle
              label="Импорт ставок из файла"
              onChange={async file => {
                if (!file) {
                  return;
                }

                setIsInProgress(true);
                setError(undefined);

                try {
                  await onUpload(file);
                  onSuccess();
                } catch (err) {
                  if (err instanceof BadRequestError) {
                    setError(err.message);
                  } else {
                    setError(
                      'Не удалось импортировать ставки: Непредвиденная ошибка'
                    );
                  }
                } finally {
                  setIsInProgress(false);
                }
              }}
            />
          </>
        )}
      </div>
    </Dialog>
  );
}

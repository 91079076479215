import { DATE_FORMAT_API_DATE, DATE_FORMAT_DATE } from '_core/dates/formats';
import { parseDate, trimTime } from '_core/dates/utils';
import { DatePicker } from '_core/inputs/datePicker';
import { deprecatedFilterInPopover } from '_deprecated/filterInPopover';
import dayjs from 'dayjs';
import * as React from 'react';

const parseValue = (
  str: string | undefined,
  fallbackValue: Date | null = null
) => (str ? trimTime(parseDate(str)) : fallbackValue);

export const DateFilterInPopover = deprecatedFilterInPopover<
  string | undefined,
  {},
  Date | null
>({
  clearValue: undefined,
  extractInitialValue: ({ initialValue }) =>
    parseValue(initialValue, new Date()),
  iconName: 'timeline-events',

  transformOutputValue: value =>
    value ? dayjs(trimTime(value)).format(DATE_FORMAT_API_DATE) : undefined,

  formatButtonLabel: ({ initialValue }) => {
    const value = parseValue(initialValue);

    return value && dayjs(value).format(DATE_FORMAT_DATE);
  },
})(function DateFilterInPopover({ value, onChange }) {
  return <DatePicker value={value || undefined} onChange={onChange} />;
});

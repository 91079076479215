import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroupForFinalForm } from '_core/inputs/inputMoney';
import { SelectInFormGroupForFinalForm } from '_core/inputs/select';
import { Link } from '_core/router5/link';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, H4, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { Fragment } from 'react';
import { ISuppliersDocumentDetail } from 'suppliersDocuments/api';
import {
  suppliersDocumentStateOptions,
  SuppliersDocumentType,
  suppliersDocumentTypeOptions,
} from 'suppliersDocuments/types';
import {
  costFromTotalCostForForm,
  totalCostForForm,
  VatRate,
  vatRateOptions,
  vatValueForForm,
  vatValueFromTotalCostForForm,
} from 'vatRates/vatRates';

interface ISuppliersDocumentsFormValues
  extends Omit<ISuppliersDocumentDetail, 'date' | 'partner'> {
  date: Date | null;
  partner: number | null;
}

export type SubmitTrigger = 'save' | 'saveAndContinue';

interface IProps {
  initialValues: ISuppliersDocumentsFormValues;
  navigationLock: IFormNavigationLock;
  setSubmitTrigger: (submitTrigger: SubmitTrigger) => void;
  suppliersDocument: ISuppliersDocumentDetail;
  onSubmit: (
    values: ISuppliersDocumentsFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function SuppliersDocumentsFromFileForm({
  initialValues,
  navigationLock,
  setSubmitTrigger,
  suppliersDocument,
  onSubmit,
}: IProps) {
  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit}>
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={8}>
                  <H4>Основная информация</H4>

                  <Grid>
                    <Col span={4}>
                      <Grid>
                        <Col span={2}>
                          <DateInputInFormGroupForFinalForm
                            label="Дата"
                            name="date"
                          />
                        </Col>

                        <Col span={2}>
                          <FormGroupForFinalForm
                            label="Номер документа"
                            name="number"
                          >
                            <InputGroupForFinalForm name="number" />
                          </FormGroupForFinalForm>
                        </Col>

                        <Col span={4}>
                          <FormGroupForFinalForm
                            label="Файл перечня"
                            name="sourceName"
                          >
                            <InputGroupForFinalForm
                              name="sourceName"
                              readOnly
                            />
                          </FormGroupForFinalForm>
                        </Col>

                        <Col span={2}>
                          <SelectInFormGroupForFinalForm
                            disabled
                            label="Тип документа"
                            name="docType"
                            options={suppliersDocumentTypeOptions}
                          />
                        </Col>

                        <Col span={2}>
                          <SelectInFormGroupForFinalForm
                            label="Статус"
                            name="state"
                            options={suppliersDocumentStateOptions}
                          />
                        </Col>
                      </Grid>

                      {suppliersDocument.docType ===
                        SuppliersDocumentType.Adjustment && (
                        <p>
                          Является корректировкой для{' '}
                          {suppliersDocument.adjustmentFor ? (
                            <Link
                              params={{ id: suppliersDocument.adjustmentFor }}
                              to="suppliersDocumentsFromFile.edit"
                            >
                              {suppliersDocument.adjustmentForNumber}
                            </Link>
                          ) : (
                            suppliersDocument.adjustmentForNumber
                          )}
                        </p>
                      )}
                    </Col>

                    <Col span={4}>
                      <Grid>
                        <Col span={2}>
                          <PartnersAutocompleteInFormGroupForFinalForm
                            label="Контрагент"
                            name="partner"
                            required
                          />

                          <InputMoneyInFormGroupForFinalForm
                            label="Сумма"
                            name="amount"
                            onChange={nextAmout => {
                              form.change(
                                'amountTotal',
                                totalCostForForm(nextAmout, values.vatRate)
                              );

                              form.change(
                                'vatValue',
                                vatValueForForm(nextAmout, values.vatRate)
                              );
                            }}
                          />

                          <InputMoneyInFormGroupForFinalForm
                            editingLocked
                            label="Сумма итого"
                            name="amountTotal"
                            onChange={nextAmoutTotal => {
                              form.change(
                                'vatValue',
                                vatValueFromTotalCostForForm(
                                  nextAmoutTotal,
                                  values.vatRate
                                )
                              );

                              form.change(
                                'amount',
                                costFromTotalCostForForm(
                                  nextAmoutTotal,
                                  values.vatRate
                                )
                              );
                            }}
                          />
                        </Col>

                        <Col span={2}>
                          <SelectInFormGroupForFinalForm
                            editingLocked
                            label="Ставка НДС"
                            name="vatRate"
                            options={vatRateOptions}
                            onChange={(nextVatRate: VatRate) => {
                              form.change(
                                'amountTotal',
                                totalCostForForm(values.amount, nextVatRate)
                              );

                              form.change(
                                'vatValue',
                                vatValueForForm(values.amount, nextVatRate)
                              );
                            }}
                          />

                          <InputMoneyInFormGroupForFinalForm
                            editingLocked
                            label="Сумма НДС"
                            name="vatValue"
                          />

                          <InputMoneyInFormGroupForFinalForm
                            editingLocked
                            label="Сумма связанных расходов"
                            name="relatedExpenses"
                          />
                        </Col>
                      </Grid>

                      {suppliersDocument.adjustmentDocs.length !== 0 && (
                        <p>
                          Есть корректировки:{' '}
                          {suppliersDocument.adjustmentDocs.map(
                            (doc, index) => (
                              <Fragment key={doc.id}>
                                {index !== 0 && ', '}

                                <Link
                                  params={{ id: doc.id }}
                                  to="suppliersDocumentsFromFile.edit"
                                >
                                  {doc.number}
                                </Link>
                              </Fragment>
                            )
                          )}
                        </p>
                      )}
                    </Col>
                  </Grid>
                </Col>

                <Col span={4}>
                  <H4>Файлы</H4>

                  <FileInputMultipleForFinalForm name="files" />
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    setSubmitTrigger('saveAndContinue');
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    setSubmitTrigger('save');
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { useApiClient } from '_core/api/context';
import { ListResponse } from '_core/api/types';
import { useDebouncedValue } from '_core/useDebouncedValue';
import { FormGroup, Icon, IconName, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';

import { Autocomplete, AutocompleteLink } from './autocomplete';
import * as css from './entityAutocomplete.module.css';

interface Props<TItem, TValue, TFormValues> {
  addToQuery?: {
    [key: string]: boolean | number | string | undefined | null;
  };
  autoFocus?: boolean;
  disabled?: boolean;
  editingLocked?: boolean;
  fetchWithEmptyQuery?: boolean;
  id?: string;
  initialItem?: TItem;
  isInvalid?: boolean;
  name?: string;
  readOnly?: boolean;
  serializeDialogFormValues?: (values: TFormValues) => unknown;
  value: TValue | null;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (newValue: TValue | null, newItem: TItem | null) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

interface CreateEntityAutocompleteConfig<
  TItem,
  TValue extends string | number,
  TFormValues = {},
  TExternalProps = {}
> {
  baseEndpoint: string;
  getLink?: (item: TItem) => AutocompleteLink;
  idProp?: string;
  idsQuery?: string;
  itemToOption: (item: TItem) => {
    label: string;
    value: TValue;
  };
  optionIcon?: IconName;
  placeholder?: string;
  create?: {
    DialogForm: React.ElementType;
    dialogProps?: { [key: string]: unknown };
    getFormInitialValues: (
      inputValue: string,
      props: TExternalProps
    ) => TFormValues;
    newOptionIcon?: IconName;
    newOptionLabel: string;
  };
}

export function createEntityAutocomplete<
  TItem,
  TValue extends string | number,
  TFormValues = {},
  TExternalProps = {}
>({
  baseEndpoint,
  create,
  getLink,
  idProp = 'id',
  idsQuery = 'ids',
  itemToOption,
  optionIcon = 'blank',
  placeholder,
}: CreateEntityAutocompleteConfig<TItem, TValue, TFormValues, TExternalProps>) {
  return function EntityAutocomplete(
    props: Props<TItem, TValue, TFormValues> & TExternalProps
  ) {
    const {
      addToQuery,
      autoFocus,
      disabled,
      editingLocked,
      fetchWithEmptyQuery,
      id,
      initialItem,
      isInvalid,
      name,
      readOnly,
      serializeDialogFormValues,
      value,
      onBlur,
      onChange,
    } = props;

    const [dialogState, setDialogState] = useState<{
      initialValues: TFormValues | undefined;
      isOpen: boolean;
    }>({
      initialValues: undefined,
      isOpen: false,
    });

    const api = useApiClient();
    const [items, setItems] = useState<TItem[]>([]);
    const [isAwaitingNewOptions, setIsAwaitingNewOptions] = useState(false);
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebouncedValue(query, 400);
    useEffect(() => {
      if (debouncedQuery.length === 0 && !fetchWithEmptyQuery) {
        setItems([]);
        setIsAwaitingNewOptions(false);
        return undefined;
      }

      let cancelled = false;

      api
        .get<ListResponse<TItem>>(baseEndpoint, {
          ...addToQuery,
          pageSize: 9,
          search: debouncedQuery,
        })
        .then(response => {
          if (cancelled) {
            return;
          }

          setItems(response.results);
          setIsAwaitingNewOptions(false);
        });

      return () => {
        cancelled = true;
      };
    }, [addToQuery, debouncedQuery, fetchWithEmptyQuery, api]);

    const [selectedItem, setSelectedItem] = useState<TItem | null>(null);

    useEffect(() => {
      if (value === null) {
        setSelectedItem(null);
        return;
      }

      if (initialItem) {
        const initialOption = itemToOption(initialItem);

        if (initialOption.value === value) {
          setSelectedItem(initialItem);
          return;
        }
      }

      const alreadyFetchedItem = items.find(
        item => itemToOption(item).value === value
      );

      if (alreadyFetchedItem) {
        setSelectedItem(alreadyFetchedItem);
        return;
      }

      let cancelled = false;

      api
        .get<ListResponse<TItem>>(baseEndpoint, {
          [idsQuery]: value,
        })
        .then(response => response.results[0] || null)
        .then(item => {
          if (item && !cancelled) {
            setSelectedItem(item);
          }
        });

      return () => {
        cancelled = true;
      };
    }, [initialItem, items, api, value]);

    return (
      <>
        <Autocomplete
          autoFocus={autoFocus}
          disabled={disabled}
          getItemLabel={item => itemToOption(item).label}
          getItemKey={item => itemToOption(item).value}
          id={id}
          isAwaitingNewItems={isAwaitingNewOptions}
          isInvalid={isInvalid}
          items={items}
          link={selectedItem && getLink ? getLink(selectedItem) : undefined}
          name={name}
          placeholder={placeholder}
          readOnly={editingLocked || readOnly}
          renderItem={item => {
            const option = itemToOption(item);

            return (
              <>
                <Icon icon={optionIcon} /> {option.label}
              </>
            );
          }}
          renderNewItem={
            create
              ? () => (
                  <>
                    <Icon icon={create.newOptionIcon || 'add'} />{' '}
                    {create.newOptionLabel}
                  </>
                )
              : undefined
          }
          selectedItem={selectedItem}
          onBlur={onBlur}
          onNewItemSelect={
            create
              ? inputValue => {
                  setDialogState({
                    initialValues: create.getFormInitialValues(
                      inputValue,
                      props
                    ),
                    isOpen: true,
                  });
                }
              : undefined
          }
          onQueryChange={newQuery => {
            setIsAwaitingNewOptions(true);
            setQuery(newQuery);
          }}
          onSelectedItemChange={newSelectedItem => {
            if (onChange) {
              if (newSelectedItem === null) {
                onChange(null, null);
              } else {
                const option = itemToOption(newSelectedItem);
                onChange(option.value, newSelectedItem);
              }
            }
          }}
        />

        {create ? (
          <create.DialogForm
            {...create.dialogProps}
            initialValues={dialogState.initialValues}
            isOpen={dialogState.isOpen}
            onClose={() => {
              setDialogState(prevState => ({ ...prevState, isOpen: false }));
            }}
            onSubmit={async (values: TFormValues) => {
              const createdItem = await api.post<TItem>(
                baseEndpoint,
                serializeDialogFormValues
                  ? serializeDialogFormValues(values)
                  : values
              );

              if (onChange) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange((createdItem as any)[idProp], createdItem);
              }

              setDialogState(prevState => ({ ...prevState, isOpen: false }));
            }}
          />
        ) : null}
      </>
    );
  };
}

export function wrapEntityAutocompleteWithFormGroup<
  TItem,
  TValue extends string | number,
  TFormValues = {},
  TExternalProps = {}
>(
  EntityAutocomplete: React.ComponentType<
    Props<TItem, TValue, TFormValues> & TExternalProps
  >
) {
  return function EntityAutocompleteInFormGroup({
    error,
    id,
    isInvalid,
    label,
    noBottomMargin,
    required,
    ...otherProps
  }: Props<TItem, TValue, TFormValues> & {
    error?: string;
    label?: string;
    noBottomMargin?: boolean;
    required?: boolean;
  } & TExternalProps) {
    return (
      <FormGroup
        className={cx({ [css.main_noBottomMargin]: noBottomMargin })}
        helperText={isInvalid && error}
        intent={isInvalid ? Intent.DANGER : undefined}
        label={label}
        labelFor={id}
        labelInfo={required ? '*' : undefined}
      >
        <EntityAutocomplete
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(otherProps as any)}
          id={id}
          isInvalid={isInvalid}
        />
      </FormGroup>
    );
  };
}

export function wrapEntityAutocompleteInFormGroupForFinalForm<
  TValue,
  TComponent extends React.ComponentType<{
    disabled?: boolean;
    error?: string;
    isInvalid?: boolean;
    name?: string;
    value: TValue;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (newValue: TValue, ...args: unknown[]) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  }>
>(EntityAutocompleteInFormGroup: TComponent) {
  type InnerProps = Omit<
    React.ComponentProps<typeof EntityAutocompleteInFormGroup>,
    'error' | 'isInvalid' | 'name' | 'value' | 'onBlur' | 'onFocus'
  > & { name: string };

  return function EntityAutocompleteInFormGroupForFinalForm({
    disabled,
    name,
    ...otherProps
  }: InnerProps) {
    const { input, meta } = useField<TValue>(name, { allowNull: true });
    const error = meta.error || meta.submitError;

    return (
      <EntityAutocompleteInFormGroup
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(otherProps as any)}
        disabled={disabled || meta.submitting}
        error={error}
        isInvalid={meta.touched && Boolean(error)}
        name={input.name}
        value={input.value}
        onBlur={input.onBlur}
        onChange={(newValue, ...args) => {
          input.onChange(newValue);
          otherProps.onChange?.(newValue, ...args);
        }}
        onFocus={input.onFocus}
      />
    );
  };
}

import { BadRequestError, ValidationError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import {
  DATE_FORMAT_API_DATE,
  DATE_FORMAT_DATE,
  DATE_FORMAT_DATETIME,
} from '_core/dates/formats';
import { createDateRange, isValidDate, parseDate } from '_core/dates/utils';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { DateFilterInPopover } from '_core/filters/date';
import { MonthsMultiSelectFilter } from '_core/filters/monthMultiSelect';
import { YesNoRadioGroupFilter } from '_core/filters/yesNoRadioGroup';
import { finiteNumberOr } from '_core/finiteNumberOr';
import { SearchForm } from '_core/forms/searchForm';
import { Switch } from '_core/inputs/switch';
import { ColumnsSelectorsGroup } from '_core/lists/columnPresets/columnsSelectorsGroup';
import { useColumnPresets } from '_core/lists/columnPresets/useColumnPresets';
import { usePreference } from '_core/me/usePreference';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { EntityLinkWithFilter } from '_core/router5/entityLinkWithFilter';
import { Link } from '_core/router5/link';
import { LinkButton } from '_core/router5/linkButton';
import {
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
} from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { Nl2Br } from '_core/strings/nl2Br';
import { plural } from '_core/strings/utils';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  Button,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverPosition,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { fetchManyPartners } from 'partners/api';
import { PartnersMultiSelectFilter } from 'partners/multiSelectFilter';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { createShipmentInfoItems, ShipmentInfoType } from 'shipmentInfo/api';
import { shipmentInfoTransportationsFieldNameForType } from 'shipmentInfo/utils';
import { ArrDepOperStationsFilterInPopover } from 'stations/arrDepOperFilterInPopover';
import { IStationSerialized } from 'stations/types';
import { SuppliersDocumentsSuggestFilterInPopover } from 'suppliersDocuments/suggestFilterInPopover';
import { fetchManyTechrunRequests } from 'techrunRequests/api';
import { TechrunRequestsMultiSelectFilterInPopover } from 'techrunRequests/multiSelectFilterInPopover';
import { ITechrunRequest } from 'techrunRequests/types';
import {
  fetchTechrunTransportations,
  moveTechrunTransportationsToGroup,
  TechrunTransportationsSortingField,
} from 'techrunTransportations/api';
import {
  allTechrunTransportationAccountingStates,
  getTechrunTransportationAccountingStateLabel,
  getTechrunTransportationGroupLabel,
  getTechrunTransportationStateLabel,
  isTechrunTransportationState,
  ITechrunTransportation,
  TechrunTransportationAccountingState,
  techrunTransportationAccountingStateOptions,
  TechrunTransportationGroup,
  techrunTransportationGroupFromRouteStatus,
  techrunTransportationStateOptions,
  techrunTransportationStatusFromGroup,
} from 'techrunTransportations/types';
import { IsEmptyFilter } from 'transportations/isEmptyFilter';
import { createTransportationDuplicateColorGenerator } from 'transportations/utils';
import { WagonNumbersFilter } from 'wagons/numbersFilter';

import * as css from './list.module.css';

interface ListTableItem
  extends Omit<
    ITechrunTransportation,
    'arrivalStation' | 'departureStation' | 'operationStation' | 'request'
  > {
  arrivalStation: IStationSerialized;
  departureStation: IStationSerialized;
  operationStation: IStationSerialized;
  request: ITechrunRequest | null;
}

const propsToFetch = {
  arrivalStation: '/directories_trainstations',
  departureStation: '/directories_trainstations',
  operationStation: '/directories_trainstations',
  request: '/techrun_requests',
};

export default function TechrunTransportationsListRoute() {
  const { route, router } = useRouteNode('techruns.transportations');
  const api = useApiClient();
  const toaster = useToaster();

  const transportationGroup = techrunTransportationGroupFromRouteStatus(
    route.params.status
  );

  const showBuhClosed = route.params.showBuhClosed === 'True';

  const [favoriteDocs] = usePreference<boolean>(['favoriteDocs']);

  const sorting = useMemo(
    () =>
      sortingFromRouterParam(route.params.sorting) || {
        field: 'created',
        direction: SortingDirection.Descending,
      },
    [route.params.sorting]
  );

  const arrivalMonthsFilterValue = useMemo(() => {
    const param = route.params.arrivalMonths as string | undefined;

    return param
      ? param
          .split(',')
          .map(str => parseDate(str))
          .filter(isValidDate)
      : [];
  }, [route.params.arrivalMonths]);

  const departureMonthsFilterValue = useMemo(() => {
    const param = route.params.departureMonths as string | undefined;

    return param
      ? param
          .split(',')
          .map(str => parseDate(str))
          .filter(isValidDate)
      : [];
  }, [route.params.departureMonths]);

  const transportations = useAsyncData(
    [
      api,
      arrivalMonthsFilterValue,
      departureMonthsFilterValue,
      favoriteDocs,
      route,
      showBuhClosed,
      sorting,
      transportationGroup,
    ],
    async () => {
      const defaultAccountingStates = new Set(
        allTechrunTransportationAccountingStates
      );

      if (transportationGroup === TechrunTransportationGroup.Confirmed) {
        defaultAccountingStates.delete(
          TechrunTransportationAccountingState.BuhClosed
        );
      }

      const accountingStates = route.params.accountingStates
        ? (route.params.accountingStates.split(
            ','
          ) as TechrunTransportationAccountingState[])
        : Array.from(defaultAccountingStates);

      if (showBuhClosed) {
        accountingStates.push(TechrunTransportationAccountingState.BuhClosed);
      }

      const transportationsSorting: Array<
        ISorting<TechrunTransportationsSortingField>
      > = [];

      switch (sorting.field) {
        case 'arrivalDate':
          transportationsSorting.push({
            field: TechrunTransportationsSortingField.ArrivalDate,
            direction: sorting.direction,
          });
          break;
        case 'created':
          transportationsSorting.push({
            field: TechrunTransportationsSortingField.Created,
            direction: sorting.direction,
          });
          break;
        case 'departureDate':
          transportationsSorting.push({
            field: TechrunTransportationsSortingField.DepartureDate,
            direction: sorting.direction,
          });
          break;
        case 'state':
          transportationsSorting.push({
            field: TechrunTransportationsSortingField.State,
            direction: sorting.direction,
          });
          break;
        default:
          throw new Error(`Unexpected sorting field: "${sorting.field}"`);
      }

      const { meta, results } = await fetchTechrunTransportations(api, {
        accountingStates,
        arrivalBefore: route.params.arrivalBefore
          ? parseDate(route.params.arrivalBefore)
          : undefined,
        arrivalMonths: arrivalMonthsFilterValue,
        arrivalStation: isFinite(route.params.arrivalStation)
          ? Number(route.params.arrivalStation)
          : undefined,
        consignee: isFinite(route.params.consignee)
          ? Number(route.params.consignee)
          : undefined,
        countExpenses:
          transportationGroup === TechrunTransportationGroup.Supplementary
            ? route.params.countExpenses === true
            : undefined,
        departureBefore: route.params.departureBefore
          ? parseDate(route.params.departureBefore)
          : undefined,
        departureMonth: route.params.departureMonth
          ? parseDate(route.params.departureMonth)
          : undefined,
        departureMonths: departureMonthsFilterValue,
        departureStation: isFinite(route.params.departureStation)
          ? Number(route.params.departureStation)
          : undefined,
        favoriteTransps: favoriteDocs,
        group: transportationGroup,
        hasSuppliersDocs:
          route.params.hasSuppliersDocs === 'True'
            ? true
            : route.params.hasSuppliersDocs === 'False'
            ? false
            : undefined,
        isEmpty:
          route.params.isEmpty === 'True'
            ? true
            : route.params.isEmpty === 'False'
            ? false
            : undefined,
        operationStation: isFinite(route.params.operationStation)
          ? Number(route.params.operationStation)
          : undefined,
        page: isFinite(route.params.page)
          ? Number(route.params.page)
          : undefined,
        pageSize: isFinite(route.params.pageSize)
          ? Number(route.params.pageSize)
          : undefined,
        partners: route.params.partners
          ? (route.params.partners as string).split(',').map(Number)
          : undefined,
        requests: route.params.requests
          ? (route.params.requests as string).split(',').map(Number)
          : undefined,
        search: route.params.search,
        sorting: transportationsSorting,
        states: route.params.states
          ? (route.params.states as string)
              .split(',')
              .filter(isTechrunTransportationState)
          : undefined,
        tariffSupplierDocument: isFinite(route.params.tariffSupplierDocument)
          ? Number(route.params.tariffSupplierDocument)
          : undefined,
        wagonNumbers: route.params.wagonNumbers
          ? (route.params.wagonNumbers as string).split(',')
          : undefined,
      });

      const arrivalDate: [Date, Date] = [
        parseDate(meta.filterOptions.arrivalDate[0]),
        parseDate(meta.filterOptions.arrivalDate[1]),
      ];

      const departureDate: [Date, Date] = [
        parseDate(meta.filterOptions.departureDate[0]),
        parseDate(meta.filterOptions.departureDate[1]),
      ];

      const [items, partners, requests] = await Promise.all([
        await (fetchRelated(api, propsToFetch, results) as unknown as Promise<
          ListTableItem[]
        >),
        fetchManyPartners(api, meta.filterOptions.partners),
        fetchManyTechrunRequests(api, meta.filterOptions.requests),
      ]);

      return {
        currentPage: meta.currentPage,
        filterOptions: { arrivalDate, departureDate, partners, requests },
        items,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
      };
    }
  );

  const listParams = JSON.stringify(route.params);

  const [submitting, setSubmitting] = useState(false);

  const [selectedTransportations, setSelectedTransportations] = useState<
    string[]
  >([]);

  const selectedTransportationsCount = selectedTransportations.length;

  const batchActionsDisabled =
    transportations.isFetching ||
    selectedTransportationsCount === 0 ||
    submitting;

  const closedItemsAreSelected = useMemo(
    () =>
      transportations.data
        ? transportations.data.items.some(
            item =>
              item.accountingState !==
                TechrunTransportationAccountingState.NotClosed &&
              selectedTransportations.includes(String(item.id))
          )
        : false,
    [selectedTransportations, transportations.data]
  );

  async function wrapBatchActionPromise(promise: Promise<unknown>) {
    setSubmitting(true);

    try {
      await promise;
      transportations.refetch();
    } catch (err) {
      if (err instanceof BadRequestError) {
        toaster.show({
          icon: 'error',
          intent: Intent.DANGER,
          message: err.message,
        });
      } else {
        toaster.show({
          icon: 'error',
          intent: Intent.DANGER,
          message: 'Что-то пошло не так',
        });

        throw err;
      }
    } finally {
      setSubmitting(false);
    }
  }

  const columns = useMemo(
    (): Array<ListTableColumn<ListTableItem>> => [
      {
        id: 'waybillNumber',
        label: 'Накладная',
        defaultWidth: 95,
        copyCellContent: transportation => transportation.waybillNumber,
        renderCellContent: transportation => (
          <div className={css.cellWithIconWrapper}>
            <Ellipsis
              component={Link}
              params={{
                id: transportation.id,
                listParams,
                status: techrunTransportationStatusFromGroup(
                  transportation.group
                ),
              }}
              rel="noopener"
              target="_blank"
              to="techruns.transportations.edit"
            >
              {transportation.waybillNumber || '<отсутствует>'}
            </Ellipsis>

            {transportation.isWaybillNumberChanged && (
              <Tooltip
                content="Изменился номер накладной"
                intent={Intent.WARNING}
                lazy
                position={PopoverPosition.BOTTOM}
              >
                <Icon
                  className={css.cellIcon}
                  icon="warning-sign"
                  intent={Intent.WARNING}
                />
              </Tooltip>
            )}

            {transportation.isDepartureDateChanged && (
              <Tooltip
                content={`Дата отправления изменилась с ${dayjs(
                  transportation.departureDate
                ).format(DATE_FORMAT_DATE)} на ${dayjs(
                  transportation.departureDateNext
                ).format(DATE_FORMAT_DATE)}`}
                intent={Intent.WARNING}
                lazy
                position={PopoverPosition.BOTTOM}
              >
                <Icon
                  className={css.cellIcon}
                  icon="warning-sign"
                  intent={Intent.WARNING}
                />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        id: 'wagon',
        label: 'Вагон',
        defaultWidth: 95,
        copyCellContent: transportation => transportation.wagon,
        renderCellContent: transportation => (
          <Ellipsis
            component={Link}
            params={{ number: transportation.wagon }}
            rel="noopener"
            target="_blank"
            to="wagons.edit"
          >
            {transportation.wagon}
          </Ellipsis>
        ),
      },
      {
        id: 'request',
        label: 'Заявка',
        defaultWidth: 240,
        copyCellContent: transportation =>
          transportation.request ? transportation.request.requestName : '',
        renderCellContent: transportation =>
          transportation.request && route ? (
            <EntityLinkWithFilter
              entityLink={{
                params: { id: String(transportation.request.id) },
                to: 'techruns.requests.view',
              }}
              filterLink={{
                params: {
                  ...route.params,
                  page: undefined,
                  requests: String(transportation.request.id),
                },
                to: route.name,
              }}
              text={transportation.request.requestName}
            />
          ) : null,
      },
      {
        id: 'supplierDocumentNumber',
        label: 'Счёт-фактура',
        defaultWidth: 230,
        copyCellContent: transportation =>
          transportation.supplierDocumentNumber
            ? transportation.supplierDocumentNumber.number
            : '',
        renderCellContent: transportation =>
          transportation.supplierDocumentNumber ? (
            <EntityLinkWithFilter
              entityLink={{
                params: {
                  id: String(transportation.supplierDocumentNumber.id),
                },
                to: 'suppliersDocumentsFromFile.edit',
              }}
              filterLink={{
                params: {
                  ...route.params,
                  page: undefined,
                  tariffSupplierDocument: String(
                    transportation.supplierDocumentNumber.id
                  ),
                },
                to: route.name,
              }}
              text={
                transportation.supplierDocumentNumber.number ||
                '<номер не указан>'
              }
            />
          ) : null,
      },
      {
        id: 'departureStation',
        label: 'Станция отправления',
        defaultWidth: 180,
        copyCellContent: transportation =>
          transportation.departureStation
            ? transportation.departureStation.name
            : '',
        renderCellContent: transportation =>
          transportation.departureStation ? (
            <Ellipsis
              component={Link}
              params={{ ids: String(transportation.departureStation.id) }}
              rel="noopener"
              target="_blank"
              to="stations"
            >
              {transportation.departureStation.name}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'consigner',
        label: 'Грузоотправитель',
        defaultWidth: 150,
        copyCellContent: transportation => transportation.consignerShort,
        renderCellContent: transportation => {
          const isWarningShown = transportation.isConsignerSame === false;

          return (
            <div className={css.cellWithIconWrapper}>
              <Ellipsis component="span">
                {transportation.consignerShort || '<отсутствует>'}
              </Ellipsis>

              {isWarningShown ? (
                <Tooltip
                  content="Не совпадает грузоотправитель в заявке и дислокации"
                  intent={Intent.WARNING}
                  lazy
                  position={PopoverPosition.BOTTOM}
                >
                  <Icon
                    className={css.cellIcon}
                    icon="warning-sign"
                    intent={Intent.WARNING}
                  />
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
      {
        id: 'operationStation',
        label: 'Станция операции',
        defaultWidth: 180,
        copyCellContent: transportation =>
          transportation.operationStation
            ? transportation.operationStation.name
            : '',
        renderCellContent: transportation =>
          transportation.operationStation ? (
            <Ellipsis
              component={Link}
              params={{ ids: String(transportation.operationStation.id) }}
              rel="noopener"
              target="_blank"
              to="stations"
            >
              {transportation.operationStation.name}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'arrivalStation',
        label: 'Станция назначения',
        defaultWidth: 180,
        copyCellContent: transportation =>
          transportation.arrivalStation
            ? transportation.arrivalStation.name
            : '',
        renderCellContent: transportation =>
          transportation.arrivalStation ? (
            <Ellipsis
              component={Link}
              params={{ ids: String(transportation.arrivalStation.id) }}
              rel="noopener"
              target="_blank"
              to="stations"
            >
              {transportation.arrivalStation.name}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'state',
        label: 'Состояние',
        defaultWidth: 100,
        sortable: true,
        copyCellContent: transportation =>
          getTechrunTransportationStateLabel(transportation.state),
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {getTechrunTransportationStateLabel(transportation.state)}
          </Ellipsis>
        ),
      },
      {
        id: 'accountingState',
        label: 'Бух. состояние',
        defaultWidth: 100,
        copyCellContent: transportation =>
          getTechrunTransportationAccountingStateLabel(
            transportation.accountingState
          ),
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {getTechrunTransportationAccountingStateLabel(
              transportation.accountingState
            )}
          </Ellipsis>
        ),
      },
      {
        id: 'isEmpty',
        label: 'Тип отправки',
        defaultWidth: 90,
        copyCellContent: transportation =>
          transportation.isEmpty ? 'Порожняя' : 'Гружёная',
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {transportation.isEmpty ? 'Порожняя' : 'Гружёная'}
          </Ellipsis>
        ),
      },
      {
        id: 'note',
        label: 'Заметка',
        defaultWidth: 200,
        copyCellContent: transportation => transportation.note,
        renderCellContent: transportation => (
          <Ellipsis component="span">{transportation.note}</Ellipsis>
        ),
      },
      {
        id: 'arrivalBeforeLoad',
        label: 'Погрузка',
        defaultWidth: 152,
        copyCellContent: transportation =>
          transportation.arrivalBeforeLoad
            ? dayjs(transportation.arrivalBeforeLoad).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: transportation =>
          transportation.arrivalBeforeLoad ? (
            <Ellipsis component="span">
              {dayjs(transportation.arrivalBeforeLoad).format(DATE_FORMAT_DATE)}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'departureDate',
        label: 'Отправка',
        sortable: true,
        defaultWidth: 112,
        copyCellContent: transportation =>
          dayjs(transportation.departureDate).format(DATE_FORMAT_DATE),
        renderCellContent: transportation => (
          <Ellipsis component="span">
            {dayjs(transportation.departureDate).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'arrivalDate',
        label: 'Прибытие',
        sortable: true,
        defaultWidth: 117,
        copyCellContent: transportation =>
          transportation.arrivalDate
            ? dayjs(transportation.arrivalDate).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: transportation =>
          transportation.arrivalDate ? (
            <Ellipsis component="span">
              {dayjs(transportation.arrivalDate).format(DATE_FORMAT_DATE)}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'created',
        label: 'Создана',
        sortable: true,
        defaultWidth: 120,
        copyCellContent: transportation =>
          transportation.created
            ? dayjs(transportation.created).format(DATE_FORMAT_DATETIME)
            : '',
        renderCellContent: transportation =>
          transportation.created ? (
            <Ellipsis component="span">
              {dayjs(transportation.created).format(DATE_FORMAT_DATETIME)}
            </Ellipsis>
          ) : null,
      },
      {
        id: 'id',
        label: 'ID',
        defaultWidth: 95,
        copyCellContent: transportation => String(transportation.id),
        renderCellContent: transportation => (
          <Ellipsis component="span">{String(transportation.id)}</Ellipsis>
        ),
      },
    ],
    [listParams, route]
  );

  const allColumnIds = columns
    .map(column => column.id)
    .filter(columnId => {
      switch (columnId) {
        case 'supplierDocumentNumber':
          return [
            TechrunTransportationGroup.Confirmed,
            TechrunTransportationGroup.Supplementary,
          ].includes(transportationGroup);
        case 'accountingState':
          return transportationGroup !== TechrunTransportationGroup.New;
        case 'arrivalBeforeLoad':
          return transportationGroup === TechrunTransportationGroup.New;
        default:
          return true;
      }
    });

  const columnLabels = columns.reduce<Record<string, string>>(
    (labels, column) => {
      labels[column.id] = column.label;

      return labels;
    },

    {}
  );

  const columnPresets = useColumnPresets(
    ['techruns', 'transportations', 'list'],
    {
      allColumnIds,
      alwaysShownColumnIds: ['waybillNumber'],
      getColumnLabel: columnId => columnLabels[columnId],
    }
  );

  const groupsAllowedToMoveTo = useMemo(() => {
    const selectedTransportationItems = transportations.data?.items.filter(
      transportation =>
        selectedTransportations.includes(String(transportation.id))
    );

    return !selectedTransportationItems ||
      selectedTransportationItems.length === 0
      ? null
      : Object.values(TechrunTransportationGroup).filter(group =>
          selectedTransportationItems.every(transp =>
            transp.groupsTransitionRoute.includes(group)
          )
        );
  }, [selectedTransportations, transportations.data?.items]);

  const getItemId = useCallback((item: ListTableItem) => String(item.id), []);

  const getRowColor = useMemo(
    () =>
      transportationGroup === TechrunTransportationGroup.New
        ? createTransportationDuplicateColorGenerator()
        : undefined,
    [transportationGroup]
  );

  const showColumns = useMemo(
    () =>
      transportations.data
        ? transportations.data.items.some(item => Boolean(item.note))
          ? columnPresets.showColumns
          : columnPresets.showColumns.filter(id => id !== 'note')
        : undefined,
    [columnPresets.showColumns, transportations.data]
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, boolean | string | undefined>) => {
      router.navigate(route.name, {
        ...route.params,
        page: undefined,
        ...filterParams,
      });
    },
    [route, router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const arrivalMonthsFilterDates = useMemo(
    () =>
      transportations.data
        ? createDateRange(
            'month',
            transportations.data.filterOptions.arrivalDate[0],
            dayjs(transportations.data.filterOptions.arrivalDate[1])
              .add(1, 'month')
              .toDate()
          )
        : [],
    [transportations.data]
  );

  const departureMonthsFilterDates = useMemo(
    () =>
      transportations.data
        ? createDateRange(
            'month',
            transportations.data.filterOptions.departureDate[0],
            dayjs(transportations.data.filterOptions.departureDate[1])
              .add(1, 'month')
              .toDate()
          )
        : [],
    [transportations.data]
  );

  return (
    <>
      <FormattedTitle>
        {getTechrunTransportationGroupLabel(transportationGroup)} отправки
        техрейса
      </FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <WagonNumbersFilter
                  initialValue={route.params.wagonNumbers}
                  onApply={wagonNumbers => {
                    applyFilterParams({ wagonNumbers });
                  }}
                />

                <PartnersMultiSelectFilter
                  initialValue={route.params.partners}
                  isFetching={transportations.isFetching}
                  items={
                    transportations.data
                      ? transportations.data.filterOptions.partners
                      : []
                  }
                  label="Клиенты"
                  onApply={partners => {
                    applyFilterParams({ partners });
                  }}
                />

                <TechrunRequestsMultiSelectFilterInPopover
                  initialValue={route.params.requests}
                  isFetching={transportations.isFetching}
                  items={
                    transportations.data
                      ? transportations.data.filterOptions.requests
                      : []
                  }
                  onApply={requests => {
                    applyFilterParams({ requests });
                  }}
                />

                <SuppliersDocumentsSuggestFilterInPopover
                  initialValue={route.params.tariffSupplierDocument}
                  onApply={tariffSupplierDocument => {
                    applyFilterParams({ tariffSupplierDocument });
                  }}
                />

                <IsEmptyFilter
                  initialValue={route.params.isEmpty}
                  label="Тип отправки"
                  onApply={isEmpty => {
                    applyFilterParams({ isEmpty });
                  }}
                />

                <CheckboxListFilter
                  label="Состояния"
                  initialValue={route.params.states}
                  options={techrunTransportationStateOptions}
                  onApply={states => {
                    applyFilterParams({ states });
                  }}
                />

                <CheckboxListFilter
                  label="Бух. состояния"
                  initialValue={route.params.accountingStates}
                  options={
                    transportationGroup === TechrunTransportationGroup.Confirmed
                      ? techrunTransportationAccountingStateOptions.filter(
                          option =>
                            option.value !==
                            TechrunTransportationAccountingState.BuhClosed
                        )
                      : techrunTransportationAccountingStateOptions
                  }
                  onApply={accountingStates => {
                    applyFilterParams({ accountingStates });
                  }}
                />

                <ArrDepOperStationsFilterInPopover
                  initialValue={{
                    arrivalStation: finiteNumberOr(
                      undefined,
                      route.params.arrivalStation
                    ),
                    departureStation: finiteNumberOr(
                      undefined,
                      route.params.departureStation
                    ),
                    operationStation: finiteNumberOr(
                      undefined,
                      route.params.operationStation
                    ),
                    consignee: route.params.consignee,
                  }}
                  showConsigneeFilter
                  onApply={({
                    arrivalStation,
                    consignee,
                    departureStation,
                    operationStation,
                  }) => {
                    applyFilterParams({
                      arrivalStation:
                        (arrivalStation && String(arrivalStation)) || undefined,

                      consignee,

                      departureStation:
                        (departureStation && String(departureStation)) ||
                        undefined,

                      operationStation:
                        (operationStation && String(operationStation)) ||
                        undefined,
                    });
                  }}
                />

                <LinkButton
                  options={{ reload: true }}
                  params={route.params}
                  text="Обновить"
                  to={route.name}
                />

                <LinkButton
                  params={{ status: route.params.status }}
                  text="Сбросить фильтры"
                  to={route.name}
                />
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Grid>
            <Col span={9}>
              <Toolbar valign="center">
                <span>
                  {plural(selectedTransportationsCount, [
                    () => `Выбрана ${selectedTransportationsCount} отправка`,
                    () => `Выбрано ${selectedTransportationsCount} отправки`,
                    () => `Выбрано ${selectedTransportationsCount} отправок`,
                  ])}
                  :
                </span>

                <span>Накладная:</span>

                <Button
                  disabled={batchActionsDisabled}
                  icon="confirm"
                  intent={Intent.PRIMARY}
                  minimal
                  onClick={() => {
                    wrapBatchActionPromise(
                      api
                        .post<ITechrunTransportation[]>(
                          '/techrun_transportations/change_active_dislocation',
                          { confirm: true },

                          {
                            query: {
                              ids: selectedTransportations.join(','),
                            },
                          }
                        )
                        .then(changedTransportations => {
                          const nChanged = changedTransportations.length;

                          toaster.show({
                            intent: Intent.SUCCESS,
                            message: plural(nChanged, [
                              () =>
                                `Изменён номер накладной в ${nChanged} отправке из ${selectedTransportationsCount}`,
                              () =>
                                `Изменён номер накладной в ${nChanged} отправках из ${selectedTransportationsCount}`,
                              () =>
                                `Изменён номер накладной в ${nChanged} отправках из ${selectedTransportationsCount}`,
                            ]),
                          });
                        })
                    );
                  }}
                />

                <Button
                  disabled={batchActionsDisabled}
                  icon="delete"
                  intent={Intent.DANGER}
                  minimal
                  onClick={() => {
                    wrapBatchActionPromise(
                      api
                        .post<ITechrunTransportation[]>(
                          '/techrun_transportations/change_active_dislocation',
                          { confirm: false },

                          {
                            query: {
                              ids: selectedTransportations.join(','),
                            },
                          }
                        )
                        .then(changedTransportations => {
                          const nChanged = changedTransportations.length;

                          toaster.show({
                            intent: Intent.SUCCESS,
                            message: plural(nChanged, [
                              () =>
                                `Изменён номер накладной в ${nChanged} отправке из ${selectedTransportationsCount}`,
                              () =>
                                `Изменён номер накладной в ${nChanged} отправках из ${selectedTransportationsCount}`,
                              () =>
                                `Изменён номер накладной в ${nChanged} отправках из ${selectedTransportationsCount}`,
                            ]),
                          });
                        })
                    );
                  }}
                />

                <span>Дата отправки:</span>

                <Button
                  disabled={batchActionsDisabled}
                  icon="confirm"
                  intent={Intent.PRIMARY}
                  minimal
                  onClick={() => {
                    wrapBatchActionPromise(
                      api
                        .post<ITechrunTransportation[]>(
                          '/techrun_transportations/change_departure_date',
                          { confirm: true },

                          {
                            query: {
                              ids: selectedTransportations.join(','),
                            },
                          }
                        )
                        .then(changedTransportations => {
                          const nChanged = changedTransportations.length;

                          toaster.show({
                            intent: Intent.SUCCESS,
                            message: plural(nChanged, [
                              () =>
                                `Изменена дата отправки в ${nChanged} отправке из ${selectedTransportationsCount}`,
                              () =>
                                `Изменена дата отправки в ${nChanged} отправках из ${selectedTransportationsCount}`,
                              () =>
                                `Изменена дата отправки в ${nChanged} отправках из ${selectedTransportationsCount}`,
                            ]),
                          });
                        })
                    );
                  }}
                />

                <Button
                  disabled={batchActionsDisabled}
                  icon="delete"
                  intent={Intent.DANGER}
                  minimal
                  onClick={() => {
                    wrapBatchActionPromise(
                      api
                        .post<ITechrunTransportation[]>(
                          '/techrun_transportations/change_departure_date',
                          { confirm: false },

                          {
                            query: {
                              ids: selectedTransportations.join(','),
                            },
                          }
                        )
                        .then(changedTransportations => {
                          const nChanged = changedTransportations.length;

                          toaster.show({
                            intent: Intent.SUCCESS,
                            message: plural(nChanged, [
                              () =>
                                `Изменена дата отправки в ${nChanged} отправке из ${selectedTransportationsCount}`,
                              () =>
                                `Изменена дата отправки в ${nChanged} отправках из ${selectedTransportationsCount}`,
                              () =>
                                `Изменена дата отправки в ${nChanged} отправках из ${selectedTransportationsCount}`,
                            ]),
                          });
                        })
                    );
                  }}
                />

                <Popover
                  content={
                    groupsAllowedToMoveTo ? (
                      <Menu>
                        {groupsAllowedToMoveTo.map(group => (
                          <MenuItem
                            key={group}
                            text={getTechrunTransportationGroupLabel(group)}
                            onClick={() => {
                              wrapBatchActionPromise(
                                moveTechrunTransportationsToGroup(
                                  api,
                                  selectedTransportations.map(Number),
                                  group
                                )
                              );
                            }}
                          />
                        ))}
                      </Menu>
                    ) : undefined
                  }
                  disabled={
                    batchActionsDisabled || groupsAllowedToMoveTo == null
                  }
                  position={Position.BOTTOM_RIGHT}
                >
                  <Button
                    disabled={
                      batchActionsDisabled || groupsAllowedToMoveTo == null
                    }
                    rightIcon="caret-down"
                    text="Переместить"
                  />
                </Popover>

                {transportationGroup ===
                  TechrunTransportationGroup.Confirmed && (
                  <Button
                    disabled={batchActionsDisabled || closedItemsAreSelected}
                    intent={Intent.SUCCESS}
                    text="Закрыть"
                    onClick={async () => {
                      setSubmitting(true);

                      try {
                        const shipmentInfoItems = await createShipmentInfoItems(
                          api,
                          {
                            [shipmentInfoTransportationsFieldNameForType(
                              ShipmentInfoType.Techrun
                            )]: selectedTransportations.map(Number),
                          }
                        );

                        router.navigate('shipmentInfo', {
                          ids: shipmentInfoItems.map(item => item.id),
                        });
                      } catch (err) {
                        setSubmitting(false);

                        if (err instanceof BadRequestError) {
                          toaster.show({
                            icon: 'error',
                            intent: Intent.DANGER,
                            message: err.message,
                          });
                        } else if (
                          err instanceof ValidationError &&
                          err.formError.length !== 0
                        ) {
                          toaster.show({
                            icon: 'error',
                            intent: Intent.DANGER,
                            message: <Nl2Br text={err.formError.join('\n')} />,
                          });
                        } else {
                          toaster.show({
                            icon: 'error',
                            intent: Intent.DANGER,
                            message: 'Что-то пошло не так',
                          });

                          throw err;
                        }
                      }
                    }}
                  />
                )}
              </Toolbar>
            </Col>

            <Col span={3}>
              <Toolbar align="right">
                {transportationGroup ===
                  TechrunTransportationGroup.Confirmed && (
                  <Switch
                    checked={showBuhClosed}
                    label="Показать закрытые бух. документами"
                    onChange={newShowBuhClosed => {
                      applyFilterParams({
                        showBuhClosed: newShowBuhClosed ? 'True' : undefined,
                      });
                    }}
                  />
                )}
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        {transportationGroup === TechrunTransportationGroup.Confirmed && (
          <Row>
            <Grid>
              <Col span={6}>
                <Toolbar>
                  <MonthsMultiSelectFilter
                    initialValue={arrivalMonthsFilterValue}
                    isFetching={transportations.isFetching}
                    label="Месяц прибытия"
                    monthDates={arrivalMonthsFilterDates}
                    onApply={newArrivalMonths => {
                      applyFilterParams({
                        arrivalMonths:
                          newArrivalMonths.length !== 0
                            ? newArrivalMonths
                                .map(date =>
                                  dayjs(date).format(DATE_FORMAT_API_DATE)
                                )
                                .join(',')
                            : undefined,
                      });
                    }}
                  />

                  <DateFilterInPopover
                    label="Дата прибытия до"
                    initialValue={route.params.arrivalBefore}
                    onApply={arrivalBefore => {
                      applyFilterParams({ arrivalBefore });
                    }}
                  />
                </Toolbar>
              </Col>

              <Col span={6}>
                <Toolbar align="right">
                  <MonthsMultiSelectFilter
                    initialValue={departureMonthsFilterValue}
                    isFetching={transportations.isFetching}
                    label="Месяц отправки"
                    monthDates={departureMonthsFilterDates}
                    onApply={newDepartureMonths => {
                      applyFilterParams({
                        departureMonths:
                          newDepartureMonths.length !== 0
                            ? newDepartureMonths
                                .map(date =>
                                  dayjs(date).format(DATE_FORMAT_API_DATE)
                                )
                                .join(',')
                            : undefined,
                      });
                    }}
                  />

                  <DateFilterInPopover
                    label="Дата отправки до"
                    initialValue={route.params.departureBefore}
                    onApply={departureBefore => {
                      applyFilterParams({ departureBefore });
                    }}
                  />
                </Toolbar>
              </Col>
            </Grid>
          </Row>
        )}

        <Row>
          <Grid>
            <Col span={6}>
              {[
                TechrunTransportationGroup.Confirmed,
                TechrunTransportationGroup.Supplementary,
              ].includes(transportationGroup) && (
                <YesNoRadioGroupFilter
                  label="Привязана счёт-фактура"
                  value={route.params.hasSuppliersDocs}
                  onChange={hasSuppliersDocs => {
                    applyFilterParams({ hasSuppliersDocs });
                  }}
                />
              )}
            </Col>

            <Col span={6}>
              <Toolbar align="right">
                {transportationGroup ===
                  TechrunTransportationGroup.Supplementary && (
                  <Switch
                    checked={route.params.countExpenses === true}
                    label="Показывать отправки без расходов"
                    onChange={countExpenses => {
                      applyFilterParams({
                        countExpenses: countExpenses || undefined,
                      });
                    }}
                  />
                )}
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <ColumnsSelectorsGroup
            columnOptions={columnPresets.columnOptions}
            defaultValue={columnPresets.defaultValue}
            initialValue={columnPresets.initialValue}
            preset={columnPresets.activePreset}
            onApply={columnPresets.onShowColumnsApply}
            onColumnPresetChange={columnPresets.onColumnPresetChange}
          />
        </Row>

        <Row stretch>
          {!transportations.data ? (
            transportations.isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : transportations.data.items.length === 0 ? (
            <EmptyListMessage />
          ) : (
            <ListTable
              columns={columns}
              columnWidths={columnPresets.columnWidths}
              getItemId={getItemId}
              getRowColor={getRowColor}
              isFetching={transportations.isFetching}
              items={transportations.data.items}
              lineNumbersStart={
                transportations.data.pageSize *
                  (transportations.data.currentPage - 1) +
                1
              }
              selectedItems={selectedTransportations}
              showColumns={showColumns}
              sorting={sorting}
              stickyColumnCount={4}
              onColumnWidthChanged={columnPresets.onColumnWidthChanged}
              onSelectedItemsChange={setSelectedTransportations}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>

        {transportations.data && (
          <Row>
            <Pagination
              pageSize={transportations.data.pageSize}
              totalPages={transportations.data.totalPages}
            />
          </Row>
        )}
      </VGrid>
    </>
  );
}

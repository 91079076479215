import { useApiClient } from '_core/api/context';
import { ListResponse } from '_core/api/types';
import { sumMaybeDecimals } from '_core/decimal';
import { TableFieldErrors } from '_core/forms/tableFieldErrors';
import { InputMoney } from '_core/inputs/inputMoney';
import { InputWithSuggest } from '_core/inputs/inputWithSuggest';
import { Select } from '_core/inputs/select';
import { isNotNull } from '_core/isNotNull';
import { formatMoney } from '_core/money/formatMoney';
import { BaseCell, CellEditor } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { createTableCellEditor } from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { Toolbar } from '_core/toolbar';
import { Button, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import { Row, VGrid } from 'layout/contentLayout';
import { IPartnerSerialized } from 'partners/api';
import { PartnersAutocompleteInFormGroup } from 'partners/autocomplete';
import * as React from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { SuppliersDocumentsFromFileAutocompleteInFormGroup } from 'suppliersDocuments/autocomplete';
import { ISuppliersDocumentSerialized } from 'suppliersDocuments/types';
import { ITransportationExpense } from 'transportations/types';
import {
  costFromTotalCostForForm,
  totalCostForForm,
  VatRate,
  vatRateLabel,
  vatRateOptions,
  vatValueForForm,
  vatValueFromTotalCostForForm,
} from 'vatRates/vatRates';

import * as css from './expensesField.module.css';

export interface IExpeditionTransportationExpensesFieldItem {
  cost: string;
  costTotal: string;
  costVatValue: string;
  meta: 'rate' | 'supplier_expense' | 'tariff' | undefined;
  name: string;
  partner: IPartnerSerialized | null;
  supplierDocument: ISuppliersDocumentSerialized | null;
  vatRate: VatRate;
}

type TableItem =
  | {
      kind: 'expense';
      expense: IExpeditionTransportationExpensesFieldItem;
      index: number;
    }
  | {
      kind: 'total';
      cost: string;
      costVatValue: string;
      costTotal: string;
    };

interface IProps {
  change: (field: string, value: unknown) => void;
  name: string;
}

export function ExpeditionTransportationExpensesField({
  change,
  name,
}: IProps) {
  const { fields, meta } =
    useFieldArray<IExpeditionTransportationExpensesFieldItem>(name);

  const api = useApiClient();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitting = (meta as any).submitting as boolean;

  const columns = React.useMemo(
    (): Array<ListTableColumn<TableItem>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 250,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          item.kind === 'total' ? 'Итого' : item.expense.name,
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {item.kind === 'total' ? 'Итого' : item.expense.name}
          </Ellipsis>
        ),
        getEditor: item =>
          item.kind === 'total' ||
          (item.expense.meta != null &&
            ['rate', 'tariff'].includes(item.expense.meta))
            ? undefined
            : createTableCellEditor({
                initialValue: item.expense.name,
                applyValue: newName => {
                  change(`${fields.name}[${item.index}].name`, newName);
                },
                render: ({ cancelEditing, style, value, onChange }) => (
                  <CellEditor style={style}>
                    <InputWithSuggest<ITransportationExpense>
                      autoFocus
                      fetchSuggestions={async query => {
                        const response = await api.get<
                          ListResponse<ITransportationExpense>
                        >('/directories_transportation_expenses', {
                          pageSize: 10,
                          search: query,
                        });

                        return response.results;
                      }}
                      getSuggestionLabel={expense => expense.name}
                      value={value}
                      onChange={onChange}
                      onSuggestionSelect={expense => {
                        change(
                          `${fields.name}[${item.index}].name`,
                          expense.name
                        );
                        change(
                          `${fields.name}[${item.index}].cost`,
                          expense.cost
                        );
                        change(
                          `${fields.name}[${item.index}].vatRate`,
                          expense.vatRate
                        );
                        change(
                          `${fields.name}[${item.index}].costVatValue`,
                          expense.costVatValue
                        );
                        change(
                          `${fields.name}[${item.index}].costTotal`,
                          expense.costTotal
                        );
                        cancelEditing();
                      }}
                    />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'cost',
        label: 'Стоимость',
        defaultWidth: 150,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          formatMoney(item.kind === 'total' ? item.cost : item.expense.cost),
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {formatMoney(item.kind === 'total' ? item.cost : item.expense.cost)}
          </Ellipsis>
        ),
        getEditor: item =>
          item.kind === 'total' ||
          (item.expense.meta != null &&
            ['rate', 'supplier_expense', 'tariff'].includes(item.expense.meta))
            ? undefined
            : createTableCellEditor({
                initialValue: item.expense.cost,
                applyValue: newCost => {
                  change(`${fields.name}[${item.index}].cost`, newCost);
                  change(
                    `${fields.name}[${item.index}].costVatValue`,
                    vatValueForForm(newCost, item.expense.vatRate)
                  );
                  change(
                    `${fields.name}[${item.index}].costTotal`,
                    totalCostForForm(newCost, item.expense.vatRate)
                  );
                },
                render: ({ style, value, onChange }) => (
                  <CellEditor style={style}>
                    <InputMoney autoFocus value={value} onChange={onChange} />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'costVatValue',
        label: 'НДС (руб.)',
        defaultWidth: 150,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          formatMoney(
            item.kind === 'total'
              ? item.costVatValue
              : item.expense.costVatValue
          ),
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {formatMoney(
              item.kind === 'total'
                ? item.costVatValue
                : item.expense.costVatValue
            )}
          </Ellipsis>
        ),
      },
      {
        id: 'vatRate',
        label: 'Ставка НДС',
        defaultWidth: 150,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          item.kind === 'total' ? '' : vatRateLabel(item.expense.vatRate),
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {item.kind === 'total' ? '' : vatRateLabel(item.expense.vatRate)}
          </Ellipsis>
        ),
        getEditor: item =>
          item.kind === 'total' || Boolean(item.expense.meta)
            ? undefined
            : createTableCellEditor({
                initialValue: item.expense.vatRate,
                applyValue: newVatRate => {
                  change(`${fields.name}[${item.index}].vatRate`, newVatRate);
                  change(
                    `${fields.name}[${item.index}].costVatValue`,
                    vatValueForForm(item.expense.cost, newVatRate)
                  );
                  change(
                    `${fields.name}[${item.index}].costTotal`,
                    totalCostForForm(item.expense.cost, newVatRate)
                  );
                },
                render: ({ style, value, onChange }) => (
                  <CellEditor style={style}>
                    <Select
                      autoFocus
                      fill
                      options={vatRateOptions}
                      value={value}
                      onChange={onChange}
                    />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'costTotal',
        label: 'Стоимость (в т.ч. НДС)',
        defaultWidth: 200,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          formatMoney(
            item.kind === 'total' ? item.costTotal : item.expense.costTotal
          ),
        renderCellContent: item => (
          <Ellipsis
            className={cx({ [css.boldCellText]: item.kind === 'total' })}
            component="span"
          >
            {formatMoney(
              item.kind === 'total' ? item.costTotal : item.expense.costTotal
            )}
          </Ellipsis>
        ),
        getEditor: item =>
          item.kind === 'total' ||
          (item.expense.meta != null &&
            ['rate', 'supplier_expense', 'tariff'].includes(item.expense.meta))
            ? undefined
            : createTableCellEditor({
                initialValue: item.expense.costTotal,
                applyValue: newCostTotal => {
                  change(
                    `${fields.name}[${item.index}].costTotal`,
                    newCostTotal
                  );
                  change(
                    `${fields.name}[${item.index}].costVatValue`,
                    vatValueFromTotalCostForForm(
                      newCostTotal,
                      item.expense.vatRate
                    )
                  );
                  change(
                    `${fields.name}[${item.index}].cost`,
                    costFromTotalCostForForm(newCostTotal, item.expense.vatRate)
                  );
                },
                render: ({ style, value, onChange }) => (
                  <CellEditor style={style}>
                    <InputMoney autoFocus value={value} onChange={onChange} />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'partner',
        label: 'Контрагент',
        defaultWidth: 250,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          item.kind === 'total' ? '' : item.expense.partner?.shortName ?? '',
        renderCellContent: item =>
          item.kind === 'total' || item.expense.partner == null ? null : (
            <Ellipsis
              component={Link}
              params={{ id: item.expense.partner.id }}
              rel="noopener"
              target="_blank"
              to="partners.edit"
            >
              {item.expense.partner.shortName}
            </Ellipsis>
          ),
        getEditor: item =>
          item.kind === 'total' || Boolean(item.expense.meta)
            ? undefined
            : createTableCellEditor({
                initialValue: item.expense.partner,
                applyValue: newPartner => {
                  change(`${fields.name}[${item.index}].partner`, newPartner);
                },
                render: ({ style, value, onChange }) => (
                  <CellEditor style={style}>
                    <PartnersAutocompleteInFormGroup
                      autoFocus
                      initialItem={value ?? undefined}
                      noBottomMargin
                      required
                      value={value?.id ?? null}
                      onChange={(_newPartnerId, newPartner) => {
                        onChange(newPartner);
                      }}
                    />
                  </CellEditor>
                ),
              }),
      },
      {
        id: 'supplierDocument',
        label: 'Документ от подрядчика',
        defaultWidth: 250,
        renderCell: ({
          children,
          item,
          style,
          onDoubleClick,
          onMouseDown,
          onMouseEnter,
        }) => (
          <BaseCell
            boldBorderTop={item.kind === 'total'}
            style={style}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        copyCellContent: item =>
          item.kind === 'total' || item.expense.supplierDocument == null
            ? ''
            : (item.expense.supplierDocument.number ||
                `<номер не указан>, id: ${item.expense.supplierDocument.id}`) ??
              '',
        renderCellContent: item =>
          item.kind === 'total' ||
          item.expense.supplierDocument == null ? null : (
            <Ellipsis
              component={Link}
              params={{ id: item.expense.supplierDocument.id }}
              rel="noopener"
              target="_blank"
              to="suppliersDocumentsFromFile.edit"
            >
              {(item.expense.supplierDocument.number ||
                `<номер не указан>, id: ${item.expense.supplierDocument.id}`) ??
                ''}
            </Ellipsis>
          ),
        getEditor: item => {
          if (item.kind === 'total' || Boolean(item.expense.meta)) {
            return undefined;
          }

          const { partner } = item.expense;

          if (!partner) {
            return undefined;
          }

          return createTableCellEditor({
            initialValue: item.expense.supplierDocument,
            applyValue: newSupplierDocument => {
              change(
                `${fields.name}[${item.index}].supplierDocument`,
                newSupplierDocument
              );
            },
            render: ({ style, value, onChange }) => (
              <CellEditor style={style}>
                <SuppliersDocumentsFromFileAutocompleteInFormGroup
                  addToQuery={{ partner: partner.id, state: 'OPEN' }}
                  autoFocus
                  initialItem={value ?? undefined}
                  noBottomMargin
                  required
                  value={value?.id ?? null}
                  onChange={(_newSupplierDocumentId, newSupplierDocument) => {
                    onChange(newSupplierDocument);
                  }}
                />
              </CellEditor>
            ),
          });
        },
      },
    ],
    [api, change, fields.name]
  );

  const getItemId = React.useCallback(
    (item: TableItem) =>
      item.kind === 'expense' ? `expense-${item.index}` : 'total',
    []
  );

  const items = React.useMemo(
    () =>
      fields.value
        .map(
          (expense, index): TableItem => ({ kind: 'expense', expense, index })
        )
        .concat({
          kind: 'total',
          cost: sumMaybeDecimals(fields.value.map(item => item.cost)).toFixed(
            2
          ),
          costVatValue: sumMaybeDecimals(
            fields.value.map(item => item.costVatValue).filter(isNotNull)
          ).toFixed(2),
          costTotal: sumMaybeDecimals(
            fields.value.map(item => item.costTotal)
          ).toFixed(2),
        }),
    [fields.value]
  );

  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  return (
    <VGrid>
      <Row>
        <TableFieldErrors
          fieldLabels={[
            ['name', 'Наименование'],
            ['cost', 'Стоимость'],
          ]}
          rowsErrors={meta.error || meta.submitError}
        />

        <ListTable
          columns={columns}
          getItemId={getItemId}
          items={items}
          maxHeight={500}
          selectedItems={selectedItems}
          stickyBottomRowCount={1}
          stickyColumnCount={3}
          onSelectedItemsChange={setSelectedItems}
        />
      </Row>

      <Row>
        <Toolbar>
          <Button
            disabled={submitting}
            icon="add-row-bottom"
            intent={Intent.PRIMARY}
            text="Добавить расход"
            onClick={() => {
              fields.push({
                cost: '',
                costTotal: '',
                costVatValue: '',
                meta: undefined,
                name: '',
                partner: null,
                supplierDocument: null,
                vatRate: VatRate.None,
              });
            }}
          />

          <Button
            disabled={
              submitting ||
              selectedItems.length === 0 ||
              selectedItems
                .map(itemId => items.find(item => getItemId(item) === itemId))
                .filter(isNotNull)
                .some(
                  item => item.kind === 'total' || item.expense.meta != null
                )
            }
            icon="remove"
            intent={Intent.DANGER}
            text="Удалить выбранные расходы"
            onClick={() => {
              selectedItems
                .map(itemId => items.find(item => getItemId(item) === itemId))
                .filter(
                  (item): item is TableItem & { kind: 'expense' } =>
                    item?.kind === 'expense'
                )
                .map(item => item.index)
                .sort((a, b) => b - a)
                .forEach(rowIndex => {
                  fields.remove(rowIndex);
                });

              setSelectedItems([]);
            }}
          />
        </Toolbar>
      </Row>
    </VGrid>
  );
}

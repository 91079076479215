import { ApiClient } from '_core/api/client';
import { makeFetchAll } from '_core/api/makeFetchAll';
import { ListResponse } from '_core/api/types';

export interface IWagonType {
  id: number;
  isDefault: boolean;
  name: string;
}

function fetchWagonTypes(api: ApiClient, { page }: { page?: number } = {}) {
  return api.get<ListResponse<IWagonType>>('/directories_wagontype', { page });
}

export const fetchAllWagonTypes = makeFetchAll(fetchWagonTypes);

import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { TreeTable, TreeTableColumn } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import { Link } from '_core/router5/link';
import { ISorting } from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { IContractSellSerialized } from 'contractsSell/api';
import dayjs from 'dayjs';
import * as React from 'react';
import { useMemo } from 'react';
import {
  ITechrunRequest,
  techrunRequestStatusLabel,
} from 'techrunRequests/types';

export interface ITechrunRequestsTreeTableItem
  extends Omit<ITechrunRequest, 'sellContract'> {
  sellContract: IContractSellSerialized;
}

export type TechrunRequestsTreeTableNodeData =
  | { kind: 'partner'; id: number; partnerName: string; protocolCount: number }
  | {
      kind: 'protocol';
      id: number;
      contract: number;
      protocolName: string;
      requestCount: number;
    }
  | { kind: 'request'; request: ITechrunRequestsTreeTableItem };

interface IProps {
  fetchChildNodes?: (
    parentNodes: Array<TreeTableNode<TechrunRequestsTreeTableNodeData>>
  ) => Promise<Array<TreeTableNode<TechrunRequestsTreeTableNodeData>>>;
  isFetching: boolean;
  listParams: string;
  nodes: Array<TreeTableNode<TechrunRequestsTreeTableNodeData>>;
  sorting: ISorting;
  onNodesChange: (
    newNodesOrUpdater:
      | Array<TreeTableNode<TechrunRequestsTreeTableNodeData>>
      | ((
          prevNodes: Array<TreeTableNode<TechrunRequestsTreeTableNodeData>>
        ) => Array<TreeTableNode<TechrunRequestsTreeTableNodeData>>)
  ) => void;
  onSortingChange: (newSorting: ISorting) => void;
}

export function TechrunRequestsTreeTable({
  fetchChildNodes,
  isFetching,
  listParams,
  nodes,
  sorting,
  onNodesChange,
  onSortingChange,
}: IProps) {
  const columns = useMemo(
    (): Array<TreeTableColumn<TechrunRequestsTreeTableNodeData>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 650,
        sortable: true,
        expandable: true,
        copyCellContent: node => {
          switch (node.data.kind) {
            case 'partner': {
              const { partnerName, protocolCount } = node.data;

              return `${partnerName} (${protocolCount})`;
            }
            case 'protocol': {
              const { protocolName, requestCount } = node.data;

              return `${protocolName} (${requestCount})`;
            }
            case 'request': {
              return node.data.request.requestName;
            }
          }
        },
        renderCellContent: node => {
          switch (node.data.kind) {
            case 'partner': {
              const { id, partnerName, protocolCount } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id }}
                  rel="noopener"
                  target="_blank"
                  to="partners.edit"
                >
                  {`${partnerName} (${protocolCount})`}
                </Ellipsis>
              );
            }
            case 'protocol': {
              const { contract, id, protocolName, requestCount } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{
                    id: contract,
                    protocolId: id,
                    showArchived: true,
                  }}
                  rel="noopener"
                  target="_blank"
                  to="contracts.sell.view.techrunProtocols.view"
                >
                  {`${protocolName} (${requestCount})`}
                </Ellipsis>
              );
            }
            case 'request': {
              const request = node.data.request;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: request.id, listParams }}
                  rel="noopener"
                  target="_blank"
                  to="techruns.requests.view"
                >
                  {request.requestName}
                </Ellipsis>
              );
            }
          }
        },
      },
      {
        id: 'startDate',
        label: 'Дата начала',
        defaultWidth: 140,
        sortable: true,
        copyCellContent: node => {
          if (node.data.kind !== 'request') {
            return '';
          }

          const request = node.data.request;

          return dayjs(request.startDate).format(DATE_FORMAT_DATE);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'request') {
            return null;
          }

          const request = node.data.request;

          return dayjs(request.startDate).format(DATE_FORMAT_DATE);
        },
      },
      {
        id: 'endDate',
        label: 'Дата окончания',
        defaultWidth: 140,
        sortable: true,
        copyCellContent: node => {
          if (node.data.kind !== 'request') {
            return '';
          }

          const request = node.data.request;

          return dayjs(request.endDate).format(DATE_FORMAT_DATE);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'request') {
            return null;
          }

          const request = node.data.request;

          return dayjs(request.endDate).format(DATE_FORMAT_DATE);
        },
      },
      {
        id: 'status',
        label: 'Статус заявки',
        defaultWidth: 150,
        copyCellContent: node => {
          if (node.data.kind !== 'request') {
            return '';
          }

          const request = node.data.request;

          return techrunRequestStatusLabel(request.status);
        },
        renderCellContent: node => {
          if (node.data.kind !== 'request') {
            return null;
          }

          const request = node.data.request;

          return techrunRequestStatusLabel(request.status);
        },
      },
      {
        id: 'contract',
        label: 'Договор',
        defaultWidth: 150,
        copyCellContent: node => {
          if (node.data.kind !== 'request') {
            return '';
          }

          return node.data.request.sellContract.number;
        },
        renderCellContent: node => {
          if (node.data.kind !== 'request') {
            return null;
          }

          const request = node.data.request;

          return (
            <Ellipsis
              component={Link}
              params={{ id: request.sellContract.id }}
              rel="noopener"
              target="_blank"
              to="contracts.sell.view"
            >
              {request.sellContract.number}
            </Ellipsis>
          );
        },
      },
    ],

    [listParams]
  );

  return (
    <TreeTable
      columns={columns}
      fetchChildNodes={fetchChildNodes}
      isFetching={isFetching}
      nodes={nodes}
      sorting={sorting}
      onNodesChange={onNodesChange}
      onSortingChange={onSortingChange}
    />
  );
}

type FieldError<
  TValues,
  TField extends keyof TValues
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = TValues[TField] extends any[]
  ? TValues[TField][number] extends object
    ? Array<ValidationErrors<TValues[TField][number]>> | string | string[]
    : string | string[]
  : TValues[TField] extends object
  ? ValidationErrors<TValues[TField]>
  : string;

type ValidationErrors<TValues> = {
  [TField in keyof TValues]?: FieldError<TValues, TField>;
};

type Validator<TValues, TField extends keyof TValues> = (
  value: TValues[TField]
) => FieldError<TValues, TField> | undefined;

export function validate<TValues>(
  validators: {
    [TField in keyof TValues]: Validator<TValues, TField> | undefined;
  }
) {
  return (values: TValues) => {
    const errors: ValidationErrors<TValues> = {};
    const fields = Object.keys(validators) as Array<keyof TValues>;

    fields.forEach(field => {
      const validator = validators[field];

      if (!validator) {
        return;
      }

      const error = validator(values[field]);

      if (error) {
        errors[field] = error;
      }
    });

    return errors;
  };
}

export function chainValidators<
  TValues extends { [key: string]: unknown },
  TField extends keyof TValues
>(...validators: Array<Validator<TValues, TField>>) {
  return (value: TValues[TField]) => {
    for (const validator of validators) {
      const error = validator(value);

      if (error) {
        return error;
      }
    }

    return undefined;
  };
}

function isEmpty(value: unknown) {
  return value === '' || value == null;
}

export function numeric(message = 'Введите число.') {
  return (value: unknown) =>
    !isEmpty(value) && isNaN(Number(String(value))) ? message : undefined;
}

export function required(message = 'Обязательно.') {
  return (value: unknown) => (isEmpty(value) ? message : undefined);
}

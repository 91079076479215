import cx from 'classnames';
import * as React from 'react';

import * as css from './contentLayout.module.css';

interface IVGridProps {
  children: React.ReactNode;
  className?: string;
  stretch?: boolean;
}

export function VGrid({ children, className, stretch }: IVGridProps) {
  return (
    <div className={cx(className, css.vgrid, { [css.vgrid_stretch]: stretch })}>
      {children}
    </div>
  );
}

interface IRowProps {
  children: React.ReactNode;
  className?: string;
  containContent?: boolean;
  stickToBottom?: boolean;
  stretch?: boolean;
  wrap?: boolean;
}

export function Row({
  children,
  className,
  containContent,
  stickToBottom,
  stretch,
  wrap,
}: IRowProps) {
  return (
    <div
      className={cx(className, css.row, {
        [css.row_containContent]: containContent,
        [css.row_stickToBottom]: stickToBottom,
        [css.row_stretch]: stretch,
        [css.row_wrap]: wrap,
      })}
    >
      {children}
    </div>
  );
}

interface IGridProps {
  children: React.ReactNode;
  className?: string;
}

export function Grid({ children, className }: IGridProps) {
  return <div className={cx(className, css.grid)}>{children}</div>;
}

interface IColProps {
  align?: 'center' | 'end';
  children?: React.ReactNode;
  className?: string;
  span?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export function Col({ align, children, className, span }: IColProps) {
  return (
    <div
      className={cx(className, css.col, {
        [css.col_align_center]: align === 'center',
        [css.col_align_end]: align === 'end',
        [css.col_span_1]: span === 1,
        [css.col_span_2]: span === 2,
        [css.col_span_3]: span === 3,
        [css.col_span_4]: span === 4,
        [css.col_span_5]: span === 5,
        [css.col_span_6]: span === 6,
        [css.col_span_7]: span === 7,
        [css.col_span_8]: span === 8,
        [css.col_span_9]: span === 9,
        [css.col_span_10]: span === 10,
        [css.col_span_11]: span === 11,
        [css.col_span_12]: span === 12,
      })}
    >
      {children}
    </div>
  );
}

import { useApiClient } from '_core/api/context';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { isNotNull } from '_core/isNotNull';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import * as React from 'react';
import { useMemo, useRef } from 'react';
import { useRouteNode } from 'react-router5';
import { createTechrunGu12Item, TechrunGu12Type } from 'techrunGu12/api';

import { SubmitTrigger, TechrunsGu12Form } from './form';
import { TechrunsGu12FormRouteWrapper } from './formRouteWrapper';

export default function TechrunsGu12CreateRoute() {
  const { route, router } = useRouteNode('techruns.gu12.create');
  const navigationLock = useFormNavigationLock('techruns.gu12.create');
  const api = useApiClient();

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <TechrunsGu12FormRouteWrapper
      listParams={listParams}
      title="Новый документ"
    >
      <TechrunsGu12Form
        initialValues={{
          arrivalStation: null,
          consigner: null,
          departureStation: null,
          endDate: null,
          number: '',
          owners: [],
          startDate: null,
          transportations: [],
          type: TechrunGu12Type.Export,
          wagonsLeft: null,
          wagonsQuantity: null,
        }}
        navigationLock={navigationLock}
        setSubmitTrigger={newSubmitTrigger => {
          submitTriggerRef.current = newSubmitTrigger;
        }}
        onSubmit={async ({ owners, ...otherValues }) => {
          try {
            const createParams = {
              ...otherValues,
              owners: owners.map(({ owner }) => owner).filter(isNotNull),
            };

            switch (submitTriggerRef.current) {
              case 'save':
                await createTechrunGu12Item(api, createParams);
                navigationLock.unlock();
                router.navigate('techruns.gu12', listParams);
                break;
              case 'saveAndContinue': {
                const createdTechrunGu12Item = await createTechrunGu12Item(
                  api,
                  createParams
                );

                navigationLock.unlock();

                router.navigate('techruns.gu12.edit', {
                  id: createdTechrunGu12Item.id,
                  listParams: route.params.listParams,
                });
                break;
              }
            }

            return undefined;
          } catch (err) {
            return submissionErrorsFromApiError(
              err,
              'Не удалось создать документ ГУ-12: Непредвиденная ошибка'
            );
          }
        }}
      />
    </TechrunsGu12FormRouteWrapper>
  );
}

import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { FileInputSingle } from '_core/inputs/fileInputSingle';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useToaster } from '_core/toaster/toasterContext';
import { Button, Intent } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import { IPayment } from 'payments/types';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useRouteNode } from 'react-router5';

import { PaymentsImportTable } from './importTable';

export default function PaymentsImportRoute() {
  const { route, router } = useRouteNode('payments.import');
  const api = useApiClient();
  const toaster = useToaster();

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const [isBankInfoUploadInProgress, setIsBankInfoUploadInProgress] =
    useState(false);

  const [isImportInProgress, setIsImportInProgress] = useState(false);
  const [payments, setPayments] = useState<IPayment[] | null>(null);
  const [selectedPayments, setSelectedPayments] = useState<string[]>([]);

  const title = 'Импорт выписок';

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Платежи"
              params={listParams}
              to="payments"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <FileInputSingle
            label="Файл с выписками"
            onChange={async file => {
              setIsBankInfoUploadInProgress(true);
              setPayments(null);

              try {
                const response = await api.post<{
                  created: Date;
                  documents: IPayment[];
                  id: number;
                }>('/payments_bank_info_upload', { file });

                setPayments(response.documents);
              } catch (err) {
                if (err instanceof BadRequestError) {
                  toaster.show({
                    icon: 'error',
                    intent: Intent.DANGER,
                    message: err.message,
                  });
                } else {
                  toaster.show({
                    icon: 'error',
                    intent: Intent.DANGER,
                    message:
                      'Не удалось импортировать выписку: Непредвиденная ошибка',
                  });

                  throw err;
                }
              } finally {
                setIsBankInfoUploadInProgress(false);
              }
            }}
          />
        </Row>

        {!payments ? (
          isBankInfoUploadInProgress ? (
            <Row stretch>
              <CenteredSpinner />
            </Row>
          ) : null
        ) : (
          <>
            <Row stretch>
              <PaymentsImportTable
                payments={payments}
                selectedPayments={selectedPayments}
                onSelectedPaymentsChange={setSelectedPayments}
              />
            </Row>

            <Row>
              <Button
                disabled={selectedPayments.length === 0 || isImportInProgress}
                intent={Intent.PRIMARY}
                loading={isImportInProgress}
                text="Импорт"
                type="button"
                onClick={async () => {
                  setIsImportInProgress(true);

                  try {
                    await api.post(
                      '/payments_bank_info',
                      {},
                      { query: { ids: selectedPayments.join(',') } }
                    );

                    router.navigate('payments', listParams);
                  } catch (err) {
                    setIsImportInProgress(false);
                    throw err;
                  }
                }}
              />
            </Row>
          </>
        )}
      </VGrid>
    </>
  );
}

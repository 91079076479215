import { BadRequestError } from '_core/api/client';
import { useApiClient } from '_core/api/context';
import { TooltipContent } from '_core/blueprint/tooltipContent';
import { formatFromTo, parseDate } from '_core/dates/utils';
import { useAsyncTasks } from '_core/download/asyncDownload';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { CheckboxListFilter } from '_core/filters/checkboxList';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { finiteNumberOr } from '_core/finiteNumberOr';
import { SearchForm } from '_core/forms/searchForm';
import { indexByLastItem } from '_core/indexBy';
import { DateRangePicker } from '_core/inputs/dateRangePicker';
import { Switch } from '_core/inputs/switch';
import { ColumnsSelectorsGroup } from '_core/lists/columnPresets/columnsSelectorsGroup';
import { useColumnPresets } from '_core/lists/columnPresets/useColumnPresets';
import { usePreference } from '_core/me/usePreference';
import { Pagination } from '_core/pagination';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { ListTable } from '_core/react-window/listTable';
import { LinkButton } from '_core/router5/linkButton';
import {
  expandSorting,
  ISorting,
  SortingDirection,
  sortingFromRouterParam,
  sortingToRouterParam,
  validateSorting,
} from '_core/sorting';
import { SortingInput } from '_core/sortingInput';
import { StateContainer } from '_core/stateContainer';
import { useToaster } from '_core/toaster/toasterContext';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Intent,
  Position,
} from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import dayjs from 'dayjs';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { ParksFilter } from 'parks/parksFilter';
import { PartnersFilter } from 'partners/filter';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { ArrDepOperStationsFilterInPopover } from 'stations/arrDepOperFilterInPopover';
import { WagonAlertsLegend } from 'wagons/alertsLegend';
import {
  addNotesToWagons,
  downloadWagonNotesXlsx,
  downloadWagonsXlsx,
  fetchWagons,
  isWagonAlert,
  IWagonSerialized,
} from 'wagons/api';
import { HistoryFilterInPopover } from 'wagons/historyFilterInPopover';
import { WagonNumbersFilter } from 'wagons/numbersFilter';
import {
  createWagonColumns,
  isWagonColumnsSortingField,
  WagonColumnsSortingField,
  wagonColumnsSortingFieldMap,
} from 'wagons/table/columns';
import {
  getWagonHighestPriorityAlertColor,
  parseWagonNumbers,
  wagonAlertsOptions,
} from 'wagons/utils';

import { HasEtranNoteFilter } from '../../wagons/hasEtranNoteFilter';
import { AddNotesToWagons } from './addNotesToWagons';

const defaultSorting: ISorting<WagonColumnsSortingField> = {
  field: WagonColumnsSortingField.Created,
  direction: SortingDirection.Descending,
};

export default function WagonsListRoute() {
  const { route, router } = useRouteNode('wagons');
  const api = useApiClient();
  const toaster = useToaster();
  const tasks = useAsyncTasks();

  const sorting = useMemo(
    () =>
      validateSorting(
        isWagonColumnsSortingField,
        sortingFromRouterParam(route.params.sorting),
        defaultSorting
      ),
    [route.params.sorting]
  );

  const queryFromRoute = useMemo(
    () => ({
      addedToPark: route.params.addedToPark === 'True' ? true : undefined,
      alerts: route.params.alerts
        ? (route.params.alerts as string).split(',').filter(isWagonAlert)
        : undefined,
      arrivalStation: isFinite(route.params.arrivalStation)
        ? Number(route.params.arrivalStation)
        : undefined,
      consignee: isFinite(route.params.consignee)
        ? Number(route.params.consignee)
        : undefined,
      departureStation: isFinite(route.params.departureStation)
        ? Number(route.params.departureStation)
        : undefined,
      dislocationDatetime: route.params.dislocationDatetime
        ? parseDate(route.params.dislocationDatetime)
        : undefined,
      hasEtranNote:
        route.params.hasEtranNote === 'True'
          ? true
          : route.params.hasEtranNote === 'False'
          ? false
          : undefined,
      numbers: route.params.numbers
        ? (route.params.numbers as string).split(',')
        : undefined,
      operationStation: isFinite(route.params.operationStation)
        ? Number(route.params.operationStation)
        : undefined,
      operationStationNot: isFinite(route.params.operationStationNot)
        ? Number(route.params.operationStationNot)
        : undefined,
      owner: isFinite(route.params.owner)
        ? Number(route.params.owner)
        : undefined,
      page: isFinite(route.params.page) ? Number(route.params.page) : undefined,
      pageSize: isFinite(route.params.pageSize)
        ? Number(route.params.pageSize)
        : undefined,
      parks: route.params.parks
        ? (route.params.parks as string).split(',').map(Number)
        : undefined,
      search: route.params.search ? (route.params.search as string) : undefined,
      sorting: expandSorting(sorting, wagonColumnsSortingFieldMap),
    }),
    [route, sorting]
  );

  const { data, isFetching, refetch, updateData } = useAsyncData(
    [api, queryFromRoute],
    async () => {
      const { meta, results } = await fetchWagons(api, queryFromRoute);

      return {
        currentPage: meta.currentPage,
        pageSize: meta.pageSize,
        totalPages: meta.totalPages,
        wagons: results,
      };
    }
  );

  const applyFilterParams = useCallback(
    (filterParams: Record<string, string | undefined>) => {
      const { name, params } = router.getState();

      router.navigate(name, {
        ...params,
        page: undefined,
        ...filterParams,
      });
    },
    [router]
  );

  const handleSortingChange = useCallback(
    (newSorting: ISorting<WagonColumnsSortingField>) => {
      applyFilterParams({ sorting: sortingToRouterParam(newSorting) });
    },
    [applyFilterParams]
  );

  const columns = createWagonColumns({
    listParams: JSON.stringify(route.params),
    sortableByDistanceToArrivalStation:
      !route.params.dislocationDatetime ||
      Boolean(route.params.numbers) ||
      Boolean(route.params.parks),

    dislocationProp: route.params.dislocationDatetime
      ? 'selectedDislocation'
      : 'latestDislocation',
  }).filter(column => column.id !== 'requestPartner');

  const columnLabels = columns.reduce<Record<string, string>>(
    (labels, column) => {
      labels[column.id] = column.label;

      return labels;
    },

    {}
  );

  const preferencesPath = ['wagons', 'list'];

  const columnPresets = useColumnPresets(preferencesPath, {
    allColumnIds: columns.map(column => column.id),
    alwaysShownColumnIds: ['number'],
    getColumnLabel: columnId => columnLabels[columnId],
  });

  const [addedToParkFilterPref, setAddedToParkFilterPref] =
    usePreference<boolean>([...preferencesPath, 'addedToPark']);

  const [parksFilterPref, setParksFilterPref] = usePreference<
    string | undefined
  >([...preferencesPath, 'parks']);

  useEffect(() => {
    const paramsToOverride: Record<string, string> = {};

    if (parksFilterPref && !route.params.parks) {
      paramsToOverride.parks = parksFilterPref;
    }

    if (addedToParkFilterPref && route.params.addedToPark !== 'True') {
      paramsToOverride.addedToPark = 'True';
    }

    if (Object.values(paramsToOverride).length !== 0) {
      // useRoute/useRouteNode subscription happens in useEffect, after this
      // useEffect, because it's higher in the tree. So if we redirect here, it
      // would not notice route change
      setTimeout(() => {
        router.navigate(
          route.name,
          {
            ...route.params,
            ...paramsToOverride,
          },
          { replace: true }
        );
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const someFilterIsApplied = Boolean(
    route.params.alerts ||
      route.params.arrivalStation ||
      route.params.consignee ||
      route.params.departureStation ||
      route.params.dislocationDatetime ||
      route.params.hasEtranNote ||
      route.params.numbers ||
      route.params.operationStation ||
      route.params.owner ||
      route.params.parks ||
      route.params.search
  );

  const getItemId = useCallback(
    (wagon: IWagonSerialized) => String(wagon.number),
    []
  );

  const getRowColor = useCallback(
    (wagon: IWagonSerialized) =>
      getWagonHighestPriorityAlertColor(wagon.alerts),
    []
  );

  return (
    <>
      <FormattedTitle>Вагоны</FormattedTitle>

      <VGrid stretch>
        <Row>
          <Grid>
            <Col span={3}>
              <SearchForm
                initialValue={route.params.search}
                onApply={search => {
                  applyFilterParams({ search });
                }}
              />
            </Col>

            <Col span={9}>
              <Toolbar align="right">
                <ColumnsSelectorsGroup
                  columnOptions={columnPresets.columnOptions}
                  defaultValue={columnPresets.defaultValue}
                  initialValue={columnPresets.initialValue}
                  preset={columnPresets.activePreset}
                  onApply={columnPresets.onShowColumnsApply}
                  onColumnPresetChange={columnPresets.onColumnPresetChange}
                />

                <WagonNumbersFilter
                  initialValue={route.params.numbers}
                  onApply={numbers => {
                    applyFilterParams({ numbers });
                  }}
                />

                <ParksFilter
                  initialValue={route.params.parks}
                  onApply={newParks => {
                    applyFilterParams({ parks: newParks });
                    setParksFilterPref(newParks);
                  }}
                />

                <HistoryFilterInPopover
                  initialValue={{
                    currentParkWagons: undefined,
                    dislocationDateTime: route.params.dislocationDatetime,
                  }}
                  onApply={values => {
                    const params: Record<string, string | undefined> = {
                      dislocationDatetime: values.dislocationDateTime,
                    };

                    if (
                      sorting.field ===
                      WagonColumnsSortingField.DistanceToArrivalStation
                    ) {
                      params.sorting = undefined;
                    }

                    applyFilterParams(params);
                  }}
                />

                <ArrDepOperStationsFilterInPopover
                  initialValue={{
                    arrivalStation: finiteNumberOr(
                      undefined,
                      route.params.arrivalStation
                    ),
                    departureStation: finiteNumberOr(
                      undefined,
                      route.params.departureStation
                    ),
                    operationStation: finiteNumberOr(
                      undefined,
                      route.params.operationStation
                    ),
                    consignee: route.params.consignee,
                  }}
                  showConsigneeFilter
                  onApply={({
                    arrivalStation,
                    consignee,
                    departureStation,
                    operationStation,
                  }) => {
                    applyFilterParams({
                      arrivalStation:
                        (arrivalStation && String(arrivalStation)) || undefined,

                      consignee,

                      departureStation:
                        (departureStation && String(departureStation)) ||
                        undefined,

                      operationStation:
                        (operationStation && String(operationStation)) ||
                        undefined,
                    });
                  }}
                />

                <CheckboxListFilter
                  allOption
                  iconName="warning-sign"
                  initialValue={route.params.alerts}
                  label="Предупреждения"
                  options={wagonAlertsOptions}
                  onApply={alerts => {
                    applyFilterParams({ alerts });
                  }}
                />

                <PartnersFilter
                  initialValue={route.params.owner}
                  label="Владелец"
                  onApply={owner => {
                    applyFilterParams({ owner });
                  }}
                />

                <HasEtranNoteFilter
                  initialValue={route.params.hasEtranNote}
                  label="Заготовка в Этране"
                  onApply={hasEtranNote => {
                    applyFilterParams({ hasEtranNote });
                  }}
                />

                <Button text="Обновить" onClick={refetch} />
                <LinkButton text="Сбросить фильтры" to={route.name} />

                <StateContainer<DateRange>
                  initialState={[
                    dayjs().startOf('day').toDate(),
                    dayjs().startOf('day').toDate(),
                  ]}
                >
                  {(dateRange, setDateRange) => (
                    <ButtonGroup>
                      <Tooltip2
                        content={
                          <TooltipContent>
                            Скачивает все имеющиеся комментарии для всех (либо
                            только для отфильтрованных) вагонов (парков) за
                            выбранный период.
                          </TooltipContent>
                        }
                        position={Position.BOTTOM}
                      >
                        <Button
                          icon="download"
                          intent={Intent.SUCCESS}
                          text="Комментарии"
                          onClick={async () => {
                            try {
                              const { taskId } = await downloadWagonNotesXlsx(
                                api,
                                {
                                  ...queryFromRoute,
                                  notesFromDatetime: dateRange[0]
                                    ? dayjs(dateRange[0])
                                        .startOf('date')
                                        .toDate()
                                    : undefined,
                                  notesToDatetime: dateRange[1]
                                    ? dayjs(dateRange[1]).endOf('date').toDate()
                                    : undefined,
                                }
                              );

                              tasks.waitForGeneratedFile(taskId);
                            } catch (err) {
                              if (err instanceof BadRequestError) {
                                toaster.show({
                                  icon: 'error',
                                  intent: Intent.DANGER,
                                  message: err.message,
                                });
                              } else {
                                throw err;
                              }
                            }
                          }}
                        />
                      </Tooltip2>

                      <Popover2
                        content={
                          <DateRangePicker
                            allowSingleDayRange
                            maxDaysInRange={
                              route.params.parks || route.params.numbers
                                ? undefined
                                : [
                                    92,
                                    'Для загрузки комментариев за период, превышающий 3 месяца, необходимо применение фильтра вагонов (парков).',
                                  ]
                            }
                            value={dateRange}
                            onChange={setDateRange}
                          />
                        }
                        position={Position.BOTTOM}
                      >
                        <Button
                          intent={Intent.SUCCESS}
                          rightIcon="calendar"
                          text={
                            dateRange[0] && dateRange[1]
                              ? formatFromTo(dateRange[0], dateRange[1])
                              : 'за всё время'
                          }
                        />
                      </Popover2>
                    </ButtonGroup>
                  )}
                </StateContainer>

                <Tooltip2
                  content={
                    <TooltipContent>
                      Скачивает текущую дислокацию выбранных вагонов. Для
                      активации кнопки выберите необходимые вагоны с помощью
                      фильтров.
                    </TooltipContent>
                  }
                  disabled={someFilterIsApplied}
                  position={Position.BOTTOM}
                >
                  <AnchorButton
                    disabled={!someFilterIsApplied}
                    icon="download"
                    intent={Intent.SUCCESS}
                    text="Дислокация"
                    onClick={async () => {
                      const { taskId } = await downloadWagonsXlsx(
                        api,
                        queryFromRoute
                      );

                      tasks.waitForGeneratedFile(taskId);
                    }}
                  />
                </Tooltip2>
              </Toolbar>
            </Col>
          </Grid>
        </Row>

        <Row>
          <Grid>
            <Col span={6}>
              <AddNotesToWagons
                onSubmit={async values => {
                  try {
                    const updatedWagons = await addNotesToWagons(
                      api,
                      parseWagonNumbers(values.wagons),
                      {
                        etranNote: values.etranNote,
                        note: values.note,
                        rzdAppealNote: values.rzdAppealNote,
                        cargoOverNote: values.cargoOverNote,
                        deliveryOverdueNote: values.deliveryOverdueNote,
                      }
                    );

                    const updatedWagonsIndex = indexByLastItem(
                      updatedWagons,
                      wagon => wagon.number
                    );

                    updateData(
                      prevData =>
                        prevData && {
                          ...prevData,
                          wagons: prevData.wagons.map(wagon => {
                            const updatedWagon =
                              updatedWagonsIndex[wagon.number];

                            return updatedWagon || wagon;
                          }),
                        }
                    );

                    return undefined;
                  } catch (err) {
                    return submissionErrorsFromApiError(
                      err,
                      'Не удалось добавить комментарии к вагонам: Непредвиденная ошибка'
                    );
                  }
                }}
              />
            </Col>

            <Col align="end" span={6}>
              <Switch
                checked={route.params.addedToPark === 'True'}
                label="В парке"
                onChange={newAddedToPark => {
                  applyFilterParams({
                    addedToPark: newAddedToPark ? 'True' : undefined,
                  });

                  setAddedToParkFilterPref(newAddedToPark);
                }}
              />
            </Col>
          </Grid>
        </Row>

        <Row>
          <Grid>
            <Col>
              <WagonAlertsLegend />
            </Col>

            <Col align="end">
              <SortingInput
                defaultValue={defaultSorting}
                options={columns
                  .filter(column => column.sortable)
                  .map(column => ({
                    label: column.label,
                    value: column.id as WagonColumnsSortingField,
                  }))}
                value={sorting}
                onChange={handleSortingChange}
              />
            </Col>
          </Grid>
        </Row>

        <Row stretch>
          {!data ? (
            isFetching ? (
              <CenteredSpinner />
            ) : (
              <GenericErrorMessage />
            )
          ) : (
            <ListTable
              columns={columns}
              columnWidths={columnPresets.columnWidths}
              getItemId={getItemId}
              getRowColor={getRowColor}
              isFetching={isFetching}
              items={data.wagons}
              lineNumbersStart={data.pageSize * (data.currentPage - 1) + 1}
              showColumns={columnPresets.showColumns}
              sorting={sorting}
              stickyColumnCount={2}
              onColumnWidthChanged={columnPresets.onColumnWidthChanged}
              onSortingChange={handleSortingChange}
            />
          )}
        </Row>

        {data && (
          <Row>
            <Pagination pageSize={data.pageSize} totalPages={data.totalPages} />
          </Row>
        )}
      </VGrid>
    </>
  );
}

import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';

export enum ShipmentInfoAccountingState {
  NotClosed = 'NOTCLOSED',
  Closed = 'CLOSED',
}

export function isShipmentInfoAccountingState(
  str: unknown
): str is ShipmentInfoAccountingState {
  return Object.values(ShipmentInfoAccountingState).includes(
    str as ShipmentInfoAccountingState
  );
}

export enum ShipmentInfoType {
  ExpeditionCost = 'EXPEDITION_COST',
  ExpeditionTariff = 'EXPEDITION_TARIFF',
  Techrun = 'TECHRUN',
  TechrunDowntime = 'TECHRUN_DOWNTIME',
  SellRentRate = 'SELL_RENT_RATE',
}

export function isShipmentInfoType(input: string): input is ShipmentInfoType {
  return Object.values(ShipmentInfoType).includes(input as ShipmentInfoType);
}

export interface IShipmentInfoListItemSerialized {
  date: string;
  dateUpd: string | null;
  feeAmount: string;
  files: string[];
  id: number;
  isClosed: boolean;
  name: string;
  numberUpd: string;
  partner: number | null;
  requestsExpeditions: number[];
  requestsTechruns: number[];
  totalAmount: string;
  type: ShipmentInfoType;
}

export interface IShipmentInfoListItem
  extends Omit<IShipmentInfoListItemSerialized, 'date' | 'dateUpd'> {
  date: Date;
  dateUpd: Date | null;
}

export function deserializeShipmentInfoListItem({
  date,
  dateUpd,
  ...otherProps
}: IShipmentInfoListItemSerialized): IShipmentInfoListItem {
  return {
    ...otherProps,
    date: parseDate(date),
    dateUpd: dateUpd == null ? null : parseDate(dateUpd),
  };
}

// NOTE: Ideally functions like this should not exist. It's more like a
// temporary solution until *Serialized type is not encapsulated completely
export function serializeShipmentInfoListItem({
  date,
  dateUpd,
  ...otherProps
}: IShipmentInfoListItem): IShipmentInfoListItemSerialized {
  return {
    ...otherProps,
    date: dayjs(date).format(DATE_FORMAT_API_DATE),
    dateUpd:
      dateUpd == null ? null : dayjs(dateUpd).format(DATE_FORMAT_API_DATE),
  };
}

interface IFetchShipmentInfoItemsParams {
  accountingStates?: ShipmentInfoAccountingState[];
  favoriteShipments?: boolean;
  forRequest?: number;
  ids?: number[];
  isClosed?: boolean;
  page?: number;
  pageSize?: number;
  partner?: number;
  search?: string;
  sorting?: ISorting[];
  type?: ShipmentInfoType[];
}

export async function fetchShipmentInfoItems(
  api: ApiClient,
  {
    accountingStates,
    favoriteShipments,
    forRequest,
    ids,
    isClosed,
    page,
    pageSize,
    partner,
    search,
    sorting,
    type,
  }: IFetchShipmentInfoItemsParams = {}
): Promise<ListResponse<IShipmentInfoListItem>> {
  const response = await api.get<ListResponse<IShipmentInfoListItemSerialized>>(
    '/shipment_info',
    {
      accountingStates:
        accountingStates && accountingStates.length !== 0
          ? accountingStates.join(',')
          : undefined,
      favoriteShipments,
      forRequest,
      ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
      isClosed,
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      page,
      pageSize,
      partner,
      search,
      type: type && type.length !== 0 ? type.join(',') : undefined,
    }
  );

  return {
    ...response,
    results: response.results.map(deserializeShipmentInfoListItem),
  };
}

export const fetchManyShipmentInfoItems = makeFetchMany(
  fetchShipmentInfoItems,
  'ids'
);

export interface IShipmentInfoSupplierDocument {
  id: number;
  number: string;
}

export interface IShipmentInfoWagonsUsageShipment {
  duration: number;
  endDate: string;
  startDate: string;
  wagon: string;
}

interface IShipmentInfoDetailSerialized
  extends IShipmentInfoListItemSerialized {
  downtimesTechrun: unknown[];
  expeditionsRequest: number | null;
  sellRentProtocol: number | null;
  supplierDoc: IShipmentInfoSupplierDocument;
  transportationsExpeditions: number[];
  transportationsTechrun: number[];
  wagonsUsageShipment: IShipmentInfoWagonsUsageShipment[];
}

export interface IShipmentInfoDetail
  extends Omit<IShipmentInfoDetailSerialized, 'date' | 'dateUpd'> {
  date: Date;
  dateUpd: Date | null;
}

function deserializeShipmentInfoDetail({
  downtimesTechrun,
  expeditionsRequest,
  sellRentProtocol,
  supplierDoc,
  transportationsExpeditions,
  transportationsTechrun,
  wagonsUsageShipment,
  ...otherProps
}: IShipmentInfoDetailSerialized): IShipmentInfoDetail {
  return {
    ...deserializeShipmentInfoListItem(otherProps),
    downtimesTechrun,
    expeditionsRequest,
    sellRentProtocol,
    supplierDoc,
    transportationsExpeditions,
    transportationsTechrun,
    wagonsUsageShipment,
  };
}

export async function fetchShipmentInfoItem(api: ApiClient, id: number) {
  const shipmentInfoItem = await api.get<IShipmentInfoDetailSerialized>(
    `/shipment_info/${id}`
  );

  return deserializeShipmentInfoDetail(shipmentInfoItem);
}

interface IUpdateShipmentInfoItemParams {
  date: Date;
  dateUpd: Date | null;
  files: string[];
  numberUpd: string;
  partner: number | null;
  supplierDoc: {
    id: number | null;
    number: string;
  };
  wagonsUsageShipment: IShipmentInfoWagonsUsageShipment[];
}

function serializeUpdateShipmentInfoItemParams({
  date,
  dateUpd,
  ...otherParams
}: IUpdateShipmentInfoItemParams) {
  return {
    ...otherParams,
    date: date ? dayjs(date).format(DATE_FORMAT_API_DATE) : null,
    dateUpd: dateUpd ? dayjs(dateUpd).format(DATE_FORMAT_API_DATE) : null,
  };
}

export async function updateShipmentInfoItem(
  api: ApiClient,
  id: number,
  params: IUpdateShipmentInfoItemParams
) {
  const updatedShipmentInfoItem = await api.put<IShipmentInfoDetailSerialized>(
    `/shipment_info/${id}`,
    serializeUpdateShipmentInfoItemParams(params)
  );

  return deserializeShipmentInfoDetail(updatedShipmentInfoItem);
}

interface ICreateShipmentInfoItemsParams {
  downtimesTechrun?: number[];
  transportationsExpeditions?: number[];
  transportationsTechrun?: number[];
}

export async function createShipmentInfoItems(
  api: ApiClient,
  params: ICreateShipmentInfoItemsParams
) {
  // NOTE: not sure about type
  const response = await api.post<IShipmentInfoDetailSerialized[]>(
    '/shipment_info',
    {
      date: dayjs().format(DATE_FORMAT_API_DATE),
      dateUpd: dayjs().format(DATE_FORMAT_API_DATE),
      files: [],
      numberUpd: '',
      transportationsExpeditions: [],
      transportationsTechrun: [],
      ...params,
    }
  );

  // TODO: deserialize? (Make sure the type above is correct)
  return response;
}

interface IAccountingCloseTransportationsForShipmentParams {
  date: Date;
  numberUpd: string;
  dateUpd: Date | null;
  transportationsExpeditions: number[];
  transportationsTechrun: number[];
  files: string[];
}

function serializeAccountingCloseTransportationsForShipmentParams({
  date,
  dateUpd,
  ...otherParams
}: IAccountingCloseTransportationsForShipmentParams) {
  return {
    ...otherParams,
    date: dayjs(date).format(DATE_FORMAT_API_DATE),
    dateUpd:
      dateUpd == null ? null : dayjs(dateUpd).format(DATE_FORMAT_API_DATE),
  };
}

export async function accountingCloseTransportationsForShipment(
  api: ApiClient,
  id: number,
  params: IAccountingCloseTransportationsForShipmentParams
) {
  await api.post(
    `/shipment_info/${id}/accounting_close_transportations`,
    serializeAccountingCloseTransportationsForShipmentParams(params)
  );
}

import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { fetchRepair, updateRepair } from 'repairs/api';

import { OurRepairsForm } from './form';

export default function OurRepairsEditRoute() {
  const { route, router } = useRouteNode('ourRepairs.edit');
  const navigationLock = useFormNavigationLock('ourRepairs.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [route.params.id],
    () => fetchRepair(api, route.params.id)
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const title = data.wagon;

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось изменить ремонт: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Наши ремонты"
              params={listParams}
              to="ourRepairs"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <OurRepairsForm
            initialValues={data}
            navigationLock={navigationLock}
            repair={data}
            onSave={async values => {
              try {
                await updateRepair(api, data.id, { ...data, ...values });
                navigationLock.unlock();
                router.navigate('ourRepairs', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedRepair = await updateRepair(api, data.id, {
                  ...data,
                  ...values,
                });
                updateData(() => updatedRepair);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Toolbar } from '_core/toolbar';
import { Button, Classes, Intent, Popover, Position } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';

import * as css from './addNewWagonsPopover.module.css';

export interface IAddNewWagonsFormValues {
  wagons: string;
}

interface IProps {
  onSubmit: (
    values: IAddNewWagonsFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function AddNewWagonsPopover({ onSubmit }: IProps) {
  return (
    <Popover
      autoFocus
      content={
        <FinalForm<IAddNewWagonsFormValues>
          initialValues={{ wagons: '' }}
          onSubmit={onSubmit}
        >
          {({ error, handleSubmit, submitError, submitting }) => (
            <BaseForm className={css.form} onSubmit={handleSubmit}>
              <FormErrors error={error || submitError} />

              <VGrid>
                <Row>
                  <FormGroupForFinalForm
                    label="Вагоны"
                    labelFor="wagons"
                    labelInfo="*"
                    name="wagons"
                  >
                    <InputMultipleForFinalForm
                      autoFocus
                      id="wagons"
                      name="wagons"
                      rows={8}
                    />
                  </FormGroupForFinalForm>
                </Row>

                <Row>
                  <Toolbar align="right">
                    <Button
                      disabled={submitting}
                      intent={Intent.PRIMARY}
                      text="Сохранить"
                      type="submit"
                    />
                  </Toolbar>
                </Row>
              </VGrid>
            </BaseForm>
          )}
        </FinalForm>
      }
      lazy
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      position={Position.BOTTOM_RIGHT}
    >
      <Button intent={Intent.SUCCESS} text="Добавить вагоны" />
    </Popover>
  );
}

import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useToaster } from '_core/toaster/toasterContext';
import { useAsyncData } from '_core/useAsyncData';
import { Intent } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import { IPayment } from 'payments/types';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';

import { PaymentForm } from './form';
import { PaymentsEditSplitForm } from './splitForm';

export default function PaymentsEditRoute() {
  const { route, router } = useRouteNode('payments.edit');
  const navigationLock = useFormNavigationLock('payments.edit');
  const api = useApiClient();
  const toaster = useToaster();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    () => api.get<IPayment>(`/payments_bank_info/${route.params.id}`)
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const title = String(data.number);

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Платежи"
              params={listParams}
              to="payments"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <VGrid>
            <Row>
              <PaymentForm
                initialValues={data}
                navigationLock={navigationLock}
                payment={data}
                onSubmit={async values => {
                  try {
                    await api.put<IPayment>(
                      `/payments_bank_info/${data.id}`,
                      values
                    );

                    navigationLock.unlock();
                    router.navigate('payments', listParams);

                    return undefined;
                  } catch (err) {
                    return submissionErrorsFromApiError(
                      err,
                      'Не удалось изменить платёж: Непредвиденная ошибка'
                    );
                  }
                }}
              />
            </Row>

            <Row>
              <PaymentsEditSplitForm
                currentValue={data.amount}
                onSubmit={async values => {
                  try {
                    const payments = await api.post<IPayment[]>(
                      `/payments_bank_info/${data.id}/split`,
                      { amount: values.amount }
                    );

                    updateData(() => payments[0]);

                    toaster.show({
                      intent: Intent.SUCCESS,
                      message: 'Платеж успешно разделен',
                    });

                    return undefined;
                  } catch (err) {
                    return submissionErrorsFromApiError(
                      err,
                      'Не удалось разделить платёж: Непредвиденная ошибка'
                    );
                  }
                }}
              />
            </Row>
          </VGrid>
        </Row>
      </VGrid>
    </>
  );
}

import { assocPath } from '_core/fp/assocPath';
import { path } from '_core/fp/path';
import { migratePreferences, PreferencesVersions } from '_core/preferences';
import { createErpAsyncThunk, ErpState, ErpThunkAction } from '_erp/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchMe,
  login,
  LoginRole,
  logout,
  ProfileSerialized,
  updateFavoriteParks,
  updateMe,
} from 'accounts/api';

const initialPreferencesState = {
  home: {
    widgetColumns: [
      { enabledWidgets: ['newTransportationsWidget'], disabledWidgets: [] },
      { enabledWidgets: ['wagonsStatesWidget'], disabledWidgets: [] },
      {
        enabledWidgets: [
          'parksAlertsWidget',
          'wagonsAlertsWidget',
          'techrunRequestsDowntimesWidget',
        ],
        disabledWidgets: [],
      },
    ],
  },
  parks: {
    repairs: {
      showColumns: [null, null, null],
      columnsWidth: [null, null, null],
    },
    downtimes: {
      showColumns: [null, null, null],
      columnsWidth: [null, null, null],
    },
    dislocations: {
      showColumns: [null, null, null],
      columnsWidth: [null, null, null],
    },
  },
  wagons: {
    list: {
      showColumns: [['created'], ['created'], ['created']],
      addedToPark: false,
      columnsWidth: [
        [{ name: 'dislocation.wagonCapacity', value: 164 }],
        [{ name: 'dislocation.arrivalDatetime', value: 182 }],
        [{ name: 'dislocation.deliveryDateOverdue', value: 283 }],
      ],
    },
    dislocationHistory: {
      showColumns: [null, null, null],
      columnsWidth: [null, null, null],
    },
  },
  repairs: {
    showColumns: [null, null, null],
    columnsWidth: [null, null, null],
  },
  version: 7,
  techruns: {
    requests: { list: { startMonths: null } },
    transportations: {
      list: {
        showColumns: [null, null, null],
        columnsWidth: [null, null, null],
      },
    },
  },
  downtimes: {
    edit: {
      showColumns: [null, null, null],
      columnsWidth: [null, null, null],
    },
    parks: {
      showColumns: [null, null, null],
      columnsWidth: [null, null, null],
    },
    showColumns: [null, null, null],
    columnsWidth: [null, null, null],
  },
  expeditions: {
    requests: { list: { startMonths: null } },
    transportations: {
      list: {
        showColumns: [null, null, null],
        columnsWidth: [null, null, null],
      },
    },
  },
  favoriteDocs: false,
};

function patchProfile(
  patcher: (profile: ProfileSerialized) => ProfileSerialized
): ErpThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    const profile = selectMe(getState());

    return profile
      ? updateMe(api, patcher(profile)).then(updatedProfile => {
          dispatch(actions.setProfileData(updatedProfile));
        })
      : Promise.reject(
          Error('User with falsy profile tries to toggle park favorite')
        );
  };
}

function patchFavoriteParks(
  patcher: (parks: number[]) => number[]
): ErpThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    const profile = selectMe(getState());

    return profile
      ? updateFavoriteParks(api, { parks: patcher(profile.parks) }).then(
          updatedProfile => {
            dispatch(actions.setProfileData(updatedProfile));
          }
        )
      : Promise.reject(
          Error('User with falsy profile tries to toggle park favorite')
        );
  };
}

export function fetchMeAction(): ErpThunkAction<Promise<void>> {
  return (dispatch, _getState, { api }) =>
    fetchMe(api).then(profile => {
      dispatch(actions.setProfileData(profile));
    });
}

export const loginAction = createErpAsyncThunk(
  'me/login',
  (
    { email, password }: { email: string; password: string },
    { extra: { api } }
  ) => login(api, LoginRole.Employee, email, password)
);

export const logoutAction =
  (message: string | null = null): ErpThunkAction<Promise<void>> =>
  async (dispatch, _getState, { api }) => {
    await logout(api);
    dispatch(actions.logout({ message }));
  };

export const setPreferenceAction = createErpAsyncThunk<
  void,
  { path: string[]; value: unknown }
>('preferences/setPreference', (_arg, { dispatch, getState }) =>
  dispatch(
    patchProfile(prev => ({
      ...prev,
      preferences: migratePreferences(getState().me.profile?.preferences ?? {}),
    }))
  )
);

function toggleArrayItem<TId>(allIds: TId[], idToToggle: TId) {
  return allIds.includes(idToToggle)
    ? allIds.filter(id => id !== idToToggle)
    : allIds.concat([idToToggle]);
}

export function toggleExpeditionRequestFavoriteStatus(
  id: number
): ErpThunkAction<Promise<void>> {
  return dispatch =>
    dispatch(
      patchProfile(profile => ({
        ...profile,
        expeditionsRequests: toggleArrayItem(profile.expeditionsRequests, id),
      }))
    );
}

export function toggleParkFavoriteStatus(
  id: number
): ErpThunkAction<Promise<void>> {
  return dispatch =>
    dispatch(patchFavoriteParks(parks => toggleArrayItem(parks, id)));
}

export function togglePurchaseContractFavoriteStatus(
  id: number
): ErpThunkAction<Promise<void>> {
  return dispatch =>
    dispatch(
      patchProfile(profile => ({
        ...profile,
        purchaseContracts: toggleArrayItem(profile.purchaseContracts, id),
      }))
    );
}

export function toggleSellContractFavoriteStatus(
  id: number
): ErpThunkAction<Promise<void>> {
  return dispatch =>
    dispatch(
      patchProfile(profile => ({
        ...profile,
        sellContracts: toggleArrayItem(profile.sellContracts, id),
      }))
    );
}

export function toggleShipmentInfoFavoriteStatus(
  id: number
): ErpThunkAction<Promise<void>> {
  return dispatch =>
    dispatch(
      patchProfile(profile => ({
        ...profile,
        shipments: toggleArrayItem(profile.shipments, id),
      }))
    );
}

export function toggleTechrunRequestFavoriteStatus(
  id: number
): ErpThunkAction<Promise<void>> {
  return dispatch =>
    dispatch(
      patchProfile(profile => ({
        ...profile,
        techrunsRequests: toggleArrayItem(profile.techrunsRequests, id),
      }))
    );
}

interface State {
  logoutMessage: string | null;
  profile: ProfileSerialized | null;
}

const initialState: State = {
  logoutMessage: null,
  profile: null,
};

const { actions, reducer } = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setProfileData: (state, { payload }: PayloadAction<ProfileSerialized>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const preferences = payload.preferences.home as PreferencesVersions;

      if (!preferences) {
        payload.preferences = initialPreferencesState;
      }

      state.profile = payload;
    },
    logout: (state, { payload }: PayloadAction<{ message: string | null }>) => {
      state.logoutMessage = payload.message;
      state.profile = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginAction.pending, state => {
        state.logoutMessage = null;
      })
      .addCase(loginAction.fulfilled, (state, { payload }) => {
        state.profile = payload.profile;
      })
      .addCase(setPreferenceAction.pending, (state, action) => {
        if (!state.profile) {
          return;
        }

        state.profile.preferences = migratePreferences(
          state.profile.preferences
        );

        const { path: prefPath, value } = action.meta.arg;
        return assocPath(['profile', 'preferences', ...prefPath], value, state);
      });
  },
});

export const meReducer = reducer;

export function selectMe(state: ErpState) {
  return state.me.profile;
}

export function selectPermissions(state: ErpState) {
  const {
    isAccountant = false,
    isFinanceDirector = false,
    isFinanceManager = false,
  } = state.me.profile ?? {};

  return { isAccountant, isFinanceDirector, isFinanceManager };
}

export function selectFavoriteParks(state: ErpState) {
  return selectMe(state)?.parks ?? [];
}

export function selectIsFavoritePark(parkId: number, state: ErpState): boolean {
  return selectFavoriteParks(state).includes(parkId);
}

export function selectIsFavoritePurchaseContract(
  parkId: number,
  state: ErpState
): boolean {
  return selectMe(state)?.purchaseContracts.includes(parkId) ?? false;
}

export function selectIsFavoriteSellContract(
  parkId: number,
  state: ErpState
): boolean {
  return selectMe(state)?.sellContracts.includes(parkId) ?? false;
}

export function selectIsFavoriteShipmentInfo(
  shipmentInfoId: number,
  state: ErpState
): boolean {
  return selectMe(state)?.shipments.includes(shipmentInfoId) ?? false;
}

export function selectFavoriteTechrunRequests(state: ErpState) {
  return selectMe(state)?.techrunsRequests ?? [];
}

export function selectIsFavoriteTechrunRequest(
  requestId: number,
  state: ErpState
) {
  return selectFavoriteTechrunRequests(state).includes(requestId);
}

export function selectFavoriteExpeditionRequests(state: ErpState) {
  return selectMe(state)?.expeditionsRequests ?? [];
}

export function selectIsFavoriteExpeditionRequest(
  requestId: number,
  state: ErpState
) {
  return selectFavoriteExpeditionRequests(state).includes(requestId);
}

export function selectLogoutMessage(state: ErpState) {
  return state.me.logoutMessage;
}

function selectPreferences(state: ErpState) {
  return migratePreferences(state.me.profile?.preferences ?? {});
}

export function selectPreference(state: ErpState, prefPath: string[]) {
  return path(prefPath, selectPreferences(state));
}

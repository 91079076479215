export interface IWidgetColumn {
  enabledWidgets: string[];
  disabledWidgets: string[];
}

export function getEnabledWidgetsInColumns(columns: IWidgetColumn[]) {
  return columns.flatMap(column => column.enabledWidgets);
}

export function updateEnabledWidgetsInColumns(
  columns: IWidgetColumn[],
  enabledWidgets: string[]
) {
  let result = columns;

  const isEnabledMap: Record<string, true | undefined> = {};

  enabledWidgets.forEach(widget => {
    isEnabledMap[widget] = true;
  });

  for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
    let columnResult = columns[columnIndex];

    for (let i = 0; i < columnResult.enabledWidgets.length; i++) {
      const widget = columnResult.enabledWidgets[i];

      if (!isEnabledMap[widget]) {
        if (columnResult === columns[columnIndex]) {
          columnResult = {
            enabledWidgets: columnResult.enabledWidgets.slice(),
            disabledWidgets: columnResult.disabledWidgets.slice(),
          };
        }

        columnResult.disabledWidgets.push(
          ...columnResult.enabledWidgets.splice(i, 1)
        );
        i--;
      }
    }

    for (let i = 0; i < columnResult.disabledWidgets.length; i++) {
      const widget = columnResult.disabledWidgets[i];

      if (isEnabledMap[widget]) {
        if (columnResult === columns[columnIndex]) {
          columnResult = {
            enabledWidgets: columnResult.enabledWidgets.slice(),
            disabledWidgets: columnResult.disabledWidgets.slice(),
          };
        }

        columnResult.enabledWidgets.push(
          ...columnResult.disabledWidgets.splice(i, 1)
        );
        i--;
      }
    }

    if (columnResult !== columns[columnIndex]) {
      if (result === columns) {
        result = columns.slice();
      }

      result[columnIndex] = columnResult;
    }
  }

  return result;
}

export function handleWidgetsDragAndDrop(
  columns: IWidgetColumn[],
  srcColIndex: number,
  srcWidgetIndex: number,
  dstColIndex: number,
  dstWidgetIndex: number
) {
  const result = columns.slice();

  if (srcColIndex === dstColIndex) {
    let column = columns[srcColIndex];
    column = { ...column, enabledWidgets: column.enabledWidgets.slice() };

    column.enabledWidgets.splice(
      dstWidgetIndex,
      0,
      ...column.enabledWidgets.splice(srcWidgetIndex, 1)
    );

    result[srcColIndex] = column;
  } else {
    let srcCol = columns[srcColIndex];
    srcCol = { ...srcCol, enabledWidgets: srcCol.enabledWidgets.slice() };

    let dstCol = columns[dstColIndex];
    dstCol = { ...dstCol, enabledWidgets: dstCol.enabledWidgets.slice() };

    dstCol.enabledWidgets.splice(
      dstWidgetIndex,
      0,
      ...srcCol.enabledWidgets.splice(srcWidgetIndex, 1)
    );

    result[srcColIndex] = srcCol;
    result[dstColIndex] = dstCol;
  }

  return result;
}

import { DocumentsStatusesDocumentType, IDocumentsStatusesItem } from './api';

export function getDocumentTypeLabel(type: DocumentsStatusesDocumentType) {
  return {
    [DocumentsStatusesDocumentType.ContractAgreementPurchase]:
      'Доп. соглашение к договору покупки',
    [DocumentsStatusesDocumentType.ContractAgreementSell]:
      'Доп. соглашение к договору продажи',
    [DocumentsStatusesDocumentType.ContractPurchase]: 'Договор покупки',
    [DocumentsStatusesDocumentType.ContractSell]: 'Договор продажи',
    [DocumentsStatusesDocumentType.ExpeditionProtocolSell]:
      'Протокол продажи экспедирования',
    [DocumentsStatusesDocumentType.RentProtocolPurchase]:
      'Протокол покупки аренды',
    [DocumentsStatusesDocumentType.RentProtocolSell]: 'Протокол продажи аренды',
    [DocumentsStatusesDocumentType.TechrunProtocolPurchase]:
      'Протокол покупки техрейса',
    [DocumentsStatusesDocumentType.TechrunProtocolSell]:
      'Протокол продажи техрейса',
  }[type];
}

interface IDocumentLink {
  params?: Record<string, unknown>;
  to: string;
}

export function getDocumentLink(
  document: Pick<
    IDocumentsStatusesItem,
    | 'contractAgreementPurchase'
    | 'contractAgreementSell'
    | 'contractPurchase'
    | 'contractSell'
    | 'documentType'
    | 'expeditionsProtocolSell'
    | 'rentProtocolPurchase'
    | 'rentProtocolSell'
    | 'techrunsProtocolPurchase'
    | 'techrunsProtocolSell'
  >
): IDocumentLink {
  switch (document.documentType) {
    case DocumentsStatusesDocumentType.ContractAgreementPurchase:
      return {
        to: 'contracts.purchase.view.agreements.view',

        params: {
          agreementId: document.contractAgreementPurchase,
          id: document.contractPurchase,
        },
      };

    case DocumentsStatusesDocumentType.ContractAgreementSell:
      return {
        to: 'contracts.sell.view.agreements.view',

        params: {
          agreementId: document.contractAgreementSell,
          id: document.contractSell,
        },
      };

    case DocumentsStatusesDocumentType.ContractPurchase:
      return {
        params: { id: document.contractPurchase },
        to: 'contracts.purchase.view',
      };

    case DocumentsStatusesDocumentType.ContractSell:
      return {
        params: { id: document.contractSell },
        to: 'contracts.sell.view',
      };

    case DocumentsStatusesDocumentType.ExpeditionProtocolSell:
      return {
        to: 'contracts.sell.view.expeditionProtocols.view',

        params: {
          id: document.contractSell,
          protocolId: document.expeditionsProtocolSell,
          showArchived: true,
        },
      };

    case DocumentsStatusesDocumentType.RentProtocolPurchase:
      return {
        to: 'contracts.purchase.view.rentProtocols.view',

        params: {
          id: document.contractPurchase,
          protocolId: document.rentProtocolPurchase,
        },
      };

    case DocumentsStatusesDocumentType.RentProtocolSell:
      return {
        to: 'contracts.sell.view.rentProtocols.view',

        params: {
          id: document.contractSell,
          protocolId: document.rentProtocolSell,
        },
      };

    case DocumentsStatusesDocumentType.TechrunProtocolPurchase:
      return {
        to: 'contracts.purchase.view.techrunProtocols.view',

        params: {
          id: document.contractPurchase,
          protocolId: document.techrunsProtocolPurchase,
          showArchived: true,
        },
      };

    case DocumentsStatusesDocumentType.TechrunProtocolSell:
      return {
        to: 'contracts.sell.view.techrunProtocols.view',

        params: {
          id: document.contractSell,
          protocolId: document.techrunsProtocolSell,
          showArchived: true,
        },
      };
  }
}

import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';

import * as css from './dropzone.module.css';

interface IProps {
  id?: string;
  isInvalid?: boolean;
  label: string;
  multiple?: boolean;
  name?: string;
  onFilesSelect: (files: File[]) => void;
}

export function Dropzone({
  id,
  isInvalid,
  label,
  multiple,
  name,
  onFilesSelect,
}: IProps) {
  return (
    <label
      htmlFor={id}
      className={cx(css.main, { [css.main_invalid]: isInvalid })}
      onDragOver={event => {
        event.preventDefault();
      }}
      onDrop={event => {
        onFilesSelect(Array.from(event.dataTransfer.files));

        event.preventDefault();
      }}
    >
      <div className={css.iconWrap}>
        <Icon icon="cloud-upload" iconSize={40} />
      </div>

      <div>{label}</div>

      <input
        id={id}
        type="file"
        hidden
        multiple={multiple}
        name={name}
        // NOTE: this is needed to trigger onChange even if we select the
        // same file in dialog
        value=""
        onChange={event => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onFilesSelect(Array.from(event.currentTarget.files!));
        }}
      />
    </label>
  );
}

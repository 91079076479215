import { DATE_FORMAT_DATE, DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { formatMoney } from '_core/money/formatMoney';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Link } from '_core/router5/link';
import { ISorting } from '_core/sorting';
import { Button } from '@blueprintjs/core';
import { formatUserName } from 'accounts/formatUserName';
import { IUser } from 'accounts/types';
import dayjs from 'dayjs';
import {
  expeditionTransportationStatusFromGroup,
  IExpeditionTransportation,
} from 'expeditionTransportations/types';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import {
  ISuppliersDocumentTransportation,
  SuppliersDocumentTransportationAmountIsCorrect,
} from 'suppliersDocuments/api';
import { SuppliersDocumentType } from 'suppliersDocuments/types';
import {
  ITechrunTransportation,
  techrunTransportationStatusFromGroup,
} from 'techrunTransportations/types';

export type TransportationsTableSortingField =
  | 'amountIsCorrect'
  | 'id'
  | 'name'
  | 'number'
  | 'type';
export function isTransportationsTableSortingField(
  field: string
): field is TransportationsTableSortingField {
  return ['amountIsCorrect', 'id', 'name', 'number', 'type'].includes(field);
}

export interface ITransportationsTableItem
  extends Omit<
    ISuppliersDocumentTransportation,
    'confirmUser' | 'expeditionTransp' | 'techrunTransp'
  > {
  confirmUser: IUser | null;
  expeditionTransp: IExpeditionTransportation | null;
  techrunTransp: ITechrunTransportation | null;
}

export function getSuppliersDocTranspAmountIsCorrectColor({
  amountIsConfirmed,
  amountIsCorrect,
}: {
  amountIsConfirmed: boolean;
  amountIsCorrect: SuppliersDocumentTransportationAmountIsCorrect;
}) {
  if (
    amountIsCorrect === SuppliersDocumentTransportationAmountIsCorrect.NotLinked
  ) {
    return '#f00';
  }

  if (
    amountIsCorrect === SuppliersDocumentTransportationAmountIsCorrect.Ok ||
    amountIsConfirmed
  ) {
    return '#0f9960';
  }

  return {
    [SuppliersDocumentTransportationAmountIsCorrect.Greater]: '#ff66a1',
    [SuppliersDocumentTransportationAmountIsCorrect.Less]: '#48aff0',
  }[amountIsCorrect];
}

interface IProps {
  docType: SuppliersDocumentType;
  isFetchingTransportations: boolean;
  selectedTransportations: string[];
  sorting: ISorting;
  transportations: ITransportationsTableItem[];
  onBindClick: (transportation: ITransportationsTableItem) => void;
  onSelectedTransportationsChange: (
    newSelectedTransportations: string[]
  ) => void;
  onSortingChange: (newSorting: ISorting) => void;
}

export function SuppliersDocumentFromFileTransportationsTable({
  docType,
  isFetchingTransportations,
  selectedTransportations,
  sorting,
  transportations,
  onBindClick,
  onSelectedTransportationsChange,
  onSortingChange,
}: IProps) {
  const columns = useMemo(
    (): Array<ListTableColumn<ITransportationsTableItem>> => [
      {
        id: 'number',
        label: 'Номер',
        defaultWidth: 70,
        sortable: true,
        copyCellContent: transportation => String(transportation.number),
        renderCellContent: transportation => transportation.number,
      },
      {
        id: 'waybillNumber',
        label: '№ документа',
        defaultWidth: 100,
        copyCellContent: transportation => transportation.waybillNumber,
        renderCellContent: transportation => transportation.waybillNumber,
      },
      {
        id: 'confirmDate',
        label: 'Дата подтверждения',
        defaultWidth: 120,
        copyCellContent: transportation =>
          transportation.confirmDate
            ? dayjs(transportation.confirmDate).format(DATE_FORMAT_DATETIME)
            : '',
        renderCellContent: transportation =>
          transportation.confirmDate
            ? dayjs(transportation.confirmDate).format(DATE_FORMAT_DATETIME)
            : null,
      },
      {
        id: 'confirmNote',
        label: 'Причина подтверждения',
        defaultWidth: 120,
        copyCellContent: transportation => transportation.confirmNote,
        renderCellContent: transportation => transportation.confirmNote,
      },
      {
        id: 'confirmUser',
        label: 'Подтвердивший пользователь',
        defaultWidth: 120,
        copyCellContent: transportation =>
          transportation.confirmUser
            ? formatUserName(transportation.confirmUser)
            : '',
        renderCellContent: transportation =>
          transportation.confirmUser
            ? formatUserName(transportation.confirmUser)
            : null,
      },
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 320,
        sortable: true,
        copyCellContent: transportation => transportation.name,
        renderCellContent: transportation => transportation.name,
      },
      {
        id: 'departureDate',
        label: 'Дата отправления',
        defaultWidth: 80,
        copyCellContent: transportation =>
          transportation.departureDate
            ? dayjs(transportation.departureDate).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: transportation =>
          transportation.departureDate &&
          dayjs(transportation.departureDate).format(DATE_FORMAT_DATE),
      },
      {
        id: 'rascreditDate',
        label: 'Дата раскредитования',
        defaultWidth: 80,
        copyCellContent: transportation =>
          transportation.rascreditDate
            ? dayjs(transportation.rascreditDate).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: transportation =>
          transportation.rascreditDate &&
          dayjs(transportation.rascreditDate).format(DATE_FORMAT_DATE),
      },
      {
        id: 'acceptDate',
        label: 'Дата приёма груза к перевозке',
        defaultWidth: 80,
        copyCellContent: transportation =>
          transportation.acceptDate
            ? dayjs(transportation.acceptDate).format(DATE_FORMAT_DATE)
            : '',
        renderCellContent: transportation =>
          transportation.acceptDate &&
          dayjs(transportation.acceptDate).format(DATE_FORMAT_DATE),
      },
      {
        id: 'type',
        label: 'Тип',
        defaultWidth: 50,
        sortable: true,
        copyCellContent: transportation => transportation.type,
        renderCellContent: transportation => transportation.type,
      },
      {
        id: 'roadStationFrom',
        label: 'Дорога станции отправления',
        defaultWidth: 200,
        copyCellContent: transportation => transportation.roadStationFrom,
        renderCellContent: transportation => transportation.roadStationFrom,
      },
      {
        id: 'roadStationTo',
        label: 'Дорога станции назначения',
        defaultWidth: 200,
        copyCellContent: transportation => transportation.roadStationTo,
        renderCellContent: transportation => transportation.roadStationTo,
      },
      {
        id: 'messageType',
        label: 'Вид сообщения',
        defaultWidth: 60,
        copyCellContent: transportation => transportation.messageType,
        renderCellContent: transportation => transportation.messageType,
      },
      {
        id: 'wagonNumber',
        label: '№ вагона/контейнера',
        defaultWidth: 100,
        copyCellContent: transportation => transportation.wagonNumber,
        renderCellContent: transportation => transportation.wagonNumber,
      },
      {
        id: 'cargoName',
        label: 'Наименование груза',
        defaultWidth: 150,
        copyCellContent: transportation => transportation.cargoName,
        renderCellContent: transportation => transportation.cargoName,
      },
      {
        id: 'cargoWeight',
        label: 'Рассчётный вес груза',
        defaultWidth: 100,
        copyCellContent: transportation => String(transportation.cargoWeight),
        renderCellContent: transportation => String(transportation.cargoWeight),
      },
      {
        id: 'amount',
        label: 'Всего (без НДС)',
        defaultWidth: 90,
        copyCellContent: transportation => formatMoney(transportation.amount),
        renderCellContent: transportation => formatMoney(transportation.amount),
      },
      {
        id: 'vatRate',
        label: 'Ставка НДС',
        defaultWidth: 70,
        copyCellContent: transportation => formatMoney(transportation.vatRate),
        renderCellContent: transportation =>
          formatMoney(transportation.vatRate),
      },
      {
        id: 'vatValue',
        label: 'Сумма НДС',
        defaultWidth: 90,
        copyCellContent: transportation => formatMoney(transportation.vatValue),
        renderCellContent: transportation =>
          formatMoney(transportation.vatValue),
      },
      {
        id: 'amountTotal',
        label: 'Сумма (с НДС)',
        defaultWidth: 90,
        copyCellContent: transportation =>
          formatMoney(transportation.amountTotal),
        renderCellContent: transportation =>
          formatMoney(transportation.amountTotal),
      },
      {
        id: 'invoiceNumber',
        label: 'Номер счета-фактуры,',
        defaultWidth: 150,
        copyCellContent: transportation => transportation.invoiceNumber,
        renderCellContent: transportation => transportation.invoiceNumber,
      },
      {
        id: 'invoiceDate',
        label: 'Дата счета-фактуры',
        defaultWidth: 90,
        copyCellContent: transportation =>
          transportation.invoiceDate == null
            ? ''
            : dayjs(transportation.invoiceDate).format(DATE_FORMAT_DATE),
        renderCellContent: transportation =>
          transportation.invoiceDate == null
            ? null
            : dayjs(transportation.invoiceDate).format(DATE_FORMAT_DATE),
      },
      {
        id: 'docNumber',
        label: 'Номер документа корректируемого',
        defaultWidth: 90,
        copyCellContent: transportation => transportation.docNumber,
        renderCellContent: transportation => transportation.docNumber,
      },
      {
        id: 'docType',
        label: 'Тип документа корректируемого',
        defaultWidth: 90,
        copyCellContent: transportation => transportation.docType,
        renderCellContent: transportation => transportation.docType,
      },
      {
        id: 'docDate',
        label: 'Дата документа корректируемого',
        defaultWidth: 90,
        copyCellContent: transportation =>
          transportation.docDate == null
            ? ''
            : dayjs(transportation.docDate).format(DATE_FORMAT_DATE),
        renderCellContent: transportation =>
          transportation.docDate == null
            ? null
            : dayjs(transportation.docDate).format(DATE_FORMAT_DATE),
      },
      {
        id: 'amountBeforeAll',
        label: 'Первоначальная сумма с НДС',
        defaultWidth: 90,
        copyCellContent: transportation =>
          formatMoney(transportation.amountBeforeAll),
        renderCellContent: transportation =>
          formatMoney(transportation.amountBeforeAll),
      },
      {
        id: 'amountBeforeNds',
        label: 'НДС первоначальной суммы',
        defaultWidth: 90,
        copyCellContent: transportation =>
          formatMoney(transportation.amountBeforeNds),
        renderCellContent: transportation =>
          formatMoney(transportation.amountBeforeNds),
      },
      {
        id: 'amountChangeAll',
        label: 'Сумма изменений с НДС',
        defaultWidth: 90,
        copyCellContent: transportation =>
          formatMoney(transportation.amountChangeAll),
        renderCellContent: transportation =>
          formatMoney(transportation.amountChangeAll),
      },
      {
        id: 'amountChangeNds',
        label: 'НДС суммы изменений',
        defaultWidth: 90,
        copyCellContent: transportation =>
          formatMoney(transportation.amountChangeNds),
        renderCellContent: transportation =>
          formatMoney(transportation.amountChangeNds),
      },
      {
        id: 'amountAfterAll',
        label: 'Сумма после изменений с НДС',
        defaultWidth: 90,
        copyCellContent: transportation =>
          formatMoney(transportation.amountAfterAll),
        renderCellContent: transportation =>
          formatMoney(transportation.amountAfterAll),
      },
      {
        id: 'amountAfterNds',
        label: 'НДС суммы после изменений',
        defaultWidth: 90,
        copyCellContent: transportation =>
          formatMoney(transportation.amountAfterNds),
        renderCellContent: transportation =>
          formatMoney(transportation.amountAfterNds),
      },
      {
        id: 'transportation',
        label: 'Отправка',
        defaultWidth: 180,
        copyCellContent: transportation =>
          transportation.expeditionTransp
            ? transportation.expeditionTransp.transportationName
            : transportation.techrunTransp
            ? transportation.techrunTransp.waybillNumber
            : '',
        renderCellContent: transportation =>
          transportation.expeditionTransp ? (
            <Link
              params={{
                id: transportation.expeditionTransp.id,

                status: expeditionTransportationStatusFromGroup(
                  transportation.expeditionTransp.group
                ),
              }}
              rel="noopener"
              target="_blank"
              to="expeditions.transportations.edit"
            >
              {transportation.expeditionTransp.transportationName}
            </Link>
          ) : transportation.techrunTransp ? (
            <Link
              params={{
                id: transportation.techrunTransp.id,

                status: techrunTransportationStatusFromGroup(
                  transportation.techrunTransp.group
                ),
              }}
              rel="noopener"
              target="_blank"
              to="techruns.transportations.edit"
            >
              {transportation.techrunTransp.transportationName}
            </Link>
          ) : (
            <Button
              text="Привязать вручную"
              onClick={() => {
                onBindClick(transportation);
              }}
            />
          ),
      },
    ],
    [onBindClick]
  );

  const getItemId = useCallback(
    (transportation: ITransportationsTableItem) => String(transportation.id),
    []
  );

  const getRowColor = useCallback(
    (transportation: ITransportationsTableItem) =>
      getSuppliersDocTranspAmountIsCorrectColor({
        amountIsConfirmed: transportation.amountIsConfirmed,
        amountIsCorrect: transportation.amountIsCorrect,
      }),
    []
  );

  const showColumns = useMemo(
    () =>
      [
        'transportation',
        'index',
        'number',
        'waybillNumber',
        'confirmDate',
        'confirmNote',
        'confirmUser',
      ].concat(
        docType === SuppliersDocumentType.Adjustment
          ? [
              'invoiceNumber',
              'invoiceDate',
              'docNumber',
              'docType',
              'docDate',
              'amountBeforeAll',
              'amountBeforeNds',
              'amountChangeAll',
              'amountChangeNds',
              'amountAfterAll',
              'amountAfterNds',
            ]
          : [
              'name',
              'departureDate',
              'rascreditDate',
              'acceptDate',
              'type',
              'roadStationFrom',
              'roadStationTo',
              'messageType',
              'wagonNumber',
              'cargoName',
              'cargoWeight',
              'amount',
              'vatRate',
              'vatValue',
              'amountTotal',
            ]
      ),
    [docType]
  );

  return (
    <ListTable
      columns={columns}
      getItemId={getItemId}
      getRowColor={getRowColor}
      isFetching={isFetchingTransportations}
      items={transportations}
      selectedItems={selectedTransportations}
      showColumns={showColumns}
      sorting={sorting}
      onSelectedItemsChange={onSelectedTransportationsChange}
      onSortingChange={onSortingChange}
    />
  );
}

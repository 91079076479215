import { useApiClient } from '_core/api/context';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { useAsyncData } from '_core/useAsyncData';
import { Button, Intent } from '@blueprintjs/core';
import { fetchManyExpeditionRequests } from 'expeditionRequests/api';
import { IExpeditionRequestSerialized } from 'expeditionRequests/types';
import * as React from 'react';
import { useField } from 'react-final-form';
import { fetchManyTechrunRequests } from 'techrunRequests/api';
import { ITechrunRequest } from 'techrunRequests/types';

import * as css from './requestsTableField.module.css';

type RequestKind = 'expedition' | 'techrun';

interface IProps {
  name: string;
  requestKind: RequestKind;
  tableEmptyMessage: string;
}

export function RequestsTableField({
  name,
  requestKind,
  tableEmptyMessage,
}: IProps) {
  const { input, meta } = useField<number[]>(name);
  const api = useApiClient();

  const { data, isFetching } = useAsyncData(
    [api, input.value, requestKind],
    (): Promise<Array<IExpeditionRequestSerialized | ITechrunRequest>> =>
      requestKind === 'expedition'
        ? fetchManyExpeditionRequests(api, input.value)
        : fetchManyTechrunRequests(api, input.value)
  );

  return (
    <Table
      emptyMessage={tableEmptyMessage}
      isFetching={isFetching}
      items={data}
    >
      <Column
        id="requestName"
        label="Название заявки"
        cell={(request: IExpeditionRequestSerialized | ITechrunRequest) => (
          <LinkCell
            params={{ id: String(request.id) }}
            to={
              requestKind === 'expedition'
                ? 'expeditions.requests.view'
                : 'techruns.requests.view'
            }
          >
            {request.requestName}
          </LinkCell>
        )}
      />

      <Column
        id="actions"
        width={52}
        cell={(item: IExpeditionRequestSerialized | ITechrunRequest) => (
          <div className={css.actionsField}>
            <Button
              disabled={meta.submitting}
              icon="delete"
              intent={Intent.DANGER}
              minimal
              small
              onClick={() => {
                if (
                  // eslint-disable-next-line no-alert
                  window.confirm(
                    'Вы уверены что хотите удалить заявку из парка?'
                  )
                ) {
                  input.onChange(input.value.filter(id => id !== item.id));
                }
              }}
            />
          </div>
        )}
      />
    </Table>
  );
}

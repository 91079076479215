import cx from 'classnames';
import * as React from 'react';

import * as css from './linkStyledButton.module.css';

export function LinkStyledButton({
  className,
  ...otherProps
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) {
  return <button className={cx(className, css.root)} {...otherProps} />;
}

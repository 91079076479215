import { useApiClient } from '_core/api/context';
import { fetchRelated } from '_core/api/fetchRelated';
import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { EmptyListMessage } from '_core/feedback/emptyListMessage';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { required, validate } from '_core/forms/validators';
import { Checkbox } from '_core/inputs/checkbox';
import { Radio, RadioGroupForFinalForm } from '_core/inputs/radio';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { ISorting, SortingDirection } from '_core/sorting';
import { useAsyncData } from '_core/useAsyncData';
import { useDebouncedValue } from '_core/useDebouncedValue';
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  NonIdealState,
} from '@blueprintjs/core';
import dayjs from 'dayjs';
import {
  ExpeditionTransportationsSortingField,
  fetchExpeditionTransportations,
} from 'expeditionTransportations/api';
import { IExpeditionTransportation } from 'expeditionTransportations/types';
import { SubmissionErrors } from 'final-form';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useField } from 'react-final-form';
import { IStationSerialized } from 'stations/types';
import {
  fetchTechrunTransportations,
  TechrunTransportationsSortingField,
} from 'techrunTransportations/api';
import { ITechrunTransportation } from 'techrunTransportations/types';
import {
  getTransportationGroupLabel,
  getTransportationStateLabel,
} from 'transportations/types';

import * as css from './bindDialog.module.css';

interface IExpeditionTransportationItem
  extends Omit<
    IExpeditionTransportation,
    'arrivalStation' | 'departureStation'
  > {
  arrivalStation: IStationSerialized;
  departureStation: IStationSerialized;
}

interface ITechrunTransportationItem
  extends Omit<ITechrunTransportation, 'arrivalStation' | 'departureStation'> {
  arrivalStation: IStationSerialized;
  departureStation: IStationSerialized;
}

export enum TransportationKind {
  Expedition = 'expeditions',
  Techrun = 'techrun',
}

interface ITransportationFieldProps {
  areBoundShown: boolean;
  debouncedQuery: string;
  kind: TransportationKind;
  name: string;
}

function TransportationField({
  areBoundShown,
  debouncedQuery,
  kind,
  name,
}: ITransportationFieldProps) {
  const { input, meta } = useField<number | null, HTMLInputElement>(name, {
    allowNull: true,
  });

  const api = useApiClient();

  const [sorting, setSorting] = useState<ISorting>({
    field: 'transportationName',
    direction: SortingDirection.Ascending,
  });

  const { data, isFetching } = useAsyncData(
    [areBoundShown, kind, api, debouncedQuery, sorting],
    async () => {
      if (!debouncedQuery) {
        return null;
      }

      const expeditionTransportationsSorting: Array<
        ISorting<ExpeditionTransportationsSortingField>
      > = [];
      const techrunTransportationsSorting: Array<
        ISorting<TechrunTransportationsSortingField>
      > = [];

      switch (sorting.field) {
        case 'arrivalDate':
          expeditionTransportationsSorting.push({
            field: ExpeditionTransportationsSortingField.ArrivalDate,
            direction: sorting.direction,
          });
          techrunTransportationsSorting.push({
            field: TechrunTransportationsSortingField.ArrivalDate,
            direction: sorting.direction,
          });
          break;
        case 'departureDate':
          expeditionTransportationsSorting.push({
            field: ExpeditionTransportationsSortingField.DepartureDate,
            direction: sorting.direction,
          });
          techrunTransportationsSorting.push({
            field: TechrunTransportationsSortingField.DepartureDate,
            direction: sorting.direction,
          });
          break;
        case 'transportationName':
          expeditionTransportationsSorting.push({
            field: ExpeditionTransportationsSortingField.Name,
            direction: sorting.direction,
          });
          techrunTransportationsSorting.push({
            field: TechrunTransportationsSortingField.Name,
            direction: sorting.direction,
          });
          break;
        default:
          throw new Error(`Unexpected sorting field: "${sorting.field}"`);
      }

      const transportations: Array<
        IExpeditionTransportation | ITechrunTransportation
      > = await (kind === TransportationKind.Expedition
        ? fetchExpeditionTransportations(api, {
            hasSuppliersDocs: areBoundShown || undefined,
            sorting: expeditionTransportationsSorting,
            search: debouncedQuery,
          }).then(response => response.results)
        : fetchTechrunTransportations(api, {
            hasSuppliersDocs: areBoundShown || undefined,
            sorting: techrunTransportationsSorting,
            search: debouncedQuery,
          }).then(response => response.results));

      const transportationsWithRelated = (await fetchRelated(
        api,
        {
          arrivalStation: '/directories_trainstations',
          departureStation: '/directories_trainstations',
        },
        transportations
      )) as unknown as Array<
        IExpeditionTransportationItem | ITechrunTransportationItem
      >;

      return transportationsWithRelated;
    }
  );

  const columns: Array<
    ListTableColumn<IExpeditionTransportationItem | ITechrunTransportationItem>
  > = [
    {
      id: 'selected',
      label: '',
      defaultWidth: 38,
      renderCellContent: transportation => (
        <Radio
          checked={transportation.id === input.value}
          name={input.name}
          style={{ margin: 0 }}
          value={String(transportation.id)}
          onChange={event => {
            input.onChange(Number(event.currentTarget.value));
          }}
        />
      ),
    },
    {
      id: 'name',
      label: 'Наименование',
      defaultWidth: 170,
      copyCellContent: transportation => transportation.transportationName,
      renderCellContent: transportation => transportation.transportationName,
    },
    {
      id: 'supplierDocument',
      label: 'Счёт-фактура',
      defaultWidth: 170,
      copyCellContent: transportation =>
        transportation.supplierDocumentNumber
          ? transportation.supplierDocumentNumber.number
          : '',
      renderCellContent: transportation =>
        transportation.supplierDocumentNumber
          ? transportation.supplierDocumentNumber.number
          : null,
    },
    {
      id: 'departureStation',
      label: 'Станция отправления',
      defaultWidth: 170,
      copyCellContent: transportation => transportation.departureStation.name,
      renderCellContent: transportation => transportation.departureStation.name,
    },
    {
      id: 'departureDate',
      label: 'Дата отправления',
      defaultWidth: 90,
      sortable: true,
      copyCellContent: transportation =>
        transportation.departureDate
          ? dayjs(transportation.departureDate).format(DATE_FORMAT_DATE)
          : '',
      renderCellContent: transportation =>
        transportation.departureDate
          ? dayjs(transportation.departureDate).format(DATE_FORMAT_DATE)
          : null,
    },
    {
      id: 'arrivalStation',
      label: 'Станция назначения',
      defaultWidth: 170,
      copyCellContent: transportation => transportation.arrivalStation.name,
      renderCellContent: transportation => transportation.arrivalStation.name,
    },
    {
      id: 'arrivalDate',
      label: 'Дата прибытия',
      defaultWidth: 90,
      sortable: true,
      copyCellContent: transportation =>
        transportation.arrivalDate
          ? dayjs(transportation.arrivalDate).format(DATE_FORMAT_DATE)
          : '',
      renderCellContent: transportation =>
        transportation.arrivalDate
          ? dayjs(transportation.arrivalDate).format(DATE_FORMAT_DATE)
          : null,
    },
    {
      id: 'state',
      label: 'Состояние',
      defaultWidth: 100,
      copyCellContent: transportation =>
        getTransportationStateLabel(transportation.state),
      renderCellContent: transportation =>
        getTransportationStateLabel(transportation.state),
    },
    {
      id: 'isEmpty',
      label: 'Гружёная',
      defaultWidth: 90,
      copyCellContent: transportation =>
        transportation.isEmpty ? 'Порожняя' : 'Гружёная',
      renderCellContent: transportation => (
        <Icon
          icon={transportation.isEmpty ? 'delete' : 'confirm'}
          intent={transportation.isEmpty ? Intent.DANGER : Intent.SUCCESS}
        />
      ),
    },
    {
      id: 'group',
      label: 'Группа',
      defaultWidth: 140,
      copyCellContent: transportation =>
        getTransportationGroupLabel(transportation.group),
      renderCellContent: transportation =>
        getTransportationGroupLabel(transportation.group),
    },
  ];

  const getItemId = useCallback(
    (
      transportation: IExpeditionTransportationItem | ITechrunTransportationItem
    ) => String(transportation.id),
    []
  );

  const error = meta.error || meta.submitError;

  return (
    <VGrid stretch>
      {meta.touched && error ? (
        <Row>
          <FormErrors error={error} />
        </Row>
      ) : null}

      <Row containContent stretch>
        {!data ? (
          isFetching ? (
            <CenteredSpinner />
          ) : !debouncedQuery ? (
            <NonIdealState
              icon="search"
              description="Введите запрос в поисковую строку"
            />
          ) : (
            <GenericErrorMessage />
          )
        ) : data.length === 0 ? (
          isFetching ? (
            <CenteredSpinner />
          ) : (
            <EmptyListMessage description="Не удалось найти подходящие отправки" />
          )
        ) : (
          <ListTable
            columns={columns}
            getItemId={getItemId}
            isFetching={isFetching}
            items={data}
            sorting={sorting}
            stickyColumnCount={1}
            onSortingChange={setSorting}
          />
        )}
      </Row>
    </VGrid>
  );
}

export interface IBindDialogFormValues {
  kind: TransportationKind;
  transportation: number | null;
}

interface IProps {
  initialValues: IBindDialogFormValues;
  isOpen: boolean;
  wagonNumber: string;
  waybillNumber: string;
  onClose: () => void;
  onClosed: () => void;
  onSubmit: (values: IBindDialogFormValues) => Promise<SubmissionErrors | void>;
}

export function TransportationBindDialog({
  initialValues,
  isOpen,
  wagonNumber,
  waybillNumber,
  onClose,
  onClosed,
  onSubmit,
}: IProps) {
  const [areBoundShown, setAreBoundShown] = useState(false);
  const [query, setQuery] = useState(wagonNumber);

  const debouncedQuery = useDebouncedValue(query, 400);

  return (
    <FinalForm
      initialValues={initialValues}
      validate={validate({
        transportation: required('Выберите отправку для привязки'),
      })}
      onSubmit={onSubmit}
    >
      {({ error, handleSubmit, submitError, submitting, values }) => (
        <Dialog
          className={css.dialog}
          isOpen={isOpen}
          lazy
          title={`Привязка отправки (${waybillNumber} ${wagonNumber})`}
          onClose={onClose}
          onClosed={onClosed}
        >
          <BaseForm className={css.form} onSubmit={handleSubmit}>
            <div className={Classes.DIALOG_BODY}>
              <FormErrors error={error || submitError} />

              <RadioGroupForFinalForm name="kind" inline>
                <Radio
                  label="Экспедирование"
                  value={TransportationKind.Expedition}
                />

                <Radio label="Техрейс" value={TransportationKind.Techrun} />
              </RadioGroupForFinalForm>

              <FormGroup>
                <InputGroup
                  autoFocus
                  value={query}
                  onChange={event => {
                    setQuery(event.currentTarget.value);
                  }}
                />
              </FormGroup>

              <Checkbox
                checked={areBoundShown}
                label="Показать уже связанные"
                onChange={setAreBoundShown}
              />

              <TransportationField
                areBoundShown={areBoundShown}
                debouncedQuery={debouncedQuery}
                kind={values.kind}
                name="transportation"
              />
            </div>

            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button disabled={submitting} text="Отмена" onClick={onClose} />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  loading={submitting}
                  text="Привязать"
                  type="submit"
                />
              </div>
            </div>
          </BaseForm>
        </Dialog>
      )}
    </FinalForm>
  );
}

import * as React from 'react';
import { useRouter } from 'react-router5';
import { NavigationOptions } from 'router5';

const modifierKeys: Array<'altKey' | 'ctrlKey' | 'metaKey' | 'shiftKey'> = [
  'altKey',
  'ctrlKey',
  'metaKey',
  'shiftKey',
];

interface IProps {
  children?: React.ReactNode;
  className?: string;
  options?: NavigationOptions;
  params?: Record<string, unknown>;
  rel?: string;
  style?: React.CSSProperties;
  target?: string;
  title?: string;
  to: string | undefined;
  onClick?: (
    event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>
  ) => void;
}

export function Link({
  children,
  className,
  options = {},
  params = {},
  rel,
  style,
  target,
  title,
  to,
  onClick,
}: IProps) {
  const router = useRouter();
  const Component = to ? 'a' : 'span';

  return (
    <Component
      {...(to && { href: router.buildPath(to, params), rel, target })}
      className={className}
      style={style}
      title={title}
      onClick={event => {
        if (onClick) {
          onClick(event);
        }

        if (
          to != null &&
          target == null &&
          event.button === 0 &&
          modifierKeys.every(key => !event[key])
        ) {
          router.navigate(to, params, options);
          event.preventDefault();
        }
      }}
    >
      {children}
    </Component>
  );
}

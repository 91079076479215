// adapted from https://github.com/sindresorhus/pretty-bytes/blob/fe126eedd38947237d014740a12bfb39495cac45/index.js
const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export default function prettyBytes(n: number): string {
  let num = n;

  if (!Number.isFinite(num)) {
    throw new TypeError(`Expected a finite number, got ${typeof num}: ${num}`);
  }

  const neg = num < 0;
  const sign = neg ? '-' : '';

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return `${sign}${num} B`;
  }

  const exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    UNITS.length - 1
  );

  const numStr = Number((num / Math.pow(1000, exponent)).toPrecision(3));
  const unit = UNITS[exponent];

  return `${sign}${numStr} ${unit}`;
}

import { deepEqual } from 'fast-equals';
import * as React from 'react';
import invariant from 'tiny-invariant';

import { TreeTableNode, TreeTableRow, TreeTableRowNode } from './types';

interface ContextValue<T> {
  isNodeSelectable: (node: TreeTableNode<T>) => boolean;
  selectedNodes: Array<TreeTableNode<T>>;
  onSelectionCheckboxClick: (
    newChecked: boolean,
    event: React.MouseEvent,
    clickedRow: TreeTableRowNode<T>
  ) => void;
}

const Context = React.createContext<ContextValue<unknown> | null>(null);

if (__DEV__) {
  Context.displayName = 'TreeTableSelectionContext';
}

interface ProviderProps<T> {
  children: React.ReactNode;
  isNodeSelectable: (node: TreeTableNode<T>) => boolean;
  rows: Array<TreeTableRow<T>>;
  selectedNodes: Array<TreeTableNode<T>>;
  onSelectedNodesChange: (newSelectedNodes: Array<TreeTableNode<T>>) => void;
}

export function TreeTableSelectionProvider<T>({
  children,
  isNodeSelectable,
  rows,
  selectedNodes,
  onSelectedNodesChange,
}: ProviderProps<T>) {
  const lastSelectedRowRef = React.useRef<TreeTableRowNode<T> | null>(null);

  const value = React.useMemo((): ContextValue<T> => {
    return {
      isNodeSelectable,
      selectedNodes,

      onSelectionCheckboxClick: (newChecked, event, clickedRow) => {
        let newSelectedNodes = selectedNodes;

        if (
          event.shiftKey &&
          lastSelectedRowRef.current &&
          deepEqual(lastSelectedRowRef.current.path, clickedRow.path)
        ) {
          const lastSelectedRow = lastSelectedRowRef.current;

          const lastSelectedRowIndex = rows.indexOf(lastSelectedRow);
          const rowIndex = rows.indexOf(clickedRow);

          if (lastSelectedRowIndex !== -1 && rowIndex !== -1) {
            const loRowIndex =
              rowIndex < lastSelectedRowIndex ? rowIndex : lastSelectedRowIndex;

            const hiRowIndex =
              rowIndex > lastSelectedRowIndex ? rowIndex : lastSelectedRowIndex;

            const nodesToAdd: Array<TreeTableNode<T>> = [];
            const nodesToRemove: Array<TreeTableNode<T>> = [];

            for (let i = loRowIndex + 1; i < hiRowIndex; i++) {
              const row = rows[i];
              invariant(row.type === 'node');

              if (newChecked && !newSelectedNodes.includes(row.node)) {
                nodesToAdd.push(row.node);
              } else if (!newChecked && newSelectedNodes.includes(row.node)) {
                nodesToRemove.push(row.node);
              }
            }

            if (nodesToAdd.length !== 0) {
              newSelectedNodes = newSelectedNodes.concat(nodesToAdd);
            }

            if (nodesToRemove.length !== 0) {
              newSelectedNodes = newSelectedNodes.filter(
                node => !nodesToRemove.includes(node)
              );
            }
          }
        } else {
          lastSelectedRowRef.current = clickedRow;
        }

        if (
          newChecked &&
          !newSelectedNodes.includes(clickedRow.node) &&
          !clickedRow.node.children
        ) {
          newSelectedNodes = newSelectedNodes.concat([clickedRow.node]);
        } else if (
          !newChecked &&
          newSelectedNodes.includes(clickedRow.node) &&
          !clickedRow.node.children
        ) {
          newSelectedNodes = newSelectedNodes.filter(
            node => node !== clickedRow.node && !node.children?.nodes
          );
        }

        if (newChecked && clickedRow.node?.children?.nodes) {
          const uniqueNodes = clickedRow.node.children.nodes.filter(
            newNode =>
              !newSelectedNodes.some(oldNode => newNode === oldNode) ||
              newSelectedNodes.length === 0
          );

          newSelectedNodes = newSelectedNodes.concat([
            ...uniqueNodes,
            clickedRow.node,
          ]);
        } else if (!newChecked && clickedRow.node?.children?.nodes) {
          newSelectedNodes = newSelectedNodes.filter(
            node =>
              !clickedRow.node.children?.nodes.some(child => child === node) &&
              node !== clickedRow.node
          );
        }

        if (newSelectedNodes !== selectedNodes) {
          onSelectedNodesChange(newSelectedNodes);
        }
      },
    };
  }, [isNodeSelectable, rows, selectedNodes, onSelectedNodesChange]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useTreeTableSelection() {
  const value = React.useContext(Context);
  invariant(value);
  return value;
}

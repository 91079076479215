import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { ListTableColumn } from '_core/react-window/listTable';
import { TreeTableColumn } from '_core/react-window/treeTable';
import { createListToTreeTableColumnAdapter } from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { IContact } from 'contacts/api';
import { formatContactName } from 'contacts/utils';
import dayjs from 'dayjs';
import { IPartnerSerialized } from 'partners/api';
import * as React from 'react';

const fioColumn: ListTableColumn<IContact> = {
  id: 'fio',
  label: 'ФИО',
  defaultWidth: 300,
  copyCellContent: contact =>
    formatContactName(contact, { includeEmail: false }),
  renderCellContent: contact =>
    formatContactName(contact, { includeEmail: false }),
};

const jobTitleColumn: ListTableColumn<IContact> = {
  id: 'jobTitle',
  label: 'Должность',
  defaultWidth: 350,
  copyCellContent: contact => contact.jobTitle,
  renderCellContent: contact => contact.jobTitle,
};

const phoneColumn: ListTableColumn<IContact> = {
  id: 'phone',
  label: 'Телефон',
  defaultWidth: 150,
  copyCellContent: contact => contact.phone,
  renderCellContent: contact => contact.phone,
};

const phoneExtraColumn: ListTableColumn<IContact> = {
  id: 'phoneExtra',
  label: 'Телефон дополнительно',
  defaultWidth: 150,
  copyCellContent: contact => contact.phoneExtra,
  renderCellContent: contact => contact.phoneExtra,
};

const noteColumn: ListTableColumn<IContact> = {
  id: 'note',
  label: 'Примечание',
  defaultWidth: 174,
  copyCellContent: contact => contact.note,
  renderCellContent: contact => contact.note,
};

const modifiedColumn: ListTableColumn<IContact> = {
  id: 'modified',
  label: 'Изменён',
  defaultWidth: 130,
  sortable: true,
  copyCellContent: contact =>
    dayjs(contact.modified).format(DATE_FORMAT_DATETIME),
  renderCellContent: contact =>
    dayjs(contact.modified).format(DATE_FORMAT_DATETIME),
};

function formatContactEmail(contact: IContact) {
  return contact.email || '<не заполнено>';
}

export function getContactsListTableColumns({
  listParams,
  partnersById,
}: {
  listParams: string;
  partnersById: Record<number, IPartnerSerialized>;
}): Array<ListTableColumn<IContact>> {
  const emailColumn: ListTableColumn<IContact> = {
    id: 'email',
    label: 'Email',
    defaultWidth: 300,
    sortable: true,
    copyCellContent: contact => formatContactEmail(contact),
    renderCellContent: contact => (
      <Link params={{ id: contact.id, listParams }} to="contacts.edit">
        {formatContactEmail(contact)}
      </Link>
    ),
  };

  const partnerColumn: ListTableColumn<IContact> = {
    id: 'partner',
    label: 'Контрагент',
    defaultWidth: 350,
    sortable: true,
    copyCellContent: contact => {
      const partner = contact.partner ? partnersById[contact.partner] : null;

      return partner ? partner.shortName : '';
    },
    renderCellContent: contact => {
      const partner = contact.partner ? partnersById[contact.partner] : null;

      return partner ? (
        <Link params={{ id: partner.id }} to="partners.edit">
          {partner.shortName}
        </Link>
      ) : null;
    },
  };

  return [
    emailColumn,
    fioColumn,
    jobTitleColumn,
    phoneColumn,
    phoneExtraColumn,
    noteColumn,
    partnerColumn,
    modifiedColumn,
  ];
}

export type ContactsTreeTableNodeData =
  | {
      kind: 'partner';
      partner: IPartnerSerialized | null;
      contactsCount: number;
    }
  | { kind: 'contact'; contact: IContact };

const adaptColumn = createListToTreeTableColumnAdapter<
  IContact,
  ContactsTreeTableNodeData
>(nodeData => (nodeData.kind === 'contact' ? nodeData.contact : null));

function formatPartnerName(
  partner: IPartnerSerialized | null,
  contactsCount: number
) {
  return `${
    partner ? partner.shortName : '<Контрагент не указан>'
  } (${contactsCount})`;
}

export function getContactsTreeTableColumns(): Array<
  TreeTableColumn<ContactsTreeTableNodeData>
> {
  const nameColumn: TreeTableColumn<ContactsTreeTableNodeData> = {
    id: 'name',
    label: 'Наименование',
    defaultWidth: 350,
    sortable: true,
    expandable: true,
    copyCellContent: node => {
      switch (node.data.kind) {
        case 'partner': {
          const { contactsCount, partner } = node.data;

          return formatPartnerName(partner, contactsCount);
        }
        case 'contact':
          return formatContactName(node.data.contact);
      }
    },
    renderCellContent: node => {
      switch (node.data.kind) {
        case 'partner': {
          const { partner, contactsCount } = node.data;
          const formattedName = formatPartnerName(partner, contactsCount);

          return partner ? (
            <Ellipsis
              component={Link}
              params={{ id: partner.id }}
              rel="noopener"
              target="_blank"
              to="partners.edit"
            >
              {formattedName}
            </Ellipsis>
          ) : (
            <Ellipsis component="span">{formattedName}</Ellipsis>
          );
        }
        case 'contact': {
          const { contact } = node.data;

          return (
            <Link
              params={{ id: contact.id }}
              rel="noopener"
              target="_blank"
              to="contacts.edit"
            >
              {formatContactEmail(contact)}
            </Link>
          );
        }
      }
    },
  };

  return [nameColumn].concat(
    [
      fioColumn,
      jobTitleColumn,
      phoneColumn,
      phoneExtraColumn,
      noteColumn,
      modifiedColumn,
    ].map(adaptColumn)
  );
}

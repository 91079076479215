import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { Button, Intent } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { useRef } from 'react';

export interface IContactFormValues {
  email: string;
  firstName: string;
  firstNameRp: string;
  jobTitle: string;
  jobTitleRp: string;
  lastName: string;
  lastNameRp: string;
  middleName: string;
  middleNameRp: string;
  note: string;
  partner: number | null;
  phone: string;
  phoneExtra: string;
}

interface IProps {
  initialValues: IContactFormValues;
  navigationLock: IFormNavigationLock;
  onSave: (values: IContactFormValues) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IContactFormValues
  ) => Promise<SubmissionErrors | void>;
}

type SubmitTrigger = 'save' | 'saveAndContinue';

export function ContactForm({
  initialValues,
  navigationLock,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <FinalForm<IContactFormValues>
      initialValues={initialValues}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({ dirty, error, form, handleSubmit, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={4}>
                  <PartnersAutocompleteInFormGroupForFinalForm
                    id="partner"
                    label="Контрагент"
                    name="partner"
                  />

                  <FormGroupForFinalForm
                    label="Email"
                    labelFor="email"
                    name="email"
                  >
                    <InputGroupForFinalForm
                      id="email"
                      name="email"
                      required
                      type="email"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Телефон"
                    labelFor="phone"
                    name="phone"
                  >
                    <InputGroupForFinalForm id="phone" name="phone" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Телефон дополнительно"
                    labelFor="phoneExtra"
                    name="phoneExtra"
                  >
                    <InputGroupForFinalForm id="phoneExtra" name="phoneExtra" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Фамилия"
                    labelFor="lastName"
                    name="lastName"
                  >
                    <InputGroupForFinalForm id="lastName" name="lastName" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Имя"
                    labelFor="firstName"
                    name="firstName"
                  >
                    <InputGroupForFinalForm id="firstName" name="firstName" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Отчество"
                    labelFor="middleName"
                    name="middleName"
                  >
                    <InputGroupForFinalForm id="middleName" name="middleName" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Должность"
                    labelFor="jobTitle"
                    name="jobTitle"
                  >
                    <InputGroupForFinalForm id="jobTitle" name="jobTitle" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Фамилия р.п."
                    labelFor="lastNameRp"
                    name="lastNameRp"
                  >
                    <InputGroupForFinalForm id="lastNameRp" name="lastNameRp" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Имя р.п."
                    labelFor="firstNameRp"
                    name="firstNameRp"
                  >
                    <InputGroupForFinalForm
                      id="firstNameRp"
                      name="firstNameRp"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Отчество р.п."
                    labelFor="middleNameRp"
                    name="middleNameRp"
                  >
                    <InputGroupForFinalForm
                      id="middleNameRp"
                      name="middleNameRp"
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Должность р.п."
                    labelFor="jobTitleRp"
                    name="jobTitleRp"
                  >
                    <InputGroupForFinalForm id="jobTitleRp" name="jobTitleRp" />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Примечание"
                    labelFor="note"
                    name="note"
                  >
                    <InputMultipleForFinalForm id="note" name="note" rows={5} />
                  </FormGroupForFinalForm>
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitTriggerRef.current = 'save';
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { indexByLastItem } from '_core/indexBy';
import { isNotNull } from '_core/isNotNull';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import { Row, VGrid } from 'layout/contentLayout';
import { fetchManyPartners } from 'partners/api';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { fetchRentProtocolsSell } from 'rentProtocolsSell/api';
import {
  fetchRentWagonSell,
  RentWagonSellPause,
  updateRentWagonSell,
} from 'rentWagonSell/api';
import { fetchManyShipmentInfoItems } from 'shipmentInfo/api';
import { fetchManySuppliersDocuments } from 'suppliersDocuments/api';

import { IRentWagonSellFormValues, RentWagonSellForm } from './form';

export default function RentWagonSellEditRoute() {
  const { route, router } = useRouteNode('rentWagons.sell.edit');
  const navigationLock = useFormNavigationLock('rentWagons.sell.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    async () => {
      const rentWagon = await fetchRentWagonSell(api, route.params.id);

      const [partnersIndex, rentProtocols, shipmentsIndex, supplierDocsIndex] =
        await Promise.all([
          fetchManyPartners(
            api,
            rentWagon.incomes
              .map(income => income.partner)
              .concat(rentWagon.expenses.map(expense => expense.partner))
              .filter(isNotNull)
          ).then(partners => indexByLastItem(partners, partner => partner.id)),

          fetchAllPages(page =>
            fetchRentProtocolsSell(api, {
              page,
              partner: rentWagon.supplier,
            })
          ).then(response => response.results),

          fetchManyShipmentInfoItems(
            api,
            rentWagon.incomes.map(income => income.shipment).filter(isNotNull)
          ).then(shipments =>
            indexByLastItem(shipments, shipment => shipment.id)
          ),

          fetchManySuppliersDocuments(
            api,
            rentWagon.expenses
              .map(expense => expense.supplierDoc)
              .filter(isNotNull)
          ).then(supplierDocs =>
            indexByLastItem(supplierDocs, supplierDoc => supplierDoc.id)
          ),
        ]);

      const initialValues: IRentWagonSellFormValues = {
        ...rentWagon,
        expenses: rentWagon.expenses.map(expense => ({
          ...expense,
          partner:
            expense.partner == null ? null : partnersIndex[expense.partner],
          supplierDoc:
            expense.supplierDoc == null
              ? null
              : supplierDocsIndex[expense.supplierDoc],
        })),
        incomes: rentWagon.incomes.map(income => ({
          ...income,
          partner:
            income.partner == null ? null : partnersIndex[income.partner],
          shipment:
            income.shipment == null ? null : shipmentsIndex[income.shipment],
        })),
      };

      return {
        initialValues,
        rentProtocols,
        rentWagon,
      };
    }
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const persist = (values: IRentWagonSellFormValues) =>
    updateRentWagonSell(api, route.params.id, {
      ...data.rentWagon,
      ...values,
      expenses: values.expenses.map(expense => ({
        ...expense,
        partner: expense.partner == null ? null : expense.partner.id,
        supplierDoc:
          expense.supplierDoc == null ? null : expense.supplierDoc.id,
      })),
      incomes: values.incomes.map(income => ({
        ...income,
        partner: income.partner == null ? null : income.partner.id,
        shipment: income.shipment == null ? null : income.shipment.id,
      })),
      pauses: values.pauses.map(
        (pause, index) =>
          new RentWagonSellPause({
            ...data.rentWagon.pauses[index],
            note: pause.note,
          })
      ),
    });

  const title = data.rentWagon.wagon;

  function handleSavingError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось сохранить аренду: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Вагон аренды исходящей"
              params={listParams}
              to="rentWagons.sell"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <RentWagonSellForm
            initialValues={data.initialValues}
            navigationLock={navigationLock}
            rentProtocols={data.rentProtocols}
            rentWagon={data.rentWagon}
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('rentWagons.sell', listParams);
                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedRentWagon = await persist(values);

                updateData(
                  prevData =>
                    prevData && {
                      ...prevData,
                      initialValues: values,
                      rentWagon: updatedRentWagon,
                    }
                );

                return undefined;
              } catch (err) {
                return handleSavingError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { DocumentStatus } from 'documents/types';
import { ExpeditionStationType } from 'expeditions/types';
import { IPartnerInline } from 'partners/api';
import { VatRate } from 'vatRates/vatRates';

const BASE_ENDPOINT = '/expeditions_protocols_sell';

export enum ApplyExpeditionCostFor {
  Any = 'ANY',
  Empty = 'EMPTY',
  Load = 'LOAD',
  NotApply = 'NOT_APPLY',
}

interface IExpeditionProtocolSellRateSerialized {
  confirmedTransportationsCount: number;
  costEmpty: string;
  costLoaded: string;
  id: number;
  partner: IPartnerInline;
  protocol: number;
  protocolName: string;
  station: number;
  tariffCompensationEmpty: string;
  tariffCompensationLoaded: string;
  tariffEmpty: string;
  tariffLoaded: string;
}

export enum ExpeditionProtocolSellRateType {
  Day = 'DAY',
  Ton = 'TON',
  Wagon = 'WAGON',
}

interface IExpeditionProtocolSellSerialized {
  applyExpeditionCostFor: ApplyExpeditionCostFor;
  author: number | null;
  authorModified: number | null;
  compensateEmptyTariff: boolean;
  compensateLoadedTariff: boolean;
  contract: number;
  created: string;
  emptyTariffPercent: string;
  endDate: string;
  expeditionCost: string | null;
  expeditionCostTotal: string | null;
  expeditionCostVat: VatRate;
  expeditionCostVatValue: string | null;
  files: string[];
  id: number;
  isEmptyTariffPercent: boolean;
  isLoadedTariffPercent: boolean;
  loadedTariffPercent: string;
  modified: string;
  note: string;
  number: string;
  partner: number;
  partnerSign: number;
  protocolName: string;
  protocolNumber: string;
  rates: IExpeditionProtocolSellRateSerialized[];
  rateType: ExpeditionProtocolSellRateType;
  signerAuthority: string;
  startDate: string;
  station: number;
  stationType: ExpeditionStationType;
  status: DocumentStatus;
}

export interface IExpeditionProtocolSell
  extends Omit<
    IExpeditionProtocolSellSerialized,
    'created' | 'endDate' | 'modified' | 'startDate'
  > {
  created: Date;
  endDate: Date;
  modified: Date;
  startDate: Date;
}

function deserializeExpeditionProtocolSell({
  created,
  endDate,
  modified,
  startDate,
  ...otherProps
}: IExpeditionProtocolSellSerialized): IExpeditionProtocolSell {
  return {
    ...otherProps,
    created: parseDate(created),
    endDate: parseDate(endDate),
    modified: parseDate(modified),
    startDate: parseDate(startDate),
  };
}

export enum ExpeditionProtocolsSellSortingField {
  Created = 'created',
  EndDate = 'endDate',
  OrderingNumber = 'orderingNumber',
  ProtocolName = 'protocolName',
  StartDate = 'startDate',
}

interface IFetchExpeditionProtocolsSellParams {
  contract?: number;
  ids?: number[];
  isArchived?: boolean;
  page?: number;
  partner?: number;
  sorting?: Array<ISorting<ExpeditionProtocolsSellSortingField>>;
}

export async function fetchExpeditionProtocolsSell(
  api: ApiClient,
  {
    contract,
    ids,
    isArchived,
    page,
    partner,
    sorting,
  }: IFetchExpeditionProtocolsSellParams = {}
): Promise<ListResponse<IExpeditionProtocolSell>> {
  const response = await api.get<
    ListResponse<IExpeditionProtocolSellSerialized>
  >(BASE_ENDPOINT, {
    contract,
    ids: ids?.join(','),
    isArchived,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    partner,
  });

  return {
    ...response,
    results: response.results.map(deserializeExpeditionProtocolSell),
  };
}

interface ICreateOrUpdateExpeditionProtocolSellParamsRate {
  confirmedTransportationsCount: number;
  costEmpty: string;
  costLoaded: string;
  station: number | null;
  tariffEmpty: string | null;
  tariffLoaded: string | null;
}

interface ICreateOrUpdateExpeditionProtocolSellParams {
  applyExpeditionCostFor: ApplyExpeditionCostFor;
  compensateEmptyTariff: boolean;
  compensateLoadedTariff: boolean;
  contract: number | null;
  emptyTariffPercent: string | null;
  endDate: Date | null;
  expeditionCost: string | null;
  expeditionCostTotal: string;
  expeditionCostVat: VatRate;
  files: string[];
  isEmptyTariffPercent: boolean;
  isLoadedTariffPercent: boolean;
  loadedTariffPercent: string | null;
  note: string;
  number: string;
  partnerSign: number | null;
  rates: ICreateOrUpdateExpeditionProtocolSellParamsRate[];
  rateType: ExpeditionProtocolSellRateType;
  signerAuthority: string;
  startDate: Date | null;
  station: number | null;
  stationType: ExpeditionStationType;
  status: DocumentStatus;
}

function serializeCreateOrUpdateExpeditionProtocolSellParams({
  endDate,
  startDate,
  ...otherParams
}: ICreateOrUpdateExpeditionProtocolSellParams) {
  return {
    ...otherParams,
    endDate: endDate && dayjs(endDate).format(DATE_FORMAT_API_DATE),
    startDate: startDate && dayjs(startDate).format(DATE_FORMAT_API_DATE),
  };
}

export async function createExpeditionProtocolSell(
  api: ApiClient,
  params: ICreateOrUpdateExpeditionProtocolSellParams
) {
  const createdProtocol = await api.post<IExpeditionProtocolSellSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateExpeditionProtocolSellParams(params)
  );

  return deserializeExpeditionProtocolSell(createdProtocol);
}

export async function updateExpeditionProtocolSell(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateExpeditionProtocolSellParams
) {
  const updatedProtocol = await api.put<IExpeditionProtocolSellSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateExpeditionProtocolSellParams(params)
  );

  return deserializeExpeditionProtocolSell(updatedProtocol);
}

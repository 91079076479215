import KeyCodes from '_core/keyCodes';
import { SortingDirection } from '_core/sorting';
import { Ellipsis } from '_core/strings/ellipsis';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';

import * as css from './cells.module.css';
import { useResizeUi } from './resizeUi';

export function BaseCell({
  boldBorderTop,
  children,
  head,
  noPadding,
  style,
  onDoubleClick,
  onMouseDown,
  onMouseEnter,
}: {
  boldBorderTop?: boolean;
  children?: React.ReactNode;
  head?: boolean;
  noPadding?: boolean;
  style: React.CSSProperties;
  onDoubleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
}) {
  return (
    <div
      className={cx(css.baseCell, {
        [css.baseCell_boldBorderTop]: boldBorderTop,
        [css.baseCell_head]: head,
        [css.baseCell_isSelectable]: onMouseDown != null,
        [css.baseCell_noPadding]: noPadding,
      })}
      style={style}
      onDoubleClick={onDoubleClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    >
      <div className={css.baseCellInner}>{children}</div>
    </div>
  );
}

export function CellEditor({
  children,
  style,
}: {
  children?: React.ReactNode;
  style: React.CSSProperties;
}) {
  return (
    <div className={css.cellEditor} style={style}>
      {children}
    </div>
  );
}

export function EditableTextCell({
  style,
  value,
  onConfirm,
  onMouseDown,
  onMouseEnter,
}: {
  style: React.CSSProperties;
  value: string;
  onConfirm: (newValue: string) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
}) {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [draftValue, setDraftValue] = React.useState(value);

  React.useLayoutEffect(() => {
    const inputEl = inputRef.current;

    if (!inputEl) {
      return;
    }

    if (isEditing) {
      inputEl.focus();
    }
  }, [isEditing]);

  return isEditing ? (
    <div className={css.editableTextCellInputWrapper} style={style}>
      <input
        className={css.editableTextCellInput}
        ref={inputRef}
        value={draftValue}
        onBlur={() => {
          onConfirm(draftValue);
          setIsEditing(false);
        }}
        onChange={event => {
          setDraftValue(event.currentTarget.value);
        }}
        onKeyDown={event => {
          if (event.keyCode === KeyCodes.Enter) {
            event.preventDefault();
            onConfirm(draftValue);
            setIsEditing(false);
          } else if (event.keyCode === KeyCodes.Escape) {
            event.preventDefault();
            setIsEditing(false);
          }
        }}
      />
    </div>
  ) : (
    <BaseCell
      style={style}
      onDoubleClick={() => {
        setDraftValue(value);
        setIsEditing(true);
      }}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    >
      <Ellipsis component="span">{value}</Ellipsis>
    </BaseCell>
  );
}

function HeadCellResizeHandle({ columnIndex }: { columnIndex: number }) {
  const resizeUi = useResizeUi();

  const handleMouseDown = React.useCallback(
    (event: React.MouseEvent) => {
      resizeUi.handleMouseDown(event, columnIndex);
    },

    [columnIndex, resizeUi]
  );

  const ref = React.useCallback(
    (el: HTMLDivElement | null) => {
      resizeUi.setResizeHandleEl(columnIndex, el);
    },

    [columnIndex, resizeUi]
  );

  return (
    <div
      className={css.headCellResizeHandle}
      ref={ref}
      onMouseDown={handleMouseDown}
    />
  );
}

export function HeadCell({
  children,
  columnIndex,
  prepend,
  sortingDirection,
  style,
  onSortingToggle,
}: {
  children: React.ReactNode;
  columnIndex: number;
  prepend?: React.ReactNode;
  sortingDirection: SortingDirection | undefined;
  style: React.CSSProperties;
  onSortingToggle: (() => void) | undefined;
}) {
  return (
    <BaseCell head style={style}>
      <div className={css.headCellContentWrapper}>
        {prepend && (
          <div className={css.headCellContentPrepended}>{prepend}</div>
        )}

        <div className={css.headCellContentMain}>
          {onSortingToggle ? (
            <button
              className={cx(css.headCellSortingButton, {
                [css.headCellSortingButton_withPrepend]: Boolean(prepend),
              })}
              type="button"
              onClick={onSortingToggle}
            >
              <span className={css.headCellSortingButtonText}>{children}</span>

              {sortingDirection != null ? (
                <Icon
                  className={css.headCellSortingButtonIcon}
                  icon={
                    sortingDirection === SortingDirection.Ascending
                      ? 'sort-asc'
                      : 'sort-desc'
                  }
                />
              ) : null}
            </button>
          ) : (
            children
          )}
        </div>
      </div>

      <HeadCellResizeHandle columnIndex={columnIndex} />
    </BaseCell>
  );
}

import { useApiClient } from '_core/api/context';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import {
  IContractPurchase,
  updateContractPurchase,
} from 'contractsPurchase/api';
import * as React from 'react';
import { useState } from 'react';

import { ContractForm } from './form';

interface IProps {
  contract: IContractPurchase;
  updateContract: (updatedContract: IContractPurchase) => void;
}

export function ContractsPurchaseViewMainTab({
  contract,
  updateContract,
}: IProps) {
  const api = useApiClient();
  const [editingLocked, setEditingLocked] = useState(true);
  const navigationLock = useFormNavigationLock('contracts.purchase.view.main');

  return (
    <ContractForm
      contract={contract}
      docxUrl={
        contract.template != null
          ? api.buildApiUrl(`/contracts_purchase/${contract.id}/download_docx`)
          : undefined
      }
      editingLocked={editingLocked}
      initialValues={{
        additionalDowntimeRate: contract.additionalDowntimeRate,
        daysLoad: String(contract.daysLoad),
        daysUnload: String(contract.daysUnload),
        downtimeLimit: String(contract.downtimeLimit),
        downtimeRate: contract.downtimeRate,
        downtimeVatRate: contract.downtimeVatRate,
        files: contract.files,
        hasRent: contract.hasRent,
        hasTechrun: contract.hasTechrun,
        number: contract.number,
        owners: contract.owners.map(owner => ({ owner })),
        partner: contract.partner,
        partnerContact: contract.partnerContact,
        partnerSign: contract.partnerSign,
        signDate: contract.signDate,
        signPlace: contract.signPlace,
        status: contract.status,
        template: contract.template,
      }}
      navigationLock={navigationLock}
      numberIsRequired
      onCancelEditing={
        editingLocked
          ? undefined
          : () => {
              setEditingLocked(true);
            }
      }
      onStartEditing={
        editingLocked
          ? () => {
              if (
                // eslint-disable-next-line no-alert
                window.confirm('Вы собираетесь изменить сохраненный договор')
              ) {
                setEditingLocked(false);
              }
            }
          : undefined
      }
      onSubmit={async ({ owners, ...otherValues }) => {
        try {
          const updatedContract = await updateContractPurchase(
            api,
            contract.id,
            {
              ...contract,
              ...otherValues,
              owners: owners.map(owner => owner.owner),
            }
          );

          updateContract(updatedContract);
          setEditingLocked(true);
          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось изменить договор: Непредвиденная ошибка'
          );
        }
      }}
    />
  );
}

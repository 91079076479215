import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { DocumentStatus } from 'documents/types';
import { VatRate } from 'vatRates/vatRates';

const BASE_ENDPOINT = '/contracts_purchase_agreement';

interface IContractsPurchaseAgreementSerialized {
  additionalDowntimeRate: string;
  author: number | null;
  authorModified: number | null;
  contract: number;
  created: string;
  date: string;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  id: number;
  modified: string;
  note: string;
  number: string;
  status: DocumentStatus;
}

export interface IContractsPurchaseAgreement
  extends Omit<
    IContractsPurchaseAgreementSerialized,
    'created' | 'date' | 'modified'
  > {
  created: Date;
  date: Date;
  modified: Date;
}

function deserializeContractPurchaseAgreement({
  created,
  date,
  modified,
  ...otherProps
}: IContractsPurchaseAgreementSerialized): IContractsPurchaseAgreement {
  return {
    ...otherProps,
    created: parseDate(created),
    date: parseDate(date),
    modified: parseDate(modified),
  };
}

export enum ContractsPurchaseAgreementsSortingField {
  Created = 'created',
  Date = 'date',
  OrderingNumber = 'orderingNumber',
}

interface IFetchContractsPurchaseAgreementsParams {
  contract?: number;
  page?: number;
  sorting?: Array<ISorting<ContractsPurchaseAgreementsSortingField>>;
}

export async function fetchContractsPurchaseAgreements(
  api: ApiClient,
  { contract, page, sorting }: IFetchContractsPurchaseAgreementsParams = {}
): Promise<ListResponse<IContractsPurchaseAgreement>> {
  const response = await api.get<
    ListResponse<IContractsPurchaseAgreementSerialized>
  >(BASE_ENDPOINT, {
    contract,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
  });

  return {
    ...response,
    results: response.results.map(deserializeContractPurchaseAgreement),
  };
}

interface ICreateOrUpdateContractsPurchaseAgreementParams {
  additionalDowntimeRate: string;
  contract: number;
  date: Date | null;
  daysLoad: number;
  daysUnload: number;
  downtimeLimit: number;
  downtimeRate: string;
  downtimeVatRate: VatRate;
  files: string[];
  note: string;
  number: string;
  status: DocumentStatus;
}

function serializeCreateOrUpdateContractsPurchaseAgreementParams({
  date,
  ...otherParams
}: ICreateOrUpdateContractsPurchaseAgreementParams) {
  return {
    ...otherParams,
    date: date && dayjs(date).format(DATE_FORMAT_API_DATE),
  };
}

export async function createContractsPurchaseAgreement(
  api: ApiClient,
  params: ICreateOrUpdateContractsPurchaseAgreementParams
) {
  const updatedAgreement =
    await api.post<IContractsPurchaseAgreementSerialized>(
      BASE_ENDPOINT,
      serializeCreateOrUpdateContractsPurchaseAgreementParams(params)
    );

  return deserializeContractPurchaseAgreement(updatedAgreement);
}

export async function updateContractsPurchaseAgreement(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateContractsPurchaseAgreementParams
) {
  const updatedAgreement = await api.put<IContractsPurchaseAgreementSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateContractsPurchaseAgreementParams(params)
  );

  return deserializeContractPurchaseAgreement(updatedAgreement);
}

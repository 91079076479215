import { identity } from '_core/fp/identity';
import { InputGroup, InputGroupProps2, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useField } from 'react-final-form';

interface ForFinalFormProps
  extends Omit<InputGroupProps2, 'value' | 'onChange'> {
  name: string;
  onChange?: (newValue: string) => void;
}

export function InputGroupForFinalForm({
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  ...otherProps
}: ForFinalFormProps) {
  const { input, meta } = useField<string>(name, {
    // this identity fn is needed because by default final-form removes field
    // key completely when it's set to an empty string
    parse: identity,
  });

  return (
    <InputGroup
      disabled={meta.submitting || disabled}
      intent={
        meta.touched && (meta.error || meta.submitError)
          ? Intent.DANGER
          : undefined
      }
      name={input.name}
      onBlur={event => {
        input.onBlur(event);
        onBlur?.(event);
      }}
      onChange={event => {
        const newValue = event.currentTarget.value;
        input.onChange(newValue);
        onChange?.(newValue);
      }}
      onFocus={event => {
        input.onFocus(event);
        onFocus?.(event);
      }}
      value={input.value}
      {...otherProps}
    />
  );
}

import { Select } from '_core/inputs/select';
import { FormGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { Field } from 'react-final-form';

import { DocumentStatusIndicator } from './statusIndicator';
import * as css from './statusSelect.module.css';
import { DocumentStatus, documentStatusOptions } from './types';

interface ICommonProps {
  disabled?: boolean;
  editingLocked?: boolean;
  id?: string;
  label: string;
}

interface IProps extends ICommonProps {
  error: string | undefined;
  isInvalid?: boolean;
  name?: string;
  value: DocumentStatus;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  onChange: (newValue: DocumentStatus) => void;
  onFocus?: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

function DocumentStatusSelect({
  disabled,
  editingLocked,
  error,
  id,
  isInvalid,
  name,
  label,
  value,
  onBlur,
  onChange,
  onFocus,
}: IProps) {
  return (
    <div className={css.root}>
      <div className={css.selectWrapper}>
        <FormGroup
          helperText={isInvalid && error}
          intent={isInvalid ? Intent.DANGER : undefined}
          label={label}
          labelFor={id}
        >
          <Select
            disabled={disabled}
            editingLocked={editingLocked}
            fill
            id={id}
            name={name}
            options={documentStatusOptions}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
          />
        </FormGroup>
      </div>

      <div className={css.statusIndicatorWrapper}>
        <DocumentStatusIndicator status={value} />
      </div>
    </div>
  );
}

export function DocumentStatusSelectForFinalForm({
  disabled,
  name,
  ...otherProps
}: ICommonProps & { name: string }) {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        const error = meta.error || meta.submitError;

        return (
          <DocumentStatusSelect
            disabled={meta.submitting || disabled}
            error={error}
            isInvalid={meta.touched && Boolean(error)}
            name={input.name}
            onBlur={input.onBlur}
            onChange={input.onChange}
            onFocus={input.onFocus}
            value={input.value}
            {...otherProps}
          />
        );
      }}
    </Field>
  );
}

import { datePickerLocaleUtils } from '_core/dates/datePickerLocaleUtils';
import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { FormGroup, Intent } from '@blueprintjs/core';
import {
  DateInput as BlueprintDateInput,
  DatePickerModifiers,
} from '@blueprintjs/datetime';
import dayjs from 'dayjs';
import * as React from 'react';
import { useField } from 'react-final-form';

const defaultMaxDate = new Date(2025, 11, 31);

interface Props {
  closeOnSelection?: boolean;
  dateFormat?: string;
  disabled?: boolean;
  editingLocked?: boolean;
  fill?: boolean;
  id?: string;
  isInvalid?: boolean;
  maxDate?: Date;
  minDate?: Date;
  modifiers?: DatePickerModifiers;
  name?: string;
  readOnly?: boolean;
  usePortal?: boolean;
  value?: string | Date | null;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (newDate: Date | null) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

function DateInput({
  closeOnSelection,
  dateFormat = DATE_FORMAT_DATE,
  disabled,
  editingLocked,
  fill,
  id,
  isInvalid,
  maxDate = defaultMaxDate,
  minDate,
  modifiers,
  name,
  readOnly,
  usePortal,
  value,
  onBlur,
  onChange,
  onFocus,
}: Props) {
  return (
    <BlueprintDateInput
      canClearSelection={false}
      closeOnSelection={closeOnSelection}
      disabled={disabled}
      formatDate={(date, locale) => {
        if (!date) {
          return '';
        }

        const d = dayjs(date);

        if (locale) {
          d.locale(locale);
        }

        return d.format(dateFormat);
      }}
      inputProps={{
        id,
        intent: isInvalid ? Intent.DANGER : undefined,
        name,
        placeholder: disabled || editingLocked || readOnly ? '' : undefined,
        readOnly: editingLocked || readOnly,
        onBlur,
        onFocus,
      }}
      invalidDateMessage="Некорректная дата"
      locale="ru"
      localeUtils={datePickerLocaleUtils}
      maxDate={maxDate}
      minDate={minDate}
      modifiers={modifiers}
      outOfRangeMessage="Дата вне допустимого диапазона"
      parseDate={(str, locale) => {
        if (!str) {
          return null;
        }

        const d = dayjs(str, { format: dateFormat, locale });

        if (!d.isValid()) {
          return false;
        }

        return d.toDate();
      }}
      popoverProps={{
        disabled: disabled || editingLocked || readOnly,
        targetTagName: fill ? 'div' : undefined,
        usePortal,
        wrapperTagName: fill ? 'div' : undefined,
      }}
      value={value ? dayjs(value).toDate() : undefined}
      onChange={onChange}
    />
  );
}

export function DateInputForFinalForm({
  disabled,
  name,
  onChange,
  ...otherProps
}: Omit<Props, 'value' | 'onBlur' | 'onChange' | 'onFocus'> & {
  name: string;
  onChange?: (newValue: Date | null) => void;
}) {
  const { input, meta } = useField<string | Date | null>(name);
  const error = meta.error || meta.submitError;

  return (
    <DateInput
      disabled={meta.submitting || disabled}
      isInvalid={meta.touched && Boolean(error)}
      name={input.name}
      onBlur={input.onBlur}
      onChange={newValue => {
        onChange?.(newValue);
        input.onChange(newValue);
      }}
      onFocus={input.onFocus}
      value={input.value}
      {...otherProps}
    />
  );
}

interface InFormGroupProps extends Props {
  error?: string;
  label?: string;
  required?: boolean;
}

export function DateInputInFormGroup({
  closeOnSelection,
  dateFormat,
  disabled,
  editingLocked,
  error,
  fill,
  id,
  isInvalid,
  label,
  maxDate,
  minDate,
  modifiers,
  name,
  readOnly,
  required,
  usePortal,
  value,
  onBlur,
  onChange,
  onFocus,
}: InFormGroupProps) {
  return (
    <FormGroup
      labelFor={id}
      helperText={isInvalid && error}
      intent={isInvalid ? Intent.DANGER : undefined}
      label={label}
      labelInfo={required ? '*' : undefined}
    >
      <DateInput
        closeOnSelection={closeOnSelection}
        dateFormat={dateFormat}
        disabled={disabled}
        editingLocked={editingLocked}
        fill={fill}
        id={id}
        isInvalid={isInvalid}
        maxDate={maxDate}
        minDate={minDate}
        modifiers={modifiers}
        name={name}
        readOnly={readOnly}
        usePortal={usePortal}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    </FormGroup>
  );
}

export function DateInputInFormGroupForFinalForm({
  disabled,
  name,
  onChange,
  ...otherProps
}: Omit<InFormGroupProps, 'value' | 'onBlur' | 'onChange' | 'onFocus'> & {
  name: string;
  onChange?: (newValue: Date | null) => void;
}) {
  const { input, meta } = useField<string | Date | null>(name);
  const error = meta.error || meta.submitError;

  return (
    <DateInputInFormGroup
      disabled={meta.submitting || disabled}
      error={error}
      isInvalid={meta.touched && Boolean(error)}
      name={input.name}
      onBlur={input.onBlur}
      onChange={newValue => {
        onChange?.(newValue);
        input.onChange(newValue);
      }}
      onFocus={input.onFocus}
      value={input.value}
      {...otherProps}
    />
  );
}

import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { createConsigner } from 'consigners/api';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo, useRef } from 'react';
import { useRouteNode } from 'react-router5';

import { ConsignersForm, IConsignersFormValues, SubmitTrigger } from './form';

export default function ConsignersCreateRoute() {
  const { route, router } = useRouteNode('consigners.create');
  const api = useApiClient();
  const navigationLock = useFormNavigationLock('consigners.create');
  const submitTriggerRef = useRef<SubmitTrigger>('save');

  const initialValues = useMemo(
    (): IConsignersFormValues => ({
      email: '',
      legalAddress: '',
      name: '',
      note: '',
      okpo: '',
      phone: '',
    }),
    []
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  const title = 'Новый грузоотправитель';

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Грузоотправители"
              params={listParams}
              to="consigners"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <ConsignersForm
            initialValues={initialValues}
            navigationLock={navigationLock}
            setSubmitTrigger={submitTrigger => {
              submitTriggerRef.current = submitTrigger;
            }}
            onSubmit={async values => {
              try {
                const createdConsigner = await createConsigner(api, {
                  partner: null,
                  ...values,
                });

                navigationLock.unlock();

                if (submitTriggerRef.current === 'save') {
                  router.navigate('consigners', listParams);
                } else {
                  router.navigate('consigners.edit', {
                    id: createdConsigner.id,
                    listParams: route.params.listParams,
                  });
                }

                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось создать грузоотправителя: Непредвиденная ошибка'
                );
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

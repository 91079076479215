import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { Link } from '_core/router5/link';
import { useErpSelector } from '_erp/redux';
import {
  Button,
  Collapse,
  HTMLTable,
  Icon,
  NonIdealState,
} from '@blueprintjs/core';
import { IMyDocumentsTechrunsRequestsItem } from 'myDocuments/api';
import * as React from 'react';
import { useState } from 'react';
import invariant from 'tiny-invariant';

import { selectWagonsStatesWidgetData } from '../redux';
import * as css from './wagonsStatesWidget.module.css';

function RequestItem({
  isExpanded,
  stats,
  onIsExpandedToggle,
}: {
  isExpanded: boolean;
  stats: IMyDocumentsTechrunsRequestsItem;
  onIsExpandedToggle: () => void;
}) {
  return (
    <div className={css.requestItem}>
      <div className={css.requestItemHeader}>
        <div className={css.requestItemToggleAndName}>
          <Icon
            className={css.requestItemToggleIcon}
            icon={isExpanded ? 'chevron-down' : 'chevron-right'}
            onClick={onIsExpandedToggle}
          />

          <Link
            className={css.requestItemNameLink}
            params={{ id: stats.requestId }}
            rel="noopener"
            target="_blank"
            to="techruns.requests.view"
          >
            {stats.requestName}
          </Link>
        </div>

        <div className={css.requestItemHeaderCount}>
          {stats.wagonsStates.total}
        </div>
      </div>

      <Collapse isOpen={isExpanded}>
        <div className={css.requestItemStatesTableWrapper}>
          <HTMLTable bordered className={css.requestItemStatesTable} condensed>
            <tbody>
              <tr>
                <td>
                  <Link
                    params={{ numbers: stats.requestWagons.join(',') }}
                    rel="noopener"
                    target="_blank"
                    to="wagons"
                  >
                    Все
                  </Link>
                </td>

                <td className={css.requestItemNumberCell}>
                  {stats.wagonsStates.total}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      numbers: stats.wagonsStates.assignedWagons.join(','),
                    }}
                    rel="noopener"
                    target="_blank"
                    to="wagons"
                  >
                    Распределённые
                  </Link>
                </td>

                <td className={css.requestItemNumberCell}>
                  {stats.wagonsStates.assigned}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      arrivalStation: stats.requestDepartureStation,
                      numbers: stats.requestWagons.join(','),
                      operationStationNot: stats.requestDepartureStation,
                    }}
                    rel="noopener"
                    target="_blank"
                    to="wagons"
                  >
                    Подход под погрузку
                  </Link>
                </td>

                <td className={css.requestItemNumberCell}>
                  {stats.wagonsStates.arrivals}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      numbers: stats.requestWagons.join(','),
                      operationStation: stats.requestDepartureStation,
                    }}
                    rel="noopener"
                    target="_blank"
                    to="wagons"
                  >
                    Под погрузкой
                  </Link>
                </td>

                <td className={css.requestItemNumberCell}>
                  {stats.wagonsStates.load}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      numbers: stats.wagonsStates.onwayWagons.join(','),
                    }}
                    rel="noopener"
                    target="_blank"
                    to={
                      stats.wagonsStates.onwayWagons.length !== 0
                        ? 'wagons'
                        : undefined
                    }
                  >
                    В пути
                  </Link>
                </td>

                <td className={css.requestItemNumberCell}>
                  {stats.wagonsStates.onway}
                </td>
              </tr>

              <tr>
                <td>
                  <Link
                    params={{
                      numbers: stats.wagonsStates.unloadWagons.join(','),
                    }}
                    rel="noopener"
                    target="_blank"
                    to={
                      stats.wagonsStates.unloadWagons.length !== 0
                        ? 'wagons'
                        : undefined
                    }
                  >
                    Под выгрузкой
                  </Link>
                </td>

                <td className={css.requestItemNumberCell}>
                  {stats.wagonsStates.unload}
                </td>
              </tr>
            </tbody>
          </HTMLTable>
        </div>
      </Collapse>
    </div>
  );
}

export function WagonsStatesWidget() {
  const wagonsStatesWidget = useErpSelector(selectWagonsStatesWidgetData);

  const [isRequestItemExpandedMap, setIsRequestItemExpandedMap] = useState<
    Record<number, boolean | undefined>
  >({});

  const areAllExpanded = wagonsStatesWidget.data
    ? wagonsStatesWidget.data.every(
        stats => isRequestItemExpandedMap[stats.requestId]
      )
    : false;

  function setAllRequestsIsExpanded(isExpanded: boolean) {
    invariant(wagonsStatesWidget.data);
    const newMap: Record<number, boolean> = {};

    wagonsStatesWidget.data.forEach(stats => {
      newMap[stats.requestId] = isExpanded;
    });

    setIsRequestItemExpandedMap(newMap);
  }

  return (
    <>
      {!wagonsStatesWidget.data ? (
        wagonsStatesWidget.isFetched ? (
          <GenericErrorMessage />
        ) : (
          <CenteredSpinner />
        )
      ) : wagonsStatesWidget.data.length === 0 ? (
        <NonIdealState
          icon="star-empty"
          title="У вас нет ни одной избранной заявки техрейса"
        />
      ) : (
        <>
          {areAllExpanded ? (
            <Button
              icon="expand-all"
              text="Свернуть всё"
              onClick={() => {
                setAllRequestsIsExpanded(false);
              }}
            />
          ) : (
            <Button
              icon="collapse-all"
              text="Развернуть всё"
              onClick={() => {
                setAllRequestsIsExpanded(true);
              }}
            />
          )}

          {wagonsStatesWidget.data.map(stats => (
            <RequestItem
              key={stats.requestId}
              isExpanded={isRequestItemExpandedMap[stats.requestId] === true}
              stats={stats}
              onIsExpandedToggle={() => {
                setIsRequestItemExpandedMap(prevState => ({
                  ...prevState,
                  [stats.requestId]: !prevState[stats.requestId],
                }));
              }}
            />
          ))}
        </>
      )}
    </>
  );
}

import { Nl2Br } from '_core/strings/nl2Br';
import * as React from 'react';

import * as css from './fieldError.module.css';

interface IProps {
  text: string | null | undefined;
}

export function FieldError({ text }: IProps) {
  return text ? (
    <div className={css.root}>
      <Nl2Br text={text} />
    </div>
  ) : null;
}

import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
} from '_core/dialogs/dialog';
import { FinalForm } from '_core/final-form/finalForm';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { DialogForm } from '_core/forms/dialogForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Button, IconName, Intent } from '@blueprintjs/core';
import { IContactSerialized } from 'contacts/api';
import * as React from 'react';

export interface IPartnersDialogFormValues {
  actualAddress: string;
  contacts: IContactSerialized[];
  email: string;
  fullName: string;
  inn: string;
  isClient: boolean;
  isSupplier: boolean;
  kpp: string;
  legalAddress: string;
  ogrn: string;
  okato: string;
  okpo: string;
  paymentData: string;
  postAddress: string;
  shortName: string;
}

interface IProps {
  icon: IconName;
  initialValues: IPartnersDialogFormValues;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (values: IPartnersDialogFormValues) => Promise<void>;
}

export function PartnersDialogForm({
  icon,
  initialValues,
  isOpen,
  title,
  onClose,
  onSubmit,
}: IProps) {
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={async values => {
        try {
          await onSubmit(values);
          return undefined;
        } catch (err) {
          return submissionErrorsFromApiError(
            err,
            'Не удалось сохранить контрагента: Непредвиденная ошибка'
          );
        }
      }}
    >
      {({ error, handleSubmit, submitError, submitting }) => (
        <DialogForm
          icon={icon}
          isOpen={isOpen}
          isSubmitting={submitting}
          title={title}
          onClose={onClose}
          onSubmit={handleSubmit}
        >
          <DialogBody>
            <FormErrors error={error || submitError} />

            <FormGroupForFinalForm
              label="Наименование полное"
              labelInfo="*"
              name="fullName"
            >
              <InputGroupForFinalForm name="fullName" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Наименование краткое"
              name="shortName"
            >
              <InputGroupForFinalForm
                name="shortName"
                required
                style={{ textTransform: 'uppercase' }}
              />
            </FormGroupForFinalForm>

            <CheckboxForFinalForm label="Является клиентом" name="isClient" />

            <CheckboxForFinalForm
              label="Является поставщиком"
              name="isSupplier"
            />

            <FormGroupForFinalForm label="Email" name="email">
              <InputGroupForFinalForm name="email" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Адрес юридический"
              name="legalAddress"
            >
              <InputMultipleForFinalForm name="legalAddress" rows={5} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="Адрес почтовый" name="postAddress">
              <InputMultipleForFinalForm name="postAddress" rows={5} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Адрес фактический"
              name="actualAddress"
            >
              <InputMultipleForFinalForm name="actualAddress" rows={5} />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="ИНН" name="inn">
              <InputGroupForFinalForm name="inn" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="КПП" name="kpp">
              <InputGroupForFinalForm name="kpp" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="ОГРН" name="ogrn">
              <InputGroupForFinalForm name="ogrn" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="ОКАТО" name="okato">
              <InputGroupForFinalForm name="okato" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="ОКПО" name="okpo">
              <InputGroupForFinalForm name="okpo" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm label="ОКТМО" name="oktmo">
              <InputGroupForFinalForm name="oktmo" />
            </FormGroupForFinalForm>

            <FormGroupForFinalForm
              label="Банковские реквизиты"
              name="paymentData"
            >
              <InputMultipleForFinalForm name="paymentData" rows={5} />
            </FormGroupForFinalForm>
          </DialogBody>

          <DialogFooter>
            <DialogFooterActions>
              <Button disabled={submitting} text="Отмена" onClick={onClose} />

              <Button
                disabled={submitting}
                intent={Intent.PRIMARY}
                loading={submitting}
                text="Сохранить"
                type="submit"
              />
            </DialogFooterActions>
          </DialogFooter>
        </DialogForm>
      )}
    </FinalForm>
  );
}

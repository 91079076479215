import { NonIdealState } from '@blueprintjs/core';
import * as React from 'react';

interface IProps {
  description?: string;
  title?: string;
}

export function EmptyListMessage({
  description = 'Нет элементов',
  title,
}: IProps) {
  return <NonIdealState icon="list" description={description} title={title} />;
}

import { identity } from '_core/fp/identity';
import { Classes, Intent } from '@blueprintjs/core';
import cx from 'classnames';
import * as React from 'react';
import { useField } from 'react-final-form';
import MaskedInput, { maskArray } from 'react-text-mask';

import * as css from './inputMasked.module.css';

interface Props {
  autoFocus?: boolean;
  bold?: boolean;
  disabled?: boolean;
  editingLocked?: boolean;
  id?: string;
  isInvalid?: boolean;
  large?: boolean;
  mask: maskArray | ((value: string) => maskArray);
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  type?: string;
  value: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (newValue: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export function InputMasked({
  autoFocus,
  bold,
  disabled,
  editingLocked,
  id,
  isInvalid,
  large,
  mask,
  name,
  placeholder,
  readOnly,
  type,
  value,
  onBlur,
  onChange,
  onFocus,
}: Props) {
  const handleChange = React.useMemo(
    () =>
      onChange
        ? (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.currentTarget.value);
          }
        : undefined,
    [onChange]
  );

  return (
    <div
      className={cx(
        Classes.INPUT_GROUP,
        isInvalid ? Classes.intentClass(Intent.DANGER) : undefined
      )}
    >
      <MaskedInput
        autoFocus={autoFocus}
        className={cx(Classes.INPUT, {
          [Classes.LARGE]: large,
          [css.bold]: bold,
        })}
        disabled={disabled}
        id={id}
        mask={mask}
        name={name}
        placeholder={disabled ? undefined : placeholder}
        readOnly={readOnly || editingLocked}
        type={type}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleChange}
      />
    </div>
  );
}

export function InputMaskedForFinalForm({
  disabled,
  name,
  onBlur,
  onChange,
  onFocus,
  ...otherProps
}: Omit<Props, 'error' | 'isInvalid' | 'value'> & {
  name: string;
}) {
  const { input, meta } = useField<string>(name, {
    // this identity fn is needed because by default final-form removes field
    // key completely when it's set to an empty string
    parse: identity,
  });

  const error = meta.error || meta.submitError;

  return (
    <InputMasked
      disabled={meta.submitting || disabled}
      isInvalid={meta.touched && Boolean(error)}
      name={name}
      onBlur={event => {
        input.onBlur(event);
        onBlur?.(event);
      }}
      onChange={newValue => {
        input.onChange(newValue);
        onChange?.(newValue);
      }}
      onFocus={event => {
        input.onFocus(event);
        onFocus?.(event);
      }}
      value={input.value}
      {...otherProps}
    />
  );
}

import { Icon, Intent } from '@blueprintjs/core';
import * as React from 'react';

import * as css from './statusIndicator.module.css';
import { DocumentStatus } from './types';

enum StatusIcon {
  Confirm = 'confirm',
  Delete = 'delete',
}

interface IStatusData {
  icon1: StatusIcon;
  icon2: StatusIcon;
}

function getIconIntent(iconName: StatusIcon) {
  return iconName === StatusIcon.Confirm ? Intent.SUCCESS : Intent.DANGER;
}

function getStatusIcons(status: DocumentStatus): IStatusData {
  return {
    [DocumentStatus.Created]: {
      icon1: StatusIcon.Delete,
      icon2: StatusIcon.Delete,
    },

    [DocumentStatus.OriginalReceived]: {
      icon1: StatusIcon.Confirm,
      icon2: StatusIcon.Confirm,
    },

    [DocumentStatus.OriginalSent]: {
      icon1: StatusIcon.Delete,
      icon2: StatusIcon.Delete,
    },

    [DocumentStatus.ScanReceived]: {
      icon1: StatusIcon.Delete,
      icon2: StatusIcon.Confirm,
    },

    [DocumentStatus.ScanSent]: {
      icon1: StatusIcon.Delete,
      icon2: StatusIcon.Delete,
    },
  }[status];
}

interface IProps {
  status: DocumentStatus;
}

export function DocumentStatusIndicator({ status }: IProps) {
  const { icon1, icon2 } = getStatusIcons(status);

  return (
    <div className={css.root}>
      {icon1 && (
        <Icon
          className={css.icon}
          icon={icon1}
          intent={getIconIntent(icon1)}
          iconSize={Icon.SIZE_LARGE}
        />
      )}

      {icon2 && (
        <Icon
          className={css.icon}
          icon={icon2}
          intent={getIconIntent(icon2)}
          iconSize={Icon.SIZE_LARGE}
        />
      )}
    </div>
  );
}

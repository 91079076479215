import { AutocompleteFilter } from '_core/filters/autocompleteFilter';
import { FilterPopoverValueProcessor } from '_core/filters/filterPopover';
import * as React from 'react';

import { PartnersAutocompleteInFormGroup } from './autocomplete';
import * as css from './filter.module.css';

const valueProcessor: FilterPopoverValueProcessor<
  string | undefined,
  number | null
> = {
  emptyValue: undefined,

  deserialize(value) {
    const n = typeof value === 'string' ? Number(value) : null;

    return n != null && isFinite(n) ? n : null;
  },

  serialize(value) {
    return value == null ? undefined : String(value);
  },

  isEmpty(value) {
    return !value;
  },
};

interface IProps {
  initialValue: string | undefined;
  label?: string;
  onApply: (nextValue: string | undefined) => void;
}

export function PartnersFilter({
  initialValue,
  label = 'Контрагент',
  onApply,
}: IProps) {
  return (
    <AutocompleteFilter
      initialValue={initialValue}
      label={label}
      valueProcessor={valueProcessor}
      onApply={onApply}
    >
      {({ value, onChange }) => (
        <div className={css.root}>
          <PartnersAutocompleteInFormGroup
            label="Контрагент"
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </AutocompleteFilter>
  );
}

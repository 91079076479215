import { useApiClient } from '_core/api/context';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import dayjs from 'dayjs';
import { IDowntime } from 'downtimes/types';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';
import { fetchTechrunTransportation } from 'techrunTransportations/api';
import { IDislocationSerialized } from 'wagons/api';

import { DowntimesForm, IDowntimesFormValues } from './form';

export default function DowntimesEditRoute() {
  const { route, router } = useRouteNode('downtimes.edit');
  const navigationLock = useFormNavigationLock('downtimes.edit');
  const api = useApiClient();

  const { data, error, isFetching, updateData } = useAsyncData(
    [api, route.params.id],
    async () => {
      const [downtime, dislocations] = await Promise.all([
        api.get<IDowntime>(`/techrun_downtimes/${route.params.id}`),
        api.get<IDislocationSerialized[]>(
          `/techrun_downtimes/${route.params.id}/dislocations`
        ),
      ]);

      const transportation = await fetchTechrunTransportation(
        api,
        downtime.transportation
      );

      return {
        dislocations,
        downtime,
        transportation,
      };
    }
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage error={error} />
    );
  }

  const persist = ({
    corrAdditionalDowntimeLimit,
    corrDowntimeLimit,
    corrEndDate,
    corrStartDate,
    ...otherValues
  }: IDowntimesFormValues) =>
    api.put<IDowntime>(`/techrun_downtimes/${route.params.id}`, {
      ...otherValues,
      corrAdditionalDowntimeLimit: corrAdditionalDowntimeLimit
        ? Number(corrAdditionalDowntimeLimit)
        : null,
      corrDowntimeLimit: corrDowntimeLimit ? Number(corrDowntimeLimit) : null,
      corrEndDate:
        corrEndDate == null
          ? null
          : dayjs(corrEndDate).format(DATE_FORMAT_API_DATE),
      corrStartDate:
        corrStartDate == null
          ? null
          : dayjs(corrStartDate).format(DATE_FORMAT_API_DATE),
    });

  const title = `Простой вагона ${data.downtime.wagon}`;

  function handleSubmissionError(err: unknown) {
    return submissionErrorsFromApiError(
      err,
      'Не удалось изменить простой: Непредвиденная ошибка'
    );
  }

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Простои"
              params={listParams}
              to="downtimes"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <DowntimesForm
            dislocations={data.dislocations}
            downtime={data.downtime}
            initialValues={{
              ...data.downtime,
              corrAdditionalDowntimeLimit:
                data.downtime.corrAdditionalDowntimeLimit == null
                  ? ''
                  : String(data.downtime.corrAdditionalDowntimeLimit),
              corrDowntimeLimit:
                data.downtime.corrDowntimeLimit == null
                  ? ''
                  : String(data.downtime.corrDowntimeLimit),
              corrEndDate:
                data.downtime.corrEndDate == null
                  ? null
                  : parseDate(data.downtime.corrEndDate),
              corrStartDate:
                data.downtime.corrStartDate == null
                  ? null
                  : parseDate(data.downtime.corrStartDate),
            }}
            navigationLock={navigationLock}
            transportation={data.transportation}
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('downtimes', listParams);
                return undefined;
              } catch (err) {
                return handleSubmissionError(err);
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedDowntime = await persist(values);
                updateData(
                  prevData =>
                    prevData && { ...prevData, downtime: updatedDowntime }
                );
                return undefined;
              } catch (err) {
                return handleSubmissionError(err);
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

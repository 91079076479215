import {
  ExpeditionTransportationAccountingState,
  ExpeditionTransportationState,
} from 'expeditionTransportations/types';
import {
  TechrunTransportationAccountingState,
  TechrunTransportationState,
} from 'techrunTransportations/types';

enum DowntimeType {
  Load = 'LOAD',
  Unload = 'UNLOAD',
}

export enum DowntimeSortingFields {
  DowntimeDuration = 'downtimeDuration',
  DurationOverLimit = 'durationOverLimit1',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

function getDowntimeTypeLabel(type: DowntimeType) {
  return {
    [DowntimeType.Load]: 'Под погрузкой',
    [DowntimeType.Unload]: 'Под выгрузкой',
  }[type];
}

export const downtimeTypeOptions = [DowntimeType.Load, DowntimeType.Unload].map(
  type => ({
    label: getDowntimeTypeLabel(type),
    value: type,
  })
);

export interface IDowntime {
  accountingState:
    | ExpeditionTransportationAccountingState
    | TechrunTransportationAccountingState;
  additionalDowntimeRate: string;
  afterUnloadWaybillNumber: string;
  arrivalStation: number;
  beforeLoadWaybillNumber: string;
  corrAdditionalDowntimeLimit: number | null;
  corrDowntimeLimit: number | null;
  corrEndDate: string | null;
  corrStartDate: string | null;
  departureStation: number;
  downtimeDuration: number | null;
  downtimeLimit1: number;
  downtimeLimit2: number;
  downtimeRate: string;
  downtimeStation: number;
  durationOverLimit1: number;
  durationOverLimit2: number;
  endDate: string | null;
  id: number;
  lastWagonNote: string;
  lastWagonNoteDate: string | null;
  name: string;
  note: string;
  overLimit1Cost: string;
  overLimit2Cost: string;
  request: number;
  startDate: string | null;
  stationDepartureDate: string;
  totalOverLimitCost: string;
  transportation: number;
  transportationState:
    | ExpeditionTransportationState
    | TechrunTransportationState;
  type: DowntimeType;
  wagon: string;
  waybillNumber: string;
}

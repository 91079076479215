import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputMultipleForFinalForm } from '_core/inputs/inputMultiple';
import { Toolbar } from '_core/toolbar';
import { Button, Classes, Intent, Popover, Position } from '@blueprintjs/core';
import { SubmissionErrors } from 'final-form';
import * as React from 'react';
import { useState } from 'react';

import * as css from './addNotesToWagons.module.css';

export interface IAddNotesToWagonsFormValues {
  etranNote: string;
  note: string;
  wagons: string;
  rzdAppealNote: string;
  cargoOverNote: string;
  deliveryOverdueNote: string;
}

interface IProps {
  onSubmit: (
    values: IAddNotesToWagonsFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function AddNotesToWagons({ onSubmit }: IProps) {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  return (
    <Popover
      content={
        <FinalForm<IAddNotesToWagonsFormValues>
          initialValues={{
            etranNote: '',
            note: '',
            wagons: '',
            rzdAppealNote: '',
            cargoOverNote: '',
            deliveryOverdueNote: '',
          }}
          onSubmit={async values => {
            const submissionErrors = await onSubmit(values);

            if (!submissionErrors) {
              setPopoverIsOpen(false);
            }

            return submissionErrors;
          }}
        >
          {({ error, handleSubmit, submitError, submitting }) => (
            <BaseForm className={css.form} onSubmit={handleSubmit}>
              <FormErrors error={error || submitError} />

              <FormGroupForFinalForm label="Вагоны" name="wagons">
                <InputMultipleForFinalForm name="wagons" rows={3} />
              </FormGroupForFinalForm>

              <FormGroupForFinalForm label="Комментарий" name="note">
                <InputMultipleForFinalForm name="note" rows={3} />
              </FormGroupForFinalForm>

              <FormGroupForFinalForm
                label="Заготовка в Этране"
                name="etranNote"
              >
                <InputMultipleForFinalForm name="etranNote" rows={3} />
              </FormGroupForFinalForm>

              <FormGroupForFinalForm
                label="Обращение в РЖД"
                name="rzdAppealNote"
              >
                <InputMultipleForFinalForm name="rzdAppealNote" rows={3} />
              </FormGroupForFinalForm>

              <FormGroupForFinalForm
                label="Просрочка доставки"
                name="deliveryOverdueNote"
              >
                <InputMultipleForFinalForm
                  name="deliveryOverdueNote"
                  rows={3}
                />
              </FormGroupForFinalForm>

              <FormGroupForFinalForm
                label={
                  <span>
                    Грузовая операция окончена
                    <br />
                    (принято приемосдатчиком)
                  </span>
                }
                name="cargoOverNote"
              >
                <InputMultipleForFinalForm name="cargoOverNote" rows={3} />
              </FormGroupForFinalForm>

              <Toolbar align="right">
                <Button
                  onClick={() => {
                    setPopoverIsOpen(false);
                  }}
                  text="Закрыть"
                />

                <Button
                  intent={Intent.PRIMARY}
                  loading={submitting}
                  text="Добавить"
                  type="submit"
                />
              </Toolbar>
            </BaseForm>
          )}
        </FinalForm>
      }
      isOpen={popoverIsOpen}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      position={Position.RIGHT_TOP}
      onInteraction={setPopoverIsOpen}
    >
      <Button intent={Intent.PRIMARY} text="Добавить комментарий к вагонам" />
    </Popover>
  );
}

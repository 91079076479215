import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { Switch } from '_core/inputs/switch';
import { LinkButton } from '_core/router5/linkButton';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { LinkCell } from '_core/table/cells/link';
import { Column } from '_core/table/column';
import { Table } from '_core/table/table';
import { Toolbar } from '_core/toolbar';
import { useAsyncData } from '_core/useAsyncData';
import { Intent, NonIdealState } from '@blueprintjs/core';
import { IContractSell } from 'contractsSell/api';
import dayjs from 'dayjs';
import { DocumentStatusIndicator } from 'documents/statusIndicator';
import { DocumentStatus } from 'documents/types';
import {
  ApplyExpeditionCostFor,
  createExpeditionProtocolSell,
  ExpeditionProtocolSellRateType,
  fetchExpeditionProtocolsSell,
  IExpeditionProtocolSell,
  updateExpeditionProtocolSell,
} from 'expeditionProtocolsSell/api';
import { ExpeditionStationType } from 'expeditions/types';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouteNode } from 'react-router5';
import { getNormalVatRate, totalCostForForm } from 'vatRates/vatRates';

import { DocumentListLayout } from './documentListLayout';
import {
  ExpeditionProtocolForm,
  IExpeditionProtocolFormValues,
} from './expeditionProtocols/expeditionProtocolForm';

interface IProps {
  contract: IContractSell;
}

export function ContractsSellViewExpeditionProtocolsTab({ contract }: IProps) {
  const { route, router } = useRouteNode(
    'contracts.sell.view.expeditionProtocols'
  );

  const navigationLock = useFormNavigationLock(route.name);
  const api = useApiClient();

  const { data, isFetching, updateData } = useAsyncData(
    [contract.id, api, route.params.showArchived],
    async () => {
      const listResponse = await fetchAllPages(page =>
        fetchExpeditionProtocolsSell(api, {
          contract: contract.id,
          isArchived: route.params.showArchived ? undefined : false,
          page,
        })
      );

      return listResponse.results;
    }
  );

  const isCreateRoute =
    route.name === 'contracts.sell.view.expeditionProtocols.create';

  const selectedProtocolId =
    route.name === 'contracts.sell.view.expeditionProtocols.view'
      ? Number(route.params.protocolId)
      : null;

  const selectedProtocol = data?.find(
    protocol => protocol.id === selectedProtocolId
  );

  const [editingLocked, setEditingLocked] = useState(selectedProtocol != null);

  useEffect(() => {
    setEditingLocked(selectedProtocol != null);
  }, [selectedProtocol]);

  const getInitialValues = (): IExpeditionProtocolFormValues => {
    function protocolToFormValues({
      expeditionCost,
      expeditionCostTotal,
      rates,
      ...otherProps
    }: IExpeditionProtocolSell): IExpeditionProtocolFormValues {
      return {
        ...otherProps,
        expeditionCost: expeditionCost == null ? '' : expeditionCost,
        expeditionCostTotal:
          expeditionCostTotal == null ? '' : expeditionCostTotal,
        rates: rates.map(rate => ({ ...rate, isLocked: true })),
      };
    }

    if (selectedProtocol) {
      return protocolToFormValues(selectedProtocol);
    }

    if (data && route.params.copyFrom) {
      const copyFromId = Number(route.params.copyFrom);

      const protocolToCopyFrom = data.find(
        protocol => protocol.id === copyFromId
      );

      if (protocolToCopyFrom) {
        return protocolToFormValues({ ...protocolToCopyFrom, files: [] });
      }
    }

    return {
      applyExpeditionCostFor: ApplyExpeditionCostFor.Load,
      compensateEmptyTariff: false,
      compensateLoadedTariff: false,
      emptyTariffPercent: '',
      endDate: dayjs().endOf('year').startOf('date').toDate(),
      expeditionCost: '',
      expeditionCostTotal: totalCostForForm('', getNormalVatRate()),
      expeditionCostVat: getNormalVatRate(),
      files: [],
      isEmptyTariffPercent: false,
      isLoadedTariffPercent: false,
      loadedTariffPercent: '',
      note: '',
      number: '',
      partnerSign: contract.partnerSign,
      rates: [],
      rateType: ExpeditionProtocolSellRateType.Wagon,
      signerAuthority: '',
      startDate: dayjs().startOf('year').toDate(),
      station: null,
      stationType: ExpeditionStationType.Departure,
      status: DocumentStatus.Created,
    };
  };

  return !data ? (
    isFetching ? (
      <CenteredSpinner />
    ) : (
      <GenericErrorMessage />
    )
  ) : (
    <VGrid>
      <Row>
        <Grid>
          <Col>
            <Toolbar>
              <Switch
                checked={route.params.showArchived === true}
                label="Архивные протоколы"
                onChange={newShowArchived => {
                  router.navigate(route.name, {
                    ...route.params,
                    showArchived: newShowArchived || undefined,
                  });
                }}
              />
            </Toolbar>
          </Col>

          <Col>
            <Toolbar align="right">
              <LinkButton
                intent={Intent.PRIMARY}
                params={{
                  ...route.params,
                  copyFrom: undefined,
                  protocolId: undefined,
                }}
                text="Добавить"
                to="contracts.sell.view.expeditionProtocols.create"
              />
            </Toolbar>
          </Col>
        </Grid>
      </Row>

      <Row>
        {!isCreateRoute && data.length === 0 ? (
          <NonIdealState
            icon="list"
            description="Вы можете создать протокол с помощью кнопки выше"
            title="У договора нет протоколов экспедирования"
          />
        ) : (
          <DocumentListLayout
            list={
              <Table
                emptyMessage="У договора нет протоколов экспедирования"
                getRowProps={(protocol: IExpeditionProtocolSell) => ({
                  selected: selectedProtocol
                    ? protocol.id === selectedProtocol.id
                    : false,
                })}
                isFetching={isFetching}
                items={data}
              >
                <Column
                  width={150}
                  id="protocolName"
                  label="Наименование"
                  cell={(protocol: IExpeditionProtocolSell) => (
                    <LinkCell
                      params={{ ...route.params, protocolId: protocol.id }}
                      to="contracts.sell.view.expeditionProtocols.view"
                    >
                      {protocol.protocolName}
                    </LinkCell>
                  )}
                />

                <Column
                  width={80}
                  id="status"
                  label="Статус"
                  cell={(protocol: IExpeditionProtocolSell) => (
                    <DocumentStatusIndicator status={protocol.status} />
                  )}
                />
              </Table>
            }
            form={
              !isCreateRoute && !selectedProtocol ? (
                <NonIdealState
                  icon="select"
                  description="Выберите протокол в списке слева или добавьте новый"
                  title="Протоколов не выбрано"
                />
              ) : (
                <ExpeditionProtocolForm
                  editingLocked={editingLocked}
                  initialValues={getInitialValues()}
                  navigationLock={navigationLock}
                  selectedProtocol={selectedProtocol}
                  onCancelEditing={
                    editingLocked || isCreateRoute
                      ? undefined
                      : () => {
                          setEditingLocked(true);
                        }
                  }
                  onDuplicate={
                    editingLocked && selectedProtocol
                      ? () => {
                          router.navigate(
                            'contracts.sell.view.expeditionProtocols.create',
                            {
                              ...route.params,
                              copyFrom: String(selectedProtocol.id),
                              protocolId: undefined,
                            }
                          );
                        }
                      : undefined
                  }
                  onStartEditing={
                    editingLocked
                      ? () => {
                          if (
                            // eslint-disable-next-line no-alert
                            window.confirm(
                              'Вы собираетесь изменить сохранённый протокол экспедирования'
                            )
                          ) {
                            setEditingLocked(false);
                          }
                        }
                      : undefined
                  }
                  onSubmit={async ({
                    emptyTariffPercent,
                    expeditionCost,
                    isEmptyTariffPercent,
                    isLoadedTariffPercent,
                    loadedTariffPercent,
                    rates,
                    ...otherValues
                  }) => {
                    try {
                      const params = {
                        ...otherValues,
                        contract: contract.id,
                        emptyTariffPercent: isEmptyTariffPercent
                          ? emptyTariffPercent || null
                          : null,
                        expeditionCost: expeditionCost || null,
                        isEmptyTariffPercent,
                        isLoadedTariffPercent,
                        loadedTariffPercent: isLoadedTariffPercent
                          ? loadedTariffPercent || null
                          : null,
                        rates: rates.map(
                          ({
                            isLocked,
                            tariffLoaded,
                            tariffEmpty,
                            ...otherRateProps
                          }) => ({
                            ...otherRateProps,
                            tariffLoaded: tariffLoaded || null,
                            tariffEmpty: tariffEmpty || null,
                          })
                        ),
                      };

                      if (selectedProtocol == null) {
                        const newProtocol = await createExpeditionProtocolSell(
                          api,
                          params
                        );

                        updateData(
                          prevData => prevData && [newProtocol].concat(prevData)
                        );

                        navigationLock.unlock();

                        router.navigate(
                          'contracts.sell.view.expeditionProtocols.view',
                          {
                            ...route.params,
                            protocolId: newProtocol.id,
                          }
                        );
                      } else {
                        const updatedProtocol =
                          await updateExpeditionProtocolSell(
                            api,
                            selectedProtocol.id,
                            params
                          );

                        updateData(
                          prevData =>
                            prevData &&
                            prevData.map(originalProtocol =>
                              originalProtocol.id === updatedProtocol.id
                                ? updatedProtocol
                                : originalProtocol
                            )
                        );

                        setEditingLocked(true);
                      }

                      return undefined;
                    } catch (err) {
                      return submissionErrorsFromApiError(
                        err,
                        selectedProtocol
                          ? 'Не удалось изменить протокол экспедирования: Непредвиденная ошибка'
                          : 'Не удалось создать протокол экспедирования: Непредвиденная ошибка'
                      );
                    }
                  }}
                />
              )
            }
          />
        )}
      </Row>
    </VGrid>
  );
}

import { createErpAsyncThunk, ErpState } from '_erp/redux';
import { createSlice } from '@reduxjs/toolkit';
import {
  IMyDocumentsParksAlertsResponse,
  IMyDocumentsTechrunRequestsDowntimesResponse,
  IMyDocumentsTechrunsRequestsResponse,
  IMyDocumentsWagonsAlertsResponse,
} from 'myDocuments/api';

export const fetchParksAlertsWidgetData = createErpAsyncThunk(
  'homePageWidgets/fetchParksAlerts',
  (_, { extra: { api } }) =>
    api.get<IMyDocumentsParksAlertsResponse>('/my_documents/parks_alerts')
);

export const fetchTechrunRequestsDowntimesWidgetData = createErpAsyncThunk(
  'homePageWidgets/fetchTechrunRequestsDowntimes',
  (_, { extra: { api } }) =>
    api.get<IMyDocumentsTechrunRequestsDowntimesResponse>(
      '/my_documents/techruns_requests_downtimes'
    )
);

export const fetchWagonsAlertsWidgetData = createErpAsyncThunk(
  'homePageWidgets/fetchWagonsAlerts',
  (_, { extra: { api } }) =>
    api.get<IMyDocumentsWagonsAlertsResponse>('/my_documents/wagons_alerts')
);

export const fetchWagonsStatesWidgetData = createErpAsyncThunk(
  '',
  (_, { extra: { api } }) =>
    api.get<IMyDocumentsTechrunsRequestsResponse>(
      '/my_documents/techruns_requests'
    )
);

function widgetState<T>(): { data: T | null; isFetched: boolean } {
  return {
    data: null,
    isFetched: false,
  };
}

const { reducer } = createSlice({
  name: 'homePageWidgets',
  initialState: {
    parksAlerts: widgetState<IMyDocumentsParksAlertsResponse>(),
    techrunRequestsDowntimes:
      widgetState<IMyDocumentsTechrunRequestsDowntimesResponse>(),
    wagonsAlerts: widgetState<IMyDocumentsWagonsAlertsResponse>(),
    wagonsStates: widgetState<IMyDocumentsTechrunsRequestsResponse>(),
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchParksAlertsWidgetData.fulfilled, (state, action) => {
        state.parksAlerts.data = action.payload;
        state.parksAlerts.isFetched = true;
      })
      .addCase(fetchParksAlertsWidgetData.rejected, state => {
        state.parksAlerts.isFetched = true;
      })
      .addCase(
        fetchTechrunRequestsDowntimesWidgetData.fulfilled,
        (state, action) => {
          state.techrunRequestsDowntimes.data = action.payload;
          state.techrunRequestsDowntimes.isFetched = true;
        }
      )
      .addCase(fetchTechrunRequestsDowntimesWidgetData.rejected, state => {
        state.techrunRequestsDowntimes.isFetched = true;
      })
      .addCase(fetchWagonsAlertsWidgetData.fulfilled, (state, action) => {
        state.wagonsAlerts.data = action.payload;
        state.wagonsAlerts.isFetched = true;
      })
      .addCase(fetchWagonsAlertsWidgetData.rejected, state => {
        state.wagonsAlerts.isFetched = true;
      })
      .addCase(fetchWagonsStatesWidgetData.fulfilled, (state, action) => {
        state.wagonsStates.data = action.payload;
        state.wagonsStates.isFetched = true;
      })
      .addCase(fetchWagonsStatesWidgetData.rejected, state => {
        state.wagonsStates.isFetched = true;
      });
  },
});

export const homeRouteReducer = reducer;

export function selectParksAlertsWidgetData(state: ErpState) {
  return state.home.parksAlerts;
}

export function selectTechrunRequestsDowntimesWidgetData(state: ErpState) {
  return state.home.techrunRequestsDowntimes;
}

export function selectWagonsAlertsWidgetData(state: ErpState) {
  return state.home.wagonsAlerts;
}

export function selectWagonsStatesWidgetData(state: ErpState) {
  return state.home.wagonsStates;
}

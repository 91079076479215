import { DATE_FORMAT_TIME } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { SelectOption } from '_core/inputs/select';
import dayjs from 'dayjs';

import { IParkSchedule } from './api';

export function parkScheduleToSelectOption(
  parkSchedule: IParkSchedule
): SelectOption {
  return {
    label: dayjs(parseDate(parkSchedule.startTime, 'HH:mm:ss')).format(
      DATE_FORMAT_TIME
    ),
    value: String(parkSchedule.id),
  };
}

import { identity } from '_core/fp/identity';
import { Classes, Intent, TextArea, TextAreaProps } from '@blueprintjs/core';
import * as React from 'react';
import { useField } from 'react-final-form';

export function InputMultiple(props: TextAreaProps) {
  return (
    <div className={Classes.INPUT_GROUP}>
      <TextArea {...props} />
    </div>
  );
}

interface ForFinalFormProps
  extends Omit<TextAreaProps, 'value' | 'onBlur' | 'onChange' | 'onFocus'> {
  name: string;
}

export function InputMultipleForFinalForm({
  disabled,
  intent,
  name,
  ...otherProps
}: ForFinalFormProps) {
  const { input, meta } = useField<string>(name, {
    // this identity fn is needed because by default final-form removes field
    // key completely when it's set to an empty string
    parse: identity,
  });

  return (
    <InputMultiple
      {...otherProps}
      disabled={meta.submitting || disabled}
      intent={
        meta.touched && (meta.error || meta.submitError)
          ? Intent.DANGER
          : intent
      }
      name={name}
      value={input.value}
      onBlur={input.onBlur}
      onChange={event => {
        input.onChange(event.currentTarget.value);
      }}
      onFocus={input.onFocus}
    />
  );
}

import { SENTRY_DSN, SENTRY_ENVIRONMENT } from '_core/config';
import { init, Integrations } from '@sentry/browser';
import { ExtraErrorData, ReportingObserver } from '@sentry/integrations';
import SentryRRWeb from '@sentry/rrweb';

const YM_RE = /^https?:\/\/mc.yandex.ru/;

init({
  attachStacktrace: true,
  debug: __DEV__,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  normalizeDepth: 10,
  release: __SENTRY_RELEASE__,
  integrations: [
    new Integrations.GlobalHandlers({
      onerror: true,
      onunhandledrejection: false,
    }),
    new ExtraErrorData({ depth: 10 }),
    new ReportingObserver(),
    new SentryRRWeb(),
  ],
  beforeBreadcrumb(breadcrumb) {
    // yandex metrika
    if (
      breadcrumb.category === 'xhr' &&
      breadcrumb.data &&
      YM_RE.test(breadcrumb.data.url)
    ) {
      return null;
    }

    return breadcrumb;
  },
});

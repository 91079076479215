import * as React from 'react';

import * as css from './legend.module.css';

export function LegendItem({
  backgroundColor,
  label,
}: {
  backgroundColor?: string;
  label: string;
}) {
  return (
    <>
      <span className={css.square} style={{ backgroundColor }} />
      <span className={css.label}> - {label}</span>
    </>
  );
}

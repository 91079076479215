import { FilterPopoverUi, useFilterPopover } from '_core/filters/filterPopover';
import { InputMultiple } from '_core/inputs/inputMultiple';
import { FormGroup } from '@blueprintjs/core';
import * as React from 'react';

import * as css from './numbersFilter.module.css';
import { parseWagonNumbers } from './utils';

interface IProps {
  initialValue: string | undefined;
  onApply: (newValue: string | undefined) => void;
}

export function WagonNumbersFilter({ initialValue, onApply }: IProps) {
  const filterPopover = useFilterPopover<string | undefined, string>(
    initialValue,
    onApply,

    {
      emptyValue: undefined,
      isEmpty: value => !value,

      serialize: value => {
        const numbers = parseWagonNumbers(value);

        return numbers.length ? numbers.join(',') : undefined;
      },

      deserialize: value => {
        const numbers = value == null ? [] : parseWagonNumbers(value);

        return numbers.length ? numbers.join(' ') : '';
      },
    }
  );

  return (
    <FilterPopoverUi api={filterPopover.ui} label="Вагоны">
      <div className={css.root}>
        <FormGroup label="Номера вагонов">
          <InputMultiple
            autoFocus
            rows={8}
            value={filterPopover.value}
            onChange={event => {
              filterPopover.change(event.currentTarget.value);
            }}
          />
        </FormGroup>
      </div>
    </FilterPopoverUi>
  );
}

import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { createDateRange } from '_core/dates/utils';
import { clamp } from '_core/fp/clamp';
import { CellInterval, Region } from '_core/react-window/baseTable';
import { BaseCell } from '_core/react-window/cells';
import { RenderableRegion } from '_core/react-window/regions';
import { TreeTable, TreeTableColumn } from '_core/react-window/treeTable';
import { TreeTableNode } from '_core/react-window/types';
import { flattenNodesToRows } from '_core/react-window/utils';
import { Link } from '_core/router5/link';
import { Ellipsis } from '_core/strings/ellipsis';
import { Tooltip } from '@blueprintjs/core';
import dayjs from 'dayjs';
import { deepEqual } from 'fast-equals';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import {
  RentWagonPurchaseListItem,
  RentWagonPurchasePause,
} from 'rentWagonPurchase/api';
import { RentWagonSellListItem, RentWagonSellPause } from 'rentWagonSell/api';
import invariant from 'tiny-invariant';

import { RentWagonColor } from './colors';
import { PauseRegion } from './pauseRegion';

export type RentWagonsTreeTableSupplierNodeData = {
  kind: 'supplier';
  supplierId: number;
  supplierName: string;
  protocolCount: number;
};

export type RentWagonsTreeTableProtocolNodeData = {
  kind: 'protocol';
  contractId: number;
  protocolId: number;
  protocolName: string;
  rentWagonCount: number;
  shipment?: number | null;
};

export type RentWagonsTreeTableRentWagonNodeData<
  TRentWagon extends RentWagonPurchaseListItem | RentWagonSellListItem
> = {
  kind: 'rentWagon';
  rentWagon: TRentWagon;
};

export type RentWagonsTreeTableNodeData<
  TRentWagon extends RentWagonPurchaseListItem | RentWagonSellListItem
> =
  | RentWagonsTreeTableSupplierNodeData
  | RentWagonsTreeTableProtocolNodeData
  | RentWagonsTreeTableRentWagonNodeData<TRentWagon>;

export const DATE_COLUMN_START_INDEX = 1;

function pauseToColumnCellInterval(
  pause: RentWagonPurchasePause | RentWagonSellPause,
  startDate: Date,
  endDate: Date
): CellInterval | null {
  if (
    pause.startDate > endDate ||
    (pause.endDate != null && pause.endDate < startDate)
  ) {
    return null;
  }

  const dateRangeLength = dayjs(endDate).add(1, 'day').diff(startDate, 'day');
  const clampDateOffset = clamp(0, dateRangeLength);

  const startDateOffset = clampDateOffset(
    dayjs(pause.startDate).diff(startDate, 'day')
  );

  const endDateOffset = clampDateOffset(
    dayjs(pause.endDate || endDate).diff(startDate, 'day')
  );

  return [
    DATE_COLUMN_START_INDEX + startDateOffset,
    DATE_COLUMN_START_INDEX + endDateOffset,
  ];
}

interface IPausesForRegionFound {
  rentWagonId: number;
  pauseIndices: number[];
}

interface IPausesForRegion {
  region: Region;
  found: IPausesForRegionFound | null;
}

export function findPausesForRegions<
  TRentWagon extends RentWagonPurchaseListItem | RentWagonSellListItem
>(
  selectedRegions: Region[],
  nodes: Array<TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>>,
  startDate: Date,
  endDate: Date
) {
  const result: IPausesForRegion[] = [];
  const nodeRows = flattenNodesToRows(nodes);

  for (const region of selectedRegions) {
    const { cols, rows } = region;

    if (rows && cols && rows[1] - rows[0] === 0) {
      const nodeRow = nodeRows[rows[0]];

      if (
        nodeRow &&
        nodeRow.type === 'node' &&
        nodeRow.node.data.kind === 'rentWagon'
      ) {
        const { rentWagon } = nodeRow.node.data;

        const pauseIndices: number[] = [];

        (
          rentWagon.pauses as Array<RentWagonPurchasePause | RentWagonSellPause>
        ).forEach((pause, pauseIndex) => {
          const pauseCols = pauseToColumnCellInterval(
            pause,
            startDate,
            endDate
          );

          if (
            pauseCols &&
            pauseCols[0] === cols[0] &&
            pauseCols[1] === cols[1]
          ) {
            pauseIndices.push(pauseIndex);
          }
        });

        result.push({
          region,
          found:
            pauseIndices.length !== 0
              ? { rentWagonId: rentWagon.id, pauseIndices }
              : null,
        });
      } else {
        result.push({ region, found: null });
      }
    } else {
      result.push({ region, found: null });
    }
  }

  return result;
}

interface IProps<
  TRentWagon extends RentWagonPurchaseListItem | RentWagonSellListItem
> {
  baseSegment: string;
  contractsBaseSegment: string;
  endDate: Date;
  fetchChildNodes: (
    parentNodes: Array<TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>>
  ) => Promise<Array<TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>>>;
  isFetching: boolean;
  nodes: Array<TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>>;
  selectedNodes: Array<TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>>;
  selectedRegions: Region[];
  startDate: Date;
  onNodesChange: (
    newNodesOrUpdater:
      | Array<TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>>
      | ((
          prevNodes: Array<
            TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>
          >
        ) => Array<TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>>)
  ) => void;
  onPauseUpdate: (
    rentWagon: RentWagonPurchaseListItem | RentWagonSellListItem,
    pauseIndex: number,
    updatedPause: RentWagonPurchasePause | RentWagonSellPause
  ) => Promise<void>;
  onSelectedNodesChange: (
    newSelectedNodes: Array<
      TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>
    >
  ) => void;
  onSelection: (newSelectedRegions: Region[]) => void;
}

export function RentWagonsTreeTable<
  TRentWagon extends RentWagonPurchaseListItem | RentWagonSellListItem
>({
  baseSegment,
  contractsBaseSegment,
  endDate,
  fetchChildNodes,
  isFetching,
  nodes,
  selectedNodes,
  selectedRegions,
  startDate,
  onNodesChange,
  onPauseUpdate,
  onSelectedNodesChange,
  onSelection,
}: IProps<TRentWagon>) {
  const datesToShow = useMemo(
    () =>
      createDateRange('day', startDate, dayjs(endDate).add(1, 'day').toDate()),

    [endDate, startDate]
  );

  const staticColumns = useMemo(
    (): Array<TreeTableColumn<RentWagonsTreeTableNodeData<TRentWagon>>> => [
      {
        id: 'name',
        label: 'Наименование',
        defaultWidth: 400,
        expandable: true,
        copyCellContent: node => {
          switch (node.data.kind) {
            case 'supplier': {
              const { supplierName, protocolCount } = node.data;

              return `${supplierName} (${protocolCount})`;
            }
            case 'protocol': {
              const { protocolName, rentWagonCount } = node.data;

              return `${protocolName} (${rentWagonCount})`;
            }
            case 'rentWagon': {
              const { rentWagon } = node.data;

              return rentWagon.wagon;
            }
          }
        },
        renderCellContent: node => {
          switch (node.data.kind) {
            case 'supplier': {
              const { supplierId, supplierName, protocolCount } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: supplierId }}
                  rel="noopener"
                  target="_blank"
                  to="partners.edit"
                >
                  {`${supplierName} (${protocolCount})`}
                </Ellipsis>
              );
            }
            case 'protocol': {
              const { contractId, protocolId, protocolName, rentWagonCount } =
                node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{
                    id: contractId,
                    protocolId,
                  }}
                  rel="noopener"
                  target="_blank"
                  to={`${contractsBaseSegment}.view.rentProtocols.view`}
                >
                  {`${protocolName} (${rentWagonCount})`}
                </Ellipsis>
              );
            }
            case 'rentWagon': {
              const { rentWagon } = node.data;

              return (
                <Ellipsis
                  component={Link}
                  params={{ id: rentWagon.id }}
                  rel="noopener"
                  target="_blank"
                  to={`${baseSegment}.edit`}
                >
                  {rentWagon.wagon}
                </Ellipsis>
              );
            }
          }
        },
      },
    ],
    [baseSegment, contractsBaseSegment]
  );

  invariant(staticColumns.length === DATE_COLUMN_START_INDEX);

  const columns = useMemo(
    () =>
      staticColumns.concat(
        datesToShow.map(
          (date): TreeTableColumn<RentWagonsTreeTableNodeData<TRentWagon>> => ({
            id: dayjs(date).toISOString(),
            label: dayjs(date).format('DD'),
            defaultWidth: 40,
            copyCellContent: () => '',
            renderHeadContent: label => (
              <Tooltip content={dayjs(date).format(DATE_FORMAT_DATE)}>
                {label}
              </Tooltip>
            ),
            renderCell: ({
              children,
              row,
              style,
              onMouseDown,
              onMouseEnter,
            }) => {
              if (row.node.data.kind !== 'rentWagon') {
                return (
                  <BaseCell
                    style={style}
                    onMouseDown={onMouseDown}
                    onMouseEnter={onMouseEnter}
                  >
                    {children}
                  </BaseCell>
                );
              }

              const { rentWagon } = row.node.data;

              return (
                <BaseCell
                  style={{
                    ...style,
                    backgroundColor:
                      date >= rentWagon.startDate &&
                      (rentWagon.endDate === null || date <= rentWagon.endDate)
                        ? RentWagonColor.RENTED
                        : RentWagonColor.NOT_RENTED,
                  }}
                  onMouseDown={onMouseDown}
                  onMouseEnter={onMouseEnter}
                >
                  {children}
                </BaseCell>
              );
            },
            renderCellContent: () => null,
          })
        )
      ),
    [datesToShow, staticColumns]
  );

  const [isDraggingOverPauses, setDraggingOverPauses] = React.useState(false);
  const [isDraggingWithDeselection, setDraggingWithDeselection] =
    React.useState(false);

  const [keyPressed, setKeyPressed] = React.useState({
    shift: false,
    control: false,
  });

  const [selectedPauses, setSelectedPauses] = React.useState<Region[]>([]);

  function getFrameRows(start: Region, end: Region): Region[] {
    const newRows = [] as Region[];
    const rowsCount = end.rows[0] - start.rows[0];

    if (rowsCount > 0) {
      for (let i = 0; i < rowsCount + 1; i++) {
        newRows.push({
          cols: start.cols,
          rows: [start.rows[0] + i, start.rows[1] + i],
        });
      }
    }

    if (rowsCount < 0) {
      for (let i = 0; i < Math.abs(rowsCount - 1); i++) {
        newRows.push({
          cols: start.cols,
          rows: [start.rows[0] - i, start.rows[1] - i],
        });
      }
    }

    return newRows;
  }

  const handleClickRow = React.useCallback(() => {
    onSelection(selectedPauses);
  }, [onSelection, selectedPauses]);

  React.useEffect(() => {
    if (
      selectedPauses.length > 1 &&
      isDraggingOverPauses &&
      !isDraggingWithDeselection &&
      !keyPressed.shift &&
      !keyPressed.control
    ) {
      onSelection(selectedPauses);
    }
  }, [
    keyPressed,
    onSelection,
    selectedPauses,
    isDraggingOverPauses,
    isDraggingWithDeselection,
  ]);

  const customRegions = useMemo(
    () => {
      const rows = flattenNodesToRows(nodes);

      const result: RenderableRegion[] = [];

      rows.forEach((row, rowIndex) => {
        if (row.type !== 'node' || row.node.data.kind !== 'rentWagon') {
          return;
        }

        const { rentWagon } = row.node.data;

        (
          rentWagon.pauses as Array<RentWagonPurchasePause | RentWagonSellPause>
        ).forEach((pause, pauseIndex) => {
          const cols = pauseToColumnCellInterval(pause, startDate, endDate);

          if (!cols) {
            return;
          }

          const region: Region = { cols, rows: [rowIndex, rowIndex] };

          const isRegionSelected = selectedRegions.some(r =>
            deepEqual(r, region)
          );

          result.push({
            region,
            render: ({ style }) => (
              <PauseRegion
                pause={pause}
                style={style}
                onMouseEnter={() => {
                  if (!isDraggingOverPauses) {
                    return;
                  }

                  if (isDraggingWithDeselection) {
                    if (isRegionSelected) {
                      setSelectedPauses(() => {
                        const pauses = selectedPauses.filter(
                          r => !deepEqual(r, region)
                        );

                        return getFrameRows(
                          pauses[0],
                          pauses[pauses.length - 1]
                        );
                      });
                    }
                  } else {
                    if (!isRegionSelected) {
                      setSelectedPauses(() => {
                        const pauses = selectedPauses.concat(region);

                        return getFrameRows(
                          pauses[0],
                          pauses[pauses.length - 1]
                        );
                      });
                    }
                  }
                }}
                onMouseSelectionStart={(isAdditive, isShiftPressing) => {
                  setKeyPressed({
                    shift: isShiftPressing || false,
                    control: isAdditive,
                  });

                  setDraggingOverPauses(true);
                  setDraggingWithDeselection(false);

                  if (isAdditive) {
                    if (isRegionSelected) {
                      setDraggingWithDeselection(true);
                      setSelectedPauses(
                        selectedPauses.filter(r => !deepEqual(r, region))
                      );
                    } else {
                      setSelectedPauses(selectedPauses.concat(region));
                    }
                  } else {
                    setSelectedPauses([region]);
                  }

                  if (isShiftPressing) {
                    if (selectedPauses?.length > 0 && region?.rows?.length) {
                      setSelectedPauses(
                        getFrameRows(selectedPauses[0], region)
                      );
                    }
                  }
                }}
                onMouseSelectionEnd={() => {
                  setDraggingOverPauses(false);
                  setKeyPressed({
                    shift: false,
                    control: false,
                  });
                }}
                onClick={handleClickRow}
                onPauseUpdate={updatedPause =>
                  onPauseUpdate(rentWagon, pauseIndex, updatedPause)
                }
              />
            ),
          });
        });
      });

      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedPauses,
      setSelectedPauses,
      isDraggingOverPauses,
      isDraggingWithDeselection,
      endDate,
      nodes,
      selectedRegions,
      startDate,
      onPauseUpdate,
      onSelection,
    ]
  );

  const isNodeSelectable = useCallback(
    (node: TreeTableNode<RentWagonsTreeTableNodeData<TRentWagon>>) =>
      node.data.kind === 'rentWagon' ||
      (node.children?.nodes.length !== 0 && node.data.kind === 'protocol'),
    []
  );

  return (
    <TreeTable
      columns={columns}
      customRegions={customRegions}
      fetchChildNodes={fetchChildNodes}
      isFetching={isFetching}
      isNodeSelectable={isNodeSelectable}
      nodes={nodes}
      selectedNodes={selectedNodes}
      selectedRegions={selectedRegions}
      stickyColumnCount={1}
      onNodesChange={onNodesChange}
      onSelectedNodesChange={onSelectedNodesChange}
      onSelection={onSelection}
    />
  );
}

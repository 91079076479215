import { FilterPopoverUi, useFilterPopover } from '_core/filters/filterPopover';
import { Select } from '_core/inputs/select';
import * as React from 'react';

import * as css from './isEmptyFilter.module.css';

interface Props {
  initialValue: string | undefined;
  label: string;
  onApply: (newValue: string | undefined) => void;
}

export function IsEmptyFilter({ initialValue, label, onApply }: Props) {
  const filterPopover = useFilterPopover<string | undefined, string | null>(
    initialValue,
    onApply,

    {
      emptyValue: undefined,
      isEmpty: value => !value,
      serialize: value => value || undefined,
      deserialize: value => value || null,
    }
  );

  return (
    <FilterPopoverUi api={filterPopover.ui} label={label}>
      <div className={css.root}>
        <Select
          fill
          options={[
            { label: 'Гружёные', value: 'False' },
            { label: 'Порожние', value: 'True' },
          ]}
          value={filterPopover.value}
          withEmptyOption
          onChange={filterPopover.change}
        />
      </div>
    </FilterPopoverUi>
  );
}

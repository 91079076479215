import * as React from 'react';
import invariant from 'tiny-invariant';

import { BaseTableColumn } from './baseTable';

const MIN_COLUMN_WIDTH = 30;

interface State {
  columnIndex: number;
  columnStartCoords: number[];
  currentHandleLeft: number;
  currentHandleRight: number;
  innerLeft: number;
  rulerHeight: number;
  rulerLeft: number;
  startClientX: number;
}

interface ContextValue {
  handleMouseDown: (event: React.MouseEvent, columnIndex: number) => void;
  rulerHeight: number | null;
  rulerLeft: number | null;
  setResizeHandleEl: (columnIndex: number, el: HTMLDivElement | null) => void;
}

const Context = React.createContext<ContextValue | null>(null);

if (__DEV__) {
  Context.displayName = 'ResizeUiContext';
}

export function ResizeUiProvider({
  children,
  columns,
  innerRef,
  onColumnWidthChanged,
}: {
  children: React.ReactNode;
  columns: BaseTableColumn[];
  innerRef: React.MutableRefObject<HTMLDivElement | null>;
  onColumnWidthChanged: (columnId: string, newWidth: number) => void;
}) {
  const resizeHandlesRef = React.useRef<Map<number, HTMLDivElement>>(new Map());
  const [state, setState] = React.useState<State | null>(null);
  const rulerHeight = state && state.rulerHeight;
  const rulerLeft = state && state.rulerLeft;

  const contextValue = React.useMemo((): ContextValue => {
    function setResizeHandleEl(columnIndex: number, el: HTMLDivElement | null) {
      const resizeHandles = resizeHandlesRef.current;

      if (el) {
        resizeHandles.set(columnIndex, el);
      } else {
        resizeHandles.delete(columnIndex);
      }
    }

    function handleMouseDown(
      event: React.MouseEvent<Element, MouseEvent>,
      columnIndex: number
    ) {
      const innerEl = innerRef.current;

      if (!innerEl) {
        return;
      }

      event.preventDefault();

      const innerBcr = innerEl.getBoundingClientRect();

      const resizeHandleBcrs = Array.from(resizeHandlesRef.current.entries())
        .sort((aEntry, bEntry) => {
          const a = aEntry[0];
          const b = bEntry[0];

          return a - b;
        })
        .map(entry => entry[1].getBoundingClientRect());

      const currentHandleLeft = resizeHandleBcrs[columnIndex].left;
      const currentHandleRight = resizeHandleBcrs[columnIndex].right;
      const innerLeft = innerBcr.left;

      const columnStartCoords = [0].concat(
        resizeHandleBcrs.map(bcr => bcr.right - innerLeft)
      );

      setState({
        columnStartCoords,
        columnIndex,
        currentHandleLeft,
        currentHandleRight,
        innerLeft,
        rulerHeight: innerBcr.height,
        rulerLeft: currentHandleLeft - innerLeft,
        startClientX: event.clientX,
      });
    }

    return {
      handleMouseDown,
      rulerHeight,
      rulerLeft,
      setResizeHandleEl,
    };
  }, [innerRef, rulerHeight, rulerLeft]);

  React.useLayoutEffect(() => {
    if (!state) {
      return;
    }

    function handleMouseMove(event: MouseEvent) {
      setState(prevState => {
        if (!prevState) {
          return null;
        }

        const {
          columnIndex,
          columnStartCoords,
          currentHandleLeft,
          innerLeft,
          startClientX,
        } = prevState;

        let newRulerLeft =
          currentHandleLeft - innerLeft + event.clientX - startClientX;

        const columnStartCoord = columnStartCoords[columnIndex];

        if (newRulerLeft < columnStartCoord + MIN_COLUMN_WIDTH) {
          newRulerLeft = columnStartCoord + MIN_COLUMN_WIDTH;
        }

        return {
          ...prevState,
          rulerLeft: newRulerLeft,
        };
      });
    }

    function handleMouseUp(event: MouseEvent) {
      if (!state) {
        return;
      }

      const {
        columnIndex,
        columnStartCoords,
        currentHandleRight,
        innerLeft,
        startClientX,
      } = state;

      setState(null);

      const newWidth = Math.max(
        currentHandleRight -
          innerLeft +
          event.clientX -
          startClientX -
          columnStartCoords[columnIndex],
        MIN_COLUMN_WIDTH
      );

      const column = columns[columnIndex];

      onColumnWidthChanged(column.id, newWidth);
    }

    window.addEventListener('mousemove', handleMouseMove, false);
    window.addEventListener('mouseup', handleMouseUp, false);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove, false);
      window.removeEventListener('mouseup', handleMouseUp, false);
    };
  }, [columns, onColumnWidthChanged, state]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export function useResizeUi() {
  const value = React.useContext(Context);
  invariant(value);
  return value;
}

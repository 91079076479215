import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { Checkbox } from '_core/inputs/checkbox';
import { DateInputInFormGroupForFinalForm } from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroup } from '_core/inputs/inputMoney';
import { Select } from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { AnchorButton, Button, FormGroup, H4, Intent } from '@blueprintjs/core';
import { ExpeditionRequestsAutocompleteInFormGroup } from 'expeditionRequests/autocomplete';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import { PartnersAutocompleteInFormGroupForFinalForm } from 'partners/autocomplete';
import * as React from 'react';
import { useRef } from 'react';
import {
  IShipmentInfoDetail,
  IShipmentInfoWagonsUsageShipment,
} from 'shipmentInfo/api';
import {
  isTechrunShipmentInfoType,
  shipmentInfoTransportationsFieldNameForType,
  shipmentInfoTypeOptions,
} from 'shipmentInfo/utils';
import { SuppliersDocumentsFromFileAutocompleteInFormGroupForFinalForm } from 'suppliersDocuments/autocomplete';

import { RentProtocolSellAutocompleteInFormGroup } from '../../rentProtocolsSell/autocomplete';
import * as css from './form.module.css';
import { ShipmentInfoFormTransportationsTable } from './transportationsTable';
import { ShipmentInfoWagonUsageShipmentTable } from './wagonUsageShipmentTable';

export interface IShipmentInfoFormValues {
  date: Date;
  dateUpd: Date | null;
  files: string[];
  numberUpd: string;
  partner: number | null;
  supplierDoc: number | null;
  wagonsUsageShipment: IShipmentInfoWagonsUsageShipment[];
}

interface IProps {
  initialValues: IShipmentInfoFormValues;
  isFavorite: boolean;
  navigationLock: IFormNavigationLock;
  shipmentInfo: IShipmentInfoDetail;
  onIsFavoriteToggle: () => void;
  onAccountingCloseTransportations: (
    values: IShipmentInfoFormValues
  ) => Promise<SubmissionErrors | void>;
  onSaveAndContinue: (
    values: IShipmentInfoFormValues
  ) => Promise<SubmissionErrors | void>;
  onSave: (values: IShipmentInfoFormValues) => Promise<SubmissionErrors | void>;
}

type SubmitTrigger =
  | 'accountingCloseTransportations'
  | 'save'
  | 'saveAndContinue';

export function ShipmentInfoForm({
  initialValues,
  isFavorite,
  navigationLock,
  shipmentInfo,
  onIsFavoriteToggle,
  onAccountingCloseTransportations,
  onSave,
  onSaveAndContinue,
}: IProps) {
  const submitTriggerRef = useRef<SubmitTrigger>('save');

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={values => {
        switch (submitTriggerRef.current) {
          case 'accountingCloseTransportations':
            return onAccountingCloseTransportations(values);
          case 'save':
            return onSave(values);
          case 'saveAndContinue':
            return onSaveAndContinue(values);
        }
      }}
    >
      {({ dirty, error, form, handleSubmit, submitError, submitting }) => (
        <BaseForm onSubmit={handleSubmit}>
          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            <Row>
              <Grid>
                <Col span={3}>
                  <H4>Основная информация</H4>

                  <Checkbox
                    checked={shipmentInfo.isClosed}
                    disabled
                    id="isClosed"
                    label="Проведён"
                    name="isClosed"
                  />

                  <DateInputInFormGroupForFinalForm
                    id="date"
                    label="Дата"
                    name="date"
                  />

                  <PartnersAutocompleteInFormGroupForFinalForm
                    id="partner"
                    label="Клиент"
                    name="partner"
                  />

                  <FormGroupForFinalForm
                    label="Номер УПД"
                    labelFor="numberUpd"
                    name="numberUpd"
                  >
                    <InputGroupForFinalForm id="numberUpd" name="numberUpd" />
                  </FormGroupForFinalForm>

                  <DateInputInFormGroupForFinalForm
                    id="dateUpd"
                    label="Дата УПД"
                    name="dateUpd"
                  />

                  <FormGroup label="Тип">
                    <Select
                      disabled
                      fill
                      id="type"
                      name="type"
                      options={shipmentInfoTypeOptions}
                      value={shipmentInfo.type}
                    />
                  </FormGroup>

                  <InputMoneyInFormGroup
                    id="totalAmount"
                    label="Итого к выставлению (с учетом НДС)"
                    name="totalAmount"
                    readOnly
                    value={shipmentInfo.totalAmount}
                  />

                  <InputMoneyInFormGroup
                    id="feeAmount"
                    label="Комиссионное вознаграждение"
                    name="feeAmount"
                    readOnly
                    value={shipmentInfo.feeAmount}
                  />

                  {shipmentInfo.type === 'SELL_RENT_RATE' ? (
                    <RentProtocolSellAutocompleteInFormGroup
                      id="rentProtocol"
                      label="Протокол аренды"
                      name="rentProtocol"
                      readOnly
                      value={shipmentInfo.sellRentProtocol}
                    />
                  ) : (
                    <ExpeditionRequestsAutocompleteInFormGroup
                      id="expeditionsRequest"
                      label="Заявка экспедирования"
                      name="expeditionsRequest"
                      readOnly
                      value={shipmentInfo.expeditionsRequest}
                    />
                  )}

                  {shipmentInfo.type !== 'SELL_RENT_RATE' ? (
                    <SuppliersDocumentsFromFileAutocompleteInFormGroupForFinalForm
                      id="supplierDoc"
                      label="Документ подрядчика"
                      name="supplierDoc"
                    />
                  ) : null}

                  <H4>Файлы</H4>

                  <FileInputMultipleForFinalForm id="files" name="files" />
                </Col>

                <Col span={9}>
                  <VGrid className={css.tableVGrid} stretch>
                    <Row>
                      <Toolbar align="right">
                        <Button
                          icon={isFavorite ? 'star' : 'star-empty'}
                          intent={Intent.SUCCESS}
                          minimal
                          onClick={() => {
                            onIsFavoriteToggle();
                          }}
                        />

                        <Button
                          disabled={shipmentInfo.isClosed || submitting}
                          intent={Intent.PRIMARY}
                          text="Провести"
                          onClick={() => {
                            submitTriggerRef.current =
                              'accountingCloseTransportations';
                            form.submit();
                          }}
                        />

                        <AnchorButton
                          href={`/api/shipment_info/${shipmentInfo.id}/download_xlsx`}
                          icon="download"
                          intent={Intent.SUCCESS}
                          text="Скачать"
                        />
                      </Toolbar>
                    </Row>

                    <Row stretch>
                      {shipmentInfo.wagonsUsageShipment.length !== 0 ? (
                        <ShipmentInfoWagonUsageShipmentTable
                          wagonsUsageShipment={shipmentInfo.wagonsUsageShipment}
                        />
                      ) : (
                        <ShipmentInfoFormTransportationsTable
                          isTechrun={isTechrunShipmentInfoType(
                            shipmentInfo.type
                          )}
                          transportationIds={
                            shipmentInfo[
                              shipmentInfoTransportationsFieldNameForType(
                                shipmentInfo.type
                              )
                            ]
                          }
                        />
                      )}
                    </Row>
                  </VGrid>
                </Col>
              </Grid>
            </Row>

            <Row stickToBottom>
              <Toolbar align="right">
                <Button
                  disabled={submitting}
                  text="Сохранить и продолжить редактирование"
                  onClick={() => {
                    submitTriggerRef.current = 'saveAndContinue';
                    form.submit();
                  }}
                />

                <Button
                  disabled={submitting}
                  intent={Intent.PRIMARY}
                  text="Сохранить"
                  type="submit"
                  onClick={() => {
                    submitTriggerRef.current = 'save';
                  }}
                />
              </Toolbar>
            </Row>
          </VGrid>
        </BaseForm>
      )}
    </FinalForm>
  );
}

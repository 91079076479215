import { calcForForm } from '_core/decimal';
import { TableFieldErrors } from '_core/forms/tableFieldErrors';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyForFinalForm } from '_core/inputs/inputMoney';
import { isNotNull } from '_core/isNotNull';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { Toolbar } from '_core/toolbar';
import { Button } from '@blueprintjs/core';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useFieldArray } from 'react-final-form-arrays';

export interface IRepairPartsFieldItem {
  cost: string;
  name: string;
  price: string;
  quantity: string;
}

interface ITableItem {
  index: number;
  item: IRepairPartsFieldItem;
}

interface IProps {
  change: (field: string, value: unknown) => void;
  name: string;
}

export function RepairPartsField({ change, name }: IProps) {
  const { fields, meta } = useFieldArray<IRepairPartsFieldItem>(name);

  const columns = React.useMemo(
    (): Array<ListTableColumn<ITableItem>> => [
      {
        id: 'name',
        label: 'Название',
        defaultWidth: 150,
        renderCell: ({ children, style, onMouseDown, onMouseEnter }) => (
          <BaseCell
            noPadding
            style={style}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        renderCellContent: item => (
          <InputGroupForFinalForm
            id={`${fields.name}[${item.index}].name`}
            name={`${fields.name}[${item.index}].name`}
          />
        ),
      },
      {
        id: 'price',
        label: 'Цена',
        defaultWidth: 80,
        renderCell: ({ children, style, onMouseDown, onMouseEnter }) => (
          <BaseCell
            noPadding
            style={style}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        renderCellContent: item => (
          <InputMoneyForFinalForm
            id={`${fields.name}[${item.index}].price`}
            name={`${fields.name}[${item.index}].price`}
            onChange={newPrice => {
              change(
                `${fields.name}[${item.index}].cost`,
                calcForForm((price, quantity) => price.mul(quantity))(
                  newPrice,
                  fields.value[item.index].quantity
                )
              );
            }}
          />
        ),
      },
      {
        id: 'quantity',
        label: 'Количество',
        defaultWidth: 80,
        renderCell: ({ children, style, onMouseDown, onMouseEnter }) => (
          <BaseCell
            noPadding
            style={style}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        renderCellContent: item => (
          <InputGroupForFinalForm
            id={`${fields.name}[${item.index}].quantity`}
            name={`${fields.name}[${item.index}].quantity`}
            onChange={newQuantity => {
              change(
                `${fields.name}[${item.index}].cost`,
                calcForForm((price, quantity) => price.mul(quantity))(
                  fields.value[item.index].price,
                  newQuantity
                )
              );
            }}
          />
        ),
      },
      {
        id: 'cost',
        label: 'Сумма',
        defaultWidth: 80,
        renderCell: ({ children, style, onMouseDown, onMouseEnter }) => (
          <BaseCell
            noPadding
            style={style}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
          >
            {children}
          </BaseCell>
        ),
        renderCellContent: item => (
          <InputMoneyForFinalForm
            id={`${fields.name}[${item.index}].cost`}
            name={`${fields.name}[${item.index}].cost`}
            onChange={newCost => {
              change(
                `${fields.name}[${item.index}].price`,
                calcForForm((quantity, cost) => cost.dividedBy(quantity))(
                  fields.value[item.index].quantity,
                  newCost
                )
              );
            }}
          />
        ),
      },
    ],
    [change, fields.name, fields.value]
  );

  const getItemId = React.useCallback(
    (item: ITableItem) => String(item.index),
    []
  );

  const items = React.useMemo(
    () => fields.value.map((item, index): ITableItem => ({ item, index })),
    [fields.value]
  );

  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  return (
    <VGrid>
      <Row>
        <TableFieldErrors
          fieldLabels={[
            ['name', 'Название'],
            ['price', 'Цена'],
            ['quantity', 'Количество'],
          ]}
          rowsErrors={meta.error || meta.submitError}
        />

        <ListTable
          columns={columns}
          getItemId={getItemId}
          items={items}
          maxHeight={250}
          selectedItems={selectedItems}
          onSelectedItemsChange={setSelectedItems}
        />
      </Row>

      <Row>
        <Toolbar>
          <Button
            text="Добавить строку"
            onClick={() => {
              fields.push({ cost: '', name: '', price: '', quantity: '' });
            }}
          />

          <Button
            disabled={selectedItems.length === 0}
            text="Удалить выбранные строки"
            onClick={() => {
              selectedItems
                .map(itemId => items.find(item => getItemId(item) === itemId))
                .filter(isNotNull)
                .map(item => item.index)
                .sort((a, b) => b - a)
                .forEach(rowIndex => {
                  fields.remove(rowIndex);
                });

              setSelectedItems([]);
            }}
          />
        </Toolbar>
      </Row>
    </VGrid>
  );
}

import { DATE_FORMAT_DATE } from '_core/dates/formats';
import { createDateRange, parseDate } from '_core/dates/utils';
import { clamp } from '_core/fp/clamp';
import { CellInterval, Region } from '_core/react-window/baseTable';
import { BaseCell } from '_core/react-window/cells';
import { ListTable, ListTableColumn } from '_core/react-window/listTable';
import { RenderableRegion } from '_core/react-window/regions';
import { Ellipsis } from '_core/strings/ellipsis';
import { Tooltip } from '@blueprintjs/core';
import dayjs from 'dayjs';
import {
  getWagonsDailyUsageStateLabel,
  IWagonsDailyUsagePause,
  IWagonsDailyUsageWagon,
} from 'expeditionRequests/types';
import { deepEqual } from 'fast-equals';
import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import invariant from 'tiny-invariant';

import { WagonsDailyUsageColor } from './colors';
import { PauseRegion } from './pauseRegion';

interface IProps {
  endDate: Date;
  isFetching: boolean;
  selectedRegions: Region[];
  selectedWagons: string[];
  startDate: Date;
  wagons: IWagonsDailyUsageWagon[];
  onPauseUpdate: (updatedPause: IWagonsDailyUsagePause) => Promise<void>;
  onSelectedWagonsChange: (newSelectedItems: string[]) => void;
  onSelection: (newSelectedRegions: Region[]) => void;
}

export const DATE_COLUMN_START_INDEX = 7;

export function columnCellIntervalToDateRange(
  rangeStartDate: Date,
  [startCol, endCol]: CellInterval
) {
  const startDate = dayjs(rangeStartDate)
    .add(startCol - DATE_COLUMN_START_INDEX, 'day')
    .toDate();

  const endDate = dayjs(rangeStartDate)
    .add(endCol - DATE_COLUMN_START_INDEX, 'day')
    .toDate();

  return [startDate, endDate];
}

function pauseToColumnCellInterval(
  pause: IWagonsDailyUsagePause,
  startDate: Date,
  endDate: Date
): CellInterval | null {
  const pauseStartDate = dayjs(pause.startDate).toDate();
  const pauseEndDate = pause.endDate ? dayjs(pause.endDate).toDate() : null;

  if (
    pauseStartDate > endDate ||
    (pauseEndDate != null && pauseEndDate < startDate)
  ) {
    return null;
  }

  const dateRangeLength = dayjs(endDate).add(1, 'day').diff(startDate, 'day');
  const clampDateOffset = clamp(0, dateRangeLength);

  const startDateOffset = clampDateOffset(
    dayjs(pauseStartDate).diff(startDate, 'day')
  );

  const endDateOffset = clampDateOffset(
    dayjs(pauseEndDate || endDate).diff(startDate, 'day')
  );

  const startCol = DATE_COLUMN_START_INDEX + startDateOffset;
  const endCol = DATE_COLUMN_START_INDEX + endDateOffset;

  return [startCol, endCol];
}

interface IPausesForRegionFound {
  wagon: string;
  pauseIndices: number[];
}

interface IPausesForRegion {
  region: Region;
  found: IPausesForRegionFound | null;
}

export function findPausesForRegions(
  regions: Region[],
  wagons: IWagonsDailyUsageWagon[],
  startDate: Date,
  endDate: Date
) {
  const result: IPausesForRegion[] = [];

  for (const region of regions) {
    const { cols, rows } = region;

    if (rows && cols && rows[1] - rows[0] === 0) {
      const wagon = wagons[rows[0]];

      if (wagon) {
        const pauseIndices: number[] = [];

        wagon.pauses.forEach((pause, pauseIndex) => {
          const pauseCols = pauseToColumnCellInterval(
            pause,
            startDate,
            endDate
          );

          if (
            pauseCols &&
            pauseCols[0] === cols[0] &&
            pauseCols[1] === cols[1]
          ) {
            pauseIndices.push(pauseIndex);
          }
        });

        result.push({
          region,
          found:
            pauseIndices.length !== 0
              ? { wagon: wagon.wagon, pauseIndices }
              : null,
        });
      } else {
        result.push({ region, found: null });
      }
    } else {
      result.push({ region, found: null });
    }
  }

  return result;
}

export function WagonsDailyUsageTabTable({
  endDate,
  isFetching,
  wagons,
  selectedRegions,
  selectedWagons,
  startDate,
  onPauseUpdate,
  onSelectedWagonsChange,
  onSelection,
}: IProps) {
  const staticColumns = useMemo(
    (): Array<ListTableColumn<IWagonsDailyUsageWagon>> => [
      {
        id: 'wagon',
        label: 'Вагон',
        defaultWidth: 90,
        copyCellContent: wagon => wagon.wagon,
        renderCellContent: wagon => (
          <Ellipsis component="span">{wagon.wagon}</Ellipsis>
        ),
      },
      {
        id: 'startDate',
        label: 'Дата начала',
        defaultWidth: 80,
        copyCellContent: wagon =>
          dayjs(wagon.startDate).format(DATE_FORMAT_DATE),
        renderCellContent: wagon => (
          <Ellipsis component="span">
            {dayjs(wagon.startDate).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'endDate',
        label: 'Дата окончания',
        defaultWidth: 80,
        copyCellContent: wagon => dayjs(wagon.endDate).format(DATE_FORMAT_DATE),
        renderCellContent: wagon => (
          <Ellipsis component="span">
            {dayjs(wagon.endDate).format(DATE_FORMAT_DATE)}
          </Ellipsis>
        ),
      },
      {
        id: 'duration',
        label: 'Кол-во суток начислено',
        defaultWidth: 40,
        copyCellContent: wagon => String(wagon.duration),
        renderCellContent: wagon => (
          <Ellipsis component="span">{String(wagon.duration)}</Ellipsis>
        ),
      },
      {
        id: 'state',
        label: 'Состояние',
        defaultWidth: 180,
        copyCellContent: wagon => getWagonsDailyUsageStateLabel(wagon.state),
        renderCellContent: wagon => (
          <Ellipsis component="span">
            {getWagonsDailyUsageStateLabel(wagon.state)}
          </Ellipsis>
        ),
      },
    ],
    []
  );

  // +1 for line number
  // +1 for selection column
  invariant(
    staticColumns.length + 2 === DATE_COLUMN_START_INDEX,
    'DATE_COLUMN_START_INDEX has a wrong value'
  );

  const datesToShow = useMemo(
    () =>
      createDateRange('day', startDate, dayjs(endDate).add(1, 'day').toDate()),

    [endDate, startDate]
  );

  const columns = useMemo(
    () =>
      staticColumns.concat(
        datesToShow.map((date): ListTableColumn<IWagonsDailyUsageWagon> => {
          return {
            id: dayjs(date).toISOString(),
            label: dayjs(date).format('DD'),
            defaultWidth: 40,
            copyCellContent: () => '',
            renderHeadContent: label => (
              <Tooltip content={dayjs(date).format(DATE_FORMAT_DATE)}>
                {label}
              </Tooltip>
            ),
            renderCell: ({
              children,
              item: wagon,
              style,
              onMouseDown,
              onMouseEnter,
            }) => {
              const activeStartDate = parseDate(wagon.startDate);
              const activeEndDate = parseDate(wagon.endDate);

              return (
                <BaseCell
                  style={{
                    ...style,
                    backgroundColor:
                      date >= activeStartDate && date <= activeEndDate
                        ? WagonsDailyUsageColor.ACTIVE
                        : WagonsDailyUsageColor.INACTIVE,
                  }}
                  onMouseDown={onMouseDown}
                  onMouseEnter={onMouseEnter}
                >
                  {children}
                </BaseCell>
              );
            },
            renderCellContent: () => null,
          };
        })
      ),
    [datesToShow, staticColumns]
  );

  const getItemId = useCallback(
    (wagon: IWagonsDailyUsageWagon) => wagon.wagon,
    []
  );

  const isDraggingOverPausesRef = useRef(false);
  const isDraggingWithDeselection = useRef(false);

  const customRegions = useMemo(() => {
    const result: RenderableRegion[] = [];

    wagons.forEach((wagon, rowIndex) => {
      wagon.pauses.forEach(pause => {
        const cols = pauseToColumnCellInterval(pause, startDate, endDate);

        if (!cols) {
          return;
        }

        const region: Region = { cols, rows: [rowIndex, rowIndex] };

        const isRegionSelected = selectedRegions.some(r =>
          deepEqual(r, region)
        );

        result.push({
          region,

          render: ({ style }) => (
            <PauseRegion
              pause={pause}
              style={style}
              onMouseEnter={() => {
                if (!isDraggingOverPausesRef.current) {
                  return;
                }

                if (isDraggingWithDeselection.current) {
                  if (isRegionSelected) {
                    onSelection(
                      selectedRegions.filter(r => !deepEqual(r, region))
                    );
                  }
                } else {
                  if (!isRegionSelected) {
                    onSelection(selectedRegions.concat(region));
                  }
                }
              }}
              onMouseSelectionStart={isAdditive => {
                isDraggingOverPausesRef.current = true;
                isDraggingWithDeselection.current = false;

                if (isAdditive) {
                  if (isRegionSelected) {
                    isDraggingWithDeselection.current = true;
                    onSelection(
                      selectedRegions.filter(r => !deepEqual(r, region))
                    );
                  } else {
                    onSelection(selectedRegions.concat(region));
                  }
                } else {
                  onSelection([region]);
                }
              }}
              onMouseSelectionEnd={() => {
                isDraggingOverPausesRef.current = false;
              }}
              onPauseUpdate={onPauseUpdate}
            />
          ),
        });
      });
    });

    return result;
  }, [endDate, selectedRegions, startDate, wagons, onPauseUpdate, onSelection]);

  return (
    <ListTable
      columns={columns}
      customRegions={customRegions}
      getItemId={getItemId}
      isFetching={isFetching}
      items={wagons}
      selectedItems={selectedWagons}
      selectedRegions={selectedRegions}
      stickyColumnCount={7}
      onSelectedItemsChange={onSelectedWagonsChange}
      onSelection={onSelection}
    />
  );
}

import { DATE_FORMAT_DATETIME } from '_core/dates/formats';
import { FinalForm } from '_core/final-form/finalForm';
import { BaseForm } from '_core/forms/baseForm';
import { FormErrors } from '_core/forms/formErrors';
import { CheckboxForFinalForm } from '_core/inputs/checkbox';
import {
  DateInputInFormGroup,
  DateInputInFormGroupForFinalForm,
} from '_core/inputs/dateInput';
import { FileInputMultipleForFinalForm } from '_core/inputs/fileInputMultiple';
import { FormGroupForFinalForm } from '_core/inputs/formGroup';
import { InputGroupForFinalForm } from '_core/inputs/inputGroup';
import { InputMoneyInFormGroupForFinalForm } from '_core/inputs/inputMoney';
import { SelectInFormGroupForFinalForm } from '_core/inputs/select';
import {
  FormNavigationLock,
  IFormNavigationLock,
} from '_core/router5/navigationLock';
import { Toolbar } from '_core/toolbar';
import { AnchorButton, Button, H4, Intent } from '@blueprintjs/core';
import { UsersAutocompleteInFormGroup } from 'accounts/autocomplete';
import { ContactsAutocompleteInFormGroupForFinalForm } from 'contacts/autocomplete';
import { DocumentStatusSelectForFinalForm } from 'documents/statusSelect';
import { DocumentStatus } from 'documents/types';
import { SubmissionErrors } from 'final-form';
import { Col, Grid, Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useState } from 'react';
import { Payer, StartRepairDate, StopRepairDate } from 'rentProtocols/types';
import {
  payerOptions,
  startRepairDateOptions,
  stopRepairDateOptions,
} from 'rentProtocols/utils';
import { IRentProtocolPurchase } from 'rentProtocolsPurchase/api';
import { IRentProtocolSell } from 'rentProtocolsSell/api';
import { VatRate } from 'vatRates/vatRates';
import { vatRateOptions } from 'vatRates/vatRates';
import { IWagonType } from 'wagonTypes/api';
import { wagonTypeToOption } from 'wagonTypes/utils';

import { ProtocolDateAlert } from '../protocolDateAlert';

export interface IRentProtocolFormValues {
  date: Date | null;
  dayRate: string;
  dayRateTotal: string;
  endDate: Date | null;
  expensiveComponentsPayer: Payer;
  files: string[];
  fromRoutineRepairTariffPayer: Payer;
  fromScheduledRepairTariffPayer: Payer;
  hasRentRateRoutineRepair: boolean;
  hasRentRateScheduledRepair: boolean;
  note: string;
  number: string;
  partnerSign: number | null;
  receiveTariffPayer: Payer;
  returnTariffPayer: Payer;
  routineRepairPayer: Payer;
  scheduledRepairPayer: Payer;
  signerAuthority: string;
  startDate: Date | null;
  startRentRateRoutineRepair: StartRepairDate | null;
  startRentRateScheduledRepair: StartRepairDate | null;
  status: DocumentStatus;
  stopRentRateRoutineRepair: StopRepairDate | null;
  stopRentRateScheduledRepair: StopRepairDate | null;
  toRoutineRepairTariffPayer: Payer;
  toScheduledRepairTariffPayer: Payer;
  vatRate: VatRate;
  wagonType: number | null;
}

interface IProps {
  docxLink: string | undefined;
  editingLocked: boolean;
  initialValues: IRentProtocolFormValues;
  navigationLock: IFormNavigationLock;
  protocol: IRentProtocolPurchase | IRentProtocolSell | undefined;
  wagonTypes: IWagonType[];
  onCancelEditing: (() => void) | undefined;
  onDuplicate: (() => void) | undefined;
  onStartEditing: (() => void) | undefined;
  onSubmit: (
    values: IRentProtocolFormValues
  ) => Promise<SubmissionErrors | void>;
}

export function RentProtocolForm({
  docxLink,
  editingLocked,
  initialValues,
  navigationLock,
  protocol,
  wagonTypes,
  onCancelEditing,
  onDuplicate,
  onStartEditing,
  onSubmit,
}: IProps) {
  const [dateAlertIsOpen, setDateAlertIsOpen] = useState(false);

  return (
    <FinalForm<IRentProtocolFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        error,
        form,
        handleSubmit,
        submitError,
        submitting,
        values,
      }) => (
        <BaseForm onSubmit={handleSubmit}>
          <H4>
            {protocol
              ? `Протокол ${protocol.protocolNumber}`
              : 'Новый протокол'}
          </H4>

          <FormNavigationLock
            navigationLock={navigationLock}
            shouldLock={dirty}
          />

          <FormErrors error={error || submitError} />

          <VGrid>
            {docxLink ? (
              <Row>
                <AnchorButton
                  href={docxLink}
                  icon="download"
                  intent={Intent.PRIMARY}
                  text="Скачать .docx"
                />
              </Row>
            ) : null}

            <Row>
              <Grid>
                <Col span={4}>
                  <H4>Основная информация</H4>

                  <FormGroupForFinalForm
                    label="Номер"
                    labelFor="number"
                    name="number"
                  >
                    <InputGroupForFinalForm
                      id="number"
                      name="number"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <DateInputInFormGroupForFinalForm
                    fill
                    id="date"
                    label="Дата"
                    name="date"
                    readOnly={editingLocked}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="wagonType"
                    label="Вид подвижного состава"
                    name="wagonType"
                    options={wagonTypes.map(wagonTypeToOption)}
                  />

                  <Grid>
                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="startDate"
                        label="Дата начала"
                        name="startDate"
                        readOnly={editingLocked}
                        required
                      />
                    </Col>

                    <Col span={2}>
                      <DateInputInFormGroupForFinalForm
                        fill
                        id="endDate"
                        label="Дата окончания"
                        name="endDate"
                        readOnly={editingLocked}
                        required
                      />
                    </Col>
                  </Grid>

                  <InputMoneyInFormGroupForFinalForm
                    id="dayRate"
                    label="Ставка аренды в сутки"
                    name="dayRate"
                    readOnly={editingLocked}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="vatRate"
                    label="Ставка НДС"
                    name="vatRate"
                    options={vatRateOptions}
                  />

                  <InputMoneyInFormGroupForFinalForm
                    id="dayRateTotal"
                    label="Ставка аренды в сутки в т.ч. НДС"
                    name="dayRateTotal"
                    readOnly
                  />

                  <ContactsAutocompleteInFormGroupForFinalForm
                    id="partnerSign"
                    label="Подписывающий контакт"
                    name="partnerSign"
                    readOnly={editingLocked}
                  />

                  <FormGroupForFinalForm
                    label="Основание для подписи"
                    labelFor="signerAuthority"
                    name="signerAuthority"
                  >
                    <InputGroupForFinalForm
                      id="signerAuthority"
                      name="signerAuthority"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <FormGroupForFinalForm
                    label="Примечание"
                    labelFor="note"
                    name="note"
                  >
                    <InputGroupForFinalForm
                      id="note"
                      name="note"
                      readOnly={editingLocked}
                    />
                  </FormGroupForFinalForm>

                  <DocumentStatusSelectForFinalForm
                    disabled={editingLocked}
                    id="status"
                    label="Статус"
                    name="status"
                  />

                  {protocol && (
                    <Grid>
                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата создания"
                          readOnly
                          value={protocol.created}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор"
                          readOnly
                          value={protocol.author}
                        />
                      </Col>

                      <Col span={2}>
                        <DateInputInFormGroup
                          dateFormat={DATE_FORMAT_DATETIME}
                          fill
                          label="Дата изменения"
                          readOnly
                          value={protocol.modified}
                        />

                        <UsersAutocompleteInFormGroup
                          label="Автор изменений"
                          readOnly
                          value={protocol.authorModified}
                        />
                      </Col>
                    </Grid>
                  )}
                </Col>

                <Col span={4}>
                  <H4>Условия ремонта вагонов</H4>

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="routineRepairPayer"
                    label="Плательщик ТР"
                    name="routineRepairPayer"
                    options={payerOptions}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="scheduledRepairPayer"
                    label="Плательщик ДР"
                    name="scheduledRepairPayer"
                    options={payerOptions}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="expensiveComponentsPayer"
                    label="Плательщик замены дорогостоящих узлов"
                    name="expensiveComponentsPayer"
                    options={payerOptions}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="toRoutineRepairTariffPayer"
                    label="Плательщик тарифа в ТР"
                    name="toRoutineRepairTariffPayer"
                    options={payerOptions}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="fromRoutineRepairTariffPayer"
                    label="Плательщик тарифа из ТР"
                    name="fromRoutineRepairTariffPayer"
                    options={payerOptions}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="toScheduledRepairTariffPayer"
                    label="Плательщик тарифа в ДР"
                    name="toScheduledRepairTariffPayer"
                    options={payerOptions}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="fromScheduledRepairTariffPayer"
                    label="Плательщик тарифа из ДР"
                    name="fromScheduledRepairTariffPayer"
                    options={payerOptions}
                  />
                </Col>

                <Col span={4}>
                  <H4>Условия приема и сдачи из аренды</H4>

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="receiveTariffPayer"
                    label="Плательщик тарифа при приеме в аренду"
                    name="receiveTariffPayer"
                    options={payerOptions}
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked}
                    id="returnTariffPayer"
                    label="Плательщик тарифа при сдаче из аренды"
                    name="returnTariffPayer"
                    options={payerOptions}
                  />

                  <H4>Условия начисления арендной платы</H4>

                  <CheckboxForFinalForm
                    disabled={editingLocked}
                    id="hasRentRateRoutineRepair"
                    label="Арендная плата не начисляется при проведении ТР"
                    name="hasRentRateRoutineRepair"
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked || !values.hasRentRateRoutineRepair}
                    id="stopRentRateRoutineRepair"
                    label="Дата остановки начисления"
                    name="stopRentRateRoutineRepair"
                    options={stopRepairDateOptions}
                    withEmptyOption
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={editingLocked || !values.hasRentRateRoutineRepair}
                    id="startRentRateRoutineRepair"
                    label="Дата возобновления начисления"
                    name="startRentRateRoutineRepair"
                    options={startRepairDateOptions}
                    withEmptyOption
                  />

                  <CheckboxForFinalForm
                    disabled={editingLocked}
                    id="hasRentRateScheduledRepair"
                    label="Арендная плата не начисляется при проведении ДР"
                    name="hasRentRateScheduledRepair"
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={
                      editingLocked || !values.hasRentRateScheduledRepair
                    }
                    id="stopRentRateScheduledRepair"
                    label="Дата остановки начисления"
                    name="stopRentRateScheduledRepair"
                    options={stopRepairDateOptions}
                    withEmptyOption
                  />

                  <SelectInFormGroupForFinalForm
                    disabled={
                      editingLocked || !values.hasRentRateScheduledRepair
                    }
                    id="startRentRateScheduledRepair"
                    label="Дата возобновления начисления"
                    name="startRentRateScheduledRepair"
                    options={startRepairDateOptions}
                    withEmptyOption
                  />
                </Col>
              </Grid>
            </Row>

            <Row>
              <FileInputMultipleForFinalForm
                disabled={editingLocked}
                id="files"
                name="files"
              />
            </Row>

            <Row>
              <Toolbar align="right">
                {onDuplicate && (
                  <Button
                    disabled={submitting}
                    icon="duplicate"
                    text="Дублировать"
                    onClick={onDuplicate}
                  />
                )}

                {onStartEditing && (
                  <Button
                    disabled={submitting}
                    icon="unlock"
                    intent={Intent.DANGER}
                    text="Редактировать"
                    onClick={onStartEditing}
                  />
                )}

                {onCancelEditing && (
                  <Button
                    disabled={submitting}
                    text="Отменить"
                    onClick={() => {
                      form.reset();
                      onCancelEditing();
                    }}
                  />
                )}

                {!editingLocked && (
                  <Button
                    disabled={submitting}
                    intent={Intent.PRIMARY}
                    loading={submitting}
                    text="Сохранить"
                    onClick={() => {
                      const now = new Date();

                      if (
                        !protocol &&
                        values.endDate &&
                        values.endDate.getFullYear() > now.getFullYear()
                      ) {
                        setDateAlertIsOpen(true);
                      } else {
                        form.submit();
                      }
                    }}
                  />
                )}
              </Toolbar>
            </Row>
          </VGrid>

          <ProtocolDateAlert
            isOpen={dateAlertIsOpen}
            onCancel={() => {
              setDateAlertIsOpen(false);
            }}
            onConfirm={() => {
              form.submit();
              setDateAlertIsOpen(false);
            }}
          />
        </BaseForm>
      )}
    </FinalForm>
  );
}

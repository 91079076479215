import { deepChangeCase } from '_core/deepChangeCase';
import { isPlainObject } from '_core/fp/isPlainObject';
import { isString } from '_core/fp/isString';
import { mapValues } from '_core/fp/mapValues';
import { omitBy } from '_core/fp/omitBy';
import { camelCase, snakeCase } from 'change-case';
import ExtendableError from 'es6-error';
import * as qs from 'querystring';

interface Query {
  [key: string]: boolean | number | string | undefined | null;
}

interface FetchJsonOptions {
  body?: unknown;
  query?: Query;
  signal?: AbortSignal;
}

type CommonOptions = Omit<FetchJsonOptions, 'body'>;
type NoBodyOptions = Omit<CommonOptions, 'query'>;

export interface ApiClient {
  buildApiUrl(url: string, query?: Query): string;
  delete<T>(url: string, query?: Query, options?: NoBodyOptions): Promise<T>;
  get<T>(url: string, query?: Query, options?: NoBodyOptions): Promise<T>;
  patch<T>(url: string, body: unknown, options?: CommonOptions): Promise<T>;
  post<T>(url: string, body?: unknown, options?: CommonOptions): Promise<T>;
  put<T>(url: string, body: unknown, options?: CommonOptions): Promise<T>;
}

type HttpMethod = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';

const JSON_MIME = 'application/json';

export class BadRequestError extends ExtendableError {
  readonly name = 'BadRequestError';
}

type ValidationErrorFields = {
  readonly [K in string]?: ValidationErrorField;
};

type ValidationErrorField =
  | readonly string[]
  | readonly ValidationErrorFields[];

type ValidationErrorFieldsJoined = {
  readonly [K in string]?: ValidationErrorFieldJoined;
};

type ValidationErrorFieldJoined =
  | string
  | readonly ValidationErrorFieldsJoined[];

export class ValidationError extends ExtendableError {
  readonly name = 'ValidationError';
  private readonly fields;
  readonly formError: string[];

  constructor({ nonFieldErrors, ...otherFieldErrors }: ValidationErrorFields) {
    super(nonFieldErrors?.join(','));
    this.fields = otherFieldErrors;
    this.formError = Array.isArray(nonFieldErrors)
      ? nonFieldErrors.map(String)
      : [];
  }

  joinFieldErrors() {
    function joinFieldError(
      value: ValidationErrorField | undefined
    ): ValidationErrorFieldJoined | undefined {
      return Array.isArray(value)
        ? isString(value[0])
          ? value.join('\n')
          : value.map(field => mapValues(joinFieldError, field))
        : undefined;
    }

    return mapValues(joinFieldError, this.fields);
  }
}

export class NotFoundError extends ExtendableError {
  readonly name = 'NotFoundError';
}

export class UnauthorizedError extends ExtendableError {
  readonly name = 'UnauthorizedError';
}

export class UnexpectedError extends ExtendableError {
  readonly name = 'UnexpectedError';

  constructor(public readonly status: number, message: string | undefined) {
    super(message);
  }
}

interface FetchOptions {
  body?: string;
  headers?: Record<string, string>;
  method?: HttpMethod;
  referrerPolicy?: ReferrerPolicy;
  signal?: AbortSignal;
}

interface FetchResponse {
  headers: {
    get(name: string): string | null;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: () => Promise<any>;
  ok: boolean;
  status: number;
  statusText: string;
}

export function createApiClient(
  fetch: (url: string, options?: FetchOptions) => Promise<FetchResponse>
): ApiClient {
  function stringifyQuery(query: Query | undefined) {
    return (
      query &&
      qs.stringify(
        deepChangeCase(
          snakeCase,
          mapValues(
            value => {
              switch (value) {
                case true:
                  return 'True';
                case false:
                  return 'False';
                case null:
                  return 'None';
                default:
                  return String(value);
              }
            },
            omitBy(value => value === undefined, query)
          )
        )
      )
    );
  }

  function buildApiUrl(apiUrl: string, query?: Query) {
    const search = stringifyQuery(query);

    return [`/api${apiUrl}`].concat(search ? [search] : []).join('?');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function deepInvokeToJSON(input: any): any {
    return typeof input === 'object' &&
      input !== null &&
      typeof input.toJSON === 'function'
      ? deepInvokeToJSON(input.toJSON())
      : isPlainObject(input)
      ? Object.fromEntries(
          Object.entries(input).map(([key, value]) => [
            key,
            deepInvokeToJSON(value),
          ])
        )
      : Array.isArray(input)
      ? input.map(deepInvokeToJSON)
      : input;
  }

  async function fetchJson<T>(
    method: HttpMethod,
    apiUrl: string,
    { body = null, query, signal }: FetchJsonOptions
  ) {
    const response = await fetch(
      buildApiUrl(apiUrl, query),
      Object.assign(
        { method, referrerPolicy: 'no-referrer', signal } as FetchOptions,
        body
          ? {
              body: JSON.stringify(
                deepChangeCase(snakeCase, deepInvokeToJSON(body))
              ),
              headers: { 'content-type': JSON_MIME },
            }
          : undefined
      )
    );

    const mimeType = response.headers.get('content-type')?.split(';')[0].trim();

    const json =
      mimeType === JSON_MIME
        ? deepChangeCase(camelCase, await response.json())
        : /* istanbul ignore next */ null;

    if (response.ok) {
      return json as T;
    }

    switch (response.status) {
      case 400: {
        switch (json?.code) {
          case 'bad_request':
            throw new BadRequestError(json.detail);
          case 'validation_error':
            throw new ValidationError(json.fields);
        }
        break;
      }
      case 401:
        throw new UnauthorizedError(json?.detail);
      case 404:
        throw new NotFoundError(json?.detail);
    }

    throw new UnexpectedError(response.status, json?.detail);
  }

  return {
    buildApiUrl,
    delete: <T>(url: string, query?: Query, options: NoBodyOptions = {}) =>
      fetchJson<T>('DELETE', url, { ...options, query }),
    get: <T>(url: string, query?: Query, options: NoBodyOptions = {}) =>
      fetchJson<T>('GET', url, { ...options, query }),
    patch: <T>(url: string, body: unknown, options: CommonOptions = {}) =>
      fetchJson<T>('PATCH', url, { ...options, body }),
    post: <T>(url: string, body?: unknown, options: CommonOptions = {}) =>
      fetchJson<T>('POST', url, { ...options, body }),
    put: <T>(url: string, body: unknown, options: CommonOptions = {}) =>
      fetchJson<T>('PUT', url, { ...options, body }),
  };
}

import { SelectOption } from '_core/inputs/select';

import { IWagonType } from './api';

export function getDefaultWagonType(
  wagonTypes: IWagonType[] | null
): IWagonType | undefined {
  if (!wagonTypes) {
    return undefined;
  }

  const indexFound = wagonTypes.findIndex(x => x.isDefault);
  const index = indexFound === -1 ? 0 : indexFound;
  const wagonType = wagonTypes[index];

  return wagonType;
}

export function wagonTypeToOption(wagonType: IWagonType): SelectOption {
  return {
    label: wagonType.name,
    value: String(wagonType.id),
  };
}

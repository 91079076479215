import { createAbortError } from '_core/abortError';
import { deepChangeCase } from '_core/deepChangeCase';
import { useToaster } from '_core/toaster/toasterContext';
import { Intent, IToastProps } from '@blueprintjs/core';
import { camelCase } from 'change-case';
import * as React from 'react';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import invariant from 'tiny-invariant';

import { FileUploaderToastMessage } from './fileUploaderToastMessage';

export interface IUploadFileResponse {
  contentType: string;
  created: string;
  file: string;
  id: string;
  name: string;
}

type UploadFile = (file: File) => {
  cancel: () => void;
  promise: Promise<IUploadFileResponse>;
};

interface IFileUploader {
  uploadFile: UploadFile;
}

const FileUploaderContext = createContext<IFileUploader | null>(null);

if (__DEV__) {
  FileUploaderContext.displayName = 'FileUploaderContext';
}

interface IProviderProps {
  children: ReactNode;
}

export function FileUploaderProvider({ children }: IProviderProps) {
  const toaster = useToaster();

  const fileUploader = useMemo(
    (): IFileUploader => ({
      uploadFile: file => {
        const xhr = new XMLHttpRequest();

        function getProgressFromEvent(
          event: ProgressEvent
        ): number | undefined {
          if (!event.lengthComputable) {
            return undefined;
          }

          return event.loaded / event.total;
        }

        return {
          cancel: () => {
            xhr.abort();
          },

          promise: new Promise<IUploadFileResponse>((resolve, reject) => {
            xhr.open('POST', '/api/uploads');
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.responseType = 'json';

            xhr.setRequestHeader(
              'Content-Disposition',
              `attachment; filename="${encodeURIComponent(file.name)}"`
            );

            let toastKey: string;

            const onDismiss = () => {
              xhr.abort();
            };

            const getToastProps = ({
              aborted,
              errorMessage,
              progress,
              uploadedFile,
            }: {
              aborted?: boolean;
              errorMessage?: string;
              progress?: number;
              uploadedFile?: IUploadFileResponse;
            }): IToastProps => {
              const intent = uploadedFile
                ? Intent.SUCCESS
                : aborted
                ? Intent.WARNING
                : errorMessage
                ? Intent.DANGER
                : Intent.PRIMARY;

              return {
                icon: 'cloud-upload',
                intent,
                onDismiss,
                timeout: uploadedFile ? 5000 : 0,
                message: (
                  <FileUploaderToastMessage
                    errorMessage={errorMessage}
                    file={file}
                    intent={intent}
                    progress={progress}
                    uploadedFile={uploadedFile}
                  />
                ),
              };
            };

            xhr.upload.onloadstart = event => {
              toastKey = toaster.show(
                getToastProps({ progress: getProgressFromEvent(event) })
              );
            };

            xhr.upload.onprogress = event => {
              toaster.show(
                getToastProps({ progress: getProgressFromEvent(event) }),
                toastKey
              );
            };

            xhr.upload.onload = () => {
              toaster.show(getToastProps({ progress: undefined }), toastKey);
            };

            xhr.onload = () => {
              const response: IUploadFileResponse = deepChangeCase(
                camelCase,
                xhr.response
              );

              if (xhr.status < 200 || xhr.status > 299) {
                reject(new Error('FILE_UPLOADER_ERROR: Unexpected status'));

                toaster.show(
                  getToastProps({
                    errorMessage: 'Не удалось загрузить файл',
                    progress: undefined,
                  }),
                  toastKey
                );
              } else {
                resolve(response);

                toaster.show(
                  getToastProps({
                    progress: undefined,
                    uploadedFile: response,
                  }),
                  toastKey
                );
              }
            };

            xhr.onabort = () => {
              reject(createAbortError('File uploading was aborted'));

              toaster.show(
                getToastProps({
                  aborted: true,
                  errorMessage: 'Загрузка файла была прервана',
                  progress: undefined,
                }),
                toastKey
              );
            };

            xhr.onerror = () => {
              toaster.show(
                getToastProps({
                  errorMessage: 'Не удалось загрузить файл',
                  progress: undefined,
                }),
                toastKey
              );

              reject(new Error('Could not upload file: unexpected error'));
            };

            xhr.send(file);
          }),
        };
      },
    }),
    [toaster]
  );

  return (
    <FileUploaderContext.Provider value={fileUploader}>
      {children}
    </FileUploaderContext.Provider>
  );
}

export function useFileUploader() {
  const fileUploader = useContext(FileUploaderContext);
  invariant(
    fileUploader,
    'You must render FileUploaderProvider higher in the tree'
  );
  return fileUploader;
}

export function useUploadFile() {
  const { uploadFile } = useFileUploader();
  const cancelsRef = useRef<Array<() => void>>([]);
  useEffect(() => () => cancelsRef.current.forEach(cancel => cancel()), []);

  return (file: File) => {
    const { cancel, promise } = uploadFile(file);
    cancelsRef.current.push(cancel);
    return promise;
  };
}

interface IConsumerProps {
  children: (fileUploader: IFileUploader) => React.ReactElement | null;
}

export function FileUploaderConsumer({ children }: IConsumerProps) {
  return children(useFileUploader());
}

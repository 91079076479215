import { Ellipsis } from '_core/strings/ellipsis';
import cx from 'classnames';
import * as React from 'react';

import * as css from './text.module.css';

interface IProps {
  align?: 'end';
  children?: React.ReactText;
  parentCellHeight?: number;
}

export function TextCell({ align, children, parentCellHeight }: IProps) {
  return (
    <Ellipsis
      className={cx(css.root, {
        [css.root_align_end]: align === 'end',
      })}
      component="span"
      style={{ height: parentCellHeight }}
    >
      {children == null ? '' : String(children)}
    </Ellipsis>
  );
}

import { BaseForm } from '_core/forms/baseForm';
import { CheckboxList } from '_core/inputs/checkboxList';
import { Button, Intent } from '@blueprintjs/core';
import * as React from 'react';

import * as css from './statusFilterForm.module.css';

export interface WeighingsStatusFilterFormValues {
  isProcessed: boolean | undefined;
  isSent: boolean | undefined;
}

interface Props {
  initialValues: WeighingsStatusFilterFormValues;
  onSubmit: (newValue: WeighingsStatusFilterFormValues) => void;
}

enum WeighingsStatusFilterOptionValue {
  IsProcessed = 'IS_PROCESSED',
  IsNotProcessed = 'IS_NOT_PROCESSED',
  IsSent = 'IS_SENT',
  IsNotSent = 'IS_NOT_SENT',
}

export function WeighingsStatusFilterForm({ initialValues, onSubmit }: Props) {
  const [value, setValue] = React.useState(initialValues);
  const checkboxListValue: WeighingsStatusFilterOptionValue[] = [];

  if (value.isProcessed === true) {
    checkboxListValue.push(WeighingsStatusFilterOptionValue.IsProcessed);
  } else if (value.isProcessed === false) {
    checkboxListValue.push(WeighingsStatusFilterOptionValue.IsNotProcessed);
  }

  if (value.isSent === true) {
    checkboxListValue.push(WeighingsStatusFilterOptionValue.IsSent);
  } else if (value.isSent === false) {
    checkboxListValue.push(WeighingsStatusFilterOptionValue.IsNotSent);
  }

  return (
    <BaseForm
      onSubmit={event => {
        onSubmit(value);
        event.preventDefault();
      }}
    >
      <div className={css.root}>
        <CheckboxList
          options={[
            {
              label: 'Обработано',
              value: WeighingsStatusFilterOptionValue.IsProcessed,
            },
            {
              label: 'Не обработано',
              value: WeighingsStatusFilterOptionValue.IsNotProcessed,
            },
            {
              label: 'Отправлено',
              value: WeighingsStatusFilterOptionValue.IsSent,
            },
            {
              label: 'Не отправлено',
              value: WeighingsStatusFilterOptionValue.IsNotSent,
            },
          ]}
          value={checkboxListValue}
          onChange={checkedItems => {
            const newValue: WeighingsStatusFilterFormValues = {
              isProcessed: undefined,
              isSent: undefined,
            };

            checkedItems.forEach(item => {
              switch (item) {
                case WeighingsStatusFilterOptionValue.IsProcessed:
                  newValue.isProcessed = true;
                  break;
                case WeighingsStatusFilterOptionValue.IsNotProcessed:
                  newValue.isProcessed = false;
                  break;
                case WeighingsStatusFilterOptionValue.IsSent:
                  newValue.isSent = true;
                  break;
                case WeighingsStatusFilterOptionValue.IsNotSent:
                  newValue.isSent = false;
                  break;
              }
            });

            setValue(newValue);
          }}
        />
      </div>

      <Button fill intent={Intent.PRIMARY} text="Применить" type="submit" />
    </BaseForm>
  );
}

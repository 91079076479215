import { useFileUploader } from '_core/fileUploader/fileUploaderContext';
import { Ellipsis } from '_core/strings/ellipsis';
import { Button, FormGroup, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import * as reactFinalForm from 'react-final-form';

import { Dropzone } from './dropzone';
import * as css from './fileInputSingle.module.css';

interface IProps {
  disabled?: boolean;
  error?: string;
  id?: string;
  isInvalid?: boolean;
  label?: string;
  value?: string | null;
  onChange: (newValue: string | null) => void;
}

export function FileInputSingle({
  disabled,
  error,
  id,
  isInvalid,
  label,
  value,
  onChange,
}: IProps) {
  const fileUploader = useFileUploader();
  const cancelRef = useRef<() => void>(() => undefined);

  useEffect(
    () => () => {
      cancelRef.current();
    },
    []
  );

  return (
    <FormGroup
      helperText={isInvalid && error}
      intent={isInvalid ? Intent.DANGER : undefined}
      labelFor={id}
      label={label}
    >
      {value && (
        <div className={css.valueWrapper}>
          <Ellipsis
            component="a"
            href={value}
            rel="noopener"
            target="_blank"
            title={value}
          >
            {value}
          </Ellipsis>

          <Button
            icon="delete"
            intent={Intent.DANGER}
            minimal
            small
            onClick={() => {
              onChange(null);
            }}
          />
        </div>
      )}

      {!disabled && (
        <Dropzone
          id={id}
          isInvalid={isInvalid}
          label="Загрузить файл"
          onFilesSelect={async files => {
            const { cancel, promise } = fileUploader.uploadFile(files[0]);
            cancelRef.current = cancel;
            const { file } = await promise;
            onChange(file);
          }}
        />
      )}
    </FormGroup>
  );
}

export function FileInputSingleForFinalForm({
  disabled,
  name,
  ...otherProps
}: Omit<IProps, 'value' | 'onChange'> & { name: string }) {
  return (
    <reactFinalForm.Field name={name}>
      {({ input, meta }) => {
        const error = meta.error || meta.submitError;

        return (
          <FileInputSingle
            disabled={meta.submitting || disabled}
            error={error}
            isInvalid={meta.touched && Boolean(error)}
            onChange={input.onChange}
            value={input.value}
            {...otherProps}
          />
        );
      }}
    </reactFinalForm.Field>
  );
}

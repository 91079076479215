import { ListTable } from '_core/react-window/listTable';
import { ISorting, SortingDirection } from '_core/sorting';
import { IConsigner } from 'consigners/types';
import { IPartnerSerialized, PartnersSortingField } from 'partners/api';
import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { SortingInput } from '../../_core/sortingInput';
import { Col, Grid, Row } from '../../layout/contentLayout';
import { getConsignersListTableColumns } from './columns';

interface IProps {
  consigners: IConsigner[];
  isFetching: boolean;
  listParams: string;
  partnersById: Record<number, IPartnerSerialized>;
  sorting: ISorting;
  onSortingChange: (newSorting: ISorting) => void;
}

export function ConsignersListTable({
  consigners,
  isFetching,
  listParams,
  partnersById,
  sorting,
  onSortingChange,
}: IProps) {
  const columns = useMemo(
    () => getConsignersListTableColumns({ listParams, partnersById }),
    [listParams, partnersById]
  );

  const getItemId = useCallback(
    (consigner: IConsigner) => String(consigner.id),
    []
  );

  const defaultSorting: ISorting<PartnersSortingField> = {
    field: PartnersSortingField.Created,
    direction: SortingDirection.Descending,
  };

  return (
    <>
      <Row>
        <Grid>
          <Col align="end">
            <SortingInput
              defaultValue={defaultSorting}
              options={columns
                .filter(column => column.sortable)
                .map(column => ({
                  label: column.label,
                  value: column.id as PartnersSortingField,
                }))}
              value={sorting}
              onChange={onSortingChange}
            />
          </Col>
        </Grid>
      </Row>

      <Row stretch>
        <ListTable
          columns={columns}
          getItemId={getItemId}
          isFetching={isFetching}
          items={consigners}
          sorting={sorting}
          onSortingChange={onSortingChange}
        />
      </Row>
    </>
  );
}

import { ApiClient } from '_core/api/client';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';
import { DocumentStatus } from 'documents/types';
import { Payer, StartRepairDate, StopRepairDate } from 'rentProtocols/types';
import { VatRate } from 'vatRates/vatRates';

const BASE_ENDPOINT = '/rent_protocols_purchase';

interface IRentProtocolPurchaseSerialized {
  author: number | null;
  authorModified: number | null;
  contract: number;
  created: string;
  date: string;
  dayRate: string;
  dayRateTotal: string;
  endDate: string;
  expensiveComponentsPayer: Payer;
  files: string[];
  fromRoutineRepairTariffPayer: Payer;
  fromScheduledRepairTariffPayer: Payer;
  hasRentRateRoutineRepair: boolean;
  hasRentRateScheduledRepair: boolean;
  id: number;
  modified: string;
  note: string;
  number: string;
  partnerSign: number;
  protocolName: string;
  protocolNumber: string;
  receiveTariffPayer: Payer;
  returnTariffPayer: Payer;
  routineRepairPayer: Payer;
  scheduledRepairPayer: Payer;
  signerAuthority: string;
  startDate: string;
  startRentRateRoutineRepair: StartRepairDate | '';
  startRentRateScheduledRepair: StartRepairDate | '';
  status: DocumentStatus;
  stopRentRateRoutineRepair: StopRepairDate | '';
  stopRentRateScheduledRepair: StopRepairDate | '';
  toRoutineRepairTariffPayer: Payer;
  toScheduledRepairTariffPayer: Payer;
  vatRate: VatRate;
  wagonType: number | null;
}

export interface IRentProtocolPurchase
  extends Omit<
    IRentProtocolPurchaseSerialized,
    | 'created'
    | 'date'
    | 'endDate'
    | 'modified'
    | 'startDate'
    | 'startRentRateRoutineRepair'
    | 'startRentRateScheduledRepair'
    | 'stopRentRateRoutineRepair'
    | 'stopRentRateScheduledRepair'
  > {
  created: Date;
  date: Date;
  endDate: Date;
  modified: Date;
  startDate: Date;
  startRentRateRoutineRepair: StartRepairDate | null;
  startRentRateScheduledRepair: StartRepairDate | null;
  stopRentRateRoutineRepair: StopRepairDate | null;
  stopRentRateScheduledRepair: StopRepairDate | null;
}

function deserializeRentProtocolPurchase({
  created,
  date,
  endDate,
  modified,
  startDate,
  startRentRateRoutineRepair,
  startRentRateScheduledRepair,
  stopRentRateRoutineRepair,
  stopRentRateScheduledRepair,
  ...otherProps
}: IRentProtocolPurchaseSerialized): IRentProtocolPurchase {
  return {
    ...otherProps,
    created: parseDate(created),
    date: parseDate(date),
    endDate: parseDate(endDate),
    modified: parseDate(modified),
    startDate: parseDate(startDate),
    startRentRateRoutineRepair: startRentRateRoutineRepair || null,
    startRentRateScheduledRepair: startRentRateScheduledRepair || null,
    stopRentRateRoutineRepair: stopRentRateRoutineRepair || null,
    stopRentRateScheduledRepair: stopRentRateScheduledRepair || null,
  };
}

export enum RentProtocolsPurchaseSortingField {
  Contract = 'contract',
  Date = 'date',
  EndDate = 'endDate',
  OrderingNumber = 'orderingNumber',
  StartDate = 'startDate',
}

interface IFetchRentProtocolsPurchaseParams {
  contract?: number;
  ids?: number[];
  page?: number;
  partner?: number;
  sorting?: Array<ISorting<RentProtocolsPurchaseSortingField>>;
}

export async function fetchRentProtocolsPurchase(
  api: ApiClient,
  { contract, ids, page, partner, sorting }: IFetchRentProtocolsPurchaseParams
): Promise<ListResponse<IRentProtocolPurchase>> {
  const response = await api.get<ListResponse<IRentProtocolPurchaseSerialized>>(
    BASE_ENDPOINT,
    {
      contract,
      ids: ids?.join(','),
      ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
      page,
      partner,
    }
  );

  return {
    ...response,
    results: response.results.map(deserializeRentProtocolPurchase),
  };
}

interface ICreateOrUpdateRentProtocolPurchaseParams {
  contract: number;
  date: Date | null;
  dayRate: string;
  dayRateTotal: string;
  endDate: Date | null;
  expensiveComponentsPayer: Payer;
  files: string[];
  fromRoutineRepairTariffPayer: Payer;
  fromScheduledRepairTariffPayer: Payer;
  hasRentRateRoutineRepair: boolean;
  hasRentRateScheduledRepair: boolean;
  note: string;
  number: string;
  partnerSign: number | null;
  receiveTariffPayer: Payer;
  returnTariffPayer: Payer;
  routineRepairPayer: Payer;
  scheduledRepairPayer: Payer;
  signerAuthority: string;
  startDate: Date | null;
  startRentRateRoutineRepair: StartRepairDate | null;
  startRentRateScheduledRepair: StartRepairDate | null;
  status: DocumentStatus;
  stopRentRateRoutineRepair: StopRepairDate | null;
  stopRentRateScheduledRepair: StopRepairDate | null;
  toRoutineRepairTariffPayer: Payer;
  toScheduledRepairTariffPayer: Payer;
  vatRate: VatRate;
  wagonType: number | null;
}

function serializeCreateOrUpdateRentProtocolPurchaseParams({
  date,
  endDate,
  startDate,
  startRentRateRoutineRepair,
  startRentRateScheduledRepair,
  stopRentRateRoutineRepair,
  stopRentRateScheduledRepair,
  ...otherParams
}: ICreateOrUpdateRentProtocolPurchaseParams) {
  return {
    ...otherParams,
    date: date && dayjs(date).format(DATE_FORMAT_API_DATE),
    endDate: endDate && dayjs(endDate).format(DATE_FORMAT_API_DATE),
    startDate: startDate && dayjs(startDate).format(DATE_FORMAT_API_DATE),
    startRentRateRoutineRepair: startRentRateRoutineRepair || '',
    startRentRateScheduledRepair: startRentRateScheduledRepair || '',
    stopRentRateRoutineRepair: stopRentRateRoutineRepair || '',
    stopRentRateScheduledRepair: stopRentRateScheduledRepair || '',
  };
}

export async function createRentProtocolPurchase(
  api: ApiClient,
  params: ICreateOrUpdateRentProtocolPurchaseParams
) {
  const createdProtocol = await api.post<IRentProtocolPurchaseSerialized>(
    BASE_ENDPOINT,
    serializeCreateOrUpdateRentProtocolPurchaseParams(params)
  );

  return deserializeRentProtocolPurchase(createdProtocol);
}

export async function updateRentProtocolPurchase(
  api: ApiClient,
  id: number,
  params: ICreateOrUpdateRentProtocolPurchaseParams
) {
  const updatedProtocol = await api.put<IRentProtocolPurchaseSerialized>(
    `${BASE_ENDPOINT}/${id}`,
    serializeCreateOrUpdateRentProtocolPurchaseParams(params)
  );

  return deserializeRentProtocolPurchase(updatedProtocol);
}

export function getRentProtocolPurchaseDocxDownloadLink(
  api: ApiClient,
  id: number
) {
  return api.buildApiUrl(`${BASE_ENDPOINT}/${id}/download_docx`);
}

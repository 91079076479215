import { useApiClient } from '_core/api/context';
import { fetchAllPages } from '_core/api/fetchAllPages';
import { ListResponse } from '_core/api/types';
import { Breadcrumbs, BreadcrumbsItem } from '_core/breadcrumbs';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { parseDate } from '_core/dates/utils';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { submissionErrorsFromApiError } from '_core/final-form/submissionErrorsFromApiError';
import { parseJsonWithFallback } from '_core/parseJsonWithFallback';
import { FormattedTitle } from '_core/react-head/formattedTitle';
import { useFormNavigationLock } from '_core/router5/navigationLock';
import { useAsyncData } from '_core/useAsyncData';
import { Currency } from 'currencies/types';
import dayjs from 'dayjs';
import { IBank, IInvoice, InvoiceVatType } from 'invoices/types';
import { Row, VGrid } from 'layout/contentLayout';
import * as React from 'react';
import { useMemo } from 'react';
import { useRouteNode } from 'react-router5';

import { IInvoiceFormValues, InvoicesForm } from './form';

export default function InvoicesCreateRoute() {
  const { route, router } = useRouteNode('invoices.create');
  const navigationLock = useFormNavigationLock('invoices.create');
  const api = useApiClient();

  const { data, isFetching } = useAsyncData(
    [api, route.params.copyFrom],
    async () => {
      const [banks, invoice] = await Promise.all([
        fetchAllPages(page =>
          api.get<ListResponse<IBank>>('/directories_banks', { page })
        ).then(response => response.results),

        route.params.copyFrom
          ? api.get<IInvoice>(`/invoices/${route.params.copyFrom}`)
          : Promise.resolve(null),
      ]);

      return {
        banks,
        invoice,
      };
    }
  );

  const listParams = useMemo(
    () => parseJsonWithFallback(route.params.listParams, {}),
    [route.params.listParams]
  );

  if (!data) {
    return isFetching ? <CenteredSpinner /> : <GenericErrorMessage />;
  }

  const persist = ({
    amount,
    date,
    paymentAmount,
    paymentBefore,
    paymentPlanned,
    ...otherProps
  }: IInvoiceFormValues) => {
    return api.post<IInvoice>('/invoices', {
      ...otherProps,
      amount: amount === '' ? undefined : amount,
      date: date ? dayjs(date).format(DATE_FORMAT_API_DATE) : null,
      paymentAmount: paymentAmount === '' ? undefined : paymentAmount,
      paymentBefore: paymentBefore
        ? dayjs(paymentBefore).format(DATE_FORMAT_API_DATE)
        : null,
      paymentPlanned: paymentPlanned
        ? dayjs(paymentPlanned).format(DATE_FORMAT_API_DATE)
        : null,
    });
  };

  const title = 'Новый счёт';

  return (
    <>
      <FormattedTitle>{title}</FormattedTitle>

      <VGrid>
        <Row>
          <Breadcrumbs>
            <BreadcrumbsItem
              label="Счета в оплату"
              params={listParams}
              to="invoices"
            />

            <BreadcrumbsItem label={title} />
          </Breadcrumbs>
        </Row>

        <Row>
          <InvoicesForm
            banks={data.banks}
            initialValues={
              data.invoice
                ? {
                    ...data.invoice,
                    bank:
                      data.invoice.bank == null
                        ? null
                        : String(data.invoice.bank),
                    date: parseDate(data.invoice.date),
                    paymentBefore: parseDate(data.invoice.paymentBefore),
                    paymentPlanned:
                      data.invoice.paymentPlanned == null
                        ? null
                        : parseDate(data.invoice.paymentPlanned),
                    scan:
                      route.params.withFiles === 'True'
                        ? data.invoice.scan
                        : null,
                  }
                : {
                    amount: '',
                    bank: null,
                    contract: null,
                    currency: Currency.RUB,
                    date: new Date(),
                    note: '',
                    number: '',
                    partner: null,
                    paymentAmount: '',
                    paymentBefore: new Date(),
                    paymentPlanned: null,
                    scan: null,
                    vatRateUsed: null,
                    vatType: InvoiceVatType.Included,
                  }
            }
            navigationLock={navigationLock}
            onSave={async values => {
              try {
                await persist(values);
                navigationLock.unlock();
                router.navigate('invoices', listParams);
                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось создать счёт: Непредвиденная ошибка'
                );
              }
            }}
            onSaveAndContinue={async values => {
              try {
                const updatedInvoice = await persist(values);
                navigationLock.unlock();
                router.navigate('invoices.edit', {
                  id: updatedInvoice.id,
                  listParams: route.params.listParams,
                });
                return undefined;
              } catch (err) {
                return submissionErrorsFromApiError(
                  err,
                  'Не удалось создать счёт: Непредвиденная ошибка'
                );
              }
            }}
          />
        </Row>
      </VGrid>
    </>
  );
}

import { useApiClient } from '_core/api/context';
import { CenteredSpinner } from '_core/feedback/centeredSpinner';
import { GenericErrorMessage } from '_core/feedback/genericErrorMessage';
import { chunk } from '_core/fp/chunk';
import { intersection } from '_core/fp/intersection';
import { without } from '_core/fp/without';
import { indexByLastItem } from '_core/indexBy';
import { useAsyncData } from '_core/useAsyncData';
import {
  Button,
  Classes,
  H4,
  Intent,
  NonIdealState,
  Popover,
  Position,
} from '@blueprintjs/core';
import { ParkWagon } from 'parks/api';
import * as React from 'react';
import { useState } from 'react';
import { useField } from 'react-final-form';
import { fetchManyWagons, IWagonSerialized } from 'wagons/api';

import { AddRemoveWagonsForm } from './addRemoveWagonsForm';
import * as css from './parkWagonsField.module.css';
import { createLocalWagonTag, WagonTags } from './wagonTags';

interface IProps {
  expeditionRequestsWagons: IWagonSerialized[];
  name: string;
  techrunRequestsWagons: IWagonSerialized[];
  wagonNumbers: ParkWagon[] | undefined;
  wagonsMatcher: (wagon: string) => boolean;
}

export function ParkWagonsField({
  expeditionRequestsWagons,
  name,
  techrunRequestsWagons,
  wagonNumbers,
  wagonsMatcher,
}: IProps) {
  const { input, meta } = useField<string[]>(name);
  const api = useApiClient();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const initialValue = meta.initial!;

  const { data, isFetching } = useAsyncData([api, input.value], async () => {
    const wagonsChunks = await Promise.all(
      chunk(300, input.value).map(numbers =>
        fetchManyWagons(api, numbers, { pageSize: 300 })
      )
    );

    return {
      wagons: wagonsChunks.flat(),
    };
  });

  const [popoverIsOpen, setPopoverIsOpen] = useState(false);

  const handleItemRemove = (wagonNumber: string) => {
    input.onChange(input.value.filter(num => num !== wagonNumber));
  };

  if (!data) {
    return isFetching ? <CenteredSpinner /> : <GenericErrorMessage />;
  }

  const justAdded =
    wagonNumbers == null
      ? {}
      : wagonNumbers.reduce<Record<string, boolean>>((result, wagon) => {
          result[wagon.wagon] = wagon.justAdded;
          return result;
        }, {});

  const wagonsIndex = indexByLastItem(data.wagons, wagon => wagon.number);

  const numbersToWagons = (numbers: string[]) =>
    numbers.map(number =>
      wagonsIndex[number] ? wagonsIndex[number] : createLocalWagonTag(number)
    );

  const oldWagons = numbersToWagons(
    intersection(input.value, initialValue)
      .filter(number => !justAdded[number])
      .sort()
  );

  const newWagons = numbersToWagons([
    ...without(initialValue, input.value),
    ...input.value.filter(number => justAdded[number]),
  ]);

  const oldWagonsFiltered = oldWagons.filter(({ number }) =>
    wagonsMatcher(number)
  );
  const newWagonsFiltered = newWagons.filter(({ number }) =>
    wagonsMatcher(number)
  );

  const totalWagonCount =
    oldWagons.length +
    newWagons.length +
    techrunRequestsWagons.length +
    expeditionRequestsWagons.length;

  const techrunRequestsWagonsFiltered = techrunRequestsWagons.filter(
    ({ number }) => wagonsMatcher(number)
  );

  const expeditionRequestsWagonsFiltered = expeditionRequestsWagons.filter(
    ({ number }) => wagonsMatcher(number)
  );

  const filteredWagonCount =
    oldWagonsFiltered.length +
    newWagonsFiltered.length +
    techrunRequestsWagonsFiltered.length +
    expeditionRequestsWagonsFiltered.length;

  return (
    <>
      <H4>
        <span className={css.headingAndButton}>
          Вагоны в парке: {totalWagonCount}{' '}
          <Popover
            content={
              <AddRemoveWagonsForm
                currentWagons={input.value}
                disabled={meta.submitting}
                onClose={() => {
                  setPopoverIsOpen(false);
                }}
                onWagonsUpdate={newWagonsValue => {
                  input.onChange(newWagonsValue);
                  setPopoverIsOpen(false);
                }}
              />
            }
            isOpen={popoverIsOpen}
            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
            position={Position.RIGHT_TOP}
            onInteraction={setPopoverIsOpen}
          >
            <Button
              disabled={meta.submitting}
              icon="manually-entered-data"
              intent={Intent.PRIMARY}
              minimal
              small
              text="Добавить/удалить"
            />
          </Popover>
        </span>
      </H4>

      {totalWagonCount === 0 ? (
        <NonIdealState icon="list" description="Нет вагонов" />
      ) : filteredWagonCount === 0 ? (
        <NonIdealState
          icon="list"
          description="Не найдено вагонов, удовлетворяющих запросу"
        />
      ) : (
        <>
          {oldWagonsFiltered.length !== 0 && (
            <div className={css.wagonTagsGroup}>
              <WagonTags
                wagons={oldWagonsFiltered}
                onItemRemove={handleItemRemove}
              />
            </div>
          )}

          {newWagonsFiltered.length !== 0 && (
            <div className={css.wagonTagsGroup}>
              <WagonTags
                wagons={newWagonsFiltered}
                onItemRemove={handleItemRemove}
              />
            </div>
          )}

          {techrunRequestsWagonsFiltered.length !== 0 && (
            <div className={css.wagonTagsGroup}>
              <WagonTags wagons={techrunRequestsWagonsFiltered} />
            </div>
          )}

          {expeditionRequestsWagonsFiltered.length !== 0 && (
            <div className={css.wagonTagsGroup}>
              <WagonTags wagons={expeditionRequestsWagonsFiltered} />
            </div>
          )}
        </>
      )}
    </>
  );
}

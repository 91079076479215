import { ApiClient } from '_core/api/client';
import { makeFetchMany } from '_core/api/makeFetchMany';
import { ListResponse } from '_core/api/types';
import { DATE_FORMAT_API_DATE } from '_core/dates/formats';
import { ISorting, sortingToApiQueryParam } from '_core/sorting';
import { snakeCase } from 'change-case';
import dayjs from 'dayjs';

import {
  ITechrunTransportation,
  TechrunTransportationAccountingState,
  TechrunTransportationGroup,
  TechrunTransportationState,
} from './types';

export enum TechrunTransportationsSortingField {
  ArrivalDate = 'arrivalDate',
  Created = 'created',
  DepartureDate = 'departureDate',
  Name = 'transportationName',
  State = 'state',
}

interface IFetchTechrunTransportationsParams {
  accountingStates?: TechrunTransportationAccountingState[];
  arrivalBefore?: Date;
  arrivalMonth?: Date;
  arrivalMonths?: Date[];
  arrivalStation?: number;
  consignee?: number;
  countExpenses?: boolean;
  departureBefore?: Date;
  departureMonth?: Date;
  departureMonths?: Date[];
  departureStation?: number;
  favoriteTransps?: boolean;
  group?: TechrunTransportationGroup;
  hasSuppliersDocs?: boolean;
  ids?: number[];
  isEmpty?: boolean;
  operationStation?: number;
  page?: number;
  pageSize?: number;
  partners?: number[];
  requests?: number[];
  search?: string;
  sorting?: Array<ISorting<TechrunTransportationsSortingField>>;
  states?: TechrunTransportationState[] | undefined;
  tariffSupplierDocument?: number;
  wagonNumbers?: string[];
}

export function fetchTechrunTransportations(
  api: ApiClient,
  {
    accountingStates,
    arrivalBefore,
    arrivalMonth,
    arrivalMonths,
    arrivalStation,
    consignee,
    countExpenses,
    departureBefore,
    departureMonth,
    departureMonths,
    departureStation,
    favoriteTransps,
    group,
    hasSuppliersDocs,
    ids,
    isEmpty,
    operationStation,
    page,
    pageSize,
    partners,
    requests,
    search,
    sorting,
    states,
    tariffSupplierDocument,
    wagonNumbers,
  }: IFetchTechrunTransportationsParams = {}
) {
  return api.get<
    ListResponse<
      ITechrunTransportation,
      {
        filterOptions: {
          arrivalDate: [string, string];
          departureDate: [string, string];
          partners: number[];
          requests: number[];
        };
      }
    >
  >('/techrun_transportations', {
    accountingStates:
      accountingStates && accountingStates.length !== 0
        ? accountingStates.join(',')
        : undefined,
    arrivalBefore:
      arrivalBefore && dayjs(arrivalBefore).format(DATE_FORMAT_API_DATE),
    arrivalMonth:
      arrivalMonth && dayjs(arrivalMonth).format(DATE_FORMAT_API_DATE),
    arrivalMonths:
      arrivalMonths && arrivalMonths.length !== 0
        ? arrivalMonths
            .map(monthDate => dayjs(monthDate).format(DATE_FORMAT_API_DATE))
            .join(',')
        : undefined,
    arrivalStation,
    consignee,
    countExpenses,
    departureBefore:
      departureBefore && dayjs(departureBefore).format(DATE_FORMAT_API_DATE),
    departureMonth:
      departureMonth && dayjs(departureMonth).format(DATE_FORMAT_API_DATE),
    departureMonths:
      departureMonths && departureMonths.length !== 0
        ? departureMonths
            .map(monthDate => dayjs(monthDate).format(DATE_FORMAT_API_DATE))
            .join(',')
        : undefined,
    departureStation,
    favoriteTransps,
    group,
    hasSuppliersDocs,
    ids: ids && ids.length !== 0 ? ids.join(',') : undefined,
    isEmpty,
    operationStation,
    ordering: sorting && sortingToApiQueryParam(sorting, snakeCase),
    page,
    pageSize,
    partners:
      partners && partners.length !== 0 ? partners.join(',') : undefined,
    requests:
      requests && requests.length !== 0 ? requests.join(',') : undefined,
    search,
    states: states && states.length !== 0 ? states.join(',') : undefined,
    tariffSupplierDocument,
    wagonNumbers:
      wagonNumbers && wagonNumbers.length !== 0
        ? wagonNumbers.join(',')
        : undefined,
  });
}

export const fetchManyTechrunTransportations = makeFetchMany(
  fetchTechrunTransportations,
  'ids'
);

export function fetchTechrunTransportation(api: ApiClient, id: number) {
  return api.get<ITechrunTransportation>(`/techrun_transportations/${id}`);
}

export function moveTechrunTransportationsToGroup(
  api: ApiClient,
  ids: number[],
  group: TechrunTransportationGroup
) {
  return api.post<ITechrunTransportation[]>(
    '/techrun_transportations/move_to_group',
    { group },
    { query: { ids: ids.join(',') } }
  );
}
